
<section id="admindashboard">
    <div class="container-fluid" >
        <div class="page-header pb-3">
            <div class="row">
                <div class="col-lg-12">
                    <div class="row justify-content-between align-item-center mx-0">
                        <h3 class="d-inline-block"> Manage Carfax Flag Report </h3>

                        <button *ngIf="buttonloaderSendEmail" class="btn btn-sm btn-primary rounded-pill">
                            <i class="fa fa-spinner fa-spin"></i>Generating</button>
                        <button type="button" *ngIf="!buttonloaderSendEmail" style="margin-left: 14px;"
                        (click)="GenerateFlagReport()" class="btn btn-sm btn-primary rounded-pill">
                        <i class="fas fa-flag ml-1"></i>&nbsp; Generate Flag Report</button>

                        <!-- <button type="button" style="font-size: 14px;" class="btn btn-sm btn-primary" (click)="GenerateFlagReport()">
                            <i class="fas fa-flag ml-1"></i>
                            Generate Flag Report
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-warning text-right">{{VehiclesPendingforCarfaxReport  }}</h5>
                                    <span>Vehicles count Pending Carfax Report</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-success text-right">{{CarfaxCounttotalCount}}</h5>
                                    <span>Vehicles Count With Carfax Report</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="card card-raised h-100">
              <div class="card-header card-raised bg-info text-white">
                <h5 class="card-title text-white text-uppercase mb-0">Vehicles Pending for Carfax Report</h5>
               </div>
               <div class="card-body p-4">
                  <div class="row">
                      <div class="col-12">
                        <div class="row justify-content-between align-items-center">
                          <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                              <li><button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="copytable('usertable')">Copy</button></li>
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="exportexcel()">Excel</button>
                              </li>
                              
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="downloadPdf()">PDF</button>
                              </li>
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="printTable()">Print</button>
                              </li>
                            </ul>
                          </div>
                          <div class="search-heros col-xl col-lg-12 col-md-12 mt-2 ml-auto mt-lg-2">
                            <div class="form-group d-flex mb-2">
                                <label class="mr-2 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchTextRegion"
                                [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </div>
                          </div>
                        </div>

                          <div class="table-responsive">
                              <table class="table table-bordered" id="usertabledealer">
                              <thead>
                                  <tr>
                                    <th  (click)="sortdealer('dealerName')"><small>Dealer Name</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th  (click)="sortdealer('regionName')"><small>VIN</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th  (click)="sortdealer('make')"><small>Make</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th  (click)="sortdealer('model')"><small>Model</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th  (click)="sortdealer('mileage')"><small>Mileage</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th  (click)="sortdealer('carYr')"><small>Year</small><i class="fa fa-fw fa-sort"></i></th>
                                    <th style="min-width:120px;text-align: right;"><small>Dealer Cost </small></th>
                                    <th style="text-align: right;"><small>Listed Price  </small></th>                                  
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let statisticsRegionWiselistData of StatisticsRegionWiselist | slice: (pageRegion-1) * pageRegionSize : pageRegion * pageRegionSize; index as i">
                                  
                                    <td>{{ statisticsRegionWiselistData.dealerName }}</td>
                                      <td>{{ statisticsRegionWiselistData.vin }}</td>
                                      <td>{{ statisticsRegionWiselistData.make }}</td>
                                      <td>{{ statisticsRegionWiselistData.model }}</td>
                                      <td>{{ statisticsRegionWiselistData.mileage }}</td>
                                      <td>{{ statisticsRegionWiselistData.carYr }}</td>
                                      <td style="min-width:120px;text-align: right;"> {{ statisticsRegionWiselistData.dealerCost | currency }}</td>
                                      <td style="text-align: right;"> {{ statisticsRegionWiselistData.listedPrice | currency }}</td>

                                  </tr>
                            
                              </tbody>
                              </table>
                              <br>
                              <ngb-pagination [collectionSize]="StatisticsRegionWiselist.length" 
                              [(page)]="pageRegion" [maxSize]="pageRegionSize" [rotate]="true" 
                              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>

                              <!-- <ngb-pagination [(page)]="pageRegion"
                              [pageSize]="pageRegionSize"
                              [collectionSize]="StatisticsRegionWiselist.length" 
                              aria-label="Default pagination"></ngb-pagination>                             -->
                          </div>
                      </div>
                  </div>
               </div>
              </div>
          </div>
            <div class="col-md-6">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">
                  <h5 class="card-title text-white text-uppercase mb-0">Vehicles With Carfax Report</h5>
                 </div>
                 <div class="card-body p-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto documentbtn">
                                  <ul class="list-unstyled mb-1">
                                    <li><button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="copytable('usertabledealer')">Copy</button></li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="exportexcelDealer()">Excel</button>
                                    </li>
                                 
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="downloadPdfDealer()">PDF</button>
                                    </li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1 rounded-pill" (click)="printTableDealer()">Print</button>
                                    </li>
                                  </ul>
                                </div>
                                <div class="search-heros col-xl col-lg-12 col-md-12 mt-2 ml-auto mt-lg-2">
                                    <div class="form-group d-flex mb-2">
                                        <label class="mr-2 mt-2">Search:</label>
                                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" id="usertabledealer">
                                <thead>
                                    <tr>
                                       
                                        <th  (click)="sortdealer('dealerName')"><small>Dealer Name</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('regionName')"><small>VIN</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('make')"><small>Make</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('model')"><small>Model</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('mileage')"><small>Mileage</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('carYr')"><small>Year</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th style="min-width:120px;text-align: right;"><small>Dealer Cost </small></th>
                                       <th style="text-align: right;"><small>Listed Price  </small></th>                                     
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let StatisticsDealerWiselistdata of StatisticsDealerWiselist | filter:searchText | slice: (pageDealer-1) * pageDealerSize : (pageDealer-1) * pageDealerSize + pageDealerSize; let i = index; let odd = odd">
                                 
                                    <td>{{ StatisticsDealerWiselistdata.dealerName }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.vin }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.make }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.model }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.mileage }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.carYr }}</td>
                                      <td style="min-width:120px;text-align: right;"> {{ StatisticsDealerWiselistdata.dealerCost | currency }}</td>
                                      <td style="text-align: right;"> {{ StatisticsDealerWiselistdata.listedPrice | currency }}</td>

                                    </tr>
                                </tbody>
                                </table>
                                <br>
                           <ngb-pagination [collectionSize]="StatisticsDealerWiselist.length" 
                           [(page)]="pageDealer" [maxSize]="5" [rotate]="true" 
                           [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
            </div>
          
        </div>
    </div>

    <!-- <app-footer id="secondfooter"></app-footer> -->

</section>









