<section id="admindashboard">
  <form class="col-12 px-0 findcardblock" [formGroup]="findCarForm">
    <div class="container-fluid">
      <!-- <div class="page-header pb-2">
                      <div class="row">
                    <div class="col-lg-12">
                        <h3>Find Cars</h3>
                    </div>
                </div>
            </div>-->
      <div class="row mt-2" id="LendersDIVID">
        <div class="col-md-12">
          <div class="card card-raised h-100">
            <div class="card-header card-raised bg-info text-white">
              <h6 class="card-title text-white text-uppercase mb-0">List of Lenders</h6>
            </div>
            <div class="card-body p-3">
              <div id="carList" class="row rounded-lg">
                <div class="form-check form-check-inline" data-step="2" data-intro="Select lenders" data-title="Step 2">
                  <div class="lenderlistName" *ngFor="let optionslist of lenderlists">
                    <label>
                      <input type="checkbox" name="options"
                        (change)="onCheckboxChange(optionslist.id,optionslist.lenderName,$event)"
                        value="{{optionslist.id}}" />
                      {{optionslist.lenderName}}
                    </label>
                  </div>

                </div>
              </div>
              <hr />
              <div class="row mt-1">
                <div class="col-md-4 col-lg-2" id="heightAuto">
                  <div class="input-group">
                    <ng-select style="width:100%;" [items]="GroupLists" bindLabel="globalCodeName"
                      [disabled]="groupDisable" (change)="GetDealerNamebyGroupId(selectedGroupIds)"
                      [ngModelOptions]="{standalone: true}" placeholder="Select group" appendTo="body"
                      [multiple]="false" [(ngModel)]="selectedGroupIds">
                    </ng-select>
                  </div>
                </div>


                <div class="col-md-4 col-lg-3" id="heightAuto">
                  <div class="input-group">
                    <ng-select style="width:100%;" [items]="dealerlistName" bindLabel="dealerName"
                      [ngModelOptions]="{standalone: true}" placeholder="Select dealer Name" appendTo="body"
                      [multiple]="true" [(ngModel)]="dealersmultiselectarray">
                    </ng-select>
                  </div>
                </div>

                <div class="col-md-4 col-lg-2">
                  <select class="form-control" name="province" formControlName="province" [(ngModel)]="province">
                    <option value="0" selected="true">Select Province name</option>
                    <option *ngFor="let Provincelistdata of Provincelist" [value]="Provincelistdata.globalCodeID">
                      {{Provincelistdata.globalCodeName}}</option>
                  </select>
                </div>

                <div class="col-md-4 col-lg-3 ">
                  <div class="form-group mb-md-0">
                    <div class="row justify-content-between align-items-center">
                      <label class="col-lg-auto col-md-12 px-lg-1">Selection List</label>
                      <div class="col-lg col-md-12 px-lg-1">
                        <select class="form-control" name="AithrHubKey" formControlName="AithrHubKey"
                          [(ngModel)]="AithrHubKey">
                          <option value="0" [selected]="true">Selected Dealers</option>
                          <option value="1">AithrHub</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto mt-lg-0 mt-4 pt-1">
                  <div class="custom-control custom-checkbox">
                    <input class="custom-control-input" formControlName="TaxExemptionCheck" type="checkbox"
                      id="TaxExemptionID">
                    <label for="TaxExemptionID" class="custom-control-label">Tax
                      Exemption</label>
                  </div>
                </div>
                <!-- </div> -->
                <!-- //End onde div panel -->

              </div>
            </div>
          </div>
        </div>
      </div>



      <div *ngIf="DisplayFilters==='true'" class="row mt-1 mb-2" id="LendersDIVID">
        <div id="lenderblock" class="col-md-12">
          <div class="card card-raised h-100">
            <div class="card-header card-raised bg-info text-white">
              <div class="row mx-0 justify-content-between align-items-center">
                <h6 class="card-title text-white text-uppercase mb-0">{{LenderNameValue}}</h6>
                <div class="col-auto">
                  <button type="button" id="TDBtnID" (click)="getUnitsByDealerNames();" *ngIf="!buttonloader"
                    class="multisearch btn btn-sm btn-secondary bg-gradient-secondary">Search</button>
                  <button *ngIf="buttonloader" class="buttonload btn btn-sm btn-secondary bg-gradient-secondary"><i
                      class="fa fa-spinner fa-spin mr-1"></i>Loading</button>
                </div>

              </div>

            </div>

            <div class="card-body">
              <div class="row">
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>Lender Key</label>
                    <select class="form-control" name="Lenderkey" #keytext formControlName="Lenderkey"
                      [(ngModel)]="Lenderkey" (change)="GetKeyText(keytext.value)">
                      <option value="0" selected>Select Lender key</option>
                      <option *ngFor="let lenderKeyListdata of lenderKeyList" [value]="lenderKeyListdata.id">
                        {{lenderKeyListdata.planType}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>Pay Call</label>
                    <div class="input-group">
                      <div class="input-group-text"><i class="fas fa-dollar-sign"></i></div>
                      <input id="TDBPCallID" class="form-control" type="number" [(ngModel)]="LenderPayCall"
                        formControlName="LenderPayCall" placeholder="0">
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>Interest Rate</label>
                    <div class="input-group" id="IntRateDIV">
                      <div class="input-group-text">
                        <i class="fas fa-percentage"></i>
                      </div>
                      <input id="TDIntRtID" class="form-control" type="number" placeholder="0"
                        [(ngModel)]="LenderInterestRate" formControlName="LenderInterestRate">
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>ACV</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                      <input id="TDTradeInID" class="form-control" type="number" placeholder="0"
                        [(ngModel)]="ACVValueControl" formControlName="ACVValueControl">
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>Lien</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                      <input id="TDLienID" class="form-control" type="number" placeholder="0"
                        [(ngModel)]="LoanAmountControl" formControlName="LoanAmountControl">
                    </div>
                  </div>
                </div>
                <div class="col-sm-4 col-md-3 col-lg-2">
                  <div class="form-group">
                    <label>Down Payment</label>
                    <div class="input-group">
                      <div class="input-group-text">
                        <i class="fas fa-dollar-sign"></i>
                      </div>
                      <input id="TDDownPayID" class="form-control" type="number" placeholder="0"
                        [(ngModel)]="DownPaymentAmountControl" formControlName="DownPaymentAmountControl">
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>


      <div *ngIf="displayList==='true'" id="inventryList" class="container-fluid">
        <!-- <div>
          <div class="row">
            <div class="form-group col-lg-2 col-md-3 col-sm-7">
              <label>Selection List</label>
              <select class="form-control" name="AithrHubKey" (change)="GetAithrHubUnits()"
                formControlName="AithrHubKey" [(ngModel)]="AithrHubKey">
                <option value="0" [selected]="true">Selected Dealers</option>
                <option value="1">AithrHub</option>
              </select>
            </div>

          </div>
        </div> -->
        <div class="row">
          <div class="col-12 mt-1 px-2">

            <div class="row justify-content-between align-items-center">
              <div class="col-auto documentbtn">
                <ul class="list-unstyled mb-1">
                  <ul class="list-unstyled mb-1">
                    <li>
                      <button class="btn btn-secondary btn-sm mb-3 mb-md-0">Copy</button>
                    </li>

                    <li>
                      <button class="btn btn-secondary btn-sm mb-3 mb-md-0">Excel</button>
                    </li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-3 mb-md-0">PDF</button>
                    </li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-3 mb-md-0">Print</button>
                    </li>
                  </ul>
                </ul>
              </div>
              <div class="search-heros col-md-auto">
                <div class="form-group d-flex mb-1">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                </div>
              </div>
            </div>
            
            <div class="row">
              <div class="col-md-12">
                <div class="card card-raised h-100">
                  <div class="card-header card-raised bg-info text-white">
                    <div class="row justify-content-between align-items-center">
                      <div class="col">
                        <h6 class="card-title text-white text-uppercase mb-0">List Of Inventory Selected for
                          {{LenderNameValue}}</h6>
                      </div>
                      <div class="col text-right">
                        <h6 style="font-size:13px;" class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                      </div>
                      <!-- <div class="card-subtitle"></div> -->
                    </div>
                  </div>
                  <div id="inventory" class="card-body p-4">
                    <div class="table-responsive">
                      <table id="usertable" class="table table-bordered" style="width:100%">
                        <thead>
                          <tr>
                            <th (click)="sort('vIN')"> VIN <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('stockNumber')"> Stock No<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('carYr')">Year <i class="fa fa-fw fa-sort"></i></th>
                            <th (click)="sort('mileage')">Mileage <i class="fa fa-fw fa-sort"></i> </th>
                            <th (click)="sort('make')">Make <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('model')">Model<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th>Condition</th>

                            <th style="text-align: right;" (click)="sort('listedPrice')">Listed Price<i
                                class="fa fa-fw fa-sort"></i></th>
                            <th style="text-align: right;" (click)="sort('dealerCost')">Dealer Cost<i
                                class="fa fa-fw fa-sort"></i></th>

                                <th style="text-align: right;" (click)="sort('ProjectedGross')">Projected Gross<i
                                  class="fa fa-fw fa-sort"></i></th>
                            <th style="text-align: right;" (click)="sort('EffectiveCBB')">EffectiveCBB<i
                                class="fa fa-fw fa-sort"></i></th>
                            <th style="text-align: right;" (click)="sort('AmountFinanced')">Total FLTV<i
                                class="fa fa-fw fa-sort"></i></th>

                            <th style="text-align: right;" (click)="sort('QualifyPrice')">Total BLTV<i
                                class="fa fa-fw fa-sort"></i></th>

                            <th style="text-align: right;" (click)="sort('AmountFinanced')">Total TLV<i
                                class="fa fa-fw fa-sort"></i></th>

                            <th style="text-align: right;" (click)="sort('lenderFee')">Lender Fee</th>
                            <th style="text-align: right;" (click)="sort('backDocFee')">Front Doc Fee</th>
                            <th style="text-align: right;" (click)="sort('backDocFee')">Back Doc Fee</th>


                            <th style="text-align: right;" (click)="sort('ppsa')">PPSA</th>
                            <th style="text-align: right;" (click)="sort('reserves')">Reserves</th>
                            <th style="text-align: right;" (click)="sort('termPeriod')">Term Period</th>
                            <th style="text-align: right;" (click)="sort('termPeriod')">Tax Rate</th>

                            <th style="text-align: right;" (click)="sort('totalPrice')">Total Price</th>
                            <th style="text-align: right;" (click)="sort('totalCost')">Total Cost</th>
                            <th style="text-align: right;" (click)="sort('totalTaxes')">Total Tax<i
                                class="fa fa-fw fa-sort"></i></th>

                            







                            <th style="text-align: right;" (click)="sort('amountToBeFinanced')">AmountToBeFinanced</th>

                            <th style="text-align: right;" (click)="sort('allowableFinance')">Allowable Finance</th>

                            <th style="text-align: right;" (click)="sort('minFinance')">Min Finance</th>
                            <th style="text-align: right;" (click)="sort('minPrice')">Min Price</th>
                            <th style="text-align: right;" (click)="sort('minCost')">Min Cost</th>


                            <th style="text-align: right;" (click)="sort('minGross')">Min Gross</th>
                            <th style="text-align: right;" (click)="sort('minTaxes')">Min Taxes</th>


                            <th style="text-align: right;" (click)="sort('fltVnew')">FLTVnew</th>
                            <th style="text-align: right;" (click)="sort('fltv')">FLTV</th>
                            <th style="text-align: right;" (click)="sort('fltv')">BLTV</th>



                            <th style="text-align:center;">
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">

                            <td>{{ dealerVehicleslistdata.vin }}</td>
                            <td> {{ dealerVehicleslistdata.stockNumber }}</td>
                            <td> {{ dealerVehicleslistdata.carYear }}</td>
                            <td> {{ dealerVehicleslistdata.mileage }}</td>
                            <td> {{ dealerVehicleslistdata.make }}</td>
                            <td> {{ dealerVehicleslistdata.model }}</td>
                            <td> {{ dealerVehicleslistdata.condition }}</td>
                           
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.listedPrice| currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.dealerCost | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.projectedGross | currency: 'USD'
                            }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB *
                              dealerVehicleslistdata.fltv | currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB *
                              dealerVehicleslistdata.bltv | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.tlv | currency: 'USD' }}</td>

                            <td style="text-align: right;"> {{ dealerVehicleslistdata.lenderFee | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.frontDocFee | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.backDocFee | currency: 'USD' }}
                            </td>


                            <td style="text-align: right;"> {{ dealerVehicleslistdata.ppsa | currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.reserves | currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.termPeriod }}</td>

                            <td style="text-align: right;"> {{ dealerVehicleslistdata.taxRate }}</td>

                            <td style="text-align: right;"> {{ dealerVehicleslistdata.totalPrice | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.totalCost | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.totalTaxes | currency: 'USD' }}
                            </td>

                           


                            <td style="text-align: right;"> {{ dealerVehicleslistdata.amountToBeFinanced | currency:
                              'USD' }}</td>

                            <td style="text-align: right;"> {{ dealerVehicleslistdata.allowableFinance | currency: 'USD'
                              }}</td>


                            <td style="text-align: right;"> {{ dealerVehicleslistdata.minFinance | currency: 'USD' }}
                            </td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.minPrice | currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.minCost | currency: 'USD' }}</td>


                            <td style="text-align: right;"> {{ dealerVehicleslistdata.minGross | currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.minTaxes | currency: 'USD' }}</td>




                            <td style="text-align: right;"> {{ dealerVehicleslistdata.fltVnew}}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.fltv}}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.bltv}}</td>

                            <td style="text-align:center;">


                              <!-- <a class="btn bg-gradient-info btn-sm"  (click)="gotoBuildPage(dealerVehicleslistdata.vin);">Build</a> -->
                              <a class="btn bg-gradient-info btn-sm" #BuildNewButton style="display: none;"
                                (click)="gotoBuildPage(dealerVehicleslistdata.vin,dealerVehicleslistdata.listedPrice,dealerVehicleslistdata.totalTaxes,dealerVehicleslistdata.allowableFinance);"
                                target="_blank" routerLink="/build-car/{{dealerVehicleslistdata.vin}}">Build</a>
                              <a class="btn bg-gradient-info btn-sm"
                                (click)="openBuildConfrimModel(buildCarConfirmModel,dealerVehicleslistdata.vin,dealerVehicleslistdata.listedPrice,dealerVehicleslistdata.totalTaxes,dealerVehicleslistdata.allowableFinance)">Build</a>

                            </td>
                          </tr>

                        </tbody>
                      </table>
                      <br>
                      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                      </ngx-spinner>

                      <div class="row justify-content-between align-items-center">
                        <div class="col">

                          <ngb-pagination [collectionSize]="dealerVehicleslist.length" [(page)]="page" [maxSize]="5"
                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>

                      </div>
                    </div>
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>


  </form>


  <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
    (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


  <ng-template #dealercheckbox>
    <div id="pdf-viewer-outer" class="modal-body">
      <h5>Dealer List </h5>
      <hr />
      <div class="row mt-2" id="dealerchckbox">

        <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
        <div class="col-12 listoption">
          <input type="checkbox" name="chckboxall" (click)="Checkalldealer($event)" id="checkall" />
          <label style="font-size:13px;" class="ml-1">
            <h6 class="mb-0" style="font-size:14px;"></h6>Select All
          </label>
        </div> <br />

        <div class="col-4 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
          <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" /> {{item.dealerName}}
        </div>

      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedDealer()">Yes</button>
      <button type="button" class="btn btn-danger btn-sm" (click)="decline()">No</button>
    </div>
  </ng-template>

  <ng-template #buildCarConfirmModel let-modal>
    <div class="modal-header" style="background-color: black;">
      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Notification</h5>
      <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <section class="generate-report col">
        Do you want to Open Multiple Tabs for Comparisons for Selected Unit/Vehicle – Each Tab for Selected Lenders?
      </section>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success btn-sm text-white" title="Open tab for All lenders"
        (click)="OpenNewTab()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
      </button>

      <button class="btn btn-danger btn-sm text-white" title="Lender" (click)="OpenNewTab()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
      </button>


    </div>
  </ng-template>


</section>