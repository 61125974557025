import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LenderService } from '@app/_services/lender.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { AIXLenders } from '@app/_models/AIXLenders';
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-list-lenders',
  templateUrl: './list-lenders.component.html',
  styleUrls: ['./list-lenders.component.less']
})
export class ListLendersComponent implements OnInit {
  ListLenderForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,

    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private router: Router,
    private lenderService: LenderService,
    private globalcodeService: GlobalcodeService,
    private accountService: AccountService,
    notifier: NotifierService) {

    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  ListofPendingUnitsData = 'ListOflenders.xlsx';

  lenderlists: AIXLenders[] = [];
  //Premiumlists: AIXLenders[] = [];

  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  // page = 1;
  phone: any;
  // pageSize = 10;
  loading = false;
  submitted = false;
  modalRef: BsModalRef;
  Provincelist = [];
  deleteitem: any;
  TotalRecord: any;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  editlenderNamevalue: any;
  editaddressvalue: any;
  editcityvalue: any;
  editregionvalue: any;
  editphonevalue: any;
  editemailvalue: any;
  editpostalcodevalue: any;
  updateid: any;
  LenderSheetName: any;
  ViewRateSheetLabel: any;
  edititemid: any;
  lenderproductData = 'ExcelSheet.xlsx';

  //for new paging start

  premiumData: any[] = [];
  paginateData: any[] = [];
  // source$: Observable<any>;
  page = 1;
  pageSize = 10;
  collectionSize = 0;

  //end


  getPremiumData() {
    debugger;
    this.lenderlists = this.premiumData
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);


  }


  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.lenderlists.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    debugger;
    this.CreateFrom();
    this.GetProvincelist();
    this.Getlenderslist();

    this.getPremiumData();
    this.ViewRateSheetLabel = "false";

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  // functions for getting lenders
  Getlenderslist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {



          this.premiumData = data;  //keep original data into premimum


          //cod for proper paging
          this.lenderlists = data;
          this.collectionSize = this.premiumData.length;
          this.getPremiumData();


          this.TotalRecord = data.length;
        },
        error => {
        });
  }


  openLendersModel(targetModal) {
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });

  }


  get f() { return this.ListLenderForm.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }

  confirm(): void {

    this.deleteitem;
    var modelGroup = {
      "Id": this.deleteitem
    }
    this.lenderService.DeleteLender(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted successfully");
          this.Getlenderslist();
        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.modalRef.hide();
  }

  openModal(template: TemplateRef<any>, itemid: any) {

    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }


  EditModalpopup(template: TemplateRef<any>, itemid: any) {

    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.lenderService.EditLenders(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.editlenderNamevalue = data[0].lenderName;
          this.editaddressvalue = data[0].address;
          this.editcityvalue = data[0].city;
          this.editregionvalue = data[0].region;
          this.editphonevalue = data[0].phone;
          this.editemailvalue = data[0].email;
          this.editpostalcodevalue = data[0].postalcode;
          this.updateid = data[0].id;
          if (data[0].logoLink != "NO") {

            var replaceName = data[0].logoLink;
            this.LenderSheetName = replaceName.replace('Lender_Pdf/', '');
            this.ViewRateSheetLabel = "true";
          }


          this.modalRef = this.confirmModalService.show(template, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.lenderlists);
  }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  printTable() {
    var prepare = [];
    this.lenderlists.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.address);
      tempObj.push(e.city);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.email);
      tempObj.push(e.postalcode);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>Name</th><th>Address</th><th>City</th><th>Region</th><th>Phone</th><th>Email</th><th>Postal Code</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  // for pdf
  downloadPdf() {
    var prepare = [];
    this.lenderlists.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.address);
      tempObj.push(e.city);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.email);
      tempObj.push(e.postalcode);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['Name', 'Address', 'City', 'Region', 'Phone', 'Email', 'Postal code']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Listoflenders' + '.pdf');
  }

  exportexcel() {
    const prepare = [];
    this.lenderlists.forEach(e => {
      const tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.address);
      tempObj.push(e.city);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.email);
      tempObj.push(e.postalcode);
      prepare.push(tempObj);
    });

    // Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      [' Name', 'Address', 'City', 'Region', 'Phone', 'Email', 'Postal code'],
      ...prepare
    ]);

    // Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Listoflenders');

    // Save the workbook to a file /
    XLSX.writeFile(wb, 'Listoflenders.xlsx');
  }

  update() {

    this.submitted = true;

    if (this.ListLenderForm.controls.editlenderName.invalid) {
      this.isValid = false;
      return;
    }
    if (this.ListLenderForm.controls.editaddress.invalid) {
      this.isValid = false;
      return;
    }
    if (this.ListLenderForm.controls.editcity.invalid) {
      this.isValid = false;
      return;
    }
    if (this.ListLenderForm.controls.editphone.invalid) {
      this.isValid = false;
      return;
    }
    if (this.ListLenderForm.controls.editemail.invalid) {
      this.isValid = false;
      return;
    }
    if (this.ListLenderForm.controls.editpostalcode.invalid) {
      this.isValid = false;
      return;
    }

    // Check if the region is selected
    if (this.editregionvalue === '0') {
      this.isValid = false;
      this.ListLenderForm.controls.editregion.setErrors({ required: true }); // Manually set the 'required' error
      return;
    }

    // formdata for post Logo
    const formData = new FormData();
    for (const key of Object.keys(this.ListLenderForm.value)) {
      var value = this.ListLenderForm.value[key];
      formData.append(key, value);
    }

    formData.append("id", this.updateid);

    var modelGroup = {
      "id": this.updateid,
      "LenderName": this.editlenderNamevalue,
      "Address": this.editaddressvalue,
      "City": this.editcityvalue,
      "Region": this.editregionvalue,
      "Phone": this.editphonevalue,
      "email": this.editemailvalue,
      "Postalcode": this.editpostalcodevalue,
    }

    // Explicitly set the 'touched' state of the editregion control
    this.ListLenderForm.controls.editregion.markAsTouched();

    this.lenderService.UpdateLender(formData)
      .subscribe(
        (data: any) => {
          this.Getlenderslist();
          this.submitted = false;
          this.CreateFrom();
          this.showNotification("success", "Data updated successfully");
          this.ViewRateSheetLabel = "false";
          this.LenderSheetName = "";
          this.modalRef.hide();
        },
        error => {
        });
  }


  Pdfview(id: any) {

    var formData = {
      "Id": id

    }


    this.lenderService.Getpdfviw(formData)
      .subscribe(
        (data: any) => {

          var byteCharacters = atob(data.filebase);
          var byteNumbers = new Array(byteCharacters.length);
          for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          var byteArray = new Uint8Array(byteNumbers);
          var file = new Blob([byteArray], { type: 'application/pdf;base64' });
          var fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        },
        error => {
        });



  }

  onSubmit() {


    this.submitted = true;

    if (this.ListLenderForm.controls.lenderName.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.ListLenderForm.controls.address.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.ListLenderForm.controls.city.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.ListLenderForm.controls.region.invalid) {
      this.isValid = false;
      return;
    } else if (this.ListLenderForm.controls.phone.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.ListLenderForm.controls.email.invalid) {
      this.isValid = false;
      return;
    } else if (this.ListLenderForm.controls.postalcode.invalid) {
      this.isValid = false;
      return;
    }
    else {
      var modelGroup = {
        "LenderName": this.ListLenderForm.controls.lenderName.value,
        "Address": this.ListLenderForm.controls.address.value,
        "City": this.ListLenderForm.controls.city.value,
        "Region": this.ListLenderForm.controls.region.value,
        "Phone": this.ListLenderForm.controls.phone.value,
        "email": this.ListLenderForm.controls.email.value,
        "Postalcode": this.ListLenderForm.controls.postalcode.value,
      }

      //formdata for post Logo

      const formData = new FormData();
      for (const key of Object.keys(this.ListLenderForm.value)) {
        var value = this.ListLenderForm.value[key];
        formData.append(key, value);
      }




      this.lenderService.addLender(formData)
        .subscribe(
          (data: any) => {
            this.Getlenderslist();
            this.submitted = false;
            this.showNotification("success", "Data saved successfully");
            this.CreateFrom();
            this.modalRef.hide();
            this.closeEditModel();
            this.closepopupmodel();
            this.modalcloseOpen.hide();
            this.modalRef.hide();
            this.ViewRateSheetLabel = "false";
            this.LenderSheetName = "";
          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }


  }

  get phoneNumberControl() {
    return this.ListLenderForm.get('phone');
  }
  get editphoneNumberControl() {
    return this.ListLenderForm.get('editphone');
  }
  keyPressCheck(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  closepopupmodel() {
    this.ListLenderForm.invalid
  }
  closeEditModel() {
    this.modalRef.hide();
  }
  CloseAddmodel() {
    this.ViewRateSheetLabel = "false";
    this.LenderSheetName = "";
    this.submitted = false;
    this.modalRef.hide();

    //this.ListLenderForm.reset();
    //this.ListLenderForm.invalid
  }

  onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.labelImport.nativeElement.innerText = file.name;
      document.getElementById("choosefile").innerHTML = file.name;
      this.ListLenderForm.patchValue({
        picture: file,
      });
    }
  }
  keyPress(event: any) {

    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.Getlenderslist();
  }
  // function for creating from
  CreateFrom() {
    this.ListLenderForm = new FormGroup({
      lenderName: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      region: this.fb.control('', Validators.required),
      phone: this.fb.control('', [
        Validators.required,
        // Validators.pattern("^\\D*([0-9]\\D*){10}$"),
        //Validators.pattern(/^\(\d{3}\)\s*\d{3}-\d{4}$/),
        Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      // phone: this.fb.control('', [ Validators.required,
      //   Validators.pattern("^[0-9]*$"),
      //   Validators.minLength(10), Validators.maxLength(10)]),
      //email: this.fb.control('', Validators.required,Validators.email),
      // email: this.fb.control('', [ Validators.required,Validators.email,
      //   Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,6}$')]),

      //postalcode: this.fb.control('', Validators.required),
      postalcode: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),

      ]),
      picture: this.fb.control(''),
      editlenderName: this.fb.control('', Validators.required),
      editaddress: this.fb.control('', Validators.required),
      editcity: this.fb.control('', Validators.required),
      editregion: this.fb.control('', Validators.required),
      editphone: this.fb.control('', [
        Validators.required,
        // Validators.pattern("^\\D*([0-9]\\D*){10}$"),
        //Validators.pattern(/^\(\d{3}\)\s*\d{3}-\d{4}$/),
        Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      // editphone: this.fb.control('', [ Validators.required,
      //   Validators.pattern("^[0-9]*$"),
      //   Validators.minLength(10), Validators.maxLength(10)]),
      editemail: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      editpostalcode: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),

      ]),
      editpicture: this.fb.control(''),
    });
  }


}
