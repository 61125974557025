import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.less']
})
export class SideNavComponent implements OnInit {
  @Output("parentFun") parentFun: EventEmitter<any> = new EventEmitter();
  list =[
    {
      nunber:'1',
      name:'Profile',
      icon:'fa fa-user',
      link:'/user-profile/{{ account.email }}',
    },
    {
      nunber:'2',
      name:'Admin',
      icon:'fa fa-user',
      link:'/List',
    },
    {
      nunber:'3',
      name:'VHR Report',
      icon:'fa fa-file-text',
      link:'/Vhr-Report',
    },
    {
      nunber:'4',
      name:'Carfax API',
      icon:'fa fa-cog fa-solid fa-box',
      link:'/Carfax-API',
    },
    {
      nunber:'5',
      name:'Inventory',
      icon:'fa fa-sticky-note',
      link:'/Vhr-Report',
    },
    {
      nunber:'6',
      name:'Dashboard',
      icon:'fa fa-tachometer',
      link:'/master-dashboard',
    },
    {
      nunber:'7',
      name:'API Call History',
      icon:'fa fa-history',
      link:'/api-history',
    },
    {
      nunber:'8',
      name:'Dealer location',
      icon:'fa fa-map',
      link:'/DealerLocation',
    },

  ];
  menuList=[];
  rolevalue:any;
  dealerNamevalue:any;
  GroupNamevalue:any;
  DealerIdvalue:any;
  UseridValue:any;
  screenname="Home";
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private manageInventoryService: ManageInventoryService,

    ) {

  }

  ngOnInit(): void {

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UseridValue = localStorage.getItem('userid');

    this.GetMenuLists();
  }

  GetMenuLists() {

    var modelGroup = {
      "Opcode": "",
      "RoleName": this.rolevalue,
      "UserId": this.UseridValue,

    }

    this.manageInventoryService.getMenuListUserRoleWise(modelGroup)
      .subscribe(
        data => {
        
          this.menuList = data;

        },
        error => {

        });
  }
  sendtoglobalcode_old(id)
  {
   
    return this.router.navigateByUrl("/globalcode/{id}",id);
    //return this.router.navigateByUrl("/globalcode/7/Roles");
  }

  sendtoglobalcode_old_backup(id,name)
  {
    

    //return this.router.navigateByUrl("/globalcode/{id}{Name}",id,Name);
  //  return this.router.navigate(['/globalcode', id, name]);
    //return this.router.navigateByUrl("/globalcode/7/Roles");

    return this.router.navigateByUrl("/GlobalCodeCategory/{id}",id);
  }

  sendtoglobalcode(id)
  {
   
    return this.router.navigateByUrl("/GlobalCodeCategory/{id}",id);
  }


  sendtoFindcar(id)
  {
    
    //window.location.href="#/find-car-final/M"
   // window.location.reload();
   // return this.router.navigateByUrl("/find-car-final/{index}",id);
   return this.router.navigateByUrl("/GlobalCodeCategory/{id}",id);  // this not any route just wrote for redirect the page
  }

  test()
  {
   
    alert('sss');
  }


sidebaropen(){
  this.parentFun.emit();
}

}
