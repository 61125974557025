<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="ListLenderForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-3">


        <div class="row justify-content-between align-items-center mb-2">
          

          <div class="search-heros col-md-auto mt-2 mt-lg-0">
            <div class="row justify-content-between align-items-center mx-0">
              <div class="form-group d-flex mb-md-0">
                <label class="mr-3 mt-2">Search:</label>
                <input class="form-control" type="text"
                name="search" [(ngModel)]="searchText"  [ngModelOptions]="{standalone: true}"
                autocomplete="off" >
            </div>
          
            </div>
          </div>
          <div class="col-auto documentbtn">
            <ul class="list-unstyled mb-1">
              <li>
                <button title="Copy" class="btn btndesign btn-sm mb-sm-0" (click)="copytable('usertable')">
                  <img src="../../assets/images/icon-btn/copy.png" alt="copy" />
                  Copy
                </button>
              </li>
              <li>
                <button title="Excel" class="btn btndesign btn-sm mb-sm-0" (click)="exportexcel()">
                  <img src="../../assets/images/icon-btn/excel.png" alt="Excel" />
                  Excel
                </button>
              </li>
              <li>
                <button title="PDF" class="btn btndesign btn-sm mb-sm-0" (click)="downloadPdf()">
                  <img src="../../assets/images/icon-btn/pdf.png" alt="PDF" />
                  PDF
                </button>
              </li>
              <li>
                <button title="Print" class="btn btndesign btn-sm mb-sm-0" (click)="printTable()">
                  <img src="../../assets/images/icon-btn/print.png" alt="Print" />
                  Print
                </button>
              </li>
            </ul>
          </div>
        </div>


    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card card-raised h-100">
          <div class="card-header card-raised bg-info text-white">
            <div class="row justify-content-between align-items-center">
              <div class="col-sm-6">
                <h5 class="card-title text-white text-uppercase mb-0">List of Lenders</h5>
              </div>
              <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
              </div>
            </div>
            </div>

            <div class="card-body p-4">
              <div class="table-responsive">
                  <table id="usertable" class="table table-bordered" style="width:100%">
                    <thead>
                      <tr>
                        <th>Rate <br>Sheet</th>

                        <th (click)="sort('lenderName')"> Name<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('address')"> Address<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('city')"> City<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('region')"> Region<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('phone')"> Phone<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('email')"> Email<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('postalcode')"> Postal code<i class="fa fa-fw fa-sort"></i></th>

                        <th style="text-align:center;" colspan="3"><button type="button"
                            (click)="openLendersModel(AddLendersModelPopup)" class="btn btn-primary btn-sm text-white"
                            >
                            <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Lender
                          </button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let lenderlistsdata of lenderlists | filter:searchText; let i = index; let odd = odd">
                        <!-- <td class="myimageDiv">
                          <img id="myimage" [src]='lenderlistsdata.logobaseSixtyfour' />
                        </td> -->
                        <td id="pdfIcon" *ngIf="lenderlistsdata.logoLink!='0'" ><a   (click)="Pdfview(lenderlistsdata.id)"
                           class="col-auto" id="viewdatesheet" title="View Rate Sheet"><i class="fas fa-file-pdf"></i></a>
                         </td>
                        <td id="pdfIcon" *ngIf="lenderlistsdata.logoLink=='0'" >No</td>
                        <td> {{ lenderlistsdata.lenderName }} </td>
                        <td> {{ lenderlistsdata.address }}</td>
                        <td> {{ lenderlistsdata.city }}</td>
                        <td> {{ lenderlistsdata.globalCodeName }}</td>
                        <td> {{ lenderlistsdata.phone }}</td>
                        <td> {{ lenderlistsdata.email }}</td>
                        <td> {{ lenderlistsdata.postalcode }}</td>
                        <td style="text-align:center;">
                          <button class="btn bg-gradient-info btn-sm"
                          (click)="EditModalpopup(ModelEditLenderCategory,lenderlistsdata.id)"><i
                              class="fa-solid fa-pen-to-square"></i></button>
                          <button class="btn bg-gradient-danger btn-sm ml-2"
                            (click)="openModal(template,lenderlistsdata.id)"><i
                              class="fa fa-trash"></i></button>
                        </td>
                      </tr>

                    </tbody>
                  </table>
                  <br>

                  <div class="row justify-content-between align-items-center">
                    <div class="col-12 col-sm-auto">

                      <!-- <ngb-pagination [collectionSize]="lenderlists.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                      [ellipses]="false" [boundaryLinks]="true"></ngb-pagination> -->

                      <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getPremiumData();" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                      </ngb-pagination>


                    </div>

                    <div class="col-12 col-sm-auto">
                      <div class="form-group mt-2">
                        <label style="padding:2px;"> Items per Page :</label>
                        <select [(ngModel)]="pageSize" (ngModelChange)="getPremiumData();" [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let size of pageSizes" [ngValue]="size">
                            {{ size }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <!-- <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                      <div class="form-group mb-0 mr-1">
                        <label style="padding:2px;"> Items per Page:</label>
                        <select [(ngModel)]="pageSize" (ngModelChange)="getPremiumData();" [ngModelOptions]="{standalone: true}">
                          <option *ngFor="let size of pageSizes" [ngValue]="size">
                              {{ size }}
                          </option>
                      </select>
                      </div>
                    </div> -->

                </div>



                    <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i
                        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                        placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
                    <ng-template #template>
                      <div class="modal-body text-center">
                        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                        <p>Are you sure you want to delete?
                        </p>
                        <p></p>
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                      </div>
                    </ng-template>

                </div>
                
           </div>

        </div>
      </div>
    </div>
      </div>
    </div>
  </div>

  <!-- <app-footer id="secondfooter"></app-footer> -->

  <ng-template #AddLendersModelPopup let-modal>
    <div class="modal-header" style="background-color: black;">
      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add</h5>
      <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <section class="generate-report col col-md-12">
        <div class="row">
            <div class="form-group col-md-6 col-lg-4">
              <label for="picture">Upload Latest Rate Sheet</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="customFile"
                             (change)="onFileChanged($event)" accept=".pdf">
                      <label class="custom-file-label" #labelImport id="choosefile"
                      for="customFile">Choose file</label>
                    </div>
                    <div class="invalid-feedback" *ngIf="f.picture.touched && f.picture.invalid">
                      <div *ngIf="f.picture.errors.required">RateSheet  is required.</div>
                    </div>
            </div>
            <div class="form-group col-md-6 col-lg-4">
                <label>Lender Name</label>
                <input type="text" formControlName="lenderName"
                 class="form-control" placeholder="Lender Name"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.lenderName.errors }" />
                <div *ngIf="submitted && f.lenderName.errors" class="invalid-feedback">
                  <div *ngIf="f.lenderName.errors.required">Lender Name is required</div>
                </div>
              </div>

              <div class="form-group col-md-6 col-lg-4">
                <label>Region</label>
                  <select  class="form-control" name="region"
                  formControlName="region" [ngClass]="{ 'is-invalid': submitted && f.region.errors }"
                   (change)="onChange($event.target.value)">
                   <option value="0" selected>Select Region</option>
                    <option *ngFor="let Provincelistdata of Provincelist"
                    [value]="Provincelistdata.globalCodeID">{{Provincelistdata.globalCodeName}}</option>
                    </select>

                <div *ngIf="submitted && f.region.errors" class="invalid-feedback">
                  <div *ngIf="f.region.errors.required">Region is required</div>
                </div>
              </div>

        </div>
        <hr/>
        <div class="row">
              <div class="form-group col-md-6 col-lg-6">
                <label>Address</label>
                <textarea type="text" formControlName="address" rows="3" class="form-control" placeholder="Address"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                </textarea>
                <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                  <div *ngIf="f.address.errors.required">Address Name is required</div>
                </div>
              </div>

            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label>City</label>
                <input type="text" formControlName="city" class="form-control" placeholder="City"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                  <div *ngIf="f.city.errors.required">City is required</div>
                </div>
              </div>

              <div class="form-group">
                <label>Postal code</label>
                <input type="text" formControlName="postalcode" class="form-control" maxlength="6"
                 placeholder="Postal Code"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" />
                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                  <div *ngIf="f.postalcode.errors.required">Postal code is required</div>
                  <div *ngIf="f.postalcode.errors.minlength || f.postalcode.errors.maxlength">
                    Postal Code must be 6 digits
                  </div>
                </div>
              </div>
            </div>

        </div>
        <hr/>
        <div class="row">
          <div class="form-group col-md-6 col-lg-6">
            <label>Phone</label>
            <input type="text"
                               formControlName="phone"
                               [preValue]="ListLenderForm.value.phone"
                               [phoneControl]="ListLenderForm.controls['phone']"
                               phoneMask
                               [(ngModel)]="phone"
                               maxlength="14"
                               class="form-control"
                               placeholder="Phone Number"
                               (keypress)="keyPressCheck($event)"
                               [ngClass]="{ 'errmsg': phoneNumberControl.dirty && phoneNumberControl.invalid, 'validmsg': phoneNumberControl.dirty && phoneNumberControl.valid }"
                        />
                        <div *ngIf="phoneNumberControl.invalid && (phoneNumberControl.dirty || submitted)" class="error-message" style="color: red;">
                          <div *ngIf="phoneNumberControl.errors.required">
                            Phone Number is required
                          </div>
                          <div *ngIf="phoneNumberControl.errors.pattern">
                            Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                          </div>
                        </div>
            <!-- <input type="text" formControlName="phone" (keypress)="keyPress($event)"
            maxlength="10" class="form-control" placeholder="Phone"
               [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
            <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
              <div *ngIf="f.phone.errors.required">Phone is required</div>
              <div *ngIf="f.phone.errors.pattern || f.phone.errors.maxlength || f.phone.errors.minlength">Phone number must be at least 10 numbers </div>
            </div> -->
          </div>

          <div class="form-group col-md-6 col-lg-6">
            <label>Email</label>
            <input type="text" formControlName="email"  lowercase  placeholder="Email"
            class="form-control email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                <div *ngIf="f.email.errors.required">Email is required</div>
                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
            </div>
          </div>
        </div>
      </section>


    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
      </button>

      <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
      </button>


    </div>
  </ng-template>


  <ng-template #ModelEditLenderCategory let-modal>
    <div class="modal-header" style="background-color: black;">
      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Edit Lenders</h5>
      <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <section class="generate-report col col-md-12">
        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="picture">Upload Latest Rate Sheet</label>
                    <div class="custom-file">
                      <input type="file" class="custom-file-input" id="customFile"
                             (change)="onFileChanged($event)" accept=".pdf">
                      <label class="custom-file-label" #labelImport id="choosefile"
                      for="customFile">Choose file</label>
                    </div>
                    <div class="invalid-feedback" *ngIf="f.editpicture.touched && f.editpicture.invalid">
                      <div *ngIf="f.editpicture.errors.required">Picture is required.</div>
                    </div>

          </div>
            <div class="form-group col-md-6 col-lg-4">
                <label>Lender Name</label>
                <input type="text" formControlName="editlenderName"
                [(ngModel)]="editlenderNamevalue"
                 class="form-control" placeholder="Lender Name"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.editlenderName.errors }"/>
                  <div *ngIf="submitted && f.editlenderName.errors" class="invalid-feedback">
                    <div *ngIf="f.editlenderName.errors.required">Lender Name is required</div>
                  </div>
              </div>

              <div class="form-group col-md-6 col-lg-4">
                <label>Region</label>
                  <select  class="form-control" name="region"
                  formControlName="editregion"  [ngClass]="{ 'is-invalid': submitted && f.editregion.errors }" [(ngModel)]="editregionvalue"
                   (change)="onChange($event.target.value)">
                   <option value="0" selected>Select region name</option>
                    <option *ngFor="let Provincelistdata of Provincelist"
                     [value]="Provincelistdata.globalCodeID">{{Provincelistdata.globalCodeName}}</option>
                    </select>

                    <div *ngIf="submitted && f.editregion.errors" class="invalid-feedback">
                      <div *ngIf="f.editregion.errors.required">Region is required</div>
                    </div>
              </div>

        </div>
        <div class="w-100">
          <span id="pdfIconedit" *ngIf="ViewRateSheetLabel==='true'">
            <i class="fas fa-file-pdf"></i>Ratesheet Name: {{LenderSheetName}}</span>
        </div>
        <hr/>
        <div class="row">
              <div class="form-group col-md-6 col-lg-6">
                <label>Address</label>
                <textarea type="text" formControlName="editaddress" [ngClass]="{ 'is-invalid': submitted && f.editaddress.errors }"
                [(ngModel)]="editaddressvalue" rows="3" class="form-control" placeholder="address"
                  (keypress)="ValidTextBox($event)">
                </textarea>
                <div *ngIf="submitted && f.editaddress.errors" class="invalid-feedback">
                  <div *ngIf="f.editaddress.errors.required">Address Name is required</div>
                </div>

              </div>

            <div class="col-md-6 col-lg-6">
              <div class="form-group">
                <label>City</label>
                <input type="text" formControlName="editcity" [ngClass]="{ 'is-invalid': submitted && f.editcity.errors }"
                [(ngModel)]="editcityvalue"  class="form-control" placeholder="city"
                  (keypress)="ValidTextBox($event)"  />
                  <div *ngIf="submitted && f.editcity.errors" class="invalid-feedback">
                    <div *ngIf="f.editcity.errors.required">City is required</div>
                  </div>
              </div>

               <div class="form-group">
                <label>Postal code</label>
                <input type="text" [(ngModel)]="editpostalcodevalue" maxlength="7"
                formControlName="editpostalcode" class="form-control" placeholder="postalcode"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.editpostalcode.errors }" />
                  <div *ngIf="submitted && f.editpostalcode.errors" class="invalid-feedback">
                    <div *ngIf="f.editpostalcode.errors.required">Postal code is required</div>
                    <div *ngIf="f.editpostalcode.errors.minlength || f.editpostalcode.errors.maxlength">
                      Postal Code must be 6 digits
                    </div>
                  </div>
              </div>
            </div>

        </div>
        <hr/>
        <div class="row">
          <div class="form-group col-md-6 col-lg-6">
            <label>Phone</label>
            <input type="text"
                               formControlName="editphone"
                               [preValue]="ListLenderForm.value.editphone"
                               [phoneControl]="ListLenderForm.controls['editphone']"
                               phoneMask
                               [(ngModel)]="editphonevalue"
                               maxlength="14"
                               class="form-control"
                               placeholder="Phone Number"
                               (keypress)="keyPressCheck($event)"
                               [ngClass]="{ 'errmsg': editphoneNumberControl.dirty && editphoneNumberControl.invalid, 'validmsg': editphoneNumberControl.dirty && phoneNumberControl.valid }"
                        />
                        <div *ngIf="editphoneNumberControl.invalid && (editphoneNumberControl.dirty || submitted)" class="error-message" style="color: red;">
                          <div *ngIf="editphoneNumberControl.errors.required">
                            Phone Number is required
                          </div>
                          <div *ngIf="editphoneNumberControl.errors.pattern">
                            Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                          </div>
                        </div>
            <!-- <input type="text" formControlName="editphone" maxlength="10"
            [(ngModel)]="editphonevalue" class="form-control" placeholder="phone"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.editphone.errors }" />
              <div *ngIf="submitted && f.editphone.errors" class="invalid-feedback">
                <div *ngIf="f.editphone.errors.required">Phone is required</div>
                <div *ngIf="f.editphone.errors.pattern || f.editphone.errors.maxlength || f.editphone.errors.minlength">Phone number must be at least 10 numbers </div>
              </div> -->
          </div>

          <div class="form-group col-md-6 col-lg-6">
            <label>Email</label>
            <input type="text" formControlName="editemail"  lowercase [ngClass]="{ 'is-invalid': submitted && f.editemail.errors }"
            [(ngModel)]="editemailvalue"  class="form-control" placeholder="email"
              (keypress)="ValidTextBox($event)" />
              <div *ngIf="submitted && f.editemail.errors" class="invalid-feedback">
                <div *ngIf="f.editemail.errors.required">Email is required</div>
                <div *ngIf="f.editemail.errors.editemail">Email must be a valid email address</div>
            </div>
          </div>
        </div>
      </section>


    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="update();">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
      </button>

      <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
      </button>


    </div>
  </ng-template>

  </form>

  </section>




