import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import { AddrepoService } from '@app/_services/addrepo.service';
import { AddgroupService } from '../_services/addgroup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-list-repo',
  templateUrl: './list-repo.component.html',
  styleUrls: ['./list-repo.component.less']
})
export class ListRepoComponent implements OnInit {

  AddRepoForm: FormGroup;
  private notifier: NotifierService;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private addRepoService: AddrepoService,
    private router: Router,
    notifier: NotifierService
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  pageRep = 1;
  pageSizeRep = 3;
  loading = false;
  submitted = false;

  repoList = [];
  deleteitem: any = 0;
  edititemid: any = 0;
  editPostionValue: any;
  editvalueFname: any;
  editvalueLname: any;
  editvalueEmail: any;
  editvaluePhone: any;
  updateid: any;


  //select position

  selectedPosition: any;
  Positionlist=[];

  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ngOnInit(): void {
    this.CreateRepoFrom();
    this.GetAithrReplist();
    this.GetPositionlist();
  }

  GetPositionlist() {
    this.addgroupService.GetPositionlist()
      .subscribe(
        (data: any) => {
          debugger;
          this.Positionlist = data;
        },
        error => {
        });
  }

  // function for creating from
  CreateRepoFrom() {
    this.AddRepoForm = new FormGroup({
      positionctrl: this.fb.control('', Validators.required),
      firstname: this.fb.control('', Validators.required),
      lastname: this.fb.control('', Validators.required),
      Email: this.fb.control('', Validators.required),
      phonenumber: this.fb.control('', Validators.required),
      Editpositionctrl: this.fb.control(''),
      Editfirstname: this.fb.control(''),
      Editlastname: this.fb.control(''),
      EditEmail: this.fb.control(''),
      Editphonenumber: this.fb.control(''),
    });
  }

  // function for open model pop up
  openModal(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
  }
  // edit functionality
  // function for open model pop up
  EditModalpopup(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.addgroupService.EditAithrRepList(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.editPostionValue=data[0].positionId;
          this.editvalueFname = data[0].fName;
          this.editvalueLname = data[0].lName;;
          this.editvalueEmail = data[0].email;
          this.editvaluePhone = data[0].phoneNumber;;

          this.updateid = data[0].id;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }

  closepopupmodel() {
    this.AddRepoForm.invalid
  }

  openRepoModel(targetModal) {


    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

  }
  // for update the edited part
  update() {
    debugger;
    this.submitted = true;

    if (this.AddRepoForm.controls.Editfirstname.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddRepoForm.controls.Editlastname.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddRepoForm.controls.EditEmail.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddRepoForm.controls.Editphonenumber.invalid) {
      this.isValid = false;
      return;
    }


    var modelGroup = {
      "id": this.updateid,
      "FName": this.editvalueFname,
      "LName": this.editvalueLname,
      "Email": this.editvalueEmail,
      "PhoneNumber": this.editvaluePhone,
      "PositionId": this.editPostionValue
    }
    this.addgroupService.UpdateAithrRepDetail(modelGroup)

      .subscribe(
        (data: any) => {
          this.GetAithrReplist();
          this.submitted = false;
          this.CreateRepoFrom();
          this.showNotification("success", "Data updated successfully")
          this.modalRef.hide();
        },
        error => {
        });
  }
  get f() { return this.AddRepoForm.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    debugger
    this.submitted = true;

    if (this.AddRepoForm.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "PositionId": this.AddRepoForm.controls.positionctrl.value,
        "FName": this.AddRepoForm.controls.firstname.value,
        "LName": this.AddRepoForm.controls.lastname.value,
        "Email": this.AddRepoForm.controls.Email.value,
        "PhoneNumber": this.AddRepoForm.controls.phonenumber.value,
      }


      this.addRepoService.SaveaddRepo(modelGroup)
        .subscribe(
          (data: any) => {
            this.GetAithrReplist();
            this.submitted = false;
            this.showNotification("success", "Data saved successfully");
            this.CreateRepoFrom();
            this.modalRef.hide();
            this.closeEditModel();
            this.closepopupmodel();
            this.modalcloseOpen.hide();

          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }


  }


  CloseAddmodel() {
    this.modalRef.hide();
  }

  // function for confirm  delete
  confirm(): void {
    debugger;
    this.deleteitem;
    var modelGroup = {
      "Id": this.deleteitem
    }
    this.addgroupService.DeleteAithrReplist(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted successfully");
          this.GetAithrReplist();
        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.modalRef.hide();
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }


  //Calling get list of group on page load

  GetAithrReplist() {
    this.addgroupService.GetAithrReplist()
      .subscribe(
        (data: any) => {
          debugger;
          this.repoList = data;
          this.modalRef.hide();
        },
        error => {
        });
  }

}