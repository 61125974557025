<section id="admindashboard">
  <form class="col-12 px-0 mt-3" [formGroup]="AddRepoForm">
<div class="container-fluid">
  <div class="row">
    <div class="col-12 mt-3">
     

      <div class="row mx-0 justify-content-between align-items-center mb-2">
        <div class="col-auto documentbtn">
          <ul class="list-unstyled mb-1">
            <li><button class="btn btn-secondary btn-sm mb-sm-0">Copy</button></li>
            <li><button class="btn btn-secondary btn-sm mb-sm-0">CSV</button></li>
            <li><button class="btn btn-secondary btn-sm mb-sm-0">Excel</button></li>
            <li><button class="btn btn-secondary btn-sm mb-sm-0">PDF</button></li>
            <li><button class="btn btn-secondary btn-sm mb-sm-0">Print</button></li>
          </ul>
        </div>
        <div class="search-heros col-sm-auto mt-2 mt-lg-0">
          <div class="form-group d-flex mb-1">
            <label class="mr-3 mt-2">Search:</label>
            <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
              [ngModelOptions]="{standalone: true}" autocomplete="off">
          </div>
        </div>
      </div>


  <div class="row mt-2">
    <div class="col-md-12">
      <div class="card card-raised h-100">
        <div class="card-header card-raised bg-info text-white">
          <div class="d-flex justify-content-between align-items-center">
              <div class="w-100">
                  <h5 class="card-title text-white text-uppercase mb-0">List of registered AithrReps</h5>
                  <div class="card-subtitle"></div>
              </div>
          </div>
          </div>
          <div class="card-body p-4">
            <div class="table-responsive">
                <table id="usertable" class="table table-bordered" style="width:100%">
                  <thead>
                    <tr>
                      <th (click)="sort('userName')" style="width: 70px;">ID <i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sort('fName')"> Position Name</th>
                      <th (click)="sort('fName')"> Rep Name</th>
                      <th (click)="sort('lName')"> Last Name</th>
                      <th (click)="sort('email')"> Email</th>
                      <th (click)="sort('phoneNumber')"> Phone Number</th>
                      <th style="text-align:center;"  colspan="3"><button type="button" class="btn btn-primary btn-sm text-white"
                          (click)="openRepoModel(ModelRepo)">
                          <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Reps
                        </button></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="let aithrepdata of repoList | filter:searchText | slice: (pageRep-1) * pageSizeRep : (pageRep-1) * pageSizeRep + pageSizeRep; let i = index; let odd = odd">
                      <td>{{ aithrepdata.id }}</td>
                      <td> {{ aithrepdata.positionsName }}</td>
                      <td> {{ aithrepdata.fName }}</td>
                      <td> {{ aithrepdata.lName }}</td>
                      <td> {{ aithrepdata.email }}</td>
                      <td> {{ aithrepdata.phoneNumber }}</td>
                      <td style="text-align:center;" >
                        <button class="btn bg-gradient-info btn-sm" (click)="EditModalpopup(editpopup,aithrepdata.id)"><i
                            class="fa-solid fa-pen-to-square"></i></button>
                        <button class="btn bg-gradient-danger btn-sm ml-2" (click)="openModal(template,aithrepdata.id)"><i
                            class="fa fa-trash"></i></button>
                      </td>
                    </tr>

                  </tbody>
                </table>
                <br>
                <ngb-pagination [collectionSize]="repoList.length" [(page)]="pageRep" [maxSize]="5" [rotate]="true"
                  [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>


              </div>
         </div>
        <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i class="fa fa-upload"
            triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
            ngbPopover="Are you sure you want to delete?"></i>
        </a>
        <ng-template #template>
          <div class="modal-body text-center">
            <span class="cross-icon"><i class="far fa-times-circle"></i></span>
            <p>Are you sure you want to delete?</p>
            <p></p>
            <button type="button" class="btn btn-sm btn-success mr-2" (click)="confirm()">Yes</button>
            <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
          </div>
        </ng-template>

        <ng-template #ModelRepo let-modal>
          <div class="modal-header" style="background-color: black;">
            <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add Reps</h5>
            <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <section class="generate-report">


              <div class="form-group">

                <select class="form-control" name="positionctrl" formControlName="positionctrl">
                  <option value="0">Select Postion</option>
                  <option *ngFor="let positiondata of Positionlist" [value]="positiondata.id">{{positiondata.positionsName}}</option>
                </select>
                <div *ngIf="submitted && f.positionctrl.errors" class="invalid-feedback">
                  <div *ngIf="f.positionctrl.errors.required">position is required</div>
                </div>
              </div>


              <div class="form-group">

                <input type="text" formControlName="firstname" class="form-control" placeholder="first Name"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                  <div *ngIf="f.firstname.errors.required">First Name is required</div>
                </div>
              </div>


              <div class="form-group">

                <input type="text" formControlName="lastname" class="form-control" placeholder="Last Name"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                  <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                </div>
              </div>

              <div class="form-group">

                <input type="text" formControlName="Email" class="form-control" placeholder="Email"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.Email.errors }" />
                <div *ngIf="submitted && f.Email.errors" class="invalid-feedback">
                  <div *ngIf="f.Email.errors.required">Email is required</div>
                </div>
              </div>


              <div class="form-group">

                <input type="text" formControlName="phonenumber" class="form-control" placeholder="Phone number"
                  (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.phonenumber.errors }" />
                <div *ngIf="submitted && f.phonenumber.errors" class="invalid-feedback">
                  <div *ngIf="f.phonenumber.errors.required">phonenumber is required</div>
                </div>
              </div>

            </section>


          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
              <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
            </button>

            <button class="btn btn-danger btn-sm text-white" (click)="CloseAddmodel()">
              <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
            </button>


          </div>
        </ng-template>



        <ng-template #editpopup let-modal>
          <div class="modal-header" style="background-color: black;">
            <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Edit Reps</h5>
            <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
              aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">

            <section class="generate-report">


              <div class="form-group">

                <select class="form-control"  [(ngModel)]="editPostionValue" name="Editpositionctrl" formControlName="positionctrl">
                  <option *ngFor="let positiondata of Positionlist" [value]="positiondata.id">{{positiondata.positionsName}}</option>
                </select>
                <div *ngIf="submitted && f.Editpositionctrl.errors" class="invalid-feedback">
                  <div *ngIf="f.Editpositionctrl.errors.required">position is required</div>
                </div>
              </div>


              <div class="form-group">

                <input type="text" formControlName="Editfirstname" class="form-control" placeholder="first Name"
                  [(ngModel)]="editvalueFname" (keypress)="ValidTextBox($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.Editfirstname.errors }" />
                <div *ngIf="submitted && f.Editfirstname.errors" class="invalid-feedback">
                  <div *ngIf="f.Editfirstname.errors.required">First Name is required</div>
                </div>
              </div>


              <div class="form-group">

                <input type="text" formControlName="Editlastname" [(ngModel)]="editvalueLname" class="form-control"
                  placeholder="Last Name" (keypress)="ValidTextBox($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.Editlastname.errors }" />
                <div *ngIf="submitted && f.Editlastname.errors" class="invalid-feedback">
                  <div *ngIf="f.Editlastname.errors.required">Last Name is required</div>
                </div>
              </div>

              <div class="form-group">

                <input type="text" formControlName="EditEmail" [(ngModel)]="editvalueEmail" class="form-control"
                  placeholder="Email" (keypress)="ValidTextBox($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.EditEmail.errors }" />
                <div *ngIf="submitted && f.EditEmail.errors" class="invalid-feedback">
                  <div *ngIf="f.EditEmail.errors.required">Email is required</div>
                </div>
              </div>


              <div class="form-group">
                <input type="text" formControlName="Editphonenumber" [(ngModel)]="editvaluePhone" class="form-control"
                  placeholder="Phone number" (keypress)="ValidTextBox($event)"
                  [ngClass]="{ 'is-invalid': submitted && f.Editphonenumber.errors }" />
                <div *ngIf="submitted && f.Editphonenumber.errors" class="invalid-feedback">
                  <div *ngIf="f.Editphonenumber.errors.required">phonenumber is required</div>
                </div>
              </div>

            </section>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-primary btn-sm text-white" (click)="update();">
              <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
            </button>

            <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
              <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
            </button>


          </div>
        </ng-template>
      
      </div>
    </div>
  </div>
    </div>
  </div>
</div>
</form>
</section>