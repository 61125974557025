<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="hubwholeSaleForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button style="display: none;" class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="copytable('usertable')">Copy</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="exportexcel()">Excel</button>
                                </li>
                                <!-- <li><button class="btn btn-secondary btn-sm mb-sm-0 "
                                        (click)="exportToCSV()">CSV</button></li> -->
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button (click)="printTable()" class="btn btn-secondary btn-sm mb-sm-0 rounded-pill">Print</button>
                                </li>
                            </ul>
                        </div>



                        <div class="col-md-auto col-sm-12">
                            <div class="col-auto unitsOption">
                                <div class="form-row">
                                    <label class="mr-1" *ngFor="let radiobutton of radioItems">
                                        <input type="radio" name="options" (click)="setradio(radiobutton)"
                                            [checked]="radiobutton ==='ALL'"> <b>{{radiobutton}}</b>
                                    </label>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div class="row justify-content-between align-items-start mt-2">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 selectregion">
                            <div class="row">
                                <label class="col-form-label col-auto">Select Region</label>
                                <div class="input-group dealername col">
                                    <ng-select style="width:fit-content;" [items]="regionlistName"
                                        bindLabel="globalCodeName" [dropdownPosition]="true"
                                        [ngModelOptions]="{standalone: true}" placeholder="Select Province Name"
                                        appendTo="body" (change)="ClearRegion($event)" (click)="CheckRegionClick()"
                                        [multiple]="true" [(ngModel)]="regionmultiselectarray">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="search-heros col-sm-12 col-lg-6 col-md-12 mt-2 mt-lg-0">
                            <div class="row align-items-center justify-content-md-end mx-0">

                                <div class="form-group d-md-flex mb-0">


                                    <label class="col-form-label col-lg-auto px-md-1">List Price less than:</label>
                                    <div class="col-lg-auto">

                                        <input type="text" formControlName="UnitFilterName" [(ngModel)]="UnitFilterName"
                                            class="form-control"
                                            placeholder="0" />

                                        <!-- <select class="form-control" name="filter"
                                        formControlName="UnitFilterName"
                                        (change)="FilterUnits($event)"
                                            [(ngModel)]="UnitFilterName">s
                                            <option value="0" selected>Select </option>

                                            <option value="30000">Less than 30k</option>
                                            <option value="30000">Greater than 30k</option>
                                        </select> -->

                                    </div>
                                </div>
                                <div class="form-group d-md-flex mb-1 searchbarradius">
                                    <label class="mr-3 mt-2">Search :</label>
                                    <!-- <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off"> -->
                                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">

                                        <button class="btn btn-info btn-sm mb-sm-0 mr-2" (click)="searchtextfunction()">Search</button>
                                </div>

                            </div>

                            <div class="row mt-1 align-items-center justify-content-md-end ">
                                <div class="col-md col-12 text-right">
                                    <button style="display: none;" class="btn btn-warning btn-sm mb-sm-0 mr-2 rounded-pill" (click)="ResetFilter()">Reset Filter</button>
                                    <button style="display: none;" class="btn btn-info btn-sm mb-sm-0 mr-2 rounded-pill"
                                    (click)="AdvancFilter(advanceFilter)"><i class="fas fa-filter"></i> Advance Filter</button>
                                    <button type="button" class="btn btn-sm btn-primary rounded-pill" (click)="ChangeViewfunction('Grid')"><i class="fas fa-list mr-1"></i>List</button>
                                    <button type="button" class="btn btn-sm btn-warning ml-2 rounded-pill" (click)="ChangeViewfunction('List')"><i class="fas fa-th mr-1"></i> Grid</button>

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-1">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">{{PageTitle}} UNITS
                                            </h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <div class="d-inline-block mr-4">
                                                <label class="mr-1" *ngFor="let radioListbutton of radioCurrencyItems">
                                                    <input type="radio" class="radiopoint" name="optionsCheck"
                                                        (click)="setCurrenyradio(radioListbutton)"
                                                        [checked]="radioListbutton ==='CAD'">
                                                    <b>${{radioListbutton}}</b>
                                                </label>
                                            </div>
                                            <button style="position:relative;top:0;"
                                                *ngIf="rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin' "
                                                type="button" (click)="GenerateFlagReportList()"
                                                class="btn btn-warning btn-sm text-white flagbtn rounded-pill">
                                                Get Flag Report
                                            </button>
                                            <h6 class="mb-0 d-inline-block ml-3"><b>Total Records :</b> {{TotalRecord}}
                                            </h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" *ngIf="listViewValue==='false'"
                                            class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th *ngIf="rolevalue==='SuperAdmin'">
                                                        <input type="checkbox" name="list_name" value="m1"
                                                            (change)="checkUncheckAll($event)" />
                                                    </th>
                                                    <th *ngIf="rolevalue!=='SuperAdmin'">

                                                    </th>
                                                    <!-- <th style="text-align: center;">
                                                        Flagged </th> -->
                                                    <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('province')"> Province <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th *ngIf="PageTitle !=='EBlock'" (click)="sort('stockNumber')"> Stock No<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('carYr')">Vehicle Information <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('mileage')">Mileage <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                     <th (click)="sort('make')">Make <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                   <!-- <th (click)="sort('model')">Model<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('trim')">Trim<i class="fa fa-fw fa-sort"></i></th> -->
                                                    <th (click)="sort('body')">Body<i class="fa fa-fw fa-sort"></i></th>

                                                    <th style="text-align: right;" (click)="sort('listedPrice')">Price
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th *ngIf="isaithropcode==5" style="text-align: right;"
                                                        (click)="sort('discountedAmount')"> Discounted Amount
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th *ngIf="isaithropcode==5" style="text-align: right;"
                                                        (click)="sort('discountAmount')">Discount
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('colour')">Colour<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('engineFuelType')">Fuel Type<i
                                                            class="fa fa-fw fa-sort"></i></th>


                                                    <th style="text-align: right;" (click)="sort('cbbValA')">
                                                        CBB<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: right;" (click)="sort('deltaValue')">Delta<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <!-- <th style="text-align: center;" (click)="sort('excited')">
                                                        Status</th> -->


                                                    <th style="text-align: center;"
                                                        *ngIf="(isaithropcode==5||isaithropcode==2)">
                                                        Offer </th>

                                                    <th style="text-align:center;">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText"> -->
                                                    <tr *ngFor="let dealerVehicleslistdata of dealerVehicleslist">

                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==0 && rolevalue==='SuperAdmin'">
                                                        <input type="checkbox" value="{{dealerVehicleslist.id}}"
                                                            [checked]="dealerVehicleslistdata.isSelected"
                                                            (change)="isAllSelected($event,dealerVehicleslistdata)" />
                                                    </td>

                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==0 && rolevalue!=='SuperAdmin'">

                                                    </td>
                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==1">
                                                        <div class="greenflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>
                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==99">
                                                        <div class="redflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==50">
                                                        <div class="orangeflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>

                                                    <!-- <td *ngIf="dealerVehicleslistdata.isFlaged==1"
                                                        style="text-align: center;">


                                                        <a class="greenflag flagIcon" title="View Flag Report"
                                                            #BuildNewButton target="_blank"
                                                            routerLink="/flag-report/{{dealerVehicleslistdata.vin}}">
                                                            <i class="fa-solid fa-flag"></i></a>

                                                    </td>
                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==0"
                                                        style="text-align: center;">
                                                        <div class="graynflag flagIcon">
                                                            <i class="fa-solid fa-flag"></i>
                                                        </div>
                                                    </td>


                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==99"
                                                        style="text-align: center;">
                                                        <div class="redflag flagIcon">
                                                            <a class="redflag flagIcon" title="View Flag Report"
                                                                #BuildNewButton target="_blank"
                                                                routerLink="/flag-report/{{dealerVehicleslistdata.vin}}">
                                                                <i class="fa-solid fa-flag"></i></a>

                                                        </div>
                                                    </td> -->

                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==50"
                                                        style="text-align: center;">
                                                        <div class="orangeflag flagIcon">
                                                            <i class="fa-solid fa-flag"></i>
                                                        </div>
                                                    </td>


                                                    <td>{{ dealerVehicleslistdata.vin }}</td>
                                                    <td>{{ dealerVehicleslistdata.province }}</td>
                                                    <td *ngIf="PageTitle !=='EBlock'"> {{ dealerVehicleslistdata.stockNumber }}</td>

                                                    <td> {{ dealerVehicleslistdata.carYr }} {{
                                                        dealerVehicleslistdata.make }} {{ dealerVehicleslistdata.model
                                                        }} {{ dealerVehicleslistdata.trim }}</td>

                                                    <td> {{ dealerVehicleslistdata.mileage }}</td>
                                                   <td> {{ dealerVehicleslistdata.make }}</td>
                                                    <!--  <td style="min-width:160px;max-width:160px"> {{
                                                        dealerVehicleslistdata.model }}</td>
                                                    <td style="min-width:180px;max-width:180px;"> {{
                                                        dealerVehicleslistdata.trim }}</td> -->
                                                    <td style="min-width:102px;"> {{ dealerVehicleslistdata.body }}</td>
                                                    <td style="text-align: right;"> {{
                                                        dealerVehicleslistdata.listedPrice| currency: 'USD' }}</td>
                                                    <td *ngIf="isaithropcode==5" style="text-align: right;"> {{
                                                        dealerVehicleslistdata.discountedAmount| currency: 'USD' }}</td>
                                                    <td *ngIf="isaithropcode==5" style="text-align: right;">
                                                        {{dealerVehicleslistdata.discountAmount| currency: 'USD' }}</td>
                                                    <td style="min-width:102px;"> {{ dealerVehicleslistdata.colour }}
                                                    </td>
                                                    <td style="min-width:102px;"> {{
                                                        dealerVehicleslistdata.engineFuelType }}</td>


                                                    <td style="text-align: right;"> {{ dealerVehicleslistdata.cbbValue
                                                        | currency: 'USD' }}</td>
                                                    <td style="text-align: right;">{{dealerVehicleslistdata.deltaValue |
                                                        currency: 'USD'}}
                                                    </td>
                                                    <!-- <td *ngIf="dealerVehicleslistdata.isinquired==true"
                                                        style="text-align: center;">
                                                        <button type="button" title="Check Inquiry  Detail"
                                                            (click)="CheckDetails(CheckDetailModel,dealerVehicleslistdata)"
                                                            class="btn btn-sm text-white">

                                                            <i class="fa fa-info-circle fa-3x" style="color:red;font-size: 20px;
                                                                vertical-align: middle;" aria-hidden="true"></i>
                                                        </button>

                                                    </td>

                                                    <td *ngIf="dealerVehicleslistdata.isinquired!=true"
                                                        style="text-align: center;">

                                                    </td> -->




                                                    <td class="dollor"
                                                        *ngIf="dealerVehicleslistdata.offer===0 && (isaithropcode==5||isaithropcode==2)">

                                                        <button class="btn btn-red" style="width:24px;font-size:8px;"
                                                            title="Offer"
                                                            (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'new')">
                                                            <i class="fas fa-dollar-sign"></i>
                                                        </button>

                                                    </td>

                                                    <td class="dollor"
                                                        *ngIf="dealerVehicleslistdata.offer>=1 && (isaithropcode==5||isaithropcode==2)">

                                                        <button class="btn btn-green" style="width:24px;font-size:8px;"
                                                            title="Offer"
                                                            (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'exit')">
                                                            <i class="fas fa-dollar-sign"></i>
                                                        </button>

                                                    </td>

                                                    <td style="text-align:center;">

                                                        <button
                                                            *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin' || rolevalue==='Dealer')"
                                                            type="button"
                                                            (click)="Aithrenquiry(enquiryform,dealerVehicleslistdata,'Enquiry')"
                                                            class="btn btn-primary btn-sm text-white rounded-pill">
                                                            AithrVerify
                                                        </button>
                                                        <!-- <button
                                                            *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='Dealer' || rolevalue==='DealerAdmin')"
                                                            type="button"
                                                            (click)="Aithrenquiry(Buyform,dealerVehicleslistdata,'Buy')"
                                                            class="btn btn-primary btn-sm text-white ml-1">
                                                            Buy
                                                        </button> -->
                                                        <button style="display: none;"
                                                            *ngIf="(rolevalue==='SuperAdmin') && dealerVehicleslistdata.isUnitClearance===false"
                                                            class="btn btn-green btn-sm ml-1" title="Mark on Clearance"
                                                            (click)="setradioMarkUnits('Clearance',dealerVehicleslistdata)">
                                                            <span class="icon"><i
                                                                    class="fas fa-arrow-alt-circle-down"></i></span>
                                                        </button>

                                                        <button
                                                            *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin') && dealerVehicleslistdata.isUnitClearance===true"
                                                            class="btn btn-lightblue btn-sm ml-1" title="Discounted">
                                                            <span class="icon"><i
                                                                    class="fas fa-arrow-alt-circle-up"></i></span>
                                                        </button>

                                                        <button
                                                            *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin')  && dealerVehicleslistdata.favorite===0"
                                                            class="btn btn-gray btn-sm ml-1" title="Mark Favourite"
                                                            (click)="setradioMarkUnits('Mark as My Favorite',dealerVehicleslistdata)">
                                                            <span class="icon"><i class="fa fa-heart"></i></span>
                                                        </button>

                                                        <button
                                                            *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin')  && dealerVehicleslistdata.favorite===1"
                                                            class="btn btn-red btn-sm ml-1"
                                                            (click)="setradioMarkUnits('Remove from Favorite',dealerVehicleslistdata)"
                                                            title="Remove from Favorite">
                                                            <span class="icon"><i class="fa fa-heart"></i></span>
                                                        </button>


                                                        <button *ngIf="rolevalue==='SuperAdmin'"
                                                            class="btn btn-sold btn-sm ml-1" title="Mark Sold"
                                                            (click)="setradioMarkUnits('Mark as Sold',dealerVehicleslistdata)">
                                                            <span class="icon"><i class="far fa-paper-plane"></i></span>
                                                        </button>
                                                        <button *ngIf="rolevalue==='SuperAdmin'"
                                                            class="btn btn-remove btn-sm ml-1" title="Mark Removed"
                                                            (click)="setradioMarkUnits('Mark as Removed',dealerVehicleslistdata)">
                                                            <span class="icon"><i class="fas fa-ban"></i></span>
                                                        </button>
                                                        <button class="btn btn-primary btn-sm ml-1"
                                                            title="Click to view images"
                                                            (click)="openImagesModelPopUp(ModelPopUpImages,dealerVehicleslistdata.vin)">
                                                            <span class="icon"><i class="fas fa-image"></i></span>
                                                        </button>
                                                        <button *ngIf="rolevalue==='SuperAdmin'"
                                                            class="btn btn-info btn-sm ml-1"
                                                            (click)="openViewDealerModelPopUp(ViewDealerDetails,dealerVehicleslistdata.vin)"
                                                            title="Dealer Detail">
                                                            <span class="icon"><i class="fas fa-eye"></i></span>
                                                        </button>
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <div id="listviewblock" class="col-12 mt-2" *ngIf="listViewValue==='true'">
                                            <div class="row">
                                                <!-- product coloum start -->
                                                <div class="col-xl-3 col-lg-4 col-md-6 col-12"
                                                    *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText">


                                                    <div class="product-card">
                                                        <div class="product-image">
                                                                <div class="product-image-block" style="width:100%; height:100%; text-align:center;margin:0; padding:0;">



                                                                     <ngb-carousel #nav *ngIf="dealerVehicleslistdata.imagedataarray"
                                                                     [data-interval]="false" [interval]="false">
                                                                        <ng-template
                                                                            ngbSlide
                                                                            *ngFor="let image of dealerVehicleslistdata.imagedataarray">
                                                                            <div (click)="onSlideClicked(LightboxModal,dealerVehicleslistdata)" 
                                                                                class="wrapper">
                                                                                <img [src]="image.image"
                                                                                    alt="Unit Images">
                                                                            </div>
                                                                            <div
                                                                                class="carousel-caption">

                                                                            </div>
                                                                        </ng-template>
                                                                    </ngb-carousel>

                                                                <!-- <div id="nofoundImageDiv" *ngIf="dealerVehicleslistdata.imagedataarray.length==0">
                                                                    <img class="linghtnoImage" src="../../assets/images/lightmode_noImage.jpg" alt="No Image">
                                                                    <img class="darknoImage" src="../../assets/images/darkmode_noImage.jpg" style="display: none;" alt="No Image">
                                                                </div> -->

                                                                </div>
                                                                <div class="favoriteheart">
                                                                    <button
                                                                    *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin')  && dealerVehicleslistdata.favorite===0"
                                                                    class="btn btn-gray btn-sm mr-1" title="Mark Favourite"
                                                                    (click)="setradioMarkUnits('Mark as My Favorite',dealerVehicleslistdata)">
                                                                    <span class="icon"><i class="fa fa-heart"></i></span>
                                                                </button>

                                                                <button
                                                                    *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin')  && dealerVehicleslistdata.favorite===1"
                                                                    class="btn btn-red btn-sm mr-1"
                                                                    (click)="setradioMarkUnits('Remove from Favorite',dealerVehicleslistdata)"
                                                                    title="Remove from Favorite">
                                                                    <span class="icon"><i class="fa fa-heart"></i></span>
                                                                </button>

                                                                </div>
                                                                <div class="product-location">
                                                                    <a href="#">
                                                                        <i class="fas fa-map-marker-alt"></i>
                                                                        <span>{{ dealerVehicleslistdata.province }}</span>
                                                                    </a>
                                                                </div>
                                                            <!-- <img class="img-fluid"  src="../../assets/images/nofoundImage.png" alt="product image"> -->
                                                        </div>
                                                        <div class="product-content">
                                                            <div class="vehicleInformation">
                                                                    <div class="row align-items-center justify-content-between mx-0">
                                                                        <h6 class="vehicle-details mb-0">{{ dealerVehicleslistdata.carYr }} {{
                                                                            dealerVehicleslistdata.make }} {{ dealerVehicleslistdata.model
                                                                            }} {{ dealerVehicleslistdata.trim }}</h6>
                                                                        <div class="price-area">
                                                                            <span class="mb-0 pricecode"><b>{{dealerVehicleslistdata.listedPrice| currency: 'USD'
                                                                                }}</b></span>
                                                                        </div>
                                                                    </div>
                                                            </div>

                                                            <ul class="product-features">
                                                                <li>
                                                                    <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                                        alt="product icon">
                                                                    {{ dealerVehicleslistdata.mileage }}
                                                                </li>
                                                                <li>
                                                                    <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                                        alt="product icon">
                                                                    {{ dealerVehicleslistdata.engineFuelType }}
                                                                </li>
                                                                <li>
                                                                    <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                                        alt="product icon">
                                                                    {{ dealerVehicleslistdata.body }}
                                                                </li>
                                                            </ul>
                                                            <div class="othervehicledetails col-12">
                                                                <div class="row">
                                                                    <div class="form-group col-12">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <label><b>VIN</b></label>
                                                                            <span class="vehiclevalue">{{
                                                                                dealerVehicleslistdata.vin }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group col-12">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <label><b>Colour</b> </label>
                                                                            <span class="vehiclevalue">{{
                                                                                dealerVehicleslistdata.colour }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group col-12">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <label><b>Stock No </b></label>
                                                                            <span class="vehiclevalue">{{
                                                                                dealerVehicleslistdata.stockNumber
                                                                                }}</span>
                                                                        </div>
                                                                    </div>

                                                                    <div class="form-group col-12">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <label><b>CBB </b></label>
                                                                            <span class="vehiclevalue"> {{
                                                                                dealerVehicleslistdata.cbbValue
                                                                                | currency: 'USD' }}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="form-group col-12">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <label><b>Delta</b></label>
                                                                            <span class="vehiclevalue"> {{
                                                                                dealerVehicleslistdata.deltaValue
                                                                                | currency: 'USD' }}</span>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                            <div class="product-price col-12 mt-1">
                                                                <div class="row justify-content-between align-items-center ">
                                                                    <button
                                                                        *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin' || rolevalue==='Dealer')"
                                                                        type="button"
                                                                        title="AithrVerify"
                                                                        (click)="Aithrenquiry(enquiryform,dealerVehicleslistdata,'Enquiry')"
                                                                        class="btn btn-primary text-white mb-1 mr-1 rounded-pill">
                                                                        AithrVerify
                                                                    </button>

                                                                    <button style="display: none;"
                                                                        *ngIf="(rolevalue==='SuperAdmin') && dealerVehicleslistdata.isUnitClearance===false"
                                                                        class="btn btn-green mb-1 mr-2"
                                                                        title="Mark on Clearance"
                                                                        (click)="setradioMarkUnits('Clearance',dealerVehicleslistdata)">
                                                                        <span class="icon"><i
                                                                                class="fas fa-arrow-alt-circle-down"></i></span>
                                                                    </button>

                                                                    <button
                                                                        *ngIf="(rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin') && dealerVehicleslistdata.isUnitClearance===true"
                                                                        class="btn btn-yellow mb-1 mr-1"
                                                                        title="Discounted">
                                                                        <span class="icon"><i
                                                                                class="fas fa-arrow-alt-circle-up"></i></span>
                                                                    </button>



                                                                    <button *ngIf="rolevalue==='SuperAdmin'"
                                                                        class="btn btn-info mb-1 mr-1 rounded-pill"
                                                                        (click)="openViewDealerModelPopUp(ViewDealerDetails,dealerVehicleslistdata.vin)"
                                                                        title="Dealer Detail">
                                                                        <span class="icon">Dealer Details</span>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>






                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>
                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <div class="col-auto px-0">


                                                    <ngb-pagination [collectionSize]="totalpagecount"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        (pageChange)="pageChangeEvent($event)"

                                                        [boundaryLinks]="true"></ngb-pagination>





                                                </div>
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        

        <ng-template #CheckDetailModel>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Inquiry Details</h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card enquire-vehicle">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Details of Inquiry</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>User Name:</label>
                                            <span>{{UserName}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Email:</label>
                                            <span>{{UserEmail}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Mobile Number:</label>
                                            <span>{{userPhoneNO}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="card enquire-vehicle">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Dealer Info</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Dealer Name:</label>
                                            <span>{{DealerName}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Email:</label>
                                            <span>{{DealerEmail}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Mobile Number:</label>
                                            <span>{{DealerNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>


        <!-- //enquiry form start -->
        <ng-template #enquiryform>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Request/Verify More Information
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="col-xl-6 col-lg-12 col-12">
                            <div class="span12 pagination-centered">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'availability')"
                                            value="availability">
                                        <span>Verify Availability</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'kilometers')"
                                            value="kilometers">
                                        <span>Verify Kilometers</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'safety')" value="safety">
                                        <span>Verify Safety</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'photos')" value="photos">
                                        <span> Photos</span>
                                    </label>
                                </div>

                                <!-- <div *ngIf="isaithrselectedvalue==='On Clearance'">
                                    VISBLE
                                </div> -->
                            </div>
                        </div>
                        <div *ngIf="isaithrselectedvalue==='On Clearance'" class="col-xl-6 col-lg-12 col-12">
                            <div class="form-group mb-1">
                                <label>Offer Amount</label>
                                <input type="text" formControlName="OfferRequest" [(ngModel)]="OfferRequest"
                                    class="form-control" />

                            </div>
                            <div class="form-group mb-1">
                                <label>Comments</label>
                                <textarea class="form-control" formControlName="OfferRequestCommment"
                                    [(ngModel)]="OfferRequestCommment" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button *ngIf="buttonloaderSendEmail" class="buttonload btn btn-sm btn-secondary bg-gradient-info rounded-pill">
                    <i class="fa fa-spinner fa-spin"></i>Email Sending</button>
                <button type="button" *ngIf="!buttonloaderSendEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info rounded-pill">Send Request</button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #makringUnits>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Marking of Unit
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="declineClose()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section id="unitInformation" class="generate-report">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="mb-0"><b>Unit Information</b></h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>VIN</label>
                                            <h6>{{MarkVIN}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Stock No</label>
                                            <h6>{{MarkStockNo}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Year</label>
                                            <h6>{{MarkYear}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Mileage</label>
                                            <h6>{{MarkMilegge}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Make</label>
                                            <h6>{{MarkMake}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Model</label>
                                            <h6>{{MarkModel}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Trim</label>
                                            <h6>{{Marktrim}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Body</label>
                                            <h6>{{Markbody}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Price</label>
                                            <h6>{{Markprice | currency}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="unitInfocheckbox" class="col-12 mt-2">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <label *ngFor="let radiobutton of radioMakringItems">
                                            <input type="radio" name="options" (click)="setradioMarkUnits(radiobutton)"
                                                class="ml-2">{{radiobutton}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">

                <button #MarkButtonCloseBox class="btn btn-danger btn-sm text-white rounded-pill" title="Close"
                    (click)="declineClose()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>
            </div>
        </ng-template>


        <a #ConfirmBoxMarkUnFav style="display: none;" [routerLink]="" (click)="openModal(templateUnFavMark)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Mark" placement="top"
                ngbPopover="Would you like to Mark Selected Unit to be your Favorite?"></i></a>
        <ng-template #templateUnFavMark>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit to be remove from Favorite?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkUnFavUints()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>


        <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(templateMark)"><i class="fa fa-upload"
                triggers="mouseenter:mouseleave" popoverTitle="Mark" placement="top"
                ngbPopover="Would you like to Mark Selected Unit to be your Favorite?"></i></a>
        <ng-template #templateMark>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit to be your Favorite?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkUints()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmMarkSoldBox style="display: none;" [routerLink]="" (click)="openModal(templateSold)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Sold" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as SOLD?"></i></a>
        <ng-template #templateSold>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as SOLD?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkSold()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>
        <a #ConfirmMarkRemoveBox style="display: none;" [routerLink]="" (click)="openModal(templateRemove)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Remove" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as REMOVED??"></i></a>
        <ng-template #templateRemove>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as REMOVED?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkRemove()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmClearanceBox style="display: none;" [routerLink]="" (click)="openModal(templateClearance)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Remove" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as REMOVED??"></i></a>
        <ng-template #templateClearance>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as Discounted ?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkDiscounted()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <!-- //enquiry form end -->

        <ng-template #Buyform>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">BUY</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <p>Please Confirm your purchase of the following <br> <b>{{Year}} {{Make}} {{Model}} </b></p>
                        <p>A 15 % deposit is required to proceed</p>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <!-- <button (click)="PostVerifyvehicleRequest()" type="button" class="btn btn-success btn-sm text-white">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
                </button> -->
                <button *ngIf="buttonloaderBuyEmail" class="buttonload btn btn-sm btn-secondary bg-gradient-info rounded-pill">
                    <i class="fa fa-spinner fa-spin"></i> Email Sending</button>
                <button type="button" *ngIf="!buttonloaderBuyEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info rounded-pill">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>Yes</button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #ModelPopUpImages let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Images</h5>
                <button type="button" class="close" style="color: white;" (click)="RefreshImageList();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report imageSize row">

                    <div class="card card-raised mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Auto Upload Images</h6>
                        </div>
                        <div class="card-body p-2">


                            <div *ngIf="errorshowAuto=='false'"
                                style="width:750px; height:485px; text-align:center;margin:0; padding:0;">
                                <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                                    [imageSize]="{width: '100%', height:200}" slideImage="1">
                                </ng-image-slider>

                            </div>

                            <div *ngIf="errorshowAuto=='true'"
                                class="bg-danger text-white col-md-4 mx-auto text-center">
                                {{errorMessageAuto}}
                            </div>
                        </div>
                    </div>

                </section>

                <section class="manual-uploadImage">
                    <div class="card card-raised  mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Manual Upload Images</h6>
                        </div>
                        <div class="card-body p-2" *ngIf="errorshow=='false'">
                            <div class="profile-icon" *ngFor="let element of customimagesrc">
                                <img [src]="element.imgsrc" width="150" height="150" />
                            </div>
                        </div>

                        <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                            {{errorMessage}}
                        </div>

                    </div>
                </section>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger ml-2 rounded-pill"
                    (click)="RefreshImageList();modal.dismiss()">Cancel</button>
            </div>
        </ng-template>


        <ng-template #ViewDealerDetails let-modal>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title mb-0" id="AccounttransferLabel" style="color: white;">Dealer Information</h6>
                <button type="button" class="close" style="color: white;" (click)="modal.dismiss()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body DealerInformationForm pt-0">
                <section class="generate-report row">
                    <div class="card card-raised mt-2 mx-auto">
                        <!-- <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0"></h6>
                        </div> -->
                        <div class="card-body p-2">
                            <div class="w-100">
                                <div class="form-group">
                                    <label>Dealer Name:</label>
                                    <span class="labelvalue">{{DvDealerName}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Dealer Address:</label>
                                    <span class="labelvalue">{{DvDealerAddress}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Dealer Contact Name:</label>
                                    <span class="labelvalue">{{DvDealerContactName}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Dealer Contact Phone Number:</label>
                                    <span class="labelvalue">{{DvDealerContactPhoneNumber}}</span>
                                </div>
                                <div class="form-group">
                                    <label>Dealer Contact Email:</label>
                                    <span class="labelvalue"><a>{{DvDealerContactEmail}}</a></span>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>



            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-sm btn-danger m-0 rounded-pill" (click)="modal.dismiss()">Cancel</button>
            </div>
        </ng-template>

    </form>


    <ng-template #ModelMakeOffer let-modal>
        <div class="modal-header" style="background-color: black;">
            <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Offer</h5>
            <button type="button" class="close" style="color: white;" (click)="modal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div id="makeofferblock" class="modal-body">
            <section class="generate-report">
                <div class="row">
                    <div id="statusIcon" data-toggle="tooltip" data-placement="top" data-trigger="hover"
                        title="Offer Details">
                        <i class="fa fa-info-circle"></i>
                    </div>
                    <div class="col-md-12">
                        <h6>Offer Section <span> Price {{UnitListPrice|currency}}</span></h6>
                    </div>

                    <div class="form-group mb-1 col-xl-4 col-lg-12">
                        <label> Amount:</label>
                        <input type="text" [readonly]="offerreadonly" [(ngModel)]="OfferAmountng" class="form-control">

                        <div class="form-group mb-1 mt-1" *ngIf="offerreadonly">
                            <label> Date:</label>
                            <input type="text" [readonly]="offerreadonly" [(ngModel)]="OfferDateBindng"
                                class="form-control">
                        </div>
                    </div>

                    <div class="form-group mb-1 col-xl-8 col-lg-12">
                        <label>Comments:</label>
                        <textarea type="text" [readonly]="offerreadonly" [(ngModel)]="OfferCommentng"
                            class="form-control" rows="3"></textarea>
                    </div>

                </div>


                <div class="row" Id="RadiobtDiv" *ngIf="statusofOffer=='exit'">
                    <div class="col-md-12">
                        <h6>Decision Section</h6>
                    </div>


                    <div class="form-group mb-1 col-xl-12 col-lg-12 ">
                        <div class="row mx-2 mb-2 radioresponse">
                            <label class="mr-1" *ngFor="let radioListaccpetdata of DecisionitemsRadiobutton">
                                <input style="font-weight:normal;" type="radio"
                                    [(ngModel)]="selectedValueDecisionRadioBT" [value]="radioListaccpetdata.value"
                                    (click)="SelectionRadioFunction(radioListaccpetdata.groupName)">
                                <b>{{radioListaccpetdata.groupName}}</b>
                            </label>

                        </div>
                    </div>



                </div>
                <hr />
                <div *ngIf="newOffer">


                    <div class="row" id="AcceptSectiondiv" *ngIf="SelectionRadiobt == 999">
                        <!-- <div class="col-md-12">
                        <h6>Accept Section</h6>
                    </div> -->
                        <div class="form-group mb-1 col-xl-4 col-lg-12 ">
                            <div class="row mx-2 mb-2 radioresponse">
                                <label class="mr-1" *ngFor="let radiodata of radioAcceptCheckItems">
                                    <input style="font-weight:normal;" type="radio" class="radiopoint"
                                        name="optionsAccpetaccept" (click)="SelectedAccepteddecline(radiodata)"
                                        [checked]="radiodata ===Isaccepted_Checked"> <b>{{radiodata}}</b>
                                </label>
                            </div>
                            <div class="row mx-0" *ngIf="Isaccept!=1">
                                <div class="form-group mb-1 col">
                                    <label>Reason:</label>


                                    <select class="form-control" name="RejectReason" [(ngModel)]="RejectReason">
                                        <option value="0" selected>Select Reason</option>
                                        <option *ngFor="let ReasonListdata of ReasonList"
                                            [value]="ReasonListdata.globalCodeID">
                                            {{ReasonListdata.globalCodeName}}</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 col-xl-8 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" rows="2" [(ngModel)]="AccpetComment"></textarea>
                        </div>
                    </div>

                    <div class="row" id="CounterSectiondiv" *ngIf="SelectionRadiobt == 100">
                        <div class="col-md-12">
                            <h6>Counter Section</h6>
                        </div>
                        <div class="form-group mb-1 col-xl-4 col-lg-12">
                            <label> Amount:</label>
                            <input type="text" [(ngModel)]="COunterOffer" class="form-control">

                            <div class="form-group mb-1 mt-1" *ngIf="IsCOunterDateVisible">
                                <label> Date:</label>
                                <input type="text" [(ngModel)]="COunterDate" [ngModelOptions]="{ standalone: true }"
                                    [readonly]="true" class="form-control">
                            </div>
                        </div>


                        <div class="form-group mb-1 col-xl-8 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" [(ngModel)]="COunterComment" rows="2"></textarea>
                        </div>
                    </div>

                    <div class="row" id="ResponseSectiondiv" *ngIf="SelectionRadiobt == 111">
                        <div class="col-md-12">
                            <h6>Response Section</h6>
                        </div>

                        <div class="form-group mb-1 col-xl-12 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" [(ngModel)]="ResponseComment" rows="3" cols="50"></textarea>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-md btn-success rounded-pill" (click)="PostOfferRequestRequest()">Save</button>
            <button type="button" class="btn btn-md btn-danger ml-2 rounded-pill" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>

    <button #regioncheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="regioncheckboxpopup(regioncheckbox,'')"></button>

    <ng-template #regioncheckbox>
        <div id="pdf-viewer-outer" class="modal-body">
            <h5>Region List </h5>
            <hr />
            <div class="row mt-2" id="dealerchckbox">

                <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
                <div class="col-12 listoption">
                    <div class="col-12 px-0 mb-2">
                        <input type="checkbox" name="chckboxall" (click)="Checkallregions($event)" id="checkall"
                            [(ngModel)]="SelectedAllCheckregion" />
                        <label class="ml-1">
                            <h6 class="mb-0" style="font-size:14px;"></h6>Select All
                        </label>
                    </div>
                    <hr class="my-1" />
                    <div class="col-12 px-0 pt-1">
                        <input type="checkbox" [(ngModel)]="groupRegionWestern" name="data" value="1"
                            (click)="GetGroupRegion(1)" /> Western Canada
                        &nbsp;
                        &nbsp;
                        <!-- <input type="checkbox" [(ngModel)]="groupRegionCentral" name="data" value="2"
                            (click)="GetGroupRegion(2)" /> Central Canada -->


                        <input type="checkbox" [(ngModel)]="groupRegionEastern" name="data" value="3"
                            (click)="GetGroupRegion(3)" /> Eastern - Canada
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionOntario" name="data" value="5"
                            (click)="GetGroupRegion(5)" /> Others
                        <!-- <input type="checkbox" [(ngModel)]="groupRegionOntario" name="data" value="5"
                            (click)="GetGroupRegion(5)" /> Ontario
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionother" name="data" value="4"
                            (click)="GetGroupRegion(4)" /> Territories -->
                    </div>
                    <hr />
                </div>
                <hr class="my-2" />
                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of regionlistName ; let i = index">
                    <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" />
                    {{item.globalCodeName}}
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="declineregion()">Back</button>
            <button type="button" class="btn btn-success btn-sm mr-1 rounded-pill" (click)="GetselectedRegions()">Done</button>
        </div>
    </ng-template>


    <ng-template #advanceFilter>
        <div class="modal-header" style="background-color: black;">
            <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Advance Filter</h6>
            <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">

            <section class="generate-report col">
                <div class="row">
                    <div class="col-md-12 enquire-vehicle px-1">
                       <div class="filter-wapper card card-raised mb-2">
                            <div class="card-header card-raised bg-info text-white p-2">
                                <b class="mb-0 d-block">Price Range</b>
                            </div>
                            <div class="card-body p-2">
                                <div class="row">
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label>Min</label>
                                            <input type="text" placeholder="0.00" class="form-control"  [(ngModel)]="Ngpriceranegmin">
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-12">
                                        <div class="form-group">
                                            <label>Max</label>
                                            <input type="text" placeholder="Max List Price(in the results)"
                                             class="form-control" [(ngModel)]="Ngpriceranegmax">
                                        </div>
                                    </div>
                                </div>
                            </div>
                       </div>
                       <div class="filter-wapper card card-raised mb-2">
                            <div class="card-header card-raised bg-info text-white p-2">
                                <b class="mb-0 d-block">Make</b>
                            </div>
                            <div class="card-body p-2">
                                <div class="row my-2">
                                    <div class="col-lg-12 col-12 checkbox-list">

                                        <div class="form-check" *ngFor="let itemmake of AdvancemakeCheckboxArray ; let i = index">
                                        <input type="checkbox" class="form-check-input" (click)="Getmakecheckbox($event,itemmake)" name="checkboxmake" [(ngModel)]="itemmake.IsSelected" />
                                        {{itemmake.make}}
                                        </div>


                                    </div>
                                </div>
                            </div>
                       </div>



                       <div class="filter-wapper card card-raised mb-2" *ngIf="AdvanceYearCheckboxArray?.length > 0">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <b class="mb-0 d-block">Year</b>
                        </div>
                        <div class="card-body p-2">
                            <div class="row my-2">
                                <div class="col-lg-12 col-12 checkbox-list">

                                    <div class="form-check" *ngFor="let itemyear of AdvanceYearCheckboxArray ; let i = index">
                                    <input type="checkbox" class="form-check-input" (click)="SelectyYearcheckbox($event,itemyear)" name="checkboxyear"  [(ngModel)]="itemyear.IsSelected" />
                                    {{itemyear.year}}
                                    </div>


                                </div>
                            </div>
                        </div>
                   </div>



                   <div class="filter-wapper card card-raised mb-2" *ngIf="Advance_Mileage_CheckboxArray?.length > 0">
                    <div class="card-header card-raised bg-info text-white p-2">
                        <b class="mb-0 d-block">Mileage</b>
                    </div>
                    <div class="card-body p-2">
                        <div class="row my-2">
                            <div class="col-lg-12 col-12 checkbox-list">

                                <div class="form-check" *ngFor="let item_milege of Advance_Mileage_CheckboxArray ; let i = index">
                                <input type="checkbox" class="form-check-input" (click)="Selecty_milege_checkbox($event,item_milege)" name="checkboxyear"  [(ngModel)]="item_milege.IsSelected" />
                                {{item_milege.mileage}}
                                </div>


                            </div>
                        </div>
                    </div>
               </div>




               <div class="filter-wapper card card-raised mb-2"   *ngIf="Advance_Model_CheckboxArray?.length > 0">
                <div class="card-header card-raised bg-info text-white p-2">
                    <b class="mb-0 d-block">Model</b>
                </div>
                <div class="card-body p-2">
                    <div class="row my-2">
                        <div class="col-lg-12 col-12 checkbox-list">

                            <div class="form-check" *ngFor="let item_model of Advance_Model_CheckboxArray ; let i = index">
                            <input type="checkbox" class="form-check-input" (click)="Selecty_model_checkbox($event,item_model)" name="checkboxmodel"  [(ngModel)]="item_model.IsSelected" />
                            {{item_model.model}}
                            </div>


                        </div>
                    </div>
                </div>
           </div>



           <div class="filter-wapper card card-raised mb-2"   *ngIf="Advance_trim_CheckboxArray?.length > 0" >
            <div class="card-header card-raised bg-info text-white p-2">
                <b class="mb-0 d-block">Trim</b>
            </div>
            <div class="card-body p-2">
                <div class="row my-2">
                    <div class="col-lg-12 col-12 checkbox-list">

                        <div class="form-check" *ngFor="let item_trim of Advance_trim_CheckboxArray ; let i = index">
                        <input type="checkbox" class="form-check-input" (click)="Selecty_trim_checkbox($event,item_trim)" name="checkboxmodel"  [(ngModel)]="item_trim.IsSelected" />
                        {{item_trim.trim}}
                        </div>


                    </div>
                </div>
            </div>
       </div>







                       <div class="filter-wapper card card-raised mb-2" *ngIf="Advance_body_CheckboxArray?.length > 0">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <b class="mb-0 d-block">Body</b>
                        </div>
                        <div class="card-body p-2">
                            <div class="row my-2">
                                <div class="col-lg-12 col-12 checkbox-list">

                                    <div class="form-check" *ngFor="let itembody of Advance_body_CheckboxArray ; let i = index">
                                    <input type="checkbox" class="form-check-input" (click)="Selecty_body_checkbox($event,itembody)" name="checkboxmake"  [(ngModel)]="itembody.IsSelected" />
                                    {{itembody.body}}
                                    </div>


                                </div>
                            </div>
                        </div>
                   </div>




                   <div class="filter-wapper card card-raised mb-2" *ngIf="Advance_color_CheckboxArray?.length > 0">
                    <div class="card-header card-raised bg-info text-white p-2">
                        <b class="mb-0 d-block">Color</b>
                    </div>
                    <div class="card-body p-2">
                        <div class="row my-2">
                            <div class="col-lg-12 col-12 checkbox-list">

                                <div class="form-check" *ngFor="let itemcolor of Advance_color_CheckboxArray ; let i = index">
                                <input type="checkbox" class="form-check-input" (click)="Selecty_color_checkbox($event,itemcolor)" name="checkboxmake"  [(ngModel)]="itemcolor.IsSelected" />
                                {{itemcolor.colour}}
                                </div>


                            </div>
                        </div>
                    </div>
               </div>



               <div class="filter-wapper card card-raised mb-2" *ngIf="Advance_transmission_CheckboxArray?.length > 0">
                <div class="card-header card-raised bg-info text-white p-2">
                    <b class="mb-0 d-block">Transmission</b>
                </div>
                <div class="card-body p-2">
                    <div class="row my-2">
                        <div class="col-lg-12 col-12 checkbox-list">

                            <div class="form-check" *ngFor="let itemtransmission of Advance_transmission_CheckboxArray ; let i = index">
                            <input type="checkbox" class="form-check-input" (click)="Selecty_transmission_checkbox($event,itemtransmission)"  name="checkboxmake"  [(ngModel)]="itemtransmission.IsSelected" />
                            {{itemtransmission.transmission}}
                            </div>


                        </div>
                    </div>
                </div>
           </div>





                    </div>

                </div>
            </section>


        </div>
        <div class="modal-footer">
            <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
            </button>
            <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="SearchAdvance()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Search
            </button>

        </div>
    </ng-template>


    <!-- Modal -->

  <ng-template #LightboxModal let-modal>
	<div id="VehicleImagesModel">
		<div class="modal-header" style="background-color:none;border-bottom:none;">
		<!-- <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Images </h5> -->
		</div>
		<div class="modal-body">
			<button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
			<ngb-carousel >
				<ng-template ngbSlide *ngFor="let imagelight of lightboxModel.imagedataarray">
				<div class="wrapper">
					<img [src]="imagelight.image" alt="Random first slide">
				</div>
				<div class="carousel-caption">
					<!-- <h3>{{ image.title }}</h3>
					<p>{{ image.short }}</p> -->
				</div>
				</ng-template>
			</ngb-carousel>
		</div>
		<!-- <div class="modal-footer">
		<div class="row justify-content-between align-items-center w-100">
			<div class="col-auto"></div>
			<div class="col-auto">




			<button class="btn btn-danger btn-sm text-white ml-2" (click)="decline()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
			</button>
			</div>
		</div>
		</div> -->
	</div>
  </ng-template>

<!-- <app-footer id="secondfooter"></app-footer> -->
</section>