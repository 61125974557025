import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';

import { AddgroupService } from '../_services/addgroup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.less']
})
export class AddGroupComponent implements OnInit {
  AddGroupForm: FormGroup;
	private notifier: NotifierService;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService:AddgroupService,
    private router: Router,
    notifier: NotifierService
    ) {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
    deleting = false;
    modalRef: BsModalRef;
   // for getting confirm box from HTML by Id
   @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

   // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 2;
  loading = false;
  submitted = false;

  Grouplist=[];
deleteitem:any=0;
edititemid:any=0;
editvaluename:any;
updateid:any;
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ngOnInit(): void {
    this.CreateGroupFrom();
    this.Getgrouplist();
  }

  // function for creating from
CreateGroupFrom() {
  this.AddGroupForm = new FormGroup({
    groupName: this.fb.control('', Validators.required),
    Editname: this.fb.control('', Validators.required)
  });
}

// function for open model pop up
openModal(template: TemplateRef<any>,itemid:any) {
  this.deleteitem=itemid;
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
}
// edit functionality
// function for open model pop up
EditModalpopup(template: TemplateRef<any>,itemid:any) {
  debugger;
  this.edititemid=itemid;
  var modelGroup = {
    "id": itemid
  }

  this.addgroupService.EditGroupList(modelGroup)
  .subscribe(
    data => {
      debugger;
      this.editvaluename=data.groupName;
      this.updateid=data.id;

      this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

    },
    error => {
    });


}


//notifier
public showNotification( type: string, message: string ): void {
  this.notifier.notify( type, message );
}

closeEditModel(){
  this.modalRef.hide() ;
}

closepopupmodel(){
  this.AddGroupForm.invalid
}

openAddGroupModel(targetModal) {

  this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

}
// for update the edited part
update()
{
  this.submitted = true;

  if (this.AddGroupForm.controls.Editname.invalid) {
    this.isValid = false;
    return;
  }
  var modelGroup = {
    "id": this.updateid,
    "GroupName": this.editvaluename,
  }
  this.addgroupService.UpdateGroupName( modelGroup)

  .subscribe(
    (data: any) => {
      this.Getgrouplist();
      this.submitted = false;
      //this.UpdateGroupName();
      this.CreateGroupFrom();
      //alert("data changed")
      this.showNotification("success","Data updated successfully")
      this.modalRef.hide() ;
    // this.Grouplist = data;
    },
    error => {
    });

  //this.updateid=id;
 // this.addgroupService.EditGroupList(updateid);
}
get f() { return this.AddGroupForm.controls; }

ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    debugger
    this.submitted = true;

    if (this.AddGroupForm.controls.groupName.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "GroupName": this.AddGroupForm.controls.groupName.value
      }


      this.addgroupService.Saveaddgroup(modelGroup)
      .subscribe(
        (data: any) => {
          debugger
          this.Getgrouplist();
          this.submitted = false;

          this.showNotification("success","Data saved successfully")
        this.CreateGroupFrom();
       this.modalRef.hide() ;
        //  this.modalcloseOpen.hide();
          this.closeEditModel();
          this.closepopupmodel();
          this.modalcloseOpen.hide();



          //this.modalRef.hide() ;
        // this.Grouplist = data;
        },
        error => {
        });
      this.loading = true;
      this.isValid = true;
    }


  }


  CloseAddmodel()
  {
    this.modalRef.hide();
  }

// function for confirm  delete
confirm(): void {
this.deleteitem;
var modelGroup = {
  "Id": this.deleteitem
}
this.addgroupService.Deletegrouplist(modelGroup)
  .subscribe(
    data => {
      debugger;
      this.Getgrouplist();
    },
    error => {
    });

  this.modalRef.hide();

}
decline(): void {
  this.modalRef.hide();
}

displayStyle = "none";

openPopup() {
  this.displayStyle = "block";
}
closePopup() {
  this.displayStyle = "none";
}


//Calling get list of group on page load

Getgrouplist()
{  debugger;
  this.addgroupService.Getgrouplist()
  .subscribe(
    (data: any) => {
      debugger;
     this.Grouplist = data;
     this.modalRef.hide() ;
    },
    error => {
    });
}


// calling for delete group

//  Deletegrouplist(Id) {

//   var modelGroup = {
//     "id": Id
//   }
//   this.addgroupService.Deletegrouplist(modelGroup)
//   .subscribe(book=>{
// debugger;

//   })

// }
}