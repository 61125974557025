import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

import { GlobalcodeService } from '@app/_services/globalcode.service';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { LenderService } from '@app/_services/lender.service';

@Component({
  selector: 'app-creditor-log',
  templateUrl: './creditor-log.component.html',
  styleUrls: ['./creditor-log.component.less']
})
export class CreditorLogComponent implements OnInit {

  creditorslist:[];
  TotalRecord:any;


  ListofPendingUnitsData = 'ListofUnits.xlsx';

  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  modalRef: BsModalRef;
  updateVIN: any;
  modalRefcheck: BsModalRef;
  isValid: boolean = true;
  opcode: any;
  DealerList = [];

  MarkVIN: any;
  TypeClick: any;
  Markid: any;
  MarkDealerId: any;
  MarkStockNo: any;
  MarkYear: any;
  MarkMilegge: any;
  MarkMake: any;
  MarkModel: any;
  Marktrim: any;
  Markbody: any;
  Markprice: any;
  markValueTitle: any;
  loading = false;
  submitted = false;
  dateRecevied = new Date();
  deleteVINNumber: any;

  filepicture = [];

  extraCleanValue: any;
  fuelType: any;
  Color: any;
  OpCode: any;
  fuellist = [];
  cleanValue: any;
  averageValue: any;
  roughvalue: any;
  VinNumber: any;
  MileageValue: any;

  bodyvalue: any;
  trimvalue: any;
  modelvalue: any;
  makevalue: any;
  yearvalue: any;
  modalcloseOpen: any;
  errorshow: any;
  errorMessage: string;

  // Controls for ng models
  textLabel: any;
  mileageControl: any;
  stockNumber: any;
  vinNumber: any;
  carYr: any;
  make: any;
  model: any;
  trim: any;
  style: any;
  body: any;
  conditon: any;
  datereceived = { year: 1789, month: 7, day: 14 };
  listPrice: any;
  cost: any;
  extraClean: any;
  clean: any;
  average: any;
  rough: any;
  // end of ng models


  conditionslist = [];

  selectedlenderid:any;
 creditorid:any;
 creditorNameValue:any;
 LenderNameValuePopUp:any;

  ApplicationForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private lenderService: LenderService,
    private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    private accountService: AccountService
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }

  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  @ViewChild('ConfirmBoxVINCheck', { static: false }) ConfirmBoxVINCheckClick: ElementRef;
  vehicleObject = [];
 customimagesrc = [];
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  UserIDValue: any;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  lenderlists:[];

  sort(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.creditorslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  DealerNameFilter: any;
  showDealerList: any;
  ColorTheme:any;

  ngOnInit(): void {

    this.GetAllCreditorlist();
  }


// function for getting user list
GetAllCreditorlist() {
  this.spinner.show();
  this.manageInventoryService.getAllCreditorList()
    .subscribe(
      (data: any) => {
        debugger;
        this.creditorslist = data;
        this.TotalRecord = data.length;
        this.spinner.hide();
      },
      error => {
      });
}


public currentDate() {
  var todayDate = new Date();
  return {
    year: todayDate.getFullYear(),
    month: todayDate.getMonth() + 1,
    day: todayDate.getDate()
  }
}

handlePageSizeChange(event) {
  this.pageSize = event.target.value;
  this.page = 1;

}


public showNotification(type: string, message: string): void {
  this.notifier.notify(type, message);
}

closeEditModel() {
  this.modalRef.hide();
}


confirm(): void {
debugger;
  var modelGroup = {
    "Lenderid": this.selectedlenderid,
    "creditorid": this.creditorid
  }
  this.manageInventoryService.UpdatedLenderFromCreditor(modelGroup)
    .subscribe(
      data => {

        this.showNotification("success", "Inventory de-activated successfully")
        this.GetAllCreditorlist();
      },
      error => {
      });

  this.modalRef.hide();

}

getSelectedValue(event:any){
  debugger
  // Prints selected value
  this.selectedlenderid=event.target.value;
}


decline(): void {
  this.modalRef.hide();
}
openModal(template: TemplateRef<any>,id:any,CreditorName:any) {
 // this.ApplicationForm.get('DealerNameFilter').enable();
 // this.textLabel = "Add";
this.creditorNameValue = CreditorName;
this.creditorid=id;

 this.Getlenderslist();
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
}

openModalSold(template: TemplateRef<any>) {
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect', backdrop: 'static', keyboard: false });
}


 // functions for getting lenders
 Getlenderslist() {
  this.lenderService.getAllLendersList()
    .subscribe(
      (data: any) => {


        //cod for proper paging
        this.lenderlists = data;



        this.TotalRecord = data.length;
      },
      error => {
      });
}




exportToCSV(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.creditorslist);
}

printTable() {
  var prepare = [];
  this.creditorslist.forEach(e => {
    var tempObj = [];
    // tempObj.push(e.vin);
    // tempObj.push(e.stockNumber);
    // tempObj.push(e.carYr);
    // tempObj.push(e.mileage);
    // tempObj.push(e.make);
    // tempObj.push(e.model);
    // tempObj.push(e.trim);
    // tempObj.push(e.body);
    // tempObj.push(e.listedPrice);
    // tempObj.push(e.dealerCost);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>VIN</th><th>StockNumber</th><th>Year</th><th>Mileage</th><th>Make</th><th>Model</th><th>Trim</th><th>Body</th><th>ListedPrice</th><th>DealerCost</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function () {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
}

downloadPdf() {
  var prepare = [];
  this.creditorslist.forEach(e => {
    var tempObj = [];
    // tempObj.push(e.vin);
    // tempObj.push(e.stockNumber);
    // tempObj.push(e.carYr);
    // tempObj.push(e.mileage);
    // tempObj.push(e.make);
    // tempObj.push(e.model);
    // tempObj.push(e.trim);
    // tempObj.push(e.body);
    // tempObj.push(e.listedPrice);
    // tempObj.push(e.dealerCost);
    // prepare.push(tempObj);
  });
  const doc = new jsPDF('l', 'pt', 'a4');
  autoTable(doc, {
    head: [['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost']],
    body: prepare,
    didDrawCell: (data) => { },
  });
  doc.save('ListofUnits' + '.pdf');
}



}
