import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

import { GlobalcodeService } from '@app/_services/globalcode.service';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { AddgroupService } from '@app/_services/addgroup.service';
@Component({
  selector: 'app-add-inventory',
  templateUrl: './add-inventory.component.html',
  styleUrls: ['./add-inventory.component.less']
})
export class AddInventoryComponent implements OnInit {
  AddInventoryForm: FormGroup;
  private notifier: NotifierService;

  @ViewChild('dealercheckboxpopupbuttonToBeClicked') buttonToBeClicked: ElementRef
  groupDisable:any;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    private accountService: AccountService,
    private addgroupService: AddgroupService,
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  @ViewChild('ConfirmMarkSoldBox', { static: false }) ConfirmMarkSoldBoxClick: ElementRef;
  ListofPendingUnitsData = 'ListofUnits.xlsx';
  TotalRecord: any;
  dealerVehicleslist: DealerVehicles[] = [];
  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  modalRef: BsModalRef;
  updateVIN: any;
  modalRefcheck: BsModalRef;
  isValid: boolean = true;
  opcode: any;
  DealerList = [];
  isExtraReadOnly: boolean = true;
  isCleanReadOnly: boolean = true;
  isRoughReadOnly: boolean = true;
  isAverageReadOnly: boolean = true;
  MarkVIN: any;
  TypeClick: any;
  Markid: any;
  MarkDealerId: any;
  MarkStockNo: any;
  MarkYear: any;
  MarkMilegge: any;
  MarkMake: any;
  MarkModel: any;
  Marktrim: any;
  Markbody: any;
  Markprice: any;
  markValueTitle: any;
  loading = false;
  submitted = false;
  dateRecevied = new Date();
  deleteVINNumber: any;

  filepicture = [];

  extraCleanValue: any;
  fuelType: any;
  Color: any;
  OpCode: any;
  fuellist = [];
  cleanValue: any;
  averageValue: any;
  roughvalue: any;
  VinNumber: any;
  MileageValue: any;

  bodyvalue: any;
  trimvalue: any;
  modelvalue: any;
  makevalue: any;
  yearvalue: any;
  modalcloseOpen: any;
  errorshow: any;
  errorshowAuto:any;
  errorMessage: string;
  errorMessageAuto:any;
  // Controls for ng models
  textLabel: any;
  mileageControl: any;
  stockNumber: any;
  vinNumber: any;
  carYr: any;
  make: any;
  model: any;
  trim: any;
  style: any;
  body: any;
  conditon: any;
  datereceived = { year: 1789, month: 7, day: 14 };
  listPrice: any;
  cost: any;
  extraClean: any;
  clean: any;
  average: any;
  rough: any;
  // end of ng models


  conditionslist = [];
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  @ViewChild('ConfirmBoxVINCheck', { static: false }) ConfirmBoxVINCheckClick: ElementRef;
  vehicleObject = [];
 customimagesrc = [];
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  UserIDValue: any;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  // function for column sorting
  sort(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  DealerNameFilter: any;
  showDealerList: any;
  ColorTheme:any;
  GroupLists = [];
  selectedGroupIds: string;
  selectedGroupId: any;
  dealersmultiselectarray: any[];
  groupidvalue: any;
  selectedgroupId: any;
  dealerName: any;
  dealerlistName = [];
  ItemCount: any = 0;
  SelectedAllCheck: any = false;
  ExtingItemCount: any;
  hideSupControlsButton:any;
  dealerlistForsearch = [];
  SelectSearchDealer:any;
  SelectSearchDealervalue:any;
  SelectSearchgroupvalue:any;
  OpcodeValue:any;
  milageCheck:any;
  VinCheck:any;
  ngOnInit(): void {
    debugger;
    this.Createform();
      this.milageCheck='false';
      this.VinCheck='false';
    this.GetGroupList();
    this.GetVehicleCondtionsList();
    this.GetFuellist();
   
    this.hideSupControlsButton="false";
    this.updateVIN = "0";
    this.Color = "";
    this.opcode = "0";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.groupidvalue = localStorage.getItem('groupid');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue = localStorage.getItem('userid');

    this.GetallDealerList();
    
    this.DealerNameFilter = this.DealerIdvalue;
    this.groupDisable = "true";
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.selectedGroupIds = this.GroupNamevalue;
    

    //this.GetAllInventoryListDealerName();
    if (this.rolevalue == "SuperAdmin") {
      this.spinner.show();
      this.OpcodeValue="1";
      this.SelectSearchDealervalue="0";
      this.SelectSearchgroupvalue="0";
      this.GetAllInventoryList();
      this.GetAddDealerName();
      this.opcode = "0";
    
      
      this.hideSupControlsButton="false";
      this.showDealerList = true;
    } else if (this.rolevalue != "SuperAdmin") {
      this.opcode = this.groupidvalue;
      this.GetDealers(this.groupidvalue);
      this.hideSupControlsButton="true";
      this.GetAddDealerName();
     
      this.showDealerList = true;
    }

    // else {
    //   this.GetAllInventoryListDealerName();
    //   this.showDealerList = false;
    //   this.hideSupControlsButton="false";
    // }

  }

  ResetUnits() {
    this.SelectSearchDealervalue = "0";
    this.SelectSearchgroupvalue = "0";
    this.OpcodeValue = "1";
    this.GetAllInventoryList();
  }
  GetallDealerList() {

    var modelGroup = {
      "Opcode": "1",
      "RoleName":this.rolevalue,
      "LoggedInUserID":this.UserIDValue
    }
    this.addgroupService.getAlldealerListForSearch(modelGroup)
      .subscribe(
        (data: any) => {
         
          this.dealerlistForsearch = []
          if (data.length > 0) {
            // this.dealerName = "";

            this.dealerlistForsearch = data
          }
          else {


          }
        }
      )

  }

  SerachDealer(event) {

   debugger;
   this.SelectSearchDealer = event;
   this.SelectSearchDealervalue = event.id;
    var serachdelaerid = this.SelectSearchDealer;

    
    this.SelectSearchgroupvalue=event.dGroupId;
    this.OpcodeValue ="2";
    this.GetAllInventoryList();

  }
  getSelectedItem() {
    return this.dealerlistName.filter(item => item.IsSelected === true);
  }

  GetselectedDealer() {
    this.dealersmultiselectarray = this.getSelectedItem();
    var selecteditem = this.getSelectedItem();
    this.decline();

    this.GetAllInventoryListDealerName();
  }

  Dealercheckboxpopup(template: TemplateRef<any>, itemid: any) {

    this.ItemCount = 0;
    for (let i = 0; i < this.dealerlistName.length; i++) {
      if (this.dealerlistName[i].IsSelected == true) {
        this.ItemCount = this.ItemCount + 1;
      }
    }
    this.ExtingItemCount = this.dealerlistName.length;
    if (this.ExtingItemCount == this.ItemCount) {
      this.SelectedAllCheck = true;
    } else {
      this.SelectedAllCheck = false;
    }

    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  //checkall dealer
  Checkalldealer(e) {
   debugger;
    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = false
      })
    }

  }

  ClearDealer(event) {
    if (this.dealerlistName.length > 1) {
      this.buttonToBeClicked.nativeElement.click();
    }
    //this.buttonToBeClicked.nativeElement.click();
  }

  GetDealers(groupId: any) {
    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {
         debugger;
          this.dealersmultiselectarray = []
          if (data.length == 0) {
            this.dealerName = "";
            // this.selectedDealerIds = [];
            this.dealersmultiselectarray = []
          } else {
            // this.selectedDealerIds = this.dealerlistName;
            //this.dealerlistName=data;

          }

          this.dealerlistName = data;

          if (this.dealerlistName.length > 1) {
            if (this.rolevalue != "SuperAdmin") {
              this.buttonToBeClicked.nativeElement.click();
            } else {

            }

          }
          else {
            this.dealersmultiselectarray = this.dealerlistName;
            this.GetAllInventoryListDealerName();
          }


        },
        error => {
        });


  }
  GetGroupList() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          this.GroupLists = data;
        },
        error => {
        });
  }
  get f() { return this.AddInventoryForm.controls; }


  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  // function for getting
  MarkSold() {
    var modelGroup = {
      "UserID": this.UserIDValue,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.UserIDValue,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();

          this.showNotification("success", "Unit marked as SOLD Successfully");
          if (this.rolevalue == "SuperAdmin") {
            this.GetAllInventoryList();
            this.GetAddDealerName();

            this.showDealerList = true;
          } else {
            this.GetAllInventoryListDealerName();
            this.showDealerList = false;
          }
        },
        error => {
        });
  }


  //upload pic
  onFileChanged(event) {
   
    this.filepicture = [];

    var filepicturestart = [];

    if (event.target.files.length > 0) {
      // const file = event.target.files[0];
      const file = event.target.files;
      // this.labelImport.nativeElement.innerText = file.name;


      filepicturestart.push(file);

      var filename = "";
      // this code help to push selected image to server
      filepicturestart.forEach((element, ind) => {
        this.filepicture.push(element);
      });

      //this code binding name
      // this.filepicture[0].FileList.forEach((element,ind) => {

      //   filename=filename+' '+element[ind].name

      //   });
      filename = this.filepicture[0].length + 'File';

      document.getElementById("choosefile").innerHTML = filename;
      this.AddInventoryForm.patchValue({
        // picture: this.filepicture,
        picture: file

      });
    }


    //   if (event.target.files && event.target.files[0]) {
    //     var filesAmount = event.target.files.length;
    //     for (let i = 0; i < filesAmount; i++) {
    //             var reader = new FileReader();

    //             reader.onload = (event:any) => {
    //               console.log(event.target.result);
    //                this.filepicture.push(event.target.result);

    //                this.AddInventoryForm.patchValue({
    //                 picture: this.filepicture
    //                });
    //             }

    //             reader.readAsDataURL(event.target.files[i]);
    //     }
    // }

  }

  setradioMarkUnits(value: any, item: any) {
    this.Markid = item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.MarkStockNo = item.stockNumber;

    this.MarkMilegge = item.mileage;

    this.Marktrim = item.trim;
    this.Markbody = item.body;
    this.Markprice = item.listedPrice;

    this.markValueTitle = value;
    this.TypeClick = "Sold";
    let el: HTMLElement = this.ConfirmMarkSoldBoxClick.nativeElement;
    el.click();


  }

  GetAddDealerName() {
    var modelGroup = {
      "Opcode": this.opcode,
    }
    this.manageInventoryService.getAddDealerNamelist(modelGroup)
      .subscribe(
        (data: any) => {
          this.DealerList = data;
        },
        error => {
        });
  }
  ConvertserverdatetoDatetime(datadate) {

    var momentDate = moment(datadate);

    var dateactive = new Date(datadate);
    var localstringdate = dateactive.toLocaleDateString();
    return {
      year: dateactive.getFullYear(),
      month: dateactive.getMonth() + 1,
      day: dateactive.getDate()
    }


  }
  CloseAddmodel() {

    this.modalRef.hide();
    this.ResetNgModels();
    this.Createform();
    this.textLabel = "";
    this.DealerNameFilter = this.DealerIdvalue;
    this.submitted = false;
    this.isValid = true;

    return;
  }

  RefreshImageList() {
    this.vehicleObject = [];
  }

  // function to reset ngmodels
  ResetNgModels() {
    this.extraCleanValue = "";

    this.cleanValue = "";
    this.averageValue = "";
    this.roughvalue = "";
    this.VinNumber = "";
    this.MileageValue = "";

    this.bodyvalue = "";
    this.trimvalue = "";
    this.modelvalue = "";
    this.makevalue = "";
    this.yearvalue = "";

    this.mileageControl = "";
    this.stockNumber = "";
    this.vinNumber = "";
    this.carYr = "";
    this.make = "";
    this.model = "";
    this.trim = "";;
    this.style = "";
    this.body = "";
    this.conditon = "";
    this.datereceived = this.currentDate();
    this.listPrice = "";
    this.cost = "";
    this.extraClean = "";
    this.clean = "";
    this.average = "";
    this.rough = "";
    this.fuelType = "";
    this.Color = "";
    this.updateVIN = 0;
    this.DealerNameFilter = "";
  }

  GetFuellist() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getfueltypelist(modelGroup)
      .subscribe(
        (data: any) => {

          this.fuellist = data;
        },
        error => {
        });
  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dealerVehicleslist);
  }

  printTable() {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      tempObj.push(e.cbbValue);
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>VIN</th><th>StockNumber</th><th>Year</th><th>Mileage</th><th>Make</th><th>Model</th><th>Trim</th><th>Body</th><th>ListedPrice</th><th>DealerCost</th><th>CBB</th><th>Delta value</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdf() {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      tempObj.push(e.cbbValue);
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost','CBB','Delta Value']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('ListofUnits' + '.pdf');
  }
  // copytable(el) {
  //   var urlField = document.getElementById(el);
  //   var range = document.createRange();
  //   range.selectNode(urlField);
  //   window.getSelection().addRange(range);
  //   document.execCommand('copy');
  //   this.showNotification("success", "Data copy to clipboard successfully")
  // }
  copytable(el) {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      prepare.push(tempObj);
    });

    var text = prepare.map(row => row.join('\t')).join('\n');

    navigator.clipboard.writeText(text).then(() => {
      alert('Data copied to clipboard successfully');
    }, (err) => {
      alert('Unable to copy data to clipboard. Please try again.');
    });
  }
  exportexcel() {
    const prepare = [];
    this.dealerVehicleslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      tempObj.push(e.cbbValue);
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost','CBB','Delta  Values'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofUnits');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofUnits.xlsx');
  }
  GetVehiclePhotosListByVIN(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {
          debugger;
          this.customimagesrc=[];
          this.vehicleObject=[];
          if (data.length > 0) {
            this.errorshow = 'false';
            this.errorshowAuto ='false';
            for (var i = 0; i < data.length; i++) {
              if(data[i].imageStatus=="1")
              {
                //this.vehicleObject=[];
                this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: ''})
              } else{
                this.customimagesrc.push({imgsrc:data[i].imagesrc })
              }
            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }
          if(this.vehicleObject.length == 0)
          {
            this.errorshowAuto = 'true';
            this.errorMessageAuto = "No images for this Vehicle.";
          }

          if(this.customimagesrc.length == 0)
          {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }

  openImagesModelPopUp(targetModal, VehicleID) {
    this.GetVehiclePhotosListByVIN(VehicleID);
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  onDateSelectactiveon(event) {

    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.dateRecevied = new Date(finalDate);
  }

  Createform() {
    this.AddInventoryForm = new FormGroup({
      mileageControl: this.fb.control('', Validators.required),
      stockNumber: this.fb.control('', Validators.required),
      vinNumber: this.fb.control('', Validators.required),
      carYr: this.fb.control('', Validators.required),
      make: this.fb.control('', Validators.required),
      model: this.fb.control('', Validators.required),
      trim: this.fb.control('', Validators.required),
      style: this.fb.control('', Validators.required),
      body: this.fb.control('', Validators.required),
      conditon: this.fb.control('', Validators.required),
      datereceived: this.fb.control('', Validators.required),
      listPrice: this.fb.control('', Validators.required),
      cost: this.fb.control('', Validators.required),
      extraClean: this.fb.control('', Validators.required),
      clean: this.fb.control('', Validators.required),
      average: this.fb.control('', Validators.required),
      rough: this.fb.control('', Validators.required),
      fuelType: this.fb.control('', Validators.required),
      Color: this.fb.control(''),
      DealerNameFilter: this.fb.control(''),
      picture: this.fb.control('')
    });
  }
  openAddInventoryModel(targetModal) {
    this.textLabel = "Add";
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-xl modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  checkVinExist(Vin: any, Mileage: any) {
    debugger;
    if (Vin == "") {

      this.VinCheck = 'true';

    }
    else if (Mileage == "") {
      this.VinCheck = 'false';
      this.milageCheck = 'true';
    } else {
      this.VinCheck = 'false';
      this.milageCheck = 'false';
      this.VinNumber = Vin;
      this.MileageValue = Mileage;
      this.GetDealerVehiclesByVin(Vin);
    }

  }
  GetVehicleCondtionsList() {
    this.globalcodeService.getVehicleCondtionsList()
      .subscribe(
        (data: any) => {

          this.conditionslist = data;
        },
        error => {
        });
  }


  onDelete(UserId: any) {
    this.deleteVINNumber = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }

  confirm(): void {

    var modelGroup = {
      "VIN": this.deleteVINNumber
    }
    this.manageInventoryService.Delete(modelGroup)
      .subscribe(
        data => {

          this.showNotification("success", "Inventory de-activated successfully")
          if (this.rolevalue == "SuperAdmin") {
            this.GetAllInventoryList();
          } else {
            this.GetAllInventoryListDealerName();
          }
        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.AddInventoryForm.get('DealerNameFilter').enable();
    this.textLabel = "Add";
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  openModalSold(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect', backdrop: 'static', keyboard: false });
  }
  openEditModel(targetModal, id) {
    this.textLabel = "Edit";
    this.updateVIN = id;
    var modelGroup = {
      "Id": id
    }
    this.AddInventoryForm.get('DealerNameFilter').disable();
    this.manageInventoryService.getDealerVehiclesByid(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.mileageControl = data[0].mileage;
          this.stockNumber = data[0].stockNumber;
          this.vinNumber = data[0].vin;
          this.carYr = data[0].carYr;
          this.makevalue = data[0].make;
          this.modelvalue = data[0].model;
          this.trimvalue = data[0].trim;
          this.style = data[0].style;
          this.bodyvalue = data[0].body;
          if (data[0].condition == "No") {
            this.conditon = "Used";
          } else {
            this.conditon = data[0].condition;
          }

         
          this.datereceived = this.ConvertserverdatetoDatetime(data[0].dateReceived);

          this.listPrice = data[0].listedPrice;
          this.cost = data[0].dealerCost;
          this.extraCleanValue = data[0].cbbValX;
          this.cleanValue = data[0].cbbValC;
          this.averageValue = data[0].cbbValA;
          this.roughvalue = data[0].cbbValR;
        //  this.fuelType = data[0].engineFuelType;

          if (data[0].engineFuelType == "No") {
            this.fuelType = "Gas";
          } else {
            this.fuelType = data[0].engineFuelType;
          }

          this.Color = data[0].colour;
          this.DealerNameFilter = data[0].dealerID;


        },
        error => {
        });
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-xl modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  public currentDate() {
    var todayDate = new Date();
    return {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate()
    }
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;

  }

  onSubmit() {
    debugger;
    if (this.updateVIN != "" && this.updateVIN != "0" && this.updateVIN != "undefined") {
      // code for Update
      var modelGroupUpdate = {
        "Id": this.updateVIN,
        "VIN": this.AddInventoryForm.controls.vinNumber.value,
        "StockNumber": (this.AddInventoryForm.controls.stockNumber.value!=undefined?this.AddInventoryForm.controls.stockNumber.value:""),
        "CarYr": this.AddInventoryForm.controls.carYr.value,
        "Mileage": this.AddInventoryForm.controls.mileageControl.value,
        "Make": this.AddInventoryForm.controls.make.value,
        "Model": this.AddInventoryForm.controls.model.value,
        "Trim": this.AddInventoryForm.controls.trim.value,
        "Body": this.AddInventoryForm.controls.body.value,
        "Colour": this.Color,
        "VehicleInfo": "",
        "ListedPrice": this.AddInventoryForm.controls.listPrice.value,
        "DealerCost": this.AddInventoryForm.controls.cost.value,
        "Condition": this.AddInventoryForm.controls.conditon.value,
        "CBBValA": this.AddInventoryForm.controls.average.value,
        "CBBValC": this.AddInventoryForm.controls.clean.value,
        "CBBValR": this.AddInventoryForm.controls.rough.value,
        "CBBValX": this.AddInventoryForm.controls.extraClean.value,
        "DateReceived": this.dateRecevied,
        "DriveTrain": "",
        "Options": "",
        "SeatCapacity": "",
        "Style": this.AddInventoryForm.controls.style.value,
        "Transmission": "",
        "EngineFuelType": this.fuelType,
        "DealerName": this.dealerNamevalue,
        "GroupName": this.GroupNamevalue,
        "DealerID": this.DealerNameFilter,
        "picture": this.AddInventoryForm.controls.picture.value,
      }


      //formdata
      let FormDatalist = new FormData();
      var stockNumberValue = this.AddInventoryForm.controls.stockNumber.value;
      if (stockNumberValue == undefined) {
        stockNumberValue="";
      }

      FormDatalist.append("Id", this.updateVIN);
      FormDatalist.append("VIN", this.AddInventoryForm.controls.vinNumber.value);
      FormDatalist.append("StockNumber", (this.AddInventoryForm.controls.stockNumber.value!=undefined?this.AddInventoryForm.controls.stockNumber.value:""));
      FormDatalist.append("CarYr", this.AddInventoryForm.controls.carYr.value);
      FormDatalist.append("Mileage", this.AddInventoryForm.controls.mileageControl.value);
      FormDatalist.append("Make", this.AddInventoryForm.controls.make.value);
      FormDatalist.append("Model", this.AddInventoryForm.controls.model.value);
      FormDatalist.append("Trim", this.AddInventoryForm.controls.trim.value);
      FormDatalist.append("Body", this.AddInventoryForm.controls.body.value);
      FormDatalist.append("Colour", this.Color);
      FormDatalist.append("VehicleInfo", "");
      FormDatalist.append("ListedPrice", this.AddInventoryForm.controls.listPrice.value);
      FormDatalist.append("DealerCost", this.AddInventoryForm.controls.cost.value);
      FormDatalist.append("Condition", this.AddInventoryForm.controls.conditon.value);

      FormDatalist.append("CBBValA", this.AddInventoryForm.controls.average.value);
      FormDatalist.append("CBBValC", this.AddInventoryForm.controls.clean.value);
      FormDatalist.append("CBBValR", this.AddInventoryForm.controls.rough.value);
      FormDatalist.append("CBBValX", this.AddInventoryForm.controls.extraClean.value);
      FormDatalist.append("DateReceivedString", this.dateRecevied.toString());
      FormDatalist.append("DriveTrain", "");


      FormDatalist.append("Options", "");
      FormDatalist.append("SeatCapacity", "");
      FormDatalist.append("Style", this.AddInventoryForm.controls.style.value);
      FormDatalist.append("Transmission", "");
      FormDatalist.append("EngineFuelType", this.fuelType);
      FormDatalist.append("DealerName", this.dealerNamevalue);
      FormDatalist.append("GroupName", this.GroupNamevalue);
      FormDatalist.append("DealerID", this.DealerNameFilter);



      //FormDatalist.append("picture",this.AddInventoryForm.controls.picture.value);

      if (this.filepicture.length > 0) {
        this.filepicture.forEach((currentValue) => {

          // currentValue.forEach((data, index)  => {



          //   FormDatalist.append('picture', data[index]);
          // });

          for (let i = 0; i < currentValue.length; i++) {
            FormDatalist.append('picture', currentValue[i]);
          }


          //  FormDatalist.append('picture', currentValue[index]);
        });
      }





      this.manageInventoryService.UpdateInventory(FormDatalist)
        .subscribe(
          (data: any) => {
            this.textLabel = "";
            if (this.rolevalue == "SuperAdmin") {
              this.GetAllInventoryList();
            } else {
              this.GetAllInventoryListDealerName();
            }

            this.showNotification("success", "Inventory added successfully.");
            this.Createform();
            this.ResetNgModels();
            this.modalRef.hide();
            this.closeEditModel();
            this.CloseAddmodel();
          },
          error => {
          });
    } else {
      this.submitted = true;
      if (this.AddInventoryForm.invalid) {
        this.isValid = false;
        return;
      } else {
        var modelGroup = {
          "VIN": this.AddInventoryForm.controls.vinNumber.value,
          "StockNumber": (this.AddInventoryForm.controls.stockNumber.value!=undefined?this.AddInventoryForm.controls.stockNumber.value:""),
          "CarYr": this.AddInventoryForm.controls.carYr.value,
          "Mileage": this.AddInventoryForm.controls.mileageControl.value,
          "Make": this.AddInventoryForm.controls.make.value,
          "Model": this.AddInventoryForm.controls.model.value,
          "Trim": this.AddInventoryForm.controls.trim.value,
          "Body": this.AddInventoryForm.controls.body.value,
          "Colour": this.Color,
          "VehicleInfo": "",
          "ListedPrice": this.AddInventoryForm.controls.listPrice.value,
          "DealerCost": this.AddInventoryForm.controls.cost.value,
          "Condition": this.AddInventoryForm.controls.conditon.value,
          "CBBValA": this.AddInventoryForm.controls.average.value,
          "CBBValC": this.AddInventoryForm.controls.clean.value,
          "CBBValR": this.AddInventoryForm.controls.rough.value,
          "CBBValX": this.AddInventoryForm.controls.extraClean.value,
          "DateReceived": this.dateRecevied,
          "DriveTrain": "",
          "Options": "",
          "SeatCapacity": "",
          "Style": this.AddInventoryForm.controls.style.value,
          "Transmission": "",
          "EngineFuelType": this.fuelType,
          "DealerName": this.dealerNamevalue,
          "GroupName": this.GroupNamevalue,
          "DealerID": this.DealerNameFilter
        }

        //formdata
        let FormDatalist = new FormData();
        FormDatalist.append("VIN", this.AddInventoryForm.controls.vinNumber.value);
        FormDatalist.append("StockNumber", (this.AddInventoryForm.controls.stockNumber.value!=undefined?this.AddInventoryForm.controls.stockNumber.value:""));
        FormDatalist.append("CarYr", this.AddInventoryForm.controls.carYr.value);
        FormDatalist.append("Mileage", this.AddInventoryForm.controls.mileageControl.value);
        FormDatalist.append("Make", this.AddInventoryForm.controls.make.value);
        FormDatalist.append("Model", this.AddInventoryForm.controls.model.value);
        FormDatalist.append("Trim", this.AddInventoryForm.controls.trim.value);
        FormDatalist.append("Body", this.AddInventoryForm.controls.body.value);
        FormDatalist.append("Colour", this.Color);
        FormDatalist.append("VehicleInfo", "");
        FormDatalist.append("ListedPrice", this.AddInventoryForm.controls.listPrice.value);
        FormDatalist.append("DealerCost", this.AddInventoryForm.controls.cost.value);
        FormDatalist.append("Condition", this.AddInventoryForm.controls.conditon.value);

        FormDatalist.append("CBBValA", this.AddInventoryForm.controls.average.value);
        FormDatalist.append("CBBValC", this.AddInventoryForm.controls.clean.value);
        FormDatalist.append("CBBValR", this.AddInventoryForm.controls.rough.value);
        FormDatalist.append("CBBValX", this.AddInventoryForm.controls.extraClean.value);
        FormDatalist.append("DateReceivedString", this.dateRecevied.toString());
        FormDatalist.append("DriveTrain", "");


        FormDatalist.append("Options", "");
        FormDatalist.append("SeatCapacity", "");
        FormDatalist.append("Style", this.AddInventoryForm.controls.style.value);
        FormDatalist.append("Transmission", this.AddInventoryForm.controls.vinNumber.value);
        FormDatalist.append("EngineFuelType", this.fuelType);
        FormDatalist.append("DealerName", this.dealerNamevalue);
        FormDatalist.append("GroupName", this.GroupNamevalue);
        FormDatalist.append("DealerID", this.DealerNameFilter);



        //FormDatalist.append("picture",this.AddInventoryForm.controls.picture.value);

        if (this.filepicture.length > 0) {
          this.filepicture.forEach((currentValue) => {

            // currentValue.forEach((data, index)  => {



            //   FormDatalist.append('picture', data[index]);
            // });

            for (let i = 0; i < currentValue.length; i++) {
              FormDatalist.append('picture', currentValue[i]);
            }


            //  FormDatalist.append('picture', currentValue[index]);
          });
        }



        // this.manageInventoryService.addInventory(modelGroup)
        this.manageInventoryService.addInventory(FormDatalist)
          .subscribe(
            (data: any) => {
              this.textLabel = "";
              if (this.rolevalue == "SuperAdmin") {
                this.GetAllInventoryList();
              } else {
                this.GetAllInventoryListDealerName();
              }

              this.showNotification("success", "Inventory added successfully.");
              this.Createform();
              this.ResetNgModels();
              this.modalRef.hide();
              this.closeEditModel();
              this.CloseAddmodel();
            },
            error => {
            });
      }
    }
    this.loading = true;
    this.isValid = true;
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }


  // function for getting user list
  GetAllInventoryList() {
   
    this.spinner.show();
    var modelGroup = {

      "DealerId": this.SelectSearchDealervalue.toString(),
      "GroupName": this.SelectSearchgroupvalue.toString(), 
      "Opcode" : this.OpcodeValue
    }
    this.manageInventoryService.getAllInventoryList(modelGroup)
      .subscribe(
        (data: any) => {
         debugger;
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetAllInventoryListDealerName() {
    this.spinner.show();
    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue,
      "LoggedInID": this.UserIDValue,
      "Dealerslist": this.dealersmultiselectarray
    }

    this.manageInventoryService.getAllInventoryListDealerName(modelGroup)
      .subscribe(
        data => {
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });


  }

  openModalCheckModel(templateCheckVin: TemplateRef<any>) {

    this.modalRefcheck = this.confirmModalService.show(templateCheckVin, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  declineCheck(): void {
    this.modalRefcheck.hide();
  }
  GetDealerVehiclesByVin(Vin: any) {
    debugger;
    var modelGroup = {
      "VIN": Vin,
    }
    this.manageInventoryService.getDealerVehiclesByVin(modelGroup)
      .subscribe(
        (data: any) => {
         debugger;
          if (data.length > 0) {
            let el: HTMLElement = this.ConfirmBoxVINCheckClick.nativeElement;
            el.click();
          } else {
            // call of functin for getting CBB values for VIN
            this.GetDealerVehiclesCBBValuesByVin(this.VinNumber, this.MileageValue);
          }
        },
        error => {
        });
  }

  GetDealerVehiclesCBBValuesByVin(Vin: any, Mileage: any) {

    var modelGroup = {
      "VIN": Vin,
      "Mileage": Mileage,
    }
    this.manageInventoryService.getDealerVehiclesCBBValuesByVin(modelGroup)
      .subscribe(
        (data: any) => {


          if (data.checkValueAPI == "exist") {

            this.extraCleanValue = data.existadjusted_whole_xclean;
            this.cleanValue = data.existadjusted_whole_clean;
            this.averageValue = data.existadjusted_whole_avg;
            this.roughvalue = data.existadjusted_whole_rough;

            this.bodyvalue = data.existBody;
            this.trimvalue = data.existtrim;
            this.modelvalue = data.existmodel;
            this.makevalue = data.existmake;
            this.yearvalue = data.existmodel_year;
            // this.fuelType =  data.existfueltype;
          } else {
            this.extraCleanValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_xclean;
            this.cleanValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_clean;
            this.averageValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_avg;
            this.roughvalue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_rough;

            this.bodyvalue = data.used_vehicles.used_vehicle_list[0].class_name;
            this.trimvalue = data.used_vehicles.used_vehicle_list[0].series;
            this.modelvalue = data.used_vehicles.used_vehicle_list[0].model;
            this.makevalue = data.used_vehicles.used_vehicle_list[0].make;
            this.yearvalue = data.used_vehicles.used_vehicle_list[0].model_year;
            // this.fuelType =  data.used_vehicles.used_vehicle_list[0].fuel_type; //data.fuelType;
          }
        },
        error => {
        });
  }
}
