import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LenderService } from '@app/_services/lender.service';
import { AIXLenderInfo } from '@app/_models/AIXLenderInfo';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountService } from '@app/_services/account.service';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { AIXLenders } from '@app/_models/AIXLenders';
@Component({
  selector: 'app-add-lender',
  templateUrl: './add-lender.component.html',
  styleUrls: ['./add-lender.component.less']
})
export class AddLenderComponent implements OnInit {
  AddLenderForm: FormGroup;
  private notifier: NotifierService;


  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private lenderService: LenderService,
    private spinner: NgxSpinnerService,
    private accountService: AccountService,
    private modalService: NgbModal) {

    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  lenderInfo: AIXLenderInfo[] = [];
  isDesc: boolean = false;
  column: string = 'lenderName';
  name = 'Angular';
  TotalRecord: any;
  modalcloseOpen: any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  modalRef: BsModalRef;
  isValid: boolean = true;
  loading = false;
  submitted = false;
  lednerSelectedId:any;
  deleteitem: any;
  edititemid: any;
  LenderName: any;
  LenderNameFilter: any;
  PlanNameFilter: any;
  PlanType: any;
  FLTVnew: any;
  FLTV: any;
  BLTV: any;
  IsDocFee: boolean = false;
  IsDocFeeFront: any;
  IsBLTVFromListPrice: any;
  IsBltvListPrice: boolean = false;
  IsBltvListPriceValue: any;
  HasGpsFee: boolean = false;
  HasGPSFeeValue: any;
  DocFee: any;
  OtherFee: any;
  LenderFee: any;
  GPSFee: any;
  PPSA: any;
  MaxLoanAmount: any;
  MaxWarranty: any;
  MaxGap: any;
  MaxJobLoss: any;
  MaxAccessories: any;
  MaxOtherTaxables: any;
  MaxTerm: any;
  maxbltv:any;
  lenderproductData = 'ExcelSheet.xlsx';
  modalTitle = 'Add Product';
  //exportexcel(): void
  //{
  //debugger;
  /* pass here the table id */
  //let element = document.getElementById('usertable');
  //const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  /* generate workbook and add the worksheet */
  //const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  /* save to file */
  //XLSX.writeFile(wb, this.lenderproductData);

  //}
  exportexcel() {
    const prepare = [];
    this.lenderInfo.forEach(e => {
      const tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.planType);
      tempObj.push(e.fltVnew);
      tempObj.push(e.fltv);
      tempObj.push(e.bltv);
      tempObj.push(e.docFee);
      tempObj.push(e.lenderFee);
      tempObj.push(e.otherFee);
      prepare.push(tempObj);
    });

    /* Convert data to worksheet */
    const ws = XLSX.utils.aoa_to_sheet([
      ['LenderName', 'PlanType', 'fltVnew', 'fltv', 'bltv', 'docFee', 'LenderFee', 'OtherFee'],
      ...prepare
    ]);

    /* Create a new workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Product_List');

    /* Save the workbook to a file */
    XLSX.writeFile(wb, 'Product_List.xlsx');
  }
  // public openPDF(): void {
  //   debugger;
  //   let DATA: any = document.getElementById('usertable');
  //   html2canvas(DATA).then((canvas) => {
  //     let fileWidth = 208;
  //     let fileHeight = (canvas.height * fileWidth) / canvas.width;
  //     const FILEURI = canvas.toDataURL('image/png');
  //     let PDF = new jsPDF('p', 'mm', 'a4');
  //     let position = 0;
  //     PDF.addImage(FILEURI, 'PNG', 0, position, fileWidth, fileHeight);
  //     PDF.save('product-list.pdf');
  //   });
  // }

  downloadPdf() {
    var prepare = [];
    this.lenderInfo.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.planType);
      tempObj.push(e.fltVnew);
      tempObj.push(e.fltv);
      tempObj.push(e.bltv);
      tempObj.push(e.docFee);
      tempObj.push(e.lenderFee);
      tempObj.push(e.otherFee);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    // doc.autoPrint();
    autoTable(doc, {
      head: [['LenderName', 'PlanType', 'fltVnew', 'fltv', 'bltv', 'docFee', 'LenderFee', 'OtherFee']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Product_List' + '.pdf');
  }

  printTable() {
    var prepare = [];
    this.lenderInfo.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.planType);
      tempObj.push(e.fltVnew);
      tempObj.push(e.fltv);
      tempObj.push(e.bltv);
      tempObj.push(e.docFee);
      tempObj.push(e.isDocFeeFront);
      tempObj.push(e.lenderFee);
      tempObj.push(e.otherFee);
      tempObj.push(e.ppsa);
      tempObj.push(e.isBLTVfromListPrice);
      tempObj.push(e.hasGPSFee);
      tempObj.push(e.gpsFee);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>LenderName</th><th>PlanType</th><th>fltVnew</th><th>fltv</th><th>bltv</th><th>docFee</th><th>Is Doc Fee Front</th><th>LenderFee</th><th>Reserves</th><th>PPSA</th><th>Is BLTV List Price</th><th>Has GPS</th><th>GPS Fee</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

   lenderlists: AIXLenders[] = [];
   lenderlistsFilter: AIXLenders[] = [];

   lenderPlanlists= [];
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.lenderInfo.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.lednerSelectedId ="0";
    this.LenderNameFilter ="0";
    this.PlanNameFilter="0";
    this.GetlenderList();
    this.GetlendersNamelist();
    this.GetlendersNameFilterlist();
    this.IsDocFee = false;
    this.IsBltvListPrice= false;
    this.HasGpsFee= false;
    this.FLTVnew=0;
    this.FLTV=0;
    this.BLTV=0;
    this.DocFee=0;
    this.GPSFee=0;
    this.OtherFee=0;
    this.LenderFee=0;
    this.PPSA=0;
    this.MaxLoanAmount=0;
    this.MaxWarranty=0;
    this.MaxGap=0;
    this.MaxJobLoss=0;
    this.MaxAccessories=0;
    this.MaxOtherTaxables=0;
    this.MaxTerm=0;
    this.maxbltv=0;

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    //this.IsBltvListPrice=true;
  }
  get f() { return this.AddLenderForm.controls; }
  Createform() {
    this.AddLenderForm = new FormGroup({
      LenderName: this.fb.control('', Validators.required),
      planType: this.fb.control('', Validators.required),
      fltVnew: this.fb.control('', Validators.required),
      fltv: this.fb.control('', Validators.required),
      bltv: this.fb.control('', Validators.required),
      docFeeControl: this.fb.control(''),
      bltvControl: this.fb.control(''),
      hasGps: this.fb.control(''),
      //docFee: this.fb.control('', Validators.required),
      docFee: this.fb.control('', [Validators.required, Validators.pattern(/^\d{1,4}(\.\d{1,2})?$/)]),
      otherFee: this.fb.control('', Validators.required),
      lenderFee: this.fb.control('', Validators.required),
      gpsfee: this.fb.control('', Validators.required),
      ppsa: this.fb.control('', Validators.required),
      maxloanamount: this.fb.control(''),
      maxwarranty: this.fb.control(''),
      maxgap: this.fb.control(''),
      maxjobloss: this.fb.control(''),
      maxaccessories: this.fb.control(''),
      maxothertaxable: this.fb.control(''),
      maxterm: this.fb.control(''),
      maxbltv: this.fb.control(''),
      LenderNameFilter: this.fb.control(''),
      PlanNameFilter: this.fb.control(''),
    });
  }


  CheckLoggedActivity(values: any): void {
    console.log(values.currentTarget.checked);
    this.IsDocFee = values.currentTarget.checked;
  }


  // function for confirm  delete
  confirm(): void {
    this.deleteitem;
    var modelGroup = {
      "Id": this.deleteitem
    }
    this.lenderService.DeletelenderProduct(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted successfully");
          this.GetlenderList();
        },
        error => {
        });

    this.modalRef.hide();

  }

  decline(): void {
    this.modalRef.hide();
  }

  onSubmit() {
    if (this.edititemid != undefined && this.edititemid != 0) {
      if (this.IsBltvListPrice == true) {
        this.IsBltvListPriceValue = 1;
      } else {
        this.IsBltvListPriceValue = 0
      }

      if (this.HasGpsFee == true) {
        this.HasGPSFeeValue = 1;
      } else {
        this.HasGPSFeeValue = 0
      }

      var modelGroup1 = {
        "id": this.edititemid,
        "LenderName": this.AddLenderForm.controls.LenderName.value.toString(),
        "PlanType": this.AddLenderForm.controls.planType.value,
        "FLTVnew": this.AddLenderForm.controls.fltVnew.value,
        "FLTV": this.AddLenderForm.controls.fltv.value,
        "BLTV": this.AddLenderForm.controls.bltv.value,
        "IsDocFeeFront": this.IsDocFee,
        "IsBLTVFromListPrice": this.IsBltvListPriceValue,
        "HasGpsFee": this.HasGPSFeeValue,
        "DocFee": this.AddLenderForm.controls.docFee.value,
        "OtherFee": this.AddLenderForm.controls.otherFee.value,
        "LenderFee": this.AddLenderForm.controls.lenderFee.value,
        "GPSFee": this.AddLenderForm.controls.gpsfee.value,
        "PPSA": this.AddLenderForm.controls.ppsa.value,
        "MaxLoanAmount":this.MaxLoanAmount, //this.AddLenderForm.controls.maxloanamount.value,
        "MaxWarranty": this.MaxWarranty,//this.AddLenderForm.controls.maxwarranty.value,
        "MaxGap": this.MaxGap,//this.AddLenderForm.controls.maxgap.value,
        "MaxJobLoss": this.MaxJobLoss,//this.AddLenderForm.controls.maxjobloss.value,
        "MaxAccessories": this.MaxAccessories,//this.AddLenderForm.controls.maxaccessories.value,
        "MaxOtherTaxables": this.MaxOtherTaxables,//this.AddLenderForm.controls.maxothertaxable.value,
        "MaxTerm": this.MaxTerm,//this.AddLenderForm.controls.maxterm.value,
        "InternalID": parseInt(this.AddLenderForm.controls.LenderName.value), 
        "MaxBLTV":this.maxbltv
      }

      // Check if the form is invalid
      if (this.AddLenderForm.invalid) {
        this.submitted = true; // Set submitted to true to display the validation messages
        return;
      }

      this.lenderService.UpdateLenderProduct(modelGroup1)
        .subscribe(
          (data: any) => {
            this.GetlenderList();
            this.submitted = false;
            this.showNotification("success", "Lender Product update successfully.");
            this.Createform();
            this.ReInitateModels();
            this.modalRef.hide();

            this.closeEditModel();
          },
          error => {
          });

    } else {
      this.submitted = true; // Set submitted to true to display the validation messages
      if (this.AddLenderForm.invalid) {
        this.isValid = false;
        return;
      } else {
        if (this.IsBltvListPrice == true) {
          this.IsBltvListPriceValue = 1;
        } else {
          this.IsBltvListPriceValue = 0
        }
        if (this.HasGpsFee == true) {
          this.HasGPSFeeValue = 1;
        } else {
          this.HasGPSFeeValue = 0
        }
        var modelGroup = {
          "LenderName": this.AddLenderForm.controls.LenderName.value.toString(),
          "PlanType": this.AddLenderForm.controls.planType.value,
          "FLTVnew": this.AddLenderForm.controls.fltVnew.value,
          "FLTV": this.AddLenderForm.controls.fltv.value,
          "BLTV": this.AddLenderForm.controls.bltv.value,
          "IsDocFeeFront": this.IsDocFee,
          "IsBLTVFromListPrice": this.IsBltvListPriceValue,
          "HasGpsFee": this.HasGPSFeeValue,
          "DocFee": this.AddLenderForm.controls.docFee.value,
          "OtherFee": this.AddLenderForm.controls.otherFee.value,
          "LenderFee": this.AddLenderForm.controls.lenderFee.value,
          "GPSFee": this.AddLenderForm.controls.gpsfee.value,
          "PPSA": this.AddLenderForm.controls.ppsa.value,
          "MaxLoanAmount": this.AddLenderForm.controls.maxloanamount.value,
          "MaxWarranty": this.AddLenderForm.controls.maxwarranty.value,
          "MaxGap": this.AddLenderForm.controls.maxgap.value,
          "MaxJobLoss": this.AddLenderForm.controls.maxjobloss.value,
          "MaxAccessories": this.AddLenderForm.controls.maxaccessories.value,
          "MaxOtherTaxables": this.AddLenderForm.controls.maxothertaxable.value,
          "MaxTerm": this.AddLenderForm.controls.maxterm.value,
          "InternalID": parseInt(this.AddLenderForm.controls.LenderName.value) ,
          "MaxBLTV":this.AddLenderForm.controls.maxbltv.value,
        }

        this.lenderService.SaveLenderProduct(modelGroup)
          .subscribe(
            (data: any) => {
              this.GetlenderList();
              this.submitted = false;
              this.showNotification("success", "Lender Product saved successfully.");
              this.Createform();
              this.ReInitateModels();
              this.modalRef.hide();
              this.edititemid="";

              this.CloseAddmodel();
            },
            error => {
            });
      }
      this.loading = true;
      this.isValid = true;
    }
  }

  openModal(template: TemplateRef<any>, itemid: any) {
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  closeEditModel() {
    this.modalRef.hide();
    this.edititemid="";
  }

  GetlendersNamelist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          this.lenderlists = data;
        },
        error => {
        });
  }

  GetLenderRateSheetbyLenderName() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": "0",
      "LenderID":this.LenderNameFilter,      
    }

    this.lenderService.getLenderPlans(modelGroup)
      .subscribe(
        data => {
          this.lenderPlanlists=data;
        },
        error => {
        });

  }

  GetlendersNameFilterlist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          this.lenderlistsFilter = data;
        },
        error => {
        });
  }

  ReInitateModels() {
    this.LenderName = "";
    this.PlanType = "";
    this.FLTVnew = "0";
    this.FLTV = "0";
    this.BLTV = "0";
    this.IsDocFee = false;
    this.IsBltvListPrice= false;
    this.HasGpsFee= false;
    
    this.IsDocFeeFront = "";
    this.IsBLTVFromListPrice = "";
    this.DocFee = "0";
    this.OtherFee = "0";
    this.LenderFee = "0";
    this.GPSFee = "0";
    this.PPSA = "0";
    this.MaxLoanAmount = "0";
    this.MaxWarranty = "0";
    this.MaxGap = "0";
    this.MaxJobLoss = "0";
    this.MaxAccessories = "0";
    this.MaxOtherTaxables = "0";
    this.MaxTerm = "0"
    this.maxbltv="0";
  }
  EditModalpopup(template: TemplateRef<any>, itemid: any) {
    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.lenderService.EditLenderProduct(modelGroup)
      .subscribe(
        data => {
          
          // alert( data[0].condition1Till);
        //  this.LenderName = data[0].lenderName;
        this.LenderName = data[0].internalID;
        
          this.PlanType = data[0].planType;

          this.FLTVnew = data[0].fltVnew;
          this.FLTV = data[0].fltv;
          this.BLTV = data[0].bltv;
          this.IsDocFeeFront = data[0].isDocFeeFront;

          if (data[0].isDocFeeFront == 0) {
            this.IsDocFee = false;
          } else {
            this.IsDocFee = true;
          }

          if (data[0].isBLTVfromListPrice == 0) {
            this.IsBltvListPrice = false;
          } else {
            this.IsBltvListPrice = true;
          }
          if (data[0].hasGPSFee == 0) {
            this.HasGpsFee = false;
          } else {
            this.HasGpsFee = true;
          }
          // this.IsBLTVFromListPrice=data[0].isBLTVfromListPrice; 
          this.DocFee = data[0].docFee;
          this.OtherFee = data[0].otherFee;
          this.LenderFee = data[0].lenderFee;
          this.GPSFee = data[0].gpsFee;
          this.PPSA = data[0].ppsa;
          this.MaxLoanAmount = data[0].maxLoanAmount;
          this.MaxWarranty = data[0].maxWarranty;
          this.MaxGap = data[0].maxGAP;
          this.MaxJobLoss = data[0].maxJobLoss;
          this.MaxAccessories = data[0].maxAccessories;
          this.MaxOtherTaxables = data[0].maxOtherTaxables;
          this.MaxTerm = data[0].maxTerm;
          this.maxbltv = data[0].maxBLTV;
          // Set the modal title to "Edit Global Data"
          this.modalTitle = 'Edit Product';
          this.modalRef = this.confirmModalService.show(template, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });

        },
        error => {
        });
  }

  restrictInput(event: any) {
    let value = event.target.value;
    const regex = /^\d{0,4}(\.\d{0,2})?$/;  
  
    if (!regex.test(value)) {
      value = value.slice(0, -1);
      event.target.value = value;
    }
  }
  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    
  }
  
  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  CloseAddmodel() {
    this.edititemid="";
    this.modalRef.hide();
    this.submitted = false;
    // this.Createform();
    this.ReInitateModels();

  }
  openAddLenderModel(targetModal) {
    this.modalTitle = 'Add Product';
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });

  }
  FilterLenderList()
  {
   var check= this.LenderNameFilter ;
   this.GetlenderList();
   this.GetLenderRateSheetbyLenderName();
  }

  FilterPLanList()
  {
   //var check= this.LenderNameFilter ;
   this.GetlenderList();
  // this.GetLenderRateSheetbyLenderName();
  }
  ResetFilter()
  {
    this.LenderNameFilter ="0";
    this.PlanNameFilter="0";
    this.GetlenderList();
  }
  GetlenderList() {
    var modelGroup = {
      "id": this.LenderNameFilter,
      "PlanType": this.PlanNameFilter
    }

    this.spinner.show();
    this.lenderService.getLenderlist(modelGroup)
      .subscribe(
        (data: any) => {
          this.lenderInfo = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }
}
