<section id="admindashboard p-0" class="carvideosection">
        <div class="carvideo-header">
            <div class="container-fluid">
                <div class="page-header">
                    <div class="row">
                        <div class="col">
                            <h2 class="text-white">
                               SOFTWARE DETAILS
                            </h2>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
                            <a class="btn btn-md btn-primary mt-3">View More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="carvideo-body">
            <div class="car-details-info">

                <div class="w-100 mb-3">
                    <div class="card card-raised h-100 w-100">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                              <div class="col">
                                <marquee class="carinfo"> Premium Pre-Owned Cars. Own a Car With Value, Clear History, and Peace of Mind. One-Price Shopping With All Patrick Premium Vehicles.</marquee>
                               </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <div class="container-fluid">
                                <div class="row mt-2">
                                    <div class="col-lg-6 col-md-12 col-12">
                                       <div class="card card-raised h-100 w-100">
                                        <div class="card-header card-raised bg-info text-white">
                                             <h6 class="mb-0">Training Details</h6>
                                        </div>
                                        <div class="card-body bg-white">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <p>It is a long established fact that a reader will be distracted by the
                                                         readable content of a page when looking at its layout. The point of
                                                         using Lorem Ipsum is that it has a more-or-less normal distribution of
                                                         letters, as opposed to using 'Content here, content here',
                                                        making it look like readable English</p>
                                                        <p>There are many variations of passages of Lorem Ipsum available,
                                                            but the majority have suffered
                                                            alteration in some form, by injected humour,</p>
                                                </div>
                                            </div>
                                        </div>
                                       </div>
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-12">
                                        <div class="card">
                                            <!-- <div class="card-header card-raised bg-info text-white">
                                                <h6 class="mb-0">Car Model</h6>
                                            </div> -->
                                            <div class="card-body p-3 video-frame">
                                                <div class="inner-container">
                                                    <!-- <video id="player" controls src="https://v2demo.aithrx.com/assets/images/sample.mp4" autoplay loop></video> -->
                                                    <video id="player" controls src="../src/assets/videos/trial.mp4" autoplay loop></video>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</section>