
<section id="admindashboard">
    <form class="col-12 px-0"  [formGroup]="CommuincationForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row mx-0 justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn px-0">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="copytable('usertable')">Copy</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="form-group d-flex mb-sm-0 mb-0 col-12 col-sm-7 col-md-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3 mt-2 mt-md-0">
                                    <label style="padding:2px;">Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">{{ size }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 px-0">
                        <div class="card card-raised h-100 mb-0">
                            <div class="card-header card-raised bg-info text-white">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-sm-6">
                                        <h5 class="card-title text-white text-uppercase mb-0">Inquiry Log</h5>
                                    </div>
                                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                        <h6 class="mb-0"><b>Total Records:</b>  {{Totalrecords}} </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-4">
                                <div class="table-responsive">
                                    <table id="usertable" class="table table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <!-- <th>&nbsp; </th> -->
                                                <th (click)="sortData('sendDate')">Send Date<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('dealerName')">Selling Dealer<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('loggedDealerName')">Buying Dealer<i class="fa fa-fw fa-sort"></i></th>                                               
                                                <th (click)="sortData('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('vin')">Vehicle Information<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('sendUserName')">Send By<i class="fa fa-fw fa-sort"></i></th>
                                                <!-- <th (click)="sortData('ipaddress')">IP Address<i class="fa fa-fw fa-sort"></i></th> -->
                                                <th (click)="sortData('buttonType')">Type<i class="fa fa-fw fa-sort"></i></th>
                                                <th >Comments</th>
                                                <th>Status</th>
                                                 <th ></th>
                                                 <!-- <th >User Name</th> -->
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let emaillogdata of emailloglist | filter:searchText | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                                                
                                                
                                                <td>{{ emaillogdata.sendDate | date }}</td>
                                                <td>{{ emaillogdata.dealerName }}</td>
                                                <td>{{ emaillogdata.loggedDealerName }}</td>
                                                <td>{{ emaillogdata.vin }}</td>
                                                <td><b>StockNumber :</b> {{ emaillogdata.stockNumber }} <b>Year : </b> {{ emaillogdata.carYr }} <b>Make:</b>  {{ emaillogdata.make }}
                                                   <b> Model:</b> {{ emaillogdata.model }}  <b>Trim:</b>{{ emaillogdata.trim }}</td>
                                               
                                                <td>{{ emaillogdata.sendUserName }}</td>
                                                <!-- <td>{{ emaillogdata.ipaddress }}</td> -->
                                                <td style="min-width:90px;">{{ emaillogdata.buttonType }}</td>
                                                <td>{{ emaillogdata.comments }}</td>

                                            
                                              <td style="text-align: center;">
                                                <button *ngIf="emaillogdata.isResponse===false" title="Not Responded " class="btn bg-gradient-danger btn-sm mb-2 mb-md-0">
                                                    <i class="fas fa-times"></i>
                                                   
                                                </button>

                                                <button *ngIf="emaillogdata.isResponse===true" title="Responded" class="btn bg-gradient-success btn-sm">
                                                    <i class="fa fa-check" style="font-size: 10px; color: #fff; vertical-align: middle; width: 9px;"></i>
                                                </button>
                                              </td>
                                                
                                                <td><button class="btn btn-sold btn-warning btn-sm" title="Mark Sold"
                                                    (click)="setradioMarkUnits('Mark as Sold',emaillogdata)">
                                                    <span class="icon"><i class="far fa-paper-plane"></i></span>
                                                </button></td>
                                                
                                                <!-- <td>{{ emaillogdata.responseDateTime | date:'medium' }}</td>
                                                <td>{{ emaillogdata.firstName }}</td> -->
                                            </tr>
                                        </tbody>
                                    </table>

                                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                                        <p style="color: white">Loading...</p>
                                    </ngx-spinner>

                                    <br>

                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-12 col-sm-auto">
                                            <ngb-pagination [collectionSize]="Totalrecords" [(page)]="currentPage" [pageSize]="itemsPerPage" [maxSize]="5" [boundaryLinks]="true" [ellipses]="false" [rotate]="true"></ngb-pagination>
                                        </div>
                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                                            <div class="form-group mb-0 mr-1">
                                                <label style="padding:2px;">Items per Page:</label>
                                                <select (change)="handlePageSizeChange($event)">
                                                    <option *ngFor="let size of pageSizes" [ngValue]="size">{{ size }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <a #ConfirmMarkSoldBox style="display: none;" [routerLink]="" (click)="openModalMarkUnitAsSold(templateSold)"><i
            class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Sold"
            placement="top" ngbPopover="Would you like to Mark Selected Unit as SOLD?"></i></a>
        <ng-template #templateSold>
          <div class="modal-body text-center">
            <span class="cross-icon"><i class="far fa-times-circle"></i></span>
            <p>Would you like to Mark Selected Unit as SOLD?</p>
            <p></p>
            <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkSold()">Yes</button>
            <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
          </div>
        </ng-template>

    </form>
</section>
