import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListDealersService } from '@app/_services/list-dealers.service';
import { DealersList } from '@app/_models/DealersList';
@Component({
  selector: 'app-notification-dashboard',
  templateUrl: './notification-dashboard.component.html',
  styleUrls: ['./notification-dashboard.component.less']
})
export class NotificationDashboardComponent implements OnInit {
  clientDbForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder, private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService, 
    private modalService: NgbModal,private addgroupService: AddgroupService,
    private confirmModalService: BsModalService,
    private datePipe: DatePipe,
    notifier: NotifierService,private spinner: NgxSpinnerService,
    private listDealersService: ListDealersService,) {
      this.notifier = notifier;
     }
  opcode:any;
  DealerNameFilter:any;
  opcodePicture:any;
  notiidvalue:any;
  opcodebuild:any;
  opcodeMAximized:any;
  opcodeAttention:any;
  DealerList=[];
  TotalInvest:any;
  V3BuildCount:any;
   V3MaximizedCount:any;
  TotalInventory:any;

    TotalOfferCount:any;
    o1Heading :any;
    o1Heading1 :any;

    o1Heading2 :any;

    o1Value1:any;

    o1Value2:any;

    o2 :any;

    o2Heading :any;
    o2Heading1 :any;
    o2Heading2:any;
    o2Value1 :any;
    o2Value2:any;

    o1TotalValue:any;
    o1HeadingMain:any;
    o2HeadingMain:any;
    o2TotalValue:any;




  dealerVehicleslist: DealerVehicles[] = [];
  dealerPicturelist: DealerVehicles[] = [];
  TotalRecordVerify:any;
  dealerVerifylist: DealerVehicles[] = [];

  dealerBuildlist: DealerVehicles[] = [];
  dealerMaximized: DealerVehicles[] = [];
  dealerAttention: DealerVehicles[] = [];



TotalRecordBuild:any;


TotalRecordmaximized:any;

TotalRecordAttention:any;

  TopResult:any;
  UnitsWithoutCost:any;
  UsedUnits:any;
  NewUnits:any;
  TotalRecord:any;
  ResponseNotes :any;
  LoggedUserName:any;
  UseridValue:any;
  CurrentDate:any;
  VINValue:any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];

  pagePicture = 1;
  pageSizePicture = 10;
  pageSizesPicture = [10, 20, 30, 40, 50, 60, 70];


  pageVerify = 1;
  pageSizeVerify = 10;
  pageSizesVerify = [10, 20, 30, 40, 50, 60, 70];


  pageAttention = 1;
  pageSizeAttention = 10;
  pageSizesAttention = [10, 20, 30, 40, 50, 60, 70];

  pagebuild = 1;
  pageSizebuild  = 10;
  pageSizesbuild  = [10, 20, 30, 40, 50, 60, 70];


  pageMaximized = 1;
  pageSizeMaximized  = 10;
  pageSizesMaximized  = [10, 20, 30, 40, 50, 60, 70];


  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  TotalRecordPicture:any;
  searchText:any;
  isDesc: boolean = false;
  column: string = 'vin';
  PageTitle:any;
  opcodeVerify:any;
  modalRef: BsModalRef;

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];


  pieChartOptionsbuild: ChartOptions;
  pieChartLabelsbuild: Label[];
  pieChartDatabuild: SingleDataSet;
  pieChartTypebuild: ChartType;
  pieChartLegendbuild: boolean;
  pieChartPluginsbuild = [];

  pieChartOptionsAttention: ChartOptions;
  pieChartLabelsAttention: Label[];
  pieChartDataAttention: SingleDataSet;
  pieChartTypeAttention: ChartType;
  pieChartLegendAttention: boolean;
  pieChartPluginsAttention = [];

  pieChartOptionsInvest: ChartOptions;
  pieChartLabelsInvest: Label[];
  pieChartDataInvest: SingleDataSet;
  pieChartTypeInvest: ChartType;
  pieChartLegendInvest: boolean;
  pieChartPluginsInvest = [];


          
  OfferNewCount:any;
  OfferwaitingCount:any;


  TotalMeOfferCount:any;
  
  OfferMeNewCount:any;
  OfferMewaitingCount:any;


  colorsInvest: Color[] = [
    {
      backgroundColor: [
        '#FC766A',
        '#783937',
        'blue'
      ]
    }
  ];

  colorsSearch: Color[] = [
    {
      backgroundColor: [
        '#8A307F',
        '#79A7D3',
        'blue'
      ]
    }
  ];

  colorsInventory: Color[] = [
    {
      backgroundColor: [
        
        '#00539C',
        '#EEA47F',
        'blue'
      ]
    }
  ];

  V4UnitattntionCount:any;
  Flagged:any;
  Nocbb:any;
  Totalcost:any;



//for chart start investment

public barChartOptions: ChartOptions = {
  responsive: true,
  scales: {
    xAxes: [{}],
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          callback: function(label, index, labels) {
            return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
        }
      }
    ]
  },
  tooltips: {
    enabled: true,
    intersect: true,
    titleFontSize: 20,
    callbacks: {
      label: function(tooltipItem, data) {
        var dataset = data.datasets[tooltipItem.datasetIndex];
        var currentValue = dataset.data[tooltipItem.index];
        return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    }
  },
  plugins: {
    datalabels: {
      anchor: "end",
      align: "end"
    }
  }
};
public barChartLabels: Label[];
public barChartType: ChartType = "bar";
public barChartLegend = true;
public barChartPlugins = [];
public barChartData: ChartDataSets[];
modalcloseOpen: any;

BindInvestmentChart()
{
  this.barChartData = [
    {
      data: [this.Totalcost, 0, 0],
      label: "Cost",
      backgroundColor: "green"
    },
    {
      data: [0, this.TotalInvest, 0],
      label: "Investment",
      backgroundColor: "blue"
    }

  ];

  this.barChartLabels = ["Cost", "Investment"];

}

//investment chart end
ColorTheme:any;
  ngOnInit(): void {

    
this.BindInvestmentChart();

   this.Createform();

   var currentd=new Date();
    this.CurrentDate = this.transformDate(currentd);
    this.UsedUnits=0;
    this.NewUnits=0;
    this.LoggedUserName = localStorage.getItem('UserName');
    this.UseridValue = localStorage.getItem('userid');
   this.rolevalue = localStorage.getItem('UserFullRole');
   this.dealerNamevalue = localStorage.getItem('DealerName');
   this.GroupNamevalue = localStorage.getItem('GroupName');
   this.DealerIdvalue = localStorage.getItem('DealerId');
   this.DealerNameFilter =this.DealerIdvalue;
   this.ColorTheme = localStorage.getItem('colorTheme');
   var element = document.body;
   if (this.ColorTheme === "White") {
     element.classList.remove("dark-mode");
   } else {

     element.classList.add("dark-mode");
   }
    if (this.rolevalue == "SuperAdmin") {
      this.GetDealerNameFilterlist();
    } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
      this.GetDealersByDealerName();
    }

    


    this.TotalOfferCount=0;
          
    this.OfferNewCount=0;
    this.OfferwaitingCount=0;
  
  
    this.TotalMeOfferCount=0;
    
    this.OfferMeNewCount=0;
    this.OfferMewaitingCount=0;

    this.clientDbForm.controls.DealerNameFilter.setValue(this.DealerIdvalue);
    this.opcode="1";
    this.opcodePicture="2";
   

    this.GetAIXAithrhubClientDB();
    this.GetClientDashboardNotificationList();
   //this.GetClientDashboardOfferToMe();
  

    this.TotalInvest=0;
    this.Totalcost=0;
    this.TotalInventory=0;
    this.TopResult=0;
    this.UnitsWithoutCost=0;

    this.V3BuildCount=0;
    this.V3MaximizedCount=0;
    this.GeneratePiChart();
    this.GenerateInvestPiChart()
  }

  GetDealersByDealerName() {

    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.listDealersService.getDealersByDealerName(modelGroup)
      .subscribe(
        data => {

          this.DealerList = data;
          
        },
        error => {
        });


  }
  GenerateFlagReportList() {
  }

  openMakeOfferModelPopUp(targetModal, VIN,offer:any) {
  
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  GeneratePiChart()
  {
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = [this.o2Heading1, this.o2Heading2];
    this.pieChartData = [this.o2Value1 , this.o2Value2];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
  }
 
  GenerateInvestPiChart()
  {
    this.pieChartOptionsInvest = this.createOptions();
    this.pieChartLabelsInvest =  [this.o1Heading1, this.o1Heading2];
    this.pieChartDataInvest = [this.o1Value1 , this.o1Value2];
    this.pieChartTypeInvest = 'pie';
    this.pieChartLegendInvest = true;
  }


  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  sortAttention(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  Createform() {
    this.clientDbForm = new FormGroup({
      DealerNameFilter: this.fb.control('', Validators.required),
      CurrentDate: this.fb.control('', Validators.required),
      LoggedUserName: this.fb.control('', Validators.required),
      ResponseNotes: this.fb.control('', Validators.required),
    });
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.GetClientDashboardNotificationList();
  }

 

 

  handlePageSizeChangePicture(event) {
    this.pageSizePicture = event.target.value;
    this.pagePicture = 1;
    this.GetClientDashboardOfferToMe();
  }



  Getdetails(event: any) {
    var lendervalue = event.target.value;
    this.DealerNameFilter = lendervalue;
    this.opcode="1";
    this.opcodePicture="2";
   

    this.GetAIXAithrhubClientDB();
    this.GetClientDashboardNotificationList();
  //  this.GetClientDashboardOfferToMe();
    

  



  }

 


  
 


  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }
  decline(): void {
    this.modalRef.hide();
  }
  OpenModel(template: TemplateRef<any>,vin:any,btn:any) {

    this.VINValue = vin;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  SaveReponseDetail() {
    debugger;
    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName":this.LoggedUserName,
      "Notes":this.ResponseNotes,
      "VIN": this.VINValue.toString(),
      "DealerId":this.DealerIdvalue.toString(),

    }

    this.manageInventoryService.sendDetailClientDashbaord(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes ="";
          this.showNotification("success", "Response send successfully");
          this.decline();
        },
        error => {
        });


  }
  SendResponseNew(notiid : any)
  {
    debugger;
    this.notiidvalue=notiid;
    this.ChangeNotificationStatus();
  }

  ChangeNotificationStatus() {
    var modelGroup = {
      "PrimeId": this.notiidvalue,
      "LoggedInUserID": this.UseridValue
    }
    this.manageInventoryService.updateNotificationStatus(modelGroup)
      .subscribe(
        (data: any) => {     
          this.showNotification("success", "Thanks for response.");    
          this.GetClientDashboardNotificationList();
        },
        error => {
        });
  }

  GetClientDashboardNotificationList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getNotificationsDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetClientDashboardOfferToMe() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodePicture,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getOfferDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerPicturelist = data;
          this.TotalRecordPicture = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetAIXAithrhubClientDB() {
    var modelGroup = {
      "Opcode": "0",
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getAIXAithrhubNotificationDBDetails(modelGroup)
      .subscribe(
        (data: any) => {
         debugger;
          this.o1TotalValue=data[0].o1TotalValue;
          this.o1HeadingMain= data[0].o1HeadingMain;

          this.o1Heading1 = data[0].o1Heading1;
          this.o1Value1 =  data[0].o1Value1;

          this.o1Heading2 = data[0].o1Heading2;
          this.o1Value2 =  data[0].o1Value2;
         

          this.o2HeadingMain=  data[0].o2HeadingMain;
          this.o2TotalValue=  data[0].o2TotalValue;

          this.o2Heading1=  data[0].o2Heading1;
          this.o2Value1=  data[0].o2Value1;

          this.o2Heading2=  data[0].o2Heading2;
          this.o2Value2=  data[0].o2Value2;
          

          

          
 
          this.GeneratePiChart();
       
          this.GenerateInvestPiChart()
          this.BindInvestmentChart();

        },
        error => {
        });
  }

  GetDealerNameFilterlist() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getDealerNameClientDB(modelGroup)
      .subscribe(
        (data: any) => {

          this.DealerList = data;
        },
        error => {
        });
  }
}
