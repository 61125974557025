<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="CreditApiLogForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="copytable('usertable')">Copy</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="exportexcel()">Excel</button>
                                </li>
                                <!-- <li><button class="btn btn-secondary btn-sm mb-sm-0 "
                                        (click)="exportToCSV()">CSV</button></li> -->
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill">Print</button>
                                </li>
                            </ul>
                        </div>



                        <!-- <div class="col-md-auto col-sm-12">
                            <div class="col-auto unitsOption">
                                <div class="form-row">
                                    <label class="mr-1" *ngFor="let radiobutton of radioItems">
                                        <input type="radio" name="options" (click)="setradio(radiobutton)"
                                            [checked]="radiobutton ==='ALL'"> <b>{{radiobutton}}</b>
                                    </label>
                                </div>
                            </div>
                        </div> -->

                    </div>
                    <hr />
                    <div class="row justify-content-between align-items-start mt-2">
                        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 ">
                            <div class="row">
                                <label class="col-form-label col-auto">Select Region</label>
                                <div class="input-group dealername col">
                                    <ng-select style="width:fit-content;" [items]="regionlistName"
                                        bindLabel="globalCodeName" [dropdownPosition]="true"
                                        [ngModelOptions]="{standalone: true}" placeholder="Select Province Name"
                                        appendTo="body" (change)="ClearRegion($event)" (click)="CheckRegionClick()"
                                        [multiple]="true" [(ngModel)]="regionmultiselectarray">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                        <div class="search-heros col-sm-12 col-lg-6 col-md-12 mt-2 mt-lg-0">
                            <div class="row align-items-center justify-content-md-end mx-0">
                                <div class="form-group d-md-flex mb-0">
                                    <label class="col-form-label col-lg-auto px-md-1">List Price less than:</label>
                                    <div class="col-lg-auto">

                                        <input type="text" formControlName="UnitFilterName" [(ngModel)]="UnitFilterName"
                                            class="form-control" (blur)="FilterUnits($event.target.value)"
                                            placeholder="0" />



                                    </div>
                                </div>
                                <div class="form-group d-md-flex mb-1">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <!-- <div class="form-group mb-0 ml-sm-3">
                                    <label style="padding:2px;"> Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    </select>
                                </div> -->
                            </div>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">credit API Log
                                            </h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <div class="d-inline-block mr-4">
                                                <label class="mr-1" *ngFor="let radioListbutton of radioCurrencyItems">
                                                    <input type="radio" class="radiopoint" name="optionsCheck"
                                                        (click)="setCurrenyradio(radioListbutton)"
                                                        [checked]="radioListbutton ==='CAD'">
                                                    <b>${{radioListbutton}}</b>
                                                </label>
                                            </div>
                                            <!-- <button style="position:relative;top:0;"
                                                *ngIf="rolevalue==='SuperAdmin' || rolevalue==='DealerAdmin' "
                                                type="button" (click)="GenerateFlagReportList()"
                                                class="btn btn-warning btn-sm text-white flagbtn">
                                                Get Flag Report
                                            </button> -->
                                            <h6 class="mb-0 d-inline-block ml-3"><b>Total Records :</b> {{TotalRecord}}
                                            </h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th>

                                                    </th>

                                                    <th style="text-align: center;">
                                                        Flagged </th>
                                                    <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('province')"> Province <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th (click)="sort('stockNumber')"> Stock No<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('carYr')">Year <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('mileage')">Mileage <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('make')">Make <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('model')">Model<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('body')">Body<i class="fa fa-fw fa-sort"></i></th>

                                                    <th style="text-align: right;" (click)="sort('listedPrice')">Price
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th style="text-align: right;" (click)="sort('discountedAmount')">
                                                        Discounted Amount
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: right;" (click)="sort('discountAmount')">
                                                        Discount
                                                        <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('colour')">Colour<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('engineFuelType')">Fuel Type<i
                                                            class="fa fa-fw fa-sort"></i></th>


                                                    <th style="text-align: right;" (click)="sort('cbbValA')">Average
                                                        CBB<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: right;" (click)="sort('deltaValue')">Delta<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: center;" (click)="sort('excited')">
                                                        Status</th>
                                                    <!-- <th style="min-width:90px;">flag Date</th> -->



                                                    <th style="text-align:center;">
                                                        Actions
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                    <td style="text-align:center;">
                                                          <input type="radio" id="{{dealerVehicleslist.id}}"
                                                            name="fav_language" value="{{dealerVehicleslist.id}}"
                                                            (click)="GetAPIDetails(dealerVehicleslistdata);">
                                                    </td>

                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==0 && rolevalue!=='SuperAdmin'">

                                                    </td>
                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==1">
                                                        <div class="greenflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>
                                                    <td style="text-align:center;"
                                                        *ngIf="dealerVehicleslistdata.isFlaged==99">
                                                        <div class="redflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==50">
                                                        <div class="orangeflag flagIcon">
                                                            <i class="fa fa-check"></i>
                                                        </div>
                                                    </td>

                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==1"
                                                        style="text-align: center;">


                                                        <a class="greenflag flagIcon" title="View Flag Report"
                                                            #BuildNewButton target="_blank"
                                                            routerLink="/flag-report/{{dealerVehicleslistdata.vin}}">
                                                            <i class="fa-solid fa-flag"></i></a>

                                                    </td>
                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==0"
                                                        style="text-align: center;">
                                                        <div class="graynflag flagIcon">
                                                            <i class="fa-solid fa-flag"></i>
                                                        </div>
                                                    </td>


                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==99"
                                                        style="text-align: center;">
                                                        <div class="redflag flagIcon">
                                                            <a class="redflag flagIcon" title="View Flag Report"
                                                                #BuildNewButton target="_blank"
                                                                routerLink="/flag-report/{{dealerVehicleslistdata.vin}}">
                                                                <i class="fa-solid fa-flag"></i></a>
                                                            <!-- <i class="fa-solid fa-flag"></i> -->
                                                        </div>
                                                    </td>

                                                    <td *ngIf="dealerVehicleslistdata.isFlaged==50"
                                                        style="text-align: center;">
                                                        <div class="orangeflag flagIcon">
                                                            <i class="fa-solid fa-flag"></i>
                                                        </div>
                                                    </td>


                                                    <td>{{ dealerVehicleslistdata.vin }}</td>
                                                    <td>{{ dealerVehicleslistdata.province }}</td>
                                                    <td> {{ dealerVehicleslistdata.stockNumber }}</td>
                                                    <td> {{ dealerVehicleslistdata.carYr }}</td>
                                                    <td> {{ dealerVehicleslistdata.mileage }}</td>
                                                    <td> {{ dealerVehicleslistdata.make }}</td>
                                                    <td style="min-width:160px;max-width:160px"> {{
                                                        dealerVehicleslistdata.model }}</td>
                                                    <td style="min-width:180px;max-width:180px;"> {{
                                                        dealerVehicleslistdata.trim }}</td>
                                                    <td style="min-width:102px;"> {{ dealerVehicleslistdata.body }}</td>
                                                    <td style="text-align: right;"> {{
                                                        dealerVehicleslistdata.listedPrice| currency: 'USD' }}</td>
                                                    <td style="text-align: right;"> {{
                                                        dealerVehicleslistdata.discountedAmount| currency: 'USD' }}</td>
                                                    <td style="text-align: right;">
                                                        {{dealerVehicleslistdata.discountAmount| currency: 'USD' }}</td>
                                                    <td style="min-width:102px;"> {{ dealerVehicleslistdata.colour }}
                                                    </td>
                                                    <td style="min-width:102px;"> {{
                                                        dealerVehicleslistdata.engineFuelType }}</td>


                                                    <td style="text-align: right;"> {{ dealerVehicleslistdata.cbbValue
                                                        | currency: 'USD' }}</td>
                                                    <td style="text-align: right;">{{dealerVehicleslistdata.deltaValue |
                                                        currency: 'USD'}}
                                                    </td>
                                                    <td style="text-align: right;">{{dealerVehicleslistdata.deltaValue |
                                                        currency: 'USD'}}
                                                    </td>
                                                    <td style="text-align: right;min-width:80px;">
                                                        <button class="btn btn-green" style="width:24px;font-size:12px;"
                                                            (click)="openModalResponse(ResponseModelPopup,dealerVehicleslistdata)"
                                                            title="Response Detail">
                                                            <i class="fas fa-arrow-alt-circle-down"></i>
                                                        </button>
                                                        <button class="btn btn-red ml-1"
                                                            style="width:24px;font-size:12px;"
                                                            (click)="openModalResponse(ResponseModelPopup,dealerVehicleslistdata)"
                                                            title="Response Detail">
                                                            <i class="fas fa-arrow-alt-circle-down"></i>
                                                        </button>
                                                    </td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>
                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <div class="col-auto px-0">
                                                    <ngb-pagination [collectionSize]="dealerVehicleslist.length"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        [boundaryLinks]="true"></ngb-pagination>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                    <ng-template #ResponseModelPopup>
                        <div class="modal-header" style="background-color: black;">
                            <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Response Detail
                            </h6>
                            <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                                aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">

                            <section class="generate-report col-12">

                            </section>


                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-success btn-sm text-white"
                                (click)="SaveReponseDetail()" title="Save">
                                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                            </button>

                            <button class="btn btn-danger btn-sm text-white" title="Cancel" (click)="decline()">
                                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                            </button>


                        </div>
                    </ng-template>

                    <div class="row mt-2" *ngIf="apiDetailValue==='true'">
                        <div id="accordionExample" class="accordion">
                            <div class="col-md-12">
                                <div class="card card-raised h-100">
                                    <div class="card-header card-raised bg-info text-white">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-sm-6">
                                                <h5 class="card-title text-white text-uppercase mb-0">API Detail</h5>
                                            </div>
                                            <div class="ml-auto col-auto px-2">
                                                <button type="button" data-toggle="collapse" data-target="#CreditAPILog"
                                                    aria-expanded="true" aria-controls="CreditAPILog"
                                                    class="btn text-white plusbtn" style="padding: 0; font-size: 13px;">
                                                    <i class="fas fa-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="CreditAPILog" aria-labelledby="BuildCar" data-parent="#accordionExample"
                                        class="collapse show">
                                        <div class="card-body mb-2">
                                            <div class="row">
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>VIN</label>
                                                    <h6>{{selectedList.vin}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Province</label>
                                                    <h6>{{selectedList.province}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Stock No</label>
                                                    <h6>{{selectedList.stockNumber}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Year</label>
                                                    <h6>{{selectedList.carYr}}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Mileage</label>
                                                    <h6>{{selectedList.mileage}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Make</label>
                                                    <h6>{{selectedList.make}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Model</label>
                                                    <h6>{{selectedList.model}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Trim</label>
                                                    <h6>{{selectedList.trim}}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Body</label>
                                                    <h6>{{selectedList.body}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Price</label>
                                                    <h6>{{selectedList.listedPrice| currency: 'USD'}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Discounted Amount</label>
                                                    <h6>{{selectedList.discountedAmount| currency: 'USD'}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Discount</label>
                                                    <h6>{{selectedList.discountAmount| currency: 'USD'}}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Colour </label>
                                                    <h6>{{selectedList.colour }}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Fuel Type</label>
                                                    <h6>{{selectedList.engineFuelType}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Average CBB</label>
                                                    <h6>{{selectedList.cbbValue| currency: 'USD'}}</h6>
                                                </div>
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Delta</label>
                                                    <h6>{{selectedList.deltaValue| currency: 'USD'}}</h6>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="form-group col-xl-3 col-md-6 col-12">
                                                    <label>Status </label>
                                                    <h6>{{selectedList.deltaValue| currency: 'USD' }}</h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                </div>
            </div>
        </div>

        <ng-template #CheckDetailModel>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Inquiry Details</h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card enquire-vehicle">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Details of Inquiry</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>User Name:</label>
                                            <span>{{UserName}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Email:</label>
                                            <span>{{UserEmail}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Mobile Number:</label>
                                            <span>{{userPhoneNO}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-3">
                            <div class="card enquire-vehicle">
                                <div class="card-header p-2">
                                    <h6 class="mb-0">Dealer Info</h6>
                                </div>
                                <div class="card-body p-2">
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Dealer Name:</label>
                                            <span>{{DealerName}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Email:</label>
                                            <span>{{DealerEmail}}</span>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="form-group col">
                                            <label>Mobile Number:</label>
                                            <span>{{DealerNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-sm text-white" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>
        <!-- //enquiry form start -->
        <ng-template #enquiryform>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Request/Verify More Information
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="col-xl-6 col-lg-12 col-12">
                            <div class="span12 pagination-centered">
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'availability')"
                                            value="availability">
                                        <span>Verify Availability</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'kilometers')"
                                            value="kilometers">
                                        <span>Verify Kilometers</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'safety')" value="safety">
                                        <span>Verify Safety</span>
                                    </label>
                                </div>
                                <div class="checkbox">
                                    <label>
                                        <input type="checkbox" (click)="VerifyVehicle($event, 'photos')" value="photos">
                                        <span> Photos</span>
                                    </label>
                                </div>

                                <!-- <div *ngIf="isaithrselectedvalue==='On Clearance'">
                                    VISBLE
                                </div> -->
                            </div>
                        </div>
                        <div *ngIf="isaithrselectedvalue==='On Clearance'" class="col-xl-6 col-lg-12 col-12">
                            <div class="form-group mb-1">
                                <label>Offer Amount</label>
                                <input type="text" formControlName="OfferRequest" [(ngModel)]="OfferRequest"
                                    class="form-control" />

                            </div>
                            <div class="form-group mb-1">
                                <label>Comments</label>
                                <textarea class="form-control" formControlName="OfferRequestCommment"
                                    [(ngModel)]="OfferRequestCommment" rows="2"></textarea>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button *ngIf="buttonloaderSendEmail" class="buttonload btn btn-sm btn-secondary bg-gradient-info">
                    <i class="fa fa-spinner fa-spin"></i>Email Sending</button>
                <button type="button" *ngIf="!buttonloaderSendEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info">Send Request</button>

                <button class="btn btn-danger btn-sm text-white" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #makringUnits>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Marking of Unit
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="declineClose()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section id="unitInformation" class="generate-report">
                    <div class="row">
                        <div class="col-12">
                            <div class="card">
                                <div class="card-header">
                                    <h6 class="mb-0"><b>Unit Information</b></h6>
                                </div>
                                <div class="card-body">
                                    <div class="row">
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>VIN</label>
                                            <h6>{{MarkVIN}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Stock No</label>
                                            <h6>{{MarkStockNo}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Year</label>
                                            <h6>{{MarkYear}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Mileage</label>
                                            <h6>{{MarkMilegge}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Make</label>
                                            <h6>{{MarkMake}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Model</label>
                                            <h6>{{MarkModel}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Trim</label>
                                            <h6>{{Marktrim}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Body</label>
                                            <h6>{{Markbody}}</h6>
                                        </div>
                                        <div class="form-group col-lg-6 mb-1">
                                            <label>Price</label>
                                            <h6>{{Markprice | currency}}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="unitInfocheckbox" class="col-12 mt-2">
                            <div class="card">
                                <div class="card-body">
                                    <div class="row">
                                        <label *ngFor="let radiobutton of radioMakringItems">
                                            <input type="radio" name="options" (click)="setradioMarkUnits(radiobutton)"
                                                class="ml-2">{{radiobutton}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">

                <button #MarkButtonCloseBox class="btn btn-danger btn-sm text-white" title="Close"
                    (click)="declineClose()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>
            </div>
        </ng-template>


        <a #ConfirmBoxMarkUnFav style="display: none;" [routerLink]="" (click)="openModal(templateUnFavMark)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Mark" placement="top"
                ngbPopover="Would you like to Mark Selected Unit to be your Favorite?"></i></a>
        <ng-template #templateUnFavMark>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit to be remove from Favorite?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkUnFavUints()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
        </ng-template>


        <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(templateMark)"><i class="fa fa-upload"
                triggers="mouseenter:mouseleave" popoverTitle="Mark" placement="top"
                ngbPopover="Would you like to Mark Selected Unit to be your Favorite?"></i></a>
        <ng-template #templateMark>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit to be your Favorite?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkUints()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmMarkSoldBox style="display: none;" [routerLink]="" (click)="openModal(templateSold)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Sold" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as SOLD?"></i></a>
        <ng-template #templateSold>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as SOLD?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkSold()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
        </ng-template>
        <a #ConfirmMarkRemoveBox style="display: none;" [routerLink]="" (click)="openModal(templateRemove)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Remove" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as REMOVED??"></i></a>
        <ng-template #templateRemove>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as REMOVED?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkRemove()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmClearanceBox style="display: none;" [routerLink]="" (click)="openModal(templateClearance)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Remove" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as REMOVED??"></i></a>
        <ng-template #templateClearance>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as Discounted ?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkDiscounted()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
        </ng-template>

        <!-- //enquiry form end -->

        <ng-template #Buyform>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">BUY</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <p>Please Confirm your purchase of the following <br> <b>{{Year}} {{Make}} {{Model}} </b></p>
                        <p>A 15 % deposit is required to proceed</p>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <!-- <button (click)="PostVerifyvehicleRequest()" type="button" class="btn btn-success btn-sm text-white">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
                </button> -->
                <button *ngIf="buttonloaderBuyEmail" class="buttonload btn btn-sm btn-secondary bg-gradient-info">
                    <i class="fa fa-spinner fa-spin"></i> Email Sending</button>
                <button type="button" *ngIf="!buttonloaderBuyEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>Yes</button>

                <button class="btn btn-danger btn-sm text-white" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #ModelPopUpImages let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Images</h5>
                <button type="button" class="close" style="color: white;" (click)="RefreshImageList();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <section class="generate-report">

                    <div *ngIf="errorshow=='false'"
                        style="width:765px; height:230px; text-align:center;margin:0; padding:0;">
                        <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                            [imageSize]="{width: '32.8%', height:210}" slideImage="1"></ng-image-slider>
                    </div>

                    <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                        {{errorMessage}}
                    </div>

                </section>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger ml-2"
                    (click)="RefreshImageList();modal.dismiss()">Cancel</button>
            </div>
        </ng-template>



    </form>


    <ng-template #ModelMakeOffer let-modal>
        <div class="modal-header" style="background-color: black;">
            <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Offer</h5>
            <button type="button" class="close" style="color: white;" (click)="modal.dismiss()" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div id="makeofferblock" class="modal-body">
            <section class="generate-report">
                <div class="row">
                    <div id="statusIcon" data-toggle="tooltip" data-placement="top" data-trigger="hover"
                        title="Offer Details">
                        <i class="fa fa-info-circle"></i>
                    </div>
                    <div class="col-md-12">
                        <h6>Offer Section <span> Price {{UnitListPrice|currency}}</span></h6>
                    </div>

                    <div class="form-group mb-1 col-xl-4 col-lg-12">
                        <label> Amount:</label>
                        <input type="text" [readonly]="offerreadonly" [(ngModel)]="OfferAmountng" class="form-control">

                        <div class="form-group mb-1 mt-1" *ngIf="offerreadonly">
                            <label> Date:</label>
                            <input type="text" [readonly]="offerreadonly" [(ngModel)]="OfferDateBindng"
                                class="form-control">
                        </div>
                    </div>

                    <div class="form-group mb-1 col-xl-8 col-lg-12">
                        <label>Comments:</label>
                        <textarea type="text" [readonly]="offerreadonly" [(ngModel)]="OfferCommentng"
                            class="form-control" rows="3"></textarea>
                    </div>

                </div>


                <div class="row" Id="RadiobtDiv" *ngIf="statusofOffer=='exit'">
                    <div class="col-md-12">
                        <h6>Decision Section</h6>
                    </div>


                    <div class="form-group mb-1 col-xl-12 col-lg-12 ">
                        <div class="row mx-2 mb-2 radioresponse">
                            <label class="mr-1" *ngFor="let radioListaccpetdata of DecisionitemsRadiobutton">
                                <input style="font-weight:normal;" type="radio"
                                    [(ngModel)]="selectedValueDecisionRadioBT" [value]="radioListaccpetdata.value"
                                    (click)="SelectionRadioFunction(radioListaccpetdata.groupName)">
                                <b>{{radioListaccpetdata.groupName}}</b>
                            </label>

                        </div>
                    </div>



                </div>
                <hr />
                <div *ngIf="newOffer">


                    <div class="row" id="AcceptSectiondiv" *ngIf="SelectionRadiobt == 999">
                        <!-- <div class="col-md-12">
                        <h6>Accept Section</h6>
                    </div> -->
                        <div class="form-group mb-1 col-xl-4 col-lg-12 ">
                            <div class="row mx-2 mb-2 radioresponse">
                                <label class="mr-1" *ngFor="let radiodata of radioAcceptCheckItems">
                                    <input style="font-weight:normal;" type="radio" class="radiopoint"
                                        name="optionsAccpetaccept" (click)="SelectedAccepteddecline(radiodata)"
                                        [checked]="radiodata ===Isaccepted_Checked"> <b>{{radiodata}}</b>
                                </label>
                            </div>
                            <div class="row mx-0" *ngIf="Isaccept!=1">
                                <div class="form-group mb-1 col">
                                    <label>Reason:</label>


                                    <select class="form-control" name="RejectReason" [(ngModel)]="RejectReason">
                                        <option value="0" selected>Select Reason</option>
                                        <option *ngFor="let ReasonListdata of ReasonList"
                                            [value]="ReasonListdata.globalCodeID">
                                            {{ReasonListdata.globalCodeName}}</option>
                                    </select>

                                </div>
                            </div>
                        </div>
                        <div class="form-group mb-1 col-xl-8 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" rows="2" [(ngModel)]="AccpetComment"></textarea>
                        </div>
                    </div>

                    <div class="row" id="CounterSectiondiv" *ngIf="SelectionRadiobt == 100">
                        <div class="col-md-12">
                            <h6>Counter Section</h6>
                        </div>
                        <div class="form-group mb-1 col-xl-4 col-lg-12">
                            <label> Amount:</label>
                            <input type="text" [(ngModel)]="COunterOffer" class="form-control">

                            <div class="form-group mb-1 mt-1" *ngIf="IsCOunterDateVisible">
                                <label> Date:</label>
                                <input type="text" [(ngModel)]="COunterDate" [ngModelOptions]="{ standalone: true }"
                                    [readonly]="true" class="form-control">
                            </div>
                        </div>


                        <div class="form-group mb-1 col-xl-8 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" [(ngModel)]="COunterComment" rows="2"></textarea>
                        </div>
                    </div>

                    <div class="row" id="ResponseSectiondiv" *ngIf="SelectionRadiobt == 111">
                        <div class="col-md-12">
                            <h6>Response Section</h6>
                        </div>

                        <div class="form-group mb-1 col-xl-12 col-lg-12">
                            <label>Comments:</label>
                            <textarea class="form-control" [(ngModel)]="ResponseComment" rows="3" cols="50"></textarea>
                        </div>
                    </div>
                </div>
            </section>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-md btn-success" (click)="PostOfferRequestRequest()">Save</button>
            <button type="button" class="btn btn-md btn-danger ml-2" (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>

    <button #regioncheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="regioncheckboxpopup(regioncheckbox,'')"></button>

    <ng-template #regioncheckbox>
        <div id="pdf-viewer-outer" class="modal-body">
            <h5>Region List </h5>
            <hr />
            <div class="row mt-2" id="dealerchckbox">

                <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
                <div class="col-12 listoption">
                    <div class="col-12 px-0 mb-2">
                        <input type="checkbox" name="chckboxall" (click)="Checkallregions($event)" id="checkall"
                            [(ngModel)]="SelectedAllCheckregion" />
                        <label class="ml-1">
                            <h6 class="mb-0" style="font-size:14px;"></h6>Select All
                        </label>
                    </div>
                    <hr class="my-1" />
                    <div class="col-12 px-0 pt-1">
                        <input type="checkbox" [(ngModel)]="groupRegionWestern" name="data" value="1"
                            (click)="GetGroupRegion(1)" /> Western Canada
                        &nbsp;
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionCentral" name="data" value="2"
                            (click)="GetGroupRegion(2)" /> Central Canada
                        &nbsp;
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionEastern" name="data" value="3"
                            (click)="GetGroupRegion(3)" /> Eastern - Ontario & Maritimes
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionOntario" name="data" value="5"
                            (click)="GetGroupRegion(5)" /> Ontario
                        &nbsp;
                        <input type="checkbox" [(ngModel)]="groupRegionother" name="data" value="4"
                            (click)="GetGroupRegion(4)" /> Territories
                    </div>
                    <hr />
                </div>
                <hr class="my-2" />
                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of regionlistName ; let i = index">
                    <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" />
                    {{item.globalCodeName}}
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="decline()">Back</button>
            <button type="button" class="btn btn-success btn-sm mr-1 rounded-pill" (click)="GetselectedRegions()">Done</button>
        </div>
    </ng-template>

</section>