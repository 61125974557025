
<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="AddLenderForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-2">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                                <ul class="list-unstyled mb-1">
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-sm-0">Copy</button>
                                    </li>
                                   
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-sm-0" (click)="exportexcel()">Excel</button>
                                    </li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-sm-0" (click)="downloadPdf()">PDF</button>
                                    </li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-sm-0" (click)="printTable()">Print</button>
                                    </li>
                                </ul>
                        </div>
                        <div class="col-lg-auto col-12 mr-auto">
                          <div class="row align-items-end mb-2 mb-lg-0">
                            <div class="form-group col-lg-auto col-md-5 col-12 mb-0">
                              <div class="row">
                                <label class="col-form-label col-lg-auto px-lg-1">Lender Name:</label>
                                <div class="col-lg-auto">
                                  <select class="form-control" name="region" formControlName="LenderNameFilter" (change)="FilterLenderList()"
                                    [(ngModel)]="LenderNameFilter">
                                    <option value="0" selected>Select Lender Name </option>
                                    <option *ngFor="let lenderlistsdata of lenderlists" [value]="lenderlistsdata.id">
                                      {{lenderlistsdata.lenderName}}</option>
                                  </select>
                                </div>
                                
                              </div>
                            </div>
                            <div class="form-group col-lg-auto col-md-5 col-12 mb-0">
                              <div class="row">
                                <label class="col-form-label col-lg-auto px-lg-1">Plan Type:</label>
                                <div class="col-lg-auto">
                                  <select class="form-control" name="region" formControlName="PlanNameFilter"  
                                  (change)="FilterPLanList()" [(ngModel)]="PlanNameFilter">
                                    <option value="0" selected>Select Plan Type</option>
                                    <option *ngFor="let lenderPlanlistsdata of lenderPlanlists" [value]="lenderPlanlistsdata.id">
                                      {{lenderPlanlistsdata.planType}}</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div class="form-group col-auto mb-0 mt-2 mt-lg-0">
                              <button id="resetbtn" class="btn bg-warning btn-sm mb-sm-0 rounded-pill" (click)="ResetFilter()">Reset</button>
                            </div>
                          </div>
                        </div>
                        <!-- <input type="button" onclick="onPrint('sectionToPrint')" value="Print" /> -->
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                          <div class="row justify-content-between align-items-center mb-1 mx-0">
                            <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                              <label class="mr-3 mt-2">Search:</label>
                              <input class="form-control" type="text" name="search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                autocomplete="off">
                            </div>
                            <div class="form-group mb-0 ml-md-3">
                              <label style="padding:2px;"> Items per Page:</label>
                              <select (change)="handlePageSizeChange($event)">
                                <option *ngFor="let size of pageSizes" [ngValue]="size">
                                  {{ size }}
                                </option>
                              </select>
                            </div>
                          </div>
                            
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                   
                                    <div class="row justify-content-between align-items-center">
                                      <div class="col-sm-6">
                                        <h5 class="card-title text-white text-uppercase mb-0">List of Lender Products</h5>
                                      </div>
                                      <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                        <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                      </div>
                                      <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('lenderName')"> Lender Name <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('planType')"> Plan Type <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('carYr')">FLTV new <i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th  style="text-align: right;" (click)="sort('fLTV')">FLTV <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th style="text-align: right;" (click)="sort('bLTV')">BLTV <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: right;" (click)="sort('maxBLTV')">Max BLTV <i class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th style="text-align: right;" >Is Doc Fee <br>Front</th>
                                                    <th style="text-align: right;" (click)="sort('docFee')">Doc Fee<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th style="text-align: right;" >Lender Fee</th>
                                                    <th style="text-align: right;">Reserves</th>
                                                    <th style="text-align: right;">PPSA</th>
                                                    <th style="text-align: right;">Is BLTV <br>List Price</th>
                                                    <th style="text-align: right;">Has GPS</th>
                                                    <th style="text-align: right;">GPS Fee</th>
                                                    <th style="text-align:center;">
                                                        <button type="button" class="btn btn-primary btn-sm text-white rounded-pill"
                                                        (click)="openAddLenderModel(ModelAddlender)" >
                                                        <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Lender Product
                                                      </button></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let lenderproductData of lenderInfo| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                   
                                                    <td>{{ lenderproductData.lenderName }}</td>
                                                    <td> {{ lenderproductData.planType  }}</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.fltVnew }}%</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.fltv }}%</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.bltv }}%</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.maxBLTV }}%</td>
                                                    <td style="text-align: right;" *ngIf="lenderproductData.isDocFeeFront ===true"> 
                                                      Yes</td>
                                                      <td style="text-align: right;" *ngIf="lenderproductData.isDocFeeFront ===false"> 
                                                        No</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.docFee | currency}}</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.lenderFee | currency }}</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.otherFee  | currency }}</td>
                                                    <td style="text-align: right;"> {{ lenderproductData.ppsa  | currency }}</td>

                                                    <td style="text-align: right;" *ngIf="lenderproductData.isBLTVfromListPrice ===1"> 
                                                      Yes</td>
                                                      <td style="text-align: right;" *ngIf="lenderproductData.isBLTVfromListPrice ===0 || lenderproductData.isBLTVfromListPrice ===Null"> 
                                                        No</td>

                                                    
                                                        <td style="text-align: right;" *ngIf="lenderproductData.hasGPSFee ===1"> 
                                                          Yes</td>
                                                          <td style="text-align: right;" *ngIf="lenderproductData.hasGPSFee ===0 || lenderproductData.hasGPSFee ===Null"> 
                                                            No</td>

                                                    
                                                    <td style="text-align: right;"> {{ lenderproductData.gpsFee  | currency }}</td>
                                                    
                                                    <td style="text-align:center;" >
                                                        <button class="btn bg-gradient-info btn-sm" title="Edit"
                                                        (click)="EditModalpopup(ModelAddlender,lenderproductData.id)">
                                                        <i
                                                            class="fa-solid fa-pen-to-square"></i></button>
                                                        <button class="btn bg-gradient-danger btn-sm ml-2"  title="Delete"><i
                                                          (click)="openModal(template,lenderproductData.id)" class="fa fa-trash"></i></button>

   
                                                             <a class="btn bg-gradient-success btn-sm ml-2 dollerIcon" title="Add Rate Sheet" target="_blank"
                                                              [routerLink]="['/Lender-Rate-sheet',lenderproductData.lenderName,lenderproductData.planType]"><i
                                                              class="fa fa-dollar"></i></a>
                                                      </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>

                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>

                                        <a #ConfirmBox style="display: none;" [routerLink]="" 
                                        (click)="openModal(template,'')"><i class="fa fa-upload"
                                          triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
                                          ngbPopover="Are you sure you want to delete?"></i>
                                      </a>
                                      <ng-template #template>
                                        <div class="modal-body text-center">
                                          <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                          <p>Are you sure you want to delete?
                                          </p>
                                          <p></p>
                                          <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                                          <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                                        </div>
                                      </ng-template>
                                    
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col">
                                            <ngb-pagination [collectionSize]="lenderInfo.length" [(page)]="page" [maxSize]="5"
                                              [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                                          </ngb-pagination>
                                        </div>
                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                                          <div class="form-group mb-0 mr-1">
                                            <label style="padding:2px;"> Items per Page:</label>
                                            <select (change)="handlePageSizeChange($event)">
                                              <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                {{ size }}
                                              </option>
                                            </select>
                                          </div>
                                        </div>
                                    </div>
                                       
                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <ng-template #ModelAddlender let-modal>
            <div class="modal-header" style="background-color: black;">
              <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">{{modalTitle}}</h5>
              <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
  
              <section class="generate-report">
                <div class="row">
                    <div class="form-group col-md-6 col-lg-4">
                        <label>Lender Name</label>

                        <select class="form-control" name="region" formControlName="LenderName" [(ngModel)]="LenderName"
                        [ngClass]="{ 'is-invalid': submitted && f.LenderName.errors }"
                          >
                          <option value="0" selected>Select Lender Name </option>
                          <option *ngFor="let lenderlistsdata of lenderlists" [value]="lenderlistsdata.id">
                            {{lenderlistsdata.lenderName}}</option>
                        </select>
                        <div *ngIf="submitted && f.LenderName.errors" class="invalid-feedback">
                          <div *ngIf="f.LenderName.errors.required">Lender Name is required</div>
                          </div>
                        <!-- <input type="text" formControlName="LenderName" class="form-control"
                        placeholder="Lender Name"
                        (keypress)="ValidTextBox($event)"[(ngModel)]="LenderName"
                        [ngClass]="{ 'is-invalid': submitted && f.LenderName.errors }" />
                        <div *ngIf="submitted && f.LenderName.errors" class="invalid-feedback">
                        <div *ngIf="f.LenderName.errors.required">Lender Name is required</div>
                        </div> -->
                    </div>

                  <div class="form-group col-md-6 col-lg-4">
                    <label>Plan Type</label>
                    <input type="text" formControlName="planType" class="form-control"
                    placeholder="Plan Type"
                      (keypress)="ValidTextBox($event)"[(ngModel)]="PlanType"
                       [ngClass]="{ 'is-invalid': submitted && f.planType.errors }" />
                    <div *ngIf="submitted && f.planType.errors" class="invalid-feedback">
                      <div *ngIf="f.planType.errors.required">Plan Type is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-lg-4">
                    <label>FLTV new</label>
                    <input type="text" formControlName="fltVnew" class="form-control"
                    
                      (keypress)="ValidTextBox($event)"[(ngModel)]="FLTVnew"
                       [ngClass]="{ 'is-invalid': submitted && f.fltVnew.errors }" />
                    <div *ngIf="submitted && f.fltVnew.errors" class="invalid-feedback">
                      <div *ngIf="f.fltVnew.errors.required">FLTV new is required</div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="form-group col-md-6 col-lg-6">
                    <label>FLTV</label>
                    <input type="text" formControlName="fltv" class="form-control"
                   
                      (keypress)="ValidTextBox($event)"[(ngModel)]="FLTV"
                       [ngClass]="{ 'is-invalid': submitted && f.fltv.errors }" />
                    <div *ngIf="submitted && f.fltv.errors" class="invalid-feedback">
                      <div *ngIf="f.fltv.errors.required">FLTV is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-lg-6">
                    <label>BLTV</label>
                    <input type="text" formControlName="bltv" class="form-control"
                    
                      (keypress)="ValidTextBox($event)"[(ngModel)]="BLTV"
                       [ngClass]="{ 'is-invalid': submitted && f.bltv.errors }" />
                    <div *ngIf="submitted && f.bltv.errors" class="invalid-feedback">
                      <div *ngIf="f.bltv.errors.required">BLTV is required</div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                    <div class="form-group col-md-12 px-0 ">
                        <div class="form-check-inline w-100">
                          <div class="col-md-6 pt-2">
                            <div class="col-md-12 px-2">
                              <input type="checkbox" id="docFee" [(ngModel)]="IsDocFee"
                               formControlName="docFeeControl" (change)="CheckLoggedActivity($event)"
                                         class="form-check-input"  
                                         (keypress)="ValidTextBox($event)"
                                      />
                                <label for="docFee">Is Front doc Fee</label>
                            </div>
                            <div class="col-md-12 px-2">
                              <input type="checkbox" id="bltvlistprice" [(ngModel)]="IsBltvListPrice"
                               formControlName="bltvControl" 
                                         class="form-check-input"  
                                         (keypress)="ValidTextBox($event)"
                                      />
                                <label for="bltvprice">Is BLTV Based on List Price</label>
                            </div>
                            <div class="col-md-12 px-2">
                              <input type="checkbox" id="hasgps" [(ngModel)]="HasGpsFee"
                               formControlName="hasGps" 
                                         class="form-check-input"  
                                         (keypress)="ValidTextBox($event)"
                                      />
                                <label for="hasGps">Has GPS</label>
                            </div>
                          </div>
                            <div class="col-md-6">
                              <div class="row">
                                <div class="col-xl-6 col-lg-12">
                                  <label >Doc Fee</label>
                                  <input type="text" formControlName="docFee" class="form-control"
                                 
                                   [(ngModel)]="DocFee"
                                  [ngClass]="{ 'is-invalid': submitted && f.docFee.errors }" (input)="restrictInput($event)" />
                                  <div *ngIf="submitted && f.docFee.errors" class="invalid-feedback">
                                  <div *ngIf="f.docFee.errors.required">Doc Fee is required</div>
                                  </div>
                                </div>
                                <div class="col-xl-6 col-lg-12">
                                  <label >GPS Fee</label>
                                  <input type="text" formControlName="gpsfee" class="form-control"
                                 
                                  (keypress)="ValidTextBox($event)" [(ngModel)]="GPSFee"
                                  [ngClass]="{ 'is-invalid': submitted && f.docFee.errors }" />
                                  <div *ngIf="submitted && f.docFee.errors" class="invalid-feedback">
                                  <div *ngIf="f.docFee.errors.required">GPS Fee is required</div>
                                  </div>
                                </div>
                              </div>
                              
                            </div>
                        </div>
                    </div>
                  
                </div>
                <hr/>
                <div class="row">
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Reserves</label>
                    <input type="text" formControlName="otherFee" class="form-control"
                   
                      (keypress)="ValidTextBox($event)"[(ngModel)]="OtherFee"
                       [ngClass]="{ 'is-invalid': submitted && f.otherFee.errors }" />
                    <div *ngIf="submitted && f.otherFee.errors" class="invalid-feedback">
                      <div *ngIf="f.otherFee.errors.required">Other Fee is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-lg-4">
                    <label>Lender Fee</label>
                    <input type="text" formControlName="lenderFee" class="form-control"
                  
                      (keypress)="ValidTextBox($event)"[(ngModel)]="LenderFee"
                       [ngClass]="{ 'is-invalid': submitted && f.lenderFee.errors }" />
                    <div *ngIf="submitted && f.lenderFee.errors" class="invalid-feedback">
                      <div *ngIf="f.lenderFee.errors.required">Lender Fee is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6 col-lg-4">
                    <label>PPSA</label>
                    <input type="text" formControlName="ppsa" class="form-control"
                  
                      (keypress)="ValidTextBox($event)"[(ngModel)]="PPSA"
                       [ngClass]="{ 'is-invalid': submitted && f.ppsa.errors }" />
                    <div *ngIf="submitted && f.ppsa.errors" class="invalid-feedback">
                      <div *ngIf="f.ppsa.errors.required">PPSA is required</div>
                    </div>
                  </div>

                </div>
                <hr/>
                <div class="row">
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max Loan Amount</label>
                    <input type="text" formControlName="maxloanamount" 
                    class="form-control"  (keypress)="ValidTextBox($event)" 
                    
                    [(ngModel)]="MaxLoanAmount"
                      />
                   
                  </div>
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max Warranty</label>
                    <input type="text" formControlName="maxwarranty" class="form-control" 
                    [(ngModel)]="MaxWarranty" />
                    
                  </div>
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max GAP</label>
                    <input type="text" formControlName="maxgap" class="form-control" 
                    [(ngModel)]="MaxGap" />
                    
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max JobLoss</label>
                    <input type="text" formControlName="maxjobloss" class="form-control"  
                    [(ngModel)]="MaxJobLoss" />
                    
                  </div>
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max Accessories</label>
                    <input type="text" formControlName="maxaccessories" class="form-control" 
                    [(ngModel)]="MaxAccessories"  />
                    
                  </div>
                  <div class="form-group col-md-6 col-lg-4">
                    <label>Max Other Taxables</label>
                    <input type="text" formControlName="maxothertaxable" class="form-control" 
                    [(ngModel)]="MaxOtherTaxables" />
                    
                  </div>
                </div>
                <div class="row">
                  <div class="form-group col-md-12 col-lg-4">
                    <label>Max Term</label>
                    <input type="text" formControlName="maxterm" class="form-control" 
                    [(ngModel)]="MaxTerm" />
                    
                  </div>

                  <div class="form-group col-md-12 col-lg-4">
                    <label>Max BLTV</label>
                    <input type="text" formControlName="maxbltv" class="form-control" 
                    [(ngModel)]="maxbltv" />
                    
                  </div>
                </div>
                
              </section>
  
  
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
              </button>
  
              <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
              </button>
  
  
            </div>
          </ng-template>
    </form>
</section>
