import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DebugEventListener, NgZone, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AIXLenderInfo } from '@app/_models/AIXLenderInfo';
import { AIXLenders } from '@app/_models/AIXLenders';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { AlertService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { AccountService } from '@app/_services/account.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationExtras } from "@angular/router";
import { TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import * as $ from 'jquery'
import { debounceTime, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { group } from '@angular/animations';
import { IDropdownSettings, } from 'ng-multiselect-dropdown';

import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { ListDealersService } from '@app/_services/list-dealers.service';

@Component({
  selector: 'app-findcar-detail',
  templateUrl: './findcar-detailfinal.component.html',
  styleUrls: ['./findcar-detailfinal.component.less'],
  providers: [NgbCarouselConfig],
  changeDetection: ChangeDetectionStrategy.OnPush,
})




export class FindcarDetailfinalComponent implements OnInit, AfterViewInit {
  findCarForm: FormGroup;
  private notifier: NotifierService;
  @ViewChild('dealercheckboxpopupbuttonToBeClicked') buttonToBeClicked: ElementRef
  @ViewChild("AithrHubKeyControl") AithrHubKeyControlclick: ElementRef;
  @ViewChild('regioncheckboxpopupbuttonToBeClicked') regionbuttonToBeClicked: ElementRef
  @ViewChild('buttonPleasecheck') buttonPleasecheckclicked: ElementRef
  @ViewChild('buttoncolorChnage') buttoncolorChnageClick: ElementRef

  @ViewChild('LockListConfirmBox', { static: false }) ConfirmBoxListPriceclick: ElementRef;

  Index: any;
  @ViewChild('disabledInput', { static: false }) disabledInput: ElementRef;

  images = [
    { title: 'First Slide', short: 'First Slide Short', src: "https://picsum.photos/id/700/900/500" },
    { title: 'Second Slide', short: 'Second Slide Short', src: "https://picsum.photos/id/1011/900/500" },
    { title: 'Third Slide', short: 'Third Slide Short', src: "https://picsum.photos/id/984/900/500" }
  ];


  @ViewChild('buildcarpopupbuttonToBeClicked') buildCarConfirmModelbuttonToBeClicked: ElementRef
  modalRef: BsModalRef;
  customermodalRef: BsModalRef;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private globalcodeService: GlobalcodeService,
    private accountService: AccountService,
    private lenderService: LenderService,
    private addgroupService: AddgroupService,
    private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    private ref: ChangeDetectorRef,
    private _ngZone: NgZone,
    config: NgbCarouselConfig,
    private listDealersService: ListDealersService,
  ) {

    config.interval = 2000;
    config.keyboard = true;
    config.pauseOnHover = true;

    this.notifier = notifier;
    this.accountService.SaveActivityLog("");

    this.Index = this.route.snapshot.paramMap.get('index');

    if (this.Index == "M") {

    } else {
      var parameterValuescheck = localStorage.getItem('Findcarparameter' + this.Index);
      let objValuescheck = JSON.parse(parameterValuescheck);

      this.App_lenderId = objValuescheck.LenderId;
      this.App_lenderName = objValuescheck.aithrLenderName;
      this.App_payCall = objValuescheck.payCall;
      this.App_interestRate = objValuescheck.interestRate;
      this.App_plantypeId = objValuescheck.aithrPlanTypeID;

      this.App_Tradevalue = objValuescheck.tradevalue;
      this.App_LienValue = objValuescheck.lienvalue;

      // parameters for Build car page
      this.applicationId = objValuescheck.applicationId;
      this.creditorId = objValuescheck.creditorId;
    }

  }
  toggle = true;
  status = 'Enable';
  ItemCount: any;
  SelectedAllCheck: any;
  ExtingItemCount: any;

  ItemCountregion: any;
  SelectedAllCheckregion: any;
  ExtingItemCountregion: any;

  firsttimechecklender: boolean = false;
  errorMessage: string;
  ShowAdvce;any;
  errorshow: any;
  errorshowAuto: any;
  errorMessageAuto: any;
  vehicleObject = [];
  customimagesrc = [];
  ViewDetails:any;
  ListViewListPrice:any;
  listViewValue: any;
  selectedbuildbuttoIndex: any;
  selectedgroupId: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;

  // Dealer ID from page

  dealeridDropDown: any;
  groupIdPage: any;

  Provincelist = [];
  InventorySorucelist=[];
  citylist = [];

  GroupLists = [];
  lenderlists: AIXLenders[] = [];
  province: any;
  HideAxises: any;
  dealerName: any;
  groupName: any;
  useridValue: any;
  ipaddressvalue: any;
  dealerlistName = [];
  ColumnList = [];
  regionlistName = [];
  regionName: any;
  regionmultiselectarray: any[];
  lenderKeyList = [];
  dealerVehicleslist: DealerVehicles[] = [];
  Did = 0;
  TotalRecord: any;
  NoCarFound: any;
  ShowDealerAdvance:any;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 12;
  pageSizes = [12,4,8,12, 16, 24,'All'];
  searchtable = "";
  searchtablearray = [];
  displayList: any;
  //selectedValue:any;
  dealerCity: any;
  isDesc: boolean = false;
  lenderIdValue: any;
  gpsFeeValueSend: any;
  DisplayFilters: any;
  IsadvanceFilterCheck: any;
  LenderNameValue: any;
  PLanType: any;
  Lenderkey: any;
  AithrHubKey: any;
  LenderPayCall: any;
  LenderInterestRate: any;
  ACVValueControl: any;
  LoanAmountControl: any;
  DownPaymentAmountControl: any;
  buttonloader: boolean = false;
  isShowDiv = false;
  checkkeyp: any = [];

  checkOriginalPagingArrayLength: any = [];

  TotalFrontproducts: any;
  TotalFrontcost: any;
  TotalBackproducts: any;
  TotalBackcost: any;
  //selectedGroupIds: string[];
  selectedGroupIds: string;
  selectedGroupId: any;
  SelectedGroupName: any;
  selectedDealerId: any;
  hideCustomerButton: any;
  //selectedDealerIds: string[];

  selectedDealerIds: string;
  ShowMultiSelectRegion: any;
  ShowAithrhubMultiSelectRegion: any;
  ShowAllowable: any;
  dealersmultiselectarray: any[];
  modalcloseOpen: any;
  that: any;
  feature = 'Feature 1'
  lendercheckboxselectedarray: any = [];
  isTaxValue: any;
  selectedIndex: number;

  CustomerviewTabledatasource: any = [];


  dropdownList = [];
  dropdownSettings: IDropdownSettings = {};

  CustomerCheckviewbutton = [];
  dealerVehicleslistimage = [];
  deductionfeedisplay: any = false;
  selectedMake: any;
  customerlist = [];
  selectedcustomerid: any;

  //for customer
  firstname: any;
  middlename: any;
  lastname: any;
  lastName: any;
  email: any;
  postalcode: any;
  phonenumber: any;
  checkActivitylog: any;
  checkActivitylogValue: any;
  IsSupportTeam: any;
  IsSupportTeamValue: any;
  city: any;
  country: any;
  address: any;
  dlnumber: any;
  otherid: any;
  gst: any;
  insurance: any;
  region: any = 0;
  isValid: boolean = true;
  loading = false;

  submittedcustomer: boolean = false;
  isValidFirstname: boolean = false;

goplanindex_forrate:any;

  sort(property, i: any) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;


    //shorting with dynamic data

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;

    var sortdata = dataarray.value




    sortdata.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });

    dataarray.setValue(sortdata);

    // this.dealerVehicleslist.sort(function (a, b) {
    //   if (a[property] < b[property]) {
    //     return -1 * direction;
    //   }
    //   else if (a[property] > b[property]) {
    //     return 1 * direction;
    //   }
    //   else {
    //     return 0;
    //   }
    // });

  };
  searchText;
  delaeridforserver = [];
  regionidforserver = [];
  // FOR Disable group down
  groupDisable: any;
  BuildlistforOpenMultipltab: any = [];
  lockPriceValue: any;
  hasGPSValue: any;
  hasGPSValueCheck: any;
  hideGps: any;
  feeValue: any;
  advanceFilter: any;
  hideCustomerViewButton: any;
  // for column setting
  ConditionColumnStatus: any;
  ListedPriceColumnStatus: any;
  DealerCostColumnStatus: any;
  ProjectedGrossColumnStatus: any;
  MaxPaycallColumnStatus: any;
  selectedyear: any;
  ColorTheme: any;
  LicenseFee: any;
  FrontEndGrossTotalAmount: any;
  BackEndGrossTotal: any;
  OtherGrossTotal: any;
  isLockListPrice:any;
  CBBValueControl: any;
  IsLockListPricevalue:any;
  FEAccessory1: any;
  FEAccessory2: any;
  FEAccessory3: any;
  FEMaximizedAmount: any;

  GWPrice1 : any;
  GWPrice2 : any;
  GWCost1 : any;
  GWCost2: any;
  GWTaxes: any;

  Year = [
    {
      key: 2018, value: "2018",
    },
    {
      key: 2018, value: "2021",
    },
    {
      key: 2018, value: "2021",
    }
  ]

  App_lenderId: any;
  App_lenderName: any;
  App_payCall: any;
  App_interestRate: any;
  App_plantypeId: any;

  applicationId: any;
  creditorId: any;

  App_Tradevalue: any;
  App_LienValue: any;


  beforeDeductionTextvalue: any ;
  DeductionTextvalue: any = 82;
  Deductiondropdownvalue: any;
  DeductiolenderValue: any;
  Deductiolender_Index: any;


  is_nonGarnishable_checked:boolean=false;
  is_Self_Employed_checked:boolean=false;
  is_Out_Of_Service_checked:boolean=false;
  is_two_repossessions_checked:boolean=false;


  CustomeraddValue: any;
  Customeradd_Index: any;
  lightboxModel:any;

  changeYear(e) {

    if (e.target.value == 1) {
      console.log("Project One");
    }
    else if (e.target.value == 2) {
      console.log("Project Two");
    }
    else if (e.target.value == 3) {
      console.log("Project Three");
    }

  }
  enableDisableRule() {
    this.toggle = !this.toggle;
    this.status = this.toggle ? 'Enable' : 'Disable';
  }

  //add customer

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }


  keyPressCheck(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  checkLockListPriceValue(values: any): void {
    if (values.currentTarget.checked == true) {
      this.IsLockListPricevalue = true;
    } else {
      this.IsLockListPricevalue = false;
    }

    let el: HTMLElement = this.ConfirmBoxListPriceclick.nativeElement;
    el.click();

  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-sm', backdrop: 'static', keyboard: false });
  }
  confirmLockPrice(): void {
    this.modalRef.hide();
    this.IsLockListPricevalue = true;
    this.findCarForm.controls.IsLockListPrice.setValue(true);
  }
  declineLockListPrice(): void {
    this.IsLockListPricevalue = false;
    this.findCarForm.controls.IsLockListPrice.setValue(false);
    this.modalRef.hide();
  }
  CloseAddmodel() {

    this.firstname = "";
    this.middlename = "",
      this.lastname = "",
      this.address = "",
      this.city = "",
      this.region = "",
      this.country = "",
      this.postalcode = "",
      this.email = "",
      this.phonenumber = "",//this.UserListForm.controls.phoneNumber.value,
      this.dlnumber = "",
      this.otherid = "",
      this.gst = "",
      this.insurance = "",
      this.DealerIdvalue = ""

    this.modalRef.hide();
  }

  onSubmitCustomer() {
   debugger;
    this.DealerIdvalue = localStorage.getItem('DealerId');

    this.submittedcustomer = true;
    if (this.firstname == "" || this.firstname == undefined) {
      this.isValidFirstname = true;
      return;
    }

    if (this.middlename == "" || this.middlename == undefined) {
      this.isValid = false;
      return;
    }

    if (this.lastname == "" || this.lastname == undefined) {
      this.isValid = false;
      return;
    }

    if (this.address == "" || this.address == undefined) {
      this.isValid = false;
      return;
    }

    if (this.city == "" || this.city == undefined) {
      this.isValid = false;
      return;
    }
    if (this.region == "" || this.region == undefined) {
      this.isValid = false;
      return;
    }
    if (this.country == "" || this.country == undefined) {
      this.isValid = false;
      return;
    }
    if (this.postalcode == "" || this.postalcode == "") {
      this.isValid = false;
      return;
    }
    if (this.email == "" || this.email == undefined) {
      this.isValid = false;
      return;
    }
    if (this.phonenumber == "" || this.phonenumber == undefined) {
      this.isValid = false;
      return;
    }
    if (this.dlnumber == "" || this.dlnumber == undefined) {
      this.isValid = false;
      return;
    }
    if (this.otherid == "" || this.otherid == undefined) {
      this.isValid = false;
      return;
    }
    if (this.gst == "" || this.gst == undefined) {
      this.isValid = false;
      return;
    }
    if (this.insurance == "" || this.insurance == undefined) {
      this.isValid = false;
      return;
    }



    if (this.city == "" || this.firstname == "") {
      this.isValid = false;
      return;
    }
    if (this.region == "" || this.firstname == "") {
      this.isValid = false;
      return;
    }

    // Check postal code validity
    const postalCodeField = this.postalcode;

    // if (postalCodeField.value && postalCodeField.invalid) {
    //   this.isValid = false;
    //   return;
    // } else if (!postalCodeField.value) {
    //   this.isValid = false;
    //   return;
    // }

    if (this.country == 0) {
      this.isValid = false;
      return;
    }
    if (this.dlnumber == 0) {
      this.isValid = false;
      return;
    }
    if (this.gst == 0) {
      this.isValid = false;
      return;
    }
    if (this.otherid == 0) {
      this.isValid = false;
      return;
    }
    if (this.insurance == 0) {
      this.isValid = false;
      return;
    }


   // Lender information

   var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    var lenderid = Lenderarraydata.value[this.Customeradd_Index].lenderkeyddl
    var LenderPayCall = Lenderarraydata.value[this.Customeradd_Index].LenderPayCall;
    var LenderInterestRate = Lenderarraydata.value[this.Customeradd_Index].LenderInterestRate;
    var TradeValue = Lenderarraydata.value[this.Customeradd_Index].ACVValueControl;
    var Lien = Lenderarraydata.value[this.Customeradd_Index].LoanAmountControl;
    var DownPayment = Lenderarraydata.value[this.Customeradd_Index].DownPaymentAmountControl;

    if (lenderid == "") {
      lenderid = "0";
    }
    if (LenderPayCall == "") {
      LenderPayCall = "0";
    }
    if (LenderInterestRate == "") {
      LenderInterestRate = "0";
    }
    if (TradeValue == "") {
      TradeValue = "0";
    }
    if (Lien == "") {
      Lien = "0";
    }
    if (DownPayment == "") {
      DownPayment = "0";
    }

    var modelGroup = {
      "Id": "0",
      "firstname": this.firstname,
      "middlename": this.middlename,
      "lastname": this.lastname,
      "address": this.address,
      "city": this.city,
      "region": this.region,
      "Province": this.region,
      "ProvinceID": this.region,
      "country": this.country,
      "postalcode": this.postalcode,
      "email": this.email,
      "Phone": this.phonenumber,//this.UserListForm.controls.phoneNumber.value,
      "dlnumber": this.dlnumber,
      "OtherIDnumber": this.otherid,
      "GSTNumber": this.gst,
      "Insurancenumber": this.insurance,
      "Dealerid": this.DealerIdvalue,
      "LenderKey": lenderid,
      "PayCall": LenderPayCall,
      "InterestRate": LenderInterestRate,
      "customerid": this.selectedcustomerid,
      "TradeValue": TradeValue,
      "Lien": Lien,
      "DownPayment": DownPayment
    }
    //console.log("Save User Data:", modelGroup);

    this.listDealersService.saveaddCustomers(modelGroup)
      .subscribe(
        (data: any) => {

          // this.hideshowbutton=false;
          if (data == "2" || data == "4") {
            console.log(data)
            this.showNotification("error", "Customer already registered in system.");
            return;
          } else {
            // this.GetCustomerList()
            this.showNotification("success", "Data saved successfully");
          }

          // this.submitted = false;
          // this.reinitiatengmodel();
          //  this.Createform();
          //  this.ngOnInit();
          this.CloseAddmodel();
          this.declineCustomer();
          // this.closepopupmodel();
          //console.log("Save User Response:", data);
        },
        error => {
        });
    this.loading = true;
    this.isValid = true;




  }

  openAddcustomerModel(targetModal) {
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-dialog-centered modal-lg',
      backdrop: 'static',
      keyboard: false
    });
  }

  ngAfterViewInit(): void {

    if (this.Index != "M") {
      var lendercheckboxid = '#lenderchck_' + this.App_lenderId;

      $(lendercheckboxid).prop("checked", true);

    }

  }


  SortDropDown(value: object, i: any) {


    var data = this.selectedMake;

    this.sort(value, i)

  }


  ngOnInit(): void {
    this.NoCarFound = false;
    this.listViewValue = "false";
    this.ShowAdvce="false";
    this.ListViewListPrice="true";
    this.ShowDealerAdvance=false;
    this.Createform();
    this.ViewDetails='Grid';
    this.GetProvincelist();
    this.GetInvertorySourcelist();
    this.GetDealerCitylist()
    this.GetProvinceMuiltiSelectlist();
   // this.GetGroupList();
    

    this.GWPrice1="0";
    this.GWPrice2="0";
    this.GWCost1="0";
    this.GWCost2="0";
    this.GWTaxes="0";

    this.LicenseFee="0";
    this.FrontEndGrossTotalAmount="0";
    this.BackEndGrossTotal="0";
    this.OtherGrossTotal="0";
    this.isLockListPrice=false;
     this.IsLockListPricevalue=false;
    this.FEAccessory1="0";
    this.FEAccessory2="0";
    this.FEAccessory3="0";
    this.FEMaximizedAmount="0";
    
    this.hideCustomerButton = "true";
    this.hideCustomerViewButton = "false";
    this.isTaxValue = false;
    this.IsadvanceFilterCheck = false;
    this.hasGPSValue = false;
    this.hasGPSValueCheck = 0;
    this.feeValue = 500;
    this.advanceFilter = "false";
    this.hideGps = "false";

    this.ConditionColumnStatus = "true";
    this.ListedPriceColumnStatus = "true";
    this.DealerCostColumnStatus = "true";
    this.ProjectedGrossColumnStatus = "true";
    this.MaxPaycallColumnStatus = "true";


    this.ItemCount = 0;
    this.SelectedAllCheck = false;
    this.ExtingItemCount = 0;

    this.ItemCountregion = 0;
    this.SelectedAllCheckregion = false;
    this.ExtingItemCountregion = 0;
    this.AithrHubKey = "0";
    this.LenderPayCall = 0;
    this.lockPriceValue = 0;
    this.LenderInterestRate = 0;
    this.ACVValueControl = 0;
    this.LoanAmountControl = 0;
    this.DownPaymentAmountControl = 0;

    this.displayList = "false";
    this.DisplayFilters = "false";
    this.ShowMultiSelectRegion = false;
    this.ShowAllowable = true;

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }


    this.rolevalue = localStorage.getItem('UserFullRole');
    if (this.rolevalue == "SuperAdmin") {
      this.groupDisable = "false";
    } else {
      this.groupDisable = "true";
    }
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    this.Getlenderslist();
    this.province = "0";
    this.dealerName = "0";
    this.groupName = "0";

    this.useridValue = localStorage.getItem('userid');
    this.GetGroupFindCarList();
    // for gettiing column setting
    this.GetFindcarlistColumns();

    this.ipaddressvalue = localStorage.getItem('IpAddresstoTrack');
    //set drop down
    this.selectedGroupIds = localStorage.getItem('GroupName');
    this.selectedGroupId = localStorage.getItem('groupid');
    //this.GetDealerNamebyGroupId(groupid)
    this.GetDealers(this.selectedGroupId);
    this.selectedDealerIds = this.dealerNamevalue;
    this.province = localStorage.getItem('Province');

    if (this.province == "11") {
      this.HideAxises = "true";

    } else {
      this.HideAxises = "false";
    }


    //this.selectedDealerIds='18','17','15';
    //this.selectedDealerIds=localStorage.getItem('DealerId');
    //this.province=localStorage.getItem('GroupName');

    this.TotalFrontproducts = 0;
    this.TotalFrontcost = 0;
    this.TotalBackproducts = 0;
    this.TotalBackcost = 0;

    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {

    } else {

    }






  }

  ShowTotalCount(i:any)
  {
    debugger
    if ($('#totalcount_' + i).css('display') == 'none') {
      $('#totalcount_' + i).show();
    }
    else {
      $('#totalcount_' + i).hide();
    }
  }

  onSlideClicked(targetModal,value: any)
  {
    debugger;
this.lightboxModel=value;
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static',
      keyboard: false
    });
  }


  hideCustomerButtonCheck() {

    this.hideCustomerButton = "true";
    this.hideCustomerViewButton = "false";
  }
  ShowCustomerButtonCheck() {

    this.hideCustomerButton = "false";
    this.hideCustomerViewButton = "true";
  }
  ChangeRegion(event) {
    debugger;
    const value = event.target.value;
    if (value == "11") {
      this.HideAxises = "true";

    } else {
      this.HideAxises = "false";
    }

if(this.goplanindex_forrate!=undefined)
{
  var index=this.goplanindex_forrate
  //set interest rate
    var interestrate=0.0;
    if(value=="12"||value=="10024")
    {
      interestrate=31.9;
    }
    else{
      interestrate=29.50;
    }
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    Lenderarraydata.at(index).get('LenderInterestRate').setValue(interestrate);
}


  }
  //   ngAfterViewChecked() {()
  //     debugger;
  // }

  // function for open model pop up
  Dealercheckboxpopup(template: TemplateRef<any>, itemid: any) {

    this.ItemCount = 0;
    for (let i = 0; i < this.dealerlistName.length; i++) {
      if (this.dealerlistName[i].IsSelected == true) {
        this.ItemCount = this.ItemCount + 1;
      }
    }
    this.ExtingItemCount = this.dealerlistName.length;
    if (this.ExtingItemCount == this.ItemCount) {
      this.SelectedAllCheck = true;
    } else {
      this.SelectedAllCheck = false;
    }


    //this below fubction when user come from application start

    if (this.Index != "M") {


      var lendercheckboxid = '#lenderchck_' + this.App_lenderId;

      //  $(lendercheckboxid).prop( "checked", true );


      //  this.lenderlists.forEach(val => {
      //       if (val.id == this.App_lenderId)
      //       {
      //          val.isSelected = true;
      //       }
      //       else {
      //         val.isSelected = false;
      //         this.DisplayFilters = "false";
      //         $('#' + "findlenderdiv_" + val.id).remove();
      //       }
      //     });


      //$(lendercheckboxid).trigger('click');


      // use setTimeout() to execute
      //setTimeout( $(lendercheckboxid).trigger('click'), 1000);

      this.onCheckboxChange(this.App_lenderId, this.App_lenderName, true);

      $('#checkall').trigger('click');


    }
    else {
      //application end


      this.modalRef = this.confirmModalService.show(template, {
        class: 'modal-lg modal-dialog-centered', backdrop: 'static',
        keyboard: false
      });
    }

  }


  OpendeductionModelPopup(template: TemplateRef<any>, itemid: any, index: any) {
    debugger

    this.DeductiolenderValue = itemid;
    this.Deductiolender_Index = index;


    this.DeductionTextvalue=82;
    this.deductionfeedisplay = false;

    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  NoDeductionclick() {
    this.DeductionTextvalue = 82;
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    Lenderarraydata.at(this.Deductiolender_Index).get('DeductionfeeInputControl').setValue(this.DeductionTextvalue);
    this.decline();
  }


  savecustomerafterselection() {
    var customerindex = this.Customeradd_Index;
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    var lenderid = Lenderarraydata.value[this.Customeradd_Index].lenderkeyddl
    var LenderPayCall = Lenderarraydata.value[this.Customeradd_Index].LenderPayCall;
    var LenderInterestRate = Lenderarraydata.value[this.Customeradd_Index].LenderInterestRate;
    var TradeValue = Lenderarraydata.value[this.Customeradd_Index].ACVValueControl;
    var Lien = Lenderarraydata.value[this.Customeradd_Index].LoanAmountControl;
    var DownPayment = Lenderarraydata.value[this.Customeradd_Index].DownPaymentAmountControl;

    var dealerid = localStorage.getItem('DealerId');

    this.savecustomer(dealerid, lenderid, LenderPayCall, LenderInterestRate, TradeValue, Lien, DownPayment);

  }



  OpenAddclientModel(template: TemplateRef<any>, itemid: any, index: any) {

    debugger
    this.CustomeraddValue = itemid;
    this.Customeradd_Index = index;

    var dealerid = localStorage.getItem('DealerId');

    this.getCustomerlistbydealerid(dealerid);

    this.customermodalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  savecustomer(dealerid, lenderid, LenderPayCall, LenderInterestRate, TradeValue, Lien, DownPayment) {
    debugger;
    if (TradeValue == "") {
      TradeValue = "0";
    }
    if (Lien == "") {
      Lien = "0";
    }
    if (DownPayment == "") {
      DownPayment = "0";
    }
    var modelGroup = {
      "opcode": 1,
      "dealerid": dealerid,
      "LenderKey": lenderid,
      "PayCall": LenderPayCall,
      "InterestRate": LenderInterestRate,
      "customerid": this.selectedcustomerid,
      "TradeValue": TradeValue,
      "Lien": Lien,
      "DownPayment": DownPayment

    }
    this.manageInventoryService.savecustomers(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.getCustomerlistbydealerid(this.DealerIdvalue);
          this.decline();
          this.showNotification("success", "Customer information saved.")

        },
        error => {
        });
  }



  getCustomerlistbydealerid(dealerid) {
    var modelGroup = {
      "opcode": 1,
      "dealerid": dealerid
    }
    this.manageInventoryService.getcustomerBydealerid(modelGroup)
      .subscribe(
        data => {

          this.customerlist = data;



        },
        error => {
        });
  }

  selectedCustomer(data) {

    this.selectedcustomerid = data.target.value;
  }


  DeductionSelectvalue(data: any) {
    debugger;
    this.deductionfeedisplay = false;
    var isChecked = data.target.checked;

    this.Deductiondropdownvalue = data.target.value;


    var checkboxid = data.target.id;

    debugger
    var selectedvalue = this.Deductiondropdownvalue;

    if (selectedvalue == "1" && isChecked) {

      this.is_nonGarnishable_checked=false;
      var checkdeductionfee=this.DeductionTextvalue;

      this.DeductionTextvalue = (this.DeductionTextvalue - 10);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;

      if(this.DeductionTextvalue<40)
      {
        this.is_nonGarnishable_checked=true;
        this.DeductionTextvalue=checkdeductionfee;
      }

      this.notlessthanFourtydedcutionfee(10, checkboxid);

    }
    if (selectedvalue == "1" && !isChecked) {

      if(this.is_nonGarnishable_checked!=true)
      {
      this.DeductionTextvalue = (this.DeductionTextvalue + 10);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;
      this.notlessthanFourtydedcutionfee(10, checkboxid);
      }
    }

    if (selectedvalue == "2" && isChecked) {

      this.is_Self_Employed_checked=false;
      var checkdeductionfee=this.DeductionTextvalue;

      this.DeductionTextvalue = (this.DeductionTextvalue - 10);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;

if(this.DeductionTextvalue<40)
{
  this.is_Self_Employed_checked=true;
  this.DeductionTextvalue=checkdeductionfee;
}


      this.notlessthanFourtydedcutionfee(10, checkboxid);


    }
    if (selectedvalue == "2" && !isChecked)
    {
      if(this.is_Self_Employed_checked!=true)
      {
      this.DeductionTextvalue = (this.DeductionTextvalue + 10);

      this.beforeDeductionTextvalue=this.DeductionTextvalue ;
      this.notlessthanFourtydedcutionfee(10, checkboxid);
      }


    }

    if (selectedvalue == "3" && isChecked) {
      this.is_Out_Of_Service_checked=false;
        var checkdeductionfee=this.DeductionTextvalue;

      this.DeductionTextvalue = (this.DeductionTextvalue - 32);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;

if(this.DeductionTextvalue<40)
{
  this.is_Out_Of_Service_checked=true;
  this.DeductionTextvalue=checkdeductionfee;


}

      this.notlessthanFourtydedcutionfee(32, checkboxid);
    }
    if (selectedvalue == "3" && !isChecked) {

      if(this.is_Out_Of_Service_checked!=true)
      {
      this.DeductionTextvalue = (this.DeductionTextvalue + 32);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;
      this.notlessthanFourtydedcutionfee(32, checkboxid);
      }
    }

    if (selectedvalue == "4" && isChecked) {

      this.is_two_repossessions_checked=false;
      var checkdeductionfee=this.DeductionTextvalue;

      this.DeductionTextvalue = (this.DeductionTextvalue - 42);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;

if(this.DeductionTextvalue<40)
{
  this.is_two_repossessions_checked=true;
  this.DeductionTextvalue=checkdeductionfee;
}


      this.notlessthanFourtydedcutionfee(42, checkboxid);
    }
    if (selectedvalue == "4" && !isChecked) {

      if(this.is_two_repossessions_checked!=true)
      {
      this.DeductionTextvalue = (this.DeductionTextvalue + 42);
      this.beforeDeductionTextvalue=this.DeductionTextvalue ;
      this.notlessthanFourtydedcutionfee(42, checkboxid);
      }

    }



    this.DeductiolenderValue;

    // var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    // Lenderarraydata.at(this.Deductiolender_Index).get('DeductionfeeInputControl').setValue(this.DeductionTextvalue);

  }

  notlessthanFourtydedcutionfee(fee, checkboxid) {
    // if (this.DeductionTextvalue < 40) {
      if (this.beforeDeductionTextvalue < 40) {

      //alert("Your deduction fee can not less than 40");
      this.DeductionTextvalue=40;
      this.deductionfeedisplay = true;
     // this.DeductionTextvalue = this.DeductionTextvalue + fee;

    //  $("#" + checkboxid).prop("checked", false);
      return false;
    }
  }


  regioncheckboxpopup(template: TemplateRef<any>, itemid: any) {
    this.ItemCountregion = 0;
    for (let i = 0; i < this.regionlistName.length; i++) {
      if (this.regionlistName[i].IsSelected == true) {
        this.ItemCountregion = this.ItemCountregion + 1;
      }
    }
    this.ExtingItemCountregion = this.regionlistName.length;
    if (this.ExtingItemCountregion == this.ItemCountregion) {
      this.SelectedAllCheckregion = true;
    } else {
      this.SelectedAllCheckregion = false;
    }
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  PopupCustomViewTable_old(template: TemplateRef<any>, itemid: any, i: any) {
    debugger;
    this.buttoncolorChnageClick.nativeElement.click();
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;

    var dataarrayvalue = dataarray.value;

    this.CustomerviewTabledatasource = dataarrayvalue;


    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-xl modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  PopupgridCustomViewTable(status: any, index: any) {
    this.ListViewListPrice="false";
  }
  PopupgridHideCustomViewTable(status: any, index: any)
  {
    this.ListViewListPrice="true";
  }
  PopupCustomViewTable(status: any, index: any) {
    debugger;

    if (this.ViewDetails == 'List') {
    

      if ($('#listViewGrossPrice_' + index).css('display') == 'none') {
        this.ListViewListPrice="true";
      }
      else {
        this.ListViewListPrice="false";
      }

    } else
    {
      this.ListViewListPrice="true";
    }

    this.buttoncolorChnageClick.nativeElement.click();
    var tableid = "#usertable-" + index;

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var dataarray = Lenderarraydata.controls[index].get('inventaryarray') as FormArray;

    var sourcedata = dataarray.value

    dataarray.controls = [];

    //adding class for clue that customer button clicked
    //$(tableid).addClass("customerviewclass");


    var isclassexist = $(tableid).hasClass('customerviewclass');

    if (isclassexist == false) {
      $(tableid).addClass("customerviewclass");

      sourcedata.forEach((data, index) => {
        // element.page=1;
        // element.pageSize=10;

        data.isconditionhide = true

        data.isDealerCost = true

        data.isProjectedGross = true


        data.isMaxPaycall = true


        data.isRegionStatushide = true

        data.isCityStatushide = true


        data.isBodyStatushide = true

        data.isMaxPaycall = true

        data.isTrimhide = true


        data.isEffectiveCBB = true


        data.isTotalFLTV = true

        data.isTotalBLTV = true


        data.isTotalTLV = true

        // data.isLenderFee=true

        // data.isFrontDocFee=true

        // data.isBackDocFee=true

        // data.isPPSA=true

        data.isReserves = true

        //data.isTermPeriod=true


        // data.isTaxRate=true


        //  data.isTotalPrice=true


        // data.isTotalCost=true

        // data.isTotalTax=true

        //  data.isAmountToBeFinanced=true

        //  data.isAllowableFinance=true

        data.isMinFinance = true

        data.isMinPrice = true

        data.isMinCost = true

        data.isMinGross = true

        data.isMinTaxes = true

        data.isFLTVnew = true
        data.isFLTV = true
        data.isBLTV = true

        dataarray.push(this.fb.group(data))

      })





      $(tableid + " thead th.ConditionColumnStatus").hide();

      $("#usertable-" + index + " thead th.DealerCostColumnStatus").hide();

      // $("#usertable-"+index+ " thead th.PPSAcolumn").hide();

      $("#usertable-" + index + " thead th.Reservescolumn").hide();

      $("#usertable-" + index + " thead th.ProjectedGrossColumnStatus").hide();

      $("#usertable-" + index + " thead th.MaxPaycallColumnStatus").hide();

      $("#usertable-" + index + " thead th.RegionColumn").hide();

      $("#usertable-" + index + " thead th.CityColumn").hide();

      $("#usertable-" + index + " thead th.BodyColumn").hide();

      $("#usertable-" + index + " thead th.TrimColumn").hide();

      $("#usertable-" + index + " thead th.EffectiveCBBcolumn").hide();

      $("#usertable-" + index + " thead th.TotalFLTVcolumn").hide();

      $("#usertable-" + index + " thead th.TotalBLTVcolumn").hide();

      $("#usertable-" + index + " thead th.TotalTLVcolumn").hide();

      // $("#usertable-"+index+ " thead th.LenderFeecolumn").hide();

      //$("#usertable-"+index+ " thead th.FrontDocFeecolumn").hide();

      // $("#usertable-"+index+ " thead th.BackDocFeecolumn").hide();



      // $("#usertable-"+index+ " thead th.TermPeriodcolumn").hide();

      // $("#usertable-"+index+ " thead th.TaxRatecolumn").hide();

      // $("#usertable-"+index+ " thead th.TotalPricecolumn").hide();

      // $("#usertable-"+index+ " thead th.TotalCostcolumn").hide();

      // $("#usertable-"+index+ " thead th.TotalTaxcolumn").hide();

      //  $("#usertable-"+index+ " thead th.AmountToBeFinancedcolumn").hide();

      //  $("#usertable-"+index+ " thead th.AllowableFinancecolumn").hide();

      $("#usertable-" + index + " thead th.MinFinancecolumn").hide();

      $("#usertable-" + index + " thead th.MinPricecolumn").hide();

      $("#usertable-" + index + " thead th.MinCostcolumn").hide();

      $("#usertable-" + index + " thead th.MinGrosscolumn").hide();

      $("#usertable-" + index + " thead th.MinTaxescolumn").hide();

      $("#usertable-" + index + " thead th.FLTVnewcolumn").hide();

      $("#usertable-" + index + " thead th.FLTVcolumn").hide();

      $("#usertable-" + index + " thead th.BLTVcolumn").hide();

    }

    else {

      $(tableid).removeClass("customerviewclass");
      sourcedata.forEach((data, index) => {
        // element.page=1;
        // element.pageSize=10;

        data.isconditionhide = false
        data.isDealerCost = false
        data.isProjectedGross = false

        data.isMaxPaycall = false

        data.isRegionStatushide = false
        data.isCityStatushide = false

        data.isBodyStatushide = false

        data.isMaxPaycall = false

        data.isTrimhide = false


        data.isEffectiveCBB = false


        data.isTotalFLTV = false

        data.isTotalBLTV = false


        data.isTotalTLV = false

        // data.isLenderFee=false

        // data.isFrontDocFee=false

        // data.isBackDocFee=false

        // data.isPPSA=false

        data.isReserves = false

        //  data.isTermPeriod=false


        // data.isTaxRate=false


        data.isTotalPrice = false


        //  data.isTotalCost=false

        // data.isTotalTax=false

        // data.isAmountToBeFinanced=false

        //  data.isAllowableFinance=false

        data.isMinFinance = false

        data.isMinPrice = false

        data.isMinCost = false

        data.isMinGross = false

        data.isMinTaxes = false

        data.isFLTVnew = false
        data.isFLTV = false
        data.isBLTV = false

        dataarray.push(this.fb.group(data))

      })

      $(tableid + " thead th.ConditionColumnStatus").show();

      $("#usertable-" + index + " thead th.DealerCostColumnStatus").show();

      //  $("#usertable-"+index+ " thead th.PPSAcolumn").show();

      $("#usertable-" + index + " thead th.Reservescolumn").show();

      $("#usertable-" + index + " thead th.ProjectedGrossColumnStatus").show();

      $("#usertable-" + index + " thead th.MaxPaycallColumnStatus").show();

      $("#usertable-" + index + " thead th.RegionColumn").show();

      $("#usertable-" + index + " thead th.CityColumn").show();

      $("#usertable-" + index + " thead th.BodyColumn").show();

      $("#usertable-" + index + " thead th.TrimColumn").show();

      $("#usertable-" + index + " thead th.EffectiveCBBcolumn").show();

      $("#usertable-" + index + " thead th.TotalFLTVcolumn").show();

      $("#usertable-" + index + " thead th.TotalBLTVcolumn").show();

      $("#usertable-" + index + " thead th.TotalTLVcolumn").show();

      // $("#usertable-"+index+ " thead th.LenderFeecolumn").show();

      $("#usertable-" + index + " thead th.FrontDocFeecolumn").show();

      //  $("#usertable-"+index+ " thead th.BackDocFeecolumn").show();

      //  $("#usertable-"+index+ " thead th.PPSAcolumn").show();

      $("#usertable-" + index + " thead th.Reservescolumn").show();

      //  $("#usertable-"+index+ " thead th.TermPeriodcolumn").show();

      //  $("#usertable-"+index+ " thead th.TaxRatecolumn").show();

      //  $("#usertable-"+index+ " thead th.TotalPricecolumn").show();

      // $("#usertable-"+index+ " thead th.TotalCostcolumn").show();

      //  $("#usertable-"+index+ " thead th.TotalTaxcolumn").show();

      // $("#usertable-"+index+ " thead th.AmountToBeFinancedcolumn").show();

      //  $("#usertable-"+index+ " thead th.AllowableFinancecolumn").show();

      $("#usertable-" + index + " thead th.MinFinancecolumn").show();

      $("#usertable-" + index + " thead th.MinPricecolumn").show();

      $("#usertable-" + index + " thead th.MinCostcolumn").show();

      $("#usertable-" + index + " thead th.MinGrosscolumn").show();

      $("#usertable-" + index + " thead th.MinTaxescolumn").show();

      $("#usertable-" + index + " thead th.FLTVnewcolumn").show();

      $("#usertable-" + index + " thead th.FLTVcolumn").show();

      $("#usertable-" + index + " thead th.BLTVcolumn").show();


    }

//bind image on custom view start


dataarray.controls.forEach(element => {


  var commaimagevalue = element.get('imagesListDEMO').value;

  if (commaimagevalue != 'No') {
    const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

    var imagearray = [];

    imagesListDEMOcommatoarray.forEach(element => {
      var data = {
        image: element,
        thumbImage: element,
        // alt: 'alt of image',
        // title: 'title of image'
      }
      imagearray.push(data);

    }
    )
    element.get('imagedataarray').setValue(imagearray);

  } else {

    const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

    var imagearray = [];

    imagesListDEMOcommatoarray.forEach(element => {
      var data = {
        image: "../../assets/images/nofoundImage.png",
        thumbImage: "../../assets/images/nofoundImage.png",
        // alt: 'alt of image',
        // title: 'title of image'
      }
      imagearray.push(data);

    }
    )
    element.get('imagedataarray').setValue(imagearray);
  }

})

//bind image on custom view end


  }

  // getValues(val: any) {
  //   debugger;
  //   this.selectedDealerId = val.id;
  //   this.dealeridDropDown = val.id;
  //   this.selectedGroupId = val.dGroupId;
  // }


  openImagesModelPopUp(targetModal, VIN) {
    this.GetVehiclePhotosListByVinList(VIN);
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }



  AddDetailThinkMoney(lenderId: any) {

    var model = {
      "ClickedBy": this.useridValue,
      "LenderID": lenderId.toString()
    }
    this.manageInventoryService.addthinkKeyDetail(model)
      .subscribe(
        (data: any) => {

        },
        error => {
        });
  }

  RefreshImageList() {
    this.vehicleObject = [];
    this.customimagesrc = [];
  }

  ChangeViewfunction(value: any, index: any) {
    debugger;
    this.ViewDetails = value.toString();
    var check = value;
    if (check === "Grid") {
      // this.listViewValue="false";
      $("#usertable-" + index).show();
      $("#listView-" + index).hide();

    } else {
      $("#listView-" + index).show();
      $("#usertable-" + index).hide();

    }

  }

  GetVehiclePhotosListByVinList(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {

          this.customimagesrc = [];
          this.vehicleObject = [];
          if (data.length > 0) {
            this.errorshow = 'false';
            this.errorshowAuto = 'false';
            for (var i = 0; i < data.length; i++) {
              if (data[i].imageStatus == "1") {
                //this.vehicleObject=[];
                this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: '' })
              } else {
                this.customimagesrc.push({ imgsrc: data[i].imagesrc })
              }
            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }
          if (this.vehicleObject.length == 0) {
            this.errorshowAuto = 'true';
            this.errorMessageAuto = "No images for this Vehicle.";
          }

          if (this.customimagesrc.length == 0) {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }
  HideShowColumn(i: any) {


    // $('#Multiselectdrpdowncoulm_'+i).show();

    if ($('#Multiselectdrpdowncoulm_' + i).css('display') == 'none') {
      $('#Multiselectdrpdowncoulm_' + i).show();
    }
    else {
      $('#Multiselectdrpdowncoulm_' + i).hide();
    }

  }




  onCheckboxChange(lenderId: any, LenderName: any, obj: any) {
debugger;
var deductionfee=0;


if(LenderName=="Go Plan")
{
  deductionfee=82;
  this.ShowAdvce = "true"
 // var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
 // Lenderarraydata.at(this.Deductiolender_Index).get('DeductionfeeInputControl').setValue(this.DeductionTextvalue);

} else
{
  this.ShowAdvce = "false"
}

    // onCheckboxChange(lenderId: any, LenderName: any, obj: any,index:any,item:any) {
    //  this.selectedIndex = obj.target.checked ? index : undefined;


    // this.lenderlists.forEach(val => {
    //   if (val.id == item.id) val.isSelected = !val.isSelected;
    //   else {
    //     val.isSelected = false;
    //     this.DisplayFilters = "false";
    //     $('#' + "findlenderdiv_" + val.id).remove();
    //   }
    // });

    var ischeched = false;


    if (this.Index != "M" && obj == true) {
      ischeched = true;
    }
    else {
      ischeched = obj.currentTarget.checked;
    }


    var source = $('#AithrHubKey').val()

    if (source == 0) {
      $('#AllowableLossDiv_' + lenderId + '').show();

    } else {
      $('#AllowableLossDiv_' + lenderId + '').hide();
    }

    var datasearch =
      { searchtext: null };


    if (ischeched == true) {

      this.searchtablearray.push(datasearch);

      this.lenderIdValue = lenderId;
      localStorage.setItem('lenderIdValue', this.lenderIdValue);
      this.LenderNameValue = LenderName;
      this.DisplayFilters = "true";

      const lenderdatadetails = this.findCarForm.controls.LenderDetailsformarray as FormArray;
      lenderdatadetails.push(this.fb.group({
        searchtext: '',
        Lenderid: lenderId,
        LenderName: LenderName,
        lenderkeyddl: '',
        //  LenderPayCall:{disabled:false, value: lenderId},
        LenderPayCall: '',
        LenderInterestRate: '',
        ACVValueControl: '',
        LoanAmountControl: '',
        DownPaymentAmountControl: '',

        // // Filter for list
        // YearListFilterControl: '',
        // ModelListFilterControl: '',
        // TrimListFilterControl: '',
        // KmListFilterControl: '',
        // BodyListFilterControl: '',
        YearCheckFilter: '0',
        MakeCheckFilter: '0',
        ModelCheckFilter: '0',
        TrimCheckFilter: '0',
        // kmCheckInvenoryFilter: '',
        // BodycheckStylefilter: '',

        OverRideFLTVControl: '',
        OverrideTermControl: '',
        FLTVSetupControl: '',
        GPSFeeControl: '',
        hasGpsCheckBox: '',
       // IsLockListPrice:'',
        MaxAmountFinanceControl: '',
        AllowableLossControl: '',

        DeductionfeeInputControl: deductionfee,


        page: { disabled: false, value: 1 },
        pageSize: { disabled: false, value: 12 },
        // inventaryarray:this.fb.array([{'id':3,'name':'test'},{'id':7,'name':'test99'}])

        inventaryarray: this.fb.array([])
        // inventaryarray: this.fb.array([
        //   {
        //     lenderFee:'',
        //     imagedataarray:this.fb.array([])
        //   }

        // ])

        // name: {disabled:false, value: LenderName},
      }));


    }
    else {
      this.DisplayFilters = "false";
      $('#' + "findlenderdiv_" + lenderId).remove();
    }



    if (ischeched) {
      this.GetLenderKeys(this.lenderIdValue, LenderName);
    }

  }

  watchdata(test: any, i: any) {
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;


    var lenderId = Lenderarraydata.controls[i].value.inventaryarray.push({ 'id': 888, 'name': 'tes55t' }, { 'id': 765, 'name': 'te444st99' });

  }
  get LenderDetailsformarray(): FormArray {
    return this.findCarForm.get('LenderDetailsformarray') as FormArray;
  }

  // just a getter for your formarray
  get formArr() {
    return (this.findCarForm.get('LenderDetailsformarray') as FormArray).controls;
  }



  LenderInventoryformarray(): FormArray {
    return this.findCarForm.get('LenderDetailsformarray') as FormArray;
  }


  lenderinventory(empIndex: number): FormArray {
    var data = this.LenderInventoryformarray()
      .at(empIndex)
      .get('inventaryarray') as FormArray;

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var dataarray = Lenderarraydata.controls[empIndex].get('inventaryarray') as FormArray;

    return dataarray;
  }

  onCheckboxChange_old_one(lenderId: any, LenderName: any, obj: any) {
    // var toppingList =[
    //   { id: 0, name: 'Extra cheese' }]
    // this.lendercheckboxselectedarray.push(toppingList)

    this.lendercheckboxselectedarray.push(this.feature);

    if (obj.currentTarget.checked == true) {
      this.lenderIdValue = lenderId;
      localStorage.setItem('lenderIdValue', this.lenderIdValue);
      this.LenderNameValue = LenderName;
      this.DisplayFilters = "true";

      //start cloning
      const emails = document.querySelector('#findcarcloneid');
      const clone = emails.cloneNode(true);
      this.that = this;
      clone.childNodes[0].parentElement.addEventListener("click", this.getUnitsByDealerNames)
      var newelementid = "";

      for (var i = 0; i < clone.childNodes.length; i++) {
        if (i == 0) {
          clone.childNodes[i].parentElement.style.display = "block";
          clone.childNodes[i].parentElement.id.replace("findcarcloneid", "");
          clone.childNodes[i].parentElement.className += "findcarparentclass";
          newelementid = clone.childNodes[i].parentElement.id = "findcar_" + lenderId;
          // clone.childNodes[i].parentElement.addEventListener("click", this.getUnitsByDealerNames);
        }
      }
      document.querySelector('.Findcars-container').appendChild(clone);
      //end cloning
      // Add event listener

      $("#" + newelementid).find("label.lendername").text(this.LenderNameValue)

      //$(document).on("click",'.Findcars-container', this.getUnitsByDealerNames() );

      //myElement.bind( 'click', this.getUnitsByDealerNames());

      //$("#"+newelementid ).find( "label.lendername" ).addEventListener("click", this.getUnitsByDealerNames());

    }
    else {
      this.DisplayFilters = "false";
      $('#' + "findcar_" + lenderId).remove();
    }

    this.GetLenderKeys(this.lenderIdValue, LenderName);

  }




  // function for Getting Cars
  onCheckboxChange_old(lenderId: any, LenderName: any, obj: any) {
    if (obj.currentTarget.checked == true) {
      this.lenderIdValue = lenderId;
      localStorage.setItem('lenderIdValue', this.lenderIdValue);
      this.LenderNameValue = LenderName;
      this.DisplayFilters = "true";
    } else {
      this.DisplayFilters = "false";
    }
    this.GetLenderKeys(this.lenderIdValue, this.LenderNameValue);
  }


  CheckValidationPaycall(event, lenderid) {
    var selectedPayCallvalue = parseInt(event.target.value);

    if (selectedPayCallvalue > 0) {
      $("#findlenderdiv_" + lenderid).find("#TDBPCallID").removeClass("borderRed");

      $("#PayCallmsg_" + lenderid).css("display", "none");
    }

  }

  CheckValidationInterestRate(event, lenderid) {
    var selectedInterestRate = parseInt(event.target.value);

    if (selectedInterestRate > 0) {
      $("#findlenderdiv_" + lenderid).find("#TDIntRtID").removeClass("borderRed");
      $("#interestratemsg_" + lenderid).css("display", "none");
    }

  }

  Lenderdropdownchange(event, i, lenderid) {
debugger;
    var selectedlendervalue = 0;
    var lendername = "";
    if (this.Index == "M") {

      if(this.LenderNameValue!="Go Plan")
      {
      selectedlendervalue = parseInt(event.target.value)
      lendername = event.target.options[event.target.options.selectedIndex].text;
      }
      else{
        selectedlendervalue=event;
        lendername=this.LenderNameValue;

        var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

        Lenderarraydata.at(i).get('lenderkeyddl').setValue(selectedlendervalue);
      }

      if(this.LenderNameValue=="RifCo" && lendername=="Tier 5+ - (125.00% )")
        {
          var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
          Lenderarraydata.at(i).get('LenderInterestRate').setValue("29.95");        
          Lenderarraydata.at(i).get('LenderInterestRate').disable();

        } else
        {
          var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

          
        //  Lenderarraydata.at(i).get('LenderInterestRate').setValue('');
         // this.findCarForm.get('LenderDetailsformarray').at(i).get('LenderInterestRate').disable();
          Lenderarraydata.at(i).get('LenderInterestRate').enable();
        }

      if (selectedlendervalue > 0) {
        $("#findlenderdiv_" + lenderid).find(".Lenderkeyddl").removeClass("borderRed");
        $("#lenderdropdownmsg_" + lenderid).css("display", "none");
      }

    }

    else {

      selectedlendervalue = this.App_plantypeId;
      lendername = this.App_lenderName;
    }

    var modelGroup = {
      "id": selectedlendervalue,
      "LenderName": lendername
    }




    this.manageInventoryService.getLenderKeysFLTVValue(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          // values for Set in FLTV
          var iddiv = "#findlenderdiv_" + lenderid;
          var iddropdown = "#FLTVSetup_" + lenderid;
          var finalidfordropdown = iddiv + " " + iddropdown;
          var overrideMaxTermsid = "#OverrideTerm_" + lenderid; 
          var overrideMaxTermsids = iddiv + overrideMaxTermsid; 
          $(finalidfordropdown).val("")
          $(finalidfordropdown).val(data[0].fltv)

          if(data[0].maxTerm>0 && data[0].maxTerm !=null)
            {
              $(overrideMaxTermsid).val(data[0].maxTerm)
              //this.findCarForm.get.
            } else
            {
              $(overrideMaxTermsid).val('');
            }

          // Value Setting in GPS AND GPS FEE

          var GPSFeeValue = "#findlenderdiv_" + lenderid;
          var gpsiddropdown = "#GPSFee_" + lenderid;
          var finalGPSidfordropdown = GPSFeeValue + " " + gpsiddropdown;
          $(finalGPSidfordropdown).val("")
          $(finalGPSidfordropdown).val(data[0].gpsFee);
          this.gpsFeeValueSend = data[0].gpsFee


          //var GPSFeeValue ="#findlenderdiv_"+lenderid;
          var checkgps = "#hasGPSValue_" + lenderid;
          var CheckfinalGPSidfordropdown = GPSFeeValue + " " + checkgps;
          // $(CheckfinalGPSidfordropdown).val("")
          // $(CheckfinalGPSidfordropdown).val(data[0].hasGPSFee);

          // var GPSFeeCheckBoxValue ="#findlenderdiv_"+lenderid;
          // var checkgpsiddropdown="#GPSFeeCheck_"+lenderid;
          // var checkfinalGPSidfordropdown=GPSFeeCheckBoxValue+" "+ checkgpsiddropdown;

          if (data[0].hasGPSFee == 0) {
            this.hasGPSValue = false;
            this.hasGPSValueCheck = 0;
            this.hideGps = "false";
            $(CheckfinalGPSidfordropdown).prop('checked', false);
          } else {
            this.hasGPSValue = true;
            this.hasGPSValueCheck = 1;
            this.hideGps = "true";
            $(CheckfinalGPSidfordropdown).prop('checked', true);
          }

          // value Setting in MAX amount Finances

          var MaxAmountFinanceValue = "#MaxAmountFinance_" + lenderid;

          $(MaxAmountFinanceValue).addClass("borderRed");

          $(MaxAmountFinanceValue).val("")
          $(MaxAmountFinanceValue).val(data[0].maxLoanAmount);

        },
        error => {
        });
  }

  getUnitsByDealerNames(index) {
    this.ListViewListPrice="true";
    this.displayList = "true";
    //this.selectedValue=12;
   

    $("#pageLength_" + index).val(12);
    //this.GetAllInventoryListbyParametersfinal();
    this.GetAllInventoryListbyParameterForBothFindCars(index);
  }


  GetLenderKeys(LenderId: any, LenderName: any) {
    debugger;
    var modelGroup = {
      "id": LenderId,
      "LenderName": LenderName
    }
    this.manageInventoryService.getLenderKeys(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderKeyList = [];
          this.lenderKeyList = data;
          //binf lender drop down
          $("#findlenderdiv_" + LenderId).find("select.Lenderkeyddl").html("");
          $("#findlenderdiv_" + LenderId).find("select.Lenderkeyddl").append($("<option></option>").val(0).html('Select Lender key'));

         var optionid="";
          this.lenderKeyList.forEach(element => {
            $("#findlenderdiv_" + LenderId).find("select.Lenderkeyddl").append($("<option></option>").val(element.id).html(element.planType));
            optionid=element.id;

          });



          if(LenderName=="Go Plan")
          {
           var index= $("#DeductionfeeIndex_" + LenderId).val();
           var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    //need verify i commented belwo code
      this.goplanindex_forrate=index;


           //set defualt drop down
           $("#lenderdropdown_"+LenderId+ " option[value="+optionid+"]").attr("selected", "selected");


           Lenderarraydata.at(index).get('DeductionfeeInputControl').setValue(this.DeductionTextvalue);

           var lenderdropdownId = '#lenderdropdown_' + LenderId;


        //set interest rate
          var interestrate=0.0;
          if(this.province=12||this.province==10024)
          {
            interestrate=31.9;
          }
          else{
            interestrate=29.50;
          }
          Lenderarraydata.at(index).get('LenderInterestRate').setValue(interestrate);

          $("#findlenderdiv_"+LenderId).find("#TDIntRtID").prop('readonly', true);
          $("#findlenderdiv_"+LenderId).find("#TDIntRtID").css('background', 'rgb(96 90 90)');

          // $(lenderdropdownId).trigger('click');

           this.Lenderdropdownchange(optionid,index, LenderId);

          // $("#lenderdropdown_1060 option[value='198']").attr("selected", "selected");
          }

          //THIS CODE FOR APPLICATION REDIRECTION

          if (this.Index != "M") {
            var paycall = '#findlenderdiv_' + this.App_lenderId + " " + "#TDBPCallID";
            var intersetRate = '#findlenderdiv_' + this.App_lenderId + " " + "#TDIntRtID";


            var lenderdropdownId = '#lenderdropdown_' + this.App_lenderId;

            var lenderdropdown = '#lenderdropdown_' + this.App_lenderId + ":eq(0)";

            var TradeValueId = '#findlenderdiv_' + this.App_lenderId + " " + "#TDTradeInID";
            var LienIDValueId = '#findlenderdiv_' + this.App_lenderId + " " + "#TDLienID";

            $(paycall).val(this.App_payCall);
            $(intersetRate).val(this.App_interestRate);

            $(lenderdropdown).val(this.App_plantypeId);

            $(lenderdropdownId).trigger('click');


            $(TradeValueId).val(this.App_Tradevalue);
            $(LienIDValueId).val(this.App_LienValue);

            this.Lenderdropdownchange(this.App_plantypeId, 0, this.App_lenderId);

            var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

            var i = 0;
            //   Lenderarraydata.controls[i].value.Lenderid;
            //  Lenderarraydata.controls[i].value.lenderkeyddl=this.App_plantypeId;
            //   Lenderarraydata.controls[i].value.LenderName;
            //   Lenderarraydata.controls[i].value.LenderPayCall=this.App_payCall;
            //    Lenderarraydata.controls[i].value.LenderInterestRate=this.App_interestRate;

            Lenderarraydata.at(i).get('lenderkeyddl').setValue(this.App_plantypeId);
            Lenderarraydata.at(i).get('LenderPayCall').setValue(this.App_payCall);
            Lenderarraydata.at(i).get('LenderInterestRate').setValue(this.App_interestRate);

            Lenderarraydata.at(i).get('ACVValueControl').setValue(this.App_Tradevalue);
            Lenderarraydata.at(i).get('LoanAmountControl').setValue(this.App_LienValue);


            this.getUnitsByDealerNames(0);

            var lendercheckboxid = '#lenderchck_' + this.App_lenderId;

            //  $(lendercheckboxid).prop( "checked", true );

            //

          }

        },
        error => {
        });


  }
  // send paramtere to other page
  gotoBuildPage(vin: any, listPrice: any, totalTaxes: any, allowableFinance: any, FrontDocFee: any, fltv: any, bltv: any, 
    effectiveCBB: any, dealerCost: any, termPeriod: any,beGrossAmount: any,feGrossAmount: any,othGrossAmount: any,licenceFee: any,isListPriceLock: any,
    feAccessory1: any,feAccessory2: any,feAccessory3: any,feMaximizedAmount: any,isDocFeeFront: any, i: any) {

    this.selectedbuildbuttoIndex = i;

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    var lenderId = Lenderarraydata.controls[i].value.Lenderid;

    var LenderKeyName = $("#findlenderdiv_" + lenderId).find("select.Lenderkeyddl option:selected").text();
    var Lenderkeyddl = Lenderarraydata.controls[i].value.lenderkeyddl;
    var LenderName = Lenderarraydata.controls[i].value.LenderName;
    var LenderPayCall = Lenderarraydata.controls[i].value.LenderPayCall;
    var LenderInterestRate = Lenderarraydata.controls[i].value.LenderInterestRate;
    var ACVValueControl = Lenderarraydata.controls[i].value.ACVValueControl;
    var LoanAmountControl = Lenderarraydata.controls[i].value.LoanAmountControl;
    var DownPaymentAmountControl = Lenderarraydata.controls[i].value.DownPaymentAmountControl;

    var OverRideFLTV = Lenderarraydata.controls[i].value.OverRideFLTVControl;

    //var OverrideTerm = Lenderarraydata.controls[i].value.OverrideTermControl;
    var OverrideTerm = $('#OverrideTerm_' + lenderId + '').val();


    var allowableLoss = Lenderarraydata.controls[i].value.AllowableLossControl;

    var deductionfee = Lenderarraydata.controls[i].value.DeductionfeeInputControl;

    var maxAmountFinance = $('#MaxAmountFinance_' + lenderId + '').val();

    if (maxAmountFinance == null || maxAmountFinance === "") {
      maxAmountFinance = 0
    }
    if (allowableLoss == null || allowableLoss === "") {
      allowableLoss = 0
    }

    if (OverRideFLTV > 0) {
      fltv = OverRideFLTV;
    } else {
      fltv = fltv;
    }

    if (DownPaymentAmountControl == "") {
      DownPaymentAmountControl = "0"
    }
    if (LenderPayCall == "") {
      LenderPayCall = "0"
    }
    if (OverRideFLTV == "") {
      OverRideFLTV = "0"
    }
    if (LenderInterestRate == "") {
      LenderInterestRate = "0"
    }
    if (LoanAmountControl == "") {
      LoanAmountControl = "0"
    }
    if (ACVValueControl == "") {
      ACVValueControl = "0"
    }
    let builparameter = {
      LenderId: lenderId,
      DealerId: this.dealeridDropDown,
      LenderKey: Lenderkeyddl,
      ProvinceId: "11",
      GroupNameId: this.groupIdPage,
      PayCall: LenderPayCall,
      InterestRate: LenderInterestRate,
      tradevalue: ACVValueControl,
      LoanAmount: LoanAmountControl,
      DownPayment: DownPaymentAmountControl,
      vin: vin, listPrice: listPrice, groupName: this.SelectedGroupName,
      totalTaxes: totalTaxes,
      IsAithrHub: this.AithrHubKey,
      DealerIDList: this.delaeridforserver,
      LenderNameValue: LenderName,
      PlanType: LenderKeyName,
      allowableFinance: allowableFinance,
      frontDocFee: FrontDocFee,
      fltv: fltv,
      bltv: bltv,
      RegionIDList: this.regionidforserver,
      gpsFee: this.gpsFeeValueSend,
      isTaxExempt: this.isTaxValue,
      effectiveCBB: effectiveCBB,
      dealerCost: dealerCost,
      allowableLoss: allowableLoss,
      maxAmountFinance: maxAmountFinance,
      termPeriod: termPeriod,
      // from Credit App
      applicationId: this.applicationId,
      creditorId: this.creditorId,
      beGrossAmount: beGrossAmount,
      feGrossAmount: feGrossAmount,
      othGrossAmount: othGrossAmount,
      licenceFee: licenceFee,
      isListPriceLock: isListPriceLock,
      feAccessory1: feAccessory1,
      feAccessory2: feAccessory2,
      feAccessory3: feAccessory3,
      feMaximizedAmount: feMaximizedAmount,
      isDocFeeFront: isDocFeeFront,

    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builparameter' + i, JSON.stringify(builparameter));


    //logic for check multiple build start

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var InventorListforbuild = Lenderarraydata.value;

    //var BuildlistforOpenMultipltab=[];
    this.BuildlistforOpenMultipltab = [];
    InventorListforbuild.forEach((currentValue, index) => {

      var datavinindex = currentValue.inventaryarray.find(x => x.vin == vin);


      if (datavinindex != undefined) {
        datavinindex.index = index;
        this.BuildlistforOpenMultipltab.push(datavinindex);
      }

    });

    //here we will check client want to be open more than one tab or not

    if (this.BuildlistforOpenMultipltab != null && this.BuildlistforOpenMultipltab.length > 1) {
      this.BuildlistforOpenMultipltab.forEach(Element => {

        if (Element.index == this.selectedbuildbuttoIndex) {
          this.GetMultipleTabArrayTab(Element, Element.index);

        }
      })
      // this.buildCarConfirmModelbuttonToBeClicked.nativeElement.click();
      // return false;
    }
    else {
      this.BuildlistforOpenMultipltab.forEach(Element => {

        if (Element.index == this.selectedbuildbuttoIndex) {
          this.GetMultipleTabArrayTab(Element, Element.index);

        }
      })
    }

    //logic for multiple build end

  }

  downloadPdf(i: any) {


    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var InventorListforbuild = Lenderarraydata.value;

    var prepare = [];
    InventorListforbuild[i].inventaryarray.forEach(e => {
      var tempObj = [];

      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYear);
      tempObj.push(e.mileage);
      tempObj.push(e.make);

      tempObj.push(e.model);
      tempObj.push(e.condition);
      tempObj.push(e.listedPrice);

      tempObj.push(e.dealerCost);
      tempObj.push(e.effectiveCBB);
      tempObj.push(e.effectiveCBB * e.fltv);

      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['vin', 'stockNumber', 'Year', 'mileage', 'make', 'model', 'condition', 'listedPrice', 'dealerCost', 'effectiveCBB', 'Total ftv']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('CarList' + '.pdf');


  }

  printTable(i: any) {

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var InventorListforbuild = Lenderarraydata.value;

    var prepare = [];
    InventorListforbuild[i].inventaryarray.forEach(e => {
      var tempObj = [];

      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYear);
      tempObj.push(e.mileage);
      tempObj.push(e.make);

      tempObj.push(e.model);
      tempObj.push(e.condition);
      tempObj.push(e.listedPrice);

      tempObj.push(e.dealerCost);
      tempObj.push(e.effectiveCBB);
      // tempObj.push(e.totalFLTV);

      prepare.push(tempObj);
    });


    var table = '<table><thead><tr><th>VIN</th><th>VIN</th><th>Stock Number</th><th>Year</th><th>Mileage</th><th>Make</th><th>Model</th><th>Condition</th><th>listed Price</th><th>DealerCost</th><th>EffectiveCBB</th>/tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  MakeCheck(event: any) {
    const value = event.target.value;
    if (value && (value.length > 50)) {
      event.target.value = value.slice(0, 50);
    }
  }
  ModelCheck(event: any) {
    const value = event.target.value;
    if (value && (value.length > 50)) {
      event.target.value = value.slice(0, 50);
    }
  }

  BodyCheck(event: any) {
    const value = event.target.value;
    if (value && (value.length > 50)) {
      event.target.value = value.slice(0, 50);
    }
  }

  SeatCheck(event: any) {
    const value = event.target.value;
    if (value && (value.length > 4)) {
      event.target.value = value.slice(0, 4);
    }
  }



  restrictInputToThreeDigits(event: any) {
    const value = event.target.value;
    if (value && (value.length > 3 || parseInt(value) > 999)) {
      event.target.value = value.slice(0, 3);
    }
  }

  OpenMultipletab() {
debugger;
    this.BuildlistforOpenMultipltab.forEach(Element => {

      this.GetMultipleTabArrayTab(Element, Element.index);

    })

    this.decline();

  }



  deductionfeeYes()
  {
    debugger;
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    Lenderarraydata.at(this.Deductiolender_Index).get('DeductionfeeInputControl').setValue(this.DeductionTextvalue);
    this.decline();
  }

  Nodeductionfee()
  {
    this.decline();
  }

  checkTaxValue(values: any): void {
    if (values.currentTarget.checked == true) {
      this.isTaxValue = true;
    } else {
      this.isTaxValue = false;
    }
  }

  
  

  checkValue(values: any): void {

    if (values.currentTarget.checked == true) {
      this.advanceFilter = "true";
    } else {
      this.advanceFilter = "false";
    }
  }

  CheckLocakPrice(values: any): void {

    if (values.currentTarget.checked == true) {
      this.lockPriceValue = 1;
    } else {
      this.lockPriceValue = 0;
    }
  }

  NoForMultipleTab() {
    this.BuildlistforOpenMultipltab.forEach(Element => {

      if (Element.index == this.selectedbuildbuttoIndex) {
        this.GetMultipleTabArrayTab(Element, Element.index);

      }
    })

    this.decline();

  }


  ///this function gt value according forcontrolid

  GetMultipleTabArrayTab(Matcheddata: any, i: any) {

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    var lenderId = Lenderarraydata.controls[i].value.Lenderid;
    var LenderKeyName = $("#findlenderdiv_" + lenderId).find("select.Lenderkeyddl option:selected").text();
    var Lenderkeyddl = Lenderarraydata.controls[i].value.lenderkeyddl;
    var LenderName = Lenderarraydata.controls[i].value.LenderName;
    var LenderPayCall = Lenderarraydata.controls[i].value.LenderPayCall;
    var LenderInterestRate = Lenderarraydata.controls[i].value.LenderInterestRate;
    var ACVValueControl = Lenderarraydata.controls[i].value.ACVValueControl;
    var LoanAmountControl = Lenderarraydata.controls[i].value.LoanAmountControl;
    var DownPaymentAmountControl = Lenderarraydata.controls[i].value.DownPaymentAmountControl;


    var allowableLoss = Lenderarraydata.controls[i].value.AllowableLossControl;
    var deductionfee = Lenderarraydata.controls[i].value.DeductionfeeInputControl;

    var maxAmountFinance = $('#MaxAmountFinance_' + lenderId + '').val();

    if (maxAmountFinance == null || maxAmountFinance === "") {
      maxAmountFinance = 0
    }
    if (allowableLoss == null || allowableLoss === "") {
      allowableLoss = 0
    }

    if (DownPaymentAmountControl == "") {
      DownPaymentAmountControl = "0"
    }
    if (LenderPayCall == "") {
      LenderPayCall = "0"
    }
    if (LenderInterestRate == "") {
      LenderInterestRate = "0"
    }
    if (LoanAmountControl == "") {
      LoanAmountControl = "0"
    }
    if (ACVValueControl == "") {
      ACVValueControl = "0"
    }
    var applicationId = "0";

    if (this.applicationId == "") {
      applicationId = "0";
    } else {
      applicationId = this.applicationId;
    }

    var creditorId = "0";

    if (this.creditorId == "") {
      creditorId = "0";
    } else {
      creditorId = this.creditorId;
    }


    let builparameter = {
      LenderId: lenderId,
      DealerId: this.dealeridDropDown,
      LenderKey: Lenderkeyddl,
      ProvinceId: this.findCarForm.controls.province.value,
      GroupNameId: this.groupIdPage,
      PayCall: LenderPayCall,
      InterestRate: LenderInterestRate,
      tradevalue: ACVValueControl,
      LoanAmount: LoanAmountControl,
      DownPayment: DownPaymentAmountControl,
      vin: Matcheddata.vin,
      listPrice: Matcheddata.listedPrice,
      groupName: this.SelectedGroupName,
      totalTaxes: Matcheddata.totalTaxes,
      IsAithrHub: this.AithrHubKey,
      DealerIDList: this.delaeridforserver,
      LenderNameValue: LenderName,
      PlanType: LenderKeyName,
      allowableFinance: Matcheddata.allowableFinance,
      frontDocFee: Matcheddata.frontDocFee,
      fltv: Matcheddata.fltv,
      bltv: Matcheddata.bltv,
      RegionIDList: this.regionidforserver,
      gpsFee: this.gpsFeeValueSend,
      isTaxExempt: this.isTaxValue,
      effectiveCBB: Matcheddata.effectiveCBB,
      dealerCost: Matcheddata.dealerCost,
      allowableLoss: allowableLoss,
      maxAmountFinance: maxAmountFinance,
      termPeriod: Matcheddata.termPeriod,
      applicationId: applicationId,
      creditorId: creditorId,
      deductionfee: deductionfee,
      beGrossAmount: Matcheddata.beGrossAmount,
      feGrossAmount: Matcheddata.feGrossAmount,
      othGrossAmount: Matcheddata.othGrossAmount,
      licenceFee: Matcheddata.licenceFee,
      isListPriceLock: Matcheddata.isListPriceLock,
      feAccessory1: Matcheddata.feAccessory1,
      feAccessory2: Matcheddata.feAccessory2,
      feAccessory3: Matcheddata.feAccessory3,
      feMaximizedAmount: Matcheddata.feMaximizedAmount,
      isDocFeeFront: Matcheddata.isDocFeeFront
    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builparameter' + i, JSON.stringify(builparameter));
    this.openNewWindow(builparameter.vin, i);
  }

  handlePageSizeChange(event,index) {
    this.pageSize = event.target.value;
    // this.pageSize = event.target.value;
    this.page = 1;
    this.GetAllInventoryListbyParameterForBothFindCars(index);
  }
  ClearDealer(event) {
    if (this.dealerlistName.length > 1) {
      this.buttonToBeClicked.nativeElement.click();
    }
    //this.buttonToBeClicked.nativeElement.click();
  }
  CheckClick() {

    var check = this.dealersmultiselectarray;

    this.dealerlistName.forEach(obj => {

      obj.IsSelected = false;

    });

    this.dealersmultiselectarray.forEach(childObj => {

      var b = this.dealerlistName.find(e => e.id == childObj.id)
      b.IsSelected = true;

    });


    if (this.dealerlistName.length > 1) {
      this.buttonToBeClicked.nativeElement.click();
    }

  }

  ClearRegion(event) {
    this.regionbuttonToBeClicked.nativeElement.click();
  }
  CheckRegionClick() {


    this.regionlistName.forEach(obj => {

      obj.IsSelected = false;

    });

    this.regionmultiselectarray.forEach(childObj => {

      var b = this.regionlistName.find(e => e.globalCodeID == childObj.globalCodeID)
      b.IsSelected = true;

    });


    this.regionbuttonToBeClicked.nativeElement.click();
  }
  openNewWindow(vin: any, i: any): void {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = '#/build-car/' + i;
    link.click();
    link.remove();
  }

  HideColumns() {
    this.DealerCostColumnStatus = "false";
    this.ListedPriceColumnStatus = "false";
    this.ProjectedGrossColumnStatus = "false";
  }

  ngDoCheck() {


  }


  onItemSelect(item: any, index: any) {

    var tableid = "#usertable-" + index;

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var dataarray = Lenderarraydata.controls[index].get('inventaryarray') as FormArray;

    var sourcedata = dataarray.value

    dataarray.controls = [];
    //  dataarray.reset()

    //  dataarray.push(new FormGroup({}));

    sourcedata.forEach((data, index) => {
      // element.page=1;
      // element.pageSize=10;
      if (item.item_text == "Condition") {
        data.isconditionhide = true
      }
      if (item.item_text == "ListedPrice") {
        data.isListedPrice = true
      }
      if (item.item_text == "DealerCost") {
        data.isDealerCost = true

      }
      if (item.item_text == "ProjectedGross") {
        data.isProjectedGross = true

      }
      if (item.item_text == "MaxPaycall") {

        data.isMaxPaycall = true

      }
      /////
      if (item.item_text == "Region") {

        data.isRegionStatushide = true

      }
      if (item.item_text == "City") {
        data.isCityStatushide = true
      }
      if (item.item_text == "Body") {

        data.isBodyStatushide = true

      }
      if (item.item_text == "MaxPaycall") {

        data.isMaxPaycall = true

      }
      if (item.item_text == "Trim") {

        data.isTrimhide = true

      }
      if (item.item_text == "EffectiveCBB") {

        data.isEffectiveCBB = true

      }
      if (item.item_text == "TotalFLTV") {

        data.isTotalFLTV = true

      }
      if (item.item_text == "TotalBLTV") {

        data.isTotalBLTV = true

      }
      if (item.item_text == "TotalTLV") {

        data.isTotalTLV = true

      }
      if (item.item_text == "LenderFee") {

        data.isLenderFee = true

      }
      if (item.item_text == "FrontDocFee") {

        data.isFrontDocFee = true

      }
      if (item.item_text == "BackDocFee") {

        data.isBackDocFee = true

      }
      if (item.item_text == "PPSA") {

        data.isPPSA = true

      }
      if (item.item_text == "Reserves") {

        data.isReserves = true

      }
      if (item.item_text == "TermPeriod") {

        data.isTermPeriod = true

      }
      if (item.item_text == "TaxRate") {

        data.isTaxRate = true

      }
      if (item.item_text == "TotalPrice") {

        data.isTotalPrice = true

      }
      if (item.item_text == "TotalCost") {

        data.isTotalCost = true

      }
      if (item.item_text == "TotalTax") {

        data.isTotalTax = true

      }
      //

      if (item.item_text == "AmountToBeFinanced") {

        data.isAmountToBeFinanced = true

      }
      if (item.item_text == "AllowableFinance") {

        data.isAllowableFinance = true

      }
      if (item.item_text == "MinFinance") {

        data.isMinFinance = true

      }
      if (item.item_text == "MinPrice") {

        data.isMinPrice = true

      }
      if (item.item_text == "MinCost") {

        data.isMinCost = true

      }
      if (item.item_text == "MinGross") {

        data.isMinGross = true

      }
      if (item.item_text == "MinTaxes") {

        data.isMinTaxes = true

      }
      if (item.item_text == "FLTVnew") {

        data.isFLTVnew = true

      }
      if (item.item_text == "FLTV") {

        data.isFLTV = true

      }
      if (item.item_text == "BLTV") {

        data.isBLTV = true

      }



      dataarray.push(this.fb.group(data))

    })


    // var datavalue=dataarray.value;
    if (item.item_text == "Condition") {

      $(tableid + " thead th.ConditionColumnStatus").hide();
      // $(tableid +" tbody td.ConditionColumnStatus").hide();

      // dataarray.value.push({ConditionColumnStatus:1});

      // datavalue.find(item => item.id == 0).id = 1;

    }
    if (item.item_text == "ListedPrice") {
      $("#usertable-" + index + " thead th.ListedPriceColumnStatus").hide();
      //$("#usertable-"+index+ " tbody td.ListedPriceColumnStatus").hide();
    }
    if (item.item_text == "DealerCost") {
      $("#usertable-" + index + " thead th.DealerCostColumnStatus").hide();
      // $("#usertable-"+index+ " tbody td.DealerCostColumnStatus").hide();
    }
    if (item.item_text == "ProjectedGross") {
      $("#usertable-" + index + " thead th.ProjectedGrossColumnStatus").hide();
      // $("#usertable-"+index+ " tbody td.ProjectedGrossColumnStatus").hide();
    }
    if (item.item_text == "MaxPaycall") {
      $("#usertable-" + index + " thead th.MaxPaycallColumnStatus").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    ////////////////
    if (item.item_text == "Region") {
      $("#usertable-" + index + " thead th.RegionColumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "City") {
      $("#usertable-" + index + " thead th.CityColumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "Body") {
      $("#usertable-" + index + " thead th.BodyColumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "Trim") {
      $("#usertable-" + index + " thead th.TrimColumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "EffectiveCBB") {
      $("#usertable-" + index + " thead th.EffectiveCBBcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalFLTV") {
      $("#usertable-" + index + " thead th.TotalFLTVcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalBLTV") {
      $("#usertable-" + index + " thead th.TotalBLTVcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalTLV") {
      $("#usertable-" + index + " thead th.TotalTLVcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "LenderFee") {
      $("#usertable-" + index + " thead th.LenderFeecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "FrontDocFee") {
      $("#usertable-" + index + " thead th.FrontDocFeecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "BackDocFee") {
      $("#usertable-" + index + " thead th.BackDocFeecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "PPSA") {
      $("#usertable-" + index + " thead th.PPSAcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "Reserves") {
      $("#usertable-" + index + " thead th.Reservescolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TermPeriod") {
      $("#usertable-" + index + " thead th.TermPeriodcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TaxRate") {
      $("#usertable-" + index + " thead th.TaxRatecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalPrice") {
      $("#usertable-" + index + " thead th.TotalPricecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalCost") {
      $("#usertable-" + index + " thead th.TotalCostcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalTax") {
      $("#usertable-" + index + " thead th.TotalTaxcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "AmountToBeFinanced") {
      $("#usertable-" + index + " thead th.AmountToBeFinancedcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "AllowableFinance") {
      $("#usertable-" + index + " thead th.AllowableFinancecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "MinFinance") {
      $("#usertable-" + index + " thead th.MinFinancecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinPrice") {
      $("#usertable-" + index + " thead th.MinPricecolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinCost") {
      $("#usertable-" + index + " thead th.MinCostcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinGross") {
      $("#usertable-" + index + " thead th.MinGrosscolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinTaxes") {
      $("#usertable-" + index + " thead th.MinTaxescolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "FLTVnew") {
      $("#usertable-" + index + " thead th.FLTVnewcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "FLTV") {
      $("#usertable-" + index + " thead th.FLTVcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "BLTV") {
      $("#usertable-" + index + " thead th.BLTVcolumn").hide();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }



    //  console.log('onItemSelect', item);
  }
  onItemDeSelect(item: any, index: any) {



    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

    var dataarray = Lenderarraydata.controls[index].get('inventaryarray') as FormArray;

    var sourcedata = dataarray.value

    dataarray.controls = [];
    //  dataarray.reset()

    //  dataarray.push(new FormGroup({}));

    //  dataarray.push(new FormGroup({}));

    sourcedata.forEach((data, index) => {
      // element.page=1;
      // element.pageSize=10;
      if (item.item_text == "Condition") {
        data.isconditionhide = false
      }
      if (item.item_text == "ListedPrice") {
        data.isListedPrice = false
      }
      if (item.item_text == "DealerCost") {
        data.isDealerCost = false

      }
      if (item.item_text == "ProjectedGross") {
        data.isProjectedGross = false

      }
      if (item.item_text == "MaxPaycall") {

        data.isMaxPaycall = false

      }
      /////
      if (item.item_text == "Region") {

        data.isRegionStatushide = false

      }
      if (item.item_text == "City") {
        data.isCityStatushide = false
      }
      if (item.item_text == "Body") {

        data.isBodyStatushide = false

      }
      if (item.item_text == "MaxPaycall") {

        data.isMaxPaycall = false

      }
      if (item.item_text == "Trim") {

        data.isTrimhide = false

      }
      if (item.item_text == "EffectiveCBB") {

        data.isEffectiveCBB = false

      }
      if (item.item_text == "TotalFLTV") {

        data.isTotalFLTV = false

      }
      if (item.item_text == "TotalBLTV") {

        data.isTotalBLTV = false

      }
      if (item.item_text == "TotalTLV") {

        data.isTotalTLV = false

      }
      if (item.item_text == "LenderFee") {

        data.isLenderFee = false

      }
      if (item.item_text == "FrontDocFee") {

        data.isFrontDocFee = false

      }
      if (item.item_text == "BackDocFee") {

        data.isBackDocFee = false

      }
      if (item.item_text == "PPSA") {

        data.isPPSA = false

      }
      if (item.item_text == "Reserves") {

        data.isReserves = false

      }
      if (item.item_text == "TermPeriod") {

        data.isTermPeriod = false

      }
      if (item.item_text == "TaxRate") {

        data.isTaxRate = false

      }
      if (item.item_text == "TotalPrice") {

        data.isTotalPrice = false

      }
      if (item.item_text == "TotalCost") {

        data.isTotalCost = false

      }
      if (item.item_text == "TotalTax") {

        data.isTotalTax = false

      }
      //

      if (item.item_text == "AmountToBeFinanced") {

        data.isAmountToBeFinanced = false

      }
      if (item.item_text == "AllowableFinance") {

        data.isAllowableFinance = false

      }
      if (item.item_text == "MinFinance") {

        data.isMinFinance = false

      }
      if (item.item_text == "MinPrice") {

        data.isMinPrice = false

      }
      if (item.item_text == "MinCost") {

        data.isMinCost = false

      }
      if (item.item_text == "MinGross") {

        data.isMinGross = false

      }
      if (item.item_text == "MinTaxes") {

        data.isMinTaxes = false

      }
      if (item.item_text == "FLTVnew") {

        data.isFLTVnew = false

      }
      if (item.item_text == "FLTV") {

        data.isFLTV = false

      }
      if (item.item_text == "BLTV") {

        data.isBLTV = false

      }



      dataarray.push(this.fb.group(data))

    })
    // dataarray.patchValue([{id:2}]);


    //(dataarray.controls['id']).updateValue(1);

    //  const myForm = (<FormArray>dataarray.get("id")).at(index);

    //dataarray.controls['id'].patchValue([{id:2}])
    // const myForm = (<FormArray>dataarray.get("id")).patchValue([{id:1}]);

    // dataarray.value.forEach((data, index)=>{


    //   dataarray.value[index].id=1;
    //     // const myForm = (<FormArray>dataarray.value.get("credentials")).at(index);
    //     // myForm.patchValue({
    //     //   id:1
    //     // })


    // })


    // var datavalue=dataarray.value;
    if (item.item_text == "Condition") {

      $("#usertable-" + index + " thead th.ConditionColumnStatus").show();
      // $(tableid +" tbody td.ConditionColumnStatus").hide();

      // dataarray.value.push({ConditionColumnStatus:1});

      // datavalue.find(item => item.id == 0).id = 1;

    }
    if (item.item_text == "ListedPrice") {
      $("#usertable-" + index + " thead th.ListedPriceColumnStatus").show();
      //$("#usertable-"+index+ " tbody td.ListedPriceColumnStatus").hide();
    }
    if (item.item_text == "DealerCost") {
      $("#usertable-" + index + " thead th.DealerCostColumnStatus").show();
      // $("#usertable-"+index+ " tbody td.DealerCostColumnStatus").hide();
    }
    if (item.item_text == "ProjectedGross") {
      $("#usertable-" + index + " thead th.ProjectedGrossColumnStatus").show();
      // $("#usertable-"+index+ " tbody td.ProjectedGrossColumnStatus").hide();
    }
    if (item.item_text == "MaxPaycall") {
      $("#usertable-" + index + " thead th.MaxPaycallColumnStatus").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    ////////////////
    if (item.item_text == "Region") {
      $("#usertable-" + index + " thead th.RegionColumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "City") {
      $("#usertable-" + index + " thead th.CityColumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "Body") {
      $("#usertable-" + index + " thead th.BodyColumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "Trim") {
      $("#usertable-" + index + " thead th.TrimColumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "EffectiveCBB") {
      $("#usertable-" + index + " thead th.EffectiveCBBcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalFLTV") {
      $("#usertable-" + index + " thead th.TotalFLTVcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalBLTV") {
      $("#usertable-" + index + " thead th.TotalBLTVcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalTLV") {
      $("#usertable-" + index + " thead th.TotalTLVcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "LenderFee") {
      $("#usertable-" + index + " thead th.LenderFeecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "FrontDocFee") {
      $("#usertable-" + index + " thead th.FrontDocFeecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "BackDocFee") {
      $("#usertable-" + index + " thead th.BackDocFeecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "PPSA") {
      $("#usertable-" + index + " thead th.PPSAcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "Reserves") {
      $("#usertable-" + index + " thead th.Reservescolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TermPeriod") {
      $("#usertable-" + index + " thead th.TermPeriodcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TaxRate") {
      $("#usertable-" + index + " thead th.TaxRatecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalPrice") {
      $("#usertable-" + index + " thead th.TotalPricecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalCost") {
      $("#usertable-" + index + " thead th.TotalCostcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "TotalTax") {
      $("#usertable-" + index + " thead th.TotalTaxcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "AmountToBeFinanced") {
      $("#usertable-" + index + " thead th.AmountToBeFinancedcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "AllowableFinance") {
      $("#usertable-" + index + " thead th.AllowableFinancecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "MinFinance") {
      $("#usertable-" + index + " thead th.MinFinancecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinPrice") {
      $("#usertable-" + index + " thead th.MinPricecolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinCost") {
      $("#usertable-" + index + " thead th.MinCostcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinGross") {
      $("#usertable-" + index + " thead th.MinGrosscolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "MinTaxes") {
      $("#usertable-" + index + " thead th.MinTaxescolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "FLTVnew") {
      $("#usertable-" + index + " thead th.FLTVnewcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }

    if (item.item_text == "FLTV") {
      $("#usertable-" + index + " thead th.FLTVcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }
    if (item.item_text == "BLTV") {
      $("#usertable-" + index + " thead th.BLTVcolumn").show();
      // $("#usertable-"+index+ " tbody td.MaxPaycallColumnStatus").hide();
    }



  }
  onSelectAll(items: any, index: any) {

    console.log('onSelectAll', items);
  }
  onUnSelectAll(index: any) {

    console.log('onUnSelectAll fires');
  }


  GetFindcarlistColumns() {
    var modelGroup = {
      "UserId": this.useridValue.toString()
    }
    this.manageInventoryService.getFindcarlistColumnSettings(modelGroup)
      .subscribe(
        data => {

          this.ColumnList = data;


          this.ColumnList.forEach(element => {
            var fieldarray = { item_id: element.columnName, item_text: element.columnName }
            this.dropdownList.push(fieldarray);
          });


          // this.dropdownList = [
          //   { item_id: 1, item_text: 'Item1' },
          //   { item_id: 2, item_text: 'Item2' },
          //   { item_id: 3, item_text: 'Item3' },
          //   { item_id: 4, item_text: 'Item4' },
          //   { item_id: 5, item_text: 'Item5' }
          // ];

          this.dropdownSettings = {
            idField: 'item_id',
            textField: 'item_text',
          };

          // for (let i = 0; i < data.length; i++) {
          //   if (data[i].columnName == "Condition") {
          //     if (data[i].isShow == 1) {
          //       this.ConditionColumnStatus = "true";
          //     } else {
          //       this.ConditionColumnStatus = "false";
          //     }

          //   } else if (data[i].columnName == "ListedPrice") {
          //     if (data[i].isShow == 1) {
          //       this.ListedPriceColumnStatus = "true";
          //     } else {
          //       this.ListedPriceColumnStatus = "false";
          //     }
          //   } else if (data[i].columnName == "DealerCost") {
          //     if (data[i].isShow == 1) {
          //       this.DealerCostColumnStatus = "true";
          //     } else {
          //       this.DealerCostColumnStatus = "false";
          //     }
          //   } else if (data[i].columnName == "ProjectedGross") {
          //     if (data[i].isShow == 1) {
          //       this.ProjectedGrossColumnStatus = "true";
          //     } else {
          //       this.ProjectedGrossColumnStatus = "false";
          //     }
          //   } else if (data[i].columnName == "MaxPaycall") {
          //     if (data[i].isShow == 1) {
          //       this.MaxPaycallColumnStatus = "true";
          //     } else {
          //       this.MaxPaycallColumnStatus = "false";
          //     }
          //   }
          // }

        },
        error => {
        });
  }

  GetAllInventoryListbyParametersfinal() {
    this.spinner.show();
    this.buttonloader = true;
    var discard = this.findCarForm.controls;
    if (this.findCarForm.controls.LenderPayCall.value == "") {
      this.LenderPayCall = "0";

    }

    if (this.findCarForm.controls.LenderInterestRate.value == "") {
      this.LenderInterestRate = "0";

    }

    if (this.findCarForm.controls.ACVValueControl.value == "") {
      this.ACVValueControl = "0";

    }
    if (this.findCarForm.controls.LoanAmountControl.value == "") {
      this.LoanAmountControl = "0";

    }
    if (this.findCarForm.controls.DownPaymentAmountControl.value == "") {
      this.DownPaymentAmountControl = "0";

    }

    var delaeridforserver = [];
    this.dealersmultiselectarray.forEach(element => {
      delaeridforserver.push(element.id);
    });


    var modelGroup = {
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': delaeridforserver,
      "GroupNameId": this.selectedgroupId,
      "ProvinceId": this.findCarForm.controls.province.value,
      "isTaxExempt": true,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.findCarForm.controls.Lenderkey.value,
      "Paycall": this.LenderPayCall,
      "Interestrate": this.LenderInterestRate,
      "ACVvalues": this.ACVValueControl,
      "LoanAmountvalue": this.LoanAmountControl,
      "DownPaymentValue": this.DownPaymentAmountControl,
      "TotalFrontproducts": this.TotalFrontproducts,
      "TotalFrontcost": this.TotalFrontcost,
      "TotalBackproducts": this.TotalBackproducts,
      "TotalBackcost": this.TotalBackcost,
      "IsInventory": this.AithrHubKey
    }

    this.manageInventoryService.getAllInventoryListbyParametersFinal(modelGroup)
      .subscribe(
        (data: any) => {
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
          this.buttonloader = false;
        },
        error => {
        });
  }


  GetAllInventoryListbyParameterForBothFindCars(i: any) {
         debugger;
        // const value = (this.disabledInput.nativeElement as HTMLInputElement).value;
    var discard = this.findCarForm.controls;

    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;


    var lenderId = Lenderarraydata.controls[i].value.Lenderid;
    var Lenderkeyddl = Lenderarraydata.controls[i].value.lenderkeyddl;
    var LenderName = Lenderarraydata.controls[i].value.LenderName;
    var LenderPayCall = Lenderarraydata.controls[i].value.LenderPayCall;
    var lenderInterestRateValue = "0";
    if (LenderName == "RifCo") {
      lenderInterestRateValue = Lenderarraydata.at(i).get('LenderInterestRate').value;
    } else {
      lenderInterestRateValue = Lenderarraydata.controls[i].value.LenderInterestRate;
    }
    

    //const lenderInterestRateValue = Lenderarraydata.at(i).get('LenderInterestRate').value;

    var ACVValueControl = Lenderarraydata.controls[i].value.ACVValueControl;
    var LoanAmountControl = Lenderarraydata.controls[i].value.LoanAmountControl;
    var DownPaymentAmountControl = Lenderarraydata.controls[i].value.DownPaymentAmountControl;

    var OverRideFLTVControl = Lenderarraydata.controls[i].value.OverRideFLTVControl;

    //var OverrideTermControl = Lenderarraydata.controls[i].value.OverrideTermControl;
   // var OverrideTermControl  = Lenderarraydata.at(i).get('OverrideTermControl').value;
   var OverrideTermControl = $('#OverrideTerm_' + lenderId + '').val();
    var gpsFeeValueControl = Lenderarraydata.controls[i].value.GPSFeeControl;

    var hasGpsCheckBoxvalue = Lenderarraydata.controls[i].value.hasGpsCheckBox;

    // parameter for Lock Price
    // if(Lenderarraydata.controls[i].value.IsLockListPrice!="")
    // {
    //   this.IsLockListPricevalue = Lenderarraydata.controls[i].value.IsLockListPrice;
    // }else
    // {
    //   this.IsLockListPricevalue = false;
    // }
    

    var maxAmountFinanceControl = Lenderarraydata.controls[i].value.MaxAmountFinanceControl;
    var allowableLossControl = Lenderarraydata.controls[i].value.AllowableLossControl;

    var deductionfeeControl = Lenderarraydata.controls[i].value.DeductionfeeInputControl;

    maxAmountFinanceControl = $('#MaxAmountFinance_' + lenderId + '').val();


    if (Lenderkeyddl == "") {
      $("#findlenderdiv_" + lenderId).find(".Lenderkeyddl").addClass("borderRed");

      $("#lenderdropdownmsg_" + lenderId).css("display", "block");

      return false;
    }
    if (LenderPayCall == "") {
      $("#findlenderdiv_" + lenderId).find("#TDBPCallID").addClass("borderRed");
      $("#PayCallmsg_" + lenderId).css("display", "block");
      return false;
    }
    if (lenderInterestRateValue == "") {
      $("#findlenderdiv_" + lenderId).find("#TDIntRtID").addClass("borderRed");
      $("#interestratemsg_" + lenderId).css("display", "block");
      return false;
    }



    if (maxAmountFinanceControl == null || maxAmountFinanceControl === "") {
      maxAmountFinanceControl = 0
    }
    if (allowableLossControl == null) {
      allowableLossControl = 0
    }
    if (OverRideFLTVControl == null) {
      OverRideFLTVControl = 0
    }

    if (OverrideTermControl == null) {
      OverrideTermControl = 0
    }


    if (LenderPayCall == null) {
      LenderPayCall = 0
    }
    if (lenderInterestRateValue == null) {
      lenderInterestRateValue = "0"
    }

    if (ACVValueControl == null) {
      ACVValueControl = 0
    }
    if (LoanAmountControl == null) {
      LoanAmountControl = 0
    }
    if (DownPaymentAmountControl == null) {
      DownPaymentAmountControl = 0
    }





    if (hasGpsCheckBoxvalue == true) {
      this.hasGPSValueCheck = "1";
    }
    else {
      this.hasGPSValueCheck = "0";
    }
    if (gpsFeeValueControl == "") {
      gpsFeeValueControl = "0";
    }
    var id = Lenderarraydata.controls[i].value.Lenderid;
    var lenderName = 'Lender_' + id

    this.spinner.show(lenderName);

    $('#inventryList_' + lenderId).show();

    if (LenderPayCall == "") {
      LenderPayCall = "0";

    }
    if (OverRideFLTVControl == "") {
      OverRideFLTVControl = "0";
    }

    if (OverrideTermControl == "") {
      OverrideTermControl = "0";
    }


    if (lenderInterestRateValue == "") {
      lenderInterestRateValue = "0";

    }

    if (ACVValueControl == "") {
      ACVValueControl = "0";

    }
    if (LoanAmountControl == "") {
      LoanAmountControl = "0";

    }
    if (DownPaymentAmountControl == "") {
      DownPaymentAmountControl = "0";

    }

    if (maxAmountFinanceControl == "") {
      maxAmountFinanceControl = "0";
    }
    if (allowableLossControl == "") {
      allowableLossControl = "0";
    }

    this.delaeridforserver = [];
    this.dealersmultiselectarray.forEach(element => {
      this.delaeridforserver.push(element.id);
    });

    this.regionidforserver = [];
    if (this.regionmultiselectarray != undefined) {
      this.regionmultiselectarray.forEach(element => {
        this.regionidforserver.push(element.globalCodeID);
      });

    }


    var modelGroup = {
      "Opcode": "0",
      // "LenderId": this.lenderIdValue.toString(),
      "LenderId": lenderId,

      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': this.delaeridforserver,
      "GroupNameId": this.selectedgroupId,
      "ProvinceId": this.findCarForm.controls.province.value,//"11",
      "isTaxExempt": this.isTaxValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": Lenderkeyddl,
      "Paycall": parseFloat(LenderPayCall),
      "Interestrate": parseFloat(lenderInterestRateValue),
      "ACVvalues": parseFloat(ACVValueControl),
      "LoanAmountvalue": parseFloat(LoanAmountControl),
      "DownPaymentValue": parseFloat(DownPaymentAmountControl),
      "IsInventory": this.AithrHubKey,
      "VIN": "",
      "MaximizeDeal": "0",
      "TradeInValue": "0",
      "TotalFrontproducts": this.TotalFrontproducts,
      "TotalFrontcost": this.TotalFrontcost,
      "FrontDocFee": "0",
      "TotalBackproducts": this.TotalBackproducts,
      "TotalBackcost": this.TotalBackcost,
      "ExtWarranty": "0",
      "GAP": "0",
      "JobLoss": "0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice": "0",
      "ScreenIntRate": "0",
      "ScreenTermPeriod": "0",
      "LoggedInUser": this.useridValue,
      "LoggedInIP": this.ipaddressvalue,
      "LoggedInSessionID": "",
      "OverRideFLTV": parseFloat(OverRideFLTVControl),
      "OverrideTerm": parseFloat(OverrideTermControl),

      "MaxAmountFinance": parseFloat(maxAmountFinanceControl),
      "AllowableLoss": parseFloat(allowableLossControl),
      "Deductionfee": parseFloat(deductionfeeControl),
      'Regionidarray': this.regionidforserver,
      "Make": this.findCarForm.controls.MakeFilterControl.value,
      "Model": this.findCarForm.controls.ModelFilterControl.value,
      "BodyStyle": this.findCarForm.controls.BodyStyleFilterControl.value,
      "Seats": this.findCarForm.controls.NumberOfSeatsFilterControl.value,
      "LockPrice": parseInt(this.lockPriceValue),
      "HasGPSFee": parseInt(this.hasGPSValueCheck),
      "GPSFee": parseFloat(this.gpsFeeValueSend),
      "DealerCity": this.dealerCity,
      "ColorTheme":this.ColorTheme,
      "LicenceFee":parseFloat(this.LicenseFee),
      "FEGrossAmount":parseFloat(this.FrontEndGrossTotalAmount),
      "BEGrossAmount":parseFloat(this.BackEndGrossTotal),
      "OthGrossAmount":parseFloat(this.OtherGrossTotal),
      "IsListPriceLock":Boolean(this.IsLockListPricevalue),
      "FEAccessory1":parseFloat(this.FEAccessory1),
      "FEAccessory2":parseFloat(this.FEAccessory2),
      "FEAccessory3":parseFloat(this.FEAccessory3),
      "FEMaximizedAmount":parseFloat(this.FEMaximizedAmount),
      "GWPrice1":parseFloat(this.GWPrice1),
      "GWPrice2":parseFloat(this.GWPrice2),
      "GWCost1":parseFloat(this.GWCost1),
      "GWCost2":parseFloat(this.GWCost2),
      "GWTaxes":parseFloat(this.GWTaxes),


    }

    this.manageInventoryService.getAllInventoryListbyParameterForBothFindCars(modelGroup)
      .subscribe(
        (data: any) => {
debugger;
          if (data.length > 0) {

            this.dealerVehicleslistimage = data;

            if (data[0].lenderName = "Go Plan") {
              this.ShowDealerAdvance =true;
            } else{
              this.ShowDealerAdvance =false;
            }

            this.NoCarFound = "true";
            $('#inventryList_' + lenderId).show();
            var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
            const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;



            dataarray.controls = [];
            dataarray.controls.values = null;
            dataarray.reset()

            //  dataarray.push(new FormGroup({}));

            data.forEach(element => {
              // element.page=1;
              // element.pageSize=10;
              dataarray.push(this.fb.group(element))

              //  dataarray.push(this.fb.group(

              //   {
              //     lenderFee: element.lenderFee,
              //     imagedataarray: element.imagedataarray
              //   }

              //  ))


            })

            //converting comma to array  testing how to assign array image



            // var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;

            // var dataarrayInventarray = Lenderarraydata.controls[0].get('inventaryarray') as FormArray;

            // dataarrayInventarray.at(0).get('imagedataarray').setValue(['example', 'example1', 'example2']);


            dataarray.controls.forEach(element => {


              var commaimagevalue = element.get('imagesListDEMO').value;

              if (commaimagevalue != 'No') {
                const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

                var imagearray = [];

                imagesListDEMOcommatoarray.forEach(element => {
                  var data = {
                    image: element,
                    thumbImage: element,
                    // alt: 'alt of image',
                    // title: 'title of image'
                  }
                  imagearray.push(data);

                }
                )
                element.get('imagedataarray').setValue(imagearray);

              } else {

                const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

                var imagearray = [];

                imagesListDEMOcommatoarray.forEach(element => {
                  var data = {
                    image: "../../assets/images/nofoundImage.png",
                    thumbImage: "../../assets/images/nofoundImage.png",
                    // alt: 'alt of image',
                    // title: 'title of image'
                  }
                  imagearray.push(data);

                }
                )
                element.get('imagedataarray').setValue(imagearray);
              }

            })

            // array image end


            // this is for year filter start
            const year_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.carYear === thing.carYear) === i
            ).map(t => t.carYear);

            year_distinct.sort((a, b) => a < b ? 1 : -1)

            $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").append($("<option></option>").val('').html('Select Year'));
            year_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").append($("<option></option>").val(element).html(element));
            });
            // this is for year filter start


            // this is for nake filter start

            data.forEach(function (item, index) {
              data[index].make = item.make.toLowerCase();
            });

            const Make_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.make === thing.make) === i
            ).map(t => t.make);
            Make_distinct.sort();
            $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").append($("<option></option>").val('').html('Select Make'));
            Make_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").append($("<option></option>").val(element).html(element));
            });
            // this is for make filter end



            // this is for model filter start


            data.forEach(function (item, index) {
              data[index].model = item.model.toLowerCase();
            });



            const Model_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.model === thing.model) === i
            ).map(t => t.model);
            Model_distinct.sort();

            $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").append($("<option></option>").val('').html('Select Model'));
            Model_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").append($("<option></option>").val(element).html(element));
            });
            // this is for year filter start


            // this is for model filter start
            const Trim_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.trim === thing.trim) === i
            ).map(t => t.trim);
            Trim_distinct.sort();

            $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").append($("<option></option>").val('').html('Select Trim'));
            Trim_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").append($("<option></option>").val(element).html(element));
            });
            // this is for year filter start


            // this is for KM filter start
            const KM_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.mileage === thing.mileage) === i
            ).map(t => t.mileage);

            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('').html('Select KM'));
            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('1').html('Less than 50k'));
            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('2').html('Less than 100k'));
            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('3').html('Between 100k-180k'));
            $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('4').html('Greater than 180k'));
            // KM_distinct.forEach(element => {
            //   $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val(element).html(element));
            // });
            // this is for KM filter start


            // this is for Body filter start
            const Bodystyle_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.body === thing.body) === i
            ).map(t => t.body);
            Bodystyle_distinct.sort();

            $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").append($("<option></option>").val('').html('Select Body'));
            Bodystyle_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").append($("<option></option>").val(element).html(element));
            });
            // this is for year filter start



            // this is for Body filter start

            data.forEach(function (item, index) {
              data[index].colour = item.colour.toLowerCase();
            });

            const Colorstyle_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.colour === thing.colour) === i
            ).map(t => t.colour);

            Colorstyle_distinct.sort();
            ///for Color
            $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").append($("<option></option>").val('').html('Select Color'));
            Colorstyle_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").append($("<option></option>").val(element).html(element));
            });


            ///for Fuel
            // this is for Body filter start
            const FuelTypestyle_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.engineFuelType === thing.engineFuelType) === i
            ).map(t => t.engineFuelType);

            FuelTypestyle_distinct.sort();
            $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").append($("<option></option>").val('').html('Select Fuel'));
            FuelTypestyle_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").append($("<option></option>").val(element).html(element));
            });

            ///for Transmission

            // this is for Body filter start
            const Transmission_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.transmission === thing.transmission) === i
            ).map(t => t.transmission);
            Transmission_distinct.sort();
            $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").append($("<option></option>").val('').html('Select Transmission'));
            Transmission_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").append($("<option></option>").val(element).html(element));
            });

            data.forEach(function (item, index) {
              data[index].dealerCity = item.dealerCity.toLowerCase();
            });
            // this is for City filter start
            const DealerCity_distinct = data.filter(
              (thing, i, arr) => arr.findIndex(t => t.dealerCity === thing.dealerCity) === i
            ).map(t => t.dealerCity);
            DealerCity_distinct.sort();
            $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
            $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").append($("<option></option>").val('').html('Select City'));
            DealerCity_distinct.forEach(element => {
              $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").append($("<option></option>").val(element).html(element));
            });








            //  Lenderarraydata.controls[i].value.inventaryarray.push({'vin':888,'stockNumber':'tes55t'},{'vin':765,'stockNumber':'te444st99'});

            this.TotalRecord = data.length;
            this.spinner.hide(lenderName);
            this.buttonloader = false;


//new code 31 jan 2024 start
if(this.checkkeyp.length>0)
{
  //  var Updating_checkkeyp=this.checkkeyp.find(i=>i.id==match);
  //  Updating_checkkeyp.Data=dataarray.value


  //  let Updating_checkkeyp = this.checkkeyp.find((p) => {
  //   return p.id === match;
  // });
  // Updating_checkkeyp.Data = dataarray.value;

  this.checkkeyp.forEach(item => {
    if(item.id==i)
    {
      item.Data=data;
    }
  });

}

//end code




          } else {
            $('#inventryList_' + lenderId).hide();
            this.NoCarFound = "false";
            this.showNotification("error", "No car found.")
            this.spinner.hide(lenderName);
          }
        },
        error => {
        });



    // this.GetFindcarlistColumns();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  lendermainarray() {
    return this.findCarForm.get('LenderDetailsformarray') as FormArray;
  }

  getInventoryist(empIndex: number): FormArray {
    return this.lendermainarray()
      .at(empIndex)
      .get('inventaryarray') as FormArray;
  }

  GetDealerNamebyGroupId(value: any) {
    this.dealersmultiselectarray = [];
    this.selectedGroupId = value.globalCodeID;
    this.SelectedGroupName = value.globalCodeName
    this.groupIdPage = value.globalCodeID;

    this.GetDealers(this.selectedGroupId);
  }


  // DealercheckboxtoggleSelection(i){
  //   debugger;
  //   this.dealerlistName.forEach((item, index) => {
  //     // if(index != i){
  //     //   this.dealerlistName[index].IsSelected = false
  //     // }

  //     if(index == i){
  //       var getvalue=this.dealerlistName[index];

  //      var k= {id: 6, dealerName: getvalue.dealerName}

  //       this.dealersmultiselectarray.push(k);
  //     }
  //   })
  //  }
  GetAithrHubUnits(event) {
    //this.GetAllInventoryListbyParametersfinal();
       debugger;
    const value = event.target.value;
    if (value == "0") {
      //$('.AllowableLossDiv').show()
      this.ShowMultiSelectRegion = false;
      this.ShowAithrhubMultiSelectRegion = false;
      this.ShowAllowable = true;
      this.GetProvinceMuiltiSelectlist();
    }
    else if (value == "1"){
      // $('.AllowableLossDiv').hide()
      this.ShowMultiSelectRegion = true;
      this.ShowAithrhubMultiSelectRegion = true;
      this.ShowAllowable = false;
      this.GetProvinceMuiltiSelectlist();
    } else if(value != "1" || value!="0" )
    {
      this.ShowMultiSelectRegion = true;
      this.ShowAithrhubMultiSelectRegion = false;
      this.ShowAllowable = false;
    }

    this.GetAllInventoryListbyParameterForBothFindCars("o");
  }
  DealercheckboxtoggleSelection_old(e) {
    var c_value = false;
    if (e.target.checked) {
      c_value = e.target.value;
      this.dealerlistName.push(c_value);
    } else {
      c_value = e.target.value;
      const index = this.dealerlistName.findIndex(item => item === c_value);
      this.dealerlistName.splice(index);
    }
  }


  GetselectedDealer() {
    this.dealersmultiselectarray = this.getSelectedItem();
    var selecteditem = this.getSelectedItem();
    this.decline();
  }

  GetselectedRegions() {

    this.regionmultiselectarray = this.getSelectedregions();
    var selecteditem = this.getSelectedregions();
    //this.declineRegion();
    this.confirmModalService.hide(1);
  }

  getSelectedregions() {
    return this.regionlistName.filter(item => item.IsSelected === true);
  }

  // get list of selected Items
  getSelectedItem() {
    return this.dealerlistName.filter(item => item.IsSelected === true);
  }
onSlideChange(event: any): void {
    // Prevent the default action (which would be changing the active slide)
    event.preventDefault();
    // You can add any additional logic here if needed
  }
  //checkall dealer
  Checkalldealer(e) {

    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = false
      })
    }

  }

  Checkallregions(e) {
    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = false
      })
    }

  }


  GetDealers(groupId: any) {

    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {


          this.dealerlistName = []; //initialize

          //Assigning value selected false which will appera by deafult uncheck
          this.dealerlistName.forEach((item, index) => {
            this.dealerlistName[index].IsSelected = false
          })

          this.dealerlistName = data;
          if (data.length > 1) {
            this.dealersmultiselectarray = this.dealerlistName.filter(item => item.id == this.DealerIdvalue);
            var selectedchckbox = this.dealerlistName.filter(item => item.id == this.DealerIdvalue);
            selectedchckbox.forEach((itemCheck, index) => {
              //this.dealerlistName[index].IsSelected = true
              var DealerList = this.dealerlistName.filter(item => item.id == itemCheck.id);
              let indexSelected = this.dealerlistName.findIndex(rank => rank.dealerName === DealerList[0].dealerName);

              this.dealerlistName[indexSelected].IsSelected = true;
            })

          } else {
            this.dealersmultiselectarray = this.dealerlistName;
          }

          if (data != null) {
            if (data != null && data.length > 1) {


              this.buttonToBeClicked.nativeElement.click();


            }
            else {


              this.buttonPleasecheckclicked.nativeElement.click();
            }



          }
        },
        error => {
        });

    if (this.Index != "M") {
      var lendercheckboxid = '#lenderchck_' + this.App_lenderId;

      $(lendercheckboxid).prop("checked", true);
    }

  }

  decline(): void {
    // this.modalRef.hide();
    this.confirmModalService.hide(1);
  }

  declineCustomer(): void {
    debugger;
     this.customermodalRef.hide();
   // this.confirmModalService.hide(1);
  }

  declineRegion(): void {

    this.modalRef.hide();

    //this.confirmModalService.hide()
    //this.modalcloseOpen()
  }

  CloseRegionList(template: TemplateRef<any>) {

    this.confirmModalService.hide(1);
  }

  GetGroupList() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          this.GroupLists = data;
        },
        error => {
        });
  }

  GetGroupFindCarList() {
    debugger;
    var modelGroup = {
      "UserId": this.useridValue,
      "RoleName": this.rolevalue
    }
    this.globalcodeService.getGroupFindCarListdata(modelGroup)
      .subscribe(
        (data: any) => {
          this.GroupLists = data;
        },
        error => {
        });
  }



  setSerachboxvalue(event, index, type) {
    // this._ngZone.run(()=>{

    //   debugger;
    //  });
    var typedvalue = "";

    if (type == 'paste') {
      typedvalue = event;
    }
    else {
      typedvalue = event.target.value;
    }

    this.searchtablearray[index].searchtext = typedvalue;


  }

  keyupFLTVSetup(event) {
    var valueSetUp = event
    if (valueSetUp > 10) {
      alert("Greater");
    }
    console.log(event);
  }
  keyupOverRide(event) {
    console.log(event);
  }
  Createform() {
    this.findCarForm = new FormGroup({
      //searchtext: this.fb.control(''),
      province: this.fb.control(''),
      dealerName: this.fb.control(''),
      groupName: this.fb.control(''),
      Lenderkey: this.fb.control(''),
      dealerCity: this.fb.control(''),
      // LenderPayCall: this.fb.control(''),
      LenderInterestRate: this.fb.control(''),
      // YearFilter: this.fb.control(''),
      // MakeFilter: this.fb.control(''),
      // ModelFilter: this.fb.control(''),
      // TrimFilter: this.fb.control(''),
      // kmFilter: this.fb.control(''),
      // BodyStyle: this.fb.control(''),
      // FLTVSetupControl: this.fb.control('',Validators.pattern("^[0-9]*$")),
      // OverRideFLTVControl: this.fb.control('',Validators.pattern("^[0-9]*$")),
      //  GPSFeeControl: this.fb.control(''),
      //   OverRideFLTVControl: this.fb.control('', [

      //     Validators.maxLength(3),
      //   ]),
      MakeFilterControl: this.fb.control('', Validators.maxLength(10)),
      ModelFilterControl: this.fb.control('', Validators.maxLength(10)),
      BodyStyleFilterControl: this.fb.control('', Validators.maxLength(10)),
      NumberOfSeatsFilterControl: this.fb.control('', Validators.maxLength(10)),
      ACVValueControl: this.fb.control(''),
      LoanAmountControl: this.fb.control(''),
      DownPaymentAmountControl: this.fb.control(''),
      TaxExemptionCheck: this.fb.control(''),
      advanceFilterCheck: this.fb.control(''),
      IsLockListPrice: this.fb.control(''),
      // hasGpsCheckBox: this.fb.control(''),

      locklistpriceCheck: this.fb.control(''),
      AithrHubKey: this.fb.control(''),
      LenderDetailsformarray: this.fb.array([]),

      // List Filters for find Car list
      // YearListFilterControl: this.fb.control(''),
      // ModelListFilterControl: this.fb.control(''),
      // TrimListFilterControl: this.fb.control(''),
      // KmListFilterControl: this.fb.control(''),
      // BodyListFilterControl: this.fb.control('')
    });
  }


  cleanNullPropert(obj) {
    for (var propName in obj) {
      if (obj[propName] === "" || obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj
  }

  // click button hide show
  toggleDisplayDiv(vin: any) {

    $("#extraDetail-" + vin).show();
    $("#buttonHideDetail-" + vin).show();
    $("#buttonShowDetail-" + vin).hide();
    // this.isShowDiv = !this.isShowDiv;
  }
  hideExtraDetail(vin: any) {
    $("#extraDetail-" + vin).hide();
    $("#buttonHideDetail-" + vin).hide();
    $("#buttonShowDetail-" + vin).show();
  }
  LowercaseFunction(value) {

    if (value != null && value != '') {
      var lowervalue = value.toLowerCase();
      return lowervalue;
    }
  }

  PageingFindCareLength(event: any, obj: any, i: any, lenderId: any) {
    var searchvalu = event.target.value;
    if (searchvalu == 'All') {
     this.getUnitsByDealerNames( i);
    } else {
      var istouch = 0;
      var dataarray_Source: any;

      if (istouch == 0) {
        // dataarray_Source = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;
      }
      this.NoCarFound = "true";
      var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
      const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;

      var controlsarray = dataarray.value;

      var match = i;
      var data: any = [];
      dataarray.controls = [];
      var checkingLength = this.checkOriginalPagingArrayLength.filter(i => i.id == match).length;
      if (checkingLength == 0)  // here I am keeping all data source for firsttime
      {
        var dataaraary = {
          "id": i,
          "Data": controlsarray
        }
        this.checkOriginalPagingArrayLength.push(dataaraary);
      }

      //data = this.checkOriginalPagingArrayLength[i].Data.slice(0,searchvalu);
      var filterData = this.checkOriginalPagingArrayLength.filter(i => i.id == match)
      data = filterData[0].Data.slice(0, searchvalu);

      if (data.length > 0) {
        data.forEach(element => {

          dataarray.push(this.fb.group(element))
        })
      }
      else {
        dataarray.push(this.fb.group({

        }))
      }

      //start image show view start

      dataarray.controls.forEach(element => {


        var commaimagevalue = element.get('imagesListDEMO').value;

        if (commaimagevalue != 'No') {
          const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

          var imagearray = [];

          imagesListDEMOcommatoarray.forEach(element => {
            var data = {
              image: element,
              thumbImage: element,
              // alt: 'alt of image',
              // title: 'title of image'
            }
            imagearray.push(data);

          }
          )
          element.get('imagedataarray').setValue(imagearray);

        } else {

          const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

          var imagearray = [];

          imagesListDEMOcommatoarray.forEach(element => {
            var data = {
              image: "../../assets/images/nofoundImage.png",
              thumbImage: "../../assets/images/nofoundImage.png",
              // alt: 'alt of image',
              // title: 'title of image'
            }
            imagearray.push(data);

          }
          )
          element.get('imagedataarray').setValue(imagearray);
        }

      })

      this.TotalRecord = data.length;
      // this.spinner.hide(lenderName);
      this.buttonloader = false;
    }
  }
  FindcarKeup(event: any, obj: any, i: any, lenderId: any) {

    var searchvalu = event.target.value;
    var istouch = 0;
    var dataarray_Source: any;
   // this.cleanDropDown(lenderId ,obj);
    if (istouch == 0) {
      // dataarray_Source = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;
    }



    //   if (searchvalu.length > 0)
    //  {
    this.NoCarFound = "true";
    var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
    const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;

    var controlsarray = dataarray.value;

    var match = i;



  //new code on 31 jan 2024 end

    if (this.checkkeyp.filter(i => i.id == match).length == 0)  // here I am keeping all data source for firsttime
    {
      var dataaraary = {
        "id": i,
        "Data": controlsarray
      }
      this.checkkeyp.push(dataaraary);
    }


    var match = i;
    var getdata = this.checkkeyp.filter(i => i.id === match);

    controlsarray = getdata[0].Data;  //comment for no reason


    var year = $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").val();
    var make = $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").val();
    var model = $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").val();
    var trim = $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").val();
    var km = $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").val();
    var bodystyle = $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").val();
    var Colorstyle = $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").val();
    var Fueltypestyle = $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").val();
    var Transmissionstyle = $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").val();


    var dealerCitystyle = $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").val();

    if (Colorstyle != null && Colorstyle != '') {
      Colorstyle = Colorstyle.toLowerCase();
    }

    if (dealerCitystyle != null && dealerCitystyle != '') {
      dealerCitystyle = dealerCitystyle.toLowerCase();
    }

    if (make != null && make != '') {
      make = make.toLowerCase();
    }

    if (model != null && model != '') {
      model = model.toLowerCase();
    }

    //beloq code here I assign Drop down selected value so that I could I know that i dont need to bind again
    var milagedata = (km != "" ? Number(km) : "");

    var selectedvlaueonfilter = {
      yearfilter: year,
      makefilter: make,
      modelfilter: model,
      trimfilter: trim,
      kmfilter: milagedata,
      bodystylefilter: bodystyle,
      fuelfilter: Fueltypestyle,
      colorfilter: Colorstyle,
      Transmissionfilter: Transmissionstyle,
      dealerCityfilter: dealerCitystyle

    }


    /// below filter property name must be equal as dataapi coulm name
    const filters = {
      carYear: year,
      make: make,
      model: model,
      mileage: milagedata,
      body: bodystyle,
      trim: trim,
      colour: Colorstyle,
      transmission: Transmissionstyle,
      engineFuelType: Fueltypestyle,
      dealerCity: dealerCitystyle


    }

    var data: any = [];
    var finalfilter = this.cleanNullPropert(filters);

    if (finalfilter.mileage != undefined &&
      finalfilter.carYear == undefined &&
      finalfilter.make == undefined &&
      finalfilter.model == undefined &&
      finalfilter.body == undefined &&
      finalfilter.trim == undefined &&
      finalfilter.colour == undefined &&
      finalfilter.transmission == undefined &&
      finalfilter.engineFuelType == undefined &&
      finalfilter.dealerCity == undefined
    ) {
      // data = controlsarray.filter(i => i.mileage > 100000);

      if (finalfilter.mileage == 1) {
        data = controlsarray.filter(i => i.mileage < 50000);
      }
      else if (finalfilter.mileage == 2) {
        data = controlsarray.filter(i => i.mileage < 100000);
      }
      else if (finalfilter.mileage == 3) {
        data = controlsarray.filter(i => i.mileage > 100000 && i.mileage < 1800000);
      }
      else if (finalfilter.mileage == 4) {
        data = controlsarray.filter(i => i.mileage > 1800000);
      }
    }
    else {

      var oldmilege = finalfilter.mileage;

      finalfilter.mileage = "";

      finalfilter = this.cleanNullPropert(finalfilter);

      // data = controlsarray.filter(i =>

      //   Object.entries(finalfilter).every(

      //     ([key, value]) => i[key.toLowerCase()] === this.LowercaseFunction(value)

      //   )
      // )

      // controlsarray.forEach((s, i, arr) => arr[i] = s.toLowerCase());

      controlsarray.forEach(function (item, index) {

        controlsarray[index].colour = item.colour.toLowerCase();
        controlsarray[index].make = item.make.toLowerCase();
        controlsarray[index].model = item.model.toLowerCase();
        controlsarray[index].dealerCity = item.dealerCity.toLowerCase();
      });

      data = controlsarray.filter(i =>

        Object.entries(finalfilter).every(

          ([key, value]) => i[key] === value

        )
      )

      if (oldmilege == 1) {
        data = data.filter(i => i.mileage < 50000);
      }
      else if (oldmilege == 2) {
        data = data.filter(i => i.mileage < 100000);
      }
      else if (oldmilege == 3) {
        data = data.filter(i => i.mileage > 100000 && i.mileage < 1800000);
      }
      else if (oldmilege == 4) {
        data = data.filter(i => i.mileage > 1800000);
      }


//start image bind


//end


    }


    // data = controlsarray.filter(i =>
    //   Object.entries(finalfilter).every(

    //     ([k, v]) => i[k] === v

    //   )
    // )


    //binding all dropdown fr filter start

    // this is for year filter start
    this.BindFilterDropdown(data, lenderId, selectedvlaueonfilter);
    // this is for year filter start
    dataarray.controls = [];
    //  dataarray.reset()

    if (data.length > 0) {
      data.forEach(element => {

        dataarray.push(this.fb.group(element))
      })
    }
    else {
      dataarray.push(this.fb.group({

      }))
    }

//start image show view start

dataarray.controls.forEach(element => {


  var commaimagevalue = element.get('imagesListDEMO').value;

  if (commaimagevalue != 'No') {
    const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

    var imagearray = [];

    imagesListDEMOcommatoarray.forEach(element => {
      var data = {
        image: element,
        thumbImage: element,
        // alt: 'alt of image',
        // title: 'title of image'
      }
      imagearray.push(data);

    }
    )
    element.get('imagedataarray').setValue(imagearray);

  } else {

    const imagesListDEMOcommatoarray = element.get('imagesListDEMO').value.split(',');

    var imagearray = [];

    imagesListDEMOcommatoarray.forEach(element => {
      var data = {
        image: "../../assets/images/nofoundImage.png",
        thumbImage: "../../assets/images/nofoundImage.png",
        // alt: 'alt of image',
        // title: 'title of image'
      }
      imagearray.push(data);

    }
    )
    element.get('imagedataarray').setValue(imagearray);
  }

})

//end


    this.TotalRecord = data.length;
    // this.spinner.hide(lenderName);
    this.buttonloader = false;
  }




  private BindFilterDropdown(data: any, lenderId: any, selectedvlue: any) {

    if (selectedvlue.yearfilter == '' || selectedvlue.yearfilter ==null)   // when year selected from drop down then drop should not bind again
    {
      const year_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.carYear === thing.carYear) === i
      ).map(t => t.carYear);

      year_distinct.sort((a, b) => a < b ? 1 : -1)

      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").append($("<option></option>").val('').html('Select Year'));



      year_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").append($("<option></option>").val(element).html(element));
      });

    }


    if (selectedvlue.makefilter == '' ||selectedvlue.makefilter==null )   // when year selected from drop down then drop should not bind again
    {
      // this is for year filter start
      // this is for nake filter start
      const Make_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.make === thing.make) === i
      ).map(t => t.make);
      // Make_distinct.sort((a,b)=> a < b ? 1:-1);
      Make_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").append($("<option></option>").val('').html('Select Make'));
      Make_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").append($("<option></option>").val(element).html(element));
      });

    }
    // this is for make filter end

    if (selectedvlue.modelfilter == '' ||selectedvlue.modelfilter==null )   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const Model_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.model === thing.model) === i
      ).map(t => t.model);
      Model_distinct.sort();

      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").append($("<option></option>").val('').html('Select Model'));
      Model_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").append($("<option></option>").val(element).html(element));
      });
      // this is for year filter start
    }


    if (selectedvlue.trimfilter == '' ||selectedvlue.trimfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const Trim_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.trim === thing.trim) === i
      ).map(t => t.trim);
      Trim_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").append($("<option></option>").val('').html('Select Trim'));
      Trim_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").append($("<option></option>").val(element).html(element));
      });

    }

    if (selectedvlue.kmfilter == '' ||selectedvlue.kmfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const KM_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.mileage === thing.mileage) === i
      ).map(t => t.mileage);

      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('').html('Select KM'));
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('1').html('Less than 50k'));
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('2').html('Less than 100k'));
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('3').html('Between 100k-180k'));
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val('4').html('Greater than 180k'));
      // KM_distinct.forEach(element => {
      //   $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").append($("<option></option>").val(element).html(element));
      // });
    }
    // this is for year filter start

    if (selectedvlue.bodystylefilter == '' ||selectedvlue.bodystylefilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const Bodystyle_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.body === thing.body) === i
      ).map(t => t.body);
      Bodystyle_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").append($("<option></option>").val('').html('Select Body'));
      Bodystyle_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").append($("<option></option>").val(element).html(element));
      });
    }



    if (selectedvlue.colorfilter == '' ||selectedvlue.colorfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const color_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.colour === thing.colour) === i
      ).map(t => t.colour);

      color_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").append($("<option></option>").val('').html('Select Color'));
      color_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").append($("<option></option>").val(element).html(element));
      });
    }

    if (selectedvlue.Transmissionfilter == '' ||selectedvlue.Transmissionfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const Transmission_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.transmission === thing.transmission) === i
      ).map(t => t.transmission);

      Transmission_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").append($("<option></option>").val('').html('Select Transmission'));
      Transmission_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").append($("<option></option>").val(element).html(element));
      });
    }

    if (selectedvlue.dealerCityfilter == '' ||selectedvlue.dealerCityfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const DealerCity_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.dealerCity === thing.dealerCity) === i
      ).map(t => t.dealerCity);

      DealerCity_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").append($("<option></option>").val('').html('Select City'));
      DealerCity_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").append($("<option></option>").val(element).html(element));
      });
    }

    if (selectedvlue.fuelfilter == '' ||selectedvlue.fuelfilter==null)   // when year selected from drop down then drop should not bind again
    {
      // this is for model filter start
      const fuel_distinct = data.filter(
        (thing, i, arr) => arr.findIndex(t => t.engineFuelType === thing.engineFuelType) === i
      ).map(t => t.engineFuelType);
      fuel_distinct.sort();
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").append($("<option></option>").val('').html('Select Fuel'));
      fuel_distinct.forEach(element => {
        $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").append($("<option></option>").val(element).html(element));
      });
    }





  }

  cleanDropDown(lenderId: any, obj: any) {
    if (obj == "Make") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      //  $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "Model") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      //  $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "Trim") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      //$("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "KM") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "Body") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    }
    else if (obj == "Color") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    }
    else if (obj == "FuelType") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "Transmission") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    } else if (obj == "City") {
      $("#findlenderdiv_" + lenderId).find("select.yearfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Makefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Modelfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Trimfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.kmvaluefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.BodyStylefilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Colorfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Transmissionfilterddl").html("");
      // $("#findlenderdiv_" + lenderId).find("select.dealerCityfilterddl").html("");
      $("#findlenderdiv_" + lenderId).find("select.Fueltypefilterddl").html("");
    }



  }

  FindcarKeup_old(event: any, obj: any, i: any) {

    var searchvalu = event.target.value;
    var istouch = 0;
    var dataarray_Source: any;

    if (istouch == 0) {
      // dataarray_Source = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;
    }
   


    if (searchvalu.length > 0) {
      this.NoCarFound = "true";
      var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
      const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;


      const controlsarray = dataarray.value;

      var match = i;

      if (this.checkkeyp.filter(i => i.id == match).length == 0)  // here I am keeping all data source for firsttime
      {
        var dataaraary = {
          "id": i,
          "Data": controlsarray
        }
        this.checkkeyp.push(dataaraary);
      }

      var data: any = [];

      if (obj == "City") {
        data = controlsarray.filter(i => i.dealerCity.toLowerCase().startsWith(searchvalu.toLowerCase()));
      }

      if (obj == "Make") {
        data = controlsarray.filter(i => i.make.toLowerCase().startsWith(searchvalu.toLowerCase()));
      }
      if (obj == "Year") {
        data = controlsarray.filter(i => i.carYear.startsWith(searchvalu));
      }
      if (obj == "Model") {
        data = controlsarray.filter(i => i.model.toLowerCase().startsWith(searchvalu.toLowerCase()));
      }
      if (obj == "KM") {
        data = controlsarray.filter(i => i.mileage.toString().startsWith(searchvalu.toString()));
      }
      if (obj == "BodyStyle") {
        data = controlsarray.filter(i => i.body.toLowerCase().startsWith(searchvalu.toLowerCase()));
        //data=controlsarray.filter(i=>i.body.toString().startsWith(searchvalu.toLowerCase()));
      }
      if (obj == "Trim") {
        data = controlsarray.filter(i => i.trim.toLowerCase().startsWith(searchvalu.toLowerCase()));
        // data=controlsarray.filter(i=>i.trim.toString().startsWith(searchvalu.toLowerCase()));
      }

      dataarray.controls = [];
      //  dataarray.reset()

      //  dataarray.push(new FormGroup({}));

      data.forEach(element => {

        dataarray.push(this.fb.group(element))
      })



      this.TotalRecord = data.length;
      // this.spinner.hide(lenderName);
      this.buttonloader = false;

    }
    else {

      var Lenderarraydata = this.findCarForm.get('LenderDetailsformarray') as FormArray;
      const dataarray = Lenderarraydata.controls[i].get('inventaryarray') as FormArray;

      var match = i;
      var getdata = this.checkkeyp.filter(i => i.id === match);

      var data = getdata[0].Data;

      dataarray.controls = [];
      //  dataarray.reset()

      //  dataarray.push(new FormGroup({}));

      data.forEach(element => {

        dataarray.push(this.fb.group(element))
      })



      this.TotalRecord = data.length;
      // this.spinner.hide(lenderName);
      this.buttonloader = false;
    }
  }


  addCreds() {

    const lenderdatadetails = this.findCarForm.controls.LenderDetailsformarray as FormArray;
    lenderdatadetails.push(this.fb.group({
      LenderPayCall: '99',
      LenderInterestRate: '',
      ACVValueControl: '',
      LoanAmountControl: '',
      DownPaymentAmountControl: '',
      // YearListFilterControl: '',
      // ModelListFilterControl: '',
      // TrimListFilterControl: '',
      // KmListFilterControl: '',
      // BodyListFilterControl: '',
    }));
  }

  trackFn(index) {
    return index;
  }

  changeAction(e, index) {
    console.log(e, index);
    if (e === "set" || e === "go") {

      // this.form
      //this.findCarForm.get('LenderDetailsformarray').at(index).get('label').enable();
      // this.form.get('lable').enable
    } else {
      // this.showLabel[index] = false;
      // this.findCarForm.get('LenderDetailsformarray').at(index).get('label').disable();
    }
  }



  Getlenderslist() {
    var modelGroup = {
      "DealerId": this.DealerIdvalue
    }
    this.lenderService.getAllLendersListFindCar(modelGroup)
      .subscribe(
        (data: any) => {
          this.lenderlists = data;
          // this.dealerlistName;
          // this.CheckClick();
          // this.AithrHubKeyControlclick.change();


          if (this.Index != "M") {
            this.lenderlists.forEach(val => {
              if (val.id == this.App_lenderId) {
                val.isSelected = true;
              }
              else {
                val.isSelected = false;
                this.DisplayFilters = "false";
                $('#' + "findlenderdiv_" + val.id).remove();
              }
            });
          }

          this.buttonPleasecheckclicked.nativeElement.click();
        },
        error => {
        });
  }
  PleaseCheck() {
  }

  GetProvinceMuiltiSelectlist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          //this.Provincelist = data;

          this.regionlistName = []; //initialize

          //Assigning value selected false which will appera by deafult uncheck
          this.regionlistName.forEach((item, index) => {
            this.regionlistName[index].IsSelected = false
          })

          this.regionlistName = data;
          this.regionmultiselectarray = this.regionlistName.filter(item => item.globalCodeID == this.province);
          var selectedchckbox = this.regionlistName.filter(item => item.globalCodeID == this.province);

          selectedchckbox.forEach((itemcheck, index) => {
            // this.regionlistName[index].IsSelected = true

            var regionList = this.regionlistName.filter(item => item.globalCodeID == itemcheck.globalCodeID);
            let indexSelected = this.regionlistName.findIndex(rank => rank.globalCodeName === regionList[0].globalCodeName);

            this.regionlistName[indexSelected].IsSelected = true;
          })

          if (data != null) {
            //if (data != null && data.length > 4) {
            // this.regionbuttonToBeClicked.nativeElement.click();

          }

        },
        error => {
        });
  }


  GetInvertorySourcelist() {
    this.globalcodeService.getSourceList()
      .subscribe(
        (data: any) => {
          this.InventorySorucelist = data;
        },
        error => {
        });
  }

  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  // Getting Dealer city
  GetDealerCitylist() {
    this.globalcodeService.getDealerCity()
      .subscribe(
        (data: any) => {
          this.citylist = data;
        },
        error => {
        });
  }

}
