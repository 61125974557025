import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AIXLenders } from '@app/_models/AIXLenders';
import { AccountService, AlertService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import * as moment from 'moment'
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { ListDealersService } from '@app/_services/list-dealers.service';
import { DashboardService } from '@app/_services/dashboard.service';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';

@Component({
  selector: 'app-admin-setting',
  templateUrl: './admin-setting.component.html',
  styleUrls: ['./admin-setting.component.less']
})
export class AdminSettingComponent implements OnInit {
  adminSettingForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private globalcodeService: GlobalcodeService,
    private router: Router,
    private listDealersService: ListDealersService,
    private spinner: NgxSpinnerService,
    private lenderService: LenderService,
    private dashboardService: DashboardService,
    private datePipe: DatePipe,
    notifier: NotifierService) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }

  // USER LIST
  pageSizesUser = [10, 20, 30, 40, 50, 60, 70];
  accounts: Account[] = [];
  isDesc: boolean = false;
  column: string = 'userName';
  TotalRecordUser: any;
  modalRef: BsModalRef;
  name = 'Angular';
  pageUser = 1;
  pageSizeUser = 10;
  modalTitle = 'Add User';
  Excelsheetdata = 'ExcelSheet.xlsx';

  exportexcelDealer(): void {
    debugger;
    /* pass here the table id */
    let element = document.getElementById('usertabledealer');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.Excelsheetdata);

  }

  exportexcel() {
    debugger
    const prepare = [];
    this.Dealerslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push( e.globalCodeName);
      tempObj.push( e.dealerPhone);
      tempObj.push( e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      prepare.push(tempObj);
    });
  
    // Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['Dealer Name','Group Name','Region/Province','Phone','Email','Postal Code','Active On','Active Until'],
      ...prepare
    ]);
  
    //Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofDealers');
  
    // Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofDealers.xlsx');
  }

  printTableDealer() {
    debugger;
    var prepare = [];
    this.Dealerslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>DealerName</th><th>GroupName</th><th>GlobalCodeName</th><th>DealerPhone</th><th>DealerEmail</th><th>PostalCode</th><th>ActiveStart</th><th>ActiveUntil</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdfDealer() {
    debugger
    var prepare = [];
    this.Dealerslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['dealerName', 'groupName', 'globalCodeName', 'dealerPhone', 'dealerEmail', 'postalCode', 'activeStart', 'activeUntil']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Dealer_List' + '.pdf');
  }


  exportexcelUser(): void {
    debugger;
    /* pass here the table id */
    let element = document.getElementById('usertableUser');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.Excelsheetdata);

  }

  printTableUser() {
    debugger
    var prepare = [];
    this.accounts.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName + e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.groupName);
      tempObj.push(e.dealerName);
      tempObj.push(e.rolename);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>User Name</th><th>Email</th><th>GroupName</th><th>DealerName</th><th>Rolename</th><th>city</th><th>PhoneNumber</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }


  downloadPdfUser() {
    var prepare = [];
    this.accounts.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.groupName);
      tempObj.push(e.dealerName);
      tempObj.push(e.rolename);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['firstName', 'lastName', 'email', 'groupName', 'dealerName', 'rolename', 'city', 'phoneNumber']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Dealer_List' + '.pdf');
  }


  exportexcelGroup(): void {
    debugger;
    /* pass here the table id */
    let element = document.getElementById('usertable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.Excelsheetdata);

  }


  sortUser(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  edititemid: any;
  dealerlistName = [];
  //ng model
  Id = null;
  Gid = 0;
  Did = 0;
  Rid = 0;
  firstname: any;
  Lastname: any;
  email: any;
  phoneNumber: any;
  checkActivitylog = "";

  city: any;
  region: any = 0;
  colortheme: any = 0;
  selectedgroupId: any;

  submitted = false;
  loading = false;
  isValid: boolean = true;
  Grouplist = [];
  rolelist = [];
  OpCode:any;
  Provincelist = [];
  GroupLists = [];
  ColourThemelist = [];
  groupName: any;
  DeleteUserid: any;
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  // END USER LIST

  // DEALER LIST
  TotalRecordDealer: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  pageDealer = 1;
  pageSizeDealer = 10;
  pageSizesDealer = [10, 20, 30, 40, 50, 60, 70];
  Dealerslist = [];
  DealerIdValue: any;
  DealerNameValue: any;
  subscriptionlist = [];

  groupid = 0;
  Adddealersid = 0;
  Adddealersname = "";
  DealerGid = 0;
  DealerDid = 0;
  DealerRid = 0;
  Dealeremail = "";
  FromTab = "1";
  activeon = { year: 1789, month: 7, day: 14 };
  activeunity = { year: 1789, month: 7, day: 14 };;
  activeonserver = new Date();
  activeunityserver = new Date();
  Dealercity = "";
  Dealerprovince = "";
  address = "";
  postalcode = "";
  phonenumber = "";
  dealerssubscription = "";
  inventorycheck = "";

  sortDealer(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Dealerslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchTextDealer;
  deleteitem: any = 0;

  @ViewChild('ConfirmDeleteDealerBox', { static: false }) ConfirmDeleteDealerBoxclick: ElementRef;

  /// END DEALER LIST

  // LENDER LIST
  TotalRecordLender: any;

  pageLender = 1;
  pageSizeLender = 10;
  pageSizesLender = [10, 20, 30, 40, 50, 60, 70];

  sortLender(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.lenderlists.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  lenderlists: AIXLenders[] = [];
  searchTextLender;
  DealerFilterValue:any;
  ColorTheme:any;
  // END OF LENDER LIST
  ngOnInit(): void {

    this.DealerFilterValue="All";
    this.Createform();
    // USER FUNCTION
    this.GetUserList();
    // this.GetGlobalcodeByRolelist();
    this.GetGroupListByGlobalID();
    this.GetProvincelist();
    this.GetColourThemelist();
    this.GetGlobalcodesubscriptionlist();
    // END USER
    this.Getlenderslist();
    this.OpCode="1";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
   
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
      this.GetDealerslist();
      this.GetGlobalcodeByRolelist()
    } else {
      this.GetDealersByDealerName();
    }

  }
  get f() { return this.adminSettingForm.controls; }
  GetDealersByDealerName() {
    debugger;
    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.listDealersService.getDealersByDealerName(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.Dealerslist = data;
          this.TotalRecordDealer = data.length;
          this.spinner.hide();
        },
        error => {
        });


  }

  GetDealerslist() {
    this.spinner.show();
    var modelGroup = {
      "FilterValues": this.DealerFilterValue,
      
    }
    this.listDealersService.GetDealerslist(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.Dealerslist = data;
          this.TotalRecordDealer = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }
  handlePageSizeChangeDealer(event) {
    this.pageSizeDealer = event.target.value;
    this.pageDealer = 1;
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
      this.GetDealerslist();
    } else {
      this.GetDealersByDealerName();
    }
  }

  handlePageSizeChange(event) {
    this.pageSizeUser = event.target.value;
    this.pageUser = 1;
    this.GetUserList();
  }


  Getlenderslist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderlists = data;

          this.TotalRecordLender = data.length;
        },
        error => {
        });
  }

  // Functions for USER

  CloseAddUsermodel() {
    debugger;
    this.modalRef.hide();
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  CreateUser() {
    debugger
    this.submitted = true;

    if (this.adminSettingForm.controls.firstname.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.Lastname.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.email.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.phoneNumber.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.city.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.region.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.colortheme.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.userRole.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.dealerName.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.groupName.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "Id": this.edititemid,
        "firstname": this.adminSettingForm.controls.firstname.value,
        "Lastname": this.adminSettingForm.controls.Lastname.value,
        "email": this.adminSettingForm.controls.email.value,
        "phoneNumber": this.adminSettingForm.controls.phoneNumber.value,
        "city": this.adminSettingForm.controls.city.value,
        "region": this.adminSettingForm.controls.region.value,
        "Rid": this.adminSettingForm.controls.userRole.value,
        "Did": this.adminSettingForm.controls.dealerName.value,
        "Gid": this.adminSettingForm.controls.groupName.value,
        "Province": this.adminSettingForm.controls.region.value,
        "ColorTheme": this.adminSettingForm.controls.colortheme.value,
        "IsLoggingActivity": (this.adminSettingForm.controls.checkActivitylog.value != "" ? this.adminSettingForm.controls.checkActivitylog.value : false),
      }
      this.accountService.Saveadduser(modelGroup)
        .subscribe(
          (data: any) => {
            debugger
            if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
              this.GetUserList();
            } else {
              this.GetUserListDealerName();
            }
            this.submitted = false;

            this.showNotification("success", "Data saved successfully")
            this.reinitiatengmodel();
            this.Createform();
            this.CloseAddUsermodel();
            this.closepopUserupmodel();

          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }


  }

  openUserDeleteModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  confirmUser(): void {
    this.modalRef.hide();
    this.deleteUser(this.DeleteUserid);
  }
  deleteUser(email: any) {
    debugger;
    this.accountService.deleteUsers(this.DeleteUserid)
      .subscribe(
        (data: any) => {
          debugger;
          // this.alertService.success("User deleted Successfully");
          this.showNotification("success", "deleted successfully");
          if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
            this.GetUserList();
          } else {
            this.GetUserListDealerName();
          }
          return this.router.navigateByUrl("/List");
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }
  declineUser(): void {
    this.modalRef.hide();
  }

  onDeleteUser(UserId: any) {
    debugger;
    this.DeleteUserid = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }

  closepopUserupmodel() {
    this.adminSettingForm.invalid
  }
  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  GetColourThemelist() {
    this.globalcodeService.getColourThemelist()
      .subscribe(
        (data: any) => {
          this.ColourThemelist = data;
        },
        error => {
        });
  }

  GetGroupListByGlobalID() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          debugger;
          this.GroupLists = data;
        },
        error => {
        });
  }
  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }
  GetGlobalcodeByRolelist() {
    var modelGroup = {
      "Opcode": this.OpCode,
    }
    this.globalcodeService.getGlobalcodeByRolelist(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.rolelist = data;
        },
        error => {
        });
  }
  GetUserListDealerName() {
    debugger;
    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.accountService.getUserListDealerName(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.accounts = data;
          this.TotalRecordUser = data.length;
        },
        error => {
        });


  }

  Createform() {
    this.adminSettingForm = new FormGroup({

      // Controls for user
      firstname: this.fb.control('', Validators.required),
      Lastname: this.fb.control('', Validators.required),
      email: this.fb.control('', [Validators.email, Validators.required]),
      phoneNumber: this.fb.control('', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]),
      city: this.fb.control('', Validators.required),
      region: this.fb.control('', Validators.required),
      colortheme: this.fb.control('', Validators.required),
      userRole: this.fb.control("0", Validators.required),
      dealerName: this.fb.control('', Validators.required),
      groupName: this.fb.control('', Validators.required),
      checkActivitylog: this.fb.control(false),
      // end of user

      // controls for Dealers
      // controls for actiavte
      DealerId: this.fb.control(true, Validators.required),
      DealerName: this.fb.control(true, Validators.required),

      groupidctrl: this.fb.control('', Validators.required),
      dealersname: this.fb.control('', Validators.required),
      activeon: this.fb.control(new Date(), Validators.required),
      activeunity: this.fb.control(new Date(), Validators.required),
      Dealercity: this.fb.control('', Validators.required),
      Dealerprovince: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      postalcode: this.fb.control('', Validators.required),
      phonenumber: this.fb.control('', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]),
      Dealeremail: this.fb.control('', [Validators.email, Validators.required]),
      dealerssubscription: this.fb.control('', Validators.required),
      inventorycheck: this.fb.control(true, Validators.required),
      // controls for actiavte
      AddDealerId: this.fb.control(true, Validators.required),
      AddDealerName: this.fb.control(true, Validators.required),
      picture: this.fb.control(''),
      FromTab: this.fb.control('')
      // end of dealers
    });
  }

  openAddModelAddUserModel(targetModal, userid) {
    debugger;
    if (userid != undefined && userid != "") {
      this.edititemid = userid;
      var modelGroup = {
        "Id": userid
      }

      this.accountService.editUserDetail(modelGroup)
        .subscribe(
          (data: any) => {
            debugger;
            //this.editvaluename=data.firstName;
            //this.updateid=data.Id;
            this.dealerlistName,
              this.Id = data[0].id,
              this.firstname = data[0].firstName,
              this.Lastname = data[0].lastName,
              this.email = data[0].email,
              this.Gid = data[0].gid,

              this.GetDealerNamebyGroupId(this.Gid);

            this.Did = data[0].did,
              this.Rid = data[0].rid,
              this.phoneNumber = data[0].phoneNumber,
              this.city = data[0].city,
              this.region = data[0].province,
              this.colortheme = data[0].colorTheme,
              this.checkActivitylog = data[0].isLoggingActivity,
              this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg' });

          },
          error => {
          });

      // Set the modal title to "Edit Global Data"
      this.modalTitle = 'Edit Global Data';
      // this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
    } else {
      this.Createform();
      this.reinitiatengmodel();
       // Set the modal title to "Add Global Data"
       this.modalTitle = 'Add Global Data';
      this.edititemid = "";
      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
    }

  }

  reinitiatengmodel() {
    const dealerid = localStorage.getItem('DealerId');
    const roleid = localStorage.getItem('roleid');
    const groupid = localStorage.getItem('groupid');
    this.firstname = "";
    this.Lastname = "";
    this.email = "";
    this.checkActivitylog = "";
    this.colortheme = 0;
    this.Gid = (groupid != null ? parseInt(groupid) : 0);


    if (dealerid != null && parseInt(dealerid) > 0) {
      this.GetDealerNamebyGroupId(this.Gid);
    }
    this.Did = (dealerid != null ? parseInt(dealerid) : 0);
    this.Rid = (roleid != null ? parseInt(roleid) : 0);
    this.phoneNumber = "";
    this.city = "";
    this.region = "";


  }

  GetDealerNamebyGroupId(value: any) {
    this.GetDealers(value);
  }

  GetDealers(groupId: any) {
    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerlistName = data;
        },
        error => {
        });


  }
  GetUserList() {
    this.accountService.getUserList()
      .subscribe(
        (data: any) => {
          debugger;
          // set data to model for show in list.
          this.accounts = data;
          debugger;
          this.TotalRecordUser = data.length;
          this.modalRef.hide();
        },
        error => {
        });
  }
  // END OF USER LIST

  // Functions for Dealer List
  // function for confirm  delete
  confirmDealer(): void {
    var modelGroup = {
      "id": this.deleteitem
    }
    this.listDealersService.Delete(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.showNotification("success", "Deleted successfully")
          this.GetDealerslist();
        },
        error => {
        });

    this.modalRef.hide();

  }

  declineDealer(): void {
    this.modalRef.hide();
  }

  openDeleteDealerModal(template: TemplateRef<any>, itemid: any) {
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  openActivateModel(template: TemplateRef<any>, itemid: any) {
    this.DealerNameValue = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  //add and edit popup
  openAddDealerModel(targetModal, id) {
debugger;
this.GetGroupListByGlobalID();

    if (id != undefined && id != "") {
      this.edititemid = id;
      var modelGroup = {
        "id": id
      }
      this.listDealersService.EditDealers(modelGroup)
        .subscribe(
          (data: any) => {
            debugger
            this.FillNgmodels(data)
          },
          error => {
          });

 // Set the modal title to "Add Global Data"
 this.modalTitle = 'Edit Global Data';
      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
    } else {
      this.Createform();
      this.reinitiateDealerngmodel();
       // Set the modal title to "Add Global Data"
       this.modalTitle = 'Add Global Data';
      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
    }

  }
  CloseAddDealermodel() {
    this.modalRef.hide();
  }

  onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.labelImport.nativeElement.innerText = file.name;
      document.getElementById("choosefile").innerHTML = file.name;
      this.adminSettingForm.patchValue({
        picture: file,
      });
    }
  }

  FillNgmodels(data) {
    this.Adddealersid = data.id;
    this.groupid = data.dGroupId;
    this.Adddealersname = data.dealerName;
    this.activeon = this.ConvertserverdatetoDatetime(data.activeStart);
    this.activeunity = this.ConvertserverdatetoDatetime(data.activeUntil);
    this.Dealercity = data.dealerCity;
    this.Dealerprovince = data.dealerRegion;
    this.address = data.dealerAddress;
    this.postalcode = data.postalCode;
    this.phonenumber = data.dealerPhone;
    this.dealerssubscription = data.isTrial;
    this.inventorycheck = "true";
    this.Dealeremail = data.dealerEmail;
  }

  onSubmit() {
    this.submitted = true;
    const formData = new FormData();
    for (const key of Object.keys(this.adminSettingForm.value)) {
      var value = this.adminSettingForm.value[key];
      if (key == "activeon") {
        var activeonserver = this.datePipe.transform(this.activeonserver, "yyyy-MM-dd"); //output : 2018-02-13
        value = activeonserver.toString();
      }
      if (key == "activeunity") {
        // var activeunityserver= this.onDateSelectactiveunity(this.activeunityserver);

        var activeunityserver = this.datePipe.transform(this.activeunityserver, "yyyy-MM-dd"); //output : 2018-02-13

        value = activeunityserver;
      }
      formData.append(key, value);
    }
    // Validations for Dealers only

    if (this.adminSettingForm.controls.groupidctrl.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.dealersname.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.activeon.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.activeunity.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.Dealercity.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.Dealerprovince.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.address.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.postalcode.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.phonenumber.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.Dealeremail.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.dealerssubscription.invalid) {
      this.isValid = false;
      return;
    }
    else {
      var modelGroup = {
        "DGroupId": this.adminSettingForm.controls.groupidctrl.value,
        "id": this.Adddealersid,
        "ActiveStart": this.activeonserver,
        "ActiveUntil": this.activeunityserver,
        "DealerName": this.adminSettingForm.controls.dealersname.value,
        "DealerCity": this.adminSettingForm.controls.Dealercity.value,
        "DealerRegion": this.adminSettingForm.controls.Dealerprovince.value,
        "DealerAddress": this.adminSettingForm.controls.address.value,
        "PostalCode": this.adminSettingForm.controls.postalcode.value,
        "DealerPhone": this.adminSettingForm.controls.phonenumber.value,
        "DealerEmail": this.adminSettingForm.controls.Dealeremail.value,
        "IsTrial": this.adminSettingForm.controls.dealerssubscription.value,
        "inventory": this.adminSettingForm.controls.inventorycheck.value,
        "Picture": this.adminSettingForm.controls.picture.value,
        "FromTab": this.adminSettingForm.controls.FromTab.value,
      }


      this.listDealersService.SaveaddDealers(formData)
        .subscribe(
          (data: any) => {
            debugger

            if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
              this.GetDealerslist();
            } else {
              this.GetDealersByDealerName();
            }
            //this.GetDealerslist();
            this.submitted = false;

            this.showNotification("success", "Data saved successfully")
            this.Createform();
            this.modalRef.hide();

            this.closeEditDealersModel();
            this.closepopupmodel();

          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }
  }
  onDateSelectactiveunity(event) {
    debugger;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.activeunityserver = new Date(finalDate);
  }
  GetGlobalcodesubscriptionlist() {
    this.globalcodeService.getGlobalcodesubscriptionlist()
      .subscribe(
        (data: any) => {
          debugger;
          this.subscriptionlist = data;
        },
        error => {
        });
  }
  closeEditDealersModel() {
    this.modalRef.hide();
    this.DealerIdValue = "";
  }
  closepopupmodel() {
    this.adminSettingForm.invalid
  }
  ConvertserverdatetoDatetime(datadate) {
    debugger;
    var momentDate = moment(datadate);

    var dateactive = new Date(datadate);
    var localstringdate = dateactive.toLocaleDateString();
    return {
      year: dateactive.getFullYear(),
      month: dateactive.getMonth() + 1,
      day: dateactive.getDate()
    }


  }

  public currentDate() {
    var todayDate = new Date();

    return {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate()
    }
  }
  reinitiateDealerngmodel() {
    this.groupid = 0;
    this.Adddealersid = 0;
    this.Adddealersname = "";
    this.activeon = this.currentDate();
    this.activeunity = this.currentDate();
    this.Dealercity = "";
    this.Dealerprovince = "";
    this.address = "";
    this.postalcode = "";
    this.phonenumber = "";
    this.dealerssubscription = "";
    this.inventorycheck = "";
    this.Dealeremail = "";

  }

  ActivateDealer() {
    debugger;
    this.submitted = true;

    if (this.adminSettingForm.controls.DealerId.invalid) {
      this.isValid = false;
      return;
    } else if (this.adminSettingForm.controls.DealerName.invalid) {
      this.isValid = false;
      return;
    }

    else {
      var modelGroup = {
        "id": this.adminSettingForm.controls.DealerId.value,//this.DealerIdValue,
        "DealerName": this.DealerNameValue
      }


      this.dashboardService.ActivateDealers(modelGroup)
        .subscribe(
          (data: any) => {
            debugger
            this.GetDealerslist();

            this.showNotification("success", "Dealer Activated successfully");
            this.Createform();
            this.modalRef.hide();
            this.closeEditDealerModel();
            this.DealerIdValue = "";

          },
          error => {
          });
    }
  }

  closeEditDealerModel() {
    this.modalRef.hide();
    this.DealerIdValue = "";
  }

  // END OF DEALERS
}
