
<!-- <div [class.dark-mode]="darkmode" class="app-container" [ngClass]="{ 'bg-light': account }"> -->
<div class="app-container" [ngClass]="{ 'bg-light': account }">
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark"  *ngIf="account">
  <!-- <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="box-shadow:1px 2px 11px 1px #1675bb;" *ngIf="account"> -->
    <!-- <a class="navbar-brand" *ngIf="account.role === Role.Admin" routerLink="/List"><img style="width:160px;" src="../assets/images/BrandImage.png" alt="brand logo"></a> -->
    <a class="navbar-brand"  routerLink="/welcome-dasboard"><img style="width:160px;" src="../assets/images/BrandImage.png" alt="brand logo"></a>
    <a *ngIf="account.role === Role.Dealer" routerLink="//Vhr-Report" class="navbar-brand">Spadez Portal</a>


  <div class="ml-auto d-flex">
    <div (click)="menuOpened = !menuOpened" class="side-nav-button ml-3 border text-light" style="cursor:pointer;padding:5px 10px;">
      <i class="fa-solid fa-bars"></i>
    </div>
    <button class="navbar-toggler userIcon" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
      aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
      <!-- <span class="navbar-toggler-icon"></span> -->
      <i class="fas fa-user"></i>
    </button>
  </div>



    <div class="collapse navbar-collapse" id="navbarNavDropdown">
      <div class="ml-md-auto">
        <div class="notifications">
          <button type="button" class="icon-button dropdown-toggle" id="dropdownMenuButton" (click)="RedirectToNotification();">
            <i class="fas fa-bell"></i>
            <span *ngIf="setCount === '1'" class="icon-button__badge">{{account.notificationCount}}</span>
            <span *ngIf="setCount === '2'" class="icon-button__badge">{{notifciationCount}}</span>
          </button>
          <!-- <button (click)="RefreshCount();">Avc</button> -->
          <div id="notification-list" class="list-group list-group-alt dropdown-menu" aria-labelledby="dropdownMenuButton">

              <div class="noty-manager-list-item noty-manager-list-item-error">
                <div class="activity-item">
                  <!-- <i class="fa fa-shopping-cart text-success"></i> -->
                  <div class="activity">
                    <a href="#">Eugene</a> ordered 2 copies of <a href="#">OEM license</a>
                    <span class="date">14 minutes ago</span>
                  </div>
                </div>
              </div>


              <div class="noty-manager-list-item">
                <div class="activity-item">
                  <!-- <i class="fa fa-shopping-cart text-success"></i> -->
                  <div class="activity">
                    <a href="#">Eugene</a> ordered 2 copies of <a href="#">OEM license</a>
                    <span class="date">14 minutes ago</span>
                  </div>
                </div>
              </div>

            <footer class="panel-footer">
              <a href="#" class="pull-right"><i class="fa fa-cog"></i></a>
              <a href="#notes" data-toggle="class:show animated fadeInRight">See all the notifications</a>
          </footer>

          </div>
        </div>

        <div id="dealerkey"  class="dropdown">
          <button type="button"  title="Dealer Key" (click)="toggleDealerKey()" class="btn btn-primary dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i class="fas fa-star"></i></button>
          <div class="tokestatus dropdown-menu" aria-labelledby="dropdownMenuButton">
            <p><b>Dealer key: </b>{{DealerKey}} </p>
          </div>
        </div>
        
      </div>

      <ul class="navbar-nav">
        <!-- <li class="nav-item active">
        </li>
        <li class="nav-item">
          <a routerLink="/user-profile/{{ account.email }}" routerLinkActive="active"
            class="nav-item nav-link">Profile</a>
        </li>
        <li class="nav-item">

          <a *ngIf="account.role === Role.Admin" routerLink="/List" class="nav-item nav-link">Admin</a>


        </li>

        <li class="nav-item">
          <a routerLink="/Vhr-Report" *ngIf="account.role === Role.Dealer" routerLinkActive="active"
            class="nav-item nav-link">VHR Report</a>
        </li>

        <li class="nav-item">
          <a routerLink="/Carfax-API" *ngIf="account.role === Role.Admin" routerLinkActive="active"
            class="nav-item nav-link">Carfax API</a>
        </li>

        <li class="nav-item">
          <a routerLink="/Vhr-Report" *ngIf="account.role === Role.Admin" routerLinkActive="active"
            class="nav-item nav-link">Inventory</a>
        </li>
        <li class="nav-item">
          <a routerLink="/master-dashboard" *ngIf="account.role === Role.Admin" routerLinkActive="active"
            class="nav-item nav-link">Dashboard</a>
        </li>
        <li class="nav-item">
          <a routerLink="/api-history" *ngIf="account.role === Role.Admin" routerLinkActive="active"
            class="nav-item nav-link">API Call History</a>
        </li>

        <li class="nav-item">
          <a routerLink="/DealerLocation" *ngIf="account.role === Role.Admin" routerLinkActive="active"
            class="nav-item nav-link">Dealer location</a>
        </li>
         -->

        <ul id="profiledetails" class="navbar-nav ml-auto">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <i class="fa-solid fa-user"></i>
              <span>{{account.userName}}</span>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item"   ><i class="fa-solid fa-circle-user"></i>{{otherMessage}}</a>

              <a class="dropdown-item" routerLink="/account-setting"  ><i class="fa-solid fa-envelope"></i> {{account.email}}</a>
              <a (click)="logout()" class="dropdown-item"><i class="fa-solid fa-arrow-right-from-bracket"></i>
                Log Out</a>
            </div>
          </li>
        </ul>
      </ul>
    </div>
  </nav>

  <app-side-nav  (parentFun)="parentFun(this)" *ngIf="account" [class.menu-open]="menuOpened" class="side-nav">
    <div class="disply-area">
      <router-outlet name="subnav"></router-outlet>
    </div>
  </app-side-nav>

  <alert class="alertmessage"></alert>
  <router-outlet></router-outlet>

  <!-- <div class="footer">
    <h2>footer</h2>
  </div> -->



  <notifier-container></notifier-container>
  <!-- <app-footer></app-footer> -->
</div>



<!-- <div class="app-container" [ngClass]="{ 'bg-light': account }">
<app-header></app-header>
<router-outlet name="subnav"></router-outlet>
<alert class="alertmessage"></alert>
<router-outlet></router-outlet>
</div> -->