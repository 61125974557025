import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Account } from '@app/_models/account';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgbPaginationConfig } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-inquiry-logo',
  templateUrl: './inquiry-logo.component.html',
  styleUrls: ['./inquiry-logo.component.less']
})
export class InquiryLogoComponent implements OnInit {
  CommuincationForm: FormGroup;
  
  private notifier: NotifierService;
  page = 1;
  pageSize = 5;

  sortedColumn: string = '';
  sortDirection: number = 1;
  @ViewChild('ConfirmMarkSoldBox', { static: false }) ConfirmMarkSoldBoxClick: ElementRef;
  // Pagination properties
  currentPage: number = 1;

  TypeClick:any;
  Markid:any;
  MarkVIN:any;
  Userid:any;
  MarkDealerId:any;
  MarkStockNo:any;
  MarkYear:any;
  MarkMilegge:any;
  MarkMake:any;
  MarkModel:any;
  Marktrim:any;
  Markbody:any;
  Markprice:any;
  markValueTitle:any;
  itemsPerPage: number = 10;
  searchText;
  pageSizes = [5,10, 20, 30, 40, 50, 60, 70];
  selectedVin: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  modalRef: BsModalRef;
  username: any;
  UserEmail: any;
  userPhoneNO: any;
  DealerName: any;
  DealerNumber: any;
  DealerAddress: any;
  DealerEmail: any;
  emailloglist=[];
  Totalrecords:any;
  CurrentDate:any;
  LoggedUserName:any;
  ResponseNotes:any;
  UserNameValue:any;
  UseridValue:any;
  VINValue:any;
  opcode:any;
  constructor(private paginationConfig: NgbPaginationConfig,
    private confirmModalService: BsModalService,
    notifier: NotifierService,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private manageInventoryService: ManageInventoryService) {
    paginationConfig.maxSize = 5;
    paginationConfig.boundaryLinks = true;
    paginationConfig.ellipses = false;
    paginationConfig.rotate = true;
    this.notifier = notifier;
  }
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    var currentd=new Date();
    this.CurrentDate = this.transformDate(currentd);

    this.LoggedUserName = localStorage.getItem('UserName');
    this.UseridValue = localStorage.getItem('userid');
    this.Userid = localStorage.getItem('userid');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    if (this.rolevalue == "SuperAdmin") {
      this.opcode="1";
      this.GetinquiryLog();
    } else {
      this.opcode="2";
      this.GetinquiryLog();
    }

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
   // this.GetEmailLogDetail();
  }
  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }
  Createform() {
    this.CommuincationForm = new FormGroup({
      CurrentDate: this.fb.control('', Validators.required),
      LoggedUserName: this.fb.control('', Validators.required),
      ResponseNotes: this.fb.control('', Validators.required),
      
    });
  }

  
  sortData(column: string): void {
    if (column === this.sortedColumn) {
      this.sortDirection = -this.sortDirection;
    } else {
      this.sortedColumn = column;
      this.sortDirection = 1;
    }

    this.emailloglist.sort((a, b) => {
      const valueA = a[column].toLowerCase();
      const valueB = b[column].toLowerCase();

      if (valueA < valueB) {
        return -1 * this.sortDirection;
      } else if (valueA > valueB) {
        return 1 * this.sortDirection;
      } else {
        return 0;
      }
    });
  }
  exportexcel() {
    const prepare = [];
    this.emailloglist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.vin);
      tempObj.push(e.userEmail);
      tempObj.push(e.sendDate);
      tempObj.push(e.sendUserName);
      tempObj.push(e.ipaddress);
      tempObj.push(e.buttonType);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });

    // Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['Dealer Name', 'VIN', 'Email Send To', 'Send Date', 'Send By', 'IP Address', 'Type', 'Comments'],
      ...prepare
    ]);

    //Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofCommunicationLogs');

    // Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofCommunicationLogs.xlsx');
  }

  downloadPdf() {
    var prepare = [];
    this.emailloglist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.vin);
      tempObj.push(e.userEmail);
      tempObj.push(e.sendDate);
      tempObj.push(e.sendUserName);
      tempObj.push(e.ipaddress);
      tempObj.push(e.buttonType);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['Dealer Name', 'VIN', 'Email Send To', 'Send Date', 'Send By', 'IP Address', 'Type', 'Comments']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Communication_Logs' + '.pdf');
  }

  printTable() {
    var prepare = [];
    this.emailloglist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.vin);
      tempObj.push(e.userEmail);
      tempObj.push(e.sendDate);
      tempObj.push(e.sendUserName);
      tempObj.push(e.ipaddress);
      tempObj.push(e.buttonType);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>Dealer Name</th><th>VIN</th><th>Email Send To</th><th>Send Date</th><th>Send By</th><th>IP Address</th><th>Type</th><th>Comments</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }
//notifier
public showNotification(type: string, message: string): void {
  this.notifier.notify(type, message);
}
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  SaveReponseDetail() {
    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName":this.LoggedUserName,
      "Notes":this.ResponseNotes,
      "VIN": this.VINValue.toString()
    }

    this.manageInventoryService.sendReponseDetail(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes ="";
          this.showNotification("success", "Response send successfully");
          this.decline();
          this.GetinquiryLog();
        },
        error => {
        });


  }
  
  decline(): void {
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>,vin:any,btn:any) {
    
    this.VINValue = vin;
    if (btn == 1) {
      this.ResponseNotes ="";
      this.LoggedUserName="";
      this.CurrentDate="";
     this.GetEmailLogDetailsbyID();
    } else{
      this.ResponseNotes ="";
    }
   
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  GetinquiryLog() {
    var modelGroup = {
      "VIN": this.selectedVin,
      "Opcode": this.opcode,   
      "DealerId": this.DealerIdvalue
    }

    this.manageInventoryService.getinquiryLogDetails(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.emailloglist = data;
          this.Totalrecords = data.length;
          this.currentPage = 1;
        },
        error => {
        });
  }

  GetEmailLogDetailsbyID() {
    var modelGroup = {
      "VIN": this.VINValue.toString()
    }
    this.manageInventoryService.getEmailLogDetailsbyID(modelGroup)
      .subscribe(
        data => {
         this.CurrentDate = this.transformDate(data[0].responseDateTime);
         this.LoggedUserName = data[0].firstName;
         this.ResponseNotes = data[0].notes;
        },
        error => {
        });
  }

  handlePageSizeChange(event) {
    this.itemsPerPage = event.target.value;
    this.currentPage = 1;
  }


  openModalMarkUnitAsSold(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect' ,backdrop: 'static', keyboard: false });
  }

  setradioMarkUnits(value: any,item: any) {
    this.Markid=item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerId;
    this.MarkStockNo= item.stockNumber;

    this.MarkMilegge= item.mileage;

    this.Marktrim= item.trim;
    this.Markbody= item.body;
    this.Markprice= item.listedPrice;

   
      this.markValueTitle = value;
      this.TypeClick="Sold";
      let el: HTMLElement = this.ConfirmMarkSoldBoxClick.nativeElement;
      el.click();
  

  }

  MarkSold() {
    debugger;
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";

          this.decline();
           this.GetinquiryLog();
          this.showNotification("success", "Unit marked as SOLD Successfully");

        },
        error => {
        });
  }
  
}
