<section id="rest-password" class="logoin-section">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-6 mx-auto" *ngIf="!Resetdone">
              <h2>Reset your Password</h2>
              <p>Please Enter your credentials first.</p>
              <form class="loginForm" [formGroup]="Resetform" (ngSubmit)="onSubmit()">
                  <div class="form-group">
                      <input type="password" formControlName="password" placeholder="Password*" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
                      <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                          <div style="color: red;" *ngIf="f.password.errors.required">Password is required</div>
                      </div>

                      <div style="color: red !important;" class="errors" *ngIf="Resetform.get('password').invalid && (Resetform.get('password').touched || Resetform.get('password').dirty)">

                        <div style="color: red;" *ngIf="Resetform.get('password').hasError('required')">
                          password is required
                        </div>

                        <div style="color: red;" *ngIf="Resetform.get('password').errors.minlength">
                          Password must be at least 8 characters
                        </div>

                        <div style="color: red;" class="error-text" *ngIf="Resetform.get('password').hasError('passwordStrength')">
                          {{Resetform.get('password').errors['passwordStrength']}}
                        </div>
                      </div>


                  </div>
                  <div class="form-group">
                      <input type="password" formControlName="confirmPassword" placeholder="Confirm Password*" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
                      <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
                          <div style="color: red;" *ngIf="f.confirmPassword.errors.required">Confirm Password is required</div>
                          <div style="color: red;" *ngIf="Resetform.get('confirmPassword').errors.confirmPasswordValidator">Passsword and
                            Confirm Password didn't match. </div>
                      </div>



                  </div>
                  <div class="form-group btn-group col-12 px-0 mt-2">
                      <div class="row mx-0 justify-content-between w-100">
                          <button [disabled]="loading" class="btn btn-primary col-auto">
                              <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                              Reset Password
                          </button>
                      </div>
                  </div>
              </form>
          </div>


          <div class="userlogin" id="SentmailforReset" *ngIf="Resetdone">
            <p style="color: green;">Passowrd Reset Successfully</p>
        </div>

      </div>
  </div>
</section>
