<section id="admindashboard">
    <form class="p-2" [formGroup]="ImportInventoryForm">
        <div class="container-fluid">
            <div class="card card-raised h-100 mb-0">
                <div class="card-header card-raised bg-info text-white">
                    <h5 class="card-title text-white text-uppercase mb-0">
                        Import Inventory 
                    </h5>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md">
                            <div class="row">
                                <div class="col-md-6 col-lg-4 col-xl-3 mb-1 mb-lg-0">
                                    <input class="form-control d-inline-block px-0" style="min-height: 34px;
                                    line-height: 26px;
                                    font-size: 0.92em;" type="file"
                                        (change)="onFileChange($event)" multiple="false" />
                                </div>
                
                                <div class="col-auto" style="margin-top:3px;">
                                    <button class="btn btn-success btn-sm rounded-pill" (click)="Postexcel()">Upload Excel</button>
                                
                                    <button class="btn btn-success btn-sm ml-2 rounded-pill" (click)="export()">Export!</button>
                                </div>
                
                                
                            </div>
                        </div>
                        <div class="col-12 mt-3 exportfile">
                            <div class="table-responsive">
                                <table class="sjs-table tbale table-bordered">
                                    <tbody>
                                        <tr *ngFor="let row of data">
                                            <td *ngFor="let val of row">
                                                {{val}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
       
      <!-- <app-footer id="secondfooter"></app-footer> -->

    </form>
</section>