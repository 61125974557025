<section id="admindashboard">
  <form class="col-12 px-0" [formGroup]="Inititform">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-2">
          <!-- <h4 class="mb-0 title">List of Global <code></code></h4> -->
          <!-- <button class="button button--primary" (click)="showNotification( 'success', 'Notification successfully opened.' )">
              Success me!
            </button> -->

          <div class="row justify-content-between align-items-center mb-2">
            <div class="search-heros col-md-12 col-lg-10 col-xl-8 mt-md-2 mt-lg-0 mt-0 mb-2 mb-md-0">
              <div class="row">
                <div class="col-sm-12 col-lg-5">
                  <div class="form-row">
                    <label class="col-md-4 col-form-label text-xl-center">Search:</label>
                    <div class="col-md-8">
                      <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-lg-7">
                  <div class="form-row mt-1 mt-md-0">
                    <label for="exampleFormControlSelect1" class="col-md-4 col-form-label text-xl-center">
                      Category</label>
                    <div class="col-md-8">
                      <select  class="form-control" [(ngModel)]="globalcategoryiddropdownfront"
                        name="globalcategoryiddropdownfront" formControlName="globalcategoryiddropdownfront"
                        (change)="onChangedropdownfront($event.target.value)">
                        <option value="0" selected>Select category name</option>
                        <option *ngFor="let codecategorylist of globalCodeCategoryOnfrontside"
                          [value]="codecategorylist.globalCodeCategoryID">{{codecategorylist.globalCodeCategoryName}}
                        </option>
                      </select>
                      <div *ngIf="submitted && f.globalcategoryidctrl.errors" class="invalid-feedback">
                        <div *ngIf="f.globalcategoryidctrl.errors.required"> Category is required</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto documentbtn">
              <ul class="list-unstyled mb-1">
                <li>
                  <button title="Copy" class="btn btndesign btn-sm mb-sm-0" (click)="copytable('usertable')">
                    <img src="../../assets/images/icon-btn/copy.png" alt="copy" />
                    Copy
                  </button>
                </li>
                <li>
                  <button title="Excel" class="btn btndesign btn-sm mb-sm-0" (click)="exportexcel()">
                    <img src="../../assets/images/icon-btn/excel.png" alt="Excel" />
                    Excel
                  </button>
                </li>
                <li>
                  <button title="PDF" class="btn btndesign btn-sm mb-sm-0" (click)="downloadPdf()">
                    <img src="../../assets/images/icon-btn/pdf.png" alt="PDF" />
                    PDF
                  </button>
                </li>
                <li>
                  <button title="Print" class="btn btndesign btn-sm mb-sm-0" (click)="printTable()">
                    <img src="../../assets/images/icon-btn/print.png" alt="Print" />
                    Print
                  </button>
                </li>
              </ul>
            </div>
            
          </div>

          <div class="col-md-12 px-0">
            <div class="card card-raised h-100 mb-0">
              <div class="card-header card-raised bg-info text-white">
                <div class="row justify-content-between align-items-center">
                  <div class="col-sm-6">
                    <h5 class="card-title text-white text-uppercase mb-0">List of {{categronametitle}} </h5>
                  </div>
                  <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table id="usertable{{globalcategoryiddropdownfront}}" *ngIf="globalcategoryiddropdownfront!==9 && globalcategoryiddropdownfront!==10009"
                    class="table table-bordered" style="width:100%">
                    <thead>
                      <tr>
                        <!-- <th (click)="sort('userName')" style="width: 70px;">ID <i class="fa fa-fw fa-sort"></i></th> -->
                        <th *ngIf="globalcategoryiddropdownfront==0" (click)="sort('globalCodeCategoryName')"> Category
                          Name <i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('globalCodeName')"> Name <i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('globalCodeValue')">Value <i class="fa fa-fw fa-sort"></i></th>
                        <!-- <th >EVB1</th>
                                <th  >EVB1</th>
                                <th  >EVB3</th> -->
                        <th>Notes</th>
                        <th style="text-align: right;" *ngIf="globalcategoryiddropdownfront==8">Total days</th>
                        <th style="text-align: right;" *ngIf="globalcategoryiddropdownfront==8">Price per month</th>
                        <th class="text-center" colspan="3"><button type="button"
                            class="btn btn-primary btn-sm text-white" (click)="openAddModel(ModelAdd,'')">
                            <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                            Add
                          </button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let globalcodelistdata of Globalcodelist | filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">
                        <!-- <td>{{ globalcodelistdata.globalCodeID }}</td> -->
                        <td *ngIf="globalcategoryiddropdownfront==0"> {{ globalcodelistdata.globalCodeCategoryName }}
                        </td>
                        <td> {{ globalcodelistdata.globalCodeName }}</td>
                        <td> {{ globalcodelistdata.globalCodeValue }}</td>
                        <!-- <td> {{ globalcodelistdata.evbI1 }}</td>
                            <td> {{ globalcodelistdata.evbI2 }}</td>
                            <td> {{ globalcodelistdata.evbI3 }}</td> -->
                        <td> {{ globalcodelistdata.globalCodeNotes }}</td>
                        <td style="text-align: right;" *ngIf="globalcategoryiddropdownfront==8">
                          {{globalcodelistdata.noOfDays}}</td>
                        <td style="text-align: right;" *ngIf="globalcategoryiddropdownfront==8">
                          {{globalcodelistdata.price | currency}}</td>
                        <td class="text-center">
                          <button class="btn bg-gradient-info btn-sm"
                            (click)="openAddModel(ModelAdd,globalcodelistdata.globalCodeID)"><i
                              class="fa-solid fa-pen-to-square"></i></button>
                          <button class="btn bg-gradient-danger btn-sm ml-2"
                            (click)="openModal(template,globalcodelistdata.globalCodeID)"><i
                              class="fa fa-trash"></i></button>

                              <button *ngIf="globalcategoryiddropdownfront==7" class="btn bg-gradient-success btn-sm ml-2"
                            (click)="ShowScreenName(templateShowScreen,globalcodelistdata.globalCodeID,globalcodelistdata.globalCodeName)"><i
                              class="fas fa-user-lock"></i></button>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <!--Province table start-->
                  <table id="usertable{{globalcategoryiddropdownfront}}" *ngIf="globalcategoryiddropdownfront==9"
                    class="table table-bordered" style="width:100%">
                    <thead>
                      <tr>
                        <!-- <th (click)="sort('userName')" style="width: 70px;">ID <i class="fa fa-fw fa-sort"></i></th> -->

                        <th (click)="sort('globalCodeName')"> Name <i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('typeglobal')">Type <i class="fa fa-fw fa-sort"></i></th>
                        <th style="text-align: right;" (click)="sort('pST')">PST <i class="fa fa-fw fa-sort"></i></th>
                        <th style="text-align: right;" (click)="sort('gST')">GST <i class="fa fa-fw fa-sort"></i></th>
                        <th style="text-align: right;" (click)="sort('hST')">HST <i class="fa fa-fw fa-sort"></i></th>
                        <th style="text-align: right;" (click)="sort('totalTaxRate')">TotalTaxRate <i class="fa fa-fw fa-sort"></i></th>
                        <!-- <th >EVB1</th>
          <th  >EVB1</th>
          <th  >EVB3</th> -->
                        <th>Notes</th>
                        <th class="text-center" colspan="3"><button type="button"
                            class="btn btn-primary btn-sm text-white" (click)="openAddModel(ModelAdd,'')">
                            <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                            Add
                          </button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let globalcodelistdata of Globalcodelist | filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">
                        <!-- <td>{{ globalcodelistdata.globalCodeID }}</td> -->

                        <td> {{ globalcodelistdata.globalCodeName }}</td>
                        <td> {{ globalcodelistdata.type }}</td>
                        <td style="text-align: right;"> {{ globalcodelistdata.pst }}</td>
                        <td style="text-align: right;"> {{ globalcodelistdata.gst }}</td>
                        <td style="text-align: right;"> {{ globalcodelistdata.hst }}</td>
                        <td style="text-align: right;"> {{ globalcodelistdata.totalTaxRate }}</td>
                        <td> {{ globalcodelistdata.globalCodeNotes }}</td>
                        <td class="text-center">
                          <button class="btn bg-gradient-info btn-sm"
                            (click)="openAddModel(ModelAdd,globalcodelistdata.globalCodeID)"><i
                              class="fa-solid fa-pen-to-square"></i></button>
                          <button class="btn bg-gradient-danger btn-sm ml-2"
                            (click)="openModal(template,globalcodelistdata.globalCodeID)"><i
                              class="fa fa-trash"></i></button>
                        </td>
                      </tr>

                    </tbody>
                  </table>

                  <!--Province table end-->


<!--Updatehome page-->
<table id="usertable{{globalcategoryiddropdownfront}}" *ngIf="globalcategoryiddropdownfront==10009"
class="table table-bordered" style="width:100%">
<thead>
  <tr>
    <!-- <th (click)="sort('userName')" style="width: 70px;">ID <i class="fa fa-fw fa-sort"></i></th> -->
    <th *ngIf="globalcategoryiddropdownfront==0" (click)="sort('globalCodeCategoryName')"> Category
      Name <i class="fa fa-fw fa-sort"></i></th>
    <th (click)="sort('globalCodeName')"> Name <i class="fa fa-fw fa-sort"></i></th>

    <!-- <th >EVB1</th>
            <th  >EVB1</th>
            <th  >EVB3</th> -->
    <th>Notes</th>

    <th class="text-center" colspan="3"><button type="button"
        class="btn btn-primary btn-sm text-white" (click)="openAddModel(ModelAddHomepage,'')">
        <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
        Add
      </button></th>
  </tr>
</thead>
<tbody>
  <tr
    *ngFor="let globalcodelistdata of Globalcodelist | filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">
    <!-- <td>{{ globalcodelistdata.globalCodeID }}</td> -->
    <td *ngIf="globalcategoryiddropdownfront==0"> {{ globalcodelistdata.globalCodeCategoryName }}
    </td>
    <td> {{ globalcodelistdata.globalCodeName }}</td>
    <!-- <td> {{ globalcodelistdata.evbI1 }}</td>
        <td> {{ globalcodelistdata.evbI2 }}</td>
        <td> {{ globalcodelistdata.evbI3 }}</td> -->
    <td> {{ globalcodelistdata.globalCodeNotes }}</td>

    <td class="text-center">
      <button class="btn bg-gradient-info btn-sm"
        (click)="openAddModel(ModelAdd,globalcodelistdata.globalCodeID)"><i
          class="fa-solid fa-pen-to-square"></i></button>
      <button class="btn bg-gradient-danger btn-sm ml-2"
        (click)="openModal(template,globalcodelistdata.globalCodeID)"><i
          class="fa fa-trash"></i></button>


    </td>
  </tr>

</tbody>
</table>

                  <br>
                  <ngb-pagination [collectionSize]="Globalcodelist.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>


                </div>
              </div>

              <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i
                  class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
                  ngbPopover="Are you sure you want to delete?"></i>
              </a>
              <ng-template #template>
                <div class="modal-body text-center">
                  <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                  <p>Are you sure you want to delete?</p>
                  <p></p>
                  <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                  <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                </div>
              </ng-template>





              <!-- --------------------- Add Dealers popup start------------------------ -->

              <ng-template #ModelAdd let-modal>
                <div class="modal-header" style="background-color: black;">
                  <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">
                    {{modalTitle}}
                  </h5>
                  <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">

                  <section class="generate-report">
                    <div class="row">
                      <div class="form-group col-md-6">
                        <label for="exampleFormControlSelect1"> Category </label>
                        <select [disabled]="isDisabled" class="form-control" name="globalcategoryidctrl"
                          [(ngModel)]="globalcategoryidctrl" formControlName="globalcategoryidctrl"
                          (change)="onChange($event.target.value)">
                          <option value="0" selected>Select Category</option>
                          <option *ngFor="let codecategorylist of globalCodeCategory"
                            [value]="codecategorylist.globalCodeCategoryID">{{codecategorylist.globalCodeCategoryName}}
                          </option>
                        </select>
                        <div *ngIf="submitted && f.globalcategoryidctrl.errors" class="invalid-feedback">
                          <div *ngIf="f.globalcategoryidctrl.errors.required"> Category is required</div>
                        </div>
                      </div>

                      <!--  Dealers -->
                      <!-- <div class="form-group">

                              <input type="text" formControlName="customerSetupNumber" [(ngModel)]="customerSetupNumber"
                              class="form-control" placeholder="Customer Name" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.customerSetupNumber.errors }"
                                />
                                <div *ngIf="submitted && f.customerSetupNumber.errors" class="invalid-feedback">
                                  <div *ngIf="f.customerSetupNumber.errors.required">customer Name is required</div>
                              </div>
                            </div> -->

                      <!--  active on -->
                      <dp-date-picker [(ngModel)]="date" name="date" [config]="datePickerConfig"></dp-date-picker>


                      <!--  city -->
                      <div class="form-group col-md-6">
                        <label>Name</label>
                        <input type="text" formControlName="GlobalCodeName" maxlength="100" [(ngModel)]="GlobalCodeName"
                          class="form-control" placeholder="Name" (keypress)="ValidTextBox($event)"
                          [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeName.errors }" />
                        <div *ngIf="submitted && f.GlobalCodeName.errors" class="invalid-feedback">
                          <div *ngIf="f.GlobalCodeName.errors.required">Name is required</div>
                        </div>
                      </div>

                    </div>
                    <hr />

                    <div class="row" *ngIf="SubscriptionDisplay==='true'">
                      <div class="form-group col-md-6">
                        <label for="exampleFormControlSelect1">Price</label>

                        <input type="text" formControlName="PriceControls"
                        [(ngModel)]="PriceControls"
                          class="form-control" placeholder="Price"  />

                      </div>


                      <div class="form-group col-md-6">
                        <label>No of Days</label>
                        <input type="text" formControlName="NoOfDaysControls" [(ngModel)]="NoOfDaysControls"
                          class="form-control" placeholder="No Of Days" />

                      </div>

                    </div>
                    <hr />

                    <div class="row" *ngIf="ProvinceDisplay==='true'">
                      <div class="form-group col-md-6">
                        <label for="exampleFormControlSelect1">Type</label>

                        <input type="text" formControlName="TypeControls" [(ngModel)]="TypeControls"
                          class="form-control" placeholder="Type" (keypress)="ValidTextBox($event)" />

                      </div>


                      <div class="form-group col-md-6">
                        <label>PST</label>
                        <input type="text" formControlName="PSTControls" [(ngModel)]="PSTControls" class="form-control"
                          placeholder="0" (keypress)="ValidTextBox($event)" (keyup)="updateTotalTaxRate()" />

                      </div>

                    </div>

                    <div class="row" *ngIf="ProvinceDisplay==='true'">
                      <div class="form-group col-md-6">
                        <label for="exampleFormControlSelect1">GST</label>

                        <input type="text" formControlName="GSTControls" [(ngModel)]="GSTControls" class="form-control"
                          placeholder="0" (keypress)="ValidTextBox($event)" (keyup)="updateTotalTaxRate()" />

                      </div>


                      <div class="form-group col-md-6">
                        <label>HST</label>
                        <input type="text" formControlName="HSTControls" [(ngModel)]="HSTControls" class="form-control"
                          placeholder="0" (keypress)="ValidTextBox($event)" (keyup)="updateTotalTaxRate()"  />

                      </div>

                    </div>

                    <div class="row" *ngIf="ProvinceDisplay==='true'">
                      <div class="form-group col-md-6">
                        <label for="exampleFormControlSelect1">Total Tax Rate</label>

                        <input type="text" formControlName="TotalTaxRateControls" [(ngModel)]="TotalTaxRateControls"
                          class="form-control" placeholder="0" (keypress)="ValidTextBox($event)" readonly/>

                      </div>


                      <div class="form-group col-md-6">


                      </div>

                    </div>
                    <hr />



                    <div class="row">
                      <!--  GlobalCodeValue -->




                      <div class="form-group col-md-6" *ngIf="globalcategoryidctrl==10009">

                        <label>Date</label>


                        <span type="text" class="form-control">{{DateUpdatefromNgEdit | date}}</span>

                      </div>


                      <div *ngIf="globalcategoryidctrl != 10009" class="form-group col-md-6">
                        <label>Interval Value</label>
                        <input type="text" formControlName="GlobalCodeValue" maxlength="30" [(ngModel)]="GlobalCodeValue" class="form-control"
                          placeholder="Value" (keypress)="ValidTextBox($event)"
                          [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeValue.errors }" />
                        <div *ngIf="submitted && f.GlobalCodeValue.errors" class="invalid-feedback">
                          <div *ngIf="f.GlobalCodeValue.errors.required">Internal Value is required</div>
                        </div>
                      </div>


                      <!--  EVBI1 -->
                      <!-- <div class="form-group">

                                <input type="text" formControlName="EVBI1" [(ngModel)]="EVBI1"
                                class="form-control" placeholder="EVBI1" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EVBI1.errors }"
                                   />
                                   <div *ngIf="submitted && f.EVBI1.errors" class="invalid-feedback">
                                    <div *ngIf="f.EVBI1.errors.required">EVBI1 is required</div>
                                </div>
                    </div> -->



                      <!--  EVBI2 -->
                      <!-- <div class="form-group">

                            <input type="text" formControlName="EVBI2" [(ngModel)]="EVBI2"
                            class="form-control" placeholder="EVBI2" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EVBI2.errors }"
                               />
                               <div *ngIf="submitted && f.EVBI2.errors" class="invalid-feedback">
                                <div *ngIf="f.EVBI2.errors.required">EVBI2 is required</div>
                            </div>
                          </div> -->


                      <!--  EVBI3 -->
                      <!-- <div class="form-group">

                              <input type="text" formControlName="EVBI3" [(ngModel)]="EVBI3"
                              class="form-control" placeholder="EVBI3" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EVBI3.errors }"
                                 />
                                 <div *ngIf="submitted && f.EVBI3.errors" class="invalid-feedback">
                                  <div *ngIf="f.EVBI3.errors.required">EVBI3 is required</div>
                              </div>
                            </div> -->

                      <!--  GlobalCodeNotes -->
                      <div class="form-group col-md-6">
                        <label> Notes</label>
                        <input type="text" formControlName="GlobalCodeNotes" [(ngModel)]="GlobalCodeNotes"
                          class="form-control" placeholder="Notes" (keypress)="ValidTextBox($event)"
                          [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeNotes.errors }" />

                      </div>
                    </div>

                  </section>


                </div>
                <div class="modal-footer">





                  <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                  </button>

                  <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                  </button>


                </div>
              </ng-template>


              <!-- --------------------- Add Dealers popup End------------------------ -->



<!---Update home page model start-->
<ng-template #ModelAddHomepage let-modal>
  <div class="modal-header" style="background-color: black;">
    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">
      {{modalTitle}}
    </h5>
    <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
      aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <section class="generate-report">
      <div class="row">
        <div class="form-group col-md-6">
          <label for="exampleFormControlSelect1"> Category </label>
          <select [disabled]="isDisabled" class="form-control" name="globalcategoryidctrl"
            [(ngModel)]="globalcategoryidctrl" formControlName="globalcategoryidctrl"
            (change)="onChange($event.target.value)">
            <option value="0" selected>Select Category</option>
            <option *ngFor="let codecategorylist of globalCodeCategory"
              [value]="codecategorylist.globalCodeCategoryID">{{codecategorylist.globalCodeCategoryName}}
            </option>
          </select>
          <div *ngIf="submitted && f.globalcategoryidctrl.errors" class="invalid-feedback">
            <div *ngIf="f.globalcategoryidctrl.errors.required"> Category is required</div>
          </div>
        </div>



        <!--  city -->
        <div class="form-group col-md-6">
          <label>Name</label>
          <input type="text" formControlName="GlobalCodeName" maxlength="100" [(ngModel)]="GlobalCodeName"
            class="form-control" placeholder="Name" (keypress)="ValidTextBox($event)"
            [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeName.errors }" />
          <div *ngIf="submitted && f.GlobalCodeName.errors" class="invalid-feedback">
            <div *ngIf="f.GlobalCodeName.errors.required">Name is required</div>
          </div>
        </div>

      </div>
      <hr />










      <div class="row">
        <!--  GlobalCodeValue -->

        <div class="form-group col-md-6">

          <label>Date</label>


          <input type="date" [(ngModel)]="DateUpdatefromNg" formControlName="DateUpdatefromNg"
           class="form-control">
          <!-- <label>Interval Value</label>
          <input type="text" formControlName="GlobalCodeValue" maxlength="30" [(ngModel)]="GlobalCodeValue"
            class="form-control" placeholder="Value" (keypress)="ValidTextBox($event)"
            [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeValue.errors }" />
          <div *ngIf="submitted && f.GlobalCodeValue.errors" class="invalid-feedback">
            <div *ngIf="f.GlobalCodeValue.errors.required">Internal Value is required</div>
          </div> -->
        </div>


        <!--  GlobalCodeNotes -->
        <div class="form-group col-md-6">
          <label> Notes</label>
          <input type="text" formControlName="GlobalCodeNotes" [(ngModel)]="GlobalCodeNotes"
            class="form-control" placeholder="Notes" (keypress)="ValidTextBox($event)"
            [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeNotes.errors }" />

        </div>
      </div>

    </section>


  </div>
  <div class="modal-footer">





    <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
    </button>

    <button class="btn btn-danger btn-sm text-white" (click)="CloseAddmodel()">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
    </button>


  </div>
</ng-template>



              <!--
                edit popup  start-->
              <!-- <ng-template #editpopup let-modal>
                  <div class="modal-header" style="background-color: black;">
                    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Edit Group</h5>
                     <button type="button" class="close" style="color: white;" #closebutton (click)="closeEditModel()"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div class="modal-body">

                  <section class="generate-report">
                    <div class="col-12">
                        <p style="margin-left: -15px;">Name
                        </p>
                    </div>
                    <div class="form-group">
                      <input type="text" formControlName="Editname"
                      class="form-control" (keypress)="ValidTextBox($event)" [(ngModel)] ="editvaluename" [ngClass]="{ 'is-invalid': submitted && f.Editname.errors }"
                         />

                         <div *ngIf="submitted && f.Editname.errors" class="invalid-feedback">
                          <div *ngIf="f.Editname.errors.required">Group Name is required</div>
                      </div>

                    </div>
                    </section>
                    </div>

                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-sm text-white" (click)="update();">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
                    </button>

                    <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                    </button>


                  </div>




                </ng-template> -->
              <!--
                edit popup  end-->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <app-footer id="secondfooter"></app-footer> -->

  </form>

     <!----Show screen start-->
     <ng-template #templateShowScreen>
      <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">

          Screen  Permission    &nbsp;&nbsp;&nbsp;&nbsp; Role Name:{{selectedGlobalcodeName}}
        </h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>

      <div class="modal-body  screenpermission">
      <!--Province table start-->

      <!-- <div id="screen-list" class="table-responsive">
        <table id="Screentable{{globalcategoryiddropdownfront}}" *ngIf="globalcategoryiddropdownfront==7"
          class="table table-bordered" style="width:100%">
          <thead>
            <tr>
              <th (click)="sort('globalCodeName')"> Name <i class="fa fa-fw fa-sort"></i></th>
              <th style="max-width:35px;text-align: center;" (click)="sort('typeglobal')">Access <i
                  class="fa fa-fw fa-sort"></i></th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let ScreenListdata of ScreenList; let i = index; let odd = odd">

              <td> {{ ScreenListdata.screenname }}</td>
              <td class="text-center">
                <input type="checkbox" [(ngModel)]="ScreenListdata.isSelected">


              </td>
            </tr>

          </tbody>
        </table>
      </div> -->

<div  *ngFor="let ScreenMasterListdata of ScreenList; let i = index; let odd = odd">

      <div class="form-group col-md-12 mb-2">
        <div class="w-100 mb-1">
          <div class="control-label" for="facilities">
            <input type="checkbox" [(ngModel)]="ScreenMasterListdata.ismasterselected" (change)="MasterscreencheckValue($event,ScreenMasterListdata.id,ScreenMasterListdata.ismasterselected)" />
            <b class="ml-2">{{ ScreenMasterListdata.screenname }}</b>
          </div>

        </div>

        <div class="col-md-12">
          <div class="row justify-content-between align-items-center">
            <div class="column_2  form-group col-md-4 col-12 mb-0"
              *ngFor="let ScreenListdata of ScreenMasterListdata.submenu; let a = index; let odd_a = odd_a">
              <span>
                <input type="checkbox" [(ngModel)]="ScreenListdata.isSelected">
                <label class="checkbox-inline ml-2">{{ScreenListdata.screenname}}</label>
              </span>

            </div>
          </div>
        </div>
        <hr class="my-1"/>
      </div>

</div>

      </div>
      <div class="model-footer col-12 mb-2">
        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="AssignRoleToScreen()">Assign</button>
        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
      </div>
    </ng-template>
    <!--Show screen end-->


</section>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
  <p style="color: white"> Loading... </p>
</ngx-spinner>