import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ST_CustomRules } from '@app/_models/ST_CustomRules';
import { NavigationExtras } from "@angular/router";
import { AIXLenders } from '@app/_models/AIXLenders';
@Component({
  selector: 'fresh-build-car',
  templateUrl: './fresh-build.component.html',
  styleUrls: ['./fresh-build.component.less']
})
export class FreshBuildComponent implements OnInit {
  buildCarForm: FormGroup;
  parameters: any;
  vinindex:any;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private globalcodeService: GlobalcodeService,
    private lenderService: LenderService,
    private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService, private modalService: NgbModal)
    {
    this.notifier = notifier;
    //this.parameters = this.router.getCurrentNavigation().extras.state.builparameter;
    this.vinindex=this.route.snapshot.paramMap.get('vin');
  }

  ValueSetForAdjustModelPopUp:any;

  ConditionControl: any;
  VINValueControl:any;
  GrossProfitControl:any;
  MakeControl: any;
  AmountFinancedControl: any;
  CBBValueControl: any;
  MonthlyControl: any;
  interestRateControl: any;
  StockNumberControl: any;
  ModelControl: any;
  ListPriceControl: any;
  AdjustLogID:any;
  DealerCostControl: any;
  SemiMonthlyControl: any;
  WeeklyControl:any;
  VINControl: any;
  UserId:any;
  TrimControl: any;
  TradeAllowanceControl: any;
  LienControl: any;
  BiWeeklyControl: any;
  ReservesControl: any;
  KmsControl: any;
  Trim1Control: any;
  ACVControl: any;
  EquityControl: any;
  TotalLoantoValueControl: any;
  Reserves1Control: any;
  DetailsControl: any;
  DownPaymentControl: any;
  TotalTaxControl: any;
  TermControl: any;
  LenderFeeControl: any;
  RegionControl: any;
  TaxRateControl: any;
  PPSAControl: any;
  GPSFeeControlValue:any;
  TOTALAccessoriesControl: any;
  TOTALCostControl: any;
  DealerAdminFreeControl: any;

  OverRideBLTVControl: any;
  OverRideFLTVControl: any;

  OverRideBLTVControlLoadValue: any;
  OverRideFLTVControlLoadValue: any;
  Accessories1Control: any;
  Accessories2Control: any;
  Accessories3Control: any;
  modalRef: BsModalRef;
  Cost1Control: any;
  Cost2Control: any;
  Cost3Control: any;

  TOTALAddOnsControls: any;
  TOTALBackEndCostControls: any;

  ExtendendServiceContractControls: any;
  ExtendendCostControls: any;
  GapControls: any;
  GapCostControls: any;
  // @ViewChild('#adjustpaymentbuttonToBeClicked') buttonToBeClicked: ElementRef;
  @ViewChild('adjustpaymentbuttonToBeClicked') closebutton;
  JobLossControls: any;
  BackDocFee:any;
  JobLossCostControls: any;
  VinNumber: any;
  ColorTheme: any;
  Checkname: any;
  regionIdValue:any;
  CurrentPayment: any;
  DesiredPayment: any;
  TotalAdjustments: any;
  AdjustmentstolistPrice: any;
  AdjustmentstolistPriceButton: any;
  AdjustmentstoExtendedWarrantyButton:any;
  AdjustmentsGapButton:any;
  AmountOfTax:any;
  AdjustmentsJobButton:any;
  AdjustmentsAccessoriesButton:any;
  AdjustmentstoExtendedWarranty: any;
      AdjustmentsGap: any;
      AdjustmentsJobLoss:any;
      AdjustmentsAccessories:any;

      valueforOpCode:any;
      AdjustmentsGapCheckValue: any;
      AdjustmentsExtCheckValue: any;
      AdjustmentsListPriceCheckValue: any;
      AdjustmentsJobCheckValue:any;
      AdjustmentsAccessCheckValue:any;
  AdjustmentsListPrice: any;
  SelectedGroupName:any;
  AdjustmentsNewListPrice: any;
  AdjustmentsMonthlyPayment: any;
  AdjustmentsNewMonthlyPayment: any;
  AdjustmentsGrossProfit: any;
  AdjustmentsNewGrossProfit: any;
  AdjustmentsRemainingFLTV: any;
  AdjustmentsNewRemainingFLTV: any;
  AdjustmentsRemainingBLTV: any;
  AdjustmentsNewRemainingBLTV: any;
  AdjustmentsTotalTaxes: any;
  AdjustmentsNewTotalTaxes: any;
  AdjustmentFrontDocumentationFee: any;
  AdjustmentNewFrontDocumentationFee: any;

  lenderIdValue: any;
  LenderPayCall: any;
  openAdjustMentPopup:any;
  LenderInterestRate: any;
  ACVValueControl: any;
  LoanAmountControl: any;
  TotalFrontproducts: any;
  TotalFrontcost: any;
  TotalBackproducts: any;
  TotalBackcost: any;
  DownPaymentAmountControl: any;
  LenderKeyValue: any;
  ProvinceIdValue: any;
  GroupNameId: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;

  PayCallValueCheck: any;
  Provincelist:[];
  DealerIdvalue: any;
  RemainingFrontLoanValue:any;
  TotalofFeeandRemainingValue:any;
  RemainingFrontLoanValues:any;
  RemainingFrontLoanValueCheck:any;
   RemainingBackLoanValue:any;
  RemainingBackLoanValues:any;
  public isVisible: boolean = false;
  public isRemainingVisible: boolean = false;
  public isLoanToValueVisible: boolean = false;
  public isRemainingBackEndVisible: boolean = false;

  CalTotalLoanamount: any;
  CalDesiredMonthlyPayment: any;
  CalInterestRate: any;
  CalLoanTerm: any;

  public isListPrice: boolean = false;
  // Variables
  SemiMonthlyValues: any;
  BiMonthlyValues: any;
  BiWeekValues: any;
  biMonth: any;
  RuleSetControl:any;
  customRuleList: ST_CustomRules[] = [];
  UpperLimitControl:any;

  LowerLimitControl:any;
  buttonloader: boolean = false;
  buttonReset: boolean = false;
  buttonloaderevaluate: boolean = false;

  buttonloaderSendEmail: boolean = false;
  buttonloaderBuyEmail: boolean = false;
  AithrHubKey: any;
  LenderNameValue: any;
  AddressValue:any;
  EmailValue:any;
  PhoneNumberValue:any;

  PlanType:any;
  PayCallDisaply:any;
  InterestRateDisplay:any;
  AllowableFinanceDisplay:any;
  delaeridforserver = [];
  regionidforserver = [];
 colormessage:any;

 colorAdjustListPrice:any;
 colorAdjustMonthly:any;
 colorAdjustGrossProfit:any;
 colorAdjustTotalTaxes:any;
 colorAdjustFLTV:any;
 colorAdjustBLTV:any;
 colorAdjustFrontFee:any;
 useridValue:any;
 ipaddressvalue:any;
 isShowDiv=false;
 islenderinfo=false;
 hideShowTopButtons:any;

 // Verify

 selectedVin: any;
 typeofaction: any;
 hideCostButton:any;
 hidetopBarButton:any;
 divTopButtonsshow:any;
 InfoFirstName:any;
 InfoLastName:any;
 InfoPhone:any;
 InfoEmail:any;
TOTALCosthide: any;
Cost1Hide: any;
Cost2Hide: any;
Cost3Hide: any;

BackendCostHide: any;
ExtendendCostHide: any;
GapCostHide: any;
JobLossHide: any;
lenderlists: AIXLenders[] = [];
lenderKeyList = [];
UnitList=[];
 verifycheckboxes = [];
 hideDealerCost:any;
 hideGrossProfit:any;
 istaxExemptValue:any;
  ngOnInit(): void {
    debugger;
    this.Createform()

    this.ColorTheme = localStorage.getItem('colorTheme');
    this.regionIdValue = localStorage.getItem('Province');

    this.GetProvincelist();
    this.istaxExemptValue=false;
    this.Getlenderslist();
    this.colormessage ="1";
    this.AdjustLogID=0;
    this.AmountOfTax=0;
    this.islenderinfo=false;
    this.InterestRateDisplay=0;
    this.PayCallDisaply=0;
    this.hideShowTopButtons="false";
    this.InfoFirstName="Capital ";
    this.AithrHubKey ="1";
    this.DownPaymentAmountControl=0;
    this.TOTALAddOnsControls=0;
    this.TOTALCostControl=0;
    this.ExtendendServiceContractControls=0;
    this.GapControls=0;
    this.JobLossControls=0;
    this.AllowableFinanceDisplay=0
    this.TOTALAccessoriesControl=0;
    this.JobLossControls=0;
    this.OverRideFLTVControl=0;
    this.OverRideBLTVControl=0;
    this.BackDocFee=0;
    this.PPSAControl=0;
    this.LenderFeeControl=0;
    this.ReservesControl=0;
    this.AmountFinancedControl=0;
    this.ListPriceControl=0;
    this.interestRateControl=0;
    this.TermControl=0;
    this.TradeAllowanceControl=0;
    this.LienControl=0;
    this.DownPaymentControl=0;
    this.AithrHubKey=0;
    this.TOTALBackEndCostControls=0;
    this.InfoLastName="Auto";
    this.InfoPhone="9876953067";
    this.InfoEmail="capitalauto@aithr.ca";
    this.divTopButtonsshow ="false";

    this.LenderInterestRate=0;
    this.ACVValueControl=0;
    this.LoanAmountControl=0;
    this.TotalFrontproducts=0;
    this.TotalFrontcost=0;
    this.TotalBackproducts=0;
    this.TotalBackcost=0;
    this.RemainingFrontLoanValue=0;
    this.TotalofFeeandRemainingValue=0;
    this.RemainingFrontLoanValues=0;
   this.RemainingFrontLoanValueCheck=0;
   this.RemainingBackLoanValue=0;
   this.RemainingBackLoanValues=0;
   this.DealerAdminFreeControl=0;
    this.hidetopBarButton ="true";
    this.CalTotalLoanamount=0;
    this.CalDesiredMonthlyPayment=0;
    this.CalInterestRate=0;
    this.CalLoanTerm=0;
    this.hideCostButton = "true";
    this.hideDealerCost= "true";
    this.hideGrossProfit= "true";

    this.TOTALCosthide= "true";
    this.Cost1Hide= "true";
    this.Cost2Hide= "true";
    this.Cost3Hide= "true";

    this.BackendCostHide= "true";
    this.ExtendendCostHide= "true";
    this.GapCostHide= "true";
    this.JobLossHide= "true";

    this.AdjustmentsJobCheckValue=0;
    this.AdjustmentsAccessCheckValue=0;
    this.AdjustmentsAccessoriesButton=0;
    this.colorAdjustListPrice ="1";
    this.colorAdjustMonthly="1";
    this.colorAdjustGrossProfit="1";
    this.colorAdjustTotalTaxes="1";
    this.colorAdjustFLTV="1";
    this.colorAdjustBLTV="1";
    this.colorAdjustFrontFee ="1";
    this.openAdjustMentPopup ="False";
    this.useridValue=localStorage.getItem('userid');
    this.ipaddressvalue=localStorage.getItem('IpAddresstoTrack');
    this.GetbuildLocalstorage();
    this.RemainingFrontLoanValue =0;
    this. RemainingFrontLoanValues=0;
    this.RemainingFrontLoanValueCheck =0;
    this.GetCustomRuleList();
    this.RemainingBackLoanValue =0;
    this. RemainingBackLoanValues=0;
    this.TOTALAddOnsControls = 0;
    this.TOTALBackEndCostControls = 0;
    this.ACVControl = 0;
    this.TotalofFeeandRemainingValue =0;
    this.Reserves1Control = 0;
    this.TradeAllowanceControl =0;
    this.ReservesControl = 0;
    this.SemiMonthlyValues = 0;
    this.BiMonthlyValues = 0;
    this.biMonth = 0;

    this.AdjustmentstolistPrice = 0;

    this.AdjustmentstolistPriceButton= 0;
    this.AdjustmentstoExtendedWarrantyButton= 0;
    this.AdjustmentsGapButton= 0;
    this.AdjustmentsJobButton=0;
    this.AdjustmentstoExtendedWarranty = 0;
    this.AdjustmentsGap = 0;
    this.AdjustmentsJobLoss=0;
    this.AdjustmentsAccessories=0;
   // this.TotalTaxControl = 0;
    this.Accessories1Control = 0;
    this.Accessories2Control = 0;
    this.Accessories3Control = 0;
    this.DealerAdminFreeControl = 0;
    this.Cost1Control = 0;
    this.Cost2Control = 0;
    this.Cost3Control = 0;
    this.TOTALAccessoriesControl = 0;
    this.TOTALBackEndCostControls = 0;
    this.ExtendendCostControls = 0;
    this.GapCostControls = 0;
    this.JobLossCostControls = 0;
    this.JobLossControls = 0;
    this.TotalFrontproducts=0;
    this.TotalFrontcost=0;
    this.TotalBackproducts=0;
    this.TotalBackcost=0;
    this.GapControls = 0;
    this.ExtendendServiceContractControls = 0;
    this.CurrentPayment = 0;
    this.DesiredPayment = 0;
    this.TotalAdjustments = 0;
    this.AdjustmentstolistPrice = 0;
    var parameterValuescheck = localStorage.getItem('builparameter'+this.vinindex);
    let objValuescheck = JSON.parse(parameterValuescheck);
    this.TotalTaxControl =  parseFloat(objValuescheck.totalTaxes).toFixed(2)
    this.AdjustmentsListPrice = parseFloat(objValuescheck.listPrice).toFixed(2);
    this.AdjustmentFrontDocumentationFee = parseFloat(objValuescheck.frontDocFee).toFixed(2);

    
  
    this.SelectedGroupName = objValuescheck.groupName;
    this.AdjustmentsNewListPrice = 0.00;

    this.AdjustmentsGapCheckValue= 0.00;
    this. AdjustmentsExtCheckValue= 0.00;
    this.AdjustmentsListPriceCheckValue= 0.00;

   // this.AdjustmentFrontDocumentationFee =  0.00;
    this.AdjustmentNewFrontDocumentationFee =  0.00;

   

    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    this.VinNumber = objValuescheck.vin; //this.route.snapshot.paramMap.get('vin');
    // this.GetAllInventoryListbyVin(this.VinNumber);


  }
  GetLenderKeyList()
  {
    this.GetLenderKeys(this.LenderNameValue,this.LenderNameValue);
  }
  Getlenderslist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          this.lenderlists = data;
        
        },
        error => {
        });
  }

  GetLenderKeys(LenderId: any, LenderName: any) {
    var modelGroup = {
      "id": LenderId,
      "LenderName": LenderName
    }
    this.manageInventoryService.getLenderFreshBuildKeys(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderKeyList = data; 
          
          this.LenderFeeControl = parseFloat(data[0].lenderFee).toFixed(2);
        },
        error => {
        });
  }

  GetDetail()
  {
    this.GetDetailofUnitByVin();
  }
  GetDetailofUnitByVin() {
    debugger;
    var modelGroup = {
      "DealerName": this.VINValueControl,
      "VIN": this.VINValueControl
    }
    this.manageInventoryService.getDetailofUnitByVin(modelGroup)
      .subscribe(
        (data: any) => {
          this.UnitList = data; 
          this.StockNumberControl =data[0].stockNumber;
          this.ConditionControl=  data[0].condition;
          this.MakeControl =   data[0].make;   
          this.ModelControl =   data[0].model;
          this.VINControl=    data[0].vin;
          this.KmsControl=data[0].mileage;
          this.Trim1Control = data[0].carYr;
          this.TrimControl = data[0].trim;
          this.CBBValueControl = parseFloat(data[0].cbbValA).toFixed(2);
          this.ListPriceControl = parseFloat(data[0].listedPrice).toFixed(2);
          this.DealerCostControl = parseFloat(data[0].dealerCost).toFixed(2);
          this.DetailsControl = data[0].vehicleInfo;
        },
        error => {
        });


  }
  showAlert() : void {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    setTimeout(()=> this.isVisible = false,2500)
  }

  RefreshAdjustValues() {
   // this.AdjustmentsJobLoss = 0;
    this.AdjustmentsJobButton = 0;

    this.AdjustmentsGapButton = 0;
   // this.AdjustmentsGap = 0;
    this.AdjustmentstoExtendedWarrantyButton = 0;
   // this.AdjustmentstoExtendedWarranty = 0;

   // this.AdjustmentsAccessories = 0;
    this.AdjustmentsAccessoriesButton = 0;

    this.AdjustmentstolistPriceButton = 0;
  //  this.AdjustmentstolistPrice = 0;

    this.TotalAdjustments = 0;
    this.DesiredPayment = 0;
   // this.CurrentPayment = 0;
  }
  OnBlurListPrice()
  {
 
    this.valueforOpCode ="11";

    if (parseFloat(this.AdjustmentstolistPrice) > 0) {
      //  this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
      //   this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    } else {
      if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
      {
        this.AdjustmentsListPriceCheckValue =0; 
        this.AdjustmentstolistPrice =0; 
      } else{
        this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
        this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
      }
    }

    // if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
    // {
    //   this.AdjustmentsListPriceCheckValue =0; 
    //   this.AdjustmentstolistPrice =0; 
    // } else{
    //   this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
    //   this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    // }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustListPriceButton() {
    this.valueforOpCode ="21";

    if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
    {
      this.AdjustmentsListPriceCheckValue =0; 
      this.AdjustmentstolistPrice =0; 
    } else{
      this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
      this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    }

   
    this.AdjustPaymentForAllControlsandButtonClicks();
  }

  OnBlurGap()
  {
    this.valueforOpCode ="14";
     
    if (parseFloat(this.AdjustmentsGap) > 0) {
      // this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
      // this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
    } else {
      if (parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton)) < 0) {
        this.AdjustmentsGapCheckValue = 0;
        this.AdjustmentsGap = 0;
      } else {
        this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
        this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
      }
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustGapButton() {
    this.valueforOpCode ="24";
    if(parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton)) < 0 )
    {
      this.AdjustmentsGapCheckValue =0; 
      this.AdjustmentsGap =0; 
    } else{
      this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
    this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  OnBlurAccessories()
  {
    this.valueforOpCode ="12";

    if (parseFloat(this.AdjustmentsAccessories) > 0) {
      // this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
      // this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
    } else {
      if (parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton)) < 0) {
        this.AdjustmentsAccessCheckValue = 0;
        this.AdjustmentsAccessories = 0;
      } else {
        this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
        this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
      }
    }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustAccessoriesButton()
  {
    this.valueforOpCode ="22";
    if(parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton)) < 0 )
    {
      this.AdjustmentsAccessCheckValue =0; 
      this.AdjustmentsAccessories =0; 
    } else{
      this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
      this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
    }
    
    this.AdjustPaymentForAllControlsandButtonClicks();
  }

  OnBlurJobLoss()
  {
    this.valueforOpCode ="15";

    if (parseFloat(this.AdjustmentsJobLoss) > 0) {
      // this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
      // this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    } else {

      if (parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0) {
        this.AdjustmentsJobCheckValue = 0;
        this.AdjustmentsJobLoss = 0;
      } else {
        this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
        this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
      }
    }

    // if(parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0 )
    // {
    //   this.AdjustmentsJobCheckValue =0; 
    //   this.AdjustmentsJobLoss =0; 
    // } else{
    //   this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
    //   this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    // }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustJobButton()
  {
    this.valueforOpCode ="25";
    if(parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0 )
    {
      this.AdjustmentsJobCheckValue =0; 
      this.AdjustmentsJobLoss =0; 
    } else{
      this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
      this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    }
    
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  OnBlurExtendedWarranty()
  {
   
    this.valueforOpCode = "13";
    if (parseFloat(this.AdjustmentstoExtendedWarranty) > 0) {

      // this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
      // this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);


    } else {

      if (parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton)) < 0) {
        this.AdjustmentsExtCheckValue = 0;
        this.AdjustmentstoExtendedWarranty = 0;
      } else {
        this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
        this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);
      }
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustExtendedButton() {
    this.valueforOpCode ="23";
    if(parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton)) < 0 )
    {
      this.AdjustmentsExtCheckValue =0; 
      this.AdjustmentstoExtendedWarranty =0; 
    } else{
      this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
      this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);
    }
    
    this.AdjustPaymentForAllControlsandButtonClicks();

  }


  AdjustRollPayment()
  {
    this.valueforOpCode="90";
    this.modalRef.hide();
    this.ListPriceControl =parseFloat(this.AdjustmentstolistPrice).toFixed(2);
    this.ExtendendServiceContractControls =parseFloat(this.AdjustmentstoExtendedWarranty).toFixed(2);
    this.GapControls =parseFloat(this.AdjustmentsGap).toFixed(2);
    this.JobLossControls=parseFloat(this.AdjustmentsJobLoss).toFixed(2);
    this.TOTALAccessoriesControl=parseFloat(this.AdjustmentsAccessories).toFixed(2);

    this.TOTALAddOnsControls = parseFloat(this.AdjustmentstoExtendedWarranty) + parseFloat(this.AdjustmentsGap) + parseFloat(this.AdjustmentsJobLoss);
     this.AdjustPaymentForAllControlsandButtonClicks();
     

     // for clear values
     this.RefreshAdjustValues();
    this.ReEvaluate();
  }

  // Call the Adjust function with opcode one

  AdjustPaymentForAllControlsandButtonClicks()
  {
    
    var modelGroup = {
      "Opcode":this.valueforOpCode,
      "PayCallOnScreen": parseFloat(this.CurrentPayment),
      "PayCallToBe": parseFloat(this.DesiredPayment),//this.findCarForm.controls.dealerName.value,
      'Interestrate': parseFloat(this.LenderInterestRate),
      "TermPeriod": parseFloat(this.TermControl),
      "TaxRate": parseFloat(this.TaxRateControl),
      "TotalAdjustments": parseFloat(this.TotalAdjustments),// this.findCarForm.controls.TaxExemptionCheck.value,
      "AmountBeforeTax": parseFloat(this.AdjustmentstolistPriceButton),
      "AmountOfTax": parseFloat(this.AmountOfTax),
      "AmountNonTaxable": parseFloat(this.AdjustmentsGapButton),
      "AdjustLogID": this.AdjustLogID,
      "ListedPrice": parseFloat(this.AdjustmentstolistPrice),
      "FEProducts": parseFloat(this.AdjustmentsAccessories),
      "ExtWarranty": parseFloat(this.AdjustmentstoExtendedWarranty),
      "GAP":parseFloat(this.AdjustmentsGap),
      "JobLoss":parseFloat(this.AdjustmentsJobLoss),
      "VIN": this.VinNumber,
      "DealerID": "0",
      "VehicleId":"0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":""
    }
    this.GetAdjustedValues(modelGroup);
  }

  showReamingAlert() : void {
    if (this.isRemainingVisible) {
      return;
    }
    this.isRemainingVisible = true;
    setTimeout(()=> this.isRemainingVisible = false,2500)
  }

  showReamingBackEndAlert() : void {
    if (this.isRemainingBackEndVisible) {
      return;
    }
    this.isRemainingBackEndVisible = true;
    setTimeout(()=> this.isRemainingBackEndVisible = false,2500)
  }

  showLoanAlert() : void {
    if (this.isLoanToValueVisible) {
      return;
    }
    this.isLoanToValueVisible = true;
    setTimeout(()=> this.isLoanToValueVisible = false,2500)
  }
  IncreaseTerms(value: any) {
    this.TermControl = parseInt(value) + 1;
  }
  DecreaseTerms(value: any) {
    this.TermControl = parseInt(value) - 1;
  }
  GetbuildLocalstorage() {
    

    this.ValueSetForAdjustModelPopUp="Load";
    var parameterValues = localStorage.getItem('builparameter'+this.vinindex);
    let objValues = JSON.parse(parameterValues);
     this.istaxExemptValue =  objValues.isTaxExempt;
    this.GPSFeeControlValue= parseFloat(objValues.gpsFee).toFixed(2); ; 
    this.AdjustmentsListPrice = parseFloat(objValues.listPrice).toFixed(2);

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = objValues.GroupNameId;
    this.DealerIdvalue = objValues.DealerId;

    this.lenderIdValue = objValues.LenderId;

     // function for getting Detail of Lender
     this.GetLenderDetailByID();
    this.LenderPayCall = objValues.PayCall;
    this.LenderInterestRate = objValues.InterestRate;
    this.ACVValueControl = objValues.tradevalue;
    this.LoanAmountControl = objValues.LoanAmount;
    this.AithrHubKey = objValues.IsAithrHub;
    this.LenderNameValue=objValues.LenderNameValue;
    this.PlanType = objValues.PlanType;
    this.AllowableFinanceDisplay = parseFloat(objValues.allowableFinance).toFixed(2) ;
    this.PayCallDisaply = parseFloat(objValues.PayCall).toFixed(2) ;
    this.InterestRateDisplay =parseFloat(objValues.InterestRate).toFixed(2) ;
    this.PayCallValueCheck ="Load";

    this.OverRideBLTVControlLoadValue= parseInt(objValues.bltv);
    this.OverRideFLTVControlLoadValue= parseInt(objValues.fltv);
    this.OverRideBLTVControl= parseInt(objValues.bltv);
    this.OverRideFLTVControl= parseInt(objValues.fltv);
    // this.OverRideBLTVControl= 0;
    // this.OverRideFLTVControl= 0;

    if(this.AithrHubKey =="1")
    {
      this.delaeridforserver = [];
    } else
    {
      this.delaeridforserver = objValues.DealerIDList;
    }

    // if(this.AithrHubKey =="1")
    // {
    //   this.regionidforserver = [];
    // } else
    // {
    //   this.regionidforserver = objValues.RegionIDList;
    // }
    this.regionidforserver = objValues.RegionIDList;

    //this.TotalTaxControl =objValues.totalTaxes;
    this.DownPaymentAmountControl = objValues.DownPayment;
    if (this.DownPaymentAmountControl == undefined) {
      this.DownPaymentAmountControl = "0";
    }

    this.LenderKeyValue = objValues.LenderKey;
    this.ProvinceIdValue = objValues.ProvinceId;
    this.GroupNameId = objValues.GroupNameId;
    this.VinNumber = objValues.vin;
    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.regionIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LoanAmountControl),
      "DownPaymentValue": parseFloat(this.DownPaymentAmountControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "0",
      "TradeInValue":"0",
      "TotalFrontproducts": "0",
      "TotalFrontcost": "0",
      "FrontDocFee":"0",
      "TotalBackproducts": "0",
      "TotalBackcost": "0",
      "ExtWarranty":"0",
      "GAP":"0",
      "JobLoss":"0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice":  "0",
      "ScreenIntRate":  "0",
      "ScreenTermPeriod":  "0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":parseFloat(this.DealerCostControl),
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "Kilometer":this.KmsControl,
     "Year":this.Trim1Control,
     "CbbValue":this.CBBValueControl,
     "TaxRate":this.TaxRateControl
    }
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
    this.GetRegionDetailbyId();
  }

  GetRegionDetailbyId() {
    var modelGroup = {
      "GlobalCodeID": parseInt(this.ProvinceIdValue)
     
    }
    this.manageInventoryService.getRegionDetailbyId(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.RegionControl = data[0].globalCodeName;
          this.TaxRateControl = parseFloat(data[0].totalTaxRate).toFixed(2);
        },
        error => {
        });


  }

  ngAfterViewInit(): void {
    this.closebutton.nativeElement.value = 'Whale!';
    // this.closebutton.nativeElement.click();

  }


  openModal(template: TemplateRef<any>, itemid: any) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  openLenderInfoModal(template: TemplateRef<any>, itemid: any) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-sm modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  openRollPaymentModel(targetModal) {
    
    this.RefreshAdjustValues();
    this.AdjustmentstoExtendedWarranty =parseFloat(this.ExtendendServiceContractControls) ;
   this.AdjustmentsGap = parseFloat(this.GapControls);
   this.AdjustmentsJobLoss =  parseFloat(this.JobLossControls);
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

  }

  // function for Adjustment Decline
  CloseAdjustMentmodel() {
    this.modalRef.hide();
   // this.GetbuildLocalstorage();
  }

  // function for Adjustment Accpet for sending New Price
  AcceptAdjustment()
  {
    this.modalRef.hide();
    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.regionIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "1",
      "TradeInValue":parseInt(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.AdjustmentFrontDocumentationFee,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.AdjustmentsNewListPrice,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "Kilometer":this.KmsControl,
     "Year":this.Trim1Control,
     "CbbValue":this.CBBValueControl,
     "TaxRate":this.TaxRateControl
    }

    //this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  CloseAddmodel() {
    this.modalRef.hide();
    this.valueforOpCode="99";
    this.AdjustPaymentForAllControlsandButtonClicks();
    this.RefreshAdjustValues();
  }
 

  // functions for Back doc fee and Front doc Fee Changes
  keyupFrontDoc(event) {
    // this.DealerAdminFreeControl = parseInt(event);

    this.DealerAdminFreeControl =event;

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

        //  this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
        //  this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

        this.ReEvaluate();
  }

  keyupBackDoc(event) {
    this.BackDocFee = parseInt(event);
    this.ExtendendServiceContractControls = parseInt(event);
    this.TOTALAddOnsControls = parseInt(this.ExtendendServiceContractControls) + parseInt(this.GapControls) + parseInt(this.JobLossControls);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    ///this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    ///this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
    this.ReEvaluate();

  }

  keyupAccessories(event) {
   
    this.Accessories1Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) +
                                   parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);

     // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }
    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    //this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
    this.ReEvaluate();



  }

  showAlertCheckListPrice(): void {
    if (this.isListPrice) {
      return;
    }
    this.isListPrice = true;
    setTimeout(() => this.isListPrice = false, 2500)
  }
  CheckListPrice(event) {
   
   // this.ListPriceControl = parseInt(event);
    if (parseFloat(this.DealerCostControl) > parseFloat(this.ListPriceControl)) {
      this.showAlertCheckListPrice();
    }

   // this.DealerAdminFreeControl =event;

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

          // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
          // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

          this.ReEvaluate();
  }

  ChangeTrade(event) {
   
    // this.TradeAllowanceControl = parseInt(event);
    this.TradeAllowanceControl = event;
    this.EquityControl = (parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl)).toFixed(2);

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )
   // var equityvalue=(parseFloat(this.EquityControl) * -1);
   // var equityvalue=(parseFloat(this.EquityControl) * -1);
   // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -equityvalue + parseFloat(this.DownPaymentControl));
   // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

   this.ReEvaluate();
  }
  ChangeLien(event) {
    // this.LienControl = parseInt(event);

    this.LienControl = event;
    this.EquityControl = parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl);
    //this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
    this.EquityControl = parseFloat(this.EquityControl).toFixed(2);
   // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
   this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
   if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
     this.showReamingAlert();
   }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
   //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

   this.ReEvaluate();
  }

  ChangeDown(event) {
   
    this.DownPaymentControl = parseInt(event);
    this.DownPaymentControl = event;
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

         // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
         // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
         this.ReEvaluate();

        }
  ChangeAccV(event) {
    // this.ACVValueControl = parseInt(event);

    this.ACVValueControl = event;
    //this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
   // this.EquityControl = parseFloat(this.EquityControl).toFixed(2);
    //this.ACVValueControl = parseFloat(this.ACVValueControl).toFixed(2);
    this.ReEvaluate();
  }


  keyupAccessories2(event) {
    //this.TOTALAccessoriesControl = parseInt(event);
    // this.Accessories2Control = parseInt(event);

    this.Accessories2Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) + parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

   // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

    this.ReEvaluate();

  }
  keyupAccessories3(event) {
    //this.TOTALAccessoriesControl = parseInt(event);
    // this.Accessories3Control = parseInt(event);
    this.Accessories3Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) + parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);

    // Remaaing FTLV
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }
   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
  // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

  this.ReEvaluate();

  }

  keyupCost(event) {

    // this.Cost1Control = parseInt(event);

    this.Cost1Control = event;
    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    //this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
    this.ReEvaluate();

  }

  keyupCost2(event) {

    // this.Cost2Control = parseInt(event);
    this.Cost2Control =event;

    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

    this.ReEvaluate();
  }
  keyupCost3(event) {

    // this.Cost3Control = parseInt(event);
    this.Cost3Control = event;
    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaaing FTLV

   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
  // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

  this.ReEvaluate();
  }

  keyupExtendendService(event) {

     this.ExtendendServiceContractControls = event;

    // this.Cost3Control = event;
    this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + parseFloat(this.JobLossControls);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

    // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }
  keyupGapValue(event) {
    // this.GapControls = parseInt(event);

    this.GapControls = event;
    this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + parseFloat(this.JobLossControls);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);
    // cal for back FLTV

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

   // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
   // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

   this.ReEvaluate();
  }
  keyupJobValue(event) {

    // this.JobLossControls = parseInt(event);
    this.JobLossControls = event;
    this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + parseFloat(this.JobLossControls);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);
    // cal for back FLTV
    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

   // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
   // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
   this.ReEvaluate();
  }

  GetRuleSetValues(RuleSetID: any) {
    
    var modelGroup = {
      "id": parseInt(RuleSetID)
    }
    this.lenderService.getCustomRulesListbyId(modelGroup)
      .subscribe(
        (data: any) => {
          
          this.UpperLimitControl=parseFloat(data[0].upperLimit).toFixed(2);
          this.LowerLimitControl=parseFloat(data[0].lowerLimit).toFixed(2);
        },
        error => {
        });


  }

  CallReevaluatefunction(any)
  {
    this.ReEvaluate();
  }


  GetLenderDetailByID() {  
    var modelGroup = {
      "id": this.lenderIdValue
    }
    this.lenderService.EditLenders(modelGroup)
      .subscribe(
        data => {
          this.AddressValue = data[0].address;
          this.EmailValue = data[0].email;
          this.PhoneNumberValue = data[0].phone;
        },
        error => {
        });


  }

  GetCustomRuleList() {
    this.lenderService.getCustomRulesList()
      .subscribe(
        (data: any) => {
          this.customRuleList = data;
        },
        error => {
        });
  }
  // FOR COST BACK END FUNTIONS

  keyupExtendendCost(event) {
    // this.ExtendendCostControls = parseInt(event);

    this.ExtendendCostControls = event;
    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) + parseFloat(this.JobLossCostControls);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }
    // cal for back FLTV
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
    
    this.ReEvaluate();
  }
  keyupGapCost(event) {
    // this.GapCostControls = parseInt(event);

    this.GapCostControls = event;
    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) + parseFloat(this.JobLossCostControls);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }
    // cal for back FLTV
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
    this.ReEvaluate();
  }
  
  keyupJobCost(event) {
    // this.JobLossCostControls = parseInt(event);

    this.JobLossCostControls = event;
    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) + parseFloat(this.JobLossCostControls);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);
    // cal for back FLTV

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }
  
  // END OF BACK END
  MaximizeDeal() {
    this.ValueSetForAdjustModelPopUp="MaximizeDeal";
    this.showAlert();
    this.openAdjustMentPopup ="True";
    this.buttonloader = true;
    if (this.PayCallDisaply == "" || this.PayCallDisaply == "undefined") {
      this.LenderPayCall = "0";

    } else
    {
      this.LenderPayCall = this.PayCallDisaply ;
    }

    if (this.InterestRateDisplay == "" || this.InterestRateDisplay == "undefined") {
      this.LenderInterestRate = "0";

    } else {
      this.LenderInterestRate = this.InterestRateDisplay
    }

    if (this.ACVValueControl == "" || this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" || this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" || this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }
    if (this.TOTALAddOnsControls == "NaN") {
      this.TOTALAddOnsControls= 0;
    }
    if(this.TOTALCostControl== "NaN")
    {
      this.TOTALCostControl =  0;
    }
    if(this.ExtendendServiceContractControls=="")
    {
      this.ExtendendServiceContractControls=0;
    }
    if(this.GapControls=="")
    {
      this.GapControls=0;
    }

    if(this.JobLossControls=="")
    {
      this.JobLossControls=0;
    }
    if(this.TOTALAccessoriesControl== "NaN")
    {
      this.TOTALAccessoriesControl=0;
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.LenderNameValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.regionIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.PlanType,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VINValueControl,
      "MaximizeDeal": "1",
      "TradeInValue":parseInt(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.DealerAdminFreeControl,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.ListPriceControl,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "frommaximize":true,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "Kilometer":this.KmsControl,
     "Year":this.Trim1Control,
     "CbbValue":this.CBBValueControl,
     "TaxRate":this.TaxRateControl
    }

    //this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  ResetDeals() {
    this.buttonReset = true;
    this.ResetValues();

    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "0",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': this.delaeridforserver,
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.regionIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LoanAmountControl),
      "DownPaymentValue": parseFloat(this.DownPaymentAmountControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "0",
      "TradeInValue":"0",
      "TotalFrontproducts": "0",
      "TotalFrontcost": "0",
      "FrontDocFee":"0",
      "TotalBackproducts": "0",
      "TotalBackcost": "0",
      "ExtWarranty":"0",
      "GAP":"0",
      "JobLoss":"0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice":  "0",
      "ScreenIntRate":  "0",
      "ScreenTermPeriod":  "0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":"0",
     "OverRideFLTV":parseFloat(this.OverRideFLTVControlLoadValue),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControlLoadValue),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "Kilometer":this.KmsControl,
     "Year":this.Trim1Control,
     "CbbValue":this.CBBValueControl,
     "TaxRate":this.TaxRateControl
    }
    // this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup)
  }

  AdjustDesiredPayment()
  {
    var modelGroup = {
      "Opcode": "0",
      "PayCallOnScreen": parseFloat(this.CurrentPayment),
      "PayCallToBe": parseFloat(this.DesiredPayment),//this.findCarForm.controls.dealerName.value,
      'Interestrate': parseFloat(this.LenderInterestRate),
      "TermPeriod": parseFloat(this.TermControl),
      "TaxRate": parseFloat(this.TaxRateControl),
      "TotalAdjustments": parseFloat(this.TotalAdjustments),// this.findCarForm.controls.TaxExemptionCheck.value,
      "AmountBeforeTax": parseFloat(this.AdjustmentstolistPriceButton),
      "AmountOfTax": parseFloat(this.AmountOfTax),
      "AmountNonTaxable": parseFloat(this.AdjustmentsGapButton),
      "AdjustLogID": this.AdjustLogID,
      "ListedPrice": parseFloat(this.AdjustmentstolistPrice),
      "FEProducts": parseFloat(this.AdjustmentsAccessories),
      "ExtWarranty": parseFloat(this.AdjustmentstoExtendedWarranty),
      "GAP":parseFloat(this.AdjustmentsGap),
      "JobLoss":parseFloat(this.AdjustmentsJobLoss),
      "VIN": this.VinNumber,
      "DealerID": "0",
      "VehicleId":"0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":""
    }
    this.GetAdjustedValues(modelGroup);
  }

  GetAdjustedValues(modelGroup:any) {
    this.manageInventoryService.getAdjustedValues(modelGroup)
      .subscribe(
        (data: any) => {
    
          // Binding Values in Adjustment
          this.TotalAdjustments =parseFloat(data[0].totalAdjustments).toFixed(2) ;
          this.AdjustmentstolistPriceButton = parseFloat(data[0].amountBeforeTax).toFixed(2) ;
          this.AdjustmentstoExtendedWarrantyButton =parseFloat(data[0].amountBeforeTax).toFixed(2) ;
          this.AdjustmentsGapButton = parseFloat(data[0].amountNonTaxable).toFixed(2); 
          this.AdjustLogID=data[0].adjustLogID;

          this.AdjustmentsAccessoriesButton=parseFloat(data[0].amountBeforeTax).toFixed(2); 
          this.AdjustmentsJobButton=parseFloat(data[0].amountNonTaxable).toFixed(2);
          
          // for Amount of Tax
          this.AmountOfTax = parseFloat(data[0].amountOfTax).toFixed(2); 
        },
        error => {
        });
  }

  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          debugger;
          this.Provincelist = data;
          
        var check = data.filter(item => item.globalCodeID == this.regionIdValue);
        this.RegionControl= check[0].globalCodeName
        this.TaxRateControl= parseFloat(check[0].totalTaxRate).toFixed(2);
         // var region = this.Provincelist.filter( x => x.globalCodeID === "192");
        },
        error => {
        });
  }
  ReEvaluate() {
    this.buttonloaderevaluate = true;
    this.PayCallValueCheck ="Evaluate";
    //this.ResetValues();
    if (this.PayCallDisaply == "" || this.PayCallDisaply == "undefined") {
      this.LenderPayCall = "0";

    } else
    {
      this.LenderPayCall = this.PayCallDisaply ;
    }

    if (this.InterestRateDisplay == "" || this.InterestRateDisplay == "undefined") {
      this.LenderInterestRate = "0";

    } else {
      this.LenderInterestRate = this.InterestRateDisplay
    }

    if (this.ACVValueControl == "" || this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" || this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" || this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }
    if (this.TOTALAddOnsControls == "NaN") {
      this.TOTALAddOnsControls= 0;
    }
    if(this.TOTALCostControl== "NaN")
    {
      this.TOTALCostControl =  0;
    }
    if(this.ExtendendServiceContractControls=="")
    {
      this.ExtendendServiceContractControls=0;
    }
    if(this.GapControls=="")
    {
      this.GapControls=0;
    }

    if(this.JobLossControls=="")
    {
      this.JobLossControls=0;
    }
    if(this.TOTALAccessoriesControl== "NaN")
    {
      this.TOTALAccessoriesControl=0;
    }

    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.LenderNameValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.regionIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.PlanType,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VINValueControl,
      "MaximizeDeal": "0",
      "TradeInValue":parseInt(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.DealerAdminFreeControl,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.ListPriceControl,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":parseFloat(this.DealerCostControl),
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "Kilometer":this.KmsControl,
     "Year":this.Trim1Control,
     "CbbValue":this.CBBValueControl,
     "TaxRate":this.TaxRateControl
    }
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  DealReport() {

    let builparameter = {
      LenderName: this.LenderNameValue,
      LenderKey: this.PlanType,
      PayCall: this.PayCallDisaply,
      InterestRate: this.InterestRateDisplay,
      AllowableFinance: this.AllowableFinanceDisplay,
      Condition: this.ConditionControl,
      Make: this.MakeControl,
      StockNumber: this.StockNumberControl,
      Model: this.ModelControl,
      VIN: this.VINControl,
      kms:this.KmsControl,
      year:this.Trim1Control,
      trim:this.TrimControl,
      AmountFinanced:this.AmountFinancedControl,
      CBBValue:this.CBBValueControl,
      ListPrice:this.ListPriceControl,
      DealerCost:this.DealerCostControl,
      TradeAllowance:this.TradeAllowanceControl,
      Lien:this.LienControl,
      ACVControl:this.ACVControl,
      EquityControl:this.EquityControl,
      DownPaymentControl:this.DownPaymentControl,
      TotalTaxControl:this.TotalTaxControl,
      RegionControl:this.RegionControl,
      TaxRateControl:this.TaxRateControl,
      MonthlyControl:this.MonthlyControl,
      interestRateControl:this.interestRateControl,
      SemiMonthlyControl:this.SemiMonthlyControl,
      WeeklyControl:this.WeeklyControl,
      BiWeeklyControl:this.BiWeeklyControl,
      ReservesControl:this.ReservesControl,
      TotalLoantoValueControl:this.TotalLoantoValueControl,
      GrossProfit:this.GrossProfitControl,
      Term:this.TermControl,
      LenderFee:this.LenderFeeControl,
      PPSA:this.PPSAControl,
      FrontDocFee:this.DealerAdminFreeControl,
      BackDocFee:this.BackDocFee,
      OverRideFLTV:this.OverRideFLTVControl,
      OverRideBLTV:this.OverRideBLTVControl,
      TOTALAccessories:this.TOTALAccessoriesControl,
      AccessoriesTOTALCost:this.TOTALCostControl,
      TOTALAddOns:this.TOTALAddOnsControls,
      TOTALBackEndCost:this.TOTALBackEndCostControls,
      ExtendendService:this.ExtendendServiceContractControls,
      ExtendendCost:this.ExtendendCostControls,
      Gap:this.GapControls,
      GapCost:this.GapCostControls,
      JobLoss:this.JobLossControls,
      JobLossCost:this.JobLossCostControls,


    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builPageValues', JSON.stringify(builparameter));

    this.openNewWindow();
  }
  openNewWindow(): void {

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = '#/build-report';
    link.click();
    link.remove();
  }

  GetAllInventoryListbyParameterForBothFindCars(modelGroup:any) {
    this.manageInventoryService.getAllInventoryListbyParameterForFreshBuild(modelGroup)
      .subscribe(
        (data: any) => {
          // controls for AdjustMent Made
          this.AdjustmentsNewListPrice = parseFloat(data[0].listedPrice).toFixed(2);
          this.AdjustmentNewFrontDocumentationFee = parseFloat(data[0].frontDocFee).toFixed(2);
          this.ConditionControl = data[0].condition;
          this.MakeControl = data[0].make;
          this.StockNumberControl = data[0].stockNumber;
          this.ModelControl = data[0].model;
          this.VINControl = data[0].vin;
          this.TrimControl = data[0].trim;
         // this.KmsControl = data[0].mileage;
          //this.Trim1Control = data[0].carYear;
          this.CBBValueControl = parseFloat(data[0].effectiveCBB).toFixed(2);
          this.ListPriceControl = parseFloat(data[0].listedPrice).toFixed(2);
          this.DealerCostControl = parseFloat(data[0].dealerCost).toFixed(2);
          this.LenderFeeControl = parseFloat(data[0].lenderFee).toFixed(2);
          this.PPSAControl = parseFloat(data[0].ppsa).toFixed(2);
          this.interestRateControl = parseFloat(data[0].intRate).toFixed(2);
          this.LienControl = parseFloat(data[0].lien).toFixed(2);
          this.DownPaymentControl = parseFloat(data[0].downPayment).toFixed(2);
          this.BackDocFee = parseFloat(data[0].backDocFee).toFixed(2);
          this.ExtendendServiceContractControls = parseFloat(data[0].extWarranty).toFixed(2);
          this.GapControls = parseFloat(data[0].gap).toFixed(2);
          this.JobLossControls = parseFloat(data[0].jobLoss).toFixed(2);
          this.MonthlyControl = parseFloat(data[0].calculatedPayCall).toFixed(2);

          // For Current Payment in Adjust Roll Payment Model Pop up
          this.CurrentPayment = parseFloat(data[0].calculatedPayCall).toFixed(2);
          this.AdjustmentstolistPrice = parseFloat(data[0].listedPrice).toFixed(2);
          this.AdjustmentstoExtendedWarranty =parseFloat(this.ExtendendServiceContractControls).toFixed(2);
          this.AdjustmentsGap =parseFloat(this.GapControls).toFixed(2);

          // END OF Roll Payment

          this.TermControl = data[0].termPeriod;
          if(this.MonthlyControl > this.LenderPayCall)
          {
            this.showAlert();
          } else
          {
            this.showAlert();
          }

          this.AllowableFinanceDisplay =parseFloat(data[0].allowableFinance).toFixed(2);
          this.AmountFinancedControl = parseFloat(data[0].amountToBeFinanced).toFixed(2);

          // for change the color of AmountFinancedControl
          if(this.AmountFinancedControl > this.AllowableFinanceDisplay)
          {
            this.colormessage ="Change";
          } else
          {
            this.colormessage ="1";
          }

          // Lower Panel

           this.TOTALAccessoriesControl= parseFloat(data[0].feProducts).toFixed(2);
          this.TOTALCostControl= parseFloat(data[0].feCost).toFixed(2);
          this.TOTALAddOnsControls= parseFloat(data[0].beProduct).toFixed(2);
          this.TOTALBackEndCostControls= parseFloat(data[0].beCost).toFixed(2);

          // for semiMonthly
          this.SemiMonthlyValues = this.MonthlyControl / 2;
          this.SemiMonthlyControl = parseFloat(this.SemiMonthlyValues).toFixed(2);

          // for Bi Weekly
          this.biMonth = this.MonthlyControl * 12;
          this.BiMonthlyValues = parseFloat(this.biMonth) / 26;
          this.BiWeeklyControl = parseFloat(this.BiMonthlyValues).toFixed(2);

          // For weekly
          this.BiWeekValues = parseFloat(this.biMonth) / 52;
          this.WeeklyControl = parseFloat(this.BiWeekValues).toFixed(2);
          this.TotalLoantoValueControl = parseFloat(data[0].tlv).toFixed(2);
          // this.ACVControl = parseFloat(this.ACVValueControl).toFixed(2);
          this.ACVControl = parseFloat(data[0].acv).toFixed(2);
          this.TradeAllowanceControl =  parseFloat(data[0].tradeInValue).toFixed(2);
         // this.TradeAllowanceControl =  parseFloat(this.ACVValueControl).toFixed(2);
          this.DealerAdminFreeControl = parseFloat(data[0].frontDocFee).toFixed(2);
          this.BackDocFee = parseFloat(data[0].backDocFee).toFixed(2);
          this.GrossProfitControl = parseFloat(data[0].projectedGross).toFixed(2);
          this.ReservesControl = parseFloat(data[0].reserves).toFixed(2);

          // ADDED NEW CODE
          this.InterestRateDisplay = parseFloat(data[0].intRate).toFixed(2);
          this.TotalTaxControl = parseFloat(data[0].totalTaxes).toFixed(2);


         this.EquityControl = parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl);
        // this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
          this.EquityControl = parseFloat(this.EquityControl).toFixed(2);

          this.RemainingBackLoanValues = parseFloat(data[0].totalBLTV).toFixed(2);

          this.RemainingBackLoanValue= parseFloat(data[0].remainingBLTV).toFixed(2);

          this.OverRideBLTVControl= parseInt(data[0].bltv);
          this.OverRideFLTVControl= parseInt(data[0].fltv);

        //  this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- (parseFloat(this.TOTALAddOnsControls) - parseFloat(this.TOTALBackEndCostControls));
        //  this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);


         // Calculations for FLTV
         this.RemainingFrontLoanValue = parseFloat(data[0].remainingFLTV).toFixed(2);
         // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

          // this.RemainingFrontLoanValueCheck = parseFloat(data[0].totalFLTV) - ((parseFloat(data[0].listedPrice) +
          //                           parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1)
          //                           + parseFloat(this.DownPaymentControl));
          // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

          this.RemainingFrontLoanValues = parseFloat(data[0].totalFLTV).toFixed(2);

          this.buttonloader = false;
          this.buttonReset = false;
          this.buttonloaderevaluate = false;

          // Total loan values must not exceeding to Amount finnace values >>>> Must give a alert mess
          if(parseFloat(this.AmountFinancedControl) > parseFloat(this.TotalLoantoValueControl))
          {
           this.showLoanAlert();
          }



          if (this.ValueSetForAdjustModelPopUp == "Load") {
            this.AdjustmentsMonthlyPayment=parseFloat(data[0].calculatedPayCall).toFixed(2);
            this.AdjustmentsGrossProfit =parseFloat(data[0].projectedGross).toFixed(2);
            this.AdjustmentsRemainingFLTV =this.RemainingFrontLoanValue ;
            this.AdjustmentsRemainingBLTV  =this.RemainingBackLoanValue;
            this.AdjustmentsTotalTaxes=parseFloat(data[0].totalTaxes).toFixed(2);


          } else if (this.ValueSetForAdjustModelPopUp == "MaximizeDeal") {
            this.AdjustmentsNewMonthlyPayment=parseFloat(data[0].calculatedPayCall).toFixed(2);
            this.AdjustmentsNewGrossProfit=parseFloat(data[0].projectedGross).toFixed(2);
            this.AdjustmentsNewRemainingFLTV=this.RemainingFrontLoanValue
            this.AdjustmentsNewRemainingBLTV=this.RemainingBackLoanValue;
            this.AdjustmentsNewTotalTaxes=parseFloat(data[0].totalTaxes).toFixed(2);
          }





          if(parseFloat(this.AdjustmentFrontDocumentationFee) != parseFloat(this.AdjustmentNewFrontDocumentationFee))
          {
            this.colorAdjustFrontFee ="Change";
          } else
          {
            this.colorAdjustFrontFee ="1";
          }

          if(parseFloat(this.AdjustmentsRemainingBLTV) != parseFloat(this.AdjustmentsNewRemainingBLTV))
          {
            this.colorAdjustBLTV ="Change";
          } else
          {
            this.colorAdjustBLTV ="1";
          }

          if(parseFloat(this.AdjustmentsRemainingFLTV) != parseFloat(this.AdjustmentsNewRemainingFLTV))
          {
            this.colorAdjustFLTV ="Change";
          } else
          {
            this.colorAdjustFLTV ="1";
          }

          // for change the color of AmountFinancedControl
          if(parseFloat(this.AdjustmentsTotalTaxes) != parseFloat(this.AdjustmentsNewTotalTaxes))
          {
            this.colorAdjustTotalTaxes ="Change";
          } else
          {
            this.colorAdjustTotalTaxes ="1";
          }


          // for change the color of AmountFinancedControl
          if(parseFloat(this.AdjustmentsNewMonthlyPayment) != parseFloat(this.AdjustmentsMonthlyPayment))
          {
            this.colorAdjustMonthly ="Change";
          } else
          {
            this.colorAdjustMonthly ="1";
          }

          if(parseFloat(this.AdjustmentsListPrice) != parseFloat(this.AdjustmentsNewListPrice))
          {
            this.colorAdjustListPrice ="Change";
          } else
          {
            this.colorAdjustListPrice ="1";
          }

          if(parseFloat(this.AdjustmentsGrossProfit) != parseFloat(this.AdjustmentsNewGrossProfit))
          {
            this.colorAdjustGrossProfit ="Change";
          } else
          {
            this.colorAdjustGrossProfit ="1";
          }

          // for showing Model pop up only in case when maximize is hit
          if (modelGroup.frommaximize == true) {
            this.closebutton.nativeElement.click();
          }
        },
        error => {
        });
  }




  ResetValues() {
    this.ConditionControl = "";
    this.VINValueControl="";
    this.GrossProfitControl = "";

    this.RemainingBackLoanValues = 0;
    this.RemainingBackLoanValue =  0;
    this.RemainingFrontLoanValue= 0;
    this.RemainingFrontLoanValueCheck=0;
    this.RemainingFrontLoanValues= 0;
    this.ReservesControl = 0;

    this.TradeAllowanceControl= 0;
    this.MakeControl = "";
    this.StockNumberControl = "";
    this.ModelControl = "";
    this.VINControl = "";
    this.TrimControl = "";
    this.KmsControl = "";
    this.Trim1Control = "";
    this.CBBValueControl = 0;
    this.ListPriceControl = 0;
    this.DealerCostControl = 0;
    this.LenderFeeControl = 0;
    this.PPSAControl = 0;
    this.interestRateControl = 0;
    this.LienControl = 0;
    this.DownPaymentControl = 0;
    this.TOTALCostControl = 0;
    this.TermControl = "";

    this.MonthlyControl = 0;
    this.AmountFinancedControl = 0;
    this.EquityControl = 0;
    this.SemiMonthlyValues = 0;
    this.SemiMonthlyControl = 0;
    this.biMonth = 0;
    this.BiMonthlyValues = 0;
    this.BiWeeklyControl = 0;
    this.TotalLoantoValueControl = 0;
    this.ACVControl = 0;
    this.DealerAdminFreeControl = 0;

    this.TOTALAccessoriesControl= 0;
    this.TOTALCostControl= 0;

    this.Accessories1Control= 0;
    this.Cost1Control= 0;
    this.Accessories2Control= 0;
    this.Cost2Control= 0;
    this.Accessories3Control= 0;
    this.Cost3Control= 0;
    this.TOTALAddOnsControls= 0;
    this.TOTALBackEndCostControls= 0;
    this.ExtendendServiceContractControls= 0;
    this.ExtendendCostControls= 0;
    this.GapControls= 0;
    this.GapCostControls= 0;
    this.JobLossControls= 0;
    this.JobLossCostControls= 0;
  }

  // click button hide show
  toggleDisplayDiv() {  
    this.isShowDiv = !this.isShowDiv;  
  }  

  // click button hide show
  togglelenderinfo() 
  {  
    this.hideShowTopButtons ="true";
    this.islenderinfo = !this.islenderinfo;  
  }  


  // Verify Units
  VerifyVehicle(e, type, item: any) {
    if (e.target.checked) {
      var getuserid = localStorage.getItem('userid');
      var checkedverifydata = {
        vin: this.selectedVin,
        type: type,
        loggeduserid: getuserid,
        notes: this.typeofaction,
        hello:"testing",
        ipAddress:localStorage.getItem('IpAddresstoTrack')

      }

      this.verifycheckboxes.push(checkedverifydata);

    }
    else {
      let updateItem = this.verifycheckboxes.find(this.findIndexToUpdate, type);

      let index = this.verifycheckboxes.indexOf(updateItem);

      this.verifycheckboxes.splice(index, 1);

    }
  }
  findIndexToUpdate(type) {
    return type === this;
  }

  Pdfview()
  {
    var formData = {
      "Id": this.lenderIdValue

    }
    this.lenderService.Getpdfviw(formData)
    .subscribe(
      (data: any) => {
        var byteCharacters = atob(data.filebase);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      error => {
      });
  }
  decline(): void {
    this.modalRef.hide();
  }

  DataForBuyClick() {
    var getuserid = localStorage.getItem('userid');
    var checkedverifydata = {
      vin: this.selectedVin,
      type: "Buy",
      loggeduserid: getuserid,
      notes: this.typeofaction,
      ipAddress:localStorage.getItem('IpAddresstoTrack')

    }

    this.verifycheckboxes.push(checkedverifydata);
  }

  Aithrenquiry(template: TemplateRef<any>, typeofaction: any) {
    this.verifycheckboxes = [];
    this.selectedVin = this.VINControl;
    this.typeofaction = typeofaction;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  

  PostVerifyvehicleRequest() {
    this.buttonloaderSendEmail=true;
    this.buttonloaderBuyEmail =true;
    if (this.typeofaction == "Buy") {
      this.DataForBuyClick();
    }

    this.spinner.show();
    var modelGroup = {
      "requestvehicle": this.verifycheckboxes,

    }

    this.manageInventoryService.PostVerifyVehicledata(modelGroup)
      .subscribe(
        (data: any) => {
          this.showNotification("success", "Request saved and communicated via email.");
         
         this.buttonloaderSendEmail=false;
         this.buttonloaderBuyEmail =false;
         this.decline();
          this.spinner.hide();
         this.SendAithrbuyAndVerifyEmailList();
          
        },
        error => {
        });

  }

  SendAithrbuyAndVerifyEmailList() {
    var modelGroup = {
      "UserId": this.useridValue.toString(),
      "VIN": this.VINControl.toString()
    }
    this.manageInventoryService.sendAithrbuyAndVerifyEmail(modelGroup)
      .subscribe(
        data => {
        },
        error => {
        });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  hidetopTopButton()
  {
    this.hidetopBarButton ="true";
    this.divTopButtonsshow="false";
  }
  showtopTopButton()
  {
    this.hidetopBarButton ="false";
    this.divTopButtonsshow="true";
  }
  HideCostValue()
  {
    this.hideCostButton="false";
    this.hideGrossProfit= "false"; 
    this.hideDealerCost= "false";

    this.TOTALCosthide= "false";
    this.Cost1Hide= "false";
    this.Cost2Hide= "false";
    this.Cost3Hide= "false";

    this.BackendCostHide= "false";
    this.ExtendendCostHide= "false";
    this.GapCostHide= "false";
    this.JobLossHide= "false";
    
  }
  ShowCostValue()
  {
    this.hideCostButton="true";

    this.hideGrossProfit= "true";
    this.hideDealerCost= "true";

    this.TOTALCosthide= "true";
    this.Cost1Hide= "true";
    this.Cost2Hide= "true";
    this.Cost3Hide= "true";

    this.BackendCostHide= "true";
    this.ExtendendCostHide= "true";
    this.GapCostHide= "true";
    this.JobLossHide= "true";
  }

  Createform() {
    this.buildCarForm = new FormGroup({
      ConditionControl: this.fb.control(''),
      VINValueControl: this.fb.control(''),
      GrossProfitControl: this.fb.control(''),
      MakeControl: this.fb.control(''),
      AmountFinancedControl: this.fb.control(''),
      CBBValueControl: this.fb.control(''),
      MonthlyControl: this.fb.control(''),
      interestRateControl: this.fb.control(''),
      StockNumberControl: this.fb.control(''),
      ModelControl: this.fb.control(''),
      ListPriceControl: this.fb.control(''),
      DealerCostControl: this.fb.control(''),
      SemiMonthlyControl: this.fb.control(''),
      WeeklyControl: this.fb.control(''),
      VINControl: this.fb.control(''),
      TrimControl: this.fb.control(''),
      TradeAllowanceControl: this.fb.control(''),
      LienControl: this.fb.control(''),
      BiWeeklyControl: this.fb.control(''),
      ReservesControl: this.fb.control(''),
      KmsControl: this.fb.control(''),
      Trim1Control: this.fb.control(''),
      ACVControl: this.fb.control(''),
      EquityControl: this.fb.control(''),
      TotalLoantoValueControl: this.fb.control(''),
      Reserves1Control: this.fb.control(''),
      DetailsControl: this.fb.control(''),
      DownPaymentControl: this.fb.control(''),
      TotalTaxControl: this.fb.control(''),
      TermControl: this.fb.control(''),
      LenderFeeControl: this.fb.control(''),
      RegionControl: this.fb.control(''),
      TaxRateControl: this.fb.control(''),
      PPSAControl: this.fb.control(''),
      GPSFeeControlValue: this.fb.control(''),

      TOTALAccessoriesControl: this.fb.control(''),
      TOTALCostControl: this.fb.control(''),
      DealerAdminFreeControl: this.fb.control(''),
      Accessories1Control: this.fb.control(''),
      Accessories2Control: this.fb.control(''),
      Accessories3Control: this.fb.control(''),
      Cost1Control: this.fb.control(''),
      Cost2Control: this.fb.control(''),
      Cost3Control: this.fb.control(''),
      TOTALAddOnsControls: this.fb.control(''),
      TOTALBackEndCostControls: this.fb.control(''),
      ExtendendServiceContractControls: this.fb.control(''),
      ExtendendCostControls: this.fb.control(''),
      GapControls: this.fb.control(''),
      GapCostControls: this.fb.control(''),
      JobLossControls: this.fb.control(''),
      JobLossCostControls: this.fb.control(''),
      BackDocFeeControl: this.fb.control(''),
      // Controls for Adjust model pop up
      CurrentPayment: this.fb.control(''),
      DesiredPayment: this.fb.control(''),
      TotalAdjustments: this.fb.control(''),
      AdjustmentstolistPrice: this.fb.control(''),

      AdjustmentstoExtendedWarranty: this.fb.control(''),
      AdjustmentsGap: this.fb.control(''),
      AdjustmentsJobLoss: this.fb.control(''),
      AdjustmentsAccessories: this.fb.control(''),
      // Adjustment Payment
      AdjustmentsListPrice: this.fb.control(''),
      AdjustmentsNewListPrice: this.fb.control(''),
      AdjustmentFrontDocumentationFee: this.fb.control(''),
      AdjustmentNewFrontDocumentationFee: this.fb.control(''),
      AdjustmentsMonthlyPayment: this.fb.control(''),
      AdjustmentsNewMonthlyPayment: this.fb.control(''),
      AdjustmentsGrossProfit: this.fb.control(''),
      AdjustmentsNewGrossProfit: this.fb.control(''),
      AdjustmentsRemainingFLTV: this.fb.control(''),
      AdjustmentsNewRemainingFLTV: this.fb.control(''),
      AdjustmentsRemainingBLTV: this.fb.control(''),
      AdjustmentsNewRemainingBLTV: this.fb.control(''),
      AdjustmentsTotalTaxes: this.fb.control(''),
      AdjustmentsNewTotalTaxes: this.fb.control(''),


      RuleSetControl: this.fb.control(''),
      UpperLimitControl: this.fb.control(''),
      LowerLimitControl: this.fb.control(''),
      LenderNameValue: this.fb.control(''),
      PlanType: this.fb.control(''),
      PayCallDisaply: this.fb.control(''),
      InterestRateDisplay: this.fb.control(''),
      AllowableFinanceDisplay: this.fb.control(''),

      OverRideBLTVControl: this.fb.control(''),
      OverRideFLTVControl: this.fb.control(''),

      // Cal Loan
      CalTotalLoanamount: this.fb.control(''),
      CalDesiredMonthlyPayment: this.fb.control(''),
      CalInterestRate: this.fb.control(''),
      CalLoanTerm: this.fb.control(''),
    });
  }
}
