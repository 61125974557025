<section id="admindashboard">
    <form class="col-12 px-0 findcardblock" [formGroup]="flagReportForm">
        <div class="container-fluid">
            <div class="row mt-2" id="FlagReport">
                <div class="col-md-12">
                    <div class="card card-raised h-100">
                        <div class="card-header card-raised bg-info text-white">
                            <h6 class="card-title text-white text-uppercase mb-0">Flag Report Dashboard</h6>
                        </div>
                        <div class="card-body">
                          <div class="accordion" id="accordionExample">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row justify-content-start align-items-center">
                                                <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto">Vehicle Detail</h6>
                                                <div class="form-inline col vehicle-info">
                                                    <div class="form-group mx-sm-2 mx-md-3">
                                                        <label><b>VIN:</b></label>
                                                        <span>{{VIN}}</span>
                                                    </div>
                                                    <div class="form-group mx-sm-2 mx-md-3">
                                                        <label><b>Year:</b></label>
                                                        <span>{{VehicleYear}}</span>
                                                    </div>
                                                    <div class="form-group mx-sm-2 mx-md-3">
                                                        <label><b>Make:</b></label>
                                                        <span>{{Make}}</span>
                                                    </div>
                                                    <div class="form-group mx-sm-2 mx-md-3">
                                                        <label><b>Model:</b></label>
                                                        <span>{{Model}}</span>
                                                    </div>
                                                </div>
                                                <div class="ml-auto col-auto">
                                                   <button class="btn btn-white plusbtn" type="button" data-toggle="collapse" data-target="#VehicleDetail" aria-expanded="true" aria-controls="VehicleDetail">
                                                        <i class="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="VehicleDetail" class="collapse show" aria-labelledby="VehicleDetail" data-parent="#accordionExample">
                                            <div class="card-body flagreport-block">
                                                <div class="form-row">
                                                    <div class="col-lg-4 col-md-6">
                                                        <div class="form-group">
                                                            <label>VIN:</label>
                                                            <span>{{VIN}}</span>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Colour:</label>
                                                            <span>{{colour}}</span>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Options:</label>
                                                            <span>{{options}}</span>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Year:</label>
                                                            <span>{{VehicleYear}}</span>
                                                        </div>
                                                        <div class="form-group">
                                                            <label>Make:</label>
                                                            <span>{{Make}}</span>
                                                        </div>
                                                    </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label>Model:</label>
                                                        <span>{{Model}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Trim:</label>
                                                        <span>{{Trim}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Drivetrain:</label>
                                                        <span>{{Drivetrain}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Body:</label>
                                                        <span>{{body}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Transmission:</label>
                                                        <span>{{Transmission}}</span>
                                                    </div>
                                                </div>
                                                <div class="col-lg-4 col-md-6">
                                                    <div class="form-group">
                                                        <label>Style:</label>
                                                        <span>{{Style}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Mileage:</label>
                                                        <span>{{mileage}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Dealer Name:</label>
                                                        <span>{{DealerName}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Dealer Email:</label>
                                                        <span>{{DealerEmail}}</span>
                                                    </div>
                                                    <div class="form-group">
                                                        <label>Phone Number:</label>
                                                        <span>{{DealerNumber}}</span>
                                                    </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <div class="row justify-content-start align-items-center">
                                                <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto">Other Detail</h6>
                                                <div class="ml-auto col-auto">
                                                   <button class="btn btn-white plusbtn" type="button" data-toggle="collapse" data-target="#OtherDetail" aria-expanded="true" aria-controls="OtherDetail">
                                                        <i class="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="OtherDetail" class="collapse show" aria-labelledby="OtherDetail" data-parent="#accordionExample">
                                            <div class="card-body flagreport-block">
                                                <div class="card">
                                                    <div class="card-header">
                                                    <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Severe Problem</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>First Date:</label>
                                                                <span  *ngIf="FirstSevereProblemDate!='N/A'">{{FirstSevereProblemDate | date:'medium'}}</span>
                                                                <span  *ngIf="FirstSevereProblemDate=='N/A'">N/A</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Latest Date:</label>

                                                                <span  *ngIf="LatestSevereProblemDate!='N/A'">{{LatestSevereProblemDate | date:'medium'}}</span>
                                                                <span  *ngIf="LatestSevereProblemDate=='N/A'">N/A</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                    <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">TitleBrand</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Title Brand:</label>
                                                                <span>{{TitleBrand}}</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Title Brand Date:</label>


                                                                <span  *ngIf="FirstTitleBrandDate!='N/A'">{{FirstTitleBrandDate | date:'medium'}}</span>
                                                                <span  *ngIf="FirstTitleBrandDate=='N/A'">N/A</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Province:</label>
                                                                <span>{{FirstTitleBrandProvince}}</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Latest Date:</label>


                                                                <span  *ngIf="LatestTitleBrandDate!='N/A'">{{LatestTitleBrandDate | date:'medium'}}</span>
                                                                <span  *ngIf="LatestTitleBrandDate=='N/A'">N/A</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Latest Province:</label>
                                                                <span>{{LatestTitleBrandProvince}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                <div class="card-header">
                                                    <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Police Reported Accident</h6>
                                                </div>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Has Accident:</label>
                                                            <span>{{HasPoliceReportedAccident}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Accident Date:</label>


                                                            <span  *ngIf="FirstPoliceReportedAccidentDate!='N/A'">{{FirstPoliceReportedAccidentDate | date:'medium'}}</span>
                                                            <span  *ngIf="FirstPoliceReportedAccidentDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Accident Date:</label>



                                                            <span  *ngIf="LatestPoliceReportedAccidentDate!='N/A'">{{LatestPoliceReportedAccidentDate | date:'medium'}}</span>
                                                            <span  *ngIf="LatestPoliceReportedAccidentDate=='N/A'">N/A</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Frame Damage</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Has Damage:</label>
                                                            <span>{{HasFrameDamage}}</span>
                                                        </div>

                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Date:</label>


                                                            <span  *ngIf="FirstFrameDamageDate!='N/A'">{{FirstFrameDamageDate | date:'medium'}}</span>
                                                            <span  *ngIf="FirstFrameDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Date:</label>


                                                            <span  *ngIf="LatestFrameDamageDate!='N/A'">{{LatestFrameDamageDate | date:'medium'}}</span>
                                                            <span  *ngIf="LatestFrameDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Hail Damage</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Hail Damage:</label>
                                                                <span>{{HailDamage}}</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>First Date:</label>

                                                                <span  *ngIf="FirstHailDamageDate!='N/A'">{{FirstHailDamageDate | date:'medium'}}</span>
                                                                <span  *ngIf="FirstHailDamageDate=='N/A'">N/A</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>First Province:</label>
                                                                <span>{{FirstHailDamageProvince}}</span>

                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Latest Date:</label>


                                                                <span  *ngIf="LatestHailDamageDate!='N/A'">{{LatestHailDamageDate | date:'medium'}}</span>
                                                                <span  *ngIf="LatestHailDamageDate=='N/A'">N/A</span>
                                                            </div>
                                                            <div class="form-group col-lg-4 col-md-6">
                                                                <label>Latest Province:</label>
                                                                <span>{{LatestHailDamageProvince}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Fire Damage</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Fire Damage:</label>
                                                            <span>{{FireDamage}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Date:</label>


                                                                <span  *ngIf="FirstFireDamageDate!='N/A'">{{FirstFireDamageDate | date:'medium'}}</span>
                                                                <span  *ngIf="FirstFireDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Province:</label>
                                                            <span>{{FirstFireDamageProvince}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Date:</label>



                                                            <span  *ngIf="LatestFireDamageDate!='N/A'">{{LatestFireDamageDate | date:'medium'}}</span>
                                                            <span  *ngIf="LatestFireDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Province:</label>
                                                            <span>{{LatestFireDamageProvince}}</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Flood Damage</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Flood Damage:</label>
                                                            <span>{{FloodDamage}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Date:</label>



                                                            <span  *ngIf="FirstFloodDamageDate!='N/A'">{{FirstFloodDamageDate | date:'medium'}}</span>
                                                            <span  *ngIf="FirstFloodDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>First Province:</label>
                                                            <span>{{FirstFloodDamageProvince}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Date:</label>


                                                            <span  *ngIf="LatestFloodDamageDate!='N/A'">{{LatestFloodDamageDate | date:'medium'}}</span>
                                                            <span  *ngIf="LatestFloodDamageDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Province:</label>
                                                            <span>{{LatestFloodDamageProvince}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Latest Stolen Date:</label>


                                                            <span  *ngIf="LatestStolenDate!='N/A'">{{LatestStolenDate | date:'medium'}}</span>
                                                            <span  *ngIf="LatestStolenDate=='N/A'">N/A</span>
                                                        </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card">
                                                    <div class="card-header">
                                                        <h6 class="card-title text-uppercase mb-0 d-inline-block col-auto px-0">Damage Over Accumaltive Threshold</h6>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="form-row">
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Over Accumulative Threshold:</label>
                                                            <span>{{DamageOverAccumulativeThreshold}}</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Threshold Date:</label>



                                                            <span  *ngIf="DamageOverAccumulativeThresholdDate!='N/A'">{{DamageOverAccumulativeThresholdDate | date:'medium'}}</span>
                                                            <span  *ngIf="DamageOverAccumulativeThresholdDate=='N/A'">N/A</span>
                                                        </div>
                                                        <div class="form-group col-lg-4 col-md-6">
                                                            <label>Threshold:</label>
                                                            <span>{{DamageAccumulativeThreshold | currency}}</span>
                                                        </div>

                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>