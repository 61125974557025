import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({ providedIn: 'root' })

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
    private router: Router,
    private http: HttpClient
  ) {

    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();

    this.loginSubject = new BehaviorSubject<Login>(null);
    this.logins = this.loginSubject.asObservable();

  }

  
  
  
  private baseUrl_GetDealersFilterList: string = environment.apiUrl + '/Dashboard/GetDealersFilterList';
  private baseUrl_GetUserFilterList: string = environment.apiUrl + '/Dashboard/GetUserFilterList';
  private baseUrl_GetFilterValueList: string = environment.apiUrl + '/Dashboard/GetFilterValueList';
  private baseUrl_GetTrackingListList: string = environment.apiUrl + '/Dashboard/GetTrackingList';
  private baseUrl_ActivateDealers: string = environment.apiUrl + '/GlobalCodeCategory/ActivateDealers';
  private baseUrl_UpdateGlobalCodeCategory: string = environment.apiUrl + '/GlobalCodeCategory/UpdateGlobalCodeCategorys';
  private baseUrl_EditGlobalCodeCategory: string = environment.apiUrl + '/GlobalCodeCategory/GetGlobalCodeCategoryById';
  private baseUrl_GetDashboardStatisticsDetails: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsDetails';
  private baseUrl_GetDashboardStatisticsRegionDetails: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsRegionWiseDetails';
  private baseUrl_GetDashboardStatisticsDealerDetails: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsDealerWiseDetails';

  private baseUrl_GlobalCodeCategory: string = environment.apiUrl + '/GlobalCodeCategory/DeleteGlobalCodeCategorys';
  private baseUrl_GetListDealersForActivationList: string = environment.apiUrl + '/Dashboard/GetListDealersForActivationList';
  private baseUrl_GetGlobalCodeCategorylist: string = environment.apiUrl + '/GlobalCodeCategory/GetAllGlobalCodeCategoryList';
  private baseUrl_SaveGlobalCodeCategory: string = environment.apiUrl + '/GlobalCodeCategory/AddGlobalCodeCategorys';



  private baseUrl_GetDashboardStatisticsDealerDetailsbyDealerName: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsDealerDetailsbyDealerName';
  private baseUrl_GetDashboardStatisticsRegionDetailsByDealerName: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsRegionDetailsByDealerName';
  private baseUrl_GetDashboardStatisticsDetailsByDealerName: string = environment.apiUrl + '/Dashboard/GetDashboardStatisticsDetailsByDealerName';
  private baseUrl_GetAithrHubDashboardStatisticsDetails: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsDetails';
  private baseUrl_GetAithrHubDashboardStatisticsRegionDetails: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsRegionWiseDetails';
  private baseUrl_GetAithrHubDashboardStatisticsDealerDetails: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsDealerWiseDetails';
  // functions for Aithrhub dashborad

  private baseUrl_GetAllGlobalCodeCategoryListby_category: string = environment.apiUrl + '/GlobalCodeCategory/GetAllGlobalCodeCategoryListby_category';

  private baseUrl_GetAithrHubDashboardStatisticsDetailsByDealerName: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsDetailsByDealerName';
  private baseUrl_GetAithrHubDashboardStatisticsRegionDetailsByDealerName: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsRegionDetailsByDealerName';
  private baseUrl_GetAithrHubDashboardStatisticsDealerDetailsByDealerName: string = environment.apiUrl + '/Dashboard/GetAithrHubDashboardStatisticsDealerDetailsByDealerName';

  private baseUrl_GetCarfaxDashboardVechileSyncDetails: string = environment.apiUrl + '/Dashboard/GetCarfaxDashboardVechileSyncDetails';

  private baseUrl_GetCarfaxDashboardVechilenotSyncList: string = environment.apiUrl + '/Dashboard/GetCarfaxDashboardVechilenotSyncList';

  private baseUrl_GetZAPIgatewayLogDetail: string = environment.apiUrl + '/CreditAppGateway/GetZAPIgatewayLogDetail';

  private baseUrl_GetUnitDetailsByDealerIds: string = environment.apiUrl + '/Dashboard/GetUnitDetailsByDealerId';

  private baseUrl_GenerateToken: string = environment.apiUrl + '/Admin/GenerateTokenFromdeler';

  getUnitDetailsByDealerIds(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetUnitDetailsByDealerIds, formdata);
  }


  getZAPIgatewayLogDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetZAPIgatewayLogDetail, formdata);
  }

  getCarfaxDashboardVechilenotSyncList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetCarfaxDashboardVechilenotSyncList);
  }


  getCarfaxDashboardVechileSyncDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetCarfaxDashboardVechileSyncDetails);
  }


  getAithrHubDashboardStatisticsDetailsByDealerName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAithrHubDashboardStatisticsDetailsByDealerName, formdata);
  }

  getAithrHubDashboardStatisticsRegionDetailsByDealerName(formdata) {

    return this.http.post<any[]>(this.baseUrl_GetAithrHubDashboardStatisticsRegionDetailsByDealerName, formdata);
  }
  getAithrHubDashboardStatisticsDealerDetailsByDealerName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAithrHubDashboardStatisticsDealerDetailsByDealerName, formdata);
  }


  getAithrHubDashboardStatisticsDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAithrHubDashboardStatisticsDetails);
  }

  getAithrHubDashboardStatisticsRegionDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAithrHubDashboardStatisticsRegionDetails);
  }

  getAithrHubDashboardStatisticsDealerDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAithrHubDashboardStatisticsDealerDetails);
  }



  // Function by Dealer names



  getDashboardStatisticsDealerDetailsbyDealerName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDashboardStatisticsDealerDetailsbyDealerName, formdata);
  }

  getDashboardStatisticsDetailsByDealerName(formdata) {

    return this.http.post<any[]>(this.baseUrl_GetDashboardStatisticsDetailsByDealerName, formdata);
  }
  getDashboardStatisticsRegionDetailsByDealerName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDashboardStatisticsRegionDetailsByDealerName, formdata);
  }



  //calling function getting Dashboard Statistics
  getDashboardStatisticsDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetDashboardStatisticsDetails);
  }

  getDashboardStatisticsRegionDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetDashboardStatisticsRegionDetails);
  }

  getDashboardStatisticsDealerDetails() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetDashboardStatisticsDealerDetails);
  }

  getListDealersForActivationList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetListDealersForActivationList);
  }

  getGlobalCodeCategorylist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetGlobalCodeCategorylist);
  }

  getGlobalCodeCategorylistby_categoryid(FormData) {
    return this.http.post<any>(this.baseUrl_GetAllGlobalCodeCategoryListby_category,FormData);
  }

  SaveGlobalCodeCategory(formdata) {
    return this.http.post<any[]>(this.baseUrl_SaveGlobalCodeCategory, formdata);
  }

  //this is for calling edit functionality
  EditGlobalCodeCategory(FormData) {
    return this.http.post<any>(this.baseUrl_EditGlobalCodeCategory, FormData);

  }
  UpdateGlobalCodeCategory(FormData) {
    return this.http.post<any>(this.baseUrl_UpdateGlobalCodeCategory, FormData);

  }

  DeleteGlobalCodeCategory(FormData) {
    return this.http.post<any>(this.baseUrl_GlobalCodeCategory, FormData);
  }

  ActivateDealers(formdata) {
    return this.http.post<any[]>(this.baseUrl_ActivateDealers, formdata);
  }


  GenerateToken(formdata) {
    return this.http.post<any[]>(this.baseUrl_GenerateToken, formdata);
  }



  getTrackingList(FormData) {
    debugger;
    return this.http.post<CDATASection[]>(this.baseUrl_GetTrackingListList,FormData);
  }

  // function for Report View 

  getDealersFilterList(FormData) {
    debugger;
    return this.http.post<CDATASection[]>(this.baseUrl_GetDealersFilterList,FormData);
  }

  getUserFilterList(FormData) {
    debugger;
    return this.http.post<CDATASection[]>(this.baseUrl_GetUserFilterList,FormData);
  }

  getFilterValueList(FormData) {
    debugger;
    return this.http.post<CDATASection[]>(this.baseUrl_GetFilterValueList,FormData);
  }



 getIPAddress()
  {
    return this.http.get("http://api.ipify.org/?format=json");
  }

}
