<!-- header start -->
<div id="admindashboard" style="padding:0 !important;height: 100vh;">
<header id="header" class="navbar navbar-full">
    <div class="container-md">
        <div class="col-12 topbar">
            <div class="row align-items-center justify-content-between">
                <div class="col-auto px-0 px-sm-2">
                    <div class="d-inline-block d-lg-none pr-1">
                        <button style="position: relative;top: 2px;" class="navbar-toggler m-0" type="button"
                            data-toggle="collapse" data-target="#mainNavbarCollapse">
                            &#9776;
                        </button>
                    </div>
                    <a class="logocon" href="#/">
                        <img class="main-logo" src="../../assets/images/loginImage.png" alt="website logo">
                        <img class="small-logo" style="display:none;" src="../../assets/images/BrandImage.png">
                    </a>
                </div>

             <div class="navbar-toggleable-md" id="mainNavbarCollapse">
                <ul class="list-unstyled nav navbar-nav">
                  <li class="nav-item">
                    <a href="#/">Home</a>
                  </li>
                  <li class="nav-item">
                    <a class="" href="#/about-us" >About Us</a>
                  </li>
                  <li class="nav-item">
                    <a href="#/contact-us">Contact Us</a>
                  </li>
                   <!-- <li class="nav-item dropdown">
                      <a class="nav-link dropdown-toggle" type="button" id="MoreOptions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        More Options
                      </a>
                      <div class="dropdown-menu" aria-labelledby="MoreOptions">
                        <a class="dropdown-item" routerLink="/shipping-returns">Shipping return</a>
                        <a class="dropdown-item" routerLink="/tearm-condition">Tearm & Condition</a>
                        <a class="dropdown-item" routerLink="/privacy-policy">Privacy Policy</a>
                        <a class="dropdown-item" routerLink="/faqs-page">Help & FAQ</a>
                      </div>
                  </li> -->
                </ul>
              </div>


                <div class="col-auto">
                    <div class="userlogin">
                        <a href="#/login" class="btn btn-danger btn-md mr-2">Sign In</a>
                        <!-- <a href="/#/Register/0" class="btn btn-transparent btn-md">SignUp</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>

<!-- header end -->


<!-- Banner Section -->
<section class="banner">
    <div class="banner-content">
        <div class="container-md">
            <div class="row">
                <div class="col-xl-7 col-lg-7 col-md-12 col-12">
                    <h1>Find Your Dream Ride Now</h1>
                    <h5 class="car-dealer">Over 15000 Cars Available at Your Fingertips</h5>
                    <p>Car Dealer is a dynamic, innovative, and versatile platform designed for 
                        automotive businesses. Perfect for car dealerships, corporate sites, and any 
                        business in the auto industry, offering modern features and a user-friendly experience.</p>
                    <a href="#" class="btn btn-danger btn-sm btn-custom find-car">Find Your Car</a>
                </div>
                <div class="col-xl-5 col-lg-5 col-md-12 col-12">
                    <div class="car-banner">
                        <img class="img-fluid" src="../../assets/images/car-image.png" alt=""/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="dream-car">
    <div class="container-md">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                <div class="dream-car-leftbar">
                    <img class="img-fluid" src="../../assets/images/dream-img.png" alt="dream car"/>
                    <div class="best-award">
                        <i class="fas fa-award"></i>
                        <h6>Best Dealer Award</h6>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 pl-lg-2">
                <h2>One of the Greatest <span class="w-100 d-block"> Pleasures You Can Have</span></h2>
                <h5>Setting the Benchmark in Used Car Retail for 25 Years</h5>
                <p>For a quarter of a century, we've been redefining the used car retail experience 
                    with one of the most innovative and dependable vehicle programs in the industry. Our extensive array of standard benefits has evolved, 
                    ensuring that every driver drives off with complete confidence and peace of mind.
                </p>
                <ul class="list-unstyled">
                    <li> <i class="far fa-check-circle"></i> Your Trusted Partner Every Step of the Way</li>
                    <li><i class="far fa-check-circle"></i> Quality Vehicles at Competitive Prices</li>
                    <li><i class="far fa-check-circle"></i> Flexible Financing Options Available for All Credit Situations</li>
                    <li><i class="far fa-check-circle"></i> Sell Your Car Effortlessly—Skip the Stress!</li>
                </ul>
                <a href="#" class="btn btn-sm btnformate btn-dark mt-2">Read More</a>
            </div>
        </div>
    </div>
</section>



<section class="custom-block">
    <div class="container-md">
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-12 mt-4">
                <h2>Why People Choose Us</h2>
                <p>Dealer Beusiness System (DBS) is a comprehensive software solution designed for automotive dealerships to enhance operational efficiency.</p>
                <ul class="list-unstyled">
                    <li> <i class="far fa-check-circle"></i>Comprehensive Solution</li>
                    <li> <i class="far fa-check-circle"></i>Enhanced Efficiency</li>
                    <li> <i class="far fa-check-circle"></i>User-Friendly Interface</li>
                    <li> <i class="far fa-check-circle"></i>Real-Time Data Insights</li>
                    <li> <i class="far fa-check-circle"></i>Scalability</li>
                    <li> <i class="far fa-check-circle"></i>Dedicated Support</li>
                    <li> <i class="far fa-check-circle"></i>Seamless Integrations</li>
                    <li> <i class="far fa-check-circle"></i>Commitment to Innovation</li>
                </ul>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
                <img class="img-fluid" src="../../assets/images/car-customer.jpg" alt="customer" />
            </div>
        </div>
    </div>
</section>


<!-- Image Showcases -->
<section class="showcase">
    <div class="container-md">
      <div class="row">
        <div class="col-lg-12">
          <h2>Our Featured Cars</h2>
          <p class="lead mb-0">
            Car Dealer is your all-in-one, cutting-edge solution for auto businesses –
             where sleek design meets powerful functionality. Perfect for transforming any 
             dealership, business, or corporate site into a modern automotive hub.</p>
        </div>
        <div class="col-12 mt-5">
            <div id="carouselExampleIndicators2" class="carousel slide" data-ride="carousel">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_01.png">
                                    <div class="card-body">
                                        <h4 class="card-title">Automated Car Panel Tracking</h4>
                                        <p class="card-text">Enhance your inventory management with automated tracking of car panels, ensuring accuracy and accessibility.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_02.png">
                                    <div class="card-body">
                                        <h4 class="card-title">Comprehensive Car Panel Management</h4>
                                        <p class="card-text">Streamline your dealership operations with intuitive tools for managing car panels efficiently.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_03.png">
                                    <div class="card-body">
                                        <h4 class="card-title">Seamless Integration of Car Panels</h4>
                                        <p class="card-text">Integrate car panel data seamlessly within your DBS for better decision-making and streamlined operations.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_01.png">
                                    <div class="card-body">
                                        <h4 class="card-title">User-Friendly Car Panel</h4>
                                        <p class="card-text">Access a user-friendly dashboard to manage and monitor all cars.</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_02.png">
                                    <div class="card-body">
                                        <h4 class="card-title">Seamless Integration of Car Panels</h4>
                                        <p class="card-text">Integrate car panel data seamlessly within your DBS for better decision-making and streamlined operations.</p>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 mb-3">
                                <div class="card">
                                    <img class="img-fluid" alt="car gallery" src="../../assets/images/car-gallery/car-item_03.png">
                                    <div class="card-body">
                                        <h4 class="card-title">Automated Car Panel Tracking</h4>
                                        <p class="card-text">Enhance your inventory management with automated tracking of car panels, ensuring accuracy and accessibility.</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      
    </div>
  </section>





<!-- Footer  start-->
<footer class="text-white pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">About</h6>
          <img style="width:135px;" class="mb-2" src="../../assets/images/BrandImage.png" alt="website logo" />
          <p class="">
             Dealer Beusiness System (DBS) is a comprehensive software solution designed for 
            automotive dealerships to enhance operational efficiency. 
          </p>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Recent Posts</h6>
          <p><a href="#" class="text-white">Buy your dream car</a></p>
          <p><a href="#" class="text-white">Does Your Life Lack Meaning</a></p>
          <p><a href="#" class="text-white">The A Z Of Motivation</a></p>
          <!-- <p><a href="#" class="text-white">Product 4</a></p> -->
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Useful links</h6>
          <p><a href="#" class="text-white">Your Account</a></p>
          <p><a href="#" class="text-white">Become an Affiliate</a></p>
          <p><a href="#" class="text-white">Shipping Rates</a></p>
          <p><a href="#" class="text-white">Help</a></p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mb-md-0 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Contact</h6>
          <p><i class="fas fa-home mr-3"></i> Calgary, Canada</p>
          <p><i class="fas fa-envelope mr-3"></i>chrisavery0594@gmail.com</p>
          <p><i class="fas fa-phone mr-3"></i> (403) 404-6773</p>
        </div>
      </div>
    </div>
    <hr style="border-color: #2b2b2b;"/>
    <div class="footer-copyright text-center py-3">
      © 2024 Copyright:
      <a href="#" class="text-white"> SPADEZ SOLUTIONS PRIVATE LIMITED</a>
    </div>
  </footer>
  <!-- footer end -->

</div>