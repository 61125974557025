import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';
import { JDPListOfPhotos } from '@app/_models/JDPListOfPhotos';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({
  providedIn: 'root'
})
export class LenderService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
    private router: Router,
    private http: HttpClient
  ) {

    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();

    this.loginSubject = new BehaviorSubject<Login>(null);
    this.logins = this.loginSubject.asObservable();

  }
  private baseUrl_GetCustomRulesListbyId: string = environment.apiUrl + '/Dashboard/GetCustomRulesListbyId';
  private baseUrl_GetCustomRulesList: string = environment.apiUrl + '/Dashboard/GetCustomRulesList';
  private baseUrl_DeletelenderProduct: string = environment.apiUrl + '/AIXLenderInfo/DeleteAIXLender';
  private baseUrl_SaveLenderProduct: string = environment.apiUrl + '/AIXLenderInfo/AddAIXLender';
  private baseUrl_UpdateLender: string = environment.apiUrl + '/AIXLenderInfo/UpdateLender';
  private baseUrl_GetLenderlist: string = environment.apiUrl + '/AIXLenderInfo/GetAllAIXLenderInfoList';

  private baseUrl_GetLenderslist: string = environment.apiUrl + '/AIXLenderInfo/GetAllLendersList';
  private baseUrl_EditLenders: string = environment.apiUrl + '/AIXLenderInfo/GetLendersById';
  private baseUrl_getDealerInformation: string = environment.apiUrl + '/AIXLenderInfo/GetDealerInformation';
  
  private baseUrl_GetProductLists: string = environment.apiUrl + '/AIXLenderInfo/GetProductListsByVin';
  
  private baseUrl_Addorderdetaills: string = environment.apiUrl + '/GlobalWarrantyAPI/Addorderdetaills';
  private baseUrl_updateProductdetaills: string = environment.apiUrl + '/GlobalWarrantyAPI/updateProductdetaills';
  

  private baseUrl_Savelenders: string = environment.apiUrl + '/AIXLenderInfo/AddLender';
  private baseUrl_DeleteLender: string = environment.apiUrl + '/AIXLenderInfo/DeleteLender';

  private baseUrl_UploadTrainingvideo: string = environment.apiUrl + '/TrainingVideo/UploadFiles';

  private baseUrl_getpdf: string = environment.apiUrl + '/AIXLenderInfo/DownloadProductList';

  private baseUrl_DeleteLenderRateSheet: string = environment.apiUrl + '/AIXLenderInfo/DeleteAIXLenderRateSheets';

  private baseUrl_GetLenderRateSheetbyLenderName: string = environment.apiUrl + '/AIXLenderInfo/GetLenderRateSheetbyLenderName';

  private baseUrl_SaveLenderRateSheet: string = environment.apiUrl + '/AIXLenderInfo/AddAIXLenderRateSheets';
  private baseUrl_EditLenderRateSheet: string = environment.apiUrl + '/AIXLenderInfo/GetAIXLenderRateSheetById';
  private baseUrl_UpdateLenderRateSheet: string = environment.apiUrl + '/AIXLenderInfo/UpdateAIXLenderRateSheet';
   private baseUrl_UpdateLenderProduct: string = environment.apiUrl + '/AIXLenderInfo/UpdateAIXLender';
   private baseUrl_EditLenderProduct: string = environment.apiUrl + '/AIXLenderInfo/GetAIXLenderInfoById';

   private baseUrl_GetLenderPlans: string = environment.apiUrl + '/AIXLenderInfo/GetLenderPlansByLenderId';

   private baseUrl_GetLenderslistFind: string = environment.apiUrl + '/AIXLenderInfo/GetAllLendersListFindCar';

   getLenderPlans(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetLenderPlans, formdata);
  }

  getLenderlist(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetLenderlist,formdata);
  }

  getAllLendersList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetLenderslist);
  }

  getAllLendersListFindCar(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetLenderslistFind,formdata);
  }


  addLender(formdata) {
    return this.http.post<any[]>(this.baseUrl_Savelenders, formdata);
  }

  UploadTrainingVideo(formdata) {
    return this.http.post<any[]>(this.baseUrl_UploadTrainingvideo, formdata);
  }

  DeleteLender(FormData) {
    return this.http.post<any>(this.baseUrl_DeleteLender, FormData);
  }

  UpdateLender(FormData) {
    return this.http.post<any>(this.baseUrl_UpdateLender, FormData);

  }

  //get pdf response
  Getpdfviw(formdata) {
    return this.http.post<any>(this.baseUrl_getpdf, formdata);
  }

  getDealerInformation(FormData) {
    return this.http.post<any>(this.baseUrl_getDealerInformation, FormData);

  }


  EditLenders(FormData) {
    return this.http.post<any>(this.baseUrl_EditLenders, FormData);

  }

  getProductLists(FormData) {
    return this.http.post<any>(this.baseUrl_GetProductLists, FormData);

  }

  addorderdetaills(FormData) {
    return this.http.post<any>(this.baseUrl_Addorderdetaills, FormData);

  }
  updateProductdetaills(FormData) {
    return this.http.post<any>(this.baseUrl_updateProductdetaills, FormData);

  }


  SaveLenderProduct(formdata) {
    return this.http.post<any[]>(this.baseUrl_SaveLenderProduct, formdata);
  }

  DeletelenderProduct(FormData) {
    return this.http.post<any>(this.baseUrl_DeletelenderProduct, FormData);
  }

  getLenderRateSheetbyLenderName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetLenderRateSheetbyLenderName, formdata);
  }

  DeleteLenderRateSheet(FormData) {
    return this.http.post<any>(this.baseUrl_DeleteLenderRateSheet, FormData);
  }

  SaveLenderRateSheet(formdata) {
    return this.http.post<any[]>(this.baseUrl_SaveLenderRateSheet, formdata);
  }

  EditLenderRateSheet(FormData) {
    return this.http.post<any>(this.baseUrl_EditLenderRateSheet, FormData);

  }

  EditLenderProduct(FormData){
    return this.http.post<any>(this.baseUrl_EditLenderProduct, FormData);
  }


  UpdateLenderRateSheet(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateLenderRateSheet, formdata);
  }

  UpdateLenderProduct(formdata){
    return this.http.post<any[]>(this.baseUrl_UpdateLenderProduct, formdata);
  }
  getCustomRulesList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetCustomRulesList);
  }
  getCustomRulesListbyId(FormData) {
    return this.http.post<any>(this.baseUrl_GetCustomRulesListbyId, FormData);

  }

}
