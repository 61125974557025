import { Directive, ElementRef, Input, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ISubscription } from 'rxjs/Subscription';

@Directive({
  selector: '[phoneMask]'
})
export class PhoneMaskDirective implements OnInit, OnDestroy {

  private _phoneControl: AbstractControl;
  private _preValue:string;

  // @Input()
  // set phoneControl(control: AbstractControl) {
  //   this._phoneControl = control;
  // }
  @Input() 
  set phoneControl(control:AbstractControl){
    this._phoneControl = control;
  }
  @Input() 
  set preValue(value:string){
    this._preValue = value;
  }

  private sub:ISubscription;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.phoneValidate();
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  // phoneValidate() {
  //   let preInputValue: string;
  
  //   this.sub = this._phoneControl.valueChanges.subscribe(data => {
  //     if (!preInputValue) {
  //       preInputValue = data;
  //     }
  
  //     var lastChar: string = preInputValue.substr(preInputValue.length - 1);
  //     var newVal = data.replace(/\D/g, '');
  
  //     let start = this.el.nativeElement.selectionStart;
  //     let end = this.el.nativeElement.selectionEnd;
  
  //     if (data.length < preInputValue.length) {
  //       if (preInputValue.length <= start && lastChar == ')') {
  //         start = start - 1;
  //         end = end - 1;
  //       }
  //       if (newVal.length <= 3) {
  //         newVal = newVal.replace(/^(\d{0,3})/, '($1');
  //       } else if (newVal.length <= 6) {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  //       } else {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  //       }
  
  //       this._phoneControl.setValue(newVal, { emitEvent: false });
  //       this.el.nativeElement.setSelectionRange(start, end);
  //     } else if (data.length > preInputValue.length && data.length >= 14) {
  //       // Disable input beyond 14 characters
  //       this._phoneControl.setValue(preInputValue, { emitEvent: false });
  //       this.el.nativeElement.setSelectionRange(start, end);
  //     } else {
  //       if (newVal.length <= 3) {
  //         newVal = newVal.replace(/^(\d{0,3})/, '($1)');
  //       } else if (newVal.length <= 6) {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  //       } else {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  //       }
  
  //       if (preInputValue.length >= start) {
  //         if (lastChar == '(') {
  //           start = start + 1;
  //           end = end + 1;
  //         } else if (lastChar == ')') {
  //           start = start + 2;
  //           end = end + 2;
  //         } else if (lastChar == '-') {
  //           start = start + 1;
  //           end = end + 1;
  //         } else if (lastChar == ' ') {
  //           start = start + 1;
  //           end = end + 1;
  //         }
  //         this._phoneControl.setValue(newVal, { emitEvent: false });
  //         this.el.nativeElement.setSelectionRange(start, end);
  //       } else {
  //         this._phoneControl.setValue(newVal, { emitEvent: false });
  //         this.el.nativeElement.setSelectionRange(start + 2, end + 2);
  //       }
  //     }
  
  //     preInputValue = data;
  //   });
  // }
  // phoneValidate() {
  //   let preInputValue: string;
  
  //   this.sub = this._phoneControl.valueChanges.subscribe(data => {
  //     if (!preInputValue) {
  //       preInputValue = data;
  //     }
  //     var lastChar: string = preInputValue.substr(preInputValue.length - 1);
  //     var newVal = data.replace(/\D/g, '');
  
  //     let start = this.el.nativeElement.selectionStart;
  //     let end = this.el.nativeElement.selectionEnd;
  
  //     if (data.length < preInputValue.length) {
  //       if (preInputValue.length <= start && lastChar == ')') {
  //         start = start - 1;
  //         end = end - 1;
  //       }
  //       if (newVal.length <= 3) {
  //         newVal = newVal.replace(/^(\d{0,3})/, '($1');
  //       } else if (newVal.length <= 6) {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  //       } else {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  //       }
  
  //       this._phoneControl.setValue(newVal, { emitEvent: false });
  //       this.el.nativeElement.setSelectionRange(start, end);
  //     } else if (data.length > preInputValue.length && data.length >= 14) {
  //       // Disable input beyond 14 characters
  //       newVal = newVal.substring(0, 10); // Limit to 10 digits
  //       newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  //       this._phoneControl.setValue(newVal, { emitEvent: false });
  //       this.el.nativeElement.setSelectionRange(start, end);
  //     } else {
  //       if (newVal.length <= 3) {
  //         newVal = newVal.replace(/^(\d{0,3})/, '($1)');
  //       } else if (newVal.length <= 6) {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
  //       } else {
  //         newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, '($1) $2-$3');
  //       }
  
  //       if (preInputValue.length >= start) {
  //         if (lastChar == '(') {
  //           start = start + 1;
  //           end = end + 1;
  //         } else if (lastChar == ')') {
  //           start = start + 2;
  //           end = end + 2;
  //         } else if (lastChar == '-') {
  //           start = start + 1;
  //           end = end + 1;
  //         } else if (lastChar == ' ') {
  //           start = start + 1;
  //           end = end + 1;
  //         }
  //         this._phoneControl.setValue(newVal, { emitEvent: false });
  //         this.el.nativeElement.setSelectionRange(start, end);
  //       } else {
  //         this._phoneControl.setValue(newVal, { emitEvent: false });
  //         this.el.nativeElement.setSelectionRange(start + 2, end + 2);
  //       }
  //     }
  
  //     preInputValue = newVal;
  //   });
  // }

  // phoneValidate() {
  //   this.sub = this._phoneControl.valueChanges.subscribe(data => {
  //     let newVal = data.replace(/\D/g, ''); // Remove non-numeric characters
  
  //     // Format the phone number
  //     let formattedVal = '';
  //     if (newVal.length > 0) {
  //       formattedVal = '(' + newVal.substring(0, 3) + ') ';
  //     }
  //     if (newVal.length > 3) {
  //       formattedVal += newVal.substring(3, 6) + '-';
  //     }
  //     if (newVal.length > 6) {
  //       formattedVal += newVal.substring(6);
  //     }
  
  //     this._phoneControl.setValue(formattedVal, { emitEvent: false });
  
  //     // Set the cursor position at the end
  //     const inputElement = this.el.nativeElement;
  //     inputElement.setSelectionRange(formattedVal.length, formattedVal.length);
  //   });
  // }

phoneValidate() {
  this.sub = this._phoneControl.valueChanges.subscribe(data => {
    const numericDigits = data.replace(/\D/g, '');
    const formattedVal = this.formatPhoneNumber(numericDigits);
    this._phoneControl.setValue(formattedVal, { emitEvent: false });

    const start = this.el.nativeElement.selectionStart;
    const end = this.el.nativeElement.selectionEnd;
    const diff = formattedVal.length - numericDigits.length;

    if (start !== null && end !== null) {
      this.el.nativeElement.setSelectionRange(start + diff, end + diff);
    }
  });
}

formatPhoneNumber(value: string): string {
  if (!value) {
    return '';
  }

  if (value.length <= 3) {
    return `(${value}`;
  } else if (value.length <= 6) {
    return `(${value.slice(0, 3)}) ${value.slice(3)}`;
  } else {
    return `(${value.slice(0, 3)}) ${value.slice(3, 6)}-${value.slice(6, 10)}`;
  }
}

}