
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { GlobalCodeCategory } from '@app/_models/GlobalCodeCategory';

import { AccountService, AlertService } from '@app/_services';
import { AddrepoService } from '@app/_services/addrepo.service';
import { AddgroupService } from '../_services/addgroup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { NotifierService } from 'angular-notifier';
import { DashboardService } from '@app/_services/dashboard.service';
@Component({
  selector: 'app-list-repo',
  templateUrl: './add-global-code-category.component.html',
  styleUrls: ['./add-global-code-category.component.less']
})
export class AddGlobalCodeCategoryComponent implements OnInit {

  AddGlobalCodeCategoryForm: FormGroup;
  private notifier: NotifierService;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private addRepoService: AddrepoService,
    private router: Router,
    private dashboardService: DashboardService,
    notifier: NotifierService
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  globalCodeCategory: GlobalCodeCategory[] = [];
  TotalRecord:any;
  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  pageRep = 1;
  pageSizeRep = 10;
  loading = false;
  submitted = false;

  globalCodeCategoryList = [];
  deleteitem: any = 0;
  edititemid: any = 0;
  editPostionValue: any;
  editvalueFname: any;
  editvalueLname: any;
  editvalueEmail: any;
  editvaluePhone: any;
  updateid: any;

  EditCategory3Value:any;
  EditCategory2Value:any;
  EditCategory1Value:any;
  EditGlobalCodeCategoryNotesValue:any;
  EditGlobalCodeCategoryValues:any;
  EditGlobalCodeCategoryNameValue:any;
  EditCustomerSetupCategoryNumberValue:any;
  //select position

  selectedPosition: any;
  Positionlist=[];

  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.globalCodeCategory.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  ipAddress:any;
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    this.CreateGlobalCodeCategoryFrom();
    this.ipAddress = localStorage.getItem('IpAddresstoTrack');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    this.GetGlobalCodeCategorylist();
    this.accountService.SaveActivityLog(this.ipAddress);
  }

//   copytable(el) {
//     var urlField = document.getElementById(el) ;
//     var range = document.createRange();
//     range.selectNode(urlField);
//     window.getSelection().addRange(range) ;
//     document.execCommand('copy');
//     this.showNotification("success","Data copy to clipboard successfully")
// }

copytable(el) {
  debugger
  var prepare=[];
  this.globalCodeCategory.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.customerSetupCategoryNumber);
    tempObj.push(e.globalCodeCategoryName);
    tempObj.push(e.globalCodeCategoryValue);
    tempObj.push(e.globalCodeCategoryNotes);
    prepare.push(tempObj);
  });

  // Create a string with the table data
  const tableData = prepare.map(row => row.join('\t')).join('\n');

  // Create a temporary <textarea> element and set its value
  const textarea = document.createElement('textarea');
  textarea.value = tableData;

  // Add the <textarea> element to the document and select its content
  document.body.appendChild(textarea);
  textarea.select();

  // Use the execCommand method to copy the selected content to the clipboard
  document.execCommand('copy');

  // Remove the temporary <textarea> element from the document
  document.body.removeChild(textarea);

  // Show a notification to the user indicating that the copy was successful
  this.showNotification("success", "Data copied to clipboard successfully");
}

exportToCSV(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.globalCodeCategory);
}

printTable() {
  var prepare = [];
  this.globalCodeCategory.forEach(e => {
    var tempObj = [];
    tempObj.push(e.customerSetupCategoryNumber);
    tempObj.push(e.globalCodeCategoryName);
    tempObj.push(e.globalCodeCategoryValue);
    tempObj.push(e.globalCodeCategoryNotes);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>CustomerSetupCategoryNumber</th><th>Category Name</th><th>Category Value</th><th>Notes</th><th>Make</th><th>Model</th><th>Trim</th><th>Body</th><th>ListedPrice</th><th>DealerCost</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 

downloadPdf() {
  var prepare=[];
  this.globalCodeCategory.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.customerSetupCategoryNumber);
    tempObj.push(e.globalCodeCategoryName);
    tempObj.push( e.globalCodeCategoryValue);
    tempObj.push( e.globalCodeCategoryNotes);
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['CustomerSetupCategoryNumber','Category Name','Category Value','Notes']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('ListofCategory' + '.pdf');
}

exportexcel() {
  const prepare = [];
  this.globalCodeCategory.forEach(e => {
    const tempObj = [];
    tempObj.push(e.customerSetupCategoryNumber);
    tempObj.push(e.globalCodeCategoryName);
    tempObj.push( e.globalCodeCategoryValue);
    tempObj.push( e.globalCodeCategoryNotes);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['CustomerSetupCategoryNumber','Category Name','Category Value','Notes'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'GlobalCodeCategory');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'GlobalCodeCategory.xlsx');
}
  // function for creating from
  CreateGlobalCodeCategoryFrom() {
    this.AddGlobalCodeCategoryForm = new FormGroup({
      CustomerSetupCategoryNumber: this.fb.control('', Validators.required),
      GlobalCodeCategoryName: this.fb.control('', Validators.required),
      GlobalCodeCategoryValue: this.fb.control('', Validators.required),
      GlobalCodeCategoryNotes: this.fb.control(''),
      Category1: this.fb.control(''),
      Category2: this.fb.control(''),
      Category3: this.fb.control(''),

      EditCustomerSetupCategoryNumber: this.fb.control(''),
      EditGlobalCodeCategoryName: this.fb.control(''),
      EditGlobalCodeCategoryValue: this.fb.control(''),
      EditGlobalCodeCategoryNotes: this.fb.control(''),
      EditCategory1: this.fb.control(''),
      EditCategory2: this.fb.control(''),
      EditCategory3: this.fb.control(''),

    });
  }

  // function for open model pop up
  openModal(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered',backdrop: 'static', keyboard: false });

    
  }
  // edit functionality
  // function for open model pop up
  EditModalpopup(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.edititemid = itemid;
    var modelGroup = {
      "GlobalCodeCategoryID": itemid
    }

    this.dashboardService.EditGlobalCodeCategory(modelGroup)
      .subscribe(
        data => {

          this.EditCategory3Value=data[0].category3;
          this.EditCategory2Value=data[0].category2;
          this.EditCategory1Value=data[0].category1;
          this.EditGlobalCodeCategoryNotesValue=data[0].globalCodeCategoryNotes;
          this.EditGlobalCodeCategoryValues=data[0].globalCodeCategoryValue;
          this.EditGlobalCodeCategoryNameValue=data[0].globalCodeCategoryName;
          this.EditCustomerSetupCategoryNumberValue=data[0].customerSetupCategoryNumber;

          this.updateid = data[0].globalCodeCategoryID;

         this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }

  closepopupmodel() {
    this.AddGlobalCodeCategoryForm.invalid
  }

  openRepoModel(targetModal) {
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

  }

  update() {
    debugger;
    this.submitted = true;

    if (this.AddGlobalCodeCategoryForm.controls.EditCustomerSetupCategoryNumber.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddGlobalCodeCategoryForm.controls.EditGlobalCodeCategoryName.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddGlobalCodeCategoryForm.controls.EditGlobalCodeCategoryValue.invalid) {
      this.isValid = false;
      return;
    }
    if (this.AddGlobalCodeCategoryForm.controls.EditGlobalCodeCategoryNotes.invalid) {
      this.isValid = false;
      return;
    }
    var modelGroup = {
      "GlobalCodeCategoryID": this.updateid,
      "CustomerSetupCategoryNumber": this.EditCustomerSetupCategoryNumberValue,
      "GlobalCodeCategoryName": this.EditGlobalCodeCategoryNameValue,
      "GlobalCodeCategoryValue": this.EditGlobalCodeCategoryNameValue,
      "GlobalCodeCategoryNotes": this.EditGlobalCodeCategoryNotesValue,
      "Category1": this.EditGlobalCodeCategoryNameValue,
      "Category2": this.EditGlobalCodeCategoryNameValue,
      "Category3": this.EditGlobalCodeCategoryNameValue,
    }

    this.dashboardService.UpdateGlobalCodeCategory(modelGroup)

      .subscribe(
        (data: any) => {
          this.GetGlobalCodeCategorylist();
          this.submitted = false;
          this.CreateGlobalCodeCategoryFrom();
          this.showNotification("success", "Data updated successfully")
          this.modalRef.hide();
        },
        error => {
        });
  }

  get f() { return this.AddGlobalCodeCategoryForm.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    debugger
    this.submitted = true;


    if (this.AddGlobalCodeCategoryForm.controls.CustomerSetupCategoryNumber.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryNotes.invalid) {
      this.isValid = false;
      return;
    }
    else if (this.AddGlobalCodeCategoryForm.controls.EditGlobalCodeCategoryNotes.invalid) {
      this.isValid = false;
      return;
    }
    else {
      var modelGroup = {
        "CustomerSetupCategoryNumber": this.AddGlobalCodeCategoryForm.controls.CustomerSetupCategoryNumber.value,
        "GlobalCodeCategoryName": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.value,
        "GlobalCodeCategoryValue": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.value,
        "GlobalCodeCategoryNotes": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryNotes.value,
        "Category1": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.value,
        "Category2": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.value,
        "Category3": this.AddGlobalCodeCategoryForm.controls.GlobalCodeCategoryName.value,
      }


      this.dashboardService.SaveGlobalCodeCategory(modelGroup)
        .subscribe(
          (data: any) => {
            this.GetGlobalCodeCategorylist();
            this.submitted = false;
            this.showNotification("success", "Data saved successfully");
            this.CreateGlobalCodeCategoryFrom();
            this.modalRef.hide();
            this.closeEditModel();
            this.closepopupmodel();
            this.modalcloseOpen.hide();
            this.modalRef.hide();
          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }


  }


  CloseAddmodel() {
    this.modalRef.hide();
    this.submitted=false;
  }

  // function for confirm  delete
  confirm(): void {
    debugger;
    this.deleteitem;
    var modelGroup = {
      "Id": this.deleteitem
    }
    this.dashboardService.DeleteGlobalCodeCategory(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted successfully");
          this.GetGlobalCodeCategorylist();
        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.modalRef.hide();
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }


  //Calling get list of group on page load

  GetGlobalCodeCategorylist() {
    this.dashboardService.getGlobalCodeCategorylist()
      .subscribe(
        (data: any) => {
          debugger;
          this.globalCodeCategory = data;
          this.TotalRecord = data.length;
        },
        error => {
        });
  }

}
