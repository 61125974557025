<section id="updateprofile">
    <div class="container-fluid">
        <div class="row">
            <div class="col-11 col-md-10 col-lg-8 col-xl-5 card mx-auto mt-md-5 mt-4 px-0">
              <div class="card-header bg-dark text-white">
                <h4 class="mb-0">Update Profile</h4>
              </div>
               <div class="card-body">
                <form class="mt-4" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <div class="form-row">

                        <div class="form-group col-md-6">
                            <label>First Name</label>
                            <input type="text" formControlName="firstName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.firstName.errors }" />
                            <div *ngIf="submitted && f.firstName.errors" class="invalid-feedback">
                                <div *ngIf="f.firstName.errors.required">First Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Last Name</label>
                            <input type="text" formControlName="lastName" class="form-control"
                                [ngClass]="{ 'is-invalid': submitted && f.lastName.errors }" />
                            <div *ngIf="submitted && f.lastName.errors" class="invalid-feedback">
                                <div *ngIf="f.lastName.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label>Email</label>
                        <input type="text" formControlName="email" class="form-control" readonly
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                        </div>
                    </div>

                    <div class="form-group mt-4">
                        <button type="submit" [disabled]="loading" class="btn btn-primary mr-2">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Update
                        </button>
<!--
                        <button type="button" (click)="onDelete()" [disabled]="deleting" class="btn btn-danger">
                            <span *ngIf="deleting" class="spinner-border spinner-border-sm mr-1"></span>
                            Delete
                        </button> -->

                        <a style="text-decoration:none;" routerLink="/List" href="" class="btn btn-outline-danger">Cancel</a>
                    </div>
                </form>
               </div>
            </div>
        </div>
    </div>
</section>
