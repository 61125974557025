<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="buildreportForm">
        <div class="container-fluid">
            <div class="page-header pb-0 pt-0">
                
            </div>
            <div class="row mt-1" id="VehiclePaymentInfo">
                <div class="accordion" id="accordionExample">
                    <div class="col-md-12">
                        <div class="card card-raised h-100">
                            <div id="BuildReport" class="card-header card-raised bg-info text-white">
                                
                                <div class="row justify-content-between align-items-center mx-0">
                                    <div class="col col-md-12 px-0">
                                        <div  class="row justify-content-start align-items-center mt-0" >
                                            <h6 class="card-title text-white text-uppercase mb-0 d-inline-block col-auto">
                                                Deal Recap Report
                                            </h6>
                                            <div class="ml-auto col-auto" >
                                                <button  class="btn btn-warning btn-sm mb-sm-0 mr-1"  (click)="printPage()">Print</button>
                                                <button class="btn btn-white plusbtn" type="button"
                                                data-toggle="collapse" data-target="#collapseOne"
                                                aria-expanded="true" aria-controls="collapseOne">
                                                <i class="fas fa-chevron-down"></i>
                                            </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="BuildCar"
                                data-parent="#accordionExample">
                                <div class="card-body heightFixed">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Dealer Info</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-6">
                                                            <label>Dealer Name</label>
                                                            <h6>{{DealerName}}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-6 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Lender Info</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-4 col-lg-4">
                                                            <label>Lender Name</label>
                                                            <h6>{{LenderName}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-4 col-lg-4">
                                                            <label>Key</label>
                                                            <h6>{{LenderKey}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-4 col-lg-4">
                                                            <label>Pay Call</label>
                                                            <h6>{{PayCall}}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Vehicle Info</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label class="text-primary">MSRP</label>
                                                            <h6>{{CBBValue}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Make</label>
                                                            <h6>{{Make}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Model</label>
                                                            <h6>{{Model}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>VIN</label>
                                                            <h6>{{VIN}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Stock Number</label>
                                                            <h6>{{StockNumber}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Kms</label>
                                                            <h6>{{kms}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Year</label>
                                                            <h6>{{year}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg col-xl">
                                                            <label>Trim</label>
                                                            <h6>{{trim}}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Trade In -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Trade In</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Net Trade In Allowance</label>
                                                            <h6>{{TradeAllowance}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Lien Amount</label>
                                                            <h6>{{Lien}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Equity</label>
                                                            <h6>{{EquityControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Down Payment</label>
                                                            <h6>{{DownPaymentControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Odometer</label>
                                                            <h6>{{kms}}</h6>
                                                        </div>
                                                        <!-- <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>ACV</label>
                                                            <h6>{{ACVControl}}</h6>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Taxes -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Taxes</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Region</label>
                                                            <h6>{{RegionControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Tax Rate</label>
                                                            <h6>{{TaxRateControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Total Tax</label>
                                                            <h6>{{TotalTaxControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>PST</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>GST/HST</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Federal Luxury Tax</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Fee -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Fee</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Admin Fee</label>
                                                            <h6>{{FrontDocFee}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Lender Fee</label>
                                                            <h6>{{LenderFee}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>PPSA</label>
                                                            <h6>{{PPSA}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Cash Down</label>
                                                            <h6>{{DownPaymentControl}}</h6>
                                                        </div>
                                                        
                                                        <!-- <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Back Doc Fee</label>
                                                            <h6>{{BackDocFee}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Cash Down</label>
                                                            <h6>{{DownPaymentControl}}</h6>
                                                        </div> -->
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>License Fee</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Rebate</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Other Taxable</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Installation and Delivery</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Other Taxable Description</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Other Non-Taxable</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Other Non-Taxable Description</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!-- Aftermarket Service -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Aftermarket Service</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Extended Service Contract</label>
                                                            <h6>{{ExtendendService}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl">
                                                            <label>GAP</label>
                                                            <h6>{{Gap}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl">
                                                            <label>Job Loss</label>
                                                            <h6>{{JobLoss}}</h6>
                                                        </div>
                                                        
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Service Contract Term</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Life Insurance</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>A/H Insurance</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Replacement Warranty</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Financing Terms -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Financing Terms</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Terms</label>
                                                            <h6>{{Term}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Interest Rate</label>
                                                            <h6>{{interestRateControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Total Amount Financed</label>
                                                            <h6>{{AmountFinanced}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Amortization</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Payment Frequency Amount</label>
                                                            <h6>{{MonthlyControl}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>APR</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Cost of Borrowing</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Payment Frequency</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label>Total Monthly Payment</label>
                                                            <h6>N/A</h6>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="form-row mt-4 paymentblock">
                                                        <div class="card w-100 col-md-4 px-0">
                                                            <div class="card-header bg-info">
                                                                <h6 class="mb-0"><b>Payment</b></h6>
                                                            </div>
                                                            <div class="card-body">
                                                                <div class="table-responsive">
                                                                    <table class="table mb-0 table-bordered">
                                                                        <tbody>
                                                                            <tr>
                                                                                <th><b>Frequency</b></th>
                                                                                <td>Amount</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Monthly</th>
                                                                                <td>{{MonthlyControl}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Semi Monthly</th>
                                                                                <td>{{SemiMonthlyControl}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Weekly</th>
                                                                                <td>{{WeeklyControl}}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <th>Bi-Weekly</th>
                                                                                <td>{{BiWeeklyControl}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                         <!-- Gross Info -->
                                        <div class="col-md-12 col-lg-12 mb-3 mb-xl-0">
                                            <div class="card buildreport-title">
                                                <h6 class="card-header bg-info"><b>Final Outcome</b></h6>
                                                <div class="card-body">
                                                    <div class="form-row">
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label class="text-primary">Gross</label>
                                                            <h6>{{GrossProfit}}</h6>
                                                        </div>
                                                        <div class="form-group col-12 col-md-6 col-lg-3 col-xl-2">
                                                            <label class="text-primary">Reserve</label>
                                                            <h6>{{ReservesControl}}</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>