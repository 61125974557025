
<div class="readersack">
    <div class="container-fluid">
        <div class="row">
          <div class="card w-100">
            <div class="card-header bg-dark text-white">
              <h6 class="card-title mb-0">Register</h6>
            </div>
            <div class="card-body">
              <form class="registerform" [formGroup]="RegisterForm" (ngSubmit)="onSubmit()" >
                <div class="panel">
                    <div class="panel-heading bg-dark">
                        <strong> Business Info</strong>
                    </div>
                    <div class="panel-body">
                      <div class="row">
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label>Business Name*</label>
                            <input type="text" formControlName="businessname" class="form-control"
                              [ngClass]="{ 'is-invalid': submitted && f.businessname.errors }" />
                            <div *ngIf="submitted && f.businessname.errors" class="invalid-feedback">
                              <div *ngIf="f.businessname.errors.required">Business Name is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label>GST Number*</label>
                            <input type="text" formControlName="gstNumber"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.gstNumber.errors }" />
                            <div *ngIf="submitted && f.gstNumber.errors" class="invalid-feedback">
                              <div *ngIf="f.gstNumber.errors.required">GST Number is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label>EFT Info*</label>
                            <input type="text" formControlName="EFTinfo"
                            class="form-control" [ngClass]="{ 'is-invalid': submitted && f.EFTinfo.errors }" />
                            <div *ngIf="submitted && f.EFTinfo.errors" class="invalid-feedback">
                              <div *ngIf="f.EFTinfo.errors.required">EFT Info is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-3">
                          <div class="form-group">
                            <label>Payment Gateways*</label>



                            <ng-select class="company-logos"
                            bindLabel="name"
                            placeholder="Select payment"
                            appendTo="body"
                            formControlName="paymenttype"
                            >
                            <ng-option [value]="item.id"  *ngFor="let item of PaymentTypelist">
                              <img src="{{item.image}}" width="20px" height="20px"/>
                              {{item.name}}
                          </ng-option>
                 </ng-select>


                          </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div class="panel">
                  <div class="panel-heading bg-dark">
                    <strong> Address</strong>
                   </div>
                   <div class="panel-body">
                    <div class="row">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Address line 1*</label>
                          <input type="text" placeholder="Start typing your address..." formControlName="addresslineone"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addresslineone.errors }" />
                          <div *ngIf="submitted && f.addresslineone.errors" class="invalid-feedback">
                            <div *ngIf="f.addresslineone.errors.required">Address line 1 is required</div>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Address line 2*</label>
                          <input type="text" placeholder="Unit#, Apt#, Suite#" formControlName="addresslinetwo"
                          class="form-control" [ngClass]="{ 'is-invalid': submitted && f.addresslinetwo.errors }" />
                          <div *ngIf="submitted && f.addresslinetwo.errors" class="invalid-feedback">
                            <div *ngIf="f.addresslinetwo.errors.required">Address line 2 is required</div>
                          </div>
                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Country*</label>

                          <select  class="form-control" formControlName="Country"
                           (change)="GetStates($event.target.value)" [ngClass]="{ 'is-invalid': submitted && f.Country.errors }">
                           <option value="new">Select Country</option>
                            <option [value]="countrylists.id" *ngFor="let countrylists of countrylist">
                              {{countrylists.name}}
                            </option>
                          </select>                         
                        </div>
                      </div>


                      <div class="col-md-3">
                        <div class="form-group">
                          <label>State*</label>
                          <select  class="form-control" formControlName="State" [ngClass]="{ 'is-invalid': submitted && f.State.errors }"
                          (change)="GetCityList($event.target.value)">
                          <option value="new">Select State</option>
                            <option [value]="statelists.id" *ngFor="let statelists of statelist">
                              {{statelists.name}}
                            </option>
                          </select>
                         
                        </div>
                      </div>

                      <div class="col-md-3">
                        <div class="form-group">
                          <label>City*</label>
                         
                          <select  class="form-control" formControlName="cityName" [ngClass]="{ 'is-invalid': submitted && f.cityName.errors }">
                            <option value="new">Select City</option>
                            <option [value]="citylists.id" *ngFor="let citylists of citylist">
                              {{citylists.name}}
                            </option>
                          </select>

                          <!-- error block -->
                          <div class="invalid-feedback" *ngIf="isSubmitted && cityName.errors?.required">
                            <sup>*</sup>Please enter your city name
                          </div>
                        </div>
                      </div>


                  </div>
                  </div>
                </div>
          
              <div class="panel">
                  <div class="panel-heading bg-dark">
                    <div class="col-12 px-0">
                      <div class="d-flex justify-content-between">
                        <strong>Account User</strong>
                        <!-- <span style="cursor:pointer;" (click)="addItem()">Add more user<i class="fas fa-plus mt-1"></i></span> -->
                        <div class="form-group text-right mb-0">
                          <button class="btn btn-sm btn-primary mr-2 add-more-user" type="button" (click)="addItem()">Add more user</button>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div class="panel-body" formArrayName="userlist" *ngFor="let item of userlist().controls; let i = index;">
                    <div class="row mt-1" [formGroupName]="i">
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>First Name*</label>

                          <input type="text"   formControlName="firstName"  required   class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.firstName.errors?.required }" />
                          <div *ngIf="submitted && item.controls.firstName.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.firstName.errors?.required">First Name is required</div>
                          </div>



                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Last Name*</label>


                          <input type="text"   formControlName="lastName"  required   class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.lastName.errors?.required }" />
                          <div *ngIf="submitted && item.controls.lastName.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.lastName.errors?.required">lastName Name is required</div>
                          </div>


                        </div>

                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Email*</label>


                          <input type="text"   formControlName="email"   required   class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.email.errors?.required }" />
                          <div *ngIf="submitted && item.controls.email.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.email.errors?.required">email  is required</div>
                          </div>
                          <div *ngIf="item.controls.email.errors?.email" style="color: red;">Email is invalid</div>

                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Phone*</label>

                          <input type="text"   (keypress)="keyPressNumbers($event)"  formControlName="phone"  required  max="10"  class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.phone.errors?.required }" />
                          <div *ngIf="submitted && item.controls.phone.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.phone.errors?.required">phone  is required</div>
                          </div>

                        </div>
                      </div>


                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Password*</label>


                          <input type="password"   formControlName="password"  required   class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.password.errors?.required }" />
                          <div *ngIf="submitted && item.controls.password.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.password.errors?.required">password  is required</div>
                          </div>
                          <div class="errors"  style="color:red;" *ngIf="item.controls.password.invalid && (item.controls.password.touched || item.controls.password.dirty)">
                            <div class="error-text"  style="color:red;" *ngIf="item.controls.password.errors.minlength">
                              Password must be at least 8 characters
                            </div>

                            <div class="error-text"  style="color:red;" class="error-text" *ngIf="item.controls.password.hasError('passwordStrength')">
                              {{item.controls.password.errors['passwordStrength']}}
                            </div>
                          </div>


                        </div>
                      </div>
                      <div class="col-lg-3">
                        <div class="form-group">
                          <label>Confirm Password*</label>

                          <input type="password"   formControlName="confirmPassword"  required   class="form-control" [ngClass]="{ 'is-invalid': submitted && item.controls.confirmPassword.errors?.required }" />
                          <div *ngIf="submitted && item.controls.password.errors?.required" class="invalid-feedback">
                            <div *ngIf="item.controls.confirmPassword.errors?.required">confirm Password  is required</div>
                          </div>
                          <div *ngIf="submitted && (item.controls.confirmPassword.touched || item.controls.confirmPassword.dirty) && (item.controls.confirmPassword.value!=item.controls.password.value)" style="color:red;">password did not matched </div>

                        </div>
                      </div>
                     </div>
                     <div class="row mt-2">
                      <div class="col-12">
                          <div class="form-group text-right" *ngIf="showRemoveButton == 'true'">
                            <button class="btn btn-sm btn-primary mr-2" *ngIf="i>0" (click)="removeGroup(i)">Remove</button>
                          </div>
                      </div>

                     </div>
                  </div>
              </div>

              <div class="col-12 text-center">
                <div class="form-group ">
                  <button class="btn btn-sm btn-primary mr-2">Register</button>
                  <button routerLink="../login" class="btn btn-sm btn-primary">Cancel</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>






