<section id="admindashboard">
<form class="col-12 px-0" [formGroup]="AddDealersForm">
<div class="container-fluid">
    <div class="row">
        <div class="col-12 mt-2">


                <div class="row justify-content-between align-items-center mb-2">
                  <div class="search-heros col-md-auto mb-2 mt-sm-0 ml-3">
                    <div class="row justify-content-between align-items-center">
                      <div class="form-group d-flex mb-sm-0 mb-0 col-12 col-sm-auto px-0">
                        <label class="mr-3 mt-2">Search:</label>
                        <input class="form-control" type="text" name="search" (keyup)="SerachPremiumDataFromTable($event)"
                          autocomplete="off">
                      </div>
                     
                    </div>
                  </div>
                  <div class="col-md-auto mb-2 mt-sm-0">
                    <div class="form-group d-flex mb-0">
                      <label class="mb-0 mr-2 pt-1">Filters</label>
                      <select  #filterValues class="form-control" name="cars" id="cars"
                      (change)="GetDealerByFilter(filterValues.value)" >
                        <option value="All" selected>All</option>
                        <option value="Hub Dealers">Hub Dealers</option>
                        <option value="Non-Hub Dealers">Non-Hub Dealers</option>
                        <option value="In-Active">In-Active</option>
                      </select> 
                    </div>
                  </div>
                  <div class="col-md-auto mb-2 mt-sm-0">
                    <div class="documentbtn d-inline-block">
                        <ul class="list-unstyled mb-1">
                          <li>
                            <button title="Copy" class="btn btndesign btn-sm mb-sm-0" (click)="copytable('usertable')">
                              <img src="../../assets/images/icon-btn/copy.png" alt="copy" />
                              Copy
                            </button>
                          </li>
                          <li>
                            <button title="Excel" class="btn btndesign btn-sm mb-sm-0" (click)="exportexcel()">
                              <img src="../../assets/images/icon-btn/excel.png" alt="Excel" />
                              Excel
                            </button>
                          </li>
                          <li>
                            <button title="PDF" class="btn btndesign btn-sm mb-sm-0" (click)="downloadPdf()">
                              <img src="../../assets/images/icon-btn/pdf.png" alt="PDF" />
                              PDF
                            </button>
                          </li>
                          <li>
                            <button title="Print" class="btn btndesign btn-sm mb-sm-0" (click)="printTable()">
                              <img src="../../assets/images/icon-btn/print.png" alt="Print" />
                              Print
                            </button>
                          </li>
                        </ul>
                    </div>
                    
                  </div>
                 
                </div>

                <div class="col-md-12 px-0">
                  <div class="card card-raised h-100 mb-0">
                      <div class="card-header card-raised bg-info text-white">
                          <div class="row justify-content-between align-items-center">
                            <div class="col-sm-6">
                              <h5 class="card-title text-white text-uppercase mb-0">List of Dealers</h5>
                            </div>
                            <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                              <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                            </div>
                          </div>
                      </div>
                  <div class="card-body p-4">
                    <div class="table-responsive">
                        <table id="usertable" class="table table-bordered" style="width:100%">
                            <thead>
                                <tr>
                                 <!-- <th>Logo</th> -->

                                    <th  (click)="sort('dealerName')"> Dealer Name <i class="fa fa-fw fa-sort"></i></th>
                                    <th (click)="sort('groupName')" style="width: 60px;">Group Name <i class="fa fa-fw fa-sort"></i></th>

                                    <th> Contact Name </th>
                                   
                                    <th>Phone</th>
                                    <th>Email</th>
                                    <th>Postal Code</th>
                                    <th>Representative </th>
                                    <th  (click)="sort('globalCodeName')">Region/Province <i class="fa fa-fw fa-sort"></i></th>
                                    <!-- <th style="min-width: 90px;">Active On</th>
                                    <th style="min-width: 90px;">Active Until</th>
                                    <th style="min-width: 90px;">Subscription Days Left</th> -->

                                    <th style="text-align:center;" colspan="3">
                                      <button *ngIf="rolevalue==='Admin' || rolevalue==='SuperAdmin'"  type="button" class="btn btn-primary btn-sm text-white" (click)="openAddModel(ModelAdd,'')">
                                      <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Dealer
                                    </button></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="DealerFilterValue=='All' || DealerFilterValue=='Non-Hub Dealers'">
                              <tr   *ngFor="let Dealerslistdata of Dealerslist | filter:searchText ; let i = index; let odd = odd">

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerName }}</td>

                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerName }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.groupName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.groupName }}</td>
                              
                                <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.dealerContactName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.dealerContactName }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerContactNumber }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerContactNumber }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerContactEmail }}</td>


                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerContactEmail }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.postalCode }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.postalCode }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.representative }}</td>
                                <td style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.representative }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                <!-- <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeStart | date }}</td>
                                <td style="min-width: 90px;" style="background-color:#454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeStart | date }}</td>

                                <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>
                                <td style="min-width: 90px;" style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>

                                <td *ngIf="Dealerslistdata.leftdays>7 ">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays<=7 && Dealerslistdata.leftdays<2 && Dealerslistdata.leftdays!=0" style="background: yellow;">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays==0" style="background:lightcoral;">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays==2" style="background:#454d55;">{{Dealerslistdata.leftdays}}</td> -->
                               
                               
                                <td style="text-align:center;min-width:183px;" *ngIf="Dealerslistdata.active=='Active'">
                                  <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                  (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                  <i class="fa-solid fa-pen-to-square"></i></button>
                                  <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                  (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                  &nbsp;
                                  <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                  (click)="openActivateModel(ActivateModelPopUp,
                                  Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                  id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                    <i class="far fa-check-circle"></i>
                                  </button>

                                  <button *ngIf="Dealerslistdata.activated==='true'"
                                  (click)="openActivateModel(ActivateModelPopUp,
                                  Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                  title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                    <i class="far fa-check-circle"></i>
                                  </button>

                                  &nbsp;
                                  <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                  (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                  <i class="fa fa-refresh"></i></button>

                                  <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                    >
                                  <i class="fa fa-refresh"></i></button>

                                  &nbsp;

                                  <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                  title="Click to Deactivate Dealer"
                                  (click)="DeactivateDealer(Dealerslistdata.id);"
                                    >
                                    <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                    </i></button>
      
      
                                    <button  *ngIf="Dealerslistdata.active!='Active'"
                                    class="btn bg-gradient-success btn-sm ml-1"
                                    title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                    > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                    </i></button>
                                  </td>


                                  <td style="text-align:center;background-color:#454d55;"  *ngIf="Dealerslistdata.active!='Active'">
                                    <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                    (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                    <i class="fa-solid fa-pen-to-square"></i></button>
                                    <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                    (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                    &nbsp;
                                    <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                    id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    <button *ngIf="Dealerslistdata.activated==='true'"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                    title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    &nbsp;
                                    <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                    (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                    <i class="fa fa-refresh"></i></button>
  
                                    <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                      >
                                    <i class="fa fa-refresh"></i></button>
  
                                    &nbsp;
  
                                    <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                    title="Click to Deactivate Dealer"
                                    (click)="DeactivateDealer(Dealerslistdata.id);"
                                      >
                                      <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                      </i></button>
        
        
                                      <button  *ngIf="Dealerslistdata.active!='Active'"
                                      class="btn bg-gradient-success btn-sm ml-1"
                                      title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                      > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                      </i></button>
                                    </td>

                              </tr>

                            </tbody>


                            <tbody *ngIf="DealerFilterValue=='Hub Dealers'">
                              <tr   *ngFor="let Dealerslistdata of Dealerslist | filter:searchText ; let i = index; let odd = odd">

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerName }}</td>

                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerName }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.groupName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.groupName }}</td>
                              
                                <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.aithrhubContactName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.aithrhubContactName }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerPhone }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerPhone }}</td>

                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerEmail }}</td>


                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerEmail }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerEmail }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.postalCode }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.representative }}</td>
                                <td style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.representative }}</td>
                                <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                <!-- <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeStart | date }}</td>
                                <td style="min-width: 90px;" style="background-color:#454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeStart | date }}</td>

                                <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>
                                <td style="min-width: 90px;" style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>

                                <td *ngIf="Dealerslistdata.leftdays>7 ">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays<=7 && Dealerslistdata.leftdays<2 && Dealerslistdata.leftdays!=0" style="background: yellow;">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays==0" style="background:lightcoral;">{{Dealerslistdata.leftdays}}</td>
                                <td *ngIf="Dealerslistdata.leftdays==2" style="background:#454d55;">{{Dealerslistdata.leftdays}}</td> -->
                               
                               
                                <td style="text-align:center;" *ngIf="Dealerslistdata.active=='Active'">
                                  <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                  (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                  <i class="fa-solid fa-pen-to-square"></i></button>
                                  <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                  (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                  &nbsp;
                                  <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                  (click)="openActivateModel(ActivateModelPopUp,
                                  Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                  id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                    <i class="far fa-check-circle"></i>
                                  </button>

                                  <button *ngIf="Dealerslistdata.activated==='true'"
                                  (click)="openActivateModel(ActivateModelPopUp,
                                  Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                  title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                    <i class="far fa-check-circle"></i>
                                  </button>

                                  &nbsp;
                                  <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                  (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                  <i class="fa fa-refresh"></i></button>

                                  <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                    >
                                  <i class="fa fa-refresh"></i></button>

                                  &nbsp;

                                  <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                  title="Click to Deactivate Dealer"
                                  (click)="DeactivateDealer(Dealerslistdata.id);"
                                    >
                                    <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                    </i></button>
      
      
                                    <button  *ngIf="Dealerslistdata.active!='Active'"
                                    class="btn bg-gradient-success btn-sm ml-1"
                                    title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                    > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                    </i></button>
                                  </td>


                                  <td style="text-align:center;background-color:#454d55;"  *ngIf="Dealerslistdata.active!='Active'">
                                    <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                    (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                    <i class="fa-solid fa-pen-to-square"></i></button>
                                    <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                    (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                    &nbsp;
                                    <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                    id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    <button *ngIf="Dealerslistdata.activated==='true'"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                    title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    &nbsp;
                                    <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                    (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                    <i class="fa fa-refresh"></i></button>
  
                                    <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                      >
                                    <i class="fa fa-refresh"></i></button>
  
                                    &nbsp;
  
                                    <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                    title="Click to Deactivate Dealer"
                                    (click)="DeactivateDealer(Dealerslistdata.id);"
                                      >
                                      <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                      </i></button>
        
        
                                      <button  *ngIf="Dealerslistdata.active!='Active'"
                                      class="btn bg-gradient-success btn-sm ml-1"
                                      title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                      > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                      </i></button>
                                    </td>

                              </tr>

                              <tbody *ngIf="DealerFilterValue=='In-Active'">
                                <tr   *ngFor="let Dealerslistdata of Dealerslist | filter:searchText ; let i = index; let odd = odd">
  
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerName }}</td>

                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerName }}</td>
  
                                  <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.groupName }}</td>
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.groupName }}</td>
                                
                                  <td *ngIf="Dealerslistdata.active=='Active'">{{ Dealerslistdata.dealerContactName }}</td>
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'">{{ Dealerslistdata.dealerContactName }}</td>
  
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerContactNumber }}</td>
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerContactNumber }}</td>
  
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.dealerContactEmail }}</td>
  
  
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.dealerContactEmail }}</td>
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.postalCode }}</td>
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.postalCode }}</td>
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.representative }}</td>
                                  <td style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.representative }}</td>
                                  <td *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                  <td style="background-color: #454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.globalCodeName }}</td>
                                  <!-- <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeStart | date }}</td>
                                  <td style="min-width: 90px;" style="background-color:#454d55;" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeStart | date }}</td>
  
                                  <td style="min-width: 90px;" *ngIf="Dealerslistdata.active=='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>
                                  <td style="min-width: 90px;" style="background-color:#454d55" *ngIf="Dealerslistdata.active!='Active'"> {{ Dealerslistdata.activeUntil | date }}</td>
  
                                  <td *ngIf="Dealerslistdata.leftdays>7 ">{{Dealerslistdata.leftdays}}</td>
                                  <td *ngIf="Dealerslistdata.leftdays<=7 && Dealerslistdata.leftdays<2 && Dealerslistdata.leftdays!=0" style="background: yellow;">{{Dealerslistdata.leftdays}}</td>
                                  <td *ngIf="Dealerslistdata.leftdays==0" style="background:lightcoral;">{{Dealerslistdata.leftdays}}</td>
                                  <td *ngIf="Dealerslistdata.leftdays==2" style="background:#454d55;">{{Dealerslistdata.leftdays}}</td> -->
                                 
                                 
                                  <td style="text-align:center;" *ngIf="Dealerslistdata.active=='Active'">
                                    <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                    (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                    <i class="fa-solid fa-pen-to-square"></i></button>
                                    <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                    (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                    &nbsp;
                                    <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                    id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    <button *ngIf="Dealerslistdata.activated==='true'"
                                    (click)="openActivateModel(ActivateModelPopUp,
                                    Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                    title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                      <i class="far fa-check-circle"></i>
                                    </button>
  
                                    &nbsp;
                                    <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                    (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                    <i class="fa fa-refresh"></i></button>
  
                                    <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                      >
                                    <i class="fa fa-refresh"></i></button>
  
                                    &nbsp;
  
                                    <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                    title="Click to Deactivate Dealer"
                                    (click)="DeactivateDealer(Dealerslistdata.id);"
                                      >
                                      <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                      </i></button>
        
        
                                      <button  *ngIf="Dealerslistdata.active!='Active'"
                                      class="btn bg-gradient-success btn-sm ml-1"
                                      title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                      > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                      </i></button>
                                    </td>
  
  
                                    <td style="text-align:center;background-color:#454d55;"  *ngIf="Dealerslistdata.active!='Active'">
                                      <button class="btn bg-gradient-info btn-sm"  title="Edit"
                                      (click)="openAddModel(ModelAdd,Dealerslistdata.id)" >
                                      <i class="fa-solid fa-pen-to-square"></i></button>
                                      <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                                      (click)="openModal(template,Dealerslistdata.id)"><i class="fa fa-trash"></i></button>
                                      &nbsp;
                                      <button *ngIf="Dealerslistdata.activated==='false'" title="Include Dealer to JD Power"
                                      (click)="openActivateModel(ActivateModelPopUp,
                                      Dealerslistdata.dealerName,Dealerslistdata.id,1)"
                                      id="ActivateDealers" class="btn btn-sm bg-gradient-danger">
                                        <i class="far fa-check-circle"></i>
                                      </button>
    
                                      <button *ngIf="Dealerslistdata.activated==='true'"
                                      (click)="openActivateModel(ActivateModelPopUp,
                                      Dealerslistdata.dealerName,Dealerslistdata.id,2)"
                                      title="Already Included to JD Power"  id="ActivateDealers" class="btn btn-sm bg-gradient-success">
                                        <i class="far fa-check-circle"></i>
                                      </button>
    
                                      &nbsp;
                                      <button  *ngIf="Dealerslistdata.activated==='true'"class="btn bg-info btn-sm"  title=" Sync Inventory of Dealer"
                                      (click)="openModalSychedModel(templateSynced,Dealerslistdata)"  >
                                      <i class="fa fa-refresh"></i></button>
    
                                      <button  *ngIf="Dealerslistdata.activated==='false'"class="btn bg-danger btn-sm"  title="This Dealer is Not in Aithr App for now"
                                        >
                                      <i class="fa fa-refresh"></i></button>
    
                                      &nbsp;
    
                                      <button  *ngIf="Dealerslistdata.active=='Active'" class="btn bg-gradient-danger btn-sm ml-1"
                                      title="Click to Deactivate Dealer"
                                      (click)="DeactivateDealer(Dealerslistdata.id);"
                                        >
                                        <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                                        </i></button>
          
          
                                        <button  *ngIf="Dealerslistdata.active!='Active'"
                                        class="btn bg-gradient-success btn-sm ml-1"
                                        title="Click to Activate Dealer"  (click)="AcivatededDealer(Dealerslistdata.id);"
                                        > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                                        </i></button>
                                      </td>
  
                                </tr>

                            </tbody>
                        </table>

                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                          <p style="color: white"> Loading... </p>
                      </ngx-spinner>

                        <br>

                        <div class="row justify-content-between align-items-center">
                          <div class="col-12 col-sm-auto">
                            <!-- <ngb-pagination [collectionSize]="Dealerslist.length" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination> -->
                            <ngb-pagination [collectionSize]="collectionSize" [(page)]="page" [pageSize]="pageSize" (pageChange)="getPremiumData();" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true">
                            </ngb-pagination>
                          </div>
                          <div class="col-12 col-sm-auto">
                            <div class="form-group mb-0">
                              <label style="padding:2px;"> Items per Page:</label>
                              <select [(ngModel)]="pageSize" (ngModelChange)="getPremiumData();" [ngModelOptions]="{standalone: true}">
                                <option *ngFor="let size of pageSizes" [ngValue]="size">
                                  {{ size }}
                                </option>
                              </select>
                            </div>
                          </div>

<!--
                          <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                            <div class="form-group mb-0 mr-1">
                              <label style="padding:2px;"> Items per Page:</label>
                              <select (change)="handlePageSizeChange($event)">
                                  <option *ngFor="let size of pageSizes" [ngValue]="size">
                                      {{ size }}
                                  </option>
                              </select>
                            </div>
                          </div> -->

                      </div>
                    </div>
                  </div>

                  <a #ConfirmBoxActivateUser style="display: none;" [routerLink]="" (click)="openModalActivate(templateActivateUser)"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="activate Dealer"
                    placement="top" ngbPopover="Are you sure you want to activate Dealer?"></i></a>
                <ng-template #templateActivateUser>
                  <div class="modal-body text-center">
                    <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                    <p>Are you sure you want to activate Dealer?</p>
                    <p></p>
                    <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmDeactivate()">Yes</button>
                    <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineActivateDealer()">No</button>
                  </div>
                </ng-template>

                  <a #ConfirmBoxActivate style="display: none;" [routerLink]="" (click)="openModalActivate(templateActivate)"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Deactivate Dealer"
                    placement="top" ngbPopover="Are you sure you want to deactivate Dealer?"></i></a>
                <ng-template #templateActivate>
                  <div class="modal-body text-center">
                    <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                    <p>Are you sure you want to deactivate Dealer?</p>
                    <p></p>
                    <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmDeactivate()">Yes</button>
                    <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineActivateDealer()">No</button>
                  </div>
                </ng-template>

                <a #ConfirmBox  style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                    placement="top" ngbPopover="Are you sure you want to delete?"></i>
                </a>
                  <ng-template #template>
                    <div class="modal-body text-center" >
                      <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                      <p>Are you sure you want to delete?</p>
                      <p></p>
                      <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()" >Yes</button>
                      <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()" >No</button>
                    </div>
                  </ng-template>

                  <a #ConfirmBoxSynced  style="display: none;" [routerLink]="" (click)="openModalSychedModel(templateSynced,'')"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Synced  Inventory"
                    placement="top" ngbPopover="Are you sure you want to manually update this dealer inventory?"></i>
                </a>
                  <ng-template #templateSynced>
                    <div class="modal-body text-center" >
                      <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                      <p>Are you sure you want to manually update this dealer inventory?</p>
                      <p></p>
                      <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmSynced()" >Yes</button>
                      <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineSynced()" >No</button>
                    </div>
                  </ng-template>


                  <!-- --------------------- Add Dealers popup start------------------------ -->

                  <ng-template #ModelAdd let-modal>
                    <div class="modal-header" style="background-color: black;">

                        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">{{userpopuplabel}}</h5>

                        <!-- <select  id="advancesetting" *ngIf="rolevalue==='SuperAdmin'"  #ddlAdvanceSetting class="form-control ng-untouched ng-pristine ng-invalid ml-2" style="max-width: 200px;"
                          (change)="onChangedealerselect(AddDealerModelPopUp,ddlAdvanceSetting.value)">
                          <option default>Advance Setting</option>
                          <option>Lender</option>
                        </select> -->

                        <ul id="profiledetails" *ngIf="rolevalue==='SuperAdmin'" class="navbar-nav ml-3">
                          <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                              <i class="fas fa-cog"></i>
                              <span>Advance Setting</span>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">                           
                              <a class="dropdown-item"  (click)="onChangedealerselect(AddDealerModelPopUp,'Lender');"><i class="fa-user fas"></i>Lender</a>
                            </div>
                          </li>
                        </ul>

                        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>


                    </div>
                    <div class="modal-body">

             <section class="generate-report ">

                <div class="row">
                  <!-- Picture -->
                  <div class="form-group  col-md-6 col-lg-6">
                    <label for="picture">Representative </label>
                    <span style="color: #dc3545;">*</span>
                    <div class="custom-file">
                      <select  class="form-control" name="AssociatedUser"
                      [(ngModel)]="AssociatedUser" formControlName="AssociatedUser"
                      [ngClass]="{ 'is-invalid': submitted && f.AssociatedUser.errors }"
                        (change)="onChange($event.target.value)">
                        <option value="0" selected>Select Representative</option>
                        <option *ngFor="let Representativelists of Representativelist"
                        [value]="Representativelists.id">{{Representativelists.userName}}</option>
                        </select>
                    </div>

                  </div>

                     <!--  Group -->
                    <div class="form-group col-md-6 col-lg-6">
                      <label for="exampleFormControlSelect1">Group Name</label>
                      <ng-select #ddlGroupValue [items]="GroupLists" bindLabel="globalCodeName"
                      (change)="getValues(selectedGroupId)"
                      formControlName="groupidctrl"
                      placeholder="Select Group" appendTo="body" [multiple]="false"
                      [(ngModel)]="selectedGroupId">
                      </ng-select>
                      <span style="font-size:.875em;color:#dc3545;" *ngIf="groupNameValueVaild === 'False'">
                        Group Name is required
                    </span>
                    </div>

                  </div>

                  <div class="row">
                      <!--  Dealers -->
                      <div class="form-group col-md-6 col-lg-6">
                        <label for="">Dealer Name</label>
                        <span style="color: #dc3545;">*</span>
                        <input type="text" formControlName="dealersname" [(ngModel)]="dealersname"
                        class="form-control" placeholder="Dealer Name" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.dealersname.errors }"
                          />
                        <div *ngIf="submitted && f.dealersname.errors" class="invalid-feedback">
                          <div *ngIf="f.dealersname.errors.required">Dealer Name is required</div>
                      </div>
                    </div>

                    <!--  province -->
                    <div class="form-group col-md-6 col-lg-6">
                      <label for="">Province / Region</label>
                      <span style="color: #dc3545;">*</span>
                      <select  class="form-control" name="province"
                      [(ngModel)]="province" formControlName="province"
                      [ngClass]="{ 'is-invalid': submitted && f.province.errors }"
                        (change)="onChange($event.target.value)">
                        <option value="0" selected>Select Province</option>
                        <option *ngFor="let Provincelistdata of Provincelist"
                        [value]="Provincelistdata.globalCodeID">{{Provincelistdata.globalCodeName}}</option>
                        </select>
                        <!-- <span *ngIf="hasDropDownError" style="color: red;">Province is required</span> -->

                        <div *ngIf="submitted && f.province.errors" class="invalid-feedback">
                            <div *ngIf="f.province.errors.required">Province is required</div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="row">
                    <!--  address -->
                    <div class="form-group col-md-6 col-lg-6">
                      <label for="">Address</label>
                      <span style="color: #dc3545;">*</span>
                      <textarea type="text" formControlName="address"  rows="3" [(ngModel)]="address"
                      class="form-control" placeholder="Address" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                          ></textarea>
                          <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                          <div *ngIf="f.address.errors.required">Address is required</div>
                      </div>
                    </div>
                     <!--  city -->
                     <div class="col-md-6 col-lg-6">
                        <div class="form-group">
                          <label for="">City</label>
                          <span style="color: #dc3545;">*</span>
                            <input type="text" formControlName="city" [(ngModel)]="city"
                            class="form-control" placeholder="City" (keypress)="ValidTextBox($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }"
                                />
                                <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">City is required</div>
                            </div>
                        </div>

                          <!--  postalcode -->
                        <div class="form-group">
                          <label for="">Postal Code </label>
                          <span style="color: #dc3545;">*</span>
                          <input type="text" formControlName="postalcode" [(ngModel)]="postalcode" maxlength="6"
                          class="form-control" placeholder="Postal Code"
                          (keypress)="ValidPostalCodeTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }"
                              />

                              <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                              <div *ngIf="f.postalcode.errors.required">Postal Code is required</div>
                              <div *ngIf="f.postalcode.errors.minlength || f.postalcode.errors.maxlength">
                                Postal Code must be 6 digits
                              </div>
                          </div>
                        </div>
                      </div>

                  </div>
                  <hr/>
                  <div id="contactInfoblock" class="row mt-3 mb-3">
                    <!--  phonenumber -->
                    <!-- <div class="form-group col-md-6 col-lg-6">
                        <label for="">Phone Number</label>
                        <input type="text" formControlName="phonenumber" [preValue]="AddDealersForm.value.phonenumber"  [phoneControl]="AddDealersForm.controls['phonenumber']"  phoneMask maxlength="14" [(ngModel)]="phonenumber"
                        class="form-control" placeholder="Phone Number" (keypress)="keyPress($event)" [ngClass]="(AddDealersForm.controls['phonenumber'].invalid)? 'errmsg' : 'validmsg'"
                            />

                      </div> -->
                      <div class="col-md-6 col-lg-6">
                        <div class="card card-raised w-100">
                          <div class="card-header card-raised bg-dark text-white">
                            <b class="d-block">AithrHub Contact Information</b>
                          </div>

                          <div class="card-body">
                            <div class="form-group">
                              <label for="">AithrHub Contact Name</label>

                            <input type="text" formControlName="AithrhubContactName" [(ngModel)]="AithrhubContactName" class="form-control"
                              placeholder="Contact Name" (keypress)="ValidTextBox($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.AithrhubContactName.errors }" />
                            <div *ngIf="submitted && f.AithrhubContactName.errors" class="invalid-feedback">
                              <div *ngIf="f.AithrhubContactName.errors.required">Aithrhub Contact Name is required</div>
                            </div>
                            </div>
                            <div class="form-group">
                              <label for="">AithrHub Contact Number</label>
                              <span style="color: #dc3545;">*</span>
                              <input type="text" formControlName="phonenumber" [preValue]="AddDealersForm.value.phonenumber"
                                [phoneControl]="AddDealersForm.controls['phonenumber']" phoneMask [(ngModel)]="phonenumber" maxlength="14"
                                class="form-control" placeholder="Phone Number" (keypress)="keyPress($event)"
                                [ngClass]="{ 'errmsg': phoneNumberControl.dirty && phoneNumberControl.invalid, 'validmsg': phoneNumberControl.dirty && phoneNumberControl.valid }" />
                              <div *ngIf="phoneNumberControl.invalid && (phoneNumberControl.dirty || submitted)" class="error-message"
                                style="color: red;">
                                <div *ngIf="phoneNumberControl.errors.required">
                                  Phone Number is required
                                </div>
                                <div *ngIf="phoneNumberControl.errors.pattern">
                                  Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <label for="">AithrHub Contact Email</label>
                              <span style="color: #dc3545;">*</span>
                              <input type="email" formControlName="email" placeholder="Email" (keyup)="onKeyUpLower($event)"
                                class="form-control email" [(ngModel)]="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>


                      <!--  email -->
                    <div class="col-md-6 col-lg-6">
                      <div class="card card-raised w-100">
                        <div class="card-header card-raised bg-dark text-white">
                          <b class="d-block">Dealer Contact Information</b>
                        </div>
                        <div class="card-body">
                          <div class="form-group">
                            <label for="">Dealer Contact Name</label>
                            <span style="color: #dc3545;">*</span>
                            <input type="text" formControlName="DealerContactName" [(ngModel)]="DealerContactName" class="form-control"
                              placeholder="Dealer Name" (keypress)="ValidTextBox($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.DealerContactName.errors }" />
                            <div *ngIf="submitted && f.DealerContactName.errors" class="invalid-feedback">
                              <div *ngIf="f.DealerContactName.errors.required">Dealer Contact Name is required</div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="">Dealer Contact Number</label>
                            <span style="color: #dc3545;">*</span>
                            <input type="text" formControlName="DealerContactNumber" [preValue]="AddDealersForm.value.DealerContactNumber"
                                [phoneControl]="AddDealersForm.controls['DealerContactNumber']" phoneMask [(ngModel)]="DealerContactNumber" maxlength="14"
                                class="form-control" placeholder="Phone Number" (keypress)="keyPress($event)"
                                [ngClass]="{ 'errmsg': DealerphoneNumberControl.dirty && DealerphoneNumberControl.invalid, 'validmsg': DealerphoneNumberControl.dirty && DealerphoneNumberControl.valid }" />
                              <div *ngIf="DealerphoneNumberControl.invalid && (DealerphoneNumberControl.dirty || submitted)" class="error-message"
                                style="color: red;">
                                <div *ngIf="DealerphoneNumberControl.errors.required">
                                  Phone Number is required
                                </div>
                                <div *ngIf="DealerphoneNumberControl.errors.pattern">
                                  Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                                </div>
                              </div>
                          </div>
                          <div class="form-group">
                            <label for="">Dealer Contact Email</label>
                            <span style="color: #dc3545;">*</span>
                            <input type="email" formControlName="DealerContactEmail" placeholder="email" (keyup)="onKeyUpLowerDealerContactEmail($event)"
                                class="form-control email" [(ngModel)]="DealerContactEmail" [ngClass]="{ 'is-invalid': submitted && f.DealerContactEmail.errors }" />
                              <div *ngIf="submitted && f.DealerContactEmail.errors" class="invalid-feedback">
                                <div *ngIf="f.DealerContactEmail.errors.required">Email is required</div>
                                <div *ngIf="f.DealerContactEmail.errors.email">Email must be a valid email address</div>
                              </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="form-group">
                    <label>Email</label>
                    <input type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div> -->
                  <hr/>
                  <div class="row">
                    <!--  active on -->
                      <div class="form-group col-md-6 col-lg-4">

                        <label for="">Subscription</label>
                        <span style="color: #dc3545;">*</span>
                        <select  class="form-control" name="dealerssubscription"
                        [(ngModel)]="dealerssubscription" formControlName="dealerssubscription"
                        [ngClass]="{ 'is-invalid': submitted && f.dealerssubscription.errors }"
                        (change)="onChangeSubscription($event.target.value)">
                        <option value="0" selected>Select Subscription</option>
                          <option *ngFor="let subscriptionlistdata of subscriptionlist"
                           [value]="subscriptionlistdata.globalCodeID">{{subscriptionlistdata.globalCodeName}}</option>
                          </select>
                        <div *ngIf="submitted && f.dealerssubscription.errors" class="invalid-feedback">
                          <div *ngIf="f.dealerssubscription.errors.required">Subscription is required</div>
                      </div>




                    </div>

                      <!--  activeunity -->
                      <div class="form-group col-md-6 col-lg-4">

                        <label for="">Active On</label>
                      <input class="form-control" placeholder="yyyy-mm-dd"
                       (dateSelect)="onDateSelectactiveon($event)" formControlName="activeon"  type="datetime"
                      name="activeon" [(ngModel)]="activeon" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">



                      </div>

                      <!--  subscription -->
                      <div class="form-group col-md-6 col-lg-4">

                        <label for="">Active Until</label>
                        <input class="form-control" placeholder="yyyy-mm-dd"
                        (dateSelect)="onDateSelectactiveunity($event)" formControlName="activeunity" type="datetime"
                        name="activeunity" [(ngModel)]="activeunity" ngbDatepicker #dt="ngbDatepicker" (click)="dt.toggle()">

                      </div>
                </div>
                  <!-- <div class="row">

                        <div class="form-group col-md-6 col-lg-4">
                          <label for="">Active On</label>
                        <input class="form-control" placeholder="yyyy-mm-dd"
                         (dateSelect)="onDateSelectactiveon($event)" formControlName="activeon"  type="datetime"
                        name="activeon" [(ngModel)]="activeon" ngbDatepicker #d="ngbDatepicker" (click)="d.toggle()">

                      </div>


                        <div class="form-group col-md-6 col-lg-4">
                          <label for="">Active Until</label>
                          <input class="form-control" placeholder="yyyy-mm-dd"
                          (dateSelect)="onDateSelectactiveunity($event)" formControlName="activeunity" type="datetime"
                          name="activeunity" [(ngModel)]="activeunity" ngbDatepicker #dt="ngbDatepicker" (click)="dt.toggle()">

                        </div>


                        <div class="form-group col-md-6 col-lg-4">
                          <label for="">Subscription</label>
                          <select  class="form-control" name="dealerssubscription"
                          [(ngModel)]="dealerssubscription" formControlName="dealerssubscription"
                          [ngClass]="{ 'is-invalid': submitted && f.dealerssubscription.errors }"
                          (change)="onChange($event.target.value)">
                          <option value="0" selected>Select Subscription</option>
                            <option *ngFor="let subscriptionlistdata of subscriptionlist"
                             [value]="subscriptionlistdata.globalCodeID">{{subscriptionlistdata.globalCodeName}}</option>
                            </select>
                          <div *ngIf="submitted && f.dealerssubscription.errors" class="invalid-feedback">
                            <div *ngIf="f.dealerssubscription.errors.required">Subscription is required</div>
                        </div>

                        </div>
                  </div> -->











                    </section>


                    </div>
                    <div class="modal-footer">
                      <div class="col-12">
                        <div class="row justify-content-between align-items-center">
                            <div class="form-group pl-3 mb-3 mb-lg-0 row">
                              <label for="picture" class="col-sm-auto col-form-label">Logo</label>
                              <div class="custom-file col-sm-9">
                                <input type="file" class="custom-file-input" id="customFile" (change)="onFileChanged($event)"
                                  accept=".jpg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx">
                                <label class="custom-file-label" #labelImport id="choosefile" for="customFile">Choose file</label>
                              </div>
                            </div>
                            <!-- Global Warranty Clients  -->
                            <div class="form-check col-auto">
                              <input type="checkbox" id="globalwarrantycheckCheck1" formControlName="globalwarrantycheck" [(ngModel)]="globalwarrantycheck"
                                class="form-check-input" />
                              <label class="form-check-label" for="globalwarrantycheckCheck1">Global Warranty Clients</label>
                            </div>

                            <!-- //check box  subscription -->
                            <div class="form-check col-auto">
                              <input type="checkbox" id="exampleCheck1" formControlName="inventorycheck" [(ngModel)]="inventorycheck"
                                class="form-check-input" (keypress)="ValidTextBox($event)" />
                              <label class="form-check-label pl-1" for="exampleCheck1">AithrHub Inventory?</label>
                            </div>

                            <div class="form-check col-auto ml-sm-auto">
                              <input type="checkbox" id="IncludeInventorySourcecheckCheck1" formControlName="IncludeInventorySourcecheck" [(ngModel)]="IncludeInventorySourcecheck"
                                class="form-check-input" />
                              <label class="form-check-label" for="IncludeInventorySourcecheckCheck1">Include as Inventory Source</label>
                            </div>
                        </div>
                        <hr/>
                        <div class="row justify-content-between align-items-center mx-0" id="IsAithrhubcheckbox">
                          <div class="form-group col-md-6 col-lg-4">
                            <div class="row justify-content-between align-items-center">
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrhub" [(ngModel)]="IsAithrhubng" formControlName="IsAithrhubng"
                                  class="form-check-input" [(checked)]="IsAithrhubng" (change)="onAithrUsers($event,1)" />
                                <label class="form-check-label pl-1" for="exampleCheck1">IsAithrhub </label>
                              </div>
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrx" [(ngModel)]="IsAithrxng" formControlName="IsAithrxng"
                                  class="form-check-input" [(checked)]="IsAithrxng" (change)="onAithrUsers($event,2)" />
                                <label class="form-check-label pl-1" for="exampleCheck1">IsAithrx </label>
                              </div>
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrMarketPlace" formControlName="IsAithrMarketPlaceng"
                                  [(ngModel)]="IsAithrMarketPlaceng" class="form-check-input" [(checked)]="IsAithrMarketPlaceng"
                                  (change)="onAithrUsers($event,3)" />
                                <label class="form-check-label pl-1" for="exampleCheck1">IsAithrMarketPlace </label>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-6 col-lg-4">

                            <label for="">Generated Token</label>
                          <input class="form-control"
                           type="text"
                           [(ngModel)]="GeneratedTokenResponse" id="TokenIn" readonly>
                          </div>


                          <div class="col-md-6 col-lg-4">

                           <label for="">GW Vendor Key</label>
                    
                           <input type="text" formControlName="GlobalVendorKeyValue"
                            [(ngModel)]="GlobalVendorKeyValue" class="form-control"
                           placeholder="GW Vendor Key" />

                          </div>
                        </div>

                        <div class="row">

                          <div class="col-md-12 col-12 mt-2 mt-md-0">
                            <hr my-3>
                            <div class="row">
                              <div class="col text-right">
                                <button type="button" class="btn btn-warning btn-sm mr-2 rounded-pill" (click)="GenerateToken();">Generate Token</button>
                                <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                                  <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                                </button>
                                <button type="button" class="btn btn-danger btn-sm text-white  ml-2 rounded-pill" (click)="CloseAddmodel()">
                                  <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        </div>

                    </div>
                  </ng-template>


              </div>
          </div>
        </div>
    </div>
</div>
<!-- <app-footer id="secondfooter"></app-footer> -->

<ng-template #ActivateModelPopUp let-modal>
  <div class="modal-header" style="background-color: black;">
    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Activate Dealers</h5>
    <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
      aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <section class="generate-report">
      <div class="activanote" *ngIf="ActivatedDetail==='true'">
        <b class="mr-1">Note:</b>This Dealer is already activated
      </div>
      <div class="form-group">
          <input type="text"
          [(ngModel)]="DealerIdValue"   formControlName="DealerId"  class="form-control"
            placeholder="Dealer Id" (keypress)="ValidTextBox($event)"
            [ngClass]="{ 'is-invalid': submitted && f.DealerId.errors }" />
          <div *ngIf="submitted && f.DealerId.errors" class="invalid-feedback">
            <div *ngIf="f.DealerId.errors.required">Dealer Id is required</div>
          </div>
        </div>

        <div class="form-group">
          <input type="text" formControlName="DealerName"
          [(ngModel)]="DealerNameValue" readonly
           class="form-control"
            placeholder="Dealer Name" (keypress)="ValidTextBox($event)"
            [ngClass]="{ 'is-invalid': submitted && f.DealerName.errors }" />
          <div *ngIf="submitted && f.DealerName.errors" class="invalid-feedback">
            <div *ngIf="f.DealerName.errors.required">Dealer Name is required</div>
          </div>
        </div>

    </section>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="ActivateDealer();">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Activate
    </button>

    <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="closeEditModel();">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
    </button>


  </div>
</ng-template>
<ng-template #AddDealerModelPopUp let-modal>
  <div class="modal-header" style="background-color: black;">
    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Assign- Lenders</h5>
    <button type="button" class="close" style="color: white;" #closebutton (click)="closeadvancesettingpopup()"
      aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">

    <section class="col-12">
      <div class="col-12 px-0 mb-2">
        <input type="checkbox" name="chckboxall" (click)="CheckallLenders($event)" id="checkall"
        [checked]="isCheckedlender"  />
        
        <label class="ml-1">
            <h6 class="mb-0" style="font-size:14px;"></h6>Select All
        </label>
    </div>
      <div class="row">
        <div class="form-check col-12 col-md-4" *ngFor="let item of lenderlistsFilter">
          <input type="checkbox" [checked]="item.checked" (change)="onCheckboxChange(item)" /> {{ item.lenderName }}
        </div>
      </div>
    </section>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="saveadvancestting();">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Assign
    </button>

    <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="closeadvancesettingpopup();">
      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
    </button>


  </div>
</ng-template>





</form>

</section>





