import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

@Component({
  selector: 'app-customer-warrantykey',
  templateUrl: './customer-warrantykey.component.html',
  styleUrls: ['./customer-warrantykey.component.less']
})
export class CustomerWarrantykeyComponent implements OnInit {
  customerWarrantyForm: FormGroup;
  constructor(private fb: FormBuilder,private manageInventoryService: ManageInventoryService,) { }
  
  OrderDetailList:[];
  keylistList:[];
  warrantykeylistList:[];
  VendorKeyDetails:[];
  WarrantyKeyDetails:[];
  searchTextBought;
  pagebought = 1;
  pageSizebought = 10;
  TotalRecord:any;
  showlist:any;
  KeyNameFilter:any;
  showWarrantylist:any;
 showVendorKeylist:any;
 showWarrantyKeylist:any;
  ngOnInit(): void {
    this.Createform();
    this.showlist="false";
    this.showWarrantylist="false";
    this.showVendorKeylist="false";
    this.showWarrantyKeylist="false";
  }

  GetDetail()
  {
    this.showlist="false";
    this.showWarrantylist="false";
   
    if(this.KeyNameFilter=="VenderKey")
    {
      this.GetKeyList();
    }else if(this.KeyNameFilter=="WarrantyKey")
    {
      this.GetWarrantyKeyList();
    }
  }



  

  GetKeyList() {
    debugger;
    var modelGroup = {
      "KeyType": this.KeyNameFilter
    }
    this.manageInventoryService.getKeyDetails(modelGroup)
      .subscribe(
        (data: any) => {
          this.showlist="true";
          this.showWarrantylist="false";
          this.showVendorKeylist="false";
         this.keylistList = data;
         this.TotalRecord=data.length;
        },
        error => {
        });
  }

  GetVednorKeyListById(KeyName:any) {
    debugger;
    var modelGroup = {
      "KeyName": KeyName
    }
    this.manageInventoryService.getVednorKeyListById(modelGroup)
      .subscribe(
        (data: any) => {
          this.VendorKeyDetails = data;
        },
        error => {
        });
  }

  GetWarrantyKeyListById(KeyName:any) {
    debugger;
    var modelGroup = {
      "KeyName": KeyName
    }
    this.manageInventoryService.getWarrantyKeyDetailbyIds(modelGroup)
      .subscribe(
        (data: any) => {
          this.WarrantyKeyDetails = data;
        },
        error => {
        });
  }
  
  ViewVendorDetail(kayName:any)
  {
     this.showVendorKeylist="true";
     this.GetVednorKeyListById(kayName);
  }
  ViewwarrantyDetail(kayName:any)
  {
    this.showWarrantyKeylist="true";
    this.GetWarrantyKeyListById(kayName);
  }
  GetWarrantyKeyList() {
    debugger;
    var modelGroup = {
      "KeyType": this.KeyNameFilter
    }
    this.manageInventoryService.getWarrantyKeyListDetail(modelGroup)
      .subscribe(
        (data: any) => {
          this.showlist="false";
          this.showWarrantylist="true";
         this.warrantykeylistList = data;
         this.showWarrantyKeylist="false";
         this.TotalRecord=data.length;
        },
        error => {
        });
  }


  Createform() {
    this.customerWarrantyForm = new FormGroup({
      KeyNameFilter: this.fb.control(''),
    });
  }
}
