import { Injectable } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { first } from 'rxjs/operators';
import { Account } from '@app/_models';
import { Login } from '../_models/Login';
import { country } from '../_models/country';
import { state } from '../_models/state';
import { city } from '../_models/city';
const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({ providedIn: 'root' })
export class AccountService {

    private accountSubject: BehaviorSubject<Account>;
    private loginSubject: BehaviorSubject<Login>;
    public account: Observable<Account>;
    public logins: Observable<Login>;
    constructor(
        private router: Router,
        private http: HttpClient
    ) {
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();

        this.loginSubject = new BehaviorSubject<Login>(null);
        this.logins = this.loginSubject.asObservable();

        router.events.subscribe((val) => {

            if (val instanceof ActivationEnd) {
                //console.log('componentName', val.snapshot.component['name']);
                var pagename = val.snapshot.component['name'];
                localStorage.setItem("pagename", pagename)
            }
        });

        this.SaveActivityLog("");
        this.CheckIFUserIsActive("");

    }

    getIPAddress() {
        return this.http.get("http://api.ipify.org/?format=json");
    }

    // Define Variables for API call
    ColorTheme: any;
    private baseUrl_GetUserListDealerName: string = environment.apiUrl + '/Common/GetUserListDealerName';
    private baseUrl_GetAithrAccountManagerDetail: string = environment.apiUrl + '/GlobalCodeCategory/GetAithrAccountManagerDetail';
    private  baseUrl_GetAithrXUpdates: string = environment.apiUrl + '/GlobalCodeCategory/GetAithrXUpdates';
    private baseUrlGetUserList: string = environment.apiUrl + '/Common/Getuserlist';
    private baseUrlRegister: string = environment.apiUrl + '/Account/RegisterWithoutmailconfirmation';
    private baseUrlGetCountryList: string = environment.apiUrl + '/Common/GetCountries';
    private baseUrlGetStateList: string = environment.apiUrl + '/Common/GetStateListByCountryIds';
    private baseUrlGetCityList: string = environment.apiUrl + '/Common/GetCityListByCountryIds';
    private baseUrlGetUserDetailById: string = environment.apiUrl + '/Common/GetUserDetailById';
    private baseUrl_Forgotpassword: string = environment.apiUrl + '/Account/ForgotPassword';
    private baseUrl_Resetpassword: string = environment.apiUrl + '/Account/ResetPassword';
    private baseUrl_Login: string = environment.apiUrl + '/Account/Login';
    private baseUrlEditUserDetail: string = environment.apiUrl + '/Admin/GetUsersById';
    private baseUrl_updateuser: string = environment.apiUrl + '/Admin/UpdateUser';
    private baseUrlLoginurl: string = environment.apiUrl + '/Users/authenticate';
    private baseUrlDeleteUser: string = environment.apiUrl + '/Common/DeleteUser';
    private baseUrlDeleteUsers: string = environment.apiUrl + '/Admin/DeleteUser/';
    // private baseUrlDeactivateUsers: string = environment.apiUrl + '/Admin/DeactivateUsers/';

    // private baseUrlActivateUsers: string = environment.apiUrl + '/Admin/ActivateUsers/';


    private baseUrl_saveadduser: string = environment.apiUrl + '/Admin/AddUser';
    private baseUrl_Resetpasswords: string = environment.apiUrl + '/Admin/Resetpassword';
    private baseUrl_uploadUserPhoto: string = environment.apiUrl + '/Admin/UploadUserPhoto';
    
    private baseUpdateaggrementuser: string = environment.apiUrl + '/Users/Updateaggrementforuser/';
    private baseUrl_SaveActivityLog: string = environment.apiUrl + '/Dashboard/SaveActivityLog';
    private baseUrl_checkingUserIfActive: string = environment.apiUrl + '/Dashboard/LoggingCheck';
    
    // functions for getting User for edit information
    private baseUrlActivateUsers: string = environment.apiUrl + '/Admin/ActivateUsers';
    private baseUrlDeactivateUsers: string = environment.apiUrl + '/Admin/DeactivateUsers';

    private baseUrlstatusDealer: string = environment.apiUrl + '/Admin/ChangeDealerStatus';
    
    editaccountsetting(formdata) {
        return this.http.post<any[]>(this.baseUrlEditUserDetail, formdata);
    }
    editUserDetail(formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',

        });
        return this.http.post(this.baseUrlEditUserDetail, formdata);
    }
    updateuser(formdata) {
        return this.http.post<any[]>(this.baseUrl_updateuser, formdata);
    }
    //calling function saving method
    Saveadduser(formdata) {
        return this.http.post<any[]>(this.baseUrl_saveadduser, formdata);
    }

    Resetpassword(formdata){
        return this.http.post<any[]>(this.baseUrl_Resetpasswords, formdata);
    }

    uploadUserPhoto(formdata){
        return this.http.post<any[]>(this.baseUrl_uploadUserPhoto, formdata);
    }

    DeleteUser(formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',

        });
        return this.http.post(this.baseUrlDeleteUser, formdata);
    }
    deleteUsers(email: any) {
        var URL = this.baseUrlDeleteUsers + email;
        return this.http.get(URL);
    }

    // deactivateUsers(email: any) {
    //     var URL = this.baseUrlDeactivateUsers + email;
    //     return this.http.get(URL);
    // }

    deactivateUsers(formdata) {
        return this.http.post<any[]>(this.baseUrlDeactivateUsers, formdata);
      }

      statusDealer(formdata) {
        return this.http.post<any[]>(this.baseUrlstatusDealer, formdata);
      }


    // activateUsers(email: any) {
    //     var URL = this.baseUrlActivateUsers + email;
    //     return this.http.get(URL);
    // }

    activateUsers(formdata) {
        return this.http.post<any[]>(this.baseUrlActivateUsers, formdata);
      }

    getUserDetailById(formdata) {
        return this.http.post<Account[]>(this.baseUrlGetUserDetailById, formdata);
    }

    // Working regitser method
    Register(formdata) {
        return this.http.post(this.baseUrlRegister, formdata);
    }

    //regsiter users
    Registerpost(formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
        });
        const requestOptions = { headers: headers };
        return this.http.post(this.baseUrlRegister, formdata);
    }

    //Forgot passowrd
    ForgotPasswordPost(formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',

        });
        const requestOptions = { headers: headers };
        return this.http.post(this.baseUrl_Forgotpassword, formdata, requestOptions);
    }




    //actibity logs method

    GetIpAddress()
    {  var Ipaddress="";
      this.getIPAddress().subscribe((res:any)=>{
        //console.log(res.ip);
        localStorage.setItem('IpAddresstoTrack', res.ip);
        Ipaddress=res.ip;
      });
      return Ipaddress;
    }



    SaveActivityLog(IpAddressValue: any) {
        var modelGroup = {
            "UserID": localStorage.getItem('userid'),
            "BrowserInfo": this.getBrowserName(),
            "SessionID": "",
            "IPAddress": localStorage.getItem('IpAddresstoTrack'),// Ipaddress "111.93.121.106",//this.ipAddress,
            "ActivityType": 1,
            "ActivityLink": localStorage.getItem('pagename'),
            "Comments": "User logged in.",
        }

        this.saveActivityLog_url(modelGroup)
            .subscribe(
                (data: any) => {

                },
                error => {
                });

                var currentUrl=window.location.href;

              var isinclude=  currentUrl.includes('#');
              if(isinclude==false)
              {
                currentUrl=currentUrl+'#/'
              }

                var urlpath=currentUrl.split('#')[1].trim();


                if(modelGroup.UserID!=null && urlpath=="/" )
                {
                    this.router.navigateByUrl("/welcome-dasboard");
                }
    }


    CheckIFUserIsActive(IpAddressValue: any) {
     
        var modelGroup = {
            "UserID": localStorage.getItem('userid'),
        }

        this.checkingUserIfActive(modelGroup)
            .subscribe(
                (data: any) => {
                    debugger;
                    if (data[0].active == false) {
                       // this.router.navigate(['/login']);
                       this.logout();
                       // localStorage.remove('userid');
                      //  localStorage.removeItem('userid')
                       // this.router.navigateByUrl("/login");
                    } else
                    {
                        
                    }

                },
                error => {
                });

                var currentUrl=window.location.href;

            //   var isinclude=  currentUrl.includes('#');
            //   if(isinclude==false)
            //   {
            //     currentUrl=currentUrl+'#/'
            //   }

            //     var urlpath=currentUrl.split('#')[1].trim();


            //     if(modelGroup.UserID!=null && urlpath=="/" )
            //     {
            //         this.router.navigateByUrl("/welcome-dasboard");
            //     }
    }



    getBrowserName() {
        const agent = window.navigator.userAgent.toLowerCase()
        switch (true) {
            case agent.indexOf('edge') > -1:
                return 'edge';
            case agent.indexOf('opr') > -1 && !!(<any>window).opr:
                return 'opera';
            case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
                return 'chrome';
            case agent.indexOf('trident') > -1:
                return 'ie';
            case agent.indexOf('firefox') > -1:
                return 'firefox';
            case agent.indexOf('safari') > -1:
                return 'safari';
            default:
                return 'other';
        }
    }

    //Reset passowrd
    ResetPassword(formdata) {
        const headers = new HttpHeaders({
            'Content-Type': 'application/json; charset=utf-8',

        });
        const requestOptions = { headers: headers };
        return this.http.post(this.baseUrl_Resetpassword, formdata, requestOptions);
    }

    public get accountValue(): Account {
        return this.accountSubject.value;
    }

    public get loginValue(): Login {
        return this.loginSubject.value;
    }



    //       //update aggrement document
    AggrementDocument(formdata) {
        var returndata: any;
        this.http.post<any>(this.baseUpdateaggrementuser, formdata)
            .pipe(first())
            .subscribe(data => {
                if (data.istermaccepted == true) {
                    this.accountSubject.next(data);

                }
            });
        return returndata;
    }

    //update aggrement document
    After_AggrementDocument_redirect(formdata) {
        return this.http.post<any>(this.baseUpdateaggrementuser, formdata);
    }


    Localstorage(data) {
        debugger;
        localStorage.setItem('userdetails', JSON.stringify(data.userdata));
        localStorage.setItem("jwt", data.jwtToken);
       // localStorage.setItem("NotificationCount", data.notificationCount);
       localStorage.setItem('isGlobalWarrantyClients', data.isGlobalWarrantyClients);

       localStorage.setItem('DealerKey', data.dealerKey);

        localStorage.setItem('AuthToken', data.jwtToken);
        localStorage.setItem('UserRole', data.role);
        localStorage.setItem('UserName', data.userName);
        localStorage.setItem('email', data.email);
        localStorage.setItem('DealerId', data.dealerID);
        localStorage.setItem('roleid', data.roleid);
        localStorage.setItem('groupid', data.groupid);
        localStorage.setItem('userid', data.userid);
        localStorage.setItem('UserFullRole', data.userFullRole);
        localStorage.setItem('DealerName', data.dealerName);
        localStorage.setItem('GroupName', data.groupName);
        //localStorage.setItem('colorTheme', data.colorTheme);
        if (data.colorTheme == "Light Mode") {
            localStorage.setItem('colorTheme', "White");
        }else{
            localStorage.setItem('colorTheme', "Black");
        }
        localStorage.setItem('Province', data.province);
        localStorage.setItem('LoggedRegionName', data.regionName);

    }

    login(Email: string, password: string) {
        return this.http.post<any>(this.baseUrlLoginurl, { Email, password })
            .pipe(map(data => {
                debugger;
                this.Localstorage(data);
                if (data.istermaccepted == true) {
                    this.accountSubject.next(data);
                }
                return data;
            }));
    }

    setValueUserAdmin(UserName: string) {
        if (UserName == "Admin") {
            localStorage.setItem('UserType', 'true');
        } else if (UserName == "Dealer") {
            localStorage.setItem('UserType', 'false');
        }

    }

    // getUserList(formdata) {
    //     return this.http.post<Account[]>(this.baseUrlGetUserList, formdata);
    // }
    getUserList(): Observable<Account[]> {
        return this.http.get<Account[]>(this.baseUrlGetUserList);
    }

    getUserListDealerName(formdata) {
        return this.http.post<Account[]>(this.baseUrl_GetUserListDealerName, formdata);
    }


    // Get AithrX UPdate

    getAithrAccountManagerDetail(formdata) {

        return this.http.post<Account[]>(this.baseUrl_GetAithrAccountManagerDetail, formdata);
    }

    getAithrXUpdates(formdata) {

        return this.http.post<Account[]>(this.baseUrl_GetAithrXUpdates, formdata);
    }

    // functions for getting City , state,country
    getCountryList() {

        return this.http.get<country[]>(this.baseUrlGetCountryList);
    }

    getStateList(formdata) {

        return this.http.post<state[]>(this.baseUrlGetStateList, formdata);
    }

    getCityList(formdata) {
     ;
        return this.http.post<city[]>(this.baseUrlGetCityList, formdata);
    }

    logout() {
        var element = document.body;
        localStorage.removeItem("userid");
        localStorage.removeItem("pagename");

        localStorage.removeItem("AuthToken");
        localStorage.removeItem("AuthToken");
        localStorage.removeItem('userdetails')
       // localStorage.removeItem("NotificationCount");
        localStorage.removeItem('email');
      //  localStorage.removeItem('UserFullRole');
        localStorage.removeItem('DealerName');
        localStorage.removeItem('GroupName');
        localStorage.removeItem('DealerId');
        localStorage.removeItem('colorTheme');

        localStorage.removeItem('DealerKey');
        localStorage.removeItem('isGlobalWarrantyClients');
        localStorage.removeItem('colorTheme');

      

        // this.stopRefreshTokenTimer();
        this.accountSubject.next(null);
        const returnUrl = '/';
        this.router.navigateByUrl(returnUrl);
        //this.router.navigate(['/account/login']);
    }

    refreshToken() {
        return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
            .pipe(map((account) => {
                this.accountSubject.next(account);
                this.startRefreshTokenTimer();
                return account;
            }));
    }

    register(account: Account) {
        return this.http.post(`${baseUrl}/register`, account);
    }

    verifyEmail(token: string) {
        return this.http.post(`${baseUrl}/verify-email`, { token });
    }



    validateResetToken(token: string) {
        return this.http.post(`${baseUrl}/validate-reset-token`, { token });
    }

    resetPassword(token: string, password: string, confirmPassword: string) {
        return this.http.post(`${baseUrl}/reset-password`, { token, password, confirmPassword });
    }

    getAll() {
        return this.http.get<Account[]>(baseUrl);
    }

    getById(id: string) {
        return this.http.get<Account>(`${baseUrl}/${id}`);
    }

    create(params) {
        return this.http.post(baseUrl, params);
    }

    update(id, params) {
        return this.http.put(`${baseUrl}/${id}`, params)
            .pipe(map((account: any) => {
                // update the current account if it was updated
                if (account.id === this.accountValue.id) {
                    // publish updated account to subscribers
                    account = { ...this.accountValue, ...account };
                    this.accountSubject.next(account);
                }
                return account;
            }));
    }

    delete(id: string) {
        return this.http.delete(`${baseUrl}/${id}`)
            .pipe(finalize(() => {
                // auto logout if the logged in account was deleted
                if (id === this.accountValue.id)
                    this.logout();
            }));
    }

    // helper methods

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        // parse json object from base64 encoded jwt token
        // const jwtToken = JSON.parse(atob(this.account.token.split('.')[1]));

        // // set a timeout to refresh the token a minute before it expires
        // const expires = new Date(jwtToken.exp * 1000);
        // const timeout = expires.getTime() - Date.now() - (60 * 1000);
        // this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }
    // for Saving Tracking Details
    saveActivityLog_url(formdata) {
        return this.http.post<any[]>(this.baseUrl_SaveActivityLog, formdata);
    }

    checkingUserIfActive(formdata) {
        return this.http.post<any[]>(this.baseUrl_checkingUserIfActive, formdata);
    }



}
