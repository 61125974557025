<section id="admindashboard">
    <div class="container-fluid">
        <div class="page-header pb-3">
            <div class="row">
                <div class="col-lg-12">
                    <h3> New & Used Cars For Sale In Canada
                        <span class="carspage"><a href="#">Cars</a></span>
                    </h3>
                    <p class="mb-0">To get the most accurate and up-to-date information</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-raised h-100">
                    <!-- <div class="card-header card-raised bg-info text-white">
                        <h5 class="card-title text-white text-uppercase mb-0">List View of Vehicles</h5>
                    </div> -->
                    <div class="card-body p-3">
                        <div class="row mb-3">
                            <div id="searchbarblock" class="col-12">
                                <div class="row justify-content-between align-items-start">
                                    <div class="col-xl-6 col-lg-6 col-12">
                                        <div class="form-group has-search">
                                            <span class="fa fa-search form-control-feedback"></span>
                                            <input type="text" class="form-control"
                                                placeholder="Search Year, Make, Model, Trim, Or Listing ID">
                                        </div>
                                    </div>
                                    <div class="col-xl-6 col-lg-6 col-12 text-md-right mx-0">
                                        <div id="locationbtngroup" class="row align-items-center">
                                            <div id="Activatelocation"
                                                (click)="openActivatelocationModel(ActivatelocationModelPopUp)"
                                                class="location"><i class="fas fa-map-marker-alt"></i>Location</div>
                                            <div id="Activatefilters"
                                                (click)="openActivatefiltersModel(ActivatefiltersModelPopUp)"
                                                class="filters">Filters<i class="fas fa-sort-amount-up-alt"></i></div>
                                            <h6>135,645 <span>Vehicles</span></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div id="listofvechiles" class="col-12">
                                <div class="row">
                                    <!-- product coloum start -->
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <div
                                                    style="width:100%; height:100%; text-align:center;margin:0; padding:0;">
                                                    <ng-image-slider #nav [images]="vehicleSlideV1" [infinite]="false"
                                                        [autoSlide]="1" [imageSize]="{width: '100%', height:148}"
                                                        slideImage="1">
                                                    </ng-image-slider>
                                                </div>
                                                <!-- <img class="img-fluid" src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg" alt="product image"> -->
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Regina, Saskatchewan</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Toyota Camry-2023</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Woodward Motors Ltd St Anthony</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        25,100 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol + Gas
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        22,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$3,560</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- product coloum end -->
                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <div
                                                    style="width:100%; height:100%; text-align:center;margin:0; padding:0;">
                                                    <ng-image-slider #nav [images]="vehicleSlideV2" [infinite]="false"
                                                        [autoSlide]="1" [imageSize]="{width: '100%', height:148}"
                                                        slideImage="1">
                                                    </ng-image-slider>
                                                </div>
                                                <!-- <img class="img-fluid" src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-3.jpg" alt="product image"> -->
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Victoria, British Columbia</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Volkswagen Golf-2023</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Woodward Motors Ltd St Anthony</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        28,100 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol + Gas
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        22,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$5,560</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <div
                                                    style="width:100%; height:100%; text-align:center;margin:0; padding:0;">
                                                    <ng-image-slider #nav [images]="vehicleSlideV3" [infinite]="false"
                                                        [autoSlide]="1" [imageSize]="{width: '100%', height:148}"
                                                        slideImage="1">
                                                    </ng-image-slider>
                                                </div>
                                                <!-- <img class="img-fluid" src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-4.jpg" alt="product image"> -->
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Mazda CX-30-2021</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Lexus RX-2021</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Woodward Motors Ltd St Anthony</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        25,300 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        22,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$4,000</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <div
                                                    style="width:100%; height:100%; text-align:center;margin:0; padding:0;">
                                                    <ng-image-slider #nav [images]="vehicleSlideV4" [infinite]="false"
                                                        [autoSlide]="1" [imageSize]="{width: '100%', height:148}"
                                                        slideImage="1">
                                                    </ng-image-slider>
                                                </div>
                                                <!-- <img class="img-fluid" src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-5.jpg" alt="product image"> -->
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Calgary, Alberta</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Cadillac Escalade-2023</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Woodward Motors Ltd St Anthony</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        21,100 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol + Gas
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        20,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$4,600</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <img class="img-fluid"
                                                    src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-9.jpg"
                                                    alt="product image">
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Calgary, Alberta</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Cadillac Escalade-2023</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Woodward Motors Ltd St Anthony</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        21,100 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol + Gas
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        20,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$3,600</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-xl-3 col-lg-4 col-md-6 col-12">
                                        <div class="product-card">
                                            <div class="product-image">
                                                <img class="img-fluid"
                                                    src="https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-7.jpg"
                                                    alt="product image">
                                            </div>
                                            <div class="product-content">
                                                <div class="product-location">
                                                    <a href="#">
                                                        <i class="fas fa-map-marker-alt"></i>
                                                        <span>Calgary, Alberta</span>
                                                    </a>
                                                </div>
                                                <h6>
                                                    <a href="#">Cadillac Escalade-2023</a>
                                                </h6>
                                                <div class="verifiedblock">
                                                    <span class="verifiyImg"><img
                                                            src="../../assets/images/verifiy_img.png"
                                                            alt="Verifiy Dealer"></span>
                                                    <div class="verifiedtext">
                                                        <h6>Lakeview Chrysler Gander</h6>
                                                        <div class="dealerstamp">
                                                            <span class="checkverifiy">
                                                                <i class="fas fa-certificate"></i>
                                                                <i class="fas fa-check"></i>
                                                            </span>
                                                            Verified Dealer
                                                        </div>
                                                    </div>
                                                </div>
                                                <ul class="product-features">
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
                                                            alt="product icon">
                                                        21,100 miles
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
                                                            alt="product icon">
                                                        Petrol + Gas
                                                    </li>
                                                    <li>
                                                        <img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
                                                            alt="product icon">
                                                        20,231 cc
                                                    </li>
                                                </ul>
                                                <div class="product-price">
                                                    <button type="button" class="btn btn-primary">View Details</button>
                                                    <div class="price-area">
                                                        <span>Great Price</span>
                                                        <h6>$5,000</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-footer id="secondfooter"></app-footer>
</section>



<!-- Active loction popup start -->

<ng-template #ActivatelocationModelPopUp let-modal>
    <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Location Filters</h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <section class="generate-report">
            <div class="col-md-12 px-0">
                <form class="w-100">
                    <div class="form-group">
                        <ng-select id="locationflt" [items]="cities" bindLabel="name" placeholder="Select city"
                            [(ngModel)]="selectedCity">
                        </ng-select>
                        <div class="card w-100 mt-2">
                            <div class="card-body p-2">
                                <p class="mb-0 mt-1">City: {{selectedCity | json}}</p>
                            </div>
                        </div>
                        <hr />
                    </div>
                </form>
            </div>
        </section>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Apply Filters
        </button>

        <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
        </button>


    </div>
</ng-template>

<!-- Active loction popup end -->



<!-- Active Filters popup start -->

<ng-template #ActivatefiltersModelPopUp let-modal>
    <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Filters</h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">

        <section id="pricefilter" class="generate-report">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12">
                        <h6>Price Range</h6>
                    </div>
                </div>
                <div class="row">
                    <div class="form-group col-12">
                        <input type="range" style="box-shadow: none;" class="form-control" id="range" [(ngModel)]="selectedValue"
                            (input)="onInputChange()" />
                        <p class="mt-2 mb-0">Selected Price Range: {{ selectedValue }}</p>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Make</h6>
                    </div>
                    <ul class="makeoptn">
                        <li><a class="active" href="#">Acura</a></li>
                        <li><a href="#">AMC</a></li>
                        <li><a href="#">BMW</a></li>
                        <li><a href="#">Fiat</a></li>
                        <li><a href="#">Ford</a></li>
                        <li><a href="#">Buick</a></li>
                        <li><a href="#">Gesnesis</a></li>
                        <li><a href="#">Geo</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Body</h6>
                    </div>
                    <ul class="valuelist">
                        <li><a class="active" href="#">All</a></li>
                        <li><a href="#">Truck</a></li>
                        <li><a href="#">SUV</a></li>
                        <li><a href="#">Hatchback</a></li>
                        <li><a href="#">Sedan</a></li>
                        <li><a href="#">Crossover</a></li>
                        <li><a href="#">Coupe</a></li>
                        <li><a href="#">Convertible</a></li>
                        <li><a href="#">Minivan</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Year</h6>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-lg-6 col-md-12 col-12">
                                <label>Min</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="form-group col-lg-6 col-md-12 col-12">
                                <label>Max</label>
                                <input type="text" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Mileage</h6>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="form-group col-lg-6 col-md-12 col-12">
                                <label>Min</label>
                                <input type="text" class="form-control" />
                            </div>
                            <div class="form-group col-lg-6 col-md-12 col-12">
                                <label>Max</label>
                                <input type="text" class="form-control" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Fuel Type</h6>
                    </div>
                    <ul class="valuelist">
                        <li><a class="active" href="#">All</a></li>
                        <li><a href="#">Gas</a></li>
                        <li><a href="#">Electric</a></li>
                        <li><a href="#">Hybrid</a></li>
                        <li><a href="#">Diesel</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Drivertain</h6>
                    </div>
                    <ul class="valuelist">
                        <li><a class="active" href="#">All</a></li>
                        <li><a href="#">All Wheel Drive</a></li>
                        <li><a href="#">Front Wheel Drive</a></li>
                        <li><a href="#">Four Wheel Drive</a></li>
                        <li><a href="#">Rear Wheel Drive</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-md-12 mt-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Passenger Limit</h6>
                    </div>
                    <ul class="valuelist">
                        <li><a class="active" href="#">All</a></li>
                        <li><a href="#">1</a></li>
                        <li><a href="#">2</a></li>
                        <li><a href="#">3</a></li>
                        <li><a href="#">4</a></li>
                        <li><a href="#">5</a></li>
                    </ul>
                </div>
            </div>
        </section>
    </div>
    <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Apply Filters
        </button>

        <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
        </button>


    </div>
</ng-template>

<!-- Active Filters popup end -->