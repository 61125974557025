import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@app/_services';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { interval, Subscription } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';

import { Component, ViewChild, AfterViewInit } from '@angular/core';

import { DatapassService } from '@app/_services/datapass.service';

import { AppComponent } from "../app.component";
import { NotifierService } from 'angular-notifier';

import { HttpClient } from '@angular/common/http';
import { VideoMaster } from '../_models/VideoMaster';
import { Observable } from 'rxjs';
import { LenderService } from '@app/_services/lender.service';
@Component({
  selector: 'app-training-video',
  templateUrl: './training-video.component.html',
  styleUrls: ['./training-video.component.less']
})
export class TrainingVideoComponent implements OnInit {
  [x: string]: any;
  //readmore variable, its true than read more string will print
  supportTicket: boolean = true
  //hiding info box
  visible: boolean = false
  private notifier: NotifierService;
  WelcomeDashboradForm: FormGroup;

  title = 'fileupload';
  remark = '';

  @ViewChild(AppComponent) child;


  constructor(private accountService: AccountService, private router: Router,
    private manageInventoryService: ManageInventoryService, private fb: FormBuilder,
    private dataService: DatapassService,
    private httpService: HttpClient,
    private lenderService: LenderService,
    notifier: NotifierService
  ) { this.notifier = notifier; }
  myFiles: string[] = [];
  myVideos: Observable<VideoMaster[]>;
  @ViewChild('videoPlayer') videoplayer: ElementRef;

  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  FirstName: any;
  LastName: any;
  Email: any;
  UpdateList = [];
  UserNamevalue: any;
  UserIdvalue: any;
  LoggedRegionName: any;
  submitted = false;
  loading = false;
  isValid: boolean = true;
  groupInventoryCount: any;
  myInventoryCount: any;
  overDamagedCount: any;
  totalHubInventoryCount: any;
  unitsBuildCount: any;
  unitsMaximizedCount: any;

  TotalUserText: any;
  ApprovalSearchedText: any;
  HubInquiriesText: any;
  AccountdamagedText: any;
  DealsBuildText: any;
  HubverifiedText: any;

  myInventoryText: any;
  groupInventoryText: any;
  overDamagedText: any;
  totalHubInventoryText: any;
  unitsBuildText: any;
  unitsMaximizedText: any;

  //for account management section
  TotalUserCount: any = 0;
  ApprovalSearchedCount: any = 0;
  HubInquiriesCount: any = 0;
  AccountdamagedCount: any;
  DealsBuildCount: any = 0;
  HubverifiedCountCount: any = 0;


  DealershipName: any;
  requester: any;
  phone: any;
  Subject: any;
  Description: any;

  opcode: any;

  url;
  format;

  name = 'Angular';
  //@ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  toggleVideo_old(event: any) {
    this.videoplayer.nativeElement.play();
  }
  playPause() {
    var myVideo: any = document.getElementById('my_video_1');
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  makeBig() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 560;
  }

  makeSmall() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 320;
  }

  makeNormal() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 420;
  }

  skip(value) {
    let video: any = document.getElementById('my_video_1');
    video.currentTime += value;
  }

  restart() {
    let video: any = document.getElementById('my_video_1');
    video.currentTime = 0;
  }
  ColorTheme:any;
  ngOnInit(): void {

   // this.CreateDashboardFrom();


   this.ColorTheme = localStorage.getItem('colorTheme');
   var element = document.body;
   if (this.ColorTheme === "White") {
     element.classList.remove("dark-mode");
   } else {

     element.classList.add("dark-mode");
   }
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.DealershipName = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserNamevalue = localStorage.getItem('UserName');
    this.UserIdvalue = localStorage.getItem('userid');

    this.LoggedRegionName = localStorage.getItem('LoggedRegionName');



  }


  onSelectFile(event) {
    const file = event.target.files && event.target.files[0];

    this.SetFileDetails(event);

    if (file) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      if(file.type.indexOf('image')> -1){
        this.format = 'image';
      } else if(file.type.indexOf('video')> -1){
        this.format = 'video';
      }
      reader.onload = (event) => {
        this.url = (<FileReader>event.target).result;
      }
    }
  }


toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
}
getvideos() {
  debugger;
 //this.myVideos= this.VediosList();
}

VediosList()
{
  //return this.httpService.get<VideoMaster[]>('http://localhost:50401/api/FileUpload/Vedios');
}




sMsg: string = '';
  StudentIdUpdate: string;


  SetFileDetails(e) {
    //console.log (e.target.files);
    debugger;
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  getFileDetails(e) {
    //console.log (e.target.files);
    debugger;
    for (var i = 0; i < e.target.files.length; i++) {
      this.myFiles.push(e.target.files[i]);
    }
  }

  uploadFiles() {
    debugger  ;
    const formData = new FormData();
    for (var i = 0; i < this.myFiles.length; i++) {
      formData.append("fileUpload", this.myFiles[i]);
    }


    this.lenderService.UploadTrainingVideo(formData)
        .subscribe(
          (data: any) => {

          },
          error => {
          });


  }

}

