import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import { AccountService, AlertService } from '@app/_services';
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.less']
})

export class ForgotPasswordComponent implements OnInit {


 form: FormGroup;
  submitted = false;
  loading=false;
  sentmail=false;
  private notifier: NotifierService;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    notifier: NotifierService,
    private accountService: AccountService,


    )
   {
    this.notifier = notifier;
   }

   ngOnInit(): void {
    this.form = this.formBuilder.group(
      {

       // email: ['', [Validators.required, Validators.email]]
       email: ['', [Validators.required, Validators.email, this.trimValidator]]
      }
    );
  }
// Add the trimValidator function
// trimValidator(control: AbstractControl) {
//   const trimmedValue = control.value.trim(); // Trim the value
//   control.setValue(trimmedValue); // Update the value in the form control
//   return null; // Return null to indicate that the validation passed
// }
trimValidator(control: AbstractControl) {
  const trimmedValue = control.value.trim(); // Trim the value

  if (control.value !== trimmedValue) {
    control.setValue(trimmedValue); 
  }

  return null; 
}
onEmailInput(value: string): void {
  const trimmedValue = value.trim(); 
  this.form.controls['email'].setValue(trimmedValue); 
}


  // convenience getter for easy access to form fields
  get f(): { [key: string]: AbstractControl } {
    return this.form.controls;
  }

//   onSubmit(): void {
//     debugger;
//     this.submitted = true;

//     if (this.form.invalid) {
//       return;
//     }
//     this.loading=true;

// this.ForgetPassowrdFunction(this.form.value.email)

//     console.log(JSON.stringify(this.form.value, null, 2));
//   }

onSubmit(): void {
  debugger;
  this.submitted = true;

  if (this.form.invalid) {
    return;
  }

  const emailControl = this.form.get('email');

  this.trimValidator(emailControl); 

  if (emailControl.errors) {
    return; 
  }

  this.loading = true;
  this.ForgetPassowrdFunction(emailControl.value);

  console.log(JSON.stringify(this.form.value, null, 2));
}

  //calling forget password api
  ForgetPassowrdFunction(email: string) {
    var model = {


    //  "email": email
    "email": email.trim()

    }

    this.accountService.ForgotPasswordPost(model)
      .subscribe(
        (data: any) => {
          if (data == "3") {
            this.loading = false;
            this.sentmail = false;
            this.showNotification("error", "Entered Email ID is not registered in our System. Please contact AithrX Support.");
          } else {
            this.loading = false;
            this.sentmail = true;
            
            this.showNotification("success", "Password Reset successfully and new password send on your email.");
          }
          
        },
        error => {

        });

  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

}
