<section id="admindashboard">
    <form class="col-12 px-0">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mb-3 mb-xl-0">
                    <div class="card buildcar-title mb-0">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto mr-auto">
                                    <div class="form-inline pt-1">
                                        <div class="form-group col-auto mb-1">
                                            <div class="row justify-content-between align-items-center">
                                                <label class="col-md-auto col-form-label">Lender Name</label>
                                                <div class="col-md-auto">
                                                    <select style="min-width:200px;max-width:200px;" class="form-control" (change)="GetLenderKeyList($event)"

                                                        [(ngModel)]="LenderNameValue">
                                                        <option value="0" [selected]="true">Select Lender Name </option>
                                                        <option *ngFor="let lenderlistsdata of lenderlists" [value]="lenderlistsdata.id">
                                                            {{lenderlistsdata.lenderName}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-auto mb-1">
                                            <div class="row justify-content-between align-items-center">
                                                <label class="col-md-auto col-form-label">Plan Type</label>
                                                <div class="col-md-8">
                                                    <select style="min-width:200px;max-width:200px;" class="form-control"
                                                    (change)="Getplantype($event)"
                                                        [(ngModel)]="PlanType">
                                                        <option value="0" [selected]="true">Select Lender key </option>
                                                        <option *ngFor="let lenderKeyListdata of lenderKeyList" [value]="lenderKeyListdata.id">
                                                            {{lenderKeyListdata.planType}}</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-auto mb-1 px-md-1">
                                            <button id="getdetails" style="position:relative;top:1px;" type="button"
                                            class="btn btn-md ml-2 btn-warning py-1 rounded-pill" (click)="GetPppsaListValues();">Get Lender Details</button>
                                        </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div id="ListLenderDetail" class="card-body px-3 py-2" >

                            <div *ngIf="showlist ==='true'" class="row mt-2">
                                <div class="col-md-12">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h6 class="card-title text-white text-uppercase mb-0">List of Lender Detail</h6>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">

                                                    <!-- <h6 class="mb-0 d-inline-block"><b>Total Records :</b> {{TotalRecord}}</h6> -->
                                                    <button class="btn bg-success text-white btn-sm ml-2">
                                                        <i class="fas fa-plus mr-1"></i>Add</button>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive mb-0">
                                                <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th>Lender Name
                                                            </th>
                                                            <th>Tier Name
                                                            </th>
                                                            <th>Province Name
                                                            </th>
                                                            <th>Province Code
                                                            </th>
                                                            <th>V1
                                                            </th>
                                                            <th>V2
                                                            </th>
                                                            <th>V3
                                                            </th>
                                                            <th>V4
                                                            </th>
                                                            <th>V5
                                                            <th>V6
                                                            </th>
                                                            <th>V7
                                                            </th>
                                                            <th class="text-center">Actions</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let LenderppasListdata of LenderppasList| filter:searchTextBought | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize;let index = index">
                                                            <td>{{ LenderppasListdata.lenderName }}</td>
                                                            <td>{{ LenderppasListdata.tierName }}</td>
                                                            <td>{{ LenderppasListdata.provinceName }}</td>
                                                            <td>{{ LenderppasListdata.provinceCode }}</td>
                                                            <td style="text-align: right;">


                                                                <input type="text" [(ngModel)]="LenderppasListdata.v1" name="positionv1-{{index}}" class="form-control input-sm"/>

                                                            </td>
                                                            <td style="text-align: right;">


                                                                <input type="text" [(ngModel)]="LenderppasListdata.v2" name="positionv2-{{index}}" class="form-control input-sm"/>

                                                            </td>
                                                            <td style="text-align: right;">

                                                                <input type="text" [(ngModel)]="LenderppasListdata.v3"  name="positionv3-{{index}}"  class="form-control input-sm"/>

                                                            </td>
                                                            <td style="text-align: right;">

                                                                <input type="text" [(ngModel)]="LenderppasListdata.v4"  name="positionv4-{{index}}"  class="form-control input-sm"/>
                                                            </td>

                                                            <td style="text-align: right;">


                                                                 <input type="text" [(ngModel)]="LenderppasListdata.v5"  name="positionv5-{{index}}"  class="form-control input-sm"/>

                                                                </td>

                                                            <td style="text-align: right;">

                                                                <input type="text" [(ngModel)]="LenderppasListdata.v6"  name="positionv6-{{index}}"  class="form-control input-sm"/>

                                                            </td>

                                                            <td style="text-align: right;">


                                                                <input type="text" [(ngModel)]="LenderppasListdata.v7"  name="positionv7-{{index}}"  class="form-control input-sm"/>
                                                            </td>
                                                            <td style="text-align:center;">
                                                                <button class="btn bg-gradient-info btn-sm" title="Update" (click)="EditPPsa(index,LenderppasListdata)">
                                                                    <i class="fa-solid fa-pen-to-square"></i></button>
                                                                <button title="Delete" class="btn bg-gradient-danger btn-sm ml-2"><i class="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                <br>


                                                <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                                    type="square-jelly-box" [fullScreen]="false">
                                                    <p style="color: white"> Loading... </p>
                                                </ngx-spinner>
                                                <div class="col-12">
                                                    <div class="row justify-content-between align-items-center mx-0">
                                                        <div class="col-auto px-0">
                                                            <ngb-pagination [collectionSize]="LenderppasList.length"
                                                            [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                            [boundaryLinks]="true"></ngb-pagination>
                                                        </div>
                                                        <div class="form-group mb-0">
                                                            <h6 style="font-size: 0.97em;position: relative;
                                                            top: 2px;" class="mb-0 d-inline-block mr-2"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                            <label style="padding:2px;"> Items per Page:</label>
                                                          <select (change)="handlePageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                              {{ size }}
                                                            </option>
                                                          </select>
                                                        </div>
                                                    </div>
                                                  </div>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <app-footer id="secondfooter"></app-footer> -->
    </form>


</section>





