import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

@Component({
  selector: 'app-customer-order',
  templateUrl: './customer-order.component.html',
  styleUrls: ['./customer-order.component.less']
})
export class CustomerOrderComponent implements OnInit {
  customerOrderForm: FormGroup;
  constructor(private fb: FormBuilder,private manageInventoryService: ManageInventoryService,) { }
  DealerCostControl:any;
  DetailsControl:any;
  TrimControl:any;
  YearControl:any;
  MileageControl:any;
  VINControl:any;
  ModelControl:any;
  StockNumberControl:any;
  MakeControl:any;
  ConditionControl:any;
  VINValueControl:any;
  ListPriceControl:any
  OrderDetailList:[];
  CustomersList:[];
  searchTextBought;
  pagebought = 1;
  pageSizebought = 10;
  TotalRecord:any;
  showlist:any;
  CustomerNameFilter:any;
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.showlist="false";
    this.GetCustomersList();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  GetDetail()
  {
    this.showlist="false";
    this.GetDetailofUnitByVin();
  }
  GetDetailofUnitByVin() {
    debugger;
    var modelGroup = {
      "DealerName": this.VINValueControl,
      "VIN": this.VINValueControl
    }
    this.manageInventoryService.getDetailofUnitByVin(modelGroup)
      .subscribe(
        (data: any) => {
          this.StockNumberControl =data[0].stockNumber;
          this.ConditionControl=  data[0].condition;
          this.MakeControl =   data[0].make;   
          this.ModelControl =   data[0].model;
          this.VINControl=    data[0].vin;
          this.MileageControl=data[0].mileage;
          this.YearControl = data[0].carYr;
          this.TrimControl = data[0].trim;
          
          this.ListPriceControl = parseFloat(data[0].listedPrice).toFixed(2);
          this.DealerCostControl = parseFloat(data[0].dealerCost).toFixed(2);
          this.DetailsControl = data[0].vehicleInfo;
        },
        error => {
        });
  }


  GetCustomerOrder() {
    debugger;
    var modelGroup = {
      "Opcode": "0",
      "VIN": this.VINControl,
      "CustomerId":this.CustomerNameFilter
    }
    this.manageInventoryService.getCustomerOrderDetail(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.showlist="true"
          this.OrderDetailList = data;
         this.TotalRecord =this.OrderDetailList.length;
        },
        error => {
        });


  }

  GetCustomersList() {
    var modelGroup = {
      "Opcode": "0"
    }
    this.manageInventoryService.getCustomersDetails(modelGroup)
      .subscribe(
        (data: any) => {
         this.CustomersList = data;
        },
        error => {
        });
  }


  Createform() {
    this.customerOrderForm = new FormGroup({
      DealerCostControl: this.fb.control(''),
      DetailsControl: this.fb.control(''),
      TrimControl: this.fb.control(''),
      YearControl: this.fb.control(''),
      MileageControl: this.fb.control(''),
      VINControl: this.fb.control(''),
      ModelControl: this.fb.control(''),
      StockNumberControl: this.fb.control(''),
      MakeControl: this.fb.control(''),
      ConditionControl: this.fb.control(''),
      VINValueControl: this.fb.control(''),
      ListPriceControl: this.fb.control(''),
      CustomerNameFilter: this.fb.control(''),
    });
  }
}
