<section id="admindashboard">
	<form class="col-12 px-0 findcardblock" [formGroup]="findCarForm">
		<div class="container-fluid">
			<!-- <div class="page-header pb-2">
                      <div class="row">
                    <div class="col-lg-12">
                        <h3>Find Cars</h3>
                    </div>
                </div>
            </div>-->

			<div class="row mt-2" id="LendersDIVID">
				<div id="accordionExample" class="accordion">
					<div class="col-md-12">
						<div class="card card-raised h-100 mb-0">
							<div class="card-header card-raised bg-info text-white">
								<div class="row justify-content-start align-items-center mx-0">
									<h6 class="card-title text-white text-uppercase mb-0">List of Lenders</h6>
									<div class="ml-auto col-auto px-2">
										<button style="padding:0;font-size:13px;" type="button" data-toggle="collapse"
											data-target="#ListLenders" aria-expanded="true" aria-controls="ListLenders"
											class="btn text-white plusbtn">
											<i class="fas fa-chevron-down"></i></button>
									</div>
								</div>
							</div>
							<div id="ListLenders" aria-labelledby="BuildCar" data-parent="#accordionExample"
								class="collapse show">
								<div class="card-body mb-2">
									<!-- <div id="Aixcheckbox" class="row">
										<div class="col-md-12 aixcheckboxin">
											<div class="lenderlistName">
												<label>
													<input type="checkbox" value="AIX" />
													AIX
												</label>
											</div>
										</div>
									</div> -->

									<div id="carList" class="row rounded-lg">
										<div class="form-check form-check-inline" data-step="2"
											data-intro="Select lenders" data-title="Step 2">

											<!--This was for multiple div start-->
											<!-- <div class="lenderlistName" *ngFor="let optionslist of lenderlists">
												<label>
													<input type="checkbox" name="options"
														(change)="onCheckboxChange(optionslist.id,optionslist.lenderName,$event)"
														value="{{optionslist.id}}" />
													{{optionslist.lenderName}}
												</label>
											</div> -->

											<!--End-->

											<div class="lenderlistName"
												*ngFor="let optionslist of lenderlists; let index = index">
												<label
													*ngIf="optionslist.lenderName !== 'Axis' || HideAxises !== 'true'">
													<input type="checkbox" id="lenderchck_{{optionslist.id}}"
														[checked]="optionslist.isSelected"
														(change)="onCheckboxChange(optionslist.id,optionslist.lenderName,$event)"
														value="{{optionslist.id}}" />
													{{optionslist.lenderName}}
												</label>
											</div>

										</div>
									</div>
									<hr />
									<div class="row mt-1">
										<div class="col-md-3 col-lg-2" id="heightAuto">
											<label>Dealer Group</label>
											<div class="input-group">
												<ng-select style="width:100%;" [items]="GroupLists"
													bindLabel="globalCodeName"
													(change)="GetDealerNamebyGroupId(selectedGroupIds)"
													[ngModelOptions]="{standalone: true}" placeholder="Select group"
													appendTo="body" [multiple]="false" [(ngModel)]="selectedGroupIds">
												</ng-select>
											</div>
										</div>

										<div class="col-auto mt-md-0 mt-1 px-2">
											<div class="form-group mb-md-0">
												<div class="w-100">
													<label>Inventory Source</label>
													<div class="col-md-12 px-0">
														<select class="form-control" #AithrHubKeyControl
															name="AithrHubKey" (change)="GetAithrHubUnits($event)"
															formControlName="AithrHubKey" id="AithrHubKey"
															[(ngModel)]="AithrHubKey">
															<option value="0" [selected]="true">My Inventory
															</option>
															<option value="1">AithrHub</option>
															<!-- <option value="2">EBlock</option> -->
															<option *ngFor="let InventorySorucelistdata of InventorySorucelist" [value]="InventorySorucelistdata.id">
																{{InventorySorucelistdata.dealerName}}</option>
														</select>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto"
											[hidden]="ShowMultiSelectRegion">
											<label>My Inventory</label>
											<div class="input-group dealername">
												<ng-select style="width:100%;" [items]="dealerlistName"
													[dropdownPosition]="true" bindLabel="dealerName"
													[ngModelOptions]="{standalone: true}"
													placeholder="Select dealer Name" appendTo="body"
													(change)="ClearDealer($event)" (click)="CheckClick()"
													[multiple]="true" [(ngModel)]="dealersmultiselectarray">
												</ng-select>
											</div>
										</div>

										<div class="col-md-2 col-lg-2  mt-md-0 mt-1 px-2" id="heightAuto"
											[hidden]="!ShowAithrhubMultiSelectRegion">
											<label>AithrHub Regions</label>
											<div class="input-group dealername">
												<ng-select style="width:100%;" [items]="regionlistName"
													bindLabel="globalCodeName" [dropdownPosition]="true"
													[ngModelOptions]="{standalone: true}"
													placeholder="Select Province Name" appendTo="body"
													(change)="ClearRegion($event)" (click)="CheckRegionClick()"
													[multiple]="true" [(ngModel)]="regionmultiselectarray">
												</ng-select>
												<!-- <div class="mt-2 w-100" [hidden]="!ShowMultiSelectRegion">
													<label>Select City</label>
													<select class="form-control" name="dealerCity" formControlName="dealerCity"
												[(ngModel)]="dealerCity" >
												<option value="0" selected="true">Select city name</option>
												<option *ngFor="let citylistdata of citylist"
													[value]="citylistdata.dealerCity">
													{{citylistdata.dealerCity}}</option>
											</select>
												</div> -->
											</div>
										</div>

										<div class="col-md-4 col-lg-2 mt-md-0 mt-1">
											<label>Set Tax Region</label>
											<select class="form-control" name="province" formControlName="province"
												[(ngModel)]="province" (change)="ChangeRegion($event)">
												<option value="0" selected="true">Select Province name</option>
												<option *ngFor="let Provincelistdata of Provincelist"
													[value]="Provincelistdata.globalCodeID">
													{{Provincelistdata.globalCodeName}}</option>
											</select>
										</div>

										<div class="col-auto mt-md-4 mt-lg-0 mt-0 pt-1 multiplecheckbox ">

											<div class="custom-control custom-checkbox mb-2">
												<input class="custom-control-input" (change)="checkTaxValue($event)"
													formControlName="TaxExemptionCheck" type="checkbox"
													id="TaxExemptionID">
												<label for="TaxExemptionID" class="custom-control-label">Tax
													Exemption</label>
											</div>

											<div class="custom-control custom-checkbox">
												<input class="custom-control-input"
													(change)="checkLockListPriceValue($event)"
													formControlName="IsLockListPrice" type="checkbox" id="LockPriceID">
												<label for="LockPriceID" class="custom-control-label">Lock List
													Price</label>
											</div>

											<div class="custom-control custom-checkbox" style="display: none;">
												<input class="custom-control-input" formControlName="advanceFilterCheck"
													(change)="checkValue($event)" type="checkbox" id="advanceID">
												<label for="advanceID" class="custom-control-label">Advanced
													Filter</label>
											</div>
											<div class="custom-control custom-checkbox" style="display:none">
												<input class="custom-control-input" (change)="CheckLocakPrice($event)"
													formControlName="locklistpriceCheck" type="checkbox"
													id="locklistpriceCheck">
												<label for="locklistpriceCheck" class="custom-control-label">Lock List
													Price</label>
											</div>

											<button #buttonPleasecheck (click)="PleaseCheck();"
												style="display: none;">CheckBUtton</button>
										</div>


										<!-- </div> -->
										<!-- //End onde div panel -->

									</div>
									<!-- filter code start -->
									<hr />
									<div id="advanced-filters" *ngIf="advanceFilter ==='true'" class="mt-1">
										<div class="row">
											<div class="col-md-12">
												<h6>Advanced Filters</h6>
											</div>
										</div>
										<div class="row">
											<div class="col-md-3 col-lg-2">
												<label>Make</label>
												<input id="makefilter" class="form-control" type="text"
													(input)="MakeCheck($event)" formControlName="MakeFilterControl">
											</div>

											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Model</label>
												<input id="Modelfilter" (input)="ModelCheck($event)"
													class="form-control" type="text"
													formControlName="ModelFilterControl">
											</div>

											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Body Style</label>
												<input id="Modelfilter" (input)="BodyCheck($event)" class="form-control"
													type="text" formControlName="BodyStyleFilterControl">
											</div>
											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Year greater than equal to</label>
												<input id="Seatfilter" (input)="SeatCheck($event)" class="form-control"
													type="text" formControlName="NumberOfSeatsFilterControl">
											</div>
										</div>
									</div>
									<!-- filter code end -->
								</div>
							</div>
						</div>
					</div>
				</div>



			</div>


			<div class="Findcars-container">

				<div class="col px-sm-3" id="findcarcloneid">

				</div>

			</div>
		</div>


		<div formArrayName="LenderDetailsformarray">


			<!-- <div *ngFor="let name of lendersarrayvalue.controls; index as idx">
				<input formControlName="LenderName" placeholder="Enter a Name">
				<label>{{ name.value.LenderName }}</label>
				<label *ngIf="name.invalid" [ngClass] = "'error'"> Name is required. </label>
			 </div> -->
			<div class="Findcars-container">
				<div *ngFor="let Lenderformarraydata of LenderDetailsformarray.controls; let i = index;trackBy:trackFn"
					class="ledermaindiv" id="findlenderdiv_{{Lenderformarraydata.value.Lenderid}}">

					<input id="DeductionfeeIndex_{{Lenderformarraydata.value.Lenderid}}" value="{{i}}"
						style="visibility:hidden;">

					<div [formGroupName]="i" style="display: flex">


						<div class="col px-sm-3" id="findcarcloneid">

							<div id="accordionExampleloop" class="accordion">
								<!-- //FILTER LENDER AREA -->
								<div class="row mt-1 mb-1">
									<div class="col-md-12">
										<div class="card card-raised h-100">
											<div class="card-header card-raised bg-info text-white">
												<div class="row justify-content-between align-items-center">
													<div class="col-lg-12 text-white text-uppercase mb-0 mt-1">
														<div class="row justify-content-between align-items-center">
															<div class="col-md-auto col pt-1">
																<h6
																	class="lendername d-inline-block mb-0 text-white font-weight-bold">
																	{{Lenderformarraydata.value.LenderName }}
																</h6>
																&nbsp;&nbsp;
																<h6 class="lendername d-inline-block mb-0 px-2 py-1 text-white"
																	style="font-weight:800;font-size: 1.12em;">
																	<span style="display: none;"
																		id="totalcount_{{Lenderformarraydata.value.Lenderid}}">Total
																		Vehicles Found :
																		{{Lenderformarraydata.get('inventaryarray').controls.length}}</span>
																</h6>

																<div class="d-inline-block ml-3"
																	*ngIf="Lenderformarraydata.value.LenderName=='Go Plan'">



																	<button #buildcarpopupbuttonToBeClicked
																		class="multisearch btn btn-sm btn-warning bg-gradient-warning lenderfindcarsearch mr-2"
																		id="dedcutionfeebutton_{{Lenderformarraydata.value.Lenderid}}"
																		(click)="OpendeductionModelPopup(DeductionModel,Lenderformarraydata.value.Lenderid,i)">
																		Advance Percentage</button>
																</div>
															</div>
															<div
																class="col-lg-auto col-md-auto ml-auto text-right mt-2 mt-md-0">

																<button type="button"
																	(click)="getUnitsByDealerNames(i);"
																	*ngIf="!buttonloader"
																	class="multisearch btn btn-sm btn-warning bg-gradient-warning lenderfindcarsearch mr-2">Search</button>
																<button type="button" style="display: none;"
																	id="savecustomer_{{Lenderformarraydata.value.Lenderid}}"
																	(click)="OpenAddclientModel(AddclientModel,Lenderformarraydata.value.Lenderid,i)"
																	class="btn btn-sm btn-success mr-2"> Save</button>

																<button type="button" class="btn btn-sm btn-primary"
																	(click)="ChangeViewfunction('Grid',i)"><i
																		class="fas fa-list mr-1"></i>List</button>
																<button type="button"
																	class="btn btn-sm btn-warning ml-2"
																	(click)="ChangeViewfunction('List',i)"><i
																		class="fas fa-th mr-1"></i>
																	Grid</button>

																<!-- collapes button icon start -->
																<button style="padding: 0; font-size: 13px;"
																	type="button" data-toggle="collapse"
																	[attr.data-target]="'#ListLendersloop_' + Lenderformarraydata.value.Lenderid"
																	(click)="ShowTotalCount(Lenderformarraydata.value.Lenderid);"
																	aria-expanded="true"
																	aria-controls="ListLendersloop_{{Lenderformarraydata.value.Lenderid}}"
																	class="btn text-white plusbtn ml-3">
																	<i class="fas fa-chevron-down"></i>
																</button>
																<!-- collapes button icon end -->
															</div>
														</div>
														<hr />
														<div id="tdblock"
															class="row mt-2 justify-content-between align-items-center">
															<div class="col-12">
																<div class="row mt-1">
																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row">
																			<div class="col-auto pt-1">
																				<div *ngIf="Lenderformarraydata.value.LenderName!='Go Plan'"
																					class="custom-control custom-checkbox pl-0">

																					<input type="checkbox"
																						id="hasGPSValue_{{Lenderformarraydata.value.Lenderid}}"
																						formControlName="hasGpsCheckBox"
																						class="" />


																					<label
																						class="text-capitalize ml-1">Has
																						GPS Fee</label>
																				</div>
																			</div>
																		</div>
																	</div>
																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row"
																			*ngIf="Lenderformarraydata.value.LenderName!='Go Plan'">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">GPS
																				Fee</label>
																			<div class="col">
																				<input class="form-control" readonly
																					id="GPSFee_{{Lenderformarraydata.value.Lenderid}}"
																					type="number"
																					formControlName="GPSFeeControl"
																					placeholder="0">
																			</div>
																		</div>
																	</div>
																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">FLTV
																				Setup</label>
																			<div class="col">
																				<input id="TDBPCallID"
																					id="FLTVSetup_{{Lenderformarraydata.value.Lenderid}}"
																					class="form-control" type="number"
																					readonly
																					appTwoDigitDecimaNumberDirective
																					formControlName="FLTVSetupControl"
																					placeholder="0">
																			</div>
																		</div>
																	</div>
																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">OverRide
																				FLTV</label>
																			<div class="col">
																				<input appNumericOnly
																					id="OverRideFLTV_{{Lenderformarraydata.value.Lenderid}}"
																					class="form-control" type="number"
																					min="0" maxlength="3"
																					(input)="restrictInputToThreeDigits($event)"
																					formControlName="OverRideFLTVControl"
																					placeholder="0">
																			</div>
																		</div>
																	</div>

																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Max
																				Term</label>
																			<div class="col">
																				<input appNumericOnly
																					id="OverrideTerm_{{Lenderformarraydata.value.Lenderid}}"
																					class="form-control" type="number"
																					min="0" maxlength="3"
																					formControlName="OverrideTermControl"
																					placeholder="0">
																			</div>
																		</div>
																	</div>

																	<div
																		class="form-group col-12 col-sm-12 col-md-auto mb-1">
																		<div class="row">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Max
																				Amount Finance</label>
																			<div class="col">
																				<input
																					id="MaxAmountFinance_{{Lenderformarraydata.value.Lenderid}}"
																					class="form-control" type="number"
																					min="0" maxlength="3"
																					placeholder="0"
																					formControlName="MaxAmountFinanceControl">
																			</div>
																		</div>
																	</div>

																	<div class="form-group col-12 col-sm-12 col-md-auto mb-1"
																		style="margin-top:7px;">


																	</div>
																	<div class="form-group col-12 col-sm-12 col-md-auto mb-1 AllowableLossDiv"
																		id="AllowableLossDiv_{{Lenderformarraydata.value.Lenderid}}">
																		<div class="row" *ngIf="ShowAllowable ===true">
																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Allowable
																				Loss</label>
																			<div class="col">
																				<input class="form-control"
																					type="number"
																					id="AllowableLoss_{{Lenderformarraydata.value.Lenderid}}"
																					min="0" maxlength="3"
																					placeholder="0"
																					formControlName="AllowableLossControl">
																			</div>
																		</div>
																	</div>


																	<div class="form-group col-12 col-sm-12 col-md-auto mb-1 DeductionfeeDiv"
																		id="DeductionfeeDDiv_{{Lenderformarraydata.value.Lenderid}}">
																		<div class="row"
																			*ngIf="Lenderformarraydata.value.LenderName=='Go Plan'">

																			<label
																				class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Advance
																				Percentage
																			</label>
																			<div class="col">

																				<!-- <input
																					id="DeductionfeeIndex_{{Lenderformarraydata.value.Lenderid}}"
																					value="{{i}}"
																					style="visibility:hidden;"> -->

																				<input readonly class="form-control"
																					type="number"
																					id="DeductionfeeInput_{{Lenderformarraydata.value.Lenderid}}"
																					min="0" maxlength="3"
																					placeholder="0"
																					formControlName="DeductionfeeInputControl">
																			</div>
																		</div>
																	</div>



																	<!-- <div class="form-group col-12 col-sm-12 col-md-auto">
															<div class="row">
																<label class="col-auto"><a id="LinkThink_{{Lenderformarraydata.value.Lenderid}}" (click)="AddDetailThinkMoney(Lenderformarraydata.value.Lenderid);"
																	href="https://thinkmoneyfinance.ca/TMFApp/registration/registration.html"
																	target="_blank" class="text-white">Need Down payment?</a></label>

															</div>
														</div> -->
																</div>
															</div>

														</div>
													</div>
													<div class="col-auto">
														<button *ngIf="buttonloader"
															class="buttonload btn btn-sm btn-secondary bg-gradient-secondary"><i
																class="fa fa-spinner fa-spin"></i>Loading</button>
													</div>
												</div>

											</div>

											<div id="ListLendersloop_{{Lenderformarraydata.value.Lenderid}}"
												aria-labelledby="BuildCar" data-parent="#accordionExampleloop"
												class="collapse show">
												<div class="card-body">
													<div class="row">
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<label>Lender Key</label>

																<select class="form-control Lenderkeyddl"
																	id="lenderdropdown_{{Lenderformarraydata.value.Lenderid}}"
																	(change)="Lenderdropdownchange($event,i,Lenderformarraydata.value.Lenderid)"
																	formControlName="lenderkeyddl">
																	<option value="0" selected>Select Lender key
																	</option>
																	<!-- <option *ngFor="let lenderKeyListdata of lenderKeyList" [value]="lenderKeyListdata.id">
													{{lenderKeyListdata.planType}}</option> -->
																</select>
																<label class="Lenderkeymsg"
																	style="display: none;color: red;"
																	id="lenderdropdownmsg_{{Lenderformarraydata.value.Lenderid}}">Please
																	select Lender
																	Key</label>
															</div>
														</div>
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<label>Pay Call</label>
																<div class="input-group">
																	<div class="input-group-text"><i
																			class="fas fa-dollar-sign"></i></div>
																	<input id="TDBPCallID" class="form-control"
																		type="number"
																		(keyup)="CheckValidationPaycall($event,Lenderformarraydata.value.Lenderid)"
																		formControlName="LenderPayCall" placeholder="0">
																</div>

																<label class="PayCallmsg"
																	style="display: none;color: red;"
																	id="PayCallmsg_{{Lenderformarraydata.value.Lenderid}}">Please
																	enter Paycall</label>
															</div>
														</div>
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<label>Interest Rate</label>
																<div class="input-group" id="IntRateDIV">
																	<div class="input-group-text">
																		<i class="fas fa-percentage"></i>
																	</div>
																	<input id="TDIntRtID" class="form-control"
																		type="number" placeholder="0"
																		(keyup)="CheckValidationInterestRate($event,Lenderformarraydata.value.Lenderid)"
																		formControlName="LenderInterestRate">
																</div>
																<label class="interestratemsg"
																	style="display: none;color: red;"
																	id="interestratemsg_{{Lenderformarraydata.value.Lenderid}}">Please
																	enter Interest
																	Rate</label>
															</div>
														</div>
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<label>Trade Value</label>
																<div class="input-group">
																	<div class="input-group-text">
																		<i class="fas fa-dollar-sign"></i>
																	</div>
																	<input id="TDTradeInID" class="form-control"
																		type="number" placeholder="0"
																		formControlName="ACVValueControl">
																</div>
															</div>
														</div>
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<label>Lien</label>
																<div class="input-group">
																	<div class="input-group-text">
																		<i class="fas fa-dollar-sign"></i>
																	</div>
																	<input id="TDLienID" class="form-control"
																		type="number" placeholder="0"
																		formControlName="LoanAmountControl">
																</div>
															</div>
														</div>
														<div class="col-sm-4 col-md-3 col-lg-2">
															<div class="form-group">
																<div
																	class="row mx-0 justify-content-between align-items-center">
																	<label class="col-auto px-1 mb-0"
																		_ngcontent-iif-c186="">Down Payment</label>
																	<a id="LinkThink_{{Lenderformarraydata.value.Lenderid}}"
																		(click)="AddDetailThinkMoney(Lenderformarraydata.value.Lenderid);"
																		href="https://thinkmoneyfinance.ca/TMFApp/registration/registration.html"
																		target="_blank"
																		style="font-size: 0.82em;margin-bottom: 3px;padding-left: 5px;">Need
																		Down
																		payment?</a>
																</div>
																<div class="input-group">
																	<div class="input-group-text">
																		<i class="fas fa-dollar-sign"></i>
																	</div>
																	<input id="TDDownPayID" class="form-control"
																		type="number" placeholder="0"
																		formControlName="DownPaymentAmountControl">
																</div>
															</div>
														</div>

													</div>
													<div class="row">
														<!--Build grid start-->
														<div class="col-12" formArrayName="inventaryarray">

															<div style="display:none"
																id="inventryList_{{Lenderformarraydata.value.Lenderid}}"
																class="container-fluid">
																<!-- <div>
								<div class="row">
									<div class="form-group col-lg-2 col-md-3 col-sm-7">
									<label>Selection List</label>
									<select class="form-control" name="AithrHubKey" (change)="GetAithrHubUnits()"
									formControlName="AithrHubKey" [(ngModel)]="AithrHubKey">
									<option value="0" [selected]="true">Selected Dealers</option>
									<option value="1">AithrHub</option>
									</select>
									</div>

								</div>
								</div> -->
																<div class="row">
																	<div class="col-12 mt-2 px-0">
																		<div
																			class="row justify-content-between align-items-center mb-2">
																			<div
																				class="col-auto col-xl-auto documentbtn">
																				<ul class="list-unstyled mb-1">

																					<li>
																						<button
																							class="btn btn-secondary btn-sm mb-1 mb-md-0">Copy</button>
																					</li>

																					<li>
																						<button
																							class="btn btn-secondary btn-sm mb-1 mb-md-0">Excel</button>
																					</li>
																					<li>
																						<button (click)="downloadPdf(i)"
																							class="btn btn-secondary btn-sm mb-1 mb-md-0">PDF</button>
																					</li>
																					<li>
																						<button
																							class="btn btn-secondary btn-sm mb-1 mb-md-0"
																							(click)="printTable(i)">Print</button>
																					</li>

																					<li>
																						<button
																							class="btn bg-gradient-info btn-sm"
																							(click)="HideShowColumn(Lenderformarraydata.value.Lenderid)"
																							title="Show columns">
																							<span class="icon">
																								<i
																									class="fa fa-cog"></i>
																							</span>
																							<span
																								class="link-text"></span>
																							<span
																								class="ml-auto myCaret"></span>
																						</button>

																					</li>

																				</ul>
																			</div>
																			<div class="col-auto mr-auto px-0">
																				<div style="display: none;"
																					id="Multiselectdrpdowncoulm_{{Lenderformarraydata.value.Lenderid}}"
																					class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
																					<ng-multiselect-dropdown
																						id="multiselectdropdown"
																						[placeholder]="'Select Your Item'"
																						[settings]="dropdownSettings"
																						[data]="dropdownList"
																						formControlName="myItems"
																						(onSelect)="onItemSelect($event,i)"
																						(onSelectAll)="onSelectAll($event,i)"
																						(onDeSelect)="onItemDeSelect($event,i)"
																						(onDeSelectAll)="onUnSelectAll(i)">
																					</ng-multiselect-dropdown>
																				</div>
																			</div>





																			<div
																				class="search-heros col-md-auto col-xl-3">
																				<div class="form-group d-flex mb-1">
																					<label
																						class="mr-3 mt-2">Search:</label>
																					<input class="form-control"
																						type="text"
																						name="searchtext{{i}}"
																						(keyup)="setSerachboxvalue($event,i,'')"
																						(keypress)="setSerachboxvalue($event,i,'')"
																						(keydown)="setSerachboxvalue($event,i,'')"
																						(paste)="setSerachboxvalue($event.clipboardData.getData('text/plain'),i,'paste')"
																						(blur)="setSerachboxvalue($event,i,'')"
																						[ngModelOptions]="{standalone: true}"
																						autocomplete="off">
																				</div>
																			</div>
																		</div>


																		<div class="w-100 mt-0">
																			<div class="col-auto col-lg-12">
																				<div class="row mb-md-2">

																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select name="Year"
																							class="yearfilterddl form-control"
																							id="Yearfilter_{{Lenderformarraydata.value.Lenderid}}"
																							[(ngModel)]="selectedyear"
																							(change)="FindcarKeup($event,'Year',i,Lenderformarraydata.value.Lenderid)">
																						</select>

																					</div>
																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">


																						<select
																							class="Makefilterddl form-control"
																							id="Makefilter_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Make',i,Lenderformarraydata.value.Lenderid)">
																						</select>
																					</div>
																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">

																						<select
																							class="Modelfilterddl form-control"
																							id="Modelfilter_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Model',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>
																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">

																						<select style="max-width:160px;"
																							class="Trimfilterddl form-control"
																							id="Trimfilter_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Trim',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>

																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="kmvaluefilterddl form-control"
																							id="KM_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'KM',i,Lenderformarraydata.value.Lenderid)">

																						</select>

																					</div>

																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="BodyStylefilterddl form-control"
																							id="Body_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Body',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>



																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="Colorfilterddl form-control"
																							id="Color_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Color',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>


																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="Fueltypefilterddl form-control"
																							id="Fueltype_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'FuelType',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>



																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="Transmissionfilterddl form-control"
																							id="Transmission_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'Transmission',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>

																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
																						<select style="max-width:160px;"
																							class="dealerCityfilterddl form-control"
																							id="dealerCity_{{Lenderformarraydata.value.Lenderid}}"
																							(change)="FindcarKeup($event,'City',i,Lenderformarraydata.value.Lenderid)">

																						</select>
																					</div>




																					<div
																						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 d-sm-none">
																						<!-- <select  class="BodyStylefilterddl form-control" id="BodyStylefilter_{{Lenderformarraydata.value.Lenderid}}" (change)="FindcarKeup($event,'BodyStyle',i)">

														</select> -->
																					</div>
																					<div
																						class="col-sm-8 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
																						<button
																							style="position:relative;top:2px;"
																							(click)="getUnitsByDealerNames(i);"
																							class="btn btn-sm btn-warning">Reset</button>
																						<button title="Customer View"
																							*ngIf="ViewDetails ==='Grid'"
																							(click)="PopupCustomViewTable(Customtablepopup,i)"
																							style="position: relative;top: 2px;padding: 5px 8px;font-size: 0.92em;"
																							id="hideButtoncustomerview"
																							type="button"
																							[ngClass]="{'green' : toggle, 'red': !toggle}"
																							class="btn btn-success btn-md ml-2">
																							<i id="viewicon"
																								class="far fa-eye"></i>
																							<span class="ml-2">Custom
																								View</span>
																						</button>

																						<button title="Customer View"
																							*ngIf="ViewDetails ==='List' && ListViewListPrice ==='true' "
																							(click)="PopupgridCustomViewTable(Customtablepopup,i)"
																							style="position: relative;top: 2px;padding: 5px 8px;font-size: 0.92em;"
																							id="hideButtoncustomerview"
																							type="button"
																							[ngClass]="{'green' : toggle, 'red': !toggle}"
																							class="btn btn-success btn-md ml-2">
																							<i id="viewicon"
																								class="far fa-eye"></i>
																							<span class="ml-2">Custom
																								View</span>
																						</button>

																						<button title="Customer View"
																							*ngIf="ViewDetails ==='List' && ListViewListPrice ==='false'"
																							(click)="PopupgridHideCustomViewTable(Customtablepopup,i)"
																							style="position: relative;top: 2px;padding: 5px 8px;font-size: 0.92em;"
																							id="hideButtoncustomerview"
																							type="button"
																							[ngClass]="{'green' : toggle, 'red': !toggle}"
																							class="btn btn-success btn-md ml-2">
																							<i id="viewicon"
																								class="far fa-eye"></i>
																							<span class="ml-2">Custom
																								View</span>
																						</button>

																						<button style="display: none;"
																							#buttoncolorChnage
																							(click)="enableDisableRule()">
																							{{status}}
																						</button>

																					</div>
																				</div>
																			</div>
																		</div>

																		<div class="row">
																			<div class="col-md-12">
																				<div class="card card-raised h-100">
																					<div
																						class="card-header card-raised bg-info text-white">
																						<div
																							class="row justify-content-between align-items-center">
																							<div class="col">
																								<h6
																									class="card-title text-white text-uppercase mb-0">
																									List Of Inventory
																									Selected for
																									{{Lenderformarraydata.value.LenderName
																									}}</h6>
																							</div>

																							<div class="col text-right">
																								<div
																									class="row justify-content-end align-items-center">
																									<div
																										class="form-group col-auto">
																										<div
																											class="row justify-content-end align-items-center">

																											<label
																												class="col-form-label col-auto"
																												style="padding:2px;">
																												Items
																												per
																												Page:</label>
																											<!-- <select (change)="handlePageSizeChange($event,i)">
																											<option *ngFor="let size of pageSizes" [ngValue]="size">
																											{{ size }}
																											</option>
																										</select> -->
																											<div
																												class="col-auto">
																												<select
																													style="max-width: 160px;padding: 2px 4px;font-size: 12px;height: 25px;"
																													class="pageLengthvaluefilterddl form-control d-inline-block ml-2"
																													id="pageLength_{{i}}"
																													(change)="PageingFindCareLength($event,'pageLength',i,Lenderformarraydata.value.Lenderid)">
																													<option
																														*ngFor="let size of pageSizes"
																														[ngValue]="size">
																														{{
																														size
																														}}
																													</option>
																												</select>
																											</div>
																										</div>

																									</div>
																									<div
																										class="col-auto">
																										<h6 style="font-size:13px;"
																											class="mb-0 d-inline-block">
																											<b>Total
																												Records
																												:</b>
																											{{Lenderformarraydata.get('inventaryarray').controls.length}}
																										</h6>
																									</div>
																								</div>
																							</div>
																							<!-- <div class="card-subtitle"></div> -->
																						</div>
																					</div>
																					<div id="inventory-{{i}}"
																						class="card-body inventorycolumfixed p-4">
																						<div class="table-responsive">
																							<table id="usertable-{{i}}"
																								class="table table-bordered"
																								style="width:100%">
																								<thead>
																									<tr>
																										<th class="position_fix"
																											style="text-align:center;">
																										</th>
																										<th class="position_fix"
																											(click)="sort('vin',i)">
																											VIN <i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="position_fix"
																											(click)="sort('stockNumber',i)">
																											Stock No<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="position_fix"
																											(click)="sort('carYear',i)">
																											Year <i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="position_fix"
																											(click)="sort('mileage',i)">
																											Mileage <i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="position_fix"
																											(click)="sort('make',i)">
																											Make <i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="position_fix"
																											(click)="sort('model',i)">
																											Model<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="TermPeriodcolumn position_fix"
																											style="text-align: right;"
																											(click)="sort('termPeriod',i)">
																											Term 
																										</th>
																										<th class="RegionColumn"
																											(click)="sort('vehicleRegionName',i)">
																											Region<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="CityColumn"
																											(click)="sort('dealerCity',i)">
																											City<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="BodyColumn"
																											(click)="sort('body',i)">
																											Body<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="TrimColumn"
																											(click)="sort('trim',i)">
																											Trim<i
																												class="fa fa-fw fa-sort"></i>
																										</th>



																										

																										<th class="transmissionColumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Transmission
																										</th>
																										<th class="engineFuelTypeColumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Fuel</th>
																										
																										<th class="ListedPriceColumnStatus"
																											style="text-align: right;"
																											(click)="sort('listedPrice',i)">
																											Listed
																											Price<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="DealerCostColumnStatus"
																											style="text-align: right;"
																											(click)="sort('dealerCost',i)">
																											Dealer
																											Cost<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="ProjectedGrossColumnStatus"
																											*ngIf="ProjectedGrossColumnStatus ==='true'"
																											style="text-align: right;"
																											(click)="sort('projectedGross',i)">
																											Projected
																											Gross<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="ConditionColumnStatus"
																											(click)="sort('condition',i)">
																											Condition<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="MaxPaycallColumnStatus"
																											style="text-align: right;"
																											(click)="sort('calculatedPayCall',i)">
																											Max Paycall
																										</th>

																										<th class="EffectiveCBBcolumn"
																											style="text-align: right;"
																											(click)="sort('effectiveCBB',i)">
																											EffectiveCBB<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="TotalFLTVcolumn"
																											style="text-align: right;"
																											(click)="sort('fltv',i)">
																											Total FLTV
																										</th>

																										<th class="TotalBLTVcolumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Total BLTV
																										</th>

																										<th class="TotalTLVcolumn"
																											style="text-align: right;"
																											(click)="sort('tlv',i)">
																											Total TLV<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="LenderFeecolumn"
																											style="text-align: right;"
																											(click)="sort('lenderFee',i)">
																											Lender Fee
																										</th>

																										<th class="FrontDocFeecolumn"
																											style="text-align: right;"
																											(click)="sort('backDocFee',i)">
																											Front Doc
																											Fee</th>

																										<th class="BackDocFeecolumn"
																											style="text-align: right;"
																											(click)="sort('backDocFee',i)">
																											Back Doc Fee
																										</th>


																										<th class="PPSAcolumn"
																											style="text-align: right;"
																											(click)="sort('ppsa',i)">
																											PPSA</th>

																										<th class="Reservescolumn"
																											style="text-align: right;"
																											(click)="sort('reserves',i)">
																											Reserves
																										</th>




																										<th class="TaxRatecolumn"
																											style="text-align: right;"
																											(click)="sort('termPeriod',i)">
																											Tax Rate
																										</th>

																										<th class="TotalPricecolumn" 
																											style="text-align: right;display: none;"
																											(click)="sort('totalPrice',i)">
																											Total Price
																										</th>


																										<th class="TotalCostcolumn"
																											style="text-align: right;display: none;"
																											(click)="sort('totalCost',i)">
																											Total Cost
																										</th>



																										<th class="TotalTaxcolumn"
																											style="text-align: right;"
																											(click)="sort('totalTaxes',i)">
																											Total Tax<i
																												class="fa fa-fw fa-sort"></i>
																										</th>


																										<th class="AmountToBeFinancedcolumn"
																											style="text-align: right;display: none;"
																											(click)="sort('amountToBeFinanced',i)">
																											AmountToBeFinanced
																										</th>


																										<th class="AllowableFinancecolumn"
																											style="text-align: right;"
																											(click)="sort('allowableFinance',i)">
																											Allowable
																											Finance</th>

																										<th class="MinFinancecolumn"
																											style="text-align: right;"
																											(click)="sort('minFinance',i)">
																											Min Finance
																										</th>

																										<th class="MinPricecolumn"
																											style="text-align: right;"
																											(click)="sort('minPrice',i)">
																											Min Price
																										</th>

																										<th class="MinCostcolumn"
																											style="text-align: right;"
																											(click)="sort('minCost',i)">
																											Min Cost
																										</th>

																										<th class="MinGrosscolumn"
																											style="text-align: right;"
																											(click)="sort('minGross',i)">
																											Min Gross
																										</th>

																										<th class="MinTaxescolumn"
																											style="text-align: right;display: none;"
																											(click)="sort('minTaxes',i)">
																											Min Taxes
																										</th>

																										<th class="FLTVnewcolumn"
																											style="text-align: right;display: none;"
																											(click)="sort('fltVnew',i)">
																											FLTVnew</th>

																										<th class="FLTVcolumn"
																											style="text-align: right;"
																											(click)="sort('fltv',i)">
																											FLTV</th>

																										<th class="BLTVcolumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											BLTV</th>

																										<th class="Colorcolumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Color</th>

																										<!-- <th class="transmissionColumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Transmission
																										</th>
																										<th class="engineFuelTypeColumn"
																											style="text-align: right;"
																											(click)="sort('bltv',i)">
																											Fuel</th> -->
																										<th class="StockinDaysColumnStatus"
																											(click)="sort('stockInDays',i)">
																											Days In
																											Stock<i
																												class="fa fa-fw fa-sort"></i>
																										</th>

																										<th class="dealerAdvance"
																											*ngIf="ShowAdvce ==='true'"
																											(click)="sort('dealerAdvance',i)">
																											Dealer
																											Advance
																											<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="dealerAdvance"
																											style="display: none;"
																											(click)="sort('feAccessory1',i)">
																											Remote Start
																											<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="dealerAdvance"
																											style="display: none;"
																											(click)="sort('feAccessory2',i)">
																											Pro Pack
																											<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="dealerAdvance"
																											style="display: none;"
																											(click)="sort('feAccessory3',i)">
																											Accessories
																											(Other)
																											<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																										<th class="dealerAdvance"
																											style="display: none;"
																											(click)="sort('feMaximizedAmount',i)">
																											MaximizedAmount
																											<i
																												class="fa fa-fw fa-sort"></i>
																										</th>
																									</tr>
																								</thead>
																								<tbody>
																									<!-- <tr *ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"> -->
																									<!-- <tr [formGroupName]="y" *ngFor="let dealerVehicleslistdata of Lenderformarraydata.get('inventaryarray').controls| filter:searchText | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index"> -->
																									<!-- <tr [formGroupName]="y" *ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchtablearray[i].searchtext | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index"> -->
																									<tr
																										*ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchtablearray[i].searchtext | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index">
																										<td class="position_fix"
																											style="text-align:center;min-width:98px;">


																											<!-- <a class="btn bg-gradient-info btn-sm"  (click)="gotoBuildPage(dealerVehicleslistdata.vin);">Build</a>  -->
																											<a class="btn bg-gradient-info btn-sm"
																												style="line-height:15px;"
																												(click)="gotoBuildPage(dealerVehicleslistdata.vin,dealerVehicleslistdata.listedPrice,dealerVehicleslistdata.totalTaxes,dealerVehicleslistdata.allowableFinance,dealerVehicleslistdata.frontDocFee,dealerVehicleslistdata.fltv,dealerVehicleslistdata.bltv,dealerVehicleslistdata.effectiveCBB,dealerVehicleslistdata.dealerCost,dealerVehicleslistdata.termPeriod,dealerVehicleslistdata.beGrossAmount,dealerVehicleslistdata.feGrossAmount,dealerVehicleslistdata.othGrossAmount,dealerVehicleslistdata.licenceFee,dealerVehicleslistdata.isListPriceLock,dealerVehicleslistdata.feAccessory1,dealerVehicleslistdata.feAccessory2,dealerVehicleslistdata.feAccessory3,dealerVehicleslistdata.feMaximizedAmount,dealerVehicleslistdata.isDocFeeFront,i);">Build</a>

																											<button
																												class="btn bg-gradient-info btn-sm ml-1"
																												style="line-height: 21px;"
																												(click)="openImagesModelPopUp(ModelPopUpImages,dealerVehicleslistdata.vin)"><i
																													class="fa fa-image"></i></button>

																										</td>
																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.vin
																											}}</td>


																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.stockNumber
																											}}</td>
																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.carYear
																											}}</td>
																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.mileage
																											}}</td>
																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.make
																											}}</td>
																										<td
																											class="position_fix">
																											{{
																											dealerVehicleslistdata.model
																											}}</td>
																										<td class="TermPeriodStatus position_fix"
																											*ngIf="dealerVehicleslistdata.isTermPeriod !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.termPeriod
																											}}</td>
																										<td class="RegionStatus"
																											*ngIf="dealerVehicleslistdata.isRegionStatushide !=true">
																											{{
																											dealerVehicleslistdata.vehicleRegionName
																											}}</td>
																										<td class="CityStatus"
																											*ngIf="dealerVehicleslistdata.isCityStatushide !=true">
																											{{
																											dealerVehicleslistdata.dealerCity
																											}}</td>
																										<td class="BodyStatus"
																											*ngIf="dealerVehicleslistdata.isBodyStatushide !=true">
																											{{
																											dealerVehicleslistdata.body
																											}}</td>
																										<td class="TrimStatus"
																											*ngIf="dealerVehicleslistdata.isTrimhide !=true">
																											{{
																											dealerVehicleslistdata.trim
																											}}</td>

																										

																										<td class="TransmissionStatus"
																											*ngIf="dealerVehicleslistdata.isTransmission !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.transmission}}
																										</td>

																										<td class="FuelStatus"
																											*ngIf="dealerVehicleslistdata.isFuel !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.engineFuelType}}
																										</td>
																										
																										<td class="ListedPriceColumnStatus"
																											*ngIf="dealerVehicleslistdata.isListedPrice !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.listedPrice|
																											currency:
																											'USD' }}
																										</td>
																										<td class="DealerCostColumnStatus"
																											*ngIf="dealerVehicleslistdata.isDealerCost !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.dealerCost
																											| currency:
																											'USD' }}
																										</td>
																										<td class="ProjectedGrossColumnStatus"
																											*ngIf="dealerVehicleslistdata.isProjectedGross !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.projectedGross|
																											currency:
																											'USD'
																											}}</td>

																											<td class="ConditionColumnStatus"
																											*ngIf="dealerVehicleslistdata.isconditionhide !=true">
																											{{
																											dealerVehicleslistdata.condition
																											}}</td>
																											<td class="MaxPaycallColumnStatus"
																											*ngIf="dealerVehicleslistdata.isMaxPaycall !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.calculatedPayCall
																											| currency:
																											'USD'}}</td>

																										<td class="EffectiveCBBStatus"
																											*ngIf="dealerVehicleslistdata.isEffectiveCBB !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.effectiveCBB
																											| currency:
																											'USD' }}
																										</td>



																										<td class="TotalFLTVStatus"
																											*ngIf="dealerVehicleslistdata.isTotalFLTV !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.totalFLTV
																											| currency:
																											'USD' }}
																										</td>





																										<td class="TotalBLTVStatus"
																											*ngIf="dealerVehicleslistdata.isTotalBLTV !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.totalBLTV
																											| currency:
																											'USD' }}
																										</td>

																										<td class="TotalTLVStatus"
																											*ngIf="dealerVehicleslistdata.isTotalTLV !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.tlv
																											| currency:
																											'USD' }}
																										</td>


																										<td class="LenderFeeStatus"
																											*ngIf="dealerVehicleslistdata.isLenderFee !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.lenderFee
																											| currency:
																											'USD' }}
																										</td>
																										<td class="FrontDocFeeStatus"
																											*ngIf="dealerVehicleslistdata.isFrontDocFee !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.frontDocFee
																											| currency:
																											'USD' }}
																										</td>

																										<td class="BackDocFeeStatus"
																											*ngIf="dealerVehicleslistdata.isBackDocFee !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.backDocFee
																											| currency:
																											'USD' }}
																										</td>

																										<td class="PPSAStatus"
																											*ngIf="dealerVehicleslistdata.isPPSA !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.ppsa
																											| currency:
																											'USD' }}
																										</td>

																										<td class="ReservesStatus"
																											*ngIf="dealerVehicleslistdata.isReserves !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.reserves
																											| currency:
																											'USD' }}
																										</td>




																										<td class="TaxRateStatus"
																											*ngIf="dealerVehicleslistdata.isTaxRate !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.taxRate
																											}}</td>


																										<td class="TotalPriceStatus"
																											*ngIf="dealerVehicleslistdata.isTotalPrice !=true"
																											style="text-align: right;display: none;">
																											{{
																											dealerVehicleslistdata.totalPrice
																											| currency:
																											'USD' }}
																										</td>


																										<td class="TotalCostStatus"
																											*ngIf="dealerVehicleslistdata.isTotalCost !=true"
																											style="text-align: right;display: none;">
																											{{
																											dealerVehicleslistdata.totalCost
																											| currency:
																											'USD' }}
																										</td>


																										<td class="TotalTaxStatus"
																											*ngIf="dealerVehicleslistdata.isTotalTax !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.totalTaxes
																											| currency:
																											'USD' }}
																										</td>


																										<td class="AmountToBeFinancedStatus"
																											*ngIf="dealerVehicleslistdata.isAmountToBeFinanced !=true"
																											style="text-align: right;display: none;">
																											{{
																											dealerVehicleslistdata.amountToBeFinanced
																											| currency:
																											'USD' }}
																										</td>


																										<td class="AllowableFinanceStatus"
																											*ngIf="dealerVehicleslistdata.isAllowableFinance !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.allowableFinance
																											| currency:
																											'USD' }}
																										</td>

																										<td class="MinFinanceStatus"
																											*ngIf="dealerVehicleslistdata.isMinFinance !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.minFinance
																											| currency:
																											'USD' }}
																										</td>


																										<td class="MinPriceStatus"
																											*ngIf="dealerVehicleslistdata.isMinPrice !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.minPrice
																											| currency:
																											'USD' }}
																										</td>

																										<td class="MinCostStatus"
																											*ngIf="dealerVehicleslistdata.isMinCost !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.minCost
																											| currency:
																											'USD' }}
																										</td>

																										<td class="MinGrossStatus"
																											*ngIf="dealerVehicleslistdata.isMinGross !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.minGross
																											| currency:
																											'USD' }}
																										</td>

																										<td class="MinTaxesStatus"
																											*ngIf="dealerVehicleslistdata.isMinTaxes !=true"
																											style="text-align: right;display: none;">
																											{{
																											dealerVehicleslistdata.minTaxes
																											| currency:
																											'USD' }}
																										</td>


																										<td class="FLTVnewStatus"
																											*ngIf="dealerVehicleslistdata.isFLTVnew !=true"
																											style="text-align: right;display: none;">
																											{{
																											dealerVehicleslistdata.fltVnew}}
																										</td>

																										<td class="FLTVStatus"
																											*ngIf="dealerVehicleslistdata.isFLTV !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.fltv}}
																										</td>

																										<td class="BLTVStatus"
																											*ngIf="dealerVehicleslistdata.isBLTV !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.bltv}}
																										</td>



																										<td class="ColorStatus"
																											*ngIf="dealerVehicleslistdata.isColor !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.colour}}
																										</td>


																										<!-- <td class="TransmissionStatus"
																											*ngIf="dealerVehicleslistdata.isTransmission !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.transmission}}
																										</td>

																										<td class="FuelStatus"
																											*ngIf="dealerVehicleslistdata.isFuel !=true"
																											style="text-align: right;">
																											{{
																											dealerVehicleslistdata.engineFuelType}}
																										</td> -->


																										<td class=""> {{
																											dealerVehicleslistdata.stockInDays
																											}}</td>

																										<td class=""
																											*ngIf="ShowAdvce ==='true'">
																											{{
																											dealerVehicleslistdata.dealerAdvance
																											|
																											currency:
																											'USD'
																											}}</td>
																										<td class=""
																											style="display: none;">
																											{{
																											dealerVehicleslistdata.feAccessory1
																											|currency:'USD'
																											}}</td>
																										<td class=""
																											style="display: none;">
																											{{
																											dealerVehicleslistdata.feAccessory2
																											|currency:'USD'
																											}}</td>
																										<td class=""
																											style="display: none;">
																											{{
																											dealerVehicleslistdata.feAccessory3
																											|currency:'USD'
																											}}</td>
																										<td class=""
																											style="display: none;">
																											{{
																											dealerVehicleslistdata.feMaximizedAmount
																											|currency:'USD'
																											}}</td>
																									</tr>
																								</tbody>
																							</table>

																							<div id="listviewblock"
																								style="display: none;"
																								class="col-12 mt-2"
																								id="listView-{{i}}">

																								<div class="row">
																									<div
																										class="col-lg-4 col-md-12 col-12">
																										<div
																											class="row">
																											<label
																												class="col-form-label col-auto">Sorting
																												Filter</label>
																											<div
																												class="col-auto">
																												<select
																													class="form-control"
																													#makeSelect
																													[(ngModel)]="selectedMake"
																													(change)='SortDropDown($event.target.value,i)'>
																													<option
																														value="0">
																														Please
																														select
																													</option>
																													<option
																														value="vin">
																														VIN
																													</option>
																													<option
																														value="stockNumber">
																														Stock
																														No
																													</option>
																													<option
																														value="carYear">
																														Year
																													</option>
																													<option
																														value="mileage">
																														Mileage
																													</option>
																													<option
																														value="make">
																														Make
																													</option>
																													<option
																														value="model">
																														Model
																													</option>
																													<option
																														value="termPeriod">
																														Term
																														Period
																													</option>
																												</select>
																											</div>
																										</div>
																									</div>
																								</div>
																								<hr class="my-2" />
																								<div class="row">
																									<div class="col-xl-3 col-lg-4 col-md-6 col-12"
																										*ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchtablearray[i].searchtext | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index">

																										<div
																											class="product-card">
																											<div
																												class="product-image">



																												<ngb-carousel
																													#currSlide
																													[data-interval]="false"
																													[interval]="false"
																													[showNavigationArrows]="true">
																													<ng-template
																														ngbSlide
																														*ngFor="let image of dealerVehicleslistdata.imagedataarray">
																														<div (click)="onSlideClicked(LightboxModal,dealerVehicleslistdata)"
																															class="wrapper">
																															<img [src]="image.image"
																																alt="Unit Images">
																														</div>
																														<div
																															class="carousel-caption">

																														</div>
																													</ng-template>

																												</ngb-carousel>



																												<div
																													class="product-location">
																													<a
																														href="javascript:void(0)">
																														<i
																															class="fas fa-map-marker-alt"></i>
																														<span>{{
																															dealerVehicleslistdata.vehicleRegionName}}
																														</span>
																													</a>
																												</div>
																											</div>
																											<div
																												class="product-content">
																												<div
																													class="vehicleInformation">
																													<div
																														class="row align-items-start justify-content-between mx-0">
																														<h6
																															class="vehicle-details mb-0">

																															{{
																															dealerVehicleslistdata.carYear
																															}}
																															{{dealerVehicleslistdata.make
																															}}
																															{{
																															dealerVehicleslistdata.model}}
																															{{
																															dealerVehicleslistdata.trim
																															}}


																														</h6>
																														<div
																															class="price-area">
																															<div id="listViewGrossPrice_{{i}}"
																																class="form-group col-12 mb-0">
																																<div *ngIf="ListViewListPrice ==='true'"
																																	class="row justify-content-between align-items-center">
																																	<b
																																		class="price-area-subtitle">Projected
																																		Gross</b>
																																	<span
																																		class="mb-0 pricecode"><b>{{
																																			dealerVehicleslistdata.projectedGross|
																																			currency:
																																			'USD'}}</b>
																																	</span>
																																</div>

																																<div *ngIf="ListViewListPrice ==='false'"
																																	class="row justify-content-between align-items-center">
																																	<b
																																		class="price-area-subtitle">Listed
																																		Price
																																	</b>
																																	<span
																																		id="listSpanListPriceView_{{i}}"
																																		class="mb-0 pricecode"><b>{{
																																			dealerVehicleslistdata.listedPrice|
																																			currency:
																																			'USD'}}</b>
																																	</span>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																												<ul
																													class="product-features">
																													<li>
																														<img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/milage_icon.svg"
																															alt="product icon">
																														{{
																														dealerVehicleslistdata.mileage}}
																													</li>
																													<li>
																														<img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/info_icon.svg"
																															alt="product icon">
																														{{
																														dealerVehicleslistdata.transmission}}
																													</li>
																													<li>
																														<img src="https://www.drivco-wp.egenslab.com/wp-content/themes/drivco/assets/img/icons/engine_icon.svg"
																															alt="product icon">
																														{{
																														dealerVehicleslistdata.engineFuelType}}
																													</li>
																												</ul>
																												<div
																													class="othervehicledetails">
																													<div
																														class="w-100">
																														<div
																															class="form-group col-12">

																															<div *ngIf="ListViewListPrice ==='true'"
																																class="row justify-content-between align-items-center">
																																<label><b>List
																																		Price</b></label>
																																<span
																																	class="vehiclevalue text-primary">{{
																																	dealerVehicleslistdata.listedPrice|
																																	currency:
																																	'USD'}}</span>
																															</div>
																														</div>
																														<div
																															class="form-group col-12">
																															<div
																																class="row justify-content-between align-items-center">
																																<label><b>Term</b></label>
																																<span
																																	class="vehiclevalue">{{
																																	dealerVehicleslistdata.termPeriod}}</span>
																															</div>
																														</div>
																														<div
																															class="form-group col-12">
																															<div
																																class="row justify-content-between align-items-center">
																																<label><b>Condition</b>
																																</label>
																																<span
																																	class="vehiclevalue">{{
																																	dealerVehicleslistdata.condition}}</span>
																															</div>
																														</div>



																														<div
																															class="form-group col-12">
																															<div
																																class="row justify-content-between align-items-center">
																																<label><b>CBB</b></label>
																																<span
																																	class="vehiclevalue">
																																	{{
																																	dealerVehicleslistdata.effectiveCBB|
																																	currency:
																																	'USD'}}</span>
																															</div>
																														</div>
																														<div
																															class="form-group col-12">
																															<div
																																class="row justify-content-between align-items-center">
																																<label><b>Max
																																		PayCall</b></label>
																																<span
																																	class="vehiclevalue">{{
																																	dealerVehicleslistdata.calculatedPayCall|
																																	currency:
																																	'USD'}}</span>
																															</div>
																														</div>
																													</div>
																													<div id="extraDetail-{{dealerVehicleslistdata.vin}}"
																														style="display: none;"
																														class="w-100 mt-3 moredetails">
																														<div
																															class="row">
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>VIN</b></label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.vin}}
																																	</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Stock
																																			No</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.stockNumber}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Total
																																			FLTV</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.totalFLTV
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Total
																																			BLTV</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.totalBLTV
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Total
																																			TLV</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.tlv
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Lender
																																			Fee</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">
																																		{{
																																		dealerVehicleslistdata.lenderFee
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Front
																																			Doc
																																			Fee</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.frontDocFee
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Back
																																			Doc
																																			Fee</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">
																																		{{
																																		dealerVehicleslistdata.backDocFee
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>PPSA</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">
																																		{{
																																		dealerVehicleslistdata.ppsa
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Reserves</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.reserves
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Total
																																			Tax</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">
																																		{{
																																		dealerVehicleslistdata.totalTaxes
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>AmountToBeFinanced</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.amountToBeFinanced
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Allowable
																																			Finance</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.allowableFinance
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Min
																																			Finance</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.minFinance
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Min
																																			Price</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.minPrice
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Min
																																			Cost</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.minCost
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Min
																																			Gross</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.minGross
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Min
																																			Taxes</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.minTaxes
																																		|
																																		currency:
																																		'USD'
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>FLTVnew</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.fltVnew
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>FLTV</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.fltv
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>BLTV</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.bltv
																																		}}</span>
																																</div>
																															</div>
																															<div
																																class="form-group col-12">
																																<div
																																	class="row justify-content-between align-items-center">
																																	<label><b>Stock
																																			In
																																			Days</b>
																																	</label>
																																	<span
																																		class="vehiclevalue">{{
																																		dealerVehicleslistdata.stockInDays
																																		}}</span>
																																</div>
																															</div>
																														</div>
																													</div>
																												</div>
																												<div
																													class="product-price w-100 mt-2">
																													<div
																														class="col mx-auto">
																														<div
																															class="row align-items-center">
																															<button
																																id="buttonShowDetail-{{dealerVehicleslistdata.vin}}"
																																(click)=toggleDisplayDiv(dealerVehicleslistdata.vin)
																																type="button"
																																title="More Information"
																																class="btn text-primary border mx-auto">
																																<i
																																	class="fas fa-chevron-down"></i>
																															</button>
																															<button
																																style="display: none;"
																																id="buttonHideDetail-{{dealerVehicleslistdata.vin}}"
																																(click)=hideExtraDetail(dealerVehicleslistdata.vin)
																																type="button"
																																title="Hide Information"
																																class="btn text-primary border mx-auto">
																																<i
																																	class="fas fa-chevron-up"></i>
																															</button>
																														</div>

																														<button
																															class="btn btn-info buildcarbtn"
																															title="Build">
																															<a style="line-height:15px;"
																																(click)="gotoBuildPage(dealerVehicleslistdata.vin,dealerVehicleslistdata.listedPrice,dealerVehicleslistdata.totalTaxes,dealerVehicleslistdata.allowableFinance,dealerVehicleslistdata.frontDocFee,dealerVehicleslistdata.fltv,dealerVehicleslistdata.bltv,dealerVehicleslistdata.effectiveCBB,dealerVehicleslistdata.dealerCost,dealerVehicleslistdata.termPeriod,dealerVehicleslistdata.beGrossAmount,dealerVehicleslistdata.feGrossAmount,dealerVehicleslistdata.othGrossAmount,dealerVehicleslistdata.licenceFee,dealerVehicleslistdata.isListPriceLock,dealerVehicleslistdata.feAccessory1,dealerVehicleslistdata.feAccessory2,dealerVehicleslistdata.feAccessory3,dealerVehicleslistdata.feMaximizedAmount,dealerVehicleslistdata.isDocFeeFront,i);">Build</a>
																														</button>
																													</div>
																												</div>
																											</div>
																										</div>
																									</div>
																								</div>
																							</div>

																							<!-- <button (click)="watchdata(Lenderformarraydata.get('inventaryarray').controls[0].value.id,i)">test </button> -->


																							<br>
																							<ngx-spinner
																								name="Lender_{{Lenderformarraydata.value.Lenderid}}"
																								bdColor="rgba(0, 0, 0, 0.8)"
																								size="small"
																								color="#fff"
																								type="square-jelly-box"
																								[fullScreen]="false">
																								<p style="color: white">
																									Loading... </p>
																							</ngx-spinner>


																							<!-- <div class="row justify-content-between align-items-center">
												<div class="col">

												<ngb-pagination name="paging-{{i}}" [collectionSize]="Lenderformarraydata.get('inventaryarray').controls.length" [(page)]="Lenderformarraydata.value.page" [maxSize]="5"
													[rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
												</div>

											</div> -->
																						</div>
																						<div
																							class="row justify-content-between align-items-center mt-3">
																							<div class="col">

																								<ngb-pagination
																									name="paging-{{i}}"
																									[collectionSize]="Lenderformarraydata.get('inventaryarray').controls.length"
																									[(page)]="Lenderformarraydata.value.page"
																									[maxSize]="5"
																									[rotate]="true"
																									[ellipses]="false"
																									[boundaryLinks]="true"></ngb-pagination>
																							</div>

																						</div>
																					</div>


																				</div>
																			</div>
																		</div>
																	</div>
																</div>

															</div>

														</div>

														<!--Build grid close-->
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>






						</div>

					</div>

				</div>
			</div>

			<!-- <app-footer id="secondfooter"></app-footer> -->
		</div>


	</form>




	<!-- here we popup tables on custom view start-->

	<ng-template #Customtablepopup>
		<div id="pdf-viewer-outer" class="modal-body">
			<h5>Customer View </h5>
			<div class="row mt-2" id="dealerchckbox">

				<table class="table table-bordered" style="width:100%">
					<thead>
						<tr>
							<th class="position_fix" style="text-align:center;">
								VIN
							</th>
							<th class="position_fix" style="text-align:center;">
								Stock No
							</th>
							<th class="position_fix" style="text-align:center;">
								Year
							</th>
							<th class="position_fix" style="text-align:center;">
								Mileage
							</th>
							<th class="position_fix" style="text-align:center;">
								Make
							</th>
							<th class="position_fix" style="text-align:center;">
								Model
							</th>
							<th class="position_fix" style="text-align:center;">
								Region
							</th>
							<th class="position_fix" style="text-align:center;">
								Body
							</th>
							<th class="position_fix" style="text-align:center;">
								Trim
							</th>
							<th class="position_fix" style="text-align:center;">
								Condition
							</th>

					</thead>

					<tbody>

						<tr *ngFor="let cutomerview of CustomerviewTabledatasource;let y = index">

							<td class="position_fix" style="text-align:center;">
								{{cutomerview.vin}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.stockNumber}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.carYear}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.mileage}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.make}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.model}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.vehicleRegionName}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.body}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.trim}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.condition}}
							</td>

						</tr>

					</tbody>
				</table>

			</div>

		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-danger btn-sm" (click)="decline()">Close</button>
			<!-- <button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedRegions()">Done</button> -->
		</div>
	</ng-template>

	<!-- custom view end -->

	<button #regioncheckboxpopupbuttonToBeClicked style="display:none;"
		(click)="regioncheckboxpopup(regioncheckbox,'')"></button>


	<ng-template #regioncheckbox>
		<div id="pdf-viewer-outer" class="modal-body">
			<h5>Region List </h5>
			<hr />
			<div class="row mt-2" id="dealerchckbox">

				<!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
				<div class="col-12 listoption">
					<input type="checkbox" name="chckboxall" (click)="Checkallregions($event)" id="checkall"
						[(ngModel)]="SelectedAllCheckregion" />
					<label style="font-size:13px;" class="ml-1">
						<h6 class="mb-0" style="font-size:14px;"></h6>Select All
					</label>
				</div> <br />

				<div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of regionlistName ; let i = index">
					<input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" />
					{{item.globalCodeName}}
				</div>

			</div>

		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-danger btn-sm" (click)="CloseRegionList(regioncheckbox)">Back</button>
			<button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedRegions()">Done</button>
		</div>
	</ng-template>

	<button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
		(click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


	<ng-template #dealercheckbox>
		<div id="pdf-viewer-outer" class="modal-body">
			<h5>My Inventory </h5>
			<hr />
			<div class="row mt-2" id="dealerchckbox">

				<!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
				<div class="col-12 listoption">
					<input type="checkbox" name="chckboxall" [(ngModel)]="SelectedAllCheck"
						(click)="Checkalldealer($event)" id="checkall" />
					<label style="font-size:13px;" class="ml-1">
						<h6 class="mb-0" style="font-size:14px;"></h6>Select All
					</label>
				</div> <br />

				<div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
					<input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" /> {{item.dealerName}}
				</div>

			</div>

		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="decline()">Back</button>
			<button type="button" class="btn btn-success btn-sm mr-1 rounded-pill"
				(click)="GetselectedDealer()">Done</button>
		</div>
	</ng-template>


	<button #buildcarpopupbuttonToBeClicked style="display:none;"
		(click)="Dealercheckboxpopup(buildCarConfirmModel,'')"></button>

	<ng-template #buildCarConfirmModel>
		<div class="modal-header" style="background-color: black;">
			<h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Notification</h5>
			<button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

			<section class="generate-report col">
				Do you want to Open Multiple Tabs for Comparisons for Selected Unit/Vehicle – Each Tab for Selected
				Lenders?
			</section>


		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-success btn-sm text-white" title="Open tab for All lenders"
				(click)="OpenMultipletab()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
			</button>

			<button class="btn btn-danger btn-sm text-white" title="Lender" (click)="NoForMultipleTab()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
			</button>


		</div>
	</ng-template>


	<ng-template #ModelPopUpImages let-modal>
		<div class="modal-header" style="background-color: black;">
			<h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Images</h5>
			<button type="button" class="close" style="color: white;" (click)="RefreshImageList();modal.dismiss()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

			<section class="generate-report imageSize row">

				<div class="card card-raised mt-2 mx-auto">
					<div class="card-header card-raised bg-info text-white p-2">
						<h6 class="mb-0">Auto Upload Images</h6>
					</div>
					<div class="card-body p-2">


						<div *ngIf="errorshowAuto=='false'"
							style="width:750px; height:485px; text-align:center;margin:0; padding:0;">
							<ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
								[imageSize]="{width: '100%', height:200}" slideImage="1">
							</ng-image-slider>

						</div>

						<div *ngIf="errorshowAuto=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
							{{errorMessageAuto}}
						</div>
					</div>
				</div>

			</section>

			<section class="manual-uploadImage">
				<div class="card card-raised  mt-2 mx-auto">
					<div class="card-header card-raised bg-info text-white p-2">
						<h6 class="mb-0">Manual Upload Images</h6>
					</div>
					<div class="card-body p-2" *ngIf="errorshow=='false'">
						<div class="profile-icon" *ngFor="let element of customimagesrc">
							<img [src]="element.imgsrc" width="150" height="150" />
						</div>
					</div>

					<div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
						{{errorMessage}}
					</div>

				</div>
			</section>

		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-danger ml-2"
				(click)="RefreshImageList();modal.dismiss()">Cancel</button>
		</div>
	</ng-template>


	<a #LockListConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template)"><i class="fa fa-upload"
			triggers="mouseenter:mouseleave" popoverTitle="lock List Price" placement="top"
			ngbPopover="Lock List Price"></i></a>
	<ng-template #template>
		<div class="modal-body text-center">
			<span class="cross-icon"><i class="fas fa-hand-holding-usd"></i></span>
			<p>Locking List Price will reduce the number of search results. Would you like to continue?</p>
			<p></p>
			<button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
				(click)="confirmLockPrice()">Yes</button>
			<button type="button" class="btn btn-sm btn-danger rounded-pill"
				(click)="declineLockListPrice()">No</button>
		</div>
	</ng-template>

	<ng-template #DeductionModel>
		<div class="modal-header" style="background-color: black;">
			<h5 class="modal-title" id="DeductionModelLabel" style="color: white;">Advance Percentage</h5>
			<button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="row mb-3">
				<div class="col-lg-4 col-md-6">
					<input readonly class="form-control" [(ngModel)]="DeductionTextvalue"
						[ngValue]="DeductionTextvalue" />

					<span id="warningdeduction" style="color:red;" *ngIf="deductionfeedisplay">Your deduction fee limit
						has ben exceeded to 40%</span>
				</div>
			</div>

			<section class="generate-report col-12">

				<div id="goplan_block" class="row">
					<div class="form-check">
						<input (change)="DeductionSelectvalue($event)" class="custom-control-input" id="1" value="1"
							type="checkbox" />
						<label class="form-check-label" for="1"> Non Garnishable</label>
					</div>
					<div class="form-check">
						<input (change)="DeductionSelectvalue($event)" class="custom-control-input" id="2" value="2"
							type="checkbox" />
						<label class="form-check-label" for="2"> Self Employed </label>
					</div>
					<div class="form-check">
						<input (change)="DeductionSelectvalue($event)" class="custom-control-input" id="3" value="3"
							type="checkbox" />
						<label class="form-check-label" for="3"> Out Of Service area </label>
					</div>
					<div class="form-check">
						<input (change)="DeductionSelectvalue($event)" class="custom-control-input" id="4" value="4"
							type="checkbox" />
						<label class="form-check-label" for="4">At least two repossessions</label>
					</div>
				</div>
			</section>


		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-success btn-sm text-white" title="" (click)="deductionfeeYes()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
			</button>

			<button class="btn btn-danger btn-sm text-white" title="" (click)="Nodeductionfee()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
			</button>


		</div>
	</ng-template>

	<!-- Add New Client -->



	<ng-template #AddclientModel>
		<div class="modal-header" style="background-color: black;">
			<h5 class="modal-title" id="DeductionModelLabel" style="color: white;">Add Client</h5>
			<button type="button" class="close" style="color: white;" #closebutton (click)="declineCustomer()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<div class="row mb-2">
				<div class="col-12">
					<span class="note-text bg-warning">
						Please click on Add Client button for adding new client.
						<button type="button" (click)="openAddcustomerModel(ModelAddUser,'')" title="Add client"
							class="btn"><i class="fas fa-plus"></i></button>
					</span>
				</div>
			</div>
			<section class="generate-report col-lg-8 col-md-10 col-12 mt-3 mx-auto">
				<div id="add_client" class="row">
					<label style="font-size:14px;">Select Client</label>
					<select #customerdropdown (change)="selectedCustomer($event)" [value]="customer"
						class="form-control">
						<option value="default">Please Select</option>
						<option *ngFor="let customer of customerlist" [value]="customer.id">
							{{customer.firstName}} {{customer.lastName}}
						</option>
					</select>
				</div>
			</section>
		</div>

		<div class="modal-footer">
			<button type="button" class="btn btn-success btn-sm text-white" title="Save Customer"
				(click)="savecustomerafterselection()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save </button>
			<button class="btn btn-danger btn-sm text-white" title="Lender" (click)="declineCustomer()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No </button>
		</div>
	</ng-template>


	<!--Add customer-->


	<ng-template #ModelAddUser let-modal>
		<div class="modal-header" style="background-color: black;">
			<h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add Customer</h5>
			<button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
				aria-label="Close">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">

			<section class="generate-report">
				<div class="row">
					<div class="form-group col-md-4 col-lg-4">
						<label for="">First Name</label>
						<input type="text" maxlength="20" [(ngModel)]="firstname" class="form-control"
							placeholder="First Name" (keypress)="ValidTextBox($event)" />
						<!-- <div *ngIf="submittedcustomer && isValidFirstname">
					<div>First Name is required</div>
				  </div> -->
					</div>

					<div class="form-group col-md-4 col-lg-4">
						<label for="">Middle Name</label>
						<input type="text" maxlength="20" [(ngModel)]="middlename" class="form-control"
							placeholder="Middle Name" (keypress)="ValidTextBox($event)" />

					</div>

					<div class="form-group col-md-4 col-lg-4">
						<label for="">Last Name</label>
						<input type="text" maxlength="20" [(ngModel)]="lastname" class="form-control"
							placeholder="last Name" (keypress)="ValidTextBox($event)" />

					</div>
				</div>
				<hr />
				<div class="row">
					<div class="form-group col-md-12 col-lg-12">
						<label for="">Address</label>
						<textarea type="text" maxlength="20" [(ngModel)]="address" class="form-control" placeholder=""
							(keypress)="ValidTextBox($event)"></textarea>

					</div>
				</div>
				<div class="row">
					<div class="form-group col-md-4 col-lg-4">
						<label for="">City</label>
						<input type="text" [(ngModel)]="city" class="form-control" placeholder="City"
							(keypress)="ValidTextBox($event)" />

					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Region</label>
						<select class="form-control" name="region" [(ngModel)]="region">
							<option value="0" selected>Select Region </option>
							<option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
								{{grouplistdata.globalCodeName}}</option>
						</select>

					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Country</label>
						<input type="text" [(ngModel)]="country" class="form-control" placeholder="country"
							(keypress)="ValidTextBox($event)" />

					</div>
				</div>
				<hr />
				<div class="row">
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Postal Code</label>
						<input type="text" [(ngModel)]="postalcode" class="form-control" placeholder="Postal code"
							(keypress)="ValidTextBox($event)" maxlength="6" />

					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Phone Number</label>
						<input type="text" phoneMask [(ngModel)]="phonenumber" maxlength="14" class="form-control"
							placeholder="Phone Number" (keypress)="keyPressCheck($event)" />


					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Email</label>
						<input type="text" lowercase [(ngModel)]="email" class="form-control email"
							placeholder="Email" />

					</div>
				</div>

				<hr />
				<div class="row">
					<div class="form-group col-md-4 col-lg-4">
						<label for="">DL Number</label>
						<input type="text" [(ngModel)]="dlnumber" class="form-control" placeholder="DL Number"
							(keypress)="ValidTextBox($event)" />

					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">Other ID Number</label>
						<input type="text" [(ngModel)]="otherid" class="form-control" placeholder="other id"
							(keypress)="ValidTextBox($event)" />

					</div>
					<div class="form-group col-md-4 col-lg-4">
						<label for="">GST Number</label>
						<input type="text" [(ngModel)]="gst" class="form-control" placeholder="GST No"
							(keypress)="ValidTextBox($event)" />

					</div>
				</div>
				<hr />
				<div class="row">
					<div class="form-group col-md-6 col-lg-6">
						<label for="">Insurance</label>
						<input type="text" [(ngModel)]="insurance" class="form-control" placeholder="Insurance NO"
							(keypress)="ValidTextBox($event)" />

					</div>
				</div>
			</section>
		</div>
		<div class="modal-footer">
			<div class="row justify-content-between align-items-center w-100">
				<div class="col-auto"></div>
				<div class="col-auto">
					<button class="btn btn-primary btn-sm text-white" (click)="onSubmitCustomer();">
						<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
					</button>



					<button class="btn btn-danger btn-sm text-white ml-2" (click)="CloseAddmodel()">
						<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
					</button>
				</div>
			</div>
		</div>
	</ng-template>


	<!-- Modal -->

	<ng-template #LightboxModal let-modal>
		<div id="VehicleImagesModel">
			<div class="modal-header" style="background-color:none;border-bottom:none;">
				<!-- <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Images </h5> -->
			</div>
			<div class="modal-body">
				<button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
					aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
				<ngb-carousel>
					<ng-template ngbSlide *ngFor="let imagelight of lightboxModel.imagedataarray">
						<div class="wrapper">
							<img [src]="imagelight.image" alt="Random first slide">
						</div>
						<div class="carousel-caption">
							<!-- <h3>{{ image.title }}</h3>
					<p>{{ image.short }}</p> -->
						</div>
					</ng-template>
				</ngb-carousel>
			</div>
			<!-- <div class="modal-footer">
		<div class="row justify-content-between align-items-center w-100">
			<div class="col-auto"></div>
			<div class="col-auto">




			<button class="btn btn-danger btn-sm text-white ml-2" (click)="decline()">
				<i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
			</button>
			</div>
		</div>
		</div> -->
		</div>
	</ng-template>




</section>