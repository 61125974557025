import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AIXLenderInfo } from '@app/_models/AIXLenderInfo';
import { AIXLenders } from '@app/_models/AIXLenders';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { AlertService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { AccountService } from '@app/_services/account.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalService,BsModalRef } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationExtras } from "@angular/router";
import {  TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-findcar-detail',
  templateUrl: './findcar-detail.component.html',
  styleUrls: ['./findcar-detail.component.less']
})


export class FindcarDetailComponent implements OnInit {
  findCarForm: FormGroup;
  private notifier: NotifierService;
  @ViewChild('dealercheckboxpopupbuttonToBeClicked') buttonToBeClicked: ElementRef
  modalRef: BsModalRef;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private globalcodeService: GlobalcodeService,
    private accountService: AccountService,
    private lenderService: LenderService,
    private addgroupService: AddgroupService,
    private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
  ) {
    this.notifier = notifier;
    debugger;
    this.accountService.SaveActivityLog("");
  }
  @ViewChild('BuildNewButton') buildNewButton;
  selectedgroupId: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;

  // Dealer ID from page

  dealeridDropDown:any;
  groupIdPage:any;

  Provincelist = [];
  GroupLists = [];
  lenderlists: AIXLenders[] = [];
  province: any;
  dealerName: any;
  groupName: any;
  dealerlistName = [];
  lenderKeyList = [];
  dealerVehicleslist: DealerVehicles[] = [];
  Did = 0;
  TotalRecord: any;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  displayList: any;
  isDesc: boolean = false;
  lenderIdValue: any;
  DisplayFilters: any;
  LenderNameValue: any;

  Lenderkey: any;
  AithrHubKey: any;
  LenderPayCall: any;
  LenderInterestRate: any;
  ACVValueControl: any;
  LoanAmountControl: any;
  DownPaymentAmountControl: any;
  buttonloader: boolean = false;
  PLanType:any;
  PLanTypeName:any;
    TotalFrontproducts: any;
    TotalFrontcost: any;
    TotalBackproducts: any;
    TotalBackcost: any;
  //selectedGroupIds: string[];
  selectedGroupIds: string;
  selectedGroupId: any;
  SelectedGroupName: any;
  selectedDealerId:any;
  //selectedDealerIds: string[];

  selectedDealerIds: string;

  dealersmultiselectarray:any[];
  sort(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  delaeridforserver = [];
 // FOR Disable group down
 groupDisable:any;

  ngOnInit(): void {
    debugger;
    this.Createform();

    this.GetProvincelist();
    this.GetGroupList();
    this.AithrHubKey ="0";
    this.LenderPayCall=0;
    this.LenderInterestRate=0;
    this.ACVValueControl=0;
    this.LoanAmountControl=0;
    this.DownPaymentAmountControl=0;
    this.Getlenderslist();
    this.displayList = "false";
    this.DisplayFilters = "false";
    this.rolevalue = localStorage.getItem('UserFullRole');
    if (this.rolevalue == "Dealer") {
      this.groupDisable = "true";
    } else {
      this.groupDisable = "false";
    }
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');


    this.province = "0";
    this.dealerName = "0";
    this.groupName = "0";


    debugger;
//set drop down
this.selectedGroupIds=localStorage.getItem('GroupName');
this.selectedGroupId=localStorage.getItem('groupid');
//this.GetDealerNamebyGroupId(groupid)
this.GetDealers(this.selectedGroupId);
this.selectedDealerIds= this.dealerNamevalue;
this.province= localStorage.getItem('Province');


//this.selectedDealerIds='18','17','15';
//this.selectedDealerIds=localStorage.getItem('DealerId');
//this.province=localStorage.getItem('GroupName');

this.TotalFrontproducts=0;
this.TotalFrontcost=0;
this.TotalBackproducts=0;
this.TotalBackcost=0;


    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {

    } else {

    }
  }


  // function for open model pop up
  Dealercheckboxpopup(template: TemplateRef<any>, itemid: any) {
    debugger;

    this.modalRef = this.confirmModalService.show(template, {
        class: 'modal-lg modal-dialog-centered', backdrop: 'static',
        keyboard: false
    });
}


  // getValues(val: any) {
  //   debugger;
  //   this.selectedDealerId = val.id;
  //   this.dealeridDropDown = val.id;
  //   this.selectedGroupId = val.dGroupId;
  // }

  // function for Getting Cars
  onCheckboxChange(lenderId: any, LenderName: any, obj: any) {
    if (obj.currentTarget.checked == true) {
      this.lenderIdValue = lenderId;
      localStorage.setItem('lenderIdValue', this.lenderIdValue);
      this.LenderNameValue = LenderName;
      this.DisplayFilters = "true";
    } else {
      this.DisplayFilters = "false";
    }
    this.GetLenderKeys(this.lenderIdValue, this.LenderNameValue);
  }
  getUnitsByDealerNames() {
    this.displayList = "true";
   //this.GetAllInventoryListbyParametersfinal();
   this.GetAllInventoryListbyParameterForBothFindCars();
  }

  GetLenderKeys(LenderId: any, LenderName: any) {
    var modelGroup = {
      "id": LenderId,
      "LenderName": LenderName
    }
    this.manageInventoryService.getLenderKeys(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderKeyList = data;
        },
        error => {
        });


  }
  // send paramtere to other page
  gotoBuildPage(vin: any, listPrice: any,totalTaxes:any,allowableFinance:any) {
    debugger;
    if (this.DownPaymentAmountControl == "") {
      this.DownPaymentAmountControl = "0"
    }
    if (this.LenderPayCall == "") {
      this.LenderPayCall = "0"
    }
    if (this.LenderInterestRate == "") {
      this.LenderInterestRate = "0"
    }
    if (this.LoanAmountControl == "") {
      this.LoanAmountControl = "0"
    }
    if (this.ACVValueControl == "") {
      this.ACVValueControl = "0"
    }
    let builparameter = {
      LenderId: this.lenderIdValue, DealerId: this.dealeridDropDown,
      LenderKey: this.findCarForm.controls.Lenderkey.value,
      ProvinceId: this.findCarForm.controls.province.value,
      GroupNameId: this.groupIdPage,
      PayCall: this.LenderPayCall, InterestRate: this.LenderInterestRate,
      tradevalue: this.ACVValueControl, LoanAmount: this.LoanAmountControl,
      DownPayment: this.DownPaymentAmountControl,
      vin: vin, listPrice: listPrice,groupName:this.SelectedGroupName,
      totalTaxes:totalTaxes,
      IsAithrHub: this.AithrHubKey,
      DealerIDList:this.delaeridforserver,
      LenderNameValue:this.LenderNameValue,
      PlanType:this.PLanType,
      allowableFinance:allowableFinance
    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builparameter', JSON.stringify(builparameter));
  }

  GetAllInventoryListbyParametersfinal() {
    debugger;
    this.spinner.show();
    this.buttonloader = true;
    var discard = this.findCarForm.controls;
    if (this.findCarForm.controls.LenderPayCall.value == "") {
      this.LenderPayCall = "0";

    }

    if (this.findCarForm.controls.LenderInterestRate.value == "") {
      this.LenderInterestRate = "0";

    }

    if (this.findCarForm.controls.ACVValueControl.value == "") {
      this.ACVValueControl = "0";

    }
    if (this.findCarForm.controls.LoanAmountControl.value == "") {
      this.LoanAmountControl = "0";

    }
    if (this.findCarForm.controls.DownPaymentAmountControl.value == "") {
      this.DownPaymentAmountControl = "0";

    }

    var delaeridforserver=[];
    this.dealersmultiselectarray.forEach(element => {
  delaeridforserver.push(element.id);
});


    var modelGroup = {
      "LenderId": this.lenderIdValue.toString(),
      "DealerId":"0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray':delaeridforserver,
      "GroupNameId": this.selectedgroupId,
      "ProvinceId":this.findCarForm.controls.province.value,
      "isTaxExempt": true,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.findCarForm.controls.Lenderkey.value,
      "Paycall": this.LenderPayCall,
      "Interestrate": this.LenderInterestRate,
      "ACVvalues": this.ACVValueControl,
      "LoanAmountvalue": this.LoanAmountControl,
      "DownPaymentValue": this.DownPaymentAmountControl,
      "TotalFrontproducts": this.TotalFrontproducts,
      "TotalFrontcost": this.TotalFrontcost,
      "TotalBackproducts": this.TotalBackproducts,
      "TotalBackcost": this.TotalBackcost,
      "IsInventory": this.AithrHubKey
    }

    this.manageInventoryService.getAllInventoryListbyParametersFinal(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
          this.buttonloader = false;
        },
        error => {
        });
  }

  openBuildConfrimModel(targetModal,vin: any, listPrice: any,totalTaxes:any,allowableFinance:any) {
    debugger;
    if (this.DownPaymentAmountControl == "") {
      this.DownPaymentAmountControl = "0"
    }
    if (this.LenderPayCall == "") {
      this.LenderPayCall = "0"
    }
    if (this.LenderInterestRate == "") {
      this.LenderInterestRate = "0"
    }
    if (this.LoanAmountControl == "") {
      this.LoanAmountControl = "0"
    }
    if (this.ACVValueControl == "") {
      this.ACVValueControl = "0"
    }
    let builparameter = {
      LenderId: this.lenderIdValue, DealerId: this.dealeridDropDown,
      LenderKey: this.findCarForm.controls.Lenderkey.value,
      ProvinceId: this.findCarForm.controls.province.value,
      GroupNameId: this.groupIdPage,
      PayCall: this.LenderPayCall, InterestRate: this.LenderInterestRate,
      tradevalue: this.ACVValueControl, LoanAmount: this.LoanAmountControl,
      DownPayment: this.DownPaymentAmountControl,
      vin: vin, listPrice: listPrice,groupName:this.SelectedGroupName,
      totalTaxes:totalTaxes,
      IsAithrHub: this.AithrHubKey,
      DealerIDList:this.delaeridforserver,
      LenderNameValue:this.LenderNameValue,
      PlanType:this.PLanType,
      allowableFinance:allowableFinance
    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builparameter', JSON.stringify(builparameter));
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

  }
  CloseAddmodel()
  {
    this.modalRef.hide();
  }
  OpenNewTab() {
    this.modalRef.hide();
    this.buildNewButton.nativeElement.click();
  }
  GetKeyText(value) {
    this.PLanTypeName  = value;
   this.PLanType =this.lenderKeyList.filter(v => v.id == this.PLanTypeName)[0].planType;
  }
  GetAllInventoryListbyParameterForBothFindCars() {
    debugger;
    this.spinner.show();
    this.buttonloader = true;
    var discard = this.findCarForm.controls;
    if (this.findCarForm.controls.LenderPayCall.value == "") {
      this.LenderPayCall = "0";

    }

    if (this.findCarForm.controls.LenderInterestRate.value == "") {
      this.LenderInterestRate = "0";

    }

    if (this.findCarForm.controls.ACVValueControl.value == "") {
      this.ACVValueControl = "0";

    }
    if (this.findCarForm.controls.LoanAmountControl.value == "") {
      this.LoanAmountControl = "0";

    }
    if (this.findCarForm.controls.DownPaymentAmountControl.value == "") {
      this.DownPaymentAmountControl = "0";

    }

   // var delaeridforserver = [];
    this.dealersmultiselectarray.forEach(element => {
      this.delaeridforserver.push(element.id);
    });


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': this.delaeridforserver,
      "GroupNameId": this.selectedgroupId,
      "ProvinceId": this.findCarForm.controls.province.value,
      "isTaxExempt": true,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.findCarForm.controls.Lenderkey.value,
      "Paycall": this.LenderPayCall,
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": this.ACVValueControl,
      "LoanAmountvalue": this.LoanAmountControl,
      "DownPaymentValue": this.DownPaymentAmountControl,
      "IsInventory": this.AithrHubKey,
      "VIN": "",
      "MaximizeDeal": "0",
      "TradeInValue": "0",
      "TotalFrontproducts": this.TotalFrontproducts,
      "TotalFrontcost": this.TotalFrontcost,
      "FrontDocFee": "0",
      "TotalBackproducts": this.TotalBackproducts,
      "TotalBackcost": this.TotalBackcost,
      "ExtWarranty": "0",
      "GAP": "0",
      "JobLoss": "0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice": "0",
      "ScreenIntRate": "0",
      "ScreenTermPeriod": "0",
      "LoggedInUser":"",
      "LoggedInIP":"",
     "LoggedInSessionID":""
    }

    this.manageInventoryService.getAllInventoryListbyParameterForBothFindCars(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
          this.buttonloader = false;
        },
        error => {
        });
  }


  GetDealerNamebyGroupId(value: any) {
    debugger;
    if (value != null) {


      this.dealersmultiselectarray = [];
      this.selectedGroupId = value.globalCodeID;
      this.SelectedGroupName = value.globalCodeName
      this.groupIdPage = value.globalCodeID;

      this.GetDealers(this.selectedGroupId);
    }
  }


  // DealercheckboxtoggleSelection(i){
  //   debugger;
  //   this.dealerlistName.forEach((item, index) => {
  //     // if(index != i){
  //     //   this.dealerlistName[index].IsSelected = false
  //     // }

  //     if(index == i){
  //       var getvalue=this.dealerlistName[index];

  //      var k= {id: 6, dealerName: getvalue.dealerName}

  //       this.dealersmultiselectarray.push(k);
  //     }
  //   })
  //  }
  GetAithrHubUnits() {
    //this.GetAllInventoryListbyParametersfinal();
    this.GetAllInventoryListbyParameterForBothFindCars();
  }
   DealercheckboxtoggleSelection_old(e) {
    debugger;
  var  c_value=false;
    if (e.target.checked) {
      c_value = e.target.value;
      this.dealerlistName.push(c_value);
    } else {
      c_value = e.target.value;
      const index = this.dealerlistName.findIndex(item => item === c_value );
      this.dealerlistName.splice(index);
    }
}


  GetselectedDealer()
  {
    debugger;
    this.dealersmultiselectarray = this.getSelectedItem();
    var selecteditem=this.getSelectedItem();
    this.decline();
  }

  // get list of selected Items
  getSelectedItem() {
    return this.dealerlistName.filter(item => item.IsSelected === true);
  }

//checkall dealer
Checkalldealer(e)
{
debugger;
if(e.target.checked==true)
{
 //Assigning value selected false which will appera by deafult uncheck
 this.dealerlistName.forEach((item, index) => {
  this.dealerlistName[index].IsSelected = true
})
}else{
 //Assigning value selected false which will appera by deafult uncheck
 this.dealerlistName.forEach((item, index) => {
  this.dealerlistName[index].IsSelected = false
})
}

}


  GetDealers(groupId: any) {
    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerlistName=[]; //initialize

          //Assigning value selected false which will appera by deafult uncheck
          this.dealerlistName.forEach((item, index) => {
              this.dealerlistName[index].IsSelected = false
          })

          this.dealerlistName = data;
          this.dealersmultiselectarray = this.dealerlistName.filter(item => item.id == this.DealerIdvalue);
          var selectedchckbox=this.dealerlistName.filter(item => item.id == this.DealerIdvalue);
          selectedchckbox.forEach((item, index) => {
            this.dealerlistName[index].IsSelected = true
        })

          if(data!=null && data.length>4)
          {
            this.buttonToBeClicked.nativeElement.click();

          }
        },
        error => {
        });
  }

  decline(): void {
    this.modalRef.hide();
}

  GetGroupList() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          debugger;
          this.GroupLists = data;
        },
        error => {
        });
  }

  Createform() {
    this.findCarForm = new FormGroup({
      province: this.fb.control(''),
      dealerName: this.fb.control(''),
      groupName: this.fb.control(''),
      Lenderkey: this.fb.control(''),
      LenderPayCall: this.fb.control(''),
      LenderInterestRate: this.fb.control(''),
      ACVValueControl: this.fb.control(''),
      LoanAmountControl: this.fb.control(''),
      DownPaymentAmountControl: this.fb.control(''),
      TaxExemptionCheck: this.fb.control(''),
      AithrHubKey: this.fb.control(''),
    });
  }

  Getlenderslist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderlists = data;

        },
        error => {
        });
  }


  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          debugger;
          this.Provincelist = data;
        },
        error => {
        });
  }

}
