
<section id="admindashboard">
    <!-- <form class="col-12 px-0"  [formGroup]="CommuincationForm"> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row mx-0 justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn px-0">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="copytable('usertable')">Copy</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>
                        <div class="col-md col-12 ml-3">
                            <div class="row justify-content-start align-items-center">
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <div class="row">
                                        <label class="col-auto col-form-label"><b> To:</b></label>
                                        <div class="col-lg-auto col-12">
                                            <input type="date" [(ngModel)]="TrackToNg" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <div class="row">
                                        <label  class="col-auto col-form-label"><b> From:</b></label>
                                        <div class="col-lg-auto col-12">
                                            <input type="date" [(ngModel)]="TrackfromNg" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <button class="btn btn-sm btn-warning rounded-pill" (click)="SearchActivity();">Search</button>
                                    &nbsp;&nbsp;
                                    <button class="btn btn-sm btn-warning rounded-pill" (click)="Reset();">Reset</button>
                                </div>
                            </div>
                        </div>
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center">
                                <div class="form-group d-flex mb-sm-0 mb-0 col-12 col-sm-7 col-md-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3 mt-2 mt-md-0">
                                    <label style="padding:2px;">Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">{{ size }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 px-0">
                        <div class="card card-raised h-100 mb-0">
                            <div class="card-header card-raised bg-info text-white">
                                <div class="row justify-content-between align-items-center">
                                    <div class="col-sm-6">
                                        <h5 class="card-title text-white text-uppercase mb-0">List of Communication Logs</h5>
                                    </div>
                                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                        <h6 class="mb-0"><b>Total Records:</b>  {{Totalrecords}} </h6>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body p-4">
                                <div class="table-responsive">
                                    <table id="usertable" class="table table-bordered" style="width:100%">
                                        <thead>
                                            <tr>
                                                <th>&nbsp; </th>
                                                <th (click)="sortData('sendDate')">Send Date<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('dealerName')">Dealer Name<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('userEmail')">Email Send To<i class="fa fa-fw fa-sort"></i></th>                                               
                                                <th (click)="sortData('sendUserName')">Send By<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('ipaddress')">IP Address<i class="fa fa-fw fa-sort"></i></th>
                                                <th (click)="sortData('buttonType')">Type<i class="fa fa-fw fa-sort"></i></th>
                                                <th >Comments</th>
                                                <th >Response Date Time</th>
                                                <th >User Name</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let emaillogdata of emailloglist | filter:searchText | paginate: { itemsPerPage: itemsPerPage, currentPage: currentPage }">
                                                <td *ngIf="emaillogdata.isResponse==true">
                                                    <a class="communication"  title="Edit Response"
                                                    (click)="openModal(ResponseModelPopup,emaillogdata.id,1)" >
                                                        <img src="../../assets/images/communicationc1.png" alt="communication icon">
                                                    </a>
                                                    
                                                </td>
                                                <td *ngIf="emaillogdata.isResponse==false">
                                                    
                                                    <a class="communication" title="Send Response"  (click)="openModal(ResponseModelPopup,emaillogdata.id,2)">
                                                        <img src="../../assets/images/communicationc2.png" alt="communication icon">
                                                    </a>
                                                </td>
                                                
                                                <td>{{ emaillogdata.sendDate | date:'medium' }}</td>
                                                <td>{{ emaillogdata.dealerName }}</td>
                                                <td>{{ emaillogdata.vin }}</td>
                                                <td>{{ emaillogdata.userEmail }}</td>
                                               
                                                <td>{{ emaillogdata.sendUserName }}</td>
                                                <td>{{ emaillogdata.ipaddress }}</td>
                                                <td>{{ emaillogdata.buttonType }}</td>
                                                <td>{{ emaillogdata.comments }}</td>
                                                <td>{{ emaillogdata.responseDateTime | date:'medium' }}</td>
                                                <td>{{ emaillogdata.firstName }}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                                        <p style="color: white">Loading...</p>
                                    </ngx-spinner>

                                    <br>

                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-12 col-sm-auto">
                                            <ngb-pagination [collectionSize]="Totalrecords" [(page)]="currentPage" [pageSize]="itemsPerPage" [maxSize]="5" [boundaryLinks]="true" [ellipses]="false" [rotate]="true"></ngb-pagination>
                                        </div>
                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                                            <div class="form-group mb-0 mr-1">
                                                <label style="padding:2px;">Items per Page:</label>
                                                <select (change)="handlePageSizeChange($event)">
                                                    <option *ngFor="let size of pageSizes" [ngValue]="size">{{ size }}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
        <!-- <app-footer id="secondfooter"></app-footer> -->
        
        <form class="col-12 px-0"  [formGroup]="CommuincationForm">
        <ng-template #ResponseModelPopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Send Response
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
    
                <section class="generate-report col-12">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label class="label-form-col">Response Date</label>
                            
                            <input  formControlName="CurrentDate" readonly
                        [(ngModel)]="CurrentDate" type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="label-form-col">User Name</label>
                            <input type="text" formControlName="LoggedUserName" readonly
                            [(ngModel)]="LoggedUserName" class="form-control" value="">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="label-form-col">Notes</label>
                            <textarea rows="3" 
                            formControlName="ResponseNotes" 
                            [(ngModel)]="ResponseNotes" class="form-control"></textarea>
                        </div>
                    </div>
                </section>
    
    
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm text-white rounded-pill" (click)="SaveReponseDetail()"
                title="Save"
                   >
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;" ></i> Save
                </button>
    
                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Cancel" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>
    
    
            </div>
        </ng-template>

    </form>
</section>
