import { Component, EventEmitter, Output,CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';

import { AccountService } from './_services';
import { Account, Role } from './_models';
import { Router,ActivatedRoute } from '@angular/router';
import { HeaderComponent } from './header/header.component';
import { ManageInventoryService } from './_services/manageInventory.service';
import { DatapassService } from '@app/_services/datapass.service';
import {Subscription} from 'rxjs/Subscription';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Component({
    selector: 'app',
     templateUrl: 'app.component.html',
     styleUrls: ['./app.component.css']
     })
export class AppComponent {


    notifciationCount:any;
    menuOpened: boolean = true;
    //darkmode:boolean = false;
    Role = Role;
    account: Account;
    lgbtn:any;
    setCount:any;
    userName:any;
    rolevalue: any;
    dealerNamevalue: any;
    GroupNamevalue: any;
    DealerIdvalue: any;
    DealerNameFilter:any;
    userFullRole:any;

    firstname: string;
    lastname: string;

    otherMessage: string;
    textFromChild: string;
    isDivVisible: boolean = false;
    DealerKey:any;
    toggleDealerKey() {
      this.isDivVisible = !this.isDivVisible;
    }

    datanew=[];

    constructor(
        private accountService: AccountService,
        private manageInventoryService: ManageInventoryService,
         private router: Router,
         private route: ActivatedRoute,
         private data: DatapassService
         ) {

        this.accountService.account.subscribe(x => this.account = x);

      //   this.route.queryParams.subscribe(params => {
      //     this.firstname = params["firstname"];
      //     this.lastname = params["lastname"];
      // });
    }
    ngOnInit(): void {
      
        var Userdetails = localStorage.getItem('userdetails');
        this.setCount="1";
        this.userFullRole=localStorage.getItem('UserFullRole')
       this.rolevalue = localStorage.getItem('UserFullRole');
       this.dealerNamevalue = localStorage.getItem('DealerName');
       this.GroupNamevalue = localStorage.getItem('GroupName');
       this.DealerIdvalue = localStorage.getItem('DealerId');
       this.DealerNameFilter =this.DealerIdvalue;

        this.account= JSON.parse(Userdetails);
        this.DealerKey= localStorage.getItem('DealerKey');
        
        this.accountService.CheckIFUserIsActive("");

// var currentUrl=window.location.href;

// var urlpath=currentUrl.split('#')[1].trim();

// if(this.account!=null && urlpath=="/" )
// {
//    this.router.navigateByUrl("/welcome-dasboard");
// }


      //   this.GetClientDashboardNotificationList();
      // // var data = this.accountService.SaveActivityLog("");
      //  //setInterval((this.func1,this.func2), 10000);
      //  setInterval(() => {
      //   this.GetClientDashboardNotificationList();
      // }, 10000);


    this.data.currentMessage.subscribe(

        (data: any) => {

          this.otherMessage=data;

      }
        );



      }

      getTextChange(text: string) {
        this.textFromChild = text;
      }

      func1() {
        console.log('func 1');
      }
       func2() {
       // debugger;
        //this.notifciationCount= localStorage.getItem('NotificationCount');
        // this.GetClientDashboardNotificationList();
        // var modelGroup = {
        //     "Opcode": "1",
        //     "DealerName": this.DealerNameFilter,
        //     "DealerId": this.DealerNameFilter
        //   }
        //  var data =  this.manageInventoryService.getNotificationsDashboardListDetails(modelGroup);
       // console.log('func 2');
      }
      RefreshCount()
      {
       // this.GetClientDashboardNotificationList();
      }
      GetClientDashboardNotificationList() {
        var modelGroup = {
          "Opcode": "1",
          "DealerName": this.DealerNameFilter,
          "DealerId": this.DealerNameFilter
        }
        this.manageInventoryService.getNotificationsDashboardListDetails(modelGroup)
          .subscribe(
            (data: any) => {
                this.setCount="2";

              this.notifciationCount = data.length;

            },
            error => {
            });
      }

    // logout() {
    //     this.accountService.logout();
    // }

    logout() {
      localStorage.removeItem('NotificationCount');

      this.accountService.logout();
    }


    RedirectToNotification()
    {
        this.GetClientDashboardNotificationList();
        return this.router.navigateByUrl("/notification-Dashboard");
    }
    parentFun(event){
        event.menuOpened= !event.menuOpened;
      }

}
