import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../_services/dashboard.service';
import { Account } from '@app/_models';
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { NotifierService } from 'angular-notifier';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
@Component({
  selector: 'app-carfaxapi-dashabord',
  templateUrl: './carfaxapi-dashabord.component.html',
  styleUrls: ['./carfaxapi-dashabord.component.less']
})
export class CarfaxapiDashabordComponent implements OnInit {
  Statisticslist = [];
  StatisticsRegionWiselist = [];
  buttonloaderSendEmail: boolean = false;
  StatisticsDealerWiselist= [];
  ListofPendingUnitsData= 'VEHICLESSYNCHEDListRegionWise.xlsx';
  // variables for Counts
  dateSynchedReceived: any;
  VehiclesPendingforCarfaxReport:any;
  CarfaxCounttotalCount:any;
  totalCount: any;
  totalSynchedCount: any;
  totalNotSynchedCount: any;

  key = 'userName'; // set default
  reverse = false;
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  rolevalue:any;
  dealerNamevalue:any;
  GroupNamevalue:any;
  DealerIdvalue:any;
  pageDealer = 1;
  pageDealerSize = 5;
  Vinarraylist:any[]=[];
  pageRegion = 1;
  pageRegionSize = 5;

  private notifier: NotifierService;
  loading = false;
  accounts: Account[] = [];
  constructor(
    private dashboardService: DashboardService,
    private accountService:AccountService,
    notifier: NotifierService,
    private manageInventoryService: ManageInventoryService
    ) {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
  sort(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsRegionWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sortdealer(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsDealerWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  searchTextRegion;
  ColorTheme:any;
  ngOnInit(): void {

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    if (this.rolevalue == "SuperAdmin") {
      
      this.GetCarfaxDashboardVechilenotSyncList();
      this.GetCarfaxDashboardVechileSyncList();

    } 

  }

  

  copytable(el) {
    var urlField = document.getElementById(el) ;  
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range) ;
    document.execCommand('copy');
    this.showNotification("success","Data copy to clipboard successfully")
}
public showNotification(type: string, message: string): void {
  this.notifier.notify(type, message);
}
exportToCSV(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.StatisticsRegionWiselist);
}
printTable() {
  var prepare = [];
  this.StatisticsRegionWiselist.forEach(e => {
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>DealerName</th><th>vin</th><th>make</th><th>model</th><th>mileage</th><th>carYr</th><th>dealerCost</th><th>listedPrice</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 
downloadPdf() {
  var prepare=[];
  this.StatisticsRegionWiselist.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['DealerName','VIN', 'Make', 'Model','Mileage','CarYr','DealerCost','List Price']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('RegionWiseDetail' + '.pdf');
}

exportexcel() {
  const prepare = [];
  this.StatisticsRegionWiselist.forEach(e => {
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['DealerName','VIN', 'Make', 'Model','Mileage','CarYr','DealerCost','List Price'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'RegionWiseDetail');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'RegionWiseDetail.xlsx');
}
printTableDealer() {
  var prepare = [];
  this.StatisticsDealerWiselist.forEach(e => {
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>DealerName</th><th>vin</th><th>make</th><th>model</th><th>mileage</th><th>carYr</th><th>dealerCost</th><th>listedPrice</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 

exportToCSVDealer(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.StatisticsDealerWiselist);
}

downloadPdfDealer() {
  var prepare=[];
  this.StatisticsDealerWiselist.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['DealerName','VIN', 'Make', 'Model','Mileage','CarYr','DealerCost','List Price']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('VEHICLESWITHCARFAXREPORT' + '.pdf');
}

exportexcelDealer() {
  const prepare = [];
  this.StatisticsDealerWiselist.forEach(e => {
    const tempObj = [];
    tempObj.push(e.dealerName);
    tempObj.push(e.vin);
    tempObj.push( e.make);
    tempObj.push( e.model);
    tempObj.push( e.mileage);
    tempObj.push( e.carYr);
    tempObj.push( e.dealerCost);
    tempObj.push( e.listedPrice);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['DealerName','VIN', 'Make', 'Model','Mileage','CarYr','DealerCost','List Price'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'VEHICLESWITHCARFAXREPORT');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'DealerWiseDetail.xlsx');
}

GenerateFlagReport() {
  this.buttonloaderSendEmail=true;
  var modelGroup = {
   //"vin": this.VinGetFlagReport, //dynmic
    //"vin": "1FM5K8GT4EGB37824", //static
    "vinarray":this.Vinarraylist
  }
  
  this.manageInventoryService.generatecarfaxFlagReport(modelGroup)
    .subscribe(
      (data: any) => {
        debugger;
        this.buttonloaderSendEmail=false;
        this.showNotification("success", "Carfax report generate successfully.")
        
      },
      error => {
      });
}

GetCarfaxDashboardVechilenotSyncList() {
    this.dashboardService.getCarfaxDashboardVechilenotSyncList()
      .subscribe(
        (data: any) => {
          this.StatisticsRegionWiselist = data;
          this.VehiclesPendingforCarfaxReport = data.length;
          
        },
        error => {
        });
  }

  GetCarfaxDashboardVechileSyncList() {
    this.dashboardService.getCarfaxDashboardVechileSyncDetails()
      .subscribe(
        (data: any) => {
          this.StatisticsDealerWiselist = data;
          this.CarfaxCounttotalCount = data.length;
        },
        error => {
        });
  }

}
