<div class="w-100">
    <div class="pdf-description col-xl-7 col-lg-8 col-sm-11 col-10 mx-auto px-0">
        <div class="card card-raised h-100">
            <div class="card-header card-raised bg-info text-white">
                <h6 class="card-title text-white text-uppercase mb-0">Lender RateSheet</h6>
            </div>
            <div class="card-body">
                <div class="w-100">
                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. '
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,</p>
                </div>
            </div>
        </div>
    </div>
    <pdf-viewer [src]="pdfSource" [zoom-scale]="'page-width'" [fit-to-page]="true" style="display: block;">
    </pdf-viewer>
</div>