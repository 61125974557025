import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';

@Component({
  selector: 'app-loan-calculator',
  templateUrl: './loan-calculator.component.html',
  styleUrls: ['./loan-calculator.component.less']
})
export class LoanCalculatorComponent implements OnInit {
  loanCalculatorForm: FormGroup;
  constructor(private fb: FormBuilder, private globalcodeService: GlobalcodeService,
    private manageInventoryService: ManageInventoryService,) { }

  PriceofVehicleControl: any;
      CalculateInterestrate: any;
      CalculateDownpayment: any;

      CalculateTradeinvalue: any;
      CalBalanceOwingonTrade: any;
      CalTerm: any;
      CalProvince: any;
      CalTotalLoanAmount: any;
      CalMonthlyPayment: any;
      CalBiWeeklyPayment: any;
      CalWeeklyPayment: any;
      CalTotalInterestPaid: any;
      FrequencySecondPanel:any;
      CalTotalTaxtesPaid:any;

// for pieChartOptionsPanel

      pieChartOptions: ChartOptions;
      pieChartLabels: Label[];
      pieChartData: SingleDataSet;
      pieChartType: ChartType;
      pieChartLegend: boolean;
      pieChartPlugins = [];

      pieChartOptionsSecond: ChartOptions;
      pieChartLabelsSecond: Label[];
      pieChartDataSecond: SingleDataSet;
      pieChartTypeSecond: ChartType;
      pieChartLegendSecond: boolean;
      pieChartPluginsSecond = [];

      TotalLoanValue:any;
      InterestAmount:any;

      // for Second Panel
      opcode:any;
      CalFrequencyofPayment: any;
      CalDesiredPayment: any;
      CalSecondInterestrate: any;
      CalSecondDownpayment: any;
      CalSecondTradeinvalue: any;
      CalSecondBalanceOwingonTrade: any;
      CalSecondTerm: any;
      CalSecondProvince: any;
      CalSecondVehiclePrice: any;
      CalSecondSalesTax: any;
      CalSecondTotalInterestPaid: any;
      CalSecondTotalLoanAmount: any;
      Provincelist = [];
      ProvinceSecondlist= [];
      LoanValuesList= [];

      CalVehiclePrice: any;
      CalSalesTax: any;
      SecondTotalInterestPaid: any;
      SecondotalLoanAmount: any;

      colors: Color[] = [
        {
          backgroundColor: [
            '#8A307F',
            '#79A7D3',
            'blue'
          ]
        }
      ];

      colorsSecond: Color[] = [
        {
          backgroundColor: [
            
            '#00539C',
            '#EEA47F',
            'blue'
          ]
        }
      ];
      ColorTheme:any;
  ngOnInit(): void {
   this.Createform();
    this.GetProvincelistFirstPanel();
    this.GetProvincelistSecondPanel();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    this.PriceofVehicleControl="0";
    this.CalDesiredPayment="0";
    this.CalculateInterestrate="0";
    this.CalculateDownpayment="0";
    this.CalculateTradeinvalue="0";
    this.CalBalanceOwingonTrade="0";
    this.CalTerm="0";
    this.CalProvince="0";

  
    this.CalTotalLoanAmount="0";
    this.CalMonthlyPayment="0";
    this.CalBiWeeklyPayment="0";
    this.CalWeeklyPayment="0";
    this.CalTotalInterestPaid="0";
    this.CalTotalTaxtesPaid="0";


    this.CalFrequencyofPayment="0";
    this.CalDesiredPayment="0";
    this.CalSecondInterestrate="0";
    this.CalSecondDownpayment="0";
    this.CalSecondTradeinvalue="0";
    this.CalSecondBalanceOwingonTrade="0";
    this.CalSecondTerm="0";
    this.CalSecondProvince="0";
    this.CalSecondVehiclePrice="0";
    this.CalSecondSalesTax="0";
    this.CalSecondTotalInterestPaid="0";
    this.CalSecondTotalLoanAmount="0";


    this.CalVehiclePrice ="0";
    this.CalSalesTax ="0";
    this.SecondTotalInterestPaid ="0";
    this.SecondotalLoanAmount ="0";

    this.GeneratePiChart();
    this.GenerateSecondPiChart();
  }
  
  // restrictInput(event: any) {
  //   let value = event.target.value;
  //   const regex = /^\d+(\.\d{0,2})?$/;
  //  // const regex = /^\d{0,9}(\.\d{0,2})?$/;  
  
  //   if (!regex.test(value)) {
  //     value = value.slice(0, -1);
  //     event.target.value = value;
  //   }
  //  }
  
   restrictInput(event) {
    let value = event.target.value;
    const regex = /^\d+(\.\d{0,2})?$/;
  
    if (!regex.test(value)) {
      value = value.slice(0, -1);
      event.target.value = value;
    }
  
    event.target.addEventListener('blur', function () {
      if (regex.test(value) && !value.includes('.')) {
        event.target.value = value + '.00';
      }
    });
  }
  
  GetProvincelistFirstPanel() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  GetProvincelistSecondPanel() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.ProvinceSecondlist = data;
        },
        error => {
        });
  }


  GetLoanValueFirstPanel() {
    this.opcode="1";
    var modelGroup = {
      "OpCode": this.opcode,
      // "Dealerslist": this.selectedDealerIds,
      "DesiredPayment": this.CalDesiredPayment,
      "InterestRate": this.CalculateInterestrate,
      "DownPayment":this.CalculateDownpayment,
      "TradeIn":this.CalculateTradeinvalue,
      "Lien":this.CalBalanceOwingonTrade,
      "TermPeriod":this.CalTerm,
      "RegionID":this.CalProvince,
      "UnitPrice":this.PriceofVehicleControl,
      "FrequencyOfPayment":"1"
    }
    this.manageInventoryService.getLoanCalculatorDetail(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.LoanValuesList = data.length;
          this.CalTotalLoanAmount=data[0].totalLoanValue;
          this.CalMonthlyPayment=data[0].monthlyPayment;
          this.CalBiWeeklyPayment=data[0].biWeeklyPayment;
          this.CalWeeklyPayment=data[0].weeklyPayment;
          this.CalTotalInterestPaid=data[0].interest;
          this.CalTotalTaxtesPaid=data[0].taxes;

          this.GeneratePiChart();
          
        },
        error => {
        });
  }
  CalculateFirstPanel()
  {
  this.GetLoanValueFirstPanel();
  }
  CalculateSecondPanel()
  {
   this.GetLoanValueSecondPanel();
  }
  GetLoanValueSecondPanel() {
    debugger;
    this.opcode="0";
    var modelGroup = {
      "OpCode": this.opcode,
      // "Dealerslist": this.selectedDealerIds,
      "DesiredPayment": this.CalDesiredPayment,
      "InterestRate": this.CalSecondInterestrate,
      "DownPayment":this.CalSecondDownpayment,
      "TradeIn":this.CalSecondTradeinvalue,
      "Lien":this.CalSecondBalanceOwingonTrade,
      "TermPeriod":this.CalSecondTerm,
      "RegionID":this.CalSecondProvince,
      "UnitPrice":"0",
      "FrequencyOfPayment":this.FrequencySecondPanel
    }
    this.manageInventoryService.getLoanCalculatorDetail(modelGroup)
      .subscribe(
        (data: any) => {
      
         
          this.CalVehiclePrice = data[0].price;
          this.CalSalesTax = data[0].taxes;
          this.SecondTotalInterestPaid = data[0].interest;
          this.SecondotalLoanAmount = data[0].totalLoanValue;
          this.GenerateSecondPiChart();
        },
        error => {
        });
  }

  Createform() {
    this.loanCalculatorForm = new FormGroup({

      // for First Panel
      PriceofVehicleControl: this.fb.control(''),
      CalculateInterestrate: this.fb.control(''),
      CalculateDownpayment: this.fb.control(''),

      CalculateTradeinvalue: this.fb.control(''),
      CalBalanceOwingonTrade: this.fb.control(''),
      CalTerm: this.fb.control(''),
      CalProvince: this.fb.control(''),
      CalTotalLoanAmount: this.fb.control(''),
      CalMonthlyPayment: this.fb.control(''),
      CalBiWeeklyPayment: this.fb.control(''),
      CalWeeklyPayment: this.fb.control(''),
      CalTotalInterestPaid: this.fb.control(''),
      FrequencySecondPanel: this.fb.control(''),

      // for Second Panel

      CalFrequencyofPayment: this.fb.control(''),
      CalDesiredPayment: this.fb.control(''),
      CalSecondInterestrate: this.fb.control(''),
      CalSecondDownpayment: this.fb.control(''),
      CalSecondTradeinvalue: this.fb.control(''),
      CalSecondBalanceOwingonTrade: this.fb.control(''),
      CalSecondTerm: this.fb.control(''),
      CalSecondProvince: this.fb.control(''),
      CalSecondVehiclePrice: this.fb.control(''),
      CalSecondSalesTax: this.fb.control(''),
      CalSecondTotalInterestPaid: this.fb.control(''),
      CalSecondTotalLoanAmount: this.fb.control(''),
    });
  }

  GeneratePiChart()
  {
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = ['Total Loan Amount', 'Interest Paid'];
    this.pieChartData = [this.CalTotalLoanAmount , this.CalTotalInterestPaid];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
  }

  GenerateSecondPiChart()
  {
    this.pieChartOptionsSecond = this.createOptions();
    this.pieChartLabelsSecond = ['Total Loan Amount', 'Interest Paid'];
    this.pieChartDataSecond = [this.SecondotalLoanAmount , this.SecondTotalInterestPaid];
    this.pieChartTypeSecond = 'pie';
    this.pieChartLegendSecond = true;
  }

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }
}
