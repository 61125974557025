import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AIXLenders } from '@app/_models/AIXLenders';
import { LenderService } from '@app/_services/lender.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-lender-ppsa',
  templateUrl: './lender-ppsa.component.html',
  styleUrls: ['./lender-ppsa.component.less']
})
export class LenderPpsaComponent implements OnInit {
  lenderPPSAForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,private manageInventoryService: ManageInventoryService,
    private lenderService: LenderService,private spinner: NgxSpinnerService,
    notifier: NotifierService,) { 
      this.notifier = notifier;
    }

  searchTextBought;

  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];

  TotalRecord:any;
  showlist:any;
  lenderlists: AIXLenders[] = [];
  LenderppasList:[];
  lenderKeyList = [];
  LenderNameValue:any;
  opcode:any;
  PlanType:any;
  isDesc: boolean = false;
  column: string = 'LenderName';
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.LenderppasList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.opcode="1";
    this.showlist="false";
    this.Getlenderslist();

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  Getlenderslist() {
    this.lenderService.getAllLendersList()
      .subscribe(
        (data: any) => {
          this.lenderlists = data;

        },
        error => {
        });
  }

  GetLenderKeyList(event:any)
  {
    debugger;
    var lendervalue=event.target.value;
    this.LenderNameValue=lendervalue;
    this.GetLenderKeys(this.LenderNameValue,this.LenderNameValue);
  }

  GetLenderKeys(LenderId: any, LenderName: any) {
    var modelGroup = {
      "id": LenderId,
      "LenderName": LenderName
    }
    this.manageInventoryService.getLenderFreshBuildKeys(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.lenderKeyList = data;

        },
        error => {
        });
  }

  EditPPsa(index:any,item:any)
  {
    debugger;
    var data= this.lenderKeyList;
    var modelGroup = {
      "itemList": item,
      "LenderID": this.LenderNameValue,
      "PlanType":this.PlanType,
    }

    this.manageInventoryService.sendLenderppsavalues(modelGroup)
      .subscribe(
        (data: any) => {
         
          this.showNotification("success", "Values updated successfully.")

        },
        error => {
        });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  Getplantype(event:any)
  {

    var plantypedata=event.target.value;
    this.PlanType=plantypedata;
  }

  GetPppsaListValues() {
    debugger;
    this.spinner.show();
    var modelGroup = {
      "OpCode": this.opcode,
      "LenderID": this.LenderNameValue,
      "PlanTypeID":parseInt(this.PlanType),
      "VType":"PPSA"
    }
    this.manageInventoryService.getPppsaListValues(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.showlist="true";
          this.spinner.hide();
          this.LenderppasList = data;
         this.TotalRecord =this.LenderppasList.length;
        },
        error => {
        });


  }
  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  Createform() {
    this.lenderPPSAForm = new FormGroup({
      //LenderNameValue: this.fb.control(''),
    //  PlanType: this.fb.control(''),
    });
  }

}
