import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({
  providedIn: 'root'
})
export class ListDealersService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
      private router: Router,
      private http: HttpClient
  ) {

      this.accountSubject = new BehaviorSubject<Account>(null);
      this.account = this.accountSubject.asObservable();

      this.loginSubject = new BehaviorSubject<Login>(null);
      this.logins = this.loginSubject.asObservable();

  }

  private baseUrl_UpdateadvancesettingDealer: string = environment.apiUrl + '/Admin/UpdateadvancesettingDealer';
  private baseUrl_AddAddDealer: string = environment.apiUrl + '/Admin/AddDealer';
  private baseUrl_GetAllDealersList: string = environment.apiUrl + '/Admin/GetAllDealer';
  private baseUrl_EditDealersList: string = environment.apiUrl + '/Admin/GetDealersById';
  private baseUrl_UpdateDealersList: string = environment.apiUrl + '/Admin/UpdateDealer';
  private baseUrl_DealerDealersList: string = environment.apiUrl + '/Admin/DeleteDealer';

  private baseUrl_GetDealersByDealerName: string = environment.apiUrl + '/Admin/GetDealersByDealerName';

  private baseUrl_getSyncedDealerName: string = environment.apiUrl + '/ICCBatchApi/GetSyncedDealerName';
  

  private baseUrl_AddCustomer: string = environment.apiUrl + '/Admin/AddCustomers';
  private baseUrl_GetAllCustomerList: string = environment.apiUrl + '/Admin/GetCustomerLists';
  private baseUrl_EditCustomerList: string = environment.apiUrl + '/Admin/GetCustomersById';
  private baseUrl_UpdateCustomerList: string = environment.apiUrl + '/Admin/UpdateCustomers';
  private baseUrl_DeleteCustomerList: string = environment.apiUrl + '/Admin/DeleteCustomers';


  private baseUrl_GetResponseReason: string = environment.apiUrl + '/Admin/GetResponseReason';

  // function for Cuystomer

  DeleteCustomer(formdata) {
    return this.http.post<any[]>(this.baseUrl_DeleteCustomerList, formdata);
  }

  updateCustomer(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateCustomerList, formdata);
}
  saveaddCustomers(formdata) {
    debugger;
    return this.http.post<any[]>(this.baseUrl_AddCustomer, formdata);
  }

  getCustomerLists(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAllCustomerList,formdata);
  }

  getCustomersById(formdata) {

    return this.http.post<any[]>(this.baseUrl_EditCustomerList, formdata);
  }

  //calling function saving method
  SaveaddDealers(formdata) {
    return this.http.post<any[]>(this.baseUrl_AddAddDealer, formdata);
}

  //updateadvance dealer setting
  updateadvanceSettingDealer(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateadvancesettingDealer, formdata);
}


  //calling function saving method
  EditDealers(formdata) {
    return this.http.post<any[]>(this.baseUrl_EditDealersList, formdata);
}

//Delete Dealers
 //calling function saving method
 Delete(formdata) {
  return this.http.post<any[]>(this.baseUrl_DealerDealersList, formdata);
}

//update dealers
  //calling function saving method
  upDealers(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateDealersList, formdata);
}


//get grouplist

  //calling function saving method
  GetDealerslist(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllDealersList,formdata);
}

getDealersByDealerName(formdata) {
  return this.http.post<any[]>(this.baseUrl_GetDealersByDealerName, formdata);
}

getResponseReasonlist(formdata) {
  return this.http.post<any[]>(this.baseUrl_GetResponseReason, formdata);
}


getSyncedDealerName(formdata) {
  return this.http.post<any[]>(this.baseUrl_getSyncedDealerName, formdata);
}


}
