<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="AddGlobalCodeCategoryForm">
  <div class="container-fluid">
    <div class="row">
      <div class="col-12 mt-2">
       
        <div class="row justify-content-between align-items-center mb-2">
          <div class="col-auto documentbtn">
            <ul class="list-unstyled mb-1">
              <li>
                <button class="btn btn-secondary btn-sm mb-sm-0"  (click)="copytable('usertable')">Copy</button>
              </li>
             
              <li>
                <button class="btn btn-secondary btn-sm mb-sm-0" (click)="exportexcel()">Excel</button>
              </li>
              
              <li>
                <button class="btn btn-secondary btn-sm mb-sm-0" (click)="downloadPdf()">PDF</button>
              </li>
              <li>
                <button class="btn btn-secondary btn-sm mb-sm-0" (click)="printTable()">Print</button>
              </li>
            </ul>
          </div>
          <div class="search-heros col-md-6 col-lg-4 mt-2 mt-sm-0">
            <div class="form-group d-flex mb-1">
              <label class="mr-3 mt-2">Search:</label>
              <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                [ngModelOptions]="{standalone: true}" autocomplete="off">
            </div>
          </div>
        </div>
  
  
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card card-raised h-100">
          <div class="card-header card-raised bg-info text-white">
           

            <div class="row justify-content-between align-items-center">
              <div class="col-sm-6">
                <h5 class="card-title text-white text-uppercase mb-0">Categories</h5>
              </div>
              <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
              </div>
            </div>

            </div>
            <div class="card-body p-4">
              <div class="table-responsive">
                  <table id="usertable" class="table table-bordered" style="width:100%">
                    <thead>
                      <tr>
                        
                        <th (click)="sort('customerSetupCategoryNumber')"> Category Number<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('globalCodeCategoryName')"> Name<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('globalCodeCategoryValue')"> Value<i class="fa fa-fw fa-sort"></i></th>
                        <th (click)="sort('globalCodeCategoryNotes')"> Note</th>
             
                        <th style="text-align:center;" colspan="3"><button type="button" class="btn btn-primary btn-sm text-white"
                            (click)="openRepoModel(ModelGlobalCodeCategory)">
                            <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Global Code Category
                          </button></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let globalCodeCategoryListdata of globalCodeCategory | filter:searchText | slice: (pageRep-1) * pageSizeRep : (pageRep-1) * pageSizeRep + pageSizeRep; let i = index; let odd = odd">
                       
                        <td> {{ globalCodeCategoryListdata.customerSetupCategoryNumber }}</td>
                        <td> {{ globalCodeCategoryListdata.globalCodeCategoryName }}</td>
                        <td> {{ globalCodeCategoryListdata.globalCodeCategoryValue }}</td>
                        <td> {{ globalCodeCategoryListdata.globalCodeCategoryNotes }}</td>
                    
                        <td style="text-align:center;">
                          <button class="btn bg-gradient-info btn-sm"  title="Edit"
                          (click)="EditModalpopup(ModelEditGlobalCodeCategory,globalCodeCategoryListdata.globalCodeCategoryID)"><i
                              class="fa-solid fa-pen-to-square"></i></button>
                          <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                           (click)="openModal(template,globalCodeCategoryListdata.globalCodeCategoryID)"><i
                              class="fa fa-trash"></i></button>
                        </td>
                      </tr>
  
                    </tbody>
                  </table>
                  <br>
                  <ngb-pagination [collectionSize]="globalCodeCategory.length" [(page)]="pageRep" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  
  
                </div>
           </div>
          <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i class="fa fa-upload"
              triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
              ngbPopover="Are you sure you want to delete?"></i>
          </a>
          <ng-template #template>
            <div class="modal-body text-center">
              <span class="cross-icon"><i class="far fa-times-circle"></i></span>
              <p>Are you sure you want to delete?
              </p>
              <p></p>
              <button type="button" class="btn btn-sm btn-success mr-2" (click)="confirm()">Yes</button>
              <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
            </div>
          </ng-template>
  

          <ng-template #ModelGlobalCodeCategory let-modal>
            <div class="modal-header" style="background-color: black;">
              <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add</h5>
              <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
  
              <section class="generate-report">
                <div class="row">
                  <div class="form-group col-md-6">
                      <label>Customer Setup Number</label>
                      <input type="text" formControlName="CustomerSetupCategoryNumber" class="form-control" placeholder="Category Setup Number"
                        (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.CustomerSetupCategoryNumber.errors }" />
                      <div *ngIf="submitted && f.CustomerSetupCategoryNumber.errors" class="invalid-feedback">
                        <div *ngIf="f.CustomerSetupCategoryNumber.errors.required">Customer Setup Number is required</div>
                      </div>
                    </div>

                  <div class="form-group col-md-6">
                    <label>Category Name</label>
                    <input type="text" formControlName="GlobalCodeCategoryName" maxlength="30"
                     class="form-control" placeholder="Category Name"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeCategoryName.errors }" />
                    <div *ngIf="submitted && f.GlobalCodeCategoryName.errors" class="invalid-feedback">
                      <div *ngIf="f.GlobalCodeCategoryName.errors.required">Category Name is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6" style="display: none;">

                    <input type="text" formControlName="GlobalCodeCategoryValue" class="form-control" placeholder="Category Value"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.GlobalCodeCategoryValue.errors }" />
                    <div *ngIf="submitted && f.GlobalCodeCategoryValue.errors" class="invalid-feedback">
                      <div *ngIf="f.GlobalCodeCategoryValue.errors.required">Category Value is required</div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="form-group col-md-12">
                     <label>Notes</label>
                      <textarea formControlName="GlobalCodeCategoryNotes" class="form-control" placeholder="Notes" 
                      (keypress)="ValidTextBox($event)" >
                    </textarea>

                  </div>
                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="Category1" class="form-control" placeholder="Category1"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.Category1.errors }" />
                    <div *ngIf="submitted && f.Category1.errors" class="invalid-feedback">
                      <div *ngIf="f.Category1.errors.required">Category1 is required</div>
                    </div>
                  </div>

                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="Category2" class="form-control" placeholder="Category2"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.Category2.errors }" />
                    <div *ngIf="submitted && f.Category2.errors" class="invalid-feedback">
                      <div *ngIf="f.Category2.errors.required">Category2 is required</div>
                    </div>
                  </div>

                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="Category3" class="form-control" placeholder="Category3"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.Category3.errors }" />
                    <div *ngIf="submitted && f.Category3.errors" class="invalid-feedback">
                      <div *ngIf="f.Category3.errors.required">Category3 is required</div>
                    </div>
                  </div>
                
                </div>
              </section>
  
  
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
              </button>
  
              <button class="btn btn-danger btn-sm text-white" (click)="CloseAddmodel()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
              </button>
  
  
            </div>
          </ng-template>

          <ng-template #ModelEditGlobalCodeCategory let-modal>
            <div class="modal-header" style="background-color: black;">
              <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Edit</h5>
              <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
  
              <section class="generate-report">
                <div class="row">
                  <div class="form-group col-md-6">
                    <label>Customer Setup Number</label>
                    <input type="text" formControlName="EditCustomerSetupCategoryNumber" 
                    [(ngModel)]="EditCustomerSetupCategoryNumberValue"
                     class="form-control" placeholder="Customer Setup Category Number"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditCustomerSetupCategoryNumber.errors }" />
                    <div *ngIf="submitted && f.EditCustomerSetupCategoryNumber.errors" class="invalid-feedback">
                      <div *ngIf="f.EditCustomerSetupCategoryNumber.errors.required">Customer Setup Number is required</div>
                    </div>
                  </div>

                  <div class="form-group col-md-6">
                    <label>Category Name</label>
                    <input type="text" formControlName="EditGlobalCodeCategoryName"
                    [(ngModel)]="EditGlobalCodeCategoryNameValue"
                     class="form-control" placeholder="Category Name"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditGlobalCodeCategoryName.errors }" />
                    <div *ngIf="submitted && f.EditGlobalCodeCategoryName.errors" class="invalid-feedback">
                      <div *ngIf="f.EditGlobalCodeCategoryName.errors.required">Category Name is required</div>
                    </div>
                  </div>

                  <div class="form-group" style="display: none;">
            
                    <input type="text" formControlName="EditGlobalCodeCategoryValue" 
                    [(ngModel)]="EditGlobalCodeCategoryValues"
                    class="form-control" placeholder="Category Value"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditGlobalCodeCategoryValue.errors }" />
                    <div *ngIf="submitted && f.EditGlobalCodeCategoryValue.errors" class="invalid-feedback">
                      <div *ngIf="f.EditGlobalCodeCategoryValue.errors.required">Category Value is required</div>
                    </div>
                  </div>
                </div>
                <hr/>
                <div class="row">
                  <div class="form-group col-md-12">
                    <label>Notes</label>
                    <textarea formControlName="EditGlobalCodeCategoryNotes"
                    [(ngModel)]="EditGlobalCodeCategoryNotesValue" class="form-control" placeholder="Notes"
                    (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditGlobalCodeCategoryNotes.errors }" >
                  </textarea>
                    <!-- <input type="text" formControlName="EditGlobalCodeCategoryNotes"
                    [(ngModel)]="EditGlobalCodeCategoryNotesValue"
                     class="form-control" placeholder="Notes"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditGlobalCodeCategoryNotes.errors }" /> -->
                    <div *ngIf="submitted && f.EditGlobalCodeCategoryNotes.errors" class="invalid-feedback">
                      <div *ngIf="f.EditGlobalCodeCategoryNotes.errors.required">Notes is required</div>
                    </div>
                  </div>
                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="EditCategory1"
                    [(ngModel)]="EditCategory1Value"
                     class="form-control" placeholder="Category1"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditCategory1.errors }" />
                    <div *ngIf="submitted && f.EditCategory1.errors" class="invalid-feedback">
                      <div *ngIf="f.EditCategory1.errors.required">Category1 is required</div>
                    </div>
                  </div>

                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="EditCategory2" 
                    [(ngModel)]="EditCategory2Value" class="form-control" placeholder="Category2"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditCategory2.errors }" />
                    <div *ngIf="submitted && f.EditCategory2.errors" class="invalid-feedback">
                      <div *ngIf="f.EditCategory2.errors.required">Category2 is required</div>
                    </div>
                  </div>

                  <div class="form-group" style="display: none;">

                    <input type="text" formControlName="EditCategory3"
                    [(ngModel)]="EditCategory3Value" class="form-control" placeholder="Category3"
                      (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.EditCategory3.errors }" />
                    <div *ngIf="submitted && f.EditCategory3.errors" class="invalid-feedback">
                      <div *ngIf="f.EditCategory3.errors.required">Category3 is required</div>
                    </div>
                  </div>
                </div>
  
              </section>
  
  
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary btn-sm text-white" (click)="update();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
              </button>
  
              <button class="btn btn-danger btn-sm text-white" (click)="CloseAddmodel()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
              </button>
  
  
            </div>
          </ng-template>
         
        
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>

  <!-- <app-footer id="secondfooter"></app-footer> -->

  </form>
  </section>