<section id="admindashboard">
    <form class="p-2" [formGroup]="">
        <div class="card card-raised h-100">
            <div class="card-header card-raised bg-info text-white">
                <h5 class="card-title text-white text-uppercase mb-0">
                    Dashboard
                </h5>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card card-raised mb-0">
                                    <div class="card-header card-raised bg-info text-white">
                                        <span class="text-uppercase">Total Activities</span>
                                    </div>
                                    <div class="card-body">
                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                            [chartType]="pieChartType" [options]="pieChartOptions" 
                                           
                                            [plugins]="pieChartPlugins"
                                            [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card card-raised mb-0">
                                    <div class="card-header card-raised bg-info text-white">
                                        <span class="text-uppercase">Lender Report</span>
                                    </div>
                                    <div class="card-body">
                                        <canvas baseChart [data]="lenderpieChartData" [labels]="lenderpieChartLabels"
                                            [chartType]="pieChartType" [options]="lenderpieChartOptions" [plugins]="lenderpieChartPlugins"
                                            [legend]="lenderpieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card card-raised">
                                    <div class="card-header card-raised bg-info text-white">
                                        <span class="text-uppercase">Total Activities</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="table-responsive col-12">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th style="text-align: right;">Find Cars Search Count</th>
                                                            <th style="text-align: right;">Build Car Visits</th>
                                                            <th style="text-align: right;">Total IncludeHub</th>
                                                            <th style="text-align: right;">Total Self Inventory</th>
                                                            <th style="text-align: right;">Total Maxmized</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr *ngFor="let reportDataMatrixListdata of reportDataMatrixList">
                                                            <td style="color: #007bff;text-align: right;"><a   (click)="GetAllDetails();"> {{ reportDataMatrixListdata.totalFindCar }} </a> </td>
                                                            <td style="color:#0093ff;text-align: right;">{{ reportDataMatrixListdata.totalBuildCar }} </td>
                                                            <td style="color:#0093ff;text-align: right;">{{ reportDataMatrixListdata.totalIncludeHub }} </td>
                                                            <td style="color:#0093ff;text-align: right;">{{ reportDataMatrixListdata.totalSelfInventory }} </td>
                                                            <td style="color:#0093ff;text-align: right;">{{ reportDataMatrixListdata.totalMaxmized }} </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card card-raised">
                                    <div class="card-header card-raised bg-info text-white">
                                        <span class="text-uppercase">Lender Report</span>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="table-responsive col-12">
                                                <table class="table table-bordered">
                                                    <thead>
                                                        <tr>
                                                            <th>TD</th>
                                                            <th>SDA</th>
                                                            <th>NORTHLAKE</th>
                                                            <th>Quantifi</th>
                                                            <th>GO PLAN</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>2</td>
                                                            <td>3</td>
                                                            <td>4</td>
                                                            <td>5</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mx-0 mt-2" *ngIf="showUnitDetail ==='true'">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="card card-raised h-100 w-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-auto">
                                            <h5 style="font-size:14px;" class="card-title text-white text-uppercase d-inline-block mb-0 pt-1">Unit Details </h5>
                                            <button class="btn btn-sm btn-warning ml-4" (click)="HideDetails();">Hide Details</button>
                                        </div>
                                        <div class="col-auto mr-auto">
                                            <h6 class="mt-0 counter text-white mb-0 pt-1"> <span class="mr-2"> </span></h6>
                                        </div>
                                        <div class="col-auto">
                                            <div class="row justify-content-between align-items-center">
                                              <div class="col-auto px-2 pt-1">
                                                  <h6 class="mt-0 counter text-white mb-0"> <span class="mr-2">Total Count:</span>{{UnittotalCount}}</h6>
                                              </div>
                                              <div class="search-heros col-xl col-md ml-auto mt-2 mt-lg-0">
                                                <div class="form-group d-flex mb-md-0 mb-2">
                                                  <label class="mr-2 mt-2">Search:</label>
                                                <input class="form-control" type="text"  [(ngModel)]="searchUnitText" 
                                                [ngModelOptions]="{standalone: true}"
                                                    autocomplete="off">
                                                </div>
                                              </div>
                                              
                                            </div>
                                      </div>
                                    </div>
                                </div>
                                <div class="card-body p-4">
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="table-responsive">
                                            <table class="table table-bordered" id="">
                                              <thead>
                                                <tr>
                                                  <th (click)="sortUnit('vin')" style="text-align: left;">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('stockNumber')" style="text-align: left;"> Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('carYr')" style="text-align: left;">Year<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('mileage')" style="text-align: left;">Mileage<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('make')" style="text-align: left;">Make<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('model')" style="text-align: left;">Model<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('trim')" style="text-align: left;">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('body')" style="text-align: left;">Body<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('colour')" style="text-align: left;">Colour<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('listedPrice')" style="text-align: right;">ListedPrice<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('dealerCost')" style="text-align: right;">Dealer Cost<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('lenderName')" style="text-align: left;">Lender Name<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('lien')" style="text-align: right;">lien<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('payCall')" style="text-align: right;">Pay Call<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('interestRate')" style="text-align: right;">Interest Rate<i class="fa fa-fw fa-sort"></i></th>
                                                  <th (click)="sortUnit('acv')" style="text-align: right;">Trade Value<i class="fa fa-fw fa-sort"></i></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                
                                                <tr *ngFor="let reportDataMatrixAllListdata of reportDataMatrixAllList | filter:searchUnitText | slice: (pageUnit-1) * pageUnitSize : (pageUnit-1) * pageUnitSize + pageUnitSize; let i = index; let odd = odd">
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.vin}} </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.stockNumber}}  </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.carYr}} </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.mileage}}  </td>
                                               <td style="text-align: left;"> {{reportDataMatrixAllListdata.make}}  </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.model}}  </td>
                                               <td style="text-align: left;"> {{reportDataMatrixAllListdata.trim}} </td>
                                               <td style="text-align: left;"> {{reportDataMatrixAllListdata.body}} </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.colour}}  </td>
                                               <td style="text-align: right;"> {{reportDataMatrixAllListdata.listedPrice | currency}}</td>
                                               <td style="text-align: right;">{{reportDataMatrixAllListdata.dealerCost | currency}} </td>
                                               <td style="text-align: left;">{{reportDataMatrixAllListdata.lenderName}}  </td> 
                                               <td style="text-align: right;">{{reportDataMatrixAllListdata.lien | currency}} </td> 
                                               <td style="text-align: right;">{{reportDataMatrixAllListdata.payCall | currency}} </td> 
                                               <td style="text-align: right;">{{reportDataMatrixAllListdata.interestRate}} </td>  
                                               <td style="text-align: right;">{{reportDataMatrixAllListdata.acv | currency}} </td> 
                                                </tr>
                              
                                              </tbody>
                                             </table>
                                             <br>
                                             <ngb-pagination [collectionSize]="reportDataMatrixAllList.length" [(page)]="pageUnit" [maxSize]="5" [rotate]="true"
                                             [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</section>