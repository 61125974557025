<section id="admindashboard">
  <form class="col-12 px-0" [formGroup]="UserListForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="row mx-0 justify-content-between align-items-center mb-2">
            <div class="search-heros col-md-auto mt-2 mt-md-0">
              <div class="row justify-content-between align-items-center mb-1">
                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                </div>
                <!-- <div class="form-group mb-0 ml-md-3">
                  <label style="padding:2px;"> Items per Page:</label>
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                </div> -->
              </div>
            </div>

            <div class="col-auto documentbtn">
              <ul class="list-unstyled mb-1">
                <li>
                  <button title="Copy" class="btn btndesign btn-sm mb-sm-0" (click)="copytable('usertable')">
                    <img src="../../assets/images/icon-btn/copy.png" alt="copy"/>
                    Copy
                  </button>
                </li>
                <li>
                  <button title="Excel" class="btn btndesign btn-sm mb-sm-0" (click)="exportexcel()">
                    <img src="../../assets/images/icon-btn/excel.png" alt="Excel"/>
                    Excel
                  </button>
                </li>
                <li>
                  <button title="PDF" class="btn btndesign btn-sm mb-sm-0" (click)="downloadPdf()">
                    <img src="../../assets/images/icon-btn/pdf.png" alt="PDF"/>
                    PDF
                  </button>
                </li>
                <li>
                  <button title="Print" class="btn btndesign btn-sm mb-sm-0" (click)="printTable()">
                    <img src="../../assets/images/icon-btn/print.png" alt="Print"/>
                    Print
                  </button>
                </li>
              </ul>
            </div>

          </div>


          <div class="row mt-2">
            <div class="col-md-12">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">

                  <div class="row justify-content-between align-items-center">
                    <div class="col-sm-6">
                      <h5 class="card-title text-white text-uppercase mb-0">List of registered Users</h5>
                    </div>
                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                      <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                    </div>
                  </div>

                </div>
                <div class="card-body p-4">
                  <div class="table-responsive">
                    <table id="usertable" class="table table-bordered" style="width:100%">
                      <thead>
                        <tr>
                          <th (click)="sort('userName')">Name <i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th (click)="sort('email')"> Email <i class="fa fa-fw fa-sort"></i>
                          </th>
                          <!-- <th (click)="sort('addresslineone')">Address <i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('country')">Country <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('state')">State <i class="fa fa-fw fa-sort"></i>
                                                    </th> -->

                          <th (click)="sort('groupName')"> Group<i class="fa fa-fw fa-sort"></i>
                          <th (click)="sort('dealerName')"> Dealer<i class="fa fa-fw fa-sort"></i>
                          <th (click)="sort('rolename')"> Role<i class="fa fa-fw fa-sort"></i>
                          <th (click)="sort('city')">City <i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th>Phone</th>
                          <th>Date</th>
                          <th>Dealer Status</th>
                          <th style="text-align:center;" colspan="2">
                            <button type="button" class="btn btn-primary btn-sm"
                              (click)="openAddModel(ModelAddUser,'')">
                              <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                              Add User
                            </button>
                          </th>
                          <!-- <th colspan="2"><i class="fa-solid fa-plus"></i></th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let account of accounts| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                          <!-- <td>{{ account.firstName }} {{ account.lastName }}</td> -->
                          <td>{{ account.firstandlastName }}</td>

                          <td>{{ account.email }}</td>
                          <!-- <td>{{ account.addresslineone }}</td>
                                                    <td>{{ account.country}}</td>
                                                    <td>{{ account.state }}</td> -->
                          <td>{{ account.groupName }}</td>
                          <td>{{ account.dealerName }}</td>
                          <td>{{ account.rolename }}</td>
                          <td>{{ account.city }}</td>
                          <td>{{ account.phoneNumber }}</td>
                          <td *ngIf="account.active==true"  >{{ account.activeDate | date }}</td>
                          <td *ngIf="account.active==false" >{{ account.deletedDate | date}}</td>
                          <td style="min-width:50px;text-align:center;">
                            <button  *ngIf="account.dealerStatus=='false'" title="De-Active" class="btn btn-xs bg-danger"><i class="fa-solid fa-lightbulb text-white" style="font-size:13px;vertical-align:middle;"></i></button>
                            <button *ngIf="account.dealerStatus=='true'" title="Active" class="btn btn-xs bg-success"><i class="fa-solid fa-lightbulb text-white" style="font-size:13px;vertical-align:middle;"></i></button>
                          </td>
                          <td style="min-width:130px;text-align:center;">

                            <!-- <button class="btn bg-gradient-success btn-sm mb-2 mb-md-0 mr-1" title="Active" *ngIf="account.active==true" >
                              <i class="fa fa-check" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;"></i>
                            </button>
                            <button  class="btn bg-gradient-danger btn-sm mb-2 mb-md-0 mr-1" title="Not Active"  *ngIf="account.active==false" >
                              <i class="fa fa-times" style="font-size:12px;color:#fff;vertical-align:middle;"></i>
                            </button> -->

                            <button class="btn bg-gradient-info btn-sm mb-2 mb-md-0"
                              (click)="openAddModel(ModelAddUser,account.userid)" title="Edit"><i
                                class="fas fa-pencil-alt"></i></button>
                            <button class="btn bg-gradient-success btn-sm mx-1 mb-2 mb-md-0" title="Reset Password"
                              (click)="openPasswordModel(ModelPasswordUser,account.email)"><i
                                class="fas fa-user-lock"></i></button>

                            <button class="btn bg-gradient-danger btn-sm mb-2 mb-md-0" title="Delete User"
                              (click)="onDelete(account.email);">
                              <i class="fas fa-user-slash"></i></button>



                            <button  *ngIf="account.active==true" class="btn bg-gradient-danger btn-sm ml-1"
                            title="Click to Deactivate User"
                              (click)="DeactivateUser(account.email);">
                              <i class="fa fa-times" style="font-size: 10px;color: #fff;vertical-align: middle;width: 9px;">
                              </i></button>


                              <button  *ngIf="account.active==false"
                              class="btn bg-gradient-success btn-sm ml-1"
                              title="Click to Activate User" (click)="activateUser(account.email);"
                              > <i class="fa fa-check" style="font-size:12px;color:#fff;vertical-align:middle;">
                              </i></button>

                          </td>

                        </tr>

                      </tbody>
                    </table>

                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                      <p style="color: white"> Loading... </p>
                  </ngx-spinner>

                    <br>

                    <div class="row justify-content-between align-items-center">
                      <div class="col-12 col-sm-auto">
                        <ngb-pagination [collectionSize]="accounts.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                          [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                      </div>
                      <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                        <div class="form-group mb-0 mr-1">
                          <label style="padding:2px;"> Items per Page:</label>
                          <select (change)="handlePageSizeChange($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                              {{ size }}
                            </option>
                          </select>
                        </div>
                      </div>

                    </div>




                  </div>
                </div>


                <a #ConfirmBoxActivateUser style="display: none;" [routerLink]="" (click)="openModal(templateActivateUser)"><i
                  class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="activate Users"
                  placement="top" ngbPopover="Are you sure you want to activate user?"></i></a>
              <ng-template #templateActivateUser>
                <div class="modal-body text-center">
                  <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                  <p>Are you sure you want to activate user?</p>
                  <p></p>
                  <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmActivate()">Yes</button>
                  <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineActivateUser()">No</button>
                </div>
              </ng-template>


                <a #ConfirmBoxActivate style="display: none;" [routerLink]="" (click)="openModal(templateActivate)"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Deactivate Users"
                    placement="top" ngbPopover="Are you sure you want to deactivate user?"></i></a>
                <ng-template #templateActivate>
                  <div class="modal-body text-center">
                    <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                    <p>Are you sure you want to deactivate user?</p>
                    <p></p>
                    <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmDeactivate()">Yes</button>
                    <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                  </div>
                </ng-template>

                <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template)"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
                    ngbPopover="Are you sure you want to delete?"></i></a>
                <ng-template #template>
                  <div class="modal-body text-center">
                    <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                    <p>Are you sure you want to delete?</p>
                    <p></p>
                    <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                    <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                  </div>
                </ng-template>



                <ng-template #ModelAddUser let-modal>
                  <div class="modal-header" style="background-color: black;">
                    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">{{userpopuplabel}}</h5>
                    <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">

                    <section class="generate-report">
                      <div class="row">
                        <div class="form-group col-md-6 col-lg-4">
                          <label for="exampleFormControlSelect1">Group Name</label>

                          <ng-select [items]="GroupLists" bindLabel="globalCodeName"
                            (change)="GetDealerNamebyGroupId(selectedGroupIds,'change')" [ngModelOptions]="{standalone: true}"
                            placeholder="Select Group" appendTo="body" [multiple]="false" [ngClass]="{'red': GruopIdvalidation === false}"
                            [(ngModel)]="selectedGroupIds">
                          </ng-select>

                          <span style="font-size:.875em;color:#dc3545;" *ngIf="GruopIdvalidation === false">
                            Group Name is required
                        </span>

                          <!-- <select class="form-control" name="groupName" formControlName="groupName" [(ngModel)]="Gid"
                                                   (change)="GetDealerNamebyGroupId($event.target.value)">
                                                    <option value="0" selected>Select group Name</option>
                                                    <option *ngFor="let grouplistdata of GroupLists" [value]="grouplistdata.globalCodeID">{{grouplistdata.globalCodeName}}</option>
                                                  </select>
                                                  <div *ngIf="submitted && f.groupName.errors" class="invalid-feedback">
                                                    <div *ngIf="f.groupName.errors.required">Group Name is required</div>
                                                  </div> -->
                        </div>

                        <div class="form-group col-md-6 col-lg-4">

                          <label for="exampleFormControlSelect2">Dealer Name</label>
                          <ng-select [items]="dealerlistName" bindLabel="dealerName" id="dealerName"
                            (change)="getValues(selectedDealerIds)" [ngModelOptions]="{standalone: true}"
                            placeholder="Select Dealer Name" appendTo="body" [multiple]="false"  [ngClass]="{'red': Dealeridvalidation === false}"
                            [(ngModel)]="selectedDealerIds">
                          </ng-select>

                          <span style="font-size:.875em;color:#dc3545;" *ngIf="Dealeridvalidation ===false">
                            Dealer Name is required
                        </span>

                          <!-- <select class="form-control" name="dealerName" formControlName="dealerName"  [(ngModel)]="Did"
                                              >
                                                <option value="0" selected>Select dealer Name</option>
                                                <option *ngFor="let dealerListdata of dealerlistName" [value]="dealerListdata.id">{{dealerListdata.dealerName}}</option>
                                              </select>
                                              <div *ngIf="submitted && f.dealerName.errors" class="invalid-feedback">
                                                <div *ngIf="f.dealerName.errors.required">Dealer Name is required</div>
                                              </div> -->
                        </div>

                        <div class="form-group col-md-6 col-lg-4">

                          <label for="">User Role</label>
                          <select class="form-control" (change)="changeonrole(Rid)" name="userRoleControl" [(ngModel)]="Rid"
                            formControlName="userRoleControl" [ngStyle]="!Roleidvalidation && {'border-color':'red'}"
                            [ngClass]="{ 'is-invalid': submitted && f.userRoleControl.errors }"
                            (change)="onChange($event.target.value)">
                            <!-- <option value="0" selected>Select User Role </option>  -->
                            <option value="0">Select User Role</option>
                            <option *ngFor="let rolelistdata of rolelist" [value]="rolelistdata.globalCodeID">
                              {{rolelistdata.globalCodeName}}</option>
                          </select>

                          <span style="font-size:.875em;color:#dc3545;" *ngIf="Roleidvalidation ===false">
                          Role is required
                        </span>

                          <!-- <div *ngIf="submitted && f.userRoleControl.errors" class="invalid-feedback">
                            <div *ngIf="f.userRoleControl.errors.required">Roles is required</div>
                          </div> -->
                        </div>
                      </div>
                      <hr />
                      <div class="row">
                        <div class="form-group col-md-4 col-lg-4">
                          <label for="">First Name</label>
                          <input type="text"
                          maxlength="20" formControlName="firstname" [(ngModel)]="firstname" class="form-control"
                            placeholder="first Name" (keypress)="ValidTextBox($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                          <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                            <div *ngIf="f.firstname.errors.required">First Name is required</div>
                          </div>
                        </div>

                        <div class="form-group col-md-4 col-lg-4">
                          <label for="">Last Name</label>
                          <input type="text" formControlName="Lastname"
                          maxlength="20"  [(ngModel)]="Lastname" class="form-control"
                            placeholder="last Name" (keypress)="ValidTextBox($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.Lastname.errors }" />
                          <div *ngIf="submitted && f.Lastname.errors" class="invalid-feedback">
                            <div *ngIf="f.Lastname.errors.required">Last Name is required</div>
                          </div>
                        </div>
                        <!-- <div class="form-group col-md-4 col-lg-4">
                          <label for="">Customize</label>
                          <select [(ngModel)]="selectedCustomizLevel" class="form-control" (change)="CustomizeOnchange($event,templateShowScreen)">
                            <option value="1">Default</option>
                            <option  value="2">Customize</option>
                          </select>
                        </div> -->
                      </div>
                      <hr />
                      <div class="row">
                        <div class="form-group col-md-12">
                          <div class="row">
                            <div class="col-md-6 col-12 pt-2 mb-3 mb-md-0">
                              <label for="">Email</label>
                              <input type="text" formControlName="email" lowercase [(ngModel)]="email"
                              class="form-control email"   placeholder="Email"
                               [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                  <div *ngIf="f.email.errors.required">Email is required</div>
                                  <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                              </div>
                              <!-- <input type="text" formControlName="email"
                              [(ngModel)]="email" class="form-control"
                                placeholder="Email" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">email is required</div>
                              </div> -->
                            </div>
                            <div class="col-md-6 col-12">
                              <label for="">Phone Number </label>
                              <input type="text"
                               formControlName="phonenumber"
                               [preValue]="UserListForm.value.phonenumber"
                               [phoneControl]="UserListForm.controls['phonenumber']"
                               phoneMask
                               [(ngModel)]="phone"
                               maxlength="14"
                               class="form-control"
                               placeholder="Phone Number"
                               (keypress)="keyPressCheck($event)"
                               [ngClass]="{ 'errmsg': phoneNumberControl.dirty && phoneNumberControl.invalid, 'validmsg': phoneNumberControl.dirty && phoneNumberControl.valid }"
                        />
                        <div *ngIf="phoneNumberControl.invalid && (phoneNumberControl.dirty || submitted)" class="error-message" style="color: red;">
                          <div *ngIf="phoneNumberControl.errors.required">
                            Phone Number is required
                          </div>
                          <div *ngIf="phoneNumberControl.errors.pattern">
                            Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                          </div>
                        </div>
                              <!-- <input type="text" formControlName="phoneNumber"  maxlength="10" [(ngModel)]="phoneNumber"
                              class="form-control" placeholder="Phone Number" (keypress)="keyPress($event)" [ngClass]="{ 'is-invalid': submitted && f.phoneNumber.errors }"
                                  />
                                  <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                                    <div *ngIf="f.phoneNumber.errors.pattern || f.phoneNumber.errors.maxlength || f.phoneNumber.errors.minlength">Phone number must be at least 10 numbers </div>
                              </div> -->
                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div class="row">
                        <div class="form-group col-md-6 col-lg-6">
                          <label for="">City</label>
                          <input type="text" formControlName="city" [(ngModel)]="city" class="form-control"
                            placeholder="City" (keypress)="ValidTextBox($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                          <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                            <div *ngIf="f.city.errors.required">City is required</div>
                          </div>
                        </div>

                        <div class="form-group col-md-6 col-lg-6">
                          <label for="">Region</label>
                          <select class="form-control" name="region" formControlName="region" [(ngModel)]="region"
                          [ngClass]="{ 'is-invalid': submitted && f.region.errors }"
                            placeholder="region">
                            <option value="0" selected>Select Region </option>
                            <option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
                              {{grouplistdata.globalCodeName}}</option>
                          </select>
                          <div *ngIf="submitted && f.region.errors" class="invalid-feedback">
                            <div *ngIf="f.region.errors.required">Region Name is required</div>
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="form-group col-md-6 col-lg-6">
                          <label for="">Color Theme</label>
                          <select class="form-control" name="colortheme" [ngStyle]="!Coloridvalidation && {'border-color':'red'}" formControlName="colortheme" (change)="ColorThemeActivity($event)"
                            [(ngModel)]="colortheme" placeholder="region" [ngClass]="{ 'is-invalid': submitted && f.colortheme.errors }" >
                            <option value="0">Select Theme Colour  </option>
                            <option *ngFor="let grouplistdata of ColourThemelist" [value]="grouplistdata.globalCodeID">
                              {{grouplistdata.globalCodeName}}</option>
                          </select>

                          <span style="font-size:.875em;color:#dc3545;" *ngIf="Coloridvalidation === false">
                            Color Theme is required
                        </span>
                          <!-- <div *ngIf="submitted && f.colortheme.errors" class="invalid-feedback">
                            <div *ngIf="f.colortheme.errors.required">Color Theme is required</div>
                          </div> -->

                        </div>
                        <div class="form-group col-md-6 col-lg-6" id="airthvirfyblock">
                          <label class="w-100 d-none d-md-block">&nbsp;</label>

                          <div class="row justify-content-between align-items-center mx-0">
                            <div class="form-check pt-1 ml-1">
                              <input type="checkbox" id="AithrVerify"
                              formControlName="AithrVerify"
                                [(ngModel)]="AithrVerify"
                                (change)="CheckAithrVerify($event)" class="form-check-input" />
                              <label class="form-check-label" for="exampleCheck1">Aithr Verify</label>
                            </div>
                            <div class="form-check pt-1 ml-1">
                              <input type="checkbox" id="Representative"
                              formControlName="Representative"
                                [(ngModel)]="Representative"
                                (change)="CheckRepresentative($event)"  class="form-check-input" />
                              <label class="form-check-label" for="exampleCheck1">Representative </label>
                            </div>

                            <div class="form-check pt-1 ml-1" style="display: None;">
                              <input type="checkbox" id="exampleCheck1"
                              (change)="CheckLoggedActivity($event)" formControlName="checkActivitylog"
                                [(ngModel)]="checkActivitylog" class="form-check-input" />
                              <label class="form-check-label" for="exampleCheck1">Track Activity?</label>
                            </div>

                            <div class="form-check pt-1 ml-1" *ngIf="hidesupportTeam ==='true'">
                              <input type="checkbox" id="exampleCheck2"
                              (change)="CheckSupportTeam($event)" formControlName="IsSupportTeam"
                                [(ngModel)]="IsSupportTeam" class="form-check-input" />
                              <label class="form-check-label" for="exampleCheck2 pl-0">In Support Team</label>
                            </div>

                          </div>




                        </div>
                      </div>


                      <hr/>
                      <!-- <div id="IsAithrhubcheckbox" class="row">
                        <div class="form-group col-md-6 col-lg-6">
                            <div class="row mx-0">
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrhub"
                                  [(ngModel)]="IsAithrhubng" class="form-check-input" [(checked)]="IsAithrhubng"  (change)="onAithrUsers($event,1)" />
                                <label class="form-check-label" for="exampleCheck1"  >IsAithrhub  </label>
                              </div>
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrx"

                                  [(ngModel)]="IsAithrxng" class="form-check-input" [(checked)]="IsAithrxng" (change)="onAithrUsers($event,2)" />
                                <label class="form-check-label"  for="exampleCheck1">IsAithrx   </label>
                              </div>
                              <div class="form-check pt-1 col">
                                <input type="checkbox" id="IsAithrMarketPlace"

                                  [(ngModel)]="IsAithrMarketPlaceng" class="form-check-input" [(checked)]="IsAithrMarketPlaceng" (change)="onAithrUsers($event,3)" />
                                <label class="form-check-label" for="exampleCheck1" >IsAithrMarketPlace   </label>
                              </div>
                            </div>
                          </div>


                      </div> -->

                    </section>


                  </div>
                  <div class="modal-footer">
                    <div class="row justify-content-between align-items-center w-100">
                      <div class="col-auto px-0">
                        <!-- <div class="form-check pt-1" *ngIf="hidesupportTeam ==='true'">
                          <input type="checkbox" id="exampleCheck2"
                          (change)="CheckSupportTeam($event)" formControlName="IsSupportTeam"
                            [(ngModel)]="IsSupportTeam" class="form-check-input" />
                          <label class="form-check-label" for="exampleCheck2 pl-0">In Support Team</label>
                        </div> -->
                      </div>
                      <div class="col-auto">
                        <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                        </button>

                        <button class="btn btn-danger btn-sm text-white ml-2 rounded-pill" (click)="CloseAddmodel()">
                          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                        </button>
                      </div>
                    </div>



                  </div>
                </ng-template>
                <ng-template #ModelPasswordUser let-modal>
                  <div class="modal-header" style="background-color: black;">
                    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Reset password</h5>
                    <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                      aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div class="modal-body">
                    <div class="row">
                      <div class="form-group col-md-12">
                        <label for="">Email</label>
                        <input type="text" formControlName="ResetPwdEmail" [(ngModel)]="ResetPwdEmail" readonly
                          class="form-control" (keypress)="ValidTextBox($event)"
                          [ngClass]="{ 'is-invalid': submitted && f.ResetPwdEmail.errors }" />
                        <div *ngIf="submitted && f.ResetPwdEmail.errors" class="invalid-feedback">
                          <div *ngIf="f.ResetPwdEmail.errors.required">email is required</div>
                        </div>
                      </div>

                  
              <div class="form-group col-md-12">
                <label for="name">New password</label>
                <div class="input-group">
                  <input type="password" class="form-control" [(ngModel)]="newPasswordCheck" (keyup)="validatePassword()"   formControlName="password" [type]="showPassword ? 'text' : 'password'">
                  <div class="input-group-append">
                    <button (click)="showPassword = !showPassword" id="eye" type="button">
                      <i class="fa fa-eye" aria-hidden="true" *ngIf="!showPassword"></i>
                      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showPassword"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="f?.password?.errors && f?.password?.touched" class="text-danger">
                  <div *ngIf="f?.password?.errors?.required">New password is required</div>
                </div>

               
              <div class="newpasswordvalidation"  *ngIf="validationtrue">
                <span >{{validationMessage}}</span>
                
                <span [ngStyle]="{ 'color': colormessagePassword }">{{validationMessagePassword}}</span>
                <span [ngStyle]="{ 'color': colormessagelowercase }">{{validationMessagelowercase}}</span>
                <span [ngStyle]="{ 'color': colormessageuppercase }">{{validationMessageuppercase}}</span>
                <span [ngStyle]="{ 'color': colormessagespecial }">{{validationMessagespecial}}</span>
                <span [ngStyle]="{ 'color': colormessagenumeric }">{{validationMessagenumeric}}</span>
            </div>
              </div>

              <div class="form-group col-md-12">
                <label for="name">Confirm password</label>
                <div class="input-group">
                  <input type="password" class="form-control" formControlName="confirmPassword" required
                    [type]="showConfirmPassword ? 'text' : 'password'">
                  <div class="input-group-append">
                    <button (click)="showConfirmPassword = !showConfirmPassword" id="eye" type="button">
                      <i class="fa fa-eye" aria-hidden="true" *ngIf="!showConfirmPassword"></i>
                      <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showConfirmPassword"></i>
                    </button>
                  </div>
                </div>
                <div *ngIf="f?.confirmPassword?.errors && f?.confirmPassword?.touched" class="text-danger">
                  <div *ngIf="f?.confirmPassword?.errors?.required">Confirm password is required</div>
                  <div *ngIf="f?.confirmPassword?.errors?.pwdsDontMatch && f?.confirmPassword?.value !== ''">New password and Confirm password didn't match.</div>
                </div>
              </div>
            </div>
          </div>

                  <!-- (click)="onSubmit();" -->
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="resetpwd();">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
                    </button>

                    <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="Closepasswordmodel()">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                    </button>


                  </div>
                </ng-template>







              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <app-footer id="secondfooter"></app-footer> -->

  </form>

  <!----Show screen start-->
  <ng-template #templateShowScreen>
    <div class="modal-header" style="background-color: black;">
      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">

        Screen  Permission    &nbsp;&nbsp;&nbsp;&nbsp; Role Name:{{selectedGlobalcodeName}}
      </h5>
      <button type="button" class="close rounded-pill" style="color: white;" #closebutton (click)="CloseAddmodel()"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body  screenpermission">
    <!--Province table start-->

    <!-- <div id="screen-list" class="table-responsive">
      <table id="Screentable{{globalcategoryiddropdownfront}}" *ngIf="globalcategoryiddropdownfront==7"
        class="table table-bordered" style="width:100%">
        <thead>
          <tr>
            <th (click)="sort('globalCodeName')"> Name <i class="fa fa-fw fa-sort"></i></th>
            <th style="max-width:35px;text-align: center;" (click)="sort('typeglobal')">Access <i
                class="fa fa-fw fa-sort"></i></th>

          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let ScreenListdata of ScreenList; let i = index; let odd = odd">

            <td> {{ ScreenListdata.screenname }}</td>
            <td class="text-center">
              <input type="checkbox" [(ngModel)]="ScreenListdata.isSelected">


            </td>
          </tr>

        </tbody>
      </table>
    </div> -->

<div  *ngFor="let ScreenMasterListdata of ScreenList; let i = index; let odd = odd">

    <div class="form-group col-md-12 mb-2">
      <div class="w-100 mb-1">
        <div class="control-label" for="facilities">
          <input type="checkbox" [(ngModel)]="ScreenMasterListdata.ismasterselected" (change)="MasterscreencheckValue($event,ScreenMasterListdata.id,ScreenMasterListdata.ismasterselected)" />
          <b class="ml-2">{{ ScreenMasterListdata.screenname }}</b>
        </div>

      </div>

      <div class="col-md-12">
        <div class="row justify-content-between align-items-center">
          <div class="column_2  form-group col-md-4 col-12 mb-0"
            *ngFor="let ScreenListdata of ScreenMasterListdata.submenu; let a = index; let odd_a = odd_a">
            <span>
              <input type="checkbox" [(ngModel)]="ScreenListdata.isSelected">
              <label class="checkbox-inline ml-2">{{ScreenListdata.screenname}}</label>
            </span>

          </div>
        </div>
      </div>
      <hr class="my-1"/>
    </div>

</div>

    </div>
    <div class="model-footer col-12 mb-2">
      <!-- <button type="button" class="btn btn-sm btn-success mr-2" (click)="AssignRoleToScreen()">Assign</button> -->
      <button type="button" class="btn btn-sm btn-danger rounded-pill rounded-pill" (click)="decline()">No</button>
    </div>
  </ng-template>
  <!--Show screen end-->


</section>