import { ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@app/_services';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { interval, Subscription } from 'rxjs';
import { Router, NavigationExtras } from '@angular/router';

import { Component, ViewChild, AfterViewInit } from '@angular/core';

import { DatapassService } from '@app/_services/datapass.service';

import { AppComponent } from "../app.component";
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-welcome-dashboard',
  templateUrl: './welcome-dashboard.component.html',
  styleUrls: ['./welcome-dashboard.component.less']
})
export class WelcomeDashboardComponent implements OnInit {
  [x: string]: any;
  //readmore variable, its true than read more string will print
  supportTicket: boolean = true
  //hiding info box
  visible: boolean = false
  private notifier: NotifierService;
  WelcomeDashboradForm: FormGroup;


  @ViewChild(AppComponent) child;


  constructor(private accountService: AccountService, private router: Router,
    private manageInventoryService: ManageInventoryService, private fb: FormBuilder,
    private dataService: DatapassService,
    notifier: NotifierService
  ) { this.notifier = notifier; }
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  FirstName: any;
  LastName: any;
  Email: any;
  UpdateList = [];
  UserNamevalue: any;
  UserIdvalue: any;
  LoggedRegionName: any;
  submitted = false;
  loading = false;
  isValid: boolean = true;
  groupInventoryCount: any;
  myInventoryCount: any;
  overDamagedCount: any;
  totalHubInventoryCount: any;
  unitsBuildCount: any;
  unitsMaximizedCount: any;

  TotalUserText: any;
  ApprovalSearchedText: any;
  HubInquiriesText: any;
  AccountdamagedText: any;
  DealsBuildText: any;
  HubverifiedText: any;

  myInventoryText: any;
  groupInventoryText: any;
  overDamagedText: any;
  totalHubInventoryText: any;
  unitsBuildText: any;
  unitsMaximizedText: any;

  //for account management section
  TotalUserCount: any = 0;
  ApprovalSearchedCount: any = 0;
  HubInquiriesCount: any = 0;
  AccountdamagedCount: any;
  DealsBuildCount: any = 0;
  HubverifiedCountCount: any = 0;


  DealershipName: any;
  requester: any;
  phone: any;
  Subject: any;
  Description: any;

  opcode: any;

  name = 'Angular';
  @ViewChild('videoPlayer', { static: false }) videoplayer: ElementRef;
  isPlay: boolean = false;
  toggleVideo(event: any) {
    this.videoplayer.nativeElement.play();
  }
  playPause() {
    var myVideo: any = document.getElementById('my_video_1');
    if (myVideo.paused) myVideo.play();
    else myVideo.pause();
  }

  makeBig() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 560;
  }

  makeSmall() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 320;
  }

  makeNormal() {
    var myVideo: any = document.getElementById('my_video_1');
    myVideo.width = 420;
  }

  skip(value) {
    let video: any = document.getElementById('my_video_1');
    video.currentTime += value;
  }

  restart() {
    let video: any = document.getElementById('my_video_1');
    video.currentTime = 0;
  }

  ngOnInit(): void {

    this.CreateDashboardFrom();



    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.DealershipName = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserNamevalue = localStorage.getItem('UserName');
    this.UserIdvalue = localStorage.getItem('userid');

    this.LoggedRegionName = localStorage.getItem('LoggedRegionName');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    // this.WelcomeDashboradForm.get("DealershipName").setValue("1");

    if (this.rolevalue == "SuperAdmin") {
      this.opcode = "1";
      // this.GetEmailLogDetail();
    } else {
      this.opcode = "2";
      // this.GetEmailLogDetail();
    }


    this.WelcomeDashboradForm.get('DealershipName').setValue(this.DealershipName);

    this.groupInventoryCount = 0;
    this.myInventoryCount = 0;
    this.overDamagedCount = 0;
    this.totalHubInventoryCount = 0;
    this.unitsBuildCount = 0;
    this.unitsMaximizedCount = 0;
    // for getting Account Manager
    this.GetAithrAccountManagerDetail();
    this.GetAithrXUpdates();
    this.CreateDashboardFrom();
    this.GetInventoryDashBoardDetails(this.opcode);
    this.GetAccountmanagersectiondashboard(this.opcode);
    console.log('WelcomeDashboradForm:', this.WelcomeDashboradForm.value);

    var userRole = localStorage.getItem('UserFullRole');
    this.changeText(userRole);

  }

  changeText(text: string) {

    this.dataService.changeMessage(text);
  }


  test() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "firstname": "Nic",
        "lastname": "Raboy"
      }
    };
    this.router.navigate(["app"], navigationExtras);
  }

  CreateDashboardFrom() {
    console.log('CreateDashboardFrom() called');
    this.WelcomeDashboradForm = new FormGroup({
      DealershipName: this.fb.control(this.DealershipName, Validators.required),
      phone: this.fb.control('', Validators.required),
      requester: this.fb.control('', Validators.required),
      Subject: this.fb.control('', Validators.required),
      Description: this.fb.control('', Validators.required),
    });
  }

  

  SendQuery() {
    this.submitted = true;
    var modelGroup = {
      "DealershipName": this.WelcomeDashboradForm.controls.DealershipName.value,
      "Phone": this.WelcomeDashboradForm.controls.phone.value,
      "Subject": this.WelcomeDashboradForm.controls.Subject.value,
      "Description": this.WelcomeDashboradForm.controls.Description.value,
      "LoggedUserId": this.UserIdvalue,
      "RequesterName": this.WelcomeDashboradForm.controls.requester.value
    };
    this.manageInventoryService.getSupportTickets(modelGroup)
      .subscribe(
        (data: any) => {

          this.submitted = false;
          this.showNotification("success", "Ticket submitted successfully.");
        },
        error => {
        });
    this.loading = true;
    this.isValid = true;
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  GetAithrAccountManagerDetail() {

    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.accountService.getAithrAccountManagerDetail(modelGroup)
      .subscribe(
        data => {
          if(data.length>0)
          {
          this.FirstName = data[0].firstName;
          this.LastName = data[0].lastName;
          this.Email = data[0].email;
        }
        },
        error => {
        });
  }

  GetInventoryDashBoardDetails(opcode) {
    var modelGroup = {
      "Opcode": opcode,
      "LoggedInUserID": this.UserIdvalue,
      "SelectedDealerID": this.DealerIdvalue,
    }
    this.manageInventoryService.getInventoryDashBoardDetails(modelGroup)
      .subscribe(
        (data: any) => {
          this.groupInventoryCount = data[0].groupInventory;
          this.myInventoryCount = data[0].myInventory;
          this.overDamagedCount = data[0].overDamaged;
          this.totalHubInventoryCount = data[0].totalHubInventory;
          this.unitsBuildCount = data[0].unitsBuild;
          this.unitsMaximizedCount = data[0].unitsMaximized;

          // FOR TEXT VALUES
          this.myInventoryText = data[0].myInventoryText;
          this.groupInventoryText = data[0].groupInventoryText;
          this.overDamagedText = data[0].overDamagedText;
          this.totalHubInventoryText = data[0].totalHubInventoryText;
          this.unitsBuildText = data[0].unitsBuildText;
          this.unitsMaximizedText = data[0].unitsMaximizedText;
        },
        error => {
        });
  }


  //for Account manager section

  GetAccountmanagersectiondashboard(opcode) {
    var modelGroup = {
      "Opcode": opcode,
      "LoggedInUserID": this.UserIdvalue,
      "SelectedDealerID": this.DealerIdvalue,
    }
    this.manageInventoryService.getAccountManagerDashBoardDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.TotalUserCount = data[0].totalUser;
          this.ApprovalSearchedCount = data[0].approvalsSearched;
          this.HubInquiriesCount = data[0].hubInquiry;
          this.AccountdamagedCount = data[0].damagedInventroy;
          this.DealsBuildCount = data[0].dealsBuilt;
          this.HubverifiedCountCount = data[0].hubVerify;

          this.TotalUserText = data[0].totalUserText;
          this.ApprovalSearchedText = data[0].approvalsSearchedText;
          this.HubInquiriesText = data[0].hubInquiryText;
          this.AccountdamagedText = data[0].damagedInventroyText;
          this.DealsBuildText = data[0].dealsBuiltText;
          this.HubverifiedText = data[0].hubVerifyText;


        },
        error => {
        });
  }

  RedirectToDashBoard() {
    return this.router.navigateByUrl("/client-dashboard");
  }

  RedirectToInventory() {
    return this.router.navigateByUrl("/managehub-inventory");
  }
  //onclick toggling both
  onclick() {
    this.supportTicket = !this.supportTicket; //not equal to condition
    this.visible = !this.visible
  }

  GetAithrXUpdates() {
    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.accountService.getAithrXUpdates(modelGroup)
      .subscribe(
        data => {
          this.UpdateList = data;
        },
        error => {
        });
  }
}
