
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AccountService, AlertService } from '@app/_services';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { DashboardService } from '../_services/dashboard.service';
import { DealersList } from '@app/_models/DealersList';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-activate-dealers-list',
  templateUrl: './activate-dealers-list.component.html',
  styleUrls: ['./activate-dealers-list.component.less']
})
export class ActivateDealersListComponent implements OnInit {
  ActivateDealerListForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private router: Router, private dashboardService: DashboardService) {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  // sorting
  key = 'dealerName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  isDesc: boolean = false;
  column: string = 'dealerName';
  dealersLists: DealersList[] = [];
  TotalRecord :any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  submitted = false;
  isValid: boolean = true;
  DealerIdValue:any;
  DealerNameValue:any;
  // function for column sorting
  sort(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealersLists.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.spinner.show();
    // For GettingDealer list
    this.GetListDealersForActivationList();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  openActivateModel(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.DealerNameValue = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
  }


  closeEditModel() {
    this.modalRef.hide();
  }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    this.submitted = true;

    if (this.ActivateDealerListForm.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "id": this.ActivateDealerListForm.controls.DealerId.value,//this.DealerIdValue,
        "DealerName": this.DealerNameValue
      }


      this.dashboardService.ActivateDealers(modelGroup)
        .subscribe(
          (data: any) => {
            debugger
            this.GetListDealersForActivationList();

            this.showNotification("success", "Dealer Activated successfully");
            this.Createform();
            this.modalRef.hide();
            this.closeEditModel();

          },
          error => {
          });
    }
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  // functiono for creating from

  printTable() {
    var prepare = [];
    this.dealersLists.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.dealerCity);
      tempObj.push(e.dealerRegion);
      tempObj.push(e.dealerAddress);
      tempObj.push(e.postalCode);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      prepare.push(tempObj);
    });
  
    var table = '<table><thead><tr><th>DealerName</th><th>DealerCity</th><th>DealerRegion</th><th>DealerAddress</th><th>PostalCode</th><th>DealerPhone</th><th>activeStart</th><th>activeUntil</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
  
    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();
  
    // Wait for the table to be fully rendered before printing
    newWin.onload = function() {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  } 

  Createform() {
    this.ActivateDealerListForm = new FormGroup({
      DealerName: this.fb.control('', Validators.required),
      DealerId: this.fb.control('', Validators.required),
    });
  }
  CloseAddmodel() {
    this.modalRef.hide();
  }

  // function for getting user list
  GetListDealersForActivationList() {
    this.spinner.show();
    this.dashboardService.getListDealersForActivationList()
      .subscribe(
        (data: any) => {
          debugger;
          this.dealersLists = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }
}

