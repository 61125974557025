<section id="admindashboard">
    <form class="mt-2" [formGroup]="AccountSettingForm">
        <div class="container-fluid">
            <div class="row gutters">
                <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="account-settings">
                                <div class="user-profile row">
                                    <div class="user-avatar col-md-6 col-lg-12" >
                                        <!-- <img src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                            alt="Maxwell Admin"> -->
                                        <!-- <img src="../../assets/images/user.png" alt="User Icon"> -->
                                        <!-- <img src="{{ProfileImageValue}}" alt="User Icon" class="user-icon" (click)="functionShow()"  > -->
                                        <div class="user-avatar-img">
                                            <img src="{{ProfileImageValue}}"  alt="User Icon" class="user-icon">
                                        </div>
                                       
                                        <!-- <div class="user-avatar-icon" *ngIf="usershow"> -->
                                        <div class="user-avatar-icon">
                                            <i class="far fa-edit" style="color: #007bff;" (click)="openPictureModelPopup(existingPictureModelPopUp)"
                                            title="Please click on this if want to upload from existing pictures."></i>
                                            <input style="display: none;"
                                        #fileInput type="file" class="custom-file-input" id="customFile" 
                                        (change)="onFileChanged($event)"
                                          accept=".jpg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx">
                                        <label class="custom-file-label" #labelImport style="display: none;"
                                        id="choosefile" for="customFile">Choose file</label>

                                        <i (click)="fileInput.click()" style="color: #1e1e8f;" title="Please click on button for upload new profile picture." 
                                            class="fas fa-upload"></i>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-12">
                                        <ul class="list-unstyled mb-0">
                                            <li><b>Name:</b>{{fullName}}</li>
                                            <li><b>Email:</b>{{Email}}</li>
                                            <li><b>Phone:</b>{{PhoneNumber}}</li>
                                            <li><b>Role:</b>{{RoleValueName}}</li>
                                            <li><b>Dealer:</b>{{DealerNameValue}}</li>
                                            <li><b>Region:</b>{{Region}}</li>
                                        </ul>
                                        
                                    </div>
                                    <div class="about col mt-3">
                                        <h5 class="mb-2 text-primary">About</h5>
                                        <p>Welcome back, <b>{{FirstName}}  {{LastName}} </b>! We're glad to see you here again.
                                             As a logged-in user, you have access to a range of personalized features and benefits 
                                            that enhance your experience on our platform.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12 mt-3 mt-lg-0">
                    <div class="card h-100">
                        <div class="card-body">
                            <div class="row gutters mb-3">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                                    <h5 class="mb-3 text-primary">Personal Details</h5>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="FirstName">First Name</label>
                                        <input type="text" [(ngModel)]="FirstName" disabled formControlName="FirstName"
                                            class="form-control" id="FirstName" placeholder="Enter First Name">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="LastName">Last Name</label>
                                        <input type="text" [(ngModel)]="LastName" disabled formControlName="LastName"
                                            class="form-control" id="LastName" placeholder="Enter Last Name">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="eMail">Email</label>
                                        <input type="email" [(ngModel)]="Email" disabled formControlName="email"
                                            class="form-control" id="eMail" placeholder="Enter email ID">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="PhoneNumber">Phone Number</label>
                                        <input type="text" [(ngModel)]="PhoneNumber" disabled
                                            formControlName="phoneNumber" class="form-control" id="PhoneNumber"
                                            placeholder="Enter Phone Number">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="city">City</label>
                                        <input type="name" [(ngModel)]="City" formControlName="city"
                                            class="form-control" id="city" disabled placeholder="Enter City">
                                    </div>
                                </div>
                                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                    <div class="form-group">
                                        <label for="Region">Region</label>
                                        <input type="text" [(ngModel)]="Region" formControlName="region"
                                            class="form-control" id="Region" disabled placeholder="Region">
                                    </div>
                                </div>



                            </div>
                           
                            
                            <div class="row mt-3 justify-content-between align-items-center">
                                <div class="form-group col-xl-6 col-12">
                                    <label for="ConfirmPassword">Theme Color</label>
                                    <select class="form-control" name="colortheme"
                                    formControlName="colortheme"
                                    [(ngModel)]="colortheme" >
                                    <option value="0">Select Theme Colour  </option>
                                    <option *ngFor="let grouplistdata of ColourThemelist" [value]="grouplistdata.globalCodeID">
                                      {{grouplistdata.globalCodeName}}</option>
                                  </select>
                                </div>
                                <div id="uploadphoto" class="form-group col-xl-6 col-12">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-auto">
                                            <!-- <label for="picture" class="w-100">Upload Photo</label> -->
                                        </div>
                                        <div class="col-auto">
                                            <!-- <i class="fas fa-file-upload" (click)="openPictureModelPopup(existingPictureModelPopUp)"
                                            title="Please click on this if want to upload from existing pictures."></i>    -->
                                        </div>
                                    </div>
                                      <div class="custom-file col-12">
                                        <!-- <input style="display: none;"
                                        #fileInput type="file" class="custom-file-input" id="customFile" 
                                        (change)="onFileChanged($event)"
                                          accept=".jpg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx">
                                        <label class="custom-file-label" #labelImport style="display: none;"
                                        id="choosefile" for="customFile">Choose file</label>

                                        <button (click)="fileInput.click()">cHECK</button> -->
                                      </div>
                                     
                                </div>
                            </div>
                            <div class="row mt-3 justify-content-between align-items-center">
                                    <div class="col-12 text-right">
                                        <button type="button" id="submit" name="button" (click)="CancelButton()"
                                            class="btn btn-secondary rounded-pill">Cancel</button>
                                        <button type="button" id="submit" name="submit" class="btn btn-primary ml-2 rounded-pill"
                                            (click)="UpdateThemeColor()">Update</button>
                                    
                                        <button type="button" id="submit" name="submit" class="btn btn-danger ml-2 rounded-pill"
                                            (click)="openChangePasswordModelPopup(changePasswordModelPopup)">Change Password</button>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <ng-template #existingPictureModelPopUp>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Existing Pictures
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="existingPicture" class="modal-body">

                <section class="generate-report col-12">
                    <div class="row">
                        <div class="user-avatar col-lg-3 col-12"
                                *ngFor="let pictureList of existingPictureslist" >
                            <img (click)="GetImageClick(pictureList.profilePhoto);" title="click to update Profile Picture" 
                            src="{{pictureList.logobaseSixtyfour}}" alt="User Icon">
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                
                <!-- <button type="button"  style="margin-left: 14px;"
                    (click)="decline()" class="btn btn-sm bg-gradient-info rounded-pill">Update</button> -->

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>
        <ng-template #changePasswordModelPopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Update Password
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row gutters mt-3">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 passwordEye">
                            <div class="form-group">

                                <label for="NewPassword">New Password</label>

                                <input [type]="password" class="form-control"  [(ngModel)]="newPasswordCheck"
                                 formControlName="newPasswordCheck" (keyup)="validatePassword()">
                                <div *ngIf="f?.newPasswordCheck?.errors && f?.newPasswordCheck?.touched"
                                    class="text-danger">
                                    <div>New password is required</div>
                                </div>

                                <div class="newpasswordvalidation"  *ngIf="validationtrue">
                                    <span >{{validationMessage}}</span>
                                    
                                    <span [ngStyle]="{ 'color': colormessagePassword }">{{validationMessagePassword}}</span>
                                    <span [ngStyle]="{ 'color': colormessagelowercase }">{{validationMessagelowercase}}</span>
                                    <span [ngStyle]="{ 'color': colormessageuppercase }">{{validationMessageuppercase}}</span>
                                    <span [ngStyle]="{ 'color': colormessagespecial }">{{validationMessagespecial}}</span>
                                    <span [ngStyle]="{ 'color': colormessagenumeric }">{{validationMessagenumeric}}</span>
                                </div>


                                <button (click)="onClick()" id="eye" type="button">
                                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                                </button>

                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12 passwordEye">
                            <div class="form-group">

                                <label for="ConfirmPassword">Confirm New Password</label>

                                <input [type]="passwordConfirm" class="form-control"
                                formControlName="confirmPassword">
                                <div *ngIf="f?.confirmPassword?.errors && f?.confirmPassword?.touched"
                                    class="text-danger">
                                    <div *ngIf="f?.confirmPassword?.errors?.required">Confirm password is
                                        required
                                    </div>
                                    <div *ngIf="f?.confirmPassword?.errors?.matchPassword">New password and
                                        Confirm password didn't match.
                                    </div>
                                </div>
                                <button (click)="onClickConfirm()" id="eye" type="button">
                                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!showConfirm"></i>
                                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="showConfirm"></i>
                                </button>
                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="form-group">


                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                
                <button type="button"  style="margin-left: 14px;"
                    (click)="UpdatePassWord()" class="btn btn-sm bg-gradient-info rounded-pill">Update</button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

    </form>
</section>