<!-- header start -->
<div id="admindashboard" style="padding:0 !important;height: 100vh;">
    <header id="header" class="navbar navbar-full">
        <div class="container-md">
            <div class="col-12 topbar">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto px-0 px-sm-2">
                        <div class="d-inline-block d-lg-none pr-1">
                            <button style="position: relative;top: 2px;" class="navbar-toggler m-0" type="button"
                                data-toggle="collapse" data-target="#mainNavbarCollapse">
                                &#9776;
                            </button>
                        </div>
                        <a class="logocon" href="#/">
                            <img class="main-logo" src="../../assets/images/loginImage.png" alt="website logo">
                            <img class="small-logo" style="display:none;" src="../../assets/images/BrandImage.png">
                        </a>
                    </div>
    
                 <div class="navbar-toggleable-md" id="mainNavbarCollapse">
                    <ul class="list-unstyled nav navbar-nav">
                      <li class="nav-item">
                        <a href="#/">Home</a>
                      </li>
                      <li class="nav-item">
                        <a class="" href="#/about-us" >About Us</a>
                      </li>
                      <li class="nav-item">
                        <a href="#/contact-us">Contact Us</a>
                      </li>
                       <!-- <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" type="button" id="MoreOptions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            More Options
                          </a>
                          <div class="dropdown-menu" aria-labelledby="MoreOptions">
                            <a class="dropdown-item" routerLink="/shipping-returns">Shipping return</a>
                            <a class="dropdown-item" routerLink="/tearm-condition">Tearm & Condition</a>
                            <a class="dropdown-item" routerLink="/privacy-policy">Privacy Policy</a>
                            <a class="dropdown-item" routerLink="/faqs-page">Help & FAQ</a>
                          </div>
                      </li> -->
                    </ul>
                  </div>
    
    
                    <div class="col-auto">
                        <div class="userlogin">
                            <a href="#/login" class="btn btn-danger btn-md mr-2">Sign In</a>
                            <!-- <a href="/#/Register/0" class="btn btn-transparent btn-md">SignUp</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    <!-- header end -->

<section class="inner-intro bg-1 bg-overlay-black-70">
    <div  class="container">
      <div class="col-12 intro-title text-center">
         <div class="row justify-content-between align-items-center h100">
               <div class="col-md-12 banner-content">
                 <h1 class="text-white text-uppercase">About us </h1>
                 <ul class="page-breadcrumb">
                    <li><a href="#"><i class="fa fa-home"></i> Home</a> <i class="fa fa-angle-double-right"></i></li>
                    <!-- <li><a href="#">pages</a> <i class="fa fa-angle-double-right"></i></li> -->
                    <li><span>about us</span> </li>
                 </ul>
            </div>
         </div>
       </div>
      </div>
</section>

<section class="welcome-4 page-section-ptb white-bg">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-10">
         <div class="section-title">
           <span>Welcome to Spadez</span>
           <h2>Your Trusted Source for Cars Online!</h2>
           <div class="separator"></div>
           <p>Everything you need to craft an<strong> exceptional dealership</strong> website is right here, made for car <strong>sellers, dealers, and auto</strong> retailers.We equip you with everything to design an outstanding dealership website, purpose-built for car sellers, dealers, and automotive retailers.</p>
         </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <img class="img-fluid" src="../../assets/images/audi-interior.jpg" alt="audi interior">
      </div>
      <div class="col-md-6">
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>
        <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here</p>
        <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-lg-3 col-sm-6">
        <div class="feature-box-3">
          <div class="icon">
           <i class="fab fa-bandcamp"></i>
          </div>
          <div class="content">
            <h6>All brands </h6>
            <p class="mb-0">Obcaecati adipisci vero dolorum pariatur </p>
          </div>
         </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-box-3">
          <div class="icon">
            <i class="fas fa-handshake"></i>
          </div>
          <div class="content">
            <h6>Free Support</h6>
            <p class="mb-0">lorem ipsum dolor sit amet, consectetur</p>
          </div>
         </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-box-3">
          <div class="icon">
            <i class="fas fa-people-carry"></i>
          </div>
          <div class="content">
            <h6>Dealership </h6>
            <p class="mb-0">Sit quisquam rerum corporis neque</p>
          </div>
         </div>
      </div>
      <div class="col-lg-3 col-sm-6">
        <div class="feature-box-3">
          <div class="icon">
            <i class="fas fa-wallet"></i>
          </div>
          <div class="content">
            <h6>affordable</h6>
            <p class="mb-0">Suscipit aperiam reiciendis, ea odio?</p>
          </div>
         </div>
      </div>
    </div>
  </div>
</section>

<section class="counter counter-style-2 bg-red bg-1 bg-overlay-black-70 page-section-ptb">
  <div class="container">
    <div class="row">
     <div class="col-lg-3 col-sm-6 item">
     <div class="counter-block text-start mb-lg-0 mb-4">
        <div class="separator"></div>
        <div class="info">
           <h6 class="text-white">Vehicles in Stock</h6>
          <i class="fas fa-car-alt"></i>
           <b class="timer text-white" data-to="561" data-speed="10000">561</b>
         </div>
        </div>
     </div>
     <div class="col-lg-3 col-sm-6 item">
     <div class="counter-block text-start mb-lg-0 mb-4">
        <div class="separator"></div>
        <div class="info">
           <h6 class="text-white">Dealer Reviews</h6>
           <i class="far fa-comments"></i>
           <b class="timer text-white" data-to="856" data-speed="10000">856</b>
         </div>
        </div>
     </div>
     <div class="col-lg-3 col-sm-6 item">
     <div class="counter-block text-start mb-sm-0 mb-4">
        <div class="separator"></div>
        <div class="info">
           <h6 class="text-white">Happy Customer</h6>
             <i class="far fa-smile"></i>
           <b class="timer text-white" data-to="789" data-speed="10000">789</b>
         </div>
        </div>
     </div>
      <div class="col-lg-3 col-sm-6 item">
      <div class="counter-block text-start mb-0">
        <div class="separator"></div>
        <div class="info">
           <h6 class="text-white">Awards</h6>
            <i class="fas fa-trophy"></i>
           <b class="timer text-white" data-to="356" data-speed="10000">356</b>
         </div>
        </div>
     </div>
    </div>
  </div>
</section>


<section class="tester_new text-center">
  <h2>What Our Happy Clients say about us</h2>
  <hr>
  <h5 class="mb-5">Our Testimonial</h5>
  <div class="container">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
      </ol>
      <div class="carousel-inner" role="listbox">
        <div class="carousel-item active">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
              <p>"The teaching, in my opinion, is the best that anyone can hope for. Sekolah High School staff provide an environment in which students are able to reach their full potential. As well as this. I felt constantly challenged to push myself to exceed what is expected. I have no doubt that the persion I become is due to my time spent here and that the last six years have been the best of my life."</p>
              <hr>
              <h5>Cynthia Nelson</h5>
              <p>Student of scince</p>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
              <p>"The teaching, in my opinion, is the best that anyone can hope for. Sekolah High School staff provide an environment in which students are able to reach their full potential. As well as this. I felt constantly challenged to push myself to exceed what is expected. I have no doubt that the persion I become is due to my time spent here and that the last six years have been the best of my life."</p>
              <hr>
              <h5>Cynthia Nelson</h5>
              <p>Student of scince</p>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
        <div class="carousel-item">
          <div class="row">
            <div class="col-lg-2"></div>
            <div class="col-lg-8">
              <p>"The teaching, in my opinion, is the best that anyone can hope for. Sekolah High School staff provide an environment in which students are able to reach their full potential. As well as this. I felt constantly challenged to push myself to exceed what is expected. I have no doubt that the persion I become is due to my time spent here and that the last six years have been the best of my life."</p>
              <hr>
              <h5>Cynthia Nelson</h5>
              <p>Student of scince</p>
            </div>
            <div class="col-lg-2"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>




<!-- Footer  start-->
<footer class="text-white pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">About</h6>
          <img style="width:135px;" class="mb-2" src="../../assets/images/BrandImage.png" alt="website logo" />
          <p class="">
             Dealer Beusiness System (DBS) is a comprehensive software solution designed for 
            automotive dealerships to enhance operational efficiency. 
          </p>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Recent Posts</h6>
          <p><a href="#" class="text-white">Buy your dream car</a></p>
          <p><a href="#" class="text-white">Does Your Life Lack Meaning</a></p>
          <p><a href="#" class="text-white">The A Z Of Motivation</a></p>
          <!-- <p><a href="#" class="text-white">Product 4</a></p> -->
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Useful links</h6>
          <p><a href="#" class="text-white">Your Account</a></p>
          <p><a href="#" class="text-white">Become an Affiliate</a></p>
          <p><a href="#" class="text-white">Shipping Rates</a></p>
          <p><a href="#" class="text-white">Help</a></p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mb-md-0 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Contact</h6>
          <p><i class="fas fa-home mr-3"></i> Calgary, Canada</p>
          <p><i class="fas fa-envelope mr-3"></i>chrisavery0594@gmail.com</p>
          <p><i class="fas fa-phone mr-3"></i> (403) 404-6773</p>
        </div>
      </div>
    </div>
    <hr style="border-color: #2b2b2b;"/>
    <div class="footer-copyright text-center py-3">
      © 2024 Copyright:
      <a href="#" class="text-white"> SPADEZ SOLUTIONS PRIVATE LIMITED</a>
    </div>
  </footer>
  <!-- footer end -->


  </div>