<section id="admindashboard">
    <!-- <form class="col-12 px-0" [formGroup]="loanCalculatorForm"> -->
        <div class="container-fluid">
            <div class="page-header">
                <div class="row w-100 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3>Car Loan Calculator</h3>
                    </div>
                </div>
            </div>

            <!-- Car Loan Calculator -->
            <div class="row">
                <div class="col-md-12">
                    <div class="card p-0 mb-0">
                        <div id="adminsetting" class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                                        <li class="nav-item">
                                            <a class="nav-link active" id="CalculatePayment-tab" data-toggle="tab" href="#CalculatePayment" role="tab"
                                                aria-controls="CalculatePayment" aria-selected="true"><b>Calculate my Payment</b></a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="CalculateAfford-tab" data-toggle="tab" href="#CalculateAfford" role="tab"
                                                aria-controls="CalculateAfford" aria-selected="false"><b>Calculate what I can afford </b></a>
                                        </li>
                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane show active" id="CalculatePayment" role="tabpanel" aria-labelledby="CalculatePayment-tab">
                                            <div class="container-fluid">
                                                <form class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-xl-5 col-md-12">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Term:</label>
                                                                                <select id="term-months-select"
                                                                                [(ngModel)]="CalTerm"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                 class="form-control">
                                                                                 <option value="">Choose a Term</option>
                                                                                 <option value="6">6 Months</option>
                                                                                 <option value="12">12 Months</option>
                                                                                 <option value="24">24 Months</option>
                                                                                 <option value="36">36 Months</option>
                                                                                 <option value="48">48 Months</option>
                                                                                 <option value="60">60 Months</option>
                                                                                 <option value="66">66 Months</option>
                                                                                 <option value="72">72 Months</option>
                                                                                 <option value="84">84 Months</option>
                                                                                 <option value="90">90 Months</option>
                                                                                 <option value="96">96 Months</option>
                                                                                  </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Province:</label>
                                                                                <select class="form-control" name="CalProvince"
                                                                                [(ngModel)]="CalProvince"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                   >
                                                                                   <option value="0" selected>Select Province</option>
                                                                                   <option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
                                                                                    {{grouplistdata.globalCodeName}}</option>
                                                                               </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Price of Vehicle:</label>
                                                                                <input type="text"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="PriceofVehicleControl"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Interest rate:</label>
                                                                                <input type="text"
                                                                                [(ngModel)]="CalculateInterestrate"
                                                                                [ngModelOptions]="{ standalone: true }"

                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Down payment:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalculateDownpayment"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Trade-in value:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalculateTradeinvalue"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Balance Owing on Trade:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalBalanceOwingonTrade"
                                                                                    class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            
                                                                            <div class="form-group">
                                                                                <button type="button" class="btn btn-primary mt-1 rounded-pill"
                                                                                (click) ="CalculateFirstPanel()" >Loan Calculate</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="pieChartblock" class="col-xl-3 col-md-12 mb-3 mt-2 mb-xl-0 mt-xl-0">
                                                            <div id="inventorycanvas">
                                                                <h5 class="mb-1">TLV vs Interest</h5>
                                                                <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                                [colors]="colors"   [chartType]="pieChartType"
                                                                    [options]="pieChartOptions" [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                                                </canvas>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-md-12">
                                                            <div id="calcoutput" class="card">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Total Loan Amount</label>
                                                                                <h6>{{CalTotalLoanAmount | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Monthly Payment</label>
                                                                                <h6>{{CalMonthlyPayment | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Bi-Weekly Payment</label>
                                                                                <h6>{{CalBiWeeklyPayment | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Weekly Payment</label>
                                                                                <h6>{{CalWeeklyPayment | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Total Interest Paid</label>
                                                                                <h6>{{CalTotalInterestPaid | currency}}</h6>
                                                                            </div>

                                                                            <div class="form-group">
                                                                                <label>Taxes</label>
                                                                                <h6>{{CalTotalTaxtesPaid | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <p> *All taxes are included</p>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <small>**The calculation is based on the information you provide and is for illustrative
                                                                                    and general information purposes only.
                                                                                    This should not be relied upon as specific financial or other advice.</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade " id="CalculateAfford" role="tabpanel" aria-labelledby="CalculateAfford-tab">
                                            <div class="container-fluid">
                                                <form class="col-md-12">
                                                    <div class="row">
                                                        <div class="col-xl-5 col-md-12">
                                                            <div class="card">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Term:</label>
                                                                                <select id="term-months-select"
                                                                                [(ngModel)]="CalSecondTerm"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                  class="form-control">
                                                                                    <option value="">Choose a Term</option>
                                                                                    <option value="6">6 Months</option>
                                                                                    <option value="12">12 Months</option>
                                                                                    <option value="24">24 Months</option>
                                                                                    <option value="36">36 Months</option>
                                                                                    <option value="48">48 Months</option>
                                                                                    <option value="60">60 Months</option>
                                                                                    <option value="66">66 Months</option>
                                                                                    <option value="72">72 Months</option>
                                                                                    <option value="84">84 Months</option>
                                                                                    <option value="90">90 Months</option>
                                                                                    <option value="96">96 Months</option>
                                                                                  </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Province:</label>
                                                                                <select class="form-control" name="CalSecondProvince"
                                                                                [(ngModel)]="CalSecondProvince"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                   >
                                                                                   <option value="0" selected>Select Province</option>
                                                                                   <option *ngFor="let grouplistdata of ProvinceSecondlist" [value]="grouplistdata.globalCodeID">
                                                                                    {{grouplistdata.globalCodeName}}</option>
                                                                               </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Frequency of Payment:</label>
                                                                                <select id="payment-freq-select"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="FrequencySecondPanel"
                                                                                name="Select-Payment-Frequency"
                                                                                 class="form-control">
                                                                                 <option value="0">Select Frequency</option>
                                                                                    <option value="1">Monthly</option>
                                                                                    <option value="2">Bi-Weekly</option>
                                                                                    <option value="3">Weekly</option>
                                                                                  </select>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Desired Payment:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalDesiredPayment"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Interest rate:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalSecondInterestrate"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Down payment:</label>
                                                                                <input type="number"

                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalSecondDownpayment"
                                                                                class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Trade-in value:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalSecondTradeinvalue"
                                                                                class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Balance Owing on Trade:</label>
                                                                                <input type="number"
                                                                                [ngModelOptions]="{ standalone: true }"
                                                                                [(ngModel)]="CalSecondBalanceOwingonTrade"
                                                                                 class="form-control" (input)="restrictInput($event)">
                                                                            </div>
                                                                            
                                                                            <div class="form-group">
                                                                                <button type="button" class="btn btn-primary mt-1 rounded-pill"
                                                                                (click) ="CalculateSecondPanel()">Loan Calculate</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div id="pieChartblock" class="col-xl-3 col-md-12 mb-3 mt-2 mb-xl-0 mt-xl-0">
                                                            <div id="inventorycanvas">
                                                                <h5 class="mb-1">TLV vs Interest</h5>
                                                                <canvas baseChart [data]="pieChartDataSecond"
                                                                [colors]="colorsSecond" [labels]="pieChartLabelsSecond" [chartType]="pieChartTypeSecond"
                                                                    [options]="pieChartOptionsSecond" [plugins]="pieChartPluginsSecond" [legend]="pieChartLegendSecond">
                                                                </canvas>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 col-md-12">
                                                            <div id="calcoutput" class="card">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="form-group">
                                                                                <label>Vehicle Price</label>
                                                                                <h6>{{CalVehiclePrice | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Sales Tax</label>
                                                                                <h6>{{CalSalesTax | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Total Interest Paid</label>
                                                                                <h6>{{SecondTotalInterestPaid | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <label>Total Loan Amount</label>
                                                                                <h6>{{SecondotalLoanAmount | currency}}</h6>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <p> *All taxes are included</p>
                                                                            </div>
                                                                            <div class="form-group">
                                                                                <small>**The calculation is based on the information you provide and is
                                                                                     for illustrative and general information purposes only.
                                                                                     This should not be relied upon as specific financial or other advice.</small>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

    <!-- </form> -->
</section>