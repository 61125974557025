import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({
  providedIn: 'root'
})
export class GlobalcodeService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
      private router: Router,
      private http: HttpClient
  ) {

      this.accountSubject = new BehaviorSubject<Account>(null);
      this.account = this.accountSubject.asObservable();

      this.loginSubject = new BehaviorSubject<Login>(null);
      this.logins = this.loginSubject.asObservable();

  }


  private baseUrl_AddAddGlobalcode: string = environment.apiUrl + '/Admin/AddGlobalcode';
  private baseUrl_GetAllcodelist: string = environment.apiUrl + '/Admin/GetAllGlobalcodelist';
  private baseUrl_EditGlobalcodeList: string = environment.apiUrl + '/Admin/EditGlobalcode';
  private baseUrl_deleteglobalcode: string = environment.apiUrl + '/Admin/deleteglobalcode';

  private baseUrl_GetAllcodebyRolelist: string = environment.apiUrl + '/GlobalCodeCategory/GetRoleList';
  private baseUrl_GetAllcodebysubscriptionlist: string = environment.apiUrl + '/GlobalCodeCategory/GetsubscriptionList';
  private baseUrl_getGlobalcodesubscriptionlistbyID: string = environment.apiUrl + '/GlobalCodeCategory/GetGlobalcodesubscriptionlistbyID';

  private baseUrl_getDealerActivated: string = environment.apiUrl + '/GlobalCodeCategory/GetDealerActivatedDetailbyId';




  private baseUrl_GetReasonList: string = environment.apiUrl + '/GlobalCodeCategory/GetReasonList';
  private baseUrl_GetProvinceList: string = environment.apiUrl + '/GlobalCodeCategory/GetProvinceList';
  private baseUrl_getSourceList: string = environment.apiUrl + '/GlobalCodeCategory/GetInventorySourceList';
  

  private baseUrl_GetVehicleCondtionsList: string = environment.apiUrl + '/GlobalCodeCategory/GetVehicleCondtionsList';
  
  private baseUrl_GetGroupFindCarListdata: string = environment.apiUrl + '/GlobalCodeCategory/GetGroupFindCarListdata';
  private baseUrl_GetGroupList: string = environment.apiUrl + '/GlobalCodeCategory/GetGroupLists';
  private baseUrl_GetRepresentativelist: string = environment.apiUrl + '/GlobalCodeCategory/GetRepresentativelist';
  private baseUrl_GetColourThemelist: string = environment.apiUrl + '/GlobalCodeCategory/GetColourThemelist';

  private baseUrl_GetGlobalcodelistbyName: string = environment.apiUrl + '/Admin/GetGlobalcodelistbyName';

  private baseUrl_GetUserTypeList: string = environment.apiUrl + '/Admin/GetUserTypeList';

  private baseUrl_GetGroupBybyUserGroup: string = environment.apiUrl + '/GlobalCodeCategory/GetGroupBybyUserGroup';

  private baseUrl_Getfueltypelist: string = environment.apiUrl + '/GlobalCodeCategory/Getfueltypelist';

  private baseUrl_GetValueTypeLists: string = environment.apiUrl + '/GlobalCodeCategory/GetValueTypeLists';

  private baseUrl_GetYearLists: string = environment.apiUrl + '/GlobalCodeCategory/GetYearLists';

  private baseUrl_sendemailRegister: string = environment.apiUrl + '/Admin/SendemailRegister';

  private baseUrl_GetCbbValueLists: string = environment.apiUrl + '/GlobalCodeCategory/GetCbbValueLists';

  private baseUrl_Get_matrixreport: string = environment.apiUrl + '/Reportmatrix/GetAllReportmatrixChart';

  private baseUrl_GetmatrixreportDetailChart: string = environment.apiUrl + '/Reportmatrix/GetAllReportDetailChart';  

  private baseUrl_GetAllReportChartListData: string = environment.apiUrl + '/Reportmatrix/GetAllReportChartLists';  
  private baseUrl_GetdetailbyVIN: string = environment.apiUrl + '/Reportmatrix/GetdetailbyVIN';  
  
  private baseUrl_Get_matrixreportList: string = environment.apiUrl + '/Reportmatrix/GetAllReportChartListData';

  private baseUrl_GetDealerCity: string = environment.apiUrl + '/GlobalCodeCategory/GetDealerCity';

  private baseUrl_GetAppDetailList: string = environment.apiUrl + '/CreditAppGateway/GetApplication';

  private baseUrl_GetAWorkSheetDetailList: string = environment.apiUrl + '/CreditAppGateway/GetWorkSheet';


  private baseUrl_SetAWorkSheetDetailList: string = environment.apiUrl + '/CreditAppGateway/SetWorkSheet';

  private baseUrl_GetUVCDetails: string = environment.apiUrl + '/CreditAppGateway/GetVehiclebyVIN';
  getUVCDetails(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetUVCDetails,formadat);
  }

  setAWorkSheetDetailList(formadat) {
    return this.http.put<CDATASection[]>(this.baseUrl_SetAWorkSheetDetailList,formadat);
  }
  getAWorkSheetDetailList(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAWorkSheetDetailList,formadat);
  }

  getAppDetailList(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAppDetailList,formadat);
  }

// getting City of Dealer
getDealerCity() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetDealerCity);
  }


  getdetailbyVIN(formadat) {
    return this.http.post<any[]>(this.baseUrl_GetdetailbyVIN,formadat);
  }
  getAllReportChartListData(formadat) {
    return this.http.post<any[]>(this.baseUrl_GetAllReportChartListData,formadat);
  }


  getmatrixreportDetailChart(formadat) {
    return this.http.post<any[]>(this.baseUrl_GetmatrixreportDetailChart,formadat);
  }

  

getmatrixreport(formadat) {
  return this.http.post<any[]>(this.baseUrl_Get_matrixreport,formadat);
}

getmatrixreportList(formadat) {
  return this.http.post<any[]>(this.baseUrl_Get_matrixreportList,formadat);
}




  getCbbValueLists(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetCbbValueLists,formadat);
  }


  sendemailRegister(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_sendemailRegister,formadat);
  }

  getGetYearLists(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetYearLists,formadat);
  }

  getValueTypeLists(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetValueTypeLists,formadat);
  }

  getfueltypelist(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_Getfueltypelist,formadat);
  }

  getGroupListBybyUserGroup(formadat) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetGroupBybyUserGroup,formadat);
  }

  getUserTypeList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetUserTypeList,formdata);
  }

  //calling function saving method
  SaveaddGlobalcode(formdata) {
    return this.http.post<any[]>(this.baseUrl_AddAddGlobalcode, formdata);
}

getGlobalcodelistbyName(formdata) {
  return this.http.post<CDATASection[]>(this.baseUrl_GetGlobalcodelistbyName,formdata);
}

  //calling function saving method
  deleteGlobalcode(formdata) {
    return this.http.post<any[]>(this.baseUrl_deleteglobalcode, formdata);
}


  //calling function saving method
  EditDealers(formdata) {
    return this.http.post<any[]>(this.baseUrl_EditGlobalcodeList, formdata);
}



//get grouplist

  //calling function saving method
  GetGlobalcodelist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllcodelist);
}

EditGlobalcode(formadat) {
  return this.http.post<any[]>(this.baseUrl_EditGlobalcodeList,formadat);
}


getGlobalcodeByRolelist(formadat) {
  return this.http.post<CDATASection[]>(this.baseUrl_GetAllcodebyRolelist,formadat);
}

getGlobalcodesubscriptionlist() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetAllcodebysubscriptionlist);
}

getGlobalcodesubscriptionlistbyID(FormData) {
  return this.http.post<any>(this.baseUrl_getGlobalcodesubscriptionlistbyID, FormData);

}

getProvinceList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetProvinceList);
}

getSourceList() {
  return this.http.get<CDATASection[]>(this.baseUrl_getSourceList);
}

getReasonList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetReasonList);
}

getRepresentativelist() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetRepresentativelist);
}

getVehicleCondtionsList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetVehicleCondtionsList);
}

getGroupList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetGroupList);
}

getGroupFindCarListdata(formadat) {
  return this.http.post<CDATASection[]>(this.baseUrl_GetGroupFindCarListdata,formadat);
}


getColourThemelist() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetColourThemelist);
}

getDealerActivated(FormData) {
  return this.http.post<any>(this.baseUrl_getDealerActivated, FormData);

}

}
