<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="AddCustomerForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3">
  
            <div class="row mx-0 justify-content-between align-items-center mb-2">
              <div class="col-auto documentbtn">
                <ul class="list-unstyled mb-1">
                  <li><button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="copytable('usertable')">Copy</button></li>
                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                  </li>
  
                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                  </li>
                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                  </li>
                </ul>
              </div>
  
              <div class="search-heros col-md-auto mt-2 mt-md-0">
                <div class="row justify-content-between align-items-center mb-1">
                  <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                    <label class="mr-3 mt-2">Search:</label>
                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                      [ngModelOptions]="{standalone: true}" autocomplete="off">
                  </div>
                  <div class="form-group mb-0 ml-md-3">
                    <label style="padding:2px;"> Items per Page:</label>
                    <select (change)="handlePageSizeChange($event)">
                      <option *ngFor="let size of pageSizes" [ngValue]="size">
                        {{ size }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
  
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="card card-raised h-100">
                  <div class="card-header card-raised bg-info text-white">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-sm-6">
                        <h5 class="card-title text-white text-uppercase mb-0">List of Customers</h5>
                      </div>
                      <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                        <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                      </div>
                    </div>
                  </div>
                  <div class="card-body p-4">
                    <div class="table-responsive">
                      <table id="usertable" class="table table-bordered" style="width:100%">
                        <thead>
                          <tr>
                            <th (click)="sort('firstName')">First Name <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('lastName')"> Last Name <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('email')"> Email<i class="fa fa-fw fa-sort"></i>
                            <th (click)="sort('globalCodeName')"> Region<i class="fa fa-fw fa-sort"></i>
                            <th (click)="sort('phone')"> Phone Number<i class="fa fa-fw fa-sort"></i>
                            <th (click)="sort('city')">City <i class="fa fa-fw fa-sort"></i>
                            </th>
                           
                            <th style="text-align:center;">
                              <button type="button" class="btn btn-primary btn-sm text-white rounded-pill" title="Add Customer"
                                (click)="openAddModel(ModelAddUser,'')">
                                <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                                Add Customer
                              </button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let CustomerListdata of CustomerList| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                            <td>{{ CustomerListdata.firstName }}</td>
                            <td> {{ CustomerListdata.lastName }}</td>
                            <td>{{ CustomerListdata.email }}</td>
                            <td>{{ CustomerListdata.globalCodeName }}</td>
                            <td>{{ CustomerListdata.phone }}</td>
                    
                            <td>{{ CustomerListdata.city }}</td>
                            
  
                            <td style="min-width: 125px;text-align:center;">
                              <button class="btn bg-gradient-info btn-sm mb-2 mb-md-0"
                                (click)="openAddModel(ModelAddUser,CustomerListdata.id)" title="Edit"><i
                                  class="fa-solid fa-pen-to-square"></i></button>
                            
                                  &nbsp;
                              <button class="btn bg-gradient-danger btn-sm mb-2 mb-md-0" title="Delete"
                                (click)="onDelete(CustomerListdata.id);">
                                <i class="fa fa-trash"></i></button>
  
                           
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <br>
  
                      <div class="row justify-content-between align-items-center">
                        <div class="col-12 col-sm-auto">
                          <ngb-pagination [collectionSize]="CustomerList.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                            [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">
                          <div class="form-group mb-0 mr-1">
                            <label style="padding:2px;"> Items per Page:</label>
                            <select (change)="handlePageSizeChange($event)">
                              <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <a #ConfirmBox style="display: none;" [routerLink]="" 
                  (click)="openModal(template)"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
                    ngbPopover="Are you sure you want to delete?"></i></a>
                <ng-template #template>
                  <div class="modal-body text-center">
                    <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                    <p>Are you sure you want to delete?</p>
                    <p></p>
                    <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                    <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                  </div>
                </ng-template>

                  <ng-template #ModelAddUser let-modal>
                    <div class="modal-header" style="background-color: black;">
                      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">{{userpopuplabel}}</h5>
                      <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                        aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div class="modal-body">
  
                      <section class="generate-report">
                        <div class="row">
                            <div class="form-group col-md-4 col-lg-4">
                                <label for="">First Name</label>
                                <input type="text" maxlength="20" formControlName="firstname" [(ngModel)]="firstname" class="form-control"
                                  placeholder="First Name" (keypress)="ValidTextBox($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                                <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                  <div *ngIf="f.firstname.errors.required">First Name is required</div>
                                </div>
                              </div>
                              
                              <div class="form-group col-md-4 col-lg-4">
                                <label for="">Middle Name</label>
                                <input type="text"
                                maxlength="20" formControlName="middlename" [(ngModel)]="middlename" class="form-control"
                                  placeholder="Middle Name" (keypress)="ValidTextBox($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.middlename.errors }" />
                                <div *ngIf="submitted && f.middlename.errors" class="invalid-feedback">
                                  <div *ngIf="f.middlename.errors.required">Middle Name is required</div>
                                </div>
                              </div>
  
                              <div class="form-group col-md-4 col-lg-4">
                                <label for="">Last Name</label>
                                <input type="text"
                                maxlength="20" formControlName="lastname" [(ngModel)]="lastname" class="form-control"
                                  placeholder="last Name" (keypress)="ValidTextBox($event)"
                                  [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
                                <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
                                  <div *ngIf="f.lastname.errors.required">Last Name is required</div>
                                </div>
                              </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="form-group col-md-12 col-lg-12">
                            <label for="">Address</label>
                            <textarea type="text"
                            maxlength="20" formControlName="address" [(ngModel)]="address" class="form-control"
                              placeholder="" (keypress)="ValidTextBox($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.address.errors }" ></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                              <div *ngIf="f.address.errors.required">Address is required</div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="form-group col-md-4 col-lg-4">
                            <label for="">City</label>
                            <input type="text" formControlName="city" [(ngModel)]="city" class="form-control"
                              placeholder="City" (keypress)="ValidTextBox($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                              <div *ngIf="f.city.errors.required">City is required</div>
                            </div>
                          </div>
                          <div class="form-group col-md-4 col-lg-4">
                            <label for="">Region</label>
                            <select class="form-control" name="region" formControlName="region" [(ngModel)]="region"
                            [ngClass]="{ 'is-invalid': submitted && f.region.errors }"
                              >
                              <option value="0" selected>Select Region </option>
                              <option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
                                {{grouplistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.region.errors" class="invalid-feedback">
                              <div *ngIf="f.region.errors.required">Region Name is required</div>
                            </div>
                          </div>
                          <div class="form-group col-md-4 col-lg-4">
                            <label for="">Country</label>
                            <input type="text" formControlName="country" [(ngModel)]="country" class="form-control"
                              placeholder="country" (keypress)="ValidTextBox($event)"
                              [ngClass]="{ 'is-invalid': submitted && f.country.errors }" />
                            <div *ngIf="submitted && f.country.errors" class="invalid-feedback">
                              <div *ngIf="f.country.errors.required">Country is required</div>
                            </div>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                            <div class="form-group col-md-4 col-lg-4">
                                <label for="">Postal Code</label>
                                <input type="text" formControlName="postalcode" [(ngModel)]="postalcode" class="form-control"
                                  placeholder="Postal code" (keypress)="ValidTextBox($event)" maxlength="6"
                                  [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" />
                                  <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                    <div *ngIf="f.postalcode.errors.required">Postal Code is required</div>
                                    <div *ngIf="f.postalcode.errors.minlength || f.postalcode.errors.maxlength">
                                      Postal Code must be 6 digits
                                    </div>
                                </div>
                              </div>
                                <div class="form-group col-md-4 col-lg-4">
                                    <label for="">Phone Number</label>
                                    <input type="text" formControlName="phonenumber" [preValue]="AddCustomerForm.value.phonenumber"
                                        [phoneControl]="AddCustomerForm.controls['phonenumber']" phoneMask [(ngModel)]="phonenumber" maxlength="14"
                                        class="form-control" placeholder="Phone Number" (keypress)="keyPressCheck($event)"
                                        [ngClass]="{ 'errmsg': phoneNumberControl.dirty && phoneNumberControl.invalid, 'validmsg': phoneNumberControl.dirty && phoneNumberControl.valid }" />
                                    <div *ngIf="phoneNumberControl.invalid && (phoneNumberControl.dirty || submitted)" class="error-message"
                                        style="color: red;">
                                        <div *ngIf="phoneNumberControl.errors.required">
                                            Phone Number is required
                                        </div>
                                        <div *ngIf="phoneNumberControl.errors.pattern">
                                            Invalid phone number format. Please use the format (XXX) XXX-XXXX.
                                        </div>
                                    </div>
                                
                                </div>
                              <div class="form-group col-md-4 col-lg-4">
                                <label for="">Email</label>
                                <input type="text" formControlName="email" lowercase [(ngModel)]="email"
                                class="form-control email"   placeholder="Email"
                                 [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
                                    <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                </div>
                              </div>
                        </div>
                       
                        <hr />
                        <div class="row">
                            <div class="form-group col-md-4 col-lg-4">
                                <label for="">DL Number</label>
                                <input type="text" formControlName="dlnumber" [(ngModel)]="dlnumber" class="form-control" placeholder="DL Number"
                                    (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.dlnumber.errors }" />
                                <div *ngIf="submitted && f.dlnumber.errors" class="invalid-feedback">
                                    <div *ngIf="f.dlnumber.errors.required">DL Number is required</div>
                                </div>
                            </div>
                            <div class="form-group col-md-4 col-lg-4">
                                <label for="">Other ID Number</label>
                                <input type="text" formControlName="otherid" [(ngModel)]="otherid" class="form-control" placeholder="other id"
                                    (keypress)="ValidTextBox($event)" />
                        
                            </div>
                            <div class="form-group col-md-4 col-lg-4">
                                <label for="">GST Number</label>
                                <input type="text" formControlName="gst" [(ngModel)]="gst" class="form-control" placeholder="GST No"
                                    (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.gst.errors }" />
                                <div *ngIf="submitted && f.gst.errors" class="invalid-feedback">
                                    <div *ngIf="f.gst.errors.required">GST is required</div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row">
                              <div class="form-group col-md-6 col-lg-6">
                                <label for="">Insurance</label>
                                <input type="text" formControlName="insurance" [(ngModel)]="insurance" class="form-control" placeholder="Insurance NO"
                                    (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.insurance.errors }" />
                                <div *ngIf="submitted && f.insurance.errors" class="invalid-feedback">
                                    <div *ngIf="f.insurance.errors.required">Insurance is required</div>
                                </div>
                              </div>
                        </div>
                      </section>
                    </div>
                    <div class="modal-footer">
                      <div class="row justify-content-between align-items-center w-100">
                        <div class="col-auto"></div>
                        <div class="col-auto">
                          <button type="submit"  *ngIf="hideshowbutton==false"  class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                          </button>

                          <button type="submit"   *ngIf="hideshowbutton==true" class="btn btn-primary btn-sm text-white rounded-pill" (click)="UpdateCustomer();">
                            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
                          </button>
      
                          <button class="btn btn-danger btn-sm text-white ml-2 rounded-pill" (click)="CloseAddmodel()">
                            <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-footer id="secondfooter"></app-footer> -->

    </form>
  </section>