<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="clientDbForm">
        <div class="container-fluid">
            <div class="page-header">
                <div class="row w-100 justify-content-between align-items-center">
                    <div class="col-auto">
                        <h3>Offer Dashboard</h3>
                    </div>
                    <div class="col-auto">
                        <div class="row justify-content-between align-items-center">
                            <label class="col-form-label mb-0 col-auto px-sm-0">Dealers</label>
                            <div class="col-md-auto">
                                <select style="max-width:280px;min-width:280px;" class="form-control" name="region"
                                    formControlName="DealerNameFilter" (change)="Getdetails($event)"
                                    [(ngModel)]="DealerNameFilter">
                                    <option value="0" selected>Select Dealer Name </option>
                                    <option *ngFor="let DealerListdata of DealerList" [value]="DealerListdata.id">
                                        {{DealerListdata.dealerName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- for single row four colums -->
            <div class="row">
                <div class="col-lg-12">
                    <div class="card-stats mb-2 mb-xl-0 card card-raised">
                        <div class="card-header p-2 bg-info text-white d-flex align-items-center justify-content-between"
                            data-toggle="collapse" href="#MyDashboard" role="button" aria-expanded="false"
                            aria-controls="MyDashboard">
                            <h5 class="text-uppercase text-white mb-0 card-title d-block">My Dashboard</h5>
                            <i class="fas fa-chevron-down"></i>
                        </div>
                        <div class="collapes show" id="MyDashboard">
                            <div class="card-body p-2">
                                <div id="clientdashboardboxs" class="row mb-3">
                                    <div class="col-lg-6 col-xl-6">
                                        <div class="card-stats mb-2 mb-xl-0 card card card-raised">
                                            <div class="card-header bg-info text-white">
                                                <h5 class="text-uppercase mb-0 card-title d-block">My Offers</h5>
                                            </div>
                                            <div class="" id="TotalInvestment">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col">
                                                            <span class="h5 font-weight-bold mb-0">{{TotalOfferCount |
                                                                number}} {{o1Heading}}</span>
                                                            <p class="mb-0 text-sm"><span class="text-pink mr-2"
                                                                    style="color:#FC766A">
                                                                    <i class="fa fa-arrow-up"></i> {{o1Value1 | number}}
                                                                    {{o1Heading1}}</span> <br>
                                                                <span class="text-nowrap text-lightblue"
                                                                    style="color:#783937">{{o1Value2 | number}}
                                                                    {{o1Heading2}}</span>
                                                            </p>
                                                        </div>
                                                        <div class="col-auto col-md-6">
                                                            <div id="inventorycanvas">
                                                                <canvas baseChart [data]="pieChartDataInvest"
                                                                    [labels]="pieChartLabelsInvest"
                                                                    [colors]="colorsInvest"
                                                                    [chartType]="pieChartTypeInvest"
                                                                    [options]="pieChartOptionsInvest"
                                                                    [plugins]="pieChartPlugins"
                                                                    [legend]="pieChartLegendInvest">
                                                                </canvas>
                                                            </div>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-xl-6">
                                        <div class="card-stats mb-2 mb-xl-0 card card card-raised">
                                            <div class="card-header bg-info text-white">
                                                <h5 class="text-uppercase mb-0 card-title d-block">Offers To Me</h5>
                                            </div>
                                            <div class="" id="TotalInventory">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-auto col-md-6">
                                                            <span class="h5 font-weight-bold mb-0">{{o2 | number }}
                                                                {{o2Heading}}</span>
                                                            <p class="mb-0 text-sm"><span class="text-pink mr-2"
                                                                    style="color: #00539C;">
                                                                    <i class="fas fa-arrow-up"></i>{{o2Value1 | number}}
                                                                    {{o2Heading1}}</span>
                                                                <span class="text-nowrap text-lightblue"
                                                                    style="color: #EEA47F;">{{o2Value2 | number}}
                                                                    {{o2Heading2}}</span>
                                                            </p>
                                                        </div>

                                                        <div class="col-auto col-md-6">
                                                            <div id="inventorycanvas">
                                                                <canvas baseChart [data]="pieChartData"
                                                                    [labels]="pieChartLabels" [colors]="colorsInventory"
                                                                    [chartType]="pieChartType"
                                                                    [options]="pieChartOptions"
                                                                    [plugins]="pieChartPlugins"
                                                                    [legend]="pieChartLegend">
                                                                </canvas>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="card card-raised h-100">
                        <!-- <div class="card-header card-raised bg-info text-white">
                        <h5 class="card-title text-white text-uppercase mb-0">Details</h5>
                    </div> -->
                        <div id="adminsetting" class="card-body p-0">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">



                                        <li class="nav-item">
                                            <a class="nav-link active" id="Group-tab" data-toggle="tab" href="#Group"
                                                role="tab" aria-controls="Group" aria-selected="true"><b>My
                                                    Offers</b></a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" id="Dealer-tab" data-toggle="tab" href="#Dealer"
                                                role="tab" aria-controls="Dealer" aria-selected="false"><b>Offer To Me
                                                </b></a>
                                        </li>




                                    </ul>
                                    <div class="tab-content" id="myTabContent">
                                        <div class="tab-pane show active" id="Group" role="tabpanel"
                                            aria-labelledby="Group-tab">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12 mt-3 groupTab">
                                                        <div class="col-md-12">
                                                            <div class="card card-raised h-100">
                                                                <div class="card-header card-raised bg-info text-white">
                                                                    <div
                                                                        class="row justify-content-between align-items-center">
                                                                        <div class="col-sm-6">
                                                                            <h5
                                                                                class="card-title text-white text-uppercase mb-0">
                                                                                My Offers
                                                                            </h5>

                                                                        </div>
                                                                        <div
                                                                            class="col-sm-6 text-sm-right mt-sm-0 mt-1">

                                                                            <h6 class="mb-0 d-inline-block ml-3">
                                                                                <b>Total
                                                                                    Records :</b> {{TotalRecord}}</h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div id="inventory" class="card-body p-4">
                                                                    <div class="table-responsive">
                                                                        <table id="usertable"
                                                                            class="table table-bordered"
                                                                            style="width:100%">
                                                                            <thead>
                                                                                <tr>
                                                                                    <!-- <th>Offer</th> -->
                                                                                    <th>Action</th>
                                                                                    <th> Offer DateTime <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('isRead')">IsRead
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('hasResponded')">
                                                                                        HasResponded
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('vin')">VIN <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('carYr')">CarYr <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('make')">Make <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="min-width:85px;" (click)="sort('model')">Model<i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('listedPrice')">
                                                                                        Price
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('offerAmount')">
                                                                                        OfferAmount
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('offerComments')">
                                                                                        OfferComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('readDateTime')">
                                                                                        ReadDateTime
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('respondedDateTime')">
                                                                                        RespondedDateTime
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('respondedComments')">
                                                                                        RespondedComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('counterOfffer')">
                                                                                        CounterOfffer
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('counterComments')">
                                                                                        CounterComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('stockNumber')">
                                                                                        StockNumber
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('mileage')">
                                                                                        Mileage
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('trim')">Trim
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize;let i = index">

                                                                                    <!-- <td>
                                                                                        <button class="btn btn-green" style="width:24px;font-size:8px;"
                                                                                        title="Offer"
                                                                                        (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'myoffer')">
                                                                                          <i class="fas fa-dollar-sign"></i>
                                                                                       </button>
                                                                                    </td> -->


                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==0">
                                                                                        <a class="communication"  title="New"

                                                                                        (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'myoffer')">
                                                                                            <img src="../../assets/images/communicationc2.png" alt="communication icon">
                                                                                        </a>

                                                                                    </td>

                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==10">

                                                                                        <a class="communication" title="Send Response"   (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'myoffer')">
                                                                                            <img src="../../assets/images/communicationc2.png" alt="communication icon">
                                                                                        </a>
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==1">

                                                                                        Green

                                                                                        <a class="communication" title="Response"   (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'myoffer')">
                                                                                            <img src="../../assets/images/communicationc1.png" alt="communication icon">
                                                                                        </a>
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==1">

                                                                                        <a id="eyeblue" class="communication" title="Viewed">
                                                                                            <!-- <img src="../../assets/images/communicationc1.png" alt="communication icon"> -->
                                                                                            <i class="fas fa-eye"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        dealerVehicleslistdata.offerDateTime
                                                                                        | date }} </td>

                                                                                    <td *ngIf="dealerVehicleslistdata.isRead===0">
                                                                                       No
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.isRead===1">
                                                                                        Yes
                                                                                     </td>


                                                                                     <td *ngIf="dealerVehicleslistdata.hasResponded===0">
                                                                                        No
                                                                                     </td>
                                                                                     <td *ngIf="dealerVehicleslistdata.hasResponded===1">
                                                                                         Yes
                                                                                      </td>




                                                                                    <td>{{ dealerVehicleslistdata.vin }}
                                                                                    </td>

                                                                                    <td> {{ dealerVehicleslistdata.carYr
                                                                                        }}
                                                                                    </td>


                                                                                    <td> {{ dealerVehicleslistdata.make
                                                                                        }}
                                                                                    </td>
                                                                                    <td> {{ dealerVehicleslistdata.model
                                                                                        }}
                                                                                    </td>


                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.listedPrice|
                                                                                        currency }}</td>
                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.offerAmount
                                                                                        | currency }}</td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.offerComments
                                                                                        }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.readDateTime
                                                                                        | date }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.respondedDateTime
                                                                                        | date }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.respondedComments
                                                                                        }}
                                                                                    </td>
                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.counterOfffer
                                                                                        | currency }}
                                                                                    </td>

                                                                                    <td > {{
                                                                                        dealerVehicleslistdata.counterComments
                                                                                        }}
                                                                                    </td>
                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.stockNumber
                                                                                        }}</td>
                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.mileage
                                                                                        }}</td>

                                                                                    <td >
                                                                                        {{
                                                                                        dealerVehicleslistdata.trim }}
                                                                                    </td>
                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <br>


                                                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                                                                            size="small" color="#fff"
                                                                            type="square-jelly-box"
                                                                            [fullScreen]="false">
                                                                            <p style="color: white"> Loading... </p>
                                                                        </ngx-spinner>
                                                                        <div class="col-12">
                                                                            <div
                                                                                class="row justify-content-between align-items-center mx-0">
                                                                                <div class="col-auto px-0">
                                                                                    <ngb-pagination
                                                                                        [collectionSize]="dealerVehicleslist.length"
                                                                                        [(page)]="page" [maxSize]="5"
                                                                                        [rotate]="true"
                                                                                        [ellipses]="false"
                                                                                        [boundaryLinks]="true"></ngb-pagination>
                                                                                </div>
                                                                                <div class="form-group mb-0">
                                                                                    <label style="padding:2px;"> Items
                                                                                        per
                                                                                        Page:</label>
                                                                                    <select
                                                                                        (change)="handlePageSizeChange($event)">
                                                                                        <option
                                                                                            *ngFor="let size of pageSizes"
                                                                                            [ngValue]="size">
                                                                                            {{ size }}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="tab-pane fade" id="Dealer" role="tabpanel"
                                            aria-labelledby="Dealer-tab">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12 mt-3 groupTab">
                                                        <div class="col-md-12">
                                                            <div class="card card-raised h-100">
                                                                <div class="card-header card-raised bg-info text-white">
                                                                    <div
                                                                        class="row justify-content-between align-items-center">
                                                                        <div class="col-sm-6">
                                                                            <h5
                                                                                class="card-title text-white text-uppercase mb-0">
                                                                                Offers To Me
                                                                            </h5>

                                                                        </div>
                                                                        <div
                                                                            class="col-sm-6 text-sm-right mt-sm-0 mt-1">

                                                                            <h6 class="mb-0 d-inline-block ml-3">
                                                                                <b>Total
                                                                                    Records :</b> {{TotalRecordPicture}}
                                                                            </h6>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                                <div id="inventory" class="card-body p-4">
                                                                    <div class="table-responsive">
                                                                        <table id="usertable"
                                                                            class="table table-bordered"
                                                                            style="width:100%">
                                                                            <thead>
                                                                                <tr>
                                                                                    <!-- <th>Offer</th> -->
                                                                                    <th>Action</th>
                                                                                    <th> Offer DateTime <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('isRead')">IsRead
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('hasResponded')">
                                                                                        HasResponded
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('vin')">VIN <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('carYr')">CarYr <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th (click)="sort('make')">Make <i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="min-width:85px;" (click)="sort('model')">Model<i
                                                                                            class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('listedPrice')">
                                                                                        Price
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('offerAmount')">
                                                                                        OfferAmount
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('offerComments')">
                                                                                        OfferComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('readDateTime')">
                                                                                        ReadDateTime
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('respondedDateTime')">
                                                                                        RespondedDateTime
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('respondedComments')">
                                                                                        RespondedComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('counterOfffer')">
                                                                                        CounterOfffer
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('counterComments')">
                                                                                        CounterComments
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>

                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('stockNumber')">
                                                                                        StockNumber
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('mileage')">
                                                                                        Mileage
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>
                                                                                    <th style="text-align: right;"
                                                                                        (click)="sort('trim')">Trim
                                                                                        <i class="fa fa-fw fa-sort"></i>
                                                                                    </th>


                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr
                                                                                    *ngFor="let dealerVehicleslistdata of dealerPicturelist| filter:searchText | slice: (pagePicture-1) * pageSizePicture : (pagePicture-1) * pageSizePicture + pageSizePicture;let i = index">

                                                                                    <!-- <td>
                                                                                        <button class="btn btn-green" style="width:24px;font-size:8px;"
                                                                                        title="Offer"
                                                                                        (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'offerme')">
                                                                                          <i class="fas fa-dollar-sign"></i>
                                                                                       </button>
                                                                                    </td> -->


                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==0">
                                                                                        <a class="communication"  title="New"
                                                                                        (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'offerme')">
                                                                                            <img src="../../assets/images/communicationc1.png" alt="communication icon">
                                                                                        </a>

                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==10">

                                                                                        <a class="communication" title="Send Response"   (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'offerme')">
                                                                                            <img src="../../assets/images/communicationc2.png" alt="communication icon">
                                                                                        </a>
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==1">

                                                                                        Green

                                                                                        <a class="communication" title="Response"   (click)="openMakeOfferModelPopUp(ModelMakeOffer,dealerVehicleslistdata.vin,dealerVehicleslistdata.offer,dealerVehicleslistdata,'offerme')">
                                                                                            <img src="../../assets/images/communicationc1.png" alt="communication icon">
                                                                                        </a>
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.actionFlag==1">

                                                                                        <a id="eyeblue" class="communication" title="Viewed">
                                                                                            <!-- <img src="../../assets/images/communicationc1.png" alt="communication icon"> -->
                                                                                            <i class="fas fa-eye"></i>
                                                                                        </a>
                                                                                    </td>
                                                                                    <td>{{
                                                                                        dealerVehicleslistdata.offerDateTime
                                                                                        | date }} </td>

                                                                                    <td *ngIf="dealerVehicleslistdata.isRead===0">
                                                                                       No
                                                                                    </td>
                                                                                    <td *ngIf="dealerVehicleslistdata.isRead===1">
                                                                                        Yes
                                                                                     </td>


                                                                                     <td *ngIf="dealerVehicleslistdata.hasResponded===0">
                                                                                        No
                                                                                     </td>
                                                                                     <td *ngIf="dealerVehicleslistdata.hasResponded===1">
                                                                                         Yes
                                                                                      </td>




                                                                                    <td>{{ dealerVehicleslistdata.vin }}
                                                                                    </td>

                                                                                    <td> {{ dealerVehicleslistdata.carYr
                                                                                        }}
                                                                                    </td>


                                                                                    <td> {{ dealerVehicleslistdata.make
                                                                                        }}
                                                                                    </td>
                                                                                    <td> {{ dealerVehicleslistdata.model
                                                                                        }}
                                                                                    </td>


                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.listedPrice|
                                                                                        currency }}</td>
                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.offerAmount
                                                                                        | currency }}</td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.offerComments
                                                                                        }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.readDateTime
                                                                                        | date }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.respondedDateTime
                                                                                        | date }}
                                                                                    </td>

                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.respondedComments
                                                                                        }}
                                                                                    </td>
                                                                                    <td style="text-align: right;"> {{
                                                                                        dealerVehicleslistdata.counterOfffer
                                                                                        | currency }}
                                                                                    </td>

                                                                                    <td > {{
                                                                                        dealerVehicleslistdata.counterComments
                                                                                        }}
                                                                                    </td>
                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.stockNumber
                                                                                        }}</td>
                                                                                    <td> {{
                                                                                        dealerVehicleslistdata.mileage
                                                                                        }}</td>

                                                                                    <td >
                                                                                        {{
                                                                                        dealerVehicleslistdata.trim }}
                                                                                    </td>


                                                                                </tr>

                                                                            </tbody>
                                                                        </table>
                                                                        <br>


                                                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)"
                                                                            size="small" color="#fff"
                                                                            type="square-jelly-box"
                                                                            [fullScreen]="false">
                                                                            <p style="color: white"> Loading... </p>
                                                                        </ngx-spinner>
                                                                        <div class="col-12">
                                                                            <div
                                                                                class="row justify-content-between align-items-center mx-0">
                                                                                <div class="col-auto px-0">
                                                                                    <ngb-pagination
                                                                                        [collectionSize]="dealerPicturelist.length"
                                                                                        [(page)]="pagePicture"
                                                                                        [maxSize]="5" [rotate]="true"
                                                                                        [ellipses]="false"
                                                                                        [boundaryLinks]="true"></ngb-pagination>
                                                                                </div>
                                                                                <div class="form-group mb-0">
                                                                                    <label style="padding:2px;"> Items
                                                                                        per
                                                                                        Page:</label>
                                                                                    <select
                                                                                        (change)="handlePageSizeChangePicture($event)">
                                                                                        <option
                                                                                            *ngFor="let size of pageSizesPicture"
                                                                                            [ngValue]="size">
                                                                                            {{ size }}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <ng-template #ResponseModelPopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Send Response
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col-12">
                    <div class="row">
                        <div class="form-group col-md-12">
                            <label class="label-form-col">Response Date</label>

                            <input formControlName="CurrentDate" readonly [(ngModel)]="CurrentDate" type="text"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="label-form-col">User Name</label>
                            <input type="text" formControlName="LoggedUserName" readonly [(ngModel)]="LoggedUserName"
                                class="form-control" value="">
                        </div>
                        <div class="form-group col-md-12">
                            <label class="label-form-col">Notes</label>
                            <textarea rows="3" formControlName="ResponseNotes" [(ngModel)]="ResponseNotes"
                                class="form-control"></textarea>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm text-white" (click)="SaveReponseDetail()"
                    title="Save">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                </button>

                <button class="btn btn-danger btn-sm text-white" title="Cancel" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>
    </form>


    <ng-template #ModelMakeOffer let-modal>
        <div class="modal-header" style="background-color: black;">
            <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Offer</h5>
            <button type="button" class="close" style="color: white;" (click)="modal.dismiss()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div id="makeofferblock" class="modal-body">
            <section class="generate-report">
                <div class="row">
                    <div id="statusIcon" data-toggle="tooltip" data-placement="top" data-trigger="hover" title="Offer Details">
                        <i class="fa fa-info-circle"></i>
                    </div>
                    <div class="col-md-12">
                        <h6>Offer Section</h6>
                    </div>

                    <div class="form-group mb-1 col-xl-4 col-lg-12">
                        <label> Amount:</label>
                        <input type="text" [readonly]="offerreadonly" [(ngModel)]="OfferAmountng"   class="form-control">

                    <div class="form-group mb-1 mt-1" *ngIf="offerreadonly">
                        <label> Date:</label>
                        <input type="text" [readonly]="offerreadonly"
                         [(ngModel)]="OfferDateBindng"   class="form-control">
                    </div>
                </div>

                    <div class="form-group mb-1 col-xl-8 col-lg-12">
                        <label>Comments:</label>
                        <textarea type="text" [readonly]="offerreadonly" [(ngModel)]="OfferCommentng"
                          class="form-control" rows="3"></textarea>
                    </div>

                </div>


                <div class="row" Id="RadiobtDiv">
                    <div class="col-md-12">
                        <h6>Decision Section</h6>
                    </div>


                    <div class="form-group mb-1 col-xl-12 col-lg-12 ">
                        <div class="row mx-2 mb-2 radioresponse">
                            <label class="mr-1" *ngFor="let radioListaccpetdata of DecisionitemsRadiobutton">
                                <input style="font-weight:normal;"
                                type="radio"
                                [(ngModel)]="selectedValueDecisionRadioBT"
                                [disabled]="radioListaccpetdata.disable"
                                [value]="radioListaccpetdata.value"
                                (click)="SelectionRadioFunction(radioListaccpetdata.groupName)"
                                 > <b>{{radioListaccpetdata.groupName}}</b>
                            </label>

                        </div>
                    </div>



                </div>
                <hr/>
                <div>


                <div class="row" id="AcceptSectiondiv" *ngIf="SelectionRadiobt == 999">
                    <!-- <div class="col-md-12">
                        <h6>Accept Section</h6>
                    </div> -->
                    <div class="form-group mb-1 col-xl-4 col-lg-12 ">
                        <div class="row mx-2 mb-2 radioresponse">
                            <label class="mr-1" *ngFor="let radiodata of radioAcceptCheckItems">
                                <input style="font-weight:normal;" type="radio" class="radiopoint" name="optionsAccpetaccept"
                                 (click)="SelectedAccepteddecline(radiodata)"
                                    [checked]="radiodata ===Isaccepted_Checked"> <b>{{radiodata}}</b>
                            </label>
                        </div>
                        <div class="row mx-0" *ngIf="Isaccept!=1">
                            <div class="form-group mb-1 col">
                                <label>Reason:</label>


                                <select  class="form-control" name="RejectReason"
                                 [(ngModel)]="RejectReason"
                                    >
                                    <option value="0" selected>Select Reason</option>
                                    <option *ngFor="let ReasonListdata of ReasonList"
                                    [value]="ReasonListdata.globalCodeID">
                                        {{ReasonListdata.globalCodeName}}</option>
                                </select>

                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-1 col-xl-8 col-lg-12">
                        <label>Comments:</label>
                        <textarea class="form-control" rows="2"

                            [(ngModel)]="AccpetComment" ></textarea>
                    </div>
                </div>

                <div class="row" id="CounterSectiondiv" *ngIf="SelectionRadiobt == 100">
                    <div class="col-md-12">
                        <h6>Counter Section</h6>
                    </div>
                    <div class="form-group mb-1 col-xl-4 col-lg-12">
                        <label> Amount:</label>
                        <input type="text"

                            [(ngModel)]="COunterOffer" class="form-control">

                    <div class="form-group mb-1 mt-1" *ngIf="IsCOunterDateVisible">
                        <label> Date:</label>
                        <input type="text"

                            [(ngModel)]="COunterDate" [ngModelOptions]="{ standalone: true }" [readonly]="true" class="form-control">
                    </div>
                </div>


                    <div class="form-group mb-1 col-xl-8 col-lg-12">
                        <label>Comments:</label>
                        <textarea class="form-control"

                            [(ngModel)]="COunterComment" rows="2"></textarea>
                    </div>
                </div>

                <div class="row" id="ResponseSectiondiv" *ngIf="SelectionRadiobt == 111">
                    <div class="col-md-12">
                        <h6>Response Section</h6>
                    </div>

                    <div class="form-group mb-1 col-xl-12 col-lg-12">
                        <label>Comments:</label>
                        <textarea class="form-control"

                            [(ngModel)]="ResponseComment" rows="3" cols="50"></textarea>
                    </div>
                </div>
            </div>
            </section>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-md btn-success"
            (click)="PostOfferRequestRequest()">Save</button>
            <button type="button" class="btn btn-md btn-danger ml-2"
            (click)="modal.dismiss()">Cancel</button>
        </div>
    </ng-template>



</section>