import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { DashboardService } from '@app/_services/dashboard.service';
import { NotifierService } from 'angular-notifier';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Tracking } from '@app/_models/Tracking';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet, Color } from 'ng2-charts';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-report-details',
  templateUrl: './report-details.component.html',
  styleUrls: ['./report-details.component.less']
})
export class ReportDetailsComponent implements OnInit {
  TrackingListForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private globalcodeService: GlobalcodeService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private router: Router, private dashboardService: DashboardService) { this.notifier = notifier; }

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];

  lenderpieChartOptions: ChartOptions;
  lenderpieChartLabels: Label[];
  lenderpieChartData: SingleDataSet;
  lenderpieChartType: ChartType;
  lenderpieChartLegend: boolean;
  lenderpieChartPlugins = [];

  key = 'dealerName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  isDesc: boolean = false;
  column: string = 'dealerName';
  tracking: Tracking[] = [];
  TotalRecord: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  pageAithrCountUnit = 1;
  pageAithrCountUnitSize = 10;
  submitted = false;
  isValid: boolean = true;
  opcode: any;
  delaerNameValue:any;
  DealerIdValue: any;
  modalRef: BsModalRef;
  TrackfromNg: any;
  TrackToNg: any;
  Opcode: any;
  VinValue: any;
  VinValueModelPopup: any;
  StockNumberValue: any;
  CarYrValue: any;
  MileageValue: any;
  MakeValue: any;
  ModelValue: any;
  Trimvalue: any;
  BodyValue: any;
  ColourValue: any;
  VehicleInfoValue: any;
  ListedPriceValue: any;
  DealerCostValue: any;
  ConditionValue: any;
  CBBValAValue: any;
  CBBValCValue: any;
  CBBValRvalue: any;
  CBBValXValue: any;
  DateReceived: any;
  GroupName: any;
  DealerName: any;

  DealerEmail: any;
  DealerPhone: any;

  Transmission: any;

  showAithrMarketColummn: any;
  DateCheckValue: any;
  buildCarShow: any;

  sort(property) {
   
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.tracking.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  DealerIdvalueDropDown:any;
  ColorTheme: any;
  actvitylistNameValue: any;
  userlistNameValue: any;
  userlistName = [];

  dealerlistName = [];
  reportDataMatrixAllList = [];
  reportAithrDataMatrixAllList = [];
  pageUnit = 1;
  pageUnitSize = 10;
  searchUnitText;
  searchAithrUnitText;
  showUnitDetail: any;
  UnitAithrtotalCount: any;
  showAithrMarketShow: any;
  UnittotalCount: any;
  actvitylistName = [];
  findCarCount: any;
  BuildCarVisitsCount: any;
  ButtonclicksCount: any;
  VisitCount: any;

  colorsSearch: Color[] = [
    {
      backgroundColor: [
        '#8A307F',
        '#79A7D3',
        'blue',
        '#FF8C00'
      ]
    }
  ];

  HideDetails() {
    //this.showUnitDetail = "false";
    this.showAithrMarketShow = "false";
  }
  ngOnInit(): void {
    this.showAithrMarketColummn = "false";
    this.Opcode = "1";
    this.showUnitDetail = "true";
    this.showAithrMarketShow = "false";
    this.buildCarShow = "false";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalueDropDown = localStorage.getItem('DealerId');
   // this.userlistNameValue = localStorage.getItem('userid');
   this.userlistNameValue = "0";
    this.findCarCount = "0";
    this.BuildCarVisitsCount = "0";
    this.ButtonclicksCount = "0";
    this.VisitCount = "0";
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.DateCheckValue = "1";
    this.setDefaultDateValues();
    if (this.rolevalue == "SuperAdmin") {
      this.opcode = "1";
      this.GetTrackingList();
    } else {
      this.opcode = "2";
      this.GetTrackingList();
    }

    this.GetFilterValueList();
   // this.GetUserFilterList();
    this.GetDealersFilterList();
    this.GetUserFilterList();
    this.GetReportmatrixlist();
    this.GetFindCountAllUnitDetails("2");
  }

  sortUnit(property) {
    
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.reportDataMatrixAllList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }

  GetFindCountAllUnitDetails(Opcode: any) {
    
    this.Opcode = "2";
    this.GetReportmatrixlistdata();
  }

  GetBuildCountAllUnitDetails(Opcode: any) {
    this.Opcode = "3";
    this.buildCarShow = "true";
    this.GetReportmatrixlistdata();
  }

  GetButtonClickCountAllUnitDetails(Opcode: any) {
    this.Opcode = "4";
    this.showAithrMarketColummn = "false";
    this.GetAithrMarketPlaceCountdata();
  }

  GetVisitCountClickCountAllUnitDetails(Opcode: any) {
    this.Opcode = "5";
    this.showAithrMarketColummn = "true";
    this.GetAithrMarketPlaceCountdata();
  }

  GetDetailByVINdata() {
   
    var modelGroup = {
      "opcode": this.Opcode,
      "VIN": this.VinValue,
    }

    this.globalcodeService.getAllReportChartListData(modelGroup)
      .subscribe(
        (data: any) => {
         
          if (data.length > 0) {
            this.showUnitDetail = "true";
            this.showAithrMarketShow = "false";
            this.reportDataMatrixAllList = data;
            this.UnittotalCount = data.length;
          } else {
            this.showUnitDetail = "false";
          }

        },
        error => {
        });
  }

  GetAithrMarketPlaceCountdata() {
  
    var modelGroup = {
      "opcode": this.Opcode,
      "FromDate": this.TrackToNg,
      "TillDate": this.TrackfromNg,
      "UserID": this.userlistNameValue,
      "DealerId":this.DealerIdvalueDropDown
    }

    this.globalcodeService.getAllReportChartListData(modelGroup)
      .subscribe(
        (data: any) => {
         
          if (data.length > 0) {
            this.showUnitDetail = "false";
            this.showAithrMarketShow = "true";
            this.reportAithrDataMatrixAllList = data;
            this.UnitAithrtotalCount = data.length;
          } else {
            this.showUnitDetail = "false";
            this.reportAithrDataMatrixAllList=[];
            this.UnitAithrtotalCount = "0";
          }

        },
        error => {
        });
  }

  GetReportmatrixlistdata() {
    debugger;
    var modelGroup = {
      "opcode": this.Opcode,
      "FromDate": this.TrackToNg,
      "TillDate": this.TrackfromNg,
      "UserID": this.userlistNameValue,
      "DealerId":this.DealerIdvalueDropDown
    }

    this.globalcodeService.getAllReportChartListData(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          if (data.length > 0) {
            this.showUnitDetail = "true";
            this.showAithrMarketShow = "false";
            this.reportDataMatrixAllList = data;
            this.UnittotalCount = data.length;
          } else {
           // this.showUnitDetail = "false";
           this.reportDataMatrixAllList = [];
           this.UnittotalCount = "0";
          }

        },
        error => {
        });
  }

  GetReportmatrixlist() {
   
    var modelGroup = {
      "opcode": "1",
      "FromDate": this.TrackToNg,
      "TillDate": this.TrackfromNg,
      "UserID": this.userlistNameValue,
      "DealerId":this.DealerIdvalueDropDown
    }

    this.globalcodeService.getmatrixreportDetailChart(modelGroup)
      .subscribe(
        (data: any) => {
          
          var chartlabel = [];
          var chartdata = [];

          // for bind only conunt
          this.findCarCount = data.totalactivepieChartData[0].data;
          this.BuildCarVisitsCount = data.totalactivepieChartData[1].data;
          this.ButtonclicksCount = data.totalactivepieChartData[2].data;
          this.VisitCount = data.totalactivepieChartData[3].data;
          var lenderpieChart_label = [];
          var lenderpieChart_data = [];

          data.totalactivepieChartLabels.forEach(function (value) {
            chartlabel.push(value.label)
          });


          data.totalactivepieChartData.forEach(function (value) {
            chartdata.push(Number(value.data))
          });
          this.pieChartOptions = this.createOptions();
          this.pieChartLabels = chartlabel;
          this.pieChartData = chartdata;
          this.pieChartType = 'pie';
          this.pieChartLegend = true;
          this.lenderpieChartOptions = this.createOptions();
          this.lenderpieChartLabels = lenderpieChart_label;
          this.lenderpieChartData = lenderpieChart_data;
          this.lenderpieChartType = 'pie';
          this.lenderpieChartLegend = true;

        },
        error => {
        });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  setDefaultDateValues() {
    const currentDate = new Date();

    const currentFromDate = new Date();
    this.TrackToNg = currentFromDate;
    this.TrackToNg.setDate(this.TrackToNg.getDate() - 6);
    this.TrackToNg = this.datePipe.transform(this.TrackToNg, 'yyyy-MM-dd');
    this.TrackfromNg = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.tracking);
  }

  printTable() {
    var prepare = [];
    this.tracking.forEach(e => {
      var tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>User Name</th><th>ActivityDatetime</th><th>BrowserInfo</th><th>ipAddress</th><th>activityLink</th><th>comments</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdf() {
    var prepare = [];
    this.tracking.forEach(e => {
      var tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['User Name', 'ActivityDatetime', 'BrowserInfo', 'ipAddress', 'activityLink', 'comments']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('tracking' + '.pdf');
  }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  exportexcel() {
    const prepare = [];
    this.tracking.forEach(e => {
      const tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['User Name', 'ActivityDatetime', 'BrowserInfo', 'ipAddress', 'activityLink', 'comments'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Listoflenders');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'Listoflenders.xlsx');
  }
  Reset() {
    // this.TrackToNg=null;
    // this.TrackfromNg=null;
    this.ngOnInit();
  }

  declineVinDetails(): void {
    this.confirmModalService.hide(1);
  }
  GetDetailByVIN(vin: any, template: TemplateRef<any>) {
    
    this.VinValue = vin;
    this.GetDetailByVINList();
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  GetDetailByVINList() {
    
    var modelGroup = {
      "opcode": this.Opcode,
      "VIN": this.VinValue,
    }

    this.globalcodeService.getdetailbyVIN(modelGroup)
      .subscribe(
        (data: any) => {
          
          this.VinValueModelPopup = data[0].vin;


          this.StockNumberValue = data[0].stockNumber;
          this.CarYrValue = data[0].carYr;
          this.MileageValue = data[0].mileage;
          this.MakeValue = data[0].make;
          this.ModelValue = data[0].model;
          this.Trimvalue = data[0].trim;
          this.BodyValue = data[0].body;
          this.ColourValue = data[0].colour;
          this.VehicleInfoValue = data[0].vin;
          this.ListedPriceValue = data[0].listedPrice;
          this.DealerCostValue = data[0].dealerCost;

          this.GroupName = data[0].groupName;
          this.DealerName = data[0].dealerName;
          this.Transmission = data[0].transmission;
          this.DealerEmail = data[0].dealerEmail;
          this.DealerPhone = data[0].dealerPhone;

        },
        error => {
        });
  }

  SearchActivity() {
    this.opcode = "1";
    this.GetReportmatrixlist();
    this.GetFindCountAllUnitDetails("2");
    this.HideDetails();
  }

  GetTrackingList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerId": this.DealerIdvalue,
      "TrackTo": this.TrackToNg,
      "TrackFrom": this.TrackfromNg,
      "DateCheck": this.DateCheckValue,
      "UserId": this.userlistNameValue,
      "ActvityValue": this.actvitylistNameValue
    }
    this.dashboardService.getTrackingList(modelGroup)
      .subscribe(
        (data: any) => {

          this.tracking = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  // function for getting Filters values

  getUserbyDealers(value:any)
  {
    
    this.DealerIdvalueDropDown=value;
    this.GetUserFilterList();
    this.userlistNameValue="0";
    this.GetReportmatrixlist();
    this.GetFindCountAllUnitDetails("2");

  }
  GetDealersFilterList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode    
    }
    this.dashboardService.getDealersFilterList(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerlistName = data;

        },
        error => {
        });
  }

  GetUserFilterList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerId": this.DealerIdvalueDropDown
    }
    this.dashboardService.getUserFilterList(modelGroup)
      .subscribe(
        (data: any) => {

          this.userlistName = data;

        },
        error => {
        });
  }

  GetFilterValueList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerId": this.DealerIdvalue
    }
    this.dashboardService.getFilterValueList(modelGroup)
      .subscribe(
        (data: any) => {

          this.actvitylistName = data;

        },
        error => {
        });
  }


}
