import { Component, OnInit } from '@angular/core';
import { DashboardService } from '../_services/dashboard.service';
import { Account } from '@app/_models';
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-aithrhub-dashboard',
  templateUrl: './aithrhub-dashboard.component.html',
  styleUrls: ['./aithrhub-dashboard.component.less']
})
export class AithrhubDashboardComponent implements OnInit {
  Statisticslist = [];
  UnitDetails=[];
  UnittotalCount:any;
  StatisticsRegionWiselist = [];
  StatisticsDealerWiselist= [];
  TitleValue:any;
  ListofPendingUnitsData= 'VEHICLESSYNCHEDListRegionWise.xlsx';
  // variables for Counts
  dateSynchedReceived: any;
  totalCount: any;
  totalSynchedCount: any;
  totalNotSynchedCount: any;

  key = 'userName'; // set default
  reverse = false;
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  rolevalue:any;
  dealerNamevalue:any;
  GroupNamevalue:any;
  DealerIdvalue:any;
  pageDealer = 1;
  pageDealerSize = 5;

  pageRegion = 1;
  pageRegionSize = 5;


  pageUnit = 1;
  pageUnitSize = 10;

  private notifier: NotifierService;
  loading = false;
  accounts: Account[] = [];
  constructor(
    private dashboardService: DashboardService,
    private accountService:AccountService,
    notifier: NotifierService
    ) {
      this.accountService.SaveActivityLog("");
    }
  sort(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsRegionWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  sortUnit(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.UnitDetails.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sortdealer(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsDealerWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  searchTextRegion;

  searchUnitText;
  ColorTheme:any;
  showUnitDetail:any;
  ValueDealerName:any;
  opcodeValue:any;
  ngOnInit(): void {
    this.showUnitDetail="false";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    if (this.rolevalue == "SuperAdmin") {
      this.GetAithrHubDashboardStatisticsDetails();
      this.GetAithrHubDashboardStatisticsRegionDetails();
      this.GetAithrHubDashboardStatisticsDealerDetails();

    } else {


      this.GetAithrHubDashboardStatisticsDetailsByDealerName();
      this.GetAithrHubDashboardStatisticsRegionDetailsByDealerName();
      this.GetAithrHubDashboardStatisticsDealerDetailsByDealerName();
    }

  }

  GetAithrHubDashboardStatisticsDetails() {
    this.dashboardService.getAithrHubDashboardStatisticsDetails()
      .subscribe(
        (data: any) => {
          this.Statisticslist = data;
          this.dateSynchedReceived = data[0].lastFeedReceivedOn;
          this.totalCount = data[0].totalCount;
        },
        error => {
        });
  }

  copytable(el) {
    var urlField = document.getElementById(el) ;  
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range) ;
    document.execCommand('copy');
    this.showNotification("success","Data copy to clipboard successfully")
}
public showNotification(type: string, message: string): void {
  this.notifier.notify(type, message);
}
exportToCSV(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.StatisticsRegionWiselist);
}
printTable() {
  var prepare = [];
  this.StatisticsRegionWiselist.forEach(e => {
    var tempObj = [];
    tempObj.push(e.regionName);
    tempObj.push(e.lastFeedReceivedOn);
    tempObj.push(e.totalCount);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>RegionName</th><th>lastFeedReceivedOn</th><th>totalCount</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 
downloadPdf() {
  var prepare=[];
  this.StatisticsRegionWiselist.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.regionName);
    tempObj.push(e.lastFeedReceivedOn);
    tempObj.push( e.totalCount);
    
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['RegionName','lastFeedReceivedOn','totalCount']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('RegionWiseDetail' + '.pdf');
}

exportexcel() {
  const prepare = [];
  this.StatisticsRegionWiselist.forEach(e => {
    const tempObj = [];
    tempObj.push(e.regionName);
    tempObj.push(e.lastFeedReceivedOn);
    tempObj.push( e.totalCount);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['RegionName', 'lastFeedReceivedOn', 'totalCount'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'RegionWiseDetail');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'RegionWiseDetail.xlsx');
}
printTableDealer() {
  var prepare = [];
  this.StatisticsDealerWiselist.forEach(e => {
    var tempObj = [];
    tempObj.push(e.dealerName);
    tempObj.push(e.regionName);
    tempObj.push(e.lastFeedReceivedOn);
    tempObj.push(e.totalCount);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>DealerName</th><th>RegionName</th><th>lastFeedReceivedOn</th><th>TotalCount</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 

exportToCSVDealer(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.StatisticsDealerWiselist);
}

downloadPdfDealer() {
  var prepare=[];
  this.StatisticsDealerWiselist.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.dealerName);
    tempObj.push(e.regionName);
    tempObj.push( e.lastFeedReceivedOn);
    tempObj.push( e.totalCount);
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['DealerName','RegionName','lastFeedReceivedOn','TotalCount']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('DealerWiseDetail' + '.pdf');
}

exportexcelDealer() {
  const prepare = [];
  this.StatisticsDealerWiselist.forEach(e => {
    const tempObj = [];
    tempObj.push(e.dealerName);
    tempObj.push(e.regionName);
    tempObj.push( e.lastFeedReceivedOn);
    tempObj.push( e.totalCount);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['DealerName','RegionName', 'lastFeedReceivedOn', 'totalCount'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'DealerWiseDetail');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'DealerWiseDetail.xlsx');
}


  GetAithrHubDashboardStatisticsRegionDetails() {
    this.dashboardService.getAithrHubDashboardStatisticsRegionDetails()
      .subscribe(
        (data: any) => {
         
          this.StatisticsRegionWiselist = data;
        },
        error => {
        });
  }

  GetAithrHubDashboardStatisticsDealerDetails() {
    this.dashboardService.getAithrHubDashboardStatisticsDealerDetails()
      .subscribe(
        (data: any) => {
         debugger;
          this.StatisticsDealerWiselist = data;
        },
        error => {
        });
  }

  HideDetails() {
    this.showUnitDetail = "false";
    this.UnitDetails = [];
    this.UnittotalCount = 0;
  }
  // functions for getting Units detatils
  GetUnitDetails(Dealerid:any,title:any,DealerNameCheck)
  {
    this.TitleValue= title;
    this.ValueDealerName=DealerNameCheck;
    if(this.TitleValue=="Dealer")
    {
      this.opcodeValue ="1";
    } else
    {
      this.opcodeValue ="2";
    }
    this.showUnitDetail="true";
    this.GetUnitDetailsByDealerId(Dealerid);
  }
  GetUnitDetailsByDealerId(ValueDealerId :any) {
    var modelGroup = {
      "DealerId":ValueDealerId.toString(), 
      "Opcode":this.opcodeValue 
    }
    this.dashboardService.getUnitDetailsByDealerIds(modelGroup)
      .subscribe(
        (data: any) => {
          
          this.UnitDetails =data; 
          this.UnittotalCount = data.length;
        },
        error => {
        });
  }

  // function for getting Dashborad data by Dealer name


  GetAithrHubDashboardStatisticsDetailsByDealerName() {
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }
    this.dashboardService.getAithrHubDashboardStatisticsDetailsByDealerName(modelGroup)
      .subscribe(
        (data: any) => {
          this.Statisticslist = data;
          this.dateSynchedReceived = data[0].lastFeedReceivedOn;
          this.totalCount = data[0].totalCount;
        },
        error => {
        });
  }

  GetAithrHubDashboardStatisticsRegionDetailsByDealerName() {
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }
    this.dashboardService.getAithrHubDashboardStatisticsRegionDetailsByDealerName(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.StatisticsRegionWiselist = data;
        },
        error => {
        });
  }

  GetAithrHubDashboardStatisticsDealerDetailsByDealerName() {
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }
    this.dashboardService.getAithrHubDashboardStatisticsDealerDetailsByDealerName(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.StatisticsDealerWiselist = data;
        },
        error => {
        });
  }
}
