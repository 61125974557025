<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="AddAithrhublogForm">
      <div class="container-fluid">
        <div class="row">
          <div class="col-12 mt-3">
  
            <div class="row justify-content-between align-items-center mb-2">
              <div class="col-auto documentbtn">
                  <ul class="list-unstyled mb-1">
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"  (click)="copytable('usertable')">Copy</button>
                    </li>
                   
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                    </li>
                    <li><button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportToCSV()">CSV</button></li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                    </li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                    </li>
                  </ul>
              </div>
              <div class="search-heros col-sm-auto mt-2 mt-lg-0">
                <div class="form-group d-flex mb-1">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                </div>
              </div>
            </div>
  
  
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="card card-raised h-100">
                  <div class="card-header card-raised bg-info text-white">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-sm-6">
                        <h5 class="card-title text-white text-uppercase mb-0">List of Pending Units</h5>
                      </div>
                      <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                        <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                      </div>
                      <!-- <div class="card-subtitle"></div> -->
                    </div>
                  </div>
                  <div id="inventory" class="card-body p-4">
                    <div class="table-responsive">
                      <table id="usertable" class="table table-bordered" style="width:100%">
                        <thead>
                          <tr>
                            <th (click)="sort('userName')"> Inquired by <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th>Inquired Date</th>
                            <th (click)="sort('phoneNumber')"> PhoneNumber <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('email')"> Email <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('dealerName')"> Dealer Name <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('dealerPhone')"> Dealer Phone <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('dealerAddress')"> Dealer Address <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('stockNumber')"> Stock No<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('carYr')">Year <i class="fa fa-fw fa-sort"></i></th>
                            <th (click)="sort('mileage')">Mileage <i class="fa fa-fw fa-sort"></i> </th>
                            <th (click)="sort('make')">Make <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sort('model')">Model<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th>Trim</th>
                          
                            <th style="text-align: right;" (click)="sort('listedPrice')">Price</th>
                            <th style="text-align: right;" (click)="sort('dealerCost')">Dealer Cost</th>
                            <th style="min-width:76px;text-align:center;">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                            <td>{{ dealerVehicleslistdata.userName }}</td>
                            <td> {{ dealerVehicleslistdata.inquiredDate | date }}</td>
                            <td>{{ dealerVehicleslistdata.phoneNumber }}</td>
                            <td>{{ dealerVehicleslistdata.email }}</td>

                            <td>{{ dealerVehicleslistdata.dealerName}}</td>
                            <td>{{ dealerVehicleslistdata.dealerPhone}}</td>
                            <td>{{ dealerVehicleslistdata.dealerAddress}}</td>
                            <td>{{ dealerVehicleslistdata.vin }}</td>
                            <td> {{ dealerVehicleslistdata.stockNumber }}</td>
                            <td> {{ dealerVehicleslistdata.carYr }}</td>
                            <td> {{ dealerVehicleslistdata.mileage }}</td>
                            <td> {{ dealerVehicleslistdata.make }}</td>
                            <td> {{ dealerVehicleslistdata.model }}</td>
                            <td> {{ dealerVehicleslistdata.trim }}</td>
                            
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.listedPrice| currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.dealerCost | currency: 'USD' }}</td>
                            <td style="min-width:76px;" > 
                              <button   class="btn bg-gradient-success btn-sm ml-1" 
                              title="Mark as sold" (click)="markAsSold(dealerVehicleslistdata.vin);"
                              ><i class="fas fa-exclamation-circle"></i></button>
                              <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                              ><i class="fa fa-trash"></i></button></td>
                          </tr>
  
                        </tbody>
                      </table>
                      <br>
  
  
                      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                      </ngx-spinner>
  
                      <div class="row justify-content-between align-items-center">
                        <div class="col">
  
                          <ngb-pagination [collectionSize]="dealerVehicleslist.length" [(page)]="page" [maxSize]="5"
                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                   
                      </div>
                      <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(templateActivate)"><i
                        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Deactivate Users"
                        placement="top" ngbPopover="Are you sure you want to deactivate user?"></i></a>
                    <ng-template #templateActivate>
                      <div class="modal-body text-center">
                        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                        <p>Are you sure you want to mark the unit Sold?</p>
                        <p></p>
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmSold()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                      </div>
                    </ng-template>
                     
                    </div>
                  </div>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>





        <div class="row">
          <div class="col-12 mt-3">
  
            <div class="row justify-content-between align-items-center mb-2">
              <div class="col-auto documentbtn">
                  <ul class="list-unstyled mb-1">
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"  (click)="copytable('usertable')">Copy</button>
                    </li>
                   
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                    </li>
                    <li><button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportToCSV()">CSV</button></li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                    </li>
                    <li>
                      <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                    </li>
                  </ul>
              </div>
              <div class="search-heros col-sm-auto mt-2 mt-lg-0">
                <div class="form-group d-flex mb-1">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                </div>
              </div>
            </div>
  
  
            <div class="row mt-2">
              <div class="col-md-12">
                <div class="card card-raised h-100">
                  <div class="card-header card-raised bg-info text-white">
                    <div class="row justify-content-between align-items-center">
                      <div class="col-sm-6">
                        <h5 class="card-title text-white text-uppercase mb-0">List of Bought/Tubed Units</h5>
                      </div>
                      <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                        <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                      </div>
                      <!-- <div class="card-subtitle"></div> -->
                    </div>
                  </div>
                  <div id="inventory" class="card-body p-4">
                    <div class="table-responsive">
                      <table id="usertable" class="table table-bordered" style="width:100%">
                        <thead>
                          <tr>
                            <th (click)="sortBought('userName')"> Inquired by <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th>Inquired Date</th>
                            <th (click)="sortBought('phoneNumber')"> PhoneNumber <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('email')"> Email <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('dealerName')"> Dealer Name <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('dealerPhone')"> Dealer Phone <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('dealerAddress')"> Dealer Address <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('stockNumber')"> Stock No<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('carYr')">Year <i class="fa fa-fw fa-sort"></i></th>
                            <th (click)="sortBought('mileage')">Mileage <i class="fa fa-fw fa-sort"></i> </th>
                            <th (click)="sortBought('make')">Make <i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th (click)="sortBought('model')">Model<i class="fa fa-fw fa-sort"></i>
                            </th>
                            <th>Trim</th>
                          
                            <th style="text-align: right;" (click)="sortBought('listedPrice')">Price</th>
                            <th style="text-align: right;" (click)="sortBought('dealerCost')">Dealer Cost</th>
                            <th style="min-width:76px;text-align:center;">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            *ngFor="let dealerVehicleslistdata of dealerVehiclesboughtlist| filter:searchTextBought | slice: (pagebought-1) * pageSizebought : (pagebought-1) * pageSizebought + pageSizebought">
                            <td>{{ dealerVehicleslistdata.userName }}</td>
                            <td> {{ dealerVehicleslistdata.inquiredDate | date }}</td>
                            <td>{{ dealerVehicleslistdata.phoneNumber }}</td>
                            <td>{{ dealerVehicleslistdata.email }}</td>

                            <td>{{ dealerVehicleslistdata.dealerName}}</td>
                            <td>{{ dealerVehicleslistdata.dealerPhone}}</td>
                            <td>{{ dealerVehicleslistdata.dealerAddress}}</td>
                            <td>{{ dealerVehicleslistdata.vin }}</td>
                            <td> {{ dealerVehicleslistdata.stockNumber }}</td>
                            <td> {{ dealerVehicleslistdata.carYr }}</td>
                            <td> {{ dealerVehicleslistdata.mileage }}</td>
                            <td> {{ dealerVehicleslistdata.make }}</td>
                            <td> {{ dealerVehicleslistdata.model }}</td>
                            <td> {{ dealerVehicleslistdata.trim }}</td>
                            
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.listedPrice| currency: 'USD' }}</td>
                            <td style="text-align: right;"> {{ dealerVehicleslistdata.dealerCost | currency: 'USD' }}</td>
                            <td style="min-width:76px;" > 
                              <button   class="btn bg-gradient-success btn-sm ml-1" 
                              title="Mark as sold" (click)="markAsSold(dealerVehicleslistdata.vin);"
                              ><i class="fas fa-exclamation-circle"></i></button>
                              <button class="btn bg-gradient-danger btn-sm ml-2" title="Delete"
                              ><i class="fa fa-trash"></i></button></td>
                          </tr>
  
                        </tbody>
                      </table>
                      <br>
  
  
                      <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                      </ngx-spinner>
  
                      <div class="row justify-content-between align-items-center">
                        <div class="col">
  
                          <ngb-pagination [collectionSize]="dealerVehiclesboughtlist.length" [(page)]="pagebought" [maxSize]="5"
                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                   
                      </div>
                      <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(templateActivate)"><i
                        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Deactivate Users"
                        placement="top" ngbPopover="Are you sure you want to deactivate user?"></i></a>
                    <ng-template #templateActivate>
                      <div class="modal-body text-center">
                        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                        <p>Are you sure you want to mark the unit Sold?</p>
                        <p></p>
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmSold()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                      </div>
                    </ng-template>
                     
                    </div>
                  </div>
  
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- <app-footer id="secondfooter"></app-footer> -->

    </form>
  </section>
