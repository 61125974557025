<!-- header start -->
<div id="admindashboard" style="padding:0 !important;height: 100vh;">
    <header id="header" class="navbar navbar-full">
        <div class="container-md">
            <div class="col-12 topbar">
                <div class="row align-items-center justify-content-between">
                    <div class="col-auto px-0 px-sm-2">
                        <div class="d-inline-block d-lg-none pr-1">
                            <button style="position: relative;top: 2px;" class="navbar-toggler m-0" type="button"
                                data-toggle="collapse" data-target="#mainNavbarCollapse">
                                &#9776;
                            </button>
                        </div>
                        <a class="logocon" href="#/">
                            <img class="main-logo" src="../../assets/images/loginImage.png" alt="website logo">
                            <img class="small-logo" style="display:none;" src="../../assets/images/BrandImage.png">
                        </a>
                    </div>
    
                 <div class="navbar-toggleable-md" id="mainNavbarCollapse">
                    <ul class="list-unstyled nav navbar-nav">
                      <li class="nav-item">
                        <a href="#/">Home</a>
                      </li>
                      <li class="nav-item">
                        <a class="" href="#/about-us" >About Us</a>
                      </li>
                      <li class="nav-item">
                        <a href="#/contact-us">Contact Us</a>
                      </li>
                       <!-- <li class="nav-item dropdown">
                          <a class="nav-link dropdown-toggle" type="button" id="MoreOptions" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            More Options
                          </a>
                          <div class="dropdown-menu" aria-labelledby="MoreOptions">
                            <a class="dropdown-item" routerLink="/shipping-returns">Shipping return</a>
                            <a class="dropdown-item" routerLink="/tearm-condition">Tearm & Condition</a>
                            <a class="dropdown-item" routerLink="/privacy-policy">Privacy Policy</a>
                            <a class="dropdown-item" routerLink="/faqs-page">Help & FAQ</a>
                          </div>
                      </li> -->
                    </ul>
                  </div>
    
    
                    <div class="col-auto">
                        <div class="userlogin">
                            <a href="#/login" class="btn btn-danger btn-md mr-2">Sign In</a>
                            <!-- <a href="/#/Register/0" class="btn btn-transparent btn-md">SignUp</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
    
    <!-- header end -->

<section class="welcome-4 page-section-ptb white-bg">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-md-10">
           <div class="section-title">
             <span>Let's Chat – We're Here to Help! </span>
             <h2>Have a Question? Let’s Talk!</h2>
             <div class="separator"></div>
           </div>
        </div>
      </div>
  
      <div class="row">
         <div class="col-lg-4 col-sm-12">
            <div class="feature-box-3 grey-border">
              <div class="icon">
                <i class="fas fa-map-marked-alt"></i>
               </div>
               <div class="content">
                 <h5>Address </h5>
                  <p>Calgary, Canada </p>
                </div>
              </div>
              <div class="feature-box-3 grey-border">
              <div class="icon">
                 <i class="fas fa-phone-alt"></i>
               </div>
               <div class="content">
                 <h5>Phone </h5>
                  <p> (403) 404-6773</p>
                </div>
              </div>
              <div class="feature-box-3 grey-border mb-0">
              <div class="icon">
                 <i class="fas fa-envelope"></i>
               </div>
               <div class="content">
                 <h5>Email  </h5>
                  <p>chrisavery0594@gmail.com </p>
                </div>
              </div>
              <div class="opening-hours gray-bg">
                  <h6 class="text-uppercase">opening hours</h6>
                  <ul class="list-unstyled">
                    <li><strong>Sunday</strong> <span class="text-red"> closed</span></li>
                    <li><strong>Monday</strong> <span> 9:00 AM to 9:00 PM</span></li>
                    <li><strong>Tuesday </strong> <span> 9:00 AM to 9:00 PM</span></li>
                    <li><strong>Wednesday </strong> <span> 9:00 AM to 9:00 PM</span></li>
                    <li><strong>Thursday </strong> <span> 9:00 AM to 9:00 PM</span></li>
                    <li><strong>Friday </strong> <span> 9:00 AM to 9:00 PM</span></li>
                    <li><strong>Saturday </strong> <span> 9:00 AM to 9:00 PM</span></li>
                  </ul>
                </div>
           </div>
        <div class="col-lg-8 col-sm-12 mb-lg-0 mb-1">
          <div class="gray-form row">
            <div class="col-md-12">
              <form class="form-horizontal" id="contactform" role="form" method="post" action="php/contact-form.php">
                <h5>Join 500 clients and 1000+ successful projects. Let’s start yours</h5>
                <p>Have something on your mind? Send us a message and we’ll be sure to get back to you within 24 hours. We can’t wait to hear from you!</p>
       
                <div class="contact-form">
                  <div class="mb-3">
                    <input id="contactform_name" type="text" placeholder="Name*" class="form-control placeholder" name="name">
                  </div>
                  <div class="mb-3">
                    <input id="contactform_email" type="email" placeholder="Email*" class="form-control placeholder" name="email">
                  </div>
                  <div class="mb-3">
                    <input id="contactform_phone" type="text" placeholder="Phone*" class="form-control placeholder" name="phone">
                  </div>
                  <div class="mb-3">
                    <textarea id="contactform_message" class="form-control input-message placeholder" placeholder="Comment*" rows="7" name="message"></textarea>
                  </div>
                  <div class="d-grid">
                    <input type="hidden" name="action" value="sendEmail">
                    <button id="submit" name="submit" type="submit" value="Send" class="button red btn-block"> Send your message <i class="fa fa-spinner fa-spin fa-fw btn-loader" style="display: none;"></i></button>
                  </div>
                </div>
              </form>
            </div>
          </div>
         </div>
        
       </div>
    </div>
  </section>

  <section class="google-map page-section-ptb py-0">
    <div class="container-fluid">
      <div class="row">
        <div class="w-100">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19120130.63421963!2d-116.74717236985772!3d54.19777836977391!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4b0d03d337cc6ad9%3A0x9968b72aa2438fa5!2sCanada!5e0!3m2!1sen!2sin!4v1728883569533!5m2!1sen!2sin" width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  </section>


<!-- Footer  start-->
<footer class="text-white pt-5 pb-4">
    <div class="container">
      <div class="row">
        <div class="col-md-3 col-lg-4 col-xl-3 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">About</h6>
          <img style="width:135px;" class="mb-2" src="../../assets/images/BrandImage.png" alt="website logo" />
          <p class="">
             Dealer Beusiness System (DBS) is a comprehensive software solution designed for 
            automotive dealerships to enhance operational efficiency. 
          </p>
        </div>
        <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Recent Posts</h6>
          <p><a href="#" class="text-white">Buy your dream car</a></p>
          <p><a href="#" class="text-white">Does Your Life Lack Meaning</a></p>
          <p><a href="#" class="text-white">The A Z Of Motivation</a></p>
          <!-- <p><a href="#" class="text-white">Product 4</a></p> -->
        </div>
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Useful links</h6>
          <p><a href="#" class="text-white">Your Account</a></p>
          <p><a href="#" class="text-white">Become an Affiliate</a></p>
          <p><a href="#" class="text-white">Shipping Rates</a></p>
          <p><a href="#" class="text-white">Help</a></p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mb-md-0 mb-4">
          <h6 class="text-uppercase font-weight-bold mb-4">Contact</h6>
          <p><i class="fas fa-home mr-3"></i> Calgary, Canada</p>
          <p><i class="fas fa-envelope mr-3"></i>chrisavery0594@gmail.com</p>
          <p><i class="fas fa-phone mr-3"></i> (403) 404-6773</p>
        </div>
      </div>
    </div>
    <hr style="border-color: #2b2b2b;"/>
    <div class="footer-copyright text-center py-3">
      © 2024 Copyright:
      <a href="#" class="text-white"> SPADEZ SOLUTIONS PRIVATE LIMITED</a>
    </div>
  </footer>
  <!-- footer end -->

</div>