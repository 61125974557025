import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { flagreport } from '@app/_models/flagreport';
@Component({
  selector: 'app-flagreport-dashboard',
  templateUrl: './flagreport-dashboard.component.html',
  styleUrls: ['./flagreport-dashboard.component.less']
})
export class FlagreportDashboardComponent implements OnInit {
  flagReportForm: FormGroup;
  flagreportlist: flagreport[];
  constructor(private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService,private route: ActivatedRoute,
    private router: Router,)
    {



     }

  // variables for Values
  VIN: any;
  RefNum: any;
  status: any;
  VehicleYear: any;
  Make: any;
  Year: any;
  Model: any;
  Trim: any;
  Drivetrain: any;
  Engine: any;
  Transmission: any;
  Style: any;
  body:any;
  colour:any;
  mileage:any;
  options:any;
  DealerName:any;
  DealerNumber:any;
  DealerAddress:any;
  DealerEmail:any;
  selectedVin: any;
  FirstSevereProblemDate:any;
  LatestSevereProblemDate:any;
  TitleBrand:any;
  FirstTitleBrandDate:any;
  FirstTitleBrandProvince:any;
  LatestTitleBrandDate:any;
  LatestTitleBrandProvince:any;
  HasPoliceReportedAccident:any;
  FirstPoliceReportedAccidentDate:any;
  LatestPoliceReportedAccidentDate:any;
  HasFrameDamage:any;
  FirstFrameDamageDate:any;
  LatestFrameDamageDate:any;
  HailDamage:any;
  FirstHailDamageDate:any;
  FirstHailDamageProvince:any;
  LatestHailDamageDate:any;
  LatestHailDamageProvince:any;
  FireDamage:any;
  FirstFireDamageDate:any;
  FirstFireDamageProvince:any;
  LatestFireDamageDate:any;
  LatestFireDamageProvince:any;
  FloodDamage:any;
  FirstFloodDamageDate:any;
  FirstFloodDamageProvince:any;
  LatestFloodDamageDate:any;
  LatestFloodDamageProvince:any;
  LatestStolenDate:any;
  DamageOverAccumulativeThreshold:any;
  DamageOverAccumulativeThresholdDate:any;
  DamageAccumulativeThreshold:any;

  ColorTheme:any;


  ngOnInit(): void {

    this.Createform();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    
    this.DamageOverAccumulativeThresholdDate = new Date();
    this.FirstFloodDamageProvince="British Columbia";
    this.selectedVin = this.route.snapshot.paramMap.get('vin');
    this.GetFlagreport();
  }
  Createform() {
    this.flagReportForm = new FormGroup({
    });
  }

  GetFlagreport() {
    var modelGroup = {
      "VIN": this.selectedVin
    }

    this.manageInventoryService.getflagreportDetailsList(modelGroup)
      .subscribe(
        (data: flagreport[]) => {
         debugger;
         this.flagreportlist = data;
        //  var a=this.flagreportlist[0].firstFloodDamageDate;
        //  var a=this.flagreportlist[0].dealerEmail;


          this.VIN = data[0].vin;
          this.RefNum= data[0].refNum;
          this.status= data[0].status;


          this.VehicleYear= data[0].carYr;
          this.Make= data[0].make;
          this.Year= data[0].carYr;
          this.Model= data[0].model;
          this.Trim= data[0].trim;
          this.mileage = data[0].mileage;
          this.options= data[0].options;
          this.Drivetrain= data[0].driveTrain;
          this.Engine= data[0].vin;
          this.Transmission= data[0].transmission;
          this.Style= data[0].style;
          this.body=data[0].body;
          this.colour= data[0].colour;
          this.DealerName= data[0].dealerName;
          this.DealerNumber= data[0].dealerPhone;
          this.DealerAddress= data[0].dealerAddress;
          this.DealerEmail= data[0].dealerEmail;


          if(data[0].firstSevereProblemDate==undefined)
          {
            this.FirstSevereProblemDate= "N/A";
          }else{
            this.FirstSevereProblemDate= data[0].firstSevereProblemDate ;
          }




          if(data[0].latestSevereProblemDate ==undefined)
          {
            this.LatestSevereProblemDate= "N/A" ;//data[0].vin;
          }else{
            this.LatestSevereProblemDate= data[0].latestSevereProblemDate ;//data[0].vin;
          }

          this.TitleBrand= (data[0].titleBrand!=undefined?data[0].titleBrand: "N/A");//data[0].vin;
          this.FirstTitleBrandDate= (data[0].firstTitleBrandDate!=undefined?data[0].firstTitleBrandDate: "N/A");   //data[0].vin;
          this.FirstTitleBrandProvince=(data[0].firstFireDamageProvince!=undefined?data[0].firstFireDamageProvince: "N/A");    //data[0].vin;
          this.LatestTitleBrandDate=(data[0].latestTitleBrandDate!=undefined?data[0].latestTitleBrandDate: "N/A");    //data[0].vin;
          this.LatestTitleBrandProvince= (data[0].latestTitleBrandProvince!=undefined?data[0].latestTitleBrandProvince: "N/A");
          this.HasPoliceReportedAccident= (data[0].hasPoliceReportedAccident!=undefined?data[0].hasPoliceReportedAccident: "N/A");



          if(data[0].hasPoliceReportedAccident == false || data[0].hasPoliceReportedAccident==undefined)
          {
            this.HasPoliceReportedAccident= "No";
          } else{
            this.HasPoliceReportedAccident=  "Yes";
          }

          this.FirstPoliceReportedAccidentDate=  (data[0].firstPoliceReportedAccidentDate!=undefined?data[0].firstPoliceReportedAccidentDate: "N/A");
          this.LatestPoliceReportedAccidentDate= (data[0].latestPoliceReportedAccidentDate!=undefined?data[0].latestPoliceReportedAccidentDate: "N/A");


          if(data[0].hasFrameDamage == false || data[0].hasFrameDamage==undefined)
          {
            this.HasFrameDamage= "No";
          } else{
            this.HasFrameDamage=  "Yes";
          }

          this.FirstFrameDamageDate=  (data[0].firstFrameDamageDate!=undefined?data[0].firstFrameDamageDate: "N/A");
          this.LatestFrameDamageDate=  (data[0].latestFrameDamageDate!=undefined?data[0].latestFrameDamageDate: "N/A");
          this.HailDamage=   (data[0].hailDamage!=undefined?data[0].hailDamage: "N/A");


          if(data[0].hailDamage == false || data[0].hailDamage==undefined)
          {
            this.HailDamage= "No";
          } else{
            this.HailDamage=  "Yes";
          }

          this.FirstHailDamageDate=   (data[0].firstHailDamageDate!=undefined?data[0].firstHailDamageDate: "N/A");
          this.FirstHailDamageProvince=   (data[0].firstHailDamageProvince!=undefined?data[0].firstHailDamageProvince: "N/A");
          this.LatestHailDamageDate= (data[0].latestHailDamageDate!=undefined?data[0].latestHailDamageDate: "N/A");
          this.LatestHailDamageProvince=  (data[0].latestHailDamageProvince!=undefined?data[0].latestHailDamageProvince: "N/A");

          if(data[0].fireDamage == false || data[0].fireDamage==undefined)
          {
            this.FireDamage= "No";
          } else{
            this.FireDamage=  "Yes";
          }

          this.FirstFireDamageDate=  (data[0].firstFireDamageDate!=undefined?data[0].firstFireDamageDate: "N/A");
          this.FirstFireDamageProvince=   (data[0].firstFireDamageProvince!=undefined?data[0].firstFireDamageProvince: "N/A");
          this.LatestFireDamageDate=  (data[0].latestFireDamageDate!=undefined?data[0].latestFireDamageDate: "N/A");
          this.LatestFireDamageProvince=   (data[0].latestFireDamageProvince!=undefined?data[0].latestFireDamageProvince: "N/A");


          if(data[0].floodDamage == false || data[0].floodDamage==undefined)
          {
            this.FloodDamage= "No";
          } else{
            this.FloodDamage=  "Yes";
          }

          this.FirstFloodDamageDate =  (data[0].firstFloodDamageDate!=undefined?data[0].firstFloodDamageDate: "N/A");
          this.FirstFloodDamageProvince=   (data[0].firstFloodDamageProvince!=undefined?data[0].firstFloodDamageProvince: "N/A");
          this.LatestFloodDamageDate =  (data[0].latestFloodDamageDate!=undefined?data[0].latestFloodDamageDate: "N/A");
          this.LatestFloodDamageProvince=   (data[0].latestFloodDamageProvince!=undefined?data[0].latestFloodDamageProvince: "N/A");
          this.LatestStolenDate=  (data[0].latestStolenDate!=undefined?data[0].latestStolenDate: "N/A");
          if(data[0].damageOverAccumulativeThreshold == false || data[0].damageOverAccumulativeThreshold==undefined)
          {
            this.DamageOverAccumulativeThreshold= "No";
          } else{
            this.DamageOverAccumulativeThreshold=  "Yes";
          }

          this.DamageOverAccumulativeThresholdDate=  (data[0].damageOverAccumulativeThresholdDate!=undefined?data[0].damageOverAccumulativeThresholdDate: "N/A");
          this.DamageAccumulativeThreshold=   (data[0].damageAccumulativeThreshold!=undefined?data[0].damageAccumulativeThreshold: "0");
        },
        error => {
        });
  }
}
