import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.less']
})
export class DetailListComponent implements OnInit {

  constructor(private lenderService: LenderService,
    private globalcodeService: GlobalcodeService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService) { }
  detailListForm: FormGroup;
  NameValueFilter: any;
  NameFilter: any;
  NameFilterValue:any;
  LabelName:any;
  TotalRecord: any;
  detailTypeFilter: any;
  Globalcodelist = [];
  Userlist = [];
  page = 1;
  pageSize = 10;
  isDesc: boolean = false;
  column: string = 'userName';
  displaylist:any;
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Userlist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ngOnInit(): void {
    this.Createform();
    this.displaylist ="false";
  }
  ResetFilter()
  {
    this.NameFilterValue="";
    this.LabelName ="";
    this.Userlist=[];
    this.displaylist ="false";
    this.ngOnInit()
   
  }
  Createform() {
    this.detailListForm = new FormGroup({
      detailTypeFilter: this.fb.control('', Validators.required),
      NameFilter: this.fb.control(''),
    });
  }

  FilterList() {
    var check = this.detailTypeFilter;
    if (this.detailTypeFilter == "1") {
      this.NameFilterValue = "1";

    } else if (this.detailTypeFilter == "2") {
      this.NameFilterValue = "2";

    } else {
      this.NameFilterValue = "3";
    }
     this.GetGlobalcodelist();
  }

  downloadPdf() {
    var prepare = [];
    this.Userlist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.roleNameValue);
      tempObj.push(e.groupNameValue);
      tempObj.push(e.dealerName);
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    // doc.autoPrint();
    autoTable(doc, {
      head: [['UserName', 'Role', 'GroupName', 'DealerName', 'FirstName', 'LastName', 'City', 'Phone']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('UserList' + '.pdf');
  }

  printTable() {
    var prepare = [];
    this.Userlist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.roleNameValue);
      tempObj.push(e.groupNameValue);
      tempObj.push(e.dealerName);
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>UserName</th><th>Role</th><th>GroupName</th><th>DealerName</th><th>FirstName</th><th>LastName</th><th>City</th><th>Phone</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  exportexcel() {
    const prepare = [];
    this.Userlist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.roleNameValue);
      tempObj.push(e.groupNameValue);
      tempObj.push(e.dealerName);
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });

    /* Convert data to worksheet */
    const ws = XLSX.utils.aoa_to_sheet([
      ['UserName', 'Role', 'GroupName', 'DealerName', 'FirstName', 'LastName', 'City', 'Phone'],
      ...prepare
    ]);

    /* Create a new workbook and add the worksheet */
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Product_List');

    /* Save the workbook to a file */
    XLSX.writeFile(wb, 'Product_List.xlsx');
  }
  GetList()
  {
    this.spinner.show();

    if (this.detailTypeFilter == "1") {
     
      this.LabelName ="Role";
    } else  if (this.detailTypeFilter == "2"){
     
      this.LabelName ="Group";
    } else
    {
      this.LabelName ="Dealer";
    }
    this.displaylist ="true";
    this.GetUserTypeListById();
  }
  GetGlobalcodelist() {
    var modelGroup = {
      "GlobalCodeName": this.detailTypeFilter,
      "GlobalCodeValue": this.detailTypeFilter
    }
    this.globalcodeService.getGlobalcodelistbyName(modelGroup)
      .subscribe(
        (data: any) => {
          this.Globalcodelist = data;
          this.TotalRecord = data.length;
        },
        error => {
        });
  }
  GetUserTypeListById() {
    var modelGroup = {
      "GlobalCodeName": this.NameFilterValue,
      "GlobalCodeValue": this.NameFilter
    }

    this.spinner.show();
    this.globalcodeService.getUserTypeList(modelGroup)
      .subscribe(
        (data: any) => {
  
          this.Userlist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

}
