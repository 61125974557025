import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListDealersService } from '@app/_services/list-dealers.service';
@Component({
  selector: 'app-aithrx-client-dashboard',
  templateUrl: './aithrx-client-dashboard.component.html',
  styleUrls: ['./aithrx-client-dashboard.component.less']
})
export class AithrxClientDashboardComponent implements OnInit {
  clientDbForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder, private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private modalService: NgbModal, private addgroupService: AddgroupService,
    private confirmModalService: BsModalService,
    private datePipe: DatePipe,
    notifier: NotifierService, private spinner: NgxSpinnerService, private listDealersService: ListDealersService,) {
    this.notifier = notifier;
    this.radioAcceptItems = ['Decision', 'Counter Offer', 'Response Only'];
    this.radioAcceptCheckItems = ['Accept', 'Decline'];
  }
  opcode: any;
  DealerNameFilter: any;
  notifciationCount: any;
  opcodePicture: any;
  radioAcceptItems: Array<string>;
  radioAcceptCheckItems: Array<string>;
  opcodebuild: any;
  opcodeMAximized: any;
  opcodeAttention: any;
  CounterSectionVisible: any;
  ResponseSectionVisible: any;
  DecisonSectionVisible: any = false;
  RadioValue: any;
  DealerList = [];
  TotalInvest: any;
  V3BuildCount: any;
  V3MaximizedCount: any;
  TotalInventory: any;
  dealerVehicleslist: DealerVehicles[] = [];
  dealerPicturelist: DealerVehicles[] = [];
  TotalRecordVerify: any;
  dealerVerifylist: DealerVehicles[] = [];

  dealerBuildlist: DealerVehicles[] = [];
  dealerMaximized: DealerVehicles[] = [];
  dealerAttention: DealerVehicles[] = [];



  TotalRecordBuild: any;


  TotalRecordmaximized: any;

  TotalRecordAttention: any;

  TopResult: any;
  UnitsWithoutCost: any;
  UsedUnits: any;
  NewUnits: any;
  TotalRecord: any;
  ResponseNotes: any;
  LoggedUserName: any;
  UseridValue: any;
  CurrentDate: any;
  VINValue: any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];

  pagePicture = 1;
  pageSizePicture = 10;
  pageSizesPicture = [10, 20, 30, 40, 50, 60, 70];


  pageVerify = 1;
  pageSizeVerify = 10;
  pageSizesVerify = [10, 20, 30, 40, 50, 60, 70];


  pageAttention = 1;
  pageSizeAttention = 10;
  pageSizesAttention = [10, 20, 30, 40, 50, 60, 70];

  pagebuild = 1;
  pageSizebuild = 10;
  pageSizesbuild = [10, 20, 30, 40, 50, 60, 70];


  pageMaximized = 1;
  pageSizeMaximized = 10;
  pageSizesMaximized = [10, 20, 30, 40, 50, 60, 70];

  SelectedVIN: any;
  VINLogCheck: any;
  OpcodeDetail: any;
  UnitDealerId: any;
  UunitVechileId: any;
  UnitDealerCost: any;
  UnitListPrice: any;
  OfferRequest: any = 0;
  OfferRequestCommment: any;

  COunterOffer: any;
  COunterComment: any;
  COunterDate: any;

  IsCOunterDateVisible: any = false;

  ResponseComment: any;
  AccpetComment: any;
  RejectReason: any;
  Isaccept: any = 0;
  IsacceptVIsbile: any = 0;
  OfferDateBindng: any;
  RadioValueCheck: any;
  OfferCommentng: any;
  selectedVin: any;
  OfferAmountng: any = "0.00";
  OpcodeForOffer: any;
  ActionTypeValue: any;
  OfferFromValue: any;
  offerreadonly: boolean = false;
  newOffer: boolean = false;
  PrimeIdUPdate: any;
  primeid: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  TotalRecordPicture: any;
  searchText: any;
  isDesc: boolean = false;
  column: string = 'vin';
  PageTitle: any;
  opcodeVerify: any;
  modalRef: BsModalRef;

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];


  pieChartOptionsbuild: ChartOptions;
  pieChartLabelsbuild: Label[];
  pieChartDatabuild: SingleDataSet;
  pieChartTypebuild: ChartType;
  pieChartLegendbuild: boolean;
  pieChartPluginsbuild = [];

  pieChartOptionsAttention: ChartOptions;
  pieChartLabelsAttention: Label[];
  pieChartDataAttention: SingleDataSet;
  pieChartTypeAttention: ChartType;
  pieChartLegendAttention: boolean;
  pieChartPluginsAttention = [];

  pieChartOptionsInvest: ChartOptions;
  pieChartLabelsInvest: Label[];
  pieChartDataInvest: SingleDataSet;
  pieChartTypeInvest: ChartType;
  pieChartLegendInvest: boolean;
  pieChartPluginsInvest = [];


  colorsInvest: Color[] = [
    {
      backgroundColor: [
        '#FC766A',
        '#783937',
        'blue'
      ]
    }
  ];

  colorsSearch: Color[] = [
    {
      backgroundColor: [
        '#8A307F',
        '#79A7D3',
        'blue'
      ]
    }
  ];

  colorsInventory: Color[] = [
    {
      backgroundColor: [

        '#00539C',
        '#EEA47F',
        'blue'
      ]
    }
  ];

  V4UnitattntionCount: any;
  Flagged: any;
  Nocbb: any;
  Totalcost: any;

  statusofOffer: any;
  //For three radio button
  SelectionRadiobt: any;
  decisionsectionradio_Checked: any;
  decisionsectionradio_Disable: any;
  Isaccepted_Checked: any;


  DecisionitemsRadiobutton = [
    { groupName: 'Decision', value: 'Decision', disable: false },
    { groupName: 'Counter Offer', value: 'Counter Offer', disable: false },
    { groupName: 'Response Only', value: 'Response Only', disable: false },
  ];

  selectedValueDecisionRadioBT: any;
  OfferType: any;


  //for chart start investment

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 20,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end"
      }
    }
  };
  public barChartLabels: Label[];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[];
  modalcloseOpen: any;
  ColorTheme: any;
  BindInvestmentChart() {
    this.barChartData = [
      {
        data: [this.Totalcost, 0, 0],
        label: "Cost",
        backgroundColor: "green"
      },
      {
        data: [0, this.TotalInvest, 0],
        label: "Investment",
        backgroundColor: "blue"
      }

    ];

    this.barChartLabels = ["Cost", "Investment"];

  }

  //investment chart end

  ngOnInit(): void {


    this.BindInvestmentChart();

    this.Createform();
    this.GetReasonlist();
    var currentd = new Date();
    // setInterval((this.func1,this.GetClientDashboardNotificationList), 10000);
    // setInterval(this.GetClientDashboardNotificationList, 1000);
    //  setInterval(
    //   function() {
    //     this.GetClientDashboardNotificationList();
    //   }, 5000);
    this.CurrentDate = this.transformDate(currentd);
    this.UsedUnits = 0;
    this.NewUnits = 0;
    this.RadioValue == "Decision";
    this.LoggedUserName = localStorage.getItem('UserName');
    this.UseridValue = localStorage.getItem('userid');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.DealerNameFilter = this.DealerIdvalue;
    // this.GetDealerNameFilterlist();
    if (this.rolevalue == "SuperAdmin") {
      this.GetDealerNameFilterlist();
    } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
      this.GetDealersByDealerName();
    }
    this.CounterSectionVisible = false;
    this.ResponseSectionVisible = false;
    this.DecisonSectionVisible = false;
    this.clientDbForm.controls.DealerNameFilter.setValue(this.DealerIdvalue);
    this.opcode = "1";
    this.opcodePicture = "2";
    this.opcodeVerify = "3";
    this.opcodebuild = "4";
    this.opcodeMAximized = "5";
    this.opcodeAttention = "6";

    this.GetAIXAithrhubClientDB();
    this.GetClientDashboardList();
    this.GetClientDashboardPictureViewedList();
    this.GetClientDashboardVerifyViewedList();
   // this.GetClientDashboardNotificationList();
    this.GetClientDashboardAttention();
    this.GetClientDashboardMaximized();
    this.GetClientDashboardBuild();

    this.TotalInvest = 0;
    this.Totalcost = 0;
    this.TotalInventory = 0;
    this.TopResult = 0;
    this.UnitsWithoutCost = 0;

    this.V3BuildCount = 0;
    this.V3MaximizedCount = 0;
    this.GeneratePiChart();
    this.GenerateBuildPiChart();
    this.GenerateAttentionPiChart();
    this.GenerateInvestPiChart()
  }

  func1() {
    console.log('func 1');
  }
  func2() {
    //this.GetClientDashboardNotificationList();

  }
  GetDealersByDealerName() {

    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.listDealersService.getDealersByDealerName(modelGroup)
      .subscribe(
        data => {

          this.DealerList = data;

        },
        error => {
        });


  }
  RefereshCount() {
   // this.GetClientDashboardNotificationList();
  }

  GetClientDashboardNotificationList() {

    debugger;
    var modelGroup = {
      "Opcode": "1",
      "DealerName": "",
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getNotificationsDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {
          this.notifciationCount = data.length;

        },
        error => {
        });
  }
  IsAcceptedClicked(value: any) {

    if (value == "Decision") {
      this.RadioValue = value;
      this.DecisonSectionVisible = true;
      this.CounterSectionVisible = false;
      this.ResponseSectionVisible = false;
    }
    else if (value == "Counter Offer") {

      this.CounterSectionVisible = true;
      this.ResponseSectionVisible = false;
      this.DecisonSectionVisible = false;
      this.RadioValue = value;
    } else if (value == "Response Only") {

      this.ResponseSectionVisible = true;
      this.CounterSectionVisible = false;
      this.DecisonSectionVisible = false;
      this.RadioValue = value;

    }

  }

  IsAcceptedClickedCheck(value: any) {

    if (value == "Accept") {
      this.Isaccept = 1;
      this.IsacceptVIsbile = 0;

      this.RadioValueCheck = value;
    }
    else if (value == "Decline") {
      this.Isaccept = 99;
      this.IsacceptVIsbile = 1;
      this.RadioValueCheck = value;
    }

  }

  CLoseOffer() {
    this.ResponseSectionVisible = false;
    this.CounterSectionVisible = false;
  }
  GenerateFlagReportList() {
  }


  openMakeOfferModelPopUp_old(targetModal, VIN, offer: any, unitList: any, offerFrom: any) {

    if (offerFrom == "UnitSearch") {
      this.OpcodeForOffer = "100";
      this.OfferFromValue = offerFrom;

    } else if (offerFrom == "Picture") {
      this.OpcodeForOffer = "130";
      this.OfferFromValue = offerFrom;
    } else if (offerFrom == "Build") {
      this.OpcodeForOffer = "110";
      this.OfferFromValue = offerFrom;
    }
    else if (offerFrom == "Maximized") {
      this.OpcodeForOffer = "120";
      this.OfferFromValue = offerFrom;
    }
    if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }
    this.SelectedVIN = VIN;
    this.VINLogCheck = VIN;
    this.selectedVin = VIN;
    this.offerreadonly = false;
    this.DecisonSectionVisible = false;
    this.IsacceptVIsbile = 0;
    this.newOffer = false;
    if (offer > 0) {
      this.newOffer = true;
      this.GetAIXZOfferLogByVINDetail();
    }
    else {
      this.OfferDateBindng = "";
      this.OfferCommentng = "";
      this.OfferAmountng = "0.00";
      this.offerreadonly = false;
    }

    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  SelectionRadioFunction(value: any) {

    if (value == "Decision") {

      this.SelectionRadiobt = 999;

      ///this.RadioValue = value;
      // this.DecisonSectionVisible= true;
      //this.CounterSectionVisible= false;
      //this.ResponseSectionVisible= false;
    }
    else if (value == "Counter Offer") {
      this.SelectionRadiobt = 100;
      // this.CounterSectionVisible= true;
      // this.ResponseSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;
    } else if (value == "Response Only") {
      this.SelectionRadiobt = 111;
      // this.ResponseSectionVisible= true;
      // this.CounterSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;

    }

  }

  openMakeOfferModelPopUp(targetModal, VIN, offer: any, unitList: any, offerFrom, status) {

    this.UnitListPrice = unitList.listedPrice;

    if (offerFrom == "UnitSearch") {
      this.OpcodeForOffer = "100";
      this.OfferFromValue = offerFrom;

    } else if (offerFrom == "Picture") {
      this.OpcodeForOffer = "130";
      this.OfferFromValue = offerFrom;
    } else if (offerFrom == "Build") {
      this.OpcodeForOffer = "110";
      this.OfferFromValue = offerFrom;
    }
    else if (offerFrom == "Maximized") {
      this.OpcodeForOffer = "120";
      this.OfferFromValue = offerFrom;
    }
    if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }


    this.statusofOffer = status;


    //Re initialize value of nngmodel start
    this.RejectReason = 0;
    this.AccpetComment = "";
    this.COunterOffer = 0.00;
    this.COunterDate = "";
    this.COunterComment = "";
    this.ResponseComment = "";
    this.Isaccepted_Checked = "";
    this.selectedValueDecisionRadioBT = 0;
    //end initialize



    this.VINLogCheck = VIN;
    this.selectedVin = VIN;
    this.offerreadonly = false;
    this.newOffer = false;
    if (offer > 0) {
      this.newOffer = true;
      this.GetAIXZOfferLogByVINDetail();
    }
    else {
      this.OfferDateBindng = "";
      this.OfferCommentng = "";
      this.OfferAmountng = "0.00";
      this.offerreadonly = false;
    }

    if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }
    this.SelectionRadiobt = 0;
    this.Isaccept = 1;
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }


  SelectedAccepteddecline(data: any) {

    if (data == "Accept") {
      this.Isaccept = 1;
    }
    if (data == "Decline") {
      this.Isaccept = 99;
    }

  }


  GetAIXZOfferLogByVINDetail_old() {
    this.OpcodeDetail = "1";
    var modelGroup = {
      "Opcode": this.OpcodeDetail,
      "VIN": this.VINLogCheck

    }

    this.manageInventoryService.getAIXZOfferLogByVINs(modelGroup)
      .subscribe(
        (data: any) => {


          this.PrimeIdUPdate = data[0].id;
          this.offerreadonly = true;

          let Offerdate = new Date(data[0].offerDateTime);
          this.OfferDateBindng = Offerdate.toDateString();
          this.OfferCommentng = data[0].offerComments;
          this.OfferAmountng = parseFloat(data[0].offerAmount).toFixed(2);

          if (data[0].counterOffer != undefined) {
            this.COunterOffer = parseFloat(data[0].counterOffer).toFixed(2);
          }
          else {
            this.COunterOffer = "0.00";
          }
          this.COunterComment = data[0].counterComments;

          if (data[0].updatedDateTime != undefined) {
            let Counterdate = new Date(data[0].updatedDateTime);
            this.COunterDate = Counterdate.toDateString();
            this.IsCOunterDateVisible = true;
          }

        },
        error => {
        });
  }

  GetAIXZOfferLogByVINDetail() {
    this.OpcodeDetail = "1";
    var modelGroup = {
      "Opcode": this.OpcodeDetail,
      "VIN": this.VINLogCheck

    }

    this.manageInventoryService.getAIXZOfferLogByVINs(modelGroup)
      .subscribe(
        (data: any) => {

          //first time i am doing disable  false
          this.DecisionitemsRadiobutton.forEach(number => {

            number.disable = false;

          })



          this.offerreadonly = true;
          this.primeid = data[0].id;
          let Offerdate = new Date(data[0].offerDateTime);
          this.OfferDateBindng = Offerdate.toDateString();
          this.OfferCommentng = data[0].offerComments;
          this.OfferAmountng = parseFloat(data[0].offerAmount).toFixed(2);

          if (data[0].counterOffer != undefined) {
            this.COunterOffer = parseFloat(data[0].counterOffer).toFixed(2);
          }
          else {
            this.COunterOffer = "0.00";
          }
          this.COunterComment = data[0].counterComments;

          if (data[0].updatedDateTime != undefined) {
            let Counterdate = new Date(data[0].updatedDateTime);
            this.COunterDate = Counterdate.toDateString();
            this.IsCOunterDateVisible = true;
          }


          //read action type and disabl rest radio button

          if (data[0].actionType == 100) //If Counter Offer pass in 100,
          {
            this.SelectionRadiobt = 100;

            this.newOffer = true;

            this.selectedValueDecisionRadioBT = 'Counter Offer';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Counter Offer") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          if (data[0].actionType == 111) //If Only Response Comments = 111
          {
            this.SelectionRadiobt = 111;

            this.ResponseComment = data[0].respondedComments;
            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Response Only';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Response Only") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          if (data[0].actionType == 999) //and If Accepted/rejeced pass in 999--- ELSE Zero
          {
            this.SelectionRadiobt = 999;
            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Decision';



            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Decision") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

            if (data[0].isAccepted == 1) {
              this.Isaccepted_Checked = 'Accept';
              this.Isaccept = 1;
              this.AccpetComment = data[0].acceptComments;
            }
            if (data[0].isAccepted == 99) {
              this.Isaccepted_Checked = 'Decline';
              this.Isaccept = 99;
              this.RejectReason = data[0].reasonId;
              this.AccpetComment = data[0].acceptComments;
            }
          }

        },
        error => {
        });
  }



  GeneratePiChart() {
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = ['New', 'Used'];
    this.pieChartData = [this.NewUnits, this.UsedUnits];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
  }
  GenerateBuildPiChart() {
    this.pieChartOptionsbuild = this.createOptions();
    this.pieChartLabelsbuild = ['Build', 'Maximized'];
    this.pieChartDatabuild = [this.V3BuildCount, this.V3MaximizedCount];
    this.pieChartTypebuild = 'pie';
    this.pieChartLegendbuild = true;
  }

  GenerateAttentionPiChart() {
    this.pieChartOptionsAttention = this.createOptions();
    this.pieChartLabelsAttention = ['Flagged', 'No CBB'];
    this.pieChartDataAttention = [this.Flagged, this.Nocbb];
    this.pieChartTypeAttention = 'pie';
    this.pieChartLegendAttention = true;
  }

  GenerateInvestPiChart() {
    this.pieChartOptionsInvest = this.createOptions();
    this.pieChartLabelsInvest = ['Investment', 'Cost'];
    this.pieChartDataInvest = [this.TotalInvest, this.Totalcost];
    this.pieChartTypeInvest = 'pie';
    this.pieChartLegendInvest = true;
  }


  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  ReasonList = [];
  sortAttention(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  Createform() {
    this.clientDbForm = new FormGroup({
      DealerNameFilter: this.fb.control('', Validators.required),
      CurrentDate: this.fb.control('', Validators.required),
      LoggedUserName: this.fb.control('', Validators.required),
      ResponseNotes: this.fb.control('', Validators.required),


      // controls for Offer
      OfferRequest: this.fb.control(''),
      OfferRequestCommment: this.fb.control(''),

      COunterOffer: this.fb.control(''),
      COunterComment: this.fb.control(''),
      AccpetComment: this.fb.control(''),
      RejectReason: this.fb.control(''),
      ResponseComment: this.fb.control(''),

    });
  }

  PostOfferRequestRequest_old() {

    var modelGroup = {};
    if (this.newOffer != true) {
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.SelectedVIN,
        "OfferAmount": this.OfferAmountng,
        "OfferComment": this.OfferCommentng,
        "PrimeId": "0",
        "ActionType": "1",
        "ActionByDealerID": this.DealerIdvalue,
        "ActionBy": this.UseridValue,
        "ActionAmount": this.OfferAmountng,
        "ActionComments": this.OfferCommentng,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": "0",
        "AcceptComments": "",
        "ReasonId": this.RejectReason,
        "ReasonComments": "",
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue,

      }
    }
    else {

      if (this.RadioValue == "Decision") {
        //this.Isaccept = "1";
        this.ActionTypeValue = "999";
      } else if (this.RadioValue == "Counter Offer") {
        this.Isaccept = "0";
        this.ActionTypeValue = "100";
      } else if (this.RadioValue == "Response Only") {
        // this.Isaccept = "99";
        this.ActionTypeValue = "111";
      }
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.SelectedVIN,
        "OfferAmount": this.COunterOffer,
        "OfferComment": this.OfferCommentng,
        "PrimeId": this.PrimeIdUPdate,
        "ActionType": this.ActionTypeValue,
        "ActionByDealerID": this.DealerIdvalue,
        "ActionBy": this.UseridValue,
        "ActionAmount": this.COunterOffer,
        "ActionComments": this.COunterComment,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": this.Isaccept,
        "AcceptComments": this.AccpetComment,
        "ReasonId": this.RejectReason,
        "ReasonComments": this.ResponseComment,
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue
      }
    }

    if (this.newOffer != true) {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
        .subscribe(
          (data: any) => {

            if (data.status == "1") {
              //    this.VinValue =data.vin;
              this.showNotification("success", "Offer saved.");

              this.CLoseOffer();
              this.GetClientDashboardList();
              this.GetClientDashboardPictureViewedList();
              this.GetClientDashboardVerifyViewedList();

              this.GetClientDashboardAttention();
              this.GetClientDashboardMaximized();
              this.GetClientDashboardBuild();
              this.modalService.dismissAll();

              this.IsacceptVIsbile = 0;
              // this.decline();
              this.declineClose();
            }
          },
          error => {
          });

    }
    else {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
        .subscribe(
          (data: any) => {


            //    this.VinValue =data.vin;
            this.showNotification("success", "Offer Updated.");

            this.CLoseOffer();
            this.GetClientDashboardList();
            this.GetClientDashboardPictureViewedList();
            this.GetClientDashboardVerifyViewedList();

            this.GetClientDashboardAttention();
            this.GetClientDashboardMaximized();
            this.GetClientDashboardBuild();
            this.modalService.dismissAll();
            this.IsacceptVIsbile = 0;
            //this.decline();
            this.declineClose();

          },
          error => {
          });

    }
  }


  //post offer method start

  //This method call when offer create from dollar
  PostOfferRequestRequest() {

    var modelGroup = {};
    // if(this.isaithropcode==2) // Flagged
    // {
    //   this.OpcodeForOffer=2;
    //   this.OfferType=0;
    // }
    // if(this.isaithropcode==5) // OnClearance
    // {
    //   this.OpcodeForOffer=1;
    //   this.OfferType=1;
    // }

    //this.OpcodeForOffer=1;
    this.OfferType = 1;

    if (this.SelectionRadiobt == 111) {

      this.OfferCommentng = this.ResponseComment;
    }
    if (this.SelectionRadiobt == 100) {

      this.OfferCommentng = this.COunterComment;
      this.OfferAmountng = this.COunterOffer;
    }
    if (this.newOffer != true)   //When new offer create
    {
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "OfferType": this.OfferType,
        "OfferAmount": this.OfferAmountng,
        "OfferComment": this.OfferCommentng,
        "PrimeId": "0",
        "ActionType": "1",
        "ActionByDealerID": parseInt(this.DealerIdvalue),
        "ActionBy": localStorage.getItem('userid'),
        "ActionAmount": this.OfferAmountng,
        "ActionComments": this.OfferCommentng,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": "0",
        "AcceptComments": "",
        "ReasonId": this.RejectReason,
        "ReasonComments": "",
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue,

      }
    }
    else {    //Existing Offer
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "OfferAmount": this.COunterOffer,
        "OfferComment": this.OfferCommentng,
        "PrimeId": this.primeid,
        "OfferType": this.OfferType,
        "ActionType": this.SelectionRadiobt,
        "ActionByDealerID": parseInt(this.DealerIdvalue),
        "ActionBy": localStorage.getItem('userid'),
        "ActionAmount": this.OfferAmountng,
        "ActionComments": this.OfferCommentng,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": this.Isaccept,
        "AcceptComments": this.AccpetComment,
        "ReasonId": this.RejectReason,
        "ReasonComments": this.AccpetComment,
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue
      }
    }

    if (this.newOffer != true)   //When new offer create
    {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
        .subscribe(
          (data: any) => {

            if (data.status == "1") {
              //    this.VinValue =data.vin;
              this.showNotification("success", "Offer saved.");


              this.GetClientDashboardList();
              this.GetClientDashboardPictureViewedList();
              this.GetClientDashboardVerifyViewedList();

              this.GetClientDashboardAttention();
              this.GetClientDashboardMaximized();
              this.GetClientDashboardBuild();

              //Reinitialize the models start
              this.Isaccept = 1;
              this.COunterComment = "";
              this.COunterOffer = 0;
              this.ResponseComment = "";
              this.selectedValueDecisionRadioBT = "";
              //Reinitialize the models nd

              this.modalService.dismissAll();
              // this.decline();
              this.declineClose();
            }
          },
          error => {
          });

    }
    else {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)  //existing offer
        .subscribe(
          (data: any) => {


            //    this.VinValue =data.vin;
            this.showNotification("success", "Offer Updated.");


            this.GetClientDashboardList();
            this.GetClientDashboardPictureViewedList();
            this.GetClientDashboardVerifyViewedList();

            this.GetClientDashboardAttention();
            this.GetClientDashboardMaximized();
            this.GetClientDashboardBuild();

            this.Isaccept = 1;
            this.selectedValueDecisionRadioBT = "";

            this.modalService.dismissAll();
            //this.decline();
            this.declineClose();

          },
          error => {
          });

    }


  }


  //post offer end






  declineClose() {
    this.confirmModalService.hide(1);
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.GetClientDashboardList();
  }

  GetReasonlist() {
    this.globalcodeService.getReasonList()
      .subscribe(
        (data: any) => {
          this.ReasonList = data;
        },
        error => {
        });
  }
  handlePageSizeChangeAttention(event) {
    this.pageSizeAttention = event.target.value;
    this.pageAttention = 1;
    this.GetClientDashboardAttention();
  }

  handlePageSizeChangeVerify(event) {
    this.pageSizeVerify = event.target.value;
    this.pageVerify = 1;
    this.GetClientDashboardVerifyViewedList();
  }

  handlePageSizeChangePicture(event) {
    this.pageSizePicture = event.target.value;
    this.pagePicture = 1;
    this.GetClientDashboardPictureViewedList();
  }

  handlePageSizeChangeBuild(event) {
    this.pageSizebuild = event.target.value;
    this.pagebuild = 1;
    this.GetClientDashboardBuild();
  }

  handlePageSizeChangeMaximized(event) {
    this.pageSizeMaximized = event.target.value;
    this.pageMaximized = 1;
    this.GetClientDashboardMaximized();
  }

  Getdetails(event: any) {
    var lendervalue = event.target.value;
    this.DealerNameFilter = lendervalue;
    this.opcode = "1";
    this.opcodePicture = "2";
    this.opcodeVerify = "3";

    this.opcodebuild = "4";
    this.opcodeMAximized = "5";
    this.opcodeAttention = "6";

    this.GetAIXAithrhubClientDB();
    this.GetClientDashboardList();
    this.GetClientDashboardPictureViewedList();
    this.GetClientDashboardVerifyViewedList();

    this.GetClientDashboardAttention();
    this.GetClientDashboardMaximized();
    this.GetClientDashboardBuild();



  }

  GetClientDashboardVerifyViewedList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodeVerify,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerVerifylist = data;
          this.TotalRecordVerify = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetClientDashboardAttention() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodeAttention,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerAttention = data;
          this.TotalRecordAttention = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetClientDashboardMaximized() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodeMAximized,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerMaximized = data;
          this.TotalRecordmaximized = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }


  GetClientDashboardBuild() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodebuild,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerBuildlist = data;
          this.TotalRecordBuild = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }


  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }
  decline(): void {
    this.modalRef.hide();
  }
  OpenModel(template: TemplateRef<any>, vin: any, btn: any) {

    this.VINValue = vin;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  SaveReponseDetail() {

    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName": this.LoggedUserName,
      "Notes": this.ResponseNotes,
      "VIN": this.VINValue.toString(),
      "DealerId": this.DealerIdvalue.toString(),

    }

    this.manageInventoryService.sendDetailClientDashbaord(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes = "";
          this.showNotification("success", "Response send successfully");
          this.decline();
        },
        error => {
        });


  }

  GetClientDashboardList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetClientDashboardPictureViewedList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodePicture,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getClientDashboardListDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerPicturelist = data;
          this.TotalRecordPicture = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetAIXAithrhubClientDB() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getAIXAithrhubClientDBDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.TotalInvest = parseInt(data[0].v1);
          this.TotalInventory = data[0].v2;
          this.TopResult = data[0].v3;

          this.V3BuildCount = data[0].v3Build;
          this.V3MaximizedCount = data[0].v3Maximized;

          this.UnitsWithoutCost = data[0].v4;


          this.V4UnitattntionCount = data[0].v4;
          this.Flagged = data[0].flaggedUnit;
          this.Nocbb = data[0].noCbbCount;


          this.Totalcost = data[0].v1Cost;

          this.UsedUnits = data[0].v2Used;
          this.NewUnits = data[0].v2New;

          this.GeneratePiChart();
          this.GenerateBuildPiChart();
          this.GenerateAttentionPiChart();
          this.GenerateInvestPiChart()
          this.BindInvestmentChart();

        },
        error => {
        });
  }

  GetDealerNameFilterlist() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getDealerNameClientDB(modelGroup)
      .subscribe(
        (data: any) => {

          this.DealerList = data;
        },
        error => {
        });
  }
}
