import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree ,Router} from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
   // private authService: AuthService,
    private router: Router)
    {

     }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

// var isAuthenticated = this.authService.getAuthStatus();
//         if (!isAuthenticated) {
//             this.router.navigate(['/login']);
//         }
//         return isAuthenticated;

var loggeduserid= localStorage.getItem('userid');

if(loggeduserid==null)
{
  this.router.navigate(['/login']);
}
    return true;
  }

}
