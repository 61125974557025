<section id="admindashboard">
    <!-- <form class="col-12 px-0" [formGroup]="managerInventoryForm"> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto mr-auto">
                                <div class="row mx-0" *ngIf="hideDealerControlsButton ==='true'">
                                    <div class="form-group mb-1 col-auto">
                                        <div class="row">
                                            <label class="col-form-label"><b>Group Name:</b></label>
                                            <div class="col-auto px-2">
                                                <label class="col-form-label">{{GroupNamevalue}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-1 col-auto">
                                        <div class="row">
                                            <label class="col-form-label col-auto col-lg-auto"><b>Dealer Name:</b></label>
                                            <div class="col-md-7">
                                                <select class="form-control" name="region" (change)="GetDealerValue($event)" [(ngModel)]="DealerNameFilter">
                                                    <option value="0" selected>Select Dealer Name </option>
                                                    <option *ngFor="let DealerListdata of DealerList" [value]="DealerListdata.id">
                                                        {{DealerListdata.dealerName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row" *ngIf="hideSupControlsButton ==='true'" >
                                    <div class="form-group col-auto mb-1">
                                        <div class="row">
                                            <label class="col-form-label col-auto"><b>Dealer Group</b></label>
                                            <div class="col-auto">
                                                <div class="input-group" style="min-width:190px;max-width:190px;">
                                                    <ng-select style="width:100%;" [items]="GroupLists" bindLabel="globalCodeName" [disabled]="groupDisable"
                                                        (change)="GetDealerNamebyGroupId(selectedGroupIds)" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Select group" appendTo="body" [multiple]="false" [(ngModel)]="selectedGroupIds">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col mb-1" id="heightAuto">
                                        <div class="row">
                                            <!-- <label class="col-form-label col-auto"><b>Select Dealers</b></label>
                                            <div class="col-auto">
                                                <div class="input-group dealername">
                                                    <ng-select [items]="dealerlistName" bindLabel="dealerName" id="dealerName"
                                                        (change)="getValues(selectedDealerIds)" [ngModelOptions]="{standalone: true}"
                                                        placeholder="Select Dealer Name" appendTo="body" [multiple]="true" [(ngModel)]="selectedDealerIds">
                                                    </ng-select>
                                                </div>
                                            </div> -->

                                            <label class="col-form-label col-auto">Select Dealers</label>
                                            <div class="input-group dealername col" style="max-width:500px;">
                                                <ng-select style="width:100%;" [items]="dealerlistName" id="dealerName" [dropdownPosition]="true"
                                                    bindLabel="dealerName" [ngModelOptions]="{standalone: true}" placeholder="Select dealer Name"
                                                    appendTo="body" (change)="ClearDealer($event)" (click)="CheckClick()" [multiple]="true"
                                                    [(ngModel)]="dealersmultiselectarray">
                                                </ng-select>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-auto px-md-0 ml-2">
                                        <button  id="resetbtn" (click)="GethubUnitList()" class="btn bg-warning btn-md mb-sm-0 rounded-pill">Get Units</button>
                                    </div>
                                </div>

                        </div>
                    </div>
                </div>
                <div id="hubunits" class="col-md-12">
                    <div class="card card-raised h-100 mb-0">
                        <div class="card-body" style="padding:10px;">
                            <div class="row">
                                <div class="col-xl-5 col-lg-12 col-12 pagioutside">
                                    <div class="card card-raised mb-0">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-lg-auto">
                                                    <h6 class="card-title text-white text-uppercase mb-0">Available Units</h6>
                                                </div>
                                                <div class="col-xl-auto col-lg-12 text-xl-right">
                                                    <span>Total Records : {{ totalNotclearRecord}}</span>
                                                    <div class="form-group mb-0 ml-2 d-inline-block">
                                                        <label class="mb-0" style="padding:2px;"> Items per Page:</label>
                                                        <select (change)="handleRemovedPageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                                {{ size }}
                                                            </option>
                                                        </select>
                                                      </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="w-100">
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th> <label>


                                                                  </label></th>
                                                                  <th (click)="sortRemovedHub('daysInStock')">Age<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('stockNumber')">Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('model')">Model<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('make')">Make<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('carYr')">Year<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let removeunitsListdata of removeunitsList | filter:searchRemovedText | slice: (pageRemoved-1) * pageSizeRemoved : (pageRemoved-1) * pageSizeRemoved + pageSizeRemoved; let i = index; let odd = odd">
                                                                <td><input type="radio" name="Notclearance" (click)="GetselectedNotclearance(removeunitsListdata)" ></td>
                                                                <td>{{removeunitsListdata.daysInStock}} </td>
                                                                <td>{{removeunitsListdata.vin}} </td>
                                                                <td>{{removeunitsListdata.stockNumber}} </td>
                                                                <td>{{removeunitsListdata.model}} </td>
                                                                <td>{{removeunitsListdata.make}} </td>
                                                                <td>{{removeunitsListdata.carYr}} </td>
                                                                <td>{{removeunitsListdata.trim}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>
                                                    <!-- <div class="row justify-content-between align-items-center">
                                                        <div class="col-12 col-sm-auto">
                                                          <ngb-pagination [collectionSize]="removeunitsList.length" [(page)]="pageRemoved" [maxSize]="5" [rotate]="true"
                                                          [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                                        </div>
                                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                                        </div>

                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-12 col-sm-auto">
                                              <ngb-pagination [collectionSize]="removeunitsList.length" [(page)]="pageRemoved" [maxSize]="5" [rotate]="true"
                                              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                            </div>
                                            <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="hubunitsmove" class="col-xl-2 col-lg-12 col-auto my-3 px-xl-1">
                                    <div class="row align-items-center justify-content-between text-center">
                                        <div class="col-12">
                                            <button type="button" class="btn btn-md btn-primary rounded-pill" (click)="openModalpopup_Movetoclearnce(OnclearancePopup)">
                                                Move To Clearance <i class="fas fa-angle-double-right"></i></button>

                                            <button type="button" class="btn btn-md btn-info mt-xl-2 ml-2 ml-xl-0 rounded-pill" (click)="openModalpopup_Removetoclearnce(OnRemovePopup)"
                                            >
                                                <i class="fas fa-angle-double-left"></i> Remove From Clearance </button>
                                        </div>
                                        <div class="w-100 mt-3">
                                            <div id="inventorycanvas">
                                            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                             [chartType]="pieChartType"  [colors]="colors" [options]="pieChartOptions"
                                                [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                            </canvas>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-5 col-lg-12 col-12 pagioutside">
                                    <div class="card card-raised mb-0">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-lg-auto">
                                                    <h6 class="card-title text-white text-uppercase mb-0">On Clearance</h6>
                                                </div>
                                                <div class="col-xl-auto col-lg-12 text-xl-right">
                                                    <span>Total Records : {{totalClearRecord}}</span>
                                                    <div class="form-group mb-0 ml-2 d-inline-block">
                                                        <label class="mb-0"  style="padding:2px;"> Items per Page:</label>
                                                        <select (change)="handlePageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                                {{ size }}
                                                            </option>
                                                        </select>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="w-100">
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th>

                                                                </th>
                                                                  <th (click)="sortAithrHub('daysInStock')">Age<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('stockNumber')">Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('model')">Model<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('make')">Make<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('carYr')">Year<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                                <th style="text-align: right;" (click)="sortAithrHub('discountAmount')">Discount Amount<i class="fa fa-fw fa-sort"></i></th>

                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let unitsListdata of unitsList| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">

                                                                <td><input type="radio" name="clearancedata" (change)="RemovefromClearanc(unitsListdata)"></td>
                                                                <td>{{unitsListdata.daysInStock}} </td>
                                                                <td>{{unitsListdata.vin}} </td>
                                                                <td>{{unitsListdata.stockNumber}} </td>
                                                                <td>{{unitsListdata.model}} </td>
                                                                <td>{{unitsListdata.make}} </td>
                                                                <td>{{unitsListdata.carYr}} </td>
                                                                <td>{{unitsListdata.trim}} </td>
                                                                <td style="text-align: right;" >{{unitsListdata.discountAmount | currency}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-12 col-sm-auto">
                                              <ngb-pagination [collectionSize]="unitsList.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                                              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                            </div>
                                            <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                      </ngx-spinner>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


        <ng-template #dealercheckbox>
            <div id="pdf-viewer-outer" class="modal-body">
              <h5>Dealer List </h5>
              <hr />
              <div class="row mt-2" id="dealerchckbox">

        <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->

        <div class="col-12 listoption">
          <input type="checkbox" name="chckboxall"
          [(ngModel)]="SelectedAllCheck"  (click)="Checkalldealer($event)" id="checkall"/>
          <label style="font-size:13px;" class="ml-1"><h6 class="mb-0" style="font-size:14px;"></h6>Select All</label>
        </div> <br/>

                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
                  <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected"
                    /> {{item.dealerName}}
                </div>

              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="declineDealer()">Back</button>
              <button type="button" class="btn btn-success btn-sm mr-1 rounded-pill" (click)="GetselectedDealer()">Done</button>
            </div>
          </ng-template>



          <ng-template #OnclearancePopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Details</h6>
                <button type="button" class="close" style="color: white;"  (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="pdf-viewer-outer" class="modal-body vehicleInfoblock">
                <section id="dealerchckbox" class="generate-report">
                    <div class="w-100">
                        <div class="col-md-12 px-0 listoption">
                            <h6>Vehicle Section</h6>
                        </div>
                        <div class="row mx-0">
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>VIN:</b></label>
                                <span>{{VINng}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label>Make:</label>
                                <span>{{Makeng}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Model:</b></label>
                                <span>{{Modelng}}</span>
                            </div>
                        </div>
                        <div class="row mx-0 mt-1">
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Stocknumber:</b></label>
                                <span>{{Stockng}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Price:</b></label>
                                <span>{{Priceng | currency}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Trim:</b></label>
                                <span>{{Trimng}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mt-2">
                        <div class="col-md-12 px-0 listoption">
                            <h6>Discounted Section</h6>
                        </div>
                            <div class="row mx-0 mt-2">
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted Amount:</b></label>
                                    <input type="text" [(ngModel)]="DiscountAmountNg" class="form-control">
                                </div>
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted From:</b></label>
                                    <input type="date" [(ngModel)]="discountfromNg" class="form-control">
                                </div>
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted To:</b></label>
                                    <input type="date" [(ngModel)]="discountToNg" class="form-control">
                                </div>
                            </div>
                        </div>
                </section>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm" (click)="decline()">Back</button>
              <button type="button" class="btn btn-success btn-sm mr-1"  (click)="Movetoclearance()">Save</button>
            </div>
          </ng-template>


          <ng-template #OnRemovePopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Details</h6>
                <button type="button" class="close" style="color: white;"  (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="pdf-viewer-outer" class="modal-body vehicleInfoblock">
                <section id="dealerchckbox" class="generate-report">
                    <div class="w-100">
                        <div class="col-md-12 px-0 listoption">
                            <h6>Vehicle Section</h6>
                        </div>
                        <div class="row mx-0">
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>VIN:</b></label>
                                <span>{{VINngRemove}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label>Make:</label>
                                <span>{{MakengRemove}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Model:</b></label>
                                <span>{{ModelngRemove}}</span>
                            </div>
                        </div>
                        <div class="row mx-0 mt-1">
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Stocknumber:</b></label>
                                <span>{{StockngRemove}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Price:</b></label>
                                <span>{{PricengRemove | currency}}</span>
                            </div>
                            <div class="form-group mb-1 col-xl-4 col-lg-12">
                                <label><b>Trim:</b></label>
                                <span>{{TrimngRemove}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="w-100 mt-2">
                        <div class="col-md-12 px-0 listoption">
                            <h6>Discounted Section</h6>
                        </div>
                            <div class="row mx-0 mt-2">
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted Amount:</b></label>
                                    <span>{{DiscountAmountRemove | currency}}</span>

                                </div>
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted From:</b></label>
                                    <span>{{discountfromRemove | date: 'dd/MM/yyyy'}}</span>

                                </div>
                                <div class="form-group mb-1 col-xl-4 col-lg-12">
                                    <label><b>Discounted To:</b></label>
                                    <span>{{discountToRemove | date: 'dd/MM/yyyy'}}</span>

                                </div>
                            </div>
                        </div>
                </section>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm" (click)="decline()">Back</button>
              <button type="button" class="btn btn-success btn-sm mr-1"  (click)="RemoveFromclearance()">Remove</button>
            </div>
          </ng-template>


</section>