<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="ApplicationForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="copytable('usertable')">Copy</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="exportexcel()">Excel</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center mb-1 mx-0">
                                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3">
                                    <label style="padding:2px;"> Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">List of Creditors
                                                Tiers</h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('creditorName')">Creditor Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('tierName')"> Tier Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('intRateFrom')"> Interest Rate From <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th (click)="sort('intRateTo')">Interest Rate To<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('creditorFee')" style="text-align:right;">Creditor Fee <i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('dealerReserve')" style="text-align:right;" >Dealer Reserve <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('maxAdvanceLTV')" style="text-align:right;">Max Advance LTV <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('maxAftermarketLTV')" style="text-align:right;">Max Aftermarket LTV <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('maxPayment')" style="text-align:right;">Max Payment <i
                                                            class="fa fa-fw fa-sort"></i> </th>


                                                    <th > Action</th>



                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let dealerVehicleslistdata of creditorslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                    <td>{{ dealerVehicleslistdata.creditorName }}</td>
                                                    <td>{{ dealerVehicleslistdata.tierName }}</td>
                                                    <td>{{ dealerVehicleslistdata.intRateFrom }}%</td>
                                                    <td> {{ dealerVehicleslistdata.intRateTo}}%</td>
                                                    <td style="text-align:right;"> {{ dealerVehicleslistdata.creditorFee | currency: 'USD' }}</td>
                                                    <td style="text-align:right;"> {{ dealerVehicleslistdata.dealerReserve | currency: 'USD' }}
                                                    </td>
                                                    <td style="text-align:right;"> {{ dealerVehicleslistdata.maxAdvanceLTV | currency: 'USD' }}
                                                    </td>
                                                    <td style="text-align:right;"> {{ dealerVehicleslistdata.maxAftermarketLTV | currency: 'USD'
                                                        }}
                                                    </td>
                                                    <td style="text-align:right;"> {{ dealerVehicleslistdata.maxPayment | currency: 'USD' }}</td>


                                                    <td style="text-align:center;">
                                                        <button class="btn bg-gradient-info btn-sm" title="Edit"
                                                        (click)="openEditModel(ModelChangeStatus,dealerVehicleslistdata.tierID,dealerVehicleslistdata.aithrXID,dealerVehicleslistdata)">
                                                        <i class="fas fa-edit"></i></button> </td>



                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>

                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <div class="col-auto px-0">
                                                    <ngb-pagination [collectionSize]="creditorslist.length"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        [boundaryLinks]="true"></ngb-pagination>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <a #ConfirmBox style="display: none;" [routerLink]=""
                                            (click)="openModal(template)"><i class="fa fa-upload"
                                                triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                                                placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
                                        <ng-template #template>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to delete?
                                                </p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirm()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <ng-template #ModelChangeStatus let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Status</h5>
                <button type="button" class="close" style="color: white;" #closebutton
                    (click)="RefreshImageList();CloseAddmodel();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report imageSize row">
                    <div class="col-12">
                        <div class="form-group mb-2">
                            <label>Tier Name</label>
                            <select  (change)="TierChangeSelect($event)"  class="form-control" name="region" [disabled]="true" formControlName="TierNameFilter"
                                [(ngModel)]="TierNameFilter">
                                <option value="0" selected>Select Plan type </option>
                                <option *ngFor="let DealerListdata of DealerList" [ngValue]="DealerListdata">
                                    {{DealerListdata.planType}}</option>
                            </select>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">

                <button class="btn btn-primary btn-sm text-white rounded-pill" (click)="UpdateTierStatus();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                </button>
                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="RefreshImageList();CloseAddmodel();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

    </form>
</section>