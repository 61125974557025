import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

import { GlobalcodeService } from '@app/_services/globalcode.service';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
@Component({
  selector: 'aithrhub-buylogs',
  templateUrl: './aithrhub-buylogs.component.html',
  styleUrls: ['./aithrhub-buylogs.component.less']
})
export class AithrhubBuylogsComponent implements OnInit {
  AddAithrhublogForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    private accountService:AccountService
    )
    {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
    TotalRecord:any;
  dealerVehicleslist: DealerVehicles[] = [];
  dealerVehiclesboughtlist: DealerVehicles[] = [];
  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  pagebought = 1;
  pageSizebought = 10;
  modalRef: BsModalRef;
  updateVIN:any;
  modalRefcheck: BsModalRef;
  isValid: boolean = true;
  loading = false;
  submitted = false;
  dateRecevied=new Date();
  deleteVINNumber:any;

  extraCleanValue:any;

  cleanValue:any;
  averageValue:any;
  roughvalue:any;
 VinNumber:any;
MileageValue:any;

bodyvalue:any;
trimvalue:any;
modelvalue:any;
makevalue:any;
yearvalue:any;
modalcloseOpen: any;
errorshow: any;
errorMessage: string;

// Controls for ng models
 textLabel:any;
  mileageControl:any;
  stockNumber:any;
  vinNumber:any;
  carYr:any;
  make:any;
  model:any;
  trim:any;
  style:any;
  body:any;
  conditon:any;
  datereceived= { year: 1789, month: 7, day: 14 };
  listPrice:any;
  cost:any;
  extraClean:any;
  clean:any;
  average:any;
  rough:any;
// end of ng models


  conditionslist=[];
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  
  vehicleObject = [];
  rolevalue:any;
  dealerNamevalue:any;
  VinSetAsSold:any;
  GroupNamevalue:any;
  DealerIdvalue:any;
  // function for column sorting
  sort(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;

  sortBought(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehiclesboughtlist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchTextBought;
  ListofPendingUnitsData= 'ListofPendingUnits.xlsx';
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.spinner.show();

    this.GetVehicleCondtionsList();
     this.updateVIN="0";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
      this.GetAIXDealerVehiclesLogList();
      this.GetAIXDealerListOfBoughtUnits();
    } else {
      this.GetAIXDealerVehiclesLogList();
      this.GetAIXDealerListOfBoughtUnits();
    }

  }
  get f() { return this.AddAithrhublogForm.controls; }


  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }

  markAsSold(VIN: any) {
    this.VinSetAsSold = VIN;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }
  decline(): void {
    this.modalRef.hide();
  }
  confirmSold(): void {
    this.modalRef.hide();
    this.SetAsSold(this.VinSetAsSold)
  }

  SetAsSold(vin: any) {
    debugger;
    this.manageInventoryService.setAsSold(this.VinSetAsSold)
      .subscribe(
        (data: any) => {
         this.showNotification("success", "Mark as sold successfully");
         this.GetAIXDealerVehiclesLogList();       
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }

  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg' ,backdrop: 'static', keyboard: false });
  }

  ConvertserverdatetoDatetime(datadate) {
    debugger;
    var momentDate = moment(datadate);

    var dateactive = new Date(datadate);
    var localstringdate = dateactive.toLocaleDateString();
    return {
      year: dateactive.getFullYear(),
      month: dateactive.getMonth() + 1,
      day: dateactive.getDate()
    }


  }
  CloseAddmodel() {
    this.modalRef.hide();
    this.textLabel="";
  }

  RefreshImageList() {
    this.vehicleObject = [];
  }
  copytable(el) {
    var urlField = document.getElementById(el) ;  
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range) ;
    document.execCommand('copy');
    this.showNotification("success","Data copy to clipboard successfully")
}
  
exportToCSV(): void {
  const options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalSeparator: '.',
    showLabels: true,
    showTitle: false,
    useTextFile: false,
    useBom: true,
    useKeysAsHeaders: true,
  };
  const csvExporter = new ExportToCsv(options);

  csvExporter.generateCsv(this.dealerVehicleslist);
}

printTable() {
  var prepare = [];
  this.dealerVehicleslist.forEach(e => {
    var tempObj = [];
    tempObj.push(e.vin);
    tempObj.push(e.stockNumber);
    tempObj.push(e.carYr);
    tempObj.push(e.mileage);
    tempObj.push(e.make);
    tempObj.push(e.model);
    tempObj.push(e.trim);
    tempObj.push(e.body);
    tempObj.push(e.listedPrice);
    tempObj.push(e.dealerCost);
    prepare.push(tempObj);
  });

  var table = '<table><thead><tr><th>VIN</th><th>StockNumber</th><th>Year</th><th>Mileage</th><th>Make</th><th>Model</th><th>Trim</th><th>Body</th><th>ListedPrice</th><th>DealerCost</th></tr></thead><tbody>';
  prepare.forEach(row => {
    table += '<tr>';
    row.forEach(col => {
      table += '<td>' + col + '</td>';
    });
    table += '</tr>';
  });
  table += '</tbody></table>';

  // Create a new window and write only the table HTML to it
  var newWin = window.open('');
  newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
  newWin.document.close();

  // Wait for the table to be fully rendered before printing
  newWin.onload = function() {
    newWin.focus();
    newWin.print();
    newWin.close();
  };
} 

downloadPdf() {
  var prepare=[];
  this.dealerVehicleslist.forEach(e=>{
    var tempObj =[];
    tempObj.push(e.vin);
    tempObj.push(e.stockNumber);
    tempObj.push( e.carYr);
    tempObj.push( e.mileage);
    tempObj.push( e.make);
    tempObj.push(e.model);
    tempObj.push(e.trim);
    tempObj.push(e.body);
    tempObj.push(e.listedPrice);
    tempObj.push(e.dealerCost);
    prepare.push(tempObj);
  });
  const doc = new jsPDF('l','pt', 'a4');
  autoTable(doc, {
    head: [['VIN','StockNumber','Year','Mileage','Make','Model','Trim','Body','ListedPrice','DealerCost']],
    body: prepare,
    didDrawCell: (data) => { },
});
  doc.save('ListofPendingUnits' + '.pdf');
}

exportexcel() {
  const prepare = [];
  this.dealerVehicleslist.forEach(e => {
    const tempObj = [];
    tempObj.push(e.vin);
    tempObj.push(e.stockNumber);
    tempObj.push( e.carYr);
    tempObj.push( e.mileage);
    tempObj.push( e.make);
    tempObj.push(e.model);
    tempObj.push(e.trim);
    tempObj.push(e.body);
    tempObj.push(e.listedPrice);
    tempObj.push(e.dealerCost);
    prepare.push(tempObj);
  });

  / Convert data to worksheet /
  const ws = XLSX.utils.aoa_to_sheet([
    ['VIN','StockNumber','Year','Mileage','Make','Model','Trim','Body','ListedPrice','DealerCost'],
    ...prepare
  ]);

  / Create a new workbook and add the worksheet /
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'ListofPendingUnits');

  / Save the workbook to a file /
  XLSX.writeFile(wb, 'ListofPendingUnits.xlsx');
}


  onDateSelectactiveon(event) {
    debugger;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.dateRecevied = new Date(finalDate);
   }

  Createform() {
    this.AddAithrhublogForm = new FormGroup({
      
    });
  }
  openAddInventoryModel(targetModal) {
    this.textLabel="Add";
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-xl modal-dialog-centered',  backdrop: 'static',
    keyboard: false });
  }

 
  GetVehicleCondtionsList() {
    this.globalcodeService.getVehicleCondtionsList()
      .subscribe(
        (data: any) => {
          debugger;
          this.conditionslist = data;
        },
        error => {
        });
  }


  onDelete(UserId: any) {
    this.deleteVINNumber = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }
  public currentDate() {
    var todayDate = new Date();
    return {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate()
    }
  }

 
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }


  // function for getting user list
  GetAIXDealerVehiclesLogList() {
    this.spinner.show();
    this.manageInventoryService.getAIXDealerVehiclesLogList()
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetAIXDealerListOfBoughtUnits() {
    this.spinner.show();
    this.manageInventoryService.getAIXDealerVehiclesLogList()
      .subscribe(
        (data: any) => {
          debugger;
          this.dealerVehiclesboughtlist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }
}
