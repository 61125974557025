import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

import { GlobalcodeService } from '@app/_services/globalcode.service';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { AddgroupService } from '@app/_services/addgroup.service';
@Component({
  selector: 'app-credit-application',
  templateUrl: './credit-application.component.html',
  styleUrls: ['./credit-application.component.less']
})
export class CreditApplicationComponent implements OnInit {
  ApplicationForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    private accountService: AccountService,
    private addgroupService: AddgroupService,
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  @ViewChild('ConfirmMarkSoldBox', { static: false }) ConfirmMarkSoldBoxClick: ElementRef;

  @ViewChild('ConfirmBoxGetdetail', { static: false }) ConfirmBoxGetDetailclick: ElementRef;

  @ViewChild('ConfirmBoxFindcarpopup', { static: false }) ConfirmBoxFindcarpopupclick: ElementRef;

  @ViewChild('ConfirmBoxGetWorkSheetdetail', { static: false }) ConfirmBoxGetWorkSheetdetailclick: ElementRef;

  @ViewChild('ConfirmBoxSetWorkSheetdetail', { static: false }) ConfirmBoxSetWorkSheetdetailclick: ElementRef;
  dealerNametextbox:any;
  applicationId:any;
  applicationIdWorkSheet :any;
  creditorId :any;
  ListofPendingUnitsData = 'ListofUnits.xlsx';
  TotalRecord: any;
  creditorslist = [];
  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  modalRef: BsModalRef;
  updateVIN: any;
  modalRefcheck: BsModalRef;
  isValid: boolean = true;
  opcode: any;
  DealerList = [];

  MarkVIN: any;
  TypeClick: any;
  Markid: any;
  MarkDealerId: any;
  MarkStockNo: any;
  MarkYear: any;
  MarkMilegge: any;
  MarkMake: any;
  MarkModel: any;
  Marktrim: any;
  Markbody: any;
  Markprice: any;
  markValueTitle: any;
  loading = false;
  submitted = false;
  dateRecevied = new Date();
  deleteVINNumber: any;

  filepicture = [];

  extraCleanValue: any;
  fuelType: any;
  Color: any;
  OpCode: any;
  fuellist = [];
  cleanValue: any;
  averageValue: any;
  roughvalue: any;
  VinNumber: any;
  MileageValue: any;

  bodyvalue: any;
  trimvalue: any;
  modelvalue: any;
  makevalue: any;
  yearvalue: any;
  modalcloseOpen: any;
  errorshow: any;
  errorMessage: string;

  // Controls for ng models
  textLabel: any;
  mileageControl: any;
  stockNumber: any;
  vinNumber: any;
  carYr: any;
  make: any;
  model: any;
  trim: any;
  style: any;
  body: any;
  conditon: any;
  datereceived = { year: 1789, month: 7, day: 14 };
  listPrice: any;
  cost: any;
  extraClean: any;
  clean: any;
  average: any;
  rough: any;
  TradeValueInput:any=0;
  LienInput:any=0;

  FindCarSelectedValueItems:any
  // end of ng models

  DealershipName: any;
  requester: any;
  phone: any;
  Subject: any;
  Description: any;

  conditionslist = [];
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  @ViewChild('ConfirmBoxVINCheck', { static: false }) ConfirmBoxVINCheckClick: ElementRef;
  vehicleObject = [];
 customimagesrc = [];
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  UserIDValue: any;

  apiDetailValue:any;
  selectedList:any;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  // function for column sorting
  sort(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.creditorslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  DealerNameFilter: any;
  showDealerList: any;
  ColorTheme:any;
  dealerlistForsearch = [];
  applicationIdSetWorkSheet:any;
    setcreditorId:any;
    SelectSearchDealer:any;
    SelectSearchDealervalue:any;
    SelectSearchgroupvalue:any;
    OpcodeValue:any;
    hideSupControlsButton:any;
    OpcodeValueforPageLoad:any;
  ngOnInit(): void {
    this.Createform();
    this.spinner.show();
    this.GetallDealerList();
    this.SelectSearchDealervalue=0;
    this.OpcodeValueforPageLoad="1";
     this.apiDetailValue="false";
     this.hideSupControlsButton="false";
    this.GetVehicleCondtionsList();
    this.GetFuellist();
    this.updateVIN = "0";
    this.Color = "";
    this.opcode = "0";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue = localStorage.getItem('userid');
    this.DealerNameFilter = this.DealerIdvalue;
    this.DealershipName = localStorage.getItem('DealerName');
    this.ColorTheme = localStorage.getItem('colorTheme');

    this.Subject = "Credit App"
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    //this.GetAllInventoryListDealerName();
    if (this.rolevalue == "SuperAdmin") {
      this.hideSupControlsButton="false";
      this.GetAllCreditorApplication();
    } else
    {
      this.SelectSearchDealervalue= this.DealerIdvalue;
      this.hideSupControlsButton="true";
      this.OpcodeValueforPageLoad="2";
      this.dealerNametextbox= this.dealerNamevalue;
      this.GetAllCreditorApplication();
    }
   


  }
  get f() { return this.ApplicationForm.controls; }

  SerachDealer(event) {
    this.SelectSearchDealer = event;
    this.SelectSearchDealervalue = event.id;
     var serachdelaerid = this.SelectSearchDealer;
 
     
     this.SelectSearchgroupvalue=event.dGroupId;
     this.OpcodeValueforPageLoad ="2";
     this.GetAllCreditorApplication();
    // this.GetAllInventoryList();
 
   }
  SendQuery() {
    this.submitted = true;
    var modelGroup = {
      "DealershipName": this.ApplicationForm.controls.DealershipName.value,
      "Phone": this.ApplicationForm.controls.phone.value,
      "Subject": this.ApplicationForm.controls.Subject.value,
      "Description": this.ApplicationForm.controls.Description.value,
      "LoggedUserId": this.UserIDValue,
      "RequesterName": this.ApplicationForm.controls.requester.value
    };
    this.manageInventoryService.getSupportTickets(modelGroup)
      .subscribe(
        (data: any) => {

          this.submitted = false;
          this.decline();
          this.showNotification("success", "Ticket submitted successfully.");
         
        },
        error => {
        });
    this.loading = true;
    this.isValid = true;
  }


  GetallDealerList() {

    var modelGroup = {
      "Opcode": "1"
    }
    this.addgroupService.getAlldealerListApplocation(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealerlistForsearch = []
          if (data.length > 0) {
            // this.dealerName = "";

            this.dealerlistForsearch = data
          }
          else {


          }
        }
      )
  }
  openModalSetWorkSheetDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  openModalgetWorkSheetDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  openModalgetDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  openModalFindCar(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md', backdrop: 'static', keyboard: false });
  }





  confirmSetWorkSheetDetail(): void {
    this.modalRef.hide();
    this.SetWorkSheetDetailList();
  }

  confirmGetWorkSheetDetail(): void {
    this.modalRef.hide();
    this.GetWorkSheetDetailList();
  }

  confirmGetAppDetail(): void {
    this.modalRef.hide();
    this.GetAppDetailList();
  }


  SetWorkSheetDetailList() {
    var modelGroup = {
      "applicationId": this.applicationIdSetWorkSheet,
      "creditorId": this.setcreditorId
    }
    this.globalcodeService.setAWorkSheetDetailList(modelGroup)
      .subscribe(
        (data: any) => {

          this.showNotification("success", "Work Sheet Details set successfully");
          this.applicationIdSetWorkSheet="";
          this.setcreditorId="";
        },
        error => {
        });
  }

  GetWorkSheetDetailList() {
    var modelGroup = {
      "applicationId": this.applicationIdWorkSheet,
      "creditorId": this.creditorId
    }
    this.globalcodeService.getAWorkSheetDetailList(modelGroup)
      .subscribe(
        (data: any) => {
          this.applicationIdWorkSheet="";
          this.creditorId="";
          this.showNotification("success", "Work Sheet Details updated successfully");
        },
        error => {
        });
  }

  GetAppDetailList() {
    var modelGroup = {
      "applicationId": this.applicationId,
    }
    this.globalcodeService.getAppDetailList(modelGroup)
      .subscribe(
        (data: any) => {
          this.applicationId="";
          this.showNotification("success", "App Details updated successfully");
        },
        error => {
        });
  }

	OpenModel(template: TemplateRef<any>, vin: any) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  SetAppWorkSheetDetails(items: any) {
    debugger;
   this.applicationIdSetWorkSheet = items.applicationId;
   this.setcreditorId = items.creditorId;
   let el: HTMLElement = this.ConfirmBoxSetWorkSheetdetailclick.nativeElement;
   el.click();
 }

  GetAppWorkSheetDetails(items: any) {
    debugger;
   this.applicationIdWorkSheet = items.applicationId;
   this.creditorId = items.creditorId;
   let el: HTMLElement = this.ConfirmBoxGetWorkSheetdetailclick.nativeElement;
   el.click();
 }

  GetFindCarpopup(items: any) {
     debugger;
     this.FindCarSelectedValueItems=items;
    this.applicationId = items.applicationId;
    let el: HTMLElement = this.ConfirmBoxFindcarpopupclick.nativeElement;
    el.click();
  }

  confirmOpenFindcar()
  {
    debugger;
    var tradevalue=this.TradeValueInput;
    var LienValue=this.LienInput;
    this.Openbuildcar(this.FindCarSelectedValueItems,this.FindCarSelectedValueItems.id)

  }

  GetAppDetails(items: any) {
    debugger;
   this.applicationId = items.applicationId;
   let el: HTMLElement = this.ConfirmBoxGetDetailclick.nativeElement;
   el.click();
 }


  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  // function for getting
  MarkSold() {
    var modelGroup = {
      "UserID": this.UserIDValue,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.UserIDValue,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();

          this.showNotification("success", "Unit marked as SOLD Successfully");
          this.GetAllCreditorApplication();
        },
        error => {
        });
  }


  openModalResponse(template: TemplateRef<any>,item:any) {


    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  //upload pic
  onFileChanged(event) {
    debugger;
    this.filepicture = [];

    var filepicturestart = [];

    if (event.target.files.length > 0) {
      // const file = event.target.files[0];
      const file = event.target.files;
      // this.labelImport.nativeElement.innerText = file.name;


      filepicturestart.push(file);

      var filename = "";
      // this code help to push selected image to server
      filepicturestart.forEach((element, ind) => {
        this.filepicture.push(element);
      });

      //this code binding name
      // this.filepicture[0].FileList.forEach((element,ind) => {

      //   filename=filename+' '+element[ind].name

      //   });
      filename = this.filepicture[0].length + 'File';

      document.getElementById("choosefile").innerHTML = filename;
      this.ApplicationForm.patchValue({
        // picture: this.filepicture,
        picture: file

      });
    }


    //   if (event.target.files && event.target.files[0]) {
    //     var filesAmount = event.target.files.length;
    //     for (let i = 0; i < filesAmount; i++) {
    //             var reader = new FileReader();

    //             reader.onload = (event:any) => {
    //               console.log(event.target.result);
    //                this.filepicture.push(event.target.result);

    //                this.AddInventoryForm.patchValue({
    //                 picture: this.filepicture
    //                });
    //             }

    //             reader.readAsDataURL(event.target.files[i]);
    //     }
    // }

  }

  setradioMarkUnits(value: any, item: any) {
    this.Markid = item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.MarkStockNo = item.stockNumber;

    this.MarkMilegge = item.mileage;

    this.Marktrim = item.trim;
    this.Markbody = item.body;
    this.Markprice = item.listedPrice;

    this.markValueTitle = value;
    this.TypeClick = "Sold";
    let el: HTMLElement = this.ConfirmMarkSoldBoxClick.nativeElement;
    el.click();


  }

  GetAddDealerName() {
    var modelGroup = {
      "Opcode": this.opcode,
    }
    this.manageInventoryService.getAddDealerNamelist(modelGroup)
      .subscribe(
        (data: any) => {
          this.DealerList = data;
        },
        error => {
        });
  }
  ConvertserverdatetoDatetime(datadate) {

    var momentDate = moment(datadate);

    var dateactive = new Date(datadate);
    var localstringdate = dateactive.toLocaleDateString();
    return {
      year: dateactive.getFullYear(),
      month: dateactive.getMonth() + 1,
      day: dateactive.getDate()
    }


  }


  Openbuildcar(data:any,i:any)
  {

    debugger;
    let builparameter = {
      LenderId: data.aithrLenderID,
      aithrLenderKey: data.aithrLenderKey,
      aithrLenderName: data.aithrLenderName,
      ProvinceId: "11",
      aithrPlanTypeID: data.aithrPlanTypeID,
      aithrPlanTypeKey: data.aithrPlanTypeKey,
      aithrPlanTypeName: data.aithrPlanTypeName,
      apIlogID: data.apIlogID,
      applicationId: data.applicationId,
      approvedPayment: data.approvedPayment,
      appstatus: data.appstatus,
      creditorId: data.creditorId,
      currency: data.currency,
      displayName: data.displayName,
      firstPaymentDate: data.firstPaymentDate,
      frequency: data.frequency,
      id: data.id,
      interestRate: data.interestRate,
      isResponded: data.isResponded,
      maxPayment: data.maxPayment,
      payCall: data.payCall,
      programId: data.programId,
      rateReduction: data.rateReduction,
      responseID: data.responseID,
      retailerId: data.retailerId,
      tierId: data.tierId,
      tierName: data.tierName,
      tradevalue:this.TradeValueInput,
      lienvalue:this.LienInput

	  }
   // Put the object into storage
   localStorage.setItem('Findcarparameter' + i, JSON.stringify(builparameter));
   this.TradeValueInput=0;
   this.LienInput=0;
   this.modalRef.hide();
    this.openNewWindow(i);

  }


  openNewWindow(i: any): void {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = '#/find-car-final/' + i;
    link.click();
    link.remove();
  }


  CloseAddmodel() {

    this.modalRef.hide();
    this.ResetNgModels();
    this.Createform();
    this.textLabel = "";
    this.DealerNameFilter = this.DealerIdvalue;
    this.submitted = false;
    this.isValid = true;

    return;
  }

  RefreshImageList() {
    this.vehicleObject = [];
  }

  // function to reset ngmodels
  ResetNgModels() {
    this.extraCleanValue = "";

    this.cleanValue = "";
    this.averageValue = "";
    this.roughvalue = "";
    this.VinNumber = "";
    this.MileageValue = "";

    this.bodyvalue = "";
    this.trimvalue = "";
    this.modelvalue = "";
    this.makevalue = "";
    this.yearvalue = "";

    this.mileageControl = "";
    this.stockNumber = "";
    this.vinNumber = "";
    this.carYr = "";
    this.make = "";
    this.model = "";
    this.trim = "";;
    this.style = "";
    this.body = "";
    this.conditon = "";
    this.datereceived = this.currentDate();
    this.listPrice = "";
    this.cost = "";
    this.extraClean = "";
    this.clean = "";
    this.average = "";
    this.rough = "";
    this.fuelType = "";
    this.Color = "";
    this.updateVIN = 0;
    this.DealerNameFilter = "";
  }

  GetFuellist() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getfueltypelist(modelGroup)
      .subscribe(
        (data: any) => {

          this.fuellist = data;
        },
        error => {
        });
  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.creditorslist);
  }

  printTable() {
    var prepare = [];
    this.creditorslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>VIN</th><th>StockNumber</th><th>Year</th><th>Mileage</th><th>Make</th><th>Model</th><th>Trim</th><th>Body</th><th>ListedPrice</th><th>DealerCost</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdf() {
    var prepare = [];
    this.creditorslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('ListofUnits' + '.pdf');
  }
  // copytable(el) {
  //   var urlField = document.getElementById(el);
  //   var range = document.createRange();
  //   range.selectNode(urlField);
  //   window.getSelection().addRange(range);
  //   document.execCommand('copy');
  //   this.showNotification("success", "Data copy to clipboard successfully")
  // }
  copytable(el) {
    var prepare = [];
    this.creditorslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      prepare.push(tempObj);
    });

    var text = prepare.map(row => row.join('\t')).join('\n');

    navigator.clipboard.writeText(text).then(() => {
      alert('Data copied to clipboard successfully');
    }, (err) => {
      alert('Unable to copy data to clipboard. Please try again.');
    });
  }
  exportexcel() {
    const prepare = [];
    this.creditorslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofUnits');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofUnits.xlsx');
  }
  GetVehiclePhotosListByVIN(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {
          this.customimagesrc=[];
          this.vehicleObject=[];
          if (data.length > 0) {


            debugger;
            this.errorshow = 'false';
            for (var i = 0; i < data.length; i++) {
              if(data[i].imageStatus=="1")
              {
                this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: ''})
              } else{
                this.customimagesrc.push({imgsrc:data[i].imagesrc })
              }


            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }

  openImagesModelPopUp(targetModal, VehicleID) {
    this.GetVehiclePhotosListByVIN(VehicleID);
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  onDateSelectactiveon(event) {

    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.dateRecevied = new Date(finalDate);
  }

  Createform() {
    this.ApplicationForm = new FormGroup({
      mileageControl: this.fb.control('', Validators.required),
      stockNumber: this.fb.control('', Validators.required),
      vinNumber: this.fb.control('', Validators.required),
      carYr: this.fb.control('', Validators.required),
      make: this.fb.control('', Validators.required),
      model: this.fb.control('', Validators.required),
      trim: this.fb.control('', Validators.required),
      style: this.fb.control('', Validators.required),
      body: this.fb.control('', Validators.required),
      conditon: this.fb.control('', Validators.required),
      datereceived: this.fb.control('', Validators.required),
      listPrice: this.fb.control('', Validators.required),
      cost: this.fb.control('', Validators.required),
      extraClean: this.fb.control('', Validators.required),
      clean: this.fb.control('', Validators.required),
      average: this.fb.control('', Validators.required),
      rough: this.fb.control('', Validators.required),
      fuelType: this.fb.control('', Validators.required),
      Color: this.fb.control(''),
      DealerNameFilter: this.fb.control(''),
      picture: this.fb.control(''),

      // controls for requetor
      DealershipName: this.fb.control(this.DealershipName, Validators.required),
      phone: this.fb.control('', Validators.required),
      requester: this.fb.control('', Validators.required),
      Subject: this.fb.control('', Validators.required),
      Description: this.fb.control('', Validators.required),

      dealerNametextbox: this.fb.control(''),
    });
  }
  openAddInventoryModel(targetModal) {
    this.textLabel = "Add";
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-xl modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  checkVinExist(Vin: any, Mileage: any) {

    this.VinNumber = Vin;
    this.MileageValue = Mileage;
    this.GetDealerVehiclesByVin(Vin);
  }
  GetVehicleCondtionsList() {
    this.globalcodeService.getVehicleCondtionsList()
      .subscribe(
        (data: any) => {

          this.conditionslist = data;
        },
        error => {
        });
  }


  onDelete(UserId: any) {
    this.deleteVINNumber = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }

  confirm(): void {

    var modelGroup = {
      "VIN": this.deleteVINNumber
    }
    this.manageInventoryService.Delete(modelGroup)
      .subscribe(
        data => {

          this.showNotification("success", "Inventory de-activated successfully")
          this.GetAllCreditorApplication();
        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.TradeValueInput=0;
    this.LienInput=0;
    this.modalRef.hide();
  }
  openModal(template: TemplateRef<any>) {
    this.ApplicationForm.get('DealerNameFilter').enable();
    this.textLabel = "Add";
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  openModalSold(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect', backdrop: 'static', keyboard: false });
  }
  openEditModel(targetModal, id) {
    this.textLabel = "Edit";
    this.updateVIN = id;
    var modelGroup = {
      "Id": id
    }
    this.ApplicationForm.get('DealerNameFilter').disable();
    this.manageInventoryService.getDealerVehiclesByid(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.mileageControl = data[0].mileage;
          this.stockNumber = data[0].stockNumber;
          this.vinNumber = data[0].vin;
          this.carYr = data[0].carYr;
          this.makevalue = data[0].make;
          this.modelvalue = data[0].model;
          this.trimvalue = data[0].trim;
          this.style = data[0].style;
          this.bodyvalue = data[0].body;
          this.conditon = data[0].condition;
          this.datereceived = this.ConvertserverdatetoDatetime(data[0].dateReceived);

          this.listPrice = data[0].listedPrice;
          this.cost = data[0].dealerCost;
          this.extraCleanValue = data[0].cbbValX;
          this.cleanValue = data[0].cbbValC;
          this.averageValue = data[0].cbbValA;
          this.roughvalue = data[0].cbbValR;
          this.fuelType = data[0].engineFuelType;
          this.Color = data[0].colour;
          this.DealerNameFilter = data[0].dealerID;


        },
        error => {
        });
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-xl modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  public currentDate() {
    var todayDate = new Date();
    return {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate()
    }
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;

  }

  GetAPIDetails(item)
  {
    this.apiDetailValue="true";
    this.selectedList=item
   // console.log('=====================================>',typeof(this.selectedList),this.selectedList)
  }



  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }


  // function for getting user list
  GetAllCreditorApplication() {
    this.spinner.show();

    var modelGroup = {
      "DealerId": this.SelectSearchDealervalue.toString(),     
      "Opcode" : this.OpcodeValueforPageLoad
    }

    this.manageInventoryService.getAllCreditorApplicationList(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.creditorslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }


  openModalCheckModel(templateCheckVin: TemplateRef<any>) {

    this.modalRefcheck = this.confirmModalService.show(templateCheckVin, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  declineCheck(): void {
    this.modalRefcheck.hide();
  }
  GetDealerVehiclesByVin(Vin: any) {
    var modelGroup = {
      "VIN": Vin,
    }
    this.manageInventoryService.getDealerVehiclesByVin(modelGroup)
      .subscribe(
        (data: any) => {

          if (data.length > 0) {
            let el: HTMLElement = this.ConfirmBoxVINCheckClick.nativeElement;
            el.click();
          } else {
            // call of functin for getting CBB values for VIN
            this.GetDealerVehiclesCBBValuesByVin(this.VinNumber, this.MileageValue);
          }
        },
        error => {
        });
  }

  GetDealerVehiclesCBBValuesByVin(Vin: any, Mileage: any) {

    var modelGroup = {
      "VIN": Vin,
      "Mileage": Mileage,
    }
    this.manageInventoryService.getDealerVehiclesCBBValuesByVin(modelGroup)
      .subscribe(
        (data: any) => {


          if (data.checkValueAPI == "exist") {

            this.extraCleanValue = data.existadjusted_whole_xclean;
            this.cleanValue = data.existadjusted_whole_clean;
            this.averageValue = data.existadjusted_whole_avg;
            this.roughvalue = data.existadjusted_whole_rough;

            this.bodyvalue = data.existBody;
            this.trimvalue = data.existtrim;
            this.modelvalue = data.existmodel;
            this.makevalue = data.existmake;
            this.yearvalue = data.existmodel_year;
            // this.fuelType =  data.existfueltype;
          } else {
            this.extraCleanValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_xclean;
            this.cleanValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_clean;
            this.averageValue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_avg;
            this.roughvalue = data.used_vehicles.used_vehicle_list[0].adjusted_whole_rough;

            this.bodyvalue = data.used_vehicles.used_vehicle_list[0].class_name;
            this.trimvalue = data.used_vehicles.used_vehicle_list[0].series;
            this.modelvalue = data.used_vehicles.used_vehicle_list[0].model;
            this.makevalue = data.used_vehicles.used_vehicle_list[0].make;
            this.yearvalue = data.used_vehicles.used_vehicle_list[0].model_year;
            // this.fuelType =  data.used_vehicles.used_vehicle_list[0].fuel_type; //data.fuelType;
          }
        },
        error => {
        });
  }
}
