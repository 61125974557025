import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Account } from '@app/_models';
import { AccountService } from '@app/_services/account.service';
@Component({
  selector: 'app-list-wholesalers',
  templateUrl: './list-wholesalers.component.html',
  styleUrls: ['./list-wholesalers.component.less']
})
export class ListWholesalersComponent implements OnInit {
  WholeSaleForm: FormGroup;
  private notifier: NotifierService;
  deleteitem: any;
  constructor(private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private router: Router,
    private accountService:AccountService,
    notifier: NotifierService)
     {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  modalRef: BsModalRef;

  WholeRepoList = [];
  RepoList = [];
  name = 'Angular';
  page = 1;
  pageSize = 10;
  loading = false;
  submitted = false;
  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  accounts: Account[] = [];
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;

  ngOnInit(): void {
    this.CreateAithrRepWholeSaleFrom();
    this.GetAithrWholeReplist();
    this.GetAithrReplist();
  }
  CloseAddmodel() {
    this.modalRef.hide();
  }
  // function for creating from
  CreateAithrRepWholeSaleFrom() {
    this.WholeSaleForm = new FormGroup({
      ActiveRepsctrl: this.fb.control('', Validators.required),
      WhoelsalerListctrl: this.fb.control('', Validators.required)
    });
  }

  openAithrRepWholeSaleModel(targetModal) {

    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

  }

  GetAithrWholeReplist() {
    this.addgroupService.GetAithrRepWholeSalelist()
      .subscribe(
        (data: any) => {
          debugger;
          this.WholeRepoList = data;
        },
        error => {
        });
  }

  GetAithrReplist() {
    this.addgroupService.GetAithrReplist()
      .subscribe(
        (data: any) => {
          debugger;
          this.RepoList = data;

        },
        error => {
        });
  }

  openModal(template: TemplateRef<any>, itemid: any) {
    debugger;
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  onSubmit() {
    debugger
    this.submitted = true;

    if (this.WholeSaleForm.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "AID": this.WholeSaleForm.controls.ActiveRepsctrl.value,
        "WID": this.WholeSaleForm.controls.WhoelsalerListctrl.value
      }


      this.addgroupService.SaveAithrRepWholeSale(modelGroup)
        .subscribe(
          (data: any) => {
            this.GetAithrReplist();
            this.submitted = false;
            this.showNotification("success", "Data saved successfully");
            this.CreateAithrRepWholeSaleFrom();
            this.modalRef.hide();
            // this.closeEditModel();
            // this.closepopupmodel();
            // this.modalcloseOpen.hide();

          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }

  }

}
