
<div class="navbar-side">
	<div (click)="sidebaropen()" class="custom-menu">
	  <button _ngcontent-ago-c105="" type="button" id="sidebarCollapse" class="btn btn-sm bginfo ">
		<i class="fas fa-arrow-right"></i></button>
	</div>



	<ul class="nav-list list-unstyled">





		<li *ngFor="let Parentmenu of menuList ; index as i;" attr.id="{{Parentmenu.liId}}">

			<a   href="#/find-car-final/M" title="{{Parentmenu.screenname}}" (click)="sendtoFindcar('M')"  *ngIf="Parentmenu.routerlink=='find-car-final/M'">
				<span class="icon">
				  <i style="font-size:18px;position:relative;top:1px;" class="{{Parentmenu.icon}}"></i>
				</span>
				<span class="link-text">{{Parentmenu.screenname}}</span>
			  </a>



			<a routerLink="{{'../'+Parentmenu.routerlink}}"  class="" data-toggle="collapse"  *ngIf="!Parentmenu.isSubMenu && Parentmenu.routerlink!='find-car-final/M'"
			title="{{Parentmenu.screenname}}" aria-expanded="false">
			 <span class="icon">
				 <i style="font-size:18px;position:relative;top:1px;" class="{{Parentmenu.icon}}"></i>
			 </span>
			 <span class="link-text">{{Parentmenu.screenname}}</span>
			 <span class="ml-auto myCaret"></span>
		   </a>



			<a class="myBtn" data-toggle="collapse" [attr.data-target]="'#test' + i" *ngIf="Parentmenu.isSubMenu"
			title="{{Parentmenu.screenname}}" aria-expanded="false">
			 <span class="icon">
				 <i style="font-size:18px;position:relative;top:1px;" class="{{Parentmenu.icon}}"></i>
			 </span>
			 <span class="link-text">{{Parentmenu.screenname}}</span>
			 <span class="ml-auto myCaret"></span>
		   </a>

		  <div id="test{{i}}" class="collapse" *ngFor="let submenudata of Parentmenu.submenu ;">

			<a routerLink="{{'../'+submenudata.routerlink}}" title="{{submenudata.screenname}}" *ngIf="submenudata.onclickMethod=='0'">
			  <span class="icon">
				<i style="font-size:18px;position:relative;top:1px;" class="{{submenudata.icon}}"></i>
			  </span>
			  <span class="link-text">{{submenudata.screenname}}</span>
			</a>

			<a   href="#/globalcode/{{submenudata.onclickMethod}}/{{submenudata.paraMeterName}}" title="{{submenudata.screenname}}" *ngIf="submenudata.onclickMethod!='0'" (click)="sendtoglobalcode(submenudata.onclickMethod)">
				<span class="icon">
				  <i style="font-size:18px;position:relative;top:1px;" class="{{submenudata.icon}}"></i>
				</span>
				<span class="link-text">{{submenudata.screenname}}</span>
			  </a>



		  </div>



	  </li>



	</ul>



	</div>