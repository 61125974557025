import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListDealersService } from '@app/_services/list-dealers.service';
@Component({
  selector: 'app-aithr-verification',
  templateUrl: './aithr-verification.component.html',
  styleUrls: ['./aithr-verification.component.less']
})
export class AithrVerificationComponent implements OnInit {
  clientDbForm: FormGroup;
  private notifier: NotifierService;


  radioAcceptCheckItems: Array<string>;
  radioAcceptItems: Array<string>;
  modalcloseOpen: any;
  radioMakringItems: Array<string>;
  vehicleObject = [];
  errorshow: any;
  errorMessage: string;
  model = { option: 'option3' };


  constructor(private fb: FormBuilder, private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService,
    private modalService: NgbModal, private addgroupService: AddgroupService,
    private confirmModalService: BsModalService, private listDealersService: ListDealersService,
    private datePipe: DatePipe,
    notifier: NotifierService, private spinner: NgxSpinnerService,) {
    this.notifier = notifier;

    this.radioAcceptItems = ['Decision', 'Counter Offer', 'Response Only'];
    this.radioAcceptCheckItems = ['Accept', 'Decline'];
  }
  opcode: any;
  DealerNameFilter: any;
  opcodePicture: any;

  opcodebuild: any;
  opcodeMAximized: any;
  opcodeAttention: any;
  DealerList = [];

  ReasonListData = [];
  TotalInvest: any;
  V3BuildCount: any;
  V3MaximizedCount: any;
  TotalInventory: any;

  TotalOfferCount: any;
  o1Heading: any;
  o1Heading1: any;

  o1Heading2: any;

  o1Value1: any;

  o1Value2: any;

  o2: any;

  o2Heading: any;
  o2Heading1: any;
  o2Heading2: any;
  o2Value1: any;
  o2Value2: any;


  dealerVehicleslist: DealerVehicles[] = [];
  dealerPicturelist: DealerVehicles[] = [];
  TotalRecordVerify: any;
  dealerVerifylist: DealerVehicles[] = [];

  dealerBuildlist: DealerVehicles[] = [];
  dealerMaximized: DealerVehicles[] = [];
  dealerAttention: DealerVehicles[] = [];
  ReasonList = [];


  TotalRecordBuild: any;


  TotalRecordmaximized: any;

  TotalRecordAttention: any;

  TopResult: any;
  UnitsWithoutCost: any;
  UsedUnits: any;
  NewUnits: any;
  TotalRecord: any;
  VinValues:any;
  InquiredByValue:any;
  responseId:any;
  ResponseNotes: any;
  LoggedUserName: any;
  UseridValue: any;
  CurrentDate: any;
  VINValue: any;
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  ResponseReason:any;
  pagePicture = 1;
  pageSizePicture = 10;
  pageSizesPicture = [10, 20, 30, 40, 50, 60, 70];


  pageVerify = 1;
  pageSizeVerify = 10;
  pageSizesVerify = [10, 20, 30, 40, 50, 60, 70];


  pageAttention = 1;
  pageSizeAttention = 10;
  pageSizesAttention = [10, 20, 30, 40, 50, 60, 70];

  pagebuild = 1;
  pageSizebuild = 10;
  pageSizesbuild = [10, 20, 30, 40, 50, 60, 70];


  pageMaximized = 1;
  pageSizeMaximized = 10;
  pageSizesMaximized = [10, 20, 30, 40, 50, 60, 70];


  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  TotalRecordPicture: any;
  searchText: any;
  isDesc: boolean = false;
  column: string = 'vin';
  PageTitle: any;
  opcodeVerify: any;
  modalRef: BsModalRef;

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];


  pieChartOptionsbuild: ChartOptions;
  pieChartLabelsbuild: Label[];
  pieChartDatabuild: SingleDataSet;
  pieChartTypebuild: ChartType;
  pieChartLegendbuild: boolean;
  pieChartPluginsbuild = [];

  pieChartOptionsAttention: ChartOptions;
  pieChartLabelsAttention: Label[];
  pieChartDataAttention: SingleDataSet;
  pieChartTypeAttention: ChartType;
  pieChartLegendAttention: boolean;
  pieChartPluginsAttention = [];

  pieChartOptionsInvest: ChartOptions;
  pieChartLabelsInvest: Label[];
  pieChartDataInvest: SingleDataSet;
  pieChartTypeInvest: ChartType;
  pieChartLegendInvest: boolean;
  pieChartPluginsInvest = [];



  OfferNewCount: any;
  OfferwaitingCount: any;
  TotalMeOfferCount: any;
  OfferMeNewCount: any;
  OfferMewaitingCount: any;

  //starting for offer
  SelectedVIN: any;
  UnitDealerId: any;
  UunitVechileId: any;
  UnitDealerCost: any;
  UnitListPrice: any;
  OpcodeForOffer: number = 0;
  OfferType: number = 0;
  OfferFromValue: any;
  statusofOffer: any;

  VINLogCheck: any;
  selectedVin: any;
  UnitFilterName: any;
  OpcodeDetail: any;

  OfferRequest: any = 0;
  OfferRequestCommment: any;

  COunterOffer: any;
  COunterComment: any;
  COunterDate: any;

  IsCOunterDateVisible: any = false;


  ResponseComment: any;
  AccpetComment: any;
  RejectReason: any;
  Isaccept: any = 1;

  OfferDateBindng: any;

  OfferCommentng: any;

  OfferAmountng: any = "0.00";

  offerreadonly: boolean = false;
  newOffer: boolean = false;
  //For three radio button
  SelectionRadiobt: any;


  decisionsectionradio_Checked: any;
  decisionsectionradio_Disable: any;
  Isaccepted_Checked: any;


  DecisionitemsRadiobutton = [
    { groupName: 'Decision', value: 'Decision', disable: false },
    { groupName: 'Counter Offer', value: 'Counter Offer', disable: false },
    { groupName: 'Response Only', value: 'Response Only', disable: false },
  ];

  selectedValueDecisionRadioBT: any;


  primeid: any;



  colorsInvest: Color[] = [
    {
      backgroundColor: [
        '#FC766A',
        '#783937',
        'blue'
      ]
    }
  ];

  colorsSearch: Color[] = [
    {
      backgroundColor: [
        '#8A307F',
        '#79A7D3',
        'blue'
      ]
    }
  ];

  colorsInventory: Color[] = [
    {
      backgroundColor: [

        '#00539C',
        '#EEA47F',
        'blue'
      ]
    }
  ];

  V4UnitattntionCount: any;
  Flagged: any;
  Nocbb: any;
  Totalcost: any;



  //for chart start investment

  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      xAxes: [{}],
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            callback: function (label, index, labels) {
              return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            }
          }
        }
      ]
    },
    tooltips: {
      enabled: true,
      intersect: true,
      titleFontSize: 20,
      callbacks: {
        label: function (tooltipItem, data) {
          var dataset = data.datasets[tooltipItem.datasetIndex];
          var currentValue = dataset.data[tooltipItem.index];
          return currentValue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "end"
      }
    }
  };
  public barChartLabels: Label[];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  public barChartPlugins = [];
  public barChartData: ChartDataSets[];


  BindInvestmentChart() {
    this.barChartData = [
      {
        data: [this.Totalcost, 0, 0],
        label: "Cost",
        backgroundColor: "green"
      },
      {
        data: [0, this.TotalInvest, 0],
        label: "Investment",
        backgroundColor: "blue"
      }

    ];

    this.barChartLabels = ["Cost", "Investment"];

  }

  //investment chart end
  ColorTheme: any;
  ngOnInit(): void {


    this.BindInvestmentChart();

    this.Createform();
    this.GetResponseReason();
    var currentd = new Date();
    this.CurrentDate = this.transformDate(currentd);
    this.UsedUnits = 0;
    this.NewUnits = 0;
    this.LoggedUserName = localStorage.getItem('UserName');
    this.UseridValue = localStorage.getItem('userid');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.DealerNameFilter = this.DealerIdvalue;
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    if (this.rolevalue == "SuperAdmin") {
      this.GetDealerNameFilterlist();
    } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
      this.GetDealersByDealerName();
    }


    // this.GetDealerNameFilterlist();
    this.GetReasonlist();

    this.TotalOfferCount = 0;

    this.OfferNewCount = 0;
    this.OfferwaitingCount = 0;


    this.TotalMeOfferCount = 0;

    this.OfferMeNewCount = 0;
    this.OfferMewaitingCount = 0;

    this.clientDbForm.controls.DealerNameFilter.setValue(this.DealerIdvalue);
    this.opcode = "1";
    this.opcodePicture = "2";


    this.GetAIXAithrhubClientDB();
    this.GetAithrVerifySendList();
    this.GetAithrVerifyRecivedList();


    this.TotalInvest = 0;
    this.Totalcost = 0;
    this.TotalInventory = 0;
    this.TopResult = 0;
    this.UnitsWithoutCost = 0;

    this.V3BuildCount = 0;
    this.V3MaximizedCount = 0;
    this.GeneratePiChart();
    this.GenerateInvestPiChart()
  }
  GetDealersByDealerName() {

    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.listDealersService.getDealersByDealerName(modelGroup)
      .subscribe(
        data => {

          this.DealerList = data;

        },
        error => {
        });


  }

  GetResponseReason() {
    var modelGroup = {     
      "Opcode": this.rolevalue
    }

    this.listDealersService.getResponseReasonlist(modelGroup)
      .subscribe(
        data => {
      
          this.ReasonListData = data;
        },
        error => {
        });


  }

  GetReasonlist() {
    this.globalcodeService.getReasonList()
      .subscribe(
        (data: any) => {
          this.ReasonList = data;
        },
        error => {
        });
  }

  GenerateFlagReportList() {
  }

  openMakeOfferModelPopUp_old(targetModal, VIN, offer: any) {

    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }


  openMakeOfferModelPopUp(targetModal, VIN, offer: any, unitList: any, status) {
   
    this.statusofOffer = status;

    this.VINLogCheck = VIN;
    this.selectedVin = VIN;
    this.offerreadonly = false;
    this.newOffer = false;

    // if (offer > 0) {
    //   this.newOffer=true;
    //  this.GetAIXZOfferLogByVINDetail();
    //  }
    //  else{
    //   this.OfferDateBindng="";
    //   this.OfferCommentng="";
    //   this.OfferAmountng="0.00";
    //   this.offerreadonly=false;
    //  }

    //Re initialize value of nngmodel start
    this.RejectReason = 0;
    this.AccpetComment = "";
    this.COunterOffer = 0.00;
    this.COunterDate = "";
    this.COunterComment = "";
    this.ResponseComment = "";
    this.Isaccepted_Checked = "";
    this.selectedValueDecisionRadioBT = 0;
    //end initialize




    this.GetAIXZOfferLogByVINDetail();

    if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }
    this.SelectionRadiobt = 0;
    this.Isaccept = 1;
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }



  SelectionRadioFunction(value: any) {
    
    if (value == "Decision") {

      this.SelectionRadiobt = 999;

      ///this.RadioValue = value;
      // this.DecisonSectionVisible= true;
      //this.CounterSectionVisible= false;
      //this.ResponseSectionVisible= false;
    }
    else if (value == "Counter Offer") {
      this.SelectionRadiobt = 100;
      // this.CounterSectionVisible= true;
      // this.ResponseSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;
    } else if (value == "Response Only") {
      this.SelectionRadiobt = 111;
      // this.ResponseSectionVisible= true;
      // this.CounterSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;

    }

  }

  SelectedAccepteddecline(data: any) {
    
    if (data == "Accept") {
      this.Isaccept = 1;
    }
    if (data == "Decline") {
      this.Isaccept = 99;
    }

  }


  GetAIXZOfferLogByVINDetail() {
    this.OpcodeDetail = "1";
    var modelGroup = {
      "Opcode": this.OpcodeDetail,
      "VIN": this.VINLogCheck

    }

    this.manageInventoryService.getAIXZOfferLogByVINs(modelGroup)
      .subscribe(
        (data: any) => {
    

          //first time i am doing disable  false
          this.DecisionitemsRadiobutton.forEach(number => {

            number.disable = false;

          })



          this.offerreadonly = true;
          this.primeid = data[0].id;
          let Offerdate = new Date(data[0].offerDateTime);
          this.OfferDateBindng = Offerdate.toDateString();
          this.OfferCommentng = data[0].offerComments;
          this.OfferAmountng = parseFloat(data[0].offerAmount).toFixed(2);

          this.ResponseComment = data[0].respondedComments;

          if (data[0].counterOffer != undefined) {
            this.COunterOffer = parseFloat(data[0].counterOffer).toFixed(2);
          }
          else {
            this.COunterOffer = "0.00";
          }
          this.COunterComment = data[0].counterComments;

          if (data[0].updatedDateTime != undefined) {
            let Counterdate = new Date(data[0].updatedDateTime);
            this.COunterDate = Counterdate.toDateString();
            this.IsCOunterDateVisible = true;
          }


          //read action type and disabl rest radio button

          if (data[0].actionType == 100) //If Counter Offer pass in 100,
          {
            this.SelectionRadiobt = 100;

            this.newOffer = true;

            this.selectedValueDecisionRadioBT = 'Counter Offer';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Counter Offer") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          else if (data[0].actionType == 111) //If Only Response Comments = 111
          {
            this.SelectionRadiobt = 111;

            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Response Only';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Response Only") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          else if (data[0].actionType == 999) //and If Accepted/rejeced pass in 999--- ELSE Zero
          {
            this.SelectionRadiobt = 999;
            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Decision';



            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Decision") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

            if (data[0].isAccepted == 1) {
              this.Isaccepted_Checked = 'Accept';
              this.Isaccept = 1;
              this.AccpetComment = data[0].acceptComments;
            }
            if (data[0].isAccepted == 99) {
              this.Isaccepted_Checked = 'Decline';
              this.Isaccept = 99;
              this.RejectReason = data[0].reasonId;
              this.AccpetComment = data[0].acceptComments;
            }
          }
          else {
            this.SelectionRadiobt = 0;
          }


          //this code is responsible for disable enable decision radio button start

          if (this.statusofOffer == "myoffer") {
            this.selectedValueDecisionRadioBT = 'Decision';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Decision") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

            this.SelectionRadiobt = 999;
          }

          if (this.statusofOffer == "offerme") {


            if (data[0].actionType == 999) {
              this.selectedValueDecisionRadioBT = '';
              this.SelectionRadiobt = 0;
            }

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Decision") {
                number.disable = true;
              }
              else {
                number.disable = false;
              }
            })
          }

          //end

        },
        error => {
        });
  }


  GeneratePiChart() {
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = [this.o2Heading1, this.o2Heading2];
    this.pieChartData = [this.o2Value1, this.o2Value2];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
  }

  GenerateInvestPiChart() {
    this.pieChartOptionsInvest = this.createOptions();
    this.pieChartLabelsInvest = [this.o1Heading1, this.o1Heading2];
    this.pieChartDataInvest = [this.o1Value1, this.o1Value2];
    this.pieChartTypeInvest = 'pie';
    this.pieChartLegendInvest = true;
  }

  declineClose() {
    this.confirmModalService.hide(1);
  }


  //post offer
  //This method call when offer create from dollar
  PostOfferRequestRequest() {
  
    var modelGroup = {};
    // if(this.isaithropcode==2) // Flagged
    // {
    // this.OpcodeForOffer=2;
    // this.OfferType=0;
    // }
    // if(this.isaithropcode==5) // OnClearance
    // {
    // this.OpcodeForOffer=1;
    // this.OfferType=1;
    // }

    this.OpcodeForOffer = 1;
    this.OfferType = 1;


    if (this.SelectionRadiobt == 111) //for response
    {

      this.OfferCommentng = this.ResponseComment;
      this.COunterOffer = this.OfferAmountng;
    }
    if (this.SelectionRadiobt == 100) //for counter
    {

      this.OfferCommentng = this.COunterComment;
      this.OfferAmountng = this.COunterOffer;
    }
    //   if(this.newOffer!=true)   //When new offer create
    //   {
    //    modelGroup = {
    //      "OpCode":this.OpcodeForOffer,
    //     "loggedUserId":localStorage.getItem('userid'),
    //     "VIN":this.selectedVin,
    //     "OfferType":this.OfferType,
    //     "OfferAmount":this.OfferAmountng,
    //     "OfferComment":this.OfferCommentng,
    //     "PrimeId":"0",
    //     "ActionType":"1",
    //     "ActionByDealerID":parseInt(this.DealerIdvalue),
    //     "ActionBy":localStorage.getItem('userid'),
    //     "ActionAmount":this.OfferAmountng,
    //     "ActionComments":this.OfferCommentng,
    //     "ActionToDealerID":this.UnitDealerId,
    //     "ActionTo":"0",
    //     "VehicleID":this.UunitVechileId,
    //     "DealerID":this.UnitDealerId,
    //     "ListedPrice":this.UnitListPrice,
    //     "DealerCost":this.UnitDealerCost,
    //     "IsAccepted":"0",
    //     "AcceptComments":"",
    //     "ReasonId":this.RejectReason,
    //     "ReasonComments":"",
    //     "CommunicationUpdateCode":"0",
    //     "OfferExpiresOn":"0",
    //     "offerFrom":this.OfferFromValue,

    //   }
    // }
    // else{    //Existing Offer
    modelGroup = {
      "OpCode": this.OpcodeForOffer,
      "loggedUserId": localStorage.getItem('userid'),
      "VIN": this.selectedVin,
      "OfferAmount": this.COunterOffer,
      "OfferComment": this.OfferCommentng,
      "PrimeId": this.primeid,
      "OfferType": this.OfferType,
      "ActionType": this.SelectionRadiobt,
      "ActionByDealerID": parseInt(this.DealerIdvalue),
      "ActionBy": localStorage.getItem('userid'),
      "ActionAmount": this.OfferAmountng,
      "ActionComments": this.OfferCommentng,
      "ActionToDealerID": this.UnitDealerId,
      "ActionTo": "0",
      "VehicleID": this.UunitVechileId,
      "DealerID": this.UnitDealerId,
      "ListedPrice": this.UnitListPrice,
      "DealerCost": this.UnitDealerCost,
      "IsAccepted": this.Isaccept,
      "AcceptComments": this.AccpetComment,
      "ReasonId": this.RejectReason,
      "ReasonComments": this.AccpetComment,
      "CommunicationUpdateCode": "0",
      "OfferExpiresOn": "0",
      "offerFrom": this.OfferFromValue
    }
    // }

    //       if(this.newOffer!=true)   //When new offer create
    //       {
    //       this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
    //         .subscribe(
    //           (data: any) => {

    //             if(data.status=="1")
    //             {
    //         //    this.VinValue =data.vin;
    //         this.showNotification("success", "Offer saved.");

    //        // this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

    //        this.GetClientDashboardOfferList();
    //        this.GetClientDashboardOfferToMe();

    //        //Reinitialize the models start
    //         this.Isaccept=1;
    //         this.COunterComment="";
    //         this.COunterOffer=0;
    //         this.ResponseComment="";
    //         this.selectedValueDecisionRadioBT="";
    // //Reinitialize the models nd

    //             this.modalService.dismissAll();
    //            // this.decline();
    //             this.declineClose();
    //             }
    //           },
    //           error => {
    //           });

    //         }
    //  else{
    this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)  //existing offer
      .subscribe(
        (data: any) => {


          //    this.VinValue =data.vin;
          this.showNotification("success", "Offer Updated.");

          //Re initialize value of nngmodel start
          this.RejectReason = 0;
          this.AccpetComment = "";
          this.COunterOffer = 0.00;
          this.COunterDate = "";
          this.COunterComment = "";
          this.ResponseComment = "";
          this.Isaccepted_Checked = "";
          //end initialize

          // this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
          this.GetAithrVerifySendList();
          this.GetAithrVerifyRecivedList();

          //this.Isaccept=1;
          this.selectedValueDecisionRadioBT = "";

          this.modalService.dismissAll();
          //this.decline();
          this.declineClose();

        },
        error => {
        });

    // }


  }





  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  sortAttention(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  Createform() {
    this.clientDbForm = new FormGroup({
      DealerNameFilter: this.fb.control('', Validators.required),
      CurrentDate: this.fb.control('', Validators.required),
      LoggedUserName: this.fb.control('', Validators.required),
      ResponseNotes: this.fb.control('', Validators.required),
      ResponseReason: this.fb.control('')
    });
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.GetAithrVerifySendList();
  }





  handlePageSizeChangePicture(event) {
    this.pageSizePicture = event.target.value;
    this.pagePicture = 1;
    this.GetAithrVerifyRecivedList();
  }



  Getdetails(event: any) {
    var lendervalue = event.target.value;
    this.DealerNameFilter = lendervalue;
    this.opcode = "1";
    this.opcodePicture = "2";


    this.GetAIXAithrhubClientDB();
    this.GetAithrVerifySendList();
    this.GetAithrVerifyRecivedList();






  }

  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }
  decline(): void {
    this.modalRef.hide();
  }
  OpenModel(template: TemplateRef<any>, vin: any, btn: any) {

    this.VINValue = vin;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  SaveReponseDetail() {
    
    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName": this.LoggedUserName,
      "Notes": this.ResponseNotes,
      "VIN": this.VINValue.toString(),
      "DealerId": this.DealerIdvalue.toString(),

    }

    this.manageInventoryService.sendDetailClientDashbaord(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes = "";
          this.showNotification("success", "Response send successfully");
          this.decline();
        },
        error => {
        });


  }

  GetAithrVerifySendList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter,
      "UserId":this.UseridValue
    }
    this.manageInventoryService.getAithrVerifySendListDetails(modelGroup)
      .subscribe(
        (data: any) => {
          
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  openPasswordModel(targetModal, Vin,InquiredBy,rowid) {

    this.VinValues = Vin;
    this.InquiredByValue = InquiredBy;
    this.responseId=rowid
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-sm', backdrop: 'static', keyboard: false });

  }
  Closepasswordmodel() {
    this.modalRef.hide();
  }


  SendPostiveDetail(VIN:any,InqureyBy:any,rowid:any) {
    debugger;
    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName": this.LoggedUserName,    
      "VIN": VIN.toString(),
      "DealerId": this.DealerIdvalue.toString(),
      "InquiredBy":InqureyBy,
      "InternalId":rowid

    }

    this.manageInventoryService.SendPostiveDetails(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes = "";
          this.showNotification("success", "Response send successfully");
          this.ResponseReason="";
          this.decline();
        },
        error => {
        });


  }

  SendReponseDetail() {
    debugger;
    var modelGroup = {
      "UserId": this.UseridValue,
      "UserName": this.LoggedUserName,    
      "VIN": this.VinValues.toString(),
      "DealerId": this.DealerIdvalue.toString(),
      "Reason":this.ResponseReason,
      "InquiredBy":this.InquiredByValue,
      "InternalId":this.responseId

    }

    this.manageInventoryService.sendReponseDetails(modelGroup)
      .subscribe(
        data => {
          this.ResponseNotes = "";
          this.showNotification("success", "Response send successfully");
          this.decline();
        },
        error => {
        });


  }

  GetAithrVerifyRecivedList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcodePicture,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter,
      "UserId":this.UseridValue
    }
    this.manageInventoryService.getAithrVerifyRecivedListDetails(modelGroup)
      .subscribe(
        (data: any) => {
        
          this.dealerPicturelist = data;
          this.TotalRecordPicture = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetAIXAithrhubClientDB() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getAIXAithrhubOfferDBDetails(modelGroup)
      .subscribe(
        (data: any) => {

          this.TotalOfferCount = parseInt(data[0].o1);
          this.o1Heading = data[0].o1Heading;
          this.o1Heading1 = data[0].o1Heading1;

          this.o1Heading2 = data[0].o1Heading2;

          this.o1Value1 = data[0].o1Value1;

          this.o1Value2 = data[0].o1Value2;

          this.o2 = data[0].o2;

          this.o2Heading = data[0].o2Heading;
          this.o2Heading1 = data[0].o2Heading1;

          this.o2Heading2 = data[0].o2Heading2;
          this.o2Value1 = data[0].o2Value1;
          this.o2Value2 = data[0].o2Value2;

          this.GeneratePiChart();

          this.GenerateInvestPiChart()
          this.BindInvestmentChart();

        },
        error => {
        });
  }

  GetDealerNameFilterlist() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getDealerNameClientDB(modelGroup)
      .subscribe(
        (data: any) => {

          this.DealerList = data;
        },
        error => {
        });
  }
}
