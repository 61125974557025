import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet,Color } from 'ng2-charts';
import { GlobalcodeService } from '@app/_services/globalcode.service';
@Component({
  selector: 'app-reportmatrix',
  templateUrl: './reportmatrix.component.html',
  styleUrls: ['./reportmatrix.component.less']
})
export class ReportmatrixComponent implements OnInit {
  ColorTheme: string;

  lenderpieChartOptions: ChartOptions;
  lenderpieChartLabels: Label[];
  lenderpieChartData: SingleDataSet;
  lenderpieChartType: ChartType;
  lenderpieChartLegend: boolean;
  lenderpieChartPlugins = [];


  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];
  searchUnitText;
  useridValue: any;
  pageUnit = 1;
  pageUnitSize = 10;
  reportDataMatrixList = [];
  reportDataMatrixAllList= [];
  UnittotalCount:any;
  colorsInvest: Color[] = [
    {
      backgroundColor: [
        '#FC766A',
        '#783937',
        'blue',
        'green',
        'red'
      ]
    }
  ];
  Opcode:any;
  reverse = false;
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  showUnitDetail:any;
  constructor(private globalcodeService: GlobalcodeService) { }

  ngOnInit(): void {
   this.showUnitDetail="false";
    this.Opcode ="2";
    this.GetReportmatrixlist();
    this.GetReportmatrixlistData();
    this.useridValue = localStorage.getItem('userid');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  sortUnit(property) {
    debugger;
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.reportDataMatrixAllList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }
  GetAllDetails()
  {
    this.Opcode ="3";
    this.GetAlllistData();
  }

  GetAlllistData() {

    var modelGroup = {
      "opcode": this.Opcode,
      "FromDate": new Date(),
      "TillDate": new Date(),
      "UserID": "0"
    }

    this.globalcodeService.getmatrixreportList(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.showUnitDetail="true";
          this.reportDataMatrixAllList = data;
          this.UnittotalCount=data.length;

        },
        error => {
        });
  }
  HideDetails()
  {
    this.showUnitDetail="false";
  }
  GetReportmatrixlistData() {

    var modelGroup = {
      "opcode": this.Opcode,
      "FromDate": new Date(),
      "TillDate": new Date(),
      "UserID": "0"
    }

    this.globalcodeService.getmatrixreportList(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.reportDataMatrixList = data;

        },
        error => {
        });
  }

  GetReportmatrixlist() {

    var modelGroup = {
      "opcode": "1",
      "FromDate": new Date(),
      "TillDate": new Date(),
      "UserID": "0"
    }

    this.globalcodeService.getmatrixreport(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          var chartlabel = [];
          var chartdata = [];

          var lenderpieChart_label = [];
          var lenderpieChart_data = [];

          data.totalactivepieChartLabels.forEach(function (value) {
            chartlabel.push(value.label)
          });


          data.totalactivepieChartData.forEach(function (value) {
            chartdata.push(Number(value.data))
          });


          data.lenderpieChartLabels.forEach(function (value) {
            lenderpieChart_label.push(value.label)
          });


          data.lenderpieChartData.forEach(function (value) {
            lenderpieChart_data.push(Number(value.data))
          });



          this.pieChartOptions = this.createOptions();
          this.pieChartLabels = chartlabel;
          this.pieChartData = chartdata;
          this.pieChartType = 'pie';
          this.pieChartLegend = true;

          this.lenderpieChartOptions = this.createOptions();
          this.lenderpieChartLabels = lenderpieChart_label;
          this.lenderpieChartData = lenderpieChart_data;
          this.lenderpieChartType = 'pie';
          this.lenderpieChartLegend = true;

        },
        error => {
        });
  }

}
