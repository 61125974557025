import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({ providedIn: 'root' })

@Injectable({
  providedIn: 'root'
})
export class AddrepoService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
      private router: Router,
      private http: HttpClient
  ) {

      this.accountSubject = new BehaviorSubject<Account>(null);
      this.account = this.accountSubject.asObservable();

      this.loginSubject = new BehaviorSubject<Login>(null);
      this.logins = this.loginSubject.asObservable();

  }


  private baseUrl_AddAithrRep: string = environment.apiUrl + '/Admin/AddAithrRep';
  private baseUrl_GetAllGroupsList: string = environment.apiUrl + '/Admin/GetAllGroupsList';
  private baseUrl_DeleteGroupsList: string = environment.apiUrl + '/Admin/DeleteGroup';
  private baseUrl_EditGroupList: string = environment.apiUrl +'/Admin/GetGroupById' ;
  private baseUrl_UpdateGroupName: string = environment.apiUrl +'/Admin/UpdateGroup' ;
  private baseUrl_GetAllPositionList: string = environment.apiUrl +'/Admin/GetAllPositionsList' ;
  private baseUrl_saveaddposition: string = environment.apiUrl +'/Admin/AddPosition' ;
  private baseUrl_DeletePositionlist:string = environment.apiUrl +'/Admin/DeletePosition' ;
  private baseUrl_EditpositionList:string = environment.apiUrl +'/Admin/GetPositionsById' ;
  private baseUrl_UpdatepositionName:string = environment.apiUrl +'/Admin/UpdatePosition' ;



  //calling function saving method
  SaveaddRepo(formdata) {
    return this.http.post<any[]>(this.baseUrl_AddAithrRep, formdata);
}

//get grouplist

  //calling function saving method
  Getgrouplist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllGroupsList);
}

//delete grouplist
 //calling function saving method
 Deletegrouplist(FormData) {
  return this.http.post<any>(this.baseUrl_DeleteGroupsList,FormData);
}

//this is for calling edit functionality
EditGroupList(FormData) {
  return this.http.post<any>(this.baseUrl_EditGroupList,FormData);

}

//this is for calling edit functionality
UpdateGroupName(FormData) {
  return this.http.post<any>(this.baseUrl_UpdateGroupName,FormData);

}
//this for getting position list
GetPositionlist(){
  return this.http.get<CDATASection[]>(this.baseUrl_GetAllPositionList);
}
// this is for add positon 
Saveaddposition(formdata) {
  return this.http.post<any[]>(this.baseUrl_saveaddposition, formdata);
}
//this is for delete position
DeletePosition(Formdata)
{
  return this.http.post<any>(this.baseUrl_DeletePositionlist,Formdata);
}

//this is for calling edit functionality
EditpositionList(FormData) {
  return this.http.post<any>(this.baseUrl_EditpositionList,FormData);

}

//this is for calling edit functionality
UpdatepostionName(FormData) {
  return this.http.post<any>(this.baseUrl_UpdatepositionName,FormData);

}
}
