<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="detailListForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div id="accordionExample" class="accordion">
                    <div class="card card-raised h-100 mb-0">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-start align-items-center mx-0">
                                <h6 class="card-title text-white text-uppercase mb-0">Manage List</h6>
                                <div class="ml-auto col-auto px-2">
                                    <button type="button" data-toggle="collapse" data-target="#DetailsList"
                                        aria-expanded="true" aria-controls="DetailsList" class="btn text-white plusbtn"
                                        style="padding:0;font-size:13px;">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="DetailsList" aria-labelledby="BuildCar" data-parent="#accordionExample"
                            class="collapse show">
                            <div class="card-body mb-2">

                                <div class="row justify-content-between align-items-center mb-2">
                                    
                                    <div class="col-auto mr-auto">
                                        <div class="form-inline">
                                            <div class="form-group col-auto mb-0">
                                                <div class="row justify-content-between align-items-center">
                                                    <label class="col-form-label col-lg-auto px-1">Detail Type:</label>
                                                    <div class="col-lg-auto">


                                                        <select class="form-control" name="detail type"
                                                            formControlName="detailTypeFilter" (change)="FilterList()"
                                                            [(ngModel)]="detailTypeFilter">
                                                            <option value="0" selected>Select Detail Type </option>
                                                            <option [value]="1">
                                                                Roles</option>
                                                            <option [value]="2">
                                                                Groups</option>
                                                                <option [value]="3">
                                                                    Dealers</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-auto mb-0">
                                                <div class="row justify-content-between align-items-center">
                                                    <label class="col-form-label col-lg-auto px-1">Name:</label>

                                                    <select style="min-width:200px;max-width:200px;" class="form-control" name="region" (change)="GetList()"
                                                        formControlName="NameFilter" [(ngModel)]="NameFilter">
                                                        <option value="0" selected>Select Name </option>
                                                        <option *ngFor="let Globalcodelistdata of Globalcodelist"
                                                            [value]="Globalcodelistdata.globalCodeID">
                                                            {{Globalcodelistdata.globalCodeName}}</option>
                                                    </select>


                                                </div>
                                            </div>
                                            <button id="resetbtn" class="btn bg-warning btn-sm mb-sm-0 ml-2 rounded-pill"
                                                (click)="ResetFilter()">Reset</button>
                                        </div>

                                    </div>
                                 
                                </div>


                                <div class="row mt-2" *ngIf="displaylist ==='true'">
                                    <div class="col-md-12">
                                        <div class="card card-raised h-100">
                                            <div class="card-header card-raised bg-info text-white">

                                                <div class="row justify-content-between align-items-center">
                                                    <div class="col-sm-6">
                                                        <h5 class="card-title text-white text-uppercase mb-0">List of Users
                                                            {{LabelName}} wise</h5>
                                                    </div>
                                                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                        <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                    </div>
                                                    <!-- <div class="card-subtitle"></div> -->
                                                </div>
                                            </div>
                                            <div id="inventory" class="card-body p-4">
                                                <div class="row justify-content-between align-items-center mb-2">
                                                    <div class="col-auto documentbtn">
                                                        <ul class="list-unstyled mb-1">
                                                            <li>
                                                                <button class="btn btn-secondary btn-sm mb-sm-0">Copy</button>
                                                            </li>
                
                                                            <li>
                                                                <button class="btn btn-secondary btn-sm mb-sm-0"
                                                                    (click)="exportexcel()">Excel</button>
                                                            </li>
                                                            <li>
                                                                <button class="btn btn-secondary btn-sm mb-sm-0"
                                                                    (click)="downloadPdf()">PDF</button>
                                                            </li>
                                                            <li>
                                                                <button class="btn btn-secondary btn-sm mb-sm-0"
                                                                    (click)="downloadPdf()">Print</button>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    
                                                    <!-- <input type="button" onclick="onPrint('sectionToPrint')" value="Print" /> -->
                                                    <div class="search-heros col-sm-8 col-md-auto mt-2 mt-lg-0">
                                                        <div class="form-group d-flex mb-1">
                                                            <label class="mr-3 mt-2">Search:</label>
                                                            <input class="form-control" type="text" name="search"
                                                                [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                                                autocomplete="off">
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered"
                                                        style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th style="text-align: left;"
                                                                    (click)="sort('userName')"> User Name
                                                                    <i class="fa fa-fw fa-sort"></i>
                                                                </th>

                                                                <!-- <th style="text-align: left;"
                                                                    (click)="sort('roleNameValue')">
                                                                    Role<i class="fa fa-fw fa-sort"></i>
                                                                </th>

                                                                <th style="text-align: left;"
                                                                    (click)="sort('groupNameValue')">
                                                                    Group Name <i class="fa fa-fw fa-sort"></i>
                                                                </th>
                                                                <th style="text-align: left;"
                                                                    (click)="sort('dealerName')"> Dealer
                                                                    Name <i class="fa fa-fw fa-sort"></i>
                                                                </th> -->
                                                                <th style="text-align: left;"
                                                                    (click)="sort('firstName')">First Name
                                                                    <i class="fa fa-fw fa-sort"></i>
                                                                </th>
                                                                <th style="text-align: left;"
                                                                    (click)="sort('lastName')">Last Name
                                                                    <i class="fa fa-fw fa-sort"></i>
                                                                </th>
                                                                <th style="text-align: left;" (click)="sort('city')">
                                                                    City <i class="fa fa-fw fa-sort"></i> </th>
                                                                <th style="text-align: left;"
                                                                    (click)="sort('phoneNumber')">Phone Number <i
                                                                        class="fa fa-fw fa-sort"></i>
                                                                </th>


                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr
                                                                *ngFor="let Userlistdata of Userlist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">

                                                                <td style="text-align: left;">{{ Userlistdata.userName
                                                                    }}</td>
                                                                <!-- <td style="text-align: left;">{{
                                                                    Userlistdata.roleNameValue }}</td>
                                                                <td style="text-align: left;"> {{
                                                                    Userlistdata.groupNameValue }}
                                                                </td>
                                                                <td style="text-align: left;"> {{
                                                                    Userlistdata.dealerName }}</td> -->
                                                                <td style="text-align: left;"> {{ Userlistdata.firstName
                                                                    }}</td>
                                                                <td style="text-align: left;"> {{ Userlistdata.lastName
                                                                    }}</td>
                                                                <td style="text-align: left;"> {{ Userlistdata.city }}
                                                                </td>
                                                                <td style="text-align: left;"> {{
                                                                    Userlistdata.phoneNumber }}</td>


                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                    <br>

                                                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                                        type="square-jelly-box" [fullScreen]="false">
                                                        <p style="color: white"> Loading... </p>
                                                    </ngx-spinner>

                                                    <div class="row justify-content-between align-items-center">
                                                        <div class="col">
                                                            <ngb-pagination [collectionSize]="Userlist.length"
                                                                [(page)]="page" [maxSize]="5" [rotate]="true"
                                                                [ellipses]="false" [boundaryLinks]="true">
                                                            </ngb-pagination>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

    </form>
</section>