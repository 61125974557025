<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="customerOrderForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mt-2 mb-3 mb-xl-0">
                    <div class="card buildcar-title mb-0">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto">
                                    <h6 class="d-inline-block mb-0 pt-1"><b>Vehicle Info</b></h6>
                                </div>
                                <div class="col-auto mr-auto">
                                    <div class="form-inline pt-1">
                                        <input type="text" placeholder="VIN"
                                        formControlName="VINValueControl" 
                                        [(ngModel)]="VINValueControl"  class="form-control">

                                        <label class="col-form-label col-lg-auto px-2">Customer Name:</label>
                                        <div class="col-lg-auto">
                                          <select class="form-control" name="region" 
                                          formControlName="CustomerNameFilter" 
                                            [(ngModel)]="CustomerNameFilter">
                                            <option value="0" selected>Select Customer Name </option>
                                            <option *ngFor="let CustomersListdata of CustomersList" [value]="CustomersListdata.id">
                                                {{CustomersListdata.firstName}} {{CustomersListdata.middleName}} {{CustomersListdata.lastName}}</option>
                                          </select>
                                        </div>

                                        <button id="getdetails" type="button" class="btn btn-md ml-2 btn-warning"
                                        (click)="GetDetail();" >Get
                                        Vehicle  Details</button>

                                        <button type="button" class="btn btn-md ml-2 btn-warning" (click)="GetCustomerOrder();">
                                            Get Customer Order</button>
                                 
                               
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card-body px-3 py-2 pb-4" style="min-height:auto;">
                            <!-- <div class="row mb-1">
                                <p id="noteVin" class="mb-0 ml-2 p-1 px-2 text-white bg-info col"> Note : Please enter VIN in text box for getting VIN detail and after getting VIN detail click on Get CBB Detail Button to get CBB values.</p>
                            </div> -->
                            <div class="w-100 mt-2">
                                <div class="form-row">
                                    <div class="form-group col-md-3">
                                        <label>Condition</label>
                                        <input type="text" formControlName="ConditionControl" [(ngModel)]="ConditionControl" placeholder="Condition"
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Make</label>
                                        <input type="text" formControlName="MakeControl" [(ngModel)]="MakeControl" placeholder="Name"
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Stock Number</label>
                                        <input type="text" formControlName="StockNumberControl" [(ngModel)]="StockNumberControl"
                                            placeholder="Stock Number" class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Model</label>
                                        <input type="text" formControlName="ModelControl" [(ngModel)]="ModelControl" placeholder="Model"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-3">
                                        <label>VIN</label>
                                        <input type="text" formControlName="VINControl" [(ngModel)]="VINControl" placeholder="VIN"
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Kms</label>
                                        <input type="text" formControlName="MileageControl" [(ngModel)]="MileageControl" placeholder="Kms"
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Year</label>
                                        <input type="text" formControlName="YearControl" [(ngModel)]="YearControl" placeholder="Year"
                                            class="form-control">
                                    </div>
                            
                                    <div class="form-group col-md-3">
                                        <label>Trim</label>
                                        <input type="text" formControlName="TrimControl" [(ngModel)]="TrimControl" placeholder="Trim"
                                            class="form-control">
                                    </div>
                                </div>
                                <div class="form-row">
                                    <div class="form-group col-md-3">
                                        <label>Details</label>
                                        <input type="text" formControlName="DetailsControl" [(ngModel)]="DetailsControl" placeholder="Details"
                                            class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>Dealer Cost</label>
                                        <input type="text" formControlName="DealerCostControl" [(ngModel)]="DealerCostControl"
                                            placeholder="Dealer Cost" class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label>List Price</label>
                                        <input type="text" formControlName="ListPriceControl" [(ngModel)]="ListPriceControl"
                                            placeholder="List Price" class="form-control">
                                    </div>
                                    <div class="form-group col-md-3">
                                        <label class="d-none d-md-block">&nbsp; </label>
                                        
                                    </div>  
                                </div>
                            </div>
                            <div *ngIf="showlist ==='true'" class="row mt-2">
                                <div class="col-md-12">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h5 class="card-title text-white text-uppercase mb-0">Order Warranty Detail</h5>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive">
                                                <table id="usertable" class="table table-bordered" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            <th>VIN
                                                            </th>
                                                            <th>Order NO
                                                            </th>
                                                            <th>Order Date
                                                            </th>
                                                            <th>Customer Name
                                                            </th>
                                                            <th>City
                                                            </th>
                                                            <th>Address
                                                            </th>
                                                            <th>Email
                                                            </th>
                                                            <th>Phone
                                                            </th>
                                                            <th>Region
                                                            </th>
                                                           
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let OrderDetailListdata of OrderDetailList| filter:searchTextBought | slice: (pagebought-1) * pageSizebought : (pagebought-1) * pageSizebought + pageSizebought">
                                                            <td>{{ OrderDetailListdata.vin }}</td>
                                                            <td>{{ OrderDetailListdata.orderNumber }}</td>
                                                            <td>{{ OrderDetailListdata.orderUtcDate | date }}</td>
                                                            <td>{{ OrderDetailListdata.firstName }} {{ OrderDetailListdata.middleName }} {{ OrderDetailListdata.lastName }}</td>
                                                            <td>{{ OrderDetailListdata.city }}</td>
                                                            <td>{{ OrderDetailListdata.address }}</td>
                                                            <td>{{ OrderDetailListdata.email }}</td>
                                                            <td>{{ OrderDetailListdata.phone }}</td>
                                                            <td>{{ OrderDetailListdata.globalCodeName }}</td>
        
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                                <br>
                            
                                            </div>
                                        </div>
                            
                            
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
   
        <!-- <app-footer id="secondfooter"></app-footer> -->
    </form>
</section>





