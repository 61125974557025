import { Component, OnInit, TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { AccountService, AlertService } from '@app/_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Account } from '@app/_models';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit, AfterViewInit {
    form: FormGroup;
    loading = false;
    submitted = false;
    lginEmailValue: any;
    show_userAccount = false;
    errorMessage: any;
    BrowserName:any;
    href:any;
    modalRef: BsModalRef;
    UserId:any;
    ipAddress:any;
    //pdfSource:any = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
    pdfSource = '/assets/Aithr.pdf';
    //@ViewChild('aggrepdf') aggrepdf : TemplateRef<any>;
    @ViewChild('aggrepdfbuttonToBeClicked') buttonToBeClicked: ElementRef

    useremail: any = "";
    private accountSubject: BehaviorSubject<Account>;
    public account: Observable<Account>;
    private notifier: NotifierService;
    constructor(
        private formBuilder: FormBuilder,
        private route: ActivatedRoute,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private confirmModalService: BsModalService,
        private modalService: NgbModal,
        notifier: NotifierService
    ) {
        this.notifier = notifier;
        this.accountSubject = new BehaviorSubject<Account>(null);
        this.account = this.accountSubject.asObservable();
        this.accountService.SaveActivityLog("");
        this.accountService.CheckIFUserIsActive("");
    }
    ngAfterViewInit(): void {
        this.buttonToBeClicked.nativeElement.value = 'Whale!';

    }
    password="";

    show = true;

    ngOnInit() {
        this.password = 'password';
        this.GetIpAddress();
        this.form = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$')]],
            password: ['', Validators.required]
        });


        // this.openModal(childNode,'')
    }

    onClick() {

        if (this.password === 'password') {
          this.password = 'text';
          this.show = true;
        } else {
          this.password = 'password';
          this.show = false;
        }
      }
    // convenience getter for easy access to form fields
    get f() { return this.form.controls; }



    // function for open model pop up
    openModal(template: TemplateRef<any>, itemid: any) {

        this.modalRef = this.confirmModalService.show(template, {
            class: 'modal-lg modal-dialog-centered', backdrop: 'static',
            keyboard: false
        });
    }

    openAddModel(model: any, data: any) {
        this.modalService.open(model, { ariaLabelledBy: 'modal-basic-title' });
      }


    decline(): void {
        this.modalRef.hide();
    }

    // function for confirm  delete
    confirmDocument(): void {
        this.useremail;
        var modelGroup = {
            "Email": this.useremail
        }
        this.accountService.AggrementDocument(modelGroup);

        this.accountService.After_AggrementDocument_redirect(modelGroup).subscribe(

            data => {
                if (data.istermaccepted == true) {
                    this.modalRef.hide();
                    this.accountSubject.next(data);
                    this.Localstorage(data);

                    // Set Landing page according to user role.
                    if (localStorage.getItem('UserRole') == "Admin") {
                        this.accountService.setValueUserAdmin("Admin");
                        return this.router.navigateByUrl("/master-dashboard");

                    } else if (localStorage.getItem('UserRole') == "Dealer") {
                        this.accountService.setValueUserAdmin("Dealer");
                        return this.router.navigateByUrl("/master-dashboard");

                    }
                    else{ // if user has no any roles then we redirect to dealers dashboard for time being
                        this.accountService.setValueUserAdmin("Dealer");
                        return this.router.navigateByUrl("/master-dashboard");
                    }
                }

            }
        );

    }
  // functions for getting IP address
    GetIpAddress() {
        this.accountService.getIPAddress().subscribe((res: any) => {
            this.ipAddress = res.ip;
            localStorage.setItem('Ipaddress', res.ip);
        });
    }


    onSubmit() {
        debugger;
        this.submitted = true;

        // reset alerts on submit
        this.alertService.clear();
        const emailValue = this.form.get('email');
        this.lginEmailValue = emailValue.value;
        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.loading = true;
        this.accountService.login(this.f.email.value, this.f.password.value.replace(/\s/g, ""))
            .pipe(first())
            .subscribe(data => {
                debugger;
                
                if (data != "4") {

                    this.useremail = data.email;
                    if (data.istermaccepted == false) {
                        this.loading = false;
                        this.buttonToBeClicked.nativeElement.click();
                        return false;
                    }
                    if (data.istermaccepted == true) {
                        this.accountSubject.next(data);
                        this.Localstorage(data);

                        this.href = this.router.url + "login";
                        this.UserId = data.userdata.id;
                        var element = document.body;
                        if (data.colorTheme === "White") {
                            element.classList.remove("dark-mode");
                        } else {

                            element.classList.add("dark-mode");
                        }

                        // Set Landing page according to user role.
                        if (localStorage.getItem('UserFullRole') == "SuperAdmin") {
                            this.accountService.setValueUserAdmin("Admin");
                            this.accountService.SaveActivityLog(this.ipAddress);
                            return this.router.navigateByUrl("/welcome-dasboard");

                        } else if (localStorage.getItem('UserFullRole') == "Dealer") {
                            this.accountService.setValueUserAdmin("Dealer");
                            this.accountService.SaveActivityLog(this.ipAddress);
                            return this.router.navigateByUrl("/welcome-dasboard");

                        } else if (localStorage.getItem('UserFullRole') == "Admin") {
                            this.accountService.setValueUserAdmin("Admin");
                            this.accountService.SaveActivityLog(this.ipAddress);
                            return this.router.navigateByUrl("/welcome-dasboard");
                        }
                        else { // if user has no any roles then we redirect to dealers dashboard for time being
                            this.accountService.setValueUserAdmin("Dealer");
                            return this.router.navigateByUrl("/welcome-dasboard");
                        }
                    }

                } else
                {
                    this.errorMessage = "Your account or Dealer has been deactivated if need assistance   contact AithrX Support.";
                    this.alertService.error(this.errorMessage);
                    this.loading = false;
                }

            },
                error => {
                    this.errorMessage = "Please check your Login Credentials";
                    this.alertService.error(this.errorMessage);
                   // this.showNotification("error", "Please check your Login Credentials");
                    this.loading = false;
                }
            );
    }

    public showNotification(type: string, message: string): void {
        this.notifier.notify(type, message);
      }


    //local storage using
    Localstorage(data) {
        localStorage.setItem('userdetails', JSON.stringify(data.userdata));
        localStorage.setItem("jwt", data.jwtToken);
        localStorage.setItem('AuthToken', data.jwtToken);
        localStorage.setItem('UserRole', data.role);
        localStorage.setItem('UserName', data.userName);

        localStorage.setItem('email', data.email);
        localStorage.setItem('DealerId', data.dealerID);
        localStorage.setItem('roleid', data.roleid);
        localStorage.setItem('groupid', data.groupid);
        localStorage.setItem('UserFullRole', data.userFullRole);
        localStorage.setItem('DealerName', data.dealerName);
        localStorage.setItem('GroupName', data.groupName);
       // localStorage.setItem('colorTheme', data.colorTheme);
        
        localStorage.setItem('Province', data.province);
        localStorage.setItem('LoggedRegionName', data.regionName);
    }
}