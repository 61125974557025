
<section id="reset-password" class="logoin-section">
    <div class="container">
        <div class="row align-items-center justify-content-center h-100">
            <div class="col-12 col-sm-10 col-md-6 col-lg-5 col-xl-5 mx-auto bg-white p-2 card text-center">
              <div class="card-body">
                <h4><b>Forgot password </b></h4>
                <p>Enter your email address associated with your account.</p>
                <form [formGroup]="form" (ngSubmit)="onSubmit()"  class="col-lg-10 mt-4 mx-auto">
                    <div class="form-group">
                        <!-- <input placeholder="Enter Your Email" type="text"
                         formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                         -->
                         <input placeholder="Enter Your Email" type="text" formControlName="email" class="form-control"
  [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
  (input)="onEmailInput($event.target.value)">

                        <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                            <div *ngIf="f.email.errors.required">Email is required</div>
                            <div *ngIf="f.email.errors.email">Email is invalid</div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button [disabled]="loading" type="submit" class="btn btn-primary">
                            <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                            Submit
                        </button>
                        <a routerLink="../login" class="btn btn-outline-danger ml-2">Cancel</a>
                    </div>
                </form>
            </div>
            </div>
        </div>

        <div id="SentmailforReset" *ngIf="sentmail">
          <p style="color: green;">We sent the mail for reset the password.Please check your mail</p>
        </div>
    </div>
  </section>


