
<section id="admindashboard">
    <div class="container-fluid" >
        <div class="page-header">
            <div class="row">
                <div class="col-lg-12">
                    <h3>
                       HUB DASHBOARD
                        <small>Vehicles Admin panel</small>
                    </h3>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-xl-6 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-calendar-days"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-warning">{{dateSynchedReceived | date }}</h5>
                                    <span>Date Synced</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-6 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-success">{{totalCount}}</h5>
                                    <span>Total Count</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>   
        
        <div class="row mt-3">
          <div class="col-md-6">
            <div class="card card-raised h-100">
              <div class="card-header card-raised bg-info text-white">
                <h5 class="card-title text-white text-uppercase mb-0">Vehicles Synced Details Region Wise</h5>
               </div>
               <div class="card-body p-4">
                  <div class="row">
                      <div class="col-12">
                        <div class="row justify-content-between align-items-center">
                          <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                              <li><button class="btn btn-secondary btn-sm mb-1" (click)="copytable('usertable')">Copy</button></li>
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1" (click)="exportexcel()">Excel</button>
                              </li>
                              
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1" (click)="downloadPdf()">PDF</button>
                              </li>
                              <li>
                                <button class="btn btn-secondary btn-sm mb-1" (click)="printTable()">Print</button>
                              </li>
                            </ul>
                          </div>
                          <div class="search-heros col-xl col-lg-12 col-md-12 mt-2 ml-auto mt-lg-2">
                            <div class="form-group d-flex mb-2">
                                <label class="mr-2 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchTextRegion"
                                [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </div>
                          </div>
                        </div>

                          <div class="table-responsive">
                              <table class="table table-bordered" id="usertabledealer">
                              <thead>
                                  <tr>
                                     <th  (click)="sort('regionName')"><small>Region Name</small><i class="fa fa-fw fa-sort"></i></th>
                                     <th><small>Date Received  </small></th>
                                     <th style="text-align: right;"><small>Active AITHR HUB Vehicles   </small></th>                                   
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let statisticsRegionWiselistData of StatisticsRegionWiselist | slice: (pageRegion-1) * pageRegionSize : pageRegion * pageRegionSize; index as i">
                                                                                                                                
                                  <td style="color: #007bff;"><a   (click)="GetUnitDetails(statisticsRegionWiselistData.regionName,'Region',statisticsRegionWiselistData.regionName);"> {{ statisticsRegionWiselistData.regionName }} </a> </td>
                                    <td style="min-width:120px;"> {{ statisticsRegionWiselistData.lastFeedReceivedOn | date:'medium' }}</td>
                                    <td style="text-align: right;"> {{ statisticsRegionWiselistData.totalCount }}</td>

                                  </tr>
                            
                              </tbody>
                              </table>
                              <br>
                              <ngb-pagination [(page)]="pageRegion"
                              [pageSize]="pageRegionSize"
                              [collectionSize]="StatisticsRegionWiselist.length" aria-label="Default pagination"></ngb-pagination>                            
                          </div>
                      </div>
                  </div>
               </div>
              </div>
          </div>
            <div class="col-md-6">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">
                  <h5 class="card-title text-white text-uppercase mb-0">Vehicles Details Dealer Wise</h5>
                 </div>
                 <div class="card-body p-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto documentbtn">
                                  <ul class="list-unstyled mb-1">
                                    <li><button class="btn btn-secondary btn-sm mb-1" (click)="copytable('usertabledealer')">Copy</button></li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1" (click)="exportexcelDealer()">Excel</button>
                                    </li>
                                    
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1" (click)="downloadPdfDealer()">PDF</button>
                                    </li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-1" (click)="printTableDealer()">Print</button>
                                    </li>
                                  </ul>
                                </div>
                                <div class="search-heros col-xl col-lg-12 col-md-12 mt-2 ml-auto mt-lg-2">
                                    <div class="form-group d-flex mb-2">
                                        <label class="mr-2 mt-2">Search:</label>
                                        <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" id="usertabledealer">
                                <thead>
                                    <tr>
                                        <th  (click)="sortdealer('dealerName')"><small>Dealer Name</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('regionName')"><small>Region</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th style="min-width:120px;"><small>Date Received  </small></th>
                                       <th style="text-align: right;"><small>Active AITHR HUB Vehicles   </small></th>                                     
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let StatisticsDealerWiselistdata of StatisticsDealerWiselist | filter:searchText | slice: (pageDealer-1) * pageDealerSize : (pageDealer-1) * pageDealerSize + pageDealerSize; let i = index; let odd = odd">
                                    <td style="color: #007bff;"><a   (click)="GetUnitDetails(StatisticsDealerWiselistdata.dealerId,'Dealer',StatisticsDealerWiselistdata.dealerName);"> {{ StatisticsDealerWiselistdata.dealerName }} </a> </td>
                                      <td>{{ StatisticsDealerWiselistdata.regionName }}</td>
                                      <td style="min-width:120px;"> {{ StatisticsDealerWiselistdata.lastFeedReceivedOn | date:'medium' }}</td>
                                      <td style="text-align: right;"> {{ StatisticsDealerWiselistdata.totalCount }}</td>

                                    </tr>
                                </tbody>
                                </table>
                                <br>
                           <ngb-pagination [collectionSize]="StatisticsDealerWiselist.length" [(page)]="pageDealer" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
            </div>
          
        </div>

  <!-- Vehicles Details Region Wise hide show block  -->
  <div class="row mt-3" *ngIf="showUnitDetail ==='true'">
    <div class="col-md-12">
      <div class="card card-raised h-100">
        
        <div class="card-header card-raised bg-info text-white">
          <div class="row justify-content-between align-items-center">
               <div class="col-auto">
                <h5 style="font-size:14px;" class="card-title text-white text-uppercase d-inline-block mb-0 pt-1">Unit Details {{ TitleValue}} Wise</h5>
                <button class="btn btn-sm btn-warning ml-4" (click)="HideDetails();">Hide Details</button>
              </div>
              <div class="col-auto mr-auto">
                <h6 class="mt-0 counter text-white mb-0 pt-1"> <span class="mr-2">{{TitleValue}} Name:</span>{{ValueDealerName}}</h6>
              </div>
              <div class="col-auto">

                  <div class="row justify-content-between align-items-center">
                    <div class="col-auto px-2 pt-1">
                    
                        <h6 class="mt-0 counter text-white mb-0"> <span class="mr-2">Total Count:</span>{{UnittotalCount}}</h6>
                    </div>
                    <div class="search-heros col-xl col-md ml-auto mt-2 mt-lg-0">
                      <div class="form-group d-flex mb-md-0 mb-2">
                        <label class="mr-2 mt-2">Search:</label>
                        <input class="form-control" type="text" name="search" [(ngModel)]="searchUnitText"
                          [ngModelOptions]="{standalone: true}" autocomplete="off">
                      </div>
                    </div>
                    
                  </div>
            </div>
          </div>

         

        </div>

        <div class="card-body p-4">
          <div class="row">
            <div class="col-12">
              <div class="table-responsive">
                <table class="table table-bordered" id="">
                  <thead>
                    <tr>
                      <th (click)="sortUnit('vin')" style="text-align: left;">VIN<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('stockNumber')" style="text-align: left;"> Stock Number<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('carYr')" style="text-align: left;">Year<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('mileage')" style="text-align: left;">Mileage<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('make')" style="text-align: left;">Make<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('model')" style="text-align: left;">Model<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('trim')" style="text-align: left;">Trim<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('body')" style="text-align: left;">Body<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('colour')" style="text-align: left;">Colour<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('listedPrice')" style="text-align: right;">ListedPrice<i class="fa fa-fw fa-sort"></i></th>
                      <th (click)="sortUnit('dealerCost')" style="text-align: right;">Dealer Cost<i class="fa fa-fw fa-sort"></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    
                    <tr *ngFor="let UnitDetailsdata of UnitDetails | filter:searchUnitText | slice: (pageUnit-1) * pageUnitSize : (pageUnit-1) * pageUnitSize + pageUnitSize; let i = index; let odd = odd">
                   <td style="text-align: left;">{{ UnitDetailsdata.vin }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.stockNumber }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.carYr }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.mileage }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.make }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.model }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.trim }}</td>
                   <td style="text-align: left;"> {{ UnitDetailsdata.body }}</td>
                   <td style="text-align: left;">{{ UnitDetailsdata.colour }}</td>
                   <td style="text-align: right;">{{ UnitDetailsdata.listedPrice | currency }}</td>
                   <td style="text-align: right;">{{ UnitDetailsdata.dealerCost | currency}}</td>
                   
                    </tr>
  
                  </tbody>
                 </table>
                 <br>
                 <ngb-pagination [collectionSize]="UnitDetails.length" [(page)]="pageUnit" [maxSize]="5" [rotate]="true"
                 [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
              </div>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>
  
  <!-- Vehicles Details Region Wise hide show block end-->


    </div>
    <!-- <app-footer id="secondfooter"></app-footer> -->
</section>








