import { Component, ElementRef, OnInit, TemplateRef, ViewChild, Injector } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import { AddrepoService } from '@app/_services/addrepo.service';
import { AddgroupService } from '../_services/addgroup.service';
import { ListDealersService } from '../_services/list-dealers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';

import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-US';
import * as moment from 'moment'
import { NgbDate, NgbDateStruct, NgbDateAdapter, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { DashboardService } from '@app/_services/dashboard.service';
import { ExportToCsv } from 'export-to-csv';

import * as $ from 'jquery';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import * as XLSX from 'xlsx';
import { LenderService } from '@app/_services/lender.service';

import "rxjs/add/operator/map";
import "rxjs/add/operator/switchMap";
@Component({
  selector: 'app-list-dealers',
  templateUrl: './list-dealers.component.html',
  styleUrls: ['./list-dealers.component.less']
})
export class ListDealersComponent implements OnInit {

  AddDealersForm: FormGroup;
  private notifier: NotifierService;

  private onChange: any = () => { }
  private onTouched: any = () => { }
  private value: string = '';
  groupNameValueCheck: any;
  groupNameValueVaild: any;
  datetimevar = new Date();
  EmailReadyOnly: boolean = false;
  ExcelsheetData = 'ExcelSheet.xlsx';
  // private ngControl: NgControl;
  config: any;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private lenderService: LenderService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private confirmModaladvancedealerService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private addRepoService: AddrepoService,
    private listDealersService: ListDealersService,
    private globalcodeService: GlobalcodeService,
    private router: Router,
    notifier: NotifierService,
    private datePipe: DatePipe,
    private spinner: NgxSpinnerService,
    private dashboardService: DashboardService
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");

  }
  days: any;
  DealerFilterValue:any;
  dateCheck: Date;
  checkDates: Date;
  selectedgroupIds: string[];
  selectedGroupId: any;
  selectedGroupIds: any;
  datePickerConfig = { 'format': 'YYYY-MM-DD', 'firstDayOfWeek': 'mo' };
  deleting = false;
  modalRef: BsModalRef;
  adavncesettingpopupmodalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  // page = 1;
  // pageSize = 10;
  loading = false;
  submitted = false;
  //IsTokenGeneratedTrue = false;
  GeneratedTokenResponse: any;
  GWVendorKey: any;
  Dealerslist = [];
  deleteitem: any = 0;
  edititemid: any = 0;
  editvaluename: any;
  updateid: any;


  //select position

  selectedPosition: any;

  Grouplist = [];
  IsAithrhubng: boolean = false;
  IsAithrxng: boolean = false;
  IsAithrMarketPlaceng: boolean = false;
  subscriptionlist = [];

  Provincelist = [];
  Representativelist = [];
  GroupLists = [];
  groupid = 0;
  dealersid = 0;
  dealersname = "";
  Gid = 0;
  Did = 0;
  Rid = 0;
  email = "";
  activeon = { year: 1789, month: 7, day: 14 };
  activeunity = { year: 1789, month: 7, day: 14 };;
  activeonserver = new Date();
  activeunityserver = new Date();
  city = "";
  province = "";
  AssociatedUser = "";

  address = "";
  postalcode = "";
  phonenumber = "";
  AithrhubContactName = "";
  DealerContactName = "";
  GlobalVendorKeyValue="";
  DealerContactNumber = "";
  DealerContactEmail = "";
  dealerssubscription = "";
  inventorycheck = "";
  globalwarrantycheck="";
  IncludeInventorySourcecheck="";
  TotalRecord: any;
  userpopuplabel: string = "Add Dealer";

  DealerIdValue: any;
  SycnDealerName: any;
  SycnDealerId : any;
  ActivatedDetail: any;
  DealerNameValue: any;
  DealerPrimayId: any;
  rolevalue: any; 
  isCheckedlender: boolean = true;
  userEmail: any;
  userName: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  ListofPendingUnitsData = 'ListOfDealers.xlsx';
  pageSizes = [10, 20, 30, 40, 50, 60, 70];

  lenderlistsFilter: any[] = [];
  @ViewChild('ConfirmBoxActivate', { static: false }) ConfirmBoxActivate: ElementRef;
  @ViewChild('ConfirmBoxActivateUser', { static: false }) ConfirmBoxActivateUser: ElementRef;
  //for new paging start
  DeleteActivateDealerid:any;
  changeStatusOpcode:any;
  premiumData: any[] = [];
  paginateData: any[] = [];
  // source$: Observable<any>;
  page = 1;
  pageSize = 10;
  collectionSize = 0;



  //activeondate = moment(new Date()).format('MM/DD/YYYY HH:mm');
  //activeondate: NgbDateStruct = { year: 1789, month: 7, day: 14 };
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Dealerslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.Dealerslist);
  }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  onChangeSubscription(event) {

    this.GetVechileDetailByVIN(event);
  }
  GetVechileDetailByVIN(GlobalCodeID: any) {
    var modelGroup = {
      "GlobalCodeID": GlobalCodeID
    }
    this.globalcodeService.getGlobalcodesubscriptionlistbyID(modelGroup)
      .subscribe(
        (data: any) => {

          this.days = data[0].noOfDays;
          var daysNo = this.days;
          //  this.dateCheck = new Date();
          //  this.dateCheck.setDate( this.dateCheck.getDate() + 8 );
          //  alert(this.dateCheck);
          this.activeunity = this.currentDateBind(daysNo);

        },
        error => {
        });


  }



  public currentCheckDate(date: any) {

    var todayDate = new Date(date);
    // this.checkDates = this.datePipe.transform(todayDate, "yyyy-MM-dd")
    return todayDate;
  }

  onAithrUsers(event, value): void {


    // var isChecked=true;
    var isChecked = event.currentTarget.checked;
    if (value == 1) {
      this.IsAithrhubng = isChecked;
    }
    if (value == 2) {
      this.IsAithrxng = isChecked;
    }
    if (value == 3) {
      this.IsAithrMarketPlaceng = isChecked;
    }
  }

  public currentDateBind(Days: any) {

    this.dateCheck = new Date();
    this.dateCheck.setDate(this.dateCheck.getDate() + Days);
    this.activeunityserver = this.dateCheck;
    return {
      year: this.dateCheck.getFullYear(),
      month: this.dateCheck.getMonth() + 1,
      day: this.dateCheck.getDate()
    }
  }
  printTable() {
    var prepare = [];
    this.Dealerslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      tempObj.push(e.leftdays);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>Dealer Name</th><th>Group Name</th><th>Region/Province</th><th>Phone</th><th>Email</th><th>Postal Code</th><th>Active On</th><th>Active Until</th><th>Subscription Days Left</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  // downloadPdf() {
  //   var prepare=[];
  //   this.Dealerslist.forEach(e=>{
  //     var tempObj =[];
  //     tempObj.push(e.dealerName);
  //     tempObj.push(e.groupName);
  //     tempObj.push( e.globalCodeName);
  //     tempObj.push( e.dealerPhone);
  //     tempObj.push( e.dealerEmail);
  //     tempObj.push(e.postalCode);
  //     tempObj.push(e.activeStart);
  //     tempObj.push(e.activeUntil);
  //     prepare.push(tempObj);
  //   });
  //   const doc = new jsPDF('l','pt', 'a4');
  //   autoTable(doc, {
  //     head: [['Dealer Name','GroupName','Region','DealerPhone','Dealer Email','Postal Code','ActiveStart','ActiveUntil','ListedPrice','DealerCost']],
  //     body: prepare,
  //     didDrawCell: (data) => { },
  // });
  //   doc.save('ListofDealers' + '.pdf');
  // }

  exportexcel() {

    const prepare = [];
    this.Dealerslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      tempObj.push(e.leftdays);
      prepare.push(tempObj);
    });

    // Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['Dealer Name', 'Group Name', 'Region/Province', 'Phone', 'Email', 'Postal Code', 'Active On', 'Active Until', 'Subscription Days Left'],
      ...prepare
    ]);

    //Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofDealers');

    // Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofDealers.xlsx');
  }
  ColorTheme: any;
  ngOnInit(): void {
  
   this.DealerFilterValue="All";
    this.CreateInitiateFrom();
    this.spinner.show();
    //this.SelectedAllCheckregion = true;
    // this.GetDealerslist();
    this.Getgrouplist();
    this.GetGlobalcodesubscriptionlist();
    this.GetProvincelist();
    this.GetRepresentativelist();
    this.GetGroupList();
    this.ActivatedDetail = "false";
    this.userName = localStorage.getItem('UserName');
    this.userEmail = localStorage.getItem('email');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    if (this.rolevalue == "SuperAdmin") {
      this.GetDealerslist();
    } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
      this.GetDealersByDealerName();
    }

  }


  // exportexcel(): void
  // {
  //   debugger;
  //   /* pass here the table id */
  //   let element = document.getElementById('usertable');
  //   const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);

  //   /* generate workbook and add the worksheet */
  //   const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

  //   /* save to file */
  //   XLSX.writeFile(wb, this.ExcelsheetData);

  // }
 
  downloadPdf() {
    var prepare = [];
    this.Dealerslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.groupName);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.dealerPhone);
      tempObj.push(e.dealerEmail);
      tempObj.push(e.postalCode);
      tempObj.push(e.activeStart);
      tempObj.push(e.activeUntil);
      tempObj.push(e.leftdays);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['Dealer Name', 'Group Name', 'Region/Province', 'Phone', 'Email', 'Postal Code', 'Active On', 'Active Until', 'Subscription Days Left']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Dealer_List' + '.pdf');
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.GetDealerslist();
  }
  getValues(val: any) {
    this.groupNameValueVaild = "True";
    this.selectedGroupIds = val.globalCodeID;
  }

  // helper to find out today's year, month, day
  public currentDate() {
    var todayDate = new Date();

    return {
      year: todayDate.getFullYear(),
      month: todayDate.getMonth() + 1,
      day: todayDate.getDate()
    }
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  // function for creating from
  CreateInitiateFrom() {

    this.GeneratedTokenResponse = "";
   // this.GWVendorKey="";
    this.AddDealersForm = new FormGroup({
      groupidctrl: this.fb.control('', Validators.required),
      dealersname: this.fb.control('', Validators.required),
      activeon: this.fb.control(new Date(), Validators.required),
      activeunity: this.fb.control(new Date(), Validators.required),
      city: this.fb.control('', Validators.required),
      province: this.fb.control('', Validators.required),
      AssociatedUser: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      // postalcode: this.fb.control('', Validators.required),
      postalcode: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
      // phonenumber: this.fb.control('', [
      //   Validators.required,
      //   Validators.pattern("^[0-9]*$"),
      //   Validators.minLength(10),
      //   Validators.maxLength(10)
      // ]),
      phonenumber: this.fb.control('', [
        Validators.required,
        // Validators.pattern("^\\D*([0-9]\\D*){10}$"),
        //Validators.pattern(/^\(\d{3}\)\s*\d{3}-\d{4}$/),
        Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      //email: this.fb.control('', [Validators.email,Validators.required]),
      //email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@gmail\.com$')]),
      dealerssubscription: this.fb.control('', Validators.required),
      inventorycheck: this.fb.control(true, Validators.required),
      globalwarrantycheck:this.fb.control(true, Validators.required),
      IncludeInventorySourcecheck:this.fb.control(true, Validators.required),

      AithrhubContactName: this.fb.control(true, Validators.required),
      DealerContactName: this.fb.control(true, Validators.required),
      GlobalVendorKeyValue:this.fb.control(true),
      DealerContactNumber: this.fb.control('', [
        Validators.required,
        // Validators.pattern("^\\D*([0-9]\\D*){10}$"),
        //Validators.pattern(/^\(\d{3}\)\s*\d{3}-\d{4}$/),
        Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      DealerContactEmail: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),

      IsAithrxng: this.fb.control(true, Validators.required),
      IsAithrMarketPlaceng: this.fb.control(true, Validators.required),
      IsAithrhubng: this.fb.control(true, Validators.required),
      // controls for actiavte
      DealerId: this.fb.control(true, Validators.required),
      DealerName: this.fb.control(true, Validators.required),
      picture: this.fb.control(''),
     
    });
  }

  //upload pic
  onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.labelImport.nativeElement.innerText = file.name;
      document.getElementById("choosefile").innerHTML = file.name;
      this.AddDealersForm.patchValue({
        picture: file,
      });
    }
  }

  GenerateToken() {
    var modelGroup = {
      "Token": '',
      "DelaerId": this.dealersid
    }


    this.dashboardService.GenerateToken(modelGroup)
      .subscribe(
        (data: any) => {
          if (data.isExit == "1") {
            this.showNotification("success", "Already Exist");
          }
          if (data.token != null && data.token != undefined) {
            this.GeneratedTokenResponse = data.token;
            $('#TokenIn').val(this.GeneratedTokenResponse);

            this.showNotification("success", "Token Generated successfully");
          }

        },
        error => {
        });




  }


  openActivateModel(template: TemplateRef<any>, itemid: any, id: any, check: any) {
    if (check == "2") {
      this.ActivatedDetail = "true";
      this.GetDealerActivationDetails(id.toString());
    } else {
      this.ActivatedDetail = "false";
    }
    this.DealerNameValue = itemid;
    this.DealerPrimayId = id
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  ActivateDealer() {

    this.submitted = true;

    if (this.AddDealersForm.controls.DealerId.invalid) {
      this.isValid = false;
      return;
    } else if (this.AddDealersForm.controls.DealerName.invalid) {
      this.isValid = false;
      return;
    }

    else {
      var modelGroup = {
        "id": this.AddDealersForm.controls.DealerId.value,//this.DealerIdValue,
        "DealerName": this.DealerNameValue,
        "DealerPrimayId": this.DealerPrimayId.toString()
      }


      this.dashboardService.ActivateDealers(modelGroup)
        .subscribe(
          (data: any) => {

            this.GetDealerslist();

            this.showNotification("success", "Dealer Activated successfully");
            this.CreateInitiateFrom();
            this.modalRef.hide();
            this.closeEditModel();
            this.DealerIdValue = "";

          },
          error => {
          });
    }
  }


  reinitiatengmodel() {
    this.groupid = 0;
    this.dealersid = 0;
    this.dealersname = "";
    this.AssociatedUser = "";
    this.activeon = this.currentDate();
    this.activeunity = this.currentDate();
    this.city = "";
    this.province = "";
    this.AssociatedUser = "";
    this.address = "";
    this.postalcode = "";
    this.phonenumber = "";
    this.dealerssubscription = "";
    this.inventorycheck = "";
    this.globalwarrantycheck = "";
    this.IncludeInventorySourcecheck="";
    this.email = "";
    this.IsAithrhubng = false;
    this.IsAithrxng = false;
    this.IsAithrMarketPlaceng = false;

    this.AithrhubContactName = "";
    this.DealerContactName = "";
    this.GlobalVendorKeyValue="";
    this.DealerContactNumber = "";
    this.DealerContactEmail = "";
    this.GWVendorKey="";

  }

  //convert server date to date format
  ConvertserverdatetoDatetime(datadate) {

    var momentDate = moment(datadate);

    var dateactive = new Date(datadate);
    var localstringdate = dateactive.toLocaleDateString();
    return {
      year: dateactive.getFullYear(),
      month: dateactive.getMonth() + 1,
      day: dateactive.getDate()
    }


  }


  Getgrouplist() {
    this.addgroupService.Getgrouplist()
      .subscribe(
        (data: any) => {

          this.Grouplist = data;
        },
        error => {
        });
  }

  GetGlobalcodesubscriptionlist() {
    this.globalcodeService.getGlobalcodesubscriptionlist()
      .subscribe(
        (data: any) => {

          this.subscriptionlist = data;
        },
        error => {
        });
  }

  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  GetRepresentativelist() {
    this.globalcodeService.getRepresentativelist()
      .subscribe(
        (data: any) => {
          this.Representativelist = data;
        },
        error => {
        });
  }

  GetGroupList() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          this.GroupLists = data;
        },
        error => {
        });
  }


  GetDealerActivationDetails(DealerId) {
    var modelGroup = {
      "DealerId": DealerId
    }
    this.globalcodeService.getDealerActivated(modelGroup)
      .subscribe(
        (data: any) => {

          this.DealerIdValue = data[0].dealerID;
        },
        error => {
        });
  }

  openModalSychedModel(template: TemplateRef<any>, itemid: any) {
  
    this.SycnDealerName = itemid.dealerName;
    this.SycnDealerId = itemid.createdBy;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  // function for open model pop up
  openModal(template: TemplateRef<any>, itemid: any) {
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  // edit functionality
  // function for open model pop up
  EditModalpopup(template: TemplateRef<any>, itemid: any) {
    this.CreateInitiateFrom();
    this.reinitiatengmodel();

    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.addgroupService.EditGroupList(modelGroup)
      .subscribe(
        data => {
          this.editvaluename = data.groupName;
          this.updateid = data.id;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
    this.DealerIdValue = "";
  }

  closepopupmodel() {
    this.AddDealersForm.invalid
  }
 
  //add and edit popup
  openAddModel(targetModal, id) {
   
    this.userpopuplabel = "Add  Dealer";
    this.EmailReadyOnly = false;
    if (id != undefined && id != "") {
      this.userpopuplabel = "Edit Dealer";
      // this.EmailReadyOnly = true;
      //this.AddDealersForm.controls['email'].disable();
      this.edititemid = id;
      var modelGroup = {
        "id": id
      }
      this.listDealersService.EditDealers(modelGroup)
        .subscribe(
          (data: any) => {
            debugger;
            $('#TokenIn').val(data.dealerkey);
           
            this.lenderlistsFilter = data.aixDealerLenderInfo;
            this.GlobalVendorKeyValue = data.gwVendorKey;
            

            for (let item of this.lenderlistsFilter) {

              if (item.dealerchecked) {
                item.checked = true;
                this.isCheckedlender=true;
              }
              else {
                item.checked = false;
                this.isCheckedlender=false;
              }
            }

            this.FillNgmodels(data)
          },
          error => {
          });


      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
    } else {
      this.edititemid = 0;
      this.selectedGroupId = [];
      this.openAddModelAdvancesetting("", this.edititemid);
      this.CreateInitiateFrom();
      this.reinitiatengmodel();
      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
    }

  }


  ///open popup advance
  openAddModelAdvancesetting(targetModal, id) {
   

    this.EmailReadyOnly = false;
    if (id != undefined && id != "" && id != 0) {

      // this.EmailReadyOnly = true;
      //this.AddDealersForm.controls['email'].disable();
      // this.edititemid = id;
      // var modelGroup = {
      //   "id": id
      // }
      //   this.lenderService.getAllLendersList()
      //  .subscribe(
      //    (data: any) => {
      //     debugger;
      //      this.lenderlistsFilter = data;

      //      for (let item of this.lenderlistsFilter) {
      //       item.checked=false;
      //     }

      //    },
      //    error => {
      //    });


      this.adavncesettingpopupmodalRef = this.confirmModaladvancedealerService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
    } else {
      //this.edititemid = 0;
      //this.selectedGroupId = [];
      //this.CreateInitiateFrom();
      // this.reinitiatengmodel();

      this.lenderService.getAllLendersList()
        .subscribe(
          (data: any) => {
            
            this.lenderlistsFilter = data;

            for (let item of this.lenderlistsFilter) {
              item.checked = true;
              item.dealerchecked = true;
            }

          },
          error => {
          });
      if (targetModal != "") {
        this.adavncesettingpopupmodalRef = this.confirmModaladvancedealerService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
      }
      
    }

  }

  onCheckboxChange(item: any): void {
  
    item.checked = !item.checked;
    item.dealerchecked = item.checked;
    
    this.lenderlistsFilter.forEach((item, index) => {
      if(this.lenderlistsFilter[index].dealerchecked == true)
      {
        this.isCheckedlender=true;
      }else
      {
        this.isCheckedlender=false;
      }
    })
    // Add any additional logic you want to perform when a checkbox changes
  }

  CheckallLenders(e)
  {

  if(e.target.checked==true)
  {
   //Assigning value selected false which will appera by deafult uncheck
   this.lenderlistsFilter.forEach((item, index) => {
    this.lenderlistsFilter[index].dealerchecked = true;
    this.lenderlistsFilter[index].checked = true;
  })
  }else{
   //Assigning value selected false which will appera by deafult uncheck
   this.lenderlistsFilter.forEach((item, index) => {
    this.lenderlistsFilter[index].dealerchecked = false;
    this.lenderlistsFilter[index].checked = false;
  })
  }
  
  }
  
  FillNgmodels(data) {
   debugger;
    this.selectedGroupIds = data.dGroupId;

    this.dealersid = data.id;
    this.groupid = data.dGroupId;
    this.selectedGroupIds = data.dGroupId;
    this.selectedGroupId = data.globalCodeName;

    this.dealersname = data.dealerName;
    this.activeon = this.ConvertserverdatetoDatetime(data.activeStart);
    this.activeunity = this.ConvertserverdatetoDatetime(data.activeUntil);
    this.activeunityserver = this.currentCheckDate(data.activeUntil);
    this.city = data.dealerCity;
    this.province = data.dealerRegion;
    this.AssociatedUser = data.associatedUserId
    this.address = data.dealerAddress;
    this.postalcode = data.postalCode;
    this.phonenumber = data.dealerPhone;
    this.dealerssubscription = data.isTrial;
    this.inventorycheck = data.isAithrHubInventory;
    this.globalwarrantycheck = data.isGlobalWarrantyClients;
    this.IncludeInventorySourcecheck = data.includeInventorySource; //new field
    this.email = data.dealerEmail;

    this.IsAithrhubng = data.isAithrhub;
    this.IsAithrxng = data.isAithrx;
    this.IsAithrMarketPlaceng = data.isAithrMarketPlace;

    this.AithrhubContactName = data.aithrhubContactName;
    this.DealerContactName = data.dealerContactName;
    this.DealerContactNumber = data.dealerContactNumber;
    this.DealerContactEmail = data.dealerContactEmail;


  }

  // for update the edited part
  update() {
    this.submitted = true;

    if (this.AddDealersForm.invalid) {
      this.isValid = false;
      return;
    }
    var modelGroup = {
      "id": this.updateid,
      "GroupName": this.editvaluename,
    }
    this.addgroupService.UpdateGroupName(modelGroup)

      .subscribe(
        (data: any) => {
          this.GetDealerslist();
          this.submitted = false;
          //this.UpdateGroupName();
          this.CreateInitiateFrom();
          //alert("data changed")
          this.showNotification("success", "Data updated successfully");
          this.EmailReadyOnly = false;
          this.modalRef.hide();
          // this.Grouplist = data;
        },
        error => {
        });

    //this.updateid=id;
    // this.addgroupService.EditGroupList(updateid);
  }
  get f() { return this.AddDealersForm.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }

  ValidPostalCodeTextBox(event: KeyboardEvent) {
    
    this.isValid = true;
    this.postalcode += event.key.toUpperCase();
    event.preventDefault();
  }

  get phoneNumberControl() {
    return this.AddDealersForm.get('phonenumber');
  }

  get DealerphoneNumberControl() {
    return this.AddDealersForm.get('DealerContactNumber');
  }

  onKeyUpLower(x) {

    this.AddDealersForm.get('email').setValue(x.target.value.toLowerCase());
  }

  onKeyUpLowerDealerContactEmail(x) {

    this.AddDealersForm.get('DealerContactEmail').setValue(x.target.value.toLowerCase());
  }

  onSubmit() {
    
    this.groupNameValueCheck = this.AddDealersForm.controls['groupidctrl'].status;
    this.submitted = true;

    // Check if the representative is empty
    const representativeField = this.AddDealersForm.get('AssociatedUser');
    if (representativeField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    // Check if the dealer name is empty
    const dealerNameField = this.AddDealersForm.get('dealersname');
    if (dealerNameField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    // Check if the address is empty
    const addressField = this.AddDealersForm.get('address');
    if (addressField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    // Check if the city is empty
    const cityField = this.AddDealersForm.get('city');
    if (cityField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    // Validate email field

    const emailField = this.AddDealersForm.get('email');
    if (emailField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    const DealerContactNumberField = this.AddDealersForm.get('DealerContactNumber');
    if (DealerContactNumberField.value.trim() === '') {
      this.isValid = false;
      return false;
    }

    const dealerssubscriptionNumberField = this.AddDealersForm.get('dealerssubscription');
    if (dealerssubscriptionNumberField.value === '') {
      this.isValid = false;
      return false;
    }


    // Check phone number validity
    // const phoneNumberField = this.AddDealersForm.get('phonenumber');
    // if (phoneNumberField.value && phoneNumberField.invalid) {
    //   this.isValid = false;
    //   return;
    // } else if (!phoneNumberField.value) {
    //   this.isValid = false;
    //   return;
    // }

    // Check phone number validity
    const phoneNumberField = this.phoneNumberControl;
    if (phoneNumberField.value && phoneNumberField.invalid) {
      this.isValid = false;
      return;
    } else if (!phoneNumberField.value) {
      this.isValid = false;
      return;
    }

    // Check postal code validity
    const postalCodeField = this.AddDealersForm.get('postalcode');
    if (postalCodeField.value && postalCodeField.invalid) {
      this.isValid = false;
      return;
    } else if (!postalCodeField.value) {
      this.isValid = false;
      return;
    }

    if (this.AddDealersForm.invalid && this.groupNameValueCheck == "INVALID") {
      this.isValid = false;
      this.groupNameValueVaild = "False";
      return;
    } else {

      const formData = new FormData();
      for (const key of Object.keys(this.AddDealersForm.value)) {
        var value = this.AddDealersForm.value[key];
        if (key == "activeon") {

          var activeonserver = this.datePipe.transform(this.activeonserver, "yyyy-MM-dd"); //output : 2018-02-13
          value = activeonserver.toString();
        }
        if (key == "activeunity") {
          // var activeunityserver= this.onDateSelectactiveunity(this.activeunityserver);

          var activeunityserver = this.datePipe.transform(this.activeunityserver, "yyyy-MM-dd"); //output : 2018-02-13

          value = activeunityserver;
        }
        if (key == "groupidctrl") {

          value = this.selectedGroupIds;
        }
        formData.append(key, value);
      }
      formData.append("id", this.edititemid); //assigning dealerid for check its for add or updated
      formData.append("UserEmail", this.userEmail);
      formData.append("UserName", this.userName);

      formData.append("TokenGenerated", this.GeneratedTokenResponse);
      formData.append("GWVendorKey", this.GWVendorKey);


      formData.append("Advancesettingdealer", JSON.stringify(this.lenderlistsFilter));

      var modelGroup = {
        "DGroupId": this.selectedGroupIds, //this.AddDealersForm.controls.groupidctrl.value,
        "id": this.dealersid,
        "ActiveStart": this.activeonserver,
        "ActiveUntil": this.activeunityserver,
        "DealerName": this.AddDealersForm.controls.dealersname.value,
        "DealerCity": this.AddDealersForm.controls.city.value,
        "DealerRegion": this.AddDealersForm.controls.province.value,
        "DealerAddress": this.AddDealersForm.controls.address.value,
        "PostalCode": this.AddDealersForm.controls.postalcode.value,
        "DealerPhone": this.AddDealersForm.controls.phonenumber.value,
        "DealerEmail": this.AddDealersForm.controls.email.value,
        "IsTrial": this.AddDealersForm.controls.dealerssubscription.value,
        "inventory": this.AddDealersForm.controls.inventorycheck.value,
        "IsGlobalWarrantyClients": this.AddDealersForm.controls.globalwarrantycheck.value,
        "IncludeInventorySourcecheck": this.AddDealersForm.controls.IncludeInventorySourcecheck.value,
        "Picture": this.AddDealersForm.controls.picture.value,
        "IsAithrhub": this.AddDealersForm.controls.IsAithrhubng.value,
        "IsAithrx": this.AddDealersForm.controls.IsAithrxng.value,
        "IsAithrMarketPlace": this.AddDealersForm.controls.IsAithrMarketPlaceng.value,
        'TokenGenerated': this.GeneratedTokenResponse,
        'Advancesettingdealer': this.lenderlistsFilter,
        'GWVendorKey': this.GWVendorKey,
        //'IsGlobalWarrantyClients':this.globalwarrantycheck
      }


      this.listDealersService.SaveaddDealers(formData)
        .subscribe(
          (data: any) => {
           
            if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
              this.GetDealerslist();
            } else {
              this.GetDealersByDealerName();
            }
            this.EmailReadyOnly = false;
            //this.GetDealerslist();
            this.submitted = false;
            this.selectedGroupId = [];
            this.showNotification("success", "Data saved successfully")
            this.CreateInitiateFrom();
            this.modalRef.hide();
            //  this.modalcloseOpen.hide();
            this.closeadvancesettingpopup();
            this.closeEditModel();
            this.closepopupmodel();
           // this.modalcloseOpen.hide();

            this.reinitiatengmodel();

            //this.modalRef.hide() ;
            // this.Grouplist = data;
          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }
  }


  //for date picker
  onDateSelectactiveon(event) {

    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.activeonserver = new Date(finalDate);
  }


  onChangedealerselect(template: TemplateRef<any>, itemid: any) {
   
    if (itemid == "Lender") {
      this.openAddModelAdvancesetting(template, this.edititemid);
    }
  }


  //for date picker
  onDateSelectactiveunity(event) {

    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    let finalDate = year + "-" + month + "-" + day;
    this.activeunityserver = new Date(finalDate);
  }



  saveadvancestting() {
   
    if (this.edititemid > 0) {
      const formData = new FormData();
      formData.append("id", this.edititemid); //assigning dealerid for check its for add or updated
      formData.append("Advancesettingdealer", JSON.stringify(this.lenderlistsFilter));

      this.listDealersService.updateadvanceSettingDealer(formData)
        .subscribe(
          (data: any) => {
            this.showNotification("success", "lender assign successfully.")
          },

          error => {
          });

    }

    this.adavncesettingpopupmodalRef.hide();

  }

  closeadvancesettingpopup() {
    this.lenderlistsFilter = [];
    this.adavncesettingpopupmodalRef.hide();
  }

  CloseAddmodel() {
    
    this.edititemid = 0;
    this.modalRef.hide();
    //this.modalcloseOpen.hide();
    this.groupNameValueVaild = "True";
    this.submitted = false;
    this.isValid = true;

    this.GeneratedTokenResponse = '';
    // this.ngOnInit();
    this.CreateInitiateFrom();
  }

  // function for confirm  delete
  confirm(): void {

    this.deleteitem;
    var modelGroup = {
      "id": this.deleteitem
    }
    this.listDealersService.Delete(modelGroup)
      .subscribe(
        data => {

          this.showNotification("success", "Deleted successfully")
          this.GetDealerslist();
        },
        error => {
        });

    this.modalRef.hide();

  }

  confirmSynced(): void {
    this.spinner.show();
    this.deleteitem;
    var modelGroup = {
      "Dealers":this.SycnDealerId.toString(), //this.SycnDealerName,
      "DealerId": this.SycnDealerId.toString(),//this.SycnDealerId.toString()
      "DealerName": this.SycnDealerName.toString()//this.SycnDealerId.toString()
    }
    this.listDealersService.getSyncedDealerName(modelGroup)
      .subscribe(
        data => {
          this.spinner.hide();
          this.showNotification("success", "Synced successfully")
          
        },
        error => {
        });

    this.modalRef.hide();

  }

  declineSynced(): void {
    this.modalRef.hide();
  }
  decline(): void {
    this.modalRef.hide();
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }


  //Calling get list of group on page load


  GetDealersByDealerName() {

    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.listDealersService.getDealersByDealerName(modelGroup)
      .subscribe(
        data => {



          this.premiumData = data;  //keep original data into premimum

          this.Dealerslist = data;
          this.collectionSize = this.premiumData.length;
          this.getPremiumData();



          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });


  }


  //This function is using for pagination and search start
  getPremiumData() {

    var collection = this.collectionSize;
    var searchtextvalue = this.searchText;

    if (searchtextvalue == undefined || searchtextvalue == "") {
      this.Dealerslist = this.premiumData
        .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      //this.collectionSize= this.Dealerslist.length;

    }
    else {
      this.GetSearchText(searchtextvalue);

      var serachdata = [];

      serachdata = this.Dealerslist;

      var selectedpagedata = serachdata.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);

      this.Dealerslist = selectedpagedata;
      this.collectionSize = serachdata.length;
    }
  }

  SerachPremiumDataFromTable(event) {

    this.page = 1;
    var collection = this.collectionSize;

    this.searchText = event.target.value;
    var searchtextvalue = this.searchText;
    this.GetSearchText(searchtextvalue);
    this.Dealerslist.slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
    this.collectionSize = this.Dealerslist.length;
    this.getPremiumData();

  }

  private GetSearchText(searchtextvalue: any) {

    //this.pageSize = pageSize;
    this.Dealerslist = this.findInValues(this.premiumData, searchtextvalue);
    // .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  findInValues(arr, value) {

    value = String(value).toLowerCase();
    return arr.filter(o =>
      Object.entries(o).some(entry =>
        String(entry[1]).toLowerCase().includes(value)
      )
    );
  }

  declineActivateDealer(): void {
    this.modalRef.hide();
  }
  confirmDeactivate(): void {
    this.modalRef.hide();
   this.ChangeDealerStatus();
  }

  ChangeDealerStatus() {
    
    var modelGroup = {
      "id": this.DeleteActivateDealerid,
      "changeStatusOpcode": this.changeStatusOpcode,
    }
    this.accountService.statusDealer(modelGroup)
      .subscribe(
        (data: any) => {

          this.showNotification("success", "Status changed successfully")
          if (this.rolevalue == "SuperAdmin") {
            this.GetDealerslist();
          } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
            this.GetDealersByDealerName();
          }
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }
  //This function is using for pagination and search End
  openModalActivate(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }
  DeactivateDealer(DealerId: any) {
    this.DeleteActivateDealerid = DealerId;
    this.changeStatusOpcode= "2";
    let el: HTMLElement = this.ConfirmBoxActivate.nativeElement;
    el.click();
    }

   AcivatededDealer(DealerId: any) {
      this.DeleteActivateDealerid = DealerId;
      this.changeStatusOpcode= "1";
      let el: HTMLElement = this.ConfirmBoxActivateUser.nativeElement;
      el.click();
      }
  

  GetDealerByFilter(value: any) {
    this.DealerFilterValue=value;
    this.GetDealerslist();
  }
  GetDealerslist() {
    this.spinner.show();

    var modelGroup = {
      "FilterValues": this.DealerFilterValue,
      
    }

    this.listDealersService.GetDealerslist(modelGroup)
      .subscribe(
        (data: any) => {

         
          this.premiumData = data;  //keep original data into premimum



          this.Dealerslist = data;
          this.collectionSize = this.premiumData.length;
          //this.pageSize=this.collectionSize ;
          this.getPremiumData();


          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  public bsValueChange(value) {
    this.writeValue(this.value);
    this.onTouched();
  }

  public writeValue(value: any) {
    this.value = this.transformDate(value);
    this.onChange(this.value);
    console.log('writeValue', 'old: ' + value, 'new: ' + this.value);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
    this.onChange(this.value); // for OnInit cycle
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    //
  }

  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }

}