<section id="admindashboard">
  <form class="col-12 px-0 mt-3" [formGroup]="AddLenderRateSheetForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-2">


          <div class="row justify-content-between align-items-center mb-2 mx-0">
            <div class="col-auto documentbtn">
              <ul class="list-unstyled mb-1">
                <li>
                  <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="copytable('usertable')">Copy</button>
                </li>

                <li>
                  <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                </li>

                <li>
                  <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                </li>
                <li>
                  <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                </li>
              </ul>
            </div>
            <div class="col-auto mr-auto">
              <div class="form-group mb-0">
                <div class="row">
                  <label class="col-form-label col-lg-auto px-1">Value Type:</label>
                  <div class="col-lg-auto">
                    <select class="form-control" name="region" 
                    formControlName="ValueTypeFilter" (change)="FilterLenderList()"
                      [(ngModel)]="ValueTypeFilter">
                     
                      <option value="0">Select Value Type</option>
                      <option *ngFor="let rolelistdata of Valuelist" [value]="rolelistdata.globalCodeName">
                      {{rolelistdata.globalCodeName}}</option>
                    </select>
                  </div>
                  <button id="resetbtn" class="btn bg-warning btn-sm mb-sm-0 rounded-pill" (click)="ResetFilter()">Reset</button>
                </div>
              </div>
            </div>
            <div class="search-heros col-md-auto mt-2 mt-md-0">
              <div class="row align-items-center justify-content-end">
                <div class="form-group d-flex mb-1">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                </div>
                <div class="form-group mb-0 ml-md-3">
                  <label style="padding:2px;"> Items per Page:</label>
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>


          <div class="row mt-2">
            <div class="col-md-12">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">


                  <div class="row justify-content-between align-items-center">
                    <div class="col-sm-6">
                      <h5 class="card-title text-white text-uppercase mb-0">Lender Rate Sheet</h5>
                    </div>
                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                      <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                    </div>
                  </div>

                </div>
                <div class="card-body p-4">
                  <div class="table-responsive">
                    <table id="usertable" class="table table-bordered mb-1" style="width:100%">
                      <thead>
                        <tr>

                          <th (click)="sort('lenderName')">Lender Name<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('planType')">Tier/Plan Type<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('condition1From')"> {{HeaderYearFrom}} FROM <i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('condition1Till')"> {{HeaderYearTill}}  Till<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('condition2From')"> {{OdometerFROMHeader}}</th>
                          <th (click)="sort('condition2Till')"> {{OdometerTillHeader}}</th>
                          <th (click)="sort('condition2Till')"> Search Type</th>
                          <th (click)="sort('condition2Till')"> Value Type</th>
                          <th (click)="sort('condition2Till')"> Return Value</th>
                          <th style="text-align:center;"><button type="button" class="btn btn-primary btn-sm text-white rounded-pill"
                              (click)="openLenderRateSheetModel(ModelAddLenderRates)">
                              <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                              Add Ledner Rate
                            </button></th>
                        </tr>
                      </thead>
                      <tbody>
                        <!-- <tr
                        *ngFor="let lenderrateSheetsdata of lenderRateSheets | filter:searchText | slice: (pageRep-1) * pageSizeRep : (pageRep-1) * pageSizeRep + pageSizeRep; let i = index; let odd = odd">
                        -->
                        <tr
                          *ngFor="let lenderrateSheetsdata of lenderRateSheets | filter:searchText | slice: (pageRep - 1) * pageSizeRep : (pageRep - 1) * pageSizeRep + pageSizeRep; let i = index; let odd = odd">
                          <td> {{ lenderrateSheetsdata.lenderName }}</td>
                          <td> {{ lenderrateSheetsdata.planType }}</td>
                          <td> {{ lenderrateSheetsdata.condition1From }}</td>
                          <td> {{ lenderrateSheetsdata.condition1Till }}</td>
                          <td *ngIf="OdometerFROMHeader !=='Province'" > {{ lenderrateSheetsdata.condition2From }}</td>
                          <td *ngIf="OdometerFROMHeader ==='Province'" > {{ lenderrateSheetsdata.globalCodeName }}</td>

                          <td *ngIf="OdometerFROMHeader !=='Province'" > {{ lenderrateSheetsdata.condition2Till }}</td>
                          <td *ngIf="OdometerFROMHeader ==='Province'" > {{ lenderrateSheetsdata.globalCodeName }}</td>

                          <!-- <td> {{ lenderrateSheetsdata.condition2Till }}</td> -->
                          <td> {{ lenderrateSheetsdata.searchType }}</td>
                          <td> {{ lenderrateSheetsdata.valueType }}</td>
                          <td *ngIf="ShowCbbValue ==='false'" > {{ lenderrateSheetsdata.returnValue }}</td>
                          <td *ngIf="ShowCbbValue ==='true'" > {{ lenderrateSheetsdata.cbbRetrunValueName }}</td>
                          <td style="text-align:center;">
                            <button class="btn bg-gradient-info btn-sm" title="Edit"
                              (click)="EditModalpopup(ModelAddLenderRates,lenderrateSheetsdata.id)"><i
                                class="fa-solid fa-pen-to-square"></i></button>
                            <button (click)="openModal(template,lenderrateSheetsdata.id)"
                              class="btn bg-gradient-danger btn-sm ml-2" title="Delete"><i
                                class="fa fa-trash"></i></button>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                    <br>
                    <!-- <ngb-pagination [collectionSize]="lenderRateSheets.length" [(page)]="pageRep" [maxSize]="5" [rotate]="true"
                    [ellipses]="false" [boundaryLinks]="true"></ngb-pagination> -->
                    <div class="col-12">
                      <div class="row justify-content-between align-items-center mx-0">
                        <div class="col-auto px-0">
                          <ngb-pagination [collectionSize]="lenderRateSheets.length" [(page)]="pageRep" [maxSize]="5"
                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                        <div class="form-group mb-0">
                          <label style="padding:2px;"> Items per Page:</label>
                          <select (change)="handlePageSizeChange($event)">
                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                              {{ size }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i
                        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Rate" placement="top"
                        ngbPopover="Are you sure you want to delete?"></i>
                    </a>
                    <ng-template #template>
                      <div class="modal-body text-center">
                        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                        <p>Are you sure you want to delete?
                        </p>
                        <p></p>
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                      </div>
                    </ng-template>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template #ModelAddLenderRates let-modal>
      <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Lender Rate Sheet</h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <section class="generate-report">
          <div class="row">
            <div class="form-group col-md-6">
              <label>Lender Name</label>
              <input type="text" formControlName="LenderID" class="form-control" placeholder="Lender Name"
                (keypress)="ValidTextBox($event)" [(ngModel)]="LenderID" readonly
                [ngClass]="{ 'is-invalid': submitted && f.LenderID.errors }" />
              <div *ngIf="submitted && f.LenderID.errors" class="invalid-feedback">
                <div *ngIf="f.LenderID.errors.required">Lender Name is required</div>
              </div>

            </div>
            <div class="form-group col-md-6">
              <label>Plan Type</label>
              <input type="text" formControlName="PlanType" class="form-control" placeholder="Plan Type"
                (keypress)="ValidTextBox($event)" [(ngModel)]="PlanType" readonly
                [ngClass]="{ 'is-invalid': submitted && f.PlanType.errors }" />
              <div *ngIf="submitted && f.PlanType.errors" class="invalid-feedback">
                <div *ngIf="f.PlanType.errors.required">Plan Type is required</div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label>Value Type</label>
              <select class="form-control" name="ValueType" [(ngModel)]="ValueType" (change)="SetSerachTypeValue();"
                formControlName="ValueType">

                <option value="0">Select Value Type</option>
                <option *ngFor="let rolelistdata of Valuelist" [value]="rolelistdata.globalCodeName">
                  {{rolelistdata.globalCodeName}}</option>
              </select>


            </div>

            <div class="form-group col-md-6">
              <label>Search Type</label>
              <input type="text" formControlName="SearchType" readonly [(ngModel)]="SearchType" class="form-control"
                placeholder="Search Type" (keypress)="ValidTextBox($event)"
                [ngClass]="{ 'is-invalid': submitted && f.SearchType.errors }" />
              <div *ngIf="submitted && f.SearchType.errors" class="invalid-feedback">
                <div *ngIf="f.SearchType.errors.required">Search Type is required</div>
              </div>
            </div>
            <div class="form-group col-md-6">
              <label>Return Value</label>
              <select *ngIf="ViewCBBDropDown ==='0'" class="form-control" name="ReturnValue" [(ngModel)]="ReturnValue"
                formControlName="ReturnValue">
                <option value="0">Select Return value</option>
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="18">18</option>
                <option value="24">24</option>
                <option value="30">30</option>
                <option value="36">36</option>
                <option value="42">42</option>
                <option value="48">48</option>
                <option value="54">54</option>
                <option value="60">60</option>
                <option value="66">66</option>
                <option value="72">72</option>
                <option value="78">78</option>
                <option value="84">84</option>
                <option value="90">90</option>
                <option value="96">96</option>
              </select>

              <select *ngIf="ViewCBBDropDown ==='1'" class="form-control" name="ReturnValue" [(ngModel)]="ReturnValue"
                formControlName="ReturnValue">
                <option value="0">Select Return value</option>
                <option *ngFor="let rolelistdata of CbbValuelist" [value]="rolelistdata.globalCodeValue">
                  {{rolelistdata.globalCodeName}}</option>

              </select>

              <input *ngIf="ViewCBBDropDown ==='2'" type="text" [(ngModel)]="ReturnValue" formControlName="ReturnValue"
                class="form-control" placeholder="Return value" (keypress)="ValidTextBox($event)"
                [ngClass]="{ 'is-invalid': submitted && f.ReturnValue.errors }" />


              <div *ngIf="submitted && f.ReturnValue.errors" class="invalid-feedback">
                <div *ngIf="f.ReturnValue.errors.required">Return Value is required</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">
            <div class="form-group col-md-6">
              <label>{{DropDownTitleFrom}} From</label>
              <select class="form-control" *ngIf="yearMonthDropDown ==='1'" name="Condition1From"
                [(ngModel)]="Condition1From" formControlName="Condition1From">

                <option value="0">Select Year From</option>
                <option *ngFor="let rolelistdata of ConditionYearFrom" [value]="rolelistdata.globalCodeName">
                  {{rolelistdata.globalCodeName}}</option>
              </select>

              <input type="text"  *ngIf="yearMonthDropDown ==='3'" [(ngModel)]="Condition1From" formControlName="Condition1From" class="form-control"
                placeholder="0" (keypress)="ValidTextBox($event)"
                [ngClass]="{ 'is-invalid': submitted && f.Condition1From.errors }" />

              <select class="form-control" *ngIf="yearMonthDropDown ==='2'" name="Condition1From"
                [(ngModel)]="Condition1From" formControlName="Condition1From" (change)="SetUnitllvalue();">

                <option value="1">Select Year From</option>

                <option value="0">0</option>
                <option value="6.01">6.01</option>
                <option value="12.01">12.01</option>
                <option value="18.01">18.01</option>
                <option value="24.01">24.01</option>
                <option value="30.01">30.01</option>
                <option value="36.01">36.01</option>
                <option value="42.01">42.01</option>
                <option value="48.01">48.01</option>
                <option value="54.01">54.01</option>
                <option value="60.01">60.01</option>
                <option value="66.01">66.01</option>
                <option value="72.01">72.01</option>
                <option value="78.01">78.01</option>
                <option value="84.01">84.01</option>
                <option value="90.01">90.01</option>
              </select>

              <div *ngIf="submitted && f.Condition1From.errors" class="invalid-feedback">
                <div *ngIf="f.Condition1From.errors.required">Condition1From is required</div>
              </div>
            </div>

            <div class="form-group col-md-6">
              <label> {{DropDownTitleTill}} Till</label>

              <select class="form-control" *ngIf="yearMonthDropDownTill ==='1'" name="Condition1Till"
                [(ngModel)]="Condition1Till" formControlName="Condition1Till">

                <option value="0">Select Year Until</option>
                <option *ngFor="let rolelistdata of ConditionYearUntill" [value]="rolelistdata.globalCodeName">
                  {{rolelistdata.globalCodeName}}</option>
              </select>

              <input type="text"  *ngIf="yearMonthDropDownTill ==='3'" [(ngModel)]="Condition1Till" formControlName="Condition1Till" class="form-control"
                placeholder="0" (keypress)="ValidTextBox($event)"
                />

              <select class="form-control" *ngIf="yearMonthDropDownTill ==='2'" name="Condition1Till"
                [(ngModel)]="Condition1Till" formControlName="Condition1Till">

                <option value="0">Select Year Until</option>
                <option value="6">6</option>
                <option value="12">12</option>
                <option value="18">18</option>
                <option value="24">24</option>
                <option value="30">30</option>
                <option value="36">36</option>
                <option value="42">42</option>
                <option value="48">48</option>
                <option value="54">54</option>
                <option value="60">60</option>
                <option value="66">66</option>
                <option value="72">72</option>
                <option value="78">78</option>
                <option value="84">84</option>
                <option value="90">90</option>
                <option value="96">96</option>
              </select>
              <div *ngIf="submitted && f.Condition1Till.errors" class="invalid-feedback">
                <div *ngIf="f.Condition1Till.errors.required">Condition 1 Till is required</div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row">

            
            <div class="form-group col-md-6"  >
              <label *ngIf="hideOdoMeterFrom ==='1'" >Odometer From</label>
              <label *ngIf="hideOdoMeterFrom ==='2' && hideOdoMeterReseve==='4'" >Province</label>
              <input *ngIf="hideOdoMeterFrom ==='1'" type="text" [(ngModel)]="Condition2From" formControlName="Condition2From" class="form-control"
                placeholder="0" (keypress)="ValidTextBox($event)"
                 />

                <select *ngIf="hideOdoMeterFrom ==='2' && hideOdoMeterReseve==='4'" 
                class="form-control" name="Condition2From" formControlName="Condition2From" [(ngModel)]="Condition2From" placeholder="region">
                  <option value="0" selected>Select Region </option>
                  <option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
                    {{grouplistdata.globalCodeName}}</option>
                </select>

              <div *ngIf="submitted && f.Condition2From.errors" class="invalid-feedback">
                <div *ngIf="f.Condition2From.errors.required">Condition Odometer From is required</div>
              </div>
            </div>

            <div class="form-group col-md-6" *ngIf="hideOdoMeterTill ==='1'">
              <label> Odometer Till</label>
              <input type="text" [(ngModel)]="Condition2Till" formControlName="Condition2Till" class="form-control"
                placeholder="0" (keypress)="ValidTextBox($event)"
                 />
              <!-- <div *ngIf="submitted && f.Condition2Till.errors" class="invalid-feedback">
                <div *ngIf="f.Condition2Till.errors.required">Condition Odometer Until is required</div>
              </div> -->
            </div>

          </div>
        </section>


      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
        </button>

        <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
        </button>


      </div>
    </ng-template>
  </form>
</section>