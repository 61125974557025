import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appNumericOnly]'
})
export class NumericOnlyDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event'])
  onInputChange(event: Event) {
    const initialValue: string = this.el.nativeElement.value;
    const regex = new RegExp(/^[0-9]*$/g);
    let newValue: string = initialValue.replace(/[^0-9]/g, '');

    if (!regex.test(newValue)) {
      newValue = newValue.slice(0, -1);
    }

    if (initialValue !== newValue) {
      this.el.nativeElement.value = newValue;
      event.stopPropagation();
    }
  }
}