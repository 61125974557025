
<section id="admindashboard">
<form class="col-12 px-0 mt-3" [formGroup]="AddRolesForm" >
<div class="container-fluid">
    <div class="row">
        <div class="col-12 mt-3">
          
                <div class="row justify-content-between align-items-center mb-2">
                    <div class="col-auto documentbtn">
                        <ul class="list-unstyled mb-1 pl-3">
                            <li><button class="btn btn-secondary btn-sm mb-sm-0">Copy</button></li>
                            <li><button class="btn btn-secondary btn-sm mb-sm-0" >CSV</button></li>
                            <li><button class="btn btn-secondary btn-sm mb-sm-0" >Excel</button></li>
                            <li><button class="btn btn-secondary btn-sm mb-sm-0" >PDF</button></li>
                            <li><button class="btn btn-secondary btn-sm mb-sm-0" >Print</button></li>                  
                        </ul>
                    </div>
                    <div class="search-heros col-md-auto  col-lg-3 mt-3 mt-lg-0">
                        <div class="form-group d-flex mb-1">
                            <label class="mr-3 mt-2">Search:</label>
                            <input class="form-control" type="text"
                            name="search" [(ngModel)]="searchText"  [ngModelOptions]="{standalone: true}"
                            autocomplete="off" >
                        </div>
                    </div>
                </div>

      <div class="row mt-2">
        <div class="col-md-12">
            <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="w-100">
                            <h5 class="card-title text-white text-uppercase mb-0">List of AIthr Roles</h5>
                            <div class="card-subtitle"></div>
                        </div>
                    </div>
                </div>
                <div id="inventory" class="card-body p-4">
                <div class="table-responsive">
                    <table id="usertable" class="table table-bordered" style="width:100%">
                        <thead>
                            <tr>
                                <th (click)="sort('userName')">ID <i class="fa fa-fw fa-sort"></i></th>
                                <th  (click)="sort('email')"> Role</th>
                                <th style="text-align: center;" colspan="3"><button type="button" class="btn btn-primary btn-sm text-white" (click)="openAddRoleModel(ModelAddGroup)">
                                  <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Role
                                </button></th>
                            </tr>
                        </thead>
                        <tbody>       
                          <tr *ngFor="let Roleslistdata of Roleslist | filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">
                            <td>{{ Roleslistdata.id }}</td>
                            <td> {{ Roleslistdata.name }}</td>
                            <td style="text-align: center;">
                              <button class="btn bg-gradient-info btn-sm" (click)="EditModalpopup(editpopup,Roleslistdata.id)" ><i class="fa-solid fa-pen-to-square"></i></button>
                              <button class="btn bg-gradient-danger btn-sm ml-2" (click)="openModal(template,Roleslistdata.id)"><i class="fa fa-trash"></i></button>
                            </td>
                          </tr>
                      
                        </tbody>
                    </table>
                    <br>
                    <ngb-pagination [collectionSize]="Roleslist.length" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>


                </div>
              </div>
                <a #ConfirmBox  style="display: none;" [routerLink]="" (click)="openModal(template,'')"><i
                    class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                    placement="top" ngbPopover="Are you sure you want to delete?"></i>
                </a>
                  <ng-template #template>
                    <div class="modal-body text-center" >
                      <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                      <p>Are you sure you want to delete?</p>
                      <p></p>
                      <button type="button" class="btn btn-sm btn-success mr-2" (click)="confirm()" >Yes</button>
                      <button type="button" class="btn btn-sm btn-danger" (click)="decline()" >No</button>
                    </div>
                  </ng-template>


                  <ng-template #ModelAddGroup let-modal>
                    <div class="modal-header" style="background-color: black;">
                        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add Group</h5>
                        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">

                      <section class="generate-report">
                        <div class="col-12">
                            <p style="margin-left: -15px;">Name*
                            </p>
                        </div>
        
                            <div class="form-group">
                              <input type="text" formControlName="Name" 
                              class="form-control" (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.Name.errors }"
                                 />

                                 <div *ngIf="submitted && f.Name.errors" class="invalid-feedback">
                                  <div *ngIf="f.Name.errors.required">Group Name is required</div>
                              </div>
      
                            </div>
        
                    </section>

                   
                    </div>
                    <div class="modal-footer">
                      <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
                        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                      </button>
                       
                      <button class="btn btn-danger btn-sm text-white" (click)="CloseAddmodel()">
                        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                      </button>
                  
                        
                    </div>
                  </ng-template>

<!-- 
                edit popup  start-->
                <ng-template #editpopup let-modal>
                  <div class="modal-header" style="background-color: black;">
                    <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Edit Group</h5>
                     <button type="button" class="close" style="color: white;" #closebutton (click)="closeEditModel()"
                            aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div class="modal-body">

                  <section class="generate-report">
                    <div class="col-12">
                        <p style="margin-left: -15px;">Name
                        </p>
                    </div>
                    <div class="form-group">
                      <input type="text" formControlName="Editname" 
                      class="form-control" (keypress)="ValidTextBox($event)" [(ngModel)] ="editvaluename" [ngClass]="{ 'is-invalid': submitted && f.Editname.errors }"
                         />
                       
                         <div *ngIf="submitted && f.Editname.errors" class="invalid-feedback">
                          <div *ngIf="f.Editname.errors.required">Group Name is required</div>
                      </div>

                    </div>
                    </section>
                    </div>
                 
                  <div class="modal-footer">
                    <button type="submit" class="btn btn-primary btn-sm text-white" (click)="update();">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Update
                    </button>
                     
                    <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
                      <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                    </button>
                
                      
                  </div>
                  
                
    

                </ng-template>
                <!-- 
                edit popup  end-->
                
             
              </div>
            </div>
          </div>
        </div>
    </div>
</div>
</form>
</section>



