import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-ccbvalue-list',
  templateUrl: './ccbvalue-list.component.html',
  styleUrls: ['./ccbvalue-list.component.less']
})
export class CcbvalueListComponent implements OnInit {
  CbbValuesForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,private manageInventoryService: ManageInventoryService,
    notifier: NotifierService,) {
      this.notifier = notifier;
     }
  DealerCostControl:any;
  DetailsControl:any;
  TrimControl:any;
  YearControl:any;
  MileageControl:any;
  VINControl:any;
  ModelControl:any;
  StockNumberControl:any;
  MakeControl:any;
  ConditionControl:any;
  VINValueControl:any;
  ListPriceControl:any
  CbbValueList:[];
  searchTextBought;
  pagebought = 1;
  pageSizebought = 10;
  TotalRecord:any;
  showlist:any;
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.showlist="false";
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  GetDetail()
  {
    this.showlist="false";
    this.GetDetailofUnitByVin();
  }
  GetDetailofUnitByVin() {
   
    var modelGroup = {
      "DealerName": this.VINValueControl,
      "VIN": this.VINValueControl
    }
    this.manageInventoryService.getDetailofUnitByVin(modelGroup)
      .subscribe(
        (data: any) => {
          
          if (data.length > 0) {
            this.StockNumberControl = data[0].stockNumber;
            this.ConditionControl = data[0].condition;
            this.MakeControl = data[0].make;
            this.ModelControl = data[0].model;
            this.VINControl = data[0].vin;
            this.MileageControl = data[0].mileage;
            this.YearControl = data[0].carYr;
            this.TrimControl = data[0].trim;

            this.ListPriceControl = parseFloat(data[0].listedPrice).toFixed(2);
            this.DealerCostControl = parseFloat(data[0].dealerCost).toFixed(2);
            this.DetailsControl = data[0].vehicleInfo;
          } else {
            this.showNotification("error", "VIN does not exist in Inventory.");
            return;
          }
          
        },
        error => {
        });
  }


  GetCbbValueByVIN() {
    var modelGroup = {
      "Mileage": parseInt(this.MileageControl),
      "VIN": this.VINControl,
      "Year":parseInt(this.YearControl)
    }
    this.manageInventoryService.getCbbValueByVINList(modelGroup)
      .subscribe(
        (data: any) => {
          this.showlist="true"
         this.CbbValueList = data.used_vehicles.used_vehicle_list;

       

         this.TotalRecord =this.CbbValueList.length;
        },
        error => {
        });


  }
  Createform() {
    this.CbbValuesForm = new FormGroup({
      DealerCostControl: this.fb.control(''),
      DetailsControl: this.fb.control(''),
      TrimControl: this.fb.control(''),
      YearControl: this.fb.control(''),
      MileageControl: this.fb.control(''),
      VINControl: this.fb.control(''),
      ModelControl: this.fb.control(''),
      StockNumberControl: this.fb.control(''),
      MakeControl: this.fb.control(''),
      ConditionControl: this.fb.control(''),
      VINValueControl: this.fb.control(''),
      ListPriceControl: this.fb.control(''),
    });
  }
}
