import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { LenderService } from '@app/_services/lender.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { AccountService } from '@app/_services/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { ST_CustomRules } from '@app/_models/ST_CustomRules';
import { NavigationExtras } from "@angular/router";
import * as $ from 'jquery'
@Component({
  selector: 'app-build-car',
  templateUrl: './build-car.component.html',
  styleUrls: ['./build-car.component.less']
})
export class BuildCarComponent implements OnInit {
  buildCarForm: FormGroup;
  parameters: any;
  vinindex:any;
  private notifier: NotifierService;
  isExServiceContractInfo: boolean;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private router: Router,
    private globalcodeService: GlobalcodeService,
    private lenderService: LenderService,
    private manageInventoryService: ManageInventoryService,
    private accountService: AccountService,
    private spinner: NgxSpinnerService, private modalService: NgbModal)
    {
    this.notifier = notifier;
    //this.parameters = this.router.getCurrentNavigation().extras.state.builparameter;
    this.vinindex=this.route.snapshot.paramMap.get('vin');
  }
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  ValueSetForAdjustModelPopUp:any;
  @ViewChild('ConfirmBoxGetWorkSheetdetail', { static: false }) ConfirmBoxGetWorkSheetdetailclick: ElementRef;
  @ViewChild('ConfirmBoxGetValidationdetail', { static: false }) ConfirmBoxGetValidationdetailclick: ElementRef;

  @ViewChild('ConfirmBoxAfterWorkSheetdetail', { static: false }) ConfirmBoxAfterWorkSheetdetailclick: ElementRef;


  
 @ViewChild('ConfirmBoxGlobalWarranty', { static: false }) ConfirmBoxGlobalWarrantyclick: ElementRef;


  @ViewChild('ConfirmBoxUVCdetail', { static: false }) ConfirmBoxUVCdetailclick: ElementRef;
  ConditionControl: any;
  GrossProfitControl:any;
  MakeControl: any;
  AmountFinancedControl: any;
  LicenseFee: any;
  FrontEndGrossTotalAmount: any;
  BackEndGrossTotal: any;
  OtherGrossTotal: any;
  isLockListPrice:any;
  CBBValueControl: any;
  MonthlyControl: any;
  showDealerAdnce:any;
  interestRateControl: any;
  StockNumberControl: any;
  ModelControl: any;
  ListPriceControl: any;
  AdjustLogID:any;
  DealerCostControl: any;
  DocAdminFeeFront: any;
  DocAdminFeeback: any;
  SemiMonthlyControl: any;
  WeeklyControl:any;
  VINControl: any;
  UserId:any;
  TrimControl: any;
  TradeAllowanceControl: any;
  //LicenseFee:any;
  FrontEndGrossTotal:any;
  BackendGrossTotal:any;
  LienControl: any;
  BiWeeklyControl: any;
  ReservesControl: any;
  KmsControl: any;
  Trim1Control: any;
  ACVControl: any;
  EquityControl: any;
  TotalLoantoValueControl: any;
  Reserves1Control: any;
  DetailsControl: any;
  UVCControl:any;
  DownPaymentControl: any;
  TotalTaxControl: any;
  TermControl: any;
  LenderFeeControl: any;
  RegionControl: any;
  TaxRateControl: any;
  PPSAControl: any;
  GPSFeeControlValue:any;
  TOTALAccessoriesControl: any;
  LcineceFee:any;
  TOTALCostControl: any;
  DealerAdminFreeControl: any;
  isDocFeeFrontValue:any;
  DealerAdminFreeControlLoad: any;
  OverRideBLTVControl: any;
  OverRideFLTVControl: any;

  OverRideBLTVControlLoadValue: any;
  OverRideFLTVControlLoadValue: any;
  Accessories1Control: any;
  Accessories2Control: any;
  Accessories3Control: any;
  FEMaximizedAmount:any;
  modalRef: BsModalRef;
  Cost1Control: any;
  Cost2Control: any;
  Cost3Control: any;
  GWPrice1 : any;
  GWPrice2 : any;
  GWCost1 : any;
  GWCost2: any;
  GWTaxes: any;
  TOTALAddOnsControls: any;
  TOTALBackEndCostControls: any;

  ExtendendServiceContractControls: any;
  TotalProductPriceAmount: any;
  checkPriceAmount:any;
  openOrderModel:any;
  TotalProductCostAmount:any;
  checkCostAmount:any;
  ExtendendCostControls: any;
  GapControls: any;
  GapCostControls: any;
  // @ViewChild('#adjustpaymentbuttonToBeClicked') buttonToBeClicked: ElementRef;
  @ViewChild('adjustpaymentbuttonToBeClicked') closebutton;
  JobLossControls: any;
  BackDocFee:any;
  JobLossCostControls: any;
  VinNumber: any;
  ColorTheme: any;
  Checkname: any;

  CurrentPayment: any;
  DesiredPayment: any;
  TotalAdjustments: any;
  AdjustmentstolistPrice: any;
  AdjustmentstolistPriceButton: any;
  AdjustmentstoExtendedWarrantyButton:any;
  AdjustmentsGapButton:any;
  AmountOfTax:any;
  AdjustmentsJobButton:any;
  AdjustmentsAccessoriesButton:any;
  AdjustmentstoExtendedWarranty: any;
      AdjustmentsGap: any;
      AdjustmentsJobLoss:any;
      AdjustmentsAccessories:any;

      valueforOpCode:any;
      AdjustmentsGapCheckValue: any;
      AdjustmentsExtCheckValue: any;
      AdjustmentsListPriceCheckValue: any;
      AdjustmentsJobCheckValue:any;
      AdjustmentsAccessCheckValue:any;
  AdjustmentsListPrice: any;
  allowableLossvalue: any;
  maxAmountFinancevalue: any;
  SelectedGroupName:any;
  AdjustmentsNewListPrice: any;
  AdjustmentsMonthlyPayment: any;
  AdjustmentsNewMonthlyPayment: any;
  AdjustmentsGrossProfit: any;
  AdjustmentsNewGrossProfit: any;
  AdjustmentsRemainingFLTV: any;
  AdjustmentsNewRemainingFLTV: any;
  AdjustmentsRemainingBLTV: any;
  AdjustmentsNewRemainingBLTV: any;
  AdjustmentsTotalTaxes: any;
  AdjustmentsNewTotalTaxes: any;
  AdjustmentFrontDocumentationFee: any;
  AdjustmentNewFrontDocumentationFee: any;

  lenderIdValue: any;
  VinViewImges:any;

  applicationId:any;
  creditorId:any;
  LogUTCDateTime:any;
  LenderPayCall: any;
  openAdjustMentPopup:any;
  LenderInterestRate: any;
  ACVValueControl: any;
  LoanAmountControl: any;
  TotalFrontproducts: any;
  TotalFrontcost: any;
  TotalBackproducts: any;
  TotalBackcost: any;
  DownPaymentAmountControl: any;
  LenderKeyValue: any;
  ProvinceIdValue: any;
  GroupNameId: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;

  PayCallValueCheck: any;
  modalcloseOpen: any;
  DealerIdvalue: any;
  deductionfeeValue:any;
  RemainingFrontLoanValue:any;
  TotalofFeeandRemainingValue:any;
  RemainingFrontLoanValues:any;
  RemainingFrontLoanValueCheck:any;
   RemainingBackLoanValue:any;
  RemainingBackLoanValues:any;
  public isVisible: boolean = false;
  public isRemainingVisible: boolean = false;
  public isLoanToValueVisible: boolean = false;
  public isRemainingBackEndVisible: boolean = false;
  vehicleObject = [];
  customimagesrc=[];
  errorshow: any;
  errorshowAuto:any;
  errorMessageAuto:any;
  errorMessage: string;
  CalTotalLoanamount: any;
  CalDesiredMonthlyPayment: any;
  CalInterestRate: any;
  CalLoanTerm: any;

  public isListPrice: boolean = false;
  // Variables
  SemiMonthlyValues: any;
  BiMonthlyValues: any;
  BiWeekValues: any;
  biMonth: any;
  DealerIdvalueGW:any;
  RuleSetControl:any;
  customRuleList: ST_CustomRules[] = [];
  UpperLimitControl:any;
 
  LowerLimitControl:any;
  buttonloader: boolean = false;
  buttonReset: boolean = false;
  buttonloaderevaluate: boolean = false;

  buttonloaderSendEmail: boolean = false;
  buttonloaderBuyEmail: boolean = false;
  AithrHubKey: any;
  LenderNameValue: any;
  AddressValue:any;
  EmailValue:any;
  PhoneNumberValue:any;

  PlanType:any;
  PayCallDisaply:any;
  InterestRateDisplay:any;
  AllowableFinanceDisplay:any;
  DealerAdvanceDisplay:any;
  delaeridforserver = [];
  regionidforserver = [];
 colormessage:any;
 colorCbbmessage:any;
 colorTermmessage:any;
 colorLenderFeemessage:any;
 colorppsamessage:any;


 colorListPricemessage:any;
 OrderProductKey:any;
 colorDealerCostmessage:any;
 colorTrademessage:any;
 colorLeinmessage:any;
 colorACVmessage:any;
 colorAdjustListPrice:any;
 colorDownmessage:any;
 colorInterestRatemessage:any;
 colorReservesmessage:any;

 colorAdjustMonthly:any;
 colorAdjustGrossProfit:any;
 colorAdjustTotalTaxes:any;
 colorAdjustFLTV:any;
 colorAdjustBLTV:any;
 colorAdjustFrontFee:any;
 useridValue:any;
 ipaddressvalue:any;
 isShowDiv=false;
 islenderinfo=false;
 hideShowTopButtons:any;

 // Verify

 selectedVin: any;
 typeofaction: any;
 hideCostButton:any;
 hidetopBarButton:any;
 divTopButtonsshow:any;
 InfoFirstName:any;
 InfoLastName:any;
 InfoPhone:any;
 InfoEmail:any;
TOTALCosthide: any;
Cost1Hide: any;
Cost2Hide: any;
Cost3Hide: any;
hidedocAdminFee:any;
BackendCostHide: any;
ExtendendCostHide: any;
GapCostHide: any;
JobLossHide: any;

 verifycheckboxes = [];
 hideDealerCost:any;
 hideGrossProfit:any;
 hideCostProduct:any;
 istaxExemptValue:any;

 // variables for Orders
 SoldPriceValue:any;
  OrderFirstName:any;
  OrderVenderKey:any;
  GlobalWarrantyClientsValue:any;
  IsCommercialCheck:any;
  ProvinceStateCode:any;
  OrderCity:any;
  purchasePriceLeaseValue:any;
  OrderLastName:any;
  OrderLienholderName:any;
  OrderPhone:any;
  OrderEmail:any;
  OrderPostalCode:any;
  OrderAddress1:any;
  OrderAddress2:any;
  DealerKey:any;
  productlists: any[] = [];

  ProuctsInformation:any="";
  ProuctsInformationOrder:any="";
  ProuctsInformationOrderSubmit:any="";
  OrderFirstNameValue:any;
  OrderCityValue:any;
  OrderLastNameValue:any;
  OrderAddress1Value:any;
  OrderAddress2Value:any;
  OrderPostalCodeValue:any;
  OrderPhoneValue:any;
  OrderEmailValue:any;
  OrderLienholderNameValue:any;

    CustomerFirstName:any;
    CustomerLastName :any;
    CustomerPhone :any;
    Customeremail:any;
    GlobalWarrantyId :any;
    orderUtcDateValue:any;
    CustomerMake:any;
    CustomerModel:any;
    CustomerVIN :any;
    Customertrim :any;
    CustomerMilege:any;
    CustomerYear:any;
    IsCoverageTypeExists:any;
    IsCoverageTypeExistsInExistingProduct:any;
  addedproductlists: any[] = [];
  addedproductlistswithCost: any[] = [];
  productList: any[] = [];
  distinctCoverageType: any[] = [];
  currentCoverageType: any[] = [];
  //SelecteddistinctCoverageType: any[] = [];
  SelecteddistinctCoverageType: { CoverageType: string }[] = [];
  AddProductDisplay:any;
  buttonloaderSumbitSendEmail: boolean = false;
  options = [
    {productKey:'AXG3Y5', soldPrice:'5000', zeroDeductible:true,isSelected:false},
    {productKey:'AXTS4Y', soldPrice:'2500', zeroDeductible:false,isSelected:false},
   
  ]
  isAllSelected($event, item) {
    debugger;
    var checkExistingList = this.addedproductlists;
    this.currentCoverageType.push(item);
    if ($event.target.checked) {
    if (this.addedproductlists.length > 0) {
      this.IsCoverageTypeExistsInExistingProduct = this.checkCovergaeTypeExistence(this.addedproductlists, this.currentCoverageType, 'coverageType');
    }

    if (this.IsCoverageTypeExistsInExistingProduct == false) {

      if ($event.target.checked) {
        // this.IsCoverageTypeExists = this.checkCovergaeTypeExistence(this.SelecteddistinctCoverageType, this.productlists, 'coverageType');
        this.IsCoverageTypeExists = this.checkCovergaeTypeExistence(this.productlists, this.currentCoverageType, 'coverageType');
        if (this.IsCoverageTypeExists == false) {
          this.productlists.push(item);
        } else {
          $event.target.checked = false;
          this.showNotification("error", "You have already added a product of this coverage type.");
        }

      } else {

        this.removeRecordById(item.id)
        // let i: number = 0;
        // this.productlists.forEach((item: any) => {
        //   console.log(item);
        //   if (item.productKey == item.productKey) {
        //     this.productlists.splice(i, 1);
        //     return;
        //   }
        //   i++;
        // });
      }
    } else {
      $event.target.checked = false;
      this.showNotification("error", "You have already added a product of this coverage type.");
    }
  } else
  {
    this.removeRecordById(item.id);
  }
  }
 removeRecordById(idToRemove: number) {
  this.productlists =  this.productlists.filter(item => item.id !== idToRemove);
   if (this.addedproductlists.length > 0) {
     this.addedproductlists = this.productlists.filter(item => item.id !== idToRemove);
     this.IsCoverageTypeExistsInExistingProduct=false;
   }
 
  }
 
  ngOnInit(): void {
      debugger;
    this.Createform()
    this.AddProductDisplay="false";
    this.IsCoverageTypeExistsInExistingProduct=false;
    this.showDealerAdnce="false";
    this.hidedocAdminFee="false";
    this.LcineceFee="false";
    this.hideCostProduct="true";

    this.OrderFirstNameValue="false";
    this.OrderCityValue="false";
    this.OrderLastNameValue="false";
    this.OrderAddress1Value="false";
    this.OrderAddress2Value="false";
    this.OrderPostalCodeValue="false";
    this.OrderPhoneValue="false";
    this.OrderEmailValue="false";
    this.OrderLienholderNameValue="false";

    this.ResetColorMessage();
    this.DealerAdminFreeControlLoad=0;
    this.AdjustLogID=0;
    this.GWPrice1="0";
    this.GWPrice2="0";
    this.GWCost1="0";
    this.GWCost2="0";
    this.GWTaxes="0";
    this.deductionfeeValue=0;
    this.FEMaximizedAmount=0;
    this.TotalProductPriceAmount=0;
    this.checkPriceAmount=0;
    this.TotalProductCostAmount=0;
    this.checkCostAmount=0;
    this.AmountOfTax=0;
    this.allowableLossvalue=0;
    this.maxAmountFinancevalue=0;
    this.hideShowTopButtons="false";
    this.InfoFirstName="Capital ";
    this.InfoLastName="Auto";
    this.InfoPhone="9876953067";
    this.InfoEmail="capitalauto@aithr.ca";
    this.divTopButtonsshow ="false";
    this.FrontEndGrossTotal="0.00";
    this.BackendGrossTotal="0.00";
    this.DealerKey= localStorage.getItem('DealerKey');
    this.OrderVenderKey= localStorage.getItem('DealerKey');
    this.GlobalWarrantyClientsValue= localStorage.getItem('isGlobalWarrantyClients');
    this.hidetopBarButton ="true";
    this.CalTotalLoanamount=0;
    this.CalDesiredMonthlyPayment=0;
    this.CalInterestRate=0;
    this.CalLoanTerm=0;
    this.hideCostButton = "true";
    this.hideDealerCost= "true";
    this.hideGrossProfit= "true";

    this.TOTALCosthide= "true";
    this.Cost1Hide= "true";
    this.Cost2Hide= "true";
    this.Cost3Hide= "true";

    this.BackendCostHide= "true";
    this.ExtendendCostHide= "true";
    this.GapCostHide= "true";
    this.JobLossHide= "true";

    this.AdjustmentsJobCheckValue=0;
    this.AdjustmentsAccessCheckValue=0;
    this.AdjustmentsAccessoriesButton=0;
    this.colorAdjustListPrice ="1";
    this.colorAdjustMonthly="1";
    this.colorAdjustGrossProfit="1";
    this.colorAdjustTotalTaxes="1";
    this.colorAdjustFLTV="1";
    this.colorAdjustBLTV="1";
    this.colorAdjustFrontFee ="1";
    this.openAdjustMentPopup ="False";
    this.useridValue=localStorage.getItem('userid');
    this.ipaddressvalue=localStorage.getItem('IpAddresstoTrack');
    this.GetbuildLocalstorage();
    this.RemainingFrontLoanValue =0;
    this. RemainingFrontLoanValues=0;
    this.RemainingFrontLoanValueCheck =0;
    this.GetCustomRuleList();
    this.RemainingBackLoanValue =0;
    this. RemainingBackLoanValues=0;
    this.TOTALAddOnsControls = 0;
    this.TOTALBackEndCostControls = 0;
    this.ACVControl = 0;
    this.TotalofFeeandRemainingValue =0;
    this.Reserves1Control = 0;
    this.TradeAllowanceControl =0;
   // this.LicenseFee =0;
    this.FrontEndGrossTotalAmount =0;
    this.OtherGrossTotal =0;
    this.BackEndGrossTotal =0;
    this.ReservesControl = 0;
    this.SemiMonthlyValues = 0;
    this.BiMonthlyValues = 0;
    this.biMonth = 0;
    this.DealerIdvalueGW = localStorage.getItem('DealerId');
    this.GetDealerDetailByID();
    this.AdjustmentstolistPrice = 0;

    this.AdjustmentstolistPriceButton= 0;
    this.AdjustmentstoExtendedWarrantyButton= 0;
    this.AdjustmentsGapButton= 0;
    this.AdjustmentsJobButton=0;
    this.AdjustmentstoExtendedWarranty = 0;
    this.AdjustmentsGap = 0;
    this.AdjustmentsJobLoss=0;
    this.AdjustmentsAccessories=0;
   // this.TotalTaxControl = 0;
    // this.Accessories1Control = 0;
    // this.Accessories2Control = 0;
    // this.Accessories3Control = 0;
   // this.DealerAdminFreeControl = 0;
    this.Cost1Control = 0;
    this.Cost2Control = 0;
    this.Cost3Control = 0;
   // this.TOTALAccessoriesControl = 0;
    this.TOTALBackEndCostControls = 0;
    this.ExtendendCostControls = 0;
    this.GapCostControls = 0;
    this.JobLossCostControls = 0;
    this.JobLossControls = 0;
    this.TotalFrontproducts=0;
    this.TotalFrontcost=0;
    this.TotalBackproducts=0;
    this.TotalBackcost=0;
    this.GapControls = 0;
    this.ExtendendServiceContractControls = 0;
    this.CurrentPayment = 0;
    this.DesiredPayment = 0;
    this.TotalAdjustments = 0;
    this.AdjustmentstolistPrice = 0;
    var parameterValuescheck = localStorage.getItem('builparameter'+this.vinindex);

   
    let objValuescheck = JSON.parse(parameterValuescheck);
    this.TotalTaxControl =  parseFloat(objValuescheck.totalTaxes).toFixed(2)
    this.AdjustmentsListPrice = parseFloat(objValuescheck.listPrice).toFixed(2);
    this.AdjustmentFrontDocumentationFee = parseFloat(objValuescheck.frontDocFee).toFixed(2);

    this.SelectedGroupName = objValuescheck.groupName;
    this.AdjustmentsNewListPrice = 0.00;

    this.AdjustmentsGapCheckValue= 0.00;
    this. AdjustmentsExtCheckValue= 0.00;
    this.AdjustmentsListPriceCheckValue= 0.00;

   // this.AdjustmentFrontDocumentationFee =  0.00;
    this.AdjustmentNewFrontDocumentationFee =  0.00;

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    this.VinNumber = objValuescheck.vin; //this.route.snapshot.paramMap.get('vin');
    // this.GetAllInventoryListbyVin(this.VinNumber);


  }

//   checkCovergaeTypeExistence(array1: any[], array2: any[], columnName: string): boolean {
//     // Extract values of the desired column from array1
//     let columnValues = array1.map(item => item[columnName]);
    
//     // Check if all values exist in array2
//     return columnValues.every(value => array2.some(item => item[columnName] === value));
// }
  checkCovergaeTypeExistence(array1: any[], array2: any[], columnName: string): boolean {
    // Extract values of the desired column from array1
    let columnValues = array1.map(item => item[columnName]);
    
    // Check if any of the values exist in array2
    for (let value of columnValues) {
      if (array2.some(item => item[columnName] === value)) {
        this.currentCoverageType=[];
        return true; // If at least one value exists, return true
      }
    }
    this.currentCoverageType=[];
    return false; // If none of the values exist, return false
  }

  ChangeControlColor(value : any)
  {

   if (value == "cbb") {
    this.colorCbbmessage ="Change";
  } else if (value == "dealerCost") {
    this.colorDealerCostmessage ="Change";
  } else if (value == "InterestRate") {
    this.colorInterestRatemessage ="Change";
  }else if (value == "Reserves") {
    this.colorReservesmessage ="Change";
  }else if (value == "Term") {
    this.colorTermmessage ="Change";
  }else if (value == "LenderFee") {
    this.colorLenderFeemessage ="Change";
  }else if (value == "ppsa") {
    this.colorppsamessage ="Change";
  }else if (value == "listPrice") {
    this.colorListPricemessage="change";
  }else if (value == "trade") {
    this.colorTrademessage="change";
  }else if (value == "Lien") {
    this.colorLeinmessage="change";
  }else if (value == "acc") {
    this.colorACVmessage="change";
  }else if (value == "down") {
    this.colorDownmessage="change";
  }

  }
  ResetColorMessage()
  {
    this.colormessage ="1";
    this.colorCbbmessage="1";
    this.colorDealerCostmessage ="1";
    this.colorListPricemessage ="1";
    this.colorACVmessage ="1";
    this.colorTrademessage="1";
    this.colorLeinmessage="1";
    this.colorDownmessage="1";
    this.colorInterestRatemessage="1";
    this.colorReservesmessage="1";
    this.colorTermmessage="1";
    this.colorLenderFeemessage="1";
    this.colorppsamessage="1";
  }
  showAlert() : void {
    if (this.isVisible) {
      return;
    }
    this.isVisible = true;
    setTimeout(()=> this.isVisible = false,2500)
  }
  RefreshImageList() {
    this.vehicleObject = [];
    this.customimagesrc=[];
  }
  openImagesModelPopUp(targetModal) {

    this.GetVehiclePhotosListByVinList(this.VinViewImges);
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }
  GetVehiclePhotosListByVinList(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {

          this.customimagesrc=[];
          this.vehicleObject=[];
          if (data.length > 0) {
            this.errorshow = 'false';
            this.errorshowAuto ='false';
            for (var i = 0; i < data.length; i++) {
              if(data[i].imageStatus=="1")
              {
                //this.vehicleObject=[];
                this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: ''})
              } else{
                this.customimagesrc.push({imgsrc:data[i].imagesrc })
              }
            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }
          if(this.vehicleObject.length == 0)
          {
            this.errorshowAuto = 'true';
            this.errorMessageAuto = "No images for this Vehicle.";
          }

          if(this.customimagesrc.length == 0)
          {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }
  RefreshAdjustValues() {

   // this.AdjustmentsJobLoss = 0;
    this.AdjustmentsJobButton = 0;

    this.AdjustmentsGapButton = 0;
   // this.AdjustmentsGap = 0;
    this.AdjustmentstoExtendedWarrantyButton = 0;
   // this.AdjustmentstoExtendedWarranty = 0;

   // this.AdjustmentsAccessories = 0;
    this.AdjustmentsAccessoriesButton = 0;

    this.AdjustmentstolistPriceButton = 0;
  //  this.AdjustmentstolistPrice = 0;

    this.TotalAdjustments = 0;
    this.DesiredPayment = 0;
   // this.CurrentPayment = 0;
  }
  OnBlurListPrice()
  {

    this.valueforOpCode ="11";

    if (parseFloat(this.AdjustmentstolistPrice) > 0) {
      //  this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
      //   this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    } else {
      if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
      {
        this.AdjustmentsListPriceCheckValue =0;
        this.AdjustmentstolistPrice =0;
      } else{
        this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
        this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
      }
    }

    // if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
    // {
    //   this.AdjustmentsListPriceCheckValue =0;
    //   this.AdjustmentstolistPrice =0;
    // } else{
    //   this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
    //   this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    // }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustListPriceButton() {
    this.valueforOpCode ="21";

    if(parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton)) < 0 )
    {
      this.AdjustmentsListPriceCheckValue =0;
      this.AdjustmentstolistPrice =0;
    } else{
      this.AdjustmentsListPriceCheckValue = parseFloat(this.AdjustmentstolistPrice) + (parseFloat(this.AdjustmentstolistPriceButton));
      this.AdjustmentstolistPrice = parseFloat( this.AdjustmentsListPriceCheckValue).toFixed(2);
    }


    this.AdjustPaymentForAllControlsandButtonClicks();
  }

  OnBlurGap()
  {
    this.valueforOpCode ="14";

    if (parseFloat(this.AdjustmentsGap) > 0) {
      // this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
      // this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
    } else {
      if (parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton)) < 0) {
        this.AdjustmentsGapCheckValue = 0;
        this.AdjustmentsGap = 0;
      } else {
        this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
        this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
      }
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustGapButton() {
    this.valueforOpCode ="24";
    if(parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton)) < 0 )
    {
      this.AdjustmentsGapCheckValue =0;
      this.AdjustmentsGap =0;
    } else{
      this.AdjustmentsGapCheckValue = parseFloat(this.AdjustmentsGap) + (parseFloat(this.AdjustmentsGapButton));
    this.AdjustmentsGap = parseFloat(this.AdjustmentsGapCheckValue).toFixed(2);
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  OnBlurAccessories()
  {
    this.valueforOpCode ="12";

    if (parseFloat(this.AdjustmentsAccessories) > 0) {
      // this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
      // this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
    } else {
      if (parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton)) < 0) {
        this.AdjustmentsAccessCheckValue = 0;
        this.AdjustmentsAccessories = 0;
      } else {
        this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
        this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
      }
    }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustAccessoriesButton()
  {
    this.valueforOpCode ="22";
    if(parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton)) < 0 )
    {
      this.AdjustmentsAccessCheckValue =0;
      this.AdjustmentsAccessories =0;
    } else{
      this.AdjustmentsAccessCheckValue = parseFloat(this.AdjustmentsAccessories) + (parseFloat(this.AdjustmentsAccessoriesButton));
      this.AdjustmentsAccessories = parseFloat(this.AdjustmentsAccessCheckValue).toFixed(2);
    }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }

  OnBlurJobLoss()
  {
    this.valueforOpCode ="15";

    if (parseFloat(this.AdjustmentsJobLoss) > 0) {
      // this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
      // this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    } else {

      if (parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0) {
        this.AdjustmentsJobCheckValue = 0;
        this.AdjustmentsJobLoss = 0;
      } else {
        this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
        this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
      }
    }

    // if(parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0 )
    // {
    //   this.AdjustmentsJobCheckValue =0;
    //   this.AdjustmentsJobLoss =0;
    // } else{
    //   this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
    //   this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    // }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustJobButton()
  {
    this.valueforOpCode ="25";
    if(parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton)) < 0 )
    {
      this.AdjustmentsJobCheckValue =0;
      this.AdjustmentsJobLoss =0;
    } else{
      this.AdjustmentsJobCheckValue = parseFloat(this.AdjustmentsJobLoss) + (parseFloat(this.AdjustmentsJobButton));
      this.AdjustmentsJobLoss = parseFloat(this.AdjustmentsJobCheckValue).toFixed(2);
    }

    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  OnBlurExtendedWarranty()
  {

    this.valueforOpCode = "13";
    if (parseFloat(this.AdjustmentstoExtendedWarranty) > 0) {

      // this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
      // this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);


    } else {

      if (parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton)) < 0) {
        this.AdjustmentsExtCheckValue = 0;
        this.AdjustmentstoExtendedWarranty = 0;
      } else {
        this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
        this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);
      }
    }
    this.AdjustPaymentForAllControlsandButtonClicks();
  }
  AdjustExtendedButton() {
    this.valueforOpCode ="23";
    if(parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton)) < 0 )
    {
      this.AdjustmentsExtCheckValue =0;
      this.AdjustmentstoExtendedWarranty =0;
    } else{
      this.AdjustmentsExtCheckValue = parseFloat(this.AdjustmentstoExtendedWarranty) + (parseFloat(this.AdjustmentstoExtendedWarrantyButton));
      this.AdjustmentstoExtendedWarranty = parseFloat(this.AdjustmentsExtCheckValue).toFixed(2);
    }

    this.AdjustPaymentForAllControlsandButtonClicks();

  }


  AdjustRollPayment()
  {
    this.valueforOpCode="90";
    this.modalRef.hide();
    this.ListPriceControl =parseFloat(this.AdjustmentstolistPrice).toFixed(2);
    this.ExtendendServiceContractControls =parseFloat(this.AdjustmentstoExtendedWarranty).toFixed(2);
    this.GapControls =parseFloat(this.AdjustmentsGap).toFixed(2);
    this.JobLossControls=parseFloat(this.AdjustmentsJobLoss).toFixed(2);
    this.TOTALAccessoriesControl=parseFloat(this.AdjustmentsAccessories).toFixed(2);

    this.TOTALAddOnsControls = parseFloat(this.AdjustmentstoExtendedWarranty) + parseFloat(this.AdjustmentsGap) + parseFloat(this.AdjustmentsJobLoss);
     this.AdjustPaymentForAllControlsandButtonClicks();


     // for clear values
     this.RefreshAdjustValues();
    this.ReEvaluate();
  }

  // Call the Adjust function with opcode one

  AdjustPaymentForAllControlsandButtonClicks()
  {
    debugger;
    var modelGroup = {
      "Opcode":this.valueforOpCode,
      "PayCallOnScreen": parseFloat(this.CurrentPayment),
      "PayCallToBe": parseFloat(this.DesiredPayment),//this.findCarForm.controls.dealerName.value,
      //'Interestrate': parseFloat(this.LenderInterestRate),
      'Interestrate': parseFloat(this.interestRateControl),

      "TermPeriod": parseFloat(this.TermControl),
      "TaxRate": parseFloat(this.TaxRateControl),
      "TotalAdjustments": parseFloat(this.TotalAdjustments),// this.findCarForm.controls.TaxExemptionCheck.value,
      "AmountBeforeTax": parseFloat(this.AdjustmentstolistPriceButton),
      "AmountOfTax": parseFloat(this.AmountOfTax),
      "AmountNonTaxable": parseFloat(this.AdjustmentsGapButton),
      "AdjustLogID": this.AdjustLogID,
      "ListedPrice": parseFloat(this.AdjustmentstolistPrice),
      "FEProducts": parseFloat(this.AdjustmentsAccessories),
      "ExtWarranty": parseFloat(this.AdjustmentstoExtendedWarranty),
      "GAP":parseFloat(this.AdjustmentsGap),
      "JobLoss":parseFloat(this.AdjustmentsJobLoss),
      "VIN": this.VinNumber,
      "DealerID": "0",
      "VehicleId":"0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "isTaxExempt": this.istaxExemptValue
    }
    this.GetAdjustedValues(modelGroup);
  }

  showReamingAlert() : void {
    if (this.isRemainingVisible) {
      return;
    }
    this.isRemainingVisible = true;
    setTimeout(()=> this.isRemainingVisible = false,2500)
  }

  showReamingBackEndAlert() : void {
    if (this.isRemainingBackEndVisible) {
      return;
    }
    this.isRemainingBackEndVisible = true;
    setTimeout(()=> this.isRemainingBackEndVisible = false,2500)
  }

  showLoanAlert() : void {
    if (this.isLoanToValueVisible) {
      return;
    }
    this.isLoanToValueVisible = true;
    setTimeout(()=> this.isLoanToValueVisible = false,2500)
  }
  IncreaseTerms(value: any) {
    this.TermControl = parseInt(value) + 1;
  }
  DecreaseTerms(value: any) {
    this.TermControl = parseInt(value) - 1;
  }
  GetbuildLocalstorage() {
   debugger;

    this.ValueSetForAdjustModelPopUp="Load";
    var parameterValues = localStorage.getItem('builparameter'+this.vinindex);
    let objValues = JSON.parse(parameterValues);
     this.istaxExemptValue =  objValues.isTaxExempt;
    this.GPSFeeControlValue= parseFloat(objValues.gpsFee).toFixed(2); ;
    this.AdjustmentsListPrice = parseFloat(objValues.listPrice).toFixed(2);

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = objValues.GroupNameId;
    this.DealerIdvalue = objValues.DealerId;


    this.deductionfeeValue =  objValues.deductionfee;

    this.lenderIdValue = objValues.LenderId;

    this.applicationId=objValues.applicationId;
    this.creditorId=objValues.creditorId;
    // fo getting Images
    this.VinViewImges = objValues.vin;
    // for Allowable loss and Max finance amount
    this.allowableLossvalue = parseFloat(objValues.allowableLoss).toFixed(2);
    this.maxAmountFinancevalue = parseFloat(objValues.maxAmountFinance).toFixed(2);
    
     // function for getting Detail of Lender
     this.GetLenderDetailByID();
    //this.GetProductListByVin();
    this.LenderPayCall = objValues.PayCall;
    this.LenderInterestRate = objValues.InterestRate;
    this.ACVValueControl = objValues.tradevalue;
    this.LoanAmountControl = objValues.LoanAmount;
    this.AithrHubKey = objValues.IsAithrHub;
    this.LenderNameValue=objValues.LenderNameValue;
    this.PlanType = objValues.PlanType;
    this.AllowableFinanceDisplay = parseFloat(objValues.allowableFinance).toFixed(2);

    this.CBBValueControl = parseFloat(objValues.effectiveCBB).toFixed(2);
    this.DealerCostControl = parseFloat(objValues.dealerCost).toFixed(2);
    this.PayCallDisaply = parseFloat(objValues.PayCall).toFixed(2) ;
    this.InterestRateDisplay =parseFloat(objValues.InterestRate).toFixed(2) ;
    this.DealerAdminFreeControl=parseFloat(objValues.frontDocFee).toFixed(2) ;
    
    this.isDocFeeFrontValue = objValues.isDocFeeFront;

    if (this.isDocFeeFrontValue == true) {
      this.hidedocAdminFee = "true";
    } else {
      this.hidedocAdminFee = "false";
    }

    // if (this.DealerAdminFreeControl > 0) {
    //   this.hidedocAdminFee = "true";
    // } else {
    //   this.hidedocAdminFee = "false";
    // }
    this.TermControl=parseFloat(objValues.termPeriod).toFixed(2) ;

    this.LicenseFee=parseFloat(objValues.licenceFee).toFixed(2) ;
    this.FrontEndGrossTotalAmount=parseFloat(objValues.feGrossAmount).toFixed(2) ;
    this.BackEndGrossTotal=parseFloat(objValues.beGrossAmount).toFixed(2) ;
    this.OtherGrossTotal=parseFloat(objValues.othGrossAmount).toFixed(2) ; 
    this.isLockListPrice = objValues.isListPriceLock;
    this.DealerAdminFreeControlLoad=parseFloat(objValues.frontDocFee).toFixed(2) ;
    this.PayCallValueCheck ="Load";

    this.Accessories1Control = "0" ; 
    this.Accessories2Control = "0" ;
    this.Accessories3Control = "0" ;
    this.FEMaximizedAmount= "0" ;
    this.TOTALAccessoriesControl = "0" ;

    this.OverRideBLTVControlLoadValue= parseInt(objValues.bltv);
    this.OverRideFLTVControlLoadValue= parseInt(objValues.fltv);
    this.OverRideBLTVControl= parseInt(objValues.bltv);
    this.OverRideFLTVControl= parseInt(objValues.fltv);
    // this.OverRideBLTVControl= 0;
    // this.OverRideFLTVControl= 0;

    if(this.AithrHubKey =="1")
    {
      this.delaeridforserver = [];
    } else
    {
      this.delaeridforserver = objValues.DealerIDList;
    }

    // if(this.AithrHubKey =="1")
    // {
    //   this.regionidforserver = [];
    // } else
    // {
    //   this.regionidforserver = objValues.RegionIDList;
    // }
    this.regionidforserver = objValues.RegionIDList;

    //this.TotalTaxControl =objValues.totalTaxes;
    this.DownPaymentAmountControl = objValues.DownPayment;
    if (this.DownPaymentAmountControl == undefined) {
      this.DownPaymentAmountControl = "0";
    }

    this.LenderKeyValue = objValues.LenderKey;
    this.ProvinceIdValue = objValues.ProvinceId;
    this.GroupNameId = objValues.GroupNameId;
    this.VinNumber = objValues.vin;

    var checkedverifydata = {
      vin: this.VinNumber,
      type: "",
      loggeduserid: this.useridValue,
      notes: "Enquiry",
      hello:"testing",
      ipAddress:localStorage.getItem('IpAddresstoTrack')

    }

    this.verifycheckboxes.push(checkedverifydata);

    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.ProvinceIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LoanAmountControl),
      "DownPaymentValue": parseFloat(this.DownPaymentAmountControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "0",
      "TradeInValue":"0",
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": "0",
      "FrontDocFee":parseFloat(this.DealerAdminFreeControl),
      "TotalBackproducts": "0",
      "TotalBackcost": "0",
      "ExtWarranty":"0",
      "GAP":"0",
      "JobLoss":"0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice":  "0",
      "ScreenIntRate":  "0",
      "ScreenTermPeriod":  "0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "CbbValue":this.CBBValueControl,
     "MaxAmountFinance":parseFloat(this.maxAmountFinancevalue),
      "AllowableLoss":parseFloat(this.allowableLossvalue),
      "OverrideTerm": parseFloat(this.TermControl),
      "Deductionfee": parseFloat(this.deductionfeeValue),
      "LicenceFee":parseFloat(this.LicenseFee),
      "FEGrossAmount":parseFloat(this.FrontEndGrossTotalAmount),
      "BEGrossAmount":parseFloat(this.BackEndGrossTotal),
      "OthGrossAmount":parseFloat(this.OtherGrossTotal),
      "IsListPriceLock":this.isLockListPrice,
      "FEAccessory1":parseFloat(this.Accessories1Control),
      "FEAccessory2":parseFloat(this.Accessories2Control),
      "FEAccessory3":parseFloat(this.Accessories3Control),
      "FEMaximizedAmount":parseFloat(this.FEMaximizedAmount),
      "GWPrice1":parseFloat(this.GWPrice1),
      "GWPrice2":parseFloat(this.GWPrice2),
      "GWCost1":parseFloat(this.GWCost1),
      "GWCost2":parseFloat(this.GWCost2),
      "GWTaxes":parseFloat(this.GWTaxes),
    }
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
    this.GetRegionDetailbyId();
  }

  GetRegionDetailbyId() {
    var modelGroup = {
      "GlobalCodeID": parseInt(this.ProvinceIdValue)

    }
    this.manageInventoryService.getRegionDetailbyId(modelGroup)
      .subscribe(
        (data: any) => {
          this.RegionControl = data[0].globalCodeName;
          this.ProvinceStateCode = data[0].globalCodeName;
          this.TaxRateControl = parseFloat(data[0].totalTaxRate).toFixed(2);
        },
        error => {
        });


  }

  ngAfterViewInit(): void {
    this.closebutton.nativeElement.value = 'Whale!';
    // this.closebutton.nativeElement.click();

  }


  openModal(template: TemplateRef<any>, itemid: any) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  openLenderInfoModal(template: TemplateRef<any>, itemid: any) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-sm modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  AddProducts()
  {
    debugger;
    console.log(this.productlists);

    for (let i = 0; i < this.productlists.length; i++) {
      this.ProuctsInformation += "ProductId:" + this.productlists[i].id +" Prouct Name:"+ this.productlists[i].productName + ",";
    }

    this.ProuctsInformation.replace("undefined", "");

    this.InsertLogs("Selecting Products from Product List" ,this.ProuctsInformation);
    var modelGroup = {
      "productlists":this.productlists

    }
    this.lenderService.updateProductdetaills(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.addedproductlists=data;

this.TotalProductCostAmount = 0;
         
          if (this.addedproductlists.length > 0) {
            for (let i = 0; i < this.addedproductlists.length; i++) {
              this.TotalProductCostAmount += parseFloat(this.addedproductlists[i].price);
            }
            this.TOTALBackEndCostControls = parseFloat(this.TotalProductCostAmount).toFixed(2);
          }
         

          this.AddProductDisplay="true";
          this.productlists=[];
          this.CloseAddmodelProducts();
        },
        error => {
        });

    
  }
  getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
        case agent.indexOf('edge') > -1:
            return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
            return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
            return 'chrome';
        case agent.indexOf('trident') > -1:
            return 'ie';
        case agent.indexOf('firefox') > -1:
            return 'firefox';
        case agent.indexOf('safari') > -1:
            return 'safari';
        default:
            return 'other';
    }
}


InsertLogs(ButtonClick:any,ProductInformation:any) {
debugger;
  var VehicleInfo=  this.ConditionControl + "," + this.MakeControl +","+ this.ModelControl + "," + this.TrimControl +","+ this.Trim1Control;
  var modelGroup = {
      "VIN": this.VINControl,
      "UserName": this.useridValue,
      "VehicleInformation":VehicleInfo,
      "GWProducts": ProductInformation,// Ipaddress "111.93.121.106",//this.ipAddress,
      "ProvinceStateCode": this.ProvinceStateCode,
      "City": this.OrderCity,
      "LienHolderName": this.OrderLienholderName,
      "Phone": this.OrderPhone,
      "Email": this.OrderEmail,
      "PostalCode": this.OrderPostalCode,
      "Address1": this.OrderAddress1,
      "LogType": ButtonClick,
      "OrderFirstName":this.OrderFirstName,
      "OrderLastName":this.OrderLastName,
      "VehicleListPrice":parseFloat(this.ListPriceControl) 

  }

  this.manageInventoryService.insertGWlogs(modelGroup)
      .subscribe(
          (data: any) => {
            this.ProuctsInformation="";
            this.ProuctsInformationOrder="";
            this.ProuctsInformationOrderSubmit="";
          },
          error => {
          });

}

  SaveActivityLog(IpAddressValue: any,ButtonClick:any) {
    var modelGroup = {
        "UserID": localStorage.getItem('userid'),
        "BrowserInfo": this.getBrowserName(),
        "SessionID": "",
        "IPAddress": localStorage.getItem('IpAddresstoTrack'),// Ipaddress "111.93.121.106",//this.ipAddress,
        "ActivityType": 1,
        "ActivityLink": localStorage.getItem('pagename'),
        "Comments": ButtonClick,
    }

    this.accountService.saveActivityLog_url(modelGroup)
        .subscribe(
            (data: any) => {

            },
            error => {
            });

}
  OrdersProductListButton( targetModal) {
     debugger;
        var allproducts = this.productList 
        var ExitingProducts = this.addedproductlists;
        this.productlists = this.addedproductlists;
        this.productList.forEach(product => product.isSelected = this.addedproductlists.find(p => p.id === product.id) ? true : false);

       
        this.InsertLogs("Open Product Model Click" ,"NO Product");
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  checkValidation()
  {
    debugger;
    this.KeyUpProductCostValidation("0")
 
  }
  KeyUpProductCostValidation(value: any) {
    debugger;

    this.TotalProductPriceAmount = 0;

    for (let i = 0; i < this.addedproductlists.length; i++) {
      var costValuecheck = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
      this.checkPriceAmount = ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value);
      if (this.checkPriceAmount == "") {
        this.checkPriceAmount = "0";
      }
      if (parseFloat(costValuecheck) > parseFloat(this.checkPriceAmount)) {

        $('#SpanPrice_' + i).show();

        this.openOrderModel = "false";
      } else {

        $('#SpanPrice_' + i).hide();
        this.openOrderModel = "true";
      }

    }

    if (this.openOrderModel == "true") {
      let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
      el.click();
    }

  }
  
  OrdersButton( targetModal) {

    for (let i = 0; i < this.addedproductlists.length; i++) {
      this.ProuctsInformationOrder += "ProductId:" + this.addedproductlists[i].id +" Prouct Name:"+ this.addedproductlists[i].productName + " Price :"+ this.addedproductlists[i].price + " Sold Price :" + this.addedproductlists[i].soldPrice + " ,";
    }

    this.InsertLogs("Order Button Click",this.ProuctsInformationOrder);
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false });
  }

  openRollPaymentModel(targetModal) {

    this.RefreshAdjustValues();
    this.AdjustmentstoExtendedWarranty =parseFloat(this.ExtendendServiceContractControls) ;
   this.AdjustmentsGap = parseFloat(this.GapControls);
   this.AdjustmentsJobLoss =  parseFloat(this.JobLossControls);
   this.AdjustmentstolistPrice = parseFloat(this.ListPriceControl).toFixed(2);
   this.AdjustmentsAccessories = parseFloat(this.TOTALAccessoriesControl).toFixed(2) ;
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

  }

  CloseMainAdjustMentmodel()
  {
    this.modalRef.hide();
    this.ResetDeals();
  }

  // openProductListPopUp(targetModal) {
  //   this.GetProductListByVin();
  //   this.modalcloseOpen = this.modalService.open(targetModal, {
  //     centered: true,
  //     backdrop: 'static',
  //     size: 'lg'
  //   });
  // }

  // CloseproductList()
  // {
  //   this.modalRef.hide();
  //  // this.ResetDeals();
  // }


   // click button hide show
   toggleExServiceContract()
   {
    
     this.isExServiceContractInfo = !this.isExServiceContractInfo;
   }

  // function for Adjustment Decline
  CloseAdjustMentmodel() {
    this.modalRef.hide();
   // this.ResetDeals();
   // this.GetbuildLocalstorage();
  }

  // function for Adjustment Accpet for sending New Price
  AcceptAdjustment()
  {
    this.modalRef.hide();
    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.ProvinceIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "1",
      "TradeInValue":parseInt(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.AdjustmentFrontDocumentationFee,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.AdjustmentsNewListPrice,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "CbbValue":this.CBBValueControl,
     "MaxAmountFinance":parseFloat(this.maxAmountFinancevalue),
     "AllowableLoss":parseFloat(this.allowableLossvalue),
     "OverrideTerm": parseFloat(this.TermControl),
     "Deductionfee": parseFloat(this.deductionfeeValue),
     "LicenceFee":parseFloat(this.LicenseFee),
      "FEGrossAmount":parseFloat(this.FrontEndGrossTotalAmount),
      "BEGrossAmount":parseFloat(this.BackEndGrossTotal),
      "OthGrossAmount":parseFloat(this.OtherGrossTotal),
      "IsListPriceLock":this.isLockListPrice,
      "FEAccessory1":parseFloat(this.Accessories1Control),
      "FEAccessory2":parseFloat(this.Accessories2Control),
      "FEAccessory3":parseFloat(this.Accessories3Control),
      "FEMaximizedAmount":parseFloat(this.FEMaximizedAmount)
    }

    //this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  CloseAddmodel() {

    this.modalRef.hide();
    this.valueforOpCode="99";
    this.AdjustPaymentForAllControlsandButtonClicks();
    this.RefreshAdjustValues();
  }

  CloseAddmodelProducts() {

    this.modalRef.hide();
  }


  // functions for Back doc fee and Front doc Fee Changes
  keyupFrontDoc(event) {
    // this.DealerAdminFreeControl = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.DealerAdminFreeControl =event;

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

        //  this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
        //  this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

        this.ReEvaluate();
  }

  keyupBackDoc(event) {
    if(event=="")
    {
      event="0";
    }
    this.BackDocFee = parseInt(event);
    this.ExtendendServiceContractControls = parseInt(event);
    this.TOTALAddOnsControls = parseInt(this.ExtendendServiceContractControls) + parseInt(this.GapControls) + parseInt(this.JobLossControls);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    ///this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    ///this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
    this.ReEvaluate();

  }

  keyupAccessories(event) {
   
    if(event=="")
    {
      event="0";
    }
    this.Accessories1Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) +
                                   parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);

     // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }
    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    //this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
    this.ReEvaluate();



  }

  showAlertCheckListPrice(): void {
    if (this.isListPrice) {
      return;
    }
    this.isListPrice = true;
    setTimeout(() => this.isListPrice = false, 2500)
  }
  CheckListPrice(event) {
   // this.colorListPricemessage ="change";
   // this.ListPriceControl = parseInt(event);
    if (parseFloat(this.DealerCostControl) > parseFloat(this.ListPriceControl)) {
      this.showAlertCheckListPrice();
    }

   // this.DealerAdminFreeControl =event;

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

          // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
          // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

          this.ReEvaluate();
  }

  openModalGLobal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  ChangeTrade(event) {
    
    if(event=="")
    {
      event="0";
    }
    // this.TradeAllowanceControl = parseInt(event);
    this.TradeAllowanceControl = event;
    this.EquityControl = (parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl)).toFixed(2);

    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )
   // var equityvalue=(parseFloat(this.EquityControl) * -1);
   // var equityvalue=(parseFloat(this.EquityControl) * -1);
   // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -equityvalue + parseFloat(this.DownPaymentControl));
   // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

   this.ReEvaluate();
  }
  ChangeLien(event) {
    // this.LienControl = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.LienControl = event;
    this.EquityControl = parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl);
    //this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
    this.EquityControl = parseFloat(this.EquityControl).toFixed(2);
   // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
   this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
   if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
     this.showReamingAlert();
   }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
   //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

   this.ReEvaluate();
  }

  ChangeDown(event) {
    if(event=="")
    {
      event="0";
    }
    this.DownPaymentControl = parseInt(event);
    this.DownPaymentControl = event;
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }


   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

         // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
         // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
         this.ReEvaluate();

        }
  ChangeAccV(event) {
    // this.ACVValueControl = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.ACVValueControl = event;
    //this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
   // this.EquityControl = parseFloat(this.EquityControl).toFixed(2);
    //this.ACVValueControl = parseFloat(this.ACVValueControl).toFixed(2);
    this.ReEvaluate();
  }


  keyupAccessories2(event) {
    //this.TOTALAccessoriesControl = parseInt(event);
    // this.Accessories2Control = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.Accessories2Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) + parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

   // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

    this.ReEvaluate();

  }

  keyupLcineceFee(event) {
    
    if(parseInt(event) > 300 )
    {
      this.LcineceFee="true";
      this.LicenseFee="0.00";
    } else
    {
      this.LcineceFee="false";
      this.ReEvaluate();
    }
  }
  keyupAccessories3(event) {
    if(event=="")
    {
      event="0";
    }
    //this.TOTALAccessoriesControl = parseInt(event);
    // this.Accessories3Control = parseInt(event);
    this.Accessories3Control = event;
    this.TOTALAccessoriesControl = parseFloat(this.Accessories1Control) + parseFloat(this.Accessories2Control) + parseFloat(this.Accessories3Control);
    this.TOTALAccessoriesControl = parseFloat(this.TOTALAccessoriesControl).toFixed(2);

    // Remaaing FTLV
    // if Total Accessorry + Document Fee not exceeding to Remaining fornt end loan value >>> then mess prompt
    this.TotalofFeeandRemainingValue = parseFloat(this.DealerAdminFreeControl) + parseFloat(this.TOTALAccessoriesControl);
    if (parseFloat(this.TotalofFeeandRemainingValue) > parseFloat(this.RemainingFrontLoanValue)) {
      this.showReamingAlert();
    }
   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
  // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

  this.ReEvaluate();

  }

  keyupCost(event) {
    if(event=="")
    {
      event="0";
    }
    // this.Cost1Control = parseInt(event);

    this.Cost1Control = event;
    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    //this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    //this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);
    this.ReEvaluate();

  }

  keyupCost2(event) {
    if(event=="")
    {
      event="0";
    }
    // this.Cost2Control = parseInt(event);
    this.Cost2Control =event;

    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

    // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
    // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

    this.ReEvaluate();
  }
  keyupCost3(event) {
    if(event=="")
    {
      event="0";
    }
    // this.Cost3Control = parseInt(event);
    this.Cost3Control = event;
    this.TOTALCostControl = parseFloat(this.Cost1Control) + parseFloat(this.Cost2Control) + parseFloat(this.Cost3Control);
    this.TOTALCostControl = parseFloat(this.TOTALCostControl).toFixed(2);

    // Remaaing FTLV

   // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

  // this.RemainingFrontLoanValueCheck = parseFloat(this.RemainingFrontLoanValues) - ((parseFloat(this.ListPriceControl) + parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1) + parseFloat(this.DownPaymentControl));
  // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

  this.ReEvaluate();
  }

  keyupExtendendService(event) {
  debugger;
    if(event=="")
    {
      event="0";
    }

     this.ExtendendServiceContractControls = event;


     this.TotalProductPriceAmount =0;

     if(this.addedproductlists.length>0)
      {
        for (let i = 0; i < this.addedproductlists.length; i++) {
          this.checkPriceAmount = ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value);
          if(this.checkPriceAmount != "NaN" && this.checkPriceAmount != "")
            {
              this.TotalProductPriceAmount += parseFloat(this.checkPriceAmount) ;
            }
          
        }
      }
    

    // this.Cost3Control = event;
    this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + 
    parseFloat(this.JobLossControls) + parseFloat(this.TotalProductPriceAmount);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

    // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }
  keyupGapValue(event) {

    // this.GapControls = parseInt(event);

    if(event=="")
    {
      event="0";
    }
    this.GapControls = event;

    this.TotalProductPriceAmount =0;

    if(this.addedproductlists.length>0)
     {
       for (let i = 0; i < this.addedproductlists.length; i++) {
         this.checkPriceAmount = ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value);
         if(this.checkPriceAmount != "NaN" && this.checkPriceAmount != "")
           {
             this.TotalProductPriceAmount += parseFloat(this.checkPriceAmount) ;
           }
         
       }
     }
   

   // this.Cost3Control = event;
   this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + 
   parseFloat(this.JobLossControls) + parseFloat(this.TotalProductPriceAmount);
   this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    // this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + parseFloat(this.JobLossControls);
   //  this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);
    // cal for back FLTV

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

   // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
   // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

   this.ReEvaluate();
  }
  keyupJobValue(event) {
    if(event=="")
    {
      event="0";
    }
    // this.JobLossControls = parseInt(event);
    this.JobLossControls = event;

    this.TotalProductPriceAmount =0;

    if(this.addedproductlists.length>0)
     {
       for (let i = 0; i < this.addedproductlists.length; i++) {
         this.checkPriceAmount = ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value);
         if(this.checkPriceAmount != "NaN" && this.checkPriceAmount != "")
           {
             this.TotalProductPriceAmount += parseFloat(this.checkPriceAmount) ;
           }
         
       }
     }
   

   // this.Cost3Control = event;
   this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + 
   parseFloat(this.JobLossControls) + parseFloat(this.TotalProductPriceAmount);
   this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    // this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) + parseFloat(this.GapControls) + parseFloat(this.JobLossControls);
    // this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);
    // cal for back FLTV
    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

   // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
   // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
   this.ReEvaluate();
  }

  GetRuleSetValues(RuleSetID: any) {

    var modelGroup = {
      "id": parseInt(RuleSetID)
    }
    this.lenderService.getCustomRulesListbyId(modelGroup)
      .subscribe(
        (data: any) => {

          this.UpperLimitControl=parseFloat(data[0].upperLimit).toFixed(2);
          this.LowerLimitControl=parseFloat(data[0].lowerLimit).toFixed(2);
        },
        error => {
        });


  }

  CallReevaluatefunction(value:any)
  {

    this.ReEvaluate();
  }


  GetLenderDetailByID() {
    var modelGroup = {
      "id": this.lenderIdValue
    }
    this.lenderService.EditLenders(modelGroup)
      .subscribe(
        data => {
          this.AddressValue = data[0].address;
          this.EmailValue = data[0].email;
          this.PhoneNumberValue = data[0].phone;
        },
        error => {
        });


  }

  GetDealerDetailByID() {
    var modelGroup = {
      "id": this.DealerIdvalueGW
    }
    this.lenderService.getDealerInformation(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.GlobalWarrantyClientsValue=data[0].isGlobalWarrantyClients.toString();
        },
        error => {
        });


  }

  // for saving the Details

   ResetOrders()
   {
    this.OrderFirstName="";
    this.OrderCity="";
    this.OrderLastName="";
    this.OrderAddress1="";
    this.OrderAddress2="";
    this.OrderPostalCode="";
    this.OrderPhone="";
    this.OrderEmail="";
    this.OrderLienholderName="";
    // this.VINControl="";
    // this.Trim1Control="";
    // this.ModelControl="";
    // this.MakeControl="";
    // this.TrimControl="";
    // this.KmsControl="";
   }

   onKeyLastName(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderLastNameValue = "false";
    } else {
      this.OrderLastNameValue = "true";
    }
  }

  onKeyCity(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderCityValue = "false";
    } else {
      this.OrderCityValue = "true";
    }
  }

  onKeyLien(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderLienholderNameValue = "false";
    } else {
      this.OrderLienholderNameValue = "true";
    }
  }
  onKeyPhone(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderPhoneValue = "false";
    } else {
      this.OrderPhoneValue = "true";
    }
  }

  onKeyEmail(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderEmailValue = "false";
    } else {
      this.OrderEmailValue = "true";
    }
  }

  onKeyPostalCode(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderPostalCodeValue = "false";
    } else {
      this.OrderPostalCodeValue = "true";
    }
  }

  onKeyAddress(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderAddress1Value = "false";
    } else {
      this.OrderAddress1Value = "true";
    }
  }

  onKeyFirstName(fistNameValue: any) {
    debugger;
    var valueCheck = fistNameValue.target.value;
    if (valueCheck != "" && valueCheck != undefined) {

      this.OrderFirstNameValue = "false";
    } else {
      this.OrderFirstNameValue = "true";
    }
  }
  SaveOrderDetails() {
    debugger;
    this.spinner.show();
    if (this.OrderFirstName == "" || this.OrderFirstName == undefined) {
      this.OrderFirstNameValue="true";
      return;
    } else if (this.OrderLastName == "" || this.OrderLastName == undefined) {
      this.OrderLastNameValue="true";
      return;
    } else if (this.OrderCity == "" || this.OrderCity == undefined) {
      this.OrderCityValue="true";
      return;
    }
    else if (this.OrderLienholderName == "" || this.OrderLienholderName == undefined) {
      this.OrderLienholderNameValue="true";
      return;
    } else if (this.OrderPhone == "" || this.OrderPhone == undefined) {
      this.OrderPhoneValue="true";
      return;
    }
    else if (this.OrderEmail == "" || this.OrderEmail == undefined) {
      this.OrderEmailValue="true";
      return;
    }
    else if (this.OrderPostalCode == "" || this.OrderPostalCode == undefined) {
      this.OrderPostalCodeValue="true";
      return;
    }
    else if (this.OrderAddress1 == "" || this.OrderAddress1 == undefined) {
      this.OrderAddress1Value="true";
      return;
    }
    else {
      for (let i = 0; i < this.addedproductlists.length; i++) {
        this.ProuctsInformationOrderSubmit += "ProductId:" + this.addedproductlists[i].id +" Prouct Name:"+ this.addedproductlists[i].productName + " Price :"+ this.addedproductlists[i].price + " Sold Price :" + this.addedproductlists[i].soldPrice + " ,";
      }
      this.InsertLogs("Submit Order Button Click",this.ProuctsInformationOrderSubmit)
      this.buttonloaderSumbitSendEmail = true;
      var modelGroup = {
        "FirstName": this.OrderFirstName,
        "LastName": this.OrderLastName,
        "Address1": this.OrderAddress1,
        "Address2": this.OrderAddress2,
        "PostalZipCode": this.OrderPostalCode,
        "Phone": this.OrderPhone,
        "Email": this.OrderEmail,
        "LienholderName": this.OrderLienholderName,
        "VIN": this.VINControl,
        "Year": this.Trim1Control,
        "Model": this.ModelControl,
        "Make": this.MakeControl,
        "Trim": this.TrimControl,
        "OdometerReading": this.KmsControl,
        "VendorKey": this.OrderVenderKey,
        "PurchasePriceOrLeaseValue": this.purchasePriceLeaseValue,
        "HasCommercialUse": false,
        "WarrantyKey": "",
        "SoldPrice": this.SoldPriceValue,
        "productfinalsts": this.addedproductlists,
        "DealerId":this.DealerIdvalueGW,
        "City":this.OrderCity,
        "ProvinceStateCode":this.ProvinceStateCode

      }
      this.lenderService.addorderdetaills(modelGroup)
        .subscribe(
          data => {
            debugger;
            if (data.length > 0) {
              this.spinner.hide();
              this.buttonloaderSumbitSendEmail = false;
              this.CustomerFirstName = data[0].firstName;
              this.CustomerLastName = data[0].lastName;
              this.CustomerPhone = data[0].phone;
              this.Customeremail = data[0].email;
              this.GlobalWarrantyId = data[0].warrantyId;
              this.orderUtcDateValue = data[0].orderUtcDate;
              this.CustomerMake = data[0].make;
              this.CustomerModel = data[0].model;
              this.CustomerVIN = data[0].vin;
              this.Customertrim = data[0].trim;
              this.CustomerMilege = data[0].odometerReading;
              this.CustomerYear= data[0].year;
              this.CloseAddmodel();
              this.ResetOrders();
              
              this.showNotification("success", "Order added successfully");
              let el: HTMLElement = this.ConfirmBoxGlobalWarrantyclick.nativeElement;
              el.click();
            } else {
              this.buttonloaderSumbitSendEmail = false;
              this.showNotification("error", "Order not submitted successfully. Please check the information.");
            }
            
          },
          error => {
          });
    }

  }

  updatePropertyAtIndex(array, index, property, newValue) {
    debugger;
    if (index >= 0 && index < array.length) {
      array[index][property] = newValue;
    }
  }

  updateCostPropertyAtIndex(array, index, property, newValue) {
    debugger;
    if (index >= 0 && index < array.length) {
      array[index][property] = newValue;
    }
  }

  KeyUpProductCost(value: any, index: any, List: any) {
    debugger;

    this.TotalProductPriceAmount = 0;

    for (let i = 0; i < this.addedproductlists.length; i++) {
      var costValuecheck = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
      this.checkPriceAmount = ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value);

      if (parseFloat(costValuecheck) > parseFloat(this.checkPriceAmount)) {
       
        $('#SpanPrice_' + i).show();

        ((document.getElementById("productPrice_" + i + "") as HTMLInputElement).value="0");
      } else {

        $('#SpanPrice_' + i).hide();
        //this.addedproductlistswithCost.push(this.checkPriceAmount)
        if (this.checkPriceAmount != "NaN" && this.checkPriceAmount != "") {
          this.TotalProductPriceAmount += parseFloat(this.checkPriceAmount);
        }

        // for updating value 
        this.updatePropertyAtIndex(this.addedproductlists, i, "soldPrice", parseFloat(this.checkPriceAmount));
      }

    }


    this.TOTALAddOnsControls = parseFloat(this.ExtendendServiceContractControls) +
      parseFloat(this.GapControls) + parseFloat(this.JobLossControls) + parseFloat(this.TotalProductPriceAmount);
    this.TOTALAddOnsControls = parseFloat(this.TOTALAddOnsControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

    // this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    // this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }

  KeyUpProductPrice(value: any, index: any, List: any) {
    debugger;
    this.TotalProductCostAmount = 0;

    if (this.addedproductlists.length > 0) {
      for (let i = 0; i < this.addedproductlists.length; i++) {
        this.checkCostAmount = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
        if (this.checkCostAmount != "NaN" && this.checkCostAmount != "") {
          this.TotalProductCostAmount += parseFloat(this.checkCostAmount);
        }
 // for updating value 
        this.updateCostPropertyAtIndex(this.addedproductlists,i,"price",parseFloat(this.checkCostAmount))
      }
      //this.TOTALBackEndCostControls = this.TotalProductCostAmount;
    }

    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) +
      parseFloat(this.JobLossCostControls) + parseFloat(this.TotalProductCostAmount);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }
    // cal for back FLTV
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues) - parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValue).toFixed(2);
    this.ReEvaluate();
  }
  GetProductListByVin() {
    var modelGroup = {
      "VIN":this.VinViewImges,
      "ProjectedGross":this.GrossProfitControl,
      "GlobalCodeName":this.RegionControl
    }
    this.lenderService.getProductLists(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.productList = data;
          //this.distinctCoverageType = this.getDistinctValues(this.productList, 'coverageType');
         // this.SelecteddistinctCoverageType = this.addDistinctValues(this.productList, this.distinctCoverageType,'coverageType');
         this.addDistinctNames(this.productList, this.SelecteddistinctCoverageType);
        },
        error => {
        });


  }

  getDistinctValues(data: any[], propertyName: string): any[] {
    return [...new Set(data.map(item => item[propertyName]))];
  }

 addDistinctNames(source: any[], destination: any[]) {
    let distinctNames = new Set(); // Use a set to ensure uniqueness
    
    // Extract distinct names
    source.forEach(item => distinctNames.add(item.coverageType));
  
    // Add distinct names to the destination array
    distinctNames.forEach(coverageType => {
      destination.push({ coverageType });
    });
  }

  GetCustomRuleList() {
    this.lenderService.getCustomRulesList()
      .subscribe(
        (data: any) => {
          this.customRuleList = data;
        },
        error => {
        });
  }
  // FOR COST BACK END FUNTIONS

  keyupExtendendCost(event) {
    // this.ExtendendCostControls = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.ExtendendCostControls = event;

    this.TotalProductCostAmount = 0;
         
          if (this.addedproductlists.length > 0) {
            for (let i = 0; i < this.addedproductlists.length; i++) {
              this.checkCostAmount = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
              if (this.checkCostAmount != "NaN" && this.checkCostAmount != "") {
                this.TotalProductCostAmount += parseFloat(this.checkCostAmount);
              }

            }
            //this.TOTALBackEndCostControls = this.TotalProductCostAmount;
          }

    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) + 
    parseFloat(this.JobLossCostControls) +  parseFloat(this.TotalProductCostAmount);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }
    // cal for back FLTV
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }
  keyupGapCost(event) {
    // this.GapCostControls = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.GapCostControls = event;

    this.TotalProductCostAmount = 0;
         
          if (this.addedproductlists.length > 0) {
            for (let i = 0; i < this.addedproductlists.length; i++) {
              this.checkCostAmount = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
              if (this.checkCostAmount != "NaN" && this.checkCostAmount != "") {
                this.TotalProductCostAmount += parseFloat(this.checkCostAmount);
              }

            }
            //this.TOTALBackEndCostControls = this.TotalProductCostAmount;
          }

    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) +
     parseFloat(this.JobLossCostControls) +  parseFloat(this.TotalProductCostAmount);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }
    // cal for back FLTV
    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);
    this.ReEvaluate();
  }

  keyupJobCost(event) {

    debugger;
    // this.JobLossCostControls = parseInt(event);
    if(event=="")
    {
      event="0";
    }
    this.JobLossCostControls = event;

    this.TotalProductCostAmount = 0;
         
    if (this.addedproductlists.length > 0) {
      for (let i = 0; i < this.addedproductlists.length; i++) {
        this.checkCostAmount = ((document.getElementById("productCost_" + i + "") as HTMLInputElement).value);
        if (this.checkCostAmount != "NaN" && this.checkCostAmount != "") {
          this.TotalProductCostAmount += parseFloat(this.checkCostAmount);
        }

      }
      //this.TOTALBackEndCostControls = this.TotalProductCostAmount;
    }

    this.TOTALBackEndCostControls = parseFloat(this.ExtendendCostControls) + parseFloat(this.GapCostControls) +
     parseFloat(this.JobLossCostControls) +  parseFloat(this.TotalProductCostAmount);
    this.TOTALBackEndCostControls = parseFloat(this.TOTALBackEndCostControls).toFixed(2);
    // cal for back FLTV

    if (parseFloat(this.TOTALAddOnsControls) > parseFloat(this.RemainingBackLoanValue)) {
      this.showReamingBackEndAlert();
    }

    this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- parseFloat(this.TOTALAddOnsControls);
    this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);

    this.ReEvaluate();
  }

  // END OF BACK END
  MaximizeDeal() {
    debugger;
    if (this.addedproductlists.length > 0) {

      if (this.addedproductlists.length == 1) {
        if (isNaN(this.addedproductlists[0].soldPrice)) {
          this.GWPrice1 = "0";
        } else {
          this.GWPrice1 = this.addedproductlists[0].soldPrice;
        }



        if (isNaN(this.addedproductlists[0].price)) {
          this.GWCost1 = "0";
        } else {
          this.GWCost1 = this.addedproductlists[0].price;
        }
        this.GWPrice2 = "0";
        this.GWCost2 = "0";
      }
      if (this.addedproductlists.length == 2) {

        if (isNaN(this.addedproductlists[0].soldPrice)) {
          this.GWPrice1 = "0";
        } else {
          this.GWPrice1 = this.addedproductlists[0].soldPrice;
        }



        if (isNaN(this.addedproductlists[0].price)) {
          this.GWCost1 = "0";
        } else {
          this.GWCost1 = this.addedproductlists[0].price;
        }
        
        if (isNaN(this.addedproductlists[1].soldPrice)) {
          this.GWPrice2 = "0";
        } else {
          this.GWPrice2 = this.addedproductlists[1].soldPrice;
        }

        if (isNaN(this.addedproductlists[1].price)) {
          this.GWCost2 = "0";
        } else {
          this.GWCost2 = this.addedproductlists[1].price;
        }
      }
      
      
      // this.GWPrice2 = this.addedproductlists[1].soldPrice
      // this.GWCost1 = this.addedproductlists[0].price
      // this.GWCost2 = this.addedproductlists[1].price
    } else {
      this.GWPrice1 = "0";
      this.GWPrice2 = "0";
      this.GWCost1 = "0";
      this.GWCost2 = "0";
    }
   

    this.ValueSetForAdjustModelPopUp="MaximizeDeal";
    this.showAlert();
    this.openAdjustMentPopup ="True";
    this.buttonloader = true;
    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.ProvinceIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "1",
      "TradeInValue":parseFloat(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.DealerAdminFreeControl,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.ListPriceControl,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "frommaximize":true,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "CbbValue":this.CBBValueControl,
     "MaxAmountFinance":parseFloat(this.maxAmountFinancevalue),
     "AllowableLoss":parseFloat(this.allowableLossvalue),
     "OverrideTerm": parseFloat(this.TermControl),
     "Deductionfee": parseFloat(this.deductionfeeValue),
     "LicenceFee":parseFloat(this.LicenseFee),
      "FEGrossAmount":parseFloat(this.FrontEndGrossTotalAmount),
      "BEGrossAmount":parseFloat(this.BackEndGrossTotal),
      "OthGrossAmount":parseFloat(this.OtherGrossTotal),
      "IsListPriceLock":this.isLockListPrice,
      "FEAccessory1":parseFloat(this.Accessories1Control),
      "FEAccessory2":parseFloat(this.Accessories2Control),
      "FEAccessory3":parseFloat(this.Accessories3Control),
      "FEMaximizedAmount":parseFloat(this.FEMaximizedAmount),
      "GWPrice1":parseFloat(this.GWPrice1),
      "GWPrice2":parseFloat(this.GWPrice2),
      "GWCost1":parseFloat(this.GWCost1),
      "GWCost2":parseFloat(this.GWCost2),
      "GWTaxes":parseFloat(this.GWTaxes),
    }

    //this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  ResetDeals() {

    this.ResetColorMessage();
    this.buttonReset = true;
    this.ResetValues();

    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }


    var modelGroup = {
      "Opcode": "0",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': this.delaeridforserver,
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.ProvinceIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LoanAmountControl),
      "DownPaymentValue": parseFloat(this.DownPaymentAmountControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "0",
      "TradeInValue":"0",
      "TotalFrontproducts": "0",
      "TotalFrontcost": "0",
      "FrontDocFee":parseFloat(this.DealerAdminFreeControlLoad),
      "TotalBackproducts": "0",
      "TotalBackcost": "0",
      "ExtWarranty":"0",
      "GAP":"0",
      "JobLoss":"0",
      "BackDocFee": "0",
      "PPSA": "0",
      "LenderFee": "0",
      "Reserves": "0",
      "Amount2Finance": "0",
      "ListedPrice":  "0",
      "ScreenIntRate":  "0",
      "ScreenTermPeriod":  "0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":"0",
     "OverRideFLTV":parseFloat(this.OverRideFLTVControlLoadValue),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControlLoadValue),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "CbbValue":this.CBBValueControl,
     "MaxAmountFinance":parseFloat(this.maxAmountFinancevalue),
     "AllowableLoss":parseFloat(this.allowableLossvalue),
     "OverrideTerm": parseFloat(this.TermControl)
    }
    // this.GetAllInventoryListbyParameters();
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup)
  }

  AdjustDesiredPayment()
  {
    var modelGroup = {
      "Opcode": "0",
      "PayCallOnScreen": parseFloat(this.CurrentPayment),
      "PayCallToBe": parseFloat(this.DesiredPayment),//this.findCarForm.controls.dealerName.value,
     // 'Interestrate': parseFloat(this.LenderInterestRate),
     'Interestrate': parseFloat(this.interestRateControl),
      "TermPeriod": parseFloat(this.TermControl),
      "TaxRate": parseFloat(this.TaxRateControl),
      "TotalAdjustments": parseFloat(this.TotalAdjustments),// this.findCarForm.controls.TaxExemptionCheck.value,
      "AmountBeforeTax": parseFloat(this.AdjustmentstolistPriceButton),
      "AmountOfTax": parseFloat(this.AmountOfTax),
      "AmountNonTaxable": parseFloat(this.AdjustmentsGapButton),
      "AdjustLogID": this.AdjustLogID,
      "ListedPrice": parseFloat(this.AdjustmentstolistPrice),
      "FEProducts": parseFloat(this.AdjustmentsAccessories),
      "ExtWarranty": parseFloat(this.AdjustmentstoExtendedWarranty),
      "GAP":parseFloat(this.AdjustmentsGap),
      "JobLoss":parseFloat(this.AdjustmentsJobLoss),
      "VIN": this.VinNumber,
      "DealerID": "0",
      "VehicleId":"0",
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "isTaxExempt": this.istaxExemptValue
    }
    this.GetAdjustedValues(modelGroup);
  }

  GetAdjustedValues(modelGroup:any) {
    this.manageInventoryService.getAdjustedValues(modelGroup)
      .subscribe(
        (data: any) => {

          // Binding Values in Adjustment
          this.TotalAdjustments =parseFloat(data[0].totalAdjustments).toFixed(2) ;
          this.AdjustmentstolistPriceButton = parseFloat(data[0].amountBeforeTax).toFixed(2) ;
          this.AdjustmentstoExtendedWarrantyButton =parseFloat(data[0].amountBeforeTax).toFixed(2) ;
          this.AdjustmentsGapButton = parseFloat(data[0].amountNonTaxable).toFixed(2);
          this.AdjustLogID=data[0].adjustLogID;

          this.AdjustmentsAccessoriesButton=parseFloat(data[0].amountBeforeTax).toFixed(2);
          this.AdjustmentsJobButton=parseFloat(data[0].amountNonTaxable).toFixed(2);

          // for Amount of Tax
          this.AmountOfTax = parseFloat(data[0].amountOfTax).toFixed(2);
        },
        error => {
        });
  }


  ReEvaluate() {
    debugger;
    if (this.addedproductlists.length > 0) {

      if (this.addedproductlists.length == 1) {
        if (isNaN(this.addedproductlists[0].soldPrice)) {
          this.GWPrice1 = "0";
        } else {
          this.GWPrice1 = this.addedproductlists[0].soldPrice;
        }



        if (isNaN(this.addedproductlists[0].price)) {
          this.GWCost1 = "0";
        } else {
          this.GWCost1 = this.addedproductlists[0].price;
        }
        this.GWPrice2 = "0";
        this.GWCost2 = "0";
      }
      if (this.addedproductlists.length == 2) {

        if (isNaN(this.addedproductlists[0].soldPrice)) {
          this.GWPrice1 = "0";
        } else {
          this.GWPrice1 = this.addedproductlists[0].soldPrice;
        }



        if (isNaN(this.addedproductlists[0].price)) {
          this.GWCost1 = "0";
        } else {
          this.GWCost1 = this.addedproductlists[0].price;
        }
        
        if (isNaN(this.addedproductlists[1].soldPrice)) {
          this.GWPrice2 = "0";
        } else {
          this.GWPrice2 = this.addedproductlists[1].soldPrice;
        }

        if (isNaN(this.addedproductlists[1].price)) {
          this.GWCost2 = "0";
        } else {
          this.GWCost2 = this.addedproductlists[1].price;
        }
      }
      
      
      // this.GWPrice2 = this.addedproductlists[1].soldPrice
      // this.GWCost1 = this.addedproductlists[0].price
      // this.GWCost2 = this.addedproductlists[1].price
    } else {
      this.GWPrice1 = "0";
      this.GWPrice2 = "0";
      this.GWCost1 = "0";
      this.GWCost2 = "0";
    }

    this.buttonloaderevaluate = true;
    this.PayCallValueCheck ="Evaluate";
    //this.ResetValues();
    if (this.LenderPayCall == "" && this.LenderPayCall == "undefined") {
      this.LenderPayCall = "0";

    }

    if (this.LenderInterestRate == "" && this.LenderInterestRate == "undefined") {
      this.LenderInterestRate = "0";

    }

    if (this.ACVValueControl == "" && this.ACVValueControl == "undefined") {
      this.ACVValueControl = "0";

    }
    if (this.LoanAmountControl == "" && this.LoanAmountControl == "undefined") {
      this.LoanAmountControl = "0";

    }
    if (this.DownPaymentAmountControl == "" && this.DownPaymentAmountControl == "undefined") {
      this.DownPaymentAmountControl = "0";
    }
    if (this.TOTALAddOnsControls == "NaN") {
      this.TOTALAddOnsControls= 0;
    }
    if(this.TOTALCostControl== "NaN")
    {
      this.TOTALCostControl =  0;
    }
    if(this.ExtendendServiceContractControls=="")
    {
      this.ExtendendServiceContractControls=0;
    }
    if(this.GapControls=="")
    {
      this.GapControls=0;
    }

    if(this.JobLossControls=="")
    {
      this.JobLossControls=0;
    }
    if(this.TOTALAccessoriesControl== "NaN")
    {
      this.TOTALAccessoriesControl=0;
    }
    if(this.TOTALBackEndCostControls== "NaN")
    {
      this.TOTALBackEndCostControls=0;
    }

    var modelGroup = {
      "Opcode": "1",
      "LenderId": this.lenderIdValue.toString(),
      "DealerId": "0",//this.findCarForm.controls.dealerName.value,
      'Dealeridarray': [],
      "GroupNameId": this.GroupNamevalue,
      "ProvinceId": this.ProvinceIdValue,
      "isTaxExempt": this.istaxExemptValue,// this.findCarForm.controls.TaxExemptionCheck.value,
      "LenderKey": this.LenderKeyValue,
      "Paycall": parseFloat(this.LenderPayCall),
      "Interestrate": parseFloat(this.LenderInterestRate),
      "ACVvalues": parseFloat(this.ACVValueControl),
      "LoanAmountvalue": parseFloat(this.LienControl),
      "DownPaymentValue": parseFloat(this.DownPaymentControl),
      "IsInventory": this.AithrHubKey,
      "VIN": this.VinNumber,
      "MaximizeDeal": "0",
      "TradeInValue":parseFloat(this.TradeAllowanceControl),
      "TotalFrontproducts": this.TOTALAccessoriesControl,
      "TotalFrontcost": this.TOTALCostControl,
      "FrontDocFee":this.DealerAdminFreeControl,
      "TotalBackproducts": this.TOTALAddOnsControls,
      "TotalBackcost": this.TOTALBackEndCostControls,
      "ExtWarranty":this.ExtendendServiceContractControls,
      "GAP":this.GapControls,
      "JobLoss":this.JobLossControls,
      "BackDocFee": this.BackDocFee,
      "PPSA":this.PPSAControl,
      "LenderFee":this.LenderFeeControl,
      "Reserves":this.ReservesControl,
      "Amount2Finance":this.AmountFinancedControl,
      "ListedPrice": this.ListPriceControl,
      "ScreenIntRate": this.interestRateControl,
      "ScreenTermPeriod": this.TermControl,
      "LoggedInUser":this.useridValue,
      "LoggedInIP":this.ipaddressvalue,
     "LoggedInSessionID":"",
     "DealerCost":this.DealerCostControl,
     "OverRideFLTV":parseFloat(this.OverRideFLTVControl),
     "OverRideBLTV":parseFloat(this.OverRideBLTVControl),
     'Regionidarray':this.regionidforserver,
     "Make": "",
     "Model": "",
     "BodyStyle": "",
     "Seats": "",
     "CbbValue":this.CBBValueControl,
     "MaxAmountFinance":parseFloat(this.maxAmountFinancevalue),
     "AllowableLoss":parseFloat(this.allowableLossvalue),
     "OverrideTerm": parseFloat(this.TermControl),
     "Deductionfee": parseFloat(this.deductionfeeValue),
     "LicenceFee":parseFloat(this.LicenseFee),
      "FEGrossAmount":parseFloat(this.FrontEndGrossTotalAmount),
      "BEGrossAmount":parseFloat(this.BackEndGrossTotal),
      "OthGrossAmount":parseFloat(this.OtherGrossTotal),
      "IsListPriceLock":this.isLockListPrice,
      "FEAccessory1":parseFloat(this.Accessories1Control),
      "FEAccessory2":parseFloat(this.Accessories2Control),
      "FEAccessory3":parseFloat(this.Accessories3Control),
      "FEMaximizedAmount":parseFloat(this.FEMaximizedAmount),
      "GWPrice1":parseFloat(this.GWPrice1),
      "GWPrice2":parseFloat(this.GWPrice2),
      "GWCost1":parseFloat(this.GWCost1),
      "GWCost2":parseFloat(this.GWCost2),
      "GWTaxes":parseFloat(this.GWTaxes),
    }
    this.GetAllInventoryListbyParameterForBothFindCars(modelGroup);
  }

  // Auto Calculator
  CalculateLoan() {
    this.GetCalculatedLoan();
  }

  GetCalculatedLoan() {
    var modelGroup = {
      "CalculateTotalLoanValue": true,
      "InterestRate": parseFloat(this.CalInterestRate),
      "TermPeriod": parseFloat(this.CalLoanTerm),
      "PayCall": parseFloat(this.CalDesiredMonthlyPayment),
      "TotalLoanValue": 0
    }
    this.manageInventoryService.getCalculatedLoan(modelGroup)
      .subscribe(
        data => {
          this.CalTotalLoanamount=data[0].totalLoanValueRetrun;
        },
        error => {
        });
  }

  DealReport() {

    let builparameter = {
      LenderName: this.LenderNameValue,
      LenderKey: this.PlanType,
      PayCall: this.PayCallDisaply,
      InterestRate: this.InterestRateDisplay,
      AllowableFinance: this.AllowableFinanceDisplay,
      Condition: this.ConditionControl,
      Make: this.MakeControl,
      StockNumber: this.StockNumberControl,
      Model: this.ModelControl,
      VIN: this.VINControl,
      kms:this.KmsControl,
      year:this.Trim1Control,
      trim:this.TrimControl,
      AmountFinanced:this.AmountFinancedControl,
      CBBValue:this.CBBValueControl,
      ListPrice:this.ListPriceControl,
      DealerCost:this.DealerCostControl,
      TradeAllowance:this.TradeAllowanceControl,
      Lien:this.LienControl,
      ACVControl:this.ACVControl,
      EquityControl:this.EquityControl,
      DownPaymentControl:this.DownPaymentControl,
      TotalTaxControl:this.TotalTaxControl,
      RegionControl:this.RegionControl,
      TaxRateControl:this.TaxRateControl,
      MonthlyControl:this.MonthlyControl,
      interestRateControl:this.interestRateControl,
      SemiMonthlyControl:this.SemiMonthlyControl,
      WeeklyControl:this.WeeklyControl,
      BiWeeklyControl:this.BiWeeklyControl,
      ReservesControl:this.ReservesControl,
      TotalLoantoValueControl:this.TotalLoantoValueControl,
      GrossProfit:this.GrossProfitControl,
      Term:this.TermControl,
      LenderFee:this.LenderFeeControl,
      PPSA:this.PPSAControl,
      FrontDocFee:this.DealerAdminFreeControl,
      BackDocFee:this.BackDocFee,
      OverRideFLTV:this.OverRideFLTVControl,
      OverRideBLTV:this.OverRideBLTVControl,
      TOTALAccessories:this.TOTALAccessoriesControl,
      AccessoriesTOTALCost:this.TOTALCostControl,
      TOTALAddOns:this.TOTALAddOnsControls,
      TOTALBackEndCost:this.TOTALBackEndCostControls,
      ExtendendService:this.ExtendendServiceContractControls,
      ExtendendCost:this.ExtendendCostControls,
      Gap:this.GapControls,
      GapCost:this.GapCostControls,
      JobLoss:this.JobLossControls,
      JobLossCost:this.JobLossCostControls,


    };
    let navigationExtras: NavigationExtras = {
      state: {
        builparameter: builparameter,
      },
    };
    // Put the object into storage
    localStorage.setItem('builPageValues', JSON.stringify(builparameter));

    this.openNewWindow();
  }
  openNewWindow(): void {

    const link = document.createElement('a');
    link.target = '_blank';
    link.href = '#/build-report';
    link.click();
    link.remove();
  }

  GetAllInventoryListbyParameterForBothFindCars(modelGroup:any) {
    this.manageInventoryService.getAllInventoryListbyParameterForBothFindCars(modelGroup)
      .subscribe(
        (data: any) => {
       
         
          // for credit APP
          this.LogUTCDateTime = data[0].logUTCDateTime;
          // controls for AdjustMent Made
          this.AdjustmentsNewListPrice = parseFloat(data[0].listedPrice).toFixed(2);

          this.LicenseFee = parseFloat(data[0].licenceFee).toFixed(2);
          this.FrontEndGrossTotalAmount = parseFloat(data[0].feGrossAmount).toFixed(2);
          this.BackEndGrossTotal = parseFloat(data[0].beGrossAmount).toFixed(2);
          this.OtherGrossTotal = parseFloat(data[0].othGrossAmount).toFixed(2);

          this.AdjustmentNewFrontDocumentationFee = parseFloat(data[0].frontDocFee).toFixed(2);
          this.ConditionControl = data[0].condition;
          this.MakeControl = data[0].make;
          this.StockNumberControl = data[0].stockNumber;
          this.ModelControl = data[0].model;
          this.VINControl = data[0].vin;
          this.TrimControl = data[0].trim;
          this.KmsControl = data[0].mileage;
          this.Trim1Control = data[0].carYear;
          this.CBBValueControl = parseFloat(data[0].effectiveCBB).toFixed(2);
          this.ListPriceControl = parseFloat(data[0].listedPrice).toFixed(2);
          this.DealerCostControl = parseFloat(data[0].dealerCost).toFixed(2);
          this.LenderFeeControl = parseFloat(data[0].lenderFee).toFixed(2);
          this.PPSAControl = parseFloat(data[0].ppsa).toFixed(2);
          this.interestRateControl = parseFloat(data[0].intRate).toFixed(2);
          this.LienControl = parseFloat(data[0].lien).toFixed(2);
          this.DownPaymentControl = parseFloat(data[0].downPayment).toFixed(2);
          this.BackDocFee = parseFloat(data[0].backDocFee).toFixed(2);
          this.ExtendendServiceContractControls = parseFloat(data[0].extWarranty).toFixed(2);
          this.GapControls = parseFloat(data[0].gap).toFixed(2);
          this.JobLossControls = parseFloat(data[0].jobLoss).toFixed(2);
          this.MonthlyControl = parseFloat(data[0].calculatedPayCall).toFixed(2);

          // For Current Payment in Adjust Roll Payment Model Pop up
          this.CurrentPayment = parseFloat(data[0].calculatedPayCall).toFixed(2);
          this.AdjustmentstolistPrice = parseFloat(data[0].listedPrice).toFixed(2);
          this.AdjustmentstoExtendedWarranty =parseFloat(this.ExtendendServiceContractControls).toFixed(2);
          this.AdjustmentsGap =parseFloat(this.GapControls).toFixed(2);

          // END OF Roll Payment

          this.TermControl = parseFloat(data[0].termPeriod).toFixed(2); //data[0].termPeriod;
          if(this.MonthlyControl > this.LenderPayCall)
          {
            this.showAlert();
          } else
          {
            this.showAlert();
          }

          this.AllowableFinanceDisplay =parseFloat(data[0].allowableFinance).toFixed(2);
          this.AmountFinancedControl = parseFloat(data[0].amountToBeFinanced).toFixed(2);
          this.DealerAdvanceDisplay = parseFloat(data[0].dealerAdvance).toFixed(2);
          this.purchasePriceLeaseValue = parseFloat(data[0].amountToBeFinanced).toFixed(2);

          


          if(data[0].lenderName=="Go Plan")
          {
           this.showDealerAdnce="true";
          }
          else
          {
            this.showDealerAdnce="false";
          }


          // for change the color of AmountFinancedControl
          if(this.AmountFinancedControl > this.AllowableFinanceDisplay)
          {
            this.colormessage ="Change";
          } else
          {
            this.colormessage ="1";
          }

          // Lower Panel

          this.TOTALAccessoriesControl= parseFloat(data[0].feProducts).toFixed(2);

           this.Accessories1Control = parseFloat(data[0].feAccessory1).toFixed(2) ; 
           this.Accessories2Control = parseFloat(data[0].feAccessory2).toFixed(2) ; 
           this.Accessories3Control =parseFloat(data[0].feAccessory3).toFixed(2) ; 
           this.FEMaximizedAmount=parseFloat(data[0].feMaximizedAmount).toFixed(2) ;
          // this.TOTALAccessoriesControl = parseFloat(objValues.feProducts).toFixed(2) ;  

          this.TOTALCostControl= parseFloat(data[0].feCost).toFixed(2);
          this.TOTALAddOnsControls= parseFloat(data[0].beProduct).toFixed(2);
          this.TOTALBackEndCostControls= parseFloat(data[0].beCost).toFixed(2);

          // for semiMonthly
          this.SemiMonthlyValues = this.MonthlyControl / 2;
          this.SemiMonthlyControl = parseFloat(this.SemiMonthlyValues).toFixed(2);

          // for Bi Weekly
          this.biMonth = this.MonthlyControl * 12;
          this.BiMonthlyValues = parseFloat(this.biMonth) / 26;
          this.BiWeeklyControl = parseFloat(this.BiMonthlyValues).toFixed(2);

          // For weekly
          this.BiWeekValues = parseFloat(this.biMonth) / 52;
          this.WeeklyControl = parseFloat(this.BiWeekValues).toFixed(2);
          this.TotalLoantoValueControl = parseFloat(data[0].tlv).toFixed(2);
          // this.ACVControl = parseFloat(this.ACVValueControl).toFixed(2);
          this.ACVControl = parseFloat(data[0].acv).toFixed(2);
          this.TradeAllowanceControl =  parseFloat(data[0].tradeInValue).toFixed(2);
         // this.TradeAllowanceControl =  parseFloat(this.ACVValueControl).toFixed(2);
          this.DealerAdminFreeControl = parseFloat(data[0].frontDocFee).toFixed(2);
          this.BackDocFee = parseFloat(data[0].backDocFee).toFixed(2);
          this.GrossProfitControl = parseFloat(data[0].projectedGross).toFixed(2);
          this.ReservesControl = parseFloat(data[0].reserves).toFixed(2);

          // ADDED NEW CODE
          this.InterestRateDisplay = parseFloat(data[0].intRate).toFixed(2);
          this.TotalTaxControl = parseFloat(data[0].totalTaxes).toFixed(2);


         this.EquityControl = parseFloat(this.TradeAllowanceControl) - parseFloat(this.LienControl);
        // this.EquityControl = (parseFloat(this.TradeAllowanceControl) + parseFloat(this.ACVValueControl) + parseFloat(this.DownPaymentControl)) - parseFloat(this.LienControl);
          this.EquityControl = parseFloat(this.EquityControl).toFixed(2);

          this.RemainingBackLoanValues = parseFloat(data[0].totalBLTV).toFixed(2);

          this.RemainingBackLoanValue= parseFloat(data[0].remainingBLTV).toFixed(2);

          this.OverRideBLTVControl= parseInt(data[0].bltv);
          this.OverRideFLTVControl= parseInt(data[0].fltv);

        //  this.RemainingBackLoanValue = parseFloat(this.RemainingBackLoanValues)- (parseFloat(this.TOTALAddOnsControls) - parseFloat(this.TOTALBackEndCostControls));
        //  this.RemainingBackLoanValue= parseFloat(this.RemainingBackLoanValue).toFixed(2);


         // Calculations for FLTV
         this.RemainingFrontLoanValue = parseFloat(data[0].remainingFLTV).toFixed(2);
         // Remaining FLTV =TOTALFLTV - ((ListedPrice + DealerAdminFee + TotalAccess.)- (Equity * -1) + Down Payment )

          // this.RemainingFrontLoanValueCheck = parseFloat(data[0].totalFLTV) - ((parseFloat(data[0].listedPrice) +
          //                           parseFloat(this.TOTALAccessoriesControl) + parseFloat(this.DealerAdminFreeControl)) -(parseFloat(this.EquityControl) * -1)
          //                           + parseFloat(this.DownPaymentControl));
          // this.RemainingFrontLoanValue = this.RemainingFrontLoanValueCheck.toFixed(2);

          this.RemainingFrontLoanValues = parseFloat(data[0].totalFLTV).toFixed(2);

          this.buttonloader = false;
          this.buttonReset = false;
          this.buttonloaderevaluate = false;

          // Total loan values must not exceeding to Amount finnace values >>>> Must give a alert mess
          if(parseFloat(this.AmountFinancedControl) > parseFloat(this.TotalLoantoValueControl))
          {
           this.showLoanAlert();
          }



          if (this.ValueSetForAdjustModelPopUp == "Load") {
            this.AdjustmentsMonthlyPayment=parseFloat(data[0].calculatedPayCall).toFixed(2);
            this.AdjustmentsGrossProfit =parseFloat(data[0].projectedGross).toFixed(2);
            this.AdjustmentsRemainingFLTV =this.RemainingFrontLoanValue ;
            this.AdjustmentsRemainingBLTV  =this.RemainingBackLoanValue;
            this.AdjustmentsTotalTaxes=parseFloat(data[0].totalTaxes).toFixed(2);
          


          } else if (this.ValueSetForAdjustModelPopUp == "MaximizeDeal") {
            this.AdjustmentsNewMonthlyPayment=parseFloat(data[0].calculatedPayCall).toFixed(2);
            this.AdjustmentsNewGrossProfit=parseFloat(data[0].projectedGross).toFixed(2);
            this.AdjustmentsNewRemainingFLTV=this.RemainingFrontLoanValue
            this.AdjustmentsNewRemainingBLTV=this.RemainingBackLoanValue;
            this.AdjustmentsNewTotalTaxes=parseFloat(data[0].totalTaxes).toFixed(2);
          }





          if(parseFloat(this.AdjustmentFrontDocumentationFee) != parseFloat(this.AdjustmentNewFrontDocumentationFee))
          {
            this.colorAdjustFrontFee ="Change";
          } else
          {
            this.colorAdjustFrontFee ="1";
          }

          if(parseFloat(this.AdjustmentsRemainingBLTV) != parseFloat(this.AdjustmentsNewRemainingBLTV))
          {
            this.colorAdjustBLTV ="Change";
          } else
          {
            this.colorAdjustBLTV ="1";
          }

          if(parseFloat(this.AdjustmentsRemainingFLTV) != parseFloat(this.AdjustmentsNewRemainingFLTV))
          {
            this.colorAdjustFLTV ="Change";
          } else
          {
            this.colorAdjustFLTV ="1";
          }

          // for change the color of AmountFinancedControl
          if(parseFloat(this.AdjustmentsTotalTaxes) != parseFloat(this.AdjustmentsNewTotalTaxes))
          {
            this.colorAdjustTotalTaxes ="Change";
          } else
          {
            this.colorAdjustTotalTaxes ="1";
          }


          // for change the color of AmountFinancedControl
          if(parseFloat(this.AdjustmentsNewMonthlyPayment) != parseFloat(this.AdjustmentsMonthlyPayment))
          {
            this.colorAdjustMonthly ="Change";
          } else
          {
            this.colorAdjustMonthly ="1";
          }

          if(parseFloat(this.AdjustmentsListPrice) != parseFloat(this.AdjustmentsNewListPrice))
          {
            this.colorAdjustListPrice ="Change";
          } else
          {
            this.colorAdjustListPrice ="1";
          }

          if(parseFloat(this.AdjustmentsGrossProfit) != parseFloat(this.AdjustmentsNewGrossProfit))
          {
            this.colorAdjustGrossProfit ="Change";
          } else
          {
            this.colorAdjustGrossProfit ="1";
          }

          // for showing Model pop up only in case when maximize is hit
          if (modelGroup.frommaximize == true) {
            this.closebutton.nativeElement.click();
          }

          // FOR GETTING UVC Detail
          this.GetUVCdetails();

           // for Getting Product List
           this.GetProductListByVin();
        },
        error => {
        });
  }




  ResetValues() {
    this.ConditionControl = "";
    this.GrossProfitControl = "";

    this.RemainingBackLoanValues = 0;
    this.RemainingBackLoanValue =  0;
    this.RemainingFrontLoanValue= 0;
    this.RemainingFrontLoanValueCheck=0;
    this.RemainingFrontLoanValues= 0;
    this.ReservesControl = 0;

    this.TradeAllowanceControl= 0;
    this.LicenseFee = 0;
    this.MakeControl = "";
    this.StockNumberControl = "";
    this.ModelControl = "";
    this.VINControl = "";
    this.TrimControl = "";
    this.KmsControl = "";
    this.Trim1Control = "";
    this.CBBValueControl = 0;
    this.ListPriceControl = 0;
    this.DealerCostControl = 0;
    this.DocAdminFeeFront= 0;
    this.DocAdminFeeback= 0;
    this.LenderFeeControl = 0;
    this.PPSAControl = 0;
    this.interestRateControl = 0;
    this.LienControl = 0;
    this.DownPaymentControl = 0;
    this.TOTALCostControl = 0;
    //this.TermControl = "";

    this.MonthlyControl = 0;
    this.AmountFinancedControl = 0;
    this.EquityControl = 0;
    this.SemiMonthlyValues = 0;
    this.SemiMonthlyControl = 0;
    this.biMonth = 0;
    this.BiMonthlyValues = 0;
    this.BiWeeklyControl = 0;
    this.TotalLoantoValueControl = 0;
    this.ACVControl = 0;
    this.DealerAdminFreeControl = 0;

    this.TOTALAccessoriesControl= 0;
    this.TOTALCostControl= 0;

    this.Accessories1Control= 0;
    this.Cost1Control= 0;
    this.Accessories2Control= 0;
    this.Cost2Control= 0;
    this.Accessories3Control= 0;
    this.Cost3Control= 0;
    this.TOTALAddOnsControls= 0;
    this.TOTALBackEndCostControls= 0;
    this.ExtendendServiceContractControls= 0;
    this.ExtendendCostControls= 0;
    this.GapControls= 0;
    this.GapCostControls= 0;
    this.JobLossControls= 0;
    this.JobLossCostControls= 0;
  }

  // click button hide show
  toggleDisplayDiv() {
    this.isShowDiv = !this.isShowDiv;
  }

  // click button hide show
  togglelenderinfo()
  {
    this.hideShowTopButtons ="true";
    this.islenderinfo = !this.islenderinfo;
  }

  GetAppUVCDetails() {
    let el: HTMLElement = this.ConfirmBoxUVCdetailclick.nativeElement;
    el.click();
  }

  GetAppWorkSheetDetails() {

    // if any paramter is not selected
    if (this.UVCControl == "") {
      let el: HTMLElement = this.ConfirmBoxGetValidationdetailclick.nativeElement;
      el.click();
    } else {
      let el: HTMLElement = this.ConfirmBoxGetWorkSheetdetailclick.nativeElement;
      el.click();
    }

  }

  openModalgetDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  openModalgetValidationDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }

  openModalgetUVCDetail(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }


  confirmUVCDetail(): void {
    this.modalRef.hide();
    this.GetUVCdetails();
  }

  confirmSetWorkSheetDetail(): void {
    this.modalRef.hide();
    this.SetWorkSheetDetailList();
  }

  confirmSetValidationDetail(): void {
    this.modalRef.hide();
    
  }

  GetUVCdetails() {

    var modelGroup = {
      "vin": this.VINControl,
      "kilometers": this.KmsControl.toString(),

    }
    this.globalcodeService.getUVCDetails(modelGroup)
      .subscribe(
        (data: any) => {
       
          //this.UVCControl=data[0].uvc;
          this.UVCControl=data;
          //data[0].uvc this.showNotification("success", "Work Sheet Details set successfully");

        },
        error => {
        });
  }

  SetWorkSheetDetailList() {

    var modelGroup = {
      "applicationId": this.applicationId,
      "creditorId": this.creditorId,
      "LogUTCDateTime":this.LogUTCDateTime
    }
    this.globalcodeService.setAWorkSheetDetailList(modelGroup)
      .subscribe(
        (data: any) => {
           
        //  this.showNotification("success", "Work Sheet Details set successfully");
        let el: HTMLElement = this.ConfirmBoxAfterWorkSheetdetailclick.nativeElement;
        el.click();

        },
        error => {
        });
  }

  // Verify Units
  VerifyVehicle(e, type, item: any) {
    if (this.verifycheckboxes.length > 0) {
      this.verifycheckboxes = [];
    }
    if (e.target.checked) {
      var getuserid = localStorage.getItem('userid');
      var checkedverifydata = {
        vin: this.selectedVin,
        type: type,
        loggeduserid: getuserid,
        notes: this.typeofaction,
        hello:"testing",
        ipAddress:localStorage.getItem('IpAddresstoTrack')

      }

      this.verifycheckboxes.push(checkedverifydata);

    }
    else {
      let updateItem = this.verifycheckboxes.find(this.findIndexToUpdate, type);

      let index = this.verifycheckboxes.indexOf(updateItem);

      this.verifycheckboxes.splice(index, 1);

    }
  }
  findIndexToUpdate(type) {
    return type === this;
  }

  Pdfview()
  {
    var formData = {
      "Id": this.lenderIdValue

    }
    this.lenderService.Getpdfviw(formData)
    .subscribe(
      (data: any) => {
        var byteCharacters = atob(data.filebase);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
      },
      error => {
      });
  }
  decline(): void {
    this.modalRef.hide();
  }

  declineCalculate(): void {
    this.CalLoanTerm=0;
    this.CalInterestRate=0;
    this.CalDesiredMonthlyPayment=0;
    this.CalTotalLoanamount=0;

    this.modalRef.hide();
  }


  DataForBuyClick() {
    var getuserid = localStorage.getItem('userid');
    var checkedverifydata = {
      vin: this.selectedVin,
      type: "Buy",
      loggeduserid: getuserid,
      notes: this.typeofaction,
      ipAddress:localStorage.getItem('IpAddresstoTrack')

    }

    this.verifycheckboxes.push(checkedverifydata);
  }

  Aithrenquiry(template: TemplateRef<any>, typeofaction: any) {
    //this.verifycheckboxes = [];
    this.selectedVin = this.VINControl;
    this.typeofaction = typeofaction;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }


  PostVerifyvehicleRequest() {
    debugger;
    this.buttonloaderSendEmail=true;
    this.buttonloaderBuyEmail =true;
    if (this.typeofaction == "Buy") {
      this.DataForBuyClick();
    }

    this.spinner.show();
    var modelGroup = {
      "requestvehicle": this.verifycheckboxes,

    }

    this.manageInventoryService.PostVerifyVehicledata(modelGroup)
      .subscribe(
        (data: any) => {
          this.showNotification("success", "Request saved and communicated via email.");

         this.buttonloaderSendEmail=false;
         this.buttonloaderBuyEmail =false;
         this.decline();
          this.spinner.hide();
         this.SendAithrbuyAndVerifyEmailList();

        },
        error => {
        });

  }

  SendAithrbuyAndVerifyEmailList() {
    var modelGroup = {
      "UserId": this.useridValue.toString(),
      "VIN": this.VINControl.toString(),
      "ButtonType":this.typeofaction.toString(),
      "PageType":"Build"
    }
    this.manageInventoryService.sendAithrbuyAndVerifyEmail(modelGroup)
      .subscribe(
        data => {
        },
        error => {
        });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  hidetopTopButton()
  {
    this.hidetopBarButton ="true";
    this.divTopButtonsshow="false";
  }
  showtopTopButton()
  {
    this.hidetopBarButton ="false";
    this.divTopButtonsshow="true";
  }
  HideCostValue()
  {

   // this.addedproductlists
    for (let i = 0; i < this.addedproductlists.length; i++) {
      $('#rowPrice_' + i).hide();
    }
    this.hideCostButton="false";
    this.hideGrossProfit= "false";
    this.hideCostProduct = "false"
    this.hideDealerCost= "false";

    this.TOTALCosthide= "false";
    this.Cost1Hide= "false";
    this.Cost2Hide= "false";
    this.Cost3Hide= "false";

    this.BackendCostHide= "false";
    this.ExtendendCostHide= "false";
    this.GapCostHide= "false";
    this.JobLossHide= "false";

  }
  ShowCostValue()
  {
    for (let i = 0; i < this.addedproductlists.length; i++) {
      $('#rowPrice_' + i).show();
    }
    this.hideCostButton="true";

    this.hideGrossProfit= "true";
    this.hideCostProduct = "true"
    this.hideDealerCost= "true";

    this.TOTALCosthide= "true";
    this.Cost1Hide= "true";
    this.Cost2Hide= "true";
    this.Cost3Hide= "true";

    this.BackendCostHide= "true";
    this.ExtendendCostHide= "true";
    this.GapCostHide= "true";
    this.JobLossHide= "true";
  }

  Createform() {
    this.buildCarForm = new FormGroup({
      ConditionControl: this.fb.control(''),
      GrossProfitControl: this.fb.control(''),
      MakeControl: this.fb.control(''),
      AmountFinancedControl: this.fb.control(''),
      CBBValueControl: this.fb.control(''),
      MonthlyControl: this.fb.control(''),
      interestRateControl: this.fb.control(''),
      StockNumberControl: this.fb.control(''),
      ModelControl: this.fb.control(''),
      ListPriceControl: this.fb.control(''),
      DealerCostControl: this.fb.control(''),
      DocAdminFeeFront: this.fb.control(''),
      DocAdminFeeback: this.fb.control(''),
      SemiMonthlyControl: this.fb.control(''),
      WeeklyControl: this.fb.control(''),
      VINControl: this.fb.control(''),
      TrimControl: this.fb.control(''),
      TradeAllowanceControl: this.fb.control(''),
      LicenseFee: this.fb.control(''),
      LienControl: this.fb.control(''),
      BiWeeklyControl: this.fb.control(''),
      ReservesControl: this.fb.control(''),
      KmsControl: this.fb.control(''),
      Trim1Control: this.fb.control(''),
      ACVControl: this.fb.control(''),
      EquityControl: this.fb.control(''),
      TotalLoantoValueControl: this.fb.control(''),
      Reserves1Control: this.fb.control(''),
      DetailsControl: this.fb.control(''),
     UVCControl: this.fb.control(''),
      DownPaymentControl: this.fb.control(''),
      TotalTaxControl: this.fb.control(''),
      TermControl: this.fb.control(''),
      LenderFeeControl: this.fb.control(''),
      RegionControl: this.fb.control(''),
      TaxRateControl: this.fb.control(''),
      PPSAControl: this.fb.control(''),
      GPSFeeControlValue: this.fb.control(''),

      TOTALAccessoriesControl: this.fb.control(''),
      TOTALCostControl: this.fb.control(''),
      DealerAdminFreeControl: this.fb.control(''),
      Accessories1Control: this.fb.control(''),
      Accessories2Control: this.fb.control(''),
      Accessories3Control: this.fb.control(''),
      Cost1Control: this.fb.control(''),
      Cost2Control: this.fb.control(''),
      Cost3Control: this.fb.control(''),
      TOTALAddOnsControls: this.fb.control(''),
      TOTALBackEndCostControls: this.fb.control(''),
      ExtendendServiceContractControls: this.fb.control(''),
      ExtendendCostControls: this.fb.control(''),
      GapControls: this.fb.control(''),
      GapCostControls: this.fb.control(''),
      JobLossControls: this.fb.control(''),
      JobLossCostControls: this.fb.control(''),
      BackDocFeeControl: this.fb.control(''),
      // Controls for Adjust model pop up
      CurrentPayment: this.fb.control(''),
      DesiredPayment: this.fb.control(''),
      TotalAdjustments: this.fb.control(''),
      AdjustmentstolistPrice: this.fb.control(''),

      AdjustmentstoExtendedWarranty: this.fb.control(''),
      AdjustmentsGap: this.fb.control(''),
      AdjustmentsJobLoss: this.fb.control(''),
      AdjustmentsAccessories: this.fb.control(''),
      // Adjustment Payment
      AdjustmentsListPrice: this.fb.control(''),
      AdjustmentsNewListPrice: this.fb.control(''),
      AdjustmentFrontDocumentationFee: this.fb.control(''),
      AdjustmentNewFrontDocumentationFee: this.fb.control(''),
      AdjustmentsMonthlyPayment: this.fb.control(''),
      AdjustmentsNewMonthlyPayment: this.fb.control(''),
      AdjustmentsGrossProfit: this.fb.control(''),
      AdjustmentsNewGrossProfit: this.fb.control(''),
      AdjustmentsRemainingFLTV: this.fb.control(''),
      AdjustmentsNewRemainingFLTV: this.fb.control(''),
      AdjustmentsRemainingBLTV: this.fb.control(''),
      AdjustmentsNewRemainingBLTV: this.fb.control(''),
      AdjustmentsTotalTaxes: this.fb.control(''),
      AdjustmentsNewTotalTaxes: this.fb.control(''),


      RuleSetControl: this.fb.control(''),
      UpperLimitControl: this.fb.control(''),
      LowerLimitControl: this.fb.control(''),
      LenderNameValue: this.fb.control(''),
      PlanType: this.fb.control(''),
      PayCallDisaply: this.fb.control(''),
      InterestRateDisplay: this.fb.control(''),
      AllowableFinanceDisplay: this.fb.control(''),
      DealerAdvanceDisplay: this.fb.control(''),
      Deductionfee: this.fb.control(''),
      OverRideBLTVControl: this.fb.control(''),
      OverRideFLTVControl: this.fb.control(''),

      // Cal Loan
      CalTotalLoanamount: this.fb.control(''),
      CalDesiredMonthlyPayment: this.fb.control(''),
      CalInterestRate: this.fb.control(''),
      CalLoanTerm: this.fb.control(''),

      // new controls

      FrontEndGrossTotalAmount: this.fb.control(''),
      OtherGrossTotal: this.fb.control(''),
      BackEndGrossTotal: this.fb.control(''),

      // controls for orders
      purchasePriceLeaseValue: this.fb.control(''),
      ProvinceStateCode: this.fb.control(''),
      OrderFirstName: this.fb.control(''),
      OrderLastName: this.fb.control(''),
      OrderLienholderName: this.fb.control(''),
      OrderPhone: this.fb.control(''),
      OrderEmail: this.fb.control(''),
      OrderPostalCode: this.fb.control(''),
      OrderAddress1: this.fb.control(''),
      OrderAddress2: this.fb.control(''),
      OrderCity: this.fb.control(''),
      SoldPriceValue: this.fb.control(''),
      OrderVenderKey: this.fb.control(''),
      IsCommercialCheck: this.fb.control(''),
      OrderProductKey: this.fb.control(''),
    });
  }
}
