<section id="admindashboard">
    <div class="container-fluid">
        <div class="page-header pt-2 pb-0 mb-1">
            <div class="row">
                <div class="col-lg-12">
                    <h3 class="mb-0 d-inline-block text-primary" style="text-transform: capitalize !important;">
                        welcome
                    </h3>
                    <h4 class="d-inline-block ml-2 mb-0"> <span class="subtitle ">{{UserNamevalue}}</span></h4>
                </div>
            </div>
        </div>
        <hr />
        <div class="row mt-1">
            <div class="col-lg-6 col-md-12 col-12 mb-2 mb-lg-0">
                <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white">
                        <h6 class="card-title text-white text-uppercase mb-0">
                            Spadez Software Updates
                        </h6>
                    </div>
                    <div class="card-body px-3 py-2">
                        <div class="row">
                            <div id="softwateUpdate" class="col-12">
                                <div class="table-responsive mb-0">
                                    <table class="table table-borderless mb-0">
                                        <tbody>
                                            <tr *ngFor="let UpdateLists of UpdateList">
                                                <td><span class="date"> {{ UpdateLists.createdDate | date}}</span></td>
                                                <td class="sheetupdate">
                                                    <span class="text-success">
                                                        {{ UpdateLists.globalCodeName}}
                                                    </span>
                                                </td>
                                                <!-- <td class="fulldetails">
                                                    <a href="#">
                                                        Click for full details
                                                    </a>
                                                </td> -->
                                            </tr>

                                            <tr>
                                                <td height="5"></td>
                                            </tr>
                                            <tr>
                                                <td></td>
                                                <td></td>
                                                <td class="seefullhistory">
                                                    <!-- <span class="px-3">
                                                        <a href="#">See Full History
                                                            <i class="fas fa-external-link"></i>
                                                        </a>
                                                    </span> -->
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-12">
                <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white">
                        <div class="row align-item-center justify-content-between mx-0">
                            <h6 class="card-title text-white text-uppercase mb-0">
                                Spadez Hub Inventory
                            </h6>
                            <button type="button" (click)="RedirectToInventory();" class="btn btn-xs btn-warning">Manage Inventory</button>
                        </div>

                    </div>
                    <div id="welcomeinventory" class="card-body px-3 pt-3 pb-0">
                        <div class="row mx-0">
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-success">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{myInventoryCount}}</h5>
                                                <i class="fas fa-truck-moving"></i>
                                                <div class="text-end w-100">
                                                    <span>{{myInventoryText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-primary">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{groupInventoryCount}}</h5>
                                                <i class="fas fa-layer-group"></i>
                                                <div class="text-end w-100">
                                                    <span>{{groupInventoryText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-warning">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{totalHubInventoryCount}}</h5>
                                                <i class="fas fa-wrench"></i>
                                                <div class="text-end w-100">
                                                    <span>{{totalHubInventoryText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-success">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{overDamagedCount}}</h5>
                                                <i class="fas fa-house-damage"></i>
                                                <div class="text-end w-100">
                                                    <span>{{overDamagedText}} {{LoggedRegionName}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-primary">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{unitsBuildCount}}</h5>
                                                <i class="far fa-building"></i>
                                                <div class="text-end w-100">
                                                    <span>{{unitsBuildText}} {{LoggedRegionName}}

                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-warning">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{unitsMaximizedCount}}</h5>
                                                <i class="fas fa-compress-alt"></i>
                                                <div class="text-end w-100">
                                                    <span>{{unitsMaximizedText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-lg-6 col-md-12 col-12 mb-2 mb-lg-0">
                <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white">
                            <h6 class="card-title text-white text-uppercase mb-0">
                                Need Help? Want to Report a Bug?
                            </h6>
                    </div>
                    <div class="card-body px-3 py-2">
                        <div class="row">
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-xl-5 col-lg-12 col-md-5 px-2">
                                        <div class="needhelp">
                                            <img src="../../assets/images/need-help.jpg" alt="Need help">
                                        </div>
                                    </div>
                                    <div class="col-xl-7 col-lg-12 col-md-7 d-flex align-items-center">
                                        <div class="needhelpdescription mt-2">
                                            <p>Your feedback is what makes our software better every day!
                                                Please reach out if you need any assistance, support, or want
                                                to report any bugs you have found.</p>
                                            <!-- <a (click)="onclick()" href="https://Spadezauto.freshdesk.com/support/home"
                                            target="_blank" type="button" id="supportTicket">
                                                Submit a support ticket here
                                            </a> -->
                                            <a (click)="onclick()" type="button" id="supportTicket">
                                                Submit a support ticket here
                                            </a>
                                            &nbsp; &nbsp; &nbsp;
                                            <!-- <a routerLink="/training-video" type="button" id="supportTicket">
                                                Training Video
                                            </a> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-6 col-md-12 col-12">
                <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white">
                        <div class="row align-item-center justify-content-between mx-0">
                            <h6 class="card-title text-white text-uppercase mb-0">
                                Analytics
                            </h6>
                            <button type="button"  (click)="RedirectToDashBoard();"
                            class="btn btn-xs btn-warning">Dashboard</button>
                        </div>

                    </div>
                    <div id="welcomeinventory" class="card-body px-3 pt-3 pb-0">
                        <div class="row mx-0">
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-success">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{TotalUserCount}}</h5>
                                                <i class="fas fa-truck-moving"></i>
                                                <div class="text-end w-100">
                                                    <span>{{TotalUserText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-primary">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{DealsBuildCount}}</h5>
                                                <i class="fas fa-layer-group"></i>
                                                <div class="text-end w-100">
                                                    <span>{{DealsBuildText}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-warning">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{HubInquiriesCount}}</h5>
                                                <i class="fas fa-wrench"></i>
                                                <div class="text-end w-100">
                                                    <span>{{HubInquiriesText}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mx-0">
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-success">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{ApprovalSearchedCount }}</h5>
                                                <i class="fas fa-house-damage"></i>
                                                <div class="text-end w-100">
                                                    <span>{{ApprovalSearchedText}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-primary">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{AccountdamagedCount | currency: 'USD'}}</h5>
                                                <i class="far fa-building"></i>
                                                <div class="text-end w-100">
                                                    <span>{{AccountdamagedText}}  </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-4 inventoryblocks">
                                <div class="card inventory-warning">
                                    <div class="card-body">
                                        <div class="stat-widget-dashboard">
                                            <div class="row justify-content-between align-item-center mx-0">
                                                <h5 class="mt-0 counter text-white">{{HubverifiedCountCount}}</h5>
                                                <i class="fas fa-compress-alt"></i>
                                                <div class="text-end w-100">
                                                    <span>{{HubverifiedText}} </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

            <app-footer id="secondfooter"></app-footer>

        



        <ng-container *ngIf="visible">
            <form class="col-12 px-0" [formGroup]="WelcomeDashboradForm">
                <div id="adddealerName" class="row mt-2">
                    <div class="col-lg-6 col-md-12 col-12">
                        <div class="card card-raised h-100">
                            <div class="card-header card-raised bg-info text-white">
                                <h6 class="card-title text-white text-uppercase mb-0">
                                    Need Help? Want to Report a Bug?
                                </h6>
                            </div>
                            <div class="card-body px-3 py-2 pt-3">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group mb-2">
                                            <label class="col-form-label">
                                                Requester (Please enter your email address)<span class="text-danger"
                                                    style="vertical-align:middle;">*</span></label>
                                            <input type="text" formControlName="requester" class="form-control">
                                        </div>
                                        <div class="form-group mb-2">
                                            <label class="col-form-label">Dealership Name</label>
                                            <input type="text" formControlName="DealershipName" class="form-control">

                                        </div>
                                        <div class="form-group mb-2">
                                            <label class="col-form-label">Please Confirm your Phone #</label>
                                            <input name="phone" type="phone" [(ngModel)]="phone" formControlName="phone"
                                                class="form-control">
                                        </div>
                                        <div class="form-group mb-2">
                                            <label class="col-form-label">Subject
                                                <span class="text-danger" [(ngModel)]="Subject"
                                                    formControlName="Subject"
                                                    style="vertical-align:middle;">*</span></label>
                                            <input name="Subject" type="text" class="form-control"
                                                formControlName="Subject">
                                        </div>
                                        <div class="form-group mb-2">
                                            <label class="col-form-label">Description
                                                <span class="text-danger" formControlName="Description"
                                                    [(ngModel)]="Description"
                                                    style="vertical-align:middle;">*</span></label>
                                            <textarea type="text" formControlName="Description"
                                                class="form-control"></textarea>
                                        </div>
                                        <div class="form-group mb-0 mt-3">
                                            <button type="submit" (click)="SendQuery();"
                                                class="btn btn-sm bg-gradient-success">Submit</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-container>
    </div>
</section>