<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="buildCarForm">
        <div class="container-fluid">
            <div class="page-header pb-1 pt-1 mb-4">
                <div class="card mb-0">
                    <div id="buildcartopbar" class="card-header card-raised bg-info text-white">
                        <div class="row justify-content-start align-items-center">
                            <div class="customerInfo col-auto">
                                <button *ngIf="hidetopBarButton ==='true'" title="View Customer and Lender info"
                                    (click)="showtopTopButton()" id="HideShowButton" type="button"
                                    class="btn btn-warning btn-xs">
                                    <i class="fas fa-plus"></i>
                                </button>
                                <button *ngIf="hidetopBarButton ==='false'" title="Hide Customer and Lender info"
                                    (click)="hidetopTopButton()" id="hideButton" type="button"
                                    class="btn btn-warning btn-xs">
                                    <i class="fas fa-minus"></i>
                                </button>

                                <!-- <button style="position:relative;top:0px;" (click)=togglelenderinfo() title="View Information" class="btn btn-xs btn-warning bg-gradient-warning">
                                                <span *ngIf="!islenderinfo"><i class="fas fa-plus mr-1"></i></span>
                                                <span *ngIf="islenderinfo"><i class="fas fa-minus mr-1"></i></span>
                                            </button> -->
                                <div class="d-inline-block" id="divTopButtons" *ngIf="divTopButtonsshow ==='true'">
                                    <button id="CustLendInfoID" type="button"
                                        (click)="openLenderInfoModal(LenderInfoandCustomer,'')"
                                        class="btn btn-warning btn-xs ml-1">Customer and Lender Info</button>
                                    <button id="RevCalcID" type="button" (click)="openModal(loanCalculator,'')"
                                        class="btn btn-warning btn-xs ml-1">Loan
                                        Reverse Calculator</button>
                                    <button *ngIf="hideCostButton ==='false'" (click)="ShowCostValue()"
                                        id="HideShowCostID" type="button" class="btn btn-warning btn-xs ml-1">Show
                                        Costs</button>
                                    <button *ngIf="hideCostButton ==='true'" (click)="HideCostValue()" id="HideCostID"
                                        type="button" class="btn btn-warning btn-xs ml-1">Hide Costs</button>
                                </div>
                            </div>
                            <div class="col-auto px-0">
                                <a title="View Deal Recap Report"
                                    class="buttonload btn btn-xs btn-warning bg-gradient-warning"
                                    (click)="DealReport();"><i class="fa fa-report"></i>Deal Recap</a>

                            </div>
                            <div class="col-md-2 col-auto ml-auto">
                                <!-- <a title="Submit to Credit APP" class="btn btn-xs btn-warning bg-gradient-warning "
                            (click)="GetAppWorkSheetDetails()">

                                <i class="fa fa-report"></i>Submit
                            </a> -->

                                <a title="Get UVC" style="display: none;" (click)="GetAppUVCDetails()"
                                    class="btn btn-xs btn-warning bg-gradient-warning ml-2">
                                    Get UVC
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="description Customerlenderinfo" [hidden]="!islenderinfo">
                    <h6 class="font-weight-bold text-white mb-1">Customer and Lender Info</h6>
                    <div id="unitInformation" class="col-12">
                        <div class="row">
                            <div class="table-responsive mb-0">
                                <table class="table table-bordered mb-0">
                                    <tbody>
                                        <tr>
                                            <th>First Name</th>
                                            <td>{{InfoFirstName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Last Name</th>
                                            <td>{{InfoLastName}}</td>
                                        </tr>
                                        <tr>
                                            <th>Phone</th>
                                            <td>{{InfoPhone}}</td>
                                        </tr>
                                        <tr>
                                            <th>Email</th>
                                            <td>{{InfoEmail}}</td>
                                        </tr>
                                        <tr>
                                            <th>Lender</th>
                                            <td>{{LenderNameValue}}</td>
                                        </tr>
                                        <tr>
                                            <th>Given Tier</th>
                                            <td>{{PlanType}}</td>
                                        </tr>
                                        <tr>
                                            <th>LTV</th>
                                            <td>{{OverRideFLTVControl}}</td>
                                        </tr>
                                        <tr>
                                            <th>Interest Rate</th>
                                            <td>{{InterestRateDisplay}}</td>
                                        </tr>

                                        <tr>
                                            <th>Pay Call</th>
                                            <td>{{PayCallDisaply}}</td>
                                        </tr>
                                        <tr>
                                            <th>Trade-In</th>
                                            <td>{{TradeAllowanceControl}}</td>
                                        </tr>
                                        <tr>
                                            <th>Lien</th>
                                            <td>{{LienControl}}</td>
                                        </tr>
                                        <tr>
                                            <th>Down Payment</th>
                                            <td>{{DownPaymentControl}}</td>
                                        </tr>
                                        <tr>
                                            <th>Equity</th>
                                            <td>{{EquityControl}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-1" id="VehiclePaymentInfo">
                <div class="accordion" id="accordionExample">
                    <div class="col-md-12">
                        <div class="card card-raised h-100">
                            <div id="BuildCar" class="card-header card-raised bg-info text-white">
                                <div class="row justify-content-between align-items-center mx-0">
                                    <div class="col col-md-12 px-0">
                                        <div class="row justify-content-start align-items-center mt-0">
                                            <div class="col-auto">
                                                <h5 class="card-title text-white text-uppercase mb-0 d-inline-block">
                                                    Build Car</h5>
                                            </div>
                                            <div class="col-auto">

                                            </div>
                                            <div class="ml-auto col-auto" *ngIf="AithrHubKey ==='1'">
                                                <a href="https://dealer.carfax.ca" target="_blank"
                                                    class="btn bg-gradient-info btn-xs">Verify Carfax</a>
                                                <button id="AithrHubVerifyButtonId"
                                                    (click)="Aithrenquiry(enquiryform,'Enquiry')"
                                                    class="btn bg-gradient-info btn-xs ml-1">AithrVerify</button>
                                                <!-- <button id="AithrHubBuyButtonId"
                                        (click)="Aithrenquiry(Buyform,'Buy')"
                                        class="btn bg-gradient-info btn-xs ml-1">Buy</button> -->
                                                <button class="btn btn-white plusbtn" type="button"
                                                    data-toggle="collapse" data-target="#collapseOne"
                                                    aria-expanded="true" aria-controls="collapseOne">
                                                    <i class="fas fa-chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                        <div class="row justify-content-start align-items-center mt-1">
                                            <div class="col-lg-auto col-md-4 mt-2 mt-md-0 px-2 px-lg-3 pricevalue">
                                                <input type="text" class="form-control" style="height: 30px"
                                                    [(ngModel)]="LenderNameValue" formControlName="LenderNameValue"
                                                    placeholder="plan Type" readonly>
                                            </div>
                                            <div class="col-xl-auto col-lg-auto col-md-6 mt-2 mt-md-0 px-2">
                                                <input type="text" class="form-control" [(ngModel)]="PlanType"
                                                    formControlName="PlanType" style="height: 30px" placeholder="Type"
                                                    readonly>
                                            </div>
                                            <div class="col-xl-auto col-lg-auto col-md-6 px-2 pricevalue">
                                                <div class="row">
                                                    <label class="col-auto col-form-label">Pay Call</label>
                                                    <div class="col-auto col-md">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="PayCallDisaply"
                                                            formControlName="PayCallDisaply" style="height: 30px"
                                                            placeholder="Type" readonly>
                                                    </div>
                                                </div>


                                            </div>
                                            <div class="col-xl-auto col-lg-auto col-md-6 px-2 pricevalue">
                                                <div class="row">
                                                    <label class="col-auto col-form-label">Interest Rate</label>
                                                    <div class="col-auto col-md px-1">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="InterestRateDisplay"
                                                            formControlName="InterestRateDisplay" style="height: 30px"
                                                            placeholder="Type" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-auto col-lg-auto col-md-4 px-2 pricevalue">
                                                <div class="row mx-0">
                                                    <label class="col-auto col-form-label px-2">Allowable
                                                        Finance</label>
                                                    <div class="col-auto col-md px-0" style="max-width:130px;">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="AllowableFinanceDisplay"
                                                            formControlName="AllowableFinanceDisplay"
                                                            style="height: 30px" placeholder="Type" readonly>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-auto col-lg-auto col-md-4 px-2 pricevalue"
                                                *ngIf="showDealerAdnce ==='true'">
                                                <div class="row mx-0">
                                                    <label class="col-auto col-form-label px-2">Dealer Advance</label>
                                                    <div class="col-auto col-md px-0">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="DealerAdvanceDisplay"
                                                            formControlName="DealerAdvanceDisplay" style="height: 30px"
                                                            placeholder="Type" readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-xl-auto col-lg-auto col-md-4 px-2 pricevalue"
                                                *ngIf="showDealerAdnce ==='true'">
                                                <div class="row mx-0">
                                                    <label class="col-auto col-form-label px-2">Advance
                                                        Percentage</label>
                                                    <div class="col-auto col-md px-0">
                                                        <input type="text" class="form-control"
                                                            formControlName="Deductionfee"
                                                            [(ngModel)]="deductionfeeValue" style="height: 30px"
                                                            placeholder="0" readonly>
                                                    </div>
                                                </div>
                                            </div>


                                            <!-- <a title="View Deal Recap Report"
                                    class="buttonload btn btn-sm btn-warning bg-gradient-warning"
							         (click)="DealReport();"><i class="fa fa-report"></i>Deal Recap</a> -->

                                            <button (click)=toggleDisplayDiv() title="View Information"
                                                class="btn btn-sm btn-warning bg-gradient-warning ml-1 plusbtnblock">
                                                <span *ngIf="!isShowDiv"><i class="fas fa-plus"></i></span>
                                                <span *ngIf="isShowDiv"><i class="fas fa-minus"></i></span>
                                            </button>
                                            <!-- <button class="btn bg-warning btn-sm ml-2" title="View Images"
                                    (click)="openImagesModelPopUp(ModelPopUpImages)"  style="line-height:20px;"><i class="fa fa-image"></i>
                                    </button> -->
                                            <div class="description" [hidden]="!isShowDiv">
                                                <div class="row justify-content-between align-items-center">
                                                    <h6 class="font-weight-bold d-inline-block col-auto mb-0">Lender
                                                        Information</h6>
                                                    <a (click)="Pdfview()" class="col-auto" id="viewdatesheet">
                                                        <span id="pdfsheeticon"> <i
                                                                class="fas fa-file-pdf"></i></span>View Ratesheet</a>
                                                </div>
                                                <div id="unitInformation" class="col-12">
                                                    <div class="row">
                                                        <div class="table-responsive mb-0">
                                                            <table class="table table-bordered mb-0">
                                                                <tbody>
                                                                    <tr>
                                                                        <th>Lender Name</th>
                                                                        <td>{{LenderNameValue}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Lienholder Address</th>
                                                                        <td>{{AddressValue}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Email</th>
                                                                        <td>{{EmailValue}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Phone Number</th>
                                                                        <td>{{PhoneNumberValue}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Tier</th>
                                                                        <td>{{PlanType}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>FLTV%</th>
                                                                        <td>{{OverRideFLTVControl}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>BLTV%</th>
                                                                        <td>{{OverRideBLTVControl}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Doc Fee</th>
                                                                        <td>{{DealerAdminFreeControl}}</td>
                                                                    </tr>
                                                                    <!-- <tr>
                                                                <th>Is Doc Fee</th>
                                                                <td>Is Doc Fee</td>
                                                            </tr> -->
                                                                    <tr>
                                                                        <th>Terms</th>
                                                                        <td>{{TermControl}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Lender Fee</th>
                                                                        <td>{{LenderFeeControl}}</td>
                                                                    </tr>

                                                                    <tr>
                                                                        <th>PPSA</th>
                                                                        <td>{{PPSAControl}}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th>Reserves</th>
                                                                        <td>{{ReservesControl}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <div id="collapseOne" class="collapse show" aria-labelledby="BuildCar"
                                data-parent="#accordionExample">
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-md-12 col-lg-6 col-xl-4 mb-3 mb-xl-0">
                                            <div class="card buildcar-title">
                                                <div class="card-header">
                                                    <div class="row justify-content-between align-items-center mx-0">
                                                        <div class="col-auto px-0">
                                                            <h6 class="mb-0"><b>Vehicle Info</b></h6>
                                                        </div>
                                                        <div class="col-auto px-0">
                                                            <button class="btn bg-gradient-info btn-sm"
                                                                title="View Images"
                                                                (click)="openImagesModelPopUp(ModelPopUpImages)"
                                                                style="line-height:18px;"><i class="fa fa-image"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card-body px-3 py-2">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Condition</label>
                                                            <input type="text" formControlName="ConditionControl"
                                                                readonly [(ngModel)]="ConditionControl"
                                                                placeholder="Conditon" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Make</label>
                                                            <input type="text" formControlName="MakeControl" readonly
                                                                [(ngModel)]="MakeControl" placeholder="make"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Stock Number</label>
                                                            <input type="text" formControlName="StockNumberControl"
                                                                readonly [(ngModel)]="StockNumberControl"
                                                                placeholder="stock number" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Model</label>
                                                            <input type="text" formControlName="ModelControl" readonly
                                                                [(ngModel)]="ModelControl" placeholder="model"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-12">
                                                            <label>VIN</label>
                                                            <input type="text" readonly formControlName="VINControl"
                                                                [(ngModel)]="VINControl" placeholder="VIN"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Kms</label>
                                                            <input type="text" formControlName="KmsControl" readonly
                                                                [(ngModel)]="KmsControl" placeholder="Kms"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Year</label>
                                                            <input type="text" formControlName="Trim1Control" readonly
                                                                [(ngModel)]="Trim1Control" placeholder="Year"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Trim</label>
                                                            <input type="text" formControlName="TrimControl" readonly
                                                                [(ngModel)]="TrimControl" placeholder="Trim"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Details</label>
                                                            <input type="text" formControlName="DetailsControl" readonly
                                                                [(ngModel)]="DetailsControl" placeholder="Name"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>UVC</label>
                                                            <input type="text" formControlName="UVCControl" readonly
                                                                [(ngModel)]="UVCControl" placeholder="UVC"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-6 col-xl-4 mb-3 mb-xl-0">
                                            <div class="card buildcar-title">
                                                <h6 class="card-header"><b>Pricing</b></h6>
                                                <div class="card-body px-3 py-2">
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Amount Financed</label>
                                                            <!-- <input type="text" readonly
                                                                formControlName="AmountFinancedControl"
                                                                [style.border-color]="colormessage.length > 1 ? 'red' : ''"
                                                                [(ngModel)]="AmountFinancedControl"
                                                                #AmountFinancedControlValue placeholder="0"
                                                                class="form-control"> -->
                                                                <input type="text" readonly
                                                                formControlName="AmountFinancedControl"
                                                                
                                                                [(ngModel)]="AmountFinancedControl"
                                                                #AmountFinancedControlValue placeholder="0"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>CBB Value</label>
                                                            <!-- <input type="text" formControlName="CBBValueControl"
                                                                (blur)="CallReevaluatefunction('cbb');"
                                                                [style.border-color]="colorCbbmessage.length > 1 ? 'red' : ''"
                                                                [(ngModel)]="CBBValueControl" placeholder="0"
                                                                class="form-control"> -->

                                                                <input type="text" formControlName="CBBValueControl"
                                                                (blur)="CallReevaluatefunction('cbb');"
                                                                
                                                                [(ngModel)]="CBBValueControl" placeholder="0"
                                                                class="form-control">

                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <div class="alert" [class.visible]="isListPrice">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                                <span style="margin-left:5px;">List Price cannot be less
                                                                    than Dealer cost. </span>
                                                            </div>
                                                            <label>List Price</label>
                                                            <!-- <input type="text"
                                                                (change)="ChangeControlColor('listPrice');"
                                                                (blur)="CheckListPrice($event.target.value)"
                                                                formControlName="ListPriceControl"
                                                                [(ngModel)]="ListPriceControl"
                                                                [style.border-color]="colorListPricemessage.length > 1 ? 'red' : ''"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->

                                                                <input type="text"
                                                                (change)="ChangeControlColor('listPrice');"
                                                                (blur)="CheckListPrice($event.target.value)"
                                                                formControlName="ListPriceControl"
                                                                [(ngModel)]="ListPriceControl"
                                                                
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                        <div class="form-group col-md-6"
                                                            *ngIf="hideDealerCost ==='true'">
                                                            <label>Dealer Cost</label>
                                                            <!-- <input type="text" formControlName="DealerCostControl"
                                                                (change)="ChangeControlColor('dealerCost');"
                                                                [(ngModel)]="DealerCostControl"
                                                                [style.border-color]="colorDealerCostmessage.length > 1 ? 'red' : ''"
                                                                (blur)="CallReevaluatefunction('dealerCost');"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" formControlName="DealerCostControl"
                                                                (change)="ChangeControlColor('dealerCost');"
                                                                [(ngModel)]="DealerCostControl"
                                                                
                                                                (blur)="CallReevaluatefunction('dealerCost');"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>

                                                    <div class="form-row" *ngIf="hidedocAdminFee ==='true'">
                                                        <div class="form-group col-md-6">

                                                            <label>Doc/Admin Fee (Front)</label>
                                                            <input type="text" formControlName="DealerAdminFreeControl"
                                                                (blur)="keyupFrontDoc($event.target.value)"
                                                                [(ngModel)]="DealerAdminFreeControl"
                                                                placeholder="Dealer Admin Fee" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>

                                                        </div>
                                                        <div class="form-group col-md-6"
                                                            *ngIf="LenderNameValue ==='Go Plan'">
                                                            <label>License Fee</label>
                                                            <input type="text"
                                                                (blur)="keyupLcineceFee($event.target.value)"
                                                                formControlName="LicenseFee" [(ngModel)]="LicenseFee"
                                                                placeholder="0" class="form-control">
                                                            <span class="mt-1 text-danger d-block"
                                                                *ngIf="LcineceFee ==='true'">License Fee cannot be
                                                                greater than 300.</span>

                                                        </div>
                                                    </div>

                                                    <div class="form-row" *ngIf="hidedocAdminFee ==='false'">

                                                        <div class="form-group col-md-6"
                                                            *ngIf="LenderNameValue ==='Go Plan'">
                                                            <label>License Fee</label>
                                                            <input type="text"
                                                                (blur)="keyupLcineceFee($event.target.value)"
                                                                formControlName="LicenseFee" [(ngModel)]="LicenseFee"
                                                                placeholder="0" class="form-control">
                                                            <span class="mt-1 text-danger d-block"
                                                                *ngIf="LcineceFee ==='true'">License Fee cannot be
                                                                greater than 300.</span>

                                                        </div>

                                                        <div class="form-group col-md-6">

                                                            <label>Doc/Admin Fee (Back)</label>
                                                            <input type="text" formControlName="BackDocFeeControl"
                                                                (blur)="CallReevaluatefunction('')" placeholder="0"
                                                                [(ngModel)]="BackDocFee" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>

                                                        </div>

                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Trade Allowance</label>
                                                            <!-- <input type="text" (blur)="ChangeTrade($event.target.value)"
                                                                formControlName="TradeAllowanceControl"
                                                                (change)="ChangeControlColor('trade');"
                                                                [(ngModel)]="TradeAllowanceControl"
                                                                [style.border-color]="colorTrademessage.length > 1 ? 'red' : ''"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" (blur)="ChangeTrade($event.target.value)"
                                                                formControlName="TradeAllowanceControl"
                                                                (change)="ChangeControlColor('trade');"
                                                                [(ngModel)]="TradeAllowanceControl"
                                                                
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Lien</label>
                                                            <!-- <input type="text" (blur)="ChangeLien($event.target.value)"
                                                                (change)="ChangeControlColor('Lien');"
                                                                [style.border-color]="colorLeinmessage.length > 1 ? 'red' : ''"
                                                                formControlName="LienControl" [(ngModel)]="LienControl"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" (blur)="ChangeLien($event.target.value)"
                                                                (change)="ChangeControlColor('Lien');"
                                                                
                                                                formControlName="LienControl" [(ngModel)]="LienControl"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>ACV</label>
                                                            <!-- <input type="text"
                                                                [style.border-color]="colorACVmessage.length > 1 ? 'red' : ''"
                                                                (change)="ChangeControlColor('acc');"
                                                                (blur)="ChangeAccV($event.target.value)"
                                                                formControlName="ACVControl" [(ngModel)]="ACVControl"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->

                                                                <input type="text"
                                                                
                                                                (change)="ChangeControlColor('acc');"
                                                                (blur)="ChangeAccV($event.target.value)"
                                                                formControlName="ACVControl" [(ngModel)]="ACVControl"
                                                                placeholder="0" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Equity</label>
                                                            <input type="text" formControlName="EquityControl" readonly
                                                                [(ngModel)]="EquityControl" placeholder="0.00"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Down Payment</label>
                                                            <!-- <input type="text" (change)="ChangeControlColor('down');"
                                                                [style.border-color]="colorDownmessage.length > 1 ? 'red' : ''"
                                                                (blur)="ChangeDown($event.target.value)"
                                                                formControlName="DownPaymentControl"
                                                                [(ngModel)]="DownPaymentControl"
                                                                placeholder="Down Payment" class="form-control"
                                                                appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" (change)="ChangeControlColor('down');"
                                                                
                                                                (blur)="ChangeDown($event.target.value)"
                                                                formControlName="DownPaymentControl"
                                                                [(ngModel)]="DownPaymentControl"
                                                                placeholder="Down Payment" class="form-control"
                                                                appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label> Tax - {{RegionControl}}
                                                                ({{TaxRateControl}}%)</label>
                                                            <input type="text" formControlName="TotalTaxControl"
                                                                readonly [(ngModel)]="TotalTaxControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6" style="display: none;">
                                                            <label for="exampleFormControlSelect1">Region</label>
                                                            <input type="text" formControlName="RegionControl" readonly
                                                                [(ngModel)]="RegionControl" placeholder="Regions"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6" style="display: none;">
                                                            <label>Tax Rate</label>
                                                            <input type="text" formControlName="TaxRateControl" readonly
                                                                [(ngModel)]="TaxRateControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 col-lg-6 col-xl-4 mb-3 mb-xl-0">
                                            <div class="card buildcar-title">
                                                <h6 class="card-header"><b>Financing Details</b></h6>
                                                <div class="card-body px-3 py-2">
                                                    <div class="form-row">

                                                        <div class="form-group col-md-6" style="position: relative;">
                                                            <div class="alert" [class.visible]="isVisible">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                                <span style="margin-left:5px;">Monthly fee increased.
                                                                </span>
                                                            </div>
                                                            <label>Monthly</label>
                                                            <div class="input-group">
                                                                <input type="text" formControlName="MonthlyControl"
                                                                    readonly [(ngModel)]="MonthlyControl"
                                                                    class="form-control" placeholder="Monthly">
                                                                <div class="input-group-append">
                                                                    <button class="btn btn-sm btn-info" type="button"
                                                                        (click)="openRollPaymentModel(ModelRollPayment)">
                                                                        Adjust
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Interest Rate</label>
                                                            <!-- <input type="text" formControlName="interestRateControl"
                                                                (change)="ChangeControlColor('InterestRate');"
                                                                [style.border-color]="colorInterestRatemessage.length > 1 ? 'red' : ''"
                                                                (blur)="CallReevaluatefunction('InterestRate')"
                                                                [(ngModel)]="interestRateControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective> -->

                                                                <input type="text" formControlName="interestRateControl"
                                                                (change)="ChangeControlColor('InterestRate');"
                                                               
                                                                (blur)="CallReevaluatefunction('InterestRate')"
                                                                [(ngModel)]="interestRateControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Semi-Monthly</label>
                                                            <input type="text" formControlName="SemiMonthlyControl"
                                                                readonly [(ngModel)]="SemiMonthlyControl"
                                                                placeholder="0" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Weekly</label>
                                                            <input type="text" formControlName="WeeklyControl" readonly
                                                                [(ngModel)]="WeeklyControl" placeholder="0"
                                                                class="form-control">
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Bi-Weekly</label>
                                                            <input type="text" formControlName="BiWeeklyControl"
                                                                readonly [(ngModel)]="BiWeeklyControl" placeholder="0"
                                                                class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Reserves</label>
                                                            <!-- <input type="text" formControlName="ReservesControl"
                                                                (change)="ChangeControlColor('Reserves');"
                                                                [style.border-color]="colorReservesmessage.length > 1 ? 'red' : ''"
                                                                (blur)="CallReevaluatefunction('Reserves')"
                                                                [(ngModel)]="ReservesControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" formControlName="ReservesControl"
                                                                (change)="ChangeControlColor('Reserves');"
                                                               
                                                                (blur)="CallReevaluatefunction('Reserves')"
                                                                [(ngModel)]="ReservesControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">

                                                            <div class="alert" [class.visible]="isLoanToValueVisible">
                                                                <i class="fas fa-exclamation-circle"></i>
                                                                <span style="margin-left:5px;">Amount Financed increased
                                                                    from Total Loan . </span>
                                                            </div>

                                                            <label>Total Loan to Value</label>
                                                            <input type="text" formControlName="TotalLoantoValueControl"
                                                                readonly [(ngModel)]="TotalLoantoValueControl"
                                                                placeholder="0" class="form-control">
                                                        </div>
                                                        <div class="form-group col-md-6"
                                                            *ngIf="hideGrossProfit ==='true'">
                                                            <!-- <label>Gross Profit</label>
                                                 <input type="text" formControlName="GrossProfitControl" readonly
                                                [(ngModel)]="GrossProfitControl"  placeholder="0" class="form-control">  -->
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>Term</label>
                                                            <div class="input-group">
                                                                <!-- <input type="text" class="form-control"
                                                                    [style.border-color]="colorTermmessage.length > 1 ? 'red' : ''"
                                                                    (change)="ChangeControlColor('Term');"
                                                                    (blur)="CallReevaluatefunction('Term')"
                                                                    [(ngModel)]="TermControl" #TermControlValue
                                                                    formControlName="TermControl" placeholder="60"> -->
                                                                    <input type="text" class="form-control"
                                                                    
                                                                    (change)="ChangeControlColor('Term');"
                                                                    (blur)="CallReevaluatefunction('Term')"
                                                                    [(ngModel)]="TermControl" #TermControlValue
                                                                    formControlName="TermControl" placeholder="60">
                                                                <div class="input-group-append">
                                                                    <span class="btn btn-sm btn-info"
                                                                        (click)="IncreaseTerms(TermControlValue.value)">
                                                                        <i class="fa fa-plus" aria-hidden="true"
                                                                            style="vertical-align:baseline;font-size:10px;"></i>
                                                                    </span>
                                                                    <span class="btn btn-sm btn-info ml-1"
                                                                        (click)="DecreaseTerms(TermControlValue.value)">
                                                                        <i class="fa fa-minus" aria-hidden="true"
                                                                            style="vertical-align:baseline;font-size:10px;"></i>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>Lender Fee</label>
                                                            <!-- <input type="text" formControlName="LenderFeeControl"
                                                                (change)="ChangeControlColor('LenderFee');"
                                                                [style.border-color]="colorLenderFeemessage.length > 1 ? 'red' : ''"
                                                                (blur)="CallReevaluatefunction('LenderFee')"
                                                                [(ngModel)]="LenderFeeControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective> -->
                                                                <input type="text" formControlName="LenderFeeControl"
                                                                (change)="ChangeControlColor('LenderFee');"
                                                               
                                                                (blur)="CallReevaluatefunction('LenderFee')"
                                                                [(ngModel)]="LenderFeeControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                    <div class="form-row">
                                                        <div class="form-group col-md-6">
                                                            <label>GPS Fee</label>
                                                            <input type="text" formControlName="GPSFeeControlValue"
                                                                [(ngModel)]="GPSFeeControlValue" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                        <div class="form-group col-md-6">
                                                            <label>PPSA</label>
                                                            <!-- <input type="text" (change)="ChangeControlColor('ppsa');"
                                                                formControlName="PPSAControl"
                                                                [style.border-color]="colorppsamessage.length > 1 ? 'red' : ''"
                                                                (blur)="CallReevaluatefunction('ppsa')"
                                                                [(ngModel)]="PPSAControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective> -->

                                                                <input type="text" (change)="ChangeControlColor('ppsa');"
                                                                formControlName="PPSAControl"
                                                                
                                                                (blur)="CallReevaluatefunction('ppsa')"
                                                                [(ngModel)]="PPSAControl" placeholder="0"
                                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="row justify-content-between align-items-center mx-0">
                                    <div class="form-group col-auto mb-1 mb-lg-0 px-0">
                                        <div class="col-12 px-0">
                                            <button *ngIf="buttonloader"
                                                class="buttonload btn btn-sm btn-secondary bg-gradient-info">
                                                <i class="fa fa-spinner fa-spin"></i>Loading</button>
                                            <button type="button" *ngIf="!buttonloader" (click)="MaximizeDeal()"
                                                class="btn btn-sm bg-gradient-info">Maximize {{LenderPayCall}}
                                                Payment</button>

                                            <button *ngIf="buttonloaderevaluate"
                                                class="buttonload btn btn-sm btn-secondary bg-gradient-info">
                                                <i class="fa fa-spinner fa-spin"></i>Loading</button>
                                            <button type="button" *ngIf="!buttonloaderevaluate"
                                                style="margin-left: 14px;" (click)="ReEvaluate()"
                                                class="btn btn-sm bg-gradient-info">Re-Evaluate</button>
                                            &nbsp;&nbsp;
                                            <button *ngIf="buttonReset"
                                                class="buttonload btn btn-sm btn-secondary bg-gradient-danger">
                                                <i class="fa fa-spinner fa-spin"></i>Loading</button>
                                            <button *ngIf="!buttonReset" type="button"
                                                class="btn btn-sm bg-gradient-danger"
                                                (click)="ResetDeals()">Reset</button>

                                        </div>
                                    </div>

                                    <div class="col-auto">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="form-group col-auto mb-0">
                                                <div class="row justify-content-between align-items-center"
                                                    *ngIf="hideGrossProfit ==='true'">
                                                    <label class="col-form-label mb-0">Front End Gross Total</label>
                                                    <div class="col-auto">
                                                        <input type="text" formControlName="FrontEndGrossTotalAmount"
                                                            [(ngModel)]="FrontEndGrossTotalAmount" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-auto mb-0">
                                                <div class="row justify-content-between align-items-center"
                                                    *ngIf="hideGrossProfit ==='true'">
                                                    <label class="col-form-label mb-0">Back End Gross Total</label>
                                                    <div class="col-auto">
                                                        <input type="text" formControlName="BackEndGrossTotal"
                                                            [(ngModel)]="BackEndGrossTotal" class="form-control">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group col-auto mb-0">
                                                <div class="row justify-content-between align-items-center"
                                                    style="display: none;">
                                                    <label class="col-form-label mb-0">Other Gross Total:</label>
                                                    <div class="col-auto">
                                                        <input type="text" formControlName="OtherGrossTotal"
                                                            [(ngModel)]="OtherGrossTotal" class="form-control">
                                                    </div>
                                                </div>

                                                <div class="row justify-content-between align-items-center"
                                                    *ngIf="hideGrossProfit ==='true'">
                                                    <label class="col-form-label mb-0">Total Gross Profit</label>
                                                    <div class="col-auto">
                                                        <input type="text" formControlName="GrossProfitControl" readonly
                                                            [(ngModel)]="GrossProfitControl" placeholder="0"
                                                            class="form-control">
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group col-lg-3 col-md-3 col-sm-12"
                                        *ngIf="SelectedGroupName === 'Steele Auto Group'">
                                        <label for="exampleFormControlSelect1">Rule Set</label>

                                        <select class="form-control" name="rule set" formControlName="RuleSetControl"
                                            (change)="GetRuleSetValues($event.target.value)"
                                            [(ngModel)]="RuleSetControl">
                                            <option value="0" selected>Select Rule Set</option>
                                            <option *ngFor="let customRuleLists of customRuleList"
                                                [value]="customRuleLists.id">{{customRuleLists.description}}</option>
                                        </select>


                                    </div>
                                    <div class="form-group col-lg-2 col-md-3 col-sm-12"
                                        *ngIf="SelectedGroupName === 'Steele Auto Group'">
                                        <label>Upper Limit</label>
                                        <input type="text" formControlName="UpperLimitControl"
                                            [(ngModel)]="UpperLimitControl" class="form-control">
                                    </div>
                                    <div class="form-group col-lg-2 col-md-3 col-sm-12"
                                        *ngIf="SelectedGroupName === 'Steele Auto Group'">
                                        <label>Lower Limit</label>
                                        <input type="text" formControlName="LowerLimitControl"
                                            [(ngModel)]="LowerLimitControl" class="form-control">
                                    </div>
                                    <div class="form-group col-auto mb-1 mb-lg-0 px-0">
                                        <div class="col-12 px-0">
                                            <!-- <button *ngIf="buttonReset" class="buttonload btn btn-sm btn-secondary bg-gradient-danger">
                                            <i class="fa fa-spinner fa-spin"></i>Loading</button>
                                        <button *ngIf="!buttonReset" type="button" class="btn btn-sm bg-gradient-danger" (click)="ResetDeals()">Reset</button> -->

                                            <a title="Submit to Credit APP"
                                                class="btn btn-xs btn-warning bg-gradient-warning "
                                                (click)="GetAppWorkSheetDetails()">

                                                <i class="fa fa-report"></i>Submit
                                            </a>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="inputradius" class="row mt-3">
                <div class="accordion col-lg-6" id="frontend">
                    <div class="card">
                        <div class="card-header card-raised bg-info text-white" style="padding: 0.45rem 0.7rem;">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between align-items-center mx-0">
                                        <h6 class="card-title text-white text-uppercase mb-1 d-inline-block">Front End
                                        </h6>
                                        <div class="col-12 col-sm col-md px-1">
                                            <div class="row justify-content-end align-items-center">
                                                <!-- <div class="form-group col-auto mb-0">
                                                <label class="mb-0">Gross Total:</label>
                                                <span class="ml-1">{{FrontEndGrossTotal}}</span>
                                            </div> -->
                                                <div class="col-auto px-2">
                                                    <button class="btn btn-white plusbtn" type="button"
                                                        data-toggle="collapse" data-target="#collapsetwo"
                                                        aria-expanded="true" aria-controls="collapsetwo">
                                                        <i class="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 totalAccessories">
                                    <div class="row justify-content-between align-items-start">
                                        <span class="col col-md-auto pt-1">Remaining FLTV</span>
                                        <div class="form-group col-md-12 col-lg-auto mb-2 mb-md-0 smallinput">
                                            <div style="display: none;"
                                                class="row justify-content-between align-items-center">
                                                <label class="col-auto px-1 pt-1">Doc/Admin Fee</label>
                                                <div class="col-auto px-1" style="max-width:75px;">
                                                    <input type="text" formControlName="DealerAdminFreeControl"
                                                        (blur)="keyupFrontDoc($event.target.value)"
                                                        [(ngModel)]="DealerAdminFreeControl" readonly
                                                        placeholder="Dealer Admin Fee" class="form-control"
                                                        appTwoDigitDecimaNumberDirective>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 col-lg-auto mb-2 mb-md-0 smallinput">
                                            <div class="row justify-content-between align-items-center">
                                                <label class="col-auto px-1 pt-1">FLTV</label>
                                                <div class="col-auto px-1" style="max-width:75px;">
                                                    <input type="text" formControlName="OverRideFLTVControl" readonly
                                                        [(ngModel)]="OverRideFLTVControl" placeholder=""
                                                        class="form-control" appTwoDigitDecimaNumberDirective>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="alert" [class.visible]="isRemainingVisible" style="top:100%;">
                                            <i class="fas fa-exclamation-circle"></i>
                                            <span style="margin-left:5px;">you are exceeding remaining front loan value
                                                - which is not allowed, please reduce total accessories + document fee .
                                            </span>
                                        </div>
                                        <span
                                            class="col col-md-auto">{{RemainingFrontLoanValue}}/{{RemainingFrontLoanValues}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapsetwo" class="collapse show" aria-labelledby="frontend" data-parent="#frontend">
                            <div class="card-body px-3 py-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-row mt-1 mt-lg-0">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>TOTAL Accessories</label>
                                                <input type="text" formControlName="TOTALAccessoriesControl" readonly
                                                    [(ngModel)]="TOTALAccessoriesControl"
                                                    placeholder="Total Accessories Cost" class="form-control"
                                                    appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="TOTALCosthide ==='true'">
                                                <label>TOTAL Cost</label>
                                                <input type="text" formControlName="TOTALCostControl" readonly
                                                    [(ngModel)]="TOTALCostControl" placeholder="0" class="form-control"
                                                    appTwoDigitDecimaNumberDirective>
                                            </div>

                                        </div>
                                        <hr />
                                        <div class="form-row">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>Remote Start</label>
                                                <input type="text" formControlName="Accessories1Control"
                                                    (blur)="keyupAccessories($event.target.value)"
                                                    [(ngModel)]="Accessories1Control" placeholder="0"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="Cost1Hide ==='true'">
                                                <label>Cost</label>
                                                <input formControlName="Cost1Control" [(ngModel)]="Cost1Control"
                                                    (blur)="keyupCost($event.target.value)" placeholder="0" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>Pro Pack</label>
                                                <input type="text" (blur)="keyupAccessories2($event.target.value)"
                                                    formControlName="Accessories2Control"
                                                    [(ngModel)]="Accessories2Control" placeholder="0"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="Cost2Hide ==='true'">
                                                <label>Cost</label>
                                                <input formControlName="Cost2Control"
                                                    (blur)="keyupCost2($event.target.value)" [(ngModel)]="Cost2Control"
                                                    placeholder="0" type="text" class="form-control"
                                                    appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>Accessories (Other)</label>
                                                <input type="text" (blur)="keyupAccessories3($event.target.value)"
                                                    formControlName="Accessories3Control"
                                                    [(ngModel)]="Accessories3Control" placeholder="0"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="Cost3Hide ==='true'">
                                                <label>Cost</label>
                                                <input type="text" formControlName="Cost3Control"
                                                    (blur)="keyupCost3($event.target.value)" [(ngModel)]="Cost3Control"
                                                    placeholder="0" class="form-control"
                                                    appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion col-lg-6" id="backend">
                    <div class="card mb-0">
                        <div class="card-header card-raised bg-gradient-danger text-white"
                            style="padding: 0.45rem 0.7rem;">
                            <div class="row">
                                <div class="col-12">
                                    <div class="row justify-content-between align-items-center mx-0">
                                        <h6 class="card-title text-white text-uppercase mb-1 d-inline-block">Back End
                                        </h6>
                                        <div class="col-12 col-sm col-md px-1">
                                            <div class="row justify-content-end align-items-center">
                                                <!-- <div class="form-group col-auto mb-0">
                                                <label class="mb-0">Gross Total:</label>
                                                <span class="ml-1">{{BackendGrossTotal}}</span>
                                            </div> -->
                                                <div class="col-auto px-2">
                                                    <button class="btn btn-white plusbtn" type="button"
                                                        data-toggle="collapse" data-target="#collapsethird"
                                                        aria-expanded="true" aria-controls="collapsethird">
                                                        <i class="fas fa-chevron-down"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <h6 class="card-title text-white text-uppercase mb-1">Back End</h6> -->
                                </div>
                                <div class="col-12 remainingBackEnd">
                                    <div class="row justify-content-between align-items-start">
                                        <span class="col col-md-auto pt-1">Remaining BLTV</span>
                                        <div class="form-group col-md-12 col-lg-auto mb-2 mb-md-0 smallinput">
                                            <div style="display: none;"
                                                class="row justify-content-between align-items-center">
                                                <label class="col-auto px-1 pt-1">Doc/Admin Fee</label>
                                                <div class="col-auto px-1" style="max-width:75px;">
                                                    <input type="text" readonly formControlName="BackDocFeeControl"
                                                        (blur)="CallReevaluatefunction('')" placeholder="0"
                                                        [(ngModel)]="BackDocFee" class="form-control"
                                                        appTwoDigitDecimaNumberDirective>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group col-md-12 col-lg-auto mb-2 mb-md-0 smallinput">
                                            <div class="row justify-content-between align-items-center">
                                                <label class="col-auto px-1 pt-1">BLTV</label>
                                                <div class="col-auto px-1" style="max-width:75px;">
                                                    <input type="text" formControlName="OverRideBLTVControl" readonly
                                                        placeholder="0" [(ngModel)]="OverRideBLTVControl"
                                                        class="form-control" appTwoDigitDecimaNumberDirective>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="alert" [class.visible]="isRemainingBackEndVisible">
                                            <i class="fas fa-exclamation-circle"></i>
                                            <span style="margin-left:5px;">Cannot Exceed Total Back End Value </span>
                                        </div>
                                        <span
                                            class="col col-md-auto">{{RemainingBackLoanValue}}/{{RemainingBackLoanValues}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="collapsethird" class="collapse show" aria-labelledby="backend" data-parent="#backend">
                            <div class="card-body px-3 py-2">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="form-row mt-1 mt-lg-0">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>TOTAL AddOns</label>
                                                <input type="number" formControlName="TOTALAddOnsControls" readonly
                                                    [(ngModel)]="TOTALAddOnsControls" placeholder="0" placeholder="0"
                                                    class="form-control">
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="BackendCostHide ==='true'">
                                                <label>TOTAL Cost</label>
                                                <input type="number" formControlName="TOTALBackEndCostControls" readonly
                                                    [(ngModel)]="TOTALBackEndCostControls" placeholder="0"
                                                    placeholder="" class="form-control">
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="form-row" id="exteneded-service">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <div class="row justify-content-start">
                                                    <label class="col-auto">Extended Service Contract</label>
                                                    <div class="addone-light col-auto mr-2 mb-1 px-0"
                                                    *ngIf="GlobalWarrantyClientsValue ==='true'">
                                                        <!-- <button title="Product List" (click)="toggleExServiceContract()" class="btn btn-xs bg-success mr-1"><i class="fas fa-lightbulb"></i></button> -->

                                                        <button title="Product List"
                                                            (click)="OrdersProductListButton(ModelPopUpProductList)"
                                                            class="btn btn-xs bg-success mr-1"><i
                                                                class="fas fa-lightbulb"></i></button>

                                                        <button title="No Product" style="display: none;"
                                                        
                                                            class="btn btn-xs btn-warning mr-1"><i
                                                                class="fas fa-lightbulb"></i></button>

                                                                <a #ConfirmBox style="display: none;" [routerLink]="" 
                                                                (click)="OrdersButton(ModelPopUpOrders)"><i
                                                                    class="fa fa-upload" triggers="mouseenter:mouseleave" 
                                                                    popoverTitle="Delete Users"
                                                                    placement="top" ngbPopover="Order"></i></a>

                                                        <button title="Orders" (click)="checkValidation(ModelPopUpOrders)"
                                                            id="hideButton" type="button"
                                                            class="btn btn-warning btn-xs ml-1">
                                                            <i class="fas fa-shopping-cart"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                                <input formControlName="ExtendendServiceContractControls" type="text"
                                                    (blur)="keyupExtendendService($event.target.value)"
                                                    [(ngModel)]="ExtendendServiceContractControls" placeholder="0"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>

                                            <div class="exteneded-service-value " [hidden]="!isExServiceContractInfo">
                                                <h6 class="font-weight-bold text-white mb-1">Products Information</h6>
                                                <div id="unitInformation" class="col-12">
                                                    <div class="row">
                                                        <div class="table-responsive mb-0">
                                                            <table class="table table-bordered mb-0">
                                                                <thead>
                                                                    <tr>
                                                                        <!-- <th></th> -->
                                                                        <th>Product</th>
                                                                        <th>Key</th>
                                                                        <th>Price</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr *ngFor="let productListdata of productList">
                                                                        <!-- <input type="checkbox" value="{{productListdata.productKey}}" 
                                                                        [checked]="productListdata.isSelected"
                                                                            (change)="isAllSelected($event,productListdata)" /> -->
                                                                        <td>{{productListdata.productName}}</td>
                                                                        <th>{{productListdata.productKey}}</th>
                                                                        <td>{{productListdata.price | currency}}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>



                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="ExtendendCostHide ==='true'">
                                                <label>Cost</label>
                                                <input formControlName="ExtendendCostControls"
                                                    (blur)="keyupExtendendCost($event.target.value)"
                                                    [(ngModel)]="ExtendendCostControls" placeholder="0" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>Other Insurances (GAP, A/H, Life/Dis) </label>
                                                <input formControlName="GapControls"
                                                    (blur)="keyupGapValue($event.target.value)"
                                                    [(ngModel)]="GapControls" placeholder="0" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="GapCostHide ==='true'">
                                                <label>Cost</label>
                                                <input formControlName="GapCostControls"
                                                    (blur)="keyupGapCost($event.target.value)"
                                                    [(ngModel)]="GapCostControls" placeholder="0" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                        <div class="form-row">
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>Other Insurances (GAP, A/H, Life/Dis) </label>
                                                <input type="text" formControlName="JobLossControls"
                                                    (blur)="keyupJobValue($event.target.value)"
                                                    [(ngModel)]="JobLossControls" placeholder="Job Loss"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                            <div class="form-group col-md-12 col-lg-6 col-xl-6 mb-1"
                                                *ngIf="JobLossHide ==='true'">
                                                <label>Cost</label>
                                                <input formControlName="JobLossCostControls"
                                                    (blur)="keyupJobCost($event.target.value)"
                                                    [(ngModel)]="JobLossCostControls" placeholder="0" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- product name and price start -->
                    <div id="addproductInfo" class="card"  *ngIf="AddProductDisplay ==='true'">
                        <div class="card-body">
                            <div class="table-responsive mb-0">
                                <table class="table table-borderless mb-0">
                                    <tbody>
                                        <tr class="form-row mx-0" *ngFor="let products of addedproductlists ;let i = index;">
                                            <td class="product-name form-group col-md-12 col-lg-6 col-xl-6 mb-1">
                                                <label>{{products.productName}}</label>
                                                <input type="text"  [(ngModel)]="products.soldPrice"
                                                id="productPrice_{{i}}" 
                                                (blur)="KeyUpProductCost($event.target.value,i,products)"
                                                 class="form-control" 
                                               />
                                               <span class="text-danger w-100" style="display: none;" id="SpanPrice_{{i}}">Price must be greater than or equal to Cost.</span>
                                            </td>
                                            <td id="rowPrice_{{i}}" class="product-name form-group col-md-12 col-lg-6 col-xl-6 mb-1" 
                                             >
                                                <label>&nbsp; Cost</label>
                                                <input  type="text"  [(ngModel)]="products.price"
                                                (blur)="KeyUpProductPrice($event.target.value,i,products)"
                                                 id="productCost_{{i}}" 
                                                 
                                                 class="form-control"  
                                                value="{{products.price}}"/>
                                                <span class="text-danger w-100" style="display: none;" id="Spancost_{{i}}">Cost must be less than or equal to Price.</span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>



                                <!-- <div class="row mb-2">
                                    <div class="for-group col-xl-8 col-lg-8 col-12">
                                        <label>Product Name</label>
                                        <input type="text" class="form-control" readonly
                                         value="{{products.productName}}"/>
                                    </div>
                                    <div class="for-group col-xl-4 col-lg-4 col-12">
                                        <label>Product Price</label>
                                        <input type="text" class="form-control" readonly 
                                        value="{{products.price | currency}}"/>
                                    </div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <!-- product name and price end -->
                </div>
            </div>
        </div>
        <ng-template #ModelPopUpProductList let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="OrderListLabel" style="color: white;">Products Information</h5>
                <button type="button" class="close" style="color: white;" (click)="CloseAddmodelProducts();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div id="product-info" class="modal-body">
                <section class="generate-report">
                    <div class="table-responsive">
                        <table class="table table-bordered mb-0">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Product</th>
                                    <th>Key</th>
                                    <th>Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let productListdata of productList">
                                    <td class="text-center"><input type="checkbox" value="{{productListdata.productKey}}"
                                         [checked]="productListdata.isSelected"
                                        (change)="isAllSelected($event,productListdata)" /></td>
                                    <td>{{productListdata.productName}}</td>
                                    <td>{{productListdata.productKey}}</td>
                                    <td>{{productListdata.price | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
            <div class="modal-footer">
                <button class="btn btn-warning btn-sm rounded-pill" (click)="AddProducts()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Add Products
                </button>
                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodelProducts()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>
                
            </div>
        </ng-template>
        <ng-template #ModelRollPayment let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Roll Payment</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="form-group col-md-12 mb-3">
                            <label>Current Payment</label>
                            <input formControlName="CurrentPayment" [(ngModel)]="CurrentPayment" type="text"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>Desired Payment</label>
                            <div class="input-group">
                                <input formControlName="DesiredPayment" [(ngModel)]="DesiredPayment" type="text"
                                    class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info" (click)="AdjustDesiredPayment()">
                                        Adjust </button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>Total Adjustments</label>
                            <input formControlName="TotalAdjustments" [(ngModel)]="TotalAdjustments" type="text"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>Adjust to List Price</label>
                            <div class="input-group">
                                <input formControlName="AdjustmentstolistPrice" [(ngModel)]="AdjustmentstolistPrice"
                                    type="text" class="form-control" (blur)="OnBlurListPrice();">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info" (click)="AdjustListPriceButton()">
                                        {{AdjustmentstolistPriceButton}} </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-3">
                            <label>Adjust to Accessories</label>
                            <div class="input-group">
                                <input formControlName="AdjustmentsAccessories" [(ngModel)]="AdjustmentsAccessories"
                                    (blur)="OnBlurAccessories();" type="text" class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info"
                                        (click)="AdjustAccessoriesButton()"> {{AdjustmentsAccessoriesButton}} </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-3">
                            <label>Adjust to Extended Warranty</label>
                            <div class="input-group">
                                <input formControlName="AdjustmentstoExtendedWarranty"
                                    [(ngModel)]="AdjustmentstoExtendedWarranty" (blur)="OnBlurExtendedWarranty();"
                                    type="text" class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info" (click)="AdjustExtendedButton()">
                                        {{AdjustmentstoExtendedWarrantyButton}} </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-3">
                            <label>Other Insurances (GAP, A/H, Life/Dis)</label>
                            <div class="input-group">
                                <input formControlName="AdjustmentsGap" [(ngModel)]="AdjustmentsGap" type="text"
                                    (blur)="OnBlurGap();" class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info" (click)="AdjustGapButton()">
                                        {{AdjustmentsGapButton}} </button>
                                </div>
                            </div>
                        </div>

                        <div class="form-group col-md-12 mb-3">
                            <label>Other Insurances (GAP, A/H, Life/Dis)</label>
                            <div class="input-group">
                                <input formControlName="AdjustmentsJobLoss" [(ngModel)]="AdjustmentsJobLoss" type="text"
                                    (blur)="OnBlurJobLoss();" class="form-control">
                                <div class="input-group-append">
                                    <button type="button" class="btn btn-sm btn-info" (click)="AdjustJobButton()">
                                        {{AdjustmentsJobButton}} </button>
                                </div>
                            </div>
                        </div>



                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm text-white rounded-pill"
                    (click)="AdjustRollPayment()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Adjust
                </button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

        <button #adjustpaymentbuttonToBeClicked style="display:none;"
            (click)="openModal(ModelAdjustPayment,'')"></button>
        <ng-template #ModelAdjustPayment let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Adjustments Made</h5>
                <button type="button" class="close" style="color: white;" #closebutton
                    (click)="CloseMainAdjustMentmodel()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col p-1">

                    <div class="row">
                        <div class="col-lg-6">
                            <div class="card smallfontsize">
                                <div class="card-header p-3 py-2">
                                    <div class="row mx-0">
                                        <small class="mb-0">Original Values</small>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Monthly Payment</label>
                                            <input formControlName="AdjustmentsMonthlyPayment"
                                                [(ngModel)]="AdjustmentsMonthlyPayment" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>List Price</label>
                                            <input formControlName="AdjustmentsListPrice"
                                                [(ngModel)]="AdjustmentsListPrice" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Gross Profit</label>
                                            <input formControlName="AdjustmentsGrossProfit"
                                                [(ngModel)]="AdjustmentsGrossProfit" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Total Taxes</label>
                                            <input formControlName="AdjustmentsTotalTaxes"
                                                [(ngModel)]="AdjustmentsTotalTaxes" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>

                                        <div class="form-group col-lg-12 mb-2" style="display: none;">
                                            <label>Remaining FLTV</label>
                                            <input formControlName="AdjustmentsRemainingFLTV"
                                                [(ngModel)]="AdjustmentsRemainingFLTV" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2" style="display: none;">
                                            <label>Remaining BLTV</label>
                                            <input formControlName="AdjustmentsRemainingBLTV"
                                                [(ngModel)]="AdjustmentsRemainingBLTV" type="text" class="form-control"
                                                appTwoDigitDecimaNumberDirective>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Front Documentation Fee</label>
                                            <input formControlName="AdjustmentFrontDocumentationFee"
                                                [(ngModel)]="AdjustmentFrontDocumentationFee" type="text"
                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="card smallfontsize">
                                <div class="card-header p-3 py-2">
                                    <div class="row mx-0">
                                        <small class="mb-0">New Maximized Values</small>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Monthly Payment</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewMonthlyPayment"
                                                    [style.border-color]="colorAdjustMonthly.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewMonthlyPayment" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>List Price</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewListPrice"
                                                    [style.border-color]="colorAdjustListPrice.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewListPrice" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Gross Profit</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewGrossProfit"
                                                    [style.border-color]="colorAdjustGrossProfit.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewGrossProfit" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Total Taxes</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewTotalTaxes"
                                                    [style.border-color]="colorAdjustTotalTaxes.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewTotalTaxes" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2" style="display: none;">
                                            <label>Remaining FLTV</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewRemainingFLTV"
                                                    [style.border-color]="colorAdjustFLTV.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewRemainingFLTV" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2" style="display: none;">
                                            <label>Remaining BLTV</label>
                                            <div class="input-group">
                                                <input formControlName="AdjustmentsNewRemainingBLTV"
                                                    [style.border-color]="colorAdjustBLTV.length > 1 ? '#29aae3' : 'green'"
                                                    [(ngModel)]="AdjustmentsNewRemainingBLTV" type="text"
                                                    class="form-control" appTwoDigitDecimaNumberDirective>

                                            </div>
                                        </div>
                                        <div class="form-group col-lg-12 mb-2">
                                            <label>Front Documentation Fee</label>
                                            <input formControlName="AdjustmentNewFrontDocumentationFee"
                                                [style.border-color]="colorAdjustFrontFee.length > 1 ? '#29aae3' : 'green'"
                                                [(ngModel)]="AdjustmentNewFrontDocumentationFee" type="text"
                                                class="form-control" appTwoDigitDecimaNumberDirective>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </section>


            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-danger btn-sm text-white rounded-pill"
                    (click)="CloseMainAdjustMentmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Decline
                </button>

                <button class="btn btn-success btn-sm text-white rounded-pill" (click)="CloseAdjustMentmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Accept
                </button>


            </div>
        </ng-template>


        <ng-template #enquiryform>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Request/Verify More Information
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="span12 pagination-centered">
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" (click)="VerifyVehicle($event, 'availability')"
                                        value="availability">
                                    <span>Verify Availability</span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" (click)="VerifyVehicle($event, 'kilometers')"
                                        value="kilometers">
                                    <span>Verify Kilometers</span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" (click)="VerifyVehicle($event, 'safety')" value="safety">
                                    <span>Verify Safety</span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" (click)="VerifyVehicle($event, 'photos')" value="photos">
                                    <span> Photos</span>
                                </label>
                            </div>
                            <div class="checkbox">
                                <label>
                                    <input type="checkbox" (click)="VerifyVehicle($event, 'purchase')" value="purchase">
                                    <span> Ready to Purchase</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <!-- <button type="button" class="btn btn-success btn-sm text-white" title="Send Request"
                (click)="PostVerifyvehicleRequest()">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Send Request
            </button> -->

                <button *ngIf="buttonloaderSendEmail"
                    class="buttonload btn btn-sm btn-secondary bg-gradient-info rounded-pill">
                    <i class="fa fa-spinner fa-spin"></i>Email Sending</button>
                <button type="button" *ngIf="!buttonloaderSendEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info rounded-pill">Send
                    Request</button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #Buyform>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">BUY</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <p>Please Confirm your purchase of the following <br> <b> {{Trim1Control}} {{MakeControl}}
                                {{ModelControl}} </b></p>
                        <p>A 15 % deposit is required to proceed</p>
                    </div>
                </section>


            </div>
            <div class="modal-footer">

                <button *ngIf="buttonloaderBuyEmail"
                    class="buttonload btn btn-sm btn-secondary bg-gradient-info rounded-pill">
                    <i class="fa fa-spinner fa-spin"></i> Email Sending</button>
                <button type="button" *ngIf="!buttonloaderBuyEmail" style="margin-left: 14px;"
                    (click)="PostVerifyvehicleRequest()" class="btn btn-sm bg-gradient-info rounded-pill">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>Yes</button>



                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>


        <ng-template #loanCalculator>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Auto Loan Reverse Calculator
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col">
                    <div class="row">
                        <div class="form-group col-md-12 mb-3">
                            <label>Total Loan amount</label>
                            <input formControlName="CalTotalLoanamount" readonly [(ngModel)]="CalTotalLoanamount"
                                type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>
                                Desired Monthly Payment</label>
                            <input formControlName="CalDesiredMonthlyPayment" [(ngModel)]="CalDesiredMonthlyPayment"
                                type="text" class="form-control">
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>Interest Rate
                            </label>
                            <input formControlName="CalInterestRate" [(ngModel)]="CalInterestRate" type="text"
                                class="form-control">
                        </div>
                        <div class="form-group col-md-12 mb-3">
                            <label>
                                Loan Term(months)

                            </label>
                            <input formControlName="CalLoanTerm" [(ngModel)]="CalLoanTerm" type="text"
                                class="form-control">
                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm text-white rounded-pill" title="Calculate"
                    (click)="CalculateLoan()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Calculate
                </button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Lender"
                    (click)="declineCalculate()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
                </button>


            </div>
        </ng-template>

        <ng-template #LenderInfoandCustomer>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Customer and Lender Info
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div id="customerlenderInfo" class="col-md-12">
                    <div class="row">
                        <div class="table-responsive">
                            <table class="table table-bordered table-responsive mb-0">
                                <tbody>
                                    <tr>
                                        <th>First Name</th>
                                        <td>{{InfoFirstName}}</td>
                                    </tr>
                                    <tr>
                                        <th>Last Name</th>
                                        <td>{{InfoLastName}}</td>
                                    </tr>
                                    <tr>
                                        <th>Phone</th>
                                        <td>{{InfoPhone}}</td>
                                    </tr>
                                    <tr>
                                        <th>Email</th>
                                        <td>{{InfoEmail}}</td>
                                    </tr>
                                    <tr>
                                        <th>Lender</th>
                                        <td>{{LenderNameValue}}</td>
                                    </tr>
                                    <tr>
                                        <th>Given Tier</th>
                                        <td>{{PlanType}}</td>
                                    </tr>
                                    <tr>
                                        <th>LTV</th>
                                        <td>{{OverRideFLTVControl}}</td>
                                    </tr>
                                    <tr>
                                        <th>Interest Rate</th>
                                        <td>{{InterestRateDisplay}}</td>
                                    </tr>

                                    <tr>
                                        <th>Pay Call</th>
                                        <td>{{PayCallDisaply}}</td>
                                    </tr>
                                    <tr>
                                        <th>Trade-In</th>
                                        <td>{{TradeAllowanceControl}}</td>
                                    </tr>
                                    <tr>
                                        <th>Lien</th>
                                        <td>{{LienControl}}</td>
                                    </tr>
                                    <tr>
                                        <th>Down Payment</th>
                                        <td>{{DownPaymentControl}}</td>
                                    </tr>
                                    <tr>
                                        <th>Equity</th>
                                        <td>{{EquityControl}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>



            </div>
            <div class="modal-footer">



            </div>
        </ng-template>


        <ng-template #ModelPopUpOrders let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="OrderListLabel" style="color: white;">Order Details</h5>
                <button type="button" class="close" style="color: white;" (click)="CloseAddmodel();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <section class="generate-report">
                    <div class="col-12 vehicle-info mb-3">
                        <div class="row">
                            <div class="w-100">
                                <div class="card">
                                    <div class="card-header card-raised bg-info text-white">
                                        <h6 class="mb-0">Vehicle Info</h6>
                                    </div>
                                    <div class="card-body px-3 py-2">
                                        <div class="row">
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>VIN</label>
                                                <div class="w-100">{{VINControl}}</div>
                                            </div>
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>Year</label>
                                                <div class="w-100">{{Trim1Control}}</div>
                                            </div>
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>Model</label>
                                                <div class="w-100">{{ModelControl}}</div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>Make</label>
                                                <div class="w-100">{{MakeControl}}</div>
                                            </div>
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>Trim</label>
                                                <div class="w-100">{{TrimControl}}</div>
                                            </div>
                                            <div class="form-group col-xl-4 col-lg-4 col-md-6">
                                                <label>OdometerReading:</label>
                                                <div class="w-100">{{KmsControl}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Vehicle List Price</label>
                            <input type="text" formControlName="purchasePriceLeaseValue" readonly
                                [(ngModel)]="purchasePriceLeaseValue" placeholder="Purchase Amount"
                                class="form-control">
                        </div>

                        <div class="form-group col-xl-4 col-lg-4 col-md-6" style="display: none;">
                            <label>Sold Price </label>
                            <input type="text" formControlName="SoldPriceValue" 
                            [(ngModel)]="SoldPriceValue"
                                placeholder="Sold Price" class="form-control">
                        </div>

                        <div class="form-group col-xl-4 col-lg-4 col-md-6" style="display: none;">
                            <label>Vendor Key</label>
                            <input type="text" formControlName="OrderVenderKey" readonly [(ngModel)]="OrderVenderKey"
                                placeholder="Vendor Key" class="form-control">
                        </div>

                        <div class="form-group col-xl-4 col-lg-4 col-md-6" style="display: none;">
                            <label>Product Key</label>
                            <input type="text" formControlName="OrderProductKey" [(ngModel)]="ProductVenderKey"
                                placeholder="Product Key" class="form-control">
                        </div>



                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>First Name</label>
                            <input type="text" formControlName="OrderFirstName"
                            (keyup)="onKeyFirstName($event)" [(ngModel)]="OrderFirstName"
                                placeholder="First Name" class="form-control">
                            <span *ngIf="OrderFirstNameValue ==='true'" class="text-danger w-100">first name required!</span>
                        </div>
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Last Name</label>
                            <input type="text" 
                            (keyup)="onKeyLastName($event)" formControlName="OrderLastName" [(ngModel)]="OrderLastName"
                                placeholder="Last Name" class="form-control">
                         <span *ngIf="OrderLastNameValue ==='true'" class="text-danger w-100">Last name required!</span>
                        </div>

                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-12">
                            <h6 for="options">Product List</h6>
                            <div class="row mt-3">
                                <div class="col-12">
                                   
                                    <table class="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <!-- <th></th> -->
                                                <th>Product Name</th>
                                                <th>Sale Price</th>
                                                <th>Cost</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let productListdata of addedproductlists">
                                                
                                                <td>{{productListdata.productName}}</td>
                                                <td>{{productListdata.soldPrice | currency}}</td>
                                                <td>{{productListdata.price | currency}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">

                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>City</label>
                            <input type="text" 
                            (keyup)="onKeyCity($event)" formControlName="OrderCity" [(ngModel)]="OrderCity" placeholder="City"
                                class="form-control">
                                <span *ngIf="OrderCityValue ==='true'" class="text-danger w-100">City name required!</span>
                        </div>

                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Province State Code</label>
                            <input type="text" formControlName="ProvinceStateCode" readonly
                                [(ngModel)]="ProvinceStateCode" placeholder="Province" class="form-control">
                                
                            </div>


                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Lien Holder Name</label>
                            <input type="text" formControlName="OrderLienholderName" [(ngModel)]="OrderLienholderName"
                            (keyup)="onKeyLien($event)" placeholder="Lien Holder Name" class="form-control">
                                <span *ngIf="OrderLienholderNameValue ==='true'" class="text-danger w-100">Lien Holder Name required!</span>
                        </div>

                    </div>
                    <div class="row">
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Phone</label>
                            <input type="text" 
                            (keyup)="onKeyPhone($event)" formControlName="OrderPhone" [(ngModel)]="OrderPhone" placeholder="Phone"
                                class="form-control">
                           <span *ngIf="OrderPhoneValue ==='true'" class="text-danger w-100">Phone required!</span>

                        </div>
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Email</label>
                            <input type="text" 
                            (keyup)="onKeyEmail($event)" formControlName="OrderEmail" [(ngModel)]="OrderEmail" placeholder="Email"
                                class="form-control">
                            <span *ngIf="OrderEmailValue ==='true'" class="text-danger w-100">Email required!</span>

                        </div>
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">
                            <label>Postal Code</label>
                            <input type="text"
                            (keyup)="onKeyPostalCode($event)"  formControlName="OrderPostalCode" [(ngModel)]="OrderPostalCode"
                                placeholder="Postal Code" class="form-control">
                            <span *ngIf="OrderPostalCodeValue ==='true'" class="text-danger w-100">Postal Code required!</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">

                            <label>Address1</label>

                            <textarea type="text" 
                            (keyup)="onKeyAddress($event)" formControlName="OrderAddress1" [(ngModel)]="OrderAddress1"
                                placeholder="Address1" class="form-control"></textarea>
                            <span *ngIf="OrderAddress1Value ==='true'" class="text-danger w-100">Address1 required!</span>
                        </div>
                        <div class="form-group col-xl-4 col-lg-4 col-md-6">

                            <label>Address2</label>
                            <textarea type="text" formControlName="OrderAddress2" [(ngModel)]="OrderAddress2"
                                placeholder="Address2" class="form-control">
                        </textarea>
                        </div>
                        <div class="form-group col-xl-4 col-lg-4 col-md-6" style="display: none;">
                            <label class="w-100">&#160;</label>
                            <input style="height: 15px;width: 15px;vertical-align: middle;
                        margin-right: 6px;" type="checkbox" formControlName="IsCommercialCheck"
                                [(ngModel)]="IsCommercialCheck" placeholder="Last Name" class="form-check-label">
                            <label>Commercial Use </label>
                        </div>
                    </div>
                </section>
            </div>
            <div class="modal-footer">

                <button *ngIf="buttonloaderSumbitSendEmail" 
                [disabled]="true" class="buttonload btn btn-sm btn-secondary bg-gradient-info rounded-pill">
                    <i class="fa fa-spinner fa-spin"></i>Order Processing</button>
                <button type="button" *ngIf="!buttonloaderSumbitSendEmail" style="margin-left: 14px;"
                    (click)="SaveOrderDetails()" class="btn btn-sm bg-gradient-info rounded-pill">Submit</button>

                <!-- <button type="button" class="btn btn-success btn-sm text-white rounded-pill"
                    (click)="SaveOrderDetails()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Submit
                </button> -->
                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>
            </div>
        </ng-template>



        <a #ConfirmBoxGlobalWarranty style="display: none;" [routerLink]=""
            (click)="openModalGLobal(ModelPopUpGlobalWarranty)"><i class="fa fa-upload" triggers="mouseenter:mouseleave"
                popoverTitle="GlobalWarranty " placement="top" ngbPopover="GlobalWarranty "></i></a>

        <ng-template #ModelPopUpGlobalWarranty let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="OrderListLabel" style="color: white;">Global Warranty Purchase Information
                </h5>
                <button type="button" class="close" style="color: white;" (click)="CloseAddmodel();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="row">
                    <div class="col-12">
                        <div class="card w-100">
                            <div class="card-header card-raised bg-info text-white">
                                <h6 class="mb-0">Vehicle Information</h6>
                            </div>
                            <div class="card-body px-3 py-2">
                                <div class="row">
                                    <div class="form-group col-xl-4 col-12">
                                        <label>VIN</label>
                                        <div class="w-100">
                                            {{CustomerVIN}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-12">
                                        <label>Year</label>
                                        <div class="w-100">
                                            {{CustomerYear}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-12">
                                        <label>Make</label>
                                        <div class="w-100">
                                            {{CustomerMake}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-xl-4 col-12">
                                        <label>Model</label>
                                        <div class="w-100">
                                            {{CustomerModel}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-12">
                                        <label>Trim</label>
                                        <div class="w-100">
                                            {{Customertrim}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-12">
                                        <label>Mileage</label>
                                        <div class="w-100">
                                            {{CustomerMilege}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                   
                        <hr />
                        <div class="card w-100">
                            <div class="card-header card-raised bg-info text-white">
                                <h6 class="mb-0">Customer Information</h6>
                            </div>
                            <div class="card-body px-3 py-2">
                                <div class="row">
                                    <div class="form-group col-xl-4 col-lg-4 col-md-12">
                                        <label>Customer Name</label>
                                        <div class="w-100">
                                            {{CustomerFirstName }} {{CustomerLastName}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-lg-4 col-md-12">
                                        <label>Phone</label>
                                        <div class="w-100">
                                            {{CustomerPhone}}
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-4 col-lg-4 col-md-12">
                                        <label>Email:</label>
                                        <div class="w-100">
                                            {{Customeremail}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card w-100">
                            <div class="card-header card-raised bg-info text-white">
                                <h6 class="mb-0">Global Warranty</h6>
                            </div>
                            <div class="card-body px-3 py-2">
                                <div class="table-responsive">
                                <!-- {{GlobalWarrantyId}} -->
                                    <table class="table table-bordered mb-0">
                                        <thead>
                                            <tr>
                                                <!-- <th></th> -->
                                                <th>Product Name</th>
                                                <th>Sale Price</th>
                                            
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let productListdata of addedproductlists">
                                                
                                                <td>{{productListdata.productName}}</td>
                                                <td>{{productListdata.soldPrice | currency}}</td>
                                                
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="row">
                                    <div class="form-group col-xl-6 col-lg-6 col-md-12">
                                        <label>Order Date</label>
                                        <div class="w-100">
                                            {{orderUtcDateValue | date}}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                
                <!-- <div class="row">
                    <div class="form-group col-xl-6 col-lg-6 col-md-12">
                        <label>Global Warranty</label>
                        <div class="w-100">
                           
                        </div>
                    </div>

                    <div class="form-group col-xl-6 col-lg-6 col-md-12">
                        <label>Order Date</label>
                        <div class="w-100">
                            {{orderUtcDateValue | date}}
                        </div>
                    </div>
                </div> -->
            </div>



            <div class="modal-footer">

                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>
            </div>
        </ng-template>


        <ng-template #ModelPopUpImages let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Images</h5>
                <button type="button" class="close" style="color: white;" (click)="RefreshImageList();modal.dismiss()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report imageSize row">

                    <div class="card card-raised mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Auto Upload Images</h6>
                        </div>
                        <div class="card-body p-2">


                            <div *ngIf="errorshowAuto=='false'"
                                style="width:750px; height:485px; text-align:center;margin:0; padding:0;">
                                <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                                    [imageSize]="{width: '100%', height:200}" slideImage="1">
                                </ng-image-slider>

                            </div>

                            <div *ngIf="errorshowAuto=='true'"
                                class="bg-danger text-white col-md-4 mx-auto text-center">
                                {{errorMessageAuto}}
                            </div>
                        </div>
                    </div>

                </section>

                <section class="manual-uploadImage">
                    <div class="card card-raised  mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Manual Upload Images</h6>
                        </div>
                        <div class="card-body p-2" *ngIf="errorshow=='false'">
                            <div class="profile-icon" *ngFor="let element of customimagesrc">
                                <img [src]="element.imgsrc" width="150" height="150" />
                            </div>
                        </div>

                        <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                            {{errorMessage}}
                        </div>

                    </div>
                </section>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-danger ml-2 rounded-pill"
                    (click)="RefreshImageList();modal.dismiss()">Cancel</button>
            </div>
        </ng-template>




        <a #ConfirmBoxGetWorkSheetdetail style="display: none;" [routerLink]=""
            (click)="openModalgetDetail(template)"><i class="fa fa-upload" triggers="mouseenter:mouseleave"
                popoverTitle="Delete Users" placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
        <ng-template #template>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Do you want to Submit current Deal as a Worksheet to Creditor?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                    (click)="confirmSetWorkSheetDetail()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmBoxGetValidationdetail style="display: none;" [routerLink]=""
            (click)="openModalgetValidationDetail(template)"><i class="fa fa-upload" triggers="mouseenter:mouseleave"
                popoverTitle="Delete Users" placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
        <ng-template #template>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>UVC for this Unit is not available.</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                    (click)="confirmSetValidationDetail()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmBoxAfterWorkSheetdetail style="display: none;" [routerLink]=""
            (click)="openModalgetValidationDetail(template)"><i class="fa fa-upload" triggers="mouseenter:mouseleave"
                popoverTitle="Delete Users" placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
        <ng-template #template>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Work Sheet Details set successfully.</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                    (click)="confirmSetValidationDetail()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

        <a #ConfirmBoxUVCdetail style="display: none;" [routerLink]="" (click)="openModalgetUVCDetail(templateUVC)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users" placement="top"
                ngbPopover="Are you sure you want to delete?"></i></a>
        <ng-template #templateUVC>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Do you want to get UVC detail?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                    (click)="confirmUVCDetail()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>

    </form>
</section>