import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalcodeService } from '@app/_services/globalcode.service';
@Component({
  selector: 'app-hubwholesale-dashborad',
  templateUrl: './hubwholesale-dashborad.component.html',
  styleUrls: ['./hubwholesale-dashborad.component.less']
})
export class HubwholesaleDashboradComponent implements OnInit {
  private notifier: NotifierService;
  modalRef: BsModalRef;
  displayedImage: string;
  radioTitle: string;
  radioItems: Array<string>;

  radioCurrencyItems: Array<string>;
  ReasonList = [];
  listViewValue: any;
  radioAcceptCheckItems: Array<string>;
  radioAcceptItems: Array<string>;
  modalcloseOpen: any;
  radioMakringItems: Array<string>;
  vehicleObject = [];
  errorshowAuto: any;
  errorMessageAuto: any;
  customimagesrc = [];
  DvDealerName: any;
  DvDealerAddress: any;
  DvDealerContactName: any;
  DvDealerContactPhoneNumber: any;
  DvDealerContactEmail: any;
  errorshow: any;
  errorMessage: string;
  model = { option: 'option3' };
  constructor(private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService,
    notifier: NotifierService,
    private confirmModalService: BsModalService,
    private modalService: NgbModal,
    private globalcodeService: GlobalcodeService,
    private fb: FormBuilder

  ) {
    this.notifier = notifier;
    this.radioItems = ['ALL', 'EBlock', 'Aithrhub', 'Flagged', 'Favorites', 'On Clearance', 'Repo', 'EV models'];
    this.radioCurrencyItems = ['CAD', 'USD'];

    this.radioAcceptItems = ['Decision', 'Counter Offer', 'Response Only'];
    this.radioAcceptCheckItems = ['Accept', 'Decline'];

    this.radioMakringItems = ['Mark as My Favorite', 'Mark as Sold', 'Mark as Removed'];

    this.decisionsectionradio_Checked = "";
    this.decisionsectionradio_Disable = "";
  }
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  @ViewChild('ConfirmMarkSoldBox', { static: false }) ConfirmMarkSoldBoxClick: ElementRef;
  @ViewChild('ConfirmMarkRemoveBox', { static: false }) ConfirmMarkRemoveBoxClick: ElementRef;
  @ViewChild('MarkButtonCloseBox', { static: false }) MarkButtonCloseBoxClick: ElementRef;
  @ViewChild('ConfirmClearanceBox', { static: false }) ConfirmClearanceBoxClick: ElementRef;
  @ViewChild('ConfirmBoxMarkUnFav', { static: false }) ConfirmBoxMarkUnFavclick: ElementRef;


  //@ViewChild('regioncheckboxpopupbuttonToBeClicked', { static: false }) regionbuttonToBeClicked: ElementRef;
  @ViewChild('regioncheckboxpopupbuttonToBeClicked') regionbuttonToBeClicked: ElementRef

  Vinarraylist: any[] = [];
  isaithr: boolean = true;
  DisplayCurrency: any;
  isaithrselectedvalue: any = "ALL";
  isaithropcode: any = 1;
  markValueTitle: any;
  UserName: any;
  UserEmail: any;
  userPhoneNO: any;
  DealerName: any;
  DealerNumber: any;
  DealerAddress: any;
  DealerEmail: any;
  selectedVin: any;
  UnitFilterName: any;
  OpcodeDetail: any;
  VINLogCheck: any;
  typeofaction: any;
  Make: any;
  Model: any;
  Year: any;
  hubwholeSaleForm: FormGroup;
  dealerVehicleslist: DealerVehicles[] = [];
  verifycheckboxes = [];
  TotalRecord: any;
  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  MarkVIN: any;
  TypeClick: any;
  Markid: any;
  MarkDealerId: any;
  MarkStockNo: any;
  MarkYear: any;
  MarkMilegge: any;
  MarkMake: any;
  MarkModel: any;
  Marktrim: any;
  Markbody: any;
  Markprice: any;
  VinGetFlagReport: any;
  DealerIdValue: any;
  DealerNameValue: any;
  rolevalue: any;
  userEmail: any;
  userName: any;
  Userid: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  UserIDValue: any;
  VinValue: any;
  buttonloaderSendEmail: boolean = false;
  buttonloaderBuyEmail: boolean = false;


  OfferRequest: any = 0;
  OfferRequestCommment: any;

  COunterOffer: any;
  COunterComment: any;
  COunterDate: any;

  IsCOunterDateVisible: any = false;


  ResponseComment: any;
  AccpetComment: any;
  RejectReason: any;
  Isaccept: any = 1;

  OfferDateBindng: any;

  OfferCommentng: any;

  OfferAmountng: any = "0.00";

  offerreadonly: boolean = false;
  newOffer: boolean = false;


  //For three radio button
  SelectionRadiobt: any;


  SelectedVIN: any;
  UnitDealerId: any;
  UunitVechileId: any;
  UnitDealerCost: any;
  UnitListPrice: any;
  OpcodeForOffer: number = 0;
  OfferType: number = 0;
  OfferFromValue: any;
  statusofOffer: any;

  primeid: any;

  Ngpriceranegmin: any;
  Ngpriceranegmax: any;


  // for Region filters
  regionlistName = [];
  groupRegionWestern: any;
  groupRegionCentral: any;
  groupRegionEastern: any;
  groupRegionother: any;
  groupRegionOntario: any;
  regionmultiselectarray: any[];
  province: any;
  ItemCountregion: any;
  SelectedAllCheckregion: any;
  ExtingItemCountregion: any;

  decisionsectionradio_Checked: any;
  decisionsectionradio_Disable: any;
  Isaccepted_Checked: any;

  GroupRegionCheckboxArray = [];

  AdvancemakeCheckboxArray = [];
  AdvancebodyCheckboxArray = [];
  AdvanceYearCheckboxArray = [];
  Advance_Mileage_CheckboxArray = [];

  Advance_Model_CheckboxArray = [];

  Advance_trim_CheckboxArray = [];


  Advance_body_CheckboxArray = [];
  Advance_color_CheckboxArray = [];
  Advance_transmission_CheckboxArray = [];


  SelectedAdvancemakeCheckboxArray: any[];
  SelectedAdvanceYearCheckboxArray: any[];
  SelectedAdvancebodyCheckboxArray: any[];
  Selected_Advancemilege_CheckboxArray: any[];

  Selected_trim_CheckboxArray: any[];

  Selected_model_CheckboxArray: any[];

  Selected_body_CheckboxArray: any[];
  Selected_color_CheckboxArray: any[];
  Selected_transmission_CheckboxArray: any[];

  totalpagecount: any = 0;

  lightboxModel: any;

  DecisionitemsRadiobutton = [
    { groupName: 'Decision', value: 'Decision', disable: false },
    { groupName: 'Counter Offer', value: 'Counter Offer', disable: false },
    { groupName: 'Response Only', value: 'Response Only', disable: false },
  ];

  selectedValueDecisionRadioBT: any;

  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  PageTitle: any;
  ColorTheme: any;
  ngOnDestroy() {
    //let body = document.getElementsByTagName('body')[0];
    //body.classList.remove("body-zoomout");
  }
  ngAfterViewInit() {
    this.clickRegionButton();
  }
  ngOnInit(): void {
    //let body = document.getElementsByTagName('body')[0];
    //body.classList.add('body-zoomout');
    this.Vinarraylist = [];
    this.Createform();
    this.GetReasonlist();
    this.PageTitle = "ALL";
    this.listViewValue = "false";
    this.Userid = localStorage.getItem('userid');
    this.userName = localStorage.getItem('UserName');
    this.userEmail = localStorage.getItem('email');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue = localStorage.getItem('userid');
    this.isaithropcode = "3";

    this.DisplayCurrency = "CAD";
    this.decisionsectionradio_Checked = "";
    this.decisionsectionradio_Disable = "";
    this.Isaccepted_Checked = "";

    this.Isaccept = 1;
    this.selectedValueDecisionRadioBT = "";

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    //this.regionbuttonToBeClicked.nativeElement.click();

    // this.GetInventoryHubwholesaleDashborads("3");
    // this.clickRegionButton()
    // for reion Filters
    this.province = localStorage.getItem('Province');
    this.GetProvinceMuiltiSelectlist();

  }

  ChangeViewfunction(value: any) {

    var check = value;
    if (check === "Grid") {
      this.listViewValue = "false";
    } else {
      this.listViewValue = "true";
    }

  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
  }
  isAllSelected($event, item) {
    this.VinGetFlagReport = item.vin;
    // this.dealerVehicleslist.forEach(val => {
    //   if (val.id == item.id) val.isSelected = !val.isSelected;
    //   else {
    //     val.isSelected = false;
    //   }
    // });


    if ($event.target.checked) {
      this.Vinarraylist.push(item.vin);
    } else {
      let i: number = 0;
      this.Vinarraylist.forEach((item: any) => {
        console.log(item);
        if (item.vin == item.vin) {
          this.Vinarraylist.splice(i, 1);
          return;
        }
        i++;
      });
    }
  }



  pageChangeEvent(event: number) {

    this.page = event;
    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
    // this.GetAllVehicleDetails();
    // this.getUsers();
  }



  searchtextfunction() {
    debugger;
    var serachtext = this.searchText;

    this.GetInventoryHubwholesaleDashborads("1");

  }


  onSlideClicked(targetModal, value: any) {
    debugger;
    this.lightboxModel = value;
    this.modalRef = this.confirmModalService.show(targetModal, {
      class: 'modal-dialog-centered modal-md',
      backdrop: 'static',
      keyboard: false
    });
  }

  //radio button selection
  SelectionRadioFunction_old(typevalue) {

    this.SelectionRadiobt = typevalue;
  }

  SelectionRadioFunction(value: any) {

    if (value == "Decision") {

      this.SelectionRadiobt = 999;

      ///this.RadioValue = value;
      // this.DecisonSectionVisible= true;
      //this.CounterSectionVisible= false;
      //this.ResponseSectionVisible= false;
    }
    else if (value == "Counter Offer") {
      this.SelectionRadiobt = 100;
      // this.CounterSectionVisible= true;
      // this.ResponseSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;
    } else if (value == "Response Only") {
      this.SelectionRadiobt = 111;
      // this.ResponseSectionVisible= true;
      // this.CounterSectionVisible= false;
      // this.DecisonSectionVisible= false;
      // this.RadioValue = value;

    }

  }



  GenerateFlagReportList() {
    if (this.VinGetFlagReport != "" && this.VinGetFlagReport != undefined) {

      this.GenerateFlagReport();

    } else {
      this.showNotification("error", "Please select atleast one VIN to get flag report.");
      return;
    }
  }
  GenerateFlagReport() {
    var modelGroup = {
      //"vin": this.VinGetFlagReport, //dynmic
      //"vin": "1FM5K8GT4EGB37824", //static
      "vinarray": this.Vinarraylist
    }
    this.spinner.show();
    this.manageInventoryService.generateFlagReport(modelGroup)
      .subscribe(
        (data: any) => {
          this.PageTitle = "ALL";
          // this.GetInventoryHubwholesaleDashborads("3");
          this.spinner.hide();
          this.Vinarraylist = [];
          this.showNotification("success", "Flag report generate successfully.")



          if (this.isaithrselectedvalue === "Aithrhub") {
            this.isaithr = true;
            this.PageTitle = "Aithr Hub Active";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "1";
            this.GetInventoryHubwholesaleDashborads("1");
          } else if (this.isaithrselectedvalue === "ALL") {
            this.isaithr = false;
            this.PageTitle = "ALL";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "3";
            this.GetInventoryHubwholesaleDashborads("3")
          } else if (this.isaithrselectedvalue === "Flagged") {
            this.isaithr = false;
            this.PageTitle = "Flag ";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "2";
            this.GetInventoryHubwholesaleDashborads("2")
          } else if (this.isaithrselectedvalue === "Favorites") {
            this.PageTitle = "Favorites";
            this.isaithropcode = "4";
            this.GetInventoryHubwholesaleDashborads("4")
          } else if (this.isaithrselectedvalue === "On Clearance") {
            this.PageTitle = "On Clearance";
            this.isaithropcode = "5";
            this.GetInventoryHubwholesaleDashborads("5");
          } else if (this.isaithrselectedvalue === "Repo") {
            this.PageTitle = "Repo";
            this.isaithropcode = "6";
            this.GetInventoryHubwholesaleDashborads("6");
          } else if (this.isaithrselectedvalue === "EV models") {
            this.PageTitle = "EV models";
            this.isaithropcode = "7";
            this.GetInventoryHubwholesaleDashborads("7");
          } else if (this.isaithrselectedvalue === "EBlock") {
            this.PageTitle = "EBlock";
            this.isaithropcode = "8";
            this.GetInventoryHubwholesaleDashborads("8");
          }
        },
        error => {
        });
  }


  setCurrenyradio(value: any) {


    this.DisplayCurrency = value;



    if (this.isaithrselectedvalue === "Aithrhub") {
      this.isaithr = true;
      this.PageTitle = "Aithrhub";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "1";
      this.GetInventoryHubwholesaleDashborads("1");
    } else if (this.isaithrselectedvalue === "ALL") {
      this.isaithr = false;
      this.PageTitle = "ALL";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "3";
      this.GetInventoryHubwholesaleDashborads("3")
    } else if (this.isaithrselectedvalue === "Flagged") {
      this.isaithr = false;
      this.PageTitle = "Flag ";
      // this.isaithrselectedvalue = value;
      this.isaithropcode = "2";
      this.GetInventoryHubwholesaleDashborads("2")
    } else if (this.isaithrselectedvalue === "Favorites") {
      this.isaithr = false;
      this.PageTitle = "Favorites";
      // this.isaithrselectedvalue = value;
      this.isaithropcode = "4";
      this.GetInventoryHubwholesaleDashborads("4")
    } else if (this.isaithrselectedvalue === "On Clearance") {
      this.isaithr = false;
      this.PageTitle = "On Clearance";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "5";
      this.GetInventoryHubwholesaleDashborads("5")
    } else if (this.isaithrselectedvalue === "Repo") {
      this.isaithr = false;
      this.PageTitle = "Repo";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "6";
      this.GetInventoryHubwholesaleDashborads("6")
    } else if (this.isaithrselectedvalue === "EV models") {
      this.isaithr = false;
      this.PageTitle = "EV models";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "7";
      this.GetInventoryHubwholesaleDashborads("7")
    } else if (this.isaithrselectedvalue === "EBlock") {
      this.isaithr = false;
      this.PageTitle = "EBlock";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "8";
      this.GetInventoryHubwholesaleDashborads("8")
    }

  }

  setradio(value: any) {

    if (value === "Aithrhub") {
      this.isaithr = true;
      this.PageTitle = "Aithrhub";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "1";
      this.GetInventoryHubwholesaleDashborads("1");
    } else if (value === "ALL") {
      this.isaithr = false;
      this.PageTitle = "ALL";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "3";
      this.GetInventoryHubwholesaleDashborads("3")
    } else if (value === "Flagged") {
      this.isaithr = false;
      this.PageTitle = "Flag ";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "2";
      this.GetInventoryHubwholesaleDashborads("2")
    } else if (value === "Favorites") {
      this.isaithr = false;
      this.PageTitle = "Favorites";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "4";
      this.GetInventoryHubwholesaleDashborads("4")
    } else if (value === "On Clearance") {
      this.isaithr = false;
      this.PageTitle = "On Clearance";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "5";
      this.GetInventoryHubwholesaleDashborads("5")
    } else if (value === "Repo") {
      this.isaithr = false;
      this.PageTitle = "Repo";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "6";
      this.GetInventoryHubwholesaleDashborads("6")
    } else if (value === "EV models") {
      this.isaithr = false;
      this.PageTitle = "EV models";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "7";
      this.GetInventoryHubwholesaleDashborads("7")
    } else if (value === "EBlock") {
      this.isaithr = false;
      this.PageTitle = "EBlock";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "8";
      this.GetInventoryHubwholesaleDashborads("8")
    }

  }

  setradioMarkUnits(value: any, item: any) {
    this.Markid = item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.MarkStockNo = item.stockNumber;

    this.MarkMilegge = item.mileage;

    this.Marktrim = item.trim;
    this.Markbody = item.body;
    this.Markprice = item.listedPrice;

    if (value === "Mark as My Favorite") {
      this.markValueTitle = value;
      this.TypeClick = "Favorite";
      let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
      el.click();

    } else if (value === "Mark as Sold") {
      this.markValueTitle = value;
      this.TypeClick = "Sold";
      let el: HTMLElement = this.ConfirmMarkSoldBoxClick.nativeElement;
      el.click();
    } else if (value === "Mark as Removed") {
      this.markValueTitle = value;
      this.TypeClick = "Removed";
      let el: HTMLElement = this.ConfirmMarkRemoveBoxClick.nativeElement;
      el.click();
    } else if (value === "Clearance") {
      this.markValueTitle = value;
      this.TypeClick = "Clearance";
      let el: HTMLElement = this.ConfirmClearanceBoxClick.nativeElement;
      el.click();
    } else if (value === "Remove from Favorite") {
      this.markValueTitle = value;
      this.TypeClick = "Clearance";
      let el: HTMLElement = this.ConfirmBoxMarkUnFavclick.nativeElement;
      el.click();

    }

  }

  // IsaithrorFlag(ischeck) {
  //   if (ischeck.target.value === 'InAithr') {
  //     this.isaithr = true;
  //     this.isaithrselectedvalue = ischeck.target.value;
  //     this.isaithropcode = "1";
  //     this.GetInventoryHubwholesaleDashborads("1");
  //   } else {
  //     this.isaithr = false;
  //     this.isaithrselectedvalue = ischeck.target.value;
  //     this.isaithropcode = "2";
  //     this.GetInventoryHubwholesaleDashborads("2")
  //   }
  // }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  Createform() {
    this.hubwholeSaleForm = new FormGroup({
      UnitFilterName: this.fb.control(''),

      // controls for Offer
      OfferRequest: this.fb.control(''),
      OfferRequestCommment: this.fb.control(''),

      COunterOffer: this.fb.control(''),
      COunterComment: this.fb.control(''),
      AccpetComment: this.fb.control(''),
      RejectReason: this.fb.control(''),
    });
  }


  GetReasonlist() {
    this.globalcodeService.getReasonList()
      .subscribe(
        (data: any) => {
          this.ReasonList = data;
        },
        error => {
        });
  }
  // function for open model pop up
  Aithrenquiry(template: TemplateRef<any>, item: any, typeofaction: any) {
    debugger;
    this.OfferRequest = "0";
    this.OfferRequestCommment = "";

    this.verifycheckboxes = [];
    this.selectedVin = item.vin;
    this.Make = item.make;
    this.Model = item.model;
    this.Year = item.carYr;
    this.typeofaction = typeofaction;

    var checkedverifydata = {
      vin: item.vin,
      type: "",
      loggeduserid: this.UserIDValue,
      notes: "Enquiry",
      hello:"testing",
      ipAddress:localStorage.getItem('IpAddresstoTrack')

    }
    this.verifycheckboxes.push(checkedverifydata);
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  // function for open model pop up
  MarkOpenModelPopup(template: TemplateRef<any>, item: any, typeofaction: any) {

    // this.verifycheckboxes = [];
    this.Markid = item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.typeofaction = typeofaction;

    this.MarkStockNo = item.stockNumber;

    this.MarkMilegge = item.mileage;

    this.Marktrim = item.trim;
    this.Markbody = item.body;
    this.Markprice = item.listedPrice;


    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  CheckDetails(template: TemplateRef<any>, item: any) {
    this.selectedVin = item.vin;
    this.GetInquiredDetail();
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  //advance filter

  AdvancFilter(template: TemplateRef<any>) {
    this.Get_makeforadvancefilter();
    this.Get_Bodyforadvancefilter();
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });

  }


  GetInquiredDetail() {
    var modelGroup = {
      "VIN": this.selectedVin
    }

    this.manageInventoryService.getInquiredDetail(modelGroup)
      .subscribe(
        data => {
          this.UserName = data[0].userName;

          this.UserEmail = data[0].userName
          this.userPhoneNO = data[0].phoneNumber
          this.DealerName = data[0].dealerName
          this.DealerNumber = data[0].dealerPhone
          this.DealerAddress = data[0].dealerAddress;
          this.DealerEmail = data[0].dealerEmail;
        },
        error => {
        });


  }


  //getmake
  Get_makeforadvancefilter() {

    var modelGroup = {
      "opcode": "1"
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          this.AdvancemakeCheckboxArray = data;

        },
        error => {
        });


  }

  //get year

  Get_YearBymakeforadvancefilter() {

    var modelGroup = {
      "opcode": "4",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.AdvanceYearCheckboxArray = data;

        },
        error => {
        });


  }


  //get Milege

  Get__Milege_By_yearforadvancefilter() {

    var modelGroup = {
      "opcode": "5",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_Mileage_CheckboxArray = data;

        },
        error => {
        });


  }


  //get body
  Get_bodyBymakeforadvancefilter() {

    var modelGroup = {
      "opcode": "4",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          this.AdvancebodyCheckboxArray = data;

        },
        error => {
        });


  }


  Get_Bodyforadvancefilter() {

    var modelGroup = {
      "opcode": "2"
    }

    this.manageInventoryService.getbodyforadvance(modelGroup)
      .subscribe(
        data => {

          this.AdvancebodyCheckboxArray = data;
        },
        error => {
        });


  }

  declineClose() {
    this.confirmModalService.hide(1);
  }


  //get Group region

  GetGroupRegion(value) {




    var regionglobalvalue = {
      globalid: value
    }


    var isexist = 0;
    if (this.GroupRegionCheckboxArray.find(x => x.globalid == value)) {
      this.GroupRegionCheckboxArray.splice(this.GroupRegionCheckboxArray.findIndex(x => x.globalid == value), 1);
      isexist = 1;
    }

    if (isexist == 0) {
      this.GroupRegionCheckboxArray.push(regionglobalvalue);
    }

    //first i do false to all checkbox start
    this.regionlistName.forEach((item, index) => {

      this.regionlistName[index].IsSelected = false
    })
    //end


    this.GroupRegionCheckboxArray.forEach(element => {

      this.regionlistName.forEach((item, index) => {

        if (item.evbI2 == element.globalid) {
          this.regionlistName[index].IsSelected = true
        }
      })

    });

    if (this.GroupRegionCheckboxArray.length == 5) {
      this.SelectedAllCheckregion = true;
    } else {
      this.SelectedAllCheckregion = false;
    }

  }


  vehicleSlideV1: Array<object> = [{
    image: '../assets/images/nofoundImage.png',
    thumbImage: '../assets/images/nofoundImage.png',
    alt: 'No image',
    //title: 'No image'
  }
  ];



  //   vehicleSlideV1: Array<object> = [{
  //     image: 'assets/images/nofoundimage.png',
  //     thumbImage: 'assets/images/nofoundimage.png',
  //     alt: 'alt of image',
  //     title: 'title of image'
  // }, {
  //     image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-25.jpg',
  //     thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-25.jpg',
  //     order: 1
  // },
  // {
  //   image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  //   thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  //   order: 2
  // }
  // ,
  // {
  //   image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
  //   thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
  //   order: 3
  // }
  // ];



  declineAdvanceFilter(): void {
    this.modalRef.hide();
  }

  declineregion(): void {
    this.confirmModalService.hide(1);
  }

  decline(): void {

    this.Ngpriceranegmin = "0";
    this.Ngpriceranegmax = "0";

    this.SelectedAdvancemakeCheckboxArray = [];

    this.SelectedAdvanceYearCheckboxArray = [];
    this.SelectedAdvanceYearCheckboxArray = [];
    this.Selected_Advancemilege_CheckboxArray = [];
    this.Selected_model_CheckboxArray = [];
    this.Selected_trim_CheckboxArray = [];
    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];


    this.AdvancemakeCheckboxArray = [];
    this.AdvanceYearCheckboxArray = [];
    this.Advance_Mileage_CheckboxArray = [];
    this.Advance_Model_CheckboxArray = [];
    this.Advance_trim_CheckboxArray = [];
    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];





    this.modalRef.hide();


  }

  VerifyVehicle(e, type, item: any) {
    if (this.verifycheckboxes.length > 0) {
      this.verifycheckboxes = [];
    }
    if (e.target.checked) {
      var getuserid = localStorage.getItem('userid');
      var checkedverifydata = {
        vin: this.selectedVin,
        type: type,
        loggeduserid: getuserid,
        notes: this.typeofaction,
        hello: "testing",
        ipAddress: localStorage.getItem('IpAddresstoTrack')

      }

      this.verifycheckboxes.push(checkedverifydata);

    }
    else {
      let updateItem = this.verifycheckboxes.find(this.findIndexToUpdate, type);

      let index = this.verifycheckboxes.indexOf(updateItem);

      this.verifycheckboxes.splice(index, 1);

    }
  }



  DataForBuyClick() {
    var getuserid = localStorage.getItem('userid');
    var checkedverifydata = {
      vin: this.selectedVin,
      type: "Buy",
      loggeduserid: getuserid,
      notes: this.typeofaction,
      ipAddress: localStorage.getItem('IpAddresstoTrack')

    }

    this.verifycheckboxes.push(checkedverifydata);
  }


  findIndexToUpdate(type) {
    return type === this;
  }


  IsAcceptedClicked(value: any) {

    if (value == "Accept") {
      this.Isaccept = 1;
    }
    else {
      this.Isaccept = 99;
    }

  }

  PostOfferRequestRequest_old() {

    this.buttonloaderSendEmail = true;
    this.buttonloaderBuyEmail = true;
    // if (this.typeofaction == "Buy") {
    //   this.DataForBuyClick();
    // }

    this.spinner.show();
    var modelGroup = {};
    if (this.newOffer != true) {
      modelGroup = {
        // "requestvehicle": this.verifycheckboxes,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "OfferAmount": this.OfferAmountng,
        "OfferComment": this.OfferCommentng

      }
    }
    else {
      modelGroup = {
        // "requestvehicle": this.verifycheckboxes,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "CounterOffer": this.COunterOffer,
        "CounterComments": this.COunterComment,
        "AcceptComments": this.AccpetComment,
        "IsAccepted": this.Isaccept,
        "Reason": this.RejectReason

      }
    }

    if (this.newOffer != true) {
      this.manageInventoryService.MarkOfferFromdollarClick(modelGroup)
        .subscribe(
          (data: any) => {

            if (data.status == "1") {
              //    this.VinValue =data.vin;
              this.showNotification("success", "Offer saved.");

              this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
              this.modalService.dismissAll();
              // this.decline();
              this.declineClose();


              this.buttonloaderSendEmail = false;
              this.buttonloaderBuyEmail = false;
              this.spinner.hide();

              // for sending email after email logs
              // this.SendAithrbuyAndVerifyEmailList();
            }
          },
          error => {
          });

    }
    else {
      this.manageInventoryService.updateAIXZOfferLogDetail(modelGroup)
        .subscribe(
          (data: any) => {


            //    this.VinValue =data.vin;
            this.showNotification("success", "Offer Updated.");
            this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
            this.modalService.dismissAll();
            //this.decline();
            this.declineClose();


            this.buttonloaderSendEmail = false;
            this.buttonloaderBuyEmail = false;
            this.spinner.hide();

            // for sending email after email logs
            // this.SendAithrbuyAndVerifyEmailList();

          },
          error => {
          });

    }
  }


  //This method call when offer create from dollar
  PostOfferRequestRequest() {

    var modelGroup = {};
    if (this.isaithropcode == 2) // Flagged
    {
      this.OpcodeForOffer = 2;
      this.OfferType = 0;
    }
    if (this.isaithropcode == 5) // OnClearance
    {
      this.OpcodeForOffer = 1;
      this.OfferType = 1;
    }


    if (this.SelectionRadiobt == 111) {

      this.OfferCommentng = this.ResponseComment;
    }
    if (this.SelectionRadiobt == 100) {

      this.OfferCommentng = this.COunterComment;
      this.OfferAmountng = this.COunterOffer;
    }
    if (this.newOffer != true)   //When new offer create
    {
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "OfferType": this.OfferType,
        "OfferAmount": this.OfferAmountng,
        "OfferComment": this.OfferCommentng,
        "PrimeId": "0",
        "ActionType": "1",
        "ActionByDealerID": parseInt(this.DealerIdvalue),
        "ActionBy": localStorage.getItem('userid'),
        "ActionAmount": this.OfferAmountng,
        "ActionComments": this.OfferCommentng,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": "0",
        "AcceptComments": "",
        "ReasonId": this.RejectReason,
        "ReasonComments": "",
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue,

      }
    }
    else {    //Existing Offer
      modelGroup = {
        "OpCode": this.OpcodeForOffer,
        "loggedUserId": localStorage.getItem('userid'),
        "VIN": this.selectedVin,
        "OfferAmount": this.COunterOffer,
        "OfferComment": this.OfferCommentng,
        "PrimeId": this.primeid,
        "OfferType": this.OfferType,
        "ActionType": this.SelectionRadiobt,
        "ActionByDealerID": parseInt(this.DealerIdvalue),
        "ActionBy": localStorage.getItem('userid'),
        "ActionAmount": this.OfferAmountng,
        "ActionComments": this.OfferCommentng,
        "ActionToDealerID": this.UnitDealerId,
        "ActionTo": "0",
        "VehicleID": this.UunitVechileId,
        "DealerID": this.UnitDealerId,
        "ListedPrice": this.UnitListPrice,
        "DealerCost": this.UnitDealerCost,
        "IsAccepted": this.Isaccept,
        "AcceptComments": this.AccpetComment,
        "ReasonId": this.RejectReason,
        "ReasonComments": this.AccpetComment,
        "CommunicationUpdateCode": "0",
        "OfferExpiresOn": "0",
        "offerFrom": this.OfferFromValue
      }
    }

    if (this.newOffer != true)   //When new offer create
    {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
        .subscribe(
          (data: any) => {

            if (data.status == "1") {
              //    this.VinValue =data.vin;
              this.showNotification("success", "Offer saved.");
              this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

              //Reinitialize the models start
              this.Isaccept = 1;
              this.COunterComment = "";
              this.COunterOffer = 0;
              this.ResponseComment = "";
              this.selectedValueDecisionRadioBT = "";
              //Reinitialize the models nd

              this.modalService.dismissAll();
              // this.decline();
              this.declineClose();
            }
          },
          error => {
          });

    }
    else {
      this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)  //existing offer
        .subscribe(
          (data: any) => {


            //    this.VinValue =data.vin;
            this.showNotification("success", "Offer Updated.");
            this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

            this.Isaccept = 1;
            this.selectedValueDecisionRadioBT = "";

            this.modalService.dismissAll();
            //this.decline();
            this.declineClose();

          },
          error => {
          });

    }


  }


  PostVerifyvehicleRequest() {
    debugger;
    this.buttonloaderSendEmail = true;
    this.buttonloaderBuyEmail = true;
    if (this.typeofaction == "Buy") {
      this.DataForBuyClick();
    }

    this.spinner.show();
    var modelGroup = {
      "requestvehicle": this.verifycheckboxes,
      "VIN": this.selectedVin,
      "OfferAmount": this.OfferRequest,
      "OfferComment": this.OfferRequestCommment

    }

    this.manageInventoryService.PostVerifyVehicledata(modelGroup)
      .subscribe(
        (data: any) => {
          this.VinValue = data.vin;
          //this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

          this.showNotification("success", "Request saved and communicated via email.");
          this.decline();
          this.buttonloaderSendEmail = false;
          this.buttonloaderBuyEmail = false;
          this.spinner.hide();

          // for sending email after email logs
          this.SendAithrbuyAndVerifyEmailList();
        },
        error => {
        });

  }

  SendAithrbuyAndVerifyEmailList() {
    var modelGroup = {
      "UserId": this.Userid.toString(),
      "VIN": this.VinValue.toString(),
      "ButtonType": this.typeofaction.toString(),
      "PageType": "AithrMarketPlace"
    }
    this.manageInventoryService.sendAithrbuyAndVerifyEmail(modelGroup)
      .subscribe(
        data => {
          debugger;
        },
        error => {
        });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect', backdrop: 'static', keyboard: false });
  }
  MarkUints() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {

          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit marked as Favorite Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkUnFavUints() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnFavoriteUnits(modelGroup)
      .subscribe(
        (data: any) => {

          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit removed from Favorite Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkSold() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit marked as SOLD Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkRemove() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit marked as REMOVED Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkDiscounted() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type": this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
          this.TypeClick = "";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit marked as Discounted Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  exportexcel() {
    const prepare = [];
    this.dealerVehicleslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.province);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr + ' ' + e.make  + ' ' + e.model + ' ' + e.trim);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.colour);
      tempObj.push(e.engineFuelType);
      tempObj.push(e.cbbValue);
      
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['VIN','Province', 'StockNumber', 'Vehicle Information', 'Mileage', 'Make', 'Body', 'ListedPrice','Colour','Fuel Type', 'CbbValue','DeltaValue'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofAithrhubActiveUnits');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofAithrhubActiveUnits.xlsx');
  }
  
  downloadPdf() {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.province);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr + ' ' + e.make  + ' ' + e.model + ' ' + e.trim);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.colour);
      tempObj.push(e.engineFuelType);
      tempObj.push(e.cbbValue);
      
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['VIN','Province', 'StockNumber', 'Vehicle Information', 'Mileage', 'Make', 'Body', 'ListedPrice','Colour','Fuel Type', 'CbbValue','DeltaValue']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('ListofAithrhubActiveUnits' + '.pdf');
  }

  printTable() {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.province);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr + ' ' + e.make  + ' ' + e.model + ' ' + e.trim);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.colour);
      tempObj.push(e.engineFuelType);
      tempObj.push(e.cbbValue);
      
      tempObj.push(e.deltaValue);
      prepare.push(tempObj);
    });
  
    var table = '<table><thead><tr><th>VIN</th><th>Province</th><th>StockNumber</th><th>Vehicle Information</th><th>Mileage</th><th>Make</th><th>Body</th><th>ListedPrice</th><th>Colour</th><th>FuelType</th><th>CbbValue</th><th>DeltaValue</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
  
    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();
  
    // Wait for the table to be fully rendered before printing
    newWin.onload = function() {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  } 
  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dealerVehicleslist);
  }


  FilterUnits(event: any) {
    debugger;
    var filterunits = event;
    if (event == "") {
      this.UnitFilterName = "0";
    } else {
      this.UnitFilterName = filterunits;
    }
  //  this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
  }

  openImagesModelPopUp(targetModal, VIN) {
    this.GetVehiclePhotosListByVinList(VIN);
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  openViewDealerModelPopUp(targetModal, VIN) {
    this.GetDealerInformationByVin(VIN);
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'md'
    });
  }


  openMakeOfferModelPopUp(targetModal, VIN, offer: any, unitList: any, status) {

    this.statusofOffer = status;

    this.UnitListPrice = unitList.listedPrice;
    //Re initialize value of nngmodel start
    this.RejectReason = 0;
    this.AccpetComment = "";
    this.COunterOffer = 0.00;
    this.COunterDate = "";
    this.COunterComment = "";
    this.ResponseComment = "";
    this.Isaccepted_Checked = "";
    this.selectedValueDecisionRadioBT = 0;
    //end initialize

    this.VINLogCheck = VIN;
    this.selectedVin = VIN;
    this.offerreadonly = false;
    this.newOffer = false;
    if (offer > 0) {
      this.newOffer = true;
      this.GetAIXZOfferLogByVINDetail();
    }
    else {
      this.OfferDateBindng = "";
      this.OfferCommentng = "";
      this.OfferAmountng = "0.00";
      this.offerreadonly = false;
    }

    if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }
    this.SelectionRadiobt = 0;
    this.Isaccept = 1;
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  GetDealerInformationByVin(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.GetDealerInformationbyVinList(model)
      .subscribe(
        (data: any) => {

          this.DvDealerName = data[0].dealerName;
          this.DvDealerAddress = data[0].dealerAddress;
          this.DvDealerContactName = data[0].dealerContactName;
          this.DvDealerContactPhoneNumber = data[0].dealerPhone;
          this.DvDealerContactEmail = data[0].dealerEmail;

        },
        error => {
        });
  }
  GetVehiclePhotosListByVinList(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {

          this.customimagesrc = [];
          this.vehicleObject = [];
          if (data.length > 0) {
            this.errorshow = 'false';
            this.errorshowAuto = 'false';
            for (var i = 0; i < data.length; i++) {
              if (data[i].imageStatus == "1") {
                //this.vehicleObject=[];
                this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: '' })
              } else {
                this.customimagesrc.push({ imgsrc: data[i].imagesrc })
              }
            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }
          if (this.vehicleObject.length == 0) {
            this.errorshowAuto = 'true';
            this.errorMessageAuto = "No images for this Vehicle.";
          }

          if (this.customimagesrc.length == 0) {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }

  RefreshImageList() {
    this.vehicleObject = [];
  }

  checkUncheckAll(evt) {
    this.dealerVehicleslist.forEach((c) => c.isSelected = evt.target.checked)
  }



  GetInventoryHubwholesaleDashborads(opcode: any) {
    debugger;
    this.spinner.show();
    var modelGroup = {
      "Opcode": opcode,
      "FilterUnits": this.UnitFilterName,
      "DisplayCurrency": this.DisplayCurrency,
      "regionList": this.regionmultiselectarray,
      "LoggedInID": this.UserIDValue,
      "Pagenumber": this.page,
      "TotalPage": 20,
      "MakeFilter": this.SelectedAdvancemakeCheckboxArray,
      "StartPrice": this.Ngpriceranegmin,
      "EndPrice": this.Ngpriceranegmax,
      "BodyFilter": this.Selected_body_CheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray,
      "modellistdata": this.Selected_model_CheckboxArray,
      "trimllistdata": this.Selected_trim_CheckboxArray,
      "colorllistdata": this.Selected_color_CheckboxArray,
      "transmissionlistdata": this.Selected_transmission_CheckboxArray,
      "searchtext": this.searchText,
      "ColorTheme":this.ColorTheme

    }

    this.manageInventoryService.getInventoryHubwholesaleDashborads(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          if (data.length > 0) {
            this.dealerVehicleslist = data;
            this.totalpagecount = data[0].totalCount

            this.TotalRecord = data[0].totalCount;
            this.spinner.hide();
          } else {
            this.dealerVehicleslist = data;
            this.totalpagecount = 0;

            this.TotalRecord = 0;
            this.spinner.hide();
          }

        },
        error => {
        });
  }


  GetAIXZOfferLogByVINDetail() {
    this.OpcodeDetail = "1";
    var modelGroup = {
      "Opcode": this.OpcodeDetail,
      "VIN": this.VINLogCheck

    }

    this.manageInventoryService.getAIXZOfferLogByVINs(modelGroup)
      .subscribe(
        (data: any) => {


          //first time i am doing disable  false
          this.DecisionitemsRadiobutton.forEach(number => {

            number.disable = false;

          })

          this.ResponseComment = data[0].respondedComments;

          this.offerreadonly = true;
          this.primeid = data[0].id;
          let Offerdate = new Date(data[0].offerDateTime);
          this.OfferDateBindng = Offerdate.toDateString();
          this.OfferCommentng = data[0].offerComments;
          this.OfferAmountng = parseFloat(data[0].offerAmount).toFixed(2);

          if (data[0].counterOffer != undefined) {
            this.COunterOffer = parseFloat(data[0].counterOffer).toFixed(2);
          }
          else {
            this.COunterOffer = "0.00";
          }
          this.COunterComment = data[0].counterComments;

          if (data[0].updatedDateTime != undefined) {
            let Counterdate = new Date(data[0].updatedDateTime);
            this.COunterDate = Counterdate.toDateString();
            this.IsCOunterDateVisible = true;
          }


          //read action type and disabl rest radio button

          if (data[0].actionType == 100) //If Counter Offer pass in 100,
          {
            this.SelectionRadiobt = 100;

            this.newOffer = true;

            this.selectedValueDecisionRadioBT = 'Counter Offer';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Counter Offer") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          if (data[0].actionType == 111) //If Only Response Comments = 111
          {
            this.SelectionRadiobt = 111;

            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Response Only';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Response Only") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

          }
          if (data[0].actionType == 999) //and If Accepted/rejeced pass in 999--- ELSE Zero
          {
            this.SelectionRadiobt = 999;
            this.newOffer = true;
            this.selectedValueDecisionRadioBT = 'Decision';



            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Decision") {
                number.disable = false;
              }
              else {
                number.disable = true;
              }
            })

            if (data[0].isAccepted == 1) {
              this.Isaccepted_Checked = 'Accept';
              this.Isaccept = 1;
              this.AccpetComment = data[0].acceptComments;
            }
            if (data[0].isAccepted == 99) {
              this.Isaccepted_Checked = 'Decline';
              this.Isaccept = 99;
              this.RejectReason = data[0].reasonId;
              this.AccpetComment = data[0].acceptComments;
            }
          }

        },
        error => {
        });
  }

  clickRegionButton() {

    this.regionbuttonToBeClicked.nativeElement.click();
  }
  // for REgion Fiters
  ClearRegion(event) {
    this.regionbuttonToBeClicked.nativeElement.click();
  }
  CheckRegionClick() {

    this.regionlistName.forEach(obj => {

      obj.IsSelected = false;

    });

    this.regionmultiselectarray.forEach(childObj => {

      var b = this.regionlistName.find(e => e.globalCodeID == childObj.globalCodeID)
      b.IsSelected = true;

    });
    this.regionbuttonToBeClicked.nativeElement.click();
  }

  Checkallregions(e) {

    this.GroupRegionCheckboxArray = [];
    if (e.target.checked == true) {


      // for Three check boxes
      this.groupRegionWestern = true;
      this.groupRegionCentral = true;
      this.groupRegionEastern = true;
      this.groupRegionother = true;
      this.groupRegionOntario = true;

      //assigning groupregion
      var regionglobalvalue1 = {
        globalid: 1
      }
      var regionglobalvalue2 = {
        globalid: 2
      }
      var regionglobalvalue3 = {
        globalid: 3
      }
      var regionglobalvalue4 = {
        globalid: 4
      }

      var regionglobalvalue5 = {
        globalid: 5
      }
      this.GroupRegionCheckboxArray.push(regionglobalvalue1);
      this.GroupRegionCheckboxArray.push(regionglobalvalue2);
      this.GroupRegionCheckboxArray.push(regionglobalvalue3);
      this.GroupRegionCheckboxArray.push(regionglobalvalue4);
      this.GroupRegionCheckboxArray.push(regionglobalvalue5);


      //Assigning value selected false which will appera by deafult uncheck
      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.groupRegionWestern = false;
      this.groupRegionCentral = false;
      this.groupRegionEastern = false;
      this.groupRegionother = false;

      this.groupRegionOntario = false;



      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = false
      })
    }

  }

  regioncheckboxpopup(template: TemplateRef<any>, itemid: any) {
    this.ItemCountregion = 0;
    for (let i = 0; i < this.regionlistName.length; i++) {
      if (this.regionlistName[i].IsSelected == true) {
        this.ItemCountregion = this.ItemCountregion + 1;
      }
    }
    this.ExtingItemCountregion = this.regionlistName.length;
    if (this.ExtingItemCountregion == this.ItemCountregion) {
      this.SelectedAllCheckregion = true;
    } else {
      this.SelectedAllCheckregion = false;
    }
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  GetselectedRegions() {

    // functino for getting units

    this.regionmultiselectarray = this.getSelectedregions();
    var selecteditem = this.getSelectedregions();
    this.declineregion();

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue = localStorage.getItem('userid');
    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
    // if (this.rolevalue == "SuperAdmin") {
    //   this.UserIDValue =localStorage.getItem('userid');
    //   this.GetInventoryHubwholesaleDashborads("3");

    // } else {
    //   this.UserIDValue ="All";
    //   this.GetInventoryHubwholesaleDashborads("3");
    // }

  }

  getSelectedregions() {
    return this.regionlistName.filter(item => item.IsSelected === true);
  }


  //selected
  getSelectedMake() {
    return this.AdvancemakeCheckboxArray.filter(item => item.IsMakeselected === true);
  }

  //selected year
  getSelected_Year() {
    return this.AdvanceYearCheckboxArray.filter(item => item.IsYearselected === true);
  }


  getSelectedbody() {
    return this.AdvancebodyCheckboxArray.filter(item => item.IsSelected === true);
  }

  SearchAdvance() {

    this.declineAdvanceFilter();
    var minamount = this.Ngpriceranegmin
    var minamount = this.Ngpriceranegmax

    this.SelectedAdvancemakeCheckboxArray = this.getSelectedMake();

    this.SelectedAdvancebodyCheckboxArray = this.getSelectedbody();

    // call the function for Getting Result

    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
  }


  ResetFilter() {
    debugger;
    this.SelectedAdvancemakeCheckboxArray = [];
    this.SelectedAdvancebodyCheckboxArray = [];
    this.AdvancebodyCheckboxArray = [];

    this.SelectedAdvancemakeCheckboxArray = [];
    this.Ngpriceranegmin = "0";
    this.Ngpriceranegmax = "0";
    this.Selected_body_CheckboxArray = [];
    this.SelectedAdvanceYearCheckboxArray = [];
    this.Selected_Advancemilege_CheckboxArray = [];
    this.Selected_model_CheckboxArray = [];
    this.Selected_trim_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];

    this.decline();
    this.searchText = "";
    // call ResetFilter the function for Getting Result
    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
  }


  Getmakecheckbox(event, itemmake: any) {
    var minamount = this.Ngpriceranegmin
    var minamount = this.Ngpriceranegmax

    if (event.target.checked == true) {

      var data = this.AdvancemakeCheckboxArray.filter(item => item.make === itemmake.make).forEach(data => {
        data.IsMakeselected = true;
      }
      );


    }
    else {
      var data = this.AdvancemakeCheckboxArray.filter(item => item.make === itemmake.make).forEach(data => {
        data.IsMakeselected = false;
      }
      );
    }


    // var Allismakeselectedarray= this.AdvancemakeCheckboxArray.filter(item => item.IsMakeselected === true);

    //refresh all array

    this.SelectedAdvanceYearCheckboxArray = [];
    this.Selected_Advancemilege_CheckboxArray = [];
    this.Selected_model_CheckboxArray = [];
    this.Selected_trim_CheckboxArray = [];
    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];

    this.AdvanceYearCheckboxArray = [];
    this.Advance_Mileage_CheckboxArray = [];
    this.Advance_Model_CheckboxArray = [];
    this.Advance_trim_CheckboxArray = [];
    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];

    //



    this.SelectedAdvancemakeCheckboxArray = this.getSelectedMake();

    var bodayarray = this.AdvancebodyCheckboxArray;

    this.Get_YearBymakeforadvancefilter();

    //  this.AdvancemakeCheckboxArray.filter(item => item.IsSelected === true);
  }

  //for year
  SelectyYearcheckbox(event, itemyear: any) {


    if (event.target.checked == true) {

      var data = this.AdvanceYearCheckboxArray.filter(item => item.year === itemyear.year).forEach(data => {
        data.IsYearselected = true;
      }
      );


    }
    else {
      var data = this.AdvanceYearCheckboxArray.filter(item => item.year === itemyear.year).forEach(data => {
        data.IsYearselected = false;
      }
      );
    }



    //refresh all array


    this.Selected_Advancemilege_CheckboxArray = [];
    this.Selected_model_CheckboxArray = [];
    this.Selected_trim_CheckboxArray = [];
    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];


    this.Advance_Mileage_CheckboxArray = [];
    this.Advance_Model_CheckboxArray = [];
    this.Advance_trim_CheckboxArray = [];
    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];

    //



    this.SelectedAdvanceYearCheckboxArray = this.getSelected_Year();

    this.Get__Milege_By_yearforadvancefilter();

  }


  //for year
  Selecty_milege_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_Mileage_CheckboxArray.filter(item => item.mileage === item_checkbox.mileage).forEach(data => {
        data.IsMilegeselected = true;
      }
      );


    }
    else {
      var data = this.Advance_Mileage_CheckboxArray.filter(item => item.mileage === item_checkbox.mileage).forEach(data => {
        data.IsMilegeselected = false;
      }
      );
    }


    //refresh all array



    this.Selected_model_CheckboxArray = [];
    this.Selected_trim_CheckboxArray = [];
    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];

    this.Advance_Model_CheckboxArray = [];
    this.Advance_trim_CheckboxArray = [];
    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];

    //



    this.Selected_Advancemilege_CheckboxArray = this.Advance_Mileage_CheckboxArray.filter(item => item.IsMilegeselected === true);

    this.Get__Model_By_Milege_foradvancefilter();

  }




  //for year
  Selecty_trim_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_trim_CheckboxArray.filter(item => item.trim === item_checkbox.trim).forEach(data => {
        data.IsTrimselected = true;
      }
      );


    }
    else {
      var data = this.Advance_trim_CheckboxArray.filter(item => item.trim === item_checkbox.trim).forEach(data => {
        data.IsTrimselected = false;
      }
      );
    }




    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];




    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];


    this.Selected_trim_CheckboxArray = this.Advance_trim_CheckboxArray.filter(item => item.IsTrimselected === true);

    this.Get__body_By_trim_foradvancefilter();

  }



  //for year
  Selecty_body_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_body_CheckboxArray.filter(item => item.body === item_checkbox.body).forEach(data => {
        data.IsBodyselected = true;
      }
      );


    }
    else {
      var data = this.Advance_body_CheckboxArray.filter(item => item.body === item_checkbox.body).forEach(data => {
        data.IsBodyselected = false;
      }
      );
    }



    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];





    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];


    this.Selected_body_CheckboxArray = this.Advance_body_CheckboxArray.filter(item => item.IsBodyselected === true);

    this.Get__color_By_body_foradvancefilter();

  }





  //for year
  Selecty_model_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_Model_CheckboxArray.filter(item => item.model === item_checkbox.model).forEach(data => {
        data.IsModelselected = true;
      }
      );


    }
    else {
      var data = this.Advance_Model_CheckboxArray.filter(item => item.model === item_checkbox.model).forEach(data => {
        data.IsModelselected = false;
      }
      );
    }

    //refresh all array




    this.Selected_trim_CheckboxArray = [];
    this.Selected_body_CheckboxArray = [];
    this.Selected_color_CheckboxArray = [];
    this.Selected_transmission_CheckboxArray = [];



    this.Advance_trim_CheckboxArray = [];
    this.Advance_body_CheckboxArray = [];
    this.Advance_color_CheckboxArray = [];

    this.Advance_transmission_CheckboxArray = [];

    //



    this.Selected_model_CheckboxArray = this.Advance_Model_CheckboxArray.filter(item => item.IsModelselected === true);

    this.Get__trim_By_Model_foradvancefilter();

  }




  //for year
  Selecty_color_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_color_CheckboxArray.filter(item => item.colour === item_checkbox.colour).forEach(data => {
        data.IsColorselected = true;
      }
      );


    }
    else {
      var data = this.Advance_color_CheckboxArray.filter(item => item.colour === item_checkbox.colour).forEach(data => {
        data.IsColorselected = false;
      }
      );
    }



    this.Selected_transmission_CheckboxArray = [];



    this.Advance_transmission_CheckboxArray = [];

    this.Selected_color_CheckboxArray = this.Advance_color_CheckboxArray.filter(item => item.IsColorselected === true);

    this.Get__Transmission_By_color_foradvancefilter();

  }


  //select transmission
  //for year
  Selecty_transmission_checkbox(event, item_checkbox: any) {

    if (event.target.checked == true) {

      var data = this.Advance_transmission_CheckboxArray.filter(item => item.transmission === item_checkbox.transmission).forEach(data => {
        data.Transmissionselected = true;
      }
      );


    }
    else {
      var data = this.Advance_transmission_CheckboxArray.filter(item => item.transmission === item_checkbox.transmission).forEach(data => {
        data.Transmissionselected = false;
      }
      );
    }





    this.Selected_transmission_CheckboxArray = this.Advance_transmission_CheckboxArray.filter(item => item.Transmissionselected === true);


  }




  //get Milege

  Get__Transmission_By_color_foradvancefilter() {

    var modelGroup = {
      "opcode": "10",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray,
      "modellistdata": this.Selected_model_CheckboxArray,
      "trimllistdata": this.Selected_trim_CheckboxArray,
      "bodyllistdata": this.Selected_body_CheckboxArray,
      "colorllistdata": this.Selected_color_CheckboxArray,
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_transmission_CheckboxArray = data;

        },
        error => {
        });


  }





  //get Milege

  Get__color_By_body_foradvancefilter() {

    var modelGroup = {
      "opcode": "9",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray,
      "modellistdata": this.Selected_model_CheckboxArray,
      "trimllistdata": this.Selected_trim_CheckboxArray,
      "bodyllistdata": this.Selected_body_CheckboxArray,
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_color_CheckboxArray = data;

        },
        error => {
        });


  }



  //get Milege

  Get__body_By_trim_foradvancefilter() {

    var modelGroup = {
      "opcode": "8",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray,
      "modellistdata": this.Selected_model_CheckboxArray,
      "trimllistdata": this.Selected_trim_CheckboxArray
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_body_CheckboxArray = data;

        },
        error => {
        });


  }





  //get trim

  Get__trim_By_Model_foradvancefilter() {

    var modelGroup = {
      "opcode": "7",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray,
      "modellistdata": this.Selected_model_CheckboxArray,
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_trim_CheckboxArray = data;

        },
        error => {
        });


  }




  //get model

  Get__Model_By_Milege_foradvancefilter() {

    var modelGroup = {
      "opcode": "6",
      "makelistdata": this.SelectedAdvancemakeCheckboxArray,
      "Yearlistdata": this.SelectedAdvanceYearCheckboxArray,
      "milegelistdata": this.Selected_Advancemilege_CheckboxArray
    }

    this.manageInventoryService.getmakforadvance(modelGroup)
      .subscribe(
        data => {

          //this.AdvancebodyCheckboxArray=data;

          this.Advance_Model_CheckboxArray = data;

        },
        error => {
        });


  }


  GetProvinceMuiltiSelectlist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {

          //this.Provincelist = data;
          this.GroupRegionCheckboxArray = [];
          this.regionlistName = []; //initialize

          //Assigning value selected false which will appera by deafult uncheck
          this.regionlistName.forEach((item, index) => {
            this.regionlistName[index].IsSelected = true
          })

          this.regionlistName = data;
          this.regionmultiselectarray = this.regionlistName.filter(item => item.globalCodeID == this.province);
          // var selectedchckbox = this.regionlistName.filter(item => item.globalCodeID == this.province);
          var selectedchckbox = this.regionlistName;
          selectedchckbox.forEach((itemcheck, index) => {
            this.regionlistName[index].IsSelected = true


          })

          this.groupRegionWestern = true;
          this.groupRegionCentral = true;
          this.groupRegionEastern = true;
          this.groupRegionother = true;
          this.groupRegionOntario = true;

          var regionglobalvalue1 = {
            globalid: 1
          }
          var regionglobalvalue2 = {
            globalid: 2
          }
          var regionglobalvalue3 = {
            globalid: 3
          }
          var regionglobalvalue4 = {
            globalid: 4
          }
          var regionglobalvalue5 = {
            globalid: 5
          }
          this.GroupRegionCheckboxArray.push(regionglobalvalue1);
          this.GroupRegionCheckboxArray.push(regionglobalvalue2);
          this.GroupRegionCheckboxArray.push(regionglobalvalue3);
          this.GroupRegionCheckboxArray.push(regionglobalvalue4);
          this.GroupRegionCheckboxArray.push(regionglobalvalue5);
          if (data != null) {
            //if (data != null && data.length > 4) {
            // this.regionbuttonToBeClicked.nativeElement.click();

          }

        },
        error => {
        });
  }

  SelectedAccepteddecline(data: any) {

    if (data == "Accept") {
      this.Isaccept = 1;
    }
    if (data == "Decline") {
      this.Isaccept = 99;
    }

  }

  excitedimgUrl = "../../assets/images/excited/excited.png";
}
