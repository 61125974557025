import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
type AOA = any[][];
@Component({
  selector: 'app-inventoryimport-excel',
  templateUrl: './inventoryimport-excel.component.html',
  styleUrls: ['./inventoryimport-excel.component.less']
})
export class InventoryimportExcelComponent implements OnInit {
  ImportInventoryForm: FormGroup;
  private notifier: NotifierService;
  constructor(private inventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService,
    notifier: NotifierService) { 
      this.notifier = notifier;
    }
  data: AOA = [[], []];
  wopts: XLSX.WritingOptions = { bookType: 'xlsx', type: 'array' };
  fileName: string = 'SheetJS.xlsx';
  dataforserver: any[] = [];
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  onFileChange(evt: any) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.data = <AOA>(XLSX.utils.sheet_to_json(ws, { header: 1 }));
      console.log("data:", this.data);
      this.data.map(res => {
        if (res[0] === "no") {
          console.log(res[0]);
        } else {
          console.log(res[0]);
        }
      })
    };
    reader.readAsBinaryString(target.files[0]);
  }


  export(): void {
    /* generate worksheet */
    const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(this.data);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }


  Createform() {
    this.ImportInventoryForm = new FormGroup({

    });
  }

  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  Postexcel() {
    this.spinner.show();
    this.dataforserver = [];
    //var dataforserver=[];
    this.data.forEach((currentValue, index) => {

      if (index != 0) {
        var createdata =
        {
          'VIN': currentValue[0],
          'StockNumber': currentValue[1],
          'CarYr': currentValue[2],
          'Mileage': currentValue[3],
          'Make': currentValue[4],
          'Model': currentValue[5],
          'Trim': currentValue[6],
          'Body': currentValue[7],
          'Colour': currentValue[8],
          'VehicleInfo': currentValue[9],
          'ListedPrice': currentValue[10],
          'DealerCost': currentValue[11],
          'Condition': currentValue[12],
          'CBBValA': currentValue[13],

          'CBBValC': currentValue[14],
          'CBBValR': currentValue[15],
          'CBBValX': currentValue[16],
          'DateReceived': currentValue[17],
          'GroupName': currentValue[18],
          'DealerName': currentValue[19],
          'CBBPullDate': currentValue[20],
          'DriveTrain': currentValue[21],
          'Options': currentValue[22],
          'SeatCapacity': currentValue[23],
          'Style': currentValue[24],
          'Transmission': currentValue[25],
          'Status': currentValue[26],
          'JDPowerAppVehicle': currentValue[27],
          'IsActive': currentValue[28],
          'EngineFuelType': currentValue[29],
        }

        this.dataforserver.push(createdata);
      }
    }
    );
    debugger;
    //posting data to server
    var fromdata = {
      "Exceldata": this.dataforserver
    }

    this.inventoryService.Postexceldata(this.dataforserver)
      .subscribe(
        data => {
          this.spinner.hide();
          this.dataforserver =[];
          this.data =[];

          this.showNotification("success", "Excel Imported successfully");
        },
        error => {
        });

  }
}
