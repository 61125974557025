<link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css">
<section class="logoin-section">
    <div class="container">
        <div class="col-xl-9 col-lg-10 col-12 mx-auto">
        <div class="row align-items-center justify-content-center h-100">
            <div class="col-md-6 col-xl-6 p-0">
                <div class="car-wapper"></div>
            </div>
            <div class="col-md-6 col-xl-6 p-0">
                <div class="card text-center form-info">
                    <div class="card-body">
                        <h4 class="text-danger font-weight-light">Welcome to</h4>
                        <div class="col loginlogo">
                            <a class="navbar-brand"  routerLink="/">
                                <img class="img-fluid" style="width:150px;" src="../../assets/images/loginImage.png"></a><br>
                        </div>
                        <hr>
            
                        <form class="loginForm" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="input-group mb-3">
                                <!-- <span class="input-group-text bg-primary">
                                            <i class="fa fa-user text-white" aria-hidden="true" style="font-size:15px;"></i>
                                        </span> -->
                                <input type="text" formControlName="email" placeholder="Email" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.errors.required">Email is required</div>
            
                                </div>
                                <div *ngIf="show_userAccount" style="color:#dc3545;font-size:.875em;">
                                    Please enter a registered email address
                                </div>
                            </div>
                            <div class="input-group mb-3">
                                <!-- <span class="input-group-text bg-primary">
                                            <i class="fa fa-lock text-white" aria-hidden="true" style="font-size:15px;"></i>
                                        </span> -->
                                <input [type]="password" formControlName="password" placeholder="Password"
                                    style="border-top-right-radius:13px;border-bottom-right-radius:13px;" class="form-control"
                                    [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
            
                                <button (click)="onClick()" id="eye" type="button">
                                    <i class="fa fa-eye" aria-hidden="true" *ngIf="!show"></i>
                                    <i class="fa fa-eye-slash" aria-hidden="true" *ngIf="show"></i>
                                </button>
            
                                <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                    <div *ngIf="f.password.errors.required">Password is required</div>
                                </div>
                            </div>
                            <div class="form-group mt-2">
                                <div class="row mx-0 justify-content-between w-100">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                        <label class="form-check-label" for="exampleCheck1">Remember</label>
                                    </div>
                                    <a routerLink="/Forgot-Password" class="btn-link text-danger col-auto">Forgot password?</a>
                                </div>
                            </div>
            
                            <div class="form-group btn-group col-12 px-0 mt-4">
                                <div class="w-100">
                                    <button [disabled]="loading" class="btn btn-danger w-100 d-block">
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>
                                        Login
                                    </button>
            
                                    <!-- <a routerLink="../register" class="btn-link col-auto">Register</a> -->
                                </div>
                            </div>
            
                            <ng-template #aggrepdf>
                                <div id="pdf-viewer-outer" class="modal-body text-center">
                                    <h4>Agreementpdf </h4>
            
                                    <pdf-viewer [src]="pdfSource" style="width: 100%;height: 500px"></pdf-viewer>
            
            
                                    <footer>
                                        <button type="button" class="btn btn-success mr-2" (click)="confirmDocument()">Yes</button>
                                        <button type="button" class="btn btn-danger" (click)="decline()">No</button>
                                    </footer>
                                </div>
                            </ng-template>
            
            
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    


        <button #aggrepdfbuttonToBeClicked style="display:none;" (click)="openModal(aggrepdf,'')"></button>


      

      
        <!-- <pdf-viewer [src]="pdfSource" [original-size]="false"></pdf-viewer> -->
    </div>
</section>