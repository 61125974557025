import { Component, OnInit, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FilterPipeDataPipe } from '../../app/filters/filter-pipe-data.pipe';
import {NgSelectModule, NgOption} from '@ng-select/ng-select';

@Component({
  selector: 'app-list-view-vehicles',
  templateUrl: './list-view-vehicles.component.html',
  styleUrls: ['./list-view-vehicles.component.less']
})
export class ListViewVehiclesComponent implements OnInit {

  modalRef: BsModalRef;


  vehicleSlideV1: Array<object> = [{
    image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
    thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
    // alt: 'alt of image',
    // title: 'title of image'
}, {
    image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-25.jpg',
    thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-25.jpg',
    order: 1
},
{
  image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  order: 2
}
,
{
  image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
  thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-1.jpg',
  order: 3
}
];


vehicleSlideV2: Array<object> = [{
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-2.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-2.jpg',
  // alt: 'alt of image',
  // title: 'title of image'
}, {
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-3.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-3.jpg',
  order: 1
},
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-4.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-4.jpg',
order: 2
}
,
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-5.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-5.jpg',
order: 3
}
];

vehicleSlideV3: Array<object> = [{
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-6.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-6.jpg',
  // alt: 'alt of image',
  // title: 'title of image'
}, {
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-7.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-7.jpg',
  order: 1
},
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-8.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-8.jpg',
order: 2
}
,
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-9.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-9.jpg',
order: 3
}
];

vehicleSlideV4: Array<object> = [{
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-9.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-9.jpg',
  // alt: 'alt of image',
  // title: 'title of image'
}, {
  image: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  thumbImage: 'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-10.jpg',
  order: 1
},
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-12.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-12.jpg',
order: 2
}
,
{
image:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-21.jpg',
thumbImage:'https://www.drivco-wp.egenslab.com/wp-content/uploads/2023/09/vehicle-21.jpg',
order: 3
}
];

cities = [
  {id: 1, name: 'Vilnius'},
  {id: 2, name: 'Kaunas'},
  {id: 3, name: 'Pavilnys'},
  {id: 4, name: 'Pabradė'},
  {id: 5, name: 'Klaipėda'}
];

selectedCity: any;
selectedCityIds: string[];
selectedCityName = 'Vilnius';
selectedCityId: number;
selectedUserIds: number[];

selectedValue: number = 50;

onInputChange() {
  // Handle value change logic here if needed
  // For example, you can perform some action when the value changes
  console.log('Selected value changed to: ', this.selectedValue);
}


constructor(private confirmModalService: BsModalService) { }

openActivatelocationModel(template: TemplateRef<any>) {
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
}

openActivatefiltersModel(template: TemplateRef<any>) {
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
}


CloseAddmodel() {
  this.modalRef.hide();
}


closeEditModel() {
  this.modalRef.hide();
}








  ngOnInit(): void {


  }




}
