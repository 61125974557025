import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Color, Label, SingleDataSet } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-manage-hubinventory',
  templateUrl: './manage-hubinventory.component.html',
  styleUrls: ['./manage-hubinventory.component.less']
})
export class ManageHubinventoryComponent implements OnInit {
  managerInventoryForm: FormGroup;
  private notifier: NotifierService;
  @ViewChild('dealercheckboxpopupbuttonToBeClicked') buttonToBeClicked: ElementRef
  modalRef: BsModalRef;
  constructor(private fb: FormBuilder,
    private confirmModalService: BsModalService,
    private manageInventoryService: ManageInventoryService,
    private globalcodeService: GlobalcodeService, private addgroupService: AddgroupService,
    private spinner: NgxSpinnerService, private modalService: NgbModal,
    notifier: NotifierService) {
    this.notifier = notifier;
  }

  selectedGroupIds: string;
  selectedGroupId: any;
  totalRemoved: any;
  totalhub: any;
  unitsList = [];
  DealerList = [];

  removeunitsList = [];

  unitsListValues = [];
  removeunitsListValues = [];
  OpCodeFinal: any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  opcode: any;
  isDesc: boolean = false;
  column: string = 'vin';
  TotalRecord: any;
  pageSizes = [ 20, 30, 40, 50, 60, 70];
  page = 1;
  pageSize = 20;
  dealersmultiselectarray: any[];
  pageSizesRemoved = [20, 30, 40, 50, 60, 70];
  pageRemoved = 1;
  pageSizeRemoved = 20;
  groupIdPage: any;
  SelectedGroupName: any;
  dealerlistName = [];

  dealerlistForsearch = [];
  dealerName:any;
  groupDisable: any;
  selectedDealerIds: string[];
  selectedgroupId: any;
  selectedDealerId: any;
  savegruopId:any;

  ItemCount:any=0;
  SelectedAllCheck:any=false;
  ExtingItemCount:any;



  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];

  SelectSearchDealer:any;

  colors: Color[] = [
    {
      backgroundColor: [
        '#17a2b8',
        '#007bff',
        'blue'
      ]
    }
  ];


  SerachDealer(event)
  {


var serachdelaerid=this.SelectSearchDealer;

this.GetDealers(serachdelaerid.dGroupId);


var groupid=this.GroupLists;

var groupname=this.GroupLists.filter(i=>i.globalCodeID==event.dGroupId)[0];

this.selectedGroupIds=groupname;


  }

  sortAithrHub(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.unitsList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
    this.GethubUnitList();
  }
  getValues(val: any) {

    this.selectedDealerId = val.id;
    this.savegruopId = val.dGroupId;
  }
  GetDealerNamebyGroupId(value: any) {
    this.dealerlistName = [];
    this.selectedDealerIds = [];
    this.selectedGroupId = value.globalCodeID;
    this.SelectedGroupName = value.globalCodeName
    this.groupIdPage = value.globalCodeID;

    this.GetDealers(this.selectedGroupId);
  }



GetallDealerList()
{
 
  var modelGroup = {
  "Opcode": "1"
}
this.addgroupService.getAlldealerListForSearch(modelGroup)
  .subscribe(
    (data: any) => {

      this.dealerlistForsearch=[]
      if (data.length>0) {
       // this.dealerName = "";

        this.dealerlistForsearch=data
      }
      else {


      }
    }
  )


}



  GetDealers(groupId: any) {
    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {

          this.dealersmultiselectarray=[]
          if (data.length == 0) {
            this.dealerName = "";
           // this.selectedDealerIds = [];
            this.dealersmultiselectarray=[]
          } else {
           // this.selectedDealerIds = this.dealerlistName;
           //this.dealerlistName=data;

          }

          this.dealerlistName = data;

          if(this.dealerlistName.length > 1)
          {
            if (this.rolevalue == "SuperAdmin") {
              this.buttonToBeClicked.nativeElement.click();
            } else {

            }

          }
          else{
            this.dealersmultiselectarray=this.dealerlistName;
          }


        },
        error => {
        });


  }


  Dealercheckboxpopup(template: TemplateRef<any>, itemid: any) {

    this.ItemCount =0;
    for (let i = 0; i < this.dealerlistName.length; i++) {
      if (this.dealerlistName[i].IsSelected == true) {
        this.ItemCount = this.ItemCount + 1;
      }
    }
    this.ExtingItemCount = this.dealerlistName.length;
    if (this.ExtingItemCount == this.ItemCount) {
        this.SelectedAllCheck =true;
    } else {
      this.SelectedAllCheck =false;
    }

    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  decline(): void {
    this.modalRef.hide();
  }

  declineDealer(): void {
    this.confirmModalService.hide(1);
  }

   // get list of selected Items
   getSelectedItem() {
    return this.dealerlistName.filter(item => item.IsSelected === true);
  }

  GetselectedDealer() {
    this.dealersmultiselectarray = this.getSelectedItem();
    var selecteditem = this.getSelectedItem();
    this.declineDealer();
  }

   //checkall dealer
   Checkalldealer(e) {

    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.dealerlistName.forEach((item, index) => {
        this.dealerlistName[index].IsSelected = false
      })
    }

  }

  ClearDealer(event) {
    if(this.dealerlistName.length > 1)
    {
      this.buttonToBeClicked.nativeElement.click();
    }
    //this.buttonToBeClicked.nativeElement.click();
  }
  CheckClick()
  {

   var check= this.dealersmultiselectarray;

   this.dealerlistName.forEach(obj=> {

    obj.IsSelected = false;

 });

   this.dealersmultiselectarray.forEach(childObj=> {

      var b = this.dealerlistName.find(e => e.id == childObj.id)
      b.IsSelected = true;

   });


    if(this.dealerlistName.length > 1)
    {
      this.buttonToBeClicked.nativeElement.click();
    }

  }



  sortRemovedHub(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.removeunitsList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  DealerNameFilter: any;
  searchRemovedText;
  handleRemovedPageSizeChange(event) {
    this.pageSizeRemoved = event.target.value;
    this.pageRemoved = 1;
    this.GethubUnitList();
  }
  TotalUnitInHUbCount:any;
  RemovedUnitCount:any;
  UserIDValue:any;
  selectRemoveAllCheckbox = false;
  selectIncludeAllCheckbox = false;
  GroupLists = [];
  hideDealerControlsButton:any;
  hideSupControlsButton:any;
  groupidvalue:any;
  isFavorite: boolean = true;
  ColorTheme:any;
  // ngOnDestroy() {
  //   let body = document.getElementsByTagName('body')[0];
  //   body.classList.remove("body-zoomout");
  // }
  ngOnInit(): void {
    // let body = document.getElementsByTagName('body')[0];
    // body.classList.add('body-zoomout');
    this.Createform();
    this.GetGroupList();
    this.totalRemoved = 0;
    this.totalhub = 0;

    this.TotalUnitInHUbCount = "0";
    this.RemovedUnitCount = "0";
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue =localStorage.getItem('userid');
    this.GeneratePiChart();
    this.groupidvalue = localStorage.getItem('groupid');
    this.selectedGroupIds =this.GroupNamevalue;
    this.GetDealers( this.groupidvalue);
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    if (this.rolevalue == "SuperAdmin") {
      this.opcode = "1";
      this.hideSupControlsButton="true";
      this.hideDealerControlsButton="false";
      this.GetDealerNameFilterlist();

    } else {
      this.opcode = "2";
      this.DealerNameFilter=this.DealerIdvalue ;
      this.GetDealerNameFilterlist();
      this.hideDealerControlsButton="true";
      this.hideSupControlsButton="false";
      this.GethubUnitList();
    }

    this.GetallDealerList();


  }
  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },

    };
  }
  GeneratePiChart()
  {
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = ['Hub Unit', 'Removed Unit'];
    this.pieChartData = [this.TotalUnitInHUbCount , this.RemovedUnitCount];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;
  }

  GetGroupList() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {
          this.GroupLists = data;
        },
        error => {
        });
  }
  Createform() {
    this.managerInventoryForm = new FormGroup({
      DealerNameFilter: this.fb.control('', Validators.required),

    });
  }


  GetDealerNameFilterlist() {
    var modelGroup = {
      "Opcode": this.opcode,
      "DealerName": this.DealerNameFilter,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getDealerNameFilterlist(modelGroup)
      .subscribe(
        (data: any) => {
          this.DealerList = data;
        },
        error => {
        });
  }
  GethubUnitList() {

    if (this.rolevalue == "SuperAdmin") {
      this.opcode = "1";
      this.GetManageHubUnitsList();
      this.GetManageRemoveUnitsList();

    } else {
      this.opcode = "2";
      this.GetManageHubUnitsList();
      this.GetManageRemoveUnitsList();
    }


  }
  GetManageHubUnitsList() {
    this.spinner.show();
    this.isFavorite = false;
    var modelGroup = {
      "Opcode": this.opcode,
      // "Dealerslist": this.selectedDealerIds,
      "Dealerslist": this.dealersmultiselectarray,
      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getManageHubUnitsList(modelGroup)
      .subscribe(
        (data: any) => {
          this.spinner.hide();
          this.isFavorite = true;
          this.totalhub = data.length;
          this.unitsList = data;
          this.unitsList.forEach((item, index) => {
            this.unitsList[index].IsIncludeSelected = false
          })
          this.unitsList = data;

          this.TotalUnitInHUbCount= data.length;

          this.GeneratePiChart();
        },
        error => {
        });
  }




  toggleIncludeSelectAll(e) {
    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.unitsList.forEach((item, index) => {
        this.unitsList[index].IsIncludeSelected = true;
        this.unitsListValues.push(item);
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.unitsList.forEach((item, index) => {
        this.unitsList[index].IsIncludeSelected = false;
        this.unitsListValues = [];
      })
    }

  }


  MoveUnitsToHub() {
   

    if(this.removeunitsListValues.length > 0)
    {
      this.OpCodeFinal = "1";
      var modelGroup = {
        "itemVehiclesList": this.removeunitsListValues,
        "Opcode": this.OpCodeFinal
      }

      this.manageInventoryService.addRemovedUnitTohub(modelGroup)
        .subscribe(
          (data: any) => {

            this.showNotification("success", "Units moved to AithrHub successfully.");
            this.removeunitsListValues=[];
            this.unitsListValues=[];
            this.GethubUnitList();
          },
          error => {
          });
    } else{
      this.showNotification("error", "Please select atleast one unit.");
      return false;
    }
    // this.OpCodeFinal = "1";
    // var modelGroup = {
    //   "itemVehiclesList": this.removeunitsListValues,
    //   "Opcode": this.OpCodeFinal
    // }

    // this.manageInventoryService.addRemovedUnitTohub(modelGroup)
    //   .subscribe(
    //     (data: any) => {

    //       this.showNotification("success", "Units moved to AithrHub successfully.");
    //       this.removeunitsListValues=[];
    //       this.unitsListValues=[];
    //       this.GethubUnitList();
    //     },
    //     error => {
    //     });
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  RemoveUnitsFromHub() {

    if(this.unitsListValues.length>0)
    {
      this.OpCodeFinal = "2";
      var modelGroup = {
        "itemVehiclesList": this.unitsListValues,
        "Opcode": this.OpCodeFinal
      }

      this.manageInventoryService.addRemovedUnitTohub(modelGroup)
        .subscribe(
          (data: any) => {
            this.showNotification("success", "Units removed from AithrHub successfully.");
            this.removeunitsListValues=[];
            this.unitsListValues=[];
            this.GethubUnitList();
          },
          error => {
          });
    } else{
      this.showNotification("error", "Please select atleast one unit.");
      return false;
    }

  }
  onIncludeheckboxChange(includeUnits: any) {
    if (includeUnits.IsIncludeSelected) {
      //this.unitsList.push(includeUnits);
      this.unitsListValues.push(includeUnits);
    } else {
      const index = this.unitsList.findIndex(d => d.id === includeUnits.id);
      if (index > -1) {
        // this.unitsList.splice(index, 1);
        this.unitsListValues.splice(index, 1);
      }
    }
  }

  toggleRemoveSelectAll(e) {
    if (e.target.checked == true) {
      //Assigning value selected false which will appera by deafult uncheck
      this.removeunitsList.forEach((item, index) => {
        this.removeunitsList[index].IsRemovedSelected = true;
        this.removeunitsListValues.push(item);
      });

    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.removeunitsList.forEach((item, index) => {
        this.removeunitsList[index].IsRemovedSelected = false;
        this.removeunitsListValues = [];
        // const indexRemove = this.removeunitsList.findIndex(d => d.id === item.id);
        // if (index > -1) {
        //   // this.removeunitsList.splice(index, 1);
        //   this.removeunitsListValues.splice(indexRemove, 1);
        // }
      });
    }
  }

  onRemovedheckboxChange(removedUnits: any) {
    if (removedUnits.IsRemovedSelected) {
      // this.removeunitsList.push(removedUnits);
      this.removeunitsListValues.push(removedUnits);
    } else {
      const index = this.removeunitsList.findIndex(d => d.id === removedUnits.id);
      if (index > -1) {
        // this.removeunitsList.splice(index, 1);
        this.removeunitsListValues.splice(index, 1);
      }
    }
  }
  GetDealerValue(event: any) {
    var lendervalue = event.target.value;
    this.DealerNameFilter = lendervalue;

    this.GethubUnitList();
  }

  GetManageRemoveUnitsList() {

    var modelGroup = {
      "Opcode": this.opcode,
      // "Dealerslist": this.selectedDealerIds,
      "Dealerslist": this.dealersmultiselectarray,

      "DealerId": this.DealerNameFilter
    }
    this.manageInventoryService.getManageRemoveUnitsList(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;
          this.totalRemoved = data.length;
          this.removeunitsList = data;
          this.removeunitsList.forEach((item, index) => {
            this.removeunitsList[index].IsRemovedSelected = false
          })
          this.removeunitsList = data;
          this.RemovedUnitCount= data.length;
          this.GeneratePiChart();
        },
        error => {
        });
  }


}
