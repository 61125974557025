import { Component, ElementRef, OnInit, TemplateRef, ViewChild, Injector, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';
import { AddrepoService } from '@app/_services/addrepo.service';
import { AddgroupService } from '../_services/addgroup.service';
import { ListDealersService } from '../_services/list-dealers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifierService } from 'angular-notifier';
import { DatePipe } from '@angular/common';

import { GlobalcodeService } from '../_services/globalcode.service';
import { DashboardService } from '../_services/dashboard.service';

import { DatepickerOptions } from 'ng2-datepicker';
import { getYear } from 'date-fns';
import locale from 'date-fns/locale/en-US';

import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable'
import html2canvas from 'html2canvas';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-global-code',
  templateUrl: './global-code.component.html',
  styleUrls: ['./global-code.component.less']
})
export class GlobalCodeComponent implements OnInit {

  Inititform: FormGroup;
  private notifier: NotifierService;

  private onChange: any = () => { }
  private onTouched: any = () => { }
  private value: string = '';

  date = new Date();


  ExcelShetData = 'ExcelSheet.xlsx';
  modalTitle = 'Add';
  EmailReadyOnly: boolean = false;
  // private ngControl: NgControl;

  @Input() Globalcodereceive: number = 0;


  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private addRepoService: AddrepoService,
    private listDealersService: ListDealersService,
    private globalcodeService: GlobalcodeService,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private router: Router,
    notifier: NotifierService,
    private datePipe: DatePipe,
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }

  datePickerConfig = { 'format': 'YYYY-MM-DD', 'firstDayOfWeek': 'mo' };
  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid: boolean = true;
  DateUpdatefromNg:any;
  column: string = 'globalCodeName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  loading = false;
  submitted = false;
  Dealerslist = [];
  deleteitem: any = 0;
  edititemid: any = 0;
  editvaluename: any;
  updateid: any;
  isDisabled = true;

  selectedGlobalcodeName:any;

  //select position

  selectedPosition: any;
  RoleIdForScreen: any;

  Grouplist = [];
  globalCodeCategory = [];

  globalCodeCategoryOnfrontside = [];


  ScreenList = [];
  SelectedScrenValue=[];
  Globalcodelist = [];
  Dealers = [{
    id: 1, name: 'Ninjaneers'
  },
  {
    id: 2, name: 'Aithrrep'
  },
  ];


  //ngmodel for bind data
  globalcodeid = 0;
  globalcategoryidctrl: any;
  customerSetupNumber: any;
  GlobalCodeName: any;
  GlobalCodeValue: any;
  EVBI1: any;
  EVBI2: any;
  EVBI3: any;
  GlobalCodeNotes: any;
  PriceControls: any;
  NoOfDaysControls: any;

  TypeControls: any;
  DateUpdatefromNgEdit:any;
  PSTControls: string = '0';
  GSTControls: string = '0';
  HSTControls: string = '0';
  TotalTaxRateControls: string = '0';

  globalcategoryiddropdownfront = 0;
  TotalRecord: any;

  categronametitle: any;
  //

  SubscriptionDisplay = "false";
  ProvinceDisplay = "false";
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.Globalcodelist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ngOnInit(): void {
    debugger;

   // this.DateUpdatefromNgEdit = new Date(new Date().toISOString().split('T').shift());
    // for getting value of VehicleId from qurey string
    var globalcodereceive = this.Globalcodereceive;

    var catid = this.route.snapshot.paramMap.get('catid');
    this.globalcategoryiddropdownfront = parseInt(catid);


    if (globalcodereceive > 0) // this is coming from admin setting
    {
      this.globalcategoryiddropdownfront = globalcodereceive;
    }

    var name = this.route.snapshot.paramMap.get('name');

    // for hiding controls
    if (name == "Subscription") {
      this.SubscriptionDisplay = "true";
    } else if (name == "Province") {
      this.ProvinceDisplay = "true";
    }

    if (this.globalcategoryiddropdownfront == 0) {
      this.categronametitle = "Category Name";
    } else {
      this.categronametitle = name;
    }

    if (globalcodereceive == 11) {
      this.categronametitle = "Group";
    }
    this.CreateInitiateFrom();
    this.GetDealerslist();

    this.GetGlobalCodeCategorylist();

    if (this.globalcategoryiddropdownfront > 0) {

      this.onChangedropdownfront(this.globalcategoryiddropdownfront)
    }
    else {
      this.GetGlobalcodelist();
    }

  }

  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  printTable() {
    var prepare = [];
    this.Globalcodelist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.globalCodeCategoryName)
      tempObj.push(e.globalCodeName);
      tempObj.push(e.globalCodeValue);
      tempObj.push(e.globalCodeNotes);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>globalCodeCategoryName</th><th>globalCodeName</th><th>globalCodeValue</th><th>globalCodeNotes</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  exportexcel() {
    if (this.globalcategoryiddropdownfront != 9 && this.globalcategoryiddropdownfront != 8) {
      const prepare = [];
      this.Globalcodelist.forEach(e => {
        const tempObj = [];
        tempObj.push(e.globalCodeCategoryName)
        tempObj.push(e.globalCodeName);
        tempObj.push(e.globalCodeValue);
        tempObj.push(e.globalCodeNotes);
        prepare.push(tempObj);
      });

      // Convert data to worksheet /
      const ws = XLSX.utils.aoa_to_sheet([
        ['CategoryName', 'Name', 'Value', 'Notes'],
        ...prepare
      ]);

      // Create a new workbook and add the worksheet /
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listofglobalcode');

      // Save the workbook to a file /
      XLSX.writeFile(wb, 'Global_code.xlsx');

    }
    if (this.globalcategoryiddropdownfront == 9) {
      const prepare = [];
      this.Globalcodelist.forEach(e => {
        const tempObj = [];
        tempObj.push(e.globalCodeName)
        tempObj.push(e.pst);
        tempObj.push(e.gst);
        tempObj.push(e.hst);
        tempObj.push(e.totalTaxRate);
        tempObj.push(e.globalCodeNotes);
        prepare.push(tempObj);
      });

      // Convert data to worksheet /
      const ws = XLSX.utils.aoa_to_sheet([
        ['Name', 'PST', 'GST', 'HST', 'TotalTaxRate', 'GlobalCodeNotes'],
        ...prepare
      ]);

      // Create a new workbook and add the worksheet /
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listofglobalcode');

      // Save the workbook to a file /
      XLSX.writeFile(wb, 'Global_code.xlsx');

    }

    if (this.globalcategoryiddropdownfront == 8) {
      const prepare = [];
      this.Globalcodelist.forEach(e => {
        const tempObj = [];
        tempObj.push(e.globalCodeName);
        tempObj.push(e.globalCodeValue);
        tempObj.push(e.globalCodeNotes);
        tempObj.push(e.noOfDays);
        tempObj.push(e.price);
        prepare.push(tempObj);
      });

      // Convert data to worksheet /
      const ws = XLSX.utils.aoa_to_sheet([
        ['Name', 'Value', 'Notes', 'Total days', 'Price per month'],
        ...prepare
      ]);

      // Create a new workbook and add the worksheet /
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Listofglobalcode');

      // Save the workbook to a file /
      XLSX.writeFile(wb, 'Global_code.xlsx');

    }

  }

  downloadPdf() {

    if (this.globalcategoryiddropdownfront != 9 && this.globalcategoryiddropdownfront != 8) {
      var prepare = [];
      this.Globalcodelist.forEach(e => {
        var tempObj = [];

        tempObj.push(e.globalCodeCategoryName)
        tempObj.push(e.globalCodeName);
        tempObj.push(e.globalCodeValue);
        tempObj.push(e.globalCodeNotes);

        prepare.push(tempObj);
      });
      const doc = new jsPDF('l', 'pt', 'a4');
      // doc.autoTable({
      //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
      //     body: prepare
      // });
      autoTable(doc, {
        head: [['CategoryName', 'Name', 'Value', 'Notes']],
        body: prepare,
        didDrawCell: (data) => { },
      });
      doc.save('Globalcode_List' + '.pdf');
    }
    if (this.globalcategoryiddropdownfront == 9) {
      var prepare = [];
      this.Globalcodelist.forEach(e => {
        var tempObj = [];
        tempObj.push(e.globalCodeName);
        tempObj.push(e.pst);
        tempObj.push(e.gst);
        tempObj.push(e.hst);
        tempObj.push(e.totalTaxRate);
        tempObj.push(e.globalCodeNotes);
        prepare.push(tempObj);
      });
      const doc = new jsPDF('l', 'pt', 'a4');
      // doc.autoTable({
      //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
      //     body: prepare
      // });
      autoTable(doc, {
        head: [['Name', 'PST', 'GST', 'HST', 'TotalTaxRate', 'GlobalCodeNotes']],
        body: prepare,
        didDrawCell: (data) => { },
      });
      doc.save('Globalcode_List' + '.pdf');
    }

    if (this.globalcategoryiddropdownfront == 8) {
      var prepare = [];
      this.Globalcodelist.forEach(e => {
        var tempObj = [];

        tempObj.push(e.globalCodeName);
        tempObj.push(e.globalCodeValue);
        tempObj.push(e.globalCodeNotes);
        tempObj.push(e.noOfDays);
        tempObj.push(e.price);

        prepare.push(tempObj);
      });
      const doc = new jsPDF('l', 'pt', 'a4');
      // doc.autoTable({
      //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
      //     body: prepare
      // });
      autoTable(doc, {
        head: [['Name', 'Value', 'Notes', 'Total days', 'Price per month']],
        body: prepare,
        didDrawCell: (data) => { },
      });
      doc.save('Globalcode_List' + '.pdf');
    }


  }


  // function for creating from
  CreateInitiateFrom() {
    this.Inititform = new FormGroup({
      globalcategoryiddropdownfront: this.fb.control(''),
      globalcategoryidctrl: this.fb.control('', Validators.required),
      // customerSetupNumber:this.fb.control('', Validators.required),
      GlobalCodeName: this.fb.control('', Validators.required),
      GlobalCodeValue: this.fb.control(''),
      // EVBI1:this.fb.control('', Validators.required),
      // EVBI2:this.fb.control('', Validators.required),
      // EVBI3:this.fb.control('', Validators.required),
      GlobalCodeNotes: this.fb.control(''),
      // Controls for Subscription
      PriceControls: this.fb.control(''),
      NoOfDaysControls: this.fb.control(''),
      TypeControls: this.fb.control(''),
      PSTControls: this.fb.control(''),
      GSTControls: this.fb.control(''),
      HSTControls: this.fb.control(''),
      TotalTaxRateControls: this.fb.control(''),
      DateUpdatefromNg: this.fb.control(''),
    });
  }


  GetGlobalcodelist() {
    this.globalcodeService.GetGlobalcodelist()
      .subscribe(
        (data: any) => {
          debugger;
          this.Globalcodelist = data;
          this.TotalRecord = data.length;
        },
        error => {
        });
  }



  Getgrouplist() {
    this.addgroupService.Getgrouplist()
      .subscribe(
        (data: any) => {
          this.Grouplist = data;
        },
        error => {
        });
  }
  // function for open model pop up
  openModal(template: TemplateRef<any>, itemid: any) {
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }


  // function for open model pop up
  ShowScreenName(template: TemplateRef<any>, itemid: any,name:any) {
    debugger;


    this.selectedGlobalcodeName=name;

    this.RoleIdForScreen = itemid;

    var modelGroup = {
      "RoleId": itemid
    }

    this.addgroupService.GetAllScreenList(modelGroup)
      .subscribe(
        data => {
debugger;
          this.ScreenList = data;
          // this.editvaluename = data.groupName;
          // this.updateid = data.id;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

        },
        error => {
        })




    //this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }



  //AssignRole to screen
  SetValue(value: any,id:any)
  {
    debugger;
    var Check = this.ScreenList;
    if (value == "1") {
      this.SelectedScrenValue=this.ScreenList;
    } else if (value == "2") {

    }
  }

  AssignRoleToScreen() {
    debugger;
    //this.loading = true;
    this.spinner.show();
    var assignrole = this.ScreenList;
    this.RoleIdForScreen

   // var SelectedScren = this.ScreenList.filter(i => i.isSelected == true);

    var modelGroup = {
      "Roleid": this.RoleIdForScreen,
      "SelectedSreenListMaster": this.ScreenList
    }

    this.addgroupService.SaveSelectedscreen(modelGroup)
      .subscribe(
        data => {


          this.showNotification("success", "Screen Assigned successfully");

          this.LoadAllscreenlist();
          this.decline()
          this.spinner.hide();
        },
        error => {
        })


  }


  MasterscreencheckValue(event:any,id:any,isSelected:any)
  {
debugger;
var CheckMaster = this.ScreenList;
var selectedvalue=this.ScreenList.filter(i=>i.id==id)[0];

if(isSelected==true)
{



  if(selectedvalue.submenu.length>0)
  {
    selectedvalue.submenu.forEach(element => {

      element.isSelected=true;
    });

  }
}
else
{
  selectedvalue.submenu.forEach(element => {

    element.isSelected=false;
  });
}



  }


  LoadAllscreenlist() {
    var modelGroup = {
      "Id": "1"
    }
    this.addgroupService.GetAllScreenList(modelGroup)
      .subscribe(
        data => {

          this.ScreenList = data;
        },
        error => {
        })
  }

  // edit functionality
  // function for open model pop up
  EditModalpopup(template: TemplateRef<any>, itemid: any) {
    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.addgroupService.EditGroupList(modelGroup)
      .subscribe(
        data => {
          this.editvaluename = data.groupName;
          this.updateid = data.id;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  // EditModalpopup(template: TemplateRef<any>, itemid: any) {
  //   this.edititemid = itemid;
  //   var modelGroup = {
  //     "id": itemid
  //   }

  //   this.addgroupService.EditGroupList(modelGroup)
  //     .subscribe(
  //       data => {
  //         this.editvaluename = data.groupName;
  //         this.updateid = data.id;

  //         // Set the modal title to "Edit Global Data"
  //         this.modalTitle = 'Edit Global Data';

  //         this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

  //       },
  //       error => {
  //       });
  // }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  closeEditModel() {
    this.modalRef.hide();
  }

  closepopupmodel() {
    this.Inititform.invalid
  }

  //add and edit popup
  // openAddModel(targetModal, id) {
  //   if (id != undefined && id != "") {
  //     this.edititemid = id;
  //     var modelGroup = {
  //       "GlobalCodeID": id
  //     }
  //     this.globalcodeService.EditGlobalcode(modelGroup)
  //       .subscribe(
  //         (data: any) => {
  //           debugger
  //           this.globalcodeid = data.globalCodeID,
  //           this.globalcategoryidctrl = data.globalCodeCategoryID;
  //           this.customerSetupNumber = data.customerSetupNumber;
  //           this.GlobalCodeName = data.globalCodeName;
  //           this.GlobalCodeValue = data.globalCodeValue;
  //           this.EVBI1 = data.evbI1;
  //           this.EVBI2 = data.evbI2;
  //           this.EVBI3 = data.evbI3;

  //           this.GlobalCodeNotes = data.globalCodeNotes;

  //           this.PriceControls = data.price;

  //           this.NoOfDaysControls = data.noOfDays;
  //           this.GSTControls = data.gst;
  //           this.HSTControls = data.hst;
  //           this.PSTControls = data.pst;
  //           this.TotalTaxRateControls =data.totalTaxRate;
  //           this.TypeControls = data.type;

  //         },
  //         error => {
  //         });


  //     this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  //   } else {
  //     this.CreateInitiateFrom();
  //     this.reinitiatengmodel();
  //     this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  //   }

  // }

  openAddModel(targetModal, id) {
    debugger;
    if (id != undefined && id != "") {
      this.edititemid = id;
      var modelGroup = {
        "GlobalCodeID": id
      }
      this.globalcodeService.EditGlobalcode(modelGroup)
        .subscribe(
          (data: any) => {
            debugger
            this.globalcodeid = data.globalCodeID,
              this.globalcategoryidctrl = data.globalCodeCategoryID;
            this.customerSetupNumber = data.customerSetupNumber;
            this.GlobalCodeName = data.globalCodeName;
            this.GlobalCodeValue = data.globalCodeValue;
            this.EVBI1 = data.evbI1;
            this.EVBI2 = data.evbI2;
            this.EVBI3 = data.evbI3;

            this.GlobalCodeNotes = data.globalCodeNotes;

            this.PriceControls = data.price;

            this.NoOfDaysControls = data.noOfDays;
            this.GSTControls = data.gst;
            this.HSTControls = data.hst;
            this.PSTControls = data.pst;
            this.TotalTaxRateControls = data.totalTaxRate;
            this.TypeControls = data.type;

            this.DateUpdatefromNgEdit =data.createdDate;

          },
          error => {
          });

      // Set the modal title to "Edit Global Data"
      this.modalTitle = 'Edit';

      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
    } else {
      //this.CreateInitiateFrom();
      this.reinitiatengmodel();

      // Set the modal title to "Add Global Data"
      this.modalTitle = 'Add';

      this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
    }
  }



  onChangedropdownfront(Id) {
    this.globalcategoryiddropdownfront = Id;
    var modelGroup = {
      "GlobalCodeCategoryID": Id
    }
    this.dashboardService.getGlobalCodeCategorylistby_categoryid(modelGroup)
      .subscribe(
        data => {
          this.Globalcodelist = data;
          this.globalcategoryidctrl = Id;
          this.globalcategoryiddropdownfront = Id;
          this.TotalRecord = data.length;
        },
        error => {
        });
  }

  reinitiatengmodel() {
    this.globalcodeid = 0;

    if (this.globalcategoryiddropdownfront > 0) {
      this.globalcategoryidctrl = this.globalcategoryiddropdownfront;
    }
    else {
      this.globalcategoryidctrl = "";
    }



    this.customerSetupNumber = "";
    this.GlobalCodeName = "";
    this.GlobalCodeValue = "";
    this.EVBI1 = "";
    this.EVBI2 = "";
    this.EVBI3 = "";
    this.GlobalCodeNotes = "";

    this.PriceControls = "";
    this.NoOfDaysControls = "";

    this.TypeControls = "";
    this.PSTControls = "";
    this.GSTControls = "";
    this.HSTControls = "";
    this.TotalTaxRateControls = "";

  }



  //Calling get list of group on page load

  GetGlobalCodeCategorylist() {
    this.dashboardService.getGlobalCodeCategorylist()
      .subscribe(
        (data: any) => {
          debugger;

          this.globalCodeCategory = data;
          this.globalCodeCategoryOnfrontside = data;
          this.globalcategoryiddropdownfront;
          if (this.globalcategoryiddropdownfront > 0) {
            this.globalCodeCategory = this.globalCodeCategory.filter(x => x.globalCodeCategoryID == this.globalcategoryiddropdownfront);
            this.globalCodeCategoryOnfrontside = this.globalCodeCategoryOnfrontside.filter(x => x.globalCodeCategoryID == this.globalcategoryiddropdownfront);
          }

          // this.globalCodeCategory.filter(data=>data.id==this.globalcategoryiddropdownfront).

        },
        error => {
        });
  }





  // for update the edited part
  update() {
    this.submitted = true;

    if (this.Inititform.invalid) {
      this.isValid = false;
      return;
    }
    var modelGroup = {
      "id": this.updateid,
      "GroupName": this.editvaluename,
    }
    this.addgroupService.UpdateGroupName(modelGroup)

      .subscribe(
        (data: any) => {
          this.GetDealerslist();
          this.submitted = false;
          //this.UpdateGroupName();
          this.CreateInitiateFrom();
          //alert("data changed")
          this.showNotification("success", "Data updated successfully")
          this.modalRef.hide();
          // this.Grouplist = data;
        },
        error => {
        });

    //this.updateid=id;
    // this.addgroupService.EditGroupList(updateid);
  }
  get f() { return this.Inititform.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  // onSubmit() {
  //   debugger
  //   this.submitted = true;
  //   if( this.Inititform.controls.NoOfDaysControls.value =="")
  //   {
  //     this.NoOfDaysControls ="0"
  //   }
  //   if( this.Inititform.controls.PriceControls.value =="")
  //   {
  //     this.PriceControls ="0"
  //   }

  //   if( this.Inititform.controls.TotalTaxRateControls.value =="")
  //   {
  //     this.TotalTaxRateControls ="0"
  //   }

  //   if( this.Inititform.controls.HSTControls.value =="")
  //   {
  //     this.HSTControls ="0"
  //   }

  //   if( this.Inititform.controls.GSTControls.value =="")
  //   {
  //     this.GSTControls ="0"
  //   }

  //   if( this.Inititform.controls.PSTControls.value =="")
  //   {
  //     this.PSTControls ="0"
  //   }

  //   if( this.Inititform.controls.TypeControls.value =="")
  //   {
  //     this.TypeControls ="0"
  //   }
  //   if (this.Inititform.invalid) {
  //     this.isValid = false;
  //     return;
  //   } else {
  //    // Calculate the sum of PST, GST, and HST
  // const pst = parseFloat(this.PSTControls);
  // const gst = parseFloat(this.GSTControls);
  // const hst = parseFloat(this.HSTControls);
  // const totalTaxRate = pst + gst + hst;

  // // Update the Total Tax Rate control value
  // this.TotalTaxRateControls = totalTaxRate.toFixed(2);

  // if (this.Inititform.invalid) {
  //   this.isValid = false;
  //   return;
  // } else {
  //     var modelGroup = {
  //       "GlobalCodeID": this.globalcodeid,
  //       "GlobalCodeCategoryID": this.Inititform.controls.globalcategoryidctrl.value,
  //       // "CustomerSetupNumber": this.Inititform.controls.customerSetupNumber.value,
  //       "GlobalCodeName": this.Inititform.controls.GlobalCodeName.value,
  //       "GlobalCodeValue": this.Inititform.controls.GlobalCodeValue.value,
  //       // "EVBI1": this.Inititform.controls.EVBI1.value,
  //       // "EVBI2": this.Inititform.controls.EVBI2.value,
  //       // "EVBI3": this.Inititform.controls.EVBI3.value,
  //       "GlobalCodeNotes": this.Inititform.controls.GlobalCodeNotes.value,
  //       "NoOfDays": this.NoOfDaysControls,
  //       "Price": this.PriceControls,
  //       "Type": this.TypeControls,
  //       "PST": this.PSTControls,
  //       "GST": this.GSTControls,
  //       "HST": this.HSTControls,
  //       "TotalTaxRate": this.TotalTaxRateControls,
  //     }


  //     this.globalcodeService.SaveaddGlobalcode(modelGroup)
  //       .subscribe(
  //         (data: any) => {
  //           debugger
  //           if (this.globalcategoryiddropdownfront > 0) {
  //             this.onChangedropdownfront(this.globalcategoryiddropdownfront)
  //           } else {
  //             this.GetGlobalcodelist();
  //           }

  //           this.submitted = false;

  //           this.showNotification("success", "Data saved successfully")
  //           this.reinitiatengmodel();
  //           this.globalcategoryiddropdownfront = this.globalcategoryiddropdownfront;
  //           this.modalRef.hide();
  //           //  this.modalcloseOpen.hide();
  //           this.closeEditModel();
  //           this.closepopupmodel();
  //           this.modalcloseOpen.hide();



  //           //this.modalRef.hide() ;
  //           // this.Grouplist = data;

  //         },
  //         error => {
  //         });
  //     this.loading = true;
  //     this.isValid = true;
  //   }
  // }

  // }

  // onSubmit() {
  //   debugger
  //   this.submitted = true;
  //   if( this.Inititform.controls.NoOfDaysControls.value =="")
  //   {
  //     this.NoOfDaysControls ="0"
  //   }
  //   if( this.Inititform.controls.PriceControls.value =="")
  //   {
  //     this.PriceControls ="0"
  //   }

  //   if( this.Inititform.controls.TotalTaxRateControls.value =="")
  //   {
  //     this.TotalTaxRateControls ="0"
  //   }

  //   if( this.Inititform.controls.HSTControls.value =="")
  //   {
  //     this.HSTControls ="0"
  //   }

  //   if( this.Inititform.controls.GSTControls.value =="")
  //   {
  //     this.GSTControls ="0"
  //   }

  //   if( this.Inititform.controls.PSTControls.value =="")
  //   {
  //     this.PSTControls ="0"
  //   }

  //   if( this.Inititform.controls.TypeControls.value =="")
  //   {
  //     this.TypeControls ="0"
  //   }
  //   if (this.Inititform.invalid) {
  //     this.isValid = false;
  //     return;
  //   } else {
  //    // Calculate the sum of PST, GST, and HST
  // const pst = parseFloat(this.PSTControls);
  // const gst = parseFloat(this.GSTControls);
  // const hst = parseFloat(this.HSTControls);
  // const totalTaxRate = pst + gst + hst;

  // // Update the Total Tax Rate control value
  // this.TotalTaxRateControls = totalTaxRate.toFixed(2);

  // if (this.Inititform.invalid) {
  //   this.isValid = false;
  //   return;
  // } else {
  //     var modelGroup = {
  //       "GlobalCodeID": this.globalcodeid,
  //       "GlobalCodeCategoryID": this.Inititform.controls.globalcategoryidctrl.value,
  //       // "CustomerSetupNumber": this.Inititform.controls.customerSetupNumber.value,
  //       "GlobalCodeName": this.Inititform.controls.GlobalCodeName.value,
  //       "GlobalCodeValue": this.Inititform.controls.GlobalCodeValue.value,
  //       // "EVBI1": this.Inititform.controls.EVBI1.value,
  //       // "EVBI2": this.Inititform.controls.EVBI2.value,
  //       // "EVBI3": this.Inititform.controls.EVBI3.value,
  //       "GlobalCodeNotes": this.Inititform.controls.GlobalCodeNotes.value,
  //       "NoOfDays": this.NoOfDaysControls,
  //       "Price": this.PriceControls,
  //       "Type": this.TypeControls,
  //       "PST": this.PSTControls,
  //       "GST": this.GSTControls,
  //       "HST": this.HSTControls,
  //       "TotalTaxRate": parseFloat(this.TotalTaxRateControls),
  //     }

  //     this.globalcodeService.SaveaddGlobalcode(modelGroup)
  //       .subscribe(
  //         (data: any) => {
  //           debugger
  //           if (this.globalcategoryiddropdownfront > 0) {
  //             this.onChangedropdownfront(this.globalcategoryiddropdownfront)
  //           } else {
  //             this.GetGlobalcodelist();
  //           }

  //           this.submitted = false;

  //           this.showNotification("success", "Data saved successfully")
  //           this.reinitiatengmodel();
  //           this.globalcategoryiddropdownfront = this.globalcategoryiddropdownfront;
  //           this.modalRef.hide();
  //           //  this.modalcloseOpen.hide();
  //           this.closeEditModel();
  //           this.closepopupmodel();
  //           this.modalcloseOpen.hide();



  //           //this.modalRef.hide() ;
  //           // this.Grouplist = data;

  //         },
  //         error => {
  //         });
  //     this.loading = true;
  //     this.isValid = true;
  //   }
  // }

  // }

  updateTotalTaxRate() {
    const pst = parseFloat(this.PSTControls) || 0;
    const gst = parseFloat(this.GSTControls) || 0;
    const hst = parseFloat(this.HSTControls) || 0;
    const totalTaxRate = pst + gst + hst;

    this.TotalTaxRateControls = totalTaxRate.toFixed(2);
  }
  onSubmit() {
    debugger;
    this.submitted = true;

    // Set default values if controls are empty
    if (this.Inititform.controls.NoOfDaysControls.value === "") {
      this.NoOfDaysControls = "0";
    }
    if (this.Inititform.controls.PriceControls.value === "") {
      this.PriceControls = "0";
    }

    if (this.Inititform.controls.TotalTaxRateControls.value === "") {
      this.TotalTaxRateControls = "0";
    }

    if (this.Inititform.controls.HSTControls.value === "") {
      this.HSTControls = "0";
    }

    if (this.Inititform.controls.GSTControls.value === "") {
      this.GSTControls = "0";
    }

    if (this.Inititform.controls.PSTControls.value === "") {
      this.PSTControls = "0";
    }

    if (this.Inititform.controls.TypeControls.value === "") {
      this.TypeControls = "0";
    }

    if (this.Inititform.invalid) {
      this.isValid = false;
      return;
    } else {
      // Calculate the sum of PST, GST, and HST
      const pst = parseFloat(this.PSTControls);
      const gst = parseFloat(this.GSTControls);
      const hst = parseFloat(this.HSTControls);
      const totalTaxRate = pst + gst + hst;

      // Update the Total Tax Rate control value
      this.TotalTaxRateControls = totalTaxRate.toFixed(2);

      if (this.Inititform.invalid) {
        this.isValid = false;
        return;
      } else {
        // Create the model group object
        var modelGroup = {
          "GlobalCodeID": this.globalcodeid,
          "GlobalCodeCategoryID": this.Inititform.controls.globalcategoryidctrl.value,
          "GlobalCodeName": this.Inititform.controls.GlobalCodeName.value,
          "GlobalCodeValue": this.Inititform.controls.GlobalCodeValue.value,
          "GlobalCodeNotes": this.Inititform.controls.GlobalCodeNotes.value,
          "NoOfDays": this.NoOfDaysControls,
          "Price": this.PriceControls,
          "Type": this.TypeControls,
          "PST": this.PSTControls,
          "GST": this.GSTControls,
          "HST": this.HSTControls,
          "TotalTaxRate": parseFloat(this.TotalTaxRateControls),
          "CreatedDate":this.DateUpdatefromNg
        };

        this.globalcodeService.SaveaddGlobalcode(modelGroup)
          .subscribe(
            (data: any) => {
              debugger;
              if (this.globalcategoryiddropdownfront > 0) {
                this.onChangedropdownfront(this.globalcategoryiddropdownfront);
              } else {
                this.GetGlobalcodelist();
              }

              this.submitted = false;

              this.showNotification("success", "Data saved successfully");
              this.reinitiatengmodel();
              this.globalcategoryiddropdownfront = this.globalcategoryiddropdownfront;
              this.modalRef.hide();
              this.closeEditModel();
              this.closepopupmodel();
              this.modalcloseOpen.hide();
            },
            error => {
              // Handle the error
            }
          );
        this.loading = true;
        this.isValid = true;
      }
    }
  }




  CloseAddmodel() {
    this.submitted = false;
    this.modalRef.hide();
  }

  // function for confirm  delete
  confirm(): void {
    this.deleteitem;
    var modelGroup = {
      "GlobalCodeID": this.deleteitem
    }
    this.globalcodeService.deleteGlobalcode(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted  successfully")

          if (this.globalcategoryiddropdownfront > 0) {
            this.onChangedropdownfront(this.globalcategoryiddropdownfront)
          } else {
            this.GetGlobalcodelist();
          }

        },
        error => {
        });

    this.modalRef.hide();

  }
  decline(): void {
    this.modalRef.hide();
  }

  displayStyle = "none";

  openPopup() {
    this.displayStyle = "block";
  }
  closePopup() {
    this.displayStyle = "none";
  }


  //Calling get list of group on page load

  GetDealerslist() {
    var modelGroup = {
      "FilterValues": "All",
      
    }
    this.listDealersService.GetDealerslist(modelGroup)
      .subscribe(
        (data: any) => {
          this.Dealerslist = data;
          //  this.modalRef.hide() ;
        },
        error => {
        });
  }



  public bsValueChange(value) {
    this.writeValue(this.value);
    this.onTouched();
  }

  public writeValue(value: any) {
    this.value = this.transformDate(value);
    this.onChange(this.value);
    console.log('writeValue', 'old: ' + value, 'new: ' + this.value);
  }

  public registerOnChange(fn: any) {
    this.onChange = fn;
    this.onChange(this.value); // for OnInit cycle
  }

  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean) {
    //
  }

  private transformDate(value: any) {
    return this.datePipe.transform(value, 'MM-dd-yyyy');
  }

}