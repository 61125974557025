<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="customerWarrantyForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12 col-lg-12 col-xl-12 mt-2 mb-3 mb-xl-0">
                    <div class="card buildcar-title mb-0">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                                <!-- <div class="col-auto">
                                    
                                </div> -->
                                <div class="col-auto mr-auto px-2">
                                    <div class="form-inline">
  
                                        <label class="col-form-label col-lg-auto px-1">Select Key Name:</label>
                                        <div class="col-lg-auto">
                                          <select class="form-control" name="region" 
                                          formControlName="KeyNameFilter" 
                                            [(ngModel)]="KeyNameFilter">
                                            <option value="0" selected>Select key Type </option>
                                            <option value="VenderKey">Vendor Key</option>
                                            <option value="WarrantyKey">Warranty Key</option>
                                          </select>
                                        </div>

                                        <button id="getdetails" type="button" class="btn btn-md ml-2 btn-warning"
                                        (click)="GetDetail();" >Get
                                        Keys </button>

                                        
                                 
                               
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card-body px-3 py-2" style="min-height:auto;">
                          
                            <div class="w-100 mt-2">
                               
                               
                              
                            </div>
                            <div *ngIf="showlist ==='true'" class="row mt-2">
                                <div class="col-md-6">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h5 class="card-title text-white text-uppercase mb-0">List of Keys</h5>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive">
                                                <table id="usertable" class="table table-bordered" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Key Name</th>
                                                      
                                                            <th>Detail</th>
                                                           
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let keylistListdata of keylistList| filter:searchTextBought | slice: (pagebought-1) * pageSizebought : (pagebought-1) * pageSizebought + pageSizebought">
                                                        
                                                            <td>{{ keylistListdata.keyName }}</td>
                                                         
                                                            <td><a   [routerLink]="" 
                                                                (click)="ViewVendorDetail(keylistListdata.keyName)">View Detail</a></td>
                                                            
        
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                                <br>
                            
                                            </div>
                                        </div>
                            
                            
                                    </div>
                                </div>

                                <!-- Key Detail Second Block -->
                                <div *ngIf="showlist ==='true' && showVendorKeylist ==='true'" class="col-md-6">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h5 class="card-title text-white text-uppercase mb-0">Key Detail</h5>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive">
                                                <table id="usertable" class="table table-bordered" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Key Name</th>
                                                            <th>Name</th>
                                                            <th>Address</th>
                                                            <th>City</th>
                                                            <th>Phone</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let VendorKeyDetailsdata of VendorKeyDetails">
                                                        
                                                            <td>{{ VendorKeyDetailsdata.keyName }}</td>
                                                            <td>{{ VendorKeyDetailsdata.name }}</td>
                                                            <td>{{ VendorKeyDetailsdata.address }}</td>
                                                            <td>{{ VendorKeyDetailsdata.city }}</td>
                                                            <td>{{ VendorKeyDetailsdata.phone }}</td>
                                                           
                                                            
        
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                                <br>
                            
                                            </div>
                                        </div>
                            
                            
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showWarrantylist ==='true'" class="row mt-2">
                                <div class="col-md-6">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h5 class="card-title text-white text-uppercase mb-0">List of Keys</h5>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive">
                                                <table id="usertable" class="table table-bordered" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Key Name</th>
                                                            <th>Detail</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let warrantykeylistListdata of warrantykeylistList| filter:searchTextBought | slice: (pagebought-1) * pageSizebought : (pagebought-1) * pageSizebought + pageSizebought">
                                                        
                                                            <td>{{ warrantykeylistListdata.keyName }}</td>
                                                            
                                                     
                                                            <td><a   [routerLink]="" 
                                                                (click)="ViewwarrantyDetail(warrantykeylistListdata.keyName)">View Detail</a></td>
                                                            
        
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                                <br>
                            
                                            </div>
                                        </div>
                            
                            
                                    </div>
                                </div>

                                <!-- Key Detail Second Block -->
                                <div class="col-md-6" *ngIf="showWarrantylist ==='true' && showWarrantyKeylist ==='true'">
                                    <div class="card card-raised">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-sm-6">
                                                    <h5 class="card-title text-white text-uppercase mb-0">Key Detail</h5>
                                                </div>
                                                <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                    <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                                </div>
                                                <!-- <div class="card-subtitle"></div> -->
                                            </div>
                                        </div>
                                        <div id="inventory" class="card-body p-4" style="min-height:auto;">
                                            <div class="table-responsive">
                                                <table id="usertable" class="table table-bordered" style="width:100%">
                                                    <thead>
                                                        <tr>
                                                            
                                                            <th>Key Name</th>
                                                            <th>Value</th>
                                                            <th>Price</th>
                                                           
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr
                                                            *ngFor="let WarrantyKeyDetailsdata of WarrantyKeyDetails">
                                                        
                                                            <td>{{ WarrantyKeyDetailsdata.keyName }}</td>
                                                            <td>{{ WarrantyKeyDetailsdata.keyValue }}</td>
                                                      
                                                            <td>{{ WarrantyKeyDetailsdata.price | currency }}</td>
                                                            
        
                                                        </tr>
                            
                                                    </tbody>
                                                </table>
                                                <br>
                            
                                            </div>
                                        </div>
                            
                            
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
   
        <!-- <app-footer id="secondfooter"></app-footer> -->

    </form>
</section>





