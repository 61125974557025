<section id="admindashboard">
    <div class="container-fluid">
        <div class="page-header">
            <div class="row">
                <div class="col-lg-12">
                    <h3>
                        Analytic Dashboard
                        <small>Vehicles Admin panel</small>
                    </h3>
                </div>
            </div>
        </div>

        <div id="analyticboxs" class="row mb-3">
            <div class="col-lg-6 col-xl-3">
                <div class="card-stats mb-2 mb-xl-0 card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <small class="text-uppercase text-muted mb-0 card-title d-block">Traffic</small>
                                <span class="h5 font-weight-bold mb-0">350,897</span>
                            </div>
                           <div class="col-auto col">
                                <div class="icon icon-shape bg-danger text-white rounded-circle">
                                    <i class="fas fa-chart-bar"></i>
                                </div>
                           </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm"><span class="text-success mr-2">
                            <i class="fa fa-arrow-up"></i> 3.48%</span> 
                            <span class="text-nowrap">Since last month</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="card-stats mb-2 mb-xl-0 card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <small class="text-uppercase text-muted mb-0 card-title d-block">New Users</small>
                                <span class="h5 font-weight-bold mb-0">2,356</span>
                            </div>
                           <div class="col-auto col">
                                <div class="icon icon-shape bg-warning text-white rounded-circle">
                                    <i class="fas fa-chart-pie"></i>
                                </div>
                           </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm"><span class="text-danger mr-2">
                            <i class="fas fa-arrow-down"></i> 3.48%</span> 
                            <span class="text-nowrap">Since last week</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="card-stats mb-2 mb-xl-0 card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <small class="text-uppercase text-muted mb-0 card-title d-block">Sales</small>
                                <span class="h5 font-weight-bold mb-0">924</span>
                            </div>
                           <div class="col-auto col">
                                <div class="icon icon-shape bg-yellow text-white rounded-circle">
                                    <i class="fas fa-users"></i>
                                </div>
                           </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm"><span class="text-warning mr-2">
                            <i class="fas fa-arrow-down"></i> 1.10%</span> 
                            <span class="text-nowrap">Since yesterday</span>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-xl-3">
                <div class="card-stats mb-2 mb-xl-0 card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col">
                                <small class="text-uppercase text-muted mb-0 card-title d-block">Performance</small>
                                <span class="h5 font-weight-bold mb-0">49,65%</span>
                            </div>
                           <div class="col-auto col">
                                <div class="icon icon-shape bg-info text-white rounded-circle">
                                    <i class="fas fa-percent"></i>
                                </div>
                           </div>
                        </div>
                        <p class="mt-3 mb-0 text-muted text-sm"><span class="text-success mr-2">
                            <i class="fas fa-arrow-up"></i> 12%</span> 
                            <span class="text-nowrap">Since last month</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-md-12">
                <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white">
                        <h5 class="card-title text-white text-uppercase mb-0">Availability of Vechiles</h5>
                    </div>
                    <div class="card-body p-4">
                        <div class="row">
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card mb-0">
                                    <div class="card-body">
                                        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                                            [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card mb-0">
                                    <div class="card-body">
                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                            [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                                            [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-4">
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card mb-0">
                                    <div class="card-body">
                                        <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                            [chartType]="pieChartType" [options]="pieChartOptions" [plugins]="pieChartPlugins"
                                            [legend]="pieChartLegend">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-12 col-12">
                                <div class="card mb-0">
                                    <div class="card-body">
                                        <canvas baseChart [datasets]="barChartData" [labels]="barChartLabels" [options]="barChartOptions"
                                            [plugins]="barChartPlugins" [legend]="barChartLegend" [chartType]="barChartType">
                                        </canvas>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- <app-footer id="secondfooter"></app-footer> -->
</section>