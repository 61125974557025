import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { NotifierService } from 'angular-notifier';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-account-setting',
  templateUrl: './account-setting.component.html',
  styleUrls: ['./account-setting.component.less']
})
export class AccountSettingComponent implements OnInit {
  AccountSettingForm: FormGroup;
  notifier: NotifierService;
  accounts: any;
  TotalRecord: any;
  submitted: boolean;
  useridValue: any;
  ImagePathValue:any;
  fullName: any;
  RoleValueName: any;
  DealerNameValue: any;
  Email: any;
  ProfileImage:any;
  ProfileImageValue:any;

  FirstName: any;
  LastName: any;
  isValid: boolean = true;
  PhoneNumber: any;
  oldPassword: any;
  City: any;
  Region: any;
  colortheme: any;
  password = "";
  show = true;

  passwordConfirm = "";
  showConfirm = true;

  //newPassword:any;
  confirmPassword: any;
  ColorTheme: any;

  validationtrue: boolean = false;
  validationMessage: string = '';
  newPasswordCheck: string = '';
  colormessage: any;
  validationMessagePassword: string = 'Password must have at least 8 characters';
  validationMessagelowercase: string = '1 lowercase';
  validationMessageuppercase: string = '1 uppercase ';
  validationMessagespecial: string = '1 special character';
  validationMessagenumeric: string = '1 numeric character';

  colormessagelowercase: any;
  colormessageuppercase: any;
  colormessagePassword: any;
  colormessagespecial: any;
  colormessagenumeric: any;

  usershow:boolean = false;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    notifier: NotifierService,
    private globalcodeService: GlobalcodeService,
    private confirmModalService: BsModalService,
    private router: Router,
    private addgroupService: AddgroupService
  ) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  ColourThemelist = [];
  existingPictureslist = [];
  modalRef: BsModalRef;
  ngOnInit(): void {

    this.Updateform();
    this.password = 'password';
    //this.usershow="false";
    this.passwordConfirm = 'password';
    this.useridValue = localStorage.getItem('userid');

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    this.GetColourThemelist();
    this.GetUserDetailbyID();
  }
  CancelButton() {
    return this.router.navigateByUrl("/master-dashboard");
  }


  validatePassword() {

    // Check if the password meets the criteria
    const isLengthValid = this.newPasswordCheck.length >= 8;
    const hasLowercase = /[a-z]/.test(this.newPasswordCheck);
    const hasUppercase = /[A-Z]/.test(this.newPasswordCheck);
    const hasSpecialChar = /[!@#$%^&*()_+{}|[\]\\;:'",.<>?/]/.test(this.newPasswordCheck);
    const hasNumber = /\d/.test(this.newPasswordCheck);


    if (isLengthValid && hasLowercase && hasUppercase && hasSpecialChar && hasNumber) {
      this.validationtrue = false;
      this.validationMessage = 'Password is valid!';
    } else {
      this.validationtrue = true;
      this.validationMessage = 'Password Requirements';
      // this.colormessage="red";

      if (isLengthValid) {
        this.colormessagePassword = "#21e121"
      }
      else {
        this.colormessagePassword = "#ff6262"
      }

      if (hasLowercase) {
        this.colormessagelowercase = "#21e121"
      }
      else {
        this.colormessagelowercase = "#ff6262"
      }

      if (hasUppercase) {
        this.colormessageuppercase = "#21e121";
      }
      else {
        this.colormessageuppercase = "#ff6262";
      }

      if (hasSpecialChar) {
        this.colormessagespecial = "#21e121";
      }
      else {
        this.colormessagespecial = "#ff6262";
      }

      if (hasNumber) {
        this.colormessagenumeric = "#21e121"
      }
      else {
        this.colormessagenumeric = "#ff6262"
      }
    }
  }



  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  decline(): void {
    this.modalRef.hide();
  }
  openChangePasswordModelPopup(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  GetImageClick(ImagePath:any)
  {
    this.ImagePathValue = ImagePath;
    this.UpdateProfilePicture();
  }

  UpdateProfilePicture() {
    var modelGroup = {
      "UserId": this.useridValue,
      "ImagePath":this.ImagePathValue
    }

    this.addgroupService.updatePicture(modelGroup)
      .subscribe(
        data => {
          this.decline();
         this.GetUserDetailbyID();
         this.showNotification("success", "Profile picture updated successfully.")
        },
        error => {
        });
  }
  openPictureModelPopup(template: TemplateRef<any>) {
    this.GetUserPicturesbyID();
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  
  GetColourThemelist() {
    this.globalcodeService.getColourThemelist()
      .subscribe(
        (data: any) => {
          this.ColourThemelist = data;
        },
        error => {
        });
  }
  functionShow()
  {
    this.usershow=!this.usershow;
  }
  // out()
  // {
  //   this.usershow="false";
  // }
  // over()
  // {
  //   this.usershow="true";
  // }
  GetUserPicturesbyID() {
    var modelGroup = {
      "UserId": this.useridValue
    }

    this.addgroupService.getUserPictures(modelGroup)
      .subscribe(
        data => {
          debugger;
          this.existingPictureslist=data;

        },
        error => {
        });
  }
  GetUserDetailbyID() {
    var modelGroup = {
      "UserId": this.useridValue
    }

    this.addgroupService.getUserDetailByUserId(modelGroup)
      .subscribe(
        data => {
          this.fullName = data[0].firstName + ' ' + data[0].lastName;
          this.Email = data[0].email;
          this.FirstName = data[0].firstName;
          this.LastName = data[0].lastName;
          this.PhoneNumber = data[0].phoneNumber;
          this.City = data[0].city;
          this.Region = data[0].globalCodeName;
          this.colortheme = data[0].colorTheme;

          this.RoleValueName = data[0].roleNameValue;
          this.DealerNameValue = data[0].dealerName;
          this.ProfileImage= data[0].profilePhoto;
          this.ProfileImageValue = data[0].logobaseSixtyfour;

        },
        error => {
        });
  }

  private matchPassword(AC: AbstractControl) {
    let password = AC.get('newPasswordCheck').value;
    if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
      let verifyPassword = AC.get('confirmPassword').value;
      if (password != verifyPassword) {
        AC.get('confirmPassword').setErrors({ matchPassword: true });
      } else {
        return null;
      }
    }
  }

  Updateform() {

    this.AccountSettingForm = new FormGroup({

      FirstName: this.fb.control(''),
      LastName: this.fb.control(''),
      //email: this.fb.control('', [Validators.email, Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      phoneNumber: this.fb.control('', [Validators.required,
      Validators.pattern("^[0-9]*$"),
      Validators.minLength(10), Validators.maxLength(10)]),
      city: this.fb.control(''),
      region: this.fb.control(''),
      colortheme: this.fb.control(''),
      // Creating controls for RESET PWD
      oldPassword: this.fb.control(''),
      newPasswordCheck: this.fb.control(''),
      confirmPassword: this.fb.control(''),
      picture: this.fb.control(''),
    },
      this.matchPassword
    );
  }

  get f() { return this.AccountSettingForm.controls; }

  public UpdatePassWord() {
    debugger;
    this.AccountSettingForm.markAllAsTouched();

    var missmatch = this.passwordMatchValidator(this.AccountSettingForm);


    if (missmatch == false && this.validationtrue == false) {
      this.UpdatePassWordCheck(this.AccountSettingForm.get('newPasswordCheck').value);

    }
    else {
      return false;
    }
  }

  public UpdateThemeColor() {
    debugger;
    this.AccountSettingForm.markAllAsTouched();

    this.UpdateProfilePhotoCheck();
    // var missmatch = this.passwordMatchValidator(this.AccountSettingForm);


    // if (missmatch == false && this.validationtrue == false) {
    //   this.UpdateThemeColorCheck(this.AccountSettingForm.get('newPasswordCheck').value);

    // }
    // else {
    //   return false;
    // }
  }

  UpdateProfilePhotoCheck() {
    debugger;
    const formData = new FormData();
      for (const key of Object.keys(this.AccountSettingForm.value)) {
        var value = this.AccountSettingForm.value[key];
        formData.append(key, value);
      }
    var modelGroup2 = {
      "Email": this.Email,      
      "ColorTheme": this.colortheme
    }
    this.accountService.uploadUserPhoto(formData)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.GetUserDetailbyID();
          this.showNotification("success", "Please log in again, to apply changes.")

        },
        error => {
        });

  }
  logout() {
    this.accountService.logout();
  }

  UpdateThemeColorCheck(newPassword: any) {
    var modelGroup2 = {
      "Email": this.Email,
      "NewPassWord": newPassword,
      "ColorTheme": this.colortheme
    }
    this.accountService.Resetpassword(modelGroup2)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.showNotification("success", "Please log in again, to apply changes.")

        },
        error => {
        });

  }

  passwordMatchValidator(formGroup: FormGroup) {
    const password = formGroup.get('newPasswordCheck').value;
    const confirmPassword = formGroup.get('confirmPassword').value;

    if (password == confirmPassword) {
      return false;
    } else {
      return true;
    }

  }



  onClickConfirm() {

    if (this.passwordConfirm === 'password') {
      this.passwordConfirm = 'text';
      this.showConfirm = true;
    } else {
      this.passwordConfirm = 'password';
      this.showConfirm = false;
    }
  }

  onClick() {

    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  onFileChanged(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      // this.labelImport.nativeElement.innerText = file.name;
      document.getElementById("choosefile").innerHTML = file.name;
      this.AccountSettingForm.patchValue({
        picture: file,
      });

      // for updating Profile Photo
      this.UpdateThemeColor();
    }
  }

  UpdatePassWordCheck(newPassword: any) {
    var modelGroup2 = {
      "Email": this.Email,
      "NewPassWord": newPassword,
      "ColorTheme": this.colortheme
    }
    this.accountService.Resetpassword(modelGroup2)
      .subscribe(
        (data: any) => {
          this.submitted = false;
          this.showNotification("success", "Password changes successfully.Please login with new password.")
          this.decline();
          this.logout();

        },
        error => {
        });

  }

}
