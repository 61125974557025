
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';

import { AddgroupService } from '../_services/addgroup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-list-postions',
  templateUrl: './list-postions.component.html',
  styleUrls: ['./list-postions.component.less']
})
export class ListPostionsComponent implements OnInit {
  AddPositionForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,

    private addgroupService:AddgroupService,
    private router: Router,
    notifier: NotifierService
    ) {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
    deleting = false;
    modalRef: BsModalRef;
   // for getting confirm box from HTML by Id
   @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

   // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  loading = false;
  submitted = false;

  Positionlist=[];
deleteitem:any=0;
edititemid:any=0;
editvaluename:any;
updateid:any;
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ngOnInit(): void {
    this.CreateGroupFrom();
    this.GetPositionlist();
  }

  // function for creating from
CreateGroupFrom() {
  this.AddPositionForm = new FormGroup({
    positionsName: this.fb.control('', Validators.required),
    Editposition: this.fb.control('', Validators.required)
  });
}

// function for open model pop up
openModal(template: TemplateRef<any>,itemid:any) {
  debugger;
  this.deleteitem=itemid;
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
}
// edit functionality
// function for open model pop up
EditModalpopup(template: TemplateRef<any>,itemid:any) {
  debugger;
  this.edititemid=itemid;
  var modelGroup = {
    "id": itemid
  }

  this.addgroupService.EditpositionList(modelGroup)
  .subscribe(
    data => {
      debugger;
      this.editvaluename=data[0].positionsName;
      this.updateid=data[0].id;

      this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

    },
    error => {
    });


}

public showNotification( type: string, message: string ): void {
  this.notifier.notify( type, message );
}

closeEditModel(){
  this.modalRef.hide() ;
}

closepopupmodel(){
  this.AddPositionForm.invalid
}

openAddGroupModel(targetModal) {


  this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

}

// for update the edited part
update()
{
  debugger;
  this.submitted = true;

  if (this.AddPositionForm.controls.Editposition.invalid) {
    this.isValid = false;
    return;
  }
  var modelGroup = {
    "id": this.updateid,
    "PositionsName": this.editvaluename,
  }
  this.addgroupService.UpdatepostionName( modelGroup)

  .subscribe(
    (data: any) => {
      this.GetPositionlist();
      this.submitted = false;
      this.showNotification("success", "updated successfully");
      this.CreateGroupFrom();

      this.modalRef.hide() ;
    // this.Grouplist = data;
    },
    error => {
    });
}

// public showNotification(type: string, message: string): void {
//   this.notifier.notify(type, message);
// }

get f() { return this.AddPositionForm.controls; }

ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    debugger
    this.submitted = true;

    if (this.AddPositionForm.controls.positionsName.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "PositionsName": this.AddPositionForm.controls.positionsName.value
      }


      this.addgroupService.Saveaddposition(modelGroup)
      .subscribe(
        (data: any) => {
          this.GetPositionlist();
          debugger
          this.submitted = false;
           //this.showNotification("success", "saved successfully");
           this.showNotification("success","Data saved successfully")
        this.CreateGroupFrom();


       this.modalRef.hide() ;

          this.closeEditModel();
          this.closepopupmodel();
          this.modalcloseOpen.hide();



          //this.modalRef.hide() ;
        // this.Grouplist = data;
        },
        error => {
        });
      this.loading = true;
      this.isValid = true;
    }


  }


  CloseAddmodel()
  {
    this.modalRef.hide();
  }

// function for confirm  delete
confirm(): void {
  debugger;
this.deleteitem;
var modelGroup = {
  "Id": this.deleteitem
}
this.addgroupService.DeletePosition(modelGroup)
  .subscribe(
    data => {
      this.showNotification("success", "deleted successfully");
      this.GetPositionlist();
    },
    error => {
    });

  this.modalRef.hide();

}
decline(): void {
  this.modalRef.hide();
}

displayStyle = "none";

openPopup() {
  this.displayStyle = "block";
}
closePopup() {
  this.displayStyle = "none";
}


//Calling get list of group on page load

GetPositionlist()
{  debugger;
  this.addgroupService.GetPositionlist()
  .subscribe(
    (data: any) => {
      debugger;
     this.Positionlist = data;
     this.modalRef.hide() ;
    },
    error => {
    });
}


// calling for delete group

//  Deletegrouplist(Id) {

//   var modelGroup = {
//     "id": Id
//   }
//   this.addgroupService.Deletegrouplist(modelGroup)
//   .subscribe(book=>{

}
