<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="AuctionsVehiclesListForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="copytable('usertable')">Copy</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="exportexcel()">Excel</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>
                        <!-- <div class="col-auto mx-md-0">
                            <div class="thanksbanner">
                                Thank you for using AithX - Credit App, found any bugs? Or want to suggest anything? Please
                                <button type="button" style="font-size:11px;" (click)="OpenModel(templateReportbug,'');"
                                class="btn btn-xs btn-warning ml-2">Click Here</button>
                            </div>
                        </div> -->
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center mb-1 mx-0">
                                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3">
                                    <label style="padding:2px;"> Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr class="my-2">
                    <!-- <div class="row" *ngIf="hideSupControlsButton ==='false'" >
                        <div class="form-group mb-1 col-auto">
                            <div class="row">
                                <label class="col-form-label col-auto"><b>Search by Dealers</b></label>
                                <div class="input-group dealername col" style="max-width:300px; min-width:300px;">
                                    <ng-select style="font-size:14px;" [items]="dealerlistForsearch" style="width:100%;"
                                        [dropdownPosition]="true" bindLabel="dealerName" placeholder="Select Dealer"
                                        [(ngModel)]="SelectSearchDealer" (change)="SerachDealer($event)">
                                    </ng-select>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="row" *ngIf="hideSupControlsButton ==='true'" >
                        <div class="form-group mb-1 col-auto">
                            <div class="row">
                                <label class="col-form-label col-auto"><b>Search by Dealers</b></label>
                                <div class="input-group dealername col" style="max-width:300px; min-width:300px;">
                                    <input name="dealerNametextbox" readonly type="text" [(ngModel)]="dealerNametextbox"
                                     formControlName="dealerNametextbox"
                                                class="form-control">
                                </div>
                            </div>
                        </div>
                    </div> -->


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">List of Vehicles</h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <button type="button" title="Get Latest Vehicles" (click)="GetLatestListOfVehiclefromEblock()" class="btn btn-xs btn-warning d-inline-block mr-2 rounded-pill">Get Latest Vehicles </button>
                                            <h6 class="mb-0 d-inline-block"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive latest-vehicles">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>


                                                    <th (click)="sort('companyId')"> Company Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th style="min-width:160px;" (click)="sort('captureType')"> Capture
                                                        Type <i class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('mileage')"> Mileage <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('make')"> Make <i class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th (click)="sort('model')"> Model <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('transmission')"> Transmission <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('fuelType')"> Fuel Type <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('driveTrain')"> Drive Train <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('vtrim')"> Trim <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('engine')"> Engine <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('exteriorColor')"> Exterior Color <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th class="Notes"> Notes  </th>

                                                    <th > Pull Address  </th>
                                                    <th > Seller </th>
                                                    <th (click)="sort('startingBid')"> Starting Bid <i
                                                        class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th >Bid Currency </th>
                                                    <th (click)="sort('currentBid')"> Current Bid <i
                                                        class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th >Current Bid Currency</th>
                                                    <th (click)="sort('nextBid')"> Next Bid <i
                                                        class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th >Next Bid Currency </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let dealerVehicleslistdata of creditorslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize;let i = index">

                                                    <td>{{ dealerVehicleslistdata.seller }}
                                                    </td>
                                                    <td>{{ dealerVehicleslistdata.captureType }} </td>

                                                    <td>{{ dealerVehicleslistdata.vin }}</td>
                                                    <td>{{ dealerVehicleslistdata.mileage}}</td>
                                                    <td>{{ dealerVehicleslistdata.make}}</td>
                                                    <td>{{ dealerVehicleslistdata.model}}</td>
                                                    <td>{{ dealerVehicleslistdata.transmission}}</td>
                                                    <td>{{ dealerVehicleslistdata.fuelType}}</td>
                                                    <td>{{ dealerVehicleslistdata.driveTrain}}</td>
                                                    <td>{{ dealerVehicleslistdata.vtrim}}</td>
                                                    <td>{{ dealerVehicleslistdata.engine}}</td>
                                                    <td>{{ dealerVehicleslistdata.exteriorColor}}</td>
                                                    <td class="Notes">{{ dealerVehicleslistdata.notes}}</td>
                                                    <td>{{ dealerVehicleslistdata.pulAddress1}} {{ dealerVehicleslistdata.pulAddress2}}
                                                        {{ dealerVehicleslistdata.pulCity}} {{ dealerVehicleslistdata.pulCountry}}
                                                        {{ dealerVehicleslistdata.pulRegionCode}} {{ dealerVehicleslistdata.pulZipCode}}
                                                    </td>
                                                    <td>{{ dealerVehicleslistdata.seller}}</td>
                                                    <td>{{ dealerVehicleslistdata.startingBid | currency}}</td>
                                                    <td>{{ dealerVehicleslistdata.startingBidCurrency}}</td>

                                                    <td>{{ dealerVehicleslistdata.currentBid | currency}}</td>
                                                    <td>{{ dealerVehicleslistdata.currentBidCurrency}}</td>

                                                    <td>{{ dealerVehicleslistdata.nextBid | currency}}</td>
                                                    <td>{{ dealerVehicleslistdata.nextBidCurrency}}</td>

                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>

                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <div class="col-auto px-0">
                                                    <ngb-pagination [collectionSize]="creditorslist.length"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        [boundaryLinks]="true"></ngb-pagination>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>



                                        <a #ConfirmBoxSetWorkSheetdetail style="display: none;" [routerLink]=""
                                            (click)="openModalSetWorkSheetDetail(templateSetWorkSheet)"><i
                                                class="fa fa-upload" triggers="mouseenter:mouseleave"
                                                popoverTitle="Delete Users" placement="top"
                                                ngbPopover="Are you sure you want to delete?"></i></a>
                                        <ng-template #templateSetWorkSheet>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to set worksheet detail?</p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirmSetWorkSheetDetail()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>

                                        <a #ConfirmBoxGetWorkSheetdetail style="display: none;" [routerLink]=""
                                            (click)="openModalgetWorkSheetDetail(templateWorkSheet)"><i
                                                class="fa fa-upload" triggers="mouseenter:mouseleave"
                                                popoverTitle="Delete Users" placement="top"
                                                ngbPopover="Are you sure you want to delete?"></i></a>
                                        <ng-template #templateWorkSheet>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to get worksheet detail?</p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirmGetWorkSheetDetail()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>

                                        <a #ConfirmBoxGetdetail style="display: none;" [routerLink]=""
                                            (click)="openModalgetDetail(templateGetDetail)"><i class="fa fa-upload"
                                                triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                                                placement="top" ngbPopover="Are you sure you want to delete?"></i></a>

                                        <a #ConfirmBoxFindcarpopup style="display: none;" [routerLink]=""
                                            (click)="openModalFindCar(templateFindCarTradeValue)"><i
                                                class="fa fa-upload" triggers="mouseenter:mouseleave"
                                                popoverTitle="Delete Users" placement="top"
                                                ngbPopover="Are you sure you want to delete?"></i></a>


                                        <ng-template #templateGetDetail>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to get application details (Name , email etc)?
                                                </p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirmGetAppDetail()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>





                                        <a #ConfirmBox style="display: none;" [routerLink]=""
                                            (click)="openModal(template)"><i class="fa fa-upload"
                                                triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                                                placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
                                        <ng-template #template>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to delete?
                                                </p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirm()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mt-2 mx-0" *ngIf="apiDetailValue==='true'">
            <div id="accordionExample" class="accordion">
                <div class="col-md-12">
                    <div class="card card-raised h-100">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-sm-6">
                                    <h5 class="card-title text-white text-uppercase mb-0">Application Detail</h5>
                                </div>
                                <div class="ml-auto col-auto px-2">
                                    <button type="button" data-toggle="collapse" data-target="#CreditAPILog"
                                        aria-expanded="true" aria-controls="CreditAPILog" class="btn text-white plusbtn"
                                        style="padding: 0; font-size: 13px;">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div id="CreditAPILog" aria-labelledby="BuildCar" data-parent="#accordionExample"
                            class="collapse show">
                            <div class="card-body mb-2">
                                <div class="row">
                                    <div class="form-group col-xl-3 col-md-6 col-12">
                                        <label>Status</label>
                                        <h6>{{selectedList.appstatus}}</h6>
                                    </div>
                                    <div class="form-group col-xl-3 col-md-6 col-12">
                                        <label>Display Name</label>
                                        <h6>{{selectedList.displayName}}</h6>
                                    </div>
                                    <div class="form-group col-xl-3 col-md-6 col-12">
                                        <label>Tier Name</label>
                                        <h6>{{selectedList.tierName}}</h6>
                                    </div>
                                    <div class="form-group col-xl-3 col-md-6 col-12">
                                        <label>Frequency</label>
                                        <h6>{{selectedList.frequency}}</h6>
                                    </div>
                                </div>




                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #templateCheckVin>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>VIN already exist
                </p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="declineCheck()">Ok</button>
            </div>
        </ng-template>


        <ng-template #ModelPopUpImages let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Images</h5>
                <button type="button" class="close" style="color: white;" #closebutton
                    (click)="RefreshImageList();CloseAddmodel();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report imageSize row">

                    <div class="card card-raised mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Auto Upload Images</h6>
                        </div>
                        <div class="card-body p-2">


                            <div *ngIf="errorshow=='false'"
                                style="width:750px; height:100%; text-align:center;margin:0; padding:0;">
                                <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                                    [imageSize]="{width: '100%', height:200}" slideImage="1">
                                </ng-image-slider>

                            </div>

                            <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                                {{errorMessage}}
                            </div>
                        </div>
                    </div>

                </section>

                <section class="manual-uploadImage">
                    <div class="card card-raised  mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Manual Upload Images</h6>
                        </div>
                        <div class="card-body p-2">
                            <div class="profile-icon" *ngFor="let element of customimagesrc">
                                <img [src]="element.imgsrc" width="50" height="50" />
                            </div>
                        </div>
                    </div>
                </section>

            </div>
            <div class="modal-footer">


                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="RefreshImageList();CloseAddmodel();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>



        <ng-template #ResponseModelPopup>
            <div class="modal-header" style="background-color: black;">
                <h6 class="modal-title" id="AccounttransferLabel" style="color: white;">Response Detail
                </h6>
                <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report col-12">

                </section>


            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-success btn-sm text-white rounded-pill" (click)="SaveReponseDetail()"
                    title="Save">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                </button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" title="Cancel" (click)="decline()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

        <ng-template #templateReportbug let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Need Help? Want to Report a
                    Bug?</h5>
                <button type="button" class="close" style="color: white;" #closebutton
                    (click)="RefreshImageList();CloseAddmodel();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <div class="row">
                    <div class="col-12">
                        <div class="form-group mb-2">
                            <label class="col-form-label">
                                Requester (Please enter your email address)<span class="text-danger"
                                    style="vertical-align:middle;">*</span></label>
                            <input type="text" formControlName="requester" [(ngModel)]="requester" class="form-control">
                        </div>
                        <div class="form-group mb-2">
                            <label class="col-form-label">Dealership Name</label>
                            <input type="text" formControlName="DealershipName" readonly [(ngModel)]="DealershipName"
                                class="form-control">

                        </div>
                        <div class="form-group mb-2">
                            <label class="col-form-label">Please Confirm your Phone #</label>
                            <input name="phone" type="phone" [(ngModel)]="phone" formControlName="phone"
                                class="form-control">
                        </div>
                        <div class="form-group mb-2">
                            <label class="col-form-label">Subject
                                <span class="text-danger" style="vertical-align:middle;">*</span></label>
                            <input name="Subject" type="text" class="form-control" readonly formControlName="Subject"
                                [(ngModel)]="Subject">
                        </div>
                        <div class="form-group mb-2">
                            <label class="col-form-label">Description
                                <span class="text-danger" style="vertical-align:middle;">*</span></label>
                            <textarea type="text" formControlName="Description" [(ngModel)]="Description"
                                class="form-control"></textarea>
                        </div>

                    </div>
                </div>
            </div>
            <div class="modal-footer">

                <button type="submit" (click)="SendQuery();" class="btn btn-sm bg-gradient-success rounded-pill">Submit</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>


            </div>
        </ng-template>

    </form>


    <ng-template #templateFindCarTradeValue>
        <div class="modal-body">
            <span class="cross-icon"><i class="far fa-times-circle"></i></span>
            <div class="col-12">
                <div class="row">
                    <div class="form-group col-12">
                        <label>Trade Value</label>
                        <input type="text" class="form-control" [(ngModel)]="TradeValueInput" placeholder="TradeValue">
                    </div>
                    <div class="form-group col-12">
                        <label>Lien Amount</label>
                        <input type="text" class="form-control" [(ngModel)]="LienInput" id="Lien" placeholder="Lien">
                    </div>
                    <div class="form-group col-12">
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                            (click)="confirmOpenFindcar()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</section>