<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="ArchivedVehiclesForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row justify-content-between align-items-center">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="copytable('usertable')">Copy</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="exportexcel()">Excel</button>
                                </li>

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>

                       

                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center mb-1 mx-0">
                                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3">
                                    <label style="padding:2px;"> Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                    <hr class="my-2" />
                    <div class="row mt-2">
                        <div class="col-12 col-lg-12 ">
                            <div class="row" *ngIf="hideSupControlsButton ==='true'">
                                <div class="form-group col-auto mb-1">
                                    <div class="row">
                                        <label class="col-form-label col-auto"><b>Dealer Group</b></label>
                                        <div class="col-auto">
                                            <div class="input-group" style="min-width:190px;max-width:190px;">
                                                <ng-select style="width:100%;" [items]="GroupLists"
                                                    bindLabel="globalCodeName" [disabled]="groupDisable"
                                                    (change)="GetDealerNamebyGroupId(selectedGroupIds)"
                                                    [ngModelOptions]="{standalone: true}" placeholder="Select group"
                                                    appendTo="body" [multiple]="false" [(ngModel)]="selectedGroupIds">
                                                </ng-select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group col mb-1" id="heightAuto" style="display: none;">
                                    <div class="row">


                                        <label class="col-form-label col-auto">Select Dealers</label>
                                        <div class="input-group dealername col"
                                            style="max-width:max-content;min-width:415px;">
                                            <ng-select style="width:100%;" [items]="dealerlistName" id="dealerName"
                                                [dropdownPosition]="true" bindLabel="dealerName"
                                                [ngModelOptions]="{standalone: true}" placeholder="Select dealer Name"
                                                appendTo="body" (change)="ClearDealer($event)" (click)="CheckClick()"
                                                [multiple]="true" [(ngModel)]="dealersmultiselectarray">
                                            </ng-select>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-auto px-md-0 ml-2">

                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <div class="row">
                                                <h5 class="card-title text-white text-uppercase col-auto mb-0">
                                                    List of Warranty Logs
                                                </h5>
                                                <div class="col-md-auto col-sm-12" style="display: none;">
                                                    <div class="col-auto unitsOption px-0 mt-0">
                                                        <div class="form-row">
                                                            <label class="labelradio" *ngFor="let radiobutton of radioItems">
                                                                <input type="radio" name="options" (click)="setradio(radiobutton)"
                                                                    [checked]="radiobutton ==='Archived'"> <b>{{radiobutton}}</b>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive warranty-logs">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('currentDateTime')"> Date Time <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('logType')"> Log Type <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('userName')"> User Name <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('vehicleListPrice')"> VehicleListPrice <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    
                                                    <th (click)="sort('vehicleInformation')"> Vehicle Information<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('gwProducts')">GW Products <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('lienHolderName')">Lien HolderName <i class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('orderfirstName')">First Name <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('orderlastName')">Last Name <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('email')">Email <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('phone')">Phone<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('postalCode')" style="min-width:170px;">Postal Code<i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('city')">City<i class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('provinceStateCode')">Province State Code<i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('address1')">Address<i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                   
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                    
                                                    <td>{{ dealerVehicleslistdata.currentDateTime | date:'medium' }}</td>
                                                    <td>{{ dealerVehicleslistdata.logType }}</td>
                                                    <td>{{ dealerVehicleslistdata.userName }}</td>
                                                    <td>{{ dealerVehicleslistdata.vin }}</td>
                                                    <td>{{ dealerVehicleslistdata.vehicleListPrice | currency }}</td> 
                                                    <td> {{ dealerVehicleslistdata.vehicleInformation }}</td>
                                                    <td class="gw_products"> {{ dealerVehicleslistdata.gwProducts }}</td>
                                                    <td> {{ dealerVehicleslistdata.lienHolderName }}</td>
                                                    <td> {{ dealerVehicleslistdata.orderfirstName }}</td>
                                                    <td> {{ dealerVehicleslistdata.orderlastName }}</td>
                                                    <td> {{ dealerVehicleslistdata.email }}</td>
                                                    <td> {{ dealerVehicleslistdata.phone }}</td>
                                                    <td> {{ dealerVehicleslistdata.postalCode }}</td>
                                                    <td> {{ dealerVehicleslistdata.city }}</td>
                                                    <td> {{ dealerVehicleslistdata.provinceStateCode }}</td>
                                                    <td> {{ dealerVehicleslistdata.address1 }}</td>
                                                    
                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>

                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <div class="col-auto px-0">
                                                    <ngb-pagination [collectionSize]="dealerVehicleslist.length"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        [boundaryLinks]="true"></ngb-pagination>
                                                </div>
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                        <a #ConfirmBox style="display: none;" [routerLink]=""
                                            (click)="openModal(template)"><i class="fa fa-upload"
                                                triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                                                placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
                                        <ng-template #template>
                                            <div class="modal-body text-center">
                                                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                                                <p>Are you sure you want to delete?
                                                </p>
                                                <p></p>
                                                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill"
                                                    (click)="confirm()">Yes</button>
                                                <button type="button" class="btn btn-sm btn-danger rounded-pill"
                                                    (click)="decline()">No</button>
                                            </div>
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <a #ConfirmMarkSoldBox style="display: none;" [routerLink]="" (click)="openModalSold(templateSold)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Sold" placement="top"
                ngbPopover="Would you like to Mark Selected Unit as SOLD?"></i></a>
        <ng-template #templateSold>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>Would you like to Mark Selected Unit as SOLD?</p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="MarkSold()">Yes</button>
                <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
            </div>
        </ng-template>
        <a #ConfirmBoxVINCheck style="display: none;" [routerLink]="" (click)="openModalCheckModel(templateCheckVin)"><i
                class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="VIN" placement="top"
                ngbPopover="VIN already exist."></i>
        </a>
        <ng-template #templateCheckVin>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>VIN already exist
                </p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="declineCheck()">Ok</button>
            </div>
        </ng-template>


        <ng-template #ModelPopUpImages let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Images</h5>
                <button type="button" class="close" style="color: white;" #closebutton
                    (click)="RefreshImageList();CloseAddmodel();" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report imageSize row">

                    <div class="card card-raised mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Auto Upload Images</h6>
                        </div>
                        <div class="card-body p-2">


                            <div *ngIf="errorshowAuto=='false'"
                                style="width:750px; height:100%; text-align:center;margin:0; padding:0;">
                                <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                                    [imageSize]="{width: '100%', height:200}" slideImage="1">
                                </ng-image-slider>

                            </div>

                            <div *ngIf="errorshowAuto=='true'"
                                class="bg-danger text-white col-md-4 mx-auto text-center">
                                {{errorMessageAuto}}
                            </div>
                        </div>
                    </div>

                </section>

                <section class="manual-uploadImage">
                    <div class="card card-raised  mt-2 mx-auto">
                        <div class="card-header card-raised bg-info text-white p-2">
                            <h6 class="mb-0">Manual Upload Images</h6>
                        </div>
                        <div class="card-body p-2" *ngIf="errorshow=='false'">
                            <div class="profile-icon" *ngFor="let element of customimagesrc">
                                <img [src]="element.imgsrc" width="50" height="50" />
                            </div>
                        </div>

                        <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                            {{errorMessage}}
                        </div>

                    </div>
                </section>

            </div>
            <div class="modal-footer">


                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="RefreshImageList();CloseAddmodel();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

    </form>

    <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


    <ng-template #dealercheckbox>
        <div id="pdf-viewer-outer" class="modal-body">
            <h5>Dealer List </h5>
            <hr />
            <div class="row mt-2" id="dealerchckbox">

                <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->

                <div class="col-12 listoption">
                    <input type="checkbox" name="chckboxall" [(ngModel)]="SelectedAllCheck"
                        (click)="Checkalldealer($event)" id="checkall" />
                    <label style="font-size:13px;" class="ml-1">
                        <h6 class="mb-0" style="font-size:14px;"></h6>Select All
                    </label>
                </div> <br />

                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
                    <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected" /> {{item.dealerName}}
                </div>

            </div>

        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="decline()">Back</button>
            <button type="button" class="btn btn-success btn-sm mr-1 rounded-pill" (click)="GetselectedDealer()">Done</button>
        </div>
    </ng-template>

    <a #ConfirmBoxActivateUser style="display: none;" [routerLink]="" (click)="openModal(templateActivateUser)"><i
        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="activate Users"
        placement="top" ngbPopover="Are you sure you want to activate user?"></i></a>
    <ng-template #templateActivateUser>
      <div class="modal-body text-center">
        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
        <p>Are you sure you want to activate unit?</p>
        <p></p>
        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmActivate()">Yes</button>
        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineActivateUser()">No</button>
      </div>
    </ng-template>

    <a #ConfirmBoxArchiveUser style="display: none;" [routerLink]="" (click)="openModal(templateArchiveUser)"><i
        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="activate Users"
        placement="top" ngbPopover="Are you sure you want to activate user?"></i></a>
    <ng-template #templateArchiveUser>
      <div class="modal-body text-center">
        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
        <p>Are you sure you want to Archive unit?</p>
        <p></p>
        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirmArchive()">Yes</button>
        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="declineActivateUser()">No</button>
      </div>
    </ng-template>

</section>