import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DealerVehicles } from '@app/_models/DealerVehicles';
import { ManageInventoryService } from '@app/_services/manageInventory.service';
import { NotifierService } from 'angular-notifier';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { TemplateRef, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalcodeService } from '@app/_services/globalcode.service';
@Component({
  selector: 'app-creditapi-log',
  templateUrl: './creditapi-log.component.html',
  styleUrls: ['./creditapi-log.component.less']
})
export class CreditapiLogComponent implements OnInit {
  private notifier: NotifierService;
  modalRef: BsModalRef;
  displayedImage: string;
  radioTitle: string;
  radioItems: Array<string>;
  apiDetailValue:any;
  radioCurrencyItems: Array<string>;
  ReasonList = [];

  radioAcceptCheckItems: Array<string>;
  radioAcceptItems: Array<string>;
  modalcloseOpen: any;
  radioMakringItems: Array<string>;
  vehicleObject = [];
  errorshow: any;
  errorMessage: string;
  model = { option: 'option3' };
  constructor(private manageInventoryService: ManageInventoryService,
    private spinner: NgxSpinnerService,
    notifier: NotifierService,
    private confirmModalService: BsModalService,
    private modalService: NgbModal,
    private globalcodeService: GlobalcodeService,
    private fb: FormBuilder

  ) {
    this.notifier = notifier;
    this.radioItems = ['ALL', 'Aithrhub', 'Flagged','Favorites','On Clearance','Repo','EV models'];
    this.radioCurrencyItems = ['CAD', 'USD'];

    this.radioAcceptItems = ['Decision', 'Counter Offer' ,'Response Only'];
    this.radioAcceptCheckItems = ['Accept', 'Decline'];

    this.radioMakringItems = ['Mark as My Favorite', 'Mark as Sold', 'Mark as Removed'];

    this.decisionsectionradio_Checked="";
this.decisionsectionradio_Disable="";
  }
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;
  @ViewChild('ConfirmMarkSoldBox', { static: false }) ConfirmMarkSoldBoxClick: ElementRef;
  @ViewChild('ConfirmMarkRemoveBox', { static: false }) ConfirmMarkRemoveBoxClick: ElementRef;
  @ViewChild('MarkButtonCloseBox', { static: false }) MarkButtonCloseBoxClick: ElementRef;
  @ViewChild('ConfirmClearanceBox', { static: false }) ConfirmClearanceBoxClick: ElementRef;
  @ViewChild('ConfirmBoxMarkUnFav', { static: false }) ConfirmBoxMarkUnFavclick: ElementRef;


  //@ViewChild('regioncheckboxpopupbuttonToBeClicked', { static: false }) regionbuttonToBeClicked: ElementRef;
  @ViewChild('regioncheckboxpopupbuttonToBeClicked') regionbuttonToBeClicked: ElementRef

  Vinarraylist:any[]=[];
  isaithr: boolean = true;
  DisplayCurrency:any;
  isaithrselectedvalue: any = "ALL";
  isaithropcode: any = 1;
  markValueTitle:any;
  UserName: any;
  UserEmail: any;
  userPhoneNO: any;
  DealerName: any;
  DealerNumber: any;
  DealerAddress: any;
  DealerEmail: any;
  selectedVin: any;
  UnitFilterName:any;
  OpcodeDetail:any;
  VINLogCheck:any;
  typeofaction: any;
  Make : any;
   Model : any;
    Year: any;
  CreditApiLogForm: FormGroup;
  dealerVehicleslist: DealerVehicles[] = [];
  verifycheckboxes = [];
  TotalRecord: any;
  isDesc: boolean = false;
  column: string = 'dealerName';
  name = 'Angular';
  page = 1;
  pageSize = 10;
  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  MarkVIN:any;
  TypeClick:any;
  Markid:any;
  MarkDealerId:any;
  MarkStockNo:any;
  MarkYear:any;
  MarkMilegge:any;
  MarkMake:any;
  MarkModel:any;
  Marktrim:any;
  Markbody:any;
  Markprice:any;
  VinGetFlagReport: any;
  DealerIdValue: any;
  DealerNameValue: any;
  rolevalue: any;
  userEmail:any;
  userName:any;
  Userid:any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  UserIDValue:any;
  VinValue:any;
  buttonloaderSendEmail: boolean = false;
  buttonloaderBuyEmail: boolean = false;
  selectedList:any;


  OfferRequest:any=0;
  OfferRequestCommment:any;

  COunterOffer:any;
  COunterComment:any;
  COunterDate:any;

  IsCOunterDateVisible:any=false;


  ResponseComment:any;
  AccpetComment:any;
  RejectReason:any;
  Isaccept:any=1;

  OfferDateBindng:any;

  OfferCommentng:any;

  OfferAmountng:any = "0.00";

  offerreadonly:boolean=false;
  newOffer:boolean=false;


  //For three radio button
  SelectionRadiobt:any;


  SelectedVIN:any;
 UnitDealerId :any;
 UunitVechileId:any;
  UnitDealerCost :any;
  UnitListPrice:any;
  OpcodeForOffer:number=0;
  OfferType:number=0;
  OfferFromValue:any;
  statusofOffer:any;

  primeid:any;


// for Region filters
regionlistName = [];
groupRegionWestern:any;
groupRegionCentral:any;
groupRegionEastern:any;
groupRegionother:any;
groupRegionOntario:any;
regionmultiselectarray: any[];
province: any;
ItemCountregion:any;
SelectedAllCheckregion:any;
ExtingItemCountregion:any;

decisionsectionradio_Checked:any;
decisionsectionradio_Disable:any;
Isaccepted_Checked:any;

GroupRegionCheckboxArray=[];


DecisionitemsRadiobutton = [
  {groupName: 'Decision', value: 'Decision',disable:false},
  {groupName: 'Counter Offer', value: 'Counter Offer',disable:false},
  {groupName: 'Response Only', value: 'Response Only',disable:false},
];

selectedValueDecisionRadioBT:any;

  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.dealerVehicleslist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  PageTitle:any;
  ColorTheme:any;
  ngOnDestroy()  {
    let body = document.getElementsByTagName('body')[0];
    body.classList.remove("body-zoomout");
  }
  ngAfterViewInit() {
    this.clickRegionButton();
  }
  ngOnInit(): void {
    let body = document.getElementsByTagName('body')[0];
    body.classList.add('body-zoomout');
    this.Vinarraylist=[];
    this.apiDetailValue="false";
    this.Createform();
    this.GetReasonlist();
    this.PageTitle = "ALL";
    this.Userid = localStorage.getItem('userid');
    this.userName = localStorage.getItem('UserName');
    this.userEmail = localStorage.getItem('email');
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue =localStorage.getItem('userid');

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.isaithropcode="3";

    this.DisplayCurrency="CAD";
    this.decisionsectionradio_Checked="";
    this.decisionsectionradio_Disable="";
    this.Isaccepted_Checked="";

    this.Isaccept=1;
    this.selectedValueDecisionRadioBT="";

    //this.regionbuttonToBeClicked.nativeElement.click();

     // this.GetInventoryHubwholesaleDashborads("3");
    // this.clickRegionButton()
    // for reion Filters
    this.province = localStorage.getItem('Province');
    this.GetProvinceMuiltiSelectlist();

  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  GetAPIDetails(item)
  {
    this.apiDetailValue="true";
    this.selectedList=item
   // console.log('=====================================>',typeof(this.selectedList),this.selectedList)
  }
  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
  }

  openModalResponse(template: TemplateRef<any>,item:any) {
    
 
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  isAllSelected($event,item) {
    this.VinGetFlagReport = item.vin;
    // this.dealerVehicleslist.forEach(val => {
    //   if (val.id == item.id) val.isSelected = !val.isSelected;
    //   else {
    //     val.isSelected = false;
    //   }
    // });


    if ($event.target.checked) {
      this.Vinarraylist.push(item.vin);
    } else {
      let i: number = 0;
      this.Vinarraylist.forEach((item: any) => {
        console.log(item);
        if (item.vin == item.vin) {
          this.Vinarraylist.splice(i, 1);
          return;
        }
        i++;
      });
    }
  }


//radio button selection
SelectionRadioFunction_old(typevalue)
{
  debugger;
  this.SelectionRadiobt=typevalue;
}

SelectionRadioFunction(value: any)
{
debugger;
  if (value == "Decision") {

    this.SelectionRadiobt=999;

    ///this.RadioValue = value;
   // this.DecisonSectionVisible= true;
    //this.CounterSectionVisible= false;
    //this.ResponseSectionVisible= false;
  }
  else if(value == "Counter Offer"){
    this.SelectionRadiobt=100;
    // this.CounterSectionVisible= true;
    // this.ResponseSectionVisible= false;
    // this.DecisonSectionVisible= false;
    // this.RadioValue = value;
  }else if(value == "Response Only")
  {
    this.SelectionRadiobt=111;
    // this.ResponseSectionVisible= true;
    // this.CounterSectionVisible= false;
    // this.DecisonSectionVisible= false;
    // this.RadioValue = value;

  }

}



  GenerateFlagReportList() {
    if (this.VinGetFlagReport != "" && this.VinGetFlagReport != undefined) {

      this.GenerateFlagReport();

    } else {
      this.showNotification("error", "Please select atleast one VIN to get flag report.");
      return;
    }
  }
  GenerateFlagReport() {
    var modelGroup = {
     //"vin": this.VinGetFlagReport, //dynmic
      //"vin": "1FM5K8GT4EGB37824", //static
      "vinarray":this.Vinarraylist
    }
    this.spinner.show();
    this.manageInventoryService.generateFlagReport(modelGroup)
      .subscribe(
        (data: any) => {
          this.PageTitle = "ALL";
         // this.GetInventoryHubwholesaleDashborads("3");
          this.spinner.hide();
          this.Vinarraylist=[];
          this.showNotification("success", "Flag report generate successfully.")



          if (this.isaithrselectedvalue === "Aithrhub") {
            this.isaithr = true;
            this.PageTitle = "Aithr Hub Active";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "1";
            this.GetInventoryHubwholesaleDashborads("1");
          } else if (this.isaithrselectedvalue === "ALL") {
            this.isaithr = false;
            this.PageTitle = "ALL";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "3";
            this.GetInventoryHubwholesaleDashborads("3")
          } else if (this.isaithrselectedvalue === "Flagged") {
            this.isaithr = false;
            this.PageTitle = "Flag ";
            // this.isaithrselectedvalue = value;
            this.isaithropcode = "2";
            this.GetInventoryHubwholesaleDashborads("2")
          } else if (this.isaithrselectedvalue === "Favorites") {
            this.PageTitle = "Favorites";
            this.isaithropcode = "4";
            this.GetInventoryHubwholesaleDashborads("4")
          } else if (this.isaithrselectedvalue === "On Clearance") {
            this.PageTitle = "On Clearance";
            this.isaithropcode = "5";
            this.GetInventoryHubwholesaleDashborads("5");
          } else if(this.isaithrselectedvalue === "Repo")
          {
            this.PageTitle = "Repo";
            this.isaithropcode = "6";
            this.GetInventoryHubwholesaleDashborads("6");
          } else if(this.isaithrselectedvalue ==="EV models")
          {
            this.PageTitle = "EV models";
            this.isaithropcode = "7";
            this.GetInventoryHubwholesaleDashborads("7");
          }
        },
        error => {
        });
  }


  setCurrenyradio(value: any) {
   debugger;

    this.DisplayCurrency= value;

    if (this.isaithrselectedvalue === "Aithrhub") {
      this.isaithr = true;
      this.PageTitle = "Aithrhub";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "1";
      this.GetInventoryHubwholesaleDashborads("1");
    } else if (this.isaithrselectedvalue === "ALL") {
      this.isaithr = false;
      this.PageTitle = "ALL";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "3";
      this.GetInventoryHubwholesaleDashborads("3")
    }else if(this.isaithrselectedvalue === "Flagged") {
      this.isaithr = false;
      this.PageTitle = "Flag ";
     // this.isaithrselectedvalue = value;
      this.isaithropcode = "2";
      this.GetInventoryHubwholesaleDashborads("2")
    } else if(this.isaithrselectedvalue === "Favorites")
    {
      this.isaithr = false;
      this.PageTitle = "Favorites";
     // this.isaithrselectedvalue = value;
      this.isaithropcode = "4";
      this.GetInventoryHubwholesaleDashborads("4")
    } else if(this.isaithrselectedvalue ==="On Clearance")
    {
      this.isaithr = false;
      this.PageTitle = "On Clearance";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "5";
      this.GetInventoryHubwholesaleDashborads("5")
    } else if(this.isaithrselectedvalue ==="Repo")
    {
      this.isaithr = false;
      this.PageTitle = "Repo";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "6";
      this.GetInventoryHubwholesaleDashborads("6")
    } else if(this.isaithrselectedvalue ==="EV models")
    {
      this.isaithr = false;
      this.PageTitle = "EV models";
      //this.isaithrselectedvalue = value;
      this.isaithropcode = "7";
      this.GetInventoryHubwholesaleDashborads("7")
    }

  }

  setradio(value: any) {
debugger;

    if (value === "Aithrhub") {
      this.isaithr = true;
      this.PageTitle = "Aithrhub";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "1";
      this.GetInventoryHubwholesaleDashborads("1");
    } else if (value === "ALL") {
      this.isaithr = false;
      this.PageTitle = "ALL";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "3";
      this.GetInventoryHubwholesaleDashborads("3")
    }else if(value === "Flagged") {
      this.isaithr = false;
      this.PageTitle = "Flag ";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "2";
      this.GetInventoryHubwholesaleDashborads("2")
    } else if(value === "Favorites")
    {
      this.isaithr = false;
      this.PageTitle = "Favorites";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "4";
      this.GetInventoryHubwholesaleDashborads("4")
    } else if(value ==="On Clearance")
    {
      this.isaithr = false;
      this.PageTitle = "On Clearance";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "5";
      this.GetInventoryHubwholesaleDashborads("5")
    } else if(value ==="Repo")
    {
      this.isaithr = false;
      this.PageTitle = "Repo";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "6";
      this.GetInventoryHubwholesaleDashborads("6")
    } else if(value ==="EV models")
    {
      this.isaithr = false;
      this.PageTitle = "EV models";
      this.isaithrselectedvalue = value;
      this.isaithropcode = "7";
      this.GetInventoryHubwholesaleDashborads("7")
    }

  }

  setradioMarkUnits(value: any,item: any) {
    this.Markid=item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.MarkStockNo= item.stockNumber;

    this.MarkMilegge= item.mileage;

    this.Marktrim= item.trim;
    this.Markbody= item.body;
    this.Markprice= item.listedPrice;

    if (value === "Mark as My Favorite") {
      this.markValueTitle = value;
      this.TypeClick="Favorite";
      let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
      el.click();

    } else if (value === "Mark as Sold") {
      this.markValueTitle = value;
      this.TypeClick="Sold";
      let el: HTMLElement = this.ConfirmMarkSoldBoxClick.nativeElement;
      el.click();
    }else if(value === "Mark as Removed") {
      this.markValueTitle = value;
      this.TypeClick="Removed";
      let el: HTMLElement = this.ConfirmMarkRemoveBoxClick.nativeElement;
      el.click();
    } else if(value === "Clearance")
    {
      this.markValueTitle = value;
      this.TypeClick="Clearance";
      let el: HTMLElement = this.ConfirmClearanceBoxClick.nativeElement;
      el.click();
    } else if (value === "Remove from Favorite")
    {
      this.markValueTitle = value;
      this.TypeClick="Clearance";
      let el: HTMLElement = this.ConfirmBoxMarkUnFavclick.nativeElement;
      el.click();

    }

  }

  // IsaithrorFlag(ischeck) {
  //   if (ischeck.target.value === 'InAithr') {
  //     this.isaithr = true;
  //     this.isaithrselectedvalue = ischeck.target.value;
  //     this.isaithropcode = "1";
  //     this.GetInventoryHubwholesaleDashborads("1");
  //   } else {
  //     this.isaithr = false;
  //     this.isaithrselectedvalue = ischeck.target.value;
  //     this.isaithropcode = "2";
  //     this.GetInventoryHubwholesaleDashborads("2")
  //   }
  // }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  Createform() {
    this.CreditApiLogForm = new FormGroup({
      UnitFilterName: this.fb.control(''),

      // controls for Offer
      OfferRequest: this.fb.control(''),
      OfferRequestCommment: this.fb.control(''),

      COunterOffer: this.fb.control(''),
      COunterComment: this.fb.control(''),
      AccpetComment: this.fb.control(''),
      RejectReason: this.fb.control(''),
    });
  }


  GetReasonlist() {
    this.globalcodeService.getReasonList()
      .subscribe(
        (data: any) => {
          this.ReasonList = data;
        },
        error => {
        });
  }
  // function for open model pop up
  Aithrenquiry(template: TemplateRef<any>, item: any, typeofaction: any) {

this.OfferRequest="0";
this.OfferRequestCommment="";

    this.verifycheckboxes = [];
    this.selectedVin = item.vin;
    this.Make = item.make;
    this.Model = item.model;
    this.Year = item.carYr;
    this.typeofaction = typeofaction;
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }

  // function for open model pop up
  MarkOpenModelPopup(template: TemplateRef<any>, item: any, typeofaction: any) {

    // this.verifycheckboxes = [];
    this.Markid=item.id;
    this.MarkVIN = item.vin;
    this.MarkMake = item.make;
    this.MarkModel = item.model;
    this.MarkYear = item.carYr;
    this.MarkDealerId = item.dealerID;
    this.typeofaction = typeofaction;

    this.MarkStockNo= item.stockNumber;

    this.MarkMilegge= item.mileage;

    this.Marktrim= item.trim;
    this.Markbody= item.body;
    this.Markprice= item.listedPrice;


    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  CheckDetails(template: TemplateRef<any>, item: any) {
    this.selectedVin = item.vin;
    this.GetInquiredDetail();
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-md modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  GetInquiredDetail() {
    var modelGroup = {
      "VIN": this.selectedVin
    }

    this.manageInventoryService.getInquiredDetail(modelGroup)
      .subscribe(
        data => {
          this.UserName = data[0].userName;

          this.UserEmail = data[0].userName
          this.userPhoneNO = data[0].phoneNumber
          this.DealerName = data[0].dealerName
          this.DealerNumber = data[0].dealerPhone
          this.DealerAddress = data[0].dealerAddress;
          this.DealerEmail = data[0].dealerEmail;
        },
        error => {
        });


  }
  declineClose() {
     this.confirmModalService.hide(1);
  }


//get Group region

GetGroupRegion(value)
{
debugger;



var regionglobalvalue={
  globalid:value
}


var isexist=0;
if (this.GroupRegionCheckboxArray.find(x => x.globalid == value)) {
  this.GroupRegionCheckboxArray.splice(this.GroupRegionCheckboxArray.findIndex(x => x.globalid == value), 1);
  isexist=1;
}

if(isexist==0)
{
this.GroupRegionCheckboxArray.push(regionglobalvalue);
}

//first i do false to all checkbox start
this.regionlistName.forEach((item, index) => {

  this.regionlistName[index].IsSelected = false
})
//end


this.GroupRegionCheckboxArray.forEach(element => {

  this.regionlistName.forEach((item, index) => {

    if(item.evbI2==element.globalid)
    {
    this.regionlistName[index].IsSelected = true
    }
  })

});

  if (this.GroupRegionCheckboxArray.length == 5) {
    this.SelectedAllCheckregion = true;
  } else {
    this.SelectedAllCheckregion = false;
  }

}






  decline(): void {
    this.modalRef.hide();
  }

  VerifyVehicle(e, type, item: any) {
    if (e.target.checked) {
      var getuserid = localStorage.getItem('userid');
      var checkedverifydata = {
        vin: this.selectedVin,
        type: type,
        loggeduserid: getuserid,
        notes: this.typeofaction,
        hello:"testing",
        ipAddress:localStorage.getItem('IpAddresstoTrack')

      }

      this.verifycheckboxes.push(checkedverifydata);

    }
    else {
      let updateItem = this.verifycheckboxes.find(this.findIndexToUpdate, type);

      let index = this.verifycheckboxes.indexOf(updateItem);

      this.verifycheckboxes.splice(index, 1);

    }
  }



  DataForBuyClick() {
    var getuserid = localStorage.getItem('userid');
    var checkedverifydata = {
      vin: this.selectedVin,
      type: "Buy",
      loggeduserid: getuserid,
      notes: this.typeofaction,
      ipAddress:localStorage.getItem('IpAddresstoTrack')

    }

    this.verifycheckboxes.push(checkedverifydata);
  }


  findIndexToUpdate(type) {
    return type === this;
  }


  IsAcceptedClicked(value: any) {

    if(value=="Accept")
    {
this.Isaccept=1;
    }
    else{
      this.Isaccept=99;
    }

  }

  PostOfferRequestRequest_old() {
    debugger;
        this.buttonloaderSendEmail=true;
        this.buttonloaderBuyEmail =true;
        // if (this.typeofaction == "Buy") {
        //   this.DataForBuyClick();
        // }

        this.spinner.show();
        var modelGroup = {};
        if(this.newOffer!=true)
        {
         modelGroup = {
         // "requestvehicle": this.verifycheckboxes,
         "loggedUserId":localStorage.getItem('userid'),
          "VIN":this.selectedVin,
          "OfferAmount":this.OfferAmountng,
          "OfferComment":this.OfferCommentng

        }
      }
      else{
        modelGroup = {
          // "requestvehicle": this.verifycheckboxes,
          "loggedUserId":localStorage.getItem('userid'),
           "VIN":this.selectedVin,
           "CounterOffer":this.COunterOffer,
           "CounterComments":this.COunterComment,
           "AcceptComments":this.AccpetComment,
           "IsAccepted":this.Isaccept,
           "Reason":this.RejectReason

         }
      }

        if(this.newOffer!=true)
        {
        this.manageInventoryService.MarkOfferFromdollarClick(modelGroup)
          .subscribe(
            (data: any) => {

              if(data.status=="1")
              {
          //    this.VinValue =data.vin;
          this.showNotification("success", "Offer saved.");

              this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
              this.modalService.dismissAll();
             // this.decline();
              this.declineClose();


             this.buttonloaderSendEmail=false;
            this.buttonloaderBuyEmail =false;
              this.spinner.hide();

              // for sending email after email logs
            // this.SendAithrbuyAndVerifyEmailList();
              }
            },
            error => {
            });

          }
          else{
            this.manageInventoryService.updateAIXZOfferLogDetail(modelGroup)
            .subscribe(
              (data: any) => {


            //    this.VinValue =data.vin;
            this.showNotification("success", "Offer Updated.");
                this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
                this.modalService.dismissAll();
                //this.decline();
                this.declineClose();


               this.buttonloaderSendEmail=false;
              this.buttonloaderBuyEmail =false;
                this.spinner.hide();

                // for sending email after email logs
              // this.SendAithrbuyAndVerifyEmailList();

              },
              error => {
              });

          }
      }


      //This method call when offer create from dollar
      PostOfferRequestRequest() {
        debugger;
            var modelGroup = {};
    if(this.isaithropcode==2) // Flagged
    {
      this.OpcodeForOffer=2;
      this.OfferType=0;
    }
    if(this.isaithropcode==5) // OnClearance
    {
      this.OpcodeForOffer=1;
      this.OfferType=1;
    }


    if(this.SelectionRadiobt==111)
    {

      this.OfferCommentng=this.ResponseComment;
    }
    if(this.SelectionRadiobt==100)
    {

      this.OfferCommentng=this.COunterComment;
      this.OfferAmountng=this.COunterOffer;
    }
            if(this.newOffer!=true)   //When new offer create
            {
             modelGroup = {
               "OpCode":this.OpcodeForOffer,
              "loggedUserId":localStorage.getItem('userid'),
              "VIN":this.selectedVin,
              "OfferType":this.OfferType,
              "OfferAmount":this.OfferAmountng,
              "OfferComment":this.OfferCommentng,
              "PrimeId":"0",
              "ActionType":"1",
              "ActionByDealerID":parseInt(this.DealerIdvalue),
              "ActionBy":localStorage.getItem('userid'),
              "ActionAmount":this.OfferAmountng,
              "ActionComments":this.OfferCommentng,
              "ActionToDealerID":this.UnitDealerId,
              "ActionTo":"0",
              "VehicleID":this.UunitVechileId,
              "DealerID":this.UnitDealerId,
              "ListedPrice":this.UnitListPrice,
              "DealerCost":this.UnitDealerCost,
              "IsAccepted":"0",
              "AcceptComments":"",
              "ReasonId":this.RejectReason,
              "ReasonComments":"",
              "CommunicationUpdateCode":"0",
              "OfferExpiresOn":"0",
              "offerFrom":this.OfferFromValue,

            }
          }
          else{    //Existing Offer
            modelGroup = {
              "OpCode":this.OpcodeForOffer,
              "loggedUserId":localStorage.getItem('userid'),
              "VIN":this.selectedVin,
              "OfferAmount":this.COunterOffer,
              "OfferComment":this.OfferCommentng,
              "PrimeId":this.primeid,
              "OfferType":this.OfferType,
              "ActionType":this.SelectionRadiobt,
              "ActionByDealerID":parseInt(this.DealerIdvalue),
              "ActionBy":localStorage.getItem('userid'),
              "ActionAmount":this.OfferAmountng,
              "ActionComments":this.OfferCommentng,
              "ActionToDealerID":this.UnitDealerId,
              "ActionTo":"0",
              "VehicleID":this.UunitVechileId,
              "DealerID":this.UnitDealerId,
              "ListedPrice":this.UnitListPrice,
              "DealerCost":this.UnitDealerCost,
              "IsAccepted":this.Isaccept,
              "AcceptComments":this.AccpetComment,
              "ReasonId":this.RejectReason,
              "ReasonComments":this.AccpetComment,
              "CommunicationUpdateCode":"0",
              "OfferExpiresOn":"0",
              "offerFrom":this.OfferFromValue
             }
          }

            if(this.newOffer!=true)   //When new offer create
            {
            this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)
              .subscribe(
                (data: any) => {

                  if(data.status=="1")
                  {
              //    this.VinValue =data.vin;
              this.showNotification("success", "Offer saved.");
              this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

             //Reinitialize the models start
              this.Isaccept=1;
              this.COunterComment="";
              this.COunterOffer=0;
              this.ResponseComment="";
              this.selectedValueDecisionRadioBT="";
 //Reinitialize the models nd

                  this.modalService.dismissAll();
                 // this.decline();
                  this.declineClose();
                  }
                },
                error => {
                });

              }
              else{
                this.manageInventoryService.MarkOfferFromCleintDashborad(modelGroup)  //existing offer
                .subscribe(
                  (data: any) => {


                //    this.VinValue =data.vin;
                this.showNotification("success", "Offer Updated.");
                this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

                this.Isaccept=1;
                this.selectedValueDecisionRadioBT="";

                    this.modalService.dismissAll();
                    //this.decline();
                    this.declineClose();

                  },
                  error => {
                  });

              }


          }


  PostVerifyvehicleRequest() {
debugger;
    this.buttonloaderSendEmail=true;
    this.buttonloaderBuyEmail =true;
    if (this.typeofaction == "Buy") {
      this.DataForBuyClick();
    }

    this.spinner.show();
    var modelGroup = {
      "requestvehicle": this.verifycheckboxes,
      "VIN":this.selectedVin,
      "OfferAmount":this.OfferRequest,
      "OfferComment":this.OfferRequestCommment

    }

    this.manageInventoryService.PostVerifyVehicledata(modelGroup)
      .subscribe(
        (data: any) => {

          this.VinValue =data.vin;
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);

          this.showNotification("success", "Request saved and communicated via email.");
          this.decline();
         this.buttonloaderSendEmail=false;
         this.buttonloaderBuyEmail =false;
          this.spinner.hide();

          // for sending email after email logs
          this.SendAithrbuyAndVerifyEmailList();
        },
        error => {
        });

  }

  SendAithrbuyAndVerifyEmailList() {
    var modelGroup = {
      "UserId": this.Userid.toString(),
      "VIN": this.VinValue.toString()
    }
    this.manageInventoryService.sendAithrbuyAndVerifyEmail(modelGroup)
      .subscribe(
        data => {
        },
        error => {
        });
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-md markselect' ,backdrop: 'static', keyboard: false });
  }
  MarkUints() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type":this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {

          this.TypeClick="";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit marked as Favorite Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkUnFavUints() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type":this.TypeClick
    }

    this.manageInventoryService.markUnFavoriteUnits(modelGroup)
      .subscribe(
        (data: any) => {

          this.TypeClick="";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
          this.showNotification("success", "Unit removed from Favorite Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  MarkSold() {
     var modelGroup = {
       "UserID": this.Userid,
       "VehicleID": this.Markid.toString(),
       "DealerID": this.MarkDealerId.toString(),
       "VIN": this.MarkVIN,
       "CreatedBy": this.Userid,
       "Type":this.TypeClick
     }

     this.manageInventoryService.markUnits(modelGroup)
       .subscribe(
         (data: any) => {
          this.TypeClick="";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
           this.showNotification("success", "Unit marked as SOLD Successfully");
           this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
         },
         error => {
         });
   }

   MarkRemove() {
     var modelGroup = {
       "UserID": this.Userid,
       "VehicleID": this.Markid.toString(),
       "DealerID": this.MarkDealerId.toString(),
       "VIN": this.MarkVIN,
       "CreatedBy": this.Userid,
       "Type":this.TypeClick
     }

     this.manageInventoryService.markUnits(modelGroup)
       .subscribe(
         (data: any) => {
          this.TypeClick="";
          this.modalService.dismissAll();
          this.decline();
          this.declineClose();
           this.showNotification("success", "Unit marked as REMOVED Successfully");
           this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
         },
         error => {
         });
   }

   MarkDiscounted() {
    var modelGroup = {
      "UserID": this.Userid,
      "VehicleID": this.Markid.toString(),
      "DealerID": this.MarkDealerId.toString(),
      "VIN": this.MarkVIN,
      "CreatedBy": this.Userid,
      "Type":this.TypeClick
    }

    this.manageInventoryService.markUnits(modelGroup)
      .subscribe(
        (data: any) => {
         this.TypeClick="";
         this.modalService.dismissAll();
         this.decline();
         this.declineClose();
          this.showNotification("success", "Unit marked as Discounted Successfully");
          this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
        },
        error => {
        });
  }

  exportexcel() {
    const prepare = [];
    this.dealerVehicleslist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofAithrhubActiveUnits');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofAithrhubActiveUnits.xlsx');
  }
  downloadPdf() {
    var prepare = [];
    this.dealerVehicleslist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.vin);
      tempObj.push(e.stockNumber);
      tempObj.push(e.carYr);
      tempObj.push(e.mileage);
      tempObj.push(e.make);
      tempObj.push(e.model);
      tempObj.push(e.trim);
      tempObj.push(e.body);
      tempObj.push(e.listedPrice);
      tempObj.push(e.dealerCost);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['VIN', 'StockNumber', 'Year', 'Mileage', 'Make', 'Model', 'Trim', 'Body', 'ListedPrice', 'DealerCost']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('ListofAithrhubActiveUnits' + '.pdf');
  }
  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.dealerVehicleslist);
  }


  FilterUnits(event: any) {
    debugger;
    var filterunits = event;
    if (event == "") {
      this.UnitFilterName = "0";
    } else {
      this.UnitFilterName = filterunits;
    }
    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
  }

  openImagesModelPopUp(targetModal, VIN) {
    this.GetVehiclePhotosListByVinList(VIN);
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }


  openMakeOfferModelPopUp(targetModal, VIN,offer:any,unitList:any,status) {
    debugger;
this.statusofOffer=status;

this.UnitListPrice=unitList.listedPrice;
//Re initialize value of nngmodel start
this.RejectReason=0;
this.AccpetComment="";
this.COunterOffer=0.00;
this.COunterDate="";
this.COunterComment="";
this.ResponseComment="";
this.Isaccepted_Checked="";
this.selectedValueDecisionRadioBT=0;
//end initialize

    this.VINLogCheck = VIN;
    this.selectedVin=VIN;
    this.offerreadonly=false;
    this.newOffer=false;
    if (offer > 0) {
      this.newOffer=true;
     this.GetAIXZOfferLogByVINDetail();
     }
     else{
      this.OfferDateBindng="";
      this.OfferCommentng="";
      this.OfferAmountng="0.00";
      this.offerreadonly=false;
     }

     if (unitList != "") {
      this.UnitDealerId = unitList.dealerID;
      this.UnitDealerCost = unitList.dealerCost;
      this.UnitListPrice = unitList.listedPrice;
      this.UunitVechileId = unitList.id;
    }
this.SelectionRadiobt=0;
this.Isaccept=1;
    this.modalcloseOpen = this.modalService.open(targetModal, {
      centered: true,
      backdrop: 'static',
      size: 'lg'
    });
  }

  GetVehiclePhotosListByVinList(VIN: string) {
    var model = {
      "VIN": VIN
    }
    this.manageInventoryService.getVehiclePhotosListByVIN(model)
      .subscribe(
        (data: any) => {
          if (data.length > 0) {
            this.errorshow = 'false';
            for (var i = 0; i < data.length; i++) {
              this.vehicleObject.push({ image: data[i].photoUrl, thumbImage: data[i].photoUrl, title: '' })
            }
          } else {
            this.errorshow = 'true';
            this.errorMessage = "No images for this Vehicle.";
          }

        },
        error => {
        });
  }

   RefreshImageList() {
    this.vehicleObject = [];
  }

  checkUncheckAll(evt) {
    this.dealerVehicleslist.forEach((c) => c.isSelected = evt.target.checked)
  }



  GetInventoryHubwholesaleDashborads(opcode: any) {
    this.spinner.show();
    var modelGroup = {
      "Opcode": opcode,
      "FilterUnits":this.UnitFilterName,
      "DisplayCurrency":this.DisplayCurrency,
      "regionList": this.regionmultiselectarray,
      "LoggedInID": this.UserIDValue,
    }

    this.manageInventoryService.getInventoryHubwholesaleDashborads(modelGroup)
      .subscribe(
        (data: any) => {
          this.dealerVehicleslist = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }


  GetAIXZOfferLogByVINDetail() {
    this.OpcodeDetail="1";
    var modelGroup = {
      "Opcode": this.OpcodeDetail,
      "VIN":this.VINLogCheck

    }

    this.manageInventoryService.getAIXZOfferLogByVINs(modelGroup)
      .subscribe(
        (data: any) => {
          debugger;

          //first time i am doing disable  false
          this.DecisionitemsRadiobutton.forEach(number => {

              number.disable=false;

          })

          this.ResponseComment=data[0].respondedComments;

          this.offerreadonly=true;
          this.primeid=data[0].id;
          let Offerdate = new Date(data[0].offerDateTime);
          this.OfferDateBindng=Offerdate.toDateString();
          this.OfferCommentng=data[0].offerComments;
          this.OfferAmountng=  parseFloat(data[0].offerAmount).toFixed(2);

            if(data[0].counterOffer!=undefined)
            {
                      this.COunterOffer= parseFloat(data[0].counterOffer).toFixed(2);
            }
            else{
            this.COunterOffer="0.00";
            }
          this.COunterComment=data[0].counterComments;

          if(data[0].updatedDateTime!=undefined)
          {
            let Counterdate = new Date(data[0].updatedDateTime);
          this.COunterDate=Counterdate.toDateString();
          this.IsCOunterDateVisible=true;
          }


          //read action type and disabl rest radio button

          if(data[0].actionType==100) //If Counter Offer pass in 100,
          {
            this.SelectionRadiobt=100;

           this.newOffer=true;

            this.selectedValueDecisionRadioBT='Counter Offer';

            this.DecisionitemsRadiobutton.forEach(number => {
              if (number.groupName === "Counter Offer"){
                number.disable=false;
              }
              else{
                number.disable=true;
              }
            })

          }
          if(data[0].actionType==111) //If Only Response Comments = 111
          {
           this.SelectionRadiobt=111;

           this.newOffer=true;
           this.selectedValueDecisionRadioBT='Response Only';

           this.DecisionitemsRadiobutton.forEach(number => {
            if (number.groupName === "Response Only"){
              number.disable=false;
            }
            else{
              number.disable=true;
            }
          })

          }
          if(data[0].actionType==999) //and If Accepted/rejeced pass in 999--- ELSE Zero
          {
           this.SelectionRadiobt=999;
             this.newOffer=true;
            this.selectedValueDecisionRadioBT='Decision';



          this.DecisionitemsRadiobutton.forEach(number => {
            if (number.groupName === "Decision"){
              number.disable=false;
            }
            else{
              number.disable=true;
            }
          })

             if(data[0].isAccepted==1)
             {
              this.Isaccepted_Checked='Accept';
              this.Isaccept=1;
              this.AccpetComment=data[0].acceptComments;
             }
             if(data[0].isAccepted==99)
             {
              this.Isaccepted_Checked='Decline';
              this.Isaccept=99;
              this.RejectReason=data[0].reasonId;
              this.AccpetComment=data[0].acceptComments;
             }
          }

        },
        error => {
        });
  }

  clickRegionButton()
  {
    debugger;
    this.regionbuttonToBeClicked.nativeElement.click();
  }
  // for REgion Fiters
  ClearRegion(event) {
    this.regionbuttonToBeClicked.nativeElement.click();
  }
  CheckRegionClick() {

    this.regionlistName.forEach(obj => {

      obj.IsSelected = false;

    });

    this.regionmultiselectarray.forEach(childObj => {

      var b = this.regionlistName.find(e => e.globalCodeID == childObj.globalCodeID)
      b.IsSelected = true;

    });
    this.regionbuttonToBeClicked.nativeElement.click();
  }

  Checkallregions(e) {
    debugger;
    this.GroupRegionCheckboxArray=[];
    if (e.target.checked == true) {


      // for Three check boxes
      this.groupRegionWestern= true;
      this.groupRegionCentral= true;
      this.groupRegionEastern= true;
      this.groupRegionother=true;
      this.groupRegionOntario = true;

//assigning groupregion
var regionglobalvalue1={
  globalid:1
}
var regionglobalvalue2={
  globalid:2
}
var regionglobalvalue3={
  globalid:3
}
var regionglobalvalue4={
  globalid:4
}

var regionglobalvalue5={
  globalid:5
}
this.GroupRegionCheckboxArray.push(regionglobalvalue1);
this.GroupRegionCheckboxArray.push(regionglobalvalue2);
this.GroupRegionCheckboxArray.push(regionglobalvalue3);
this.GroupRegionCheckboxArray.push(regionglobalvalue4);
this.GroupRegionCheckboxArray.push(regionglobalvalue5);


      //Assigning value selected false which will appera by deafult uncheck
      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = true
      })
    } else {
      //Assigning value selected false which will appera by deafult uncheck
      this.groupRegionWestern= false;
      this.groupRegionCentral= false;
      this.groupRegionEastern= false;
      this.groupRegionother=false;

    this.groupRegionOntario = false;



      this.regionlistName.forEach((item, index) => {
        this.regionlistName[index].IsSelected = false
      })
    }

  }

  regioncheckboxpopup(template: TemplateRef<any>, itemid: any) {
    this.ItemCountregion =0;
    for (let i = 0; i < this.regionlistName.length; i++) {
      if (this.regionlistName[i].IsSelected == true) {
        this.ItemCountregion = this.ItemCountregion + 1;
      }
    }
    this.ExtingItemCountregion = this.regionlistName.length;
    if (this.ExtingItemCountregion == this.ItemCountregion) {
        this.SelectedAllCheckregion =true;
    } else {
      this.SelectedAllCheckregion =false;
    }
    this.modalRef = this.confirmModalService.show(template, {
      class: 'modal-lg modal-dialog-centered', backdrop: 'static',
      keyboard: false
    });
  }
  GetselectedRegions() {

    // functino for getting units

    this.regionmultiselectarray = this.getSelectedregions();
    var selecteditem = this.getSelectedregions();
    this.decline();

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.UserIDValue =localStorage.getItem('userid');
    this.GetInventoryHubwholesaleDashborads(this.isaithropcode);
    // if (this.rolevalue == "SuperAdmin") {
    //   this.UserIDValue =localStorage.getItem('userid');
    //   this.GetInventoryHubwholesaleDashborads("3");

    // } else {
    //   this.UserIDValue ="All";
    //   this.GetInventoryHubwholesaleDashborads("3");
    // }

  }

  getSelectedregions() {
    return this.regionlistName.filter(item => item.IsSelected === true);
  }

  GetProvinceMuiltiSelectlist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {

          //this.Provincelist = data;
          this.GroupRegionCheckboxArray=[];
          this.regionlistName = []; //initialize

          //Assigning value selected false which will appera by deafult uncheck
          this.regionlistName.forEach((item, index) => {
            this.regionlistName[index].IsSelected = true
          })

          this.regionlistName = data;
          this.regionmultiselectarray = this.regionlistName.filter(item => item.globalCodeID == this.province);
         // var selectedchckbox = this.regionlistName.filter(item => item.globalCodeID == this.province);
         var selectedchckbox = this.regionlistName;
          selectedchckbox.forEach((itemcheck, index) => {
             this.regionlistName[index].IsSelected = true


          })

          this.groupRegionWestern= true;
          this.groupRegionCentral= true;
          this.groupRegionEastern= true;
          this.groupRegionother= true;
          this.groupRegionOntario= true;

          var regionglobalvalue1={
            globalid:1
          }
          var regionglobalvalue2={
            globalid:2
          }
          var regionglobalvalue3={
            globalid:3
          }
          var regionglobalvalue4={
            globalid:4
          }
          var regionglobalvalue5={
            globalid:5
          }
          this.GroupRegionCheckboxArray.push(regionglobalvalue1);
          this.GroupRegionCheckboxArray.push(regionglobalvalue2);
          this.GroupRegionCheckboxArray.push(regionglobalvalue3);
          this.GroupRegionCheckboxArray.push(regionglobalvalue4);
          this.GroupRegionCheckboxArray.push(regionglobalvalue5);
          if (data != null) {
          //if (data != null && data.length > 4) {
           // this.regionbuttonToBeClicked.nativeElement.click();

          }

        },
        error => {
        });
  }

  SelectedAccepteddecline(data:any)
  {
    debugger;
    if(data=="Accept")
    {
      this.Isaccept=1;
    }
    if(data=="Decline")
    {
      this.Isaccept=99;
    }

  }

  excitedimgUrl = "../../assets/images/excited/excited.png";
}
