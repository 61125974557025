import { Component, OnInit } from '@angular/core';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import { Label, SingleDataSet } from 'ng2-charts';
import { Account } from '@app/_models';
import { DashboardService } from '../_services/dashboard.service';
import { AccountService } from '@app/_services/account.service';
import { NotifierService } from 'angular-notifier';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-master-dashboard',
  templateUrl: './master-dashboard.component.html',
  styleUrls: ['./master-dashboard.component.less']
})
export class MasterDashboardComponent implements OnInit {
  private notifier: NotifierService;
  Statisticslist = [];
  StatisticsRegionWiselist = [];
  StatisticsDealerWiselist= [];
  // variables for Counts
  dateSynchedReceived: any;
  totalCount: any;
  totalSynchedCount: any;
  totalNotSynchedCount: any;

  key = 'userName'; // set default
  reverse = false;
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  page = 1;
  pageSize = 12;
  pageRegion = 1;
  pageRegionSize = 5;
  pageSizeRegion = 5;

  dealerpage = 1;
  dealerpageSize = 10;
  loading = false;
  accounts: Account[] = [];

  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Test Maruti Dealer', stack: 'a' },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'Test Honda Dealer', stack: 'a' }
  ];

  public chart = {
    "datasets": [
      { "data": [0, 30, 20, 40, 35, 45, 33, 0, 0], "label": "Test Maruti Dealer" },
      { "data": [0, 50, 60, 55, 59, 30, 40, 0, 0], "label": "Test Honda Dealer" },
      // { "data": [45, 45, 45, 45, 45, 45, 45, 45, 45], "label": "Line", "type": "line" }
    ],
    "labels": ["FirstPlaceholder", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday", "LastPlaceholder"],
    "options": {
      "legend": {
        "text": "You awesome chart with average line",
        "display": true,
      },
      "scales": {
        "yAxes": [{
          "ticks": {
            "beginAtZero": true
          }
        }],
        "xAxes": [{
          "ticks": {
            "min": "Monday",
            "max": "Sunday",
          }
        }],
      }
    }
  };

  pieChartOptions: ChartOptions;
  pieChartLabels: Label[];
  pieChartData: SingleDataSet;
  pieChartType: ChartType;
  pieChartLegend: boolean;
  pieChartPlugins = [];

  rolevalue:any;
  dealerNamevalue:any;
  GroupNamevalue:any;
  DealerIdvalue:any;

  constructor(private dashboardService: DashboardService,
    private accountService:AccountService,
    notifier: NotifierService
  ) {
    this.accountService.SaveActivityLog("");
  }

  sort(property) {

    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsRegionWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  
  sortdealer(property) {
   
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.StatisticsDealerWiselist.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  }; 

  searchText;
  searchRegionWiseText;
  ColorTheme:any;
  ngOnInit(): void {
   
    this.GetIpAddress();
    this.pieChartOptions = this.createOptions();
    this.pieChartLabels = ['Test Maruti Dealer', 'Test Honda Dealer', 'Test Maruti Dealer'];
    this.pieChartData = [50445, 33655, 15900];
    this.pieChartType = 'pie';
    this.pieChartLegend = true;

    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    if (this.rolevalue == "DealerAdmin" || this.rolevalue == "SuperAdmin") {
      this.GetDashboardStatisticsDetails();
      this.GetDashboardStatisticsRegionDetails();
      this.GetDashboardStatisticsDealerDetails();



    } else {

      this.GetDashboardStatisticsDetailsByDealerName();
      this.GetDashboardStatisticsRegionDetailsByDealerName();
      this.GetDashboardStatisticsDealerDetailsbyDealerName();
    }

    // this.GetDashboardStatisticsDetails();
    // this.GetDashboardStatisticsRegionDetails();
    // this.GetDashboardStatisticsDealerDetails();



  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  exportexcel() {
    const prepare = [];
    this.StatisticsRegionWiselist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      prepare.push(tempObj);
    });
  
    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['RegionName', 'lastFeedReceivedOn', 'TotalSynched', 'TotalNotSynched', 'TotalCount'],
      ...prepare
    ]);
  
    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'RegionWiseDetail');
  
    / Save the workbook to a file /
    XLSX.writeFile(wb, 'RegionWiseDetail.xlsx');
  }
  printTable() {
    var prepare = [];
    this.StatisticsRegionWiselist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      prepare.push(tempObj);
    });
  
    var table = '<table><thead><tr><th>RegionName</th><th>lastFeedReceivedOn</th><th>TotalSynched</th><th>TotalNotSynched</th><th>TotalCount</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
  
    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();
  
    // Wait for the table to be fully rendered before printing
    newWin.onload = function() {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  } 
  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.StatisticsRegionWiselist);
  }

  exportToCSVDealer(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.StatisticsDealerWiselist);
  }

  downloadPdf() {
    var prepare = [];
    this.StatisticsRegionWiselist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['RegionName', 'lastFeedReceivedOn', 'TotalSynched', 'TotalNotSynched', 'TotalCount']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('RegionWiseDetail' + '.pdf');
  }
  printTableDealer() {
    var prepare = [];
    this.StatisticsDealerWiselist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      prepare.push(tempObj);
    });
  
    var table = '<table><thead><tr><th>Dealer Name</th><th>RegionName</th><th>lastFeedReceivedOn</th><th>TotalSynched</th><th>TotalNotSynched</th><th>TotalCount</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
  
    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();
  
    // Wait for the table to be fully rendered before printing
    newWin.onload = function() {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  } 
  downloadPdfDealer() {
    var prepare = [];
    this.StatisticsDealerWiselist.forEach(e => {
      var tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['Dealer Name', 'RegionName', 'lastFeedReceivedOn', 'TotalSynched', 'TotalNotSynched', 'TotalCount']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('DealerWiseDetail' + '.pdf');
  }

  exportexcelDealer() {
    const prepare = [];
    this.StatisticsDealerWiselist.forEach(e => {
      const tempObj = [];
      tempObj.push(e.dealerName);
      tempObj.push(e.regionName);
      tempObj.push(e.lastFeedReceivedOn);
      tempObj.push(e.totalSynched);
      tempObj.push(e.totalNotSynched);
      tempObj.push(e.totalCount);
      prepare.push(tempObj);
    });
  
    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['Dealer Name', 'RegionName', 'lastFeedReceivedOn', 'TotalSynched', 'TotalNotSynched', 'TotalCount'],
      ...prepare
    ]);
  
    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DealerWiseDetail');
  
    / Save the workbook to a file /
    XLSX.writeFile(wb, 'DealerWiseDetail.xlsx');
  }

  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  GetIpAddress()
  {
    this.dashboardService.getIPAddress().subscribe((res:any)=>{
      console.log(res.ip);
      localStorage.setItem('IpAddresstoTrack', res.ip);
    });
  }

  GetDashboardStatisticsDetailsByDealerName() {
  
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }

    this.dashboardService.getDashboardStatisticsDetailsByDealerName(modelGroup)
      .subscribe(
        data => {
          this.dateSynchedReceived = data[0].lastFeedReceivedOn;
          this.totalCount = data[0].totalCount;
          this.totalSynchedCount = data[0].totalSynched;
          this.totalNotSynchedCount = data[0].totalNotSynched;
        },
        error => {
        });


  }

  GetDashboardStatisticsRegionDetailsByDealerName() {
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }

    this.dashboardService.getDashboardStatisticsRegionDetailsByDealerName(modelGroup)
      .subscribe(
        (data: any) => {
          this.StatisticsRegionWiselist = data;


        },
        error => {
        });
  }

  GetDashboardStatisticsDealerDetailsbyDealerName() {
    var modelGroup = {
      "DealerName":this.dealerNamevalue,
      "DealerId":this.DealerIdvalue,
      "GroupName":this.GroupNamevalue,
      "Role":this.rolevalue
    }
    this.dashboardService.getDashboardStatisticsDealerDetailsbyDealerName(modelGroup)
      .subscribe(
        (data: any) => {
         
          this.StatisticsDealerWiselist = data;
        },
        error => {
        });
  }


  GetDashboardStatisticsDetails() {
    this.dashboardService.getDashboardStatisticsDetails()
      .subscribe(
        (data: any) => {
          this.Statisticslist = data;
          this.dateSynchedReceived = data[0].lastFeedReceivedOn;
          this.totalCount = data[0].totalCount;
          this.totalSynchedCount = data[0].totalSynched;
          this.totalNotSynchedCount = data[0].totalNotSynched;

        },
        error => {
        });
  }

  GetDashboardStatisticsRegionDetails() {
    this.dashboardService.getDashboardStatisticsRegionDetails()
      .subscribe(
        (data: any) => {
          this.StatisticsRegionWiselist = data;


        },
        error => {
        });
  }

  GetDashboardStatisticsDealerDetails() {
    this.dashboardService.getDashboardStatisticsDealerDetails()
      .subscribe(
        (data: any) => {
       
          this.StatisticsDealerWiselist = data;
        },
        error => {
        });
  }


  private createOptions(): ChartOptions {
    return {
      responsive: true,
      maintainAspectRatio: true,
      plugins: {
        labels: {
          render: 'percentage',
          fontColor: ['green', 'white', 'red'],
          precision: 2
        }
      },
    };
  }

}
