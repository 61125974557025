<section id="admindashboard">
    <form class="col-12 px-0 findcardblock" [formGroup]="CreditfindCarForm">
        <div class="container-fluid">
      <!-- <div class="page-header pb-2">
                      <div class="row">
                    <div class="col-lg-12">
                        <h3>Find Cars</h3>
                    </div>
                </div>
            </div>-->

            <div class="row mt-2" id="LendersDIVID">
				<div id="accordionExample" class="accordion">
					<div class="col-md-12">
						<div class="card card-raised h-100">
							<div class="card-header card-raised bg-info text-white">
								<div class="row justify-content-start align-items-center mx-0">
									<h6 class="card-title text-white text-uppercase mb-0">List of Lenders</h6>
									<div class="ml-auto col-auto px-2">
										<button style="padding:0;font-size:13px;" type="button" data-toggle="collapse" data-target="#ListLenders" aria-expanded="true"
											aria-controls="ListLenders" class="btn text-white plusbtn">
											<i class="fas fa-chevron-down"></i></button>
									</div>
								</div>
							</div>
							<div id="ListLenders" aria-labelledby="BuildCar" data-parent="#accordionExample" class="collapse show">
								<div class="card-body mb-2">
									<!-- <div id="Aixcheckbox" class="row">
										<div class="col-md-12 aixcheckboxin">
											<div class="lenderlistName">
												<label>
													<input type="checkbox" value="AIX" />
													AIX
												</label>
											</div>
										</div>
									</div> -->

									<div id="carList" class="row rounded-lg">
										<div class="form-check form-check-inline" data-step="2" data-intro="Select lenders"
											data-title="Step 2">

<!--This was for multiple div start-->
											<!-- <div class="lenderlistName" *ngFor="let optionslist of lenderlists">
												<label>
													<input type="checkbox" name="options"
														(change)="onCheckboxChange(optionslist.id,optionslist.lenderName,$event)"
														value="{{optionslist.id}}" />
													{{optionslist.lenderName}}
												</label>
											</div> -->

											<!--End-->

											<div class="lenderlistName" *ngFor="let optionslist of lenderlists; let index = index">
												<label *ngIf="optionslist.lenderName !== 'Axis' || HideAxises !== 'true'">
													<input type="checkbox"   [checked]="optionslist.isSelected"
														(change)="onCheckboxChange(optionslist.id,optionslist.lenderName,$event)"
														value="{{optionslist.id}}" />
													{{optionslist.lenderName}}
												</label>
											</div>

										</div>
									</div>
									<hr />
									<div class="row mt-1">
										<div class="col-md-3 col-lg-2" id="heightAuto">
											<label>Dealer Group</label>
											<div class="input-group">
												<ng-select style="width:100%;" [items]="GroupLists" bindLabel="globalCodeName"
													[disabled]="groupDisable" (change)="GetDealerNamebyGroupId(selectedGroupIds)"
													[ngModelOptions]="{standalone: true}" placeholder="Select group" appendTo="body"
													[multiple]="false" [(ngModel)]="selectedGroupIds">
												</ng-select>
											</div>
										</div>

										<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
											<label>Select Dealers</label>
											<div class="input-group dealername">
												<ng-select style="width:100%;" [items]="dealerlistName"
												[dropdownPosition]="true"  bindLabel="dealerName"
													[ngModelOptions]="{standalone: true}" placeholder="Select dealer Name"
													appendTo="body" (change)="ClearDealer($event)" (click)="CheckClick()"
													[multiple]="true" [(ngModel)]="dealersmultiselectarray">
												</ng-select>
											</div>
										</div>



										<div class="col-md-4 col-lg-2 mt-md-0 mt-1">
											<label>Province</label>
											<select class="form-control" name="province" formControlName="province"
												[(ngModel)]="province" (change)="ChangeRegion($event)">
												<option value="0" selected="true">Select Province name</option>
												<option *ngFor="let Provincelistdata of Provincelist"
													[value]="Provincelistdata.globalCodeID">
													{{Provincelistdata.globalCodeName}}</option>
											</select>
										</div>

										<div class="col-auto mt-md-0 mt-1 px-2">
											<div class="form-group mb-md-0">
												<div class="w-100">
													<label>Source</label>
													<div class="col-md-12 px-0">
														<select class="form-control" #AithrHubKeyControl
														name="AithrHubKey" (change)="GetAithrHubUnits($event)"
															formControlName="AithrHubKey" id="AithrHubKey"
															 [(ngModel)]="AithrHubKey">
															<option value="0" [selected]="true">Selected Dealers</option>
															<option value="1">AithrHub</option>
														</select>
													</div>
												</div>
											</div>
										</div>

										<div class="col-md-2 col-lg-2  mt-md-0 mt-1 px-2" id="heightAuto" [hidden]="!ShowMultiSelectRegion">
											<label>Select Province</label>
											<div class="input-group dealername">
												<ng-select style="width:100%;" [items]="regionlistName" bindLabel="globalCodeName" [dropdownPosition]="true"
													[ngModelOptions]="{standalone: true}" placeholder="Select Province Name"
													appendTo="body" (change)="ClearRegion($event)" (click)="CheckRegionClick()"
													[multiple]="true" [(ngModel)]="regionmultiselectarray">
												</ng-select>
												<!-- <div class="mt-2 w-100" [hidden]="!ShowMultiSelectRegion">
													<label>Select City</label>
													<select class="form-control" name="dealerCity" formControlName="dealerCity"
												[(ngModel)]="dealerCity" >
												<option value="0" selected="true">Select city name</option>
												<option *ngFor="let citylistdata of citylist"
													[value]="citylistdata.dealerCity">
													{{citylistdata.dealerCity}}</option>
											</select>
												</div> -->
											</div>
										</div>

										<div class="col-auto mt-md-4 mt-lg-0 mt-0 pt-1 multiplecheckbox">

											<div class="custom-control custom-checkbox">
												<input class="custom-control-input"
												(change)="checkTaxValue($event)" formControlName="TaxExemptionCheck" type="checkbox"
													id="TaxExemptionID">
												<label for="TaxExemptionID" class="custom-control-label">Tax Exemption</label>
											</div>
											<div class="custom-control custom-checkbox" style="display: none;">
												<input class="custom-control-input" formControlName="advanceFilterCheck"
												(change)="checkValue($event)"  type="checkbox"
													id="advanceID">
												<label for="advanceID" class="custom-control-label">Advanced Filter</label>
											</div>
											<div class="custom-control custom-checkbox" style="display:none">
												<input class="custom-control-input" (change)="CheckLocakPrice($event)" formControlName="locklistpriceCheck"
												  type="checkbox"
													id="locklistpriceCheck">
												<label for="locklistpriceCheck" class="custom-control-label">Lock List Price</label>
											</div>

											<button #buttonPleasecheck  (click)="PleaseCheck();" style="display: none;"  >CheckBUtton</button>
										</div>


										<!-- </div> -->
										<!-- //End onde div panel -->

									</div>
									<!-- filter code start -->
									<hr />
									<div id="advanced-filters"
									*ngIf="advanceFilter ==='true'" class="mt-1">
										<div class="row">
											<div class="col-md-12">
												<h6>Advanced Filters</h6>
											</div>
										</div>
										<div class="row">
											<div class="col-md-3 col-lg-2">
												<label>Make</label>
												<input id="makefilter" class="form-control" type="text"
												(input)="MakeCheck($event)"
													formControlName="MakeFilterControl">
											</div>

											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Model</label>
												<input id="Modelfilter"
												(input)="ModelCheck($event)"  class="form-control" type="text"
													formControlName="ModelFilterControl">
											</div>

											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Body Style</label>
												<input id="Modelfilter"
												(input)="BodyCheck($event)"  class="form-control" type="text"
													formControlName="BodyStyleFilterControl">
											</div>
											<div class="col-md-3 col-lg-3  mt-md-0 mt-1" id="heightAuto">
												<label>Year greater than equal to</label>
												<input id="Seatfilter"
												(input)="SeatCheck($event)"  class="form-control" type="text"
													formControlName="NumberOfSeatsFilterControl">
											</div>
										</div>
									</div>
									<!-- filter code end -->
								</div>
							</div>
						</div>
					</div>
				</div>



	</div>


   <div class="Findcars-container">

<div class="col px-sm-3"  id="findcarcloneid">

</div>

	</div>
</div>


        <div formArrayName="LenderDetailsformarray">


			<!-- <div *ngFor="let name of lendersarrayvalue.controls; index as idx">
				<input formControlName="LenderName" placeholder="Enter a Name">
				<label>{{ name.value.LenderName }}</label>
				<label *ngIf="name.invalid" [ngClass] = "'error'"> Name is required. </label>
			 </div> -->
			 <div class="Findcars-container">
		<div *ngFor="let Lenderformarraydata of LenderDetailsformarray.controls; let i = index;trackBy:trackFn" class="ledermaindiv" id="findlenderdiv_{{Lenderformarraydata.value.Lenderid}}">


			<div [formGroupName]="i" style="display: flex">

			<div class="col px-sm-3"  id="findcarcloneid">

				<!-- //FILTER LENDER AREA -->
				<div  class="row mt-1 mb-1">
				  <div  class="col-md-12">
					<div class="card card-raised h-100">
					  <div class="card-header card-raised bg-info text-white">
						<div class="row justify-content-between align-items-center">
						  <div class="col-lg-12 text-white text-uppercase mb-0 mt-1">
						    <div class="row justify-content-between align-items-center">
								<div class="col-md-auto col pt-1">
									<h6 class="lendername d-inline-block mb-0">{{Lenderformarraydata.value.LenderName }}</h6>
								</div>
								<div class="col-lg-2 col-md-auto ml-auto text-right mt-2 mt-md-0">
									<button type="button"  (click)="getUnitsByDealerNames(i);" *ngIf="!buttonloader"
							  class="multisearch btn btn-sm btn-warning bg-gradient-warning lenderfindcarsearch">Search</button>
								</div>
							</div>
							<hr/>
							<div id="tdblock" class="row mt-2 justify-content-between align-items-center">
								<div class="col-12">
									<div class="row mt-1">
										<div class="form-group col-12 col-sm-12 col-md-auto mb-1">
											<div class="row">
												<div class="col-auto pt-1">
													<div class="custom-control custom-checkbox pl-0">

														 <input type="checkbox" id="hasGPSValue_{{Lenderformarraydata.value.Lenderid}}"
														 formControlName="hasGpsCheckBox"
															class=""  />


														<label class="text-capitalize ml-1" >Has GPS Fee</label>
													</div>
												</div>
											</div>
										</div>
										<div class="form-group col-12 col-sm-12 col-md-auto mb-1"  >
											<div class="row">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">GPS Fee</label>
												<div class="col">
													<input
													 class="form-control" readonly
													 id="GPSFee_{{Lenderformarraydata.value.Lenderid}}"   type="number"
														formControlName="GPSFeeControl" placeholder="0">
												</div>
											</div>
										</div>
										<div class="form-group col-12 col-sm-12 col-md-auto mb-1">
											<div class="row">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">FLTV Setup</label>
												<div class="col">
													<input id="TDBPCallID"
													id="FLTVSetup_{{Lenderformarraydata.value.Lenderid}}" class="form-control" type="number" readonly
														appTwoDigitDecimaNumberDirective formControlName="FLTVSetupControl" placeholder="0">
												</div>
											</div>
										</div>
										<div class="form-group col-12 col-sm-12 col-md-auto mb-1">
											<div class="row">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">OverRide FLTV</label>
												<div class="col">
													<input appNumericOnly
													id="OverRideFLTV_{{Lenderformarraydata.value.Lenderid}}"
													class="form-control" type="number"
													min="0" maxlength="3" (input)="restrictInputToThreeDigits($event)"
														formControlName="OverRideFLTVControl" placeholder="0">
												</div>
											</div>
										</div>

										<div class="form-group col-12 col-sm-12 col-md-auto mb-1" >
											<div class="row">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Max Term</label>
												<div class="col">
													<input appNumericOnly
													id="OverrideTerm_{{Lenderformarraydata.value.Lenderid}}"
													class="form-control" type="number"
													min="0" maxlength="3"
														formControlName="OverrideTermControl" placeholder="0">
												</div>
											</div>
										</div>

										<div class="form-group col-12 col-sm-12 col-md-auto mb-1">
											<div class="row">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Max Amount Finance</label>
												<div class="col">
													<input
													id="MaxAmountFinance_{{Lenderformarraydata.value.Lenderid}}"
													class="form-control" type="number"
													min="0" maxlength="3" placeholder="0" formControlName="MaxAmountFinanceControl">
												</div>
											</div>
										</div>
										<div class="form-group col-12 col-sm-12 col-md-auto mb-1 AllowableLossDiv" id="AllowableLossDiv_{{Lenderformarraydata.value.Lenderid}}">
											<div class="row" *ngIf="ShowAllowable ===true">
												<label class="col-auto col-12 col-sm-4 col-md-auto col-form-label text-capitalize">Allowable Loss</label>
												<div class="col">
													<input
													class="form-control" type="number"
													id="AllowableLoss_{{Lenderformarraydata.value.Lenderid}}"
													min="0" maxlength="3" placeholder="0" formControlName="AllowableLossControl">
												</div>
											</div>
										</div>
									</div>
								</div>

						   </div>
						  </div>
						  <div class="col-auto">
							<button *ngIf="buttonloader" class="buttonload btn btn-sm btn-secondary bg-gradient-secondary"><i
								class="fa fa-spinner fa-spin"></i>Loading</button>
						  </div>
						</div>

					  </div>

					  <div class="card-body">
						<div class="row">
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Lender Key</label>
							  <select class="form-control Lenderkeyddl" id="lenderdropdown_{{Lenderformarraydata.value.Lenderid}}"  (change)="Lenderdropdownchange($event,i,Lenderformarraydata.value.Lenderid)"  formControlName="lenderkeyddl">
								<option value="0" selected>Select Lender key</option>
								<!-- <option *ngFor="let lenderKeyListdata of lenderKeyList" [value]="lenderKeyListdata.id">
								  {{lenderKeyListdata.planType}}</option> -->
							  </select>
							</div>
						  </div>
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Pay Call</label>
							  <div class="input-group">
								<div class="input-group-text"><i class="fas fa-dollar-sign"></i></div>
								<input id="TDBPCallID" class="form-control" type="number" (keyup)="CheckValidationPaycall($event,Lenderformarraydata.value.Lenderid)"

								  formControlName="LenderPayCall" placeholder="0">
							  </div>
							</div>
						  </div>
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Interest Rate</label>
							  <div class="input-group" id="IntRateDIV">
								<div class="input-group-text">
								  <i class="fas fa-percentage"></i>
								</div>
								<input id="TDIntRtID" class="form-control" type="number" placeholder="0" (keyup)="CheckValidationInterestRate($event,Lenderformarraydata.value.Lenderid)"
								   formControlName="LenderInterestRate">
							  </div>
							</div>
						  </div>
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Trade Value</label>
							  <div class="input-group">
								<div class="input-group-text">
								  <i class="fas fa-dollar-sign"></i>
								</div>
								<input id="TDTradeInID" class="form-control" type="number" placeholder="0"
								  formControlName="ACVValueControl">
							  </div>
							</div>
						  </div>
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Lien</label>
							  <div class="input-group">
								<div class="input-group-text">
								  <i class="fas fa-dollar-sign"></i>
								</div>
								<input id="TDLienID" class="form-control" type="number" placeholder="0"
								  formControlName="LoanAmountControl">
							  </div>
							</div>
						  </div>
						  <div class="col-sm-4 col-md-3 col-lg-2">
							<div class="form-group">
							  <label>Down Payment</label>
							  <div class="input-group">
								<div class="input-group-text">
								  <i class="fas fa-dollar-sign"></i>
								</div>
								<input id="TDDownPayID" class="form-control" type="number" placeholder="0"
								 formControlName="DownPaymentAmountControl">
							  </div>
							</div>
						  </div>

						</div>
					  </div>
					</div>
				  </div>
				</div>


<!--Build grid start-->
<div formArrayName="inventaryarray"  >

<div  style="display:none" id="inventryList_{{Lenderformarraydata.value.Lenderid}}" class="container-fluid">
	<!-- <div>
	  <div class="row">
        <div class="form-group col-lg-2 col-md-3 col-sm-7">
          <label>Selection List</label>
          <select class="form-control" name="AithrHubKey" (change)="GetAithrHubUnits()"
          formControlName="AithrHubKey" [(ngModel)]="AithrHubKey">
          <option value="0" [selected]="true">Selected Dealers</option>
          <option value="1">AithrHub</option>
          </select>
        </div>

	  </div>
	</div> -->
	<div class="row">
	  <div class="col-12 mt-2 px-0">
		<div class="row justify-content-between align-items-center mb-2">
		  <div class="col-auto col-xl-auto documentbtn">
			<ul class="list-unstyled mb-1">

				<li>
				  <button class="btn btn-secondary btn-sm mb-1 mb-md-0">Copy</button>
				</li>

				<li>
				  <button class="btn btn-secondary btn-sm mb-1 mb-md-0">Excel</button>
				</li>
				<li>
				  <button (click)="downloadPdf(i)" class="btn btn-secondary btn-sm mb-1 mb-md-0">PDF</button>
				</li>
				<li>
				  <button class="btn btn-secondary btn-sm mb-1 mb-md-0" (click)="printTable(i)" >Print</button>
				</li>

				<li>
					<button class="btn bg-gradient-info btn-sm"  (click)="HideShowColumn(Lenderformarraydata.value.Lenderid)"
					 title="Show columns" >
					  <span class="icon">
						<i class="fa fa-cog"></i>
					  </span>
					  <span class="link-text"></span>
					  <span class="ml-auto myCaret"></span>
					</button>

				  </li>

			</ul>
		  </div>
		  <div class="col-auto mr-auto px-0">
				<div style="display: none;" id="Multiselectdrpdowncoulm_{{Lenderformarraydata.value.Lenderid}}"
					class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
					<ng-multiselect-dropdown id="multiselectdropdown" [placeholder]="'Select Your Item'"
						[settings]="dropdownSettings" [data]="dropdownList" formControlName="myItems"
						(onSelect)="onItemSelect($event,i)" (onSelectAll)="onSelectAll($event,i)"
						(onDeSelect)="onItemDeSelect($event,i)" (onDeSelectAll)="onUnSelectAll(i)">
					</ng-multiselect-dropdown>
				</div>
		  </div>





		  <div class="search-heros col-md-auto col-xl-3">
			<div class="form-group d-flex mb-1">
			  <label class="mr-3 mt-2">Search:</label>
			  <input class="form-control" type="text" name="searchtext{{i}}"
			   (keyup)="setSerachboxvalue($event,i,'')"
			    (keypress)="setSerachboxvalue($event,i,'')"
				 (keydown)="setSerachboxvalue($event,i,'')"
				 (paste)="setSerachboxvalue($event.clipboardData.getData('text/plain'),i,'paste')"
				 (blur)="setSerachboxvalue($event,i,'')" [ngModelOptions]="{standalone: true}" autocomplete="off">
			</div>
		  </div>
		</div>
				  <!-- <button (click)="watchdata(Lenderformarraydata.value.inventaryarray,i)">test </button> -->

		<div class="w-100 mt-0">
			<div class="col-auto col-lg-12">
				<div class="row mb-md-2">
					<!-- <div style="display: none;" id="Multiselectdrpdowncoulm_{{Lenderformarraydata.value.Lenderid}}"
						class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
						<ng-multiselect-dropdown id="multiselectdropdown" [placeholder]="'Select Your Item'"
							[settings]="dropdownSettings" [data]="dropdownList" formControlName="myItems"
							(onSelect)="onItemSelect($event,i)" (onSelectAll)="onSelectAll($event,i)"
							(onDeSelect)="onItemDeSelect($event,i)" (onDeSelectAll)="onUnSelectAll(i)">
						</ng-multiselect-dropdown>
					</div> -->
					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
						<select name="Year" class="yearfilterddl form-control"
							id="Yearfilter_{{Lenderformarraydata.value.Lenderid}}" [(ngModel)]="selectedyear"
							(change)="FindcarKeup($event,'Year',i,Lenderformarraydata.value.Lenderid)">
						</select>
						<!-- <input id="yearvalue" type="number" class="form-control"
								formControlName="YearCheckFilter" (input)="SeatCheck($event)" (keyup)="FindcarKeup($event,'Year',i)"
								  placeholder="Year"> -->
					</div>
					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
						<!-- <input id="Makevalue" type="text" class="form-control" placeholder="Make" (keyup)="FindcarKeup($event,'Make',i)"
								formControlName="MakeCheckFilter" > -->

						<select class="Makefilterddl form-control" id="Makefilter_{{Lenderformarraydata.value.Lenderid}}"
							(change)="FindcarKeup($event,'Make',i,Lenderformarraydata.value.Lenderid)">
						</select>
					</div>
					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
						<!-- <input id="Modelvalue"
								formControlName="ModelCheckFilter" type="text" class="form-control" placeholder="Model"
								 (keyup)="FindcarKeup($event,'Model',i)"> -->
						<select class="Modelfilterddl form-control" id="Modelfilter_{{Lenderformarraydata.value.Lenderid}}"
							(change)="FindcarKeup($event,'Model',i,Lenderformarraydata.value.Lenderid)">

						</select>
					</div>
					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
						<!-- <input id="Trimvalue"
								(keyup)="FindcarKeup($event,'Trim',i)" type="text" class="form-control"
								  placeholder="Trim"> -->

						<select style="max-width:160px;" class="Trimfilterddl form-control"
							id="Trimfilter_{{Lenderformarraydata.value.Lenderid}}"
							(change)="FindcarKeup($event,'Trim',i,Lenderformarraydata.value.Lenderid)">

						</select>
					</div>

					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 " >
								 <select style="max-width:160px;" class="kmvaluefilterddl form-control"
								 id="KM_{{Lenderformarraydata.value.Lenderid}}"
								 (change)="FindcarKeup($event,'KM',i,Lenderformarraydata.value.Lenderid)">

							 </select>

					</div>

					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
						<select style="max-width:160px;" class="BodyStylefilterddl form-control"
						id="Body_{{Lenderformarraydata.value.Lenderid}}"
						(change)="FindcarKeup($event,'Body',i,Lenderformarraydata.value.Lenderid)">

					</select>
				</div>



				<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
					<select style="max-width:160px;" class="Colorfilterddl form-control"
					id="Color_{{Lenderformarraydata.value.Lenderid}}"
					(change)="FindcarKeup($event,'Color',i,Lenderformarraydata.value.Lenderid)">

				</select>
			</div>


			<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
				<select style="max-width:160px;" class="Fueltypefilterddl form-control"
				id="Fueltype_{{Lenderformarraydata.value.Lenderid}}"
				(change)="FindcarKeup($event,'FuelType',i,Lenderformarraydata.value.Lenderid)">

			</select>
		</div>



		<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
			<select style="max-width:160px;" class="Transmissionfilterddl form-control"
			id="Transmission_{{Lenderformarraydata.value.Lenderid}}"
			(change)="FindcarKeup($event,'Transmission',i,Lenderformarraydata.value.Lenderid)">

		</select>
	</div>

	<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
		<select style="max-width:160px;" class="dealerCityfilterddl form-control"
		id="dealerCity_{{Lenderformarraydata.value.Lenderid}}"
		(change)="FindcarKeup($event,'City',i,Lenderformarraydata.value.Lenderid)">

	</select>
</div>

					<!-- <div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1 ">
								<input id="Bodystylevalue"
								 (keyup)="FindcarKeup($event,'BodyStyle',i)"   type="text" class="form-control"
								placeholder="Body Style">
							</div> -->


					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
						<!-- <select  class="BodyStylefilterddl form-control" id="BodyStylefilter_{{Lenderformarraydata.value.Lenderid}}" (change)="FindcarKeup($event,'BodyStyle',i)">

							</select> -->
					</div>
					<div class="col-sm-4 col-md-2 col-lg-auto mb-1 mb-md-0 px-lg-1">
						<button style="position:relative;top:2px;" (click)="getUnitsByDealerNames(i);"
							class="btn btn-sm btn-warning">Reset</button>
						<button title="Customer View" (click)="PopupCustomViewTable(Customtablepopup,i)"
							style="position: relative;top: 2px;padding: 5px 8px;
							font-size: 0.92em;" id="hideButtoncustomerview" type="button"
							[ngClass]="{'green' : toggle, 'red': !toggle}"
							class="btn btn-success btn-md ml-2">
							<i id="viewicon" class="far fa-eye"></i>
							 <span class="ml-2">Custom View</span>
						</button>
						<button style="display: none;" #buttoncolorChnage (click)="enableDisableRule()" >
							{{status}}
						</button>

					</div>
				</div>
			</div>
		</div>

		<div class="row">
		  <div class="col-md-12">
			<div class="card card-raised h-100">
			  <div class="card-header card-raised bg-info text-white">
				<div class="row justify-content-between align-items-center">
				  <div class="col">
					<h6 class="card-title text-white text-uppercase mb-0">List Of Inventory Selected for {{Lenderformarraydata.value.LenderName }}</h6>
				  </div>
				  <div class="col text-right">
					<h6 style="font-size:13px;" class="mb-0"><b>Total Records :</b> {{Lenderformarraydata.get('inventaryarray').controls.length}}</h6>
				  </div>
				  <!-- <div class="card-subtitle"></div> -->
				</div>
			  </div>
			  <div id="inventory-{{i}}" class="card-body inventorycolumfixed p-4">
				<div class="table-responsive">
				  <table id="usertable-{{i}}" class="table table-bordered" style="width:100%">
					<thead>
					  <tr>
						<th class="position_fix" style="text-align:center;">
						</th>
						<th class="position_fix" (click)="sort('vin',i)"> VIN <i class="fa fa-fw fa-sort"></i>
						</th>
						<th class="position_fix" (click)="sort('stockNumber',i)"> Stock No<i class="fa fa-fw fa-sort"></i>
						</th>
						<th class="position_fix" (click)="sort('carYear',i)">Year <i class="fa fa-fw fa-sort"></i></th>
						<th class="position_fix" (click)="sort('mileage',i)">Mileage <i class="fa fa-fw fa-sort"></i> </th>
						<th class="position_fix" (click)="sort('make',i)">Make <i class="fa fa-fw fa-sort"></i>
						</th>
						<th class="position_fix" (click)="sort('model',i)">Model<i class="fa fa-fw fa-sort"></i>
						</th>
						<th class="TermPeriodcolumn position_fix"  style="text-align: right;" (click)="sort('termPeriod',i)">Term Period</th>
						<th class="RegionColumn"  (click)="sort('vehicleRegionName',i)">Region<i class="fa fa-fw fa-sort"></i></th>
						<th class="CityColumn"  (click)="sort('dealerCity',i)">City<i class="fa fa-fw fa-sort"></i></th>
						<th class="BodyColumn" (click)="sort('body',i)">Body<i class="fa fa-fw fa-sort"></i></th>

						<th class="TrimColumn" (click)="sort('trim',i)">Trim<i class="fa fa-fw fa-sort"></i></th>



						<th  class="ConditionColumnStatus"   (click)="sort('condition',i)">Condition<i class="fa fa-fw fa-sort"></i></th>
						<th class="MaxPaycallColumnStatus"   style="text-align: right;" (click)="sort('calculatedPayCall',i)">Max Paycall</th>
						<th class="ListedPriceColumnStatus"  style="text-align: right;" (click)="sort('listedPrice',i)">Listed Price<i
							class="fa fa-fw fa-sort"></i></th>
						<th  class="DealerCostColumnStatus"    style="text-align: right;" (click)="sort('dealerCost',i)">Dealer Cost<i
							class="fa fa-fw fa-sort"></i></th>
							<th class="ProjectedGrossColumnStatus" *ngIf="ProjectedGrossColumnStatus ==='true'" style="text-align: right;" (click)="sort('projectedGross',i)">Projected Gross<i
								class="fa fa-fw fa-sort"></i></th>

                            <th  class="EffectiveCBBcolumn"  style="text-align: right;" (click)="sort('effectiveCBB',i)">EffectiveCBB<i
                                class="fa fa-fw fa-sort"></i></th>

                                <th  class="TotalFLTVcolumn" style="text-align: right;" (click)="sort('fltv',i)">Total FLTV</th>

                                    <th class="TotalBLTVcolumn"  style="text-align: right;" (click)="sort('bltv',i)">Total BLTV
										</th>

                                        <th  class="TotalTLVcolumn" style="text-align: right;" (click)="sort('tlv',i)">Total TLV<i
                                            class="fa fa-fw fa-sort"></i></th>

                                            <th  class="LenderFeecolumn" style="text-align: right;" (click)="sort('lenderFee',i)">Lender Fee</th>

											<th class="FrontDocFeecolumn" style="text-align: right;" (click)="sort('backDocFee',i)">Front Doc Fee</th>

											<th class="BackDocFeecolumn" style="text-align: right;" (click)="sort('backDocFee',i)">Back Doc Fee</th>


                                            <th class="PPSAcolumn"  style="text-align: right;" (click)="sort('ppsa',i)">PPSA</th>

                                            <th class="Reservescolumn" style="text-align: right;" (click)="sort('reserves',i)">Reserves</th>

                                           


                                            <th class="TaxRatecolumn" style="text-align: right;" (click)="sort('termPeriod',i)">Tax Rate</th>

                                            <th class="TotalPricecolumn" style="text-align: right;" (click)="sort('totalPrice',i)">Total Price</th>


											<th  class="TotalCostcolumn" style="text-align: right;" (click)="sort('totalCost',i)">Total Cost</th>



                        <th class="TotalTaxcolumn"  style="text-align: right;" (click)="sort('totalTaxes',i)">Total Tax<i
							class="fa fa-fw fa-sort"></i></th>


                            <th  class="AmountToBeFinancedcolumn" style="text-align: right;" (click)="sort('amountToBeFinanced',i)">AmountToBeFinanced</th>


                            <th class="AllowableFinancecolumn" style="text-align: right;" (click)="sort('allowableFinance',i)">Allowable Finance</th>

                            <th class="MinFinancecolumn" style="text-align: right;" (click)="sort('minFinance',i)">Min Finance</th>

						<th class="MinPricecolumn" style="text-align: right;" (click)="sort('minPrice',i)">Min Price</th>

						<th class="MinCostcolumn" style="text-align: right;" (click)="sort('minCost',i)">Min Cost</th>

						<th  class="MinGrosscolumn" style="text-align: right;" (click)="sort('minGross',i)">Min Gross</th>

						<th class="MinTaxescolumn" style="text-align: right;" (click)="sort('minTaxes',i)">Min Taxes</th>

                        <th class="FLTVnewcolumn" style="text-align: right;" (click)="sort('fltVnew',i)">FLTVnew</th>

						<th class="FLTVcolumn" style="text-align: right;" (click)="sort('fltv',i)">FLTV</th>

						<th class="BLTVcolumn" style="text-align: right;" (click)="sort('bltv',i)">BLTV</th>

						<th class="Colorcolumn" style="text-align: right;" (click)="sort('bltv',i)">Color</th>

						<th class="transmissionColumn" style="text-align: right;" (click)="sort('bltv',i)">Transmission</th>
						<th class="engineFuelTypeColumn" style="text-align: right;" (click)="sort('bltv',i)">Fuel</th>
						<th class="StockinDaysColumnStatus" (click)="sort('stockInDays',i)"> Days In Stock<i class="fa fa-fw fa-sort"></i></th>
					  </tr>
					</thead>
					<tbody>
					  <!-- <tr *ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize"> -->
						<!-- <tr [formGroupName]="y" *ngFor="let dealerVehicleslistdata of Lenderformarraydata.get('inventaryarray').controls| filter:searchText | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index"> -->
							<!-- <tr [formGroupName]="y" *ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchtablearray[i].searchtext | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index"> -->
								<tr  *ngFor="let dealerVehicleslistdata of Lenderformarraydata.value.inventaryarray| filter:searchtablearray[i].searchtext | slice: (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize : (Lenderformarraydata.value.page-1) * Lenderformarraydata.value.pageSize + Lenderformarraydata.value.pageSize;let y = index">
								<td class="position_fix" style="text-align:center;min-width:98px;">


									<!-- <a class="btn bg-gradient-info btn-sm"  (click)="gotoBuildPage(dealerVehicleslistdata.vin);">Build</a>  -->
								   <a class="btn bg-gradient-info btn-sm" style="line-height:15px;"
							(click)="gotoBuildPage(dealerVehicleslistdata.vin,dealerVehicleslistdata.listedPrice,dealerVehicleslistdata.totalTaxes,dealerVehicleslistdata.allowableFinance,dealerVehicleslistdata.frontDocFee,dealerVehicleslistdata.fltv,dealerVehicleslistdata.bltv,dealerVehicleslistdata.effectiveCBB,dealerVehicleslistdata.dealerCost,dealerVehicleslistdata.termPeriod,i);">Build</a>

							<button class="btn bg-gradient-info btn-sm ml-1" style="line-height: 21px;"
							(click)="openImagesModelPopUp(ModelPopUpImages,dealerVehicleslistdata.vin)"><i
								class="fa fa-image"></i></button>

								 </td>
					<td class="position_fix">{{ dealerVehicleslistdata.vin }}</td>

					<!-- <td>{{ dealerVehicleslistdata.controls.vin.value }}</td>
						<td> {{ dealerVehicleslistdata.controls.stockNumber.value }}</td> -->
						<td class="position_fix"> {{ dealerVehicleslistdata.stockNumber }}</td>
						<td class="position_fix"> {{ dealerVehicleslistdata.carYear }}</td>
						<td class="position_fix"> {{ dealerVehicleslistdata.mileage }}</td>
						<td class="position_fix"> {{ dealerVehicleslistdata.make }}</td>
						<td class="position_fix"> {{ dealerVehicleslistdata.model }}</td>
						<td  class="TermPeriodStatus position_fix" *ngIf="dealerVehicleslistdata.isTermPeriod !=true" style="text-align: right;"> {{ dealerVehicleslistdata.termPeriod }}</td>
						<td class="RegionStatus" *ngIf="dealerVehicleslistdata.isRegionStatushide !=true"> {{ dealerVehicleslistdata.vehicleRegionName }}</td>
						<td class="CityStatus" *ngIf="dealerVehicleslistdata.isCityStatushide !=true"> {{ dealerVehicleslistdata.dealerCity }}</td>
						<td class="BodyStatus" *ngIf="dealerVehicleslistdata.isBodyStatushide !=true"> {{ dealerVehicleslistdata.body }}</td>
						<td  class="TrimStatus" *ngIf="dealerVehicleslistdata.isTrimhide !=true" > {{ dealerVehicleslistdata.trim }}</td>

						<td class="ConditionColumnStatus" *ngIf="dealerVehicleslistdata.isconditionhide !=true" > {{ dealerVehicleslistdata.condition }}</td>
						<td  class="MaxPaycallColumnStatus" *ngIf="dealerVehicleslistdata.isMaxPaycall !=true" style="text-align: right;"> {{ dealerVehicleslistdata.calculatedPayCall | currency: 'USD'}}</td>
						<td  class="ListedPriceColumnStatus" *ngIf="dealerVehicleslistdata.isListedPrice !=true" style="text-align: right;"> {{ dealerVehicleslistdata.listedPrice| currency: 'USD' }}
						</td>
						<td class="DealerCostColumnStatus" *ngIf="dealerVehicleslistdata.isDealerCost !=true" style="text-align: right;"> {{ dealerVehicleslistdata.dealerCost | currency: 'USD' }}
						</td>
						<td class="ProjectedGrossColumnStatus" *ngIf="dealerVehicleslistdata.isProjectedGross !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.projectedGross| currency: 'USD'
						}}</td>

                        <td  class="EffectiveCBBStatus" *ngIf="dealerVehicleslistdata.isEffectiveCBB !=true" style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB | currency: 'USD' }}
						</td>

                        <!-- <td style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB * dealerVehicleslistdata.fltv | currency: 'USD' }}</td> -->

						<td  class="TotalFLTVStatus" *ngIf="dealerVehicleslistdata.isTotalFLTV !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.totalFLTV | currency: 'USD' }}</td>



                        <!-- <td style="text-align: right;"> {{ dealerVehicleslistdata.effectiveCBB * dealerVehicleslistdata.bltv | currency: 'USD' }} -->

							<td  class="TotalBLTVStatus" *ngIf="dealerVehicleslistdata.isTotalBLTV !=true" style="text-align: right;"> {{ dealerVehicleslistdata.totalBLTV | currency: 'USD' }}
						</td>

						<td  class="TotalTLVStatus" *ngIf="dealerVehicleslistdata.isTotalTLV !=true" style="text-align: right;"> {{ dealerVehicleslistdata.tlv | currency: 'USD' }}</td>


                        <td   class="LenderFeeStatus" *ngIf="dealerVehicleslistdata.isLenderFee !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.lenderFee | currency: 'USD' }}
						</td>
						<td class="FrontDocFeeStatus" *ngIf="dealerVehicleslistdata.isFrontDocFee !=true" style="text-align: right;"> {{ dealerVehicleslistdata.frontDocFee | currency: 'USD' }}</td>

						<td  class="BackDocFeeStatus" *ngIf="dealerVehicleslistdata.isBackDocFee !=true" style="text-align: right;"> {{ dealerVehicleslistdata.backDocFee | currency: 'USD' }}</td>

                        <td class="PPSAStatus" *ngIf="dealerVehicleslistdata.isPPSA !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.ppsa | currency: 'USD' }}</td>

		<td class="ReservesStatus" *ngIf="dealerVehicleslistdata.isReserves !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.reserves | currency: 'USD' }}</td>

         


		 <td  class="TaxRateStatus" *ngIf="dealerVehicleslistdata.isTaxRate !=true" style="text-align: right;"> {{ dealerVehicleslistdata.taxRate }}</td>


		 <td  class="TotalPriceStatus" *ngIf="dealerVehicleslistdata.isTotalPrice !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.totalPrice | currency: 'USD' }}</td>


		 <td  class="TotalCostStatus" *ngIf="dealerVehicleslistdata.isTotalCost !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.totalCost | currency: 'USD' }}</td>


		 <td   class="TotalTaxStatus" *ngIf="dealerVehicleslistdata.isTotalTax !=true" style="text-align: right;"> {{ dealerVehicleslistdata.totalTaxes | currency: 'USD' }}
						</td>


  <td class="AmountToBeFinancedStatus" *ngIf="dealerVehicleslistdata.isAmountToBeFinanced !=true"    style="text-align: right;"> {{ dealerVehicleslistdata.amountToBeFinanced | currency: 'USD' }}</td>


    <td class="AllowableFinanceStatus" *ngIf="dealerVehicleslistdata.isAllowableFinance !=true"    style="text-align: right;"> {{ dealerVehicleslistdata.allowableFinance | currency: 'USD' }}</td>

     <td class="MinFinanceStatus" *ngIf="dealerVehicleslistdata.isMinFinance !=true"   style="text-align: right;"> {{ dealerVehicleslistdata.minFinance | currency: 'USD' }}</td>


	 <td class="MinPriceStatus" *ngIf="dealerVehicleslistdata.isMinPrice !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.minPrice | currency: 'USD' }}</td>

	 <td class="MinCostStatus" *ngIf="dealerVehicleslistdata.isMinCost !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.minCost | currency: 'USD' }}</td>

	 <td class="MinGrossStatus" *ngIf="dealerVehicleslistdata.isMinGross !=true"  style="text-align: right;"> {{ dealerVehicleslistdata.minGross | currency: 'USD' }}</td>

	 <td class="MinTaxesStatus" *ngIf="dealerVehicleslistdata.isMinTaxes !=true" style="text-align: right;"> {{ dealerVehicleslistdata.minTaxes | currency: 'USD' }}</td>


	<td class="FLTVnewStatus" *ngIf="dealerVehicleslistdata.isFLTVnew !=true"   style="text-align: right;"> {{ dealerVehicleslistdata.fltVnew}}</td>

	<td class="FLTVStatus" *ngIf="dealerVehicleslistdata.isFLTV !=true" style="text-align: right;"> {{ dealerVehicleslistdata.fltv}}</td>

	<td class="BLTVStatus" *ngIf="dealerVehicleslistdata.isBLTV !=true" style="text-align: right;"> {{ dealerVehicleslistdata.bltv}}</td>



	<td class="ColorStatus" *ngIf="dealerVehicleslistdata.isColor !=true" style="text-align: right;"> {{ dealerVehicleslistdata.colour}}</td>


	<td class="TransmissionStatus" *ngIf="dealerVehicleslistdata.isTransmission !=true" style="text-align: right;"> {{ dealerVehicleslistdata.transmission}}</td>

	<td class="FuelStatus" *ngIf="dealerVehicleslistdata.isFuel !=true" style="text-align: right;"> {{ dealerVehicleslistdata.engineFuelType}}</td>


	<td class=""> {{ dealerVehicleslistdata.stockInDays }}</td>

					  </tr>
					</tbody>
				  </table>

				  <!-- <button (click)="watchdata(Lenderformarraydata.get('inventaryarray').controls[0].value.id,i)">test </button> -->


				  <br>
				  <ngx-spinner name="Lender_{{Lenderformarraydata.value.Lenderid}}" bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
					[fullScreen]="false">
					<p style="color: white"> Loading... </p>
				  </ngx-spinner>


				  <!-- <div class="row justify-content-between align-items-center">
					<div class="col">

					  <ngb-pagination name="paging-{{i}}" [collectionSize]="Lenderformarraydata.get('inventaryarray').controls.length" [(page)]="Lenderformarraydata.value.page" [maxSize]="5"
						[rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
					</div>

				  </div> -->
				</div>
				<div class="row justify-content-between align-items-center mt-3">
					<div class="col">

					  <ngb-pagination name="paging-{{i}}" [collectionSize]="Lenderformarraydata.get('inventaryarray').controls.length" [(page)]="Lenderformarraydata.value.page" [maxSize]="5"
						[rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
					</div>

				  </div>
			  </div>


			</div>
		  </div>
		</div>
	  </div>
	</div>

  </div>

</div>

<!--Build grid close-->

		  </div>

          </div>

	</div>
	</div>

        </div>


  </form>




<!-- here we popup tables on custom view start-->

<ng-template #Customtablepopup>
	<div id="pdf-viewer-outer" class="modal-body">
	  <h5>Customer View </h5>
	  <div class="row mt-2" id="dealerchckbox">

		<table  class="table table-bordered" style="width:100%">
			<thead>
			  <tr>
				<th class="position_fix" style="text-align:center;">
					VIN
				</th>
				<th class="position_fix" style="text-align:center;">
					Stock No
				</th>
				<th class="position_fix" style="text-align:center;">
					Year
				</th>
				<th class="position_fix" style="text-align:center;">
					Mileage
				</th>
				<th class="position_fix" style="text-align:center;">
					Make
				</th>
				<th class="position_fix" style="text-align:center;">
					Model
				</th>
				<th class="position_fix" style="text-align:center;">
					Region
				</th>
				<th class="position_fix" style="text-align:center;">
					Body
				</th>
				<th class="position_fix" style="text-align:center;">
					Trim
				</th>
				<th class="position_fix" style="text-align:center;">
					Condition
				</th>

				</thead>

					<tbody>

					<tr  *ngFor="let cutomerview of CustomerviewTabledatasource;let y = index">

							<td class="position_fix" style="text-align:center;">
								{{cutomerview.vin}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.stockNumber}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.carYear}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.mileage}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.make}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.model}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.vehicleRegionName}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.body}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.trim}}
							</td>
							<td class="position_fix" style="text-align:center;">
								{{cutomerview.condition}}
							</td>

					</tr>

					</tbody>
		</table>

	  </div>

	</div>
	<div class="modal-footer">
	  <button type="button" class="btn btn-danger btn-sm" (click)="decline()">Close</button>
	  <!-- <button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedRegions()">Done</button> -->
	</div>
  </ng-template>

<!-- custom view end -->

  <button #regioncheckboxpopupbuttonToBeClicked style="display:none;"
  (click)="regioncheckboxpopup(regioncheckbox,'')"></button>


<ng-template #regioncheckbox>
  <div id="pdf-viewer-outer" class="modal-body">
	<h5>Region List </h5>
	<hr />
	<div class="row mt-2" id="dealerchckbox">

<!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
<div class="col-12 listoption">
<input type="checkbox" name="chckboxall" (click)="Checkallregions($event)" id="checkall"
[(ngModel)]="SelectedAllCheckregion"  />
<label style="font-size:13px;" class="ml-1"><h6 class="mb-0" style="font-size:14px;"></h6>Select All</label>
</div> <br/>

	  <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of regionlistName ; let i = index">
		<input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected"
		  /> {{item.globalCodeName}}
	  </div>

	</div>

  </div>
  <div class="modal-footer">
	<button type="button" class="btn btn-danger btn-sm" (click)="CloseRegionList(regioncheckbox)">Back</button>
	<button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedRegions()">Done</button>
  </div>
</ng-template>

  <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
    (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


  <ng-template #dealercheckbox>
    <div id="pdf-viewer-outer" class="modal-body">
      <h5>Dealer List </h5>
      <hr />
      <div class="row mt-2" id="dealerchckbox">

<!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->
<div class="col-12 listoption">
  <input type="checkbox" name="chckboxall"
  [(ngModel)]="SelectedAllCheck"  (click)="Checkalldealer($event)" id="checkall"/>
  <label style="font-size:13px;" class="ml-1"><h6 class="mb-0" style="font-size:14px;"></h6>Select All</label>
</div> <br/>

        <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
          <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected"
            /> {{item.dealerName}}
        </div>

      </div>

    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger btn-sm" (click)="decline()">Back</button>
	  <button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedDealer()">Done</button>
    </div>
  </ng-template>


  <button #buildcarpopupbuttonToBeClicked style="display:none;"
  (click)="Dealercheckboxpopup(buildCarConfirmModel,'')"></button>

  <ng-template #buildCarConfirmModel>
    <div class="modal-header" style="background-color: black;">
      <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Notification</h5>
      <button type="button" class="close" style="color: white;" #closebutton (click)="decline()"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">

      <section class="generate-report col">
        Do you want to Open Multiple Tabs for Comparisons for Selected Unit/Vehicle – Each Tab for Selected Lenders?
      </section>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success btn-sm text-white" title="Open tab for All lenders"
        (click)="OpenMultipletab()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Yes
      </button>

      <button class="btn btn-danger btn-sm text-white" title="Lender" (click)="NoForMultipleTab()">
        <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> No
      </button>


    </div>
  </ng-template>


  <ng-template #ModelPopUpImages let-modal>
    <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Vehicle Images</h5>
        <button type="button" class="close" style="color: white;" (click)="RefreshImageList();modal.dismiss()"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
      
		<section class="generate-report imageSize row">

            <div class="card card-raised mt-2 mx-auto">
              <div class="card-header card-raised bg-info text-white p-2">
                <h6 class="mb-0">Auto Upload Images</h6>
            </div>
            <div class="card-body p-2">


           <div *ngIf="errorshowAuto=='false'" style="width:750px; height:485px; text-align:center;margin:0; padding:0;">
                  <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                      [imageSize]="{width: '100%', height:200}" slideImage="1">
                    </ng-image-slider>

              </div>

              <div *ngIf="errorshowAuto=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                  {{errorMessageAuto}}
              </div>
            </div>
          </div>

        </section>

        <section class="manual-uploadImage">
          <div class="card card-raised  mt-2 mx-auto">
            <div class="card-header card-raised bg-info text-white p-2">
              <h6 class="mb-0">Manual Upload Images</h6>
          </div>
          <div class="card-body p-2" *ngIf="errorshow=='false'">
            <div class="profile-icon" *ngFor="let element of customimagesrc">
              <img [src]="element.imgsrc" 
              
              width="150" height="150" />
            </div>
          </div>

          <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
            {{errorMessage}}
        </div>

        </div>
        </section>

    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger ml-2" (click)="RefreshImageList();modal.dismiss()">Cancel</button>
    </div>
</ng-template>

</section>