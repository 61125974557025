<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="ApplicationForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <!-- <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0"
                                        (click)="copytable('usertable')">Copy</button>
                                </li> -->

                                <!-- <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0"
                                        (click)="exportexcel()">Excel</button>
                                </li> -->

                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"
                                        (click)="printTable()">Print</button>
                                </li>
                            </ul>
                        </div>
                        <div class="search-heros col-md-auto mt-2 mt-md-0">
                            <div class="row justify-content-between align-items-center mb-1 mx-0">
                                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                        [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                <div class="form-group mb-0 ml-md-3">
                                    <label style="padding:2px;"> Items per Page:</label>
                                    <select (change)="handlePageSizeChange($event)">
                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                            {{ size }}
                                        </option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">List of Creditors</h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                        <!-- <div class="card-subtitle"></div> -->
                                    </div>
                                </div>
                                <div id="inventory" class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('appstatus')"> AithrX Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('displayName')"> Creditors Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>

                                                    <th (click)="sort('firstPaymentDate')">Display Name<i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
													<th (click)="sort('firstPaymentDate')">Base Source<i
														class="fa fa-fw fa-sort"></i>
												</th>

												<th (click)="sort('firstPaymentDate')">Action<i
													class="fa fa-fw fa-sort"></i>
											</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let creditorslistdata of creditorslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">

                                                    <td>{{ creditorslistdata.aithrXName }}</td>
                                                    <td>{{ creditorslistdata.creditorName }}</td>
                                                    <td> {{ creditorslistdata.displayName }}</td>
                                                    <td> {{ creditorslistdata.baseSource }}</td>

													<td style="text-align:center;">

														<button class="btn bg-gradient-danger btn-sm ml-2" title="Update"
														(click)="openModal(template,creditorslistdata.creditorId,creditorslistdata.creditorName)"><i class="fa fa-pen-to-square"></i></button>



														</td>


                                                </tr>

                                            </tbody>
                                        </table>
                                        <br>


                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>

                                        <div class="col-12">
                                            <div class="row justify-content-between align-items-center mx-0">
                                                <!-- <div class="col-auto px-0">
                                                    <ngb-pagination [collectionSize]="creditorslist.length"
                                                        [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                        [boundaryLinks]="true"></ngb-pagination>
                                                </div> -->
                                                <div class="form-group mb-0">
                                                    <label style="padding:2px;"> Items per Page:</label>
                                                    <select (change)="handlePageSizeChange($event)">
                                                        <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                            {{ size }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <ng-template #templateCheckVin>
            <div class="modal-body text-center">
                <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                <p>VIN already exist
                </p>
                <p></p>
                <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="declineCheck()">Ok</button>
            </div>
        </ng-template>


		<ng-template #template>
			<div class="modal-body text-center" >


			  <div class="form-group">
				<label for="website">Lender: {{creditorNameValue}}</label>
				<select  class="form-control" (change)="getSelectedValue($event)">
					<option disabled>Select Lender</option>
					<option>Choose Lender</option>
					<option *ngFor="let lender of lenderlists" [value]="lender.id">{{lender.lenderName}}</option>
				</select>
				</div>


			  <p></p>
			  <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()" >Update</button>
			  <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()" >No</button>
			</div>
		  </ng-template>






    </form>
</section>