<div class="app-container"  [ngClass]="{ 'bg-light': account }">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark" *ngIf="account">
      <a class="navbar-brand" *ngIf="account.role === Role.Admin" routerLink="/List">Aithr Wholesale Portal</a>
      <a *ngIf="account.role === Role.Dealer" routerLink="//Vhr-Report" class="navbar-brand">Aithr Wholesale Portal</a>
      <div class="side-nav-button ml-3 border text-light" style="cursor:pointer;padding: 3px 10px;">
        <i class="fa-solid fa-bars"></i>
      </div>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNavDropdown">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
          </li>
          <li class="nav-item">
            <a routerLink="/user-profile/{{ account.email }}" routerLinkActive="active"
              class="nav-item nav-link">Profile</a>
          </li>
          <li class="nav-item">
  
            <a *ngIf="account.role === Role.Admin" routerLink="/List" class="nav-item nav-link">Admin</a>
  
  
          </li>
  
          <li class="nav-item">
            <a routerLink="/Vhr-Report" *ngIf="account.role === Role.Dealer" routerLinkActive="active"
              class="nav-item nav-link">VHR Report</a>
          </li>
  
          <li class="nav-item">
            <a routerLink="/Carfax-API" *ngIf="account.role === Role.Admin" routerLinkActive="active"
              class="nav-item nav-link">Carfax API</a>
          </li>
  
          <li class="nav-item">
            <a routerLink="/Vhr-Report" *ngIf="account.role === Role.Admin" routerLinkActive="active"
              class="nav-item nav-link">Inventory</a>
          </li>
          <li class="nav-item">
            <a routerLink="/master-dashboard" *ngIf="account.role === Role.Admin" routerLinkActive="active"
              class="nav-item nav-link">Dashboard</a>
          </li>
          <li class="nav-item">
            <a routerLink="/api-history" *ngIf="account.role === Role.Admin" routerLinkActive="active"
              class="nav-item nav-link">API Call History</a>
          </li>
  
          <li class="nav-item">
            <a routerLink="/DealerLocation" *ngIf="account.role === Role.Admin" routerLinkActive="active"
              class="nav-item nav-link">Dealer location</a>
          </li>
          
  
          <ul id="profiledetails" class="navbar-nav ml-auto">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <i class="fa-solid fa-user"></i>
                <span>{{account.userName}}</span>
              </a>
              <div class="dropdown-menu" aria-labelledby="">
                <a class="dropdown-item"  routerLink="/account-setting"  ><i class="fa-solid fa-circle-user"></i>{{account.userName}}</a>
                <a class="dropdown-item" routerLink="/account-setting"  ><i class="fa-solid fa-envelope"></i> {{account.email}}</a>
                <a (click)="logout()" class="dropdown-item"><i class="fa-solid fa-arrow-right-from-bracket"></i>
                  Log Out</a>
              </div>
            </li>
          </ul>
          
        </ul>
      </div>
    </nav>
  </div>