import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { AccountService, AlertService } from '@app/_services';
import { Account } from '@app/_models';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, from } from 'rxjs';
import { map, every, pluck, filter, mergeMap, toArray, concatMap } from 'rxjs/operators';
import { AddgroupService } from '../_services/addgroup.service';
import { DealersList } from '@app/_models/DealersList';
import { NotifierService } from 'angular-notifier';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { ListDealersService } from '@app/_services/list-dealers.service';
import { NgxSpinnerService } from 'ngx-spinner';

enum CheckBoxType { IsAithrhub=1, IsAithrx=2,IsAithrMarketPlace=3, NONE };

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',

  styleUrls: ['./user-list.component.less']
})

export class UserListComponent implements OnInit {
  UserListForm: FormGroup;
  //UserResetPassword: FormGroup;
  private notifier: NotifierService;

  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private globalcodeService: GlobalcodeService,
    private listDealersService: ListDealersService,
    private router: Router,
    private spinner: NgxSpinnerService,
    notifier: NotifierService) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }

  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  @ViewChild('ConfirmBoxActivate', { static: false }) ConfirmBoxActivate: ElementRef;

  @ViewChild('ConfirmBoxActivateUser', { static: false }) ConfirmBoxActivateUser: ElementRef;

  isValid: boolean = true;
  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  DeleteActivateUserid: any;
   ActivateUserid: any;
  accounts: Account[] = [];
  TotalRecord: any;
  dealersLists: DealersList[] = [];
  isDesc: boolean = false;
  column: string = 'firstName';
  modalcloseOpen: any;
  submitted = false;
  Grouplist = [];
  selectedGroupId: any;
  selectedDealerIds: string[];
  savegruopId: any;
  hidesupportTeam: any;
  selectedGroupIds: string[];
  UserEmailSend:any;
  emailpassword:any;
  selectedDealerId: any;
  dealerlistName = [];
  loading = false;
  rolelist = [];
  OpCode:any;
  ButtonShow:any;
  GroupLists = [];
  Provincelist = [];
  ColourThemelist = [];
  edititemid: any;
  editvaluename: any;
  updateid: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  selectedgroupId: any;

  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  //ng model
  Id = null;
  Gid = 0;
  Did = 0;
  Rid = 0;
  firstname: any;
  Lastname: any;
  email: any;
  phoneNumber: any;
  phone:any;
  checkActivitylog: any;
  checkActivitylogValue: any;

  Representative:any;
  AithrVerify:any;
  IsSupportTeam: any;
  IsSupportTeamValue: any;
  IsRepresentativeValue:any;
  IsAithrVerifyValue:any;
  city: any;
  region: any = 0;
  colortheme: any = 0;
  userRole: any;
  userRoleControl: any = 0;
  dealerName: any;
  groupName: any;

  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  province: any;
  ResetPwdEmail: any;
  ResetPwdNewPassword: any;
  ResetPwdConfirmPwd: any;
  password: any;
  confirmPassword: any;
  show = true;
  passwordConfirm="";
  showConfirm = true;
  userpopuplabel: string = "Add Users";

  GruopIdvalidation: boolean = true;
  Dealeridvalidation: boolean = true;
  Roleidvalidation: boolean = true;
  Coloridvalidation: boolean = true;


  check_box_type = CheckBoxType;

  currentlyChecked: CheckBoxType;


  IsAithrhubng:boolean=false;
  IsAithrxng:boolean=false;
  IsAithrMarketPlaceng:boolean=false;
  ScreenList = [];
  SelectedScrenValue=[];

  selectedCustomizLevel;
  RoleIdForScreen:any;


  newPasswordCheck:string="";
  validationMessage:string="";
  validationtrue:boolean=false;
  colormessage:any;
  validationMessagePassword: string = 'Password must have at least 8 characters';
  validationMessagelowercase: string = '1 lowercase';
  validationMessageuppercase : string = '1 uppercase ';
  validationMessagespecial : string = '1 special character';
  validationMessagenumeric : string = '1 numeric character';

  colormessagelowercase:any;
  colormessageuppercase:any;
  colormessagePassword:any;
  colormessagespecial:any;
  colormessagenumeric:any;

   @ViewChild('templateShowScreen') templateShowScreen : TemplateRef<any>;

  selectCheckBox(targetType: CheckBoxType) {
    // If the checkbox was already checked, clear the currentlyChecked variable
    if(this.currentlyChecked === targetType) {
      this.currentlyChecked = CheckBoxType.NONE;
      return;
    }

    this.currentlyChecked = targetType;
  }

  // function for column sorting
  // sort(property) {
  //   this.isDesc = !this.isDesc; //change the direction
  //   this.column = property;
  //   let direction = this.isDesc ? 1 : -1;

  //   this.accounts.sort(function (a, b) {
  //     if (a[property] < b[property]) {
  //       return -1 * direction;
  //     }
  //     else if (a[property] > b[property]) {
  //       return 1 * direction;
  //     }
  //     else {
  //       return 0;
  //     }
  //   });
  // };
  sort(property) {
    this.isDesc = !this.isDesc; // change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    if (property === 'userName') {
      // Sort based on the concatenation of firstName and lastName
      this.accounts.sort((a, b) => {
        const nameA = `${a.firstName} ${a.lastName}`.toLowerCase();
        const nameB = `${b.firstName} ${b.lastName}`.toLowerCase();

        if (nameA < nameB) {
          return -1 * direction;
        } else if (nameA > nameB) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    } else {
      // Sort based on the specified property
      this.accounts.sort((a, b) => {
        if (a[property] < b[property]) {
          return -1 * direction;
        } else if (a[property] > b[property]) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }
  searchText;
  groupidvalue:any;
  ColorTheme:any;
  ngOnInit(): void {

    this.Createform();
    this.spinner.show();
    // For Getting User list
    // this.GetUserList();
    this.hidesupportTeam = "false";
    this.checkActivitylogValue = false;
    this.IsSupportTeamValue = false;
    this.IsRepresentativeValue=false;
    this.IsAithrVerifyValue = false;
    this.Getgrouplist();
    //this.GetGlobalcodeByRolelist();

    this.GetProvincelist();
    this.GetColourThemelist();
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.groupidvalue = localStorage.getItem('groupid');


    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    // this.region = localStorage.getItem('Province');
    if (this.rolevalue == "SuperAdmin") {
      this.GetUserList();
      this.GetGroupListByGlobalID();
      this.ButtonShow=true;
      this.OpCode="1";
      this.GetGlobalcodeByRolelist();
    } else if (this.rolevalue == "DealerAdmin" || this.rolevalue == "Dealer") {
      this.OpCode="2";
      this.ButtonShow=false;
      this.GetGroupBybyUserGroup();
      this.GetGlobalcodeByRolelist();
      this.GetUserListDealerName();
    }
    // this.dealerList=[];
  }

  validatePassword() {
    debugger;
    // Check if the password meets the criteria
    const isLengthValid = this.newPasswordCheck.length >= 8;
    const hasLowercase = /[a-z]/.test(this.newPasswordCheck);
    const hasUppercase = /[A-Z]/.test(this.newPasswordCheck);
    const hasSpecialChar = /[!@#$%^&*()_+{}|[\]\\;:'",.<>?/]/.test(this.newPasswordCheck);
    const hasNumber = /\d/.test(this.newPasswordCheck);

    if (isLengthValid && hasLowercase && hasUppercase && hasSpecialChar && hasNumber) {
      this.validationtrue=false;
      this.validationMessage = 'Password is valid!';
    } else {
      this.validationtrue=true;
      this.validationMessage = 'Password Requirements';
      if (isLengthValid) {
        this.colormessagePassword = "#21e121"
      }
      else {
        this.colormessagePassword = "#ff6262"
      }
  
        if (hasLowercase) {
          this.colormessagelowercase = "#21e121"
        }
        else {
          this.colormessagelowercase = "#ff6262"
        }
  
        if (hasUppercase) {
          this.colormessageuppercase = "#21e121"
        }
        else {
          this.colormessageuppercase = "#ff6262"
        }
  
        if (hasSpecialChar) {
          this.colormessagespecial = "#21e121"
        }
        else {
          this.colormessagespecial = "#ff6262"
        }
  
        if (hasNumber) {
          this.colormessagenumeric = "#21e121"
        }
        else {
          this.colormessagenumeric = "#ff6262"
        }
      //this.validationMessage = 'Password must have at least 8 characters, 1 lowercase, 1 uppercase, 1 special character, and 1 numeric character.';
    }
  }



  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    // this.pageSize = event.target.value;
    this.page = 1;
    this.GetUserList();
  }
  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  GetColourThemelist() {
    this.globalcodeService.getColourThemelist()
      .subscribe(
        (data: any) => {
          this.ColourThemelist = data;
        },
        error => {
        });
  }

  GetGroupListByGlobalID() {
    this.globalcodeService.getGroupList()
      .subscribe(
        (data: any) => {

          this.GroupLists = data;
        },
        error => {
        });
  }

  GetGroupBybyUserGroup() {
    var modelGroup = {
      "Opcode": this.OpCode,
      "GroupName":this.groupidvalue
    }
    this.globalcodeService.getGroupListBybyUserGroup(modelGroup)
      .subscribe(
        (data: any) => {

          this.GroupLists = data;
        },
        error => {
        });
  }

  GetGlobalcodeByRolelist() {
    var modelGroup = {
      "Opcode": this.OpCode,
    }
    this.globalcodeService.getGlobalcodeByRolelist(modelGroup)
      .subscribe(
        (data: any) => {

          this.rolelist = data;
        },
        error => {
        });
  }
  // functiono for creating from

  Createform() {

    this.UserListForm = new FormGroup({

      firstname: this.fb.control('', Validators.required),
      Lastname: this.fb.control('', Validators.required),
      //email: this.fb.control('', [Validators.email, Validators.required]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,8}$')]),
      // phoneNumber: this.fb.control('', [
      //   Validators.required,
      //   Validators.pattern("^[0-9]*$"),
      //   Validators.minLength(10),
      //   Validators.maxLength(10)
      // ]),
      phonenumber: this.fb.control('', [
        Validators.required,
       // Validators.pattern("^\\D*([0-9]\\D*){10}$"),
       //Validators.pattern(/^\(\d{3}\)\s*\d{3}-\d{4}$/),
       Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      city: this.fb.control('', Validators.required),
      region: this.fb.control('', Validators.required),
      colortheme: this.fb.control('', Validators.required),
      userRoleControl: this.fb.control("", Validators.required),
      dealerName: this.fb.control('', Validators.required),
      groupName: this.fb.control(''),
      checkActivitylog: this.fb.control(true, Validators.required),
      AithrVerify: this.fb.control(true, Validators.required),
      Representative : this.fb.control(true, Validators.required),
      IsSupportTeam: this.fb.control(false),

      // Creating controls for RESET PWD
      ResetPwdEmail: this.fb.control(''),
      //  ResetPwdNewPassword: this.fb.control(''),
      // ResetPwdConfirmPwd: this.fb.control(''),
      password: this.fb.control('', Validators.required),
      confirmPassword: this.fb.control('', Validators.required),
    },
      // this.UserResetPassword = new FormGroup({
      //   ResetPwdEmail: this.fb.control(''),
      // password: this.fb.control('', Validators.required),
      //confirmPassword: this.fb.control('', Validators.required),
      // },
      this.pwdsDontMatch
    );
  }
  openPasswordModel(targetModal, email) {

    this.ResetPwdEmail = email;
    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-sm', backdrop: 'static', keyboard: false });

  }


  openAddModel(targetModal, userid) {
    this.submitted = false;
    this.Roleidvalidation = true;
    this.Dealeridvalidation = true;
    this.GruopIdvalidation = true;
    this.Coloridvalidation = true;
    this.userpopuplabel = "Add User";
    this.selectedGroupIds = [];
    this.selectedDealerIds = [];

    if (userid != undefined && userid != "") {
      this.userpopuplabel = "Edit User";
      this.edititemid = userid;

      var modelGroup = {
        "Id": userid
      };

      this.accountService.editUserDetail(modelGroup).subscribe(
        (data: any) => {

          this.Dealeridvalidation = true;
          this.Roleidvalidation = true;

          this.dealerlistName;
          this.Id = data[0].id;
          this.firstname = data[0].firstName;
          this.Lastname = data[0].lastName;
          this.email = data[0].email;
          this.Gid = data[0].globalCodeName;
          this.selectedGroupIds = data[0].globalCodeName;
          this.selectedDealerIds = data[0].dealerName;
          this.selectedDealerId = data[0].did;

// this.IsAithrhubng=data[0].isAithrhub;
// this.IsAithrxng=data[0].isAithrx;
// this.IsAithrMarketPlaceng=data[0].isAithrMarketPlace;


          this.GetDealerNamebyGroupId(data[0].gid, "");
          this.savegruopId = data[0].gid;
          this.Did = data[0].did;
          this.Rid = data[0].rid;

          // Hide support team checkbox if gid is "24"
          if (data[0].gid == "24") {
            this.hidesupportTeam = "true";
          } else {
            this.hidesupportTeam = "false";
          }

          this.phone = data[0].phoneNumber;
          this.city = data[0].city;
          this.region = data[0].province;
          this.colortheme = data[0].colorTheme;
          this.checkActivitylog = data[0].isLoggingActivity;
          this.IsSupportTeam = data[0].isSupportedTeam;
          this.Representative = data[0].isRepresentative;
          this.AithrVerify = data[0].isAithrVerify;

          this.modalRef = this.confirmModalService.show(targetModal, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static',
            keyboard: false
          });
        },
        error => {
          // Handle error
        }
      );
    } else {
      this.Createform();
      this.reinitiatengmodel();
      this.edititemid = "";
      this.modalRef = this.confirmModalService.show(targetModal, {
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static',
        keyboard: false
      });
    }
  }



  // function for open model pop up
  ShowScreenName(template: TemplateRef<any>, itemid: any,name:any)
  {
    debugger;


    //this.selectedGlobalcodeName=name;

    this.RoleIdForScreen = itemid;

    var modelGroup = {
      "RoleId": this.RoleIdForScreen
    }

    this.addgroupService.GetAllScreenList(modelGroup)
      .subscribe(
        data => {
debugger;
          this.ScreenList = data;
          // this.editvaluename = data.groupName;
          // this.updateid = data.id;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

        },
        error => {
        })
      }

      MasterscreencheckValue(event:any,id:any,isSelected:any)
      {
    debugger;
    var CheckMaster = this.ScreenList;
    var selectedvalue=this.ScreenList.filter(i=>i.id==id)[0];

    if(isSelected==true)
    {



      if(selectedvalue.submenu.length>0)
      {
        selectedvalue.submenu.forEach(element => {

          element.isSelected=true;
        });

      }
    }
    else
    {
      selectedvalue.submenu.forEach(element => {

        element.isSelected=false;
      });
    }



      }

      CustomizeOnchange(event:any,template: TemplateRef<any>) {
        debugger
        this.selectedCustomizLevel=event.target.value;
        var val=this.selectedCustomizLevel;

        var roleid=this.Rid;
        this.ShowScreenName(template,roleid,"screen")
        // I want to do something here with the new selectedDevice, but what I
        // get here is always the last selection, not the one I just selected.
    }


  EditModalpopup(template: TemplateRef<any>, itemid: any) {

    this.edititemid = itemid;
    var modelGroup = {
      "Id": itemid
    }

    this.accountService.editUserDetail(modelGroup)
      .subscribe(
        (data: any) => {

          //this.editvaluename=data.firstName;
          //this.updateid=data.Id;

          this.dealerlistName,
            this.firstname = data.firstName,
            this.Lastname = data.lastName,
            this.email = data.Email,
            this.groupName = data.Gid,
            this.dealerName = data.Did,
            this.userRoleControl = data.Rid,
            this.phoneNumber = data.phone,
            this.city = data.City,

            this.selectedDealerId = data.Did;
          this.savegruopId = data.Gid;
          if (data.globalCodeID == "24") {
            this.hidesupportTeam = "true";
          } else {
            this.hidesupportTeam = "true";
          }

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg' });

        },
        error => {
        });


  }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }

  onClick() {

    if (this.password === 'password') {
      this.password = 'text';
      this.show = true;
    } else {
      this.password = 'password';
      this.show = false;
    }
  }

  onClickConfirm() {

    if (this.passwordConfirm === 'password') {
      this.passwordConfirm = 'text';
      this.showConfirm = true;
    } else {
      this.passwordConfirm = 'password';
      this.showConfirm = false;
    }
  }

  getValues(val: any) {

    this.Dealeridvalidation = true;
    this.selectedDealerId = val.id;
    this.savegruopId = val.dGroupId;
    this.GetDealersbyDealerID();
  }

  Getgrouplist() {
    this.addgroupService.Getgrouplist()
      .subscribe(
        (data: any) => {
          this.Grouplist = data;
        },
        error => {
        });
  }
  GetDealerNamebyGroupId(value: any, check: any) {


    // for hide and show support team
    if ((value.globalCodeID == "24" || value == "24") && value.globalCodeID != undefined) {
      this.hidesupportTeam = "true";
    } else {
      this.hidesupportTeam = "false";
    }
    //   if (value == "24" && check =="") {
    //     this.hidesupportTeam = "true";
    // } else{
    //   this.hidesupportTeam = "false";
    // }


    if (check == "change") {
      this.dealerName = "";
      this.selectedDealerIds = [];
    }

    this.GruopIdvalidation = true;
    this.selectedGroupId = value.globalCodeID;
    this.savegruopId = value.globalCodeID;
    if (value.globalCodeID == undefined) {
      this.GetDealers(value);
    } else {
      this.GetDealers(this.selectedGroupId);
    }

  }

  GetDealersbyDealerID() {
    var modelGroup = {
      "id": this.selectedDealerId
    }
    this.listDealersService.EditDealers(modelGroup)
      .subscribe(
        (data: any) => {

          this.region = data.dealerRegion;
        },
        error => {
        });
  }

  GetDealers(groupId: any) {
    this.selectedgroupId = groupId;
    var modelGroup = {
      "id": parseInt(this.selectedgroupId)
    }
    this.addgroupService.getdealerListbygroupId(modelGroup)
      .subscribe(
        (data: any) => {

          if (data.length == 0) {
            this.dealerName = "";
            this.selectedDealerIds = [];
          }


          this.dealerlistName = data;

        },
        error => {
        });


  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.accounts);
  }

  printTable() {

    var prepare = [];
    this.accounts.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName + e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.groupName);
      tempObj.push(e.dealerName);
      tempObj.push(e.rolename);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>Name</th><th>Email</th><th>Group</th><th>Dealer</th><th>Role</th><th>City</th><th>Phone</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdf() {
    var prepare = [];
    this.accounts.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName + e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.groupName);
      tempObj.push(e.dealerName);
      tempObj.push(e.rolename);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['Name', 'Email', 'Group', 'Dealer', 'Role', 'City', 'Phone']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('UserList' + '.pdf');
  }

  exportexcel() {
    const prepare = [];
    this.accounts.forEach(e => {
      const tempObj = [];
      tempObj.push(e.firstName + e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.groupName);
      tempObj.push(e.dealerName);
      tempObj.push(e.rolename);
      tempObj.push(e.city);
      tempObj.push(e.phoneNumber);
      prepare.push(tempObj);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['Name', 'Email', 'Group', 'Dealer', 'Role', 'City', 'Phone'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'UserList');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'UserList.xlsx');
  }

  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  // function for getting user list
  GetUserList() {
    this.spinner.show();
    this.accountService.getUserList()
      .subscribe(
        (data: any) => {
          debugger;
          this.accounts = data;

          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }

  GetUserListDealerName() {
    this.spinner.show();
    var modelGroup = {
      "DealerName": this.dealerNamevalue,
      "DealerId": this.DealerIdvalue,
      "GroupName": this.GroupNamevalue,
      "Role": this.rolevalue
    }

    this.accountService.getUserListDealerName(modelGroup)
      .subscribe(
        data => {

          this.accounts = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });


  }
  //function for delete
  onDelete(UserId: any) {

    this.DeleteUserid = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }

  DeactivateUser(UserId: any) {
    this.DeleteActivateUserid = UserId;
    let el: HTMLElement = this.ConfirmBoxActivate.nativeElement;
    el.click();
  }

  activateUser(UserId: any) {

    this.ActivateUserid = UserId;
    let el: HTMLElement = this.ConfirmBoxActivateUser.nativeElement;
    el.click();
  }
  confirmActivate(): void {

    this.modalRef.hide();
    this.ActivateUsers(this.ActivateUserid);
  }
  declineActivateUser(): void {
    this.modalRef.hide();
  }

  // function for open model pop up
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }
  CloseAddmodel() {

    this.modalRef.hide();
  }

  closepopupmodel() {
    this.UserListForm.invalid
  }

  openAddUserModel(targetModal) {

    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

  }
  // function for confirm
  confirm(): void {
    this.modalRef.hide();
    this.deleteUser(this.DeleteUserid);
  }
  decline(): void {
    this.modalRef.hide();
  }

  // function for confirm
  confirmDeactivate(): void {
    this.modalRef.hide();
    this.DeactivateUsers(this.DeleteActivateUserid);
  }

  get f() { return this.UserListForm.controls; }
  get phoneNumberControl() {
    return this.UserListForm.get('phonenumber');
  }
  keyPressCheck(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  private pwdsDontMatch(AC: AbstractControl) {
    let password = AC.get('password').value;
    if (AC.get('confirmPassword').touched || AC.get('confirmPassword').dirty) {
      let verifyPassword = AC.get('confirmPassword').value;
      if (password != verifyPassword) {
        AC.get('confirmPassword').setErrors({ pwdsDontMatch: true });
      } else {
        // AC.get('confirmPassword').setErrors(null);
        return null
      }
    }
    // return null;
  }

  resetpwd() {

    this.submitted = true;
    this.UserListForm.markAllAsTouched();
    // this.UserListForm.updateValueAndValidity();
    //console.log(this.UserListForm.invalid, this.UserListForm.hasError('pwdsDontMatch'), this.ResetPwdEmail, this.UserListForm.get('password').value);

    // if (this.UserListForm.controls.password.value === "") {
    //   this.isValid = false;
    //   return;
    // }
    // if (this.UserListForm.controls.confirmPassword.value == "") {
    //   this.isValid = false;
    //   return;
    // }
    // if (this.UserListForm.hasError('pwdsDontMatch')) {
    //   this.isValid = false;
    //   return;
    // }
    if (this.UserListForm.controls.password.value === "" || this.UserListForm.controls.confirmPassword.value === "") {
      console.log(this.UserListForm.controls.confirmPassword.value)
      this.isValid = false;
      return;
    }

    if (this.UserListForm.controls.password.value !== this.UserListForm.controls.confirmPassword.value) {
      this.UserListForm.controls.confirmPassword.setErrors({ pwdsDontMatch: true });
      this.isValid = false;
      return;
    }
    else {
      var modelGroup2 = {
        "Email": this.ResetPwdEmail,
        // "NewPassWord": this.password,
        "NewPassWord": this.UserListForm.get('password').value,
        "RoleNameValue": this.rolevalue
      };
    }
    this.accountService.Resetpassword(modelGroup2)
      .subscribe(
        (data: any) => {
          if (this.rolevalue == "SuperAdmin") {
            this.GetUserList();
            this.GetGroupListByGlobalID();
            this.ButtonShow = true;
            this.OpCode = "1";
            this.GetGlobalcodeByRolelist();
          } else {
            this.OpCode = "2";
            this.ButtonShow = false;
            this.GetGroupBybyUserGroup();
            this.GetGlobalcodeByRolelist();
            this.GetUserListDealerName();
          }
          this.submitted = false;

          this.showNotification("success", "Password updated successfully");
          this.reinitiatengmodel();
          this.Createform();
          this.Closepasswordmodel();
          this.closepopupmodel();
        },
        error => {
        });
  }

  Closepasswordmodel() {

    this.UserListForm.controls.confirmPassword.setValue('');
    this.UserListForm.controls.password.setValue('');
    this.ngOnInit();
    this.modalRef.hide();
  }

  CheckLoggedActivity(values: any): void {


    this.checkActivitylogValue = values.currentTarget.checked;
  }

  CheckSupportTeam(values: any): void {


    this.IsSupportTeamValue = values.currentTarget.checked;
  }

  CheckAithrVerify(values: any): void {
    this.IsAithrVerifyValue = values.currentTarget.checked;
  }

  CheckRepresentative(values: any): void {
    this.IsRepresentativeValue = values.currentTarget.checked;
  }

  ColorThemeActivity(values: any): void {


    this.Coloridvalidation = true;
  }


  //change event on role select

  changeonrole(data) {

    this.Roleidvalidation = true;
  }

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }



  onAithrUsers(event,value): void  {


   // var isChecked=true;
    var isChecked = event.currentTarget.checked;
    if(value==1)
    {
      this.IsAithrhubng=isChecked;
    }
    if(value==2)
    {
      this.IsAithrxng=isChecked;
    }
    if(value==3)
    {
      this.IsAithrMarketPlaceng=isChecked;
    }
   }

  onSubmit() {
debugger
    this.submitted = true;
    //console.log("Form Submitted");

    if (this.selectedDealerId == "") {
      this.Dealeridvalidation = false;

    } else {
      this.Dealeridvalidation = true;
    }


    if (this.savegruopId == undefined) {
      this.GruopIdvalidation = false;

    } else {
      this.GruopIdvalidation = true;
    }

    if (this.UserListForm.controls.userRoleControl.value == "0") {
      this.Roleidvalidation = false;

    } else {
      this.Roleidvalidation = true;
    }

    if (this.UserListForm.controls.colortheme.value == 0) {
      this.Coloridvalidation = false;

    } else {
      this.Coloridvalidation = true;
    }


    //check validation

    if (this.UserListForm.controls.firstname.value == "") {
      this.isValid = false;
      return;
    }


    if (this.UserListForm.controls.Lastname.value == "") {
      this.isValid = false;
      return;
    }

    if (this.UserListForm.controls.email.value == "") {
      this.isValid = false;
      return;
    }
    const phoneNumberField = this.phoneNumberControl;
    if (phoneNumberField.value && phoneNumberField.invalid) {
      this.isValid = false;
      return;
    } else if (!phoneNumberField.value) {
         this.isValid = false;
         return;
       }
    // if (this.UserListForm.controls.phoneNumber.value == "") {
    //   this.isValid = false;
    //   return;
    // }
    // if (this.UserListForm.controls.phoneNumber.value.length < 10) {
    //   this.isValid = false;
    //   return;
    // }

    if (this.UserListForm.controls.city.value == "") {
      this.isValid = false;
      return;
    }
    if (this.UserListForm.controls.region.value == "") {
      this.isValid = false;
      return;
    }

    if (this.UserListForm.controls.colortheme.value == 0) {
      this.isValid = false;
      return;
    }

    // if (this.UserListForm.controls.colortheme.value == "") {
    //   this.isValid = false;
    //   return;
    // }



    if (this.UserListForm.controls.userRoleControl.value == "") {
      this.isValid = false;
      return;
    }


    if (this.UserListForm.controls.email.errors != null) {
      this.isValid = false;
      return;
    }
    // console.log("Form Validated");
    if (this.UserListForm.controls.invalid) {
      this.isValid = false;
      return;
    }
    else {
      var modelGroup = {
        "Id": this.edititemid,
        "firstname": this.UserListForm.controls.firstname.value,
        "Lastname": this.UserListForm.controls.Lastname.value,
        "email": this.UserListForm.controls.email.value,
        "phoneNumber": this.phoneNumberControl.value,//this.UserListForm.controls.phoneNumber.value,
        "city": this.UserListForm.controls.city.value,
        "region": this.UserListForm.controls.region.value,
        "Rid": this.UserListForm.controls.userRoleControl.value,
        "Did": this.selectedDealerId,//this.UserListForm.controls.dealerName.value,
        "Gid": this.savegruopId,//this.UserListForm.controls.groupName.value,
        "Province": this.UserListForm.controls.region.value,
        "ColorTheme": this.UserListForm.controls.colortheme.value,
        "IsLoggingActivity": this.checkActivitylogValue,
        "IsSupportedTeam": this.IsSupportTeamValue,
        "IsAithrhub": this.IsAithrhubng,
        "IsAithrx": this.IsAithrxng,
        "IsAithrMarketPlace": this.IsAithrMarketPlaceng,
        "Roleid": this.RoleIdForScreen,
        "SelectedSreenListMaster": this.ScreenList,
        "IsRepresentative": this.IsRepresentativeValue,
        "IsAithrVerify": this.IsAithrVerifyValue

      }
      //console.log("Save User Data:", modelGroup);

      this.accountService.Saveadduser(modelGroup)
        .subscribe(
          (data: any) => {
           debugger;
            if (data == "2" || data == "4") {
              this.showNotification("error", "User already registered in system.");
              return;
            } else {


              if (this.rolevalue == "SuperAdmin") {
                this.GetUserList();
                this.ButtonShow=true;
                this.GetGroupListByGlobalID();
                this.OpCode="1";
                this.GetGlobalcodeByRolelist();
              } else {
                this.OpCode="2";
                this.ButtonShow=false;
                this.GetGroupBybyUserGroup();
                this.GetGlobalcodeByRolelist();
                this.GetUserListDealerName();
              }
              this.selectedGroupIds = [];
              this.selectedDealerIds = [];
              this.showNotification("success", "Data saved successfully");

              // for sending email
              if(data != "1")
              {
                this.UserEmailSend =data.email;
                this.emailpassword=data.password;
                this.SendRegisterationEmail();
              }

            }

            this.submitted = false;
            this.reinitiatengmodel();
            this.Createform();
            this.ngOnInit();
            this.CloseAddmodel();
            this.closepopupmodel();
            //console.log("Save User Response:", data);
          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }


  }


SendRegisterationEmail() {
  debugger;
  var modelGroup = {
    "UserId": this.UserEmailSend.toString(),
    "password":this.emailpassword
  }
  this.globalcodeService.sendemailRegister(modelGroup)
    .subscribe(
      data => {
      },
      error => {
      });
}

  reinitiatengmodel() {

    const dealerid = localStorage.getItem('DealerId');
    const roleid = localStorage.getItem('roleid');
    const groupid = localStorage.getItem('groupid');
    this.firstname = "";
    this.Lastname = "";
    this.email = "";
    this.checkActivitylog = "";
    this.colortheme = 0;
    this.checkActivitylogValue = false;
    this.IsSupportTeamValue = false;
    this.IsRepresentativeValue = false;
    this.IsAithrVerifyValue=false;
    this.Gid = (groupid != null ? parseInt(groupid) : 0);


    if (dealerid != null && parseInt(dealerid) > 0) {
      this.GetDealerNamebyGroupId(this.Gid, "");
    }
    this.Did = (dealerid != null ? parseInt(dealerid) : 0);
    // this.Rid = (roleid != null ? parseInt(roleid) : 0);
    this.Rid = 0;
   this.phoneNumber = "";
   this.phone="";
    this.city = "";
    this.region = "";
    this.selectedGroupId = "";
    this.selectedDealerId = "";

  }
  // function for delete users
  deleteUser(email: any) {

    this.accountService.deleteUsers(this.DeleteUserid)
      .subscribe(
        (data: any) => {
          this.showNotification("success", "Deleted successfully");
          if (this.rolevalue === "SuperAdmin") {
            this.GetUserList();
            this.ButtonShow=true;
            this.GetGroupListByGlobalID();
            this.OpCode="1";
            this.GetGlobalcodeByRolelist();
          } else {
          this.OpCode="2";
          this.ButtonShow=false;
          this.GetGroupBybyUserGroup();
          this.GetGlobalcodeByRolelist();
          this.GetUserListDealerName();
          this.GetUserList();
          }
         // return this.router.navigateByUrl("/List");
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }

  DeactivateUsers(email: any) {
    var modelGroup = {
      "Email": this.DeleteActivateUserid,
    }
    this.accountService.deactivateUsers(modelGroup)
      .subscribe(
        (data: any) => {
          this.DeleteActivateUserid="";
          if (this.rolevalue === "SuperAdmin") {
            this.GetGroupListByGlobalID();
            this.GetUserList();
            this.ButtonShow=true;
            this.OpCode="1";
            this.GetGlobalcodeByRolelist();
            this.showNotification("success", "Deactivated successfully");
          } else {
            this.OpCode="2";
            this.ButtonShow=false;
            this.GetGroupBybyUserGroup();
            this.GetGlobalcodeByRolelist();
            this.GetUserListDealerName();
          }
         // return this.router.navigateByUrl("/List");
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }

  // function for Activating the users

  ActivateUsers(email: any) {

    var modelGroup = {
      "Email": this.ActivateUserid,
    }

    this.accountService.activateUsers(modelGroup)
      .subscribe(
        (data: any) => {
          this.ActivateUserid="";
          if (this.rolevalue === "SuperAdmin") {

            // alert("ACTIVETED");
            this.GetUserList();
            this.GetGroupListByGlobalID();
            this.ButtonShow = true;
            this.OpCode = "1";
            this.GetGlobalcodeByRolelist();
            this.showNotification("success", "Activated successfully");
          } else {
            this.OpCode = "2";
            this.ButtonShow = false;
            this.GetGroupBybyUserGroup();
            this.GetGlobalcodeByRolelist();
            this.GetUserListDealerName();
          }
          return this.router.navigateByUrl("/List");
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }

}
