import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';

const baseUrl = `${environment.apiUrl}/Account`;

@Injectable({ providedIn: 'root' })

@Injectable({
  providedIn: 'root'
})
export class AddgroupService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  constructor(
    private router: Router,
    private http: HttpClient
  ) {

    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();

    this.loginSubject = new BehaviorSubject<Login>(null);
    this.logins = this.loginSubject.asObservable();

  }


  private baseUrl_saveaddgroup: string = environment.apiUrl + '/Admin/AddGroup';
  private baseUrl_GetAllGroupsList: string = environment.apiUrl + '/Admin/GetAllGroupsList';
  private baseUrl_DeleteGroupsList: string = environment.apiUrl + '/Admin/DeleteGroup';
  private baseUrl_EditGroupList: string = environment.apiUrl + '/Admin/GetGroupById';
  private baseUrl_UpdateGroupName: string = environment.apiUrl + '/Admin/UpdateGroup';
  private baseUrl_GetAllPositionList: string = environment.apiUrl + '/Admin/GetAllPositionsList';
  private baseUrl_saveaddposition: string = environment.apiUrl + '/Admin/AddPosition';
  private baseUrl_DeletePositionlist: string = environment.apiUrl + '/Admin/DeletePosition';
  private baseUrl_EditpositionList: string = environment.apiUrl + '/Admin/GetPositionsById';
  private baseUrl_UpdatepositionName: string = environment.apiUrl + '/Admin/UpdatePosition';

  private baseUrl_GetAllAithrRepList: string = environment.apiUrl + '/Admin/GetAllAithrRepsList';
  private baseUrl_EditAithrRep: string = environment.apiUrl + '/Admin/GetAithrRepsById';
  private baseUrl_UpdateAithrRep: string = environment.apiUrl + '/Admin/UpdateAithrRep';
  private baseUrl_DeleteAithrRep: string = environment.apiUrl + '/Admin/DeleteAithrRep';

  private baseUrl_GetAllAithrRepWholeSales: string = environment.apiUrl + '/Admin/GetAllAithrRepWholeSales';

  private baseUrl_AddAithrRepWholeSale: string = environment.apiUrl + '/Admin/AddAithrRepWholeSales';
  private baseUrl_GetAllRolesList:string = environment.apiUrl + '/Admin/GetAllRolesList';
  private baseUrl_saveaddroles:string = environment.apiUrl + '/Admin/AddRole';
  private baseUrl_EditrolesList:string = environment.apiUrl + '/Admin/GetRolesById';
  private baseUrl_DeleteRolelist:string = environment.apiUrl + '/Admin/DeleteRole';
  private baseUrl_UpdaterolesList:string = environment.apiUrl + '/Admin/UpdateRole';

  private baseUrl_GetdealerListbygroupId:string = environment.apiUrl + '/Dashboard/GetdealerListbygroupId';

  private baseUrl_GetUserDetailByUserId: string = environment.apiUrl + '/Admin/GetUserDetailByUserId';
  private baseUrl_getUserPictures: string = environment.apiUrl + '/Admin/GetUserPicturesById';
  private baseUrl_updatePicture: string = environment.apiUrl + '/Admin/updateProfilePicture';
  

  private baseUrl_GetAllDealerList:string = environment.apiUrl + '/ManageInventory/GetAllDealerNameForSearch';


  private baseUrl_GetallScreenList: string = environment.apiUrl + '/ManageInventory/GetAllScreenList';

  private baseUrl_SaveSelectedScreenList: string = environment.apiUrl + '/ManageInventory/SaveSelectedScreenList';

  private baseUrl_GetAlldealerListApplocation:string = environment.apiUrl + '/ManageInventory/GetAlldealerListApplocation';

  getAlldealerListApplocation(FormData) {
    return this.http.post<any>(this.baseUrl_GetAlldealerListApplocation, FormData);

  }
  
  //calling function saving method
  Saveaddgroup(formdata) {
    return this.http.post<any[]>(this.baseUrl_saveaddgroup, formdata);
  }

  //get grouplist

  //calling function saving method
  Getgrouplist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllGroupsList);
  }

  // Get dealer list by group Id
  getdealerListbygroupId(FormData) {
    return this.http.post<any>(this.baseUrl_GetdealerListbygroupId, FormData);

  }


  // Get All dealer list
  getAlldealerListForSearch(FormData) {
    return this.http.post<any>(this.baseUrl_GetAllDealerList, FormData);

  }

  //delete grouplist
  //calling function saving method
  Deletegrouplist(FormData) {
    return this.http.post<any>(this.baseUrl_DeleteGroupsList, FormData);
  }

  //this is for calling edit functionality
  EditGroupList(FormData) {
    return this.http.post<any>(this.baseUrl_EditGroupList, FormData);

  }



  //get all screenlist

  //this is for calling edit functionality
  GetAllScreenList(FormData) {
    debugger;
    return this.http.post<any>(this.baseUrl_GetallScreenList, FormData);

  }


   //this is for calling edit functionality
   SaveSelectedscreen(FormData) {
    debugger;
    return this.http.post<any>(this.baseUrl_SaveSelectedScreenList, FormData);

  }




  //this is for calling edit functionality
  UpdateGroupName(FormData) {
    return this.http.post<any>(this.baseUrl_UpdateGroupName, FormData);

  }
  //this for getting position list
  GetPositionlist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllPositionList);
  }
  // this is for add positon
  Saveaddposition(formdata) {
    return this.http.post<any[]>(this.baseUrl_saveaddposition, formdata);
  }
  //this is for delete position
  DeletePosition(Formdata) {
    return this.http.post<any>(this.baseUrl_DeletePositionlist, Formdata);
  }

  //this is for calling edit functionality
  EditpositionList(FormData) {
    return this.http.post<any>(this.baseUrl_EditpositionList, FormData);

  }

  //this is for calling edit functionality
  UpdatepostionName(FormData) {
    return this.http.post<any>(this.baseUrl_UpdatepositionName, FormData);

  }


  //calling function Get method
  GetAithrReplist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllAithrRepList);
  }

  //this is for calling edit functionality
  EditAithrRepList(FormData) {
    return this.http.post<any>(this.baseUrl_EditAithrRep, FormData);

  }

  //this is for calling edit functionality
  UpdateAithrRepDetail(FormData) {
    return this.http.post<any>(this.baseUrl_UpdateAithrRep, FormData);

  }

  DeleteAithrReplist(FormData) {
    return this.http.post<any>(this.baseUrl_DeleteAithrRep, FormData);
  }

  // Functions for getting AITHRWHOLE SALER
  GetAithrRepWholeSalelist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllAithrRepWholeSales);
  }


  // function for saving AddAithrRepWholeSales
  SaveAithrRepWholeSale(formdata) {
    return this.http.post<any[]>(this.baseUrl_AddAithrRepWholeSale, formdata);
  }
//this for getting roles list
GetRoleslist() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetAllRolesList);
}

Saveaddroles(formdata) {
  return this.http.post<any[]>(this.baseUrl_saveaddroles, formdata);
}
EditrolesList(FormData) {
  return this.http.post<any>(this.baseUrl_EditrolesList, FormData);

}
UpdaterolesList(FormData) {
  return this.http.post<any>(this.baseUrl_UpdaterolesList, FormData);

}


DeleteRole(Formdata) {
  return this.http.post<any>(this.baseUrl_DeleteRolelist, Formdata);
}


getUserDetailByUserId(FormData) {
  return this.http.post<any>(this.baseUrl_GetUserDetailByUserId, FormData);

}

getUserPictures(FormData) {
  return this.http.post<any>(this.baseUrl_getUserPictures, FormData);

}

updatePicture(FormData) {
  return this.http.post<any>(this.baseUrl_updatePicture, FormData);

}

}
