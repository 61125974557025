  <footer  class="main-footer text-center">
    <div class="col-md-12">
      <div class="row mb-1">
        <div class="d-inline-block w-100">
          Welcome to Spadez
          <strong>Copyright © 2023 - 2024  Spadez Automotive Intelligence Corp. </strong> All rights reserved.
        </div>
      </div>
      <div id="address-user" class="row">
        <ul class="list-unstyled">
          <li><a href="tel:403-971-1173">587-317-7744</a></li>
          <!-- <li>1.833.4SpadezX</li> -->
          <li><a href="mailto:spadez@gmail.com">spadez@gmail.com</a></li>
          <li><a id="websiteaddress" target="_blank" href="Spadez@gmail.com">spadezgroup.com</a></li>
        </ul>
      </div>
    </div>



</footer>