
<section id="admindashboard">
    <div class="container-fluid" >
        <div class="page-header">
            <div class="row">
                <div class="col-lg-12">
                    <h3>
                        Admin Dashboard
                        <small>Vehicles Admin panel</small>
                    </h3>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-calendar-days"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-warning">{{dateSynchedReceived | date }}</h5>
                                    <span>Date Synced</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                    <h5 class="mt-0 counter text-warning">{{totalCount}}</h5>
                                    <span>Total Count</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                        <h5 class="mt-0 counter text-success">{{totalSynchedCount}}</h5>
                                    <span>Total Synced</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-xl-3 col-lg-6 col-sm-6">
                <div class="card">
                    <div class="card-body">
                        <div class="stat-widget-dashboard">
                            <div class="d-flex justify-content-between align-item-center">
                                <i class="fa-solid fa-car"></i>
                                <div class="text-end">
                                        <h5 class="mt-0 counter text-warning">{{totalNotSynchedCount}}</h5>
                                    <span>Total Not Synced</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      
        <!-- <div class="row">
              <div class="col-md-6"> 
                <div class="card card-raised h-100">
                  <div class="card-header card-raised bg-secondary text-white px-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="me-4">
                            <h4 class="card-title text-white mb-0">Availability of Vechiles </h4>
                            <div class="card-subtitle">Compared to previous year</div>
                        </div>
                    </div>
                   </div>
                   <div class="card-body p-4">
                      <div class="row">
                          <canvas baseChart 
                          [datasets]="barChartData"
                          [labels]="barChartLabels"
                          [options]="barChartOptions"
                          [plugins]="barChartPlugins"
                          [legend]="barChartLegend"
                          [chartType]="barChartType">
                        </canvas>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card card-raised h-100">
                    <div class="card-header card-raised bg-info text-white px-4">
                      <div class="d-flex justify-content-between align-items-center">
                          <div class="me-4">
                              <h4 class="card-title text-white mb-0">Statistics  of Vechiles</h4>
                              <div class="card-subtitle">Compared to previous year</div>
                          </div>
                      </div>
                     </div>
                     <div class="card-body p-4">
                        <div class="row">
                            <div class="col-12">
                                <h5 class="text-info mb-3">Dealer Details</h5>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                           <th><small>Dealer Name</small></th>
                                           <th><small>Make </small></th>
                                           <th><small>Count</small></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Empire Mazda of Huntington</td>
                                            <td>Mazda</td>
                                            <td>50</td>
                                        </tr>
                                        <tr>
                                            <td>Test Buick Gmc Dealer</td>
                                            <td>Mazda</td>
                                            <td>35</td>
                                        </tr>
                                        <tr>
                                            <td>Empire Ford Lincoln of Abingdon</td>
                                            <td>Ford</td>
                                            <td>22</td>
                                        </tr>
                                        <tr>
                                            <td>Century RV</td>
                                            <td>Rockwood</td>
                                            <td>37</td>
                                        </tr>
                                        <tr>
                                            <td>Test Hyundai Dealer</td>
                                            <td>Hyundai</td>
                                            <td>56</td>
                                        </tr>
                                    </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                     </div>
                    </div>
                </div>
        </div> -->
        <div class="row mt-3">
          <div class="col-md-12">
            <div class="card card-raised h-100">
              <div class="card-header card-raised bg-info text-white">
                <h5 class="card-title text-white text-uppercase mb-0">Vehicles Synced Details Region Wise</h5>
               </div>
               <div class="card-body p-4">
                  <div class="row">
                      <div class="col-12">
                        <div class="row justify-content-between align-items-center">
                            <div class="col-auto documentbtn">
                              <ul class="list-unstyled mb-1">
                                <li><button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="copytable('usertable')">Copy</button></li>
                                <li>
                                  <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="exportexcel()">Excel</button>
                                </li>
                                
                                <li>
                                  <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="downloadPdf()">PDF</button>
                                </li>
                                <li>
                                  <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="printTable()">Print</button>
                                </li>
                              </ul>
                            </div> 
                            <div class="search-heros col-md-6 col-lg-5 col-xl-3 ml-auto">
                                <div class="form-group d-flex mb-2">
                                <label class="mr-3 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchRegionWiseText"
                                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                            </div>
                        </div>

                          <div class="table-responsive">
                              <table class="table table-bordered" id="usertable">
                              <thead>
                                  <tr>
                                     <th  (click)="sort('regionName')"><small>Region Name</small><i class="fa fa-fw fa-sort"></i></th>
                                     <th><small>Date Received  </small></th>
                                     <th style="text-align: right;"><small>Total Synced</small></th>
                                     <th style="text-align: right;"><small>Total Not Synced</small></th>
                                     <th style="text-align: right;"><small>Total Count</small></th>
                                     <th class="text-center"><small>Status</small></th>
                                  </tr>
                              </thead>
                              <tbody>
                                <tr *ngFor="let StatisticsRegionWiselistData of StatisticsRegionWiselist | slice: (pageRegion-1) * pageRegionSize : pageRegion * pageRegionSize; index as i">
                                    <td>{{ StatisticsRegionWiselistData.regionName }}</td>
                                    <td> {{ StatisticsRegionWiselistData.lastFeedReceivedOn | date }}</td>
                                    <td style="text-align: right;"> {{ StatisticsRegionWiselistData.totalSynched }}</td>
                                    <td style="text-align: right;"> {{ StatisticsRegionWiselistData.totalNotSynched }}</td>
                                    <td style="text-align: right;"> {{ StatisticsRegionWiselistData.totalCount }}</td>
                                    <td class="text-center" *ngIf="StatisticsRegionWiselistData.totalNotSynched === 0"> 
                                        
                                        <i class="fa-solid fa-circle-check text-success" style="font-size: 18px;vertical-align: middle;"></i>
                                    </td>
                                    <td class="text-center" *ngIf="StatisticsRegionWiselistData.totalNotSynched !==0">  
                                        
                                        <i class="fa-solid fa-circle-check text-danger ml-1" style="font-size: 18px;vertical-align: middle;"></i>
                                    </td>
                                     
                                  </tr>
                              </tbody>
                              </table>
                              <br>
                              <ngb-pagination [(page)]="pageRegion"
                              [pageSize]="pageRegionSize"
                              [collectionSize]="StatisticsRegionWiselist.length" aria-label="Default pagination"></ngb-pagination> 
                         <!-- <ngb-pagination [collectionSize]="StatisticsRegionWiselist.length" [(page)]="page" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination> -->

                          </div>
                      </div>
                  </div>
               </div>
              </div>
          </div>
        </div>
        <div class="row mt-3">
            <div class="col-md-12">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">
                <h5 class="card-title text-white text-uppercase mb-0">Vehicles Synced Details Dealer Wise</h5>
                 </div>
                 <div class="card-body p-4">
                    <div class="row">
                        <div class="col-12">
                            <div class="row justify-content-between align-items-center">
                                <div class="col-auto documentbtn">
                                  <ul class="list-unstyled mb-1">
                                    <li><button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="copytable('usertableDealer')">Copy</button></li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="exportexcelDealer()">Excel</button>
                                    </li>
                                   
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="downloadPdfDealer()">PDF</button>
                                    </li>
                                    <li>
                                      <button class="btn btn-secondary btn-sm mb-md-0 mb-1" (click)="printTableDealer()">Print</button>
                                    </li>
                                  </ul>
                                </div>
                                <div class="search-heros col-md-6 col-lg-5 col-xl-3 ml-auto">
                                <div class="form-group d-flex mb-2">
                                    <label class="mr-3 mt-2">Search:</label>
                                    <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                                </div>
                                </div>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-bordered" id="usertableDealer">
                                <thead>
                                    <tr>
                                        <th  (click)="sortdealer('dealerName')"><small>Dealer Name</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th  (click)="sortdealer('regionName')"><small>Region Name</small><i class="fa fa-fw fa-sort"></i></th>
                                       <th style="min-width:95px;"><small>Date Received  </small></th>
                                       <th style="text-align: right;"><small>Total Synced</small></th>
                                       <th style="text-align: right;"><small>Total Not Synced</small></th>
                                       <th style="text-align: right;"><small>Total Count</small></th>
                                       <th class="text-center"><small>Status</small></th>
                                    </tr>
                                </thead>
                                <tbody>
                                  <tr *ngFor="let StatisticsDealerWiselistdata of StatisticsDealerWiselist | filter:searchText | slice: (dealerpage-1) * dealerpageSize : (dealerpage-1) * dealerpageSize + dealerpageSize; let i = index; let odd = odd">
                                    <td>{{ StatisticsDealerWiselistdata.dealerName }}</td>
                                      <td>{{ StatisticsDealerWiselistdata.regionName }}</td>
                                      <td> {{ StatisticsDealerWiselistdata.lastFeedReceivedOn | date }}</td>
                                      <td style="text-align: right;"> {{ StatisticsDealerWiselistdata.totalSynched }}</td>
                                      <td style="text-align: right;"> {{ StatisticsDealerWiselistdata.totalNotSynched }}</td>
                                      <td style="text-align: right;"> {{ StatisticsDealerWiselistdata.totalCount }}</td>
                                      <td class="text-center" *ngIf="StatisticsDealerWiselistdata.totalNotSynched === 0"> 
                                        
                                        <i class="fa-solid fa-circle-check text-success" style="font-size: 18px;vertical-align: middle;"></i>
                                    </td>
                                    <td class="text-center" *ngIf="StatisticsDealerWiselistdata.totalNotSynched !==0">  
                                        
                                        <i class="fa-solid fa-circle-check text-danger ml-1" style="font-size: 18px;vertical-align: middle;"></i>
                                    </td>
                                      
                                    </tr>
                                </tbody>
                                </table>
                                <br>
                           <ngb-pagination [collectionSize]="StatisticsDealerWiselist.length" [(page)]="dealerpage" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
  
                            </div>
                        </div>
                    </div>
                 </div>
                </div>
            </div>
          </div>
    </div>
    <!-- <app-footer id="secondfooter"></app-footer> -->
</section>








