import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from '@app/_services';
import { AddrepoService } from '@app/_services/addrepo.service';
import { DashboardService } from '@app/_services/dashboard.service';
import { LenderService } from '@app/_services/lender.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotifierService } from 'angular-notifier';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { AIXLenderRateSheet } from '@app/_models/AIXLenderRateSheet';
import { AccountService } from '@app/_services/account.service';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { GlobalcodeService } from '@app/_services/globalcode.service';
@Component({
  selector: 'app-add-lender-rate-sheet',
  templateUrl: './add-lender-rate-sheet.component.html',
  styleUrls: ['./add-lender-rate-sheet.component.less']
})
export class AddLenderRateSheetComponent implements OnInit {
  [x: string]: any;
  AddLenderRateSheetForm: FormGroup;
  private notifier: NotifierService;
  constructor(private route: ActivatedRoute,
    private fb: FormBuilder,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private addRepoService: AddrepoService,
    private router: Router,
    private dashboardService: DashboardService,
    private spinner: NgxSpinnerService,
    private lenderService: LenderService,
    private accountService: AccountService,
    private globalcodeService: GlobalcodeService,
    notifier: NotifierService) {

    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }
  LenderName: any;
  OpcodeCheck:any;
  lenderrateSheet = [];
  pageRep = 1;
  lenderRateSheets: AIXLenderRateSheet[] = [];
  pageSizeRep = 10;
  ValueTypeFilter:any;
  HeaderYearFrom:any;
  HeaderYearTill:any;
  OdometerFROMHeader:any;
  OdometerTillHeader:any;
  isDesc: boolean = false;
  isValid: boolean = true;
  column: string = 'userName';
  TotalRecord: any;
  deleteitem: any = 0;
  modalRef: BsModalRef;
  LenderID: any;
  ValueType: any;
  PlanType: any;
  SearchType: any;
  ViewCBBDropDown: any;
  ReturnValue: any;
  Condition1From: any;
  Condition1Till: any;
  Condition2From: any;
  Condition2Till: any;
  ShowCbbValue:any;
  Valuelist = [];
  CbbValuelist = [];
  ConditionYearFrom = [];
  ConditionYearUntill = [];
  ColorTheme: any;
  DropDownTitleFrom: any;
  DropDownTitleTill: any;

  hideOdoMeterFrom: any;
  hideOdoMeterTill: any;
  yearMonthDropDownTill: any;
  yearMonthDropDown: any;
  hideOdoMeterReseve: any;
  Provincelist = [];
  page = 1;
  pageSize = 10;

  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.lenderrateSheet.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };

  loading = false;
  submitted = false;
  updateid: any;
  edititemid: any;
  searchText;
  ngOnInit(): void {

    // for getting value of VehicleId from qurey string
    this.LenderName = this.route.snapshot.paramMap.get('lenderName');

    this.PlanType = this.route.snapshot.paramMap.get('planType');


    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.CreateFrom();
    this.spinner.show();
    this.OpcodeCheck="1";
    this.GetLenderRateSheetbyLenderName(this.LenderName);
    this.LenderID = this.LenderName;

    this.GetValueType();
    this.GetConditionYearFrom();
    this.GetConditionYearUntill();
    this.ViewCBBDropDown = "0";
    this.yearMonthDropDownTill = "1";
    this.yearMonthDropDown = "1";

    this.hideOdoMeterFrom = "1";
    this.hideOdoMeterTill = "1";

    this.HeaderYearFrom="Year";
    this.HeaderYearTill="Year";
    this.OdometerFROMHeader = "Odometer FROM";
    this.OdometerTillHeader="Odometer Till";
    this.ShowCbbValue="false";
    this.GetValueCBBValuies();
    this.GetProvincelist();
    // this.SearchType ="CarYear-OdoMeter";
  }

  openModal(template: TemplateRef<any>, itemid: any) {
    this.deleteitem = itemid;
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
  }
  decline(): void {
    this.modalRef.hide();
  }
  get f() { return this.AddLenderRateSheetForm.controls; }

  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  // function for confirm  delete
  confirm(): void {
    this.deleteitem;
    var modelGroup = {
      "Id": this.deleteitem
    }
    this.lenderService.DeleteLenderRateSheet(modelGroup)
      .subscribe(
        data => {
          this.showNotification("success", "Deleted successfully");
          this.GetLenderRateSheetbyLenderName(this.LenderName);
        },
        error => {
        });

    this.modalRef.hide();

  }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.lenderRateSheets);
  }

  GetValueType() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getValueTypeLists(modelGroup)
      .subscribe(
        (data: any) => {

          this.Valuelist = data;
        },
        error => {
        });
  }

  GetValueCBBValuies() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getCbbValueLists(modelGroup)
      .subscribe(
        (data: any) => {
         
          this.CbbValuelist = data;
        },
        error => {
        });
  }

  GetConditionYearUntill() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getGetYearLists(modelGroup)
      .subscribe(
        (data: any) => {

          this.ConditionYearUntill = data;
        },
        error => {
        });
  }

  GetConditionYearFrom() {
    var modelGroup = {
      "Opcode": "1",
    }
    this.globalcodeService.getGetYearLists(modelGroup)
      .subscribe(
        (data: any) => {

          this.ConditionYearFrom = data;
        },
        error => {
        });
  }

  printTable() {
    var prepare = [];
    this.lenderRateSheets.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.condition1From);
      tempObj.push(e.condition1Till);
      tempObj.push(e.condition2From);
      tempObj.push(e.condition2Till);
      tempObj.push(e.searchType);
      tempObj.push(e.valueType);
      tempObj.push(e.returnValue);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>LenderName</th><th>condition 1 From</th><th>condition 1 Till</th><th>condition 2 From</th><th>condition 2 Till</th><th>searchType</th><th>valueType</th><th>Return Value</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  downloadPdf() {
    var prepare = [];
    this.lenderRateSheets.forEach(e => {
      var tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.condition1From);
      tempObj.push(e.condition1Till);
      tempObj.push(e.condition2From);
      tempObj.push(e.condition2Till);
      tempObj.push(e.searchType);
      tempObj.push(e.valueType);
      tempObj.push(e.returnValue);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['LenderName', 'Condition1From', 'Condition1Till', 'Condition2From', 'Condition2Till', 'SearchType', 'ValueType', 'Return Value']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Lenderratesheet' + '.pdf');
  }
  exportexcel() {
    const prepare = [];
    this.lenderRateSheets.forEach(e => {
      const tempObj = [];
      tempObj.push(e.lenderName);
      tempObj.push(e.condition1From);
      tempObj.push(e.condition1Till);
      tempObj.push(e.condition2From);
      tempObj.push(e.condition2Till);
      tempObj.push(e.searchType);
      tempObj.push(e.valueType);
      tempObj.push(e.returnValue);
      prepare.push(tempObj);
    });

    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['LenderName', 'Condition1From', 'Condition1Till', 'Condition2From', 'Condition2Till', 'SearchType', 'ValueType', 'Return Value'],
      ...prepare
    ]);

    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'VEHICLESSYNCHEDListDealerWise');

    / Save the workbook to a file /
    XLSX.writeFile(wb, 'Lenderratesheet.xlsx');
  }

  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  openLenderRateSheetModel(targetModal) {
    debugger;
    this.edititemid = 0;

    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

  }

  CloseAddmodel() {
    this.ReInitateModels();
    this.submitted = false;
    this.modalRef.hide();
  }


  onSubmit() {
    debugger;

    if (this.ValueType === "Term") {


    } else if (this.ValueType === "PPSA") {

      this.Condition2Till = this.Condition2From;
    } else if (this.ValueType === "CBB") {


    } else if (this.ValueType === "Reserves") {

    this.Condition2From ="0";
    this.Condition2Till="0";
    }
    if (this.edititemid !== undefined && this.edititemid !== 0) {
      // EDIT KA CODE
      var modelGroup1 = {
        "id": this.edititemid,
        "LenderID": 0,
        "lenderName": this.AddLenderRateSheetForm.controls.LenderID.value,
        "ValueType": this.AddLenderRateSheetForm.controls.ValueType.value,
        "SearchType": this.AddLenderRateSheetForm.controls.SearchType.value,
        "ReturnValue": this.AddLenderRateSheetForm.controls.ReturnValue.value,
        "Condition1From": this.AddLenderRateSheetForm.controls.Condition1From.value,
        "Condition1Till": this.AddLenderRateSheetForm.controls.Condition1Till.value,
        "Condition2From": this.Condition2From,
        "Condition2Till": this.Condition2Till,
        "ConditionMatch": this.AddLenderRateSheetForm.controls.ConditionMatch.value,
        "PlanType": this.AddLenderRateSheetForm.controls.PlanType.value,
      };
      // Check if the form is invalid
      if (this.AddLenderRateSheetForm.invalid) {
        console.log("Form is invalid. Errors:", this.AddLenderRateSheetForm.errors);
        // for (const controlName in this.AddLenderRateSheetForm.controls) {
        //   const control = this.AddLenderRateSheetForm.get(controlName);
        //   if (control.invalid) {
        //     console.log(`Control: ${controlName}, Errors:`, control.errors);
        //   }
        // }

        this.submitted = true; // Set submitted to true to display the validation messages
        return;
      }
      this.lenderService.UpdateLenderRateSheet(modelGroup1)
        .subscribe(
          (data: any) => {

            this.GetLenderRateSheetbyLenderName(this.LenderName);
            this.submitted = false;
            this.showNotification("success", "Data Updated successfully");
            this.CreateFrom();
            this.ReInitateModels();
            this.modalRef.hide();
            this.closeEditModel();
            this.closepopupmodel();
            this.modalRef.hide();
          },
          error => {
          });
    } else {
      this.submitted = true; // Set submitted to true to display the validation messages
      if (this.AddLenderRateSheetForm.invalid) {
        this.isValid = false;
        return;
      } else {
        // SAVE  CODE
        var modelGroup = {
          "LenderID": 0,
          "lenderName": this.AddLenderRateSheetForm.controls.LenderID.value,
          "ValueType": this.AddLenderRateSheetForm.controls.ValueType.value,
          "SearchType": this.AddLenderRateSheetForm.controls.SearchType.value,
          "ReturnValue": this.AddLenderRateSheetForm.controls.ReturnValue.value,
          "Condition1From": this.AddLenderRateSheetForm.controls.Condition1From.value,
          "Condition1Till": this.AddLenderRateSheetForm.controls.Condition1Till.value,
          "Condition2From": this.Condition2From,
          "Condition2Till": this.Condition2Till,
          "ConditionMatch": this.AddLenderRateSheetForm.controls.ConditionMatch.value,
          "PlanType": this.AddLenderRateSheetForm.controls.PlanType.value,
        };

        this.lenderService.SaveLenderRateSheet(modelGroup)
          .subscribe(
            (data: any) => {
              this.GetLenderRateSheetbyLenderName(this.LenderName);
              this.submitted = false;
              this.showNotification("success", "Data saved successfully");
              this.CreateFrom();
              this.ReInitateModels();
              this.modalRef.hide();
              this.closeEditModel();
              this.closepopupmodel();
              this.modalRef.hide();
            },
            error => {
            });

        this.loading = true;
        this.isValid = true;
      }
    }
  }


  // function for open model pop up
  EditModalpopup(template: TemplateRef<any>, itemid: any) {

    this.edititemid = itemid;
    var modelGroup = {
      "id": itemid
    }

    this.lenderService.EditLenderRateSheet(modelGroup)
      .subscribe(
        data => {
          this.PlanType = this.PlanType;
          // alert( data[0].condition1Till);
          this.LenderID = data[0].lenderName;
          this.ValueType = data[0].valueType;

          if (this.ValueType === "Term") {
            this.SearchType = "CarYear-OdoMeter";
            this.ViewCBBDropDown = "0";
            this.DropDownTitleFrom = "Year";
            this.DropDownTitleTill = "Year";
            this.yearMonthDropDownTill = "1";
            this.yearMonthDropDown = "1";
            this.hideOdoMeterFrom = "1";
            this.hideOdoMeterTill = "1";
      
          } else if (this.ValueType === "PPSA") {
            this.SearchType = "Term";
            this.ViewCBBDropDown = "2";
            this.DropDownTitleFrom = "Month";
            this.DropDownTitleTill = "Month";
      
            this.yearMonthDropDownTill = "2";
            this.yearMonthDropDown = "2";
      
            this.hideOdoMeterReseve = "4";
      
            this.hideOdoMeterFrom = "2";
            this.hideOdoMeterTill = "2";
      
          } else if (this.ValueType === "CBB") {
            this.ViewCBBDropDown = "1";
            this.DropDownTitleFrom = "Year";
            this.DropDownTitleTill = "Year";
            this.SearchType = "CarYear-OdoMeter-Condition";
            this.yearMonthDropDownTill = "1";
            this.yearMonthDropDown = "1";
            this.hideOdoMeterFrom = "1";
            this.hideOdoMeterTill = "1";
      
          } else if (this.ValueType === "Reserves") {
            this.ViewCBBDropDown = "2";
            this.SearchType = "TotalFinance";
            this.DropDownTitleFrom = "Amount Finance";
            this.DropDownTitleTill = "Amount Finance";
      
            this.yearMonthDropDownTill = "3";
            this.yearMonthDropDown = "3";
      
            this.hideOdoMeterFrom = "2";
            this.hideOdoMeterTill = "2";
            this.hideOdoMeterReseve = "3";
      
          }

          this.SearchType = data[0].searchType;
          this.ReturnValue = data[0].returnValue;
          this.Condition1From = data[0].condition1From;
          this.Condition1Till = data[0].condition1Till;
          this.Condition2From = data[0].condition2From;
          this.Condition2Till = data[0].condition2Till;
          this.updateid = data[0].id;
          //this.PlanType =data[0].planType;

          this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });

        },
        error => {
        });


  }

  ReInitateModels() {
    // this.LenderID = "";
    this.ValueType = "";
    // this.PlanType=
    this.SearchType = "";
    this.ReturnValue = "";
    this.Condition1From = "";
    this.Condition1Till = "";
    this.Condition2From = "";
    this.Condition2Till = "";
  }
  closepopupmodel() {
    this.AddLenderRateSheetForm.invalid
  }

  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          this.Provincelist = data;
        },
        error => {
        });
  }

  SetUnitllvalue() {
    debugger;
    var check = this.Condition1From;
    this.Condition1Till = parseInt(this.Condition1From) + 6;
  }
  SetSerachTypeValue() {
    if (this.ValueType === "Term") {
      this.SearchType = "CarYear-OdoMeter";
      this.ViewCBBDropDown = "0";
      this.DropDownTitleFrom = "Year";
      this.DropDownTitleTill = "Year";
      this.yearMonthDropDownTill = "1";
      this.yearMonthDropDown = "1";
      this.hideOdoMeterFrom = "1";
      this.hideOdoMeterTill = "1";

    } else if (this.ValueType === "PPSA") {
      this.SearchType = "Term";
      this.ViewCBBDropDown = "2";
      this.DropDownTitleFrom = "Month";
      this.DropDownTitleTill = "Month";

      this.yearMonthDropDownTill = "2";
      this.yearMonthDropDown = "2";

      this.hideOdoMeterReseve = "4";

      this.hideOdoMeterFrom = "2";
      this.hideOdoMeterTill = "2";

    } else if (this.ValueType === "CBB") {
      this.ViewCBBDropDown = "1";
      this.DropDownTitleFrom = "Year";
      this.DropDownTitleTill = "Year";
      this.SearchType = "CarYear-OdoMeter-Condition";
      this.yearMonthDropDownTill = "1";
      this.yearMonthDropDown = "1";
      this.hideOdoMeterFrom = "1";
      this.hideOdoMeterTill = "1";

    } else if (this.ValueType === "Reserves") {
      this.ViewCBBDropDown = "2";
      this.SearchType = "TotalFinance";
      this.DropDownTitleFrom = "Amount Finance";
      this.DropDownTitleTill = "Amount Finance";

      this.yearMonthDropDownTill = "3";
      this.yearMonthDropDown = "3";

      this.hideOdoMeterFrom = "2";
      this.hideOdoMeterTill = "2";
      this.hideOdoMeterReseve = "3";

    }
  }
  closeEditModel() {
    this.modalRef.hide();
  }

  ResetFilter()
  {
    this.ValueTypeFilter ="0";
    this.OpcodeCheck="1";
    this.GetLenderRateSheetbyLenderName(this.LenderName);
  }
  FilterLenderList()
  {
   var check= this.ValueTypeFilter ;

   if (this.ValueTypeFilter === "Term") {
      
    this.HeaderYearFrom="Year";
    this.HeaderYearTill="Year";
    this.OdometerFROMHeader = "Odometer FROM";
    this.OdometerTillHeader="Odometer Till";
    this.ShowCbbValue="false";
   } else if (this.ValueTypeFilter === "PPSA") {
     
    this.HeaderYearFrom="Month";
    this.HeaderYearTill="Month";

    this.OdometerFROMHeader = "Province";
    this.OdometerTillHeader="Province";
    this.ShowCbbValue="false";
   } else if (this.ValueTypeFilter === "CBB") {
     
    this.ShowCbbValue="true";
    this.HeaderYearFrom="Year";
    this.HeaderYearTill="Year";
    this.OdometerFROMHeader = "Odometer FROM";
    this.OdometerTillHeader="Odometer Till";
   } else if (this.ValueTypeFilter === "Reserves") {
    this.HeaderYearFrom="Year";
    this.HeaderYearTill="Year";
    this.ShowCbbValue="false";
    this.OdometerFROMHeader = "Odometer FROM";
    this.OdometerTillHeader="Odometer Till";
   }

   
   this.OpcodeCheck="2";
   this.GetLenderRateSheetbyLenderName(this.LenderName);
  }

  GetLenderRateSheetbyLenderName(LenderName: any) {
    this.spinner.show();
    var modelGroup = {
      "LenderName": this.LenderName,
      "ValueType":this.ValueTypeFilter,
      "Opcode":this.OpcodeCheck
    }

    this.lenderService.getLenderRateSheetbyLenderName(modelGroup)
      .subscribe(
        data => {
          debugger;
          data.forEach((data, index) => {

            data.planType = this.PlanType;
          });
          this.lenderRateSheets = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });


  }


  handlePageSizeChange(event) {
    this.pageSizeRep = event.target.value;
    this.pageRep = 1;
  }

  // function for creating from
  CreateFrom() {
    this.AddLenderRateSheetForm = new FormGroup({
      LenderID: this.fb.control('', Validators.required),
      ValueType: this.fb.control('', Validators.required),
      SearchType: this.fb.control('', Validators.required),
      ReturnValue: this.fb.control('', Validators.required),
      Condition1From: this.fb.control(''),
      Condition1Till: this.fb.control(''),
      Condition2From: this.fb.control(''),
      Condition2Till: this.fb.control(''),
      ConditionMatch: this.fb.control(''),
      PlanType: this.fb.control(''),
      ValueTypeFilter: this.fb.control(''),

    });
  }
}
