import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home';
// import { AuthGuard } from './_helpers';
import { Role } from './_models';
import { CarfexDashboardComponent } from './carfex-dashboard/carfex-dashboard.component';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component';
import { AppComponent } from './app.component';
import { UserListComponent } from './user-list/user-list.component';
import { CarfaxapiListComponent } from './carfaxapi-list/carfaxapi-list.component';
import { VhrReportComponent } from './vhr-report/vhr-report.component';
import { LoginComponent } from './account/login.component';
import { VhrpdfreportViewComponent } from './vhrpdfreport-view/vhrpdfreport-view.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetpasswordaftermailLinkComponent } from './resetpasswordaftermail-link/resetpasswordaftermail-link.component'
import { UserProfileComponent } from './user-profile/user-profile.component';
import { IicBatchComponent } from './iic-batch/iic-batch.component';
import { DealerLocationComponent } from './dealer-location/dealer-location.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { ApicallHistoryComponent } from './apicall-history/apicall-history.component';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { AddGroupComponent } from './add-group/add-group.component';
import { AddUserrolesComponent } from './add-userroles/add-userroles.component';
import { ListPostionsComponent } from './list-postions/list-postions.component';
import { ListWholesalersComponent } from './list-wholesalers/list-wholesalers.component';
import { AddRepsComponent } from './add-reps/add-reps.component'
import { ListRepoComponent } from './list-repo/list-repo.component'
import { ListDealersComponent } from './list-dealers/list-dealers.component';
import { ActivateDealersListComponent } from './activate-dealers-list/activate-dealers-list.component';

import { AithrhubDashboardComponent } from './aithrhub-dashboard/aithrhub-dashboard.component'
import { AddGlobalCodeCategoryComponent } from './add-global-code-category/add-global-code-category.component'

import { GlobalCodeComponent } from './global-code/global-code.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component';
import { AddLenderComponent } from './add-lender/add-lender.component';
import { ListLendersComponent } from './list-lenders/list-lenders.component';

import { TrackingDetailsComponent } from './tracking-details/tracking-details.component';
import { FindcarDetailComponent } from './findcar-detail/findcar-detail.component';
import { BuildCarComponent } from './build-car/build-car.component';
import { AdminSettingComponent } from './admin-setting/admin-setting.component';
import { DashboardComponent } from './dashboard/dashboard.component';

const accountModule = () => import('./account/account.module').then(x => x.AccountModule);
const adminModule = () => import('./admin/admin.module').then(x => x.AdminModule);
const profileModule = () => import('./profile/profile.module').then(x => x.ProfileModule);
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AuthGuard } from '../app/_services/auth.guard';
import { AddLenderRateSheetComponent } from './add-lender-rate-sheet/add-lender-rate-sheet.component';
import { FindcarDetailfinalComponent } from './findcar-detailfinal/findcar-detailfinal.component';
import { AithrhubBuylogsComponent } from './aithrhub-buylogs/aithrhub-buylogs.component';
import { InventoryimportExcelComponent } from './inventoryimport-excel/inventoryimport-excel.component';
import { HubwholesaleDashboradComponent } from './hubwholesale-dashborad/hubwholesale-dashborad.component';
import { FlagreportDashboardComponent } from './flagreport-dashboard/flagreport-dashboard.component';
import { AccountSettingComponent } from './account-setting/account-setting.component';

import { BuildReportComponent } from './build-report/build-report.component';
import { WelcomeDashboardComponent } from './welcome-dashboard/welcome-dashboard.component';
import { CommunicationLogsComponent } from './communication-logs/communication-logs.component';
import { LenderRateSheetPdfComponent } from './lender-rate-sheet-pdf/lender-rate-sheet-pdf.component';
import { DetailListComponent } from './detail-list/detail-list.component';
import { AnalyticDashboardComponent } from './analytic-dashboard/analytic-dashboard.component';
import { FreshBuildComponent } from './fresh-build/fresh-build.component';
import { CcbvalueListComponent } from './ccbvalue-list/ccbvalue-list.component';
import { LenderPpsaComponent } from './lender-ppsa/lender-ppsa.component';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { ManageHubinventoryComponent } from './manage-hubinventory/manage-hubinventory.component';
import { CustomerWarrantykeyComponent } from './customer-warrantykey/customer-warrantykey.component';
import { CustomerOrderComponent } from './customer-order/customer-order.component';
import { AithrxClientDashboardComponent } from './aithrx-client-dashboard/aithrx-client-dashboard.component';
import { LoanCalculatorComponent } from './loan-calculator/loan-calculator.component';
import { OfferDashboardComponent } from './offer-dashboard/offer-dashboard.component';
import { NotificationDashboardComponent } from './notification-dashboard/notification-dashboard.component';
import { UnitClearanceComponent } from './unit-clearance/unit-clearance.component';
import { InquiryLogoComponent } from './inquiry-logo/inquiry-logo.component';
import { CarfaxapiDashabordComponent } from './carfaxapi-dashabord/carfaxapi-dashabord.component';
import { TrainingVideoComponent } from './training-video/training-video.component';
import { CreditapiLogComponent } from './creditapi-log/creditapi-log.component';
import { CreditApplicationComponent } from './credit-application/credit-application.component';
import { CreditorLogComponent } from './creditor-log/creditor-log.component';
import { CreditorsTiresComponent } from './creditors-tires/creditors-tires.component';
import { VehiclearchivedListComponent } from './vehiclearchived-list/vehiclearchived-list.component';
import { WarrantylogsComponent } from './warranty-logs/warranty-logs.component';
import { CreditappCustomerComponent } from './creditapp-customer/creditapp-customer.component';
import { CreditFindcarDetailfinalComponent } from './creditapp-findcar/creditapp-findcar.component';
import { AithrVerificationComponent } from './aithr-verification/aithr-verification.component';
import { AuctionsListComponent } from './auctions-list/auctions-list.component';
import { AuctionsVehiclesListComponent } from './auctions-vehicles-list/auctions-vehicles-list.component';
import { AuctionsTimeslotsListComponent } from './auctions-timeslots-list/auctions-timeslots-list.component';
import { ListViewVehiclesComponent } from './list-view-vehicles/list-view-vehicles.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { ReportmatrixComponent } from './reportmatrix/reportmatrix.component';

import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component'
const routes: Routes = [
    { path: 'ResetpasswordMail/:token/:email', component: ResetpasswordaftermailLinkComponent},
    { path: '', component: DashboardComponent},
    { path: 'login', component: LoginComponent , data: { title: 'login' } },
    { path: 'about-us', component: AboutUsComponent , data: { title: 'About-us' } },
    { path: 'contact-us', component: ContactUsComponent , data: { title: 'Contact-us' } },
    { path: 'AithrVerification', component: AithrVerificationComponent,canActivate: [AuthGuard] },
    { path: 'Home', component: HomeComponent,canActivate: [AuthGuard] },
    { path: 'account', loadChildren: accountModule },
    { path: 'profile', loadChildren: profileModule },
    { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard], data: { roles: [Role.Admin] } },
    { path: 'admin', loadChildren: adminModule, canActivate: [AuthGuard] },
    { path: 'Carfex-portal', component: CarfexDashboardComponent, data: { title: 'Carfex - Portal' },canActivate: [AuthGuard] },
    { path: 'main', component: AppComponent, data: { title: 'Home' } },
    { path: 'Vehicle-list', component: VehicleListComponent,data: { title: 'Vehicle-list' },canActivate: [AuthGuard] },
    { path: 'user-profile/:userName', component: UserProfileComponent, data: { title: 'Aithr - Edit-profile' },canActivate: [AuthGuard] },
    { path: 'List', component: UserListComponent,canActivate: [AuthGuard],data: { title: 'user-list' }, },
    { path: 'Carfax-API', component: CarfaxapiListComponent, data: { title: 'Carfax - API' },canActivate: [AuthGuard] },
    { path: 'Vhr-Report', component: VhrReportComponent, data: { title: 'VHR - Report' },canActivate: [AuthGuard] },
    { path: 'pdf-Report', component: VhrpdfreportViewComponent, data: { title: 'VHR - Report' },canActivate: [AuthGuard] },
    { path: 'register', component: SignUpComponent, data: { title: 'register' } },
    { path: 'Forgot-Password', component: ForgotPasswordComponent, data: { title: 'Forgot-Password' } },
    { path: 'icc-batch', component: IicBatchComponent, data: { title: 'icc-batch' } },
    { path: 'DealerLocation', component: DealerLocationComponent, data: { title: 'dealer locations' } },
    { path: 'master-dashboard', component: MasterDashboardComponent, data: { title: 'master dashboard' }, canActivate: [AuthGuard] },
    { path: 'api-history', component: ApicallHistoryComponent, data: { title: 'api-history' },canActivate: [AuthGuard] },
    { path: 'vehicle-details/:vehicleId', component: VehicleDetailsComponent, data: { title: 'api-history' },canActivate: [AuthGuard] },
    { path: 'add-groups', component: AddGroupComponent, data: { title: 'add -groups' } },
    { path: 'add-roles', component: AddUserrolesComponent, data: { title: 'add -roles' } },
    { path: 'add-postions', component: ListPostionsComponent, data: { title: 'add-postions' } },
    { path: 'add-Wholesalers', component: ListWholesalersComponent, data: { title: 'add-Wholesalers' } },
    { path: 'add-Reps', component: AddRepsComponent, data: { title: 'add-Reps' } },
    { path: 'list-Reps', component: ListRepoComponent, data: { title: 'add-Reps' } },
    { path: 'list-dealers', component: ListDealersComponent, data: { title: 'add-Reps' }, pathMatch: 'full',canActivate: [AuthGuard],},
    { path: 'Aithrhub-dashboard', component: AithrhubDashboardComponent, data: { title: 'add-Reps' },canActivate: [AuthGuard] },
    { path: 'activate-Dealers', component: ActivateDealersListComponent, data: { title: 'add-Reps' },canActivate: [AuthGuard] },
    { path: 'globalcode/:catid/:name', component: GlobalCodeComponent, data: { title: 'global code' },canActivate: [AuthGuard] },

    // { path: 'globalcode', component: GlobalCodeComponent, data :{ catid:'1', name:"Angular"}},
    { path: 'add-GlobalCodeCategory', component: AddGlobalCodeCategoryComponent, data: { title: 'add-Reps' },canActivate: [AuthGuard] },
    { path: 'add-inventory', component: AddInventoryComponent, data: { title: 'add-inventory' } },
    { path: 'List-of-Lender-Products', component: AddLenderComponent, data: { title: 'List of Products' },canActivate: [AuthGuard] },
    { path: 'list-lender', component: ListLendersComponent, data: { title: 'list-inventory' } ,canActivate: [AuthGuard]},
    { path: 'tracking-details', component: TrackingDetailsComponent, data: { title: 'list-inventory' } ,canActivate: [AuthGuard]},
    { path: 'find-car', component: FindcarDetailComponent, data: { title: 'find-car' } ,canActivate: [AuthGuard]},
    { path: 'build-car/:vin', component: BuildCarComponent, data: { title: 'build-car' },canActivate: [AuthGuard] },
    { path: 'admin-setting', component: AdminSettingComponent, data: { title: 'admin-setting' } ,canActivate: [AuthGuard]},
    { path: 'Lender-Rate-sheet/:lenderName/:planType', component: AddLenderRateSheetComponent, data: { title: 'AITHR - Lender-Rate-sheet' },canActivate: [AuthGuard] },
    { path: 'import-excel', component: InventoryimportExcelComponent, data: { title: 'import-excel' },canActivate: [AuthGuard] },
    { path: 'build-report', component: BuildReportComponent, data: { title: 'build-report' },canActivate: [AuthGuard] },


    { path: 'Aithrhub-Buy-logs', component: AithrhubBuylogsComponent, data: { title: 'Aithrhub-Buy-logs' },canActivate: [AuthGuard] },
    { path: 'hubwholeSale-dashboard', component: HubwholesaleDashboradComponent, data: { title: 'hubwholeSale-dashboard' } ,canActivate: [AuthGuard]},
    { path: 'find-car-final/:index', component: FindcarDetailfinalComponent, data: { title: 'find-car' } ,canActivate: [AuthGuard]},

    { path: 'flag-report/:vin', component: FlagreportDashboardComponent, data: { title: 'flag-report' },canActivate: [AuthGuard] },
    { path: 'account-setting', component: AccountSettingComponent, data: { title: 'account-setting' } },
    { path: 'welcome-dasboard', component: WelcomeDashboardComponent, data: { title: 'welcome-dasboard' },canActivate: [AuthGuard] },
    { path: 'communication-Logs', component: CommunicationLogsComponent, data: { title: 'communication-Logs' },canActivate: [AuthGuard] },
    { path: 'lender-ratesheet/:id', component: LenderRateSheetPdfComponent, data: { title: 'communication-Logs' } },
    { path: 'detail-list', component: DetailListComponent, data: { title: 'detail-list' },canActivate: [AuthGuard] },
    { path: 'analytic-Dashboard', component: AnalyticDashboardComponent, data: { title: 'analytic-Dashboard' },canActivate: [AuthGuard] },
    { path: 'fresh-build', component: FreshBuildComponent, data: { title: 'fresh-build' },canActivate: [AuthGuard] },
    { path: 'cbb-values', component: CcbvalueListComponent, data: { title: 'cbb-values' },canActivate: [AuthGuard] },
    { path: 'lender-ppsa', component: LenderPpsaComponent, data: { title: 'lender-ppsa' },canActivate: [AuthGuard] },
    { path: 'add-customer', component: AddCustomerComponent, data: { title: 'add-customer' },canActivate: [AuthGuard] },
    { path: 'managehub-inventory', component: ManageHubinventoryComponent, data: { title: 'managehub-inventory' },canActivate: [AuthGuard] },
    { path: 'customer-keys', component: CustomerWarrantykeyComponent, data: { title: 'customer-keys' },canActivate: [AuthGuard] },
    { path: 'customer-Order', component: CustomerOrderComponent, data: { title: 'customer-Order' },canActivate: [AuthGuard] },
    { path: 'client-dashboard', component: AithrxClientDashboardComponent, data: { title: 'client-dashboard' } },
    { path: 'loan-calculator', component: LoanCalculatorComponent, data: { title: 'loan-calculator' },canActivate: [AuthGuard] },
    { path: 'Offer-Dashboard', component: OfferDashboardComponent, data: { title: 'Offer-Dashboard' },canActivate: [AuthGuard] },
    { path: 'notification-Dashboard', component: NotificationDashboardComponent, data: { title: 'notification-Dashboard' },canActivate: [AuthGuard] },

    { path: 'unit-clearance', component: UnitClearanceComponent, data: { title: 'unit-clearance' },canActivate: [AuthGuard] },

    { path: 'Inquiry-log', component: InquiryLogoComponent, data: { title: 'Inquiry-log' },canActivate: [AuthGuard] },
    { path: 'carfax-dashboard', component: CarfaxapiDashabordComponent, data: { title: 'carfax-dashboard' },canActivate: [AuthGuard] },
    { path: 'training-video', component: TrainingVideoComponent, data: { title: 'training-video' },canActivate: [AuthGuard] },
    { path: 'creditapi-Log', component: CreditapiLogComponent, data: { title: 'training-video' },canActivate: [AuthGuard] },

    { path: 'application', component: CreditApplicationComponent, data: { title: 'application' },canActivate: [AuthGuard] },
    { path: 'creditor', component: CreditorLogComponent, data: { title: 'creditor' } ,canActivate: [AuthGuard]},
    { path: 'creditor-tier', component: CreditorsTiresComponent, data: { title: 'creditor-tier' },canActivate: [AuthGuard] },
    { path: 'archived-vehicles', component: VehiclearchivedListComponent, data: { title: 'archived-vehicles' },canActivate: [AuthGuard] },
    { path: 'warranty-logs', component: WarrantylogsComponent, data: { title: 'warranty-logs' },canActivate: [AuthGuard] },
    { path: 'creditapp-customer', component: CreditappCustomerComponent, data: { title: 'creditapp-customer' },canActivate: [AuthGuard] },

    { path: 'creditapp-findcar/:i', component: CreditFindcarDetailfinalComponent, data: { title: 'creditapp-findcar' } ,canActivate: [AuthGuard]},

    { path: 'auctions-List', component: AuctionsListComponent, data: { title: 'auctions-List' },canActivate: [AuthGuard] },
    { path: 'auctionsvehicles-List/:auctionid', component: AuctionsVehiclesListComponent, data: { title: 'auctionsvehicles-List' },canActivate: [AuthGuard] },
    { path: 'auctionstimeslots-List/:auctionid', component: AuctionsTimeslotsListComponent, data: { title: 'auctions-List' },canActivate: [AuthGuard] },
    { path: 'list-view', component: ListViewVehiclesComponent, data: { title: 'list-view' },canActivate: [AuthGuard] },
    { path: 'report-view', component: ReportDetailsComponent, data: { title: 'report-view' },canActivate: [AuthGuard] },
    { path: 'matrix-report', component: ReportmatrixComponent, data: { title: 'matrix-report' },canActivate: [AuthGuard] },
    
    // otherwise redirect to home
    { path: '**', redirectTo: '' }
];

@NgModule({
    imports: [
         RouterModule.forRoot(routes,{ useHash: true })
        //RouterModule.forRoot(routes, {useHash: true})
    ],
    bootstrap: [AppComponent],
    exports: [RouterModule],
    // providers: [
    //     // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
    //     { provide: LocationStrategy, useClass: HashLocationStrategy },
    //     // provider used to create fake backend

    // ],
    providers: [AuthGuard]

})
export class AppRoutingModule { }
