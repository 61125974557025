<section id="admindashboard">
    <form class="col-12 px-0" [formGroup]="adminSettingForm">
        <div class="container-fluid">
            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="card card-raised h-100">
                        <div class="card-header card-raised bg-info text-white">
                            <div class="row justify-content-between align-items-center">
                                <div class="col">
                                    <h5 class="card-title text-white text-uppercase mb-0">Settings</h5>
                                </div>
                                <div class="col text-right">

                                </div>
                            </div>
                        </div>
                        <div id="adminsetting" class="card-body p-3">
                            <div class="row">
                                <div class="col-md-12">
                                    <ul class="nav nav-tabs" id="myTab" role="tablist">

                                        <li class="nav-item">
                                            <a class="nav-link active" id="Group-tab" data-toggle="tab" href="#Group"
                                                role="tab" aria-controls="Group" aria-selected="true">Group</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" id="Dealer-tab" data-toggle="tab" href="#Dealer"
                                                role="tab" aria-controls="Dealer" aria-selected="false">Dealer</a>
                                        </li>

                                        <li class="nav-item">
                                            <a class="nav-link" id="User-tab" data-toggle="tab" href="#User" role="tab"
                                                aria-controls="User" aria-selected="false">User</a>
                                        </li>

                                    </ul>
                                    <div class="tab-content" id="myTabContent">

                                        <div class="tab-pane fade show active" id="Group" role="tabpanel"
                                        aria-labelledby="Group-tab">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <div class="col-12 mt-3 groupTab">
                                                    <app-global-code [Globalcodereceive]="11"></app-global-code>
                                                    </div>
                                                    </div>
                                        </div>
                                        </div>

                                        <!--Panel for dealer start-->

                                        <div class="tab-pane fade" id="Dealer" role="tabpanel"
                                            aria-labelledby="Dealer-tab">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12 mt-3">

                                                        <app-list-dealers></app-list-dealers>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>
       <!--Panel for dealer End-->

          <!--Panel for User start-->
                                        <div class="tab-pane fade" id="User" role="tabpanel" aria-labelledby="User-tab">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12 mt-3">

                                                      <app-user-list></app-user-list>


                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                          <!--Panel for User end-->

                                        <div class="tab-pane fade" id="Lender" role="tabpanel"
                                            aria-labelledby="Lender-tab">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <div class="col-12 mt-3">


                                                        <div
                                                            class="row justify-content-between align-items-center mb-2 mx-0">
                                                            <div class="col-auto documentbtn px-0">
                                                                <ul class="list-unstyled mb-1">
                                                                    <li><button
                                                                            class="btn btn-secondary btn-sm mb-sm-0 rounded-pill">Copy</button>
                                                                    </li>
                                                                    <li><button
                                                                            class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" >Excel</button>
                                                                    </li>
                                                                    <li><button
                                                                            class="btn btn-secondary btn-sm mb-sm-0 rounded-pill">PDF</button>
                                                                    </li>
                                                                    <li><button
                                                                            class="btn btn-secondary btn-sm mb-sm-0 rounded-pill">Print</button>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            <div class="search-heros col-md-auto  mt-2 mt-lg-0">
                                                                <div
                                                                    class="row justify-content-between align-items-center mb-1">
                                                                    <div class="form-group d-flex mb-1">
                                                                        <label class="mr-3 mt-2">Search:</label>
                                                                        <input class="form-control" type="text"
                                                                            name="search" [(ngModel)]="searchTextLender"
                                                                            [ngModelOptions]="{standalone: true}"
                                                                            autocomplete="off">
                                                                    </div>
                                                                    <div class="form-group mb-0 ml-md-3">
                                                                        <label style="padding:2px;"> Items per
                                                                            Page:</label>
                                                                        <select (change)="handlePageSizeChange($event)">
                                                                            <option *ngFor="let size of pageSizesLender"
                                                                                [ngValue]="size">
                                                                                {{ size }}
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div class="row mt-2">
                                                            <div class="col-md-12">
                                                                <div class="card card-raised h-100">
                                                                    <div
                                                                        class="card-header card-raised bg-info text-white">
                                                                        <div
                                                                            class="row justify-content-between align-items-center">
                                                                            <div class="col-sm-6">
                                                                                <h5
                                                                                    class="card-title text-white text-uppercase mb-0">
                                                                                    List of Lenders</h5>
                                                                            </div>
                                                                            <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                                                                <h6 class="mb-0"><b>Total Records :</b>
                                                                                    {{TotalRecordLender}}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="card-body p-4">
                                                                        <div class="table-responsive">
                                                                            <table id="usertable"
                                                                                class="table table-bordered"
                                                                                style="width:100%">
                                                                                <thead>
                                                                                    <tr>

                                                                                        <th
                                                                                            (click)="sortLender('lenderName')">
                                                                                            Name<i
                                                                                                class="fa fa-fw fa-sort"></i>
                                                                                        </th>
                                                                                        <th
                                                                                            (click)="sortLender('address')">
                                                                                            Address<i
                                                                                                class="fa fa-fw fa-sort"></i>
                                                                                        </th>
                                                                                        <th
                                                                                            (click)="sortLender('city')">
                                                                                            City<i
                                                                                                class="fa fa-fw fa-sort"></i>
                                                                                        </th>
                                                                                        <th
                                                                                            (click)="sortLender('region')">
                                                                                            Region</th>
                                                                                        <th
                                                                                            (click)="sortLender('phone')">
                                                                                            Phone</th>
                                                                                        <th
                                                                                            (click)="sortLender('email')">
                                                                                            Email</th>
                                                                                        <th
                                                                                            (click)="sortLender('postalcode')">
                                                                                            Postal code</th>

                                                                                        <th style="text-align:center;"
                                                                                            colspan="3"><button
                                                                                                type="button"
                                                                                                class="btn btn-primary btn-sm text-white rounded-pill">
                                                                                                <i class="fas fa-plus"
                                                                                                    style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                                                                                                Add Ledners
                                                                                            </button></th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr
                                                                                        *ngFor="let lenderlistsdata of lenderlists | filter:searchTextLender | slice: (pageLender-1) * pageSizeLender : (pageLender-1) * pageSizeLender + pageSizeLender; let i = index; let odd = odd">

                                                                                        <td> {{
                                                                                            lenderlistsdata.lenderName
                                                                                            }}</td>
                                                                                        <td> {{ lenderlistsdata.address
                                                                                            }}</td>
                                                                                        <td> {{ lenderlistsdata.city }}
                                                                                        </td>
                                                                                        <td> {{
                                                                                            lenderlistsdata.globalCodeName
                                                                                            }}</td>
                                                                                        <td> {{ lenderlistsdata.phone }}
                                                                                        </td>
                                                                                        <td> {{ lenderlistsdata.email }}
                                                                                        </td>
                                                                                        <td> {{
                                                                                            lenderlistsdata.postalcode
                                                                                            }}</td>
                                                                                        <td style="text-align:center;">
                                                                                            <button
                                                                                                class="btn bg-gradient-info btn-sm"
                                                                                                (click)="EditModalpopup(ModelEditLenderCategory,lenderlistsdata.id)"><i
                                                                                                    class="fa-solid fa-pen-to-square"></i></button>
                                                                                            <button
                                                                                                class="btn bg-gradient-danger btn-sm ml-2"
                                                                                                (click)="openModal(template,lenderlistsdata.id)"><i
                                                                                                    class="fa fa-trash"></i></button>
                                                                                        </td>
                                                                                    </tr>

                                                                                </tbody>
                                                                            </table>
                                                                            <br>

                                                                            <div
                                                                                class="row justify-content-between align-items-center">
                                                                                <div class="col">
                                                                                    <ngb-pagination
                                                                                        [collectionSize]="lenderlists.length"
                                                                                        [(page)]="pageLender"
                                                                                        [maxSize]="5" [rotate]="true"
                                                                                        [ellipses]="false"
                                                                                        [boundaryLinks]="true"></ngb-pagination>
                                                                                </div>
                                                                                <div class="col text-right">
                                                                                    <div class="form-group mb-0 mr-1">
                                                                                        <label style="padding:2px;">
                                                                                            Items per Page:</label>
                                                                                        <select
                                                                                            (change)="handlePageSizeChange($event)">
                                                                                            <option
                                                                                                *ngFor="let size of pageSizesLender"
                                                                                                [ngValue]="size">
                                                                                                {{ size }}
                                                                                            </option>
                                                                                        </select>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <ng-template #ModelAddDealer let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add Dealers</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddDealermodel()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report ">

                    <div class="row">
                        <!-- Picture -->
                        <div class="form-group  col-md-6 col-lg-6">
                            <label for="picture">Picture</label>
                            <div class="custom-file">
                                <input type="file" class="custom-file-input" id="customFile" (change)="onFileChanged($event)"
                                    accept=".jpg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx">
                                <label class="custom-file-label" #labelImport id="choosefile" for="customFile">Choose
                                    file</label>
                            </div>
                            <div class="invalid-feedback" *ngIf="f.picture.touched && f.picture.invalid">
                                <div *ngIf="f.picture.errors.required">Picture is required.</div>
                            </div>
                        </div>

                        <!--  Group -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="exampleFormControlSelect1">Group Name</label>
                            <select class="form-control" name="groupidctrl" [(ngModel)]="groupid" formControlName="groupidctrl"
                                >
                                <option value="0" selected>Select group name</option>
                                <option *ngFor="let grouplistdata of GroupLists" [value]="grouplistdata.globalCodeID">
                                    {{grouplistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.groupidctrl.errors" class="invalid-feedback">
                                <div *ngIf="f.groupidctrl.errors.required">Group Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <!--  Dealers -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Dealers Name</label>
                            <input type="text" formControlName="dealersname" [(ngModel)]="dealersname" class="form-control"
                                placeholder="Dealers Name" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.dealersname.errors }" />
                            <div *ngIf="submitted && f.dealersname.errors" class="invalid-feedback">
                                <div *ngIf="f.dealersname.errors.required">Dealers Name is required</div>
                            </div>
                        </div>

                        <!--  province -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Province / Region</label>
                            <select class="form-control" name="Dealerprovince" [(ngModel)]="Dealerprovince"
                            formControlName="Dealerprovince"
                                >
                                <option value="0" selected>Select Province name</option>
                                <option *ngFor="let Provincelistdata of Provincelist" [value]="Provincelistdata.globalCodeID">
                                    {{Provincelistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.Dealerprovince.errors" class="invalid-feedback">
                                <div *ngIf="f.Dealerprovince.errors.required">Province is required</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <!--  address -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Address</label>
                            <textarea type="text" formControlName="address" rows="3" [(ngModel)]="address" class="form-control"
                                placeholder="address" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }"></textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">address is required</div>
                            </div>
                        </div>
                        <!--  city -->
                        <div class="col-md-6 col-lg-6">
                            <div class="form-group">
                                <label for="">City</label>
                                <input type="text" formControlName="Dealercity" [(ngModel)]="Dealercity" class="form-control"
                                    placeholder="city" (keypress)="ValidTextBox($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.Dealercity.errors }" />
                                <div *ngIf="submitted && f.Dealercity.errors" class="invalid-feedback">
                                    <div *ngIf="f.Dealercity.errors.required">city is required</div>
                                </div>
                            </div>

                            <!--  postalcode -->
                            <div class="form-group">
                                <label for="">Postal Code </label>
                                <input type="text" formControlName="postalcode" [(ngModel)]="postalcode" class="form-control"
                                    placeholder="postalcode" (keypress)="ValidTextBox($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.postalcode.errors }" />
                                <div *ngIf="submitted && f.postalcode.errors" class="invalid-feedback">
                                    <div *ngIf="f.postalcode.errors.required">Postal code is required</div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div class="row">
                        <!--  phonenumber -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Phone Number</label>
                            <input type="text" formControlName="phonenumber"    id="mobileNumber"   
                            type="text"   
                            class="form-control">  
                        <div *ngIf="f.mobileNumber.touched && f.mobileNumber.invalid" class="alert alert-danger">  
                            <div *ngIf="f.mobileNumber.errors.required">Mobile Number is required.</div>  
                            <div *ngIf="f.mobileNumber.errors.pattern">Please, Enter 10 digit Mobile Number.</div>  
                        </div>  
                    </div>  
                        <!--  email -->
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Email</label>
                            <input type="text" formControlName="Dealeremail" [(ngModel)]="Dealeremail" class="form-control"
                                placeholder="email" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.Dealeremail.errors }" />
                            <div *ngIf="submitted && f.Dealeremail.errors" class="invalid-feedback">
                                <div *ngIf="f.Dealeremail.errors.required">email is required</div>
                            </div>

                            <input style="display: none;" type="text" formControlName="FromTab" [(ngModel)]="FromTab" class="form-control"
                               />

                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <!--  active on -->
                        <div class="form-group col-md-6 col-lg-4">
                            <label for="">Active On</label>
                            <input class="form-control" placeholder="yyyy-mm-dd" (dateSelect)="onDateSelectactiveon($event)"
                                formControlName="activeon" type="datetime" name="activeon" [(ngModel)]="activeon" ngbDatepicker
                                #d="ngbDatepicker" (click)="d.toggle()">

                        </div>

                        <!--  activeunity -->
                        <div class="form-group col-md-6 col-lg-4">
                            <label for="">Active Until</label>
                            <input class="form-control" placeholder="yyyy-mm-dd" (dateSelect)="onDateSelectactiveunity($event)"
                                formControlName="activeunity" type="datetime" name="activeunity" [(ngModel)]="activeunity"
                                ngbDatepicker #dt="ngbDatepicker" (click)="dt.toggle()">

                        </div>

                        <!--  subscription -->
                        <div class="form-group col-md-6 col-lg-4">
                            <label for="">Subscription</label>
                            <select class="form-control" name="dealerssubscription" [(ngModel)]="dealerssubscription"
                                formControlName="dealerssubscription" (change)="onChange($event.target.value)">
                                <option value="0" selected>Select Subscription name</option>
                                <option *ngFor="let subscriptionlistdata of subscriptionlist"
                                    [value]="subscriptionlistdata.globalCodeID">{{subscriptionlistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.dealerssubscription.errors" class="invalid-feedback">
                                <div *ngIf="f.dealerssubscription.errors.required">subscription is required</div>
                            </div>

                        </div>
                    </div>
                </section>


            </div>
            <div class="modal-footer">


                <!-- //check box  subscription -->
                <div class="form-check">
                    <input type="checkbox" id="exampleCheck1" formControlName="inventorycheck" [(ngModel)]="inventorycheck"
                        class="form-check-input" (keypress)="ValidTextBox($event)"
                        [ngClass]="{ 'is-invalid': submitted && f.inventorycheck.errors }" />
                    <label class="form-check-label" for="exampleCheck1">AithrHub Inventory?</label>
                </div>


                <div *ngIf="submitted && f.inventorycheck.errors" class="invalid-feedback">
                    <div *ngIf="f.inventorycheck.errors.required">inventorycheck is required</div>
                </div>




                <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                </button>

                <button type="button" class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddDealermodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

        <ng-template #ModelAddUser let-modal>
            <div class="modal-header" style="background-color: black;">
                <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Add Users</h5>
                <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddUsermodel()"
                    aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">

                <section class="generate-report">
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-4">
                            <label for="exampleFormControlSelect1">Group Name</label>
                            <select class="form-control" name="groupName" formControlName="groupName" [(ngModel)]="Gid"
                                (change)="GetDealerNamebyGroupId($event.target.value)">
                                <option value="0" selected>Select group Name</option>
                                <option *ngFor="let grouplistdata of GroupLists" [value]="grouplistdata.globalCodeID">
                                    {{grouplistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.groupName.errors" class="invalid-feedback">
                                <div *ngIf="f.groupName.errors.required">Group Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-md-6 col-lg-4">

                            <label for="exampleFormControlSelect2">Dealer Name</label>
                            <select class="form-control" name="dealerName" formControlName="dealerName"
                                [(ngModel)]="Did">
                                <option value="0" selected>Select dealer Name</option>
                                <option *ngFor="let dealerListdata of dealerlistName" [value]="dealerListdata.id">
                                    {{dealerListdata.dealerName}}</option>
                            </select>
                            <div *ngIf="submitted && f.dealerName.errors" class="invalid-feedback">
                                <div *ngIf="f.dealerName.errors.required">Dealer Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-md-6 col-lg-4">

                            <label for="">User role</label>
                            <select class="form-control" name="userRole" [(ngModel)]="Rid" formControlName="userRole"
                                [ngClass]="{ 'is-invalid': submitted && f.userRole.errors }">
                                <!-- <option value="0" selected>Select User Role </option>  -->
                                <option value="0">Select User Role</option>
                                <option *ngFor="let rolelistdata of rolelist" [value]="rolelistdata.globalCodeID">
                                    {{rolelistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.userRole.errors" class="invalid-feedback">
                                <div *ngIf="f.userRole.errors.required">roles is required</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">First Name</label>
                            <input type="text" formControlName="firstname" [(ngModel)]="firstname" class="form-control"
                                placeholder="first Name" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
                            <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
                                <div *ngIf="f.firstname.errors.required">First Name is required</div>
                            </div>
                        </div>

                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Last Name</label>
                            <input type="text" formControlName="Lastname" [(ngModel)]="Lastname" class="form-control"
                                placeholder="last Name" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.Lastname.errors }" />
                            <div *ngIf="submitted && f.Lastname.errors" class="invalid-feedback">
                                <div *ngIf="f.Lastname.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-md-12 px-0 ">
                            <div class="form-check-inline w-100">
                                <div class="col-md-6 pt-2">
                                    <label for="">Email</label>
                                    <input type="text" formControlName="email" [(ngModel)]="email" class="form-control"
                                        placeholder="email" (keypress)="ValidTextBox($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">email is required</div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <label for="">Phone Number</label>
                                    <input type="text" formControlName="phoneNumber" [(ngModel)]="phoneNumber"
                                        class="form-control" placeholder="phone Number"
                                        (keypress)="ValidTextBox($event)"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.phoneNumber.errors" class="invalid-feedback">
                                        <div *ngIf="f.phoneNumber.errors.required">Phone Number is required</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <hr />
                    <div class="row">
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">City</label>
                            <input type="text" formControlName="city" [(ngModel)]="city" class="form-control"
                                placeholder="city" (keypress)="ValidTextBox($event)"
                                [ngClass]="{ 'is-invalid': submitted && f.city.errors }" />
                            <div *ngIf="submitted && f.city.errors" class="invalid-feedback">
                                <div *ngIf="f.city.errors.required">city is required</div>
                            </div>
                        </div>

                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Region</label>
                            <select class="form-control" name="region" formControlName="region" [(ngModel)]="region"
                                placeholder="region">

                                <option value="0" selected>Select Region Name </option>

                                <option *ngFor="let grouplistdata of Provincelist" [value]="grouplistdata.globalCodeID">
                                    {{grouplistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.region.errors" class="invalid-feedback">
                                <div *ngIf="f.region.errors.required">Region Name is required</div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="form-group col-md-6 col-lg-6">
                            <label for="">Color Theme</label>
                            <select class="form-control" name="colortheme" formControlName="colortheme"
                                [(ngModel)]="colortheme" placeholder="Color Theme">

                                <option value="0" selected>Select Color theme </option>

                                <option *ngFor="let grouplistdata of ColourThemelist"
                                    [value]="grouplistdata.globalCodeID">{{grouplistdata.globalCodeName}}</option>
                            </select>
                            <div *ngIf="submitted && f.colortheme.errors" class="invalid-feedback">
                                <div *ngIf="f.colortheme.errors.required">Color theme Name is required</div>
                            </div>
                        </div>
                        <div class="form-group col-md-6 col-lg-6">
                            <label class="w-100 ">&nbsp;</label>
                            <div class="form-check pt-1 ml-1">
                                <input type="checkbox" id="exampleCheck1" formControlName="checkActivitylog"
                                    [(ngModel)]="checkActivitylog" class="form-check-input" />
                                <label class="form-check-label" for="exampleCheck1">Is Activity log?</label>
                            </div>



                        </div>
                    </div>

                </section>


            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="CreateUser();">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
                </button>

                <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddUsermodel()">
                    <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
                </button>


            </div>
        </ng-template>

        <ng-template #ActivateModelPopUp let-modal>
            <div class="modal-header" style="background-color: black;">
              <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Activate Dealers</h5>
              <button type="button" class="close" style="color: white;" #closebutton (click)="closeEditDealerModel()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">

              <section class="generate-report">


                <div class="form-group">
                    <input type="text"
                    [(ngModel)]="DealerIdValue"   formControlName="DealerId"  class="form-control"
                      placeholder="Dealer Id" (keypress)="ValidTextBox($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.DealerId.errors }" />
                    <div *ngIf="submitted && f.DealerId.errors" class="invalid-feedback">
                      <div *ngIf="f.DealerId.errors.required">Dealer Id is required</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="text" formControlName="DealerName"
                    [(ngModel)]="DealerNameValue" readonly
                     class="form-control"
                      placeholder="Dealer Name" (keypress)="ValidTextBox($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.DealerName.errors }" />
                    <div *ngIf="submitted && f.DealerName.errors" class="invalid-feedback">
                      <div *ngIf="f.DealerName.errors.required">Dealer Name is required</div>
                    </div>
                  </div>

              </section>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="ActivateDealer();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Activate
              </button>

              <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="closeEditDealerModel();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
              </button>


            </div>
          </ng-template>

    </form>
</section>