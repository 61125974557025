import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Account } from '@app/_models';
import { Login } from '../_models/Login';
import { JDPListOfPhotos } from '@app/_models/JDPListOfPhotos';
import { ImportDealerVehcile } from '@app/_models/ImportDealerVehcile';
const baseUrl = `${environment.apiUrl}/Account`;
import { flagreport } from '@app/_models/flagreport';

@Injectable({ providedIn: 'root' })

@Injectable({
  providedIn: 'root'
})
export class ManageInventoryService {

  private accountSubject: BehaviorSubject<Account>;
  private loginSubject: BehaviorSubject<Login>;
  public account: Observable<Account>;
  public logins: Observable<Login>;
  flagreportlist: flagreport[] = [];
  constructor(
    private router: Router,
    private http: HttpClient
  ) {

    this.accountSubject = new BehaviorSubject<Account>(null);
    this.account = this.accountSubject.asObservable();

    this.loginSubject = new BehaviorSubject<Login>(null);
    this.logins = this.loginSubject.asObservable();

  }

  private baseUrl_GetVechileDetailByVIN: string = environment.apiUrl + '/ManageInventory/GetVechileDetailByVIN';
  private baseUrl_GetRegionDetailbyId: string = environment.apiUrl + '/ManageInventory/GetRegionDetailbyId';
  private baseUrl_GetDealerVehiclesByid: string = environment.apiUrl + '/ManageInventory/GetInventoryById';
  private baseUrlGetVehiclePhotosListByVin: string = environment.apiUrl + '/ManageInventory/GetVehiclePhotosListByVIN';
  private baseUrl_Delete: string = environment.apiUrl + '/ManageInventory/DeleteInventorys';

  private baseUrl_UpdatedLendorFromcreditor: string = environment.apiUrl + '/ManageInventory/UpdatedLendorFromcreditor';


  private baseUrl_GetAIXDealerVehiclesLogList: string = environment.apiUrl + '/ManageInventory/GetAIXDealerVehiclesLogList';
  private baseUrl_GetAllInventoryList: string = environment.apiUrl + '/ManageInventory/GetAllInventoryList';
  private baseUrl_addInventory: string = environment.apiUrl + '/ManageInventory/AddInventorys';

  private baseUrl_Postexcelnventory: string = environment.apiUrl + '/ManageInventory/Getexceldata';


  private baseUrl_PostverifyVehicle: string = environment.apiUrl + '/ManageInventory/PostverifyVehicle';

  private baseUrl_MarkOfferFromdollarClickVehicle: string = environment.apiUrl + '/ManageInventory/MarkOfferFromdollarClick';


  private baseUrl_MarkOfferFromCleintDashborad: string = environment.apiUrl + '/ManageInventory/MarkOfferFromOfferDashBoard';

  private baseUrl_MarkUnits: string = environment.apiUrl + '/ManageInventory/MarkUnits';

  private baseUrl_UpdateInventory: string = environment.apiUrl + '/ManageInventory/UpdateInventorys';
  private baseUrl_GetDealerVehiclesByVin: string = environment.apiUrl + '/ManageInventory/GetDealerVehiclesByVin';
  private baseUrl_GetDealerVehiclesCBBValuesByVin: string = environment.apiUrl + '/CBBDumpOptions/GetDealerVehiclesCBBValuesByVin';


  private baseUrl_GetAllInventoryListDealerName: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListDealerName';

  private baseUrl_GetLenderKeysbyId:string = environment.apiUrl + '/ManageInventory/GetLenderKeysbyId';
  private baseUrl_GetLenderFreshBuildKeys:string = environment.apiUrl + '/ManageInventory/GetLenderFreshBuildKeys';


  private baseUrl_GetLenderKeysFLTVValue:string = environment.apiUrl + '/ManageInventory/GetLenderKeysFLTVValue';

  private baseUrl_GetAllInventoryListbyParameters: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyParameters';
  private baseUrl_GetAllInventoryListbyVin: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyVin';

  private baseUrl_GetAllInventoryListbyParametersFinal: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyParametersFinal';
  private baseUrl_GetAllInventoryListbyParametersDetailbyVIN: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyParametersDetailbyVIN';


  private baseUrl_GetAllInventoryListbyParameterForBothFindCars: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyParameterForBothFindCars';


  private baseUrl_GetAllInventoryListbyParameterForFreshBuild: string = environment.apiUrl + '/ManageInventory/GetAllInventoryListbyParameterForFreshBuild';

  private baseUrl_GetInventoryHubwholesaleDashborads: string = environment.apiUrl + '/ManageInventory/GetInventoryHubwholesaleDashborads';

  private baseUrl_GetAIXZOfferLogByVINs: string = environment.apiUrl + '/ManageInventory/GetAIXZOfferLogByVINs';

  private baseUrl_UpdateAIXZOfferLogDetail: string = environment.apiUrl + '/ManageInventory/UpdateAIXZOfferLogDetail';

  private baseUrl_GenerateFlagReport: string = environment.apiUrl + '/CarfexAPIValue/GetFlaggingRequestId';
  private baseUrlsetAsSold: string = environment.apiUrl + '/ManageInventory/MarkAsSold/';

  private baseUrl_GetInquiredDetail: string = environment.apiUrl + '/ManageInventory/GetInquiredDetail';

  private baseUrl_Getadvancefiltermake: string = environment.apiUrl + '/ManageInventory/Bindadvancefiltermakebind';
  private baseUrl_Getadvancefilterbody: string = environment.apiUrl + '/ManageInventory/BindadvancefilterBody';

  private baseUrl_GetEmailLogDetails: string = environment.apiUrl + '/ManageInventory/GetEmailLogDetails';

  private baseUrl_SendReponseDetail: string = environment.apiUrl + '/ManageInventory/SendReponseDetail';
  private baseUrl_SendDetailClientDashbaord: string = environment.apiUrl + '/ManageInventory/SendDetailClientDashbaord';
  private baseUrl_GetEmailLogDetailsbyID: string = environment.apiUrl + '/ManageInventory/GetEmailLogDetailsbyID';
  private baseUrl_GetflagreportDetailsList: string = environment.apiUrl + '/ManageInventory/GetflagreportDetailsList';

  private baseUrl_GetAdjustedValues: string = environment.apiUrl + '/ManageInventory/GetBuildAdjustedValues';

  private baseUrl_GetFindcarlistColumnSettings: string = environment.apiUrl + '/ManageInventory/GetFindcarlistColumnSettings';

  private baseUrl_SendAithrbuyAndVerifyEmail: string = environment.apiUrl + '/ManageInventory/SendAithrbuyAndVerifyEmail';

  private baseUrl_GetcustomerBydealerid: string = environment.apiUrl + '/ManageInventory/GetCustomersDetailsBydealerid';

  private baseUrl_savcustomer: string = environment.apiUrl + '/ManageInventory/savecustomerfromfindcar';

  private baseUrl_GetDetailofUnitByVin: string = environment.apiUrl + '/Dashboard/GetDetailofUnitByVin';

  private baseUrl_GetCalculatedLoan: string = environment.apiUrl + '/ManageInventory/GetCalculatedLoan';

  private baseUrl_GetCbbValueByVINList: string = environment.apiUrl + '/ICCBatchApi/GetCbbValueByVINList';

  private baseUrl_GetPppsaListValues: string = environment.apiUrl + '/ManageInventory/GetPppsaListValues';

  private baseUrl_SendLenderppsavalues: string = environment.apiUrl + '/ManageInventory/SendLenderppsavalues';


  private baseUrl_GetInventoryDashBoardDetails: string = environment.apiUrl + '/Dashboard/GetInventoryDashBoardDetails';


  private baseUrl_GetAccountManagerDashBoardDetails: string = environment.apiUrl + '/Dashboard/GetAccountManagerDashBoardDetails';

  private baseUrl_GetDealerNameFilterlist: string = environment.apiUrl + '/ManageInventory/GetDealerNameFilterlist';

  private baseUrl_GetManageHubUnitsList: string = environment.apiUrl + '/ManageInventory/GetManageHubUnitsList';

  private baseUrl_GetLoanCalculatorDetail: string = environment.apiUrl + '/ManageInventory/GetLoanCalculatorDetail';

  private baseUrl_GetManageRemoveUnitsList: string = environment.apiUrl + '/ManageInventory/GetManageRemoveUnitsList';

//for on clearance
  private baseUrl_GetNotclearanceList: string = environment.apiUrl + '/ManageInventory/GetNotclearanceList';

  //for on clearance
  private baseUrl_GetclearanceList: string = environment.apiUrl + '/ManageInventory/GetclearanceList';

  private baseUrl_Saveclearnacedata: string = environment.apiUrl + '/ManageInventory/Saveclearnacedata';

  private baseUrl_AddRemovedUnitTohub: string = environment.apiUrl + '/ManageInventory/AddRemovedUnitTohub';

  private baseUrl_GetCustomersDetails: string = environment.apiUrl + '/ManageInventory/GetCustomersDetails';

  private baseUrl_GetCustomerOrderDetail: string = environment.apiUrl + '/ICCBatchApi/GetCustomerOrderDetail';


  private baseUrl_GetKeyDetails: string = environment.apiUrl + '/ICCBatchApi/GetVendorKeyListDetail';

  private baseUrl_GetWarrantyKeyListDetail: string = environment.apiUrl + '/ICCBatchApi/GetWarrantyKeyListDetail';

  private baseUrl_GetVednorKeyListById: string = environment.apiUrl + '/ICCBatchApi/GetVendorKeyDetailbyIds';

  private baseUrl_GetWarrantyKeyDetailbyIds: string = environment.apiUrl + '/ICCBatchApi/GetWarrantyKeyDetailbyIds';

  private baseUrl_GetDealerNameClientDB: string = environment.apiUrl + '/ManageInventory/GetDealerNameClientDB';

  private baseUrl_GetAIXAithrhubClientDBDetails: string = environment.apiUrl + '/Dashboard/GetAIXAithrhubClientDBDetails';

  private baseUrl_getClientDashboardListDetails: string = environment.apiUrl + '/ManageInventory/GetClientDashboardListDetails';


  private baseUrl_getOfferDashboardListDetails: string = environment.apiUrl + '/ManageInventory/GetOfferDashboardListDetails';

  private baseUrl_GetAIXAithrhubOfferDBDetails: string = environment.apiUrl + '/Dashboard/GetAIXAithrhubOfferDBDetails';

  private baseUrl_GetAIXAithrhubNotificationDBDetails: string = environment.apiUrl + '/Dashboard/GetAIXAithrhubNotificationDBDetails';

  //private baseUrl_GetNotificationsDashboardListDetails: string = environment.apiUrl + '/Dashboard/GetNotificationsDashboardListDetails';

  private baseUrl_GetNotificationsDashboardListDetails: string = '/Dashboard/GetNotificationsDashboardListDetails';
  private baseUrl_UpdateNotificationStatus: string = environment.apiUrl + '/Dashboard/UpdateNotificationStatus';


  private baseUrl_MarkUnFavoriteUnits: string = environment.apiUrl + '/ManageInventory/MarkUnFavoriteUnits';

  private baseUrl_generatecarfaxFlagReport: string = environment.apiUrl + '/CarfexAPIValue/GenerateFlagReportV1';


  private baseUrl_GetAddDealerNamelist: string = environment.apiUrl + '/ManageInventory/GetAddDealerNamelist';

  private baseUrl_GetSupportTickets: string = environment.apiUrl + '/Dashboard/GetSupportTickets';

  private baseUrl_GetMenuListUserRoleWise: string = environment.apiUrl + '/ManageInventory/GetMenuListUserRoleWise';


  private baseUrl_GetinquiryLogDetails: string = environment.apiUrl + '/ManageInventory/GetinquiryLogDetails';

  private baseUrl_GetAllCreditorApplicationList: string = environment.apiUrl + '/ManageInventory/GetAllCreditorApplicationList';

  private baseUrl_GetAllCreditorList: string = environment.apiUrl + '/ManageInventory/GetAllCreditorList';
  private baseUrl_GetAllCreditorTierslist: string = environment.apiUrl + '/ManageInventory/GetAllCreditorTierslist';

  private baseUrl_GetTierNameListbyid: string = environment.apiUrl + '/ManageInventory/GetTierNameListbyid';

  private baseUrl_UpdateTierStatusDetails: string = environment.apiUrl + '/ManageInventory/UpdateTierStatusDetails';

  private baseUrl_GetarchivedVehiclesList: string = environment.apiUrl + '/ManageInventory/GetArchivedVehiclesList';

  private baseUrl_getwarrantyLogsList: string = environment.apiUrl + '/ManageInventory/GetwarrantyLogsList'; 

  private baseUrl_GetarchivedVehiclesListDealerName: string = environment.apiUrl + '/ManageInventory/GetarchivedVehiclesListDealerName';

  private baseUrl_ActivateUnit: string = environment.apiUrl + '/ManageInventory/ActivateUnit';

  private baseUrl_GetActiveVehiclesList: string = environment.apiUrl + '/ManageInventory/GetActiveVehiclesList';

  private baseUrl_GetActiveVehiclesListDealerName: string = environment.apiUrl + '/ManageInventory/GetActiveVehiclesListDealerName';

  private baseUrl_archiveUnit: string = environment.apiUrl + '/ManageInventory/ArchiveUnit';

  private baseUrl_getAithrVerifySendListDetails: string = environment.apiUrl + '/ManageInventory/GetAithrVerifySendListDetails';

  // functions for getting AithrVerify Send
  private baseUrl_getAithrVerifyRecivedListDetails: string = environment.apiUrl + '/ManageInventory/GetAithrVerifyRecivedListDetails';

  private baseUrl_SendReponseDetailList: string = environment.apiUrl + '/ManageInventory/SendAithrVerifyReponseDetail';

  private baseUrl_SendPostiveDetails: string = environment.apiUrl + '/ManageInventory/SendPostiveResponseDetails';

  private baseUrl_GetAuctionsList: string = environment.apiUrl + '/ManageInventory/GetAuctionsList';

  private baseUrl_GetAuctionsvehiclesList: string = environment.apiUrl + '/ManageInventory/GetAuctionsVehiclesList';

  private baseUrl_GetAuctionsvehiclesList_eblock: string = environment.apiUrl + '/EBlock/EBGetAuctionVehicles';

  private baseUrl_GetAuctionsTimeSlotList: string = environment.apiUrl + '/ManageInventory/GetAuctionsTimeSlotList';

  private baseUrl_GetAuctionsListfromAPI: string = environment.apiUrl + '/EBlock/EBGetAuctions';

  private baseUrl_GetAuctionsTimeSlotListFromAPI: string = environment.apiUrl + '/EBlock/EBGetAuctionTimeSlots';

  private baseUrlGetDealerInformationbyVinList: string = environment.apiUrl + '/ManageInventory/GetDealerInformationbyVinList';

  private baseUrl_addthinkKeyDetail: string = environment.apiUrl + '/ManageInventory/AddthinkKeyDetail';

  private baseUrl_InsertGWLogsDetails: string = environment.apiUrl + '/ManageInventory/InsertGWLogsDetails';

  insertGWlogs(FormData) {
    return this.http.post<any>(this.baseUrl_InsertGWLogsDetails, FormData);

  }

  addthinkKeyDetail(FormData) {
    return this.http.post<any>(this.baseUrl_addthinkKeyDetail, FormData);

  }

  GetDealerInformationbyVinList(formdata) {
    return this.http.post<JDPListOfPhotos[]>(this.baseUrlGetDealerInformationbyVinList, formdata);
  }


  getAuctionsTimeSlotListFromAPI(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAuctionsTimeSlotListFromAPI,formdata);
  }

  getAuctionsListfromAPI() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAuctionsListfromAPI);
  }

  getAuctionsTimeSlotList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAuctionsTimeSlotList,formdata);
  }

  // functions for getting List of Auctions
  getAuctionsList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAuctionsList,formdata);
  }

  getAuctionsvehiclesList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAuctionsvehiclesList,formdata);
  }


  getAuctionsvehiclesList_EblcokAPI(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAuctionsvehiclesList_eblock,formdata);
  }

  SendPostiveDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendPostiveDetails, formdata);
  }

  sendReponseDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendReponseDetailList, formdata);
  }

  getAithrVerifyRecivedListDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_getAithrVerifyRecivedListDetails, formdata);
  }


  getAithrVerifySendListDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_getAithrVerifySendListDetails, formdata);
  }

  archiveUnit(formdata) {
    return this.http.post<any[]>(this.baseUrl_archiveUnit, formdata);
  }

  activateUnit(formdata) {
    return this.http.post<any[]>(this.baseUrl_ActivateUnit, formdata);
  }

// functions for Vehicles list


getwarrantyLogsList() {
  return this.http.get<CDATASection[]>(this.baseUrl_getwarrantyLogsList);
}

getarchivedVehiclesList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetarchivedVehiclesList);
}

getactiveVehiclesList() {
  return this.http.get<CDATASection[]>(this.baseUrl_GetActiveVehiclesList);
}

getActiveVehiclesListDealerName(formdata) {
  return this.http.post<any[]>(this.baseUrl_GetActiveVehiclesListDealerName, formdata);
}

// getarchivedVehiclesListDealerNames(formdata) {
//   debugger;
//   return this.http.post<any[]>(this.baseUrl_GetarchivedVehiclesListDealerName, formdata);
// }

getarchivedVehiclesListDealerNames(formdata) {
  return this.http.post<any[]>(this.baseUrl_GetarchivedVehiclesListDealerName, formdata);
}

  UpdateTierStatusDetails(FormData) {
    return this.http.post<any>(this.baseUrl_UpdateTierStatusDetails, FormData);

  }

  getAllCreditorTierslist() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllCreditorTierslist);
  }

  GetTierNameListbyid(FormData) {
    return this.http.post<any>(this.baseUrl_GetTierNameListbyid, FormData);

  }

  getAllCreditorApplicationList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAllCreditorApplicationList,formdata);
  }


  getAllCreditorList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAllCreditorList);
  }


  getinquiryLogDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetinquiryLogDetails, formdata);
  }

  getMenuListUserRoleWise(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetMenuListUserRoleWise, formdata);
  }

  getSupportTickets(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetSupportTickets, formdata);
  }

  getAddDealerNamelist(FormData) {
    return this.http.post<any>(this.baseUrl_GetAddDealerNamelist, FormData);

  }

  generatecarfaxFlagReport(formdata) {
    return this.http.post<any[]>(this.baseUrl_generatecarfaxFlagReport, formdata);
  }

  markUnFavoriteUnits(formdata) {
    return this.http.post<any>(this.baseUrl_MarkUnFavoriteUnits, formdata);
  }

  // for Notification Center

  updateNotificationStatus(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateNotificationStatus, formdata);
  }


  getNotificationsDashboardListDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetNotificationsDashboardListDetails, formdata);
  }

  getAIXAithrhubNotificationDBDetails(FormData) {
    return this.http.post<any>(this.baseUrl_GetAIXAithrhubNotificationDBDetails, FormData);

  }

  getAIXAithrhubOfferDBDetails(FormData) {
    return this.http.post<any>(this.baseUrl_GetAIXAithrhubOfferDBDetails, FormData);

  }

  getOfferDashboardListDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_getOfferDashboardListDetails, formdata);
  }

  getClientDashboardListDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_getClientDashboardListDetails, formdata);
  }

  getAIXAithrhubClientDBDetails(FormData) {
    return this.http.post<any>(this.baseUrl_GetAIXAithrhubClientDBDetails, FormData);

  }

  getDealerNameClientDB(FormData) {
    return this.http.post<any>(this.baseUrl_GetDealerNameClientDB, FormData);

  }


  getWarrantyKeyDetailbyIds(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetWarrantyKeyDetailbyIds, formdata);
  }


  getVednorKeyListById(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetVednorKeyListById, formdata);
  }

  getWarrantyKeyListDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetWarrantyKeyListDetail, formdata);
  }

  getKeyDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetKeyDetails, formdata);
  }

  getCustomerOrderDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetCustomerOrderDetail, formdata);
  }

  getCustomersDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetCustomersDetails, formdata);
  }

  addRemovedUnitTohub(formdata) {
    return this.http.post<any[]>(this.baseUrl_AddRemovedUnitTohub, formdata);
  }

  getManageRemoveUnitsList(FormData) {
    return this.http.post<any>(this.baseUrl_GetManageRemoveUnitsList, FormData);

  }

  getManageNotClearnceList(FormData) {
    return this.http.post<any>(this.baseUrl_GetNotclearanceList, FormData);

  }



  getManageClearnceList(FormData) {
    return this.http.post<any>(this.baseUrl_GetclearanceList, FormData);

  }

  getManageHubUnitsList(FormData) {
    return this.http.post<any>(this.baseUrl_GetManageHubUnitsList, FormData);

  }

  getLoanCalculatorDetail(FormData) {
    return this.http.post<any>(this.baseUrl_GetLoanCalculatorDetail, FormData);

  }

  getDealerNameFilterlist(FormData) {
    return this.http.post<any>(this.baseUrl_GetDealerNameFilterlist, FormData);

  }


//post onclearnce data
SaveClearancedata(FormData) {
  return this.http.post<any>(this.baseUrl_Saveclearnacedata, FormData);

}


  getInventoryDashBoardDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetInventoryDashBoardDetails, formdata);
  }

  getAccountManagerDashBoardDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAccountManagerDashBoardDetails, formdata);
  }

  getPppsaListValues(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetPppsaListValues, formdata);
  }

  sendLenderppsavalues(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendLenderppsavalues, formdata);
  }

  getCbbValueByVINList(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetCbbValueByVINList, formdata);
  }

  getCalculatedLoan(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetCalculatedLoan, formdata);
  }

  sendAithrbuyAndVerifyEmail(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendAithrbuyAndVerifyEmail, formdata);
  }

  getFindcarlistColumnSettings(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetFindcarlistColumnSettings, formdata);
  }


 savecustomers(formdata) {
    return this.http.post<any[]>(this.baseUrl_savcustomer, formdata);
  }

  getcustomerBydealerid(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetcustomerBydealerid, formdata);
  }

  getflagreportDetailsList(formdata) {
    return this.http.post<flagreport[]>(this.baseUrl_GetflagreportDetailsList, formdata);
  }
  getInquiredDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetInquiredDetail, formdata);
  }


  // for advance filters
  getmakforadvance(formdata) {
    return this.http.post<any[]>(this.baseUrl_Getadvancefiltermake, formdata);
  }

  getbodyforadvance(formdata) {
    return this.http.post<any[]>(this.baseUrl_Getadvancefilterbody, formdata);
  }



  getEmailLogDetailsbyID(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetEmailLogDetailsbyID, formdata);
  }


  sendReponseDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendReponseDetail, formdata);
  }

  sendDetailClientDashbaord(formdata) {
    return this.http.post<any[]>(this.baseUrl_SendDetailClientDashbaord, formdata);
  }

  getEmailLogDetails(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetEmailLogDetails, formdata);
  }


  setAsSold(Vin: any) {
    var URL = this.baseUrlsetAsSold + Vin;
    return this.http.get(URL);
  }
  getInventoryHubwholesaleDashborads(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetInventoryHubwholesaleDashborads, formdata);
  }

  getAIXZOfferLogByVINs(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAIXZOfferLogByVINs, formdata);
  }

  updateAIXZOfferLogDetail(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateAIXZOfferLogDetail, formdata);
  }


  generateFlagReport(formdata) {
    return this.http.post<any[]>(this.baseUrl_GenerateFlagReport, formdata);
  }

//verify vehicle

PostVerifyVehicledata(formdata) {

  return this.http.post<any>(this.baseUrl_PostverifyVehicle, formdata);
}

MarkOfferFromdollarClick(formdata) {

  return this.http.post<any>(this.baseUrl_MarkOfferFromdollarClickVehicle, formdata);
}

MarkOfferFromCleintDashborad(formdata) {

  return this.http.post<any>(this.baseUrl_MarkOfferFromCleintDashborad, formdata);
}



markUnits(formdata) {
  return this.http.post<any>(this.baseUrl_MarkUnits, formdata);
}




  //calling function getting GetAllInventoryList
  getAllInventoryList(formdata) {
    return this.http.post<CDATASection[]>(this.baseUrl_GetAllInventoryList, formdata);
  }

  getAIXDealerVehiclesLogList() {
    return this.http.get<CDATASection[]>(this.baseUrl_GetAIXDealerVehiclesLogList);
  }

  getAllInventoryListDealerName(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListDealerName, formdata);
  }

  addInventory(formdata) {
    return this.http.post<any[]>(this.baseUrl_addInventory, formdata);
  }

  Postexceldata(formdata) {
    debugger;
    return this.http.post<ImportDealerVehcile[]>(this.baseUrl_Postexcelnventory, formdata);
  }


  UpdateInventory(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdateInventory, formdata);
  }

  Delete(formdata) {
    return this.http.post<any[]>(this.baseUrl_Delete, formdata);
  }


  UpdatedLenderFromCreditor(formdata) {
    return this.http.post<any[]>(this.baseUrl_UpdatedLendorFromcreditor, formdata);
  }


  getDealerVehiclesByVin(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDealerVehiclesByVin, formdata);
  }

  getDealerVehiclesCBBValuesByVin(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDealerVehiclesCBBValuesByVin, formdata);
  }

   // for getting List  Of Photos
   getVehiclePhotosListByVIN(formdata) {
    return this.http.post<JDPListOfPhotos[]>(this.baseUrlGetVehiclePhotosListByVin, formdata);
  }

  // for getting cars on Find car page

  getAllInventoryListbyParameters(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyParameters, formdata);
  }

  getAllInventoryListbyParametersFinal(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyParametersFinal, formdata);
  }


  getAllInventoryListbyParametersDetailbyVIN(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyParametersDetailbyVIN, formdata);
  }

  getDetailofUnitByVin(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDetailofUnitByVin, formdata);
  }

  getLenderFreshBuildKeys(FormData) {
    return this.http.post<any>(this.baseUrl_GetLenderFreshBuildKeys, FormData);

  }
  // Get dealer list by group Id
  getLenderKeys(FormData) {
    return this.http.post<any>(this.baseUrl_GetLenderKeysbyId, FormData);

  }

  getLenderKeysFLTVValue(FormData) {
    return this.http.post<any>(this.baseUrl_GetLenderKeysFLTVValue, FormData);
  }

  getAllInventoryListbyVin(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyVin, formdata);
  }

  getDealerVehiclesByid(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetDealerVehiclesByid, formdata);
  }

  // Get region detail by Id
  getRegionDetailbyId(FormData) {
    return this.http.post<any>(this.baseUrl_GetRegionDetailbyId, FormData);

  }

  getVechileDetailByVIN(FormData) {
    return this.http.post<any>(this.baseUrl_GetVechileDetailByVIN, FormData);

  }



  // Final function for getting Result for FIND Car and build Page

  getAllInventoryListbyParameterForBothFindCars(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyParameterForBothFindCars, formdata);
  }

  getAllInventoryListbyParameterForFreshBuild(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAllInventoryListbyParameterForFreshBuild, formdata);
  }
  // function for Adjust Values

  getAdjustedValues(formdata) {
    return this.http.post<any[]>(this.baseUrl_GetAdjustedValues, formdata);
  }
}
