<section id="admindashboard">
  <form class="col-12 px-0" [formGroup]="AddInventoryForm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 mt-3">
          <div class="row justify-content-between align-items-start">
            <div class="col-auto documentbtn">
                <ul class="list-unstyled mb-1">
                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"  (click)="copytable('usertable')">Copy</button>
                  </li>

                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                  </li>

                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                  </li>
                  <li>
                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                  </li>
                </ul>
            </div>

           

            <div class="search-heros col-md-auto mt-2 mt-md-0">
              <div class="row justify-content-between align-items-center mb-1 mx-0">
                <div class="form-group d-flex mb-sm-0 mb-2 col-12 col-sm-auto px-0">
                  <label class="mr-3 mt-2">Search:</label>
                  <input class="form-control" type="text" name="search" [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                    autocomplete="off">
                </div>
                <div class="form-group mb-0 ml-md-3">
                  <label style="padding:2px;"> Items per Page:</label>
                  <select (change)="handlePageSizeChange($event)">
                    <option *ngFor="let size of pageSizes" [ngValue]="size">
                      {{ size }}
                    </option>
                  </select>
                </div>
              </div>

            </div>
          </div>
          <hr class="my-2"/>
          <div class="row mt-2">
            <div class="col-12 col-lg-12 ">
              <div class="row" *ngIf="hideSupControlsButton ==='true'">
                <div class="form-group col-auto mb-1">
                    <div class="row">
                        <label class="col-form-label col-auto"><b>Dealer Group</b></label>
                        <div class="col-auto">
                            <div class="input-group" style="min-width:190px;max-width:190px;">
                                <ng-select style="width:100%;" [items]="GroupLists"
                                bindLabel="globalCodeName" [disabled]="groupDisable"
                                    (change)="GetDealerNamebyGroupId(selectedGroupIds)" [ngModelOptions]="{standalone: true}"
                                    placeholder="Select group" appendTo="body" [multiple]="false" [(ngModel)]="selectedGroupIds">
                                </ng-select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group col mb-1" id="heightAuto">
                    <div class="row">
                  

                        <label class="col-form-label col-auto">Select Dealers</label>
                        <div class="input-group dealername col" style="max-width:max-content;min-width:415px;">
                            <ng-select style="width:100%;" [items]="dealerlistName" id="dealerName" [dropdownPosition]="true"
                                bindLabel="dealerName" [ngModelOptions]="{standalone: true}" placeholder="Select dealer Name"
                                appendTo="body" (change)="ClearDealer($event)" (click)="CheckClick()" [multiple]="true"
                                [(ngModel)]="dealersmultiselectarray">
                            </ng-select>
                        </div>

                    </div>
                </div>
                <div class="col-auto px-md-0 ml-2">
                    
                </div>
              </div>

              <div class="row mt-1" *ngIf="hideSupControlsButton ==='false'">
                <div class="form-group mb-1 col-auto">
                    <div class="row">
                        <label class="col-form-label col-auto"><b>Search by Dealers</b></label>
                        <div class="input-group dealername col" style="max-width:300px; min-width:300px;">
                            <ng-select style="font-size:14px;" [items]="dealerlistForsearch" style="width:100%;"
                                [dropdownPosition]="true" bindLabel="dealerName" placeholder="Select Dealer"
                                [(ngModel)]="SelectSearchDealer" (change)="SerachDealer($event)">
                            </ng-select>
                        </div>

                        <button id="resetbtn"  style="font-size:12px;" (click)="ResetUnits()" class="btn btn-md btn-warning rounded-pill">
                          Reset</button>
                    </div>
                </div>
            </div>

            </div>
          </div>


          <div class="row mt-2">
            <div class="col-md-12">
              <div class="card card-raised h-100">
                <div class="card-header card-raised bg-info text-white">
                  <div class="row justify-content-between align-items-center">
                    <div class="col-sm-6">
                      <h5 class="card-title text-white text-uppercase mb-0">List of Inventory</h5>
                    </div>
                    <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                      <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                    </div>
                    <!-- <div class="card-subtitle"></div> -->
                  </div>
                </div>
                <div id="inventory" class="card-body p-4">
                  <div class="table-responsive">
                    <table id="usertable" class="table table-bordered" style="width:100%">
                      <thead>
                        <tr>
                          <th (click)="sort('vin')"> VIN <i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th (click)="sort('stockNumber')"> Stock No<i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th (click)="sort('carYr')">Year <i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('mileage')">Mileage <i class="fa fa-fw fa-sort"></i> </th>
                          <th (click)="sort('make')">Make <i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th (click)="sort('model')">Model<i class="fa fa-fw fa-sort"></i>
                          </th>
                          <th (click)="sort('trim')" style="min-width:170px;">Trim<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('body')" >Body<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('colour')" >Color<i class="fa fa-fw fa-sort"></i></th>
                          <th (click)="sort('engineFuelType')" >Fuel Type<i class="fa fa-fw fa-sort"></i></th>
                          <th style="text-align: right;" (click)="sort('listedPrice')">Price</th>
                          <th style="text-align: right;" (click)="sort('dealerCost')">Dealer Cost</th>
                          <th style="text-align: right;" (click)="sort('cbbValue')">CBB</th>
                          <th style="text-align: right;" (click)="sort('deltaValue')">Delta Value</th>
                          <th style="text-align:center;">
                            <button type="button" class="btn btn-primary btn-sm text-white rounded-pill"
                              (click)="openAddInventoryModel(ModelAddInventroy)" >
                              <i class="fas fa-plus" style="margin-right:4px;vertical-align:middle;font-size:13px;"></i>
                              Add Inventory
                            </button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          *ngFor="let dealerVehicleslistdata of dealerVehicleslist| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">

                          <td>{{ dealerVehicleslistdata.vin }}</td>
                          <td> {{ dealerVehicleslistdata.stockNumber }}</td>
                          <td> {{ dealerVehicleslistdata.carYr }}</td>
                          <td> {{ dealerVehicleslistdata.mileage }}</td>
                          <td> {{ dealerVehicleslistdata.make }}</td>
                          <td> {{ dealerVehicleslistdata.model }}</td>
                          <td> {{ dealerVehicleslistdata.trim }}</td>
                          <td> {{ dealerVehicleslistdata.body }}</td>
                          <td> {{ dealerVehicleslistdata.colour }}</td>
                          <td> {{ dealerVehicleslistdata.engineFuelType }}</td>
                          <td style="text-align: right;"> {{ dealerVehicleslistdata.listedPrice| currency: 'USD' }}</td>
                          <td style="text-align: right;"> {{ dealerVehicleslistdata.dealerCost | currency: 'USD' }}</td>
                          <td style="text-align: right;"> {{ dealerVehicleslistdata.cbbValue
                            | currency: 'USD' }}</td>
                        <td style="text-align: right;">{{dealerVehicleslistdata.deltaValue |
                            currency: 'USD'}}
                        </td>
                          <td style="text-align:center;">


                            <button class="btn bg-gradient-info btn-sm " title="Edit"
                            (click)="openEditModel(ModelAddInventroy,dealerVehicleslistdata.id)">
                              <i class="fa-solid fa-pen-to-square"></i></button>
                            &nbsp;
                            <button  class="btn btn-sold btn-sm ml-1" title="Mark Sold"
                              (click)="setradioMarkUnits('Mark as Sold',dealerVehicleslistdata)">
                              <span class="icon"><i class="far fa-paper-plane"></i></span>
                            </button>
                            &nbsp;
                            <button class="btn btn-info btn-success" title="View Photos" style="background:green"
                             (click)="openImagesModelPopUp(ModelPopUpImages,dealerVehicleslistdata.vin)"><i
                                class="fa fa-image"></i></button>

                            <button class="btn bg-gradient-danger btn-sm ml-2 rounded-pill" title="Delete"><i (click)="onDelete(dealerVehicleslistdata.vin);"
                                class="fa fa-trash"></i></button>
                          </td>
                        </tr>

                      </tbody>
                    </table>
                    <br>


                    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                      [fullScreen]="false">
                      <p style="color: white"> Loading... </p>
                    </ngx-spinner>

                    <div class="col-12">
                      <div class="row justify-content-between align-items-center mx-0">
                          <div class="col-auto px-0">
                            <ngb-pagination [collectionSize]="dealerVehicleslist.length" [(page)]="page" [maxSize]="5"
                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                          </div>
                          <div class="form-group mb-0">
                            <label style="padding:2px;"> Items per Page:</label>
                            <select (change)="handlePageSizeChange($event)">
                              <option *ngFor="let size of pageSizes" [ngValue]="size">
                                {{ size }}
                              </option>
                            </select>
                          </div>
                      </div>
                    </div>
                    <!-- <div class="row justify-content-between align-items-center">
                      <div class="col">

                        <ngb-pagination [collectionSize]="dealerVehicleslist.length" [(page)]="page" [maxSize]="5"
                          [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                      </div>

                    </div> -->

                    <a #ConfirmBox style="display: none;" [routerLink]="" (click)="openModal(template)"><i
                        class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="Delete Users"
                        placement="top" ngbPopover="Are you sure you want to delete?"></i></a>
                    <ng-template #template>
                      <div class="modal-body text-center">
                        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
                        <p>Are you sure you want to delete?
                        </p>
                        <p></p>
                        <button type="button" class="btn btn-sm btn-success mr-2 rounded-pill" (click)="confirm()">Yes</button>
                        <button type="button" class="btn btn-sm btn-danger rounded-pill" (click)="decline()">No</button>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-footer id="secondfooter"></app-footer> -->
    
    <a #ConfirmMarkSoldBox style="display: none;" [routerLink]="" (click)="openModalSold(templateSold)"><i class="fa fa-upload"
        triggers="mouseenter:mouseleave" popoverTitle="Sold" placement="top"
        ngbPopover="Would you like to Mark Selected Unit as SOLD?"></i></a>
    <ng-template #templateSold>
      <div class="modal-body text-center">
        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
        <p>Would you like to Mark Selected Unit as SOLD?</p>
        <p></p>
        <button type="button" class="btn btn-sm btn-success mr-2" (click)="MarkSold()">Yes</button>
        <button type="button" class="btn btn-sm btn-danger" (click)="decline()">No</button>
      </div>
    </ng-template>
    <a #ConfirmBoxVINCheck  style="display: none;" [routerLink]="" (click)="openModalCheckModel(templateCheckVin)"><i
      class="fa fa-upload" triggers="mouseenter:mouseleave" popoverTitle="VIN"
      placement="top" ngbPopover="VIN already exist."></i>
  </a>
    <ng-template #templateCheckVin>
      <div class="modal-body text-center" >
        <span class="cross-icon"><i class="far fa-times-circle"></i></span>
        <p>VIN already exist
        </p>
        <p></p>
        <button type="button" class="btn btn-sm btn-success mr-2" (click)="declineCheck()">Ok</button>
      </div>
    </ng-template>


    <ng-template #ModelPopUpImages let-modal>
      <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;"> Images</h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="RefreshImageList();CloseAddmodel();"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <section class="generate-report imageSize row">

            <div class="card card-raised mt-2 mx-auto">
              <div class="card-header card-raised bg-info text-white p-2">
                <h6 class="mb-0">Auto Upload Images</h6>
            </div>
            <div class="card-body p-2">


           <div *ngIf="errorshowAuto=='false'" style="width:750px; height:485px; text-align:center;margin:0; padding:0;">
                  <ng-image-slider #nav [images]="vehicleObject" [infinite]="false" [autoSlide]="1"
                      [imageSize]="{width: '100%', height:200}" slideImage="1">
                    </ng-image-slider>

              </div>

              <div *ngIf="errorshowAuto=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
                  {{errorMessageAuto}}
              </div>
            </div>
          </div>

        </section>

        <section class="manual-uploadImage">
          <div class="card card-raised  mt-2 mx-auto">
            <div class="card-header card-raised bg-info text-white p-2">
              <h6 class="mb-0">Manual Upload Images</h6>
          </div>
          <div class="card-body p-2" *ngIf="errorshow=='false'">
            <div class="profile-icon" *ngFor="let element of customimagesrc">
              <img [src]="element.imgsrc"  width="50" height="50" />
            </div>
          </div>

          <div *ngIf="errorshow=='true'" class="bg-danger text-white col-md-4 mx-auto text-center">
            {{errorMessage}}
        </div>

        </div>
        </section>

      </div>
      <div class="modal-footer">


        <button class="btn btn-danger btn-sm text-white" (click)="RefreshImageList();CloseAddmodel();">
          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
        </button>


      </div>
    </ng-template>


    

    <ng-template #ModelAddInventroy let-modal>
      <div class="modal-header" style="background-color: black;">
        <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">{{textLabel}} Inventory</h5>
        <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

      <section class="generate-report ">
        <div class="row">
          <div class="form-group col-sm-6 col-md-3 col-lg-3" *ngIf="showDealerList ===true">
            <div class="row mx-0">
              <label>Dealer Name:</label>
              <div class="w-100">
                <select class="form-control" name="region"  [disabled]="true"
                formControlName="DealerNameFilter"
                 [(ngModel)]="DealerNameFilter">
                  <option value="0" selected>Select Dealer Name </option>
                  <option *ngFor="let DealerListdata of DealerList" [value]="DealerListdata.id">
                    {{DealerListdata.dealerName}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Mileage</label>
            <span style="color: #dc3545;">*</span>
            
            <input type="text" #inputTypemileage
            [(ngModel)]="mileageControl" formControlName="mileageControl" class="form-control" placeholder="mileage"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.mileageControl.errors }" />
              <span *ngIf="milageCheck ==='true'" style="color: #dc3545;">*Enter mileage</span>
            <div *ngIf="submitted && f.mileageControl.errors" class="invalid-feedback">
              <div *ngIf="f.mileageControl.errors.required">mileage is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-2 col-lg-2">
            <label>Stock Number</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" formControlName="stockNumber"
            [(ngModel)]="stockNumber"   class="form-control" placeholder="Stock Number"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.stockNumber.errors }" />
            <div *ngIf="submitted && f.stockNumber.errors" class="invalid-feedback">
              <div *ngIf="f.stockNumber.errors.required">stockNumber is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>VIN Number</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" #inputTypeVin
            [(ngModel)]="vinNumber"  formControlName="vinNumber" class="form-control" placeholder="VIN"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.vinNumber.errors }" />
              <span *ngIf="VinCheck ==='true'" style="color: #dc3545;">*Enter VIN</span>
            <div *ngIf="submitted && f.vinNumber.errors" class="invalid-feedback">
              <div *ngIf="f.vinNumber.errors.required">VIN Number is required</div>
            </div>
          </div>
          <div class="form-group col-sm-auto col-md-auto col-lg-auto">
            <label class="w-100">&nbsp;</label>
            <button type="button" (click)="checkVinExist(inputTypeVin.value,inputTypemileage.value)" class="btn btn-primary btn-sm text-white rounded-pill">
              Check
            </button>

          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Year</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" [(ngModel)]="carYr"
            [(ngModel)]="yearvalue" formControlName="carYr" class="form-control" placeholder="Year"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.carYr.errors }" />
            <div *ngIf="submitted && f.carYr.errors" class="invalid-feedback">
              <div *ngIf="f.carYr.errors.required">Year is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Make</label>
            <span style="color: #dc3545;">*</span>
            <input type="text"
             [(ngModel)]="makevalue" formControlName="make" class="form-control" placeholder="make"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.make.errors }" />
            <div *ngIf="submitted && f.make.errors" class="invalid-feedback">
              <div *ngIf="f.make.errors.required">make is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Model</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" [(ngModel)]="modelvalue" formControlName="model" class="form-control" placeholder="model"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.model.errors }" />
            <div *ngIf="submitted && f.model.errors" class="invalid-feedback">
              <div *ngIf="f.model.errors.required">model is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Trim</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" [(ngModel)]="trimvalue" formControlName="trim" class="form-control" placeholder="trim"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.trim.errors }" />
            <div *ngIf="submitted && f.trim.errors" class="invalid-feedback">
              <div *ngIf="f.trim.errors.required">trim is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Style</label>
            <span style="color: #dc3545;">*</span>
            <input type="text"  [(ngModel)]="style" formControlName="style" class="form-control" placeholder="style"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.style.errors }" />
            <div *ngIf="submitted && f.style.errors" class="invalid-feedback">
              <div *ngIf="f.style.errors.required">style is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Body</label>
            <span style="color: #dc3545;">*</span>
            <input type="text"  [(ngModel)]="bodyvalue" formControlName="body" class="form-control" placeholder="body"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.body.errors }" />
            <div *ngIf="submitted && f.body.errors" class="invalid-feedback">
              <div *ngIf="f.body.errors.required">body is required</div>
            </div>
          </div>

          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Conditon</label>
            <select class="form-control" name="conditon"  [(ngModel)]="conditon"  formControlName="conditon">
              <option value="0" selected>Select conditon name</option>
              <option *ngFor="let conditionslistdata of conditionslist" [value]="conditionslistdata.globalCodeName">
                {{conditionslistdata.globalCodeName}}</option>
            </select>
            <div *ngIf="submitted && f.conditon.errors" class="invalid-feedback">
              <div *ngIf="f.conditon.errors.required">conditon is required</div>
            </div>

          </div>



          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Date</label>
            <input class="form-control" [(ngModel)]="datereceived" 
            placeholder="yyyy-mm-dd" (dateSelect)="onDateSelectactiveon($event)"
              formControlName="datereceived" type="datetime" name="activeon" 
              [(ngModel)]="activeon" ngbDatepicker [maxDate]="{year: 2048, month: 12, day: 31}"
              #d="ngbDatepicker" (click)="d.toggle()">

          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>List Price</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" [(ngModel)]="listPrice" formControlName="listPrice" class="form-control"
             placeholder="list Price"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.listPrice.errors }" />
            <div *ngIf="submitted && f.listPrice.errors" class="invalid-feedback">
              <div *ngIf="f.listPrice.errors.required">listPrice is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Cost</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" [(ngModel)]="cost" formControlName="cost" class="form-control" placeholder="cost"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.cost.errors }" />
            <div *ngIf="submitted && f.cost.errors" class="invalid-feedback">
              <div *ngIf="f.cost.errors.required">cost is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Fuel Type</label>
            <select class="form-control"
             name="fuelType" [(ngModel)]="fuelType"
                            formControlName="fuelType"          >
                            <!-- <option value="0" selected>Select User Role </option>  -->
                            <option value="0">Select Fuel Type</option>
                            <option *ngFor="let rolelistdata of fuellist" [value]="rolelistdata.globalCodeName">
                              {{rolelistdata.globalCodeName}}</option>
                          </select>
            <!-- <input type="text" [(ngModel)]="fuelType" formControlName="fuelType"
             class="form-control" placeholder="fuel Type"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.fuelType.errors }" /> -->

            <div *ngIf="submitted && f.fuelType.errors" class="invalid-feedback">
              <div *ngIf="f.fuelType.errors.required">fuel Type is required</div>
            </div>

          </div>

          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Color</label>
            <input type="text" [(ngModel)]="Color" formControlName="Color"
             class="form-control" placeholder="Color"
              (keypress)="ValidTextBox($event)"
               />
            <!-- <select class="form-control" name="Color"  [(ngModel)]="Color"  formControlName="Color">
              <option value="0" selected>Select Color</option>
              <option *ngFor="let conditionslistdata of conditionslist" [value]="conditionslistdata.globalCodeID">
                {{conditionslistdata.globalCodeName}}</option>
            </select> -->

            <!-- <div *ngIf="submitted && f.Color.errors" class="invalid-feedback">
              <div *ngIf="f.Color.errors.required">fuel Type is required</div>
            </div> -->

          </div>
        </div>
        <hr/>
        <div class="row">
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Extra Clean</label>
            <span style="color: #dc3545;">*</span>
            <input type="text"
            [readonly]="isExtraReadOnly"
             [(ngModel)]="extraCleanValue"  formControlName="extraClean" class="form-control" placeholder="extraClean"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.extraClean.errors }" />
            <div *ngIf="submitted && f.extraClean.errors" class="invalid-feedback">
              <div *ngIf="f.extraClean.errors.required">extraClean is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Clean</label>
            <span style="color: #dc3545;">*</span>
            <input type="text"
            [readonly]="isCleanReadOnly"
             [(ngModel)]="cleanValue"  formControlName="clean" class="form-control" placeholder="clean"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.clean.errors }" />
            <div *ngIf="submitted && f.clean.errors" class="invalid-feedback">
              <div *ngIf="f.clean.errors.required">clean is required</div>
            </div>
          </div>
          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Average</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" 
            [readonly]="isAverageReadOnly"
            [(ngModel)]="averageValue"  formControlName="average" class="form-control" placeholder="average"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.average.errors }" />
            <div *ngIf="submitted && f.average.errors" class="invalid-feedback">
              <div *ngIf="f.average.errors.required">average is required</div>
            </div>
          </div>

          <div class="form-group col-sm-6 col-md-4 col-lg-3">
            <label>Rough</label>
            <span style="color: #dc3545;">*</span>
            <input type="text" 
            [readonly]="isRoughReadOnly" [(ngModel)]="roughvalue"  formControlName="rough" class="form-control" placeholder="rough"
              (keypress)="ValidTextBox($event)" [ngClass]="{ 'is-invalid': submitted && f.rough.errors }" />
            <div *ngIf="submitted && f.rough.errors" class="invalid-feedback">
              <div *ngIf="f.rough.errors.required">rough is required</div>
            </div>
          </div>
        </div>
        </section>


        <div class="form-group pl-3 mb-3 mb-lg-0 row">
          <label for="picture" class="col-sm-auto col-form-label">Picture</label>
          <div class="custom-file col-sm-9">
            <input type="file" multiple class="custom-file-input" id="customFile" (change)="onFileChanged($event)"
              accept=".jpg,.png,.gif,.pdf,.xls,.xlsx,.doc,.docx,.ppt,.pptx">
            <label class="custom-file-label" #labelImport id="choosefile" for="customFile">Choose file</label>
          </div>
        </div>



      </div>
      <div class="modal-footer">
        <button type="submit" class="btn btn-primary btn-sm text-white rounded-pill" (click)="onSubmit();">
          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Save
        </button>

        <button class="btn btn-danger btn-sm text-white rounded-pill" (click)="CloseAddmodel()">
          <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
        </button>


      </div>
    </ng-template>




  </form>

  <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


        <ng-template #dealercheckbox>
            <div id="pdf-viewer-outer" class="modal-body">
              <h5>Dealer List </h5>
              <hr />
              <div class="row mt-2" id="dealerchckbox">

        <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->

        <div class="col-12 listoption">
          <input type="checkbox" name="chckboxall"
          [(ngModel)]="SelectedAllCheck"  (click)="Checkalldealer($event)" id="checkall"/>
          <label style="font-size:13px;" class="ml-1"><h6 class="mb-0" style="font-size:14px;"></h6>Select All</label>
        </div> <br/>

                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
                  <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected"
                    /> {{item.dealerName}}
                </div>

              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm" (click)="decline()">Back</button>
              <button type="button" class="btn btn-success btn-sm mr-1" (click)="GetselectedDealer()">Done</button>
            </div>
          </ng-template>
</section>