<section id="admindashboard">
    <!-- <form class="col-12 px-0" [formGroup]="managerInventoryForm"> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">
                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-12">
                                <div class="row" *ngIf="hideDealerControlsButton ==='true'">
                                    <div class="form-group mb-1 col-auto">
                                        <div class="row">
                                            <label class="col-form-label"><b>Group Name:</b></label>
                                            <div class="col-auto px-2">
                                                <label class="col-form-label">{{GroupNamevalue}}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group mb-1 col-auto">
                                        <div class="row">
                                            <label class="col-form-label col-auto col-lg-auto"><b>Dealer Name:</b></label>
                                            <div class="col-md-7">
                                                <select class="form-control" name="region" (change)="GetDealerValue($event)" [(ngModel)]="DealerNameFilter">
                                                    <option value="0" selected>Select Dealer Name </option>
                                                    <option *ngFor="let DealerListdata of DealerList" [value]="DealerListdata.id">
                                                        {{DealerListdata.dealerName}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="w-100" *ngIf="hideSupControlsButton ==='true'">
                                    <div class="row">
                                        <div class="form-group col-auto col-md-auto col-sm-12 mb-1">
                                            <div class="row">
                                                <label class="col-form-label col-auto"><b>Dealer Group</b></label>
                                                <div class="col-auto">
                                                    <div class="input-group" style="min-width:190px;max-width:190px;">
                                                        <ng-select style="width:100%;" [items]="GroupLists" bindLabel="globalCodeName"
                                                            [disabled]="groupDisable" (change)="GetDealerNamebyGroupId(selectedGroupIds)"
                                                            [ngModelOptions]="{standalone: true}" placeholder="Select group" appendTo="body"
                                                            [multiple]="false" [(ngModel)]="selectedGroupIds">
                                                        </ng-select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group col-lg-auto col-md-12 col mb-1" id="heightAuto">
                                            <div class="row">
                                                <label class="col-form-label col-auto">Select Dealers</label>
                                                <div id="Dealersselectbox" class="input-group dealername col">
                                                    <ng-select style="width:100%;" [items]="dealerlistName" id="dealerName" [dropdownPosition]="true"
                                                        bindLabel="dealerName" [ngModelOptions]="{standalone: true}" placeholder="Select dealer Name"
                                                        appendTo="body" (change)="ClearDealer($event)" (click)="CheckClick()" [multiple]="true"
                                                        [(ngModel)]="dealersmultiselectarray">
                                                    </ng-select>
                                                </div>
                                                <div class="col-auto px-xl-0 mt-2 mt-xl-0">
                                                    <button id="resetbtn" [ngClass]="[ isFavorite ? 'bg-warning' : 'bg-success text-white']" style="font-size:12px;" (click)="GethubUnitList()" class="btn btn-md mb-sm-0 ml-2 rounded-pill">Get
                                                        Units</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-1">
                                        <div class="form-group mb-1 col-auto">
                                            <div class="row">
                                                <label class="col-form-label col-auto"><b>Search by Dealers</b></label>
                                                <div class="input-group dealername col" style="max-width:300px; min-width:300px;">
                                                    <ng-select style="font-size:14px;" [items]="dealerlistForsearch" style="width:100%;"
                                                        [dropdownPosition]="true" bindLabel="dealerName" placeholder="Select Dealer"
                                                        [(ngModel)]="SelectSearchDealer" (change)="SerachDealer($event)">
                                                    </ng-select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
                <div id="hubunits" class="col-md-12">
                    <div class="card card-raised h-100 mb-0">
                        <div class="card-body" style="padding:10px;">
                            <div class="row">
                                <div class="col-xl-5 col-lg-12 col-12 pagioutside">
                                    <div class="card card-raised mb-0">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-lg-auto">
                                                    <h6 class="card-title text-white text-uppercase mb-0">Available Units</h6>
                                                </div>
                                                <div class="col-xl-auto col-lg-12 text-xl-right">
                                                    <span>Total Records : {{totalRemoved}}</span>
                                                    <div class="form-group mb-0 ml-2 d-inline-block">
                                                        <label class="mb-0" style="padding:2px;"> Items per Page:</label>
                                                        <select (change)="handleRemovedPageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                                {{ size }}
                                                            </option>
                                                        </select>
                                                      </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="w-100">
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th> <label>
                                                                    <input style="position: relative;top:2px;"  type="checkbox" [(ngModel)]="selectRemoveAllCheckbox"
                                                                    (change)="toggleRemoveSelectAll($event)">

                                                                  </label></th>
                                                                  <th (click)="sortRemovedHub('daysInStock')">Age<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('stockNumber')">Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('model')">Model<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('make')">Make<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('carYr')">Year<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let removeunitsListdata of removeunitsList | filter:searchRemovedText | slice: (pageRemoved-1) * pageSizeRemoved : (pageRemoved-1) * pageSizeRemoved + pageSizeRemoved; let i = index; let odd = odd">
                                                                <td><input type="checkbox" [(ngModel)]="removeunitsListdata.IsRemovedSelected" (change)="onRemovedheckboxChange(removeunitsListdata)"></td>
                                                                <td>{{removeunitsListdata.daysInStock}} </td>
                                                                <td>{{removeunitsListdata.vin}} </td>
                                                                <td>{{removeunitsListdata.stockNumber}} </td>
                                                                <td>{{removeunitsListdata.model}} </td>
                                                                <td>{{removeunitsListdata.make}} </td>
                                                                <td>{{removeunitsListdata.carYr}} </td>
                                                                <td>{{removeunitsListdata.trim}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>
                                                    <!-- <div class="row justify-content-between align-items-center">
                                                        <div class="col-12 col-sm-auto">
                                                          <ngb-pagination [collectionSize]="removeunitsList.length" [(page)]="pageRemoved" [maxSize]="5" [rotate]="true"
                                                          [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                                        </div>
                                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                                        </div>

                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-12 col-sm-auto">
                                              <ngb-pagination [collectionSize]="removeunitsList.length" [(page)]="pageRemoved" [maxSize]="5" [rotate]="true"
                                              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                            </div>
                                            <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div id="hubunitsmove" class="col-xl-2 col-lg-12 col-auto my-3 px-xl-1">
                                    <div class="row h-75 align-items-center justify-content-between text-center">
                                        <div class="col-12">
                                            <button type="button" class="btn btn-md btn-primary rounded-pill" (click)="MoveUnitsToHub()">
                                                Move To Hub <i class="fas fa-angle-double-right"></i></button>

                                            <button type="button" class="btn btn-md btn-info mt-xl-2 ml-2 ml-xl-0 rounded-pill" (click)="RemoveUnitsFromHub()">
                                                <i class="fas fa-angle-double-left"></i> Remove From Hub </button>
                                        </div>
                                        <div class="w-100 mt-2">
                                            <div id="inventorycanvas">
                                            <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                             [chartType]="pieChartType"  [colors]="colors" [options]="pieChartOptions"
                                                [plugins]="pieChartPlugins" [legend]="pieChartLegend">
                                            </canvas>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div class="col-xl-5 col-lg-12 col-12 pagioutside">
                                    <div class="card card-raised mb-0">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-lg-auto">
                                                    <h6 class="card-title text-white text-uppercase mb-0">Hub Units</h6>
                                                </div>
                                                <div class="col-xl-auto col-lg-12 text-xl-right">
                                                    <span>Total Records : {{totalhub}}</span>
                                                    <div class="form-group mb-0 ml-2 d-inline-block">
                                                        <label class="mb-0"  style="padding:2px;"> Items per Page:</label>
                                                        <select (change)="handlePageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                                {{ size }}
                                                            </option>
                                                        </select>
                                                      </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="w-100">
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th><label>
                                                                    <input style="position: relative;top:2px;" type="checkbox" [(ngModel)]="selectIncludeAllCheckbox"
                                                                    (change)="toggleIncludeSelectAll($event)">

                                                                  </label></th>
                                                                  <th (click)="sortAithrHub('daysInStock')">Age<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('stockNumber')">Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('model')">Model<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('make')">Make<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('carYr')">Year<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortAithrHub('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let unitsListdata of unitsList| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize; let i = index; let odd = odd">

                                                                <td><input type="checkbox" [(ngModel)]="unitsListdata.IsIncludeSelected" (change)="onIncludeheckboxChange(unitsListdata)"></td>
                                                                <td>{{unitsListdata.daysInStock}} </td>
                                                                <td>{{unitsListdata.vin}} </td>
                                                                <td>{{unitsListdata.stockNumber}} </td>
                                                                <td>{{unitsListdata.model}} </td>
                                                                <td>{{unitsListdata.make}} </td>
                                                                <td>{{unitsListdata.carYr}} </td>
                                                                <td>{{unitsListdata.trim}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>
                                                    <!-- <div class="row justify-content-between align-items-center">
                                                        <div class="col-12 col-sm-auto">
                                                          <ngb-pagination [collectionSize]="unitsList.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                                                          [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                                        </div>
                                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                                        </div>

                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-12 col-sm-auto">
                                              <ngb-pagination [collectionSize]="unitsList.length" [(page)]="page" [maxSize]="5" [rotate]="true"
                                              [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                            </div>
                                            <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="col-xl-5 col-lg-5 col-12">
                                    <div class="card card-raised h-100">
                                        <div class="card-header card-raised bg-info text-white">
                                            <div class="row justify-content-between align-items-center">
                                                <div class="col-lg-auto">
                                                    <h6 class="card-title text-white text-uppercase mb-0">Available Units</h6>
                                                </div>
                                                <div class="col-lg-8 text-right">
                                                    <span>Total Records : {{totalRemoved}}</span>
                                                    <div class="form-group mb-0 ml-2 d-inline-block">
                                                        <label class="mb-0" style="padding:2px;"> Items per Page:</label>
                                                        <select (change)="handleRemovedPageSizeChange($event)">
                                                            <option *ngFor="let size of pageSizes" [ngValue]="size">
                                                                {{ size }}
                                                            </option>
                                                        </select>
                                                      </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-body">
                                            <div class="w-100">
                                                <div class="table-responsive">
                                                    <table id="usertable" class="table table-bordered mb-0" style="width:100%">
                                                        <thead>
                                                            <tr>
                                                                <th> <label>
                                                                    <input style="position: relative;top:2px;"  type="checkbox" [(ngModel)]="selectRemoveAllCheckbox"
                                                                    (change)="toggleRemoveSelectAll($event)">
                                                                    Select All
                                                                  </label></th>
                                                                <th (click)="sortRemovedHub('vin')">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('stockNumber')">Stock Number<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('model')">Model<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('make')">Make<i class="fa fa-fw fa-sort"></i></th>
                                                                <th (click)="sortRemovedHub('trim')">Trim<i class="fa fa-fw fa-sort"></i></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr *ngFor="let removeunitsListdata of removeunitsList | filter:searchRemovedText | slice: (pageRemoved-1) * pageSizeRemoved : (pageRemoved-1) * pageSizeRemoved + pageSizeRemoved; let i = index; let odd = odd">
                                                                <td><input type="checkbox" [(ngModel)]="removeunitsListdata.IsRemovedSelected" (change)="onRemovedheckboxChange(removeunitsListdata)"></td>

                                                                <td>{{removeunitsListdata.vin}} </td>
                                                                <td>{{removeunitsListdata.stockNumber}} </td>
                                                                <td>{{removeunitsListdata.model}} </td>
                                                                <td>{{removeunitsListdata.make}} </td>
                                                                <td>{{removeunitsListdata.trim}} </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br>
                                                    <div class="row justify-content-between align-items-center">
                                                        <div class="col-12 col-sm-auto">
                                                          <ngb-pagination [collectionSize]="removeunitsList.length" [(page)]="pageRemoved" [maxSize]="5" [rotate]="true"
                                                          [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                                        </div>
                                                        <div class="col-12 col-sm-auto text-sm-right mt-2 mt-lg-0">

                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </div>

                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box"
                        [fullScreen]="false">
                        <p style="color: white"> Loading... </p>
                      </ngx-spinner>

                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

        <button #dealercheckboxpopupbuttonToBeClicked style="display:none;"
        (click)="Dealercheckboxpopup(dealercheckbox,'')"></button>


        <ng-template #dealercheckbox>
            <div id="pdf-viewer-outer" class="modal-body">
              <h5>Dealer List </h5>
              <hr />
              <div class="row mt-2" id="dealerchckbox">

        <!-- <input type="checkbox" name="chckboxall" id="checkall"/> <label>Check All</label> -->

        <div class="col-12 listoption">
          <input type="checkbox" name="chckboxall"
          [(ngModel)]="SelectedAllCheck"  (click)="Checkalldealer($event)" id="checkall"/>
          <label style="font-size:13px;" class="ml-1"><h6 class="mb-0" style="font-size:14px;"></h6>Select All</label>
        </div> <br/>

                <div class="col-6 col-sm-4 col-md-4 listoption" *ngFor="let item of dealerlistName ; let i = index">
                  <input type="checkbox" name="checkboxdealer" [(ngModel)]="item.IsSelected"
                    /> {{item.dealerName}}
                </div>

              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="declineDealer()">Back</button>
              <button type="button" class="btn btn-success btn-sm mr-1 rounded-pill" (click)="GetselectedDealer()">Done</button>
            </div>
          </ng-template>



</section>