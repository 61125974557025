
import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Account } from '@app/_models';
import { AccountService, AlertService } from '@app/_services';

import { AddgroupService } from '../_services/addgroup.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { NotifierService } from 'angular-notifier';
import { ExportToCsv } from 'export-to-csv';

@Component({
  selector: 'app-add-userroles',
  templateUrl: './add-userroles.component.html',
  styleUrls: ['./add-userroles.component.less']
})
export class AddUserrolesComponent implements OnInit {
  AddRolesForm: FormGroup;
	private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private modalService: NgbModal,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService:AddgroupService,
    private router: Router,
    notifier: NotifierService
    ) {
      this.notifier = notifier;
      this.accountService.SaveActivityLog("");
    }
    deleting = false;
    modalRef: BsModalRef;
   // for getting confirm box from HTML by Id
   @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

   // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  accounts: Account[] = [];
  isDesc: boolean = false;
  isValid:boolean = true;
  column: string = 'userName';
  modalcloseOpen: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  loading = false;
  submitted = false;

  Roleslist=[];
deleteitem:any=0;
edititemid:any=0;
editvaluename:any;
updateid:any;
  // function for column sorting
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.accounts.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    this.CreateGroupFrom();
    this.GetRoleslist();
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
  }

  

  // function for creating from
CreateGroupFrom() {
  this.AddRolesForm = new FormGroup({
    Name: this.fb.control('', Validators.required),
    Editname: this.fb.control('', Validators.required)
  });
}

// function for open model pop up
openModal(template: TemplateRef<any>,itemid:any) {
  this.deleteitem=itemid;
  this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });
}
// edit functionality
// function for open model pop up
EditModalpopup(template: TemplateRef<any>,itemid:any) {
  debugger;
  this.edititemid=itemid;
  var modelGroup = {
    "GuiId": itemid
  }

  this.addgroupService.EditrolesList(modelGroup)
  .subscribe(
    data => {
      debugger;
      this.editvaluename=data.name;
      this.updateid=data.id;

      this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

    },
    error => {
    });


}

//notifier
public showNotification( type: string, message: string ): void {
  this.notifier.notify( type, message );
}

closeEditModel(){
  this.modalRef.hide() ;
}

closepopupmodel(){
  this.AddRolesForm.invalid
}

openAddRoleModel(targetModal) {

  this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered' ,backdrop: 'static', keyboard: false });

}
// for update the edited part
update()
{
  this.submitted = true;

  if (this.AddRolesForm.controls.Editname.invalid) {
    this.isValid = false;
    return;
  }
  var modelGroup = {
    "GuiId": this.updateid,
    "name": this.editvaluename,
  }
  this.addgroupService.UpdaterolesList( modelGroup)

  .subscribe(
    (data: any) => {
      this.GetRoleslist();
      this.submitted = false;
      //this.UpdateGroupName();
      this.CreateGroupFrom();
      //alert("data changed")
      this.showNotification("success","Data updated successfully")
      this.modalRef.hide() ;
    // this.Grouplist = data;
    },
    error => {
    });

  //this.updateid=id;
 // this.addgroupService.EditGroupList(updateid);
}
get f() { return this.AddRolesForm.controls; }

ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }
  onSubmit() {
    debugger
    this.submitted = true;

    if (this.AddRolesForm.controls.Name.invalid) {
      this.isValid = false;
      return;
    } else {
      var modelGroup = {
        "Name": this.AddRolesForm.controls.Name.value
      }


      this.addgroupService.Saveaddroles(modelGroup)
      .subscribe(
        (data: any) => {
          debugger
          this.GetRoleslist();
          this.submitted = false;

          this.showNotification("success","Data saved successfully")
        this.CreateGroupFrom();
       this.modalRef.hide() ;
        //  this.modalcloseOpen.hide();
          this.closeEditModel();
          this.closepopupmodel();
          this.modalcloseOpen.hide();



          //this.modalRef.hide() ;
        // this.Grouplist = data;
        },
        error => {
        });
      this.loading = true;
      this.isValid = true;
    }


  }


  CloseAddmodel()
  {
    this.modalRef.hide();
  }

// function for confirm  delete
confirm(): void {
this.deleteitem;
var modelGroup = {
  "GuiId": this.deleteitem
}
this.addgroupService.DeleteRole(modelGroup)
  .subscribe(
    data => {
      debugger;
      this.showNotification("success", "deleted successfully");
      this.GetRoleslist();
    },
    error => {
    });

  this.modalRef.hide();

}
decline(): void {
  this.modalRef.hide();
}

displayStyle = "none";

openPopup() {
  this.displayStyle = "block";
}
closePopup() {
  this.displayStyle = "none";
}


//Calling get list of group on page load

GetRoleslist()
{  debugger;
  this.addgroupService.GetRoleslist()
  .subscribe(
    (data: any) => {
      debugger;
     this.Roleslist = data;
     this.modalRef.hide() ;
    },
    error => {
    });
}



}


