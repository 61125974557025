<section id="admindashboard">
    <!-- <form class="col-12 px-0" [formGroup]="TrackingListForm"> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"  (click)="copytable('usertable')">Copy</button>
                                  </li>
                                 
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                                  </li>
                                  
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                                  </li>
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                                  </li>
                            </ul>
                        </div>
                    

                        <div class="search-heros col-sm-12 col-md-auto mt-2 mt-lg-0">
                            <div class="form-group d-flex mb-1">
                                <label class="mr-3 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </div>
                        </div>
                    </div>

                    <hr class="my-1"/>

                    <!-- <div class="col-md col-12 mt-3">
                        <div class="row justify-content-start align-items-center">
                            <div class="form-group mb-1 col-lg-auto px-xl-2">
                                <div class="row">
                                    <label class="col-auto col-form-label"><b> from:</b></label>
                                    <div class="col-lg-auto col-12 px-2">
                                        <input type="date" [(ngModel)]="TrackToNg" class="form-control" style="width:138px;">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-1 col-lg-auto px-xl-2">
                                <div class="row">
                                    <label  class="col-auto col-form-label"><b> to:</b></label>
                                    <div class="col-lg-auto col-12 px-2">
                                        <input type="date" [(ngModel)]="TrackfromNg" class="form-control">
                                    </div>
                                </div>
                            </div>
                            <div class="form-group mb-1 col-lg-auto px-xl-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="mr-2 mb-0">User</label>

                                    <select class="form-control" [(ngModel)]="userlistNameValue">
                                        <option value="0" selected>Select User</option>
                                        <option *ngFor="let userlistNamelist of userlistName" [value]="userlistNamelist.id">
                                            {{userlistNamelist.firstName}} {{userlistNamelist.lastName}}
                                        </option>
                                    </select>
                                    
                                </div>
                            </div>
                            <-- <div class="form-group mb-1 col-lg-auto px-xl-2">
                                <div class="d-flex justify-content-between align-items-center">
                                    <label class="mr-2 mb-0">Activity</label>
                                    <select class="form-control"
                                    [(ngModel)]="actvitylistNameValue" >
                                    <option value="0" selected>Select Actvity</option>
                                    <option *ngFor="let actvitylistNames of actvitylistName"
                                    [value]="actvitylistNames.activityLink">{{actvitylistNames.activityLinkShow}}
                                    </option>
                                </select>
                                </div>
                            </div> -
                            <div class="form-group mb-1 col-lg-auto px-xl-2">
                                <button class="btn btn-sm btn-warning rounded-pill" (click)="SearchActivity();">Search</button>
                                <button class="btn btn-sm btn-warning ml-2 rounded-pill" (click)="Reset();">Reset</button>
                            </div>
                        </div>
                    </div> -->


                    <div class="row mt-3 mb-3">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 col-12">
                                    <div class="card card-raised mb-0">
                                        <div class="card-header card-raised bg-info text-white">
                                            <span class="text-uppercase">Total Activities</span>
                                        </div>
                                        <div class="card-body">
                                            <div class="row align-items-center justify-content-between">
                                                <div class="col-lg-5 col-md-5 col-12">
                                                    <div class="card w-100">
                                                        <div class="card-header card-raised bg-info text-white">
                                                            <h6 class="mb-0">Details</h6>
                                                        </div>
                                                        <div class="card-body" id="detailstotalActivities">
                                                            <div class="table-responsive total-activies">
                                                                <table class="table table-bordered mb-0">
                                                                    <tr>
                                                                        <td><b>Find Cars Search Count:</b></td>
                                                                        <td><a style="color: #4e4edc;" (click)="GetFindCountAllUnitDetails('2');"> {{findCarCount}}</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Build Car Visits:</b></td>
                                                                        <td><a style="color: #4e4edc;" (click)="GetBuildCountAllUnitDetails('3');">  {{BuildCarVisitsCount}}</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Total Aithr Verify Button clicks:</b></td>
                                                                        <td><a style="color: #4e4edc;" (click)="GetButtonClickCountAllUnitDetails('4');"> {{ButtonclicksCount}}</a></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Aithmarketplace visits:</b></td>
                                                                        <td><a  style="color: #4e4edc;" (click)="GetVisitCountClickCountAllUnitDetails('5');">  {{VisitCount}}</a></td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-5 col-md-5 col-12">
                                                    <canvas baseChart [data]="pieChartData" [labels]="pieChartLabels"
                                                        [chartType]="pieChartType" [options]="pieChartOptions" 
                                                        [colors]="colorsSearch"
                                                        [plugins]="pieChartPlugins"
                                                        [legend]="pieChartLegend">
                                                    </canvas>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-2" style="display: none;">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">Tracking Details</h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('activityDatetime')">Activity DateTime <i
                                                        class="fa fa-fw fa-sort"></i>
                                                </th>
                                                    <th (click)="sort('userName')"> User Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    
                                                    <th (click)="sort('browserInfo')">Browser Info<i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('ipAddress')">IP Address <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('ipAddress')">Resource <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th >Comments </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let trackingdata of tracking| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                    <td> {{ trackingdata.activityDatetime| date:'medium'}}</td>
                                                    <td>{{ trackingdata.userName }}</td>
                                                   
                                                    <td> {{ trackingdata.browserInfo }}</td>
                                                    <td> {{ trackingdata.ipAddress }}</td>
                                                    <td> {{ trackingdata.activityLink }}</td>
                                                    <td> {{ trackingdata.comments }}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>
                                        <br>

                                        <div class="row justify-content-between align-items-center">
                                            <div class="col">

                                                <ngb-pagination [collectionSize]="tracking.length" [(page)]="page"
                                                    [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                    [boundaryLinks]="true"></ngb-pagination>
                                            </div>

                                        </div>




                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>

                   
                    <div class="row mx-0 mt-1">
                        <div class="col-md-12 px-0">
                            <div class="card card-raised h-100 w-100 mb-0">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row">
                                        <div class="col-md col-12 mt-3">
                                            <div class="row justify-content-start align-items-center">
                                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <div class="row">
                                                        <label class="col-auto col-form-label"><b> from:</b></label>
                                                        <div class="col-lg-auto col-12 px-2">
                                                            <input type="date" [(ngModel)]="TrackToNg" class="form-control" style="width:138px;">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <div class="row">
                                                        <label  class="col-auto col-form-label"><b> to:</b></label>
                                                        <div class="col-lg-auto col-12 px-2">
                                                            <input type="date" [(ngModel)]="TrackfromNg" class="form-control">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <label class="mr-2 mb-0">Dealer</label>
                                                        <select class="form-control" [(ngModel)]="DealerIdvalueDropDown"
                                                        (change)="getUserbyDealers($event.target.value)" >
                                                            <option value="0" selected>Select Dealer</option>
                                                            <option *ngFor="let dealerlistNamedata of dealerlistName" [value]="dealerlistNamedata.id">
                                                                {{dealerlistNamedata.dealerName}} 
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <label class="mr-2 mb-0">User</label>
                                                        <select class="form-control" [(ngModel)]="userlistNameValue">
                                                            <option value="0" selected>Select User</option>
                                                            <option *ngFor="let userlistNamelist of userlistName" [value]="userlistNamelist.id">
                                                                {{userlistNamelist.firstName}} {{userlistNamelist.lastName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <!-- <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <label class="mr-2 mb-0">Activity</label>
                                                        <select class="form-control"
                                                        [(ngModel)]="actvitylistNameValue" >
                                                        <option value="0" selected>Select Actvity</option>
                                                        <option *ngFor="let actvitylistNames of actvitylistName"
                                                        [value]="actvitylistNames.activityLink">{{actvitylistNames.activityLinkShow}}
                                                        </option>
                                                    </select>
                                                    </div>
                                                </div> -->
                                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                                    <button class="btn btn-sm btn-warning rounded-pill" (click)="SearchActivity();">Search</button>
                                                    <button class="btn btn-sm btn-warning ml-2 rounded-pill" (click)="Reset();">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12"  *ngIf="showUnitDetail ==='true'">
                            <div class="row">
                                <div class="card card-raised h-100 w-100">
                                    <div class="card-header card-raised bg-info text-white">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-auto">
                                                <h5 style="font-size:14px;" class="card-title text-white text-uppercase d-inline-block mb-0 pt-1"> Details </h5>
                                                <button class="btn btn-sm btn-warning ml-4" (click)="HideDetails();">Hide Details</button>
                                            </div>
                                           
                                            <div class="col-auto mr-auto">
                                                <h6 class="mt-0 counter text-white mb-0 pt-1"> <span class="mr-2"> </span></h6>
                                            </div>
                                            <div class="col-auto">
                                                <div class="row justify-content-between align-items-center">
                                                  <div class="col-auto px-2 pt-1">
                                                      <h6 class="mt-0 counter text-white mb-0"> <span class="mr-2">Total Count:</span>{{UnittotalCount}}</h6>
                                                  </div>
                                                  <div class="search-heros col-xl col-md ml-auto mt-2 mt-lg-0">
                                                    <div class="form-group d-flex mb-md-0 mb-2">
                                                      <label class="mr-2 mt-2">Search:</label>
                                                    <input class="form-control" type="text"  [(ngModel)]="searchUnitText" 
                                                    [ngModelOptions]="{standalone: true}"
                                                        autocomplete="off">
                                                    </div>
                                                  </div>
                                                  
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-4">
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered" id="">
                                                  <thead>
                                                    <tr>
                                                      <th (click)="sortUnit('logUTCDateTime')" style="text-align: left;">LogUTC DateTime<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('userName')" style="text-align: left;"> User Name<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('lenderName')" style="text-align: left;"> Lender Name<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('planType')" style="text-align: left;"> Plan Type<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('payCall')" style="text-align: right;"> Pay Call<i class="fa fa-fw fa-sort"></i></th>

                                                      <th (click)="sortUnit('interestRate')" style="text-align: left;">Interest Rate<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('downPayment')" style="text-align: right;">Down Payment<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('Lien')" style="text-align: right;">Lien<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('acv')" style="text-align: right;">Trade in Value<i class="fa fa-fw fa-sort"></i></th>

                                                        <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('vin')" style="text-align: right;">VIN<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('amount2Finance')" style="text-align: right;">Amount Finance<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('backDocFee')" style="text-align: right;">Back Doc Fee<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('frontDocFee')" style="text-align: right;">front Doc Fee<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('dealerCost')" style="text-align: right;">Dealer Cost<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('reserves')" style="text-align: right;">Reserves<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('lenderFee')" style="text-align: right;">Lender Fee<i class="fa fa-fw fa-sort"></i></th>
                                                      <th *ngIf="buildCarShow ==='true'" (click)="sortUnit('ListedPrice')" style="text-align: right;">Listed Price<i class="fa fa-fw fa-sort"></i></th>
                                                      
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    
                                                    <tr *ngFor="let reportDataMatrixAllListdata of reportDataMatrixAllList | filter:searchUnitText | slice: (pageUnit-1) * pageUnitSize : (pageUnit-1) * pageUnitSize + pageUnitSize; let i = index; let odd = odd">
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.logUTCDateTime | date}} </td>
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.userName}} </td>
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.lenderName}} </td>
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.planType}} </td>
                                                   <td style="text-align: right;">{{reportDataMatrixAllListdata.payCall | currency}}  </td>
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.interestRate}} </td>
                                                   <td style="text-align: right;">{{reportDataMatrixAllListdata.downPayment | currency}}  </td>
                                                   <td style="text-align: right;">{{reportDataMatrixAllListdata.lien | currency}}  </td>
                                                   <td style="text-align: right;"> {{reportDataMatrixAllListdata.acv | currency}}  </td>
                                                   
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.vin }}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.amount2Finance | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.backDocFee | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.frontDocFee | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.dealerCost | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.reserves | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.lenderFee | currency}}  </td>
                                                   <td *ngIf="buildCarShow ==='true'" style="text-align: right;"> {{reportDataMatrixAllListdata.listedPrice | currency}}  </td>
                                                   
                                                    </tr>
                                  
                                                  </tbody>
                                                 </table>
                                                 <br>
                                                 <ngb-pagination [collectionSize]="reportDataMatrixAllList.length" [(page)]="pageUnit" [maxSize]="5" [rotate]="true"
                                                 [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row mx-0 mt-2" *ngIf="showAithrMarketShow ==='true'">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="card card-raised h-100 w-100">
                                    <div class="card-header card-raised bg-info text-white">
                                        <div class="row justify-content-between align-items-center">
                                            <div class="col-auto">
                                                <h5 style="font-size:14px;" class="card-title text-white text-uppercase d-inline-block mb-0 pt-1"> Details </h5>
                                                <button class="btn btn-sm btn-warning ml-4" (click)="HideDetails();">Hide Details</button>
                                            </div>
                                            <div class="col-auto mr-auto">
                                                <h6 class="mt-0 counter text-white mb-0 pt-1"> <span class="mr-2"> </span></h6>
                                            </div>
                                            <div class="col-auto">
                                                <div class="row justify-content-between align-items-center">
                                                  <div class="col-auto px-2 pt-1">
                                                      <h6 class="mt-0 counter text-white mb-0"> <span class="mr-2">Total Count:</span>{{UnitAithrtotalCount}}</h6>
                                                  </div>
                                                  <div class="search-heros col-xl col-md ml-auto mt-2 mt-lg-0">
                                                    <div class="form-group d-flex mb-md-0 mb-2">
                                                      <label class="mr-2 mt-2">Search:</label>
                                                    <input class="form-control" type="text"  [(ngModel)]="searchUnitText" 
                                                    [ngModelOptions]="{standalone: true}"
                                                        autocomplete="off">
                                                    </div>
                                                  </div>
                                                  
                                                </div>
                                          </div>
                                        </div>
                                    </div>
                                    <div class="card-body p-4">
                                        <div class="row">
                                          <div class="col-12">
                                            <div class="table-responsive">
                                                <table class="table table-bordered" id="">
                                                  <thead>
                                                    <tr>
                                                      <th (click)="sortUnit('userName')" style="text-align: left;">Inquired By<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('vin')" *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;"> Vin<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('inquiredDate')" *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;"> Inquired Date<i class="fa fa-fw fa-sort"></i></th>                                           
                                                      <th (click)="sortUnit('notes')" *ngIf="showAithrMarketColummn ==='false'" style="text-align: right;"> Notes<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('status')" *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;">Status<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('activityDate')" *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">Activity Date<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('activityLink')" *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">ActivityLink<i class="fa fa-fw fa-sort"></i></th>
                                                      <th (click)="sortUnit('comments')" *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">Comments<i class="fa fa-fw fa-sort"></i></th>
                                                    </tr>
                                                  </thead>
                                                  <tbody>
                                                    
                                                    <tr *ngFor="let reportDataMatrixAllListdata of reportAithrDataMatrixAllList | filter:searchAithrUnitText | slice: (pageAithrCountUnit-1) * pageAithrCountUnitSize : (pageAithrCountUnit-1) * pageAithrCountUnitSize + pageAithrCountUnitSize; let i = index; let odd = odd">
                                                   <td style="text-align: left;">{{reportDataMatrixAllListdata.userName }} </td>
                                                   <td *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;"><a style="color: #4e4edc;" (click)="GetDetailByVIN(reportDataMatrixAllListdata.vin,vindetailModelpop);"> {{reportDataMatrixAllListdata.vin}}</a> </td>
                                                   <td *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;">{{reportDataMatrixAllListdata.inquiredDate | date}} </td>
                                                   <td *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;">{{reportDataMatrixAllListdata.notes}} </td>
                                                   <td *ngIf="showAithrMarketColummn ==='false'" style="text-align: left;">{{reportDataMatrixAllListdata.status }}  </td>

                                                   <td *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">{{reportDataMatrixAllListdata.activityDatetime | date }}  </td>
                                                   <td *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">{{reportDataMatrixAllListdata.activityLink }}  </td>
                                                   <td *ngIf="showAithrMarketColummn ==='true'" style="text-align: left;">{{reportDataMatrixAllListdata.comments }}  </td>
                                                   
                                                   
                                                    </tr>
                                  
                                                  </tbody>
                                                 </table>
                                                 <br>
                                                 <ngb-pagination [collectionSize]="reportAithrDataMatrixAllList.length" [(page)]="pageAithrCountUnit" [maxSize]="5" [rotate]="true"
                                                 [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <ng-template #vindetailModelpop>
            <div id="pdf-viewer-outer" class="modal-body ">
                <div class="row">
                    <h5 class="col mb-0">Unit Details</h5>
                </div>
                <hr/>
                <div class="unit-details col-12">
                    <div class="row mt-1">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>VIN</b></label>   
                                <span class="value">{{VinValueModelPopup}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>StockNumber</b></label>   
                                <span class="value">{{StockNumberValue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>CarYr</b></label>   
                                <span class="value">{{CarYrValue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Mileage</b></label>   
                                <span class="value">{{MileageValue}}</span>         
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Make</b></label>   
                                <span class="value">{{MakeValue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Model</b></label>   
                                <span class="value">{{ModelValue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Trim</b></label>   
                                <span class="value">{{Trimvalue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Body</b></label>   
                                <span class="value">{{BodyValue}}</span>         
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Colour:</b></label>   
                                <span class="value">{{ColourValue}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>VehicleInfo:</b></label>   
                                <span class="value">NULL</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Listed Price</b></label>   
                                <span class="value">{{ListedPriceValue | currency}}</span>         
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Dealer Cost</b></label>   
                                <span class="value">{{DealerCostValue | currency}}</span>         
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Group Name</b></label>   
                                <span class="value">{{GroupName}}</span>
                                       
                            </div>
                        </div>
                        
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Dealer Name</b></label>   
                                <span class="value">{{DealerName}}</span>     
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Dealer Email</b></label>   
                                <span class="value">{{DealerEmail}}</span> 
                            </div>
                        </div>
                        <div class="col-12 col-xl-3 col-lg-3 col-md-6">
                            <div class="form-group">
                                <label><b>Dealer Phone</b></label>   
                                <span class="value">{{DealerPhone}}</span>         
                            </div>
                        </div>
                    </div>
                   
                    
                </div>
              
              

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-danger btn-sm rounded-pill" (click)="declineVinDetails()">Back</button>
              
            </div>
          </ng-template>

    <!-- </form> -->
</section>