import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lender-rate-sheet-pdf',
  templateUrl: './lender-rate-sheet-pdf.component.html',
  styleUrls: ['./lender-rate-sheet-pdf.component.less']
})
export class LenderRateSheetPdfComponent implements OnInit {

  constructor() { }
  pdfSource = '/assets/MobileReport.pdf'
  ngOnInit(): void {
  }

}
