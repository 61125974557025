<section id="admindashboard">
    <!-- <form class="col-12 px-0" [formGroup]="TrackingListForm"> -->
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                            <ul class="list-unstyled mb-1">
                                <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill"  (click)="copytable('usertable')">Copy</button>
                                  </li>
                                 
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="exportexcel()">Excel</button>
                                  </li>
                                  
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="downloadPdf()">PDF</button>
                                  </li>
                                  <li> 
                                    <button class="btn btn-secondary btn-sm mb-sm-0 rounded-pill" (click)="printTable()">Print</button>
                                  </li>
                            </ul>
                        </div>
                        <div class="col-md col-12">
                            <div class="row justify-content-start align-items-center">
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <div class="row">
                                        <label class="col-auto col-form-label"><b> To:</b></label>
                                        <div class="col-lg-auto col-12">
                                            <input type="date" [(ngModel)]="TrackToNg" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <div class="row">
                                        <label  class="col-auto col-form-label"><b> From:</b></label>
                                        <div class="col-lg-auto col-12">
                                            <input type="date" [(ngModel)]="TrackfromNg" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group mb-1 col-lg-auto px-xl-2">
                                    <button class="btn btn-sm btn-warning rounded-pill" (click)="SearchActivity();">Search</button>
                                    &nbsp;&nbsp;
                                    <button class="btn btn-sm btn-warning rounded-pill" (click)="Reset();">Reset</button>
                                </div>
                            </div>
                        </div>

                        <div class="search-heros col-sm-12 col-md-auto mt-2 mt-lg-0">
                            <div class="form-group d-flex mb-1">
                                <label class="mr-3 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </div>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="row justify-content-between align-items-center">
                                        <div class="col-sm-6">
                                            <h5 class="card-title text-white text-uppercase mb-0">Tracking Details</h5>
                                        </div>
                                        <div class="col-sm-6 text-sm-right mt-sm-0 mt-1">
                                            <h6 class="mb-0"><b>Total Records :</b> {{TotalRecord}}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('activityDatetime')">Activity DateTime <i
                                                        class="fa fa-fw fa-sort"></i>
                                                </th>
                                                    <th (click)="sort('userName')"> User Name <i
                                                            class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    
                                                    <th (click)="sort('browserInfo')">Browser Info<i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('ipAddress')">IP Address <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('ipAddress')">Resource <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th >Comments </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let trackingdata of tracking| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                    <td> {{ trackingdata.activityDatetime| date:'medium'}}</td>
                                                    <td>{{ trackingdata.userName }}</td>
                                                   
                                                    <td> {{ trackingdata.browserInfo }}</td>
                                                    <td> {{ trackingdata.ipAddress }}</td>
                                                    <td> {{ trackingdata.activityLink }}</td>
                                                    <td> {{ trackingdata.comments }}</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff"
                                            type="square-jelly-box" [fullScreen]="false">
                                            <p style="color: white"> Loading... </p>
                                        </ngx-spinner>
                                        <br>

                                        <div class="row justify-content-between align-items-center">
                                            <div class="col">

                                                <ngb-pagination [collectionSize]="tracking.length" [(page)]="page"
                                                    [maxSize]="5" [rotate]="true" [ellipses]="false"
                                                    [boundaryLinks]="true"></ngb-pagination>
                                            </div>

                                        </div>




                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <app-footer id="secondfooter"></app-footer> -->

    <!-- </form> -->
</section>