import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService, AlertService } from '@app/_services';
import { DashboardService } from '@app/_services/dashboard.service';
import { NotifierService } from 'angular-notifier';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxSpinnerService } from 'ngx-spinner';
import { Tracking } from '@app/_models/Tracking';
import { ExportToCsv } from 'export-to-csv';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { DatePipe } from '@angular/common'; 

@Component({
  selector: 'app-tracking-details',
  templateUrl: './tracking-details.component.html',
  styleUrls: ['./tracking-details.component.less']
})
export class TrackingDetailsComponent implements OnInit {
  TrackingListForm: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    notifier: NotifierService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private router: Router, private dashboardService: DashboardService) { this.notifier = notifier; }

    key = 'dealerName'; // set default
    reverse = false;
    sortedUsers: any;
    DeleteUserid: any;
    isDesc: boolean = false;
    column: string = 'dealerName';
    tracking: Tracking[] = [];
    TotalRecord :any;
    //paging
    name = 'Angular';
    page = 1;
    pageSize = 10;
    submitted = false;
    isValid: boolean = true;
    opcode:any;
    DealerIdValue:any;

    TrackfromNg: any;
    TrackToNg: any;
    DateCheckValue:any;

    
    sort(property) {
      debugger;
      this.isDesc = !this.isDesc; //change the direction
      this.column = property;
      let direction = this.isDesc ? 1 : -1;
  
      this.tracking.sort(function (a, b) {
        if (a[property] < b[property]) {
          return -1 * direction;
        }
        else if (a[property] > b[property]) {
          return 1 * direction;
        }
        else {
          return 0;
        }
      });
    };
    searchText;
    rolevalue: any;
    dealerNamevalue: any;
    GroupNamevalue: any;
    DealerIdvalue: any;
    ColorTheme:any;
  ngOnInit(): void {
    
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    this.DateCheckValue="1";
    this.setDefaultDateValues();
    if (this.rolevalue == "SuperAdmin") {
      this.opcode="1";
      this.GetTrackingList();
    } else {
      this.opcode="2";
      this.GetTrackingList();
    }
    
  }
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }

  setDefaultDateValues() {
    const currentDate = new Date();
    this.TrackToNg = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
    this.TrackfromNg = this.datePipe.transform(currentDate, 'yyyy-MM-dd');
  }

  exportToCSV(): void {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      showTitle: false,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    };
    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(this.tracking);
  }

  printTable() {
    var prepare = [];
    this.tracking.forEach(e => {
      var tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });
  
    var table = '<table><thead><tr><th>User Name</th><th>ActivityDatetime</th><th>BrowserInfo</th><th>ipAddress</th><th>activityLink</th><th>comments</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';
  
    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();
  
    // Wait for the table to be fully rendered before printing
    newWin.onload = function() {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  } 

  downloadPdf() {
    var prepare = [];
    this.tracking.forEach(e => {
      var tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    autoTable(doc, {
      head: [['User Name', 'ActivityDatetime', 'BrowserInfo', 'ipAddress', 'activityLink', 'comments']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('tracking' + '.pdf');
  }
  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  exportexcel() {
    const prepare = [];
    this.tracking.forEach(e => {
      const tempObj = [];
      tempObj.push(e.userName);
      tempObj.push(e.activityDatetime);
      tempObj.push(e.browserInfo);
      tempObj.push(e.ipAddress);
      tempObj.push(e.activityLink);
      tempObj.push(e.comments);
      prepare.push(tempObj);
    });
  
    / Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['User Name', 'ActivityDatetime', 'BrowserInfo', 'ipAddress', 'activityLink', 'comments'],
      ...prepare
    ]);
  
    / Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Listoflenders');
  
    / Save the workbook to a file /
    XLSX.writeFile(wb, 'Listoflenders.xlsx');
  }
  Reset()
  {
    // this.TrackToNg=null;
    // this.TrackfromNg=null;
    this.ngOnInit();
  }
  SearchActivity()
  {
    this.DateCheckValue="2";
    this.GetTrackingList();
  }
  
  GetTrackingList() {
    this.spinner.show();
    var modelGroup = {
      "Opcode": this.opcode,   
      "DealerId": this.DealerIdvalue,
      "TrackTo":this.TrackToNg,
      "TrackFrom":this.TrackfromNg,
      "DateCheck": this.DateCheckValue, 
    }
    this.dashboardService.getTrackingList(modelGroup)
      .subscribe(
        (data: any) => {
        
          this.tracking = data;
          this.TotalRecord = data.length;
          this.spinner.hide();
        },
        error => {
        });
  }


}
