
﻿import { NgModule, APP_INITIALIZER , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule,FormsModule  } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

// used to create fake backend
import { fakeBackendProvider } from './_helpers';

import { AppRoutingModule } from './app-routing.module';
import { JwtInterceptor, ErrorInterceptor, appInitializer } from './_helpers';
import { AccountService } from './_services';
import { AppComponent } from './app.component';
import { AlertComponent } from './_components';
import { HomeComponent } from './home';;
import { CarfexDashboardComponent } from './carfex-dashboard/carfex-dashboard.component'
;
import { HeaderComponent } from './header/header.component'
;
import { FooterComponent } from './footer/footer.component'
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { VehicleListComponent } from './vehicle-list/vehicle-list.component'
;
import { UserListComponent } from './user-list/user-list.component'
;
import { CarfaxapiListComponent } from './carfaxapi-list/carfaxapi-list.component';
import { VhrReportComponent } from './vhr-report/vhr-report.component';;
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { NgxLoaderModule } from '@tusharghoshbd/ngx-loader';
// import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { VhrpdfreportViewComponent } from './vhrpdfreport-view/vhrpdfreport-view.component';;
import { SignUpComponent } from './sign-up/sign-up.component'
;
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component'

import { HashLocationStrategy,Location, LocationStrategy } from '@angular/common';
import { ResetpasswordaftermailLinkComponent } from './resetpasswordaftermail-link/resetpasswordaftermail-link.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JwtModule } from "@auth0/angular-jwt";;
import { IicBatchComponent } from './iic-batch/iic-batch.component'
import { NgImageSliderModule } from 'ng-image-slider';
import { JwPaginationModule } from 'jw-angular-pagination';
import { AgmCoreModule } from '@agm/core';
import { AgmOverlays } from 'agm-overlays';;
import { DealerLocationComponent } from './dealer-location/dealer-location.component';
import { MasterDashboardComponent } from './master-dashboard/master-dashboard.component';
import { ChartsModule } from 'ng2-charts';
import { ApicallHistoryComponent } from './apicall-history/apicall-history.component';
import { NgxSpinnerModule } from 'ngx-spinner';
//function is use to get jwt token from local storage
export function tokenGetter() {
  return localStorage.getItem("jwt");
}
import { FilterPipe} from '../app/filters/filter.pipe';
import { VehicleDetailsComponent } from './vehicle-details/vehicle-details.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { SideNavComponent } from "./side-nav/side-nav.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';;
import { AddGroupComponent } from './add-group/add-group.component';
import { AddUserrolesComponent } from './add-userroles/add-userroles.component';;
import { ListPostionsComponent } from './list-postions/list-postions.component'

import { ListWholesalersComponent } from './list-wholesalers/list-wholesalers.component'
;
import { AddRepsComponent } from './add-reps/add-reps.component'
import { NotifierModule, NotifierOptions } from 'angular-notifier';;
import { ListRepoComponent } from './list-repo/list-repo.component'
;
import { ListDealersComponent } from './list-dealers/list-dealers.component'
;
import { DatePipe } from '@angular/common';;
import { AithrhubDashboardComponent } from './aithrhub-dashboard/aithrhub-dashboard.component'
;
import { ActivateDealersListComponent } from './activate-dealers-list/activate-dealers-list.component'
;
import { GlobalCodeComponent } from './global-code/global-code.component'
import { AddGlobalCodeCategoryComponent } from './add-global-code-category/add-global-code-category.component';

import { AddLenderComponent } from './add-lender/add-lender.component';
import { ListLendersComponent } from './list-lenders/list-lenders.component';
import { AddInventoryComponent } from './add-inventory/add-inventory.component'
import { LoginComponent } from './account/login.component';
import { TrackingDetailsComponent } from './tracking-details/tracking-details.component'
;
import { FindcarDetailComponent } from './findcar-detail/findcar-detail.component'
;
import { BuildCarComponent } from './build-car/build-car.component';
import { AdminSettingComponent } from './admin-setting/admin-setting.component';
import { AddLenderRateSheetComponent } from './add-lender-rate-sheet/add-lender-rate-sheet.component';;
import { FindcarDetailfinalComponent } from './findcar-detailfinal/findcar-detailfinal.component'
;
import { AithrhubBuylogsComponent } from './aithrhub-buylogs/aithrhub-buylogs.component'
;
import { InventoryimportExcelComponent } from './inventoryimport-excel/inventoryimport-excel.component'
;
import { HubwholesaleDashboradComponent } from './hubwholesale-dashborad/hubwholesale-dashborad.component'
;
import { FlagreportDashboardComponent } from './flagreport-dashboard/flagreport-dashboard.component'
;
import { TwoDigitDecimaNumberDirectiveDirective } from './Two-digit-decimal-number-directive/two-digit-decima-number-directive.directive'
;
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { AccountSettingComponent} from './account-setting/account-setting.component';
import { BuildReportComponent} from './build-report/build-report.component';
import { NumericOnlyDirective } from './numeric-only.directive'
;
import { WelcomeDashboardComponent } from './welcome-dashboard/welcome-dashboard.component'
import { PhoneMaskDirective } from './phone-mask.directive'
import { CommunicationLogsComponent } from './communication-logs/communication-logs.component'
import { LenderRateSheetPdfComponent } from './lender-rate-sheet-pdf/lender-rate-sheet-pdf.component'
import { DetailListComponent } from './detail-list/detail-list.component'
import { AnalyticDashboardComponent } from './analytic-dashboard/analytic-dashboard.component'
import { FreshBuildComponent } from './fresh-build/fresh-build.component'
import { CcbvalueListComponent } from './ccbvalue-list/ccbvalue-list.component';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown'

import { LenderPpsaComponent } from './lender-ppsa/lender-ppsa.component';;
import { AddCustomerComponent } from './add-customer/add-customer.component'
import { ManageHubinventoryComponent } from './manage-hubinventory/manage-hubinventory.component';
import { CustomerWarrantykeyComponent } from './customer-warrantykey/customer-warrantykey.component';
import { CustomerOrderComponent } from './customer-order/customer-order.component';
import { AithrxClientDashboardComponent } from './aithrx-client-dashboard/aithrx-client-dashboard.component';
import { LoanCalculatorComponent } from './loan-calculator/loan-calculator.component';
import { OfferDashboardComponent } from './offer-dashboard/offer-dashboard.component';
import { NotificationDashboardComponent } from './notification-dashboard/notification-dashboard.component';
import { UnitClearanceComponent } from './unit-clearance/unit-clearance.component';
import { InquiryLogoComponent } from './inquiry-logo/inquiry-logo.component';
import { CarfaxapiDashabordComponent } from './carfaxapi-dashabord/carfaxapi-dashabord.component';
import { TrainingVideoComponent } from './training-video/training-video.component';
import { CreditapiLogComponent } from './creditapi-log/creditapi-log.component';
import { WarrantylogsComponent } from './warranty-logs/warranty-logs.component';
import { CreditApplicationComponent } from './credit-application/credit-application.component';
import { CreditorLogComponent } from './creditor-log/creditor-log.component';
import { CreditorsTiresComponent } from './creditors-tires/creditors-tires.component';
import { VehiclearchivedListComponent } from './vehiclearchived-list/vehiclearchived-list.component';
import { CreditappCustomerComponent } from './creditapp-customer/creditapp-customer.component';
import { CreditFindcarDetailfinalComponent } from './creditapp-findcar/creditapp-findcar.component';;
import { AithrVerificationComponent } from './aithr-verification/aithr-verification.component';

import { AuctionsListComponent } from './auctions-list/auctions-list.component'
import { AuctionsTimeslotsListComponent } from './auctions-timeslots-list/auctions-timeslots-list.component'
import { AuctionsVehiclesListComponent } from './auctions-vehicles-list/auctions-vehicles-list.component'
import { ListViewVehiclesComponent } from './list-view-vehicles/list-view-vehicles.component';
import { ReportDetailsComponent } from './report-details/report-details.component';

import { FilterPipeDataPipe } from './filters/filter-pipe-data.pipe';
import { ReportmatrixComponent } from './reportmatrix/reportmatrix.component';;
import { DashboardComponent } from './dashboard/dashboard.component'
;
import { AboutUsComponent } from './about-us/about-us.component'
;
import { ContactUsComponent } from './contact-us/contact-us.component'
const customNotifierOptions: NotifierOptions = {
    position: {
          horizontal: {
              position: 'right',
              distance: 12
          },
          vertical: {
              position: 'top',
              distance: 12,
              gap: 10
          }
      },
    theme: 'material',
    behaviour: {
      autoHide: 5000,
      onClick: 'hide',
      onMouseover: 'pauseAutoHide',
      showDismissButton: true,
      stacking: 4
    },
    animations: {
      enabled: true,
      show: {
        preset: 'slide',
        speed: 300,
        easing: 'ease'
      },
      hide: {
        preset: 'fade',
        speed: 300,
        easing: 'ease',
        offset: 50
      },
      shift: {
        speed: 300,
        easing: 'ease'
      },
      overlap: 150
    }
  };



@NgModule({
    declarations: [
        AppComponent,
        AlertComponent,
        HomeComponent,
        CarfexDashboardComponent,
        FooterComponent,
        VehicleListComponent,
        UserListComponent,
        CarfaxapiListComponent,
        VhrReportComponent,
        VhrpdfreportViewComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        ResetpasswordaftermailLinkComponent,
        UserProfileComponent,
        IicBatchComponent,
        DealerLocationComponent,
        MasterDashboardComponent,
        FilterPipe,
        ApicallHistoryComponent,
        VehicleDetailsComponent,
        SideNavComponent,
        HeaderComponent,
        AccountSettingComponent
,
        AddGroupComponent
,
        AddUserrolesComponent ,
        ListPostionsComponent ,
        ListWholesalersComponent ,
        AddRepsComponent ,
        ListRepoComponent ,
        ListDealersComponent ,
        AithrhubDashboardComponent
,
        ActivateDealersListComponent
,
        AddGlobalCodeCategoryComponent,
        GlobalCodeComponent
,
        AddInventoryComponent
,
        AddLenderComponent ,
        ListLendersComponent ,
        TrackingDetailsComponent ,
        FindcarDetailComponent ,
        BuildCarComponent,
        AdminSettingComponent,AddLenderRateSheetComponent
      ,FindcarDetailfinalComponent,AithrhubBuylogsComponent,InventoryimportExcelComponent,HubwholesaleDashboradComponent,
      FlagreportDashboardComponent,
      BuildReportComponent,
      CommunicationLogsComponent,
      LenderRateSheetPdfComponent,
      TwoDigitDecimaNumberDirectiveDirective
,
      NumericOnlyDirective
,
      WelcomeDashboardComponent,
      DetailListComponent,
      LenderPpsaComponent,
      AnalyticDashboardComponent,
      CcbvalueListComponent,
      FreshBuildComponent,
      PhoneMaskDirective,
      AddCustomerComponent,
      ManageHubinventoryComponent,
      CustomerWarrantykeyComponent,
      CustomerOrderComponent,
      AithrxClientDashboardComponent,
      LoanCalculatorComponent,
      OfferDashboardComponent,
      NotificationDashboardComponent,
      UnitClearanceComponent,
      InquiryLogoComponent,
      CarfaxapiDashabordComponent,
      TrainingVideoComponent,
      CreditapiLogComponent,
      CreditApplicationComponent,
      CreditorLogComponent,
      CreditorsTiresComponent,
      VehiclearchivedListComponent,
      CreditappCustomerComponent,
      CreditFindcarDetailfinalComponent,
      AithrVerificationComponent,
      AuctionsListComponent,
     AuctionsTimeslotsListComponent,
    AuctionsVehiclesListComponent,
    ListViewVehiclesComponent
,
    FilterPipeDataPipe,
    ReportDetailsComponent
,
    ReportmatrixComponent,
    WarrantylogsComponent
,
    DashboardComponent
,
    AboutUsComponent
,
    ContactUsComponent
    ],


    providers: [
     // Location,
        // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AccountService] },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        // provider used to create fake backend
        fakeBackendProvider,
        DatePipe,
        BsModalService
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        PdfViewerModule,
        ReactiveFormsModule,
        FormsModule,
        NgxSpinnerModule,
        HttpClientModule,
        AppRoutingModule,
        Ng2SearchPipeModule,
        JwPaginationModule,
        ModalModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        Ng2OrderModule,
        ChartsModule,
        NgxLoaderModule,
         PdfViewerModule,
        NgSelectModule,
        NgImageSliderModule,
        NgxPaginationModule,
        NgbModule,
        AgmOverlays,
        BrowserAnimationsModule,
        NotifierModule.withConfig(customNotifierOptions),
        AgmCoreModule.forRoot({
            // please get your own API key here:
            // https://developers.google.com/maps/documentation/javascript/get-api-key?hl=en
            apiKey: 'AIzaSyAjb57aQQNvA0qmT4zAt4mopp-zeEZyNKc',
            language: "en",
            libraries: ['visualization', 'places', 'drawing', 'geometry']
        }),


    ]
})
export class AppModule { }

