

<section id="admindashboard">
    <form class="col-12 px-0 mt-3" [formGroup]="ActivateDealerListForm">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12 mt-3">

                    <div class="row justify-content-between align-items-center mb-2">
                        <div class="col-auto documentbtn">
                              <ul class="list-unstyled mb-1">
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0">Copy</button>
                                  </li>
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0">CSV</button>
                                  </li>
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0">Excel</button>
                                  </li>
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0">PDF</button>
                                  </li>
                                  <li>
                                    <button class="btn btn-secondary btn-sm mb-sm-0" (click)="printTable()">Print</button>
                                  </li>
                              </ul>
                        </div>
                        <div class="search-heros col-sm-auto mt-2 mt-lg-0">
                            <div class="form-group d-flex mb-1">
                                <label class="mr-3 mt-2">Search:</label>
                                <input class="form-control" type="text" name="search" [(ngModel)]="searchText"
                                    [ngModelOptions]="{standalone: true}" autocomplete="off">
                            </div>
                        </div>
                    </div>


                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="card card-raised h-100">
                                <div class="card-header card-raised bg-info text-white">
                                    <div class="d-flex justify-content-between align-items-center">
                                        <div class="w-100">
                                            <h5 class="card-title text-white text-uppercase mb-0">List of Activate
                                                Dealers</h5>
                                            <div class="card-subtitle"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body p-4">
                                    <div class="table-responsive">
                                        <table id="usertable" class="table table-bordered" style="width:100%">
                                            <thead>
                                                <tr>
                                                    <th (click)="sort('dealerName')"> Dealer Name <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('dealerCity')"> City <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('dealerRegion')">Region/Province <i
                                                            class="fa fa-fw fa-sort"></i></th>
                                                    <th (click)="sort('country')">Address <i
                                                            class="fa fa-fw fa-sort"></i> </th>
                                                    <th (click)="sort('state')">Postal Code <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th (click)="sort('city')">Phone Number <i class="fa fa-fw fa-sort"></i>
                                                    </th>
                                                    <th>Active On</th>
                                                    <th>Active Until</th>
                                                    <th style="text-align:center;">Active</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let dealersListsdata of dealersLists| filter:searchText | slice: (page-1) * pageSize : (page-1) * pageSize + pageSize">
                                                   
                                                    <td>{{ dealersListsdata.dealerName }}</td>
                                                    <td> {{ dealersListsdata.dealerCity  }}</td>
                                                    <td> {{ dealersListsdata.dealerRegion }}</td>
                                                    <td> {{ dealersListsdata.dealerAddress }}</td>
                                                    <td> {{ dealersListsdata.postalCode }}</td>
                                                    <td> {{ dealersListsdata.dealerPhone }}</td>
                                                    <td> {{ dealersListsdata.activeStart |date }}</td>
                                                    <td> {{ dealersListsdata.activeUntil | date }}</td>
                                                    <td *ngIf="dealersListsdata.activated==='false'" style="text-align: center;"> 
                                                        <div style="display:block;" class="form-inline">
                                                            <button id="ActivateDealers" 
                                                            (click)="openActivateModel(ActivateModelPopUp,
                                                            dealersListsdata.dealerName)" class="btn btn-sm bg-gradient-success">
                                                                <i class="far fa-check-circle"></i>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td *ngIf="dealersListsdata.activated==='true'">Activated</td>
                                                </tr>

                                            </tbody>
                                        </table>
                                        <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="small" color="#fff" type="square-jelly-box" [fullScreen]="false">
                                          <p style="color: white"> Loading... </p>
                                      </ngx-spinner>
                                        <br>

                                        <div class="row justify-content-between align-items-center">
                                          <div class="col">
                                            
                                            <ngb-pagination [collectionSize]="dealersLists.length" [(page)]="page" [maxSize]="5"
                                            [rotate]="true" [ellipses]="false" [boundaryLinks]="true"></ngb-pagination>
                                          </div>
                                          <div class="col text-right">
                                            <h6><b>Total Records :</b> {{TotalRecord}}</h6>
                                          </div>
                                      </div>
                                     
                                       


                                    </div>
                                </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

          <ng-template #ActivateModelPopUp let-modal>
            <div class="modal-header" style="background-color: black;">
              <h5 class="modal-title" id="AccounttransferLabel" style="color: white;">Activate Dealers</h5>
              <button type="button" class="close" style="color: white;" #closebutton (click)="CloseAddmodel()"
                aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
  
              <section class="generate-report">
  
  
                <div class="form-group">
                    <input type="text" formControlName="DealerId"  class="form-control"
                      placeholder="Dealer Id" (keypress)="ValidTextBox($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.DealerId.errors }" />
                    <div *ngIf="submitted && f.DealerId.errors" class="invalid-feedback">
                      <div *ngIf="f.DealerId.errors.required">Dealer Id is required</div>
                    </div>
                  </div>

                  <div class="form-group">
                    <input type="text" formControlName="DealerName" [(ngModel)]="DealerNameValue" readonly
                     class="form-control"
                      placeholder="Dealer Name" (keypress)="ValidTextBox($event)"
                      [ngClass]="{ 'is-invalid': submitted && f.DealerName.errors }" />
                    <div *ngIf="submitted && f.DealerName.errors" class="invalid-feedback">
                      <div *ngIf="f.DealerName.errors.required">Dealer Name is required</div>
                    </div>
                  </div>
  
              </section>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary btn-sm text-white" (click)="onSubmit();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Activate
              </button>
  
              <button class="btn btn-danger btn-sm text-white" (click)="closeEditModel();">
                <i style="margin-right:4px;vertical-align:middle;font-size:13px;"></i> Cancel
              </button>
  
  
            </div>
          </ng-template>

    </form>
</section>