import { Component, ElementRef, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DealersList } from '@app/_models/DealersList';
import { Account } from '@app/_models/account';
import { AccountService, AlertService } from '@app/_services';
import { AddgroupService } from '@app/_services/addgroup.service';
import { GlobalcodeService } from '@app/_services/globalcode.service';
import { ListDealersService } from '@app/_services/list-dealers.service';
import { NotifierService } from 'angular-notifier';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.less']
})
export class AddCustomerComponent implements OnInit {

  AddCustomerForm: FormGroup;
  //UserResetPassword: FormGroup;
  private notifier: NotifierService;
  constructor(private fb: FormBuilder,
    private accountService: AccountService,
    private confirmModalService: BsModalService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private addgroupService: AddgroupService,
    private globalcodeService: GlobalcodeService,
    private listDealersService: ListDealersService,
    private router: Router,
    notifier: NotifierService) {
    this.notifier = notifier;
    this.accountService.SaveActivityLog("");
  }

  deleting = false;
  modalRef: BsModalRef;
  // for getting confirm box from HTML by Id
  @ViewChild('ConfirmBox', { static: false }) ConfirmBoxclick: ElementRef;

  @ViewChild('ConfirmBoxActivate', { static: false }) ConfirmBoxActivate: ElementRef;

  isValid: boolean = true;
  // sorting
  key = 'userName'; // set default
  reverse = false;
  sortedUsers: any;
  DeleteUserid: any;
  DeleteActivateUserid: any;
  accounts: Account[] = [];
  TotalRecord: any;
  CustomerList = [];
  dealersLists: DealersList[] = [];
  isDesc: boolean = false;
  column: string = 'firstName';
  modalcloseOpen: any;
  submitted = false;
  Grouplist = [];
  selectedGroupId: any;
  selectedDealerIds: string[];
  savegruopId: any;
  hidesupportTeam: any;
  selectedGroupIds: string[];
  selectedDealerId: any;
  dealerlistName = [];
  loading = false;
  rolelist = [];
  OpCode:any;
  GroupLists = [];
  Provincelist = [];
  ColourThemelist = [];
  edititemid: any = 0;
  editvaluename: any;
  updateid: any;
  //paging
  name = 'Angular';
  page = 1;
  pageSize = 10;
  selectedgroupId: any;

  pageSizes = [10, 20, 30, 40, 50, 60, 70];
  //ng model
  Id = null;
  Gid = 0;
  Did = 0;
  Rid = 0;
  firstname: any;
  middlename:any;
  lastname:any;
  lastName: any;
  email: any;
  phoneNumber: any;
  checkActivitylog: any;
  checkActivitylogValue: any;
  IsSupportTeam: any;
  IsSupportTeamValue: any;
  city: any;
  country: any;
  address:any;
  dlnumber:any;
  otherid:any;
  gst:any;
  insurance:any;
  region: any = 0;
  colortheme: any = 0;
  userRole: any;
  userRoleControl: any = 0;
  dealerName: any;
  groupName: any;
  postalcode = "";
  phonenumber:any;
  rolevalue: any;
  dealerNamevalue: any;
  GroupNamevalue: any;
  DealerIdvalue: any;
  province: any;
  ResetPwdEmail: any;
  ResetPwdNewPassword: any;
  ResetPwdConfirmPwd: any;
  password: any;
  confirmPassword: any;
  show = true;
  passwordConfirm="";
  showConfirm = true;
  userpopuplabel: string = "Add Customers";
   hideshowbutton: boolean = false;
   sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.CustomerList.sort(function (a, b) {
      if (a[property] < b[property]) {
        return -1 * direction;
      }
      else if (a[property] > b[property]) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  searchText;
  ColorTheme:any;
  ngOnInit(): void {
    this.Createform();
    this.hidesupportTeam = "false";
    this.hideshowbutton=false;
    this.checkActivitylogValue = false;
    this.IsSupportTeamValue = false;
    this.edititemid=0;
   
    this.GetProvincelist();
    this.GetCustomerList();
    // for Sending Dealer id
    this.rolevalue = localStorage.getItem('UserFullRole');
    this.dealerNamevalue = localStorage.getItem('DealerName');
    this.GroupNamevalue = localStorage.getItem('GroupName');
    this.DealerIdvalue = localStorage.getItem('DealerId');
    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }
    
    // this.dealerList=[];
  }
  handlePageSizeChange(event) {
    this.pageSize = event.target.value;
    this.page = 1;
   
  }
  GetProvincelist() {
    this.globalcodeService.getProvinceList()
      .subscribe(
        (data: any) => {
          debugger;
          this.Provincelist = data;
        },
        error => {
        });
  }

  // functiono for creating from

  Createform() {
    this.AddCustomerForm = new FormGroup({
      firstname: this.fb.control('', Validators.required),
      middlename: this.fb.control('', Validators.required),
      lastname: this.fb.control('', Validators.required),
      address: this.fb.control('', Validators.required),
      city: this.fb.control('', Validators.required),
      region: this.fb.control('', Validators.required),
      country: this.fb.control('', Validators.required),
      postalcode: this.fb.control('', [
        Validators.required,
        Validators.minLength(6),
        Validators.maxLength(6),
      ]),
     
      phonenumber: this.fb.control('', [
        Validators.required,
       Validators.pattern(/^\(\d{3}\)\s\d{3}-\d{4}$/)
      ]),
      email: this.fb.control('', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
      dlnumber: this.fb.control('', Validators.required),
      gst: this.fb.control("", Validators.required),
      insurance: this.fb.control("", Validators.required),
      otherid: this.fb.control(""),
    },
    );
  }

  copytable(el) {
    var urlField = document.getElementById(el);
    var range = document.createRange();
    range.selectNode(urlField);
    window.getSelection().addRange(range);
    document.execCommand('copy');
    this.showNotification("success", "Data copy to clipboard successfully")
  }

  exportexcel() {
    debugger
    const prepare = [];
    this.CustomerList.forEach(e => {
      const tempObj = [];
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.city);
      prepare.push(tempObj);
    });

    // Convert data to worksheet /
    const ws = XLSX.utils.aoa_to_sheet([
      ['First Name', 'Last Name', 'Email', 'Region', 'Phone Number', 'City'],
      ...prepare
    ]);

    //Create a new workbook and add the worksheet /
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'ListofDealers');

    // Save the workbook to a file /
    XLSX.writeFile(wb, 'ListofDealers.xlsx');
  }

  downloadPdf() {
    var prepare = [];
    this.CustomerList.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.city);
      prepare.push(tempObj);
    });
    const doc = new jsPDF('l', 'pt', 'a4');
    // doc.autoTable({
    //     head: [['lenderName','','planType','','fltVnew','','fltv','','bltv','','docFee','','lenderFee','','otherFee']],
    //     body: prepare
    // });
    autoTable(doc, {
      head: [['First Name', 'Last Name', 'Email' , 'Region', 'Phone Number', 'City']],
      body: prepare,
      didDrawCell: (data) => { },
    });
    doc.save('Customer_List' + '.pdf');
  }

  printTable() {
    var prepare = [];
    this.CustomerList.forEach(e => {
      var tempObj = [];
      tempObj.push(e.firstName);
      tempObj.push(e.lastName);
      tempObj.push(e.email);
      tempObj.push(e.globalCodeName);
      tempObj.push(e.phone);
      tempObj.push(e.city);
      prepare.push(tempObj);
    });

    var table = '<table><thead><tr><th>First Name</th><th>Last Name</th><th>Email</th><th>Region</th><th>Phone Number</th><th>City</th></tr></thead><tbody>';
    prepare.forEach(row => {
      table += '<tr>';
      row.forEach(col => {
        table += '<td>' + col + '</td>';
      });
      table += '</tr>';
    });
    table += '</tbody></table>';

    // Create a new window and write only the table HTML to it
    var newWin = window.open('');
    newWin.document.write('<html><head><title>Table</title><style>table {border-collapse: collapse;width: 100%;}th, td {text-align: left;padding: 8px;}th {background-color: #f2f2f2;}</style></head><body>' + table + '</body></html>');
    newWin.document.close();

    // Wait for the table to be fully rendered before printing
    newWin.onload = function () {
      newWin.focus();
      newWin.print();
      newWin.close();
    };
  }

  openAddModel(targetModal, userid) {
    this.submitted = false;
    this.hideshowbutton = false;
    this.userpopuplabel = "Add Customers";
    if (userid != undefined && userid != "") {
      this.userpopuplabel = "Edit Customers";
      this.edititemid = userid;
      this.hideshowbutton=true;

      var modelGroup = {
        "Id": userid
      };

      this.listDealersService.getCustomersById(modelGroup).subscribe(
        (data: any) => {
          this.firstname=data.firstName;
          this.middlename=data.middleName;
          this.lastname=data.lastName;
          this.address=data.address;
          this.city=data.city;
          this.region=data.provinceID;
          this.country = data.country;
          this.postalcode= data.postalcode;
          this.phonenumber = data.phone;
          this.email = data.email;
           this.dlnumber=data.dlNumber;
           this.otherid= data.otherIDnumber;
           this.gst=data.gstNumber;
           this.insurance=data.insurancenumber;

          this.modalRef = this.confirmModalService.show(targetModal, {
            class: 'modal-dialog-centered modal-lg',
            backdrop: 'static',
            keyboard: false
          });
        },
        error => {
          // Handle error
        }
      );
    } else {
      this.Createform();
      this.reinitiatengmodel();
      this.edititemid = "";
      this.modalRef = this.confirmModalService.show(targetModal, {
        class: 'modal-dialog-centered modal-lg',
        backdrop: 'static',
        keyboard: false
      });
    }
  }

  
  ValidTextBox(event: KeyboardEvent) {
    this.isValid = true;
  }

 

  
  //notifier
  public showNotification(type: string, message: string): void {
    this.notifier.notify(type, message);
  }
  // function for getting user list
  GetCustomerList() {
    var modelGroup = {
      "id": "0"
    }
    this.listDealersService.getCustomerLists(modelGroup)
      .subscribe(
        (data: any) => {
          this.CustomerList = data;
          this.TotalRecord = data.length;
          // this.modalRef.hide();
        },
        error => {
        });
  }

  
  //function for delete
  onDelete(UserId: any) {
   debugger;
    this.DeleteUserid = UserId;
    let el: HTMLElement = this.ConfirmBoxclick.nativeElement;
    el.click();
  }

  // function for open model pop up
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.confirmModalService.show(template, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });
  }
  CloseAddmodel() {
   this.hideshowbutton = false;
    this.modalRef.hide();
  }

  closepopupmodel() {
    this.AddCustomerForm.invalid
  }

  openAddUserModel(targetModal) {

    this.modalRef = this.confirmModalService.show(targetModal, { class: 'modal-dialog-centered modal-lg', backdrop: 'static', keyboard: false });

  }
  // function for confirm
  confirm(): void {
    this.modalRef.hide();
    this.deleteCustomer(this.DeleteUserid);
  }
  decline(): void {
    this.modalRef.hide();
  }

  // function for confirm
  

  get f() { return this.AddCustomerForm.controls; }
  get phoneNumberControl() {
    return this.AddCustomerForm.get('phonenumber');
  }
  keyPressCheck(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
  

  keyPress(event: any) {
    const pattern = /[0-9\+\-\ ]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 8 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.AddCustomerForm.controls.firstname.value == "") {
      this.isValid = false;
      return;
    }

    if (this.AddCustomerForm.controls.middlename.value == "") {
      this.isValid = false;
      return;
    }

    if (this.AddCustomerForm.controls.lastname.value == "") {
      this.isValid = false;
      return;
    }

    if (this.AddCustomerForm.controls.email.value == "") {
      this.isValid = false;
      return;
    }
    const phoneNumberField = this.phoneNumberControl;
    if (phoneNumberField.value && phoneNumberField.invalid) {
      this.isValid = false;
      return;
    } else if (!phoneNumberField.value) {
         this.isValid = false;
         return;
       }
    

    if (this.AddCustomerForm.controls.city.value == "") {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.region.value == "") {
      this.isValid = false;
      return;
    }

   // Check postal code validity
  const postalCodeField = this.AddCustomerForm.get('postalcode');
  if (postalCodeField.value && postalCodeField.invalid) {
    this.isValid = false;
    return;
  } else if (!postalCodeField.value) {
    this.isValid = false;
    return;
  }

    if (this.AddCustomerForm.controls.country.value == 0) {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.dlnumber.value == 0) {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.gst.value == 0) {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.otherid.value == 0) {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.insurance.value == 0) {
      this.isValid = false;
      return;
    }
    

    if (this.AddCustomerForm.controls.email.errors != null) {
      this.isValid = false;
      return;
    }
    if (this.AddCustomerForm.controls.invalid) {
      this.isValid = false;
      return;
    }
    else {
      var modelGroup = {
        "Id":"0" ,
        "firstname": this.AddCustomerForm.controls.firstname.value,
        "middlename": this.AddCustomerForm.controls.middlename.value,
        "lastname": this.AddCustomerForm.controls.lastname.value,
        "address": this.AddCustomerForm.controls.address.value,
        "city": this.AddCustomerForm.controls.city.value,
        "region": this.AddCustomerForm.controls.region.value,
        "Province": this.AddCustomerForm.controls.region.value,
        "ProvinceID": this.AddCustomerForm.controls.region.value,
        "country": this.AddCustomerForm.controls.country.value,
        "postalcode": this.AddCustomerForm.controls.postalcode.value,
        "email": this.AddCustomerForm.controls.email.value,
        "Phone": this.phoneNumberControl.value,//this.UserListForm.controls.phoneNumber.value,
        "dlnumber": this.AddCustomerForm.controls.dlnumber.value,
        "OtherIDnumber": this.AddCustomerForm.controls.otherid.value,
        "GSTNumber": this.AddCustomerForm.controls.gst.value,
        "Insurancenumber": this.AddCustomerForm.controls.insurance.value,
        "Dealerid":this.DealerIdvalue,
      }
      //console.log("Save User Data:", modelGroup);

      this.listDealersService.saveaddCustomers(modelGroup)
        .subscribe(
          (data: any) => {
            this.hideshowbutton=false;
            if (data == "2" || data == "4") {
              console.log(data)
              this.showNotification("error", "Customer already registered in system.");
              return;
            } else {
              this.GetCustomerList()
              this.showNotification("success", "Data saved successfully");
            }

            this.submitted = false;
            this.reinitiatengmodel();
            this.Createform();
            this.ngOnInit();
            this.CloseAddmodel();
            this.closepopupmodel();
            //console.log("Save User Response:", data);
          },
          error => {
          });
      this.loading = true;
      this.isValid = true;
    }
  }

  UpdateCustomer() {
   this.submitted = true;
   if (this.AddCustomerForm.controls.firstname.value == "") {
     this.isValid = false;
     return;
   }

   if (this.AddCustomerForm.controls.middlename.value == "") {
     this.isValid = false;
     return;
   }

   if (this.AddCustomerForm.controls.lastname.value == "") {
     this.isValid = false;
     return;
   }

   if (this.AddCustomerForm.controls.email.value == "") {
     this.isValid = false;
     return;
   }
   const phoneNumberField = this.phoneNumberControl;
   if (phoneNumberField.value && phoneNumberField.invalid) {
     this.isValid = false;
     return;
   } else if (!phoneNumberField.value) {
        this.isValid = false;
        return;
      }
   

   if (this.AddCustomerForm.controls.city.value == "") {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.region.value == "") {
     this.isValid = false;
     return;
   }

   if (this.AddCustomerForm.controls.postalcode.value == 0) {
     this.isValid = false;
     return;
   }

   if (this.AddCustomerForm.controls.country.value == 0) {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.dlnumber.value == 0) {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.gst.value == 0) {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.otherid.value == 0) {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.insurance.value == 0) {
     this.isValid = false;
     return;
   }
   

   if (this.AddCustomerForm.controls.email.errors != null) {
     this.isValid = false;
     return;
   }
   if (this.AddCustomerForm.controls.invalid) {
     this.isValid = false;
     return;
   }
   else {
     var modelGroup = {
       "Id":this.edititemid ,
       "firstname": this.AddCustomerForm.controls.firstname.value,
       "middlename": this.AddCustomerForm.controls.middlename.value,
       "lastname": this.AddCustomerForm.controls.lastname.value,
       "address": this.AddCustomerForm.controls.address.value,
       "city": this.AddCustomerForm.controls.city.value,
       "region": this.AddCustomerForm.controls.region.value,
       "Province": this.AddCustomerForm.controls.region.value.toString(),
       "ProvinceID": this.AddCustomerForm.controls.region.value,
       "country": this.AddCustomerForm.controls.country.value,
       "postalcode": this.AddCustomerForm.controls.postalcode.value,
       "email": this.AddCustomerForm.controls.email.value,
       "Phone": this.phoneNumberControl.value,//this.UserListForm.controls.phoneNumber.value,
       "dlnumber": this.AddCustomerForm.controls.dlnumber.value,
       "OtherIDnumber": this.AddCustomerForm.controls.otherid.value,
       "GSTNumber": this.AddCustomerForm.controls.gst.value,
       "Insurancenumber": this.AddCustomerForm.controls.insurance.value,
     }
     //console.log("Save User Data:", modelGroup);

     this.listDealersService.updateCustomer(modelGroup)
       .subscribe(
         (data: any) => {
          this.hideshowbutton=false;
          this.GetCustomerList()
          this.showNotification("success", "Customer updated successfully");
          
           this.submitted = false;
           this.reinitiatengmodel();
           this.Createform();
           this.ngOnInit();
           this.CloseAddmodel();
           this.closepopupmodel();
           //console.log("Save User Response:", data);
         },
         error => {
         });
     this.loading = true;
     this.isValid = true;
   }
 }

  reinitiatengmodel() {
    this.firstname = "";
    this.middlename = "";
    this.lastname = "";
    this.address = "";
    this.city = "";
    this.region = "";
    this.country = "";
    this.postalcode = "";
    this.phonenumber = "";
    this.email = "";
    this.dlnumber = "";
    this.otherid = "";
    this.gst = "";
    this.insurance = "";

  }
  // function for delete users
  deleteCustomer(email: any) {
    var modelGroup = {
      "id": this.DeleteUserid
    }
    this.listDealersService.DeleteCustomer(modelGroup)
      .subscribe(
        (data: any) => {
          this.showNotification("success", "Deleted successfully");
          this.GetCustomerList();
          return this.router.navigateByUrl("/add-customer");
        },
        (error: any) => {
          this.alertService.error("error");
        }
      );
  }

}

