import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ManageInventoryService } from '@app/_services/manageInventory.service';

@Component({
  selector: 'app-build-report',
  templateUrl: './build-report.component.html',
  styleUrls: ['./build-report.component.less']
})
export class BuildReportComponent implements OnInit {
  buildreportForm: FormGroup;
  constructor(private manageInventoryService: ManageInventoryService,) { }


  LenderName:any;
  LenderKey: any;
  PayCall: any;
  InterestRate: any;
  AllowableFinance: any;
  Condition: any;
  Make:any;
  StockNumber: any;
  Model: any;
  VIN: any;
  kms:any;
  year:any;
  trim:any;
  AmountFinanced:any;
  CBBValue:any;
  ListPrice:any;
  DealerCost:any;
  TradeAllowance:any;
  Lien:any;
  ACVControl:any;
  EquityControl:any;
  DownPaymentControl:any;
  TotalTaxControl:any;
  RegionControl:any;
  TaxRateControl:any;
  MonthlyControl:any;
  interestRateControl:any;
  SemiMonthlyControl:any;
  WeeklyControl:any;
  BiWeeklyControl:any;
  ReservesControl:any;
  TotalLoantoValueControl:any;
  GrossProfit:any;
  Term:any;
  LenderFee:any;
  PPSA:any;
  FrontDocFee:any;
  BackDocFee:any;
  OverRideFLTV:any;
  OverRideBLTV:any;
  TOTALAccessories:any;
  AccessoriesTOTALCost:any;
  TOTALAddOns:any;
  TOTALBackEndCost:any;
  ExtendendService:any;
  ExtendendCost:any;
  Gap:any;
  GapCost:any;
  JobLoss:any;
  JobLossCost:any;
  DealerName:any;
  ColorTheme:any;
  ngOnInit(): void {
   

    this.ColorTheme = localStorage.getItem('colorTheme');
    var element = document.body;
    if (this.ColorTheme === "White") {
      element.classList.remove("dark-mode");
    } else {

      element.classList.add("dark-mode");
    }

    var PageValues = localStorage.getItem('builPageValues');
    let objValues = JSON.parse(PageValues);
    //this.AdjustmentsListPrice = parseFloat(objValues.listPrice).toFixed(2);

    this.LenderName= objValues.LenderName;
    this.LenderKey= objValues.LenderKey;
    this.PayCall= parseFloat(objValues.PayCall).toFixed(2);
    this.InterestRate= parseFloat(objValues.InterestRate).toFixed(2);
    this.AllowableFinance= parseFloat(objValues.AllowableFinance).toFixed(2);
    this.Condition= objValues.Condition;
    this.Make= objValues.Make;
    this.StockNumber= objValues.StockNumber;
    this.Model= objValues.Model;
    this.VIN= objValues.VIN;
    this.kms= objValues.kms;
    this.year= objValues.year;
    this.trim= objValues.trim;

   
    this.AmountFinanced= parseFloat(objValues.AmountFinanced).toFixed(2);
    this.CBBValue= parseFloat(objValues.ListPrice).toFixed(2);
    this.ListPrice= parseFloat(objValues.ListPrice).toFixed(2);
    this.DealerCost= parseFloat(objValues.DealerCost).toFixed(2);
    this.TradeAllowance= parseFloat(objValues.TradeAllowance).toFixed(2);
    this.Lien= parseFloat(objValues.Lien).toFixed(2);
    this.ACVControl= parseFloat(objValues.ACVControl).toFixed(2);
    this.EquityControl= parseFloat(objValues.EquityControl).toFixed(2);
    this.DownPaymentControl= parseFloat(objValues.DownPaymentControl).toFixed(2);
    this.TotalTaxControl= parseFloat(objValues.TotalTaxControl).toFixed(2);
    this.RegionControl= objValues.RegionControl;
    this.TaxRateControl= parseFloat(objValues.TaxRateControl).toFixed(2);
    this.MonthlyControl= parseFloat(objValues.MonthlyControl).toFixed(2);
    this.interestRateControl= parseFloat(objValues.interestRateControl).toFixed(2);
    this.SemiMonthlyControl= parseFloat(objValues.SemiMonthlyControl).toFixed(2);
    this.WeeklyControl= parseFloat(objValues.WeeklyControl).toFixed(2);
    this.BiWeeklyControl= parseFloat(objValues.BiWeeklyControl).toFixed(2);
    this.ReservesControl= parseFloat(objValues.ReservesControl).toFixed(2);
    this.TotalLoantoValueControl= parseFloat(objValues.TotalLoantoValueControl).toFixed(2);
    this.GrossProfit= parseFloat(objValues.GrossProfit).toFixed(2);
    this.Term= parseFloat(objValues.Term).toFixed(2);
    this.LenderFee= parseFloat(objValues.LenderFee).toFixed(2);
    this.PPSA= parseFloat(objValues.PPSA).toFixed(2);

    // changes for Fornt and Back end doc fee

    //this.FrontDocFee= parseFloat(objValues.FrontDocFee).toFixed(2);

    if(objValues.FrontDocFee >0 && objValues.BackDocFee==0)
    {
      this.FrontDocFee = parseFloat(objValues.FrontDocFee).toFixed(2)
    } else if(objValues.FrontDocFee <=0 && objValues.BackDocFee>0)
    {
      this.FrontDocFee = parseFloat(objValues.BackDocFee).toFixed(2)
    } else if(objValues.FrontDocFee <=0 && objValues.BackDocFee<=0)
    {
      this.FrontDocFee=0.00;
    }
    this.BackDocFee= parseFloat(objValues.BackDocFee).toFixed(2);
    this.OverRideFLTV= parseFloat(objValues.OverRideFLTV).toFixed(2);
    this.OverRideBLTV= parseFloat(objValues.OverRideBLTV).toFixed(2);
    this.TOTALAccessories= parseFloat(objValues.TOTALAccessories).toFixed(2);
    this.AccessoriesTOTALCost= parseFloat(objValues.AccessoriesTOTALCost).toFixed(2);
    this.TOTALAddOns= parseFloat(objValues.TOTALAddOns).toFixed(2);
    this.TOTALBackEndCost= parseFloat(objValues.TOTALBackEndCost).toFixed(2);
    this.ExtendendService= parseFloat(objValues.ExtendendService).toFixed(2);
    this.ExtendendCost= parseFloat(objValues.ExtendendCost).toFixed(2);
    this.Gap= parseFloat(objValues.Gap).toFixed(2);
    this.GapCost= parseFloat(objValues.GapCost).toFixed(2);
    this.JobLoss= parseFloat(objValues.JobLoss).toFixed(2);
    this.JobLossCost= parseFloat(objValues.JobLossCost).toFixed(2);

    this.GetVechileDetailByVIN(objValues.VIN);
  }

//   printPage() {
//    // const printContents = document.getElementById(admindashboard).innerHTML;
// //  const originalContents = document.body.innerHTML;
//     // var element = document.body;
//    // document.body.innerHTML = printContents;
//    document.body.classList.add('sidebar');
//     document.body.classList.add('scrollbarhide');
//     window.print();
//     document.body.classList.remove('sidebar');
//     document.body.classList.remove('scrollbarhide');
    
//   //  document.body.innerHTML = originalContents;
//     // var print = document.getElementById('collapseOne').innerHTML;
//   }
 printPage() {
  const printContents = document.getElementById('collapseOne').innerHTML;
  const originalContents = document.body.innerHTML;
  
  document.body.classList.add('sidebar');
  document.body.classList.add('scrollbarhide');
  
  document.body.innerHTML = printContents;
  
  window.print();
  
  document.body.innerHTML = originalContents;
  document.body.classList.remove('sidebar');
  document.body.classList.remove('scrollbarhide');
}

//  printPage() {
//   const printContents = document.getElementById('admindashboard').innerHTML;
//   const originalContents = document.body.innerHTML;

//   document.body.classList.add('sidebar');
//   document.body.classList.add('scrollbarhide');

//   document.body.innerHTML = printContents;

//   window.print();

//   setTimeout(function() {
//     document.body.innerHTML = originalContents;
//     document.body.classList.remove('sidebar');
//     document.body.classList.remove('scrollbarhide');
//   }, 1000); // Adjust the timeout duration as needed
// }




//  printPage() {
//   const printContents = document.getElementById('collapseOne').innerHTML;
//   const originalContents = document.body.innerHTML;
//   const originalClassList = Array.from(document.body.classList);

//   document.body.classList.add('sidebar');
//   document.body.classList.add('scrollbarhide');

//   document.body.innerHTML = printContents;

//   window.print();

//   setTimeout(function () {
//     document.body.innerHTML = originalContents;
//     document.body.classList.add(...originalClassList);
//   }, 100);
// }

  GetVechileDetailByVIN(VIN:any) {
    var modelGroup = {
      "VIN": VIN
    }
    this.manageInventoryService.getVechileDetailByVIN(modelGroup)
      .subscribe(
        (data: any) => {
         debugger;
         this.DealerName = data[0].dealerName;
        },
        error => {
        });


  }

}
